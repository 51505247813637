import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { primary, black },
} = colors
const radioButtonSize = reduceCSSCalc(`calc(2 * ${unit})`)

const radio = {
  fieldMarginBottom: reduceCSSCalc(`calc(1.5 * ${unit})`),
  buttonSize: radioButtonSize,
  innerMargin: reduceCSSCalc(`calc(${radioButtonSize} / 4)`),
  innerColor: primary,
  focusColor: color.convert(`color(${black} a(10%))`),
  checkedFocusColor: color.convert(`color(${primary} a(26%))`),
  textColor: black,
  disabledColor: color.convert(`color(${black} a(26%))`),
  textFontSize: reduceCSSCalc(`calc(1.4 * ${unit})`),
}

export default radio
