import { css, useTheme } from '@emotion/react'
import { matchPath } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { Button, SVGWrapper } from '../../../../components'
import { Bank, PiggyBank, Link } from '../../../../assets/icons'
import { numberToDollars } from '../../../../utils'
import { planTypeIdToAccountKey } from '../../../../constants'
import _ from 'lodash'

import {
  AccountGoalBox,
  BalanceSD,
  LabelSD,
  MainSideBarLabel,
  RowSD,
  SideDrawerButtonWrapper,
  AccountTypeLabel,
} from './styled'

function SideDrawerAccounts(props) {
  const theme = useTheme()
  const defaultFill = theme.svgSideBarDrawerFill

  const typeToPathKey = {
    Annuity: 'annuity',
    Institutional: 'institutional',
    NonGc: 'non-gc',
    Pension: 'pension',
  }

  const handleAddAccountClick = () => {
    const { history } = props
    history.push('/accounts/add')
  }

  const handleAccountClick = () => {
    const { history } = props
    history.push('/accounts')
  }

  const employerRelatedAccounts = () => {
    const {
      history,
      location,
      store: {
        person: { displayName },
        features,
        config,
        account: { managedAccount },
        employerAccounts,
        stockOptionsOverview: { hasVested, hasNotVested },
        baseCase,
      },
    } = props

    const handleAccountClick = ({ type, planType, id }) => {
      const accountType = planTypeIdToAccountKey[planType]
      history.push(`/accounts/${typeToPathKey[type]}/${accountType}/${id}`)
    }

    const isHighlighted = account => {
      const accountType = planTypeIdToAccountKey[account.planType]
      return !!matchPath(location.pathname, {
        path: `/accounts/${typeToPathKey[account.type]}/${accountType}/${account.id}`,
      })
    }

    return employerAccounts.length > 0 ? (
      <>
        <RowSD>
          <AccountTypeLabel>
            <div>Employer-related accounts</div>
            <div
              css={css`
                font-size: 12px;
                margin-top: 4px;
              `}>
              {features.managedAccountAvailable && managedAccount
                ? '* ' + config.template.managedAccountLabel
                : ''}
            </div>
          </AccountTypeLabel>
        </RowSD>

        {/* Institutional Account */}
        {employerAccounts
          .filter(account => account.type === 'Institutional')
          .map(account => (
            <AccountGoalBox
              id={'sidebar-account-' + account.type + '-' + account.id}
              key={account.type + account.id}
              isHighlighted={isHighlighted(account)}
              onClick={() => handleAccountClick(account)}>
              <RowSD>
                <LabelSD>
                  {account.name}
                  {features.managedAccountAvailable && managedAccount ? '*' : ''}
                </LabelSD>
              </RowSD>
              <RowSD>
                <BalanceSD isHighlighted={isHighlighted(account)}>
                  {account.contributing &&
                    _.get(baseCase, 'primary.ongoingAnnualContributions', 0) > 0 && (
                      <SVGWrapper
                        svg={PiggyBank}
                        fill={defaultFill}
                        size='small'
                        css={css`
                          margin: 0 8px;
                          vertical-align: top;
                        `}
                      />
                    )}
                  ${numberToDollars(account.balance || 0, true)}
                </BalanceSD>
              </RowSD>
            </AccountGoalBox>
          ))}

        {/* Additional Employer Sponsored Accounts */}
        {employerAccounts
          .filter(account => account.type !== 'Institutional' && account.employerSponsoredAccount)
          .map(account => (
            <AccountGoalBox
              id={'sidebar-account-' + account.type + '-' + account.id}
              key={account.type + account.id}
              isHighlighted={isHighlighted(account)}
              onClick={() => handleAccountClick(account)}>
              <RowSD>
                <LabelSD>{account.name}</LabelSD>
              </RowSD>
              <RowSD>
                <BalanceSD isHighlighted={isHighlighted(account)}>
                  {account.contributing && (
                    <SVGWrapper
                      svg={PiggyBank}
                      fill={defaultFill}
                      size='small'
                      css={css`
                        margin: 0 8px;
                        vertical-align: top;
                      `}
                    />
                  )}
                  ${numberToDollars(account.balance || 0, true)}
                </BalanceSD>
              </RowSD>
            </AccountGoalBox>
          ))}

        {/* Stock Options */}
        {features.stockOptionsEnabled && (hasVested || hasNotVested) && (
          <AccountGoalBox
            id='sidebar-account-stock-options'
            key='stock-options'
            isHighlighted={Boolean(
              matchPath(location.pathname, { path: '/accounts/stock-options' })
            )}
            onClick={() => history.push('/accounts/stock-options')}>
            <RowSD>
              <LabelSD>{displayName}'s Stock Options</LabelSD>
            </RowSD>
            <RowSD>
              <BalanceSD
                isHighlighted={Boolean(
                  matchPath(location.pathname, { path: '/accounts/stock-options' })
                )}>
                {hasVested && <div>Vested</div>}
                {hasNotVested && <div>Unvested</div>}
              </BalanceSD>
            </RowSD>
          </AccountGoalBox>
        )}

        {/* Non-GC Accounts */}
        {employerAccounts
          .filter(account => account.type !== 'Institutional' && !account.employerSponsoredAccount)
          .map(account => (
            <AccountGoalBox
              id={'sidebar-account-' + account.type + '-' + account.id}
              key={account.type + account.id}
              isHighlighted={isHighlighted(account)}
              onClick={() => handleAccountClick(account)}>
              <RowSD>
                <LabelSD>{account.name}</LabelSD>
              </RowSD>
              <RowSD>
                <BalanceSD isHighlighted={isHighlighted(account)}>
                  {account.plaidLinked && (
                    <span
                      css={css`
                        font-size: 14px;
                        margin: 0 6px;
                      `}>
                      <Link />
                    </span>
                  )}
                  {account.contributing && (
                    <SVGWrapper
                      svg={PiggyBank}
                      fill={defaultFill}
                      size='small'
                      css={css`
                        margin: 0 8px;
                        vertical-align: top;
                      `}
                    />
                  )}
                  ${numberToDollars(account.balance || 0, true)}
                </BalanceSD>
              </RowSD>
            </AccountGoalBox>
          ))}
      </>
    ) : null
  }

  const otherAccounts = () => {
    const {
      history,
      location,
      store: { otherAccounts },
    } = props

    const handleAccountClick = ({ type, planType, id }) => {
      const accountType = planTypeIdToAccountKey[planType]
      history.push(`/accounts/${typeToPathKey[type]}/${accountType}/${id}`)
    }

    const isHighlighted = account => {
      const accountType = planTypeIdToAccountKey[account.planType]
      return !!matchPath(location.pathname, {
        path: `/accounts/${typeToPathKey[account.type]}/${accountType}/${account.id}`,
      })
    }

    return otherAccounts.length > 0 ? (
      <>
        <RowSD>
          <AccountTypeLabel>
            <div>Other types of accounts</div>
            <div
              css={css`
                font-size: 12px;
                margin-top: 4px;
              `}
            />
          </AccountTypeLabel>
        </RowSD>
        {otherAccounts.map(account => (
          <AccountGoalBox
            id={'sidebar-account-' + account.type + '-' + account.id}
            key={account.type + account.id}
            isHighlighted={isHighlighted(account)}
            onClick={() => handleAccountClick(account)}>
            <RowSD>
              <LabelSD>{account.name}</LabelSD>
            </RowSD>
            <RowSD>
              <BalanceSD isHighlighted={isHighlighted(account)}>
                {account.contributing && (
                  <SVGWrapper
                    svg={PiggyBank}
                    fill={defaultFill}
                    size='small'
                    css={css`
                      margin: 0 8px;
                      vertical-align: top;
                    `}
                  />
                )}
                ${numberToDollars(account.balance || 0, true)}
              </BalanceSD>
            </RowSD>
          </AccountGoalBox>
        ))}
      </>
    ) : null
  }

  const handleSsiAccountClick = () => {
    const { history } = props
    history.push('/social-security')
  }
  const {
    person: { displayName, maritalStatus, includeSpouse },
    spouse: { firstName: spouseName },
    primarySSIBenefits,
    spouseSSIBenefits,
    features,
  } = props.store
  const { location } = props

  return (
    <div>
      <MainSideBarLabel
        isHighlighted={!!matchPath(location.pathname, { path: '/accounts', exact: true })}
        onClick={handleAccountClick}>
        <SVGWrapper
          css={css`
            display: flex;
            justify-content: flex-start;
            align-items: center;
            & span {
              width: 20px;
              height: 24px;
            }
          `}
          margin='4px'
          svg={Bank}
          size='mediumLarge'
          stroke={defaultFill}
        />
        <span>Accounts</span>
      </MainSideBarLabel>

      {employerRelatedAccounts()}

      {otherAccounts()}

      <RowSD>
        <AccountTypeLabel>Social Security</AccountTypeLabel>
      </RowSD>
      <AccountGoalBox cursor='pointer' onClick={handleSsiAccountClick}>
        <RowSD>
          <LabelSD>{displayName}</LabelSD>
          <BalanceSD>
            {_.get(primarySSIBenefits, 'includeSsi', null) ? 'Include' : 'Exclude'}
          </BalanceSD>
        </RowSD>
      </AccountGoalBox>
      {maritalStatus && includeSpouse && (
        <AccountGoalBox cursor='pointer' onClick={handleSsiAccountClick}>
          <RowSD>
            <LabelSD>{spouseName}</LabelSD>
            <BalanceSD>
              {_.get(spouseSSIBenefits, 'includeSsi', null) ? 'Include' : 'Exclude'}
            </BalanceSD>
          </RowSD>
        </AccountGoalBox>
      )}

      {features.allowAddAccounts && (
        <SideDrawerButtonWrapper
          css={css`
            padding-bottom: 48px;
          `}>
          <Button
            secondary
            label='+ Add a new account'
            size='small'
            onClick={handleAddAccountClick}
          />
        </SideDrawerButtonWrapper>
      )}
    </div>
  )
}

export default inject('store')(observer(SideDrawerAccounts))
