import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'

import { FillGaugeWithDescription, ComparisonFillGaugeWithDescription } from '../elements'

const LeftSection = styled.div`
  flex: 0 1 575px;
  margin-bottom: ${p => (p.marginBottom ? p.marginBottom : '24px')};
`
const SubHeader = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  padding-bottom: 8px;
  line-height: 1.25;
`

const StrategyResultsSection = ({ store, displayInflation }) => {
  const { modifiedCase } = store

  return (
    <LeftSection>
      <SubHeader>
        {modifiedCase ? 'Compare new strategy results' : 'Recommended strategy results'}
      </SubHeader>
      {modifiedCase ? (
        <ComparisonFillGaugeWithDescription displayInflation={displayInflation} />
      ) : (
        <FillGaugeWithDescription
          displayInflation={displayInflation}
          engineType='recommended'
          label='Recommended'
          id='recommendedRetirementGoalInitial'
        />
      )}
    </LeftSection>
  )
}

export default inject('store')(observer(StrategyResultsSection))
