import { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'

const heights = {
  extraLarge: '140px',
  large: '80px',
  medium: '54px',
  small: '38px',
  h41: '41px',
}

const fontSizes = {
  extraLarge: '1.5rem',
  large: '1.125rem',
  medium: '1.125rem',
  small: '1rem',
}

const primary = p =>
  p.primary &&
  css`
    & {
      color: #ffffff;
      background: ${p.theme.recalculateButtonColor ||
      'linear-gradient(to bottom, #39a3c6, #1291bb)'};
      min-height: 38px;
      min-width: 85px;
      height: ${heights[p.size]};
    }

    &:disabled {
      cursor: default;
      background: ${p.theme.disabledRecalculateButtonColor};
    }

    &:active:not(:disabled) {
      background: ${p.theme.activeRecalculateButtonColor};
    }

    &:hover:not(:disabled):not(:active) {
      background: ${p.theme.hoverRecalculateButtonColor};
    }
  `

const secondary = p =>
  p.secondary &&
  css`
    color: ${p.theme.addButtonTextColor};
    background-color: ${p.theme.addButtonBgColor};
    border: ${p.theme.addButtonBorder};
    min-height: 38px;
    min-width: 85px;
    height: ${heights[p.size]};
    &:disabled {
      cursor: default;
      color: ${p.theme.addButtonDisabledTextColor};
      background-color: ${p.theme.addButtonDisabledColor};
      border: ${p.theme.addButtonDisabledBorder};
    }
    &:active:not(:disabled) {
      color: ${p.theme.addButtonActiveTextColor};
      background-color: ${p.theme.addButtonActiveColor};
      border: ${p.theme.addButtonActiveBorder};
    }
    &:hover:not(:disabled):not(:active) {
      color: ${p.theme.addButtonHoverTextColor};
      background-color: ${p.theme.addButtonHoverColor};
      border: ${p.theme.addButtonHoverBorder};
    }
  `

const select = p =>
  p.select &&
  css`
    & {
      color: ${p.selected ? '#4d9927' : 'white'};
      background: ${p.selected ? 'white' : '#4d9927'};
      min-height: 38px;
      min-width: 85px;
      height: ${heights[p.size]};
    }

    &:disabled {
      cursor: default;
      background: ${p.selected ? '' : '#B8D6A9'};
      color: ${p.selected ? '#B8D6A9' : ''};
    }

    &:active:not(:disabled) {
      background: ${p.selected ? '' : '#4f793a'};
      color: ${p.selected ? '#4F793A' : ''};
    }

    &:hover:not(:disabled):not(:active) {
      background: ${p.selected ? '#E6E9EA' : '#3f7e20'};
      #E6E9EA
    }
  `

const leftArrow = css`
  content: '\\2039';
  left: 46%;
`
const rightArrow = css`
  content: '\\203A';
  left: 54%;
`

const round = p =>
  p.round &&
  css`
    position: relative;
    border: 1px solid #b6c0c4;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    font-size: 4rem;
    line-height: 2.25rem;
    background-color: #ffffff;
    color: #b6c0c4;

    &:hover:not(:active) {
      background-color: #f3f4f5;
      color: #022a3a;
    }

    &:active {
      background-color: #ffffff;
      color: #038ab7;
    }

    &:after {
      align-items: center;
      ${p.left ? leftArrow : rightArrow};
      position: absolute;
      top: 40%;
      transform: translate(-50%, -50%);
    }
  `

const text = p =>
  p.text &&
  css`
    color: #4a606a;
    background-color: transparent;
  `

const slider = p =>
  p.slider &&
  css`
    height: 38px;
    width: 10px;

    background: ${p.theme.sliderColor};

    &:disabled {
      cursor: default;
      background: #ffffff;
      border: 1px solid #dadfe1;
    }

    &:active:not(:disabled) {
      background: ${p.theme.activeSliderColor};
    }

    &:hover:not(:disabled):not(:active) {
      background: ${p.theme.hoverSliderColor};
    }
  `

const StyledButton = styled.button`
  cursor: pointer;
  user-select: none;
  border: none;
  outline: none;
  margin: 10px 0;
  border-radius: 4px;
  font-size: ${p => fontSizes[p.size]};
  transition: 0.25s;
  min-width: 85px;
  max-width: 100%;
  font-weight: ${p => (p.size === 'extraLarge' ? 600 : 'normal')};

  width: ${p => (p.width ? p.width : '200px')};
  ${primary};
  ${secondary};
  ${select};
  ${round};
  ${text};
  ${slider};
`

class Button extends Component {
  static defaultProps = {
    size: 'medium',
  }

  static propTypes = {
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    select: PropTypes.bool,
    round: PropTypes.bool,
    text: PropTypes.bool,
    slider: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    width: PropTypes.string,
  }

  render() {
    return <StyledButton {...this.props}>{this.props.label}</StyledButton>
  }
}

const PrimaryButton = props => <Button primary {...props} />

const SecondaryButton = props => <Button secondary {...props} />

const RoundButton = props => <Button round {...props} />

const TextButton = props => <Button text {...props} />

const SliderButton = props => <Button slider {...props} />

export { Button as default, PrimaryButton, SecondaryButton, RoundButton, TextButton, SliderButton }
