import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { primary, primaryContrast, accent, white, grey900, black },
} = colors
const floatingMiniHeight = reduceCSSCalc(`calc(4 * ${unit})`)

const button = {
  borderRadius: reduceCSSCalc(`calc(0.2 * ${unit})`),
  height: reduceCSSCalc(`calc(3.6 * ${unit})`),
  toggleFontSize: reduceCSSCalc(`calc(2 * ${unit})`),
  primaryColor: primary,
  primaryColorHover: color.convert(`color(${primary} a(20%))`),
  primaryColorContrast: primaryContrast,
  accentColorContrast: primaryContrast,
  accentColorHover: color.convert(`color(${accent} a(20%))`),
  accentColor: accent,
  neutralColor: white,
  neutralColorContrast: grey900,
  neutralColorHover: color.convert(`color(${grey900} a(20%))`),
  floatingFontSize: reduceCSSCalc(`calc(2.4 * ${unit})`),
  floatingHeight: reduceCSSCalc(`calc(5.6 * ${unit})`),
  floatingMiniHeight,
  floatingMiniFontSize: reduceCSSCalc(`calc(${floatingMiniHeight} / 2.25)`),
  disabledTextColor: color.convert(`color(${black} a(26%))`),
  disabledBackgroundColor: color.convert(`color(${black} a(12%))`),
  disabledTextColorInverse: color.convert(`color(${black} a(54%))`),
  disabledBackgroundInverse: color.convert(`color(${black} a(8%))`),
  squaredIconMargin: reduceCSSCalc(`calc(0.6 * ${unit})`),
  squaredMinWidth: reduceCSSCalc(`calc(9 * ${unit})`),
  squaredPadding: `0 ${reduceCSSCalc(`calc(1.2 * ${unit})`)}`,
}

export default button
