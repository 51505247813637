import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { Slider } from '../../../components'

const PlusIcon = props => (
  <svg data-name='Layer 1' viewBox='0 0 24 24' {...props}>
    <title>add-normal-c</title>
    <path
      data-name='icn/add-normal'
      d='M21.6 12a9.6 9.6 0 0 0-19.2 0M0 12a12 12 0 0 1 24 0M2.4 12a9.6 9.6 0 0 0 19.2 0m2.4 0a12 12 0 0 1-24 0m11-1H6v2h5v5h2v-5h5v-2h-5V6h-2v5z'
    />
  </svg>
)

const MinusIcon = props => (
  <svg viewBox='0 0 24 24' {...props}>
    <title>subtract-normal-c</title>
    <g>
      <path d='M21.6 12c0-5.3-4.3-9.6-9.6-9.6S2.4 6.7 2.4 12M0 12C0 5.4 5.4 0 12 0s12 5.4 12 12M2.4 12c0 5.3 4.3 9.6 9.6 9.6s9.6-4.3 9.6-9.6m2.4 0c0 6.6-5.4 12-12 12S0 18.6 0 12' />
      <path d='M6 13h12v-2H6z' />
    </g>
  </svg>
)

const IconContainer = styled.span`
  display: inline-block;
  width: 40px;
  height: 24px;
  padding: 0 8px;
`

const SubLabel = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 0.75rem;
  padding: 4px 0 4px 15px;
  line-height: 1.4;
`

class RiskCompare extends Component {
  state = { active: false }

  toggleActive = () => this.setState({ active: !this.state.active })

  render() {
    const {
      baseCase,
      institutionalAccount,
      otherInvestmentAccounts,
      features,
      config,
      person: { displayName },
    } = this.props.store
    const { values } = this.props

    if (!institutionalAccount?.balance && otherInvestmentAccounts?.length === 0) {
      return null
    }

    return (
      <div>
        <div
          css={css`
            display: flex;
            align-items: center;
            cursor: pointer;
            & svg {
              fill: #7a8e96;
              transition: color 0.3s, fill 0.3s;
            }
            &:hover {
              & svg {
                fill: #053240;
              }
            }
            &:active {
              & svg {
                fill: #0495bf;
              }
            }
          `}
          onClick={this.toggleActive}>
          <IconContainer>{this.state.active ? <MinusIcon /> : <PlusIcon />}</IconContainer>
          <span
            css={css`
              font-size: 14px;
              color: #677b84;
            `}>
            Compare with{' '}
            <span
              css={css`
                font-weight: bold;
              `}>
              current risk
            </span>
          </span>
        </div>
        {this.state.active && (
          <FieldContainer>
            <InputBox />
            <SliderBox>
              <SliderTextContainer
                css={
                  !institutionalAccount?.balance &&
                  css`
                    display: none;
                  `
                }>
                <CurrentEmployerPlanLevel risk={baseCase.riskLevel} />
                <CurrentEmployerPlanLevelText risk={baseCase.riskLevel}>
                  {displayName}'s investment plan account with {config.template.sponsorName}
                  {features.outsideAdviceEnabled ? '*' : ''}
                </CurrentEmployerPlanLevelText>
              </SliderTextContainer>
              <Slider
                min={101}
                max={600}
                value={0}
                onChange={value => {}}
                step={1}
                minRisk={values.recomMinRiskLevel}
                maxRisk={values.recomMaxRiskLevel}
              />
              <SliderTextContainer>
                <AllInvestmentsLevel risk={baseCase.riskLevelAll} />
                <AllInvestmentsLevelText risk={baseCase.riskLevelAll}>
                  All investment accounts
                </AllInvestmentsLevelText>
              </SliderTextContainer>
              {features.outsideAdviceEnabled && Boolean(institutionalAccount?.balance) && (
                <SubLabel>*And any other accounts included for investment advice</SubLabel>
              )}
            </SliderBox>
          </FieldContainer>
        )}
      </div>
    )
  }
}

const FieldContainer = styled.div`
  display: flex;
  padding: 12px 0 0;
  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`

const InputBox = styled.div`
  flex: 0 1 300px;
  visibility: hidden;
  @media (max-width: 720px) {
    flex: 0 1 100%;
    display: none;
  }
`

const SliderBox = styled.div`
  position: relative;
  flex: 1 1 400px;
  padding: 0 8px;
  @media (max-width: 720px) {
    flex: 0 1 100%;
  }
  & > div:nth-of-type(2) {
    & > div:first-of-type {
      & > div:first-of-type {
        display: none;
      }
    }
  }
`

const SliderTextContainer = styled.div`
  margin: 0 15px;
  position: relative;
  height: 48px;
`

const CurrentEmployerPlanLevel = styled.div`
  background-color: #7a8e96;
  border: 2px solid #7a8e96;
  width: 4px;
  height: 20px;
  position: absolute;
  top: 44px;
  z-index: 1;
  left: calc(${p => (p.risk ? (p.risk - 101) / 5 : 0)}% - 2px);
`

const CurrentEmployerPlanLevelText = styled.div`
  position: absolute;
  white-space: nowrap;
  font-size: 14px;
  color: #7a8e96;
  top: 20px;
  left: ${p => (p.risk ? (p.risk - 101) / 5 : 0)}%;
  transform: ${p =>
    ((p.risk ? (p.risk - 101) / 5 : 0) < 10 && 'translateX(0%)') ||
    ((p.risk ? (p.risk - 101) / 5 : 0) > 90 && 'translateX(-100%)') ||
    'translateX(-50%)'};
`

const AllInvestmentsLevel = styled.div`
  background-color: #7a8e96;
  position: absolute;
  border: 2px solid #7a8e96;
  width: 4px;
  height: 20px;
  top: -16px;
  z-index: 1;
  left: calc(${p => (p.risk ? (p.risk - 101) / 5 : 0)}% - 2px);
`

const AllInvestmentsLevelText = styled.div`
  position: absolute;
  white-space: nowrap;
  font-size: 14px;
  color: #7a8e96;
  top: 10px;
  left: ${p => (p.risk ? (p.risk - 101) / 5 : 0)}%;
  transform: ${p =>
    ((p.risk ? (p.risk - 101) / 5 : 0) < 10 && 'translateX(0%)') ||
    ((p.risk ? (p.risk - 101) / 5 : 0) > 90 && 'translateX(-100%)') ||
    'translateX(-50%)'};
`

export default inject('store')(observer(RiskCompare))
