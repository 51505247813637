import { useState, useEffect, useCallback, useRef } from 'react'
import { numberToDollars } from '../../utils/utils'

const INITIAL_OFFSET = 25
const circleConfig = {
  viewBox: '0 0 38 38',
  x: '19',
  y: '19',
  radio: '15.91549430918954',
}

const CircleProgressBarBase = ({
  className,
  strokeColor,
  strokeWidth,
  innerText,
  legendText,
  percentage,
  trailStrokeWidth,
  trailStrokeColor,
  trailSpaced,
  speed,
  amount,
}) => {
  const [progressBar, setProgressBar] = useState(0)
  const pace = percentage / speed
  const timeout = useRef(null)
  const updatePercentage = useCallback(() => {
    timeout.current = setTimeout(() => {
      setProgressBar(progressBar => progressBar + 1)
    }, pace)
  }, [setProgressBar, pace])

  useEffect(() => {
    if (percentage > 0) updatePercentage()
    return () => clearTimeout(timeout)
  }, [percentage, updatePercentage])

  useEffect(() => {
    if (progressBar < percentage) updatePercentage()
    return () => clearTimeout(timeout)
  }, [progressBar, percentage, updatePercentage])

  return (
    <figure className={className}>
      <svg viewBox={circleConfig.viewBox}>
        <circle
          className='donut-ring'
          cx={circleConfig.x}
          cy={circleConfig.y}
          r={circleConfig.radio}
          fill='transparent'
          stroke={trailStrokeColor}
          strokeWidth={trailStrokeWidth}
          strokeDasharray={trailSpaced ? 1 : 0}
        />

        <circle
          className='donut-segment'
          cx={circleConfig.x}
          cy={circleConfig.y}
          r={circleConfig.radio}
          fill='transparent'
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray={`${progressBar} ${100 - progressBar}`}
          strokeDashoffset={INITIAL_OFFSET}
        />

        <g className='chart-text'>
          <text x='50%' y='50%' className='chart-number'>
            ${numberToDollars(amount, true)}
          </text>
          <text x='50%' y='50%' className='chart-label'>
            {innerText}
          </text>
        </g>
      </svg>
      {legendText && (
        <figcaption className='figure-key'>
          <ul className='figure-key-list' aria-hidden='true' role='presentation'>
            <li>
              <span className='shape-circle' />
              <span>{legendText}</span>
            </li>
          </ul>
        </figcaption>
      )}
    </figure>
  )
}

CircleProgressBarBase.defaultProps = {
  strokeColor: 'blueviolet',
  strokeWidth: 5,
  innerText: 'monthly',
  legendText: '',
  percentage: 0,
  trailStrokeWidth: 5,
  trailStrokeColor: '#d2d3d4',
  trailSpaced: false,
  speed: 1,
}

export default CircleProgressBarBase
