import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import PlaidLink from './components/PlaidLink'

import { ButtonsBox, HelpIcon, Spacer, Text, TooltipText, WizardStep } from '../../../components'
import { accountTypeKeyToPlanTypeId } from '../../../constants'
import { useEffect } from 'react'

const ButtonBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  max-width: 340px;
  height: 76px;
  border-radius: 4px;
  background: ${p => p.theme.buttonColor};
  cursor: pointer;
  padding: 8px;
  margin-left: 12px;
  text-align: center;
  &:hover {
    background: ${p => p.theme.hoverButtonColor};
  }
  &:active {
    background: ${p => p.theme.activeButtonColor};
  }
  @media only screen and (max-width: 800px) {
    margin: 24px 0 0 0;
  }
`
const SecondaryButtonBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  max-width: 340px;
  height: 76px;
  border-radius: 4px;
  background: #7a8e96;
  cursor: pointer;
  padding: 8px;
  margin-right: 12px;
  &:hover {
    background: #65767e;
  }
  &:active {
    background: #65767e;
  }
  @media only screen and (max-width: 800px) {
    margin: 24px 0 0 0;
  }
`
const ButtonTopText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 1.375rem;
  user-select: none;
`
const ButtonBottomText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 0.875rem;
  user-select: none;
`

const employerRelated = {
  '401a': true,
  '401k': true,
  '403b': true,
  '457': true,
  'keogh': true,
  'ira-rollover': false,
  'ira-roth': false,
  'ira-deductible': false,
  'ira-non-deductible': false,
  'brokerage-account': false,
  'other-asset': false,
  'annuity-fixed': false,
  'payroll-deduct-ira': false,
  'sep-ira': false,
  'simple-ira': false,
}

const informationEntryContent = ({ config }) => {
  switch (config.templateId) {
    // Florida template
    case 1:
    case 2:
      return `Follow the steps to enter information from the most recent account statement. If you need help, contact the ${config.template.supportGroup} via the ${config.template.supportName} at ${config.template.supportLine} or on ${config.template.supportLinkOverDomain}`

    // Institutional client template
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
      return `Follow the steps to enter information from the most recent account statement. If you need help, contact ${config.template.supportName} at ${config.template.supportLine} or ${config.template.brandSupportEmail}`

    // Schwab template
    case 9:
    case 10:
      return `Follow the steps to enter information from the most recent account statement. If you need help, contact the ${config.template.supportName} at ${config.template.supportLine}.`
    default:
      return `Follow the steps to enter information from the most recent account statement. If you need help, contact the ${config.template.supportName} at ${config.template.supportLine}.`
  }
}

const AddInvestments = props => {
  const {
    params: { type, accountType, id },
  } = props.match
  const {
    features,
    getFeatures,
    config,
    config: { setRetirementAccountReviewStatus },
    institutionalAccountUnderReview,
    institutionalAccount,
  } = props.store
  const isInstitutionalAccount = props.account?.account?.id === institutionalAccount?.id

  useEffect(() => {
    const fetchFeatures = async () => {
      if (isInstitutionalAccount && institutionalAccountUnderReview) {
        await setRetirementAccountReviewStatus()
      }
      await getFeatures()
    }

    return () => fetchFeatures()
  }, [
    getFeatures,
    institutionalAccountUnderReview,
    isInstitutionalAccount,
    setRetirementAccountReviewStatus,
  ])

  const handleBack = () => {
    props.history.goBack()
  }

  const handleNext = () => {
    const {
      params: { type, accountType, id },
    } = props.match
    props.history.push(`/accounts/${type}/${accountType}/${id}`)
  }

  const handleAddInvestments = () => {
    const {
      params: { type, accountType, id },
    } = props.match
    props.history.push(`/accounts/add/${type}/${accountType}/${id}/investments`)
  }

  const handlePlaidLinkSuccess = () => {
    props.store.getExistingPlaidLinks()
    props.store.getAccounts()
    handleAddInvestments()
  }

  return (
    <WizardStep
      onBackClick={handleBack}
      onNextClick={handleNext}
      showDefaultButtons={false}
      title="Let's talk investments">
      <Text>We need information about how this account is invested.</Text>

      <Spacer space='12px' />

      <Text
        css={css`
          margin-bottom: 24px;
        `}>
        {informationEntryContent({ config })}
      </Text>

      {employerRelated[accountType] && (
        <Text>
          Important: We can provide more personalized investment advice if you include all the
          investment options available — even those without a balance. These investment options must
          be entered manually. <br />
          <HelpIcon tooltip={TooltipText.otherInvestmentOptions()} />
        </Text>
      )}

      <Spacer space='24px' />
      <ButtonsBox justifyContent='space-evenly'>
        <SecondaryButtonBox onClick={handleNext}>
          <ButtonTopText>Skip investments</ButtonTopText>
          <ButtonBottomText>I'll come back or have input</ButtonBottomText>
          <ButtonBottomText>done for me</ButtonBottomText>
        </SecondaryButtonBox>
        <ButtonBox onClick={handleAddInvestments}>
          <ButtonTopText>Step me through</ButtonTopText>
          <ButtonBottomText>manually adding investments</ButtonBottomText>
        </ButtonBox>

        {((features.enablePlaidLink && type === 'non-gc') ||
          (features.enablePlaidLink &&
            type === 'Institutional' &&
            features.editInstitutionalAccount)) && (
          <PlaidLink
            onSuccess={handlePlaidLinkSuccess}
            accountId={id}
            bigButton
            planType={accountTypeKeyToPlanTypeId[accountType]}
          />
        )}
      </ButtonsBox>
    </WizardStep>
  )
}

export default inject('store', 'account')(observer(AddInvestments))
