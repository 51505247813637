/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'
import { NoGuideStyle } from './style'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { BackButton, NextButton } from '../../../components/styled'
import { Spacer } from '../../../components'

function NoGuide(props) {
  const { history } = props

  return (
    <NoGuideStyle.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
      `}>
      <div>
        <Heading>Congratulations! You've got a solid plan in the works</Heading>

        <NoGuideStyle.Container>
          <div
            css={css`
              padding: 0 20px;
            `}>
            <div>
              <p
                css={css`
                  line-height: 21px;
                `}>
                Retiring is not that far away, but until then,{' '}
                <b>no transactions will be made as a result of spend-down modeling</b>; similarly,
                creating a guide that tells you how much to withdraw and from what investments isn't
                yet applicable. Feel free to return to the spend-down modeling mode anytime to
                update your plan with new information and, most importantly, in the months before
                retiring.
              </p>
              <br />
              <br />
              In the meantime, there are a few other options to keep in mind.
              <Spacer space='20px;' />
              <Heading
                css={css`
                  font-size: 1.8rem;
                  margin: 0;
                `}>
                Additional options to explore
              </Heading>
              <Ul>
                <li>
                  Consider{' '}
                  <CustomLink
                    to='insuring-final-expenses'
                    target='_blank'
                    rel='noreferrer noopener'>
                    insuring final expenses
                  </CustomLink>
                </li>
                <li>
                  Tap your home equity and opt for a{' '}
                  <CustomLink to='reverse-mortgage' target='_blank' rel='noreferrer noopener'>
                    reverse mortgage
                  </CustomLink>{' '}
                  if your home is paid off
                </li>
                <li>Consider a move to a less expensive area</li>
                <li>
                  Make certain your finances are in order—
                  <CustomLink to='handy-checklist' target='_blank' rel='noreferrer noopener'>
                    handy checklist
                  </CustomLink>
                </li>
              </Ul>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin: 30px 0;
            `}>
            <BackButton backgroundColor='#FFFFFF' onClick={() => history.goBack()}>
              Back
            </BackButton>
            <NextButton onClick={() => history.push('/home')}>Home</NextButton>
          </div>
        </NoGuideStyle.Container>
      </div>
    </NoGuideStyle.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(NoGuide))

const Heading = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2.3rem;
  width: 100%;
  color: #4a606a;
  -webkit-transition: 0.75s;
  transition: 0.75s;
  font-weight: 300;
  line-height: 1.25;
  word-break: break-word;
  max-width: 630px;
  margin-bottom: 40px;
`
const Ul = styled.ul`
  padding-left: 20px;
  & > li {
    margin: 10px 0;
  }
`
const CustomLink = styled(Link)`
  cursor: pointer;
  color: ${p => p.theme.customLinkColor};
  text-decoration: none;
`
