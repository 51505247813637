import variables from '../variables'
import colors from '../colors'
import reduceCSSCalc from 'reduce-css-calc'

const {
  unit,
  type: {
    sizes: { s: small },
  },
} = variables

const chip = {
  height: reduceCSSCalc(`calc(3.2 * ${unit})`),
  padding: reduceCSSCalc(`calc(1.2 * ${unit})`),
  marginRight: reduceCSSCalc(`calc(0.25 * ${unit})`),
  background: colors.rt.grey200,
  iconFontSize: reduceCSSCalc(`calc(2 * ${unit})`),
  iconMarginRight: reduceCSSCalc(`calc(0.8 * ${unit})`),
  color: colors.rt.textSecondary,
  fontSize: small,
  removeSize: reduceCSSCalc(`calc(2.4 * ${unit})`),
  removeMargin: reduceCSSCalc(`calc(0.4 * ${unit})`),
  removeStrokeWidth: reduceCSSCalc(`calc(0.4 * ${unit})`),
  removeBackground: colors.rt.grey400,
  removeBackgroundHover: colors.rt.grey500,
  removeColor: colors.rt.white,
}

export default chip
