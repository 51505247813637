import { css } from '@emotion/react'
import { Component, useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'

import { Page, PageHeader, PageFooter } from '../elements'
import { ContentMainHeader, ContentSubHeader, ContentText, FootnoteText } from '../elements/styled'
import { Spacer } from '../../../components'

const TextContainer = styled.div`
  padding: 12px 0;
  line-height: 1.5;
`

const PensionDisclaimers = props => {
  const {
    store: {
      config: { template },
    },
  } = props
  const [pensionDisclaimers, setPensionDisclaimers] = useState([])

  useEffect(() => {
    setPensionDisclaimers(
      template?.disclaimer?.filter(disclaimer => {
        const now = new Date()
        const lessThan = new Date(disclaimer.lessThan)
        const greaterThan = new Date(disclaimer.greaterThan)
        return disclaimer.module === 'PENSION' && now < lessThan && now > greaterThan
      })
    )
  }, [template.disclaimer])

  return (
    <>
      {pensionDisclaimers?.map(disclaimer => (
        <TextContainer>
          <ContentText dangerouslySetInnerHTML={{ __html: disclaimer.textToDisplay }} />
        </TextContainer>
      ))}
    </>
  )
}

const analysisParagraph = ({ config }) => {
  const { templateId } = config

  switch (templateId) {
    case 1:
    case 2:
      return `The analysis done by GuidedChoice is based on information provided by you about your
        personal and financial circumstances, as well as information related to your retirement
        plan account with The ${config.template.sponsorName}, as provided to GuidedChoice by your
        plan recordkeeper, ${config.template.recordKeeperName}. As such, the investments and
        associated balances of any account in the GuidedChoice digital service may not reflect
        today's value.`
    case 19:
      return `The analysis done by GuidedChoice is based on information provided by you about your
        personal and financial circumstances. As such, the investments and associated balances of 
        any account in the GuidedChoice Advisory Service may not reflect today's value.`
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    default:
      return `The analysis done by GuidedChoice is based on information provided by you about your
        personal and financial circumstances, as well as information related to your retirement
        plan account with ${config.template.sponsorName}, as provided to GuidedChoice by your
        plan recordkeeper, ${config.template.recordKeeperName}. As such, the investments and
        associated balances of any account in the GuidedChoice digital service may not reflect
        today's value.`
  }
}

const fullDisclosureRequest = ({ config, features }) => {
  const { templateId } = config

  switch (templateId) {
    case 1:
    case 2:
      return `You may request a copy of the full GuidedChoice Disclosure Document and Terms of Service by contacting ${config.template.supportGroup} via the ${config.template.supportName} at ${config.template.supportLine} or on ${config.template.supportLinkOverDomainDisplay} in the Privacy, Security & Terms of Use section. It is also available within the GuidedChoice Advisory Service digital application. Specific fund information for ${config.template.sponsorShortHand} accounts is available from the Investment Plan Administrator or on ${config.template.supportLinkOverDomainDisplay} in the Investment Funds section. Contact the financial institutions where other accounts are to obtain specific fund information for those accounts.`
    case 19:
      return `You may request a copy of the full GuidedChoice Disclosure Document for Independent Clients and Terms of Service by contacting GuidedChoice Customer Service at (800) 774-7459 or help@guidedchoice.com. Contact the financial institutions where other accounts are to obtain specific fund information for those accounts.`
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    default:
      return `You may request a copy of the full GuidedChoice Disclosure Document and Terms of Service by contacting GuidedChoice Customer Service at ${
        config.template.customerSupportNumber
      } or help@guidedchoice.com. Specific fund information for your retirement plan account with ${
        config.template.employerName
      } is available from the plan recordkeeper, ${config.template.recordKeeperName}. ${
        features.outsideAdviceEnabled
          ? 'Contact the financial institutions where other accounts are to obtain specific fund information for those accounts.'
          : ''
      }`
  }
}

class Disclosure extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'disclosure' })
  }

  render() {
    const { config, features } = this.props.store
    const {
      timeStamp,
      guidePages: {
        pages: { findByName },
      },
    } = this.props.guide

    const page = findByName('disclosure')
    const { isRetail } = config

    return (
      <Page
        header={<PageHeader />}
        footer={
          <PageFooter>
            <p
              css={css`
                line-height: 1.4;
              `}>
              Life is full of changes - and so are financial markets, tax laws, and Social Security
              rules. Be sure to visit the GuidedChoice Advisory Service every year, or more often if
              there's a major change, to receive an updated guide.
            </p>
          </PageFooter>
        }
        page={page}>
        <ContentMainHeader>Assumptions and Disclosures</ContentMainHeader>
        <ContentSubHeader>THE FINE PRINT</ContentSubHeader>

        <Spacer space='8px' />

        <TextContainer>
          <ContentText>{analysisParagraph({ config })}</ContentText>
        </TextContainer>

        <PensionDisclaimers {...this.props} />

        <TextContainer>
          <ContentText>
            Historical performance of a fund can only give you an idea of the risk level involved,
            it does not tell you how the fund will perform in the future. There is no guarantee of
            the performance of the Portfolio(s) or that the objective can be obtained.
          </ContentText>
        </TextContainer>

        <TextContainer>
          <ContentText>
            You may lose money by investing in any Portfolio. The likelihood of loss is greater if
            you invest for a shorter period of time. Your investments in any Portfolio is not a
            deposit or obligation of, or insured or guaranteed by, any entity or person, including
            the U.S. Government and the Federal Deposit Insurance Corporation (FDIC).
          </ContentText>
        </TextContainer>

        <TextContainer>
          <ContentText>{fullDisclosureRequest({ config, features })}</ContentText>
        </TextContainer>

        {isRetail && (
          <TextContainer>
            <ContentText>
              To learn more about GuidedChoice, please visit our web site at www.guidedchoice.com
            </ContentText>
          </TextContainer>
        )}
        <Spacer space='20px' />

        <FootnoteText>{`Created: ${timeStamp}`}</FootnoteText>
      </Page>
    )
  }
}

export default inject('store', 'guide')(observer(Disclosure))
