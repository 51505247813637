const GCLogoDark = `
<svg version="1.1" id="Layer_1" 
xmlns="http://www.w3.org/2000/svg" 
xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 454.5 139.5" style="enable-background:new 0 0 454.5 139.5;" xml:space="preserve">
<style type="text/css">.st0_logo_dark_bg{fill:#FFFFFF;}.st1_logo_dark_bg{fill:#008AB7;}
</style>
	<g>
		<path class="st0_logo_dark_bg" d="M130.8,80.1v-6.8H126c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.3-0.3-0.6v-2.4h9.5V82c-3.1,2.2-7,3.3-11.7,3.3		c-4.5,0-8.2-1.4-11-4.4s-4.3-6.8-4.3-11.4c0-4.8,1.4-8.5,4.2-11.4c2.9-2.9,6.7-4.4,11.6-4.4c3.4,0,7,0.8,10.9,2.2v7.2h-2.1		c-0.5,0-0.8-0.2-0.9-0.7c0-0.2-0.2-1.5-0.3-3.9c-0.7-0.2-1.2-0.4-1.6-0.5c-1.6-0.4-3.5-0.7-5.6-0.7s-3.9,0.3-5.3,0.9		c-1.4,0.6-2.6,1.4-3.6,2.4c-2.1,2.2-3.1,5.2-3.1,8.9c0,3.9,1,6.9,3.2,9.2c2.1,2.2,4.8,3.3,8.2,3.3C126,81.9,128.5,81.3,130.8,80.1z		"/>
		<path class="st0_logo_dark_bg" d="M150.4,82.3c2.2,0,4.1-1,5.9-3v-13c-0.3-0.1-1.1-0.3-2.5-0.5c-0.4-0.1-0.6-0.4-0.6-0.8v-1.9h3.1h3.8v18.7		c1.4,0.3,2.2,0.4,2.5,0.5c0.4,0.1,0.6,0.3,0.6,0.8V85h-5.4c-0.5,0-0.9-0.3-1-0.8l-0.3-2.3c-2.1,2.3-4.5,3.5-7.3,3.5		c-2.3,0-4.1-0.8-5.4-2.3c-1.2-1.5-1.9-3.4-1.9-6V66.3c-1.4-0.3-2.2-0.4-2.4-0.5c-0.4-0.1-0.7-0.4-0.7-0.8v-1.8h7V77		c0,1.6,0.3,3,1.1,3.9C147.8,81.8,148.9,82.2,150.4,82.3L150.4,82.3z"/>
		<path class="st0_logo_dark_bg" d="M178,83.1V85h-10.1v-1.9c0-0.4,0.3-0.7,0.6-0.8c0.4-0.1,2.5-0.5,2.5-0.5V66.2c0,0-2.1-0.4-2.5-0.5		s-0.6-0.4-0.6-0.8v-1.8h7v18.7c0,0,2.1,0.4,2.5,0.5C177.7,82.4,178,82.7,178,83.1z M170.5,57.3c-0.2-0.3-0.2-0.7-0.2-1		c0-0.3,0-0.7,0.2-1.1c0.2-0.3,0.3-0.6,0.6-0.9c0.3-0.3,0.5-0.4,0.9-0.6c0.3-0.2,0.7-0.2,1-0.2c0.3,0,0.7,0,1,0.2		c0.3,0.2,0.6,0.3,0.9,0.6s0.4,0.6,0.6,0.9c0.2,0.3,0.3,0.7,0.3,1.1c0,0.3-0.1,0.7-0.3,1c-0.2,0.3-0.3,0.6-0.6,0.9		c-0.3,0.3-0.5,0.4-0.9,0.6c-0.3,0.2-0.7,0.2-1,0.2c-0.3,0-0.7,0-1-0.2c-0.3-0.2-0.6-0.3-0.9-0.6C170.8,57.9,170.7,57.6,170.5,57.3z		"/>
		<path class="st0_logo_dark_bg" d="M191.6,62.7c2.4,0,4.4,0.9,6,2.6v-9c-1.5-0.3-2.5-0.4-2.9-0.5c-0.4-0.1-0.6-0.3-0.6-0.8v-1.9h7.3v28.7		c1.4,0.3,2.2,0.4,2.5,0.5c0.4,0.1,0.6,0.3,0.6,0.8V85h-5.4c-0.5,0-0.9-0.3-1-0.8l-0.3-2.6c-2.1,2.5-4.5,3.7-7.3,3.7		c-2.6,0-4.6-1-6-2.8c-1.5-1.9-2.2-4.7-2.2-8.3c0-3.3,0.9-6,2.5-8.1C186.5,63.8,188.8,62.7,191.6,62.7L191.6,62.7z M191.8,82.2		c2.2,0,4.2-1.1,5.9-3.3V68.3c-1.3-1.7-3-2.6-5.1-2.6c-2.1,0-3.7,0.7-4.8,2.2c-1.1,1.5-1.6,3.5-1.6,6.2c0,4.1,1,6.7,3.2,7.6		C190,82,190.9,82.1,191.8,82.2L191.8,82.2z"/>
		<path class="st0_logo_dark_bg" d="M218.8,62.8c1.3,0,2.5,0.2,3.6,0.6c1.1,0.4,2.1,1,2.9,1.9c0.8,0.8,1.5,1.9,1.9,3.1c0.4,1.2,0.7,2.6,0.7,4.1		c0,0.6,0,1-0.2,1.2s-0.4,0.3-0.8,0.3h-14.5c0,1.4,0.2,2.6,0.5,3.6s0.9,1.8,1.5,2.5c0.6,0.7,1.3,1.2,2.2,1.6		c0.9,0.3,1.7,0.5,2.8,0.5c0.9,0,1.8-0.1,2.5-0.3c0.7-0.2,1.3-0.5,1.8-0.7c0.5-0.3,1-0.5,1.3-0.7s0.6-0.3,0.9-0.3		c0.3,0,0.6,0.1,0.7,0.4l1.1,1.4c-0.5,0.6-1,1-1.7,1.5c-0.7,0.4-1.3,0.8-2.1,1.1c-0.8,0.3-1.6,0.5-2.3,0.6c-0.8,0.1-1.6,0.2-2.4,0.2		c-1.5,0-2.9-0.3-4.2-0.8c-1.3-0.5-2.4-1.3-3.3-2.3s-1.6-2.2-2.2-3.7c-0.5-1.5-0.8-3.1-0.8-5c0-1.6,0.3-2.9,0.7-4.2		c0.5-1.3,1.2-2.5,2-3.5c0.9-1,1.9-1.7,3.2-2.3C215.9,63,217.3,62.8,218.8,62.8L218.8,62.8z M218.9,65.6c-1.9,0-3.3,0.5-4.4,1.6		c-1,1.1-1.7,2.6-2,4.5h11.9c0-0.9-0.1-1.7-0.4-2.4c-0.3-0.7-0.6-1.4-1-1.9c-0.5-0.5-1.1-1-1.8-1.3		C220.6,65.7,219.8,65.6,218.9,65.6L218.9,65.6z"/>
		<path class="st0_logo_dark_bg" d="M242.6,62.7c2.4,0,4.4,0.9,6,2.6v-9c-1.5-0.3-2.5-0.4-2.9-0.5c-0.4-0.1-0.6-0.3-0.6-0.8v-1.9h7.3v28.7		c1.4,0.3,2.2,0.4,2.5,0.5c0.4,0.1,0.6,0.3,0.6,0.8V85h-5.4c-0.5,0-0.9-0.3-1-0.8l-0.3-2.6c-2.1,2.5-4.5,3.7-7.3,3.7		c-2.6,0-4.6-1-6-2.8c-1.5-1.9-2.2-4.7-2.2-8.3c0-3.3,0.9-6,2.5-8.1C237.5,63.8,239.8,62.7,242.6,62.7L242.6,62.7z M242.8,82.2		c2.2,0,4.2-1.1,5.9-3.3V68.3c-1.3-1.7-3-2.6-5.1-2.6c-2.1,0-3.7,0.7-4.8,2.2c-1.1,1.5-1.6,3.5-1.6,6.2c0,4.1,1,6.7,3.2,7.6		C241,82,241.9,82.1,242.8,82.2L242.8,82.2z"/>
		<path class="st0_logo_dark_bg" d="M285.6,80.9c-0.6,0.7-1.3,1.3-2.1,1.8c-0.7,0.5-1.6,1-2.4,1.4c-0.9,0.4-1.9,0.7-2.9,0.9		c-1.1,0.2-2.2,0.3-3.5,0.3c-2.1,0-4-0.4-5.8-1.1s-3.3-1.8-4.5-3.2c-1.3-1.4-2.2-3-2.9-4.9c-0.7-1.9-1.1-4.1-1.1-6.4		c0-2.3,0.3-4.4,1.1-6.3c0.7-1.9,1.7-3.5,3.1-4.9c1.3-1.4,2.9-2.4,4.8-3.2c1.9-0.8,3.8-1.1,6-1.1c2.8,0,6.1,0.7,9.8,2.2v6.9h-1		c-0.6,0-0.9-0.3-0.9-1c0-0.3-0.1-1.9-0.3-4.7c-2.2-1-4.8-1.4-7.6-1.4c-1.9,0-3.5,0.3-5.1,1c-1.6,0.6-2.9,1.6-4,2.7		c-1.1,1.2-2,2.6-2.6,4.3c-0.6,1.7-0.9,3.6-0.9,5.7c0,2.2,0.3,4.1,0.9,5.8c0.6,1.7,1.5,3.2,2.6,4.3c1.1,1.2,2.3,2.1,3.8,2.7		c1.5,0.6,3.2,1,4.9,1c1.1,0,2-0.1,2.9-0.2c0.9-0.1,1.6-0.3,2.4-0.6c0.7-0.3,1.4-0.6,2-1c0.6-0.4,1.3-0.9,1.9-1.5		c0.1,0,0.2-0.1,0.2-0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.3,0.2L285.6,80.9z"/>
		<path class="st0_logo_dark_bg" d="M314.7,84.1V85h-3.1h-2V71.2c0-2-0.5-3.6-1.4-4.8c-0.9-1.1-2.3-1.7-4.2-1.7c-1.4,0-2.7,0.3-3.9,1.1		c-1.2,0.7-2.3,1.7-3.2,3v14.4c0.4,0,0.7,0,1,0c0.3,0,0.5,0,0.6,0h0.3h0.2c0.6,0,0.9,0.3,0.9,0.8V85h-3.1h-2.1h-3.1v-0.9		c0-0.5,0.3-0.7,0.9-0.8h0.3h0.7c0.3,0,0.8-0.1,1.3-0.1v-28c-0.4,0-0.7,0-1,0H293c-0.1,0-0.3,0-0.3,0h-0.2c-0.6,0-0.9-0.3-0.9-0.8		v-0.8h3.1h2.1v13.6c1-1.2,2.1-2.2,3.4-2.9c1.3-0.7,2.7-1.1,4.3-1.1c1.2,0,2.2,0.2,3.1,0.6c0.9,0.4,1.6,0.9,2.2,1.6s1,1.6,1.3,2.5		c0.3,1,0.4,2.1,0.4,3.4v12c0.4,0,0.8,0,1.1,0c0.3,0,0.5,0,0.6,0h0.3h0.3C314.5,83.4,314.7,83.6,314.7,84.1z"/>
		<path class="st0_logo_dark_bg" d="M334.2,63.9c1.2,0.5,2.2,1.3,3.1,2.2c0.8,1,1.5,2.1,1.9,3.5c0.4,1.4,0.6,2.9,0.6,4.6s-0.2,3.2-0.6,4.5		c-0.4,1.3-1.1,2.5-1.9,3.5c-0.8,1-1.9,1.7-3.1,2.2c-1.2,0.5-2.5,0.8-4.1,0.8s-2.9-0.3-4.1-0.8c-1.2-0.5-2.2-1.3-3.1-2.2		c-0.9-1-1.5-2.2-1.9-3.5c-0.4-1.3-0.7-2.8-0.7-4.5s0.3-3.2,0.7-4.6s1-2.5,1.9-3.5c0.9-0.9,1.9-1.7,3.1-2.2s2.6-0.8,4.1-0.8		S333,63.4,334.2,63.9z M333.4,83c1-0.4,1.8-1.1,2.4-1.9c0.6-0.8,1.1-1.8,1.4-3c0.3-1.2,0.5-2.5,0.5-3.9c0-1.4-0.2-2.7-0.5-3.9		c-0.3-1.2-0.8-2.2-1.4-3c-0.6-0.8-1.5-1.5-2.4-1.9c-1-0.4-2-0.6-3.3-0.6c-1.3,0-2.4,0.2-3.4,0.6c-1,0.4-1.8,1.1-2.4,1.9		c-0.6,0.8-1.1,1.8-1.4,3c-0.3,1.2-0.5,2.5-0.5,3.9c0,1.4,0.1,2.7,0.5,3.9c0.3,1.2,0.8,2.2,1.4,3s1.5,1.5,2.4,1.9s2.1,0.6,3.4,0.6		C331.4,83.6,332.5,83.4,333.4,83z"/>
		<path class="st0_logo_dark_bg" d="M354.1,84.1V85H351h-2.1h-3.1v-0.9c0-0.5,0.3-0.7,0.9-0.8h0.3h0.7c0.3,0,0.8-0.1,1.3-0.1v-18c-0.4,0-0.7,0-1,0		h-0.6c-0.1,0-0.3,0-0.3,0h-0.2c-0.6,0-0.9-0.3-0.9-0.8v-0.9h3.1h2.1v19.8c0.4,0,0.7,0,1,0c0.3,0,0.5,0,0.6,0h0.3h0.2		C353.8,83.4,354.1,83.6,354.1,84.1z M348.2,56.8c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.4,0.4-0.6		s0.4-0.3,0.6-0.4s0.5-0.1,0.7-0.1s0.5,0,0.7,0.1s0.4,0.3,0.6,0.4s0.3,0.4,0.4,0.6c0.1,0.3,0.2,0.5,0.2,0.7c0,0.3-0.1,0.5-0.2,0.7		c-0.1,0.2-0.3,0.4-0.4,0.6s-0.4,0.3-0.6,0.4s-0.5,0.2-0.7,0.2s-0.5-0.1-0.7-0.2s-0.4-0.2-0.6-0.4S348.3,57,348.2,56.8z"/>
		<path class="st0_logo_dark_bg" d="M376.2,82.2c-0.3,0.4-0.7,0.8-1.3,1.2s-1,0.7-1.7,1c-0.6,0.3-1.3,0.4-2.1,0.6s-1.5,0.3-2.3,0.3		c-1.4,0-2.6-0.3-3.7-0.8c-1.1-0.5-2.1-1.2-2.9-2.2c-0.8-1-1.5-2.1-1.9-3.5c-0.5-1.4-0.7-2.9-0.7-4.7c0-1.6,0.3-3.2,0.7-4.5		c0.4-1.3,1-2.5,1.9-3.5s1.9-1.7,3.1-2.3s2.5-0.8,4.1-0.8c0.5,0,1.3,0.1,2.3,0.3s1.9,0.5,2.6,0.7l1.1,0.3v5.4h-1c-0.6,0-0.9-0.3-1-1		c0,0,0-0.2,0-0.5c0-0.3-0.1-0.7-0.1-1.2c0-0.5,0-1.1-0.1-1.6c-1.4-0.5-2.6-0.7-3.7-0.7c-1.3,0-2.3,0.2-3.3,0.6		c-1,0.4-1.8,1.1-2.4,1.9c-0.6,0.8-1.2,1.8-1.5,3c-0.3,1.2-0.5,2.5-0.5,3.9c0,1.5,0.2,2.9,0.5,4c0.3,1.2,0.9,2.2,1.5,2.9		c0.6,0.8,1.4,1.4,2.3,1.8c0.9,0.4,1.9,0.6,3.1,0.6c1,0,1.9-0.1,2.6-0.4c0.7-0.3,1.3-0.5,1.7-0.8c0.4-0.3,0.8-0.6,1.1-0.8		c0.3-0.3,0.4-0.3,0.6-0.3s0.3,0.1,0.4,0.2L376.2,82.2z"/>
		<path class="st0_logo_dark_bg" d="M394.7,63.8c1,0.4,1.9,1,2.7,1.8c0.8,0.8,1.3,1.8,1.8,3s0.6,2.5,0.6,4c0,0.3-0.1,0.5-0.2,0.6		c-0.1,0.1-0.2,0.2-0.4,0.2h-15.5v0.4c0,1.6,0.2,3.1,0.6,4.3s0.9,2.2,1.6,3.1c0.7,0.8,1.5,1.4,2.5,1.9s2.1,0.6,3.3,0.6		c1.1,0,2-0.1,2.8-0.4c0.8-0.3,1.5-0.5,2-0.8c0.6-0.3,1-0.6,1.3-0.8c0.3-0.3,0.6-0.3,0.7-0.3c0.2,0,0.3,0.1,0.4,0.2l0.6,0.7		c-0.3,0.4-0.8,0.8-1.4,1.2c-0.6,0.4-1.2,0.7-1.9,1c-0.7,0.3-1.5,0.5-2.2,0.6c-0.8,0.2-1.6,0.2-2.4,0.2c-1.5,0-2.8-0.2-4-0.7		c-1.2-0.5-2.2-1.3-3.1-2.2s-1.5-2.2-2-3.6s-0.7-3.1-0.7-4.9c0-1.6,0.3-3,0.7-4.3s1.1-2.4,1.9-3.4c0.8-0.9,1.8-1.7,3-2.2		s2.6-0.8,4.1-0.8C392.5,63.1,393.6,63.3,394.7,63.8z M388.4,65.2c-0.9,0.3-1.6,0.8-2.3,1.5c-0.6,0.6-1.2,1.4-1.6,2.3		s-0.6,1.9-0.8,3.1h14.2c0-1.2-0.2-2.2-0.5-3.1c-0.3-0.9-0.8-1.6-1.3-2.3c-0.6-0.6-1.3-1.1-2.1-1.5c-0.8-0.3-1.7-0.5-2.7-0.5		C390.2,64.7,389.2,64.8,388.4,65.2z"/>
	</g>
	<g>
		<path class="st0_logo_dark_bg" d="M41,71.1c-1.6-5.5,0.6-13.5,5.8-21.7l-3.1-3.1c-5.7,9.1-7.8,18-5.1,23.4C39.4,70.3,40.2,70.8,41,71.1z"/>
		<path class="st0_logo_dark_bg" d="M45,72.3c0.3,0,0.7,0.1,1,0.1c0.1-8.2,5.9-19.6,15.9-29.6l-3.1-3.1c-11,11.1-17,23.8-15.7,32.1		C43.7,72.1,44.3,72.2,45,72.3z"/>
		<path class="st0_logo_dark_bg" d="M48.6,72.5c1.2,0,2.5-0.2,3.7-0.5c1.5-5.6,4.9-11.9,9.9-18L59.1,51C53.1,58.5,49.4,66.2,48.6,72.5		C48.5,72.5,48.5,72.5,48.6,72.5z"/>
		<path class="st1_logo_dark_bg" d="M74.3,63.5l5.2,5.2c-9.9,8.6-20.6,13.1-27.9,12.1c-0.9-0.1-1.7-0.3-2.5-0.6c-0.5-0.2-1-0.4-1.5-0.7l3,3		c0.5,0.3,1,0.5,1.5,0.7c7.2,2.6,19.2-1.9,30.3-11.5l5.3,5.3V63.5H74.3z"/>
		<path class="st1_logo_dark_bg" d="M71.1,65.7c-6.7,5.1-13.5,8.4-19.2,9.4c-1.2,0.2-2.3,0.3-3.4,0.3c-0.8,0-1.5,0-2.2-0.1		c-0.9-0.1-1.7-0.3-2.5-0.6c-0.5-0.2-1-0.4-1.5-0.7l3,3c0.5,0.3,1,0.5,1.5,0.7c0.6,0.2,1.2,0.4,1.9,0.5c0.9,0.2,1.8,0.2,2.8,0.2		c6.3-0.1,14.6-3.6,22.6-9.8L71.1,65.7z"/>
		<path class="st1_logo_dark_bg" d="M56.2,88.1c6.3,3.6,17.8,0.1,28.8-8.4L82,76.6c-11,8.5-22.5,12-28.8,8.4L56.2,88.1z"/>
	</g>
	<path class="st0_logo_dark_bg" d="M403.2,56.1c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.3,0.9-0.3c0.3,0,0.6,0.1,0.9,0.2	c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.3,0.6,0.3,1c0,0.3-0.1,0.7-0.3,0.9c-0.2,0.3-0.4,0.5-0.7,0.7c-0.3,0.2-0.6,0.3-0.9,0.3	c-0.3,0-0.7-0.1-1-0.3c-0.3-0.2-0.5-0.4-0.7-0.7C403.3,56.7,403.2,56.4,403.2,56.1z M403.6,56.1c0,0.3,0.1,0.5,0.2,0.7	c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.7	c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5	C403.7,55.6,403.6,55.8,403.6,56.1z M405.8,55.8c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2l0.3,0.6h-0.5l-0.3-0.6H405v0.6h-0.5	v-1.6h0.5C405.6,55.3,405.8,55.5,405.8,55.8z M405,56L405,56c0.1,0,0.1,0,0.2,0c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.1	c0,0-0.1,0-0.2,0h0V56z"/>
</svg>`

export default GCLogoDark
