import { Component } from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import events from '../utils'
import ReactDOM from 'react-dom'
import TextErrorField from './TextErrorField'
import _ from 'lodash'

const InputWrapper = styled.div`
  margin-top: 8px;
  min-height: 48px;
  padding: 10px 0;
`

const DropdownBox = styled.div`
  margin: 0px 5px;
  margin-left: ${p => p.leftMost && '0px'};
  position: relative;
  cursor: pointer;
  width: ${p => p.width || '200px'};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: ${p => p.fontSize || '1.125rem'};
  line-height: 1.5rem;
`

const ValueBox = styled.div`
  position: relative;
  background-color: transparent;
  transition: border-bottom 0.3s;
  border-bottom: ${p => (p.active ? '1.5px solid #008ab6' : '1.5px solid #b6c0c4')};

  &:hover {
    border-bottom: ${p => !p.active && '1.5px solid #053240'};
  }
  &::after {
    position: absolute;
    top: 5px;
    right: 8px;
    pointer-events: none;
    font-size: 30px;
    content: '\u2039';
    transform: rotate(-90deg);
    transition: color 0.3s;
    color: ${p => (p.active ? '#008ab6' : '')};
  }
`

const Value = styled.div`
  width: 100%;
  padding-right: 24px;
  padding-bottom: 1px;
  text-align: left;
  display: inline-block;
  justify-content: center;
  word-wrap: ${p => (p.nowrap ? 'normal' : 'break-word')};
  color: ${p => (p.selected ? p.theme.dropdownTextColor : '#b6c0c4')};
`

const OptionsBox = styled.ul`
  position: absolute;
  z-index: 100;
  width: calc(100% + 14px);
  overflow-y: auto;
  list-style: none;
  border-radius: 2px;
  padding: 0;
  margin: 0;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  top: 43px;
  max-height: ${p => p.size || 0}px;
  bottom: auto;
  background-color: rgba(240, 243, 245, 1);
  min-height: 160px;
  & li {
    padding: 7px 0 7px 18px;
  }
  & li:first-of-type {
    padding-top: 14px;
  }
  & li:last-child {
    padding-bottom: 14px;
  }
`

const Option = styled.li`
  font-size: 16px;
  line-height: 20px;
  position: relative;
  overflow: hidden;
  color: ${p => (p.selected ? '#038ab7' : 'rgba(119, 119, 119, 0.8)')};
  word-wrap: ${p => (p.nowrap ? 'normal' : 'break-word')};
  display: ${p => p.disabled && 'none'};
  width: 100%;
  &:hover {
    background-color: rgba(79, 173, 204, 0.2);
  }
`

class Dropdown extends Component {
  static defaultProps = {
    leftMost: true,
    options: [],
  }

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    forceActive: PropTypes.bool,
    fontSize: PropTypes.string,
    leftMost: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    options: PropTypes.array,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    showError: PropTypes.bool,
    switchForceActive: PropTypes.func,
    width: PropTypes.string,
    id: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      active: false,
      up: true,
      size: 0,
    }
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.forceActive && !this.state.active) {
      this.open()
    }
    if (nextProps.forceActive === false && this.state.active) {
      this.close()
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!this.state.active && nextState.active) {
      events.addEventsToDocument(this.getDocumentEvents())
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.active && !this.state.active) {
      events.removeEventsFromDocument(this.getDocumentEvents())
    }
  }

  componentWillUnmount() {
    if (this.state.active) {
      events.removeEventsFromDocument(this.getDocumentEvents())
    }
  }

  getDocumentEvents = () => ({
    click: this.handleDocumentClick,
    touched: this.handleDocumentClick,
  })

  getSelectedItem() {
    for (const item of this.props.source) {
      if (item[this.props.valueKey] === this.props.value) return item
    }
    return !this.props.allowBlank ? this.props.source[0] : undefined
  }

  handleSelect(item, event) {
    const { name, onChange, disabled } = this.props
    if (!disabled && onChange) {
      onChange(name, item)
      // render onBlur event (GoalYearControl's React-Final-Form Field needed onBlur event)
      if (this.props.onBlur) this.props.onBlur(event)
      this.close()
    }
  }

  handleClick(event) {
    if (this.props.disabled) return
    this.state.active ? this.close() : this.open(event)
    events.pauseEvent(event)
    if (this.props.onClick) this.props.onClick(event)
  }

  handleDocumentClick = event => {
    if (this.state.active && !events.targetIsDescendant(event, ReactDOM.findDOMNode(this))) {
      this.close()
    }
  }

  close() {
    const { onBlur, name, switchForceActive } = this.props
    if (switchForceActive) switchForceActive(false)
    if (this.state.active) {
      this.setState({ active: false }, () => {
        if (onBlur) onBlur(name)
      })
    }
  }

  open() {
    if (this.state.active) return
    this.setState({ active: true })
  }

  handleFocus(event) {
    event.stopPropagation()
    if (!this.props.disabled) this.open(event)
    if (this.props.onFocus) this.props.onFocus(event)
  }

  handleBlur(event) {
    event.stopPropagation()
    if (this.state.active) this.close()
    if (this.props.onBlur) this.props.onBlur(event)
  }

  getOptionsConfig() {
    if (!this.dropdownBox) return { size: 0, up: false }
    const client = this.dropdownBox.getBoundingClientRect()
    const screenHeight = window.innerHeight || document.documentElement.offsetHeight
    const size = screenHeight - client.bottom - 40
    const up = client.top > screenHeight / 2 + client.height
    return { size, up }
  }

  render() {
    const {
      id,
      error,
      fontSize,
      leftMost,
      options,
      placeholder,
      selected,
      showError,
      width,
      nowrap,
    } = this.props
    const { active } = this.state
    const { size } = this.getOptionsConfig()
    const selectedOption = _.find(options, ({ value }) => value === selected)

    const boundHandleClick = this.handleClick.bind(this)

    return (
      <InputWrapper>
        <div ref={box => (this.dropdownBox = box)} onClick={boundHandleClick}>
          <DropdownBox
            id={id}
            fontSize={fontSize}
            active={active}
            leftMost={leftMost}
            width={width}>
            <ValueBox active={active}>
              <Value selected={!!selected} nowrap={nowrap}>
                {selectedOption ? selectedOption.label : placeholder}
              </Value>
            </ValueBox>
            {active && (
              <OptionsBox size={size}>
                {options.map((opt, id) => {
                  return (
                    <Option
                      key={id}
                      disabled={opt.disabled}
                      selected={selected === opt.value}
                      onClick={this.handleSelect.bind(this, opt.value)}
                      value={opt.value}
                      nowrap={nowrap}>
                      {opt.label}
                    </Option>
                  )
                })}
              </OptionsBox>
            )}
          </DropdownBox>
        </div>
        <TextErrorField {...{ error, showError }} />
      </InputWrapper>
    )
  }
}

export default observer(Dropdown)
