import { types, flow, getEnv } from 'mobx-state-tree'
import dayjs from 'dayjs'
import URLSearchParams from 'url-search-params'
import months from '../../constants/months'
import GuidePages from './GuidePages'
const { model, optional, maybeNull } = types

const GuideStore = model({
  status: 'loading',
  error: '',
  guidePages: optional(GuidePages, {}),
  generationDate: maybeNull(types.Date),
})
  .views(self => ({
    get date() {
      const now = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
      }).format(self.generationDate)

      const date = dayjs(now)

      const month = months[date.format('M')]
      const day = date.format('D')
      const year = date.format('YYYY')
      return `${month} ${day}, ${year}`
    },
    get timeStamp() {
      const now = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'America/Los_Angeles',
      }).format(self.generationDate)

      const date = dayjs(now)

      const formattedDate = date.format('MMDDYYYY HHmmss')
      return formattedDate + ' PST'
    },
  }))
  .actions(self => ({
    afterCreate: flow(function* () {
      const { store, location, match } = getEnv(self)
      const { caseId } = match.params
      const token = new URLSearchParams(location.search).get('token')

      try {
        if (caseId) {
          store.auth.setGuideToken(token)

          yield store.getConfig()
          yield store.getFeatures()

          if (store.features.enableSpendDown && store.config.spendingMode) {
            self.status = 'done'
            return
          }

          yield Promise.all([
            store.getAccount(),
            store.getAccountInsight(),
            store.getContactInfo(),
            store.getDependents(),
            store.getAccounts(),
            store.getGoals(),
            store.getPerson(),
            store.getSpouse(),
            store.getBaseCase(),
            store.getAcceptedCase(caseId),
            store.getAcceptedContributionDistribution(caseId),
            store.getInstitutionalAccount(),
            // store.getBreakdown(caseId),
            // store.getBreakDownForPDF(caseId),
            store.getNonGCAccounts(),
            store.getPortfolioConstruction(),
            store.getPlanOverview(),
            store.getSSBenefits(),
            store.getAllPensions(),
            store.initializeActiveAccount(),
          ])

          self.guidePages.setFetchComplete(true)
          self.generationDate = new Date()
          self.status = 'done'
        } else {
          self.error = 'Missing token or case'
          self.status = 'error'
        }
      } catch (err) {
        self.error = 'Something went wrong, please try again later'
        self.error = JSON.stringify(err, Object.getOwnPropertyNames(err))
        self.status = 'error'
        throw new Error(err)
      }
    }),
  }))

export default GuideStore
