import { Component } from 'react'
import styled from '@emotion/styled'

const QuickActionsWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  padding: 0 0 0 40px;
  height: 44px;
  width: 100%;
  max-width: 100%;
  color: #4a606a;
  font-size: 26px;
  font-weight: 600;
  line-height: 27px;
`

class QuickActionMenu extends Component {
  render() {
    const { history } = this.props
    return (
      <QuickActionsWrapper
        onClick={() => {
          history.push('/quickactions')
        }}>
        Quick Actions
      </QuickActionsWrapper>
    )
  }
}
export default QuickActionMenu
