import styled from '@emotion/styled'

const Page = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
`

Page.Container = styled(Page)`
  margin: ${p => (p.margin ? p.margin : '0 auto')};
`
Page.FormBox = styled.div`
  min-height: 325px;
`
Page.GoalsWrapper = styled(Page)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;
`
Page.FlexRow = styled.div`
  display: flex;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : null)};
  align-items: ${p => (p.alignItems ? p.alignItems : 'flex-start')};
  flex-wrap: ${p => (p.wrap ? p.wrap : 'wrap')};
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  margin: ${p => (p.childMargin ? '0 -8px' : '36px 0 12px')};
  flex-direction: ${p => (p.flex ? 'row' : 'column')};

  & > * {
    margin: ${p => (p.childMargin ? '0 8px' : null)};
  }
`
Page.Row = styled(Page.FlexRow)`
  margin: 0;
  flex-direction: row;
  align-items: center;
`
Page.MainTitle = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 3rem;
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  color: ${p => p.theme.mediumGray};
  font-weight: 300;
  line-height: 1.25;
  transition: 0.25s;
  word-wrap: break-word;
  white-space: pre-wrap;
  margin-bottom: 32px;

  @media (max-width: 800px) {
    font-size: ${p => (p.shrink ? '2rem' : null)};
  }
`
Page.Label = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
`
Page.YearLabel = styled(Page.Label)`
  margin-right: 1rem;
`
Page.YearRow = styled.div`
  display: flex;
  align-items: center;
`
Page.ButtonsBox = styled.div`
  display: flex;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'space-between')};
  max-width: ${p => (p.maxWidth ? p.maxWidth : '1200px')};
  margin: 60px 0;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    margin: 40px auto;
  }
`
Page.LeftButtonBox = styled.div`
  flex: 0 1 360px;
  margin: 20px 0;

  @media (max-width: 800px) {
    flex: 1 1 100%;
    order: 2;
    text-align: center;
  }
`
Page.RightButtonBox = styled.div`
  flex: 1 1 320px;
  margin: 20px 0;

  @media (max-width: 800px) {
    flex: 1 1 100%;
    order: 1;
    text-align: center;
  }
`
Page.ValueBox = styled.div`
  word-wrap: break-word;
  white-space: pre-wrap;
`
Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`
Page.SpanText = styled.span`
  color: #9eacb0;
  padding: 0 8px;
`
Page.Text = styled.div`
  color: ${p => p.theme.cardFieldValueColor};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: ${p => (p.fontSize ? p.fontSize : '1.125rem')};
  line-height: 1.35;
  padding: ${p => (p.padding ? p.padding : null)};
  word-break: break-word;
`
Page.SeekText = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1.5rem;
  padding-right: 10px;
  max-width: 180px;
`
Page.SVGContainer = styled.div`
  padding: 20px 10px;
`
Page.SVGHelpContainer = styled.div`
  display: inline-flex;
  position: relative;
  padding-left: 8px;
  bottom: 4px;
`
Page.ViewRow = styled(Page.FlexRow)`
  justify-content: center;
  flex-direction: row;
  margin: 12px 0;
`
Page.ViewLabel = styled(Page.Label)`
  flex: 2 1 165px;
  text-align: ${p => (p.align ? 'start' : 'end')};

  @media (max-width: 800px) {
    flex: 2 1 100px;
  }
`
Page.ViewLabelWithdrawn = styled(Page.ViewLabel)`
  flex: 2 1 130px;
  text-align: start;
  margin-left: auto;

  @media (max-width: 800px) {
    flex: 2 1 105px;
  }
`
Page.ViewValueBox = styled(Page.ValueBox)`
  flex: 1 1 130px;
  padding: 0 20px;
`

export default Page
