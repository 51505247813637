import styled from '@emotion/styled'

export const ContentMainHeader = styled.div`
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-size: 22pt;
  padding: 6px 0;
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  text-align: ${p => (p.textAlign ? p.textAlign : null)};
  line-height: 1.2;
`

export const ContentMainHeaderChart = styled.div`
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-size: 22pt;
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  text-align: ${p => (p.textAlign ? p.textAlign : null)};
  line-height: 1.2;
`

export const ContentSubHeader = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-size: 12pt;
  padding: 8px 0;
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  text-align: ${p => (p.textAlign ? p.textAlign : null)};
`

export const ContentText = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-size: 11pt;
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  text-align: ${p => (p.textAlign ? p.textAlign : null)};
  line-height: 1.5;
`
export const DataAlertText = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-size: 11pt;
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  text-align: ${p => (p.textAlign ? p.textAlign : null)};
  line-height: 1.5;
`
export const DataAlertLI = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-size: 12pt;
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  text-align: ${p => (p.textAlign ? p.textAlign : null)};
  line-height: 1.5;
`
export const DataAlertSubHeader = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-size: 12pt;
  padding: 8px 0;
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  text-align: ${p => (p.textAlign ? p.textAlign : null)};
  font-weight: bold;
`

export const ListPadding = styled.span`
  padding-left: 20px;
`

export const InvestmentsListItem = styled.li`
  padding: 6px 0;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'flex-start')};
  align-items: ${p => (p.alignItems ? p.alignItems : 'center')};
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};

  margin: ${p => (p.childPadding ? `0 -${p.childPadding}` : null)};

  & > * {
    margin: ${p => (p.childPadding ? `0 ${p.childPadding}` : null)};
  }
`

export const HorizontalLine = styled.hr`
  background-color: #dadfe1;
  height: 1px;
  border: none;
`

export const FootnoteText = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 9pt;
  line-height: 1.5;
`

export const P = styled.p`
  margin: 0;
  padding: 7px;
`

export const GoalTable = styled.div`
  flex: 1 1 250px;
  border: solid 3pt #e7f3f9;
  border-radius: 7pt;
  padding: 0 15pt;
`

export const GoalTableCurrency = styled.span`
  color: ${p => p.theme.darkestGray};
  text-align: right;
  font-size: 20pt;
  flex: 1 0 50%;
  &:before {
    content: '$';
    color: ${p => p.theme.lightestGray};
    font-size: 20pt;
  }
`

export const GoalTableText = styled.div`
  flex: 1 0 50%;
  font-size: 11pt;
  color: ${p => p.theme.lightestGray};
`

export const GoalTableLabel = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: ${p => (p.fontSize ? p.fontSize : '13pt')};
  padding: ${p => (p.padding ? p.padding : null)};
  flex: ${p => (p.flex ? p.flex : null)};
`

export const StyledBar = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.25rem;
  flex: 1 1 auto;
  background-color: ${p => (p.isGray ? '#f3f4f5' : '#e7f3f9')};
  color: ${p => (p.isGray ? '#7a8e96' : '#038ab7')};
  text-align: center;
  padding: 16px 8px;
`
