import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { Layout } from '../elements'
import CompanyStockConfirmation from './CompanyStockConfirmation'
import SellingStockChart from './SellingStockChart'

const Container = styled(Layout.Container)`
  margin: 24px auto;
  padding: 0 12px;
`
// hardcoded spacing and wrapping for checkbox
const DisplayXLarge = styled(Layout.DisplayXLarge)`
  margin: 56px 24px 24px;
  line-height: 1.45;
`
const DisplayLarge = styled(Layout.DisplayLarge)`
  margin: 8px 24px;
`
const Body = styled(Layout.DisplaySmall)`
  margin: 24px;
`

function CompanyStock(props) {
  const { config, acceptedCase, institutional } = props.store

  // Total Value of all Company Stocks / Institutional Balance
  const currentPercentage = Math.round(
    (acceptedCase.companyStocks.reduce((total, stock) => total + stock.totalValue, 0) /
      (institutional.balance || 1)) *
      100
  )

  // (Total Value of all Company Stocks - Total Amount to Sell) / Institutional Balance
  const implementPercentage = Math.round(
    ((acceptedCase.companyStocks.reduce((total, stock) => total + stock.totalValue, 0) -
      acceptedCase.companyStocks.reduce((total, stock) => total + stock.amountToSell, 0)) /
      (institutional.balance || 1)) *
      100
  )

  const companyStocksSellAll = acceptedCase.companyStocks.filter(
    stock => stock.percentToSell === 100
  )

  return (
    <Container>
      <DisplayXLarge>Before we can finalize your plan</DisplayXLarge>

      <DisplayLarge>Company stock decision</DisplayLarge>

      <Body
        css={css`
          margin-top: 8px;
        `}>
        Your plan includes a decision to sell some of the company stock you hold in your{' '}
        {config.template.sponsorName} retirement plan account and to have the proceeds from that
        sale be allocated proportionately amongst your other account investments.
      </Body>

      <Body>
        If you decide to have us implement changes to your {config.template.sponsorName} retirement
        plan account, we will send {config.template.recordKeeperName} instructions to:
      </Body>

      {acceptedCase.companyStocks.map(stock => (
        <Layout.BrandText
          css={css`
            text-align: center;
          `}
          key={stock.positionId}>
          Sell {stock.percentToSell}% of {stock.securityName}
        </Layout.BrandText>
      ))}

      <SellingStockChart
        companyStockNames={acceptedCase.companyStockNames}
        currentPercentage={currentPercentage}
        implementPercentage={implementPercentage}
      />

      <CompanyStockConfirmation companyStocksSellAll={companyStocksSellAll} />
    </Container>
  )
}

export default inject('store')(observer(CompanyStock))
