import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { numberToDollars } from '../../../utils'
import brand from './brand'
import PersonalizedStrategy from './PersonalizedStrategy'

const breakpoint = 'max-width: 767px'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    margin: 36px;
  }

  & > div:first-of-type {
    flex: 0 1 480px;
    text-align: center;
    font-family: 'Open Sans';
    color: #4a606a;
    font-size: 18px;
    line-height: 1.35;
  }

  & > div:nth-of-type(2) {
    flex: 0 1 240px;
    text-align: center;
  }

  @media (${breakpoint}) {
    flex-wrap: wrap;
    justify-content: center;
    & > div:nth-of-type(2) {
      text-align: center;
    }
  }
`
const MonthlyEarnings = styled.div`
  margin: 28px 0;
  & > div:first-of-type {
    & > span:first-of-type {
      font-size: 48px;
      color: ${brand.green};
    }
    & > span:nth-of-type(2) {
      font-size: 12px;
    }
  }
  & > div:nth-of-type(2) {
    color: ${brand.blue};
  }
`
const GreenText = styled.span`
  color: ${brand.green};
`

function createProps({ config, person }) {
  switch (config.templateId) {
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 10:
      return {
        contentText: `${person.displayName}, thank you for allowing us to help you stay on track for a comfier retirement. If you implement the changes you've made today, you can expect to have:`,
      }

    // Schwab Legacy
    case 9:
      return {
        contentText: `${person.displayName}, thank you for allowing us to help you stay on track for a comfier retirement. If you implement the changes you've made today, you can expect to have:`,
      }
    default:
      return null
  }
}

function PersonalStrategy({ store }) {
  const { config, person, spouse, baseCase, acceptedCase } = store

  const renderProps = createProps({ config, person })
  const estimatedTotalMonthlyIncome = acceptedCase.estimatedTotalMonthlyIncome

  const primaryRetAge = _.get(acceptedCase, 'primary.retAge')
  const furthestMortalityAge = person.includeSpouse
    ? Math.max(person.expectedMortalityAge, spouse.expectedMortalityAge)
    : person.expectedMortalityAge
  const ageRange = furthestMortalityAge - primaryRetAge
  const estimatedExtraIncome =
    (acceptedCase.estimatedTotalMonthlyIncome - baseCase.estimatedTotalMonthlyIncome) *
    12 *
    ageRange

  return (
    <Container>
      <div>
        <div>{renderProps.contentText}</div>

        <MonthlyEarnings>
          <div>
            <span>${numberToDollars(estimatedTotalMonthlyIncome, true)}</span>
            <span> /month</span>
          </div>
          <div>
            from age {primaryRetAge} to {furthestMortalityAge}*
          </div>
        </MonthlyEarnings>

        {estimatedExtraIncome > 0 && (
          <div>
            That's <GreenText>${numberToDollars(estimatedExtraIncome, true)}* more</GreenText> in
            spendable, after-tax income than your current plan!
          </div>
        )}
      </div>
      <PersonalizedStrategy />
    </Container>
  )
}

export default inject('store')(observer(PersonalStrategy))
