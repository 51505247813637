import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import SpendingGuide from '../spend-down-guide'
import Guide from './Guide'

function GuideDashboard(props) {
  const { store } = props

  const token = new URLSearchParams(props.location.search).get('token')

  const [state, setState] = useState('loading')

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token !== null) {
          store.auth.setGuideToken(token)
        }

        await store.getConfig()
        await store.getFeatures()
        setState('done')
      } catch (err) {
        setState('error')
      }
    }
    fetchData()
  }, [store, token])

  if (state === 'loading') {
    return <div>Loading</div>
  }

  if (state === 'error') {
    return <div>{state.error}</div>
  }

  if (_.get(store, 'features.enableSpendDown', null) && _.get(store, 'config.spendingMode', null)) {
    return <SpendingGuide location={props.location} match={props.match} />
  }

  if (!_.get(store, 'config.spendingMode', null)) {
    return <Guide location={props.location} match={props.match} />
  }

  return null
}

export default inject('store')(observer(GuideDashboard))
