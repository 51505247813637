const Coin = `
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
	.st0-coin-svg{fill:#7A8E96;}
</style>
<g>
	<g>
		<path class="st0-coin-svg" d="M256,0C114.9,0,0,114.9,0,256s114.9,256,256,256s256-114.9,256-256S397.1,0,256,0z M256,459
			c-111.9,0-203-91.1-203-203S144.1,53,256,53s203,91.1,203,203S367.9,459,256,459z"/>
		<path class="st0-coin-svg" d="M299.7,241.6c-12.4-6.9-25.5-12-38.5-17.3c-7.5-3.1-14.7-6.8-21.1-11.8c-12.5-10-10.2-26.2,4.5-32.7
			c4.1-1.8,8.5-2.4,12.9-2.6c17-1,33.1,2.2,48.6,9.6c7.6,3.7,10.2,2.5,12.8-5.4c2.8-8.5,5.1-17,7.5-25.5c1.7-5.7-0.4-9.5-5.8-11.8
			c-9.8-4.3-20-7.5-30.7-9.1c-13.9-2.2-13.9-2.2-14-16.2c-0.1-19.8-0.1-19.8-19.8-19.7c-2.9,0-5.7-0.1-8.6,0
			c-9.2,0.3-10.8,1.9-11,11.1c-0.1,4.1,0,8.4,0,12.5c-0.1,12.4-0.1,12.2-12,16.4c-28.6,10.4-46.2,29.8-48.1,61
			c-1.7,27.6,12.7,46.2,35.3,59.8c14,8.4,29.4,13.3,44.2,19.9c5.8,2.5,11.3,5.5,16.1,9.5c14.3,11.7,11.6,31.3-5.3,38.7
			c-9.1,3.9-18.6,5-28.4,3.7c-15.1-1.9-29.6-5.8-43.2-12.9c-7.9-4.1-10.3-3.1-13,5.6c-2.3,7.5-4.4,15-6.5,22.5
			c-2.8,10.2-1.8,12.6,7.9,17.2c12.3,5.9,25.5,9,38.9,11.2c10.5,1.7,10.9,2.1,11,13c0,5,0.1,9.9,0.1,14.8c0.1,6.2,3.1,9.8,9.5,9.9
			c7.3,0.1,14.6,0.1,21.9,0c5.9-0.1,9-3.4,9.1-9.4c0-6.8,0.3-13.5,0.1-20.3c-0.3-6.9,2.6-10.4,9.3-12.2
			c15.2-4.1,28.2-12.4,38.3-24.5C349.6,312.8,339,263.4,299.7,241.6z"/>
	</g>
</g>
</svg>
`

export default Coin
