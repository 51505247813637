// Formerly PersonalAccounts had account type contributions hard-coded, so other account types do not need to be included here
export const accountTypeToAllowedContributions = {
  'ira-rollover': {
    preTaxContribAllowed: true,
    rothContribAllowed: false,
    posttaxContribAllowed: false,
  },
  'ira-roth': {
    preTaxContribAllowed: false,
    rothContribAllowed: false,
    posttaxContribAllowed: true,
  },
  'ira-deductible': {
    preTaxContribAllowed: true,
    rothContribAllowed: false,
    posttaxContribAllowed: false,
  },
  'ira-non-deductible': {
    preTaxContribAllowed: false,
    rothContribAllowed: false,
    posttaxContribAllowed: true,
  },
  'brokerage-account': {
    preTaxContribAllowed: false,
    rothContribAllowed: false,
    posttaxContribAllowed: true,
  },
  'other-asset': {
    preTaxContribAllowed: false,
    rothContribAllowed: false,
    posttaxContribAllowed: true,
  },
}

export function getIraContributionType(type) {
  switch (type) {
    case 14: // roth ira
    case 11: // non-deductible ira
      return 'postTaxSavingsAmt'
    default:
      return 'preTaxSavingsAmt'
  }
}

export function getIraTaxType(type) {
  switch (type) {
    case 'ira-roth':
    case 'ira-non-deductible':
      return 'afterTax'
    default:
      return 'preTax'
  }
}
