import styled from '@emotion/styled'

const Wrapper = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
`
Wrapper.Heading = styled.div`
  font-size: 36px;
  font-weight: 300;
  line-height: 49px;
`
Wrapper.Intro = styled.div`
  color: #06293a;
  font-size: 11pt;
  line-height: 1.35;
  padding: 12px 0 8px;
`
export { Wrapper }
