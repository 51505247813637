import variables from '../variables'
import colors from '../colors'

const { unit } = variables
const {
  rt: { black },
} = colors

const navigation = {
  space: unit,
  color: black,
}

export default navigation
