import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { primary, accent, black, primaryContrast, divider },
} = colors

const tabLabelHeight = reduceCSSCalc(`calc(4.8 * ${unit})`)

const tabTextHeight = reduceCSSCalc(`calc(1.4 * ${unit})`)

const tabText = black

const tabInverseText = primaryContrast

const tab = {
  labelDisabledOpacity: 0.2,
  labelHPadding: reduceCSSCalc(`calc(1.2 * ${unit})`),
  labelHeight: tabLabelHeight,
  iconHeight: reduceCSSCalc(`calc(2.4 * ${unit})`),
  iconMarginBottom: reduceCSSCalc(`calc(0.8 * ${unit})`),
  textHeight: tabTextHeight,
  labelVPadding: reduceCSSCalc(`calc((${tabLabelHeight} - ${tabTextHeight}) / 2)`),
  navigationBorderColor: divider,
  pointerColor: primary,
  pointerHeight: reduceCSSCalc(`calc(0.2 * ${unit})`),
  focusColor: color.convert(`color(${primaryContrast} a(10%))`),
  text: tabText,
  textColor: tabText,
  textInactiveColor: color.convert(`color(${tabText} a(70%))`),
  inverseBarColor: primary,
  inversePointerColor: accent,
  inverseText: tabInverseText,
  inverseTextColor: tabInverseText,
  inverseTextInactiveColor: color.convert(`color(${tabInverseText} a(30%))`),
}

export default tab
