import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { FORM_ERROR } from 'final-form'

import { LargeCheckbox } from '../../../guided-toolbox/checkbox'
import { Layout } from '../elements'
import CompanyStockButtons from './CompanyStockButtons'

const StyledSelectionBox = styled(Layout.SelectionBox)`
  margin: 28px 24px;
`
const StyledSelectionField = styled(Layout.SelectionField)`
  display: flex;

  label {
    display: inline-flex;
    width: 36px;
    margin: 0;

    /* enlarge checkbox */
    transform: scale(1.5, 1.5) translate(5px, 5px);
  }
`
const StyledCheckboxLabel = styled(Layout.DisplaySmall)`
  display: inline-flex;
  width: 96%;
  margin-left: 1%;
  color: #4a606a;
  cursor: pointer;
`

function CompanyStockConfirmation(props) {
  function initialValues() {
    // map across companyStocksSellAll to determine how many fields to display
    return {
      companyStocksSellAll: props.companyStocksSellAll.map(({ positionId, securityName }) => ({
        positionId,
        securityName,
        confirmed: false,
      })),
    }
  }

  function validate(value) {
    // make sure they've confirmed sell-off of company stock(s) being sold to 100%
    return value ? undefined : 'Please confirm your company stock decision before continuing.'
  }

  function onSubmit(values) {
    // push them to the next page once valid
    return handleSubmit(values)
  }

  async function handleSubmit(values) {
    try {
      await Promise.resolve()

      const {
        history,
        offboarding: { nextRoute },
      } = props

      history.push(`/implement/${nextRoute('company-stock')}`)
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const { config } = props.store

  return (
    <Form
      initialValues={initialValues()}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      subscription={{ touched: true, errors: true, submitting: true, submitError: true }}
      render={({ touched, errors, submitting, submitError, handleSubmit }) => {
        const submittingError = !submitting && submitError
        const termsError =
          !submitting &&
          touched.companyStocksSellAll &&
          errors.companyStocksSellAll &&
          'Please confirm your company stock decision before continuing.'

        return (
          <form onSubmit={handleSubmit}>
            <FieldArray name='companyStocksSellAll'>
              {({ fields }) =>
                fields.value.length > 0 && (
                  <StyledSelectionBox>
                    <Layout.SelectionTitle>Confirmation</Layout.SelectionTitle>

                    {fields.map((name, index) => (
                      <Field
                        key={fields.value[index].positionId}
                        name={`${name}.confirmed`}
                        validate={validate}
                        render={({ input }) => (
                          <StyledSelectionField>
                            {/* eslint-disable */}
                            <LargeCheckbox
                              checked={input.value === true}
                              onChange={
                                submitting
                                  ? () => {}
                                  : () => {
                                      input.onFocus()
                                      input.onChange(!input.value)
                                      input.onBlur()
                                    }
                              }
                            />
                            <StyledCheckboxLabel
                              onClick={
                                submitting
                                  ? () => {}
                                  : () => {
                                      input.onFocus()
                                      input.onChange(!input.value)
                                      input.onBlur()
                                    }
                              }>
                              {/* eslint-enable */}
                              Please confirm you are aware that 100% of your{' '}
                              {fields.value[index].securityName} will be sold if you elect to have
                              GuidedChoice implement the changes to your{' '}
                              {config.template.sponsorName} retirement plan account.
                            </StyledCheckboxLabel>
                          </StyledSelectionField>
                        )}
                      />
                    ))}
                  </StyledSelectionBox>
                )
              }
            </FieldArray>

            <Layout.ErrorMessage>{submittingError || termsError}</Layout.ErrorMessage>

            <CompanyStockButtons
              isDisabled={submitting || !!errors.terms}
              handleNext={handleSubmit}
            />
          </form>
        )
      }}
    />
  )
}

export default withRouter(inject('store', 'offboarding')(observer(CompanyStockConfirmation)))
