import createDecorator from 'final-form-calculate'

export const emergencyFundCalculator = createDecorator(
  {
    field: 'emergencyFundInput',
    updates: {
      emergencyFundSlider: (ignoredValue, allValues) => {
        if (allValues.emergencyFundInput === undefined) return
        let number
        if (
          typeof allValues.emergencyFundInput === 'string' &&
          allValues.emergencyFundInput.includes(',')
        ) {
          number = allValues.emergencyFundInput.replace(',', '')
          number = parseInt(number)
        }
        if (
          !number &&
          (isNaN(allValues.emergencyFundInput) || allValues.emergencyFundInput === undefined)
        )
          return 0
        if (
          number > allValues.maximumEmergencyFundInput ||
          allValues.emergencyFundInput > allValues.maximumEmergencyFundInput
        )
          return allValues.maximumEmergencyFundInput
        return parseInt(number || allValues.emergencyFundInput)
      },
    },
  },
  {
    field: 'emergencyFundSlider',
    updates: {
      emergencyFundInput: (ignoredValue, allValues) => {
        return parseInt(allValues.emergencyFundSlider)
      },
    },
  }
)
