import createDecorator from 'final-form-calculate'
import dayjs from 'dayjs'
import * as R from 'ramda'
import * as yup from 'yup'

import { stringWhitelist } from '../../../utils'

export const schema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .trim()
    .min(1, 'Name is too short')
    .max(32, 'Goal name cannot be longer than 32 characters')
    .required('Name is required')
    .test('is-unique-name', 'Please create a unique name', function (name) {
      const { existingGoalNames } = this.options.context
      return !existingGoalNames.includes(name.toLowerCase())
    })
    .test('is-valid-name', 'Special characters are not allowed', stringWhitelist),
  isOneTime: yup.boolean().nullable().required('Goal type is required'),
  sameAmount: yup
    .boolean()
    .nullable()
    .when('isOneTime', (isOneTime, schema) =>
      isOneTime === false ? schema.required('This field is required') : schema
    ),
})

export const calculator = createDecorator(
  {
    field: 'isOneTime',
    updates: {
      endDate: (isOneTime, values) =>
        isOneTime === true
          ? dayjs(values.startDate).endOf('year').format('YYYY-MM-DD')
          : values.endDate,
      sameAmount: (isOneTime, values) => (isOneTime === true ? null : values.sameAmount),
      amounts: (isOneTime, values) =>
        isOneTime === true ? [R.head(values.amounts)] : values.amounts,
    },
  },
  {
    field: 'sameAmount',
    updates: {
      amounts: (sameAmount, values) =>
        R.isNil(sameAmount) ? values.amounts : updateAmounts(values),
    },
  },
  {
    field: 'startDate',
    updates: {
      endDate: (startDate, values) => {
        if (values.isOneTime) {
          return dayjs(startDate).endOf('year').format('YYYY-MM-DD')
        }

        // check if endDate is less than the new startDate, if true update endDate
        if (dayjs(startDate).year() > dayjs(values.endDate).year()) {
          return dayjs(startDate).endOf('year').format('YYYY-MM-DD')
        }

        return values.endDate
      },
      amounts: (startDate, values) => {
        let { endDate } = values
        if (values.isOneTime) {
          endDate = dayjs(startDate).endOf('year').format('YYYY-MM-DD')
        }

        // check if endDate is less than the new startDate, if true update endDate
        if (dayjs(startDate).year() > dayjs(endDate).year()) {
          endDate = dayjs(startDate).endOf('year').format('YYYY-MM-DD')
        }

        return updateAmounts(values, endDate)
      },
    },
  },
  {
    field: 'endDate',
    updates: {
      amounts: (endDate, values) => updateAmounts(values, endDate),
    },
  }
)

const handleYears = (values, endDate = null) => {
  const endYear = R.when(R.isNil, R.always(values.endDate))(endDate)
  const startYear = dayjs(values.startDate).year()
  const yearRange = dayjs(endYear).diff(values.startDate, 'years') + 1

  return [startYear, yearRange]
}
const updateAmounts = (values, endDate) => {
  const [startYear, yearRange] = handleYears(values, endDate)
  const amountsModel = R.repeat({ year: 0, amount: null, paid: false }, yearRange)
  const updateYear = (amt, idx) => ({ ...amt, year: startYear + idx })
  const updateAmount = amt => {
    const safeAmount = R.propOr(null, 'amount')
    const amountExists = R.find(R.eqProps('year', amt))
    const modifyAmount = R.assoc('amount', R.__, amt)

    return R.pipe(amountExists, safeAmount, modifyAmount)(values.amounts)
  }

  // preserve curried argument position
  return amountsModel.map(updateYear).map(updateAmount)
}

export const initialize = ({ foundGoal, isEdit }) => {
  // destructure values from editing goal
  if (isEdit) {
    const {
      name,
      type,
      startDate,
      endDate,
      amount,
      sameAmount,
      disbursements,
      totalRemaining,
      paid,
      id,
      participantId,
      dependentId,
    } = foundGoal

    const amounts = disbursements.map(item => ({
      year: dayjs(item.startDate).year(),
      amount: item.amount,
      paid: item.paid,
    }))

    return {
      name,
      isOneTime: type,
      sameAmount,
      startDate,
      endDate,
      amountPerYear: amount,
      amounts,
      totalRemaining,
      paid,
      id,
      participantId,
      dependentId,
      minYear: Math.min(amounts[0].year, dayjs().year()),
    }
  }

  // initial add goal
  return {
    name: '',
    isOneTime: null,
    sameAmount: null,
    startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('year').format('YYYY-MM-DD'),
    amountPerYear: null,
    amounts: [
      {
        year: dayjs().endOf('year').year(),
        amount: null,
        paid: false,
      },
    ],
    paid: false,
    minYear: dayjs().year(),
  }
}

export const prepareValuesForSubmit = values => {
  let disbursements = []

  if (values.isOneTime) {
    const paid = dayjs(values.startDate).isBefore(dayjs(), 'year')
      ? true
      : dayjs().isSame(values.startDate, 'year')
      ? values.paid
      : false
    disbursements[0] = {
      amount: values.amountPerYear,
      startDate: values.startDate,
      endDate: values.endDate,
      paid,
    }
  }

  if (values.isOneTime === false && values.sameAmount) {
    disbursements = values.amounts.map(({ paid, year }) => ({
      amount: values.amountPerYear,
      paid: dayjs(year + '-01-01').isBefore(dayjs(), 'year')
        ? true
        : dayjs().isSame(year + '-01-01', 'year')
        ? values.paid
        : paid,
      startDate: year + '-01-01',
      endDate: year + '-12-31',
    }))
  }

  if (values.isOneTime === false && values.sameAmount === false) {
    disbursements = values.amounts.map(({ amount, paid, year }) => ({
      amount,
      paid: dayjs(year + '-01-01').isBefore(dayjs(), 'year')
        ? true
        : dayjs().isSame(year + '-01-01', 'year')
        ? values.paid
        : paid,
      startDate: year + '-01-01',
      endDate: year + '-12-31',
    }))
  }

  return {
    id: values.id,
    name: values.name,
    disbursements,
    dependentId: values.dependentId,
    participantId: values.participantId,
  }
}

export const valuesEqualGoal = (values, goal) => {
  if (!goal) {
    return false
  }

  const normalizedGoal = {
    dependentId: goal.dependentId,
    disbursements: goal.disbursements
      .slice()
      .map(({ amount, paid, startDate, endDate }) => ({ amount, paid, startDate, endDate })),
    id: goal.id,
    name: goal.name,
    participantId: goal.participantId,
  }

  return R.equals(values, normalizedGoal)
}

export const calcTotalRemaining = values => {
  if (values.isOneTime) {
    const isPast =
      dayjs(values.startDate).isBefore(dayjs(), 'year') ||
      (dayjs().isSame(values.startDate, 'year') && values.paid)
    return isPast ? 0 : values.amountPerYear || 0
  }

  if (values.sameAmount) {
    const startBeforeCurrentYear = dayjs(values.startDate).isBefore(dayjs(), 'year')
    const currentToEndDateRange = dayjs(values.endDate).diff(dayjs(), 'years') + 1
    const startDateToEndDateRange = dayjs(values.endDate).diff(values.startDate, 'years') + 1
    let numberOfYearsRemaining = 0

    if (startBeforeCurrentYear && values.paid) {
      // Start Date is before current year and current year is paid
      numberOfYearsRemaining = currentToEndDateRange - 1
    } else if (startBeforeCurrentYear && !values.paid) {
      // Start Date is before current year but current year is unpaid
      numberOfYearsRemaining = currentToEndDateRange
    } else if (dayjs().isSame(values.startDate, 'year') && values.paid) {
      // Start Date is same as current year and current year is paid
      numberOfYearsRemaining = startDateToEndDateRange - 1
    } else {
      // every other case
      numberOfYearsRemaining = startDateToEndDateRange
    }

    return Math.round((values.amountPerYear || 0) * numberOfYearsRemaining)
  }

  const reduceAmount = (total, goal) => {
    if (dayjs(goal.year + '-01-01').isBefore(dayjs(), 'year')) {
      return total
    }

    if (dayjs(goal.year + '-01-01').isSame(dayjs(), 'year') && (goal.paid || values.paid)) {
      return total
    }

    return total + (goal.amount || 0)
  }

  return values.amounts.reduce(reduceAmount, 0)
}
