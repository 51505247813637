import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import dayjs from 'dayjs'

import { CurrencyText, Drawer, Loading } from '../../../../components'
import { TotalBalanceBox, GreyText, LightGreyText } from '../../styled'
import InstitutionalInvestmentsGroup from './InstitutionalInvestmentsGroup'
import EditInvestment from '../EditInvestment'
import AddInvestmentButton from './AddInvestmentButton'
import AvailableInvestments from './AvailableInvestments'

const InstitutionalInvestmentsDisplay = props => {
  const [editInvestment, setEditInvestment] = useState(null)
  const toggleEdit = id => setEditInvestment(id)
  const { type, accountType, id } = props.match.params

  const {
    features: { editInstitutionalAccount },
    institutionalAccount,
  } = props.store

  const {
    investments,
    investedInvestments,
    totalBalance,
    preTaxInvestments,
    rothInvestments,
    afterTaxInvestments,
    notInvestedInvestments,
    preTaxBalance,
    rothBalance,
    afterTaxBalance,
    availableInvestments,
    getAvailableInvestments,
  } = props.account
  const [loadingAvailableInvestments, setLoadingAvailableInvestments] = useState(true)

  useEffect(() => {
    const fetchAvailableInvestments = async () => {
      try {
        if (editInstitutionalAccount) {
          await getAvailableInvestments()
        }
        setLoadingAvailableInvestments(false)
      } catch (error) {
        setLoadingAvailableInvestments(false)
        console.error(error)
      }
    }
    fetchAvailableInvestments()
  }, [editInstitutionalAccount, getAvailableInvestments])

  const investmentDates = investments.map(investment =>
    dayjs(investment.dateOfUserInfo || new Date()).valueOf()
  )

  const canEdit = editInstitutionalAccount
  const mostRecentInvestmentDate = dayjs(Math.min(...investmentDates)).format('M/D/YYYY')
  const isInstitutionalAccount = Number(id) === institutionalAccount?.id

  return (
    <div>
      {investedInvestments.length > 0 && (
        <div
          css={css`
            margin-bottom: 40px;
          `}>
          <LightGreyText>Data as of {mostRecentInvestmentDate}</LightGreyText>
          <TotalBalanceBox>
            <LightGreyText>Account balance:</LightGreyText>
            <GreyText>
              <CurrencyText decimalScale={2} value={totalBalance} />
            </GreyText>
          </TotalBalanceBox>
        </div>
      )}

      <div>
        {preTaxInvestments.length > 0 && (
          <div
            css={css`
              margin-bottom: 40px;
            `}>
            <InstitutionalInvestmentsGroup
              balance={preTaxBalance}
              balanceLabel='Pre-tax balance:'
              investments={preTaxInvestments}
              title='Pre-tax investments'
              canEdit={canEdit}
              toggleEdit={toggleEdit}
              accountId={id}
            />
          </div>
        )}

        {rothInvestments.length > 0 && (
          <div
            css={css`
              margin-bottom: 40px;
            `}>
            <InstitutionalInvestmentsGroup
              balance={rothBalance}
              balanceLabel='Roth balance:'
              investments={rothInvestments}
              title='Roth investments'
              canEdit={canEdit}
              toggleEdit={toggleEdit}
              accountId={id}
            />
          </div>
        )}

        {afterTaxInvestments.length > 0 && (
          <div
            css={css`
              margin-bottom: 40px;
            `}>
            <InstitutionalInvestmentsGroup
              balance={afterTaxBalance}
              balanceLabel='After-tax balance:'
              investments={afterTaxInvestments}
              title='After-tax investments'
              canEdit={canEdit}
              toggleEdit={toggleEdit}
              accountId={id}
            />
          </div>
        )}
      </div>

      {notInvestedInvestments.length > 0 && (
        <div
          css={css`
            margin-bottom: ${availableInvestments.length > 0 ? '0px' : '40px'};
          `}>
          <InstitutionalInvestmentsGroup
            hideBalance
            investments={notInvestedInvestments}
            title='Other available investment options'
            toggleEdit={toggleEdit}
            match={props.match}
            history={props.history}
          />
        </div>
      )}
      {loadingAvailableInvestments && <Loading></Loading>}

      {editInstitutionalAccount && isInstitutionalAccount && availableInvestments.length > 0 && (
        <AvailableInvestments
          displayTitle={!notInvestedInvestments.length}
          match={props.match}
          history={props.history}
          availableInvestments={availableInvestments}
        />
      )}

      {canEdit && (
        <AddInvestmentButton
          linkAddress={`/accounts/add/${type}/${accountType}/${id}/investment`}
        />
      )}

      <Drawer active={!!editInvestment} title='Edit investment' maxWidth='700px' width='60%'>
        {!!editInvestment && (
          <EditInvestment investmentId={editInvestment} toggleEdit={toggleEdit} />
        )}
      </Drawer>
    </div>
  )
}

export default inject('account', 'store')(observer(InstitutionalInvestmentsDisplay))
