/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import _ from 'lodash'

import { SVGWrapper, Spacer } from '../../../components'
import { CircleNegative, CirclePlus } from '../../../assets/icons'
import { Page } from './style'

const SaveScenario = props => {
  const {
    modifiedMonthlyIncome,
    store: { saveSpendownScenario, getAllScenarios },
  } = props
  const [scenarioSaved, setScenarioSaved] = useState(false)

  const handleSaveScenario = async (values, form) => {
    try {
      await saveSpendownScenario({
        ...modifiedMonthlyIncome,
        spendingScenario: {
          ...modifiedMonthlyIncome.spendingScenario,
          scenarioDescription: values.scenarioDescription,
          scenarioName: values.scenarioName,
        },
      })
      setScenarioSaved(true)
      await getAllScenarios()
      setTimeout(function () {
        form.mutators.toggleOpen()
      }, 500)
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const onSubmit = (values, form) => {
    return handleSaveScenario(values, form)
  }

  const mutators = {
    toggleDescription: (args, state, utils) => {
      utils.changeValue(state, 'openDescription', values => !state.formState.values.openDescription)
    },
    toggleOpen: (args, state, utils) => {
      utils.changeValue(state, 'open', values => !state.formState.values.open)
    },
  }

  const initialValues = {
    scenarioName: '',
    scenarioDescription: '',
    openDescription: false,
    open: false,
  }

  return (
    <>
      <Form
        mutators={mutators}
        initialValues={initialValues}
        onSubmit={onSubmit}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ form, handleSubmit, submitting, values }) => (
          <>
            <div onClick={form.mutators.toggleOpen}>
              <SVGWrapper
                css={css`
                  margin-right: 10px;
                `}
                svg={values.open ? CircleNegative : CirclePlus}
                fill='#3A4D57'
                size='large'
              />
              <Page.OptionalText>Optional: Save this scenario</Page.OptionalText>
              <Spacer space='5px' />
            </div>
            {values.open && (
              <Page.ScenarioContainer>
                <Page.Text>
                  Have access to this scenario plus four more for seven days. We'll keep the latest
                  five you save.
                </Page.Text>
                <Spacer space='5px' />

                <Page.WhiteBox>
                  <Page.Row>
                    <Page.Text> Give it a name: </Page.Text>
                    <ScenarioName />
                  </Page.Row>

                  <Spacer space='5px' />

                  <div onClick={form.mutators.toggleDescription}>
                    <SVGWrapper
                      css={css`
                        margin-right: 10px;
                      `}
                      svg={values.openDescription ? CircleNegative : CirclePlus}
                      fill='#3A4D57'
                      size='large'
                    />
                    <Page.SpanText>Optional: Add a description</Page.SpanText>
                  </div>

                  {values.openDescription && <ScenarioDescription />}
                </Page.WhiteBox>
                <Page.Row
                  css={css`
                    justify-content: flex-end;
                  `}>
                  <Page.SaveScenarioBtn
                    onClick={handleSubmit}
                    disabled={submitting || scenarioSaved}
                    type='button'
                    secondary
                    label={scenarioSaved ? 'Scenario saved' : 'Save scenario'}
                  />
                </Page.Row>
              </Page.ScenarioContainer>
            )}
          </>
        )}
      />
    </>
  )
}

export default inject('store')(observer(SaveScenario))

function ScenarioName() {
  const { input, meta } = useField('scenarioName', {
    validate: value =>
      yup
        .string()
        .required('Name required; must be from 1 to 20 characters')
        .min(1, 'Name required; must be from 1 to 20 characters')
        .max(20, 'Name required; must be from 1 to 20 characters')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <Page.Column>
      <Page.NameInput maxLength='20' {...input} />
      {meta.touched && <Page.Error>{meta.error}</Page.Error>}
    </Page.Column>
  )
}
function ScenarioDescription() {
  const { input, meta } = useField('scenarioDescription', {
    validate: value =>
      yup
        .string()
        .max(180, 'Description cannot be longer than 180 characters')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <Page.Column>
      <Page.ScenarioDescription maxLength='180' {...input} />
      {meta.touched && <Page.Error>{meta.error}</Page.Error>}
    </Page.Column>
  )
}
