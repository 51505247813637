import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'
import dayjs from 'dayjs'
import uuid from 'uuid/v4'

import { Page, PageHeader } from '../../elements'
import {
  DataAlertSubHeader,
  DataAlertText,
  ContentSubHeader,
  DataAlertLI,
} from '../../elements/styled'
import { Spacer } from '../../../../components'
import { numberToDollars } from '../../../../utils'

function UpdateInfoAlert(props) {
  const {
    store: { accounts, person, spouse, allPensions, goals },
    getInvestments,
    page,
  } = props

  const [showGoalWithdrawal, setShowGoalWithdrawal] = useState(false)
  const [showLumpSum, setShowLumpSum] = useState(false)
  const [showOutdated, setShowOutdated] = useState(false)
  const [isLessThanYear, setIsLessThanYear] = useState(false)
  const [isMoreThanYear, setIsMoreThanYear] = useState(false)

  const isMounted = useRef(true)

  const currentYearStartOfDate = dayjs().startOf('year').format('YYYY-MM-DD')
  const shortListAccounts = accounts.filter(
    account => account.type === 'Institutional' || account.type === 'NonGc'
  )
  let outdatedAccounts = useMemo(() => [], [])
  let goalWithdrawalsNeeded = useMemo(() => [], [])
  let pensionPayouts = useMemo(() => [], [])

  const getAllInvestments = useCallback(async () => {
    for (const account of shortListAccounts) {
      const id = account.id
      let type = account.type

      switch (type) {
        case 'Institutional':
          type = 'institutional'
          break
        case 'NonGc':
          type = 'non-gc'
          break
        default:
          break
      }
      const accountInv = await getInvestments({ type, id })

      accountInv.every(investment => {
        if (parseInt(dayjs(investment.dateOfUserInfo).toNow(true)) >= 185) {
          if (parseInt(dayjs(investment.dateOfUserInfo).toNow(true)) <= 365) {
            // 185 < x < 365
            setIsLessThanYear(true)
          } else {
            // x > 365
            setIsMoreThanYear(true)
          }
          outdatedAccounts.push(toJS(account))
          return false
        } else {
          return true
        }
      })
    }

    if (outdatedAccounts.length > 0) {
      setShowOutdated(true)
    }
  }, [getInvestments, outdatedAccounts, shortListAccounts])

  const showGoalWithdraw = useCallback(() => {
    goals?.every(goal => {
      if (
        goal.disbursements.map(disbursement => {
          if (disbursement.startDate === currentYearStartOfDate) {
            if (!disbursement.paid) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        })
      ) {
        if (!showGoalWithdrawal) {
          setShowGoalWithdrawal(true)
        }
        goalWithdrawalsNeeded.push(goal)
        return true
      } else {
        return true
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYearStartOfDate, goals, goalWithdrawalsNeeded])

  const showLump = useCallback(() => {
    const pensions = allPensions
      .filter(account => account.paymentType === 'L')
      .filter(account => account.lumpsumreceived === 'N')

    if (person.includeSpouse) {
      const spousePensions = pensions
        .filter(account => account.ownerId === spouse.id)
        .filter(account => account.pensionStartAge <= spouse.age)
      const primaryPensions = pensions
        .filter(account => account.ownerId === person.id)
        .filter(account => account.pensionStartAge <= person.age)

      if ((spousePensions.length > 0 || primaryPensions.length > 0) && !showLumpSum) {
        setShowLumpSum(true)
      }
      pensionPayouts.push(...primaryPensions)
      pensionPayouts.push(...spousePensions)
    } else {
      const primaryPensions = pensions.filter(account => account.pensionStartAge === person.age)

      if (primaryPensions.length > 0 && !showLumpSum) {
        setShowLumpSum(true)
      }
      pensionPayouts.push(...primaryPensions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPensions, person.age, person.id, person.includeSpouse, spouse?.age, spouse?.id])

  useEffect(() => {
    try {
      if (isMounted.current) {
        getAllInvestments()
        showGoalWithdraw()
        showLump()
      }
    } catch (err) {
      console.error(err)
    }

    return () => {
      isMounted.current = false
    }
  }, [getAllInvestments, showGoalWithdraw, showLump])

  const outdatedAccts = () => {
    return (
      <>
        {outdatedAccounts.map(acct => {
          return (
            <DataAlertLI key={uuid()}>
              {acct.name} (
              {acct.participantId === person.id
                ? person.preferredName
                  ? person.preferredName
                  : person.firstName
                : spouse.firstName}
              's)
            </DataAlertLI>
          )
        })}
      </>
    )
  }

  const goalWithdrawals = () => {
    return goalWithdrawalsNeeded.map(goal => {
      const startYear = goal.disbursements[0].startYear
      const endYear = goal.disbursements[goal.disbursements.length - 1].startYear
      let year = startYear

      if (startYear !== endYear) {
        year = startYear + '-' + endYear
      }

      return (
        <DataAlertLI key={uuid()}>
          {goal.name}—{year} amount needed: ${numberToDollars(goal.totalRemaining, true)}
        </DataAlertLI>
      )
    })
  }
  const lumpSum = () => {
    return pensionPayouts.map(pension => {
      return (
        <DataAlertLI key={uuid()}>
          {pension.pensionName} (
          {pension.ownerId === person.id
            ? person.preferredName
              ? person.preferredName
              : person.firstName
            : spouse.firstName}
          's)—amount expected: ${numberToDollars(pension.monthlyPensionIncome, true)}
        </DataAlertLI>
      )
    })
  }

  if (showGoalWithdrawal || showLumpSum || showOutdated) {
    return (
      <Page header={<PageHeader text='Snapshot' />} page={page}>
        <ContentSubHeader>DATA ALERT STILL IN EFFECT</ContentSubHeader>
        <DataAlertText>
          Be sure to update your data as soon as you can. The advice and projections we've provided
          have been impacted by the following issue(s), possible to a significant degree. Entering
          updated information may require other changes to your strategy to reach your financial
          goals.
        </DataAlertText>

        {showGoalWithdrawal && (
          <>
            <Spacer space='20px' />
            <DataAlertSubHeader>Goal withdrawal</DataAlertSubHeader>
            <Spacer space='5px' />
            {goalWithdrawals()}
            <Spacer space='5px' />
            <DataAlertText>
              We consider this amount as money yet to be withdrawn from investment accounts.
            </DataAlertText>
          </>
        )}

        {showLumpSum && (
          <>
            <Spacer space='20px' />
            <DataAlertSubHeader>Lump sum pension payout</DataAlertSubHeader>
            <Spacer space='5px' />
            {lumpSum()}
            <Spacer space='5px' />
            <DataAlertText>
              We consider this amount expected income that will be rolled into a tax-advantaged
              account.
            </DataAlertText>
          </>
        )}

        {showOutdated && (
          <>
            <Spacer space='20px' />
            <DataAlertSubHeader>Outdated investment balances</DataAlertSubHeader>
            <Spacer space='5px' />
            {outdatedAccts()}
            <Spacer space='5px' />
            <DataAlertText>
              Some of your investment balance information is {isLessThanYear && `6-12 months old.`}
              {isMoreThanYear && `more than a year old.`} The greater the difference between the
              balance in our database and the actual balance, the greater the impact on your advice
              and income projections.
            </DataAlertText>
          </>
        )}
      </Page>
    )
  } else {
    return null
  }
}

export default inject('store')(observer(UpdateInfoAlert))
