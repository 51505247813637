import { useState } from 'react'
import { observer } from 'mobx-react'

import Tabs from './Tabs'
import Legend from './Legend'
import MixLabels from './MixLabels'
import Risk from './Risk'
import Breakdown from './Breakdown'

function InvestmentMixChart(props) {
  const [state, setState] = useState({ active: 'assetClassBreakdown' })
  function handleChange(active) {
    setState({ active })
  }

  const { marketingOnly, allTabsEnabled, store, ...propsRest } = props
  // Hide the Old Mix for Asset Class Breakdown Tab
  const hideOld = state.active === 'assetClassBreakdown'

  return (
    <div {...propsRest}>
      <Tabs
        store={store}
        active={state.active}
        handleChange={handleChange}
        allTabsEnabled={allTabsEnabled}
        theme={props.theme}
      />

      <Legend />

      <MixLabels hideOld={hideOld} theme={props.theme} />

      <Risk store={store} hideOld={hideOld} theme={props.theme} />

      <Breakdown
        selectedaccount={props.selectedaccount}
        store={store}
        active={state.active}
        hideOld={hideOld}
        theme={props.theme}
      />
    </div>
  )
}

export default observer(InvestmentMixChart)
