export const calculateBalance = investments =>
  investments.reduce((accumulator, investment) => accumulator + investment.balance, 0)

export const totalBalance = ({ preTax, roth, afterTax, other }) => {
  let balance = 0

  if (preTax) {
    balance = balance + calculateBalance(preTax)
  }

  if (roth) {
    balance = balance + calculateBalance(roth)
  }

  if (afterTax) {
    balance = balance + calculateBalance(afterTax)
  }

  if (other) {
    balance = calculateBalance(other)
  }

  return balance
}

export const sortFunction = (investment, sortBy) => {
  if (sortBy === 'balance') {
    return investment.balance || 0
  }

  if (sortBy === 'name') {
    return investment.securityName
  }
}

export const sortActive = (active, sortOrder) => {
  if (!active) {
    return 'neutral'
  }

  if (sortOrder === 'asc') {
    return 'up'
  }

  if (sortOrder === 'desc') {
    return 'down'
  }
}
