import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

const TableRow = styled.div`
  display: flex;
  position: relative;
`
const PrimaryColumn = styled.div`
  flex: 0 0 60%;
`
const SecondaryColumn = styled.div`
  flex: 0 0 20%;
`

function CheckMark() {
  return (
    <div
      css={css`
        text-align: center;
      `}>
      <svg height='50' width='50'>
        <circle cx='25' cy='25' r='15' fill='#038AB7' />
      </svg>
    </div>
  )
}

function CompareItem(props) {
  return (
    <TableRow
      css={css`
        props.border && &:after {
          position: absolute;
          bottom: 0;
          right: 90px;
          content: '';
          display: block;
          margin: 0 auto;
          width: 31%;
          border-bottom: 1px solid #7a8e96;
          opacity: 0.5;
        }
      `}>
      <PrimaryColumn
        css={css`
          display: flex;
          align-items: center;
        `}>
        <div
          css={css`
            color: #022a3a;
            font-size: 16px;
            line-height: 1.35;
            padding: 0.5rem 0;
            margin-right: 1rem;
          `}>
          {props.item}
        </div>
      </PrimaryColumn>
      <SecondaryColumn
        css={css`
          min-width: 160px;
          max-width: 200px;
          padding: 0 0.25rem;
          position: relative;
        `}>
        <div
          css={css`
            background-color: rgba(231, 243, 249, 0.8);
            width: 100%;
            min-height: 50px;
            height: 100%;
          `}>
          {props.keep}
        </div>
      </SecondaryColumn>
      <SecondaryColumn
        css={css`
          min-width: 160px;
          max-width: 200px;
          padding: 0 0.25rem;
          position: relative;
        `}>
        <div
          css={css`
            background-color: rgba(79, 173, 204, 0.2);
            width: 100%;
            min-height: 50px;
            height: 100%;
          `}>
          {props.upgrade}
        </div>
      </SecondaryColumn>
    </TableRow>
  )
}

function EnrollmentSelection(props) {
  async function handleEnrollManagedAccount() {
    try {
      const { acceptedCaseId, updateManagedAccountStatus } = props.store
      const { setAcceptedCaseSubmitted } = props.offboarding

      await updateManagedAccountStatus({
        productId: 1,
        managedAccountStatus: true,
        caseId: acceptedCaseId,
      })

      setAcceptedCaseSubmitted(true)
      window.location.reload()
    } catch (err) {
      console.error(err)
    }
  }

  async function handleEnrollPersonalizedTargetDatePlus() {
    try {
      const { acceptedCaseId, updateManagedAccountStatus } = props.store
      const { setAcceptedCaseSubmitted } = props.offboarding

      await updateManagedAccountStatus({
        productId: 5,
        managedAccountStatus: true,
        caseId: acceptedCaseId,
      })

      setAcceptedCaseSubmitted(true)

      props.history.push('/implement/enrollment-confirmed')
    } catch (err) {
      console.error(err)
    }
  }

  function handleCancel() {
    props.history.push('/overall')
  }

  return (
    <div>
      <div
        css={css`
          color: #4a606a;
          font-size: 50px;
          font-weight: 300;
          line-height: 1.35;
        `}>
        Confirm the right service for you
      </div>

      <div
        css={css`
          color: #4a606a;
          font-size: 16px;
          line-height: 1.35;
          margin: 90px 0 45px;
        `}>
        Sometimes quick and easy is best, other times, more is better. Take a look and see what
        suits you best.
      </div>

      <div
        css={css`
          overflow-x: auto;
        `}>
        <TableRow>
          <PrimaryColumn>
            <div
              css={css`
                display: flex;
                align-items: flex-end;
                color: #022a3a;
                font-size: 36px;
                line-height: 1.35;
                margin-right: 1rem;
              `}>
              Compare features
            </div>
          </PrimaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              max-width: 200px;
            `}>
            <div
              css={css`
                color: #022a3a;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.35;
                text-align: center;
                margin: 0 1rem 0.5rem;
              `}>
              GuidedChoice Personalized Target Date+
            </div>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              max-width: 200px;
            `}>
            <div
              css={css`
                color: #022a3a;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.35;
                text-align: center;
                margin: 0 1rem 0.5rem;
              `}>
              GuidedChoice Managed Account
            </div>
          </SecondaryColumn>
        </TableRow>

        <CompareItem
          item='Shows you how much to expect in retirement from your savings'
          keep={<CheckMark />}
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Invests and manages your account for you'
          keep={<CheckMark />}
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Recommends a savings rate based on the income you want during retirement'
          keep={<CheckMark />}
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Allows you to model different scenarios'
          keep={<CheckMark />}
          upgrade={<CheckMark />}
          border
        />

        <CompareItem item='Include a spouse in your planning' upgrade={<CheckMark />} border />

        <CompareItem
          item='Set other goals beyond just retirement income'
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Include other accounts outside of your employer plan(s)'
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Get savings and investment advice on those accounts outside the employer plan(s)'
          upgrade={<CheckMark />}
        />

        <div
          css={css`
            color: #022a3a;
            font-size: 36px;
            line-height: 1.35;
            margin: 50px 0 8px;
          `}>
          Compare fees
        </div>

        <TableRow>
          <PrimaryColumn>
            <div
              css={css`
                color: #022a3a;
                font-size: 16px;
                line-height: 1.35;
                padding: 0.5rem 0;
                margin-right: 1rem;
              `}>
              What do these services cost annually?
            </div>
          </PrimaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              max-width: 200px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: rgba(231, 243, 249, 0.8);
                width: 100%;
                min-height: 50px;
                height: 100%;
                padding: 1rem 0.5rem;
                color: #4a606a;
                font-size: 16px;
                line-height: 1.35;
              `}>
              $1.50 per $1,000 GuidedChoice manages
            </div>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              max-width: 200px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: rgba(79, 173, 204, 0.2);
                width: 100%;
                min-height: 50px;
                height: 100%;
              `}>
              <div
                css={css`
                  padding: 1rem 0.5rem;
                  color: #4a606a;
                  font-size: 16px;
                  line-height: 1.35;
                `}>
                $2.50 per $1,000 GuidedChoice manages
              </div>
              <div
                css={css`
                  padding: 0 0.5rem 1rem;
                  color: #038ab7;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 1.35;
                `}>
                Please note: Once enrolled in a GuidedChoice Managed Account, you cannot return to
                the GuidedChoice Personalized Target Date+.
              </div>
            </div>
          </SecondaryColumn>
        </TableRow>

        <div
          css={css`
            color: #022a3a;
            font-size: 36px;
            line-height: 1.35;
            margin: 50px 0 8px;
          `}>
          Make a selection
        </div>

        <TableRow>
          <PrimaryColumn>
            <div
              css={css`
                color: #022a3a;
                font-size: 16px;
                line-height: 1.35;
                padding: 0.5rem 0;
                margin-right: 1rem;
              `}>
              What happens once you've selected a service
            </div>
          </PrimaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              max-width: 200px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: rgba(231, 243, 249, 0.8);
                width: 100%;
                height: 100%;
                min-height: 50px;
              `}>
              <div
                css={css`
                  padding: 1rem 0.5rem;
                  color: #4a606a;
                  font-size: 16px;
                  line-height: 1.35;
                `}>
                Your enrollment is complete!
              </div>
              <div
                css={css`
                  padding: 0 0.5rem 1rem;
                  color: #4a606a;
                  font-size: 10px;
                  line-height: 1.35;
                `}>
                An email confirmation will be sent to you.
              </div>
            </div>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              max-width: 200px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: rgba(79, 173, 204, 0.2);
                width: 100%;
                min-height: 50px;
                height: 100%;
              `}>
              <div
                css={css`
                  padding: 1rem 0.5rem;
                  color: #4a606a;
                  font-size: 16px;
                  line-height: 1.35;
                `}>
                You will be given access into the Managed Account service where you can enter other
                personal and account information.
              </div>
              <div
                css={css`
                  padding: 0 0.5rem 1rem;
                  color: #4a606a;
                  font-size: 10px;
                  line-height: 1.35;
                `}>
                An email confirmation will be sent to you.
              </div>
            </div>
          </SecondaryColumn>
        </TableRow>

        <TableRow
          css={css`
            margin: 1rem 0 4rem;
          `}>
          <PrimaryColumn
            css={css`
              display: flex;
              align-items: flex-end;
            `}>
            <div>
              <button
                css={css`
                  height: 51px;
                  width: 78px;
                  border: 1px solid #b6c0c4;
                  border-radius: 4px;
                  background-color: #ffffff;
                  color: #4a606a;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 1.35;
                  cursor: pointer;
                `}
                onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </PrimaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              max-width: 200px;
              padding: 0 0.25rem;
              position: relative;
              text-align: center;
            `}>
            <button
              css={css`
                width: 160px;
                height: 98px;
                border: 1px solid #4a606a;
                border-radius: 4px;
                background-color: #4a606a;
                color: #ffffff;
                font-size: 18px;
                line-height: 1.35;
                cursor: pointer;
              `}
              onClick={() => handleEnrollPersonalizedTargetDatePlus()}>
              Enroll: Personalized Target Date+
            </button>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              max-width: 200px;
              padding: 0 0.25rem;
              position: relative;
              text-align: center;
            `}>
            <button
              css={css`
                width: 160px;
                height: 98px;
                border: 1px solid #038ab7;
                border-radius: 4px;
                background-color: #038ab7;
                color: #ffffff;
                font-size: 18px;
                line-height: 1.35;
                cursor: pointer;
                padding: 1px 12px;
              `}
              onClick={() => handleEnrollManagedAccount()}>
              Enroll: Managed Account
            </button>
          </SecondaryColumn>
        </TableRow>
      </div>
    </div>
  )
}

export default inject('store', 'offboarding')(observer(EnrollmentSelection))
