/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css, ClassNames } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import { RadioGroup, RadioButton } from '../../../guided-toolbox'
import styled from '@emotion/styled'
import { Form, Field, useFormState } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-calculate'

import Page from './style'
import {
  SVGWrapper,
  Spacer,
  Button,
  FormError,
  Dropdown as DropdownElement,
} from '../../../components'
import { numberToDollars } from '../../../utils/utils'

import { MoneyBagBlueEmptySVG, LifePreserverSVG } from '../../../assets/icons'

const calculator = createDecorator({
  field: 'startingAge',
  updates: {
    selectedPension: (ignoredValue, allValues) => {
      return allValues.allAvailablePensionOptions[allValues.startingAge]
        ? allValues.allAvailablePensionOptions[allValues.startingAge][0].pensionId.toString()
        : null
    },
  },
})

function EditPensionMarried({
  handleCancel,
  history,
  setStatus,
  store: {
    pensionOptions,
    savePensionOption,
    person,
    spouse,
    selectedInstitutionalPension,
    allPensions,
    processModifiedCase,
  },
  config: { isSpendown },
}) {
  const primaryInstitutionalPension = allPensions.filter(
    pension => pension.id === selectedInstitutionalPension.pensionId
  )[0]

  const handleSave = async values => {
    const pension = pensionOptions[values.startingAge].filter(
      pension => pension.pensionId === parseInt(values.selectedPension)
    )[0]
    try {
      await savePensionOption(pension)
      if (isSpendown) {
        await processModifiedCase()
      }
      handleCancel()
      history.push('/spending')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const onSubmit = values => {
    if (
      pensionOptions[values.startingAge].some(
        pension => pension.pensionId === parseInt(values.selectedPension)
      )
    ) {
      return handleSave(values)
    } else {
      return { [FORM_ERROR]: 'Select the radio button to save your new start age and payment' }
    }
  }

  const ages = Object.keys(pensionOptions)

  const initialValues = {
    startingAge: primaryInstitutionalPension.pensionStartAge + '',
    selectedPension: selectedInstitutionalPension.pensionId + '',
    allAvailablePensionOptions: pensionOptions,
  }

  const validate = values => {
    const errors = {}
    if (values.startingAge === null) {
      errors.startingAge = 'Please select age'
    }

    if (values.selectedPension === null) {
      errors.selectedPension = 'Please select payment option'
    }
    return errors
  }

  return (
    <>
      <Page.Heading>Select a new start age to change the payment option</Page.Heading>
      <Page>
        <Page.Container
          css={css`
            align-self: flex-start;
          `}>
          <Form
            decorators={[calculator]}
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
            subscription={{ submitting: true, submitError: true }}
            render={({ handleSubmit, submitting, submitError }) => (
              <>
                <Page.Text>
                  At what age should {person.displayName} start receiving monthly benefit payments?
                </Page.Text>

                <Field
                  name='startingAge'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => <Dropdown ages={ages} meta={meta} input={input} />}
                />
                <Spacer space='24px' />

                <PaymentOptions spouse={spouse} person={person} pensionOptions={pensionOptions} />

                <Page.ButtonGroup>
                  <div>
                    <Button
                      type='button'
                      onClick={handleCancel}
                      secondary
                      label='Back'
                      disabled={submitting}
                      width='140px'
                    />
                  </div>
                  <div>
                    <Button
                      type='button'
                      onClick={handleSubmit}
                      primary
                      label='Save'
                      disabled={submitting}
                      width='140px'
                    />
                  </div>
                </Page.ButtonGroup>
                {submitError && <FormError err={submitError} />}
              </>
            )}
          />
        </Page.Container>
      </Page>
    </>
  )
}
export default inject('store')(observer(EditPensionMarried))

const PaymentOptions = ({ person, spouse, pensionOptions }) => {
  const {
    values: { startingAge },
  } = useFormState({ subscription: { values: true } })

  return startingAge ? (
    <>
      <Page.Text>Payment options</Page.Text>
      <Label>
        Depending upon the survivor percentage chosen, while {person.displayName} is alive the
        amount in {person.displayName}'s bag is paid monthly
      </Label>
      <Field
        name='selectedPension'
        subscription={{ value: true, touched: true, error: true }}
        render={({ input, meta }) => (
          <>
            <ClassNames>
              {({ css, cx }) => (
                <RadioGroup
                  className={css`
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                  `}
                  value={input.value}
                  onChange={input.onChange}>
                  {pensionOptions[startingAge].map((pension, index) => {
                    return (
                      <RadioButton
                        key={index}
                        value={pension.pensionId + ''}
                        className={css`
                          position: relative;
                          height: 160px;
                        `}>
                        <SVGContainer>
                          <SVGWrapper
                            svg={LifePreserverSVG}
                            fill='#7a8e96'
                            hoverFill='black'
                            activeFill='#b6c0c4'
                            size='custom120'
                            css={css`
                              position: absolute;
                              left: 0px;
                              top: 0px;
                              bottom: 0px;
                              right: 0px;
                            `}
                          />
                          <SVGTextSurvivor
                            css={css`
                              top: 43%;
                            `}>
                            <Percentage>{pension.rate}</Percentage>
                            <Name>survivor</Name>
                          </SVGTextSurvivor>
                          <SVGWrapper
                            svg={MoneyBagBlueEmptySVG}
                            fill='#7a8e96'
                            hoverFill='black'
                            activeFill='#b6c0c4'
                            size='custom120'
                            css={css`
                              position: absolute;
                              left: 60px;
                              top: 42px;
                              bottom: 0px;
                              right: 0px;
                            `}
                          />
                          <SVGText>
                            <Amount>{numberToDollars(pension.ownerPension, true)}</Amount>
                            <Name>{person.displayName}</Name>
                          </SVGText>
                        </SVGContainer>
                      </RadioButton>
                    )
                  })}
                </RadioGroup>
              )}
            </ClassNames>
            {meta.touched && (meta.error || meta.submitError) && (
              <Error>{meta.error || meta.submitError}</Error>
            )}
          </>
        )}
      />
      <Spacer space='8px' />
      <Label>
        If {person.displayName} passes before {spouse.firstName}, the amount in {spouse.firstName}'s
        bag is paid until her death
      </Label>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}>
        {pensionOptions[startingAge].map((pension, index) => {
          return (
            <SVGContainer key={index}>
              <SVGWrapper
                svg={MoneyBagBlueEmptySVG}
                fill='#7a8e96'
                hoverFill='black'
                activeFill='#b6c0c4'
                size='custom120'
                css={css`
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  bottom: 0px;
                  right: 0px;
                `}
              />
              <SVGTextSurvivor>
                <Amount>{numberToDollars(pension.amount, true)}</Amount>
                <Name>{spouse.firstName}</Name>
              </SVGTextSurvivor>
            </SVGContainer>
          )
        })}
      </div>

      <Spacer space='12px' />
    </>
  ) : null
}

const SVGContainer = styled.div`
  position: relative;
  height: 120px;
  width: 120px;
  margin-right: 20px;
`
const SVGText = styled.span`
  position: absolute;
  top: 90%;
  left: 100%;
  transform: translate(-50%, -30%);
`
const SVGTextSurvivor = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
`
const Amount = styled.div`
  text-align: center;
  cursor: pointer;
  &::before {
    content: '$';
    color: #838383;
  }
`
const Percentage = styled.div`
  text-align: center;
  cursor: pointer;
  &::after {
    content: '%';
    color: #838383;
  }
`
const Name = styled.div`
  color: #838383;
  text-align: center;
  cursor: pointer;
`
const Label = styled.div`
  color: #7a8e96;
  font-size: 1rem;
  word-break: break-word;
  padding: 5px 0;
`
const Dropdown = ({ ages, input, meta }) => {
  const ageOptions = () => {
    const arr = []
    ages.map(age => arr.push({ label: age, value: age }))
    return arr
  }

  return (
    <DropdownElement
      error={meta.error}
      name={input.name}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      options={ageOptions()}
      placeholder={input.value}
      selected={input.value}
      showError={meta.touched}
    />
  )
}

const Error = styled.div`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
`
