import { css } from '@emotion/react'
import { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { observer, inject, Provider } from 'mobx-react'
import { types, flow, getEnv } from 'mobx-state-tree'

import { Loading, ErrorPage } from '../../components'
import { TopNav, MainWrapper, MainLayout, MainSection, MainContent } from './components'
import { SideBar } from './components/sidebar'
import { Help, Demo } from './containers'
import Accounts from '../accounts'
import Home from '../home/Home'
import Overall from '../overall-strategy'
import Profile from '../profile'
import Goals from '../goals'
import TransactionConfirmed from '../offboarding/transactions/TransactionConfirmed'
import UpdateContributionsOnly from '../offboarding/transactions/UpdateContributionsOnly'
import SpendDownDashboard from '../spend-down/Dashboard'
import SocialSecurityIncome from '../social-security/SocialSecurityDashboard'

const { model, enumeration } = types

const MainState = model({
  status: enumeration(['loading', 'done', 'error']),
  sidebarActive: true,
  strategyActionQueue: enumeration(['edit', 'compare', '']),
  enableSidebar: true,
  enableTopNav: true,
}).actions(self => ({
  afterCreate: flow(function* () {
    const store = getEnv(self).store
    const {
      features,
      getFeatures,
      getAccount,
      getConfig,
      getCommunicationPreferences,
      getPerson,
      getSpouse,
      getDependents,
      getContactInfo,
      getGoals,
      getAccounts,
      getInstitutionalAccount,
      initializeActiveAccount,
      getAllPensions,
      getUserSettings,
      getPlanOverview,
      getExistingPlaidLinks,
      getSSBenefits,
    } = store

    if (features.enablePlaidLink) {
      try {
        yield getExistingPlaidLinks()
      } catch (err) {
        console.error(err)
      }
    }

    try {
      yield Promise.all([
        getFeatures(),
        getAccount(),
        getConfig(),
        getCommunicationPreferences(),
        getPerson(),
        getSpouse(),
        getDependents(),
        getContactInfo(),
        getGoals(),
        getAccounts(),
        getInstitutionalAccount(),
        initializeActiveAccount(),
        getAllPensions(),
        getUserSettings(),
        getPlanOverview(),
        getSSBenefits(),
      ])

      self.status = 'done'
    } catch (err) {
      console.error(err)
      self.status = 'error'
    }
  }),
  toggleSidebar() {
    self.sidebarActive = !self.sidebarActive
  },
  expandSidebar() {
    self.sidebarActive = true
  },
  setDrawer(value) {
    self.strategyActionQueue = value
  },
  setSidebar(value) {
    self.enableSidebar = value
  },
  setTopNav(value) {
    self.enableTopNav = value
  },
}))

class Main extends Component {
  constructor(props) {
    super(props)
    const { store } = props
    this.state = MainState.create({ status: 'loading', strategyActionQueue: '' }, { store })
  }

  render() {
    const { features } = this.props.store
    const EmptySpan = () => <span />

    if (this.state.status === 'loading') {
      return (
        <div
          css={css`
            padding: 20px;
          `}>
          <Loading />
        </div>
      )
    }

    if (this.state.status === 'error') {
      return <ErrorPage />
    }

    return (
      <Provider main={this.state}>
        <MainWrapper>
          <MainLayout>
            <Switch>
              <Route path='/change-contribution' component={EmptySpan} />
              <Route path='/discontinue' component={EmptySpan} />
              <Route path='/transaction-confirmed' component={EmptySpan} />
              <Route path='/update-contributions-only' component={EmptySpan} />
              <Route component={TopNav} />
            </Switch>
            <MainSection>
              <Switch>
                <Route path='/accounts/add/' component={EmptySpan} />
                <Route path='/accounts/:type/:id/investments' component={EmptySpan} />
                <Route path='/accounts/:type/:id/investments/add-wizard' component={EmptySpan} />
                <Route path='/change-contribution' component={EmptySpan} />
                <Route path='/discontinue' component={EmptySpan} />
                <Route path='/transaction-confirmed' component={EmptySpan} />
                <Route path='/update-contributions-only' component={EmptySpan} />
                {features.showSideBar && <Route component={SideBar} />}
              </Switch>
              <MainContent id='main-app'>
                <Switch>
                  <Route path='/spending' component={SpendDownDashboard} />
                  {features.enableGoals && <Route path='/goals' component={Goals} />}
                  {features.enableAccounts && <Route path='/accounts' component={Accounts} />}
                  <Route path='/overall' component={Overall} />
                  <Route path='/home' component={Home} />
                  {features.enableHelp && <Route path='/help' component={Help} />}
                  <Route path='/profile' component={Profile} />
                  <Route path='/social-security' component={SocialSecurityIncome} />
                  <Route exact path='/transaction-confirmed' component={TransactionConfirmed} />
                  <Route
                    exact
                    path='/update-contributions-only'
                    component={UpdateContributionsOnly}
                  />
                  {features.enablePersonalizedTargetDatePlus ? (
                    <Route path='/' render={() => <Redirect to='/overall' />} />
                  ) : (
                    <Route path='/' render={() => <Redirect to='/home' />} />
                  )}

                  {process.env.REACT_APP_ENABLE_DEMO_ROUTE && (
                    <Route path='/demo' component={Demo} />
                  )}
                </Switch>
              </MainContent>
            </MainSection>
          </MainLayout>
        </MainWrapper>
      </Provider>
    )
  }
}

export default inject('store')(observer(Main))
