/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { ClassNames, css } from '@emotion/react'
import { useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import { RadioGroup, RadioButton } from '../../../guided-toolbox'
import createDecorator from 'final-form-calculate'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { PensionSingleStyle } from './style'

import { Page } from '../elements'
import {
  SVGWrapper,
  Spacer,
  Button,
  FormError,
  Dropdown as DropdownElement,
} from '../../../components'
import { numberToDollars } from '../../../utils/utils'

import { MoneyBagBlueEmptySVG } from '../../../assets/icons'
import { DisclaimerText } from '../../accounts/styled'

function PensionMarried({
  history,
  setStatus,
  pensionOptions,
  store: {
    savePensionOption,
    config: { templateId },
  },
  onboarding: { person },
}) {
  const calculator = useMemo(
    () =>
      createDecorator({
        field: 'startingAge',
        updates: {
          selectedPension: (ignoredValue, allValues) => {
            return allValues.allAvailablePensionOptions[allValues.startingAge]
              ? allValues.allAvailablePensionOptions[allValues.startingAge][0].pensionId.toString()
              : null
          },
        },
      }),
    []
  ) // eslint-disable-line

  const handleSave = async values => {
    const pension = pensionOptions[values.startingAge].filter(
      pension => pension.pensionId === parseInt(values.selectedPension)
    )[0]

    try {
      await savePensionOption(pension)
      setStatus('success')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const onSubmit = values => {
    return handleSave(values)
  }

  const ages = Object.keys(pensionOptions)

  const initialValues = {
    startingAge: null,
    selectedPension: null,
    allAvailablePensionOptions: pensionOptions,
  }

  const validate = values => {
    const errors = {}
    if (values.startingAge === null) {
      errors.startingAge = 'Please select age'
    }

    if (values.selectedPension === null) {
      errors.selectedPension = 'Please select payment option'
    }
    return errors
  }

  return (
    <Page>
      <Page.Heading title='Select pension option' />
      {
        // only show for atmos TEMPORARY
        templateId === 4 && (
          <DisclaimerText style={{ marginTop: '-10px' }}>
            <b>Be aware:</b> Regardless of the payout age selected, the payment shown reflects the
            most recent cash value of your pension balance. This amount will change on an annual
            basis.
          </DisclaimerText>
        )
      }
      <Spacer space='8px' />

      <Page.Container
        css={css`
          align-self: flex-start;
        `}>
        <Form
          initialValues={initialValues}
          decorators={[calculator]}
          validate={validate}
          onSubmit={onSubmit}
          subscription={{ submitting: true, submitError: true, values: true }}
          render={({ handleSubmit, submitting, submitError, values }) => (
            <>
              <Page.Text>
                At what age should {person.displayName} start receiving monthly benefit payments?
              </Page.Text>

              <Field
                name='startingAge'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => <Dropdown ages={ages} meta={meta} input={input} />}
              />
              <Spacer space='24px' />

              {values.startingAge && (
                <>
                  <Page.Text
                    css={css`
                      color: #022a3a;
                    `}>
                    Payment
                  </Page.Text>
                  <Spacer space='5px;' />

                  <PensionSingleStyle.Label>
                    While {person.displayName} is alive, the amount in {person.displayName}'s bag is
                    paid monthly
                  </PensionSingleStyle.Label>
                  <Spacer space='5px;' />

                  <Field
                    name='selectedPension'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <>
                        <ClassNames>
                          {({ css, cx }) => (
                            <RadioGroup
                              className={css`
                                display: flex;
                                flex-wrap: wrap;
                              `}
                              value={input.value}
                              onChange={input.onChange}>
                              {pensionOptions[values.startingAge].map(pension => {
                                return (
                                  <RadioButton
                                    key={pension.pensionId}
                                    value={pension.pensionId + ''}
                                    className={css`
                                      position: relative;
                                      height: 160px;
                                      margin-left: 20px;
                                      margin-right: 30px;
                                    `}>
                                    <PensionSingleStyle.SVGContainer key={pension.pensionId}>
                                      <SVGWrapper
                                        svg={MoneyBagBlueEmptySVG}
                                        fill='#7a8e96'
                                        hoverFill='black'
                                        activeFill='#b6c0c4'
                                        size='custom120'
                                        css={css`
                                          position: absolute;
                                          left: 0px;
                                          top: 0px;
                                          bottom: 0px;
                                          right: 0px;
                                        `}
                                      />
                                      <PensionSingleStyle.SVGTextSurvivor>
                                        <PensionSingleStyle.Amount>
                                          {numberToDollars(pension.ownerPension, true)}
                                        </PensionSingleStyle.Amount>
                                        <PensionSingleStyle.Name>
                                          {person.displayName}
                                        </PensionSingleStyle.Name>
                                      </PensionSingleStyle.SVGTextSurvivor>
                                    </PensionSingleStyle.SVGContainer>
                                  </RadioButton>
                                )
                              })}
                            </RadioGroup>
                          )}
                        </ClassNames>
                        {meta.touched && (meta.error || meta.submitError) && (
                          <PensionSingleStyle.Error>
                            {meta.error || meta.submitError}
                          </PensionSingleStyle.Error>
                        )}
                      </>
                    )}
                  />
                  <Spacer space='8px' />

                  <PensionSingleStyle.Label>
                    Select a different age to see a different payment or select Next to move
                    forward.
                  </PensionSingleStyle.Label>
                  <Spacer space='8px' />
                </>
              )}

              <Page.ButtonGroup>
                <div>
                  <Button
                    type='button'
                    onClick={history.goBack}
                    secondary
                    label='Back'
                    disabled={submitting}
                    width='140px'
                  />
                </div>
                <div>
                  <Button
                    type='button'
                    onClick={handleSubmit}
                    primary
                    label='Next'
                    disabled={submitting}
                    width='140px'
                  />
                </div>
              </Page.ButtonGroup>
              {submitError && <FormError err={submitError} />}
            </>
          )}
        />
      </Page.Container>
    </Page>
  )
}
export default inject('onboarding', 'store')(observer(PensionMarried))

const Dropdown = ({ ages, input, meta }) => {
  const ageOptions = () => {
    const arr = []
    ages.map(age => arr.push({ label: age, value: age }))
    return arr
  }

  return (
    <DropdownElement
      error={meta.error}
      name={input.name}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      options={ageOptions()}
      placeholder=''
      selected={input.value}
      showError={meta.touched}
    />
  )
}
