/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'

import { Card, CardField, CardFieldError, Drawer, Spacer } from '../../../components'
import { numberToDollars } from '../../../utils'
import EditPaycheckContributions from './EditPaycheckContributions'

const EditPaycheckContributionsCard = props => {
  const [editActive, setEditActive] = useState(false)

  const handleEdit = () => setEditActive(true)

  const handleCancel = () => setEditActive(false)

  const {
    store: {
      config: { isSpendown },
      features,
    },
    account: {
      account: {
        contributionEligibility,
        employeeContrib,
        contribMethod,
        pretaxSavingsRate,
        rothContribAllowed,
        rothSavingsRate,
        posttaxContribAllowed,
        posttaxSavingsRate,
        preTaxSavingsAmt,
        rothSavingsAmt,
        postTaxSavingsAmt,
        type,
        rateChangeAllowed,
      },
    },
  } = props

  const canEditPaycheckContributions = type !== 'Institutional' || features.editInstitutionalAccount

  const updateContributionsAllowed =
    type === 'Institutional' && features.enableSavingsRateUpdate && rateChangeAllowed

  const showPreTaxSavingsRate =
    contributionEligibility &&
    employeeContrib &&
    contribMethod === 'P' &&
    pretaxSavingsRate !== null

  const showRothSavingsRate =
    contributionEligibility &&
    employeeContrib &&
    contribMethod === 'P' &&
    rothContribAllowed &&
    rothSavingsRate !== null

  const showAfterTaxSavingsRate =
    contributionEligibility &&
    employeeContrib &&
    contribMethod === 'P' &&
    posttaxContribAllowed &&
    posttaxSavingsRate !== null

  const showPreTaxAmount =
    contributionEligibility && employeeContrib && contribMethod === 'D' && preTaxSavingsAmt !== null

  const showRothAmount =
    contributionEligibility &&
    employeeContrib &&
    contribMethod === 'D' &&
    rothContribAllowed &&
    rothSavingsAmt !== null

  const showAfterTaxAmount =
    contributionEligibility &&
    employeeContrib &&
    contribMethod === 'D' &&
    posttaxContribAllowed &&
    postTaxSavingsAmt !== null

  return (
    <Card
      title='Paycheck contributions'
      handleEdit={canEditPaycheckContributions ? handleEdit : null}>
      {contributionEligibility !== null && (
        <CardField label='Eligible?' value={contributionEligibility ? 'Yes' : 'No'} />
      )}

      {contributionEligibility && (
        <CardField label='Contributing now?' value={employeeContrib ? 'Yes' : 'No'} />
      )}

      <Spacer space='4px' />

      {updateContributionsAllowed && !isSpendown && (
        <div
          css={css`
            margin: 1rem 0;
            text-align: right;
          `}>
          <div
            css={css`
              border-bottom: 1px solid #7a8e96;
              margin-bottom: 8px;
            `}
          />
          <Link
            to='/update-contributions-only'
            css={css`
              font-size: 14px;
              color: #038ab7;
              line-height: 1.35;
            `}>
            Change how much I'm saving only
          </Link>
        </div>
      )}

      {showPreTaxSavingsRate && (
        <CardField label='Pre-tax amount' value={`${numberToDollars(pretaxSavingsRate, true)}%`} />
      )}

      {showRothSavingsRate && (
        <CardField label='Roth amount' value={`${numberToDollars(rothSavingsRate, true)}%`} />
      )}

      {showAfterTaxSavingsRate && (
        <CardField
          label='After-tax amount'
          value={`${numberToDollars(posttaxSavingsRate, true)}%`}
        />
      )}

      {showPreTaxAmount && (
        <CardField
          label='Pre-tax amount'
          value={`$${numberToDollars(preTaxSavingsAmt, true)}/year`}
        />
      )}

      {showRothAmount && (
        <CardField label='Roth amount' value={`$${numberToDollars(rothSavingsAmt, true)}/year`} />
      )}

      {showAfterTaxAmount && (
        <CardField
          label='After-tax amount'
          value={`$${numberToDollars(postTaxSavingsAmt, true)}/year`}
        />
      )}

      {contributionEligibility === null && (
        <CardFieldError>GuidedChoice does not have data on record for this account</CardFieldError>
      )}

      <Drawer
        active={editActive}
        title='Paycheck contributions'
        subtitle='Edit account'
        width='50%'>
        <EditPaycheckContributions handleCancel={handleCancel} />
      </Drawer>
    </Card>
  )
}

export default inject('store', 'account')(observer(EditPaycheckContributionsCard))
