import { css } from '@emotion/react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { withRouter } from 'react-router-dom'
import { inject, observer, Observer } from 'mobx-react'
import _ from 'lodash'

import { Button, Loading } from '../../../components'
import { numberToDollars } from '../../../utils'
import { Layout } from '../elements'
import { Title } from './AccountChangesStyles'
import InvestmentMix from './InvestmentMix'

const SectionChanges = styled.div`
  margin: 24px 0 42px;
`
const HeadingChanges = styled.div`
  font-size: 1.5rem;
  color: #022a3a;
  line-height: 1.35;
`
const ParagraphChanges = styled.div`
  font-size: 1.25rem;
  color: #4a606a;
  line-height: 1.35;
`
const HighlightChanges = styled.div`
  color: ${props => (props.new ? '#038ab7' : '#7A8E96')};
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.35;
`

const ContributionChanges = inject('store')(observer(renderContributionChanges))

function renderContributionChanges(props) {
  const { config, baseCase, acceptedCase, institutional } = props.store

  const contributionDiff = acceptedCase.totalAnnualPercentage - baseCase.totalAnnualPercentage
  const contributionPositive = Math.sign(contributionDiff) >= 0
  const contributionChange = Math.abs(contributionDiff)
  const annualContributionChange = Math.abs(
    acceptedCase.totalAnnualSavings - baseCase.totalAnnualSavings
  )
  const monthlyContributionChange = annualContributionChange / 12

  function acceptedContributions() {
    return (
      <div
        css={css`
          margin: 20px 0;
        `}>
        <div>{institutional.oneContributionOnly ? 'Annual pre-tax total' : 'Annual total'}</div>
        <div>
          {acceptedCase.totalAnnualPercentage}% | $
          {numberToDollars(acceptedCase.totalAnnualSavings, true)}/year
        </div>

        {!institutional.oneContributionOnly && (
          <div
            css={css`
              margin-top: 36px;
            `}>
            <div>
              <div>Includes pre-tax contribution of:</div>
              <div>
                {acceptedCase.primary.preTaxAnnualContributions}% | $
                {numberToDollars(acceptedCase.primary.preTaxAnnualContribDollars, true)}/year
              </div>
            </div>

            {institutional.rothContribAllowed && (
              <div>
                <div>Includes Roth contribution of:</div>
                <div>
                  {acceptedCase.primary.rothAnnualContributions}% | $
                  {numberToDollars(acceptedCase.primary.rothAnnualContribDollars, true)}/year
                </div>
              </div>
            )}

            {institutional.posttaxContribAllowed && (
              <div>
                <div>Includes after-tax contribution of:</div>
                <div>
                  {acceptedCase.primary.postTaxAnnualContributions}% | $
                  {numberToDollars(acceptedCase.primary.postTaxAnnualContribDollars, true)}/year
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <SectionChanges>
      <HeadingChanges>Contributions</HeadingChanges>

      {baseCase.totalAnnualPercentage === acceptedCase.totalAnnualPercentage ? (
        <ParagraphChanges>
          You did not make a change to how much you are saving in your {config.template.sponsorName}{' '}
          retirement plan account.
        </ParagraphChanges>
      ) : (
        <ParagraphChanges>
          You elected to {contributionPositive ? 'increase' : 'decrease'} your annual contribution
          in your {config.template.sponsorName} retirement plan account by{' '}
          {numberToDollars(contributionChange, true)}%—which is approximately $
          {numberToDollars(annualContributionChange, true)} annually or $
          {numberToDollars(monthlyContributionChange, true)} monthly.
        </ParagraphChanges>
      )}

      {baseCase.totalAnnualPercentage === acceptedCase.totalAnnualPercentage ? (
        <HighlightChanges>{acceptedContributions()}</HighlightChanges>
      ) : (
        <HighlightChanges new>{acceptedContributions()}</HighlightChanges>
      )}
    </SectionChanges>
  )
}

const CompanyStockChanges = inject('store')(observer(renderCompanyStockChanges))

function renderCompanyStockChanges(props) {
  const { config, acceptedCase } = props.store

  const companyStocksBeingSold = _.orderBy(
    acceptedCase.companyStocks.filter(stock => stock.percentToSell > 0),
    [stock => stock.percentToSell, stock => stock.securityName.toLowerCase()],
    ['desc', 'asc']
  )

  if (companyStocksBeingSold.length === 0) {
    return null
  }

  return (
    <SectionChanges>
      <HeadingChanges>Company stock in your account</HeadingChanges>
      <ParagraphChanges>
        You have authorized GuidedChoice to sell company stock from your{' '}
        {config.template.sponsorName} retirement plan account, allocating proportionately amongst
        your other account investments the proceeds from the sale. GuidedChoice has sent
        instructions to {config.template.recordKeeperName} to:
      </ParagraphChanges>
      <div
        css={css`
          margin: 24px 0;
        `}>
        {companyStocksBeingSold.map(companyStock => (
          <HighlightChanges new key={companyStock.positionId}>
            Sell {companyStock.percentToSell}% of {companyStock.securityName}
          </HighlightChanges>
        ))}
      </div>
    </SectionChanges>
  )
}

// This page is a review of the previous sections in Offboarding:
// Contribution Changes, Company Stock, and Breakdown
function InstitutionalChanges(props) {
  const [state] = useState('done')

  function handleNext() {
    const {
      history,
      offboarding: { nextRoute },
    } = props
    history.push(`/implement/${nextRoute('account-changes')}`)
  }

  function render() {
    if (state === 'loading') {
      return (
        <div
          css={css`
            padding: 20px;
          `}>
          <Loading />
        </div>
      )
    }

    if (state === 'error') {
      return (
        <div
          css={css`
            padding: 20px;
            color: crimson;
            text-align: center;
          `}>
          Something went wrong with the submission, please try again later
        </div>
      )
    }

    const {
      person: { displayName },
    } = props.store

    return (
      <Layout.Container
        css={css`
          padding: 0 24px;
        `}>
        <Title>Changes to {displayName}'s current employer plan account</Title>

        <ContributionChanges />

        <CompanyStockChanges />

        <InvestmentMix />

        <Layout.ButtonGroup
          css={css`
            margin: 50px 0;
          `}>
          <div />
          <div>
            <Button
              type='button'
              onClick={handleNext}
              primary
              label='Next'
              disabled={false}
              width='124px'
            />
          </div>
        </Layout.ButtonGroup>
      </Layout.Container>
    )
  }

  return <Observer>{render}</Observer>
}

export default withRouter(inject('store', 'offboarding')(InstitutionalChanges))
