import { Component } from 'react'
import { observer } from 'mobx-react'
import { PercentInput, CurrencyInput, TextErrorField } from './index'
import PropTypes from 'prop-types'

class PercentOrDollarInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func,
    error: PropTypes.string,
    showError: PropTypes.bool,
    decimalScale: PropTypes.number,
    readonly: PropTypes.bool,
  }

  handlePercentChange = (name, value) => {
    this.props.onChange(name, value)
  }

  handleDollarChange = (name, value) => {
    this.handlePercentChange(name, value * 100)
  }

  render() {
    const { name, value, onBlur, error, showError, decimalScale, readonly } = this.props
    return [
      <PercentInput
        key='%'
        name={name}
        onChange={this.handlePercentChange}
        value={value}
        width='115px'
        onBlur={onBlur}
        readonly={readonly}
      />,
      '\u00A0\u00A0\u00A0\u00A0',
      'or',
      '\u00A0\u00A0\u00A0\u00A0',
      <CurrencyInput
        key='$'
        name={name}
        onChange={this.handleDollarChange}
        value={value / 100}
        width='115px'
        onBlur={onBlur}
        decimalScale={decimalScale}
        readonly={readonly}
      />,
      <TextErrorField key='e' error={error} showError={showError} />,
    ]
  }
}

export default observer(PercentOrDollarInput)
