const SvgComponent = props => (
  <svg viewBox='0 0 300 300' {...props}>
    <g fill='none' strokeMiterlimit={10}>
      <path
        stroke='#0d8bb8'
        d='M115.6 180.8h67.3m-67.3-29.9h7.4v23.6h-7.4zm59.9-12.2h7.4v35.9h-7.4zm-39.9-.5h7.4v36.3h-7.4zm19.9 11.4h7.4v25h-7.4z'
      />
      <circle cx={159.2} cy={137.6} r={3.1} stroke='#90be3f' />
      <path
        d='M122.5 139c0-1.7-1.4-3.1-3.1-3.1-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1 1.6 0 3.1-1.4 3.1-3.1zm-.8-2.1l13.1-7.9m9.3-.7l12 7.5m5.9.4l12.5-7.2'
        stroke='#90be3f'
      />
      <ellipse
        transform='rotate(-67.623 139.273 126.286)'
        cx={139.3}
        cy={126.3}
        rx={5.2}
        ry={5.2}
        stroke='#90be3f'
      />
      <ellipse
        transform='rotate(-67.623 178.591 126.286)'
        cx={178.6}
        cy={126.3}
        rx={5.2}
        ry={5.2}
        stroke='#90be3f'
      />
    </g>
    <path
      d='M149.9 86.6V51.2c-24.2 0-45.5 7.7-64 23.3l22.7 27.1c11.1-9.4 25.5-15 41.3-15z'
      fill='#fff8f1'
    />
    <path
      d='M85.7 150.7c0-19.7 8.9-37.3 22.9-49.1l-22.8-27C55.5 100 43.4 138.4 53.6 176.7l34.3-9.2c-1.4-5.4-2.2-11-2.2-16.8z'
      fill='#dad9d9'
    />
    <path
      d='M149.9 214.9c-29.7 0-54.6-20.1-62-47.4l-34.3 9.2c4 14.9 10 26.5 19.9 38.3 35.4 42.1 98.2 47.6 140.4 12.3L191.1 200c-11.2 9.3-25.5 14.9-41.2 14.9z'
      opacity={0.22}
      fill='#726fb1'
    />
    <path
      d='M226.2 86.8l-27.1 22.7c9.3 11.1 15 25.5 15 41.2 0 19.8-8.9 37.5-23 49.2l22.8 27.2c42.2-35.3 47.7-98.1 12.3-140.3z'
      fill='#e8f4f9'
    />
    <path
      d='M149.9 86.6c19.8 0 37.4 8.9 49.2 23l27.1-22.7c-19.8-23.6-45.5-35.6-76.3-35.6v35.3z'
      fill='#f2f9f2'
    />
  </svg>
)

export default SvgComponent
