import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'
import { numberToDollars } from '../../../utils/utils'

function WithdrawFromAnnuities(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
      investmentWithdrawals,
      isWithdrawingFromCompanyStockForAnnuity,
    },
    store: { person },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'withdrawFromAnnuities' })
    willMount.current = false
  }

  const taxType = type => {
    switch (type) {
      case 1:
        return '—Pre-tax investments'
      case 2:
        return '—After-tax investments'
      case 5:
        return '—Roth investments'
      default:
        return ''
    }
  }

  const page = findByName('withdrawFromAnnuities')
  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.PrimaryAnnuityPageTitle>
          How much to convert/withdraw for your annuity purchase
        </Wrapper.PrimaryAnnuityPageTitle>
        <Wrapper.PrimaryAnnuityFromWhichAccounts>
          From which accounts
        </Wrapper.PrimaryAnnuityFromWhichAccounts>
        <Spacer space='10pt' />
        <Wrapper.PrimaryAnnuityDisclaimer>
          Below are the possible accounts to sell in order to convert/withdraw the amount that you
          need to purchase your{' '}
          {!_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) &&
          person.includeSpouse &&
          !_.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome)
            ? 'annuities'
            : 'annuity'}
          .
        </Wrapper.PrimaryAnnuityDisclaimer>
        <Spacer space='10pt' />

        {isWithdrawingFromCompanyStockForAnnuity && (
          <Wrapper.PrimaryAnnuityDisclaimer>
            Important: Your withdrawal includes company stock. Please be sure to read the notice
            regarding the use of company stock for expenses located in The Fine Print area towards
            the end of this guide.
          </Wrapper.PrimaryAnnuityDisclaimer>
        )}
        {/* Primary Accounts */}
        {_.get(
          investmentWithdrawals,
          'primaryAnnuitiesWithdrawalAdvice.investmentAccountWithdrawals',
          []
        ).map(account => {
          return (
            <div key={account.planName + account.totalAmount}>
              <Wrapper.PrimaryAnnuityFlexSpaceBetween>
                <Wrapper.PrimaryAnnuityFundsNeededSellFromTitle>
                  {account.planName}
                  {taxType(_.get(account, 'taxType', 0))}
                </Wrapper.PrimaryAnnuityFundsNeededSellFromTitle>
                <Wrapper.PrimaryAnnuityFundsNeededSellFromTypeAmount dark>
                  {numberToDollars(account.totalAmount, true)}
                </Wrapper.PrimaryAnnuityFundsNeededSellFromTypeAmount>
              </Wrapper.PrimaryAnnuityFlexSpaceBetween>
              <Spacer space='10pt' />
            </div>
          )
        })}

        {/* Spouse Accounts */}
        {_.get(
          investmentWithdrawals,
          'spouseAnnuitiesWithdrawalAdvice.investmentAccountWithdrawals',
          []
        ).map(account => {
          return (
            <div key={account.portfolioName + account.totalAmount}>
              <Wrapper.PrimaryAnnuityFundsNeededSellFromTitle>
                {account.portfolioName}
                {taxType(_.get(account, 'taxType', 0))}
              </Wrapper.PrimaryAnnuityFundsNeededSellFromTitle>
              {account.investments.map(investment => (
                <Wrapper.PrimaryAnnuityFlexSpaceBetween key={investment.name}>
                  <Wrapper.PrimaryAnnuityFundsNeededSellFromType>
                    {investment.name}
                  </Wrapper.PrimaryAnnuityFundsNeededSellFromType>
                  <Wrapper.PrimaryAnnuityFundsNeededSellFromTypeAmount>
                    {numberToDollars(investment.amount, true)}
                  </Wrapper.PrimaryAnnuityFundsNeededSellFromTypeAmount>
                </Wrapper.PrimaryAnnuityFlexSpaceBetween>
              ))}
              <Spacer space='10pt' />
            </div>
          )
        })}

        <Wrapper.PrimaryAnnuityFlexSpaceBetween>
          <Wrapper.PrimaryAnnuityFundsNeededSellFromGrandTotal>
            Grand total from all accounts
          </Wrapper.PrimaryAnnuityFundsNeededSellFromGrandTotal>
          <Wrapper.PrimaryAnnuityFundsNeededSellFromGrandTotalAmount>
            {numberToDollars(
              _.get(investmentWithdrawals, 'primaryAnnuitiesWithdrawalAdvice.grandTotal', 0) +
                _.get(investmentWithdrawals, 'spouseAnnuitiesWithdrawalAdvice.grandTotal', 0),
              true
            )}
          </Wrapper.PrimaryAnnuityFundsNeededSellFromGrandTotalAmount>
        </Wrapper.PrimaryAnnuityFlexSpaceBetween>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(WithdrawFromAnnuities))
