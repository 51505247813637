/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useEffect, useCallback, useState } from 'react'
import { inject, observer, Provider } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import dayjs from 'dayjs'
import _ from 'lodash'

import { API } from '../../../api'
import {
  Button,
  ButtonsBox,
  PageTitle,
  Spacer,
  Loading,
  MultiButtonInput,
  Label,
  HelpIcon,
  TooltipText,
} from '../../../components'
import { TAX_TYPES } from '../../../constants'
import { reduceValidationError } from '../../../utils'
import SecuritySelector from './components/SecuritySelector'
import { AddInvestmentContainer } from '../styled'
import AddNewInvestmentState from './AddNewInvestmentState'
import PreTaxForm, { preTaxSchema } from './forms/PreTaxForm'
import RothForm, { rothSchema } from './forms/RothForm'
import AfterTaxForm, { afterTaxSchema } from './forms/AfterTaxForm'
import { initializeValues, typeToInvestmentsAPI } from './investmentUtils'
import { ButtonGroup, CancelButton, SecondaryButton, PrimaryButton } from '../elements/SaveButtons'

const AddNewInvestment = props => {
  const { store, account, location } = props // provides account, investments, and control from AccountStore

  const [state] = useState(() =>
    AddNewInvestmentState.create(
      { status: 'loading', securitySearchStatus: 'done' },
      { store, account }
    )
  )

  const fetchInitialState = useCallback(
    async ({ type, id }) => {
      try {
        state.setStatus('loading')
        const { getAccount, getInvestments } = props.account

        if (type === 'institutional') {
          const { getInstitutionalAccount } = props.store
          await getInstitutionalAccount()
        } else {
          await getAccount({ type, id })
          await getInvestments({ type, id })
        }

        state.setStatus('done')
      } catch (err) {
        console.error(err)
        state.setStatus('error')
      }
    },
    [props, state]
  )

  useEffect(() => {
    if (!_.isEmpty(location?.state?.security)) {
      state.setSecurity(location?.state?.security)
    }
    if (props.account.account === null) {
      const { type, id } = props.match.params
      fetchInitialState({ type, id })
    } else {
      state.setStatus('done')
    }
  }, [
    fetchInitialState,
    location?.state?.security,
    props.account.account,
    props.match.params,
    state,
  ])

  const handleCancel = () => {
    props.history.goBack()
  }

  const handleSave = async ({ addBalance, preTax, roth, afterTax, resetForm }, form) => {
    try {
      const { type, id } = props.match.params
      const apiURL = typeToInvestmentsAPI({ type, id })

      // if addBalance is false, it means add the investment of the Not Invested type
      if (addBalance === false) {
        const { id: securityId, securityType, securityName: tickerName } = state.security
        const {
          account: { participantId },
        } = props.account

        await API.post(apiURL, {
          personId: participantId,
          securities: { id: securityId },
          securityType,
          tickerName,
          taxType: TAX_TYPES.notInvested,
        })
      }

      // if addBalance is true, determine preTax, roth, or afterTax forms to submit
      if (addBalance === true && preTax) {
        const {
          account: { participantId },
        } = props.account

        const security = getSnapshot(state.security)
        const { securityType, securityName: tickerName } = security
        const { purchaseDate, quantity, totalCostBasis, totalValue, valuationMethod } = preTax

        await API.post(apiURL, {
          dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
          personId: participantId,
          securities: getSnapshot(state.security),
          securityType,
          taxType: TAX_TYPES.preTax,
          tickerName,
          purchaseDate,
          quantity,
          totalCostBasis: totalCostBasis || 0,
          totalValue,
          valuationMethod,
        })
      }

      if (addBalance === true && roth) {
        const {
          account: { participantId },
        } = props.account

        const security = getSnapshot(state.security)
        const { securityType, securityName: tickerName } = security
        const { purchaseDate, quantity, totalCostBasis, totalValue, valuationMethod } = roth

        await API.post(apiURL, {
          dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
          personId: participantId,
          securities: getSnapshot(state.security),
          securityType,
          taxType: TAX_TYPES.roth,
          tickerName,
          purchaseDate,
          quantity,
          totalCostBasis: totalCostBasis || 0,
          totalValue,
          valuationMethod,
        })
      }

      if (addBalance === true && afterTax) {
        const {
          account: { participantId },
        } = props.account

        const security = getSnapshot(state.security)
        const { securityType, securityName: tickerName } = security
        const { purchaseDate, quantity, totalCostBasis, totalValue, valuationMethod } = afterTax

        await API.post(apiURL, {
          dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
          personId: participantId,
          securities: getSnapshot(state.security),
          securityType,
          taxType: TAX_TYPES.afterTax,
          tickerName,
          purchaseDate,
          quantity,
          totalCostBasis: totalCostBasis || 0,
          totalValue,
          valuationMethod,
        })
      }

      // The following case should only run when investments already added and available for edit
      // if (addBalance === true && !preTax && !roth && !afterTax) {
      //   console.log('At least one Balance Form is required')
      // }

      await props.store.getAccounts()

      if (resetForm) {
        const { type, id } = props.match.params
        state.setSecurity(null)
        await fetchInitialState({ type, id })
      } else {
        handleCancel()
      }
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const validate = async values => {
    const preTax = values.preTax
      ? await preTaxSchema
          .validate(values.preTax, { abortEarly: false })
          .then(valid => {})
          .catch(err => reduceValidationError(err))
      : undefined

    const roth = values.roth
      ? await rothSchema
          .validate(values.roth, { abortEarly: false })
          .then(valid => {})
          .catch(err => reduceValidationError(err))
      : undefined

    const afterTax = values.afterTax
      ? await afterTaxSchema
          .validate(values.afterTax, { abortEarly: false })
          .then(valid => {})
          .catch(err => reduceValidationError(err))
      : undefined

    return _.pickBy({ preTax, roth, afterTax }, _.identity)
  }

  const initialValues = () => {
    const { accountType } = props.match.params
    const { price, securityType } = state.security
    const {
      showAddBalanceButton,
      showPreTaxForm,
      showRothForm,
      showAfterTaxForm,
      notInvestedAlreadyExistsForSecurity,
    } = state

    return initializeValues({
      accountType,
      price,
      securityType,
      showAddBalanceButton,
      showPreTaxForm,
      showRothForm,
      showAfterTaxForm,
      notInvestedAlreadyExistsForSecurity,
    })
  }

  if (state.status === 'loading') {
    return <Loading />
  }

  if (state.status === 'error') {
    return (
      <div
        css={css`
          color: crimson;
        `}>
        Something went wrong, please try again later.
      </div>
    )
  }

  const {
    account: { type },
  } = props.account
  const { security, showPreTaxForm, showRothForm, showAfterTaxForm } = state

  return (
    <Provider newInvestment={state}>
      <div
        css={css`
          max-width: 1200px;
          margin: auto;
          padding: 1rem;
        `}>
        <AddInvestmentContainer>
          <PageTitle>Let's add an investment</PageTitle>

          {/* Investment Type and Security Selection */}
          <SecuritySelector security={state?.security || null} />

          <Spacer space='20px' />

          {/* Forms go here */}
          {state.security && (
            <Form
              onSubmit={handleSave}
              validate={validate}
              initialValues={initialValues()}
              subscription={{ submitting: true, submitError: true, invalid: true, values: true }}
              render={({ form, handleSubmit, submitting, submitError, invalid, values }) => (
                <div>
                  {/* Ask Add Balance or Not Invested here */}
                  {values.showAddBalanceButton && (
                    <div>
                      <Label isDrawer>
                        Value <HelpIcon tooltip={TooltipText.investmentValue()} />
                      </Label>
                      <Field
                        name='addBalance'
                        render={({ input, meta }) => (
                          <MultiButtonInput
                            buttons={[
                              { text: 'Add Balance', value: true },
                              { text: 'Not Invested', value: false },
                            ]}
                            margin='10px'
                            name='addBalance'
                            onChange={(name, value) => {
                              if (!value) {
                                form.reset({
                                  ...values,
                                  preTax: null,
                                  roth: null,
                                  afterTax: null,
                                })
                              }
                              input.onChange(value)
                            }}
                            value={input.value}
                          />
                        )}
                      />
                    </div>
                  )}

                  {showPreTaxForm && values.addBalance && (
                    <PreTaxForm
                      security={security}
                      type={type}
                      hideNotInvested
                      allowRemoval={showRothForm || showAfterTaxForm}
                    />
                  )}

                  {showRothForm && values.addBalance && (
                    <RothForm security={security} type={type} hideNotInvested allowRemoval />
                  )}

                  {showAfterTaxForm && values.addBalance && (
                    <AfterTaxForm
                      security={security}
                      type={type}
                      hideNotInvested
                      allowRemoval={type !== 'Personal'}
                    />
                  )}

                  {values.addBalance === true &&
                    !showPreTaxForm &&
                    !showRothForm &&
                    !showAfterTaxForm && (
                      <div
                        css={css`
                          color: #e31e27;
                          font-size: 1rem;
                        `}>
                        Investment already added and available for edit
                      </div>
                    )}

                  <ButtonGroup>
                    <CancelButton
                      primaryText='Cancel'
                      onClick={handleCancel}
                      disabled={submitting}
                    />
                    {/* Show Save Button when a Security is selected and a Form (Add Balance or Not Invested) is visible */}
                    <div
                      css={css`
                        display: flex;
                        @media (max-width: 800px) {
                          flex-wrap: wrap;
                          flex-direction: column-reverse;
                        }
                      `}>
                      <SecondaryButton
                        primaryText='Save'
                        secondaryText='return to summary'
                        onClick={() => {
                          form.change('resetForm', false)
                          handleSubmit()
                        }}
                        disabled={
                          submitting ||
                          !(
                            values.addBalance === false ||
                            (showPreTaxForm && values.addBalance) ||
                            (showRothForm && values.addBalance) ||
                            (showAfterTaxForm && values.addBalance)
                          )
                        }
                      />
                      <PrimaryButton
                        primaryText='Save +'
                        secondaryText='add another investment'
                        onClick={() => {
                          form.change('resetForm', true)
                          handleSubmit()
                        }}
                        disabled={
                          submitting ||
                          !(
                            values.addBalance === false ||
                            (showPreTaxForm && values.addBalance) ||
                            (showRothForm && values.addBalance) ||
                            (showAfterTaxForm && values.addBalance)
                          )
                        }
                      />
                    </div>
                  </ButtonGroup>
                </div>
              )}
            />
          )}

          {/* Provide a Cancel button when a security hasn't been selected yet */}
          {!state.security && (
            <ButtonsBox>
              <Button label='Cancel' onClick={handleCancel} secondary width='85px' />
            </ButtonsBox>
          )}
        </AddInvestmentContainer>
      </div>
    </Provider>
  )
}

export default inject('store', 'account')(observer(AddNewInvestment))
