import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const DividerLine = styled.hr`
  margin-left: 15px;
  margin-right: 15px;
  flex-grow: 1;
`
const DividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: #9eacb0;
`

export default class LabeledDivider extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
  }

  render() {
    const { label } = this.props

    return (
      <DividerWrapper>
        <DividerLine />
        {label}
        <DividerLine />
      </DividerWrapper>
    )
  }
}
