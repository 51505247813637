import { useState, useEffect, useRef } from 'react'
import { observer, inject } from 'mobx-react'
import PensionSingle from './PensionSingle'
import PensionMarried from './PensionMarried'
import Success from './Success'
import WelcomeSpendingMode from './WelcomeSpendingMode'
import _ from 'lodash'

function Dashboard(props) {
  const {
    history,
    store: { getSurvivorPensionOptions },
    onboarding: {
      person: { maritalStatus, includeSpouse },
      config: { isRetail, isSpendown },
    },
  } = props
  const [status, setStatus] = useState('single')
  const [pensionOptions, setPensionOptions] = useState({})
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    const fetchData = async () => {
      try {
        const result = await getSurvivorPensionOptions()
        if (_.isEmpty(result)) {
          setStatus('success')
          return null
        }
        setPensionOptions(result)
      } catch (err) {
        console.error(err)
      }
    }
    if (isMounted.current) {
      fetchData()
    }

    return () => {
      isMounted.current = false
    }
  }, [getSurvivorPensionOptions])

  if (status === 'success' && _.get(history, 'location.state.from', null) === '/home') {
    return history.push('/spending')
  }

  if (status === 'success') {
    if (isRetail && isSpendown) {
      return <WelcomeSpendingMode props={props}></WelcomeSpendingMode>
    }
    return <Success history={history} props={props} />
  }

  return maritalStatus && includeSpouse ? (
    <PensionMarried pensionOptions={pensionOptions} setStatus={setStatus} history={history} />
  ) : (
    <PensionSingle pensionOptions={pensionOptions} setStatus={setStatus} history={history} />
  )
}

export default inject('onboarding', 'store')(observer(Dashboard))
