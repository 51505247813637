import { createElement } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Portal from './Portal'
import ActivableRenderer from './ActivableRenderer'
import InjectOverlay from './Overlay'

const drawerBorderColor = 'rgb(224, 224, 224)'

const animationDuration = '0.35s'

const animationCurve = 'cubic-bezier(0.4, 0, 0.2, 1)'

const activeStyle = p =>
  p.active &&
  css`
    pointer-events: all;
    transform: translateX(0);
    transition-delay: 0.07s;
  `

const rightStyle = p =>
  p.type === 'right' &&
  css`
    border-left: 1px solid ${drawerBorderColor};
    right: 0;
  `

const rightInactiveStyle = p =>
  p.type === 'right' &&
  !p.active &&
  css`
    transform: translateX(100%);
  `

const leftStyle = p =>
  p.type === 'left' &&
  css`
    border-right: 1px solid ${drawerBorderColor};
    left: 0;
  `

const leftInactiveStyle = p =>
  p.type === 'left' &&
  !p.active &&
  css`
    transform: translateX(-100%);
  `

const DrawerView = styled.aside`
  background-color: ${p => p.theme.editDrawerBgColor};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: ${p => p.theme.editDrawerTextColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform-style: preserve-3d;
  transition: transform ${animationDuration} ${animationCurve};
  transition-delay: 0s;
  width: ${p => (p.width ? p.width : '40%')};
  max-width: ${p => p.maxWidth || 'none'};
  min-width: 300px;
  will-change: transform;
  padding: ${p => (p.nopad ? '0px' : '1.25rem')};
  ${activeStyle};
  ${rightStyle};
  ${rightInactiveStyle};
  ${leftStyle};
  ${leftInactiveStyle};
  ${p => p.fullMobile && '@media (max-width: 800px) { width: 100%; }'};
`

const Subtitle = styled.div`
  flex: 0 0 auto;
  font-size: 0.875rem;
  line-height: 1.5;
  color: ${p => p.theme.lightestGray};
  padding-left: 3px;
  word-break: break-word;
`
const Title = styled.div`
  flex: 0 0 auto;
  color: ${p => p.theme.mediumGray};
  font-size: 2.5rem;
  line-height: 1.35;
  font-weight: 300;
  padding-bottom: 20px;
  word-break: break-word;
`
const BodyGroup = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Children = styled.div`
  flex: 1 0 auto;
`

const factory = Overlay => {
  const Drawer = ({
    active,
    children,
    className,
    insideTree,
    onOverlayClick,
    onEscKeyDown,
    theme,
    type,
    withOverlay,
    maxWidth,
    subtitle,
    title,
    width,
    nopad,
    fullMobile,
  }) => {
    const content = (
      <DrawerView
        maxWidth={maxWidth}
        width={width}
        type={type}
        active={active}
        nopad={nopad}
        fullMobile={fullMobile}>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {title && <Title>{title}</Title>}
        <BodyGroup id='drawer'>
          <Children>{children}</Children>
        </BodyGroup>
      </DrawerView>
    )

    return createElement(
      insideTree ? 'div' : Portal,
      null,
      withOverlay && (
        <Overlay
          active={active}
          onClick={onOverlayClick}
          onEscKeyDown={onEscKeyDown}
          theme={theme}
          themeNamespace='overlay'
        />
      ),
      content
    )
  }

  Drawer.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    insideTree: PropTypes.bool,
    onEscKeyDown: PropTypes.func,
    onOverlayClick: PropTypes.func,
    theme: PropTypes.shape({
      active: PropTypes.string,
      drawer: PropTypes.string,
      left: PropTypes.string,
      right: PropTypes.string,
    }),
    type: PropTypes.oneOf(['left', 'right']),
    withOverlay: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.string,
    maxWidth: PropTypes.string,
  }

  Drawer.defaultProps = {
    active: false,
    className: '',
    insideTree: false,
    type: 'right',
    withOverlay: true,
  }

  return ActivableRenderer()(Drawer)
}

const Drawer = observer(factory(InjectOverlay))

export default Drawer
