import { css } from '@emotion/react'
import { isValidElement, Component } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import {
  NavLink,
  PrimaryLink,
  SecondaryLink,
  AnswerSection,
  QuestionTitle,
  content,
} from '../../../constants/help-content'

class Help extends Component {
  scrollToHash = () => {
    const { hash } = this.props.location
    const id = hash.replace(/^#/, '')
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
    }
  }

  componentDidMount() {
    if (this.props.location.hash) {
      this.scrollToHash()
    }
  }

  componentDidUpdate() {
    if (this.props.location.hash) {
      this.scrollToHash()
    }
  }

  render() {
    const secretUnretireLink = true

    const handleUnretire = async () => {
      await this.props.store.config.disableSpendingMode()
      window.location.reload()
    }

    return (
      <div
        css={css`
          line-height: 1.5;
          max-width: 1100px;
          margin: auto;
        `}>
        {content.map(main => (
          <NavLink key={main.id}>
            <a href={'#' + main.id}>{main.title}</a>
          </NavLink>
        ))}
        <div
          css={css`
            padding-top: 50px;
          `}
        />
        {content.map(main => {
          return (
            <div id={main.id} key={main.id}>
              <PrimaryLink>
                <a href={'#' + main.id}>{main.title}</a>
              </PrimaryLink>
              {main.fields.map(
                field =>
                  (field.link && (
                    <SecondaryLink key={'#' + field.id}>
                      <a href={'#' + field.id}>{field.title}</a>
                    </SecondaryLink>
                  )) ||
                  null
              )}
              {main.fields.map(field => (
                <AnswerSection id={field.id} key={field.id}>
                  <QuestionTitle>{field.title}</QuestionTitle>
                  {field.answer.map(answer => {
                    if (typeof answer === 'string') {
                      return <div key={_.uniqueId('answer')}>{answer}</div>
                    }
                    if (isValidElement(answer)) {
                      return <div key={_.uniqueId('answer')}>{answer}</div>
                    }
                    return null
                  })}
                </AnswerSection>
              ))}
            </div>
          )
        })}
        {secretUnretireLink && (
          <div
            css={css`
              width: 30px;
              height: 30px;
              opacity: 0;
              position: fixed;
              bottom: 0;
              right: 0;
              cursor: default;
            `}
            onClick={handleUnretire}>
            Unretire
          </div>
        )}
      </div>
    )
  }
}

export default inject('store')(observer(Help))
