import { Component } from 'react'
import { Provider, inject, observer } from 'mobx-react'
import { Route, Switch } from 'react-router-dom'

import AccountStore from './AccountStore'
import Dashboard from './dashboard/Dashboard'
import Overview from './overview/Overview'
import UpdateBalances from './update-balances/UpdateBalances'
import AddAccount from './AddAccount'
import AddIntro from './intro/AddIntro'
import AddBasics from './basics/AddBasics'
import AddContributions from './contributions/AddContributions'
import AddPaycheckContributions from './contributions/AddPaycheckContributions'
import AddEmployerContributions from './contributions/AddEmployerContributions'
import AddPayout from './payout/AddPayout'
import AddInvestmentsIntro from './investments/AddInvestmentsIntro'
import AddInvestments from './investments/AddInvestments'
import AddNewInvestment from './investments/AddNewInvestment'
import AddAnnuity from './annuity/AddAnnuity'
import StockOptions from './stock-options/StockOptions'
import DuplicateAccount from './duplicate-account/DuplicateAccount'

class Accounts extends Component {
  constructor(props) {
    super(props)
    const { store } = props
    this.state = AccountStore.create({ account: null }, { store })
  }

  render() {
    return (
      <Provider account={this.state}>
        <Switch>
          <Route path='/accounts/add/:type/:accountType/intro' component={AddIntro} />
          <Route
            path='/accounts/add/:type/:accountType/duplicate-account'
            component={DuplicateAccount}
          />
          <Route path='/accounts/add/:type/:accountType/basics' component={AddBasics} />
          <Route
            path='/accounts/add/:type/:accountType/:id/contributions'
            component={AddContributions}
          />
          <Route
            path='/accounts/add/:type/:accountType/:id/paycheck-contributions'
            component={AddPaycheckContributions}
          />
          <Route
            path='/accounts/add/:type/:accountType/:id/employer-contributions'
            component={AddEmployerContributions}
          />
          <Route path='/accounts/add/:type/:accountType/payout' component={AddPayout} />
          <Route
            path='/accounts/add/:type/:accountType/:id/investments-intro'
            component={AddInvestmentsIntro}
          />
          <Route path='/accounts/add/:type/:accountType/:id/annuity' component={AddAnnuity} />
          <Route
            path='/accounts/add/:type/:accountType/:id/investments'
            component={AddInvestments}
          />
          <Route
            path='/accounts/add/:type/:accountType/:id/investment'
            component={AddNewInvestment}
          />
          <Route path='/accounts/add' component={AddAccount} />
          <Route
            path='/accounts/:type/:accountType/:id/update-balances'
            component={UpdateBalances}
          />
          <Route path='/accounts/:type/:accountType/:id' component={Overview} />
          <Route path='/accounts/stock-options' component={StockOptions} />
          <Route path='/accounts' component={Dashboard} />
        </Switch>
      </Provider>
    )
  }
}

export default inject('store')(observer(Accounts))
