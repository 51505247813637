/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import * as yup from 'yup'
import _ from 'lodash'

import { TextInput } from '../../../components'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import Page from './style'
import CardField from '../../../components/CardField'
import { LOGIN_URL, LOGOUT } from '../../../api/instances'

function EditEmail({ handleCancel, store: { changeUsername, getLoginEmail } }) {
  const [status, setStatus] = useState('idle')
  const [newEmail, setNewEmail] = useState('')

  const onSubmit = values => {
    return handleChangeUsername(values)
  }

  const handleChangeUsername = async values => {
    try {
      await changeUsername(values)
      setNewEmail(values.email)
      setStatus('success')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const backToLogin = () => {
    LOGOUT.get('logout')
    window.open(LOGIN_URL, '_self')
  }

  return status === 'success' ? (
    <div>
      <Label>
        An email with further instructions has been sent successfully to {newEmail}.
        <br />
        <br />
        Do not close this page until you login again from that email and receive notification at
        that point that your new login email is in effect.
      </Label>
      <div
        css={css`
          text-align: right;
          margin: 40px 0 60px;
        `}>
        <NextButton onClick={backToLogin}>Log off</NextButton>
      </div>
    </div>
  ) : (
    <Form
      onSubmit={onSubmit}
      subscription={{ submitting: true, submitError: true }}
      render={({ handleSubmit, submitting }) => (
        <div>
          <Page.Header>
            Change login email
            <div>
              When you click save, you will have to authenticate your new email address. This
              process will include requiring you to log back in.
            </div>
          </Page.Header>

          <FieldContainer>
            <CardField
              label='New login email'
              value={
                <Value>
                  <NewEmailField />
                </Value>
              }
            />
          </FieldContainer>

          <ButtonsBox>
            <BackButton backgroundColor='#FFFFFF' onClick={handleCancel}>
              Cancel
            </BackButton>
            <NextButton onClick={handleSubmit} disabled={submitting}>
              Save
            </NextButton>
          </ButtonsBox>
        </div>
      )}
    />
  )
}

export default inject('store')(observer(EditEmail))

const Value = styled.div`
  color: #051f2c;
  font-size: 1rem;
  word-break: break-word;
`

const Label = styled.div`
  color: #7a8e96;
  font-size: 1rem;
  word-break: break-word;
`

const FieldContainer = styled.div`
  margin-left: 125px;
  padding-top: 20px;
`

function NewEmailField() {
  const { input, meta } = useField('email', {
    validate: value =>
      yup
        .string()
        .email()
        .required('Email is required')
        .max(128, 'Maximum character limit 128 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='email'
      error={meta.error}
      type='text'
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      showError={meta.touched}
      value={input.value}
      width='220px'
      fontSize='16px'
    />
  )
}
