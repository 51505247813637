const BulletStatistics = `
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"/>
            <path d="M10 10h30v30H10z"/>
            <g fill="#02769D" fill-rule="nonzero">
                <path d="M16.563 24.25c.567 0 1.08-.228 1.46-.593l2.08 1.04c-.01.085-.025.169-.025.256 0 1.163.946 2.11 2.11 2.11 1.163 0 2.109-.947 2.109-2.11 0-.325-.08-.628-.212-.904l2.824-2.823c.275.132.579.212.904.212 1.163 0 2.109-.947 2.109-2.11 0-.219-.043-.426-.105-.625l2.453-1.84c.335.224.736.356 1.167.356 1.164 0 2.11-.947 2.11-2.11 0-1.163-.946-2.109-2.11-2.109-1.163 0-2.109.946-2.109 2.11 0 .218.043.425.105.624l-2.453 1.84a2.095 2.095 0 0 0-1.168-.355c-1.163 0-2.109.946-2.109 2.11 0 .324.08.628.212.903l-2.824 2.823a2.084 2.084 0 0 0-.904-.211c-.567 0-1.08.227-1.46.593l-2.08-1.04c.01-.086.025-.169.025-.256 0-1.163-.946-2.11-2.11-2.11-1.163 0-2.109.947-2.109 2.11 0 1.163.946 2.109 2.11 2.109z"/>
                <path d="M36.297 35.594h-.75v-14.86a.703.703 0 0 0-.703-.703H32.03a.703.703 0 0 0-.703.703v14.86h-1.406v-10.64a.703.703 0 0 0-.703-.704h-2.813a.703.703 0 0 0-.703.703v10.64h-1.406v-5.015a.703.703 0 0 0-.703-.703H20.78a.703.703 0 0 0-.703.703v5.016h-1.406v-7.828a.703.703 0 0 0-.703-.703h-2.813a.703.703 0 0 0-.703.703v7.828h-.75a.703.703 0 1 0 0 1.406h22.594a.703.703 0 1 0 0-1.406z"/>
            </g>
        </g>
    </svg>
`
export default BulletStatistics
