/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { numberToDollars } from '../../utils/utils'

import {
  MoneyBagFullGreen,
  PreserverDonut,
  Avatar,
  FlatMoneyBagSVG,
  LineChart,
} from '../../assets/icons'
import { SVGWrapper, Spacer, HelpIcon, TooltipText, HelpPersonIcon } from '../../components'
import { DoughnutChart } from '../shared'
import { Section } from './styles'

const EmployerPlanAccount = props => {
  const {
    mode,
    store: {
      account,
      plan,
      person,
      spouse,
      config,
      spendingModifiedCases,
      spendingBaseCases,
      institutional,
      baseCase,
      institutionalAccount,
    },
    store,
    setDrawer,
  } = props
  const isSpendown = _.get(store, 'config.isSpendown', null)

  const { totalAnnualSavings, totalAnnualPercentage } = store.baseCase || (isSpendown && 0)

  const preTaxContribution = institutional.preTaxSavingsAmt
  const preTaxContributionPercent = institutional.pretaxSavingsRate
  const rothContribution = institutional.rothSavingsAmt
  const rothContributionPercent = institutional.rothSavingsRate
  const afterTaxContribution = institutional.postTaxSavingsAmt
  const afterTaxContributionPercent = institutional.posttaxSavingsRate

  const isStateOfFlorida =
    plan.sponsorName === 'State of Florida' || plan.sponsorName === 'FRS Investment Plan'
  const isManagedAccount = account.managedAccount

  if (mode === 'retMoreThanThreeYearsAway') {
    if (isStateOfFlorida && !_.get(store, 'config.canTransact', null)) {
      return null
    }

    return (
      <Section>
        <Section.EmployerPlanAccountContainer>
          <Section.SectionTitle>
            {person.displayName}'s retirement plan account with
            <br /> {config.template.planName}{' '}
          </Section.SectionTitle>

          <Section.SectionBody>
            <Spacer space='10px' />
            <Section>
              <Section.ContributionsWrapper>
                <Section.SvgContainer>
                  <MoneyBagFullGreen />
                </Section.SvgContainer>
                <Section.SubTitle>Paycheck contributions</Section.SubTitle>
              </Section.ContributionsWrapper>
              <Section.ContributionsAnnualText>
                <Section.AnnualTotal>
                  Annual{' '}
                  {!institutional.rothContribAllowed &&
                    !institutional.posttaxContribAllowed &&
                    'pre-tax'}{' '}
                  total
                </Section.AnnualTotal>
                <Section.DarkGreyText>{totalAnnualPercentage}</Section.DarkGreyText>% | $
                <Section.DarkGreyText>
                  {numberToDollars(totalAnnualSavings, true)}
                </Section.DarkGreyText>
                /year
              </Section.ContributionsAnnualText>
              {(institutional.rothContribAllowed || institutional.posttaxContribAllowed) && (
                <>
                  <Spacer space='10px' />
                  <Section.ContributionsText>
                    Includes pre-tax contribution of: <br />
                    {preTaxContributionPercent}% | ${numberToDollars(preTaxContribution, true)}
                    /year
                  </Section.ContributionsText>
                  <Spacer space='6px' />
                </>
              )}
              {institutional.rothContribAllowed && (
                <>
                  <Section.ContributionsText>
                    Includes Roth contribution of: <br />
                    {rothContributionPercent}% | ${numberToDollars(rothContribution, true)}/year
                  </Section.ContributionsText>
                  <Spacer space='6px' />
                </>
              )}
              {institutional.posttaxContribAllowed && (
                <Section.ContributionsText>
                  Includes after-tax contribution of: <br />
                  {afterTaxContributionPercent}% | ${numberToDollars(afterTaxContribution, true)}
                  /year
                </Section.ContributionsText>
              )}
            </Section>

            {!isStateOfFlorida && (
              <>
                <Section>
                  <Spacer space='10px' />
                  <Section.ContributionsWrapper>
                    <Section.SvgContainer>
                      <PreserverDonut />
                    </Section.SvgContainer>
                    <Section.SubTitle>Investments</Section.SubTitle>
                  </Section.ContributionsWrapper>
                  <Section.SecondaryText marginLeft='46px'>
                    {isManagedAccount ? 'GuidedChoice is' : 'You are'} managing your account's
                    investments.
                  </Section.SecondaryText>
                </Section>
                {baseCase.companyStocks.reduce((acc, stock) => {
                  return acc + stock.totalValue
                }, 0) > 0 && (
                  <Section>
                    <Spacer space='10px' />
                    <Section.ContributionsWrapper>
                      <Section.SvgContainer>
                        <SVGWrapper
                          svg={LineChart}
                          fill='#4A606A'
                          stroke='#4A606A'
                          size='large'
                          cursor='default'
                        />
                      </Section.SvgContainer>
                      <Section.SubTitle>Company stock holdings</Section.SubTitle>
                    </Section.ContributionsWrapper>
                    <Section.SecondaryText marginLeft='46px'>
                      Percentage of your account in{' '}
                      {baseCase.companyStocks.map(stock => stock.securityName).join(', ')}
                    </Section.SecondaryText>

                    <Section.SVGContainer>
                      <DoughnutChart
                        percent={
                          (baseCase.companyStocks.reduce((acc, stock) => {
                            return acc + stock.totalValue
                          }, 0) /
                            _.get(institutionalAccount, 'balance', 0)) *
                          100
                        }
                        height={130}
                      />
                    </Section.SVGContainer>
                  </Section>
                )}
              </>
            )}
          </Section.SectionBody>
        </Section.EmployerPlanAccountContainer>
        <Spacer space='30px' />
      </Section>
    )
  }

  if (mode === 'retWithinThreeYears') {
    if (isStateOfFlorida && !_.get(store, 'config.canTransact', null)) {
      return null
    }

    return (
      <Section>
        <Section.EmployerPlanAccountContainer>
          <Section.SectionTitle>
            {person.displayName}'s retirement plan account with
            <br /> {config.template.planName}{' '}
          </Section.SectionTitle>

          <Section.SectionBody>
            <Spacer space='10px' />
            <Section>
              <Section.ContributionsWrapper>
                <Section.SvgContainer>
                  <MoneyBagFullGreen />
                </Section.SvgContainer>
                <Section.SubTitle>Paycheck contributions</Section.SubTitle>
              </Section.ContributionsWrapper>
              <Section.ContributionsAnnualText>
                <Section.AnnualTotal>Annual total</Section.AnnualTotal>
                <Section.DarkGreyText>{totalAnnualPercentage}</Section.DarkGreyText>% | $
                <Section.DarkGreyText>
                  {numberToDollars(totalAnnualSavings, true)}
                </Section.DarkGreyText>
                /year
              </Section.ContributionsAnnualText>
              {(institutional.rothContribAllowed || institutional.posttaxContribAllowed) && (
                <>
                  <Spacer space='10px' />
                  <Section.ContributionsText>
                    Includes pre-tax contribution of: <br />
                    {preTaxContributionPercent}% | ${numberToDollars(preTaxContribution, true)}
                    /year
                  </Section.ContributionsText>
                  <Spacer space='6px' />
                </>
              )}
              {institutional.rothContribAllowed && (
                <>
                  <Section.ContributionsText>
                    Includes Roth contribution of: <br />
                    {rothContributionPercent}% | ${numberToDollars(rothContribution, true)}/year
                  </Section.ContributionsText>
                  <Spacer space='6px' />
                </>
              )}
              {institutional.posttaxContribAllowed && (
                <Section.ContributionsText>
                  Includes after-tax contribution of: <br />
                  {afterTaxContributionPercent}% | ${numberToDollars(afterTaxContribution, true)}
                  /year
                </Section.ContributionsText>
              )}
            </Section>

            {!isStateOfFlorida && (
              <>
                <Section>
                  <Spacer space='10px' />
                  <Section.ContributionsWrapper>
                    <Section.SvgContainer>
                      <PreserverDonut />
                    </Section.SvgContainer>
                    <Section.SubTitle>Investments</Section.SubTitle>
                  </Section.ContributionsWrapper>
                  <Section.SecondaryText marginLeft='46px'>
                    {isManagedAccount ? 'GuidedChoice is' : 'You are'} managing your account's
                    investments.
                  </Section.SecondaryText>
                </Section>

                {baseCase.companyStocks.reduce((acc, stock) => {
                  return acc + stock.totalValue
                }, 0) > 0 && (
                  <Section>
                    <Spacer space='10px' />
                    <Section.ContributionsWrapper>
                      <Section.SvgContainer>
                        <SVGWrapper
                          svg={LineChart}
                          fill='#4A606A'
                          stroke='#4A606A'
                          size='large'
                          cursor='default'
                        />
                      </Section.SvgContainer>
                      <Section.SubTitle>Company stock holdings</Section.SubTitle>
                    </Section.ContributionsWrapper>
                    <Section.SecondaryText marginLeft='46px'>
                      Percentage of your account in{' '}
                      {baseCase.companyStocks.map(stock => stock.securityName).join(', ')}
                    </Section.SecondaryText>
                    <Section.SVGContainer>
                      <DoughnutChart
                        percent={
                          (baseCase.companyStocks.reduce((acc, stock) => {
                            return acc + stock.totalValue
                          }, 0) /
                            _.get(institutionalAccount, 'balance', 0)) *
                          100
                        }
                        height={130}
                      />
                    </Section.SVGContainer>
                  </Section>
                )}
              </>
            )}
          </Section.SectionBody>
        </Section.EmployerPlanAccountContainer>
        <Spacer space='30px' />
      </Section>
    )
  }

  return (
    <Section>
      <Section.EmployerPlanAccountContainer>
        <Section.SectionTitle>How long it will last</Section.SectionTitle>

        <Spacer space='12px' />

        <Section.SectionBody>
          <Section.ContributionsWrapper>
            <Section.SvgContainer>
              <SVGWrapper
                svg={Avatar}
                fill='#4A606A'
                stroke='#4A606A'
                size='large'
                cursor='default'
              />
            </Section.SvgContainer>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}>
              <Section.SubTitle
                css={css`
                  margin-right: 5px;
                `}>
                Life expectancy—age we're planning to
              </Section.SubTitle>
              <HelpIcon tooltip={TooltipText.lifeExpectancyMortalityAge()} />
            </div>
          </Section.ContributionsWrapper>
          <Section>
            <div
              css={css`
                padding-left: 44px;
              `}>
              <Section.LightLabel>{person.displayName}: </Section.LightLabel>
              {person.includeSpouse
                ? _.get(
                    spendingBaseCases,
                    'averageMarketSpendingAdvice.primary.lifeExpectancyYear',
                    0
                  ) >=
                  _.get(
                    spendingBaseCases,
                    'averageMarketSpendingAdvice.spouse.lifeExpectancyYear',
                    0
                  )
                  ? _.get(
                      spendingModifiedCases,
                      'averageMarketSpendingAdvice.primary.lifeExpectancyAge',
                      0
                    ) + 10
                  : _.get(
                      spendingBaseCases,
                      'averageMarketSpendingAdvice.primary.lifeExpectancyAge',
                      0
                    )
                : _.get(
                    spendingModifiedCases,
                    'averageMarketSpendingAdvice.primary.lifeExpectancyAge',
                    0
                  ) + 10}
            </div>
            {person.includeSpouse && (
              <div
                css={css`
                  padding-left: 44px;
                `}>
                <Section.LightLabel>{spouse.firstName}: </Section.LightLabel>
                {!(
                  _.get(
                    spendingBaseCases,
                    'averageMarketSpendingAdvice.primary.lifeExpectancyYear',
                    0
                  ) >=
                  _.get(
                    spendingBaseCases,
                    'averageMarketSpendingAdvice.spouse.lifeExpectancyYear',
                    0
                  )
                )
                  ? _.get(
                      spendingModifiedCases,
                      'averageMarketSpendingAdvice.spouse.lifeExpectancyAge',
                      0
                    ) + 10
                  : _.get(
                      spendingBaseCases,
                      'averageMarketSpendingAdvice.spouse.lifeExpectancyAge',
                      0
                    )}
              </div>
            )}
          </Section>
          <Spacer space='10px' />

          <Section.ContributionsWrapper>
            <Section.SvgContainer>
              <SVGWrapper svg={FlatMoneyBagSVG} stroke='#4A606A' size='large' cursor='default' />
            </Section.SvgContainer>

            <Section.SubTitle>First potential shortfall</Section.SubTitle>
          </Section.ContributionsWrapper>
          <Section>
            {_.get(spendingBaseCases, 'averageMarketSpendingAdvice.firstShortfallYear', null) >
            0 ? (
              <div
                css={css`
                  padding-left: 44px;
                `}>
                <Section.LightLabel>
                  <Section.OrangeText>
                    First shortfall year:{' '}
                    {_.get(
                      spendingBaseCases,
                      'averageMarketSpendingAdvice.firstShortfallYear',
                      null
                    )}{' '}
                  </Section.OrangeText>
                  <Section.LightLabel>| </Section.LightLabel>
                  {_.get(
                    spendingBaseCases,
                    'averageMarketSpendingAdvice.survivorNameAtFirstShortfallYear',
                    null
                  )}
                  's age that year:{' '}
                </Section.LightLabel>
                <Section.DarkLabel>
                  {_.get(
                    spendingBaseCases,
                    'averageMarketSpendingAdvice.survivorAgeAtFirstShortfallYear',
                    null
                  )}
                </Section.DarkLabel>
                <HelpPersonIcon
                  size='xLarge'
                  helpLabel='Results: See why'
                  onClick={() => setDrawer('Personalized Help')}
                />
              </div>
            ) : (
              <div
                css={css`
                  padding-left: 44px;
                `}>
                <Section.DarkLabel>Great news—no shortfalls!</Section.DarkLabel>
              </div>
            )}
          </Section>
          <Spacer space='10px' />

          <Section.ContributionsWrapper>
            <Section.SvgContainer>
              <MoneyBagFullGreen />
            </Section.SvgContainer>

            <Section.SubTitle>Remaining balance for estate</Section.SubTitle>
          </Section.ContributionsWrapper>
          <Section>
            <div
              css={css`
                padding-left: 44px;
              `}>
              <Section.LightLabel>$</Section.LightLabel>
              <Section.DarkLabel>0 - </Section.DarkLabel>
              <Section.LightLabel>$</Section.LightLabel>
              <Section.DarkLabel>
                {numberToDollars(
                  _.get(spendingBaseCases, 'averageMarketSpendingAdvice.remainingLegacyAmount', 0),
                  true
                )}
              </Section.DarkLabel>
            </div>
          </Section>

          <Spacer space='10px' />
          <Section />

          <Spacer space='10px' />
        </Section.SectionBody>
      </Section.EmployerPlanAccountContainer>

      {_.get(spendingBaseCases, 'currentCompanyStockWeightPercentage', 0) > 0 && (
        <>
          <Spacer space='30px' />
          <Section.EmployerPlanAccountContainer>
            <Section.SectionTitle>
              {person.displayName}'s retirement plan account with
              <br /> {config.template.planName}{' '}
            </Section.SectionTitle>

            <Section>
              <Spacer space='10px' />
              <Section.ContributionsWrapper>
                <Section.SvgContainer>
                  <SVGWrapper
                    svg={LineChart}
                    fill='#4A606A'
                    stroke='#4A606A'
                    size='large'
                    cursor='default'
                  />
                </Section.SvgContainer>
                <Section.SubTitle>Company stock holdings</Section.SubTitle>
              </Section.ContributionsWrapper>
              <Section.SecondaryText marginLeft='46px'>
                Percentage of your account in{' '}
                {_.get(spendingBaseCases, 'companyStocks', [])
                  .map(stock => stock.securityName)
                  .join(', ')}
              </Section.SecondaryText>
              <Section.SVGContainer>
                <DoughnutChart
                  percent={_.get(spendingBaseCases, 'currentCompanyStockWeightPercentage', 0)}
                  height={130}
                />
              </Section.SVGContainer>
            </Section>
          </Section.EmployerPlanAccountContainer>
        </>
      )}

      <Spacer space='30px' />
    </Section>
  )
}

export default inject('store')(observer(EmployerPlanAccount))
