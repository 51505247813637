import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { Observer, inject } from 'mobx-react'
import _ from 'lodash'

import { numberToDollars } from '../../../utils'
import { WizardStep, Card, CardField, Loading } from '../../../components'
import GrantStatus from './GrantStatus'

function StockOptions(props) {
  const [state, setState] = useState('loading')

  async function fetchInitialState() {
    try {
      setState('loading')
      await props.store.getStockOptions()
      setState('done')
    } catch (err) {
      setState('error')
    }
  }

  useEffect(() => {
    fetchInitialState()
  }, []) // eslint-disable-line

  function render() {
    const {
      config,
      person: { displayName },
      stockOptions,
    } = props.store

    const vestedOptionsQuantityInProjections = _.sumBy(stockOptions, stockOption =>
      stockOption.vested ? stockOption.quantity : 0
    )

    const states = {
      loading: <Loading />,

      error: (
        <div
          css={css`
            color: crimson;
          `}>
          Something went wrong. Please try again later.
        </div>
      ),

      done: (
        <div>
          <CardField
            label='No. of vested options in projections'
            value={numberToDollars(vestedOptionsQuantityInProjections, true)}
          />
          <GrantStatus />
        </div>
      ),
    }

    return (
      <WizardStep title={`${displayName}'s Stock Options info`}>
        <Card title='Option basics' cardWidth='900px'>
          <CardField label='Company name' value={config.template.sponsorName} />
          <CardField label='Optionee name' value={displayName} />
        </Card>
        <Card title='Option details' cardWidth='900px'>
          {states[state]}
        </Card>
      </WizardStep>
    )
  }

  return <Observer>{render}</Observer>
}

export default inject('store')(StockOptions)
