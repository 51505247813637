import { css } from '@emotion/react'

export default function LoginError({ err }) {
  return (
    <div
      css={css`
        color: #e31e27;
        font-size: 14px;
        text-align: left;
        line-height: 1.5;
        margin-top: 20px;
        margin-left: 12px;
      `}>
      {err || 'Our database does not contain that email and password combination.'}
    </div>
  )
}
