import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const CardStyled = styled.div`
  width: 100%;
  background-color: ${p => p.theme.cardBgColor};
  border: ${p => p.theme.cardBorder};
  padding: 0.75rem 0.75rem 1rem 0.75rem;
  margin: 2rem 0;
  max-width: ${p => (p.cardWidth ? p.cardWidth : '800px')};
`

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`

const TitleText = styled.div`
  color: ${p => (p.red ? '#eb436c' : p.theme.cardTextColor)};
  font-size: 30px;
`

const EditButton = styled.a`
  font-size: 0.75rem;
  color: ${p => p.theme.cardEditButtonColor};
  cursor: pointer;
  align-self: flex-start;
  text-decoration: underline;
`

class Card extends Component {
  static defaultProps = {
    editLabel: 'Edit',
  }

  static propTypes = {
    title: PropTypes.string,
    handleEdit: PropTypes.func,
    editLabel: PropTypes.string,
  }

  render() {
    const { title, red, handleEdit, editLabel, children, cardWidth, id } = this.props
    return (
      <CardStyled cardWidth={cardWidth} id={id}>
        {title && (
          <CardTitle>
            <TitleText red={red}>{title}</TitleText>
            {!!handleEdit && <EditButton onClick={handleEdit}>{editLabel}</EditButton>}
          </CardTitle>
        )}
        {children}
      </CardStyled>
    )
  }
}

export default Card
