const bank = `
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="-icons/accounts/normal" class="icon-color">
        <path
          d="M14,12 L14,11.5680299 C14,11.4600374 13.9487253,11.3638065
            13.8582745,11.2798717 C13.754573,11.1959369 13.6381967,11.1478214
            13.5091459,11.1478214 L13.0822411,11.1478214 L13.0822411,6
            L11.2328964,6 L11.2328964,11.1478214 L10.3018868,11.1478214
            L10.3018868,6 L8.45254213,6 L8.45254213,11.1478214 L7.53478323,
            11.1478214 L7.53478323,6 L5.68543857,6 L5.68543857,11.1478214
            L4.76710356,11.1478214 L4.76710356,6 L2.91833501,6 L2.91833501,
            11.1478214 L2.49143022,11.1478214 C2.36180326,11.1478214
            2.24542705,11.1959369 2.1423016,11.2798717 C2.05185078,11.3638065
            2,11.4600374 2,11.5680299 L2,12 L14,12 L14,12 Z"
          id="Path"
        >
        </path>
        <path
          d="M1,15.5 L15,15.5 L15,14.1727727 C15,13.9862614 14.9609801,
            13.8363863 14.8561504,13.7056619 C14.7519032,13.5749376 14.634261,
            13.5 14.5032239,13.5 L1.50958859,13.5 C1.36573901,13.5 1.24809684,
            13.5749376 1.14384958,13.7056619 C1.05241483,13.8363863 1,13.9862614
            1,14.1727727 L1,15.5 L1,15.5 Z"
          id="Path"
        >
        </path>
        <path
          d="M2.43538737,4.5 C2.43944621,4.50523862 2.42717689,4.5 2.4264063,4.5
            L2.43538737,4.5 Z M2.43538737,4.5 L13.5591962,4.5 C13.5667119,
            4.48473243 13.5708248,4.46855112 13.5708248,4.45454545 L13.5708248,
            3.95454545 L14.5,3.95454545 L14.5,3.64880594 L8.00028251,0.553788294
            L1.5,3.64881724 L1.5,3.95454545 L2.42917517,3.95454545 L2.42917517,
            4.45454545 C2.42917517,4.47564023 2.43927841,4.50290807 2.43426598,
            4.49872013 C2.43472872,4.49920332 2.4350992,4.49962807
            2.43538737,4.5 Z"
          id="Path"
        >
        </path>
      </g>
    </g>
  </svg>
`

export default bank
