import { css } from '@emotion/react'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { ButtonsBox, Button, CurrencyText, Drawer } from '../../../../components'
import { trackInvestmentsByTaxType } from '../../utils'
import { TotalBalanceBox, GreyText, LightGreyText } from '../../styled'
import EditInvestment from '../EditInvestment'
import AddInvestmentButton from './AddInvestmentButton'
import InvestmentGroup from './InvestmentGroup'

class InvestmentsDisplay extends Component {
  state = { editInvestment: null }

  toggleEdit = id => this.setState({ editInvestment: id })

  handleUpdateBalances = () => {
    const { history, match } = this.props
    const { type, accountType, id } = match.params
    history.push(`/accounts/${type}/${accountType}/${id}/update-balances`)
  }

  render() {
    const { type, accountType, id } = this.props.match.params
    const {
      plaidLinkedAccountIds,
      features: { editInstitutionalAccount },
    } = this.props.store
    const {
      account: { rothContribAllowed, posttaxContribAllowed },
      investments,
      investedInvestments,
      totalBalance,
      preTaxInvestments,
      rothInvestments,
      afterTaxInvestments,
      notInvestedInvestments,
      preTaxBalance,
      rothBalance,
      afterTaxBalance,
    } = this.props.account
    const canEdit = type !== 'institutional' || editInstitutionalAccount
    const isWizard = this.props.match.url.substring(0, 14) === '/accounts/add/'
    const plaidAccountLinked = _.includes(plaidLinkedAccountIds, Number(id))
    const showSplitBalanceWarning =
      isWizard && plaidAccountLinked && (rothContribAllowed || posttaxContribAllowed)

    return (
      <div>
        {canEdit && !isWizard && (
          <ButtonsBox
            css={css`
              flex-direction: row-reverse;
              margin: 0;
            `}>
            <Button
              label='Update balances only'
              onClick={this.handleUpdateBalances}
              primary
              width='215px'
            />
          </ButtonsBox>
        )}
        {canEdit && !isWizard && investments.length > 5 && (
          <div
            css={css`
              margin: 20px 0;
            `}>
            <AddInvestmentButton
              linkAddress={`/accounts/add/${type}/${accountType}/${id}/investment`}
            />
          </div>
        )}
        {investedInvestments.length > 0 && (
          <div
            css={css`
              margin-bottom: 40px;
            `}>
            <TotalBalanceBox>
              <LightGreyText>Account balance: </LightGreyText>
              <GreyText>
                <CurrencyText decimalScale={2} value={totalBalance} />
              </GreyText>
            </TotalBalanceBox>
          </div>
        )}
        {trackInvestmentsByTaxType(accountType) ? (
          <div>
            {preTaxInvestments.length > 0 && (
              <div
                css={css`
                  margin-bottom: 40px;
                `}>
                <InvestmentGroup
                  balance={preTaxBalance}
                  balanceLabel='Pre-tax balance:'
                  investments={preTaxInvestments}
                  title='Pre-tax investments'
                  accountId={id}
                  canEdit={canEdit}
                  toggleEdit={this.toggleEdit}
                  showSplitBalanceWarning={showSplitBalanceWarning}
                  match={this.props.match}
                  history={this.props.history}
                />
              </div>
            )}

            {rothInvestments.length > 0 && (
              <div
                css={css`
                  margin-bottom: 40px;
                `}>
                <InvestmentGroup
                  balance={rothBalance}
                  balanceLabel='Roth balance:'
                  investments={rothInvestments}
                  title='Roth investments'
                  accountId={id}
                  canEdit={canEdit}
                  toggleEdit={this.toggleEdit}
                  match={this.props.match}
                  history={this.props.history}
                />
              </div>
            )}

            {afterTaxInvestments.length > 0 && (
              <div
                css={css`
                  margin-bottom: 40px;
                `}>
                <InvestmentGroup
                  balance={afterTaxBalance}
                  balanceLabel='After-tax balance:'
                  investments={afterTaxInvestments}
                  title='After-tax investments'
                  accountId={id}
                  canEdit={canEdit}
                  toggleEdit={this.toggleEdit}
                  match={this.props.match}
                  history={this.props.history}
                />
              </div>
            )}
          </div>
        ) : (
          investedInvestments.length > 0 && (
            <div
              css={css`
                margin-bottom: 40px;
              `}>
              <InvestmentGroup
                investments={investedInvestments}
                balance={totalBalance}
                accountId={id}
                canEdit={canEdit}
                toggleEdit={this.toggleEdit}
                match={this.props.match}
                history={this.props.history}
              />
            </div>
          )
        )}
        {notInvestedInvestments.length > 0 && trackInvestmentsByTaxType(accountType) && (
          <div
            css={css`
              margin-bottom: '40px';
            `}>
            <InvestmentGroup
              accountId={id}
              hideBalance
              investments={notInvestedInvestments}
              title='Other available investment options'
              match={this.props.match}
              history={this.props.history}
            />
          </div>
        )}

        {canEdit && (
          <AddInvestmentButton
            linkAddress={`/accounts/add/${type}/${accountType}/${id}/investment`}
          />
        )}
        <Drawer
          active={!!this.state.editInvestment}
          title='Edit investment'
          maxWidth='700px'
          width='60%'>
          {!!this.state.editInvestment && (
            <EditInvestment investmentId={this.state.editInvestment} toggleEdit={this.toggleEdit} />
          )}
        </Drawer>
      </div>
    )
  }
}

export default withRouter(inject('store', 'account')(observer(InvestmentsDisplay)))
