/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import { Form, useField, useFormState } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'

import { LargeCheckbox } from '../../../guided-toolbox/checkbox'
import { Button } from '../../../components'
import { numberToDollars, reduceValidationError } from '../../../utils'

const schema = yup.object().shape({
  terms: yup.boolean().equals([true], 'Please confirm contributions before continuing'),
})

function TermsField({ acceptedCase, institutional }) {
  const { submitting } = useFormState({ subscription: { submitting: true } })
  const { input } = useField('terms')

  function handleOnChange() {
    if (submitting) {
      //
    } else {
      input.onFocus()
      input.onChange(!input.value)
      input.onBlur()
    }
  }

  return (
    <div
      css={css`
        border: 6px solid #c4d7e0;
        border-radius: 8px;
        background-color: #ffffff;
        padding: 1rem;
        margin: 60px 0 60px;
      `}>
      <div
        css={css`
          color: #022a3a;
          font-size: 24px;
          line-height: 1.35;
          margin-bottom: 20px;
        `}>
        Confirmation
      </div>
      <div
        css={css`
          display: flex;
          & > label {
            display: inline-flex;
            width: 36px;
            margin: 0;
            transform: scale(1.5, 1.5) translate(5px, 5px);
          }
        `}>
        <LargeCheckbox checked={input.value === true} onChange={handleOnChange} />
        <div
          css={css`
            color: #4a606a;
            font-size: 20px;
            line-height: 1.35;
            padding-left: 1rem;
            cursor: pointer;
          `}
          onClick={handleOnChange}>
          I understand my total annual contribution to my {institutional.name} account is
          approximately ${numberToDollars(acceptedCase.totalAnnualSavings, true)} annually or $
          {numberToDollars(acceptedCase.totalAnnualSavings / 52, true)} weekly
        </div>
      </div>
    </div>
  )
}

function ConfirmPaycheckContributions(props) {
  function handleSubmit(values) {
    try {
      const {
        history,
        offboarding: { nextRoute },
      } = props

      history.push(`/implement/${nextRoute('confirm-paycheck-contributions')}`)
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  function onSubmit(values) {
    return handleSubmit(values)
  }

  function validate(values) {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  const { acceptedCase, institutional } = props.store

  return (
    <div>
      <div
        css={css`
          color: #4a606a;
          font-size: 50px;
          font-weight: 300;
          line-height: 1.35;
          margin-bottom: 60px;
        `}>
        Before we take the next step...
      </div>

      <div
        css={css`
          color: #4a606a;
          font-size: 36px;
          line-height: 1.35;
        `}>
        Confirm your paycheck contributions
      </div>

      <div
        css={css`
          max-width: 800px;
          margin: 2rem auto;
        `}>
        <div
          css={css`
            display: flex;
            align-items: flex-end;
            margin-bottom: 32px;
          `}>
          <div
            css={css`
              flex: 0 1 320px;
              color: #4a606a;
              font-size: 20px;
              line-height: 1.35;
            `}>
            {institutional.oneContributionOnly ? 'Annual pre-tax total' : 'Annual total'}
          </div>
          <div
            css={css`
              flex: 0 1 300px;
              color: #038ab7;
              font-size: 20px;
              line-height: 1.35;
            `}>
            {acceptedCase.totalAnnualPercentage}% | $
            {numberToDollars(acceptedCase.totalAnnualSavings, true)}/year
          </div>
        </div>

        {!institutional.oneContributionOnly && (
          <div>
            <div
              css={css`
                display: flex;
                align-items: flex-end;
                margin: 1rem 0;
              `}>
              <div
                css={css`
                  flex: 0 1 320px;
                  color: #4a606a;
                  font-size: 16px;
                  line-height: 1.35;
                `}>
                Includes pre-tax contribution of:
              </div>
              <div
                css={css`
                  flex: 0 1 300px;
                  color: #038ab7;
                  font-size: 16px;
                  line-height: 1.35;
                `}>
                {acceptedCase.primary.preTaxAnnualContributions}% | $
                {numberToDollars(acceptedCase.primary.preTaxAnnualContribDollars, true)}/year
              </div>
            </div>

            {institutional.rothContribAllowed && (
              <div
                css={css`
                  display: flex;
                  align-items: flex-end;
                  margin: 1rem 0;
                `}>
                <div
                  css={css`
                    flex: 0 1 320px;
                    color: #4a606a;
                    font-size: 16px;
                    line-height: 1.35;
                  `}>
                  Includes Roth contribution of:
                </div>
                <div
                  css={css`
                    flex: 0 1 300px;
                    color: #038ab7;
                    font-size: 16px;
                    line-height: 1.35;
                  `}>
                  {acceptedCase.primary.rothAnnualContributions}% | $
                  {numberToDollars(acceptedCase.primary.rothAnnualContribDollars, true)}/year
                </div>
              </div>
            )}

            {institutional.posttaxContribAllowed && (
              <div
                css={css`
                  display: flex;
                  align-items: flex-end;
                  margin: 1rem 0;
                `}>
                <div
                  css={css`
                    flex: 0 1 320px;
                    color: #4a606a;
                    font-size: 16px;
                    line-height: 1.35;
                  `}>
                  Includes after-tax contribution of:
                </div>
                <div
                  css={css`
                    flex: 0 1 300px;
                    color: #038ab7;
                    font-size: 16px;
                    line-height: 1.35;
                  `}>
                  {acceptedCase.primary.postTaxAnnualContributions}% | $
                  {numberToDollars(acceptedCase.primary.postTaxAnnualContribDollars, true)}/year
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <Form
        initialValues={{ terms: false }}
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ touched: true, errors: true, submitting: true, submitError: true }}
        render={({ touched, errors, submitting, submitError, handleSubmit }) => {
          const submittingError = !submitting && submitError
          const termsError = !submitting && touched.terms && errors.terms

          return (
            <form onSubmit={handleSubmit}>
              <TermsField institutional={institutional} acceptedCase={acceptedCase} />

              <div
                css={css`
                  text-align: center;
                  color: crimson;
                  margin: 20px 0;
                  line-height: 1.35;
                `}>
                {submittingError || termsError}
              </div>

              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}>
                <Button
                  secondary
                  label='Back'
                  disabled={false}
                  onClick={props.history.goBack}
                  width='125px;'
                />
                <Button
                  primary
                  label='Next'
                  disabled={submitting || !!errors.terms}
                  onClick={handleSubmit}
                  width='125px;'
                />
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}

export default inject('store', 'offboarding')(observer(ConfirmPaycheckContributions))
