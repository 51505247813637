import { Component } from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'

import { LeftSideBar, SideBarDrawer } from './'

const SideBox = styled.div`
  flex: 0 0 auto;
  display: flex;
  @media (max-width: 800px) {
    display: none;
  }
`

class SideBar extends Component {
  render() {
    if (this.props.main.enableSidebar !== undefined && this.props.main.enableSidebar === false) {
      return null
    }

    return (
      <SideBox>
        <LeftSideBar {...this.props} />
        <SideBarDrawer {...this.props} />
      </SideBox>
    )
  }
}

export default inject('store', 'main')(observer(SideBar))
