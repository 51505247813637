import { types, getEnv, flow } from 'mobx-state-tree'
import _ from 'lodash'

import { API, API_URL } from '../../../api'

const { model, maybeNull, string, array, number, boolean } = types

const SpendDownOffboardingStore = model({
  acceptedRecommendation: false,
  email: maybeNull(string),
  version: maybeNull(string),
  steps: array(string),
  currentStep: maybeNull(number),
  stepper: maybeNull(boolean),
  hybridMember: maybeNull(boolean),
  modeledAnnuity: maybeNull(boolean),
})
  .volatile(self => ({
    spendingRoutes: [],
  }))
  .views(self => ({
    get spedDownTotalSteps() {
      return self.steps
    },
    get spedDownCurrentStep() {
      return self.currentStep
    },
    get spendDownVersion() {
      return self.version
    },
    get enableSpendDownStepper() {
      return self.stepper
    },
    get isHybridMember() {
      return self.hybridMember
    },
    get isModeledAnnuity() {
      return self.modeledAnnuity
    },
    get initialSpendingPage() {
      const { templateId } = getEnv(self).store.config

      switch (templateId) {
        case 1:
        case 2:
          return '/implement/intro'

        // case 13 covers PTD+ Product, either confirm-paycheck-contributions or enrollment-selection
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 13:
        case 19:
          return '/implement/' + self.spendingRoutes[0]

        default:
          return '/overall'
      }
    },
    get enableSpendingStepper() {
      const { templateId, isRetail } = getEnv(self).store.config

      if (isRetail) {
        return true
      }

      switch (templateId) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          return true

        // disable for PTD+ Product
        case 13:
          return false
        default:
          return null
      }
    },
    activeSpendingStep(route) {
      const found = self.spendingRoutes.findIndex(element => element === route)
      return found + 1
    },
  }))
  .actions(self => ({
    initializeSpedDownOffboarding() {
      const spendingMode = getEnv(self).store.config.spendingMode

      const { store } = getEnv(self)

      self.hybridMember = _.get(store, 'config.canTransact', null)
      self.modeledAnnuity =
        !_.isEmpty(store.spendingModifiedCases.primaryLifetimeInvestmentIncome) ||
        !_.isEmpty(store.spendingModifiedCases.spouseLifetimeInvestmentIncome)

      const retailRoutes = () => {
        if (spendingMode) {
          self.spendingRoutes = ['accept-recommendation', 'congratulations', 'get-your-guide']
        } else {
          self.spendingRoutes = ['congratulations/2']
        }
      }

      const transactionRoutes = () => {
        // FL Institutional
        if (spendingMode) {
          self.spendingRoutes = [
            'accept-recommendation',
            'congratulations',
            'confirm-email',
            'download',
          ]
          const companyStocks = _.get(
            getEnv(self),
            'store.allSpendingModifiedCases.companyStocks',
            []
          )
          const companyStocksToSell = companyStocks.filter(stock => stock.percentToSell > 0)
          if (companyStocksToSell.length > 0) {
            self.spendingRoutes.unshift('company-stock')
          }
          self.stepper = true
        } else {
          self.spendingRoutes = ['no-guide']
          self.stepper = false
        }
      }

      const institutionalRoutes = () => {
        // Institutional: Paychex/Atmos
        if (spendingMode) {
          const companyStocks = _.get(
            getEnv(self),
            'store.allSpendingModifiedCases.companyStocks',
            []
          )
          const companyStocksToSell = companyStocks.filter(stock => stock.percentToSell > 0)
          if (companyStocksToSell.length > 0) {
            self.spendingRoutes.push('company-stock')
          }

          const { managedAccount } = getEnv(self).store.account

          if (managedAccount) {
            self.spendingRoutes.push('implement-changes')
          } else {
            self.spendingRoutes.push('accept-managed-account')
          }

          self.spendingRoutes.push('congratulations', 'save-email-download-guide')

          self.stepper = true
        } else {
          self.spendingRoutes = ['no-guide']
          self.stepper = false
        }
      }

      const enrollmentRoutes = () => {
        // PTD+ Product
        if (spendingMode) {
          self.spendingRoutes = ['no-guide']
          self.stepper = false
        } else {
          self.spendingRoutes = ['no-guide']
          self.stepper = false
        }
      }
      self.currentStep = 0

      const { templateId, isRetail } = getEnv(self).store.config

      if (isRetail) {
        return retailRoutes()
      }

      switch (templateId) {
        case 1:
        case 2:
          return transactionRoutes()

        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
          return institutionalRoutes()

        case 13:
          return enrollmentRoutes()

        default:
          return null
      }
    },
    incrementStep(history, currentPath) {
      self.currentStep = _.findIndex(self.spendingRoutes, value => value === currentPath) + 1
      history.push(`/spending/implement/${self.spendingRoutes[self.currentStep]}`)
    },
    decrementStep(history) {
      self.currentStep -= 1
      history.push(`/spending/implement/${self.spendingRoutes[self.currentStep]}`)
    },
    acceptSpendDownRecommendations: function () {
      self.acceptedRecommendation = true
    },
    setSpedDownOffboardingEmail: flow(function* (value) {
      const { store } = getEnv(self)
      self.email = value
      yield API.post(`${API_URL}guide/email`, null, {
        params: { caseId: store.spendingAcceptedCaseId, email: self.email },
      })
    }),
  }))

export default SpendDownOffboardingStore
