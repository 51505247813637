import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
// import { FORM_ERROR } from 'final-form'

import { Drawer, Button, HelpIcon, ProgressBar, TooltipText } from '../../../components'
import { reduceValidationError } from '../../../utils'
import { DrawerTitle, TopButtonGroup, BottomButtonGroup, LoadingBox } from './EditDrawerStyle'
import MakeChangesContribution from './MakeChangesContribution'
import MakeChangesAdditionalSavings from './MakeChangesAdditionalSavings'
import MakeChangesCompanyStock from './MakeChangesCompanyStock'
import MakeChangesStockOptions from './MakeChangesStockOptions'
import MakeChangesRisk from './MakeChangesRisk'
import MakeChangesRetirementAge from './MakeChangesRetirementAge'
import MakeChangesGoals from './MakeChangesGoals'
import {
  initializeValues,
  restoreRecommendation,
  valuesEqualRecommended,
  valuesEqualModified,
  prepareValuesForSubmit,
  schema,
  calculator,
} from './EditDrawerUtils'

class EditDrawer extends Component {
  handleCancel = () => {
    this.props.handleCancel()
  }

  validate = values => {
    const { primaryRetAgeEditable, spouseRetAgeEditable } = this.editable()
    const {
      person: { age: primaryAge },
      spouse: { age: spouseAge },
    } = this.props.store

    return schema
      .validate(values, {
        abortEarly: false,
        context: {
          primaryRetAgeEditable,
          spouseRetAgeEditable,
          primaryMaxRetAge: Math.min(99, primaryAge + 55),
          spouseMaxRetAge: spouseRetAgeEditable ? Math.min(99, spouseAge + 55) : 99,
        },
      })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  onSubmit = async formValues => {
    const { modifiedCase, recommendedCase, getModifiedCase, resetModifiedCase } = this.props.store
    const values = prepareValuesForSubmit({ formValues, modifiedCase, recommendedCase })

    if (valuesEqualRecommended({ values, recommendedCase })) {
      // set modifiedCase back to null and cancel drawer
      resetModifiedCase()
    } else if (valuesEqualModified({ values, modifiedCase })) {
      // no changes were made and we can just cancel drawer
    } else {
      await getModifiedCase(values)
    }

    this.handleCancel()
  }

  editable = () => {
    const {
      recommendedCase,
      modifiedCase,
      person: { includeSpouse },
      primaryEmployerConfig,
      spouseHasEmployer,
      special457,
      special403b,
      currentGoals,
      features,
    } = this.props.store

    const { primary, spouse, companyStocks, stockOptions } = modifiedCase || recommendedCase

    return {
      primaryEmployer: Boolean(primaryEmployerConfig.rateChangeAllowed && primary),
      special457: Boolean(!primaryEmployerConfig.rateChangeAllowed && special457 && primary),
      special403b: Boolean(!primaryEmployerConfig.rateChangeAllowed && special403b && primary),
      spouseEmployer: Boolean(includeSpouse && spouseHasEmployer && spouse),
      additionalSavingsIsEditable: true,
      companyStockIsEditable:
        features.companyStockEnabled &&
        companyStocks !== null &&
        companyStocks.filter(companyStock => !companyStock.restricted).length > 0,
      stockOptionsAreEditable:
        features.stockOptionsEnabled &&
        stockOptions !== null &&
        // Show Stock Options if vested (vestedDate is before current date),
        // unexpired (expirationDate is in future),
        // and not under water (exercisePrice < currentPrice)
        stockOptions.filter(
          ({ availableToExercise, isUnderWater }) => availableToExercise && !isUnderWater
        ).length > 0,
      riskIsEditable: true,
      primaryRetAgeEditable: Boolean(primary),
      spouseRetAgeEditable: Boolean(includeSpouse && spouse),
      goalsIsEditable: currentGoals.length > 0,
    }
  }

  render() {
    const { store, active } = this.props
    const {
      account: { annualSalary: primaryAnnualIncome },
      spouse: { annualIncome: spouseAnnualIncome },
      recommendedCase,
      modifiedCase,
      currentGoals,
    } = store
    const editable = this.editable()

    return (
      <Drawer active={active} width='80%' fullMobile nopad>
        <Form
          onSubmit={this.onSubmit}
          decorators={[calculator]}
          mutators={{ ...arrayMutators }}
          initialValues={initializeValues({
            recommendedCase,
            modifiedCase,
            currentGoals,
            primaryAnnualIncome,
            spouseAnnualIncome,
          })}
          validate={this.validate}
          subscription={{ submitting: true, submitError: true, values: true }}
          render={({ handleSubmit, submitting, values, form }) => (
            <div
              css={css`
                padding: 20px 20px 80px 20px;
                @media (max-width: 800px) {
                  padding: 1rem 1rem 80px 1rem;
                }
              `}>
              <DrawerTitle>Change strategy details to see potential effects</DrawerTitle>
              <TopButtonGroup>
                <Button
                  primary
                  width='290px'
                  label='Restore recommendation'
                  onClick={restoreRecommendation({
                    form,
                    recommendedCase,
                    currentGoals,
                    primaryAnnualIncome,
                    spouseAnnualIncome,
                  })}
                />
                &nbsp;&nbsp;
                <HelpIcon tooltip={TooltipText.restoreRecommendation()} />
              </TopButtonGroup>

              <MakeChangesContribution
                store={store}
                editable={editable}
                form={form}
                values={values}
              />

              <MakeChangesAdditionalSavings store={store} editable={editable} />

              <MakeChangesCompanyStock store={store} editable={editable} values={values} />

              <MakeChangesStockOptions store={store} editable={editable} />

              <MakeChangesRisk store={store} editable={editable} form={form} values={values} />

              <MakeChangesRetirementAge store={store} editable={editable} />

              <MakeChangesGoals store={store} editable={editable} />

              <BottomButtonGroup>
                {submitting && (
                  <LoadingBox>
                    <ProgressBar type='circular' multicolor />
                  </LoadingBox>
                )}

                {!submitting && (
                  <Button secondary width='120px' label='Cancel' onClick={this.handleCancel}>
                    Cancel
                  </Button>
                )}

                {!submitting && (
                  <Button
                    primary
                    width='167px'
                    label='Recalculate'
                    onClick={handleSubmit}
                    disabled={!active}
                  />
                )}
              </BottomButtonGroup>
            </div>
          )}
        />
      </Drawer>
    )
  }
}

export default inject('store')(observer(EditDrawer))
