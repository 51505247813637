import styled from '@emotion/styled'

export const Page = styled.div``
Page.ScenarioName = styled.div`
  color: #022a3a;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
`
Page.ScenarioDisclaimer = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
`
Page.BiggestDropText = styled.div`
  color: #022a3a;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`
Page.BiggestDropValue = styled.span`
  color: #022a3a;
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`
Page.DollarSign = styled.span`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`
