import { types, flow, getParent } from 'mobx-state-tree'
import _ from 'lodash'

import { API } from '../api'
import { CLIENT_OVERRIDES } from '../constants/client-overrides'

const { model, maybeNull, number, boolean, string, array } = types

const Disclaimer = model({
  greaterThan: string,
  lessThan: string,
  module: string,
  objectName: string,
  textToDisplay: string,
})

const Template = model({
  // GuidedChoice
  brandName: maybeNull(string),

  // www.guidedchoice.com
  brandSite: maybeNull(string),

  // help@guidedchoice.com
  brandSupportEmail: maybeNull(string),

  disclaimer: maybeNull(array(Disclaimer)),

  // The label to use if managedAccountAvailable=true, one of 'managed by GuidedChoice' or 'point-in-time advice by GuidedChoice'
  managedAccountLabel: maybeNull(string),

  // Managed account or Point-in-time advice
  managedAccountType: maybeNull(string),

  // Determines if they're a migrating client
  isMigrating: boolean,

  // determines which component acts as the overall page, One of: Overall, Simplified
  overallPageComponent: maybeNull(string),

  // Likely need to deprecate this in favor of Institutional account name
  planName: maybeNull(string),

  // Name of the Recordkeeper
  recordKeeperName: maybeNull(string),

  // Name of the Company Sponsor for the account
  sponsorName: maybeNull(string),

  // Abbreviated name for the Sponsor (if applicable)
  sponsorShortHand: maybeNull(string),

  // The relevant state for the Sponsor
  sponsorState: maybeNull(string),

  // The support group to contact for the Sponsor
  supportGroup: maybeNull(string),

  // Support line for client/customer
  supportLine: maybeNull(string),

  // Link related to Sponsor
  supportLinkOverDomain: maybeNull(string),

  // Alternative display for the linkover domain
  supportLinkOverDomainDisplay: maybeNull(string),

  // The name of the linkover contact
  supportLinkOverName: maybeNull(string),

  // The name of the customer support contact
  supportName: maybeNull(string),

  // List of possible scales for the Risk Ruler ('none', 'scale7', 'scale100')
  riskRulerStates: array(string),

  // Allow user to change contribution rate from shortcuts [deprecated]
  changeContributionShortcutEnabled: false,

  // show/hide the left side bar "Quick Actions" link [unused]
  showQuickActions: false,

  // Determines the starting page for the user, used for product selection
  landingPage: maybeNull(string),

  recordKeeperId: maybeNull(number),
})

const Config = model({
  canSubmitTrans: maybeNull(boolean),
  enableCatchupContributions: maybeNull(boolean),
  guideTypeId: maybeNull(number),
  offBoardWizardId: maybeNull(number),
  onBoardWizardId: maybeNull(number),
  onBoarded: maybeNull(boolean),
  spendingMode: maybeNull(boolean),
  spendDownModeling: false,
  requireToAcceptTerms: maybeNull(boolean),
  templateId: number,
  template: maybeNull(Template),
})
  .views(self => ({
    get canTransact() {
      // definitive proxy for determining Florida's offboarding routes
      return { 1: true, 2: false }[self.offBoardWizardId]
    },
    get isSpendown() {
      const { enableSpendDown } = getParent(self).features
      return enableSpendDown && self.spendingMode
    },
    get isRetail() {
      return self.templateId === 19
    },
    get isRetailFrs() {
      return false // temporary until frs retail flag is created
    },
  }))
  .actions(self => ({
    enableSpendingMode: flow(function* () {
      try {
        yield API.post('spend-down/enable')
        self.spendingMode = true
        yield getParent(self).getConfig()
      } catch (err) {
        console.error(err)
        self.spendingMode = false
      }
    }),
    disableSpendingMode: flow(function* () {
      try {
        yield API.post('spend-down/disable')
        self.spendingMode = false
        yield getParent(self).getConfig()
      } catch (err) {
        console.error(err)
        self.spendingMode = true
      }
    }),
    enableSpendDownModeling() {
      self.spendDownModeling = true
    },
    disableSpendDownModeling() {
      self.spendDownModeling = false
    },
    setRetirementAccountReviewStatus: flow(function* () {
      yield API.post('institutional-account/reviewed')
    }),
  }))

const ConfigStore = model({
  config: maybeNull(Config),
}).actions(self => ({
  getConfig: flow(function* () {
    const config = yield API.get('ui/config')
    self.setConfig(config.data)
    return config.data
  }),
  setConfig(values) {
    const { template, ...config } = values

    // Transform API key names to our Template model names
    function filterTemplate({ migrating, ...rest }) {
      return { isMigrating: migrating, ...rest }
    }

    // Recursively merge in config overrides
    self.config = _.merge(
      { ...config, template: filterTemplate(template) },
      CLIENT_OVERRIDES.config
    )
  },
  acceptTerms: flow(function* () {
    yield API.post('termsAndConditions/accept')
    const config = yield API.get('ui/config')
    self.setConfig(config.data)
  }),
}))

export default ConfigStore
