import { Component } from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'
import Big from 'big.js'
import BaseInput from './BaseInput'
import styled from '@emotion/styled'

const StyledPercentFormat = styled.div`
  input {
    text-align: right;
    padding-right: 20px;
    &:disabled {
      border-bottom: none !important;
    }
  }
  &:before {
    position: absolute;
    right: 0;
    content: '%';
    color: ${p => p.theme.lightestGray};
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`

export default class PercentInput extends Component {
  static defaultProps = {
    allowNegative: false,
    decimalScale: 0,
    disabled: false,
    readonly: false,
    showError: false,
    maxLength: 3,
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    allowNegative: PropTypes.bool,
    children: PropTypes.string,
    decimalScale: PropTypes.number,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    readonly: PropTypes.bool,
    placeholder: PropTypes.string,
    showError: PropTypes.bool,
    value: PropTypes.number,
    width: PropTypes.string,
    mobileWidth: PropTypes.string,
    maxLength: PropTypes.number,
    isAllowed: PropTypes.func,
  }

  handleBlur() {
    const { name, onBlur } = this.props
    if (onBlur) {
      onBlur(name)
    }
  }

  state = {
    value: this.props.value ? parseFloat(Big(this.props.value).round(2)) : this.props.value,
    floatValue: this.props.value ? parseFloat(Big(this.props.value).round(2)) : this.props.value,
  }

  handleBlurAndUpdate() {
    const { name, onBlur, onChange } = this.props
    if (onBlur) {
      if (!isNaN(this.state.floatValue)) {
        onChange(name, this.state.floatValue)
      }
      onBlur(name)
    }
  }

  handleChange(values) {
    const { name, onChange } = this.props
    if (values.floatValue === null || isNaN(values.floatValue)) {
      values.floatValue = 0
    }

    if (onChange) {
      onChange(name, values.floatValue)
      this.setState({ value: values.value, floatValue: values.floatValue })
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps

    if (value !== this.state.value) {
      const newValue = value ? parseFloat(Big(value).round(2)) : value === 0 ? 0 : null
      this.setState({ value: newValue, floatValue: newValue })
    }
  }

  render() {
    const {
      className,
      allowNegative,
      decimalScale,
      disabled,
      error,
      name,
      placeholder,
      readonly,
      showError,
      width,
      mobileWidth,
      maxLength,
      isAllowed,
    } = this.props

    const currentValue = this.state.value

    return (
      <BaseInput
        className={className}
        error={error}
        showError={showError}
        readonly={readonly}
        width={width}
        mobileWidth={mobileWidth}>
        <StyledPercentFormat>
          <NumericFormat
            allowNegative={allowNegative}
            decimalScale={decimalScale}
            disabled={disabled}
            name={name}
            onBlur={this.handleBlurAndUpdate.bind(this)}
            onValueChange={this.handleChange.bind(this)}
            placeholder={placeholder}
            value={currentValue || (currentValue === 0 ? 0 : undefined)}
            maxLength={maxLength}
            isAllowed={isAllowed}
          />
        </StyledPercentFormat>
      </BaseInput>
    )
  }
}
