import { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'

const color = 'white'
const opacity = 0.8
const animationDuration = '0.35s'
const animationCurve = 'cubic-bezier(0.4, 0, 0.2, 1)'

const activeStyle = p =>
  p.active &&
  css`
    opacity: ${opacity};
    pointer-events: all;
  `

const StyledOverlay = styled.div`
  background-color: ${color};
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity ${animationDuration} ${animationCurve};
  width: 100vw;
  ${activeStyle};
`

class Overlay extends Component {
  static propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    lockScroll: PropTypes.bool,
    onClick: PropTypes.func,
    onEscKeyDown: PropTypes.func,
    theme: PropTypes.shape({
      active: PropTypes.string,
      backdrop: PropTypes.string,
      overlay: PropTypes.string,
    }),
  }

  static defaultProps = {
    lockScroll: true,
  }

  componentDidMount() {
    const { active, lockScroll, onEscKeyDown } = this.props
    if (onEscKeyDown) document.body.addEventListener('keydown', this.handleEscKey)
    if (active && lockScroll) document.body.style.overflow = 'hidden'
  }

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.lockScroll) {
      const becomingActive = nextProps.active && !this.props.active
      const becomingUnactive = !nextProps.active && this.props.active

      if (becomingActive) {
        document.body.style.overflow = 'hidden'
      }

      if (becomingUnactive && !document.querySelectorAll('[data-react-toolbox="overlay"]')[1]) {
        document.body.style.overflow = ''
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.onEscKeyDown) {
      if (this.props.active && !prevProps.active) {
        document.body.addEventListener('keydown', this.handleEscKey)
      } else if (!this.props.active && prevProps.active) {
        document.body.removeEventListener('keydown', this.handleEscKey)
      }
    }
  }

  componentWillUnmount() {
    if (this.props.active && this.props.lockScroll) {
      if (!document.querySelectorAll('[data-react-toolbox="overlay"]')[1]) {
        document.body.style.overflow = ''
      }
    }

    if (this.props.onEscKeyDown) {
      document.body.removeEventListener('keydown', this.handleEscKey)
    }
  }

  handleEscKey = e => {
    if (this.props.active && this.props.onEscKeyDown && e.which === 27) {
      this.props.onEscKeyDown(e)
    }
  }

  handleClick = event => {
    event.preventDefault()
    event.stopPropagation()
    if (this.props.onClick) {
      this.props.onClick(event)
    }
  }

  render() {
    const { active, lockScroll, theme, onEscKeyDown, ...other } = this.props // eslint-disable-line
    return (
      <StyledOverlay
        {...other}
        onClick={this.handleClick}
        active={active}
        data-react-toolbox='overlay'
      />
    )
  }
}

export default Overlay
