import styled from '@emotion/styled'
import { RadioButton } from '../../../guided-toolbox'

const PensionSingleStyle = styled.div``
PensionSingleStyle.SVGContainer = styled.div`
  position: relative;
  height: 120px;
  width: 120px;
  margin-right: 20px;
`
PensionSingleStyle.SVGTextSurvivor = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
`
PensionSingleStyle.Amount = styled.div`
  text-align: center;
  cursor: pointer;
  &::before {
    content: '$';
    color: #838383;
  }
`
PensionSingleStyle.Name = styled.div`
  color: #838383;
  text-align: center;
  cursor: pointer;
`
PensionSingleStyle.Label = styled.div`
  color: #7a8e96;
  font-size: 1rem;
  word-break: break-word;
  padding: 5px 0;
`
PensionSingleStyle.Error = styled.div`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
`

PensionSingleStyle.RadioButton = styled(RadioButton)`
  position: relative;
  height: 160px;
  margin-left: 20px;
  margin-right: 30px;
`
export { PensionSingleStyle }
