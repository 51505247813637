/* eslint-disable import/no-anonymous-default-export */
import { css, keyframes, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const checkmarkExpand = keyframes`
  0% {
    height: 0;
    left: ${reduceCSSCalc(`calc(0.6 * ${ds.get('unit')})`)};
    top: ${reduceCSSCalc(`calc(0.9 * ${ds.get('unit')})`)};
    width: 0;
  }

  100% {
    height: ${reduceCSSCalc(`calc(1.2 * ${ds.get('unit')})`)};
    left: ${reduceCSSCalc(`calc(0.4 * ${ds.get('unit')})`)};
    top: ${reduceCSSCalc(`calc(-0.1 * ${ds.get('unit')})`)};
    width: ${reduceCSSCalc(`calc(0.7 * ${ds.get('unit')})`)};
  }
`

const check = 'rt-checkbox-check'

const checked = 'rt-checkbox-checked'

const disabled = 'rt-checkbox-disabled'

const field = 'rt-checkbox-field'

const input = 'rt-checkbox-input'

const ripple = 'rt-checkbox-ripple'

const text = 'rt-checkbox-text'

const _field = css`
  display: block;
  height: ${ds.get('checkbox.size')};
  margin-bottom: ${ds.get('checkbox.fieldMarginBottom')};
  position: relative;
  white-space: nowrap;

  ${ds.get('reset')};

  & .${ripple} {
    background-color: ${ds.get('checkbox.color')};
    opacity: 0.3;
    transition-duration: ${ds.get('checkbox.rippleDuration')};
  }
`

const _text = css`
  color: ${ds.get('checkbox.textColor')};
  display: inline-block;
  font-size: ${ds.get('checkbox.textFontSize')};
  line-height: ${ds.get('checkbox.size')};
  padding-left: ${ds.get('unit')};
  vertical-align: top;
  white-space: nowrap;
`

const _input = css`
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0;

  &:focus ~ .${check} {
    &::before {
      background-color: ${ds.get('checkbox.focusColor')};
      border-radius: 50%;
      content: '';
      height: ${ds.get('checkbox.focusSize')};
      left: 50%;
      margin-left: ${reduceCSSCalc(`calc(-1 * ${ds.get('checkbox.focusSize')} / 2)`)};
      margin-top: ${reduceCSSCalc(`calc(-1 * ${ds.get('checkbox.focusSize')} / 2)`)};
      pointer-events: none;
      position: absolute;
      top: 50%;
      width: ${ds.get('checkbox.focusSize')};
    }

    &.${checked}::before {
      background-color: ${ds.get('checkbox.focusCheckedColor')};
    }
  }
`

const _check = css`
  border-color: ${ds.get('checkbox.borderColor')};
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: inline-block;
  height: ${ds.get('checkbox.size')};
  position: relative;
  transition-duration: ${ds.get('checkbox.transitionDuration')};
  transition-property: background-color;
  transition-timing-function: ${ds.get('animation.curveDefault')};
  vertical-align: top;
  width: ${ds.get('checkbox.size')};

  ${ds.get('reset')};

  &.${checked} {
    background-color: ${ds.get('checkbox.color')};
    border-color: ${ds.get('checkbox.color')};

    &::after {
      animation: ${checkmarkExpand} 140ms ease-out forwards;
      border-bottom-width: 2px;
      border-color: ${ds.get('colors.rt.background')};
      border-left: 0;
      border-right-width: 2px;
      border-style: solid;
      border-top: 0;
      content: '';
      height: ${reduceCSSCalc(`calc(1.2 * ${ds.get('unit')})`)};
      left: ${reduceCSSCalc(`calc(0.4 * ${ds.get('unit')})`)};
      position: absolute;
      top: ${reduceCSSCalc(`calc(-0.1 * ${ds.get('unit')})`)};
      transform: rotate(45deg);
      width: ${reduceCSSCalc(`calc(0.7 * ${ds.get('unit')})`)};
    }
  }
`

const _disabled = css`
  & > .${text} {
    color: ${ds.get('checkbox.disabledColor')};
  }

  & > .${check} {
    border-color: ${ds.get('checkbox.disabledColor')};
    cursor: auto;

    &.${checked} {
      background-color: ${ds.get('checkbox.disabledColor')};
      border-color: transparent;
      cursor: auto;
    }
  }
`

export default {
  check: cx(check, _check),
  checked,
  disabled: cx(disabled, _disabled),
  field: cx(field, _field),
  input: cx(input, _input),
  ripple,
  text: cx(text, _text),
}
