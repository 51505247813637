// This component needs to check for existing plaid credentials
// If credentials and institutions exist, render the drawer which lets them select
// an institution from the list OR "Add new financial institution" button which triggers PlaidLink Login again
// If credentials or institutions do not exist, render the PlaidLink login button

import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { usePlaidLink } from 'react-plaid-link'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'

import { API } from '../../../../api'
import { Unlink, Refresh } from '../../../../assets/icons'
import { HelpIcon, TooltipText } from '../../../../components'
import PlaidLinkDrawer from './PlaidLinkDrawer'

const LinkAccount = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  max-width: 340px;
  height: 76px;
  border-radius: 4px;
  background: ${p => p.theme.buttonColor};
  cursor: pointer;
  padding: 8px;
  margin-left: 12px;
  text-align: center;
  &:hover {
    background: ${p => p.theme.hoverButtonColor};
  }
  &:active {
    background: ${p => p.theme.activeButtonColor};
  }
  @media only screen and (max-width: 800px) {
    margin: 24px 0 0 0;
  }
`
const ButtonTopText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 1.375rem;
  user-select: none;
`
const ButtonBottomText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 0.875rem;
  user-select: none;
`
const plaidLinkStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
}

const LinkStyle = styled.a`
  font-size: 14px;
  color: ${p => p.theme.primaryColor};
  cursor: pointer;
  background-color: transparent !important;
  text-decoration: underline;
  border: none;
`

function PlaidLink({ store, onSuccess, accountId, bigButton, planType }) {
  const [state, setState] = useState('preload')
  const [selectedInstitution, setSelectedInstitution] = useState('')
  const [institutionsData, setInstitutionsData] = useState([])
  const [plaidAccounts, setPlaidAccounts] = useState([])
  const [plaidLinkToken, setPlaidLinkToken] = useState('')
  const [drawerActive, setDrawerActive] = useState(false)
  const { getLinkToken } = store

  const { open, exit, ready, error } = usePlaidLink({
    onSuccess: handlePlaidLoginSuccess,
    onExit: (err, metadata) => {
      console.log('Exiting Plaid')
      if (err) {
        console.error(err)
      }
    },
    token: plaidLinkToken,
  })

  const openPlaid = () => {
    if (ready) {
      open()
    }
  }

  useEffect(() => {
    if (error) {
      console.log('Plaid Error')
      exit()
    }
  }, [error, exit])

  useEffect(() => {
    const fetchPlaidLinkToken = async () => {
      try {
        const token = await getLinkToken()
        setPlaidLinkToken(token)
      } catch (err) {
        console.error(err)
        setState('preload-error')
      }
    }

    fetchInstitutions()
    fetchPlaidLinkToken()
  }, [getLinkToken])

  async function fetchInstitutions() {
    try {
      // Used to fetch existing available institutions list available for linking if credentials still valid
      const institutions = await API.get('aggregation/link')
      setInstitutionsData(institutions.data)

      setState('done')
    } catch (err) {
      console.error(err)
      setState('preload-error')
    }
  }

  // Save aggregationAccounts data and pass it to the Drawer for selection
  async function handlePlaidLoginSuccess(token, metadata) {
    try {
      const aggregationAccounts = await API.post('aggregation/link', {
        publicToken: token,
      })
      setPlaidAccounts(aggregationAccounts.data)
      setSelectedInstitution(_.get(metadata, 'institution.name', 'your institution'))
      setDrawerActive(true)
    } catch (err) {
      console.error(err)
      setState('plaid-login-error')
    }
  }

  // Call this after they successfully select the institution and/or account they want to link
  async function handleAccountLinkSuccess() {
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  }

  // If institutions exist, open the Drawer and have them select from the list
  function handleInstitutionsClick() {
    setDrawerActive(true)
  }

  function handleCloseDrawer() {
    setDrawerActive(false)
  }

  // Return nothing if preloading or fetching institutions failed
  if (state === 'preload' || state === 'preload-error') {
    return null
  }

  // If institutions exist, render a button that activates the Drawer
  if (!_.isEmpty(institutionsData) && bigButton) {
    return (
      <LinkAccount>
        <div style={plaidLinkStyle} onClick={handleInstitutionsClick} />
        <ButtonTopText>Link account</ButtonTopText>
        <ButtonBottomText>import investments</ButtonBottomText>
        <PlaidLinkDrawer
          active={drawerActive}
          fetchInstitutions={fetchInstitutions}
          institutionsData={institutionsData}
          plaidAccounts={plaidAccounts}
          setPlaidAccounts={setPlaidAccounts}
          onHandleCloseDrawer={handleCloseDrawer}
          onSuccessfulLink={handleAccountLinkSuccess}
          accountId={accountId}
          planType={planType}
          openPlaid={openPlaid}
          selectedInstitution={selectedInstitution}
          setSelectedInstitution={setSelectedInstitution}
          state={state}
          setState={setState}
        />
        <div
          css={css`
            position: absolute;
            top: 0;
            right: -30px;
          `}>
          <HelpIcon tooltip={TooltipText.plaidHelpContent()} />
        </div>
      </LinkAccount>
    )
  }

  // Else, render the Plaid Link for them to Login
  if (_.isEmpty(institutionsData) && bigButton) {
    return (
      <LinkAccount>
        <div style={plaidLinkStyle} onClick={openPlaid} />
        <ButtonTopText>Link account</ButtonTopText>
        <ButtonBottomText>import investments</ButtonBottomText>
        <div
          css={css`
            position: absolute;
            top: 0;
            right: -30px;
          `}>
          <HelpIcon tooltip={TooltipText.plaidHelpContent()} />
        </div>
        <PlaidLinkDrawer
          active={drawerActive}
          fetchInstitutions={fetchInstitutions}
          institutionsData={institutionsData}
          plaidAccounts={plaidAccounts}
          setPlaidAccounts={setPlaidAccounts}
          onHandleCloseDrawer={handleCloseDrawer}
          onSuccessfulLink={handleAccountLinkSuccess}
          accountId={accountId}
          planType={planType}
          openPlaid={openPlaid}
          selectedInstitution={selectedInstitution}
          setSelectedInstitution={setSelectedInstitution}
          state={state}
          setState={setState}
        />
      </LinkAccount>
    )
  }

  // The following are the default buttons
  if (!_.isEmpty(institutionsData)) {
    return (
      <span>
        <PlaidButton onClick={handleInstitutionsClick}>Link</PlaidButton>
        <PlaidLinkDrawer
          active={drawerActive}
          fetchInstitutions={fetchInstitutions}
          institutionsData={institutionsData}
          plaidAccounts={plaidAccounts}
          setPlaidAccounts={setPlaidAccounts}
          onHandleCloseDrawer={handleCloseDrawer}
          onSuccessfulLink={handleAccountLinkSuccess}
          accountId={accountId}
          planType={planType}
          openPlaid={openPlaid}
          selectedInstitution={selectedInstitution}
          setSelectedInstitution={setSelectedInstitution}
          state={state}
          setState={setState}
        />
      </span>
    )
  }

  return (
    <span>
      <LinkStyle onClick={openPlaid}>Link</LinkStyle>
      <PlaidLinkDrawer
        active={drawerActive}
        fetchInstitutions={fetchInstitutions}
        institutionsData={institutionsData}
        plaidAccounts={plaidAccounts}
        setPlaidAccounts={setPlaidAccounts}
        onHandleCloseDrawer={handleCloseDrawer}
        onSuccessfulLink={handleAccountLinkSuccess}
        accountId={accountId}
        planType={planType}
        openPlaid={openPlaid}
        selectedInstitution={selectedInstitution}
        setSelectedInstitution={setSelectedInstitution}
        state={state}
        setState={setState}
      />
    </span>
  )
}

const PlaidRelink = inject('store')(
  observer(function ({ store, accessId, onSuccess }) {
    const [plaidLinkToken, setPlaidLinkToken] = useState('')
    const { getUpdateLinkToken } = store

    const { open, exit, ready, error } = usePlaidLink({
      onSuccess,
      onExit: (err, metadata) => {
        console.log('Exiting Plaid')
        if (err) {
          console.error(err)
        }
      },
      token: plaidLinkToken,
    })

    const openPlaid = () => {
      if (ready) {
        open()
      }
    }

    useEffect(() => {
      if (error) {
        console.log('Plaid Error')
        exit()
      }
    }, [error, exit])

    useEffect(() => {
      const fetchPlaidLinkToken = async () => {
        try {
          const token = await getUpdateLinkToken(accessId)
          setPlaidLinkToken(token)
        } catch (err) {
          console.error(err)
        }
      }

      fetchPlaidLinkToken()
    }, [getUpdateLinkToken, accessId])

    return (
      <span
        css={css`
          font-size: 14px;
          color: #038ab7;
          cursor: pointer;
          background-color: transparent;
          text-decoration: underline;
        `}
        style={{ border: 'none' }}
        onClick={openPlaid}>
        Relink
      </span>
    )
  })
)

// Clickable link to unlink their Plaid account with non-GC account
const PlaidUnlink = inject('store')(
  observer(function ({ store, accountId, className, onSuccess }) {
    async function unlinkPlaidLink() {
      await store.unlinkPlaidAccount(accountId)

      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }

    return (
      <button className={className} onClick={unlinkPlaidLink}>
        <Unlink
          css={css`
            margin: 0 6px 0 0;
          `}
        />
        Unlink
      </button>
    )
  })
)

// Clickable link to refresh their Plaid account linked with non-GC account
const PlaidRefresh = inject('store')(
  observer(function ({ store, accountId, className, onSuccess }) {
    async function refreshPlaidLink() {
      await store.refreshPlaidAccount(accountId)

      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }

    return (
      <button className={className} onClick={refreshPlaidLink}>
        <Refresh
          css={css`
            margin: 0 6px 0 0;
          `}
        />
        Refresh
      </button>
    )
  })
)

const PlaidButton = styled.button`
  font-size: 14px;
  color: ${p => p.theme.editColor};
  cursor: pointer;
  background-color: transparent !important;
  text-decoration: underline;
  border: none;
`

export { PlaidRelink, PlaidUnlink, PlaidRefresh }

export default inject('store')(observer(PlaidLink))
