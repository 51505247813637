import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { FieldContainer, Title, BulletText } from '../styled'

class OtherAccounts extends Component {
  render() {
    return (
      <FieldContainer>
        <Title>Update the investment mix for each of the following accounts:</Title>
        {this.props.accounts.map(account => {
          return <BulletText key={_.uniqueId()}>{account.name}</BulletText>
        })}
      </FieldContainer>
    )
  }
}

export default inject('store')(observer(OtherAccounts))
