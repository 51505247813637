import styled from '@emotion/styled'

export const AccountGoalBox = styled.div`
  background-color: ${p => (p.isHighlighted ? p.theme.containerColor : 'transparent')};
  color: ${p => (p.isHighlighted ? p.theme.primaryColor : p.theme.subLabelColor)};
  cursor: ${p => (p.cursor ? p.cursor : 'pointer')};
  line-height: 1.45;
  padding: 7px 0px;
  white-space: normal;
  min-width: 248px;
  transition: color 0.3s, background-color 0.3s;
`

export const RowSD = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px 0px 16px;
  white-space: normal;
  min-width: 248px;
`

export const LabelSD = styled.div`
  font-size: 14px;
  word-break: break-word;
  white-space: pre-wrap;
  min-width: 142px;
`

export const BalanceSD = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 14px;
  color: ${p => (p.isHighlighted ? p.theme.sideBarLabelColor : p.theme.lightestGray)};
  text-align: right;
`

export const ArrowIconRow = styled.div`
  margin: 18px 18px 32px 18px;
  display: flex;
  justify-content: flex-end;
  flex: 0 1 auto;
`
export const ArrowBox = styled.div`
  position: absolute;
  padding: 20px 20px 20px 20px;
  margin-left: -40px;
  margin-top: -20px;
  width: 100%;
  background-color: ${p => p.theme.topNavColor};
`

export const SideDrawerButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 248px;
`

export const FlexSpace = styled.div`
  flex-grow: 1;
`
export const AccountTypeLabel = styled.div`
  padding: 8px 0px;
  color: #9eacb0;
  font-size: 14px;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`

export const MainSideBarLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 0 0 16px;
  height: 44px;
  width: 100%;
  max-width: 100%;
  color: ${p => (p.isHighlighted ? p.theme.primaryColor : p.theme.sideBarLabelColor)};
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  background-color: ${p => (p.isHighlighted ? p.theme.containerColor : 'transparent')};
  transition: color 0.3s, background-color 0.3s;
  min-width: 248px;
`
export const MainBar = styled.div`
  width: ${p => (p.showSidebar ? '250px' : '0px')};
  border: ${p => p.theme.sideBarDrawerBorder};
  background-color: ${p => p.theme.sideBarBgColor};
  box-shadow: 0 2px 2px 0 rgba(182, 192, 196, 0.15);
  transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  overflow-x: hidden;
  white-space: nowrap;
  position: relative;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 0px; /* remove scrollbar space */
    background: transparent; /* optional: just make scrollbar invisible */
  }
  -ms-overflow-style: none;
`

export const OverallSub = styled.div`
  color: ${p => p.theme.subLabelColor};
  font-size: 14px;
  line-height: 1.45;
  margin: 14px 1rem;
  cursor: pointer;
`
