import styled from '@emotion/styled'

const Header = styled.div`
  background-color: #012a3a;
  padding-bottom: 18px;
`

const HeaderContainer = styled.div`
  padding-top: 12px;
  padding-bottom: 40pt;
`

const HeaderHeading = styled.div`
  color: white;
  font-size: 22pt;
  padding: 0 0 20pt;
`
const HeaderText = styled.div`
  color: white;
  font-size: 12pt;
  line-height: 1.5;
`

const SnapshotBanner = styled.div`
  background-color: #038ab7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155pt;
  transform: translateY(-50%);
  height: 40pt;
`
const SnapshotText = styled.div`
  color: #ffffff;
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 15pt;
  font-weight: bold;
`

const IndentContainer = styled.div`
  padding-top: 12px;
  padding-left: ${p => (p.paddingLeft ? p.paddingLeft : null)};
`

// TODO replace usage of margin
const TableRow = styled.div`
  padding: 6pt 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${p => (p.childPadding ? `0 -${p.childPadding}` : null)};

  & > * {
    margin: ${p => (p.childPadding ? `0 ${p.childPadding}` : null)};
  }
`

const FillSVGWrapper = styled.div`
  padding: 0 16px;
`

const LogoSVGWrapper = styled.div`
  padding-top: 24px;
  width: 100%;
  max-width: 150pt;
`

export {
  Header,
  HeaderContainer,
  HeaderHeading,
  HeaderText,
  SnapshotBanner,
  SnapshotText,
  IndentContainer,
  TableRow,
  FillSVGWrapper,
  LogoSVGWrapper,
}
