import { css } from '@emotion/react'
import { useState } from 'react'
import { inject, Observer } from 'mobx-react'

import { numberToDollars } from '../../../utils'
import { Page, PageHeader } from '../elements'

function VestedStockOptions(props) {
  useState(() => {
    props.guide.guidePages.pages.addPage({
      name: 'vestedStockOptions',
    })
  })

  const { features, acceptedCase } = props.store

  const includedStockOptions = acceptedCase.stockOptions.filter(stockOption => stockOption.included)

  const page = props.guide.guidePages.pages.findByName('vestedStockOptions')

  function render() {
    return (
      features.stockOptionsEnabled &&
      includedStockOptions.length > 0 && (
        <Page header={<PageHeader text='Snapshot' />} page={page}>
          <div
            css={css`
              min-height: 640px;
            `}>
            <div
              css={css`
                color: #022a3a;
                font-size: 1rem;
                padding-bottom: 12px;
              `}>
              VESTED STOCK OPTIONS INCLUDED IN PROJECTIONS
            </div>

            {includedStockOptions.map(stockOption => (
              <div
                key={stockOption.id}
                css={css`
                  padding: 12px 0;
                  width: 70%;
                `}>
                <div
                  css={css`
                    background-color: #f3f4f5;
                    color: #7a8e96;
                    padding: 1rem;
                  `}>
                  <div
                    css={css`
                      padding-bottom: 8px;
                    `}>
                    <b>Grant</b> {stockOption.optionGrantNo} |{' '}
                    {numberToDollars(stockOption.quantity, true)} <b>Options</b> | <b>Vested</b>{' '}
                    {new Intl.DateTimeFormat('en-US', {
                      timeZone: 'America/Los_Angeles',
                    }).format(new Date(stockOption.vestedDate))}
                  </div>
                  <div>
                    <b>Exercise price</b> ${numberToDollars(stockOption.exercisePrice, true)} |{' '}
                    <b>Current price</b> ${numberToDollars(stockOption.currentPrice, true)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Page>
      )
    )
  }

  return <Observer>{render}</Observer>
}

export default inject('store', 'guide')(VestedStockOptions)
