import { css } from '@emotion/react'
import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import { Container } from './styles'

import Intro from './Intro'
import IncomeGoal from './IncomeGoal'
import EmployerPlanAccount from './EmployerPlanAccount'
import HomeActions from './HomeActions'

import { Drawer, Loading } from '../../components'
import UnderstandingYourResults from '../spend-down/understanding-your-results/UnderstandingYourResults'

function Home(props) {
  const [mode, setMode] = useState('idle')
  const {
    history,
    main: { sidebarActive, toggleSidebar, expandSidebar },
    store: {
      getBaseAdvicesForSpending,
      allSpendingRecommendedCases,
      getRecommendedAdvicesForSpending,
      spendingRecommendedCases,
      getBaseCase,
      recommendedCase,
      getRecommendedCase,
      getSurvivorPension,
      config: { spendingMode, spendDownModeling, disableSpendDownModeling, isRetail, isSpendown },
      features: { enableSpendDown },
      config,
      auth: { hasStripeSessionId, retailLinkAccount, deleteStripeSessionId },
    },
  } = props
  const [status, setStatus] = useState('loading')
  const [drawer, setDrawer] = useState('')

  const handleCancel = useCallback(() => setDrawer(''), [setDrawer])

  useEffect(() => {
    if (spendDownModeling) {
      disableSpendDownModeling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!config.onBoarded) {
      history.replace('/welcome')
    }
  }, [config.onBoarded]) // eslint-disable-line

  useEffect(() => {
    const handleRetailLinkAccount = async () => {
      if (hasStripeSessionId) {
        try {
          await retailLinkAccount()
          deleteStripeSessionId()
        } catch (e) {
          console.log(e)
        }
      }
    }
    handleRetailLinkAccount()
  }, []) // eslint-disable-line

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getBaseCase()
        if (_.isEmpty(recommendedCase)) {
          await getRecommendedCase()
        }
        await getSurvivorPension()

        if (enableSpendDown) {
          if (spendingMode) {
            await getBaseAdvicesForSpending()
            if (_.isEmpty(spendingRecommendedCases)) {
              await getRecommendedAdvicesForSpending()
            }
            setMode('spending')
          } else {
            setMode('retWithinThreeYears')
          }
        } else {
          setMode('retMoreThanThreeYearsAway')
        }
        setStatus('success')
      } catch (err) {
        setStatus('error')
        console.error(err)
      }
    }

    fetchData()
  }, [
    enableSpendDown,
    getBaseAdvicesForSpending,
    getBaseCase,
    getRecommendedAdvicesForSpending,
    getRecommendedCase,
    getSurvivorPension,
    recommendedCase,
    spendingMode,
    spendingRecommendedCases,
  ])

  useEffect(() => {
    if (sidebarActive) {
      toggleSidebar()
    }
    return () => expandSidebar()
  }, []) // eslint-disable-line

  if (status === 'error') {
    return (
      <div
        css={css`
          color: #e31e27;
          font-size: 14px;
          line-height: 1.5;
          text-align: center;
          padding: 0.25rem 0 0.75rem 0;
        `}>
        Something went wrong, please try again later.
      </div>
    )
  }

  const displayEmployerPlanAccount = isRetail ? (!isSpendown ? false : true) : true

  return status === 'success' ? (
    <Container>
      <Intro history={history} mode={mode} />
      <IncomeGoal mode={mode} />
      {displayEmployerPlanAccount && (
        <EmployerPlanAccount
          allSpendingRecommendedCases={allSpendingRecommendedCases}
          mode={mode}
          setDrawer={setDrawer}
        />
      )}
      <HomeActions history={history} mode={mode} />
      <Drawer active={drawer === 'Personalized Help'} width='100%'>
        <UnderstandingYourResults setDrawer={setDrawer} handleCancel={handleCancel} />
      </Drawer>
    </Container>
  ) : (
    <Loading />
  )
}

export default inject('store', 'main')(observer(Home))
