/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { ButtonsBox, NextButton, PageTitle, Spacer, Container, Loading } from '../../../components'
import { StyledLink } from '../styled'
import InvestmentsDisplay from './components/InvestmentsDisplay'
import InstitutionalInvestmentsDisplay from './components/InstitutionalInvestmentsDisplay'

const ErrorMessage = () => (
  <div
    css={css`
      color: crimson;
    `}>
    Something went wrong, please try again later.
  </div>
)

const AddInvestments = props => {
  const [status, setStatus] = useState('loading')
  const { account, getInvestments, getAccount } = props.account
  const { type, id, accountType } = props.match.params
  const { getFeatures, institutionalAccountUnderReview, institutionalAccount } = props.store
  const isInstitutionalAccount = props.account?.account?.id === institutionalAccount?.id

  const fetchInitialState = useCallback(async () => {
    try {
      if (!account) {
        await getAccount({ type, id })
      }

      await getInvestments({ type, id })

      setStatus('done')
    } catch (err) {
      console.error(err)
      setStatus('error')
    }
  }, [account, getAccount, getInvestments, id, type])

  useEffect(() => {
    fetchInitialState()

    const fetchFeatures = async () => {
      await getFeatures()
    }

    return () => fetchFeatures()
  }, [fetchInitialState, getFeatures, institutionalAccountUnderReview])

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return <ErrorMessage />
  }

  const { investments } = props.account

  return (
    <div
      css={css`
        max-width: 1200px;
        margin: auto;
        padding: 1rem;
      `}>
      <Container>
        <PageTitle>Let's add an investment</PageTitle>

        {isInstitutionalAccount ? (
          <InstitutionalInvestmentsDisplay history={props.history} match={props.match} />
        ) : (
          <InvestmentsDisplay />
        )}

        <Spacer space='20px' />
        <StyledLink>
          <Link to={`/accounts/${type}/${accountType}/${id}`}>
            Skip this step - I'll come back to it later
          </Link>
        </StyledLink>
        {!!investments.length && (
          <ButtonsBox>
            <div />
            <Link to={`/accounts/${type}/${accountType}/${id}`}>
              <NextButton width='254px'>All investments added</NextButton>
            </Link>
          </ButtonsBox>
        )}
      </Container>
    </div>
  )
}

export default inject('account', 'store')(observer(AddInvestments))
