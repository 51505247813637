const piggyBank = `
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="testingNew"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="-icons/accounts/contribute-normal"
        fill-rule="nonzero"
        class="icon-color"
      >
        <g id="piggy-bank-copy">
          <path
            d="M8.53214343,0 C7.35847012,0 6.40360159,0.93621875 6.40360159,
              2.08696875 C6.40360159,3.23771875 7.35847012,4.1739375
              8.53214343,4.1739375 C9.70581673,4.1739375 10.6606853,3.23771875
              10.6606853,2.08696875 C10.6606534,0.93621875 9.70581673,0
              8.53214343,0 Z M8.53214343,3.1304375 C7.94530677,3.1304375
              7.46788845,2.66234375 7.46788845,2.08696875 C7.46788845,
              1.51159375 7.94530677,1.0435 8.53214343,1.0435 C9.11898008,
              1.0435 9.59639841,1.51159375 9.59639841,2.08696875 C9.59639841,
              2.66234375 9.11898008,3.1304375 8.53214343,3.1304375 Z"
            id="Shape"
          >
          </path>
          <path
            d="M10.1285418,7.6521875 L6.93574502,7.6521875 C6.64188048,
              7.6521875 6.40360159,7.88578125 6.40360159,8.1739375 C6.40360159,
              8.4620625 6.64184861,8.6956875 6.93574502,8.6956875 L10.1285418,
              8.6956875 C10.4224064,8.6956875 10.6606853,8.46209375 10.6606853,
              8.1739375 C10.6606534,7.88578125 10.4224064,7.6521875 10.1285418,
              7.6521875 Z"
            id="Shape"
          >
          </path>
          <path
            d="M14.3855936,8.69565625 C14.1168446,8.69565625 13.8635219,
              8.76140625 13.6410837,8.87690625 C13.037259,6.96109375
              11.2139602,5.56521875 9.06428685,5.56521875 L5.87145817,
              5.56521875 C5.75684462,5.56521875 5.64194422,5.56921875
              5.52761753,5.5771875 C4.84713944,4.72621875 3.85287649,
              4.15915625 2.75388048,4.00525 C2.43359363,3.960625 2.14651793,
              4.2040625 2.14651793,4.52175 L2.14651793,7.309375 C1.80494024,
              7.72384375 1.53619124,8.195 1.35518725,8.695625 L1.08223108,
              8.695625 C0.495394422,8.695625 0.0179760956,9.16371875
              0.0179760956,9.73909375 L0.0179760956,10.7825625 C0.0179760956,
              11.3579375 0.495394422,11.8260312 1.08223108,11.8260312
              L1.355251,11.8260312 C1.69870916,12.7748437 2.35423108,
              13.6023437 3.21077291,14.1654687 L3.21077291,14.9564687
              C3.21077291,15.5318437 3.68819124,15.9999375 4.27502789,
              15.9999375 L5.33928287,15.9999375 C5.92611952,15.9999375
              6.40353785,15.5318437 6.40353785,14.9564687 L8.53207968,
              14.9564687 C8.53207968,15.5318437 9.00949801,15.9999375
              9.59633466,15.9999375 L10.6605896,15.9999375 C11.2474263,
              15.9999375 11.7248446,15.5318437 11.7248446,14.9564687
              L11.7248446,14.165625 C13.0452271,13.2985312 13.8533865,
              11.8293437 13.8533865,10.2608125 C13.8533865,9.973125
              14.0921116,9.7390625 14.3855299,9.7390625 C14.6789482,
              9.7390625 14.9176733,9.973125 14.9176733,10.2608125 C14.9176733,
              10.5489375 15.1559203,10.7825625 15.4498167,10.7825625
              C15.7437131,10.7825625 15.9819602,10.5489687 15.9819602,
              10.2608125 C15.981992,9.3978125 15.2658486,8.69565625 14.3855936,
              8.69565625 Z M10.9263426,13.4247188 C10.7619124,13.518
              10.6606534,13.690125 10.6606534,13.8763438 L10.6606534,
              14.9565313 L9.59639841,14.9565313 L9.59639841,14.4347813
              C9.59639841,14.1466563 9.35815139,13.9130313 9.06425498,
              13.9130313 L5.87145817,13.9130313 C5.57759363,13.9130313
              5.33931474,14.146625 5.33931474,14.4347813 L5.33931474,
              14.9565313 L4.27505976,14.9565313 L4.27505976,13.8763438
              C4.27505976,13.690125 4.1738008,13.5179688 4.00937052,
              13.4247188 C3.14709163,12.935625 2.51088446,12.1154063
              2.26387251,11.1743125 C2.20334661,10.94375 1.9912988,
              10.7825938 1.7485259,10.7825938 L1.08229482,10.7825938
              L1.08229482,9.739125 L1.74855777,9.739125 C1.99136255,
              9.739125 2.20337849,9.57796875 2.26387251,9.3474375 C2.40889243,
              8.79490625 2.69032669,8.2755 3.07760956,7.84528125 C3.16344223,
              7.7499375 3.21077291,7.62715625 3.21077291,7.5 L3.21077291,
              5.183375 C3.86881275,5.40884375 4.44194422,5.83965625 4.83470916,
              6.4168125 C4.94807968,6.58346875 5.14871713,6.67178125 5.3510757,
              6.6439375 C5.52181673,6.62059375 5.69689243,6.6086875 5.87145817,
              6.6086875 L9.06425498,6.6086875 C11.1181833,6.6086875 12.7891952,
              8.2470625 12.7891952,10.260875 C12.7891952,11.5607188 12.0753785,
              12.7730938 10.9263426,13.4247188 Z"
            id="Shape"
          >
          </path>
          <ellipse id="Oval" cx="4.27505976" cy="8.69565625" rx="1" ry="1">
          </ellipse>
        </g>
      </g>
    </g>
  </svg>
`

export default piggyBank
