import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { css } from '@emotion/react'

import { Page } from '../elements'
import { Button, Spacer } from '../../../components'

class Greeting extends Component {
  clientTitle = () => {
    const { config } = this.props.onboarding
    const { isRetail } = config

    // Excludes FRS plans from showing that Welcome conflict text with Welcome New Changes Page
    if (config.template.isMigrating && !isRetail) {
      return "Let's get planning!"
    }

    return 'Welcome to your GuidedChoice account!'
  }

  render() {
    const { config } = this.props.onboarding
    const { isRetail } = config

    const handleClick = () => {
      if (isRetail) {
        return this.props.history.push('/home')
      }
      this.props.history.push('/welcome/income-goal')
    }

    return (
      <Page>
        <Page.Heading title={this.clientTitle()} />
        <Page.AnimateGroup>
          <Page.AnimateItem
            css={css`
              padding-right: 75px;
            `}>
            <Spacer space='10px' />
            {isRetail ? (
              <>
                <Page.Text>
                  The key to getting started is to add information about your financial resources
                  related to retirement planning—such as work-related investment accounts and
                  IRAs—and plan for specific financial goals.
                </Page.Text>
                <Spacer space='10px' />
                <Page.Text>
                  If you don't yet have any accounts added, not all the numbers will make sense on
                  your home page. Your action item is to add at least one account. After that, it's
                  up to you: get projected results based on this one account, add other accounts,
                  set financial goals, or do some scenario planning. No matter what, we'll help you
                  set a confident path for your retirement.
                </Page.Text>
              </>
            ) : (
              <>
                <Page.Text>
                  For the most accurate picture, we recommend adding information about any other
                  financial resources related to retirement planning—such as work-related investment
                  accounts and IRAs—and planning for specific financial goals.
                </Page.Text>
                <Spacer space='10px' />
                <Page.Text>
                  But you don’t have to. What’s been provided by your employer is enough to give you
                  an idea of how you’re doing. From the next page, which is your home page, you can
                  get projected results based on your employer plan account, add other accounts, set
                  financial goals, or do some scenario planning. No matter what, we’ll help you set
                  a confident path for your retirement.
                </Page.Text>
              </>
            )}
            <Spacer space='20px' />
          </Page.AnimateItem>
          <Page.AnimateItem>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                width: 90%;
              `}>
              <Button
                id='lets-get-planning-ok-button'
                primary
                label='Ok'
                width='100px'
                onClick={handleClick}></Button>
            </div>
          </Page.AnimateItem>
        </Page.AnimateGroup>
      </Page>
    )
  }
}

export default inject('onboarding')(observer(Greeting))
