import variables from '../variables'
import colors from '../colors'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { grey200 },
} = colors
const ListContentLeftSpacing = `calc(7.2 * ${unit})`
const ListHorizontalPadding = `calc(1.6 * ${unit})`
const ListItemIconSize = `calc(1.8 * ${unit})`
const ListItemRightIconMargin = `calc(${ListContentLeftSpacing} - 2 * ${ListHorizontalPadding} - ${ListItemIconSize})`

const list = {
  verticalPadding: reduceCSSCalc(`calc(0.8 * ${unit})`),
  horizontalPadding: ListHorizontalPadding,
  contentLeftSpacing: ListContentLeftSpacing,
  subheaderHeight: reduceCSSCalc(`calc(4.8 * ${unit})`),
  subheaderFontSize: reduceCSSCalc(`calc(1.4 * ${unit})`),
  subheaderFontWeight: 500,
  dividerHeight: reduceCSSCalc(`calc(0.1 * ${unit})`),
  itemMinHeight: reduceCSSCalc(`calc(4.8 * ${unit})`),
  itemMinHeightLegend: reduceCSSCalc(`calc(7.2 * ${unit})`),
  itemHoverColor: grey200,
  itemLegendMarginTop: reduceCSSCalc(`calc(0.3 * ${unit})`),
  itemIconFontSize: reduceCSSCalc(`calc(2.4 * ${unit})`),
  itemIconSize: ListItemIconSize,
  itemRightIconMargin: ListItemRightIconMargin,
  itemRightCheckboxMargin: reduceCSSCalc(
    `calc(${ListItemRightIconMargin} + ${ListHorizontalPadding})`
  ),
  itemAvatarHeight: reduceCSSCalc(`calc(4 * ${unit})`),
  itemAvatarMargin: reduceCSSCalc(`calc(0.8 * ${unit})`),
  itemChildMargin: reduceCSSCalc(`calc(0.8 * ${unit})`),
}

export default list
