import { Component } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import { numberToDollars } from '../../utils'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Bank, Coin, LineChart, PieChart } from '../../assets/icons'
import InlineSVG from 'svg-inline-react'

const LIGHTGRAY = '#7a8e96'
const DARKGRAY = '#022a3a'
const DOTLINE = '#69dee3'

const StyledDiv = styled.div`
  position: relative;
  height: ${p => (p.height ? `${p.height}px` : null)};
  padding: ${p => (p.padding ? p.padding : null)};
`

const ChartSVGContainer = styled.div`
  position: absolute;
  display: inline-block;
  height: 36px;
  width: 36px;
  top: 18px;
  left: 45px;

  svg {
    .icon-color {
      fill: ${p => p.theme.lightestGray};
    }
  }
`

const svgMap = {
  Bonds: Bank,
  Cash: Coin,
  Stock: LineChart,
  Misc: PieChart,
}

class SplitBarChartOffboard extends Component {
  constructor() {
    super()

    this.rowYPosition = 0
    this.rowHeight = 64
    this.padding = 10
    this.barCenterPercentage = 75
  }

  static defaultProps = {
    width: 500,
  }

  static propTypes = {
    title: PropTypes.string,
  }

  renderHeight() {
    const { data } = this.props
    let numberRows = 0
    if (data == null) {
      return 0
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i].oldValue !== null || data[i].newValue !== null) {
        numberRows++
      }
    }
    return this.rowHeight * (numberRows + 0.25) + 2 * this.padding
  }

  componentDidMount() {
    this.createBarChart()
  }

  componentDidUpdate = prevProps => {
    if (prevProps.data !== this.props.data) {
      const node = this.node
      this.rowYPosition = 0
      while (node.firstChild) {
        node.removeChild(node.firstChild)
      }
      this.createBarChart()
    }
  }

  appendName = name => {
    const node = this.node
    const nameRowWidth = 416
    const CHARWIDTH = 12
    let counter = 0
    let firstLine = true
    let nameLine1 = ''
    let nameLine2 = ''

    const nameArray = name.split(' ')

    for (let i = 0; i < nameArray.length; i++) {
      counter += nameArray[i].length * CHARWIDTH
      if (counter < nameRowWidth) {
        if (firstLine) {
          nameLine1 += nameArray[i] + ' '
        } else {
          nameLine2 += nameArray[i] + ' '
        }
        counter += 6
      } else if (counter > nameRowWidth && nameLine2 === '') {
        counter = 0
        firstLine = false

        nameLine2 += nameArray[i] + ' '
        counter += 6
      } else if (counter > nameRowWidth && nameLine2) {
        nameLine2 = nameLine2.slice(0, -1) + '...'
        break
      }
    }

    const nameRowOne = d3
      .select(node)
      .append('text')
      .text(`${nameLine1}`)
      .attr('x', '100px')
      .attr('font-family', 'Open Sans')
      .attr('font-size', '14px')
      .attr('fill', DARKGRAY)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')

    if (nameLine2) {
      nameRowOne.attr('y', this.rowYPosition + 0.4 * this.rowHeight)

      d3.select(node)
        .append('text')
        .text(`${nameLine2}`)
        .attr('x', '100px')
        .attr('y', this.rowYPosition + 0.4 * this.rowHeight + 18)
        .attr('font-family', 'Open Sans')
        .attr('font-size', '14px')
        .attr('fill', DARKGRAY)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
    } else {
      nameRowOne.attr('y', this.rowYPosition + 0.5 * this.rowHeight)
    }
  }

  appendIndividualBar = (isOld, percent, dollar, theme) => {
    const node = this.node

    percent = Math.min(percent, 100)

    const maxBarWidth = 20
    const gap = 20
    const barHeight = 14

    const fillColor = isOld ? LIGHTGRAY : theme.primaryColor
    const moveGap = isOld ? gap * -1 : gap
    const textAnchor = isOld ? 'end' : 'start'
    const barWidthPercentage = (maxBarWidth * percent) / 100
    const percentString = numberToDollars(percent, true)

    const dollarPercentText = `$${numberToDollars(dollar)} | ${percentString}%`

    d3.select(node)
      .append('text')
      .text(dollarPercentText)
      .attr('x', `${this.barCenterPercentage}%`)
      .attr('y', this.rowYPosition + 0.5 * this.rowHeight)
      .attr('font-family', 'Open Sans')
      .attr('fill', fillColor)
      .attr('text-anchor', textAnchor)
      .attr('alignment-baseline', 'middle')
      .attr('transform', `translate(${moveGap})`)

    d3.select(node)
      .append('rect')
      .attr('width', `${barWidthPercentage}%`)
      .attr('height', barHeight)
      .attr(
        'x',
        `${isOld ? this.barCenterPercentage - barWidthPercentage : this.barCenterPercentage}%`
      )
      .attr('y', this.rowYPosition + this.rowHeight - barHeight)
      .attr('fill', fillColor)

    d3.select(node)
      .append('line')
      .attr('x1', '100px')
      .attr('x2', '95%')
      .attr('y1', `${this.rowYPosition + this.rowHeight}px`)
      .attr('y2', `${this.rowYPosition + this.rowHeight}px`)
      .attr('alignment-baseline', 'hanging')
      .attr('fill', 'none')
      .attr('stroke', DOTLINE)
      .attr('stroke-dasharray', '1, 3')
      .attr('stroke-linecap', 'round')
  }

  appendLeftBar = (percent, dollar, theme) => {
    this.appendIndividualBar(true, percent, dollar, theme)
  }

  appendRightBar = (percent, dollar, theme) => {
    this.appendIndividualBar(false, percent, dollar, theme)
  }

  nextRow = fraction => {
    if (isNaN(fraction) || fraction === null) {
      fraction = 1
    } else {
      fraction = Math.min(Math.max(0, fraction), 1)
    }

    this.rowYPosition += this.rowHeight * fraction
  }

  createBarChart = () => {
    const { data, hideOld, theme } = this.props
    const node = this.node

    this.nextRow(0.1)

    if (data) {
      for (let i = 0; i < data.length; i++) {
        const { name, newValue } = data[i]

        const oldValue = hideOld ? null : data[i].oldValue

        if (oldValue !== null && newValue !== null) {
          const { dollar: oldDollar, percent: oldPercent } = oldValue
          const { dollar: newDollar, percent: newPercent } = newValue

          this.appendName(name)
          this.appendLeftBar(oldPercent, oldDollar, theme)
          this.appendRightBar(newPercent, newDollar, theme)
          d3.select(node)
            .append('rect')
            .attr('x', `${this.barCenterPercentage}%`)
            .attr('y', this.rowYPosition + 0.1 * this.rowHeight)
            .attr('width', 1)
            .attr('height', 1.1 * this.rowHeight)
            .attr('fill', '#979797')
          this.nextRow()
        } else if (oldValue === null) {
          if (newValue) {
            const { dollar: newDollar, percent: newPercent } = newValue
            this.appendName(name)
            this.appendRightBar(newPercent, newDollar, theme)
            d3.select(node)
              .append('rect')
              .attr('x', `${this.barCenterPercentage}%`)
              .attr('y', this.rowYPosition + 0.1 * this.rowHeight)
              .attr('width', 1)
              .attr('height', 1.1 * this.rowHeight)
              .attr('fill', '#979797')
            this.nextRow()
          }
        } else if (newValue === null) {
          const { dollar: oldDollar, percent: oldPercent } = oldValue
          this.appendName(name)
          this.appendLeftBar(oldPercent, oldDollar, theme)
          d3.select(node)
            .append('rect')
            .attr('x', `${this.barCenterPercentage}%`)
            .attr('y', this.rowYPosition + 0.1 * this.rowHeight)
            .attr('width', 1)
            .attr('height', 1.1 * this.rowHeight)
            .attr('fill', '#979797')
          this.nextRow()
        }
      }
    }
  }

  render() {
    return (
      <StyledDiv height={this.renderHeight()}>
        {svgMap[this.props.svg] && (
          <ChartSVGContainer>
            <InlineSVG className='icon' src={svgMap[this.props.svg]} element='span' />
          </ChartSVGContainer>
        )}
        <svg ref={node => (this.node = node)} width='100%' height={this.renderHeight()} />
      </StyledDiv>
    )
  }
}

class SplitBarChartHeaderOffboard extends Component {
  constructor() {
    super()
    this.barCenterPercentage = 75
  }

  static defaultProps = {
    width: 500,
  }

  componentDidMount() {
    this.createBarChart()
  }

  createBarChart = () => {
    const node = this.node

    const topPadding = 20

    d3.select(node)
      .append('text')
      .text('Old mix')
      .attr('x', `${this.barCenterPercentage}%`)
      .attr('y', topPadding)
      .attr('font-family', 'Open Sans')
      .attr('font-size', '1.33rem')
      .attr('font-weight', '600')
      .attr('fill', '#677B84')
      .attr('text-anchor', 'middle')
      .attr('transform', 'translate(-70)')

    d3.select(node)
      .append('text')
      .text('New mix')
      .attr('x', `${this.barCenterPercentage}%`)
      .attr('y', topPadding)
      .attr('font-family', 'Open Sans')
      .attr('font-size', '1.33rem')
      .attr('font-weight', '600')
      .attr('fill', `${this.props.theme.assetChartHeader}`)
      .attr('text-anchor', 'middle')
      .attr('transform', 'translate(70)')
  }

  render() {
    return (
      <StyledDiv height={30}>
        <svg
          ref={node => (this.node = node)}
          width='100%'
          height={30}
          css={css`
            ${this.props.hideOld ? '& > text:nth-of-type(1) { display: none; }' : ''}
          `}
        />
      </StyledDiv>
    )
  }
}

class SplitBarChartRiskOffboard extends Component {
  constructor() {
    super()

    this.rowYPosition = 0
    this.rowHeight = 50
    this.barCenterPercentage = 75
  }

  static defaultProps = {
    oldRiskText: '',
    newRiskText: '',
    width: 500,
  }

  static propTypes = {
    oldRiskText: PropTypes.string,
    newRiskText: PropTypes.string,
    title: PropTypes.string,
  }

  componentDidMount() {
    this.createBarChart()
  }

  createBarChart = () => {
    const { oldRiskText, newRiskText, rangeIndexOld, rangeIndexNew, theme } = this.props
    const node = this.node

    const moveGap = 20

    d3.select(node)
      .append('text')
      .text('Risk - relative to our preferred range')
      .attr('x', '12%')
      .attr('y', this.rowYPosition + this.rowHeight / 2)
      .attr('font-family', 'Open Sans')
      .attr('font-size', '16px')
      .attr('fill', LIGHTGRAY)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')

    d3.select(node)
      .append('text')
      .text(`${oldRiskText}`)
      .attr('x', `${this.barCenterPercentage}%`)
      .attr('y', this.rowYPosition + this.rowHeight / 2)
      .attr('font-family', 'Open Sans')
      .attr('fill', '#677B84')
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .attr('transform', `translate(${rangeIndexOld >= 0 ? -2 * moveGap - 90 : -moveGap})`)

    d3.select(node)
      .append('text')
      .text(`${newRiskText}`)
      .attr('x', `${this.barCenterPercentage}%`)
      .attr('y', this.rowYPosition + this.rowHeight / 2)
      .attr('font-family', 'Open Sans')
      .attr('fill', `${theme.assetChartHeader}`)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .attr('transform', `translate(${moveGap})`)
      .attr('id', 'newText')

    d3.select(node)
      .append('rect')
      .attr('x', `${this.barCenterPercentage}%`)
      .attr('y', 0.1 * this.rowHeight)
      .attr('width', 1)
      .attr('height', this.rowHeight * 0.9)
      .attr('fill', '#979797')

    const Textwidth = d3.select('#newText').node().getBBox().width
    const moveRectangleGap = Textwidth + moveGap + moveGap

    if (rangeIndexOld >= 0) {
      for (let i = 0; i < 3; i++) {
        d3.select(node)
          .append('rect')
          .attr('x', `${this.barCenterPercentage}%`)
          .attr('y', this.rowYPosition + this.rowHeight / 2 - 8)
          .attr('width', 30)
          .attr('height', 14)
          .attr('fill', rangeIndexOld === i ? `${theme.chartBgColor}` : '#FFFFFF')
          .attr('alignment-baseline', 'middle')
          .attr('stroke', '#979797')
          .attr('stroke-width', '1px')
          .attr('transform', `translate(${-(i + 1) * 30 - moveGap})`)
          .attr('class', 'offboard-risk-old-bar')
      }
    }

    if (rangeIndexNew >= 0) {
      for (let i = 0; i < 3; i++) {
        d3.select(node)
          .append('rect')
          .attr('x', `${this.barCenterPercentage}%`)
          .attr('y', this.rowYPosition + this.rowHeight / 2 - 8)
          .attr('width', 30)
          .attr('height', 14)
          .attr('fill', rangeIndexNew === i ? `${theme.chartBgColor}` : '#FFFFFF')
          .attr('alignment-baseline', 'middle')
          .attr('stroke', '#979797')
          .attr('stroke-width', '1px')
          .attr('transform', `translate(${moveRectangleGap + i * 30})`)
      }
    }
  }

  render() {
    return (
      <StyledDiv height={45}>
        <svg
          ref={node => (this.node = node)}
          width='100%'
          height={45}
          css={css`
            ${this.props.hideOld
              ? '& > text:nth-of-type(2) { display: none; }; & > .offboard-risk-old-bar { display: none; }'
              : ''}
          `}
        />
      </StyledDiv>
    )
  }
}

const SVGLegendItem = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
`

const StyledSVGContainer = styled.div`
  display: inline-block;
  height: 22px;
  width: 22px;
  margin-right: 6px;

  svg {
    .icon-color {
      fill: ${p => p.theme.lightestGray};
    }
  }
`

const LegendText = styled.span`
  font-size: 16px;
  fill: ${p => p.theme.darkestGray};
`

class SplitBarChartLegendOffboard extends Component {
  render() {
    return (
      <StyledDiv height={24} padding='0'>
        <SVGLegendItem>
          <StyledSVGContainer>
            <InlineSVG className='icon' src={Coin} element='span' />
          </StyledSVGContainer>
          <LegendText>Cash</LegendText>
        </SVGLegendItem>
        <SVGLegendItem>
          <StyledSVGContainer>
            <InlineSVG className='icon' src={Bank} element='span' />
          </StyledSVGContainer>
          <LegendText>Bonds</LegendText>
        </SVGLegendItem>
        <SVGLegendItem>
          <StyledSVGContainer>
            <InlineSVG className='icon' src={LineChart} element='span' />
          </StyledSVGContainer>
          <LegendText> Stock</LegendText>
        </SVGLegendItem>
        <SVGLegendItem>
          <StyledSVGContainer>
            <InlineSVG className='icon' src={PieChart} element='span' />
          </StyledSVGContainer>
          <LegendText>Other</LegendText>
        </SVGLegendItem>
      </StyledDiv>
    )
  }
}

export {
  SplitBarChartOffboard as default,
  SplitBarChartHeaderOffboard,
  SplitBarChartRiskOffboard,
  SplitBarChartLegendOffboard,
}
