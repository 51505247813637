/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'

const active = 'rt-overlay-active'

const backdrop = 'rt-overlay-backdrop'

const overlay = 'rt-overlay-overlay'

const _overlay = css`
  background-color: ${ds.get('overlay.color')};
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')};
  width: 100vw;

  &.${active} {
    opacity: ${ds.get('overlay.opacity')};
    pointer-events: all;
  }
`

export default {
  active,
  backdrop,
  overlay: cx(overlay, _overlay),
}
