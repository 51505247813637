import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { API_URL } from '../../../api'
import { Button } from '../../../components'
import ImplementChangesIcon from '../value-proposition/icons/ImplementChangesIcon'
import ManageRiskIcon from '../value-proposition/icons/ManageRiskIcon'
import AutoRebalanceIcon from '../value-proposition/icons/AutoRebalanceIcon'
import AutoAdjustRiskIcon from './AutoAdjustRiskIcon'

const IconGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
  font-family: 'Open Sans';
  color: #4a606a;
  margin: 20px 0 56px;
  & > div {
    flex: 0 1 200px;
    margin: 10px 20px;
    line-height: 1.35;
  }
`

function EnrollmentConfirmed(props) {
  function handleLogout() {
    window.open(`${API_URL.slice(0, -4)}logout`, '_self')
  }

  return (
    <div>
      <div
        css={css`
          color: #4a606a;
          font-size: 50px;
          font-weight: 300;
          line-height: 1.35;
        `}>
        Enrollment: complete!
      </div>

      <div
        css={css`
          color: #038ab7;
          font-size: 24px;
          line-height: 1.35;
          margin: 90px 2rem 0;
        `}>
        GuidedChoice will now:
      </div>

      <IconGroup>
        <div>
          <div>
            <ImplementChangesIcon />
          </div>
          <div>Implement your savings rate and investments for you</div>
        </div>

        <div>
          <div>
            <ManageRiskIcon />
          </div>
          <div>
            Minimize risk — while maximizing reward — for your personalized, diversified portfolio
          </div>
        </div>

        <div>
          <div
            css={css`
              padding: 0 20px;
            `}>
            <AutoRebalanceIcon
              css={css`
                width: 90%;
                height: auto;
                margin-bottom: 25px;
              `}
            />
          </div>
          <div>Automatically rebalance your account to keep you on track</div>
        </div>

        <div>
          <div
            css={css`
              padding: 0 35px;
            `}>
            <AutoAdjustRiskIcon
              css={css`
                width: 85%;
                height: auto;
                margin-bottom: 52px;
              `}
            />
          </div>
          <div>Automatically adjust your risk level as you approach retirement</div>
        </div>
      </IconGroup>

      <div
        css={css`
          color: #4a606a;
          font-size: 16px;
          line-height: 1.35;
          margin: 2rem;
        `}>
        You should receive an email confirmation shortly.
      </div>

      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}>
        <Button primary label='Logout' onClick={handleLogout} width='135px' />
      </div>
    </div>
  )
}

export default EnrollmentConfirmed
