/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import styled from '@emotion/styled'
import createDecorator from 'final-form-calculate'
import _ from 'lodash'

import { numberToDollars } from './../../../utils/utils'

import { SliderWithValueUnderneath, FormError } from '../../../components'
import { BackButton, ButtonsBox, NextButton, Spacer } from '../../../components/styled'
import HelpIcon from '../../../components/HelpIcon'
import TooltipText from '../../../components/TooltipText'

const calculator = createDecorator(
  {
    field: 'emergencyFundInput',
    updates: {
      emergencyFundSlider: (ignoredValue, allValues) => {
        if (allValues.emergencyFundInput === undefined) return
        let number
        if (
          typeof allValues.emergencyFundInput === 'string' &&
          allValues.emergencyFundInput.includes(',')
        ) {
          number = allValues.emergencyFundInput.replace(',', '')
          number = parseInt(number)
        }
        if (
          !number &&
          (isNaN(parseInt(allValues.emergencyFundInput)) ||
            parseInt(allValues.emergencyFundInput) === undefined)
        ) {
          return 0
        }
        if (
          number > allValues.maximumEmergencyFundInput ||
          allValues.emergencyFundInput > allValues.maximumEmergencyFundInput
        ) {
          return allValues.maximumEmergencyFundInput
        }
        return parseInt(number || allValues.emergencyFundInput)
      },
    },
  },
  {
    field: 'emergencyFundSlider',
    updates: {
      emergencyFundInput: (ignoredValue, allValues) => {
        return parseInt(allValues.emergencyFundSlider)
      },
    },
  }
)

function EmergencyFundEdit({
  setDrawer,
  handleCancel,
  store: {
    totalPrimaryBalanceExcludingPensionAndAnnuity,
    saveEmergencyFunds,
    processModifiedCase,
    emergencyFund,
  },
  store,
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        //
      } catch (err) {
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  const handlePost = async values => {
    try {
      await saveEmergencyFunds({ caseId: emergencyFund.caseId, amount: values.emergencyFundInput })
      await processModifiedCase({ emergencyFund: values.emergencyFundInput })
      setDrawer('')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const onSubmit = values => {
    return handlePost(values)
  }

  const validate = values => {
    const errors = {}
    if (values.emergencyFundInput < 0) {
      errors.emergencyFundInput = 'Amount must be 0 or greater'
    }
    return errors
  }

  const initialValues = {
    emergencyFundInput: _.get(emergencyFund, 'amount', 0),
    maximumEmergencyFundInput: totalPrimaryBalanceExcludingPensionAndAnnuity,
  }

  return (
    <Form
      keepDirtyOnReinitialize
      decorators={[calculator]}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      subscription={{ submitting: true, submitError: true, values: true }}
      render={({ handleSubmit, submitting, values, form, submitError }) => (
        <MainWrapper>
          <Label>
            Please note: amount taken from investment balance &nbsp;
            <HelpIcon size='medium' tooltip={TooltipText.emergencyFundHelp()} />
          </Label>
          <Spacer space='10px' />
          <SliderContainer
            css={css`
              margin: 20px 0;
            `}>
            <SliderLabel>
              <EmergencyFundInputField
                totalPrimaryBalanceExcludingPensionAndAnnuity={
                  totalPrimaryBalanceExcludingPensionAndAnnuity
                }
                fieldName='emergencyFundInput'
              />
            </SliderLabel>
            <EmergencyFundSliderField
              emergencyFund={emergencyFund.amount}
              totalPrimaryBalanceExcludingPensionAndAnnuity={
                totalPrimaryBalanceExcludingPensionAndAnnuity
              }
              fieldName='emergencyFundSlider'
            />
          </SliderContainer>

          {submitError && <FormError err={submitError} />}
          <ButtonsBox>
            <BackButton backgroundColor='#FFFFFF' onClick={handleCancel}>
              Cancel
            </BackButton>
            <NextButton onClick={handleSubmit} disabled={submitting}>
              Save
            </NextButton>
          </ButtonsBox>
        </MainWrapper>
      )}
    />
  )
}

export default inject('store')(observer(EmergencyFundEdit))

const MainWrapper = styled.div`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  margin-left: 3px;
`

const EmergencyFundSliderField = ({
  fieldName,
  totalPrimaryBalanceExcludingPensionAndAnnuity,
  emergencyFund,
}) => {
  const { input } = useField(fieldName, {
    subscription: { value: true, touched: true, error: true },
  })
  return (
    <SliderWithValueUnderneath
      min='0'
      max={totalPrimaryBalanceExcludingPensionAndAnnuity}
      value={input.value}
      onChange={input.onChange}
      displayValue={false}
      frozenValue={emergencyFund}
      step='100'
    />
  )
}

const Input = styled.input`
  border: none;
  border-bottom: 1px solid #7a8e96;
  outline: none;
  text-indent: 20px;
  font-size: 20px;
`
const EmergencyFundInputFieldContainer = styled.div`
  position: relative;
  &:before {
    position: absolute;
    content: '$';
    font-size: 20px;
  }
`

const EmergencyFundInputField = ({ fieldName }) => {
  const { input, meta } = useField(fieldName, {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <EmergencyFundInputFieldContainer>
      <Input {...input} value={numberToDollars(parseInt(input.value), true)} />
      {meta.error && meta.touched && <FormError err={meta.error} />}
    </EmergencyFundInputFieldContainer>
  )
}

const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
`
const Label = styled.div``
const SliderLabel = styled.div`
  margin-right: 80px;
`
