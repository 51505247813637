import variables from '../variables'
import colors from '../colors'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { white, grey200 },
} = colors
const menuItemIconFontSize = reduceCSSCalc(`calc(2.4 * ${unit})`)

const menu = {
  expandDuration: '0.3s',
  fadeDuration: '0.2s',
  rippleDelay: '0.3s',
  backgroundColor: white,
  padding: `${reduceCSSCalc(`calc(0.8 * ${unit})`)} 0`,
  outlineBorderRadius: reduceCSSCalc(`calc(0.2 * ${unit})`),
  itemHoverBackground: grey200,
  itemSelectedBackground: 'transparent',
  itemIconFontSize: menuItemIconFontSize,
  itemIconSize: reduceCSSCalc(`calc(1.6 * ${menuItemIconFontSize})`),
  itemHeight: reduceCSSCalc(`calc(4.8 * ${unit})`),
  itemPadding: reduceCSSCalc(`calc(1.6 * ${unit})`),
  itemFontSize: reduceCSSCalc(`calc(1.6 * ${unit})`),
  dividerHeight: reduceCSSCalc(`calc((4.8 / 4) * ${unit})`),
  iconSize: reduceCSSCalc(`calc(2.3 * ${unit})`),
  iconRippleDuration: '650ms',
}

export default menu
