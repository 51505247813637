/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import _ from 'lodash'

import { CircleNegative, CirclePlus } from '../../../../assets/icons'
import { SVGWrapper, Spacer, Slider, PercentInput } from '../../../../components'
import { Page } from '../style'
import { numberToDollars } from '../../../../utils/utils'

function SellCompanyStock(props) {
  const [open, setOpen] = useState(false)
  const companyStocks = _.get(props.store, 'allSpendingRecommendedCases.companyStocks', [])
  const unrestrictedCompanyStocksToSell = companyStocks.filter(stock => !stock.restricted)

  if (unrestrictedCompanyStocksToSell.length === 0) return null
  return (
    <Page.ConsiderationWrapper>
      <Page.ConsiderationContainer onClick={() => setOpen(!open)}>
        <SVGWrapper
          css={css`
            margin-right: 10px;
          `}
          svg={open ? CircleNegative : CirclePlus}
          fill='#3A4D57'
          size='large'
        />
        <Page.ConsiderationLabel>Change how much company stock selling</Page.ConsiderationLabel>
      </Page.ConsiderationContainer>
      {open && (
        <>
          <Spacer space='5px' />
          <Page.EmergencyAmountText>UNRESTRICTED</Page.EmergencyAmountText>

          <FieldArray name='companyStocks' component={CompanyStockFields} />
        </>
      )}
    </Page.ConsiderationWrapper>
  )
}

export default inject('store', 'main')(observer(SellCompanyStock))

const CompanyStockFields = ({ fields }) =>
  fields.value.length > 0 && (
    <div>
      {fields.map((name, index) => (
        <Field
          key={name}
          name={`${name}.restricted`}
          render={restricted =>
            restricted.input.value ? null : (
              <Field
                name={`${name}.totalValue`}
                render={totalValue => (
                  <div>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}>
                      <div
                        css={css`
                          flex: 0 1 300px;
                        `}>
                        <span
                          css={css`
                            color: #022a3a;
                            font-size: 18px;
                          `}>
                          Sell{' '}
                        </span>
                        <Field
                          name={`${name}.amountToSell`}
                          render={amountToSell => (
                            <Field
                              name={`${name}.percentToSell`}
                              format={v => v}
                              parse={v => v}
                              render={({ input, meta }) => (
                                <PercentInput
                                  name={input.name}
                                  value={input.value === '' ? null : input.value}
                                  onChange={(name, value) => {
                                    input.onChange(value)
                                    amountToSell.input.onChange(
                                      Math.round((value / 100) * totalValue.input.value)
                                    )
                                  }}
                                  onBlur={input.onBlur}
                                  error={meta.error}
                                  showError={false}
                                  placeholder='0'
                                  width='80px'
                                  css={css`
                                    display: inline-block;
                                    margin-right: 12px;
                                  `}
                                />
                              )}
                            />
                          )}
                        />
                        <Field
                          name={`${name}.amountToSell`}
                          render={({ input }) => (
                            <span
                              css={css`
                                color: #4a606a;
                                font-size: 14px;
                              `}>
                              (${numberToDollars(input.value, true)})
                            </span>
                          )}
                        />
                        <Field
                          name={`${name}.securityName`}
                          render={({ input }) => (
                            <div
                              css={css`
                                color: #7a8e96;
                                font-size: 14px;
                                font-weight: 300;
                                line-height: 1.35;
                              `}>
                              {input.value}
                            </div>
                          )}
                        />
                      </div>
                      <Field
                        name={`${name}.amountToSell`}
                        render={amountToSell => (
                          <Field
                            name={`${name}.percentToSell`}
                            render={percentToSell => (
                              <div
                                css={css`
                                  flex: 1 1 400px;
                                `}>
                                <Slider
                                  value={percentToSell.input.value || 0}
                                  onChange={value => {
                                    percentToSell.input.onChange(value)
                                    amountToSell.input.onChange(
                                      Math.round((value / 100) * totalValue.input.value)
                                    )
                                  }}
                                  step={1}
                                  min={0}
                                  max={100}
                                />
                              </div>
                            )}
                          />
                        )}
                      />
                    </div>
                    <div
                      css={css`
                        color: #f15d7f;
                        line-height: 1.35;
                        margin: 12px 0;
                      `}>
                      <Field
                        name={`${name}.percentToSell`}
                        validate={value => (value > 100 ? 'Cannot sell more than 100%' : undefined)}
                        render={percentToSell => percentToSell.meta.error || ''}
                      />
                    </div>
                  </div>
                )}
              />
            )
          }
        />
      ))}
    </div>
  )
