import { types, flow } from 'mobx-state-tree'

import { API } from '../api'

const { model, maybeNull, boolean } = types

const CommunicationPreferences = model({
  RECEIVE_MARKETING_EMAILS: maybeNull(boolean),
  RECEIVE_ELECTRONIC_STATEMENTS: maybeNull(boolean),
})

const CommunicationPreferencesStore = model({
  communicationPreferences: maybeNull(CommunicationPreferences),
}).actions(self => ({
  getCommunicationPreferences: flow(function* () {
    const settings = yield API.get('communications-settings/communications-status')
    self.setCommunicationPreferences(settings.data)
  }),
  setCommunicationPreferences(values) {
    self.communicationPreferences = {
      RECEIVE_MARKETING_EMAILS: values.RECEIVE_MARKETING_EMAILS,
      RECEIVE_ELECTRONIC_STATEMENTS: values.RECEIVE_ELECTRONIC_STATEMENTS,
    }
  },
  updateCommunicationPreferences: flow(function* (values) {
    const communications = yield API.post('communications-settings/change-communication-settings', {
      RECEIVE_ELECTRONIC_STATEMENTS: values.RECEIVE_ELECTRONIC_STATEMENTS,
      RECEIVE_MARKETING_EMAILS: values.RECEIVE_MARKETING_EMAILS,
    })
    self.setCommunicationPreferences(communications.data)
  }),
}))

export default CommunicationPreferencesStore
