import { types, getParent, flow } from 'mobx-state-tree'
import toastr from 'toastr'
import _ from 'lodash'

import { AUTH, API, API_URL } from '../api'

const { model, optional, maybeNull, string } = types

const displayErrors = process.env.NODE_ENV === 'development'
const preventTimeout = process.env.NODE_ENV === 'development'

const timeouts = {}

const Auth = model('Auth', {
  isAuthenticated: false,
  stripeSessionId: maybeNull(string),
})
  .views(self => ({
    get hasStripeSessionId() {
      return self.stripeSessionId?.length > 0
    },
  }))
  .actions(self => ({
    afterAttach() {
      const successfulRes = res => {
        if (self.isAuthenticated) {
          const currentTime = new Date()
          _.forEach(timeouts, function (timeout, key) {
            if (timeout.timestamp < currentTime) {
              const timeoutId = timeout.id
              clearTimeout(timeoutId)
              delete timeouts[key]
            }
          })
        }

        const timeoutId = preventTimeout
          ? setTimeout(() => getParent(self).getConfig(), 1000000)
          : setTimeout(() => window.open(`${API_URL.slice(0, -4)}logout`, '_self'), 1200000)

        const timeoutIdStr = String(timeoutId)
        timeouts[timeoutIdStr] = { id: timeoutId, timestamp: new Date() }

        return res
      }

      const errorRes = err => {
        if (err.response && err.response.status === 403) {
          self.invalidate()
        }

        if (err.response && displayErrors) {
          toastr.error(
            typeof err.response.data.message === 'string'
              ? err.response.data.message
              : 'Something went wrong...'
          )
        }

        return Promise.reject(err)
      }

      API.interceptors.response.use(successfulRes, errorRes)
    },
    authenticate(token, signature, recordKeeper) {
      return AUTH.post('auth/sso', {
        token,
        signature,
        recordKeeper,
      })
    },
    setGuideToken(token) {
      API.defaults.headers.common.Authorization = token
      self.authorize()
    },
    authorize() {
      self.isAuthenticated = true
    },
    invalidate() {
      self.isAuthenticated = false
    },
    setStripeSessionId(stripeSessionId) {
      self.stripeSessionId = stripeSessionId
    },
    deleteStripeSessionId() {
      self.stripeSessionId = null
    },
    retailLinkAccount: flow(function* () {
      const res = yield API.post('retail-link', null, {
        params: { stripeSessionId: self.stripeSessionId },
      })
      return res
    }),
  }))

const AuthStore = model({ auth: optional(Auth, {}) })

export default AuthStore
