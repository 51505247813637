import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'

import { Spacer, Drawer, ButtonsBox } from '../../../../components'
import { BackButton, NextButton } from '../../../../components/styled'
import { RadioGroup, RadioButton } from '../../../../guided-toolbox'
import { API } from '../../../../api'

function ImportSources({ institutionsData, handleSourceClick, openPlaid }) {
  return (
    <div>
      <div>
        If this account is with a financial institution you've already connected to, select that
        institution below or add a new one.
      </div>

      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
        `}>
        {institutionsData.map(({ accessId, institutionName, createdOn }) => (
          <div
            css={css`
              width: 275px;
              text-align: center;
              box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
              border-radius: 2px;
              margin: 16px;
              padding: 16px 8px;
              cursor: pointer;
              &:hover {
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
              }
            `}
            key={accessId}
            onClick={() => handleSourceClick(accessId, institutionName)}>
            <div
              css={css`
                font-size: 16px;
                color: #038ab7;
                line-height: 1.35;
              `}>
              {institutionName}
            </div>
            {createdOn ? (
              <div
                css={css`
                  font-size: 14px;
                  margin-top: 8px;
                `}>
                Created on {dayjs(createdOn).format('MM/DD/YYYY')}
              </div>
            ) : null}
          </div>
        ))}
      </div>

      {/* Add Plaid Link here to select a new financial institution */}
      <div
        css={css`
          font-size: 14px;
          color: #038ab7;
          cursor: pointer;
          text-decoration: underline;
          text-align: center;
          width: 85%;
          display: block;
          margin: 24px auto 0;
        `}
        style={{
          border: '1px solid #b6c0c4',
          borderRadius: 4,
          padding: 10,
          backgroundColor: '#fafeff',
        }}
        onClick={openPlaid}>
        {' '}
        + Add new financial institution
      </div>
    </div>
  )
}

function PlaidLinkDrawer({
  active,
  onHandleCloseDrawer,
  fetchInstitutions,
  institutionsData,
  plaidAccounts,
  setPlaidAccounts,
  accountId,
  onSuccessfulLink,
  planType,
  openPlaid,
  selectedInstitution,
  setSelectedInstitution,
  state,
  setState,
}) {
  const [selectedValue, setSelectedValue] = useState(null)

  useEffect(() => {
    // Reset the list of accounts when they close the drawer
    // Fetch institutions again if they've previously logged in through Plaid
    if (!active) {
      setPlaidAccounts([])
      fetchInstitutions()
      setSelectedValue(null)
    }
  }, [active]) // eslint-disable-line

  async function handleSourceClick(accessId, institutionName) {
    try {
      setState('loading')
      const searchAccounts = await API.get('aggregation/link/search', { params: { accessId } })
      setSelectedInstitution(institutionName)
      setPlaidAccounts(searchAccounts.data)
      setState('done')
    } catch (err) {
      console.error(err)
      setState('handle-source-click-error')
    }
  }

  function getAggAccounts() {
    return plaidAccounts
      .map(({ aggAccount, lastRefreshedOn, linkedAccount }) => ({
        name: _.get(aggAccount, 'name'),
        accountId: _.get(aggAccount, 'accountId'),
        alreadyLinked: Boolean(linkedAccount),
        accessId: _.get(aggAccount, 'accessId'),
        lastRefreshedOn,
        compatible: _.includes(
          _.get(aggAccount, 'compatibleLinkedAccountTypes', []),
          planType.toString()
        ),
      }))
      .filter(({ compatible }) => compatible)
  }

  function handleAccountSelect(value) {
    setSelectedValue(value)
  }

  async function handleImportClick() {
    try {
      const [plaidAccountId, accessId] = (selectedValue || '').split('-')

      setState('loading')
      // Used to bind an account with Plaid, financialAccountId = nonGC account ID
      await API.post('aggregation/bind', {
        financialAccountId: Number(accountId),
        plaidAccountId: plaidAccountId,
        accessId: Number(accessId),
      })
      onHandleCloseDrawer()
      onSuccessfulLink()
      setState('done')
    } catch (err) {
      console.error(err)
      setState('bind-account-error')
    }
  }

  function getErrorMessage() {
    const aggAccountsList = getAggAccounts()

    if (aggAccountsList.length === 0) {
      return 'None of the accounts available using these credentials match what you created as an account. Please try again or manually enter your investments.'
    }

    const accountsAlreadyLinked =
      aggAccountsList.length > 0 &&
      aggAccountsList.filter(({ alreadyLinked }) => alreadyLinked).length === aggAccountsList.length
    if (accountsAlreadyLinked) {
      return 'None of the accounts available using these credentials match what you created as an account. Please try again or manually enter your investments.'
    }

    return ''
  }

  // If institutions exist and plaidAccounts is absent, render the institutions selection list
  // If plaidAccounts (provided on Plaid Login) or existingAggAccounts (After they select an institution to link) exist, render the account selection

  const institutionsExist = !_.isEmpty(institutionsData)
  const plaidAccountsExist = !_.isEmpty(plaidAccounts)

  return (
    <Drawer
      active={active}
      title={
        institutionsExist && !plaidAccountsExist
          ? 'Sources available to import from'
          : `Accounts available with ${selectedInstitution}`
      }
      width='50%'>
      {institutionsExist && !plaidAccountsExist && (
        <ImportSources
          institutionsData={institutionsData}
          handleSourceClick={handleSourceClick}
          openPlaid={openPlaid}
        />
      )}

      {plaidAccountsExist && (
        <div>
          <Message>
            The credentials entered are associated with the following accounts. We've selected the
            likely account from which you want to import your investment information, but you may
            select a different one.
          </Message>

          <Spacer space='24px' />
          <RadioGroup
            name='plaidAccountId'
            value={selectedValue}
            onChange={handleAccountSelect}
            css={css`
              padding: 0px 16px 24px 16px;
            `}>
            {getAggAccounts().map(({ accountId, name, alreadyLinked, accessId }) => (
              <RadioButton
                key={accountId}
                label={name}
                value={accountId + '-' + accessId}
                disabled={alreadyLinked}
              />
            ))}
          </RadioGroup>
          <div
            css={css`
              margin: 0 16px;
              color: crimson;
            `}>
            {getErrorMessage()}
          </div>
        </div>
      )}

      <ButtonsBox>
        <BackButton
          backgroundColor='#FFFFFF'
          onClick={onHandleCloseDrawer}
          disabled={state === 'loading'}>
          Cancel
        </BackButton>
        {plaidAccountsExist && (
          <NextButton disabled={!selectedValue || state === 'loading'} onClick={handleImportClick}>
            Import
          </NextButton>
        )}
      </ButtonsBox>
    </Drawer>
  )
}

const Message = styled.div`
  color: #4a606a;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.125rem;
  line-height: 1.35;
  word-break: break-word;
`

export default inject('store')(observer(PlaidLinkDrawer))
