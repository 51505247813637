import { observer, inject } from 'mobx-react'

import {
  SectionRow,
  SectionLabel,
  SectionSubLabel,
  NameField,
  BalanceField,
  EditButton,
  SVGContainer,
  SVGPlaceholder,
} from './DashboardStyle'

function DashboardStockOption(props) {
  function handleViewButton() {
    const { history } = props
    history.push('/accounts/stock-options')
  }

  const {
    person: { displayName },
  } = props.store

  return (
    <SectionRow id='dashboard-stock-options'>
      <NameField>
        <SVGContainer>
          <SVGPlaceholder />
        </SVGContainer>
        <div>
          <SectionLabel>Stock Options</SectionLabel>
          <SectionSubLabel>{displayName}</SectionSubLabel>
        </div>
      </NameField>
      <BalanceField>
        <EditButton onClick={handleViewButton}>View</EditButton>
      </BalanceField>
    </SectionRow>
  )
}

export default inject('store')(observer(DashboardStockOption))
