import createDecorator from 'final-form-calculate'
import _ from 'lodash'
import * as yup from 'yup'

const getPrimaryData = primary => ({
  primaryPreTaxPercent: _.get(primary, 'preTaxAnnualContributions', null),
  primaryPreTaxDollars: _.get(primary, 'preTaxAnnualContribDollars', null),
  primaryRothPercent: _.get(primary, 'rothAnnualContributions', null),
  primaryRothDollars: _.get(primary, 'rothAnnualContribDollars', null),
  primaryAfterTaxPercent: _.get(primary, 'postTaxAnnualContributions', null),
  primaryAfterTaxDollars: _.get(primary, 'postTaxAnnualContribDollars', null),
  primary457PreTaxPercent: _.get(primary, 'preTax457AnnualContributions', null),
  primary457PreTaxDollars: _.get(primary, 'preTax457AnnualContribDollars', null),
  primary457RothPercent: _.get(primary, 'roth457AnnualContributions', null),
  primary403bPreTaxPercent: _.get(primary, 'preTax403bAnnualContributions', null),
  primary403bPreTaxDollars: _.get(primary, 'preTax403bAnnualContribDollars', null),
  primaryPreTaxSSIncome: _.get(primary, 'preTaxSSIncome', null),
  primaryRetAge: _.get(primary, 'retAge', null),
  primaryOngoingAnnualContributions: _.get(primary, 'ongoingAnnualContributions', null),
  primaryOngoingMonthlyContributions: _.get(primary, 'ongoingMonthlyContributions', null),
})

const getSpouseData = spouse => ({
  spousePreTaxPercent: _.get(spouse, 'preTaxAnnualContributions', null),
  spousePreTaxDollars: _.get(spouse, 'preTaxAnnualContribDollars', null),
  spouseRothPercent: _.get(spouse, 'rothAnnualContributions', null),
  spouseRothDollars: _.get(spouse, 'rothAnnualContribDollars', null),
  spouseAfterTaxPercent: _.get(spouse, 'postTaxAnnualContributions', null),
  spouseAfterTaxDollars: _.get(spouse, 'postTaxAnnualContribDollars', null),
  spouse457PreTaxPercent: _.get(spouse, 'preTax457AnnualContributions', null),
  spouse457PreTaxDollars: _.get(spouse, 'preTax457AnnualContribDollars', null),
  spouse457RothPercent: _.get(spouse, 'roth457AnnualContributions', null),
  spouse403bPreTaxPercent: _.get(spouse, 'preTax403bAnnualContributions', null),
  spouse403bPreTaxDollars: _.get(spouse, 'preTax403bAnnualContribDollars', null),
  spousePreTaxSSIncome: _.get(spouse, 'preTaxSSIncome', null),
  spouseRetAge: _.get(spouse, 'retAge', null),
  spouseOngoingAnnualContributions: _.get(spouse, 'ongoingAnnualContributions', null),
  spouseOngoingMonthlyContributions: _.get(spouse, 'ongoingMonthlyContributions', null),
})

export const initializeValues = ({
  recommendedCase,
  modifiedCase,
  currentGoals,
  primaryAnnualIncome,
  spouseAnnualIncome,
}) => {
  const {
    outOfPlanMonthlySavings: additionalMonthlySavings,
    primary,
    retirementIncomeGoal,
    riskLevel,
    recomMinRiskLevel,
    recomMaxRiskLevel,
    riskLevelAll,
    spouse,
    includedGoalsIds,
    companyStocks,
    stockOptions,
  } = modifiedCase || recommendedCase

  const {
    primaryPreTaxPercent,
    primaryPreTaxDollars,
    primaryRothPercent,
    primaryRothDollars,
    primaryAfterTaxPercent,
    primaryAfterTaxDollars,
    primary457PreTaxPercent,
    primary457PreTaxDollars,
    primary457RothPercent,
    // doesn't exist in API model
    // primary457RothDollars
    primary403bPreTaxPercent,
    primary403bPreTaxDollars,
    primaryPreTaxSSIncome,
    primaryRetAge,
    primaryOngoingAnnualContributions,
    primaryOngoingMonthlyContributions,
  } = getPrimaryData(primary)

  const {
    spousePreTaxPercent,
    spousePreTaxDollars,
    spouseRothPercent,
    spouseRothDollars,
    spouseAfterTaxPercent,
    spouseAfterTaxDollars,
    spouse457PreTaxPercent,
    spouse457PreTaxDollars,
    spouse457RothPercent,
    // doesn't exist in API model
    // spouse457RothDollars
    spouse403bPreTaxPercent,
    spouse403bPreTaxDollars,
    spousePreTaxSSIncome,
    spouseRetAge,
    spouseOngoingAnnualContributions,
    spouseOngoingMonthlyContributions,
  } = getSpouseData(spouse)

  const goals = currentGoals.map(({ id }) => ({
    id,
    active: _.includes(includedGoalsIds, id),
  }))

  return {
    primaryAnnualIncome,
    spouseAnnualIncome,
    primaryPreTaxPercent,
    primaryPreTaxDollars,
    primaryRothPercent,
    primaryRothDollars,
    primaryAfterTaxPercent,
    primaryAfterTaxDollars,
    primary457PreTaxPercent,
    primary457PreTaxDollars,
    primary457RothPercent,
    primary457RothDollars: (primary457RothPercent * primaryAnnualIncome) / 100,
    primary403bPreTaxPercent,
    primary403bPreTaxDollars,
    primaryPreTaxSSIncome,
    primaryRetAge,
    primaryOngoingAnnualContributions,
    primaryOngoingMonthlyContributions,
    spousePreTaxPercent,
    spousePreTaxDollars,
    spouseRothPercent,
    spouseRothDollars,
    spouseAfterTaxPercent,
    spouseAfterTaxDollars,
    spouse457PreTaxPercent,
    spouse457PreTaxDollars,
    spouse457RothPercent,
    spouse457RothDollars: (spouse457RothPercent * spouseAnnualIncome) / 100,
    spouse403bPreTaxPercent,
    spouse403bPreTaxDollars,
    spousePreTaxSSIncome,
    spouseRetAge,
    spouseOngoingAnnualContributions,
    spouseOngoingMonthlyContributions,
    additionalAnnualSavings: additionalMonthlySavings * 12,
    additionalMonthlySavings,
    companyStocks: companyStocks.map(
      ({
        amountToSell,
        percentToSell,
        positionId,
        restricted,
        securityId,
        securityName,
        totalValue,
        aggregatedPositionIds,
      }) => ({
        amountToSell,
        percentToSell,
        positionId,
        restricted,
        securityId,
        securityName,
        totalValue,
        aggregatedPositionIds,
      })
    ),
    stockOptions: stockOptions.map(
      ({
        id,
        optionGrantNo,
        quantity,
        vestedDate,
        grantDate,
        expirationDate,
        exercisePrice,
        currentPrice,
        included,
      }) => ({
        id,
        optionGrantNo,
        quantity,
        vestedDate,
        grantDate,
        expirationDate,
        exercisePrice,
        currentPrice,
        included,
      })
    ),
    riskLevel,
    recomMinRiskLevel,
    recomMaxRiskLevel,
    riskLevelAll,
    goals,
    retirementIncomeGoal,
  }
}

export const restoreRecommendation =
  ({ form, recommendedCase, currentGoals, primaryAnnualIncome, spouseAnnualIncome }) =>
  () => {
    const {
      outOfPlanMonthlySavings: additionalMonthlySavings,
      primary,
      retirementIncomeGoal,
      riskLevel,
      recomMinRiskLevel,
      recomMaxRiskLevel,
      riskLevelAll,
      spouse,
      includedGoalsIds,
      companyStocks,
      stockOptions,
    } = recommendedCase

    const {
      primaryPreTaxPercent,
      primaryPreTaxDollars,
      primaryRothPercent,
      primaryRothDollars,
      primaryAfterTaxPercent,
      primaryAfterTaxDollars,
      primary457PreTaxPercent,
      primary457PreTaxDollars,
      primary457RothPercent,
      // doesn't exist in API model
      // primary457RothDollars
      primary403bPreTaxPercent,
      primary403bPreTaxDollars,
      primaryPreTaxSSIncome,
      primaryRetAge,
      primaryOngoingAnnualContributions,
      primaryOngoingMonthlyContributions,
    } = getPrimaryData(primary)

    const {
      spousePreTaxPercent,
      spousePreTaxDollars,
      spouseRothPercent,
      spouseRothDollars,
      spouseAfterTaxPercent,
      spouseAfterTaxDollars,
      spouse457PreTaxPercent,
      spouse457PreTaxDollars,
      spouse457RothPercent,
      // doesn't exist in API model
      // spouse457RothDollars
      spouse403bPreTaxPercent,
      spouse403bPreTaxDollars,
      spousePreTaxSSIncome,
      spouseRetAge,
      spouseOngoingAnnualContributions,
      spouseOngoingMonthlyContributions,
    } = getSpouseData(spouse)

    const goals = currentGoals.map(({ id }) => ({
      id,
      active: _.includes(includedGoalsIds, id),
    }))

    form.initialize({
      primaryAnnualIncome,
      spouseAnnualIncome,
      primaryPreTaxPercent,
      primaryPreTaxDollars,
      primaryRothPercent,
      primaryRothDollars,
      primaryAfterTaxPercent,
      primaryAfterTaxDollars,
      primary457PreTaxPercent,
      primary457PreTaxDollars,
      primary457RothPercent,
      primary457RothDollars: (primary457RothPercent * primaryAnnualIncome) / 100,
      primary403bPreTaxPercent,
      primary403bPreTaxDollars,
      primaryPreTaxSSIncome,
      primaryRetAge,
      primaryOngoingAnnualContributions,
      primaryOngoingMonthlyContributions,
      spousePreTaxPercent,
      spousePreTaxDollars,
      spouseRothPercent,
      spouseRothDollars,
      spouseAfterTaxPercent,
      spouseAfterTaxDollars,
      spouse457PreTaxPercent,
      spouse457PreTaxDollars,
      spouse457RothPercent,
      spouse457RothDollars: (spouse457RothPercent * spouseAnnualIncome) / 100,
      spouse403bPreTaxPercent,
      spouse403bPreTaxDollars,
      spousePreTaxSSIncome,
      spouseRetAge,
      spouseOngoingAnnualContributions,
      spouseOngoingMonthlyContributions,
      additionalAnnualSavings: additionalMonthlySavings * 12,
      additionalMonthlySavings,
      companyStocks: companyStocks.map(
        ({
          amountToSell,
          percentToSell,
          positionId,
          restricted,
          securityId,
          securityName,
          totalValue,
          aggregatedPositionIds,
        }) => ({
          amountToSell,
          percentToSell,
          positionId,
          restricted,
          securityId,
          securityName,
          totalValue,
          aggregatedPositionIds,
        })
      ),
      stockOptions: stockOptions.map(
        ({
          id,
          optionGrantNo,
          quantity,
          vestedDate,
          grantDate,
          expirationDate,
          exercisePrice,
          currentPrice,
          included,
        }) => ({
          id,
          optionGrantNo,
          quantity,
          vestedDate,
          grantDate,
          expirationDate,
          exercisePrice,
          currentPrice,
          included,
        })
      ),
      riskLevel,
      recomMinRiskLevel,
      recomMaxRiskLevel,
      riskLevelAll,
      goals,
      retirementIncomeGoal,
    })
  }

export const valuesEqualRecommended = ({ values, recommendedCase }) => {
  const valuesObj = _.pick(values, [
    'includedGoalsIds',
    'outOfPlanMonthlySavings',
    'primary',
    'riskLevel',
    'spouse',
    'companyStocks',
    'stockOptions',
  ])

  const recommendedObj = {
    includedGoalsIds: recommendedCase.includedGoalsIds.slice().sort(),
    outOfPlanMonthlySavings: recommendedCase.outOfPlanMonthlySavings,
    primary: {
      preTaxAnnualContributions: recommendedCase.primary.preTaxAnnualContributions,
      preTaxAnnualContribDollars: recommendedCase.primary.preTaxAnnualContribDollars,
      rothAnnualContributions: recommendedCase.primary.rothAnnualContributions,
      rothAnnualContribDollars: recommendedCase.primary.rothAnnualContribDollars,
      postTaxAnnualContributions: recommendedCase.primary.postTaxAnnualContributions,
      postTaxAnnualContribDollars: recommendedCase.primary.postTaxAnnualContribDollars,
      preTax457AnnualContributions: recommendedCase.primary.preTax457AnnualContributions,
      preTax457AnnualContribDollars: recommendedCase.primary.preTax457AnnualContribDollars,
      roth457AnnualContributions: recommendedCase.primary.roth457AnnualContributions,
      preTax403bAnnualContributions: recommendedCase.primary.preTax403bAnnualContributions,
      preTax403bAnnualContribDollars: recommendedCase.primary.preTax403bAnnualContribDollars,
      preTaxSSIncome: recommendedCase.primary.preTaxSSIncome,
      retAge: recommendedCase.primary.retAge,
      ongoingAnnualContributions: recommendedCase.primary.ongoingAnnualContributions,
      ongoingMonthlyContributions: recommendedCase.primary.ongoingMonthlyContributions,
    },
    riskLevel: recommendedCase.riskLevel,
    spouse: recommendedCase.spouse
      ? {
          preTaxAnnualContributions: recommendedCase.spouse.preTaxAnnualContributions,
          preTaxAnnualContribDollars: recommendedCase.spouse.preTaxAnnualContribDollars,
          rothAnnualContributions: recommendedCase.spouse.rothAnnualContributions,
          rothAnnualContribDollars: recommendedCase.spouse.rothAnnualContribDollars,
          postTaxAnnualContributions: recommendedCase.spouse.postTaxAnnualContributions,
          postTaxAnnualContribDollars: recommendedCase.spouse.postTaxAnnualContribDollars,
          preTax457AnnualContributions: recommendedCase.spouse.preTax457AnnualContributions,
          preTax457AnnualContribDollars: recommendedCase.spouse.preTax457AnnualContribDollars,
          roth457AnnualContributions: recommendedCase.spouse.roth457AnnualContributions,
          preTax403bAnnualContributions: recommendedCase.spouse.preTax403bAnnualContributions,
          preTax403bAnnualContribDollars: recommendedCase.spouse.preTax403bAnnualContribDollars,
          preTaxSSIncome: recommendedCase.spouse.preTaxSSIncome,
          retAge: recommendedCase.spouse.retAge,
          ongoingAnnualContributions: recommendedCase.spouse.ongoingAnnualContributions,
          ongoingMonthlyContributions: recommendedCase.spouse.ongoingMonthlyContributions,
        }
      : null,
    companyStocks: recommendedCase.companyStocks.map(
      ({ positionId, percentToSell, aggregatedPositionIds }) => ({
        positionId,
        percentToSell,
        aggregatedPositionIds,
      })
    ),
    stockOptions: recommendedCase.stockOptions.map(({ id, included }) => ({ id, included })),
  }

  return _.isEqual(valuesObj, recommendedObj)
}

export const valuesEqualModified = ({ values, modifiedCase }) => {
  const valuesObj = _.pick(values, [
    'includedGoalsIds',
    'outOfPlanMonthlySavings',
    'primary',
    'riskLevel',
    'spouse',
    'companyStocks',
    'stockOptions',
  ])

  const modifiedObj = modifiedCase && {
    includedGoalsIds: modifiedCase.includedGoalsIds.slice().sort(),
    outOfPlanMonthlySavings: modifiedCase.outOfPlanMonthlySavings,
    primary: {
      preTaxAnnualContributions: modifiedCase.primary.preTaxAnnualContributions,
      preTaxAnnualContribDollars: modifiedCase.primary.preTaxAnnualContribDollars,
      rothAnnualContributions: modifiedCase.primary.rothAnnualContributions,
      rothAnnualContribDollars: modifiedCase.primary.rothAnnualContribDollars,
      postTaxAnnualContributions: modifiedCase.primary.postTaxAnnualContributions,
      postTaxAnnualContribDollars: modifiedCase.primary.postTaxAnnualContribDollars,
      preTax457AnnualContributions: modifiedCase.primary.preTax457AnnualContributions,
      preTax457AnnualContribDollars: modifiedCase.primary.preTax457AnnualContribDollars,
      roth457AnnualContributions: modifiedCase.primary.roth457AnnualContributions,
      preTax403bAnnualContributions: modifiedCase.primary.preTax403bAnnualContributions,
      preTax403bAnnualContribDollars: modifiedCase.primary.preTax403bAnnualContribDollars,
      preTaxSSIncome: modifiedCase.primary.preTaxSSIncome,
      retAge: modifiedCase.primary.retAge,
      ongoingAnnualContributions: modifiedCase.primary.ongoingAnnualContributions,
      ongoingMonthlyContributions: modifiedCase.primary.ongoingMonthlyContributions,
    },
    riskLevel: modifiedCase.riskLevel,
    spouse: modifiedCase.spouse
      ? {
          preTaxAnnualContributions: modifiedCase.spouse.preTaxAnnualContributions,
          preTaxAnnualContribDollars: modifiedCase.spouse.preTaxAnnualContribDollars,
          rothAnnualContributions: modifiedCase.spouse.rothAnnualContributions,
          rothAnnualContribDollars: modifiedCase.spouse.rothAnnualContribDollars,
          postTaxAnnualContributions: modifiedCase.spouse.postTaxAnnualContributions,
          postTaxAnnualContribDollars: modifiedCase.spouse.postTaxAnnualContribDollars,
          preTax457AnnualContributions: modifiedCase.spouse.preTax457AnnualContributions,
          preTax457AnnualContribDollars: modifiedCase.spouse.preTax457AnnualContribDollars,
          roth457AnnualContributions: modifiedCase.spouse.roth457AnnualContributions,
          preTax403bAnnualContributions: modifiedCase.spouse.preTax403bAnnualContributions,
          preTax403bAnnualContribDollars: modifiedCase.spouse.preTax403bAnnualContribDollars,
          preTaxSSIncome: modifiedCase.spouse.preTaxSSIncome,
          retAge: modifiedCase.spouse.retAge,
          ongoingAnnualContributions: modifiedCase.spouse.ongoingAnnualContributions,
          ongoingMonthlyContributions: modifiedCase.spouse.ongoingMonthlyContributions,
        }
      : null,
    companyStocks: modifiedCase.companyStocks.map(
      ({ positionId, percentToSell, aggregatedPositionIds }) => ({
        positionId,
        percentToSell,
        aggregatedPositionIds,
      })
    ),
    stockOptions: modifiedCase.stockOptions.map(({ id, included }) => ({ id, included })),
  }

  return _.isEqual(valuesObj, modifiedObj)
}

export const prepareValuesForSubmit = ({ formValues, modifiedCase, recommendedCase }) => {
  const {
    additionalMonthlySavings: outOfPlanMonthlySavings,
    primaryPreTaxPercent,
    primaryPreTaxDollars,
    primaryRothPercent,
    primaryRothDollars,
    primaryAfterTaxPercent,
    primaryAfterTaxDollars,
    primary457PreTaxPercent,
    primary457PreTaxDollars,
    primary457RothPercent,
    primary403bPreTaxPercent,
    primary403bPreTaxDollars,
    primaryPreTaxSSIncome,
    primaryRetAge,
    primaryOngoingAnnualContributions,
    primaryOngoingMonthlyContributions,
    spousePreTaxPercent,
    spousePreTaxDollars,
    spouseRothPercent,
    spouseRothDollars,
    spouseAfterTaxPercent,
    spouseAfterTaxDollars,
    spouse457PreTaxPercent,
    spouse457PreTaxDollars,
    spouse457RothPercent,
    spouse403bPreTaxPercent,
    spouse403bPreTaxDollars,
    spousePreTaxSSIncome,
    spouseRetAge,
    spouseOngoingAnnualContributions,
    spouseOngoingMonthlyContributions,
    riskLevel,
    goals,
    retirementIncomeGoal,
    companyStocks,
    stockOptions,
  } = formValues

  const { spouse } = modifiedCase || recommendedCase

  const includedGoalsIds = goals
    .filter(goal => goal.active)
    .map(goal => goal.id)
    .sort()

  return {
    includedGoalsIds,
    outOfPlanMonthlySavings,
    primary: {
      preTaxAnnualContributions: primaryPreTaxPercent,
      preTaxAnnualContribDollars: primaryPreTaxDollars,
      rothAnnualContributions: primaryRothPercent,
      rothAnnualContribDollars: primaryRothDollars,
      postTaxAnnualContributions: primaryAfterTaxPercent,
      postTaxAnnualContribDollars: primaryAfterTaxDollars,
      preTax457AnnualContributions: primary457PreTaxPercent,
      preTax457AnnualContribDollars: primary457PreTaxDollars,
      roth457AnnualContributions: primary457RothPercent,
      preTax403bAnnualContributions: primary403bPreTaxPercent,
      preTax403bAnnualContribDollars: primary403bPreTaxDollars,
      preTaxSSIncome: primaryPreTaxSSIncome,
      retAge: primaryRetAge,
      ongoingAnnualContributions: primaryOngoingAnnualContributions,
      ongoingMonthlyContributions: primaryOngoingMonthlyContributions,
    },
    spouse: spouse
      ? {
          preTaxAnnualContributions: spousePreTaxPercent,
          preTaxAnnualContribDollars: spousePreTaxDollars,
          rothAnnualContributions: spouseRothPercent,
          rothAnnualContribDollars: spouseRothDollars,
          postTaxAnnualContributions: spouseAfterTaxPercent,
          postTaxAnnualContribDollars: spouseAfterTaxDollars,
          preTax457AnnualContributions: spouse457PreTaxPercent,
          preTax457AnnualContribDollars: spouse457PreTaxDollars,
          roth457AnnualContributions: spouse457RothPercent,
          preTax403bAnnualContributions: spouse403bPreTaxPercent,
          preTax403bAnnualContribDollars: spouse403bPreTaxDollars,
          preTaxSSIncome: spousePreTaxSSIncome,
          retAge: spouseRetAge,
          ongoingAnnualContributions: spouseOngoingAnnualContributions,
          ongoingMonthlyContributions: spouseOngoingMonthlyContributions,
        }
      : null,
    retirementIncomeGoal,
    riskLevel,
    companyStocks: companyStocks.map(({ positionId, percentToSell, aggregatedPositionIds }) => ({
      positionId,
      percentToSell,
      aggregatedPositionIds,
    })),
    stockOptions: stockOptions.map(({ id, included }) => ({ id, included })),
    estimatedTotalMonthlyIncome: recommendedCase.targetedAftTaxIncome,
  }
}

export const schema = yup.object().shape({
  primaryRetAge: yup
    .number()
    .nullable()
    .when(
      ['$primaryRetAgeEditable', '$primaryMaxRetAge'],
      (primaryRetAgeEditable, primaryMaxRetAge, schema) =>
        primaryRetAgeEditable
          ? schema
              .required('Valid age required')
              .min(20, `Valid ages: 20 - ${primaryMaxRetAge}`)
              .max(primaryMaxRetAge, `Valid ages: 20 - ${primaryMaxRetAge}`)
          : schema
    ),
  spouseRetAge: yup
    .number()
    .nullable()
    .when(
      ['$spouseRetAgeEditable', '$spouseMaxRetAge'],
      (spouseRetAgeEditable, spouseMaxRetAge, schema) =>
        spouseRetAgeEditable
          ? schema
              .required('Valid age required')
              .min(20, `Valid ages: 20 - ${spouseMaxRetAge}`)
              .max(spouseMaxRetAge, `Valid ages: 20 - ${spouseMaxRetAge}`)
          : schema
    ),
})

export const riskScaleIndexCalc = ({ riskLevel, recomMaxRiskLevel, recomMinRiskLevel }) => {
  if (recomMaxRiskLevel === null || recomMinRiskLevel === null) {
    return -1
  }

  if (riskLevel <= recomMaxRiskLevel && riskLevel >= recomMinRiskLevel) {
    const difference = Math.max(recomMaxRiskLevel - recomMinRiskLevel, 0)
    if (riskLevel < recomMinRiskLevel + difference / 3) {
      return 0
    } else if (riskLevel < recomMinRiskLevel + (2 * difference) / 3) {
      return 1
    } else {
      return 2
    }
  } else {
    return -1
  }
}

export const withinRangeCalc = riskScaleIndex => {
  return riskScaleIndex >= 0
}

export const riskLevelTextCalc = ({ riskLevel, recomMaxRiskLevel, recomMinRiskLevel }) => {
  if (riskLevel === null) {
    return ''
  } else if (riskLevel < recomMinRiskLevel) {
    return 'Lower than our preferred range'
  } else if (riskLevel <= recomMaxRiskLevel && riskLevel >= recomMinRiskLevel) {
    return 'Within our preferred range'
  } else if (riskLevel > recomMaxRiskLevel) {
    return 'Higher than our preferred range'
  } else {
    return ''
  }
}

// calculations between percent and dollars can get tricky and in certain scenarios trigger infinite loops
// so currently we just focus on calculating read-only one-way values
// and delegate percent / dollars conversions to the onChange events of the individual inputs
export const calculator = createDecorator(
  // {
  //   field: 'primaryPreTaxPercent',
  //   updates: {
  //     primaryPreTaxDollars: (primaryPreTaxPercent, allValues) =>
  //       Math.round((primaryPreTaxPercent / 100) * allValues.primaryAnnualIncome)
  //   }
  // },
  // {
  //   field: 'primaryPreTaxDollars',
  //   updates: {
  //     primaryPreTaxPercent: (primaryPreTaxDollars, allValues) =>
  //       Math.round((primaryPreTaxDollars / allValues.primaryAnnualIncome) * 100)
  //   }
  // },
  // {
  //   field: 'primaryRothPercent',
  //   updates: {
  //     primaryRothDollars: (primaryRothPercent, allValues) =>
  //       Math.round((primaryRothPercent / 100) * allValues.primaryAnnualIncome)
  //   }
  // },
  // {
  //   field: 'primaryRothDollars',
  //   updates: {
  //     primaryRothPercent: (primaryRothDollars, allValues) =>
  //       Math.round((primaryRothDollars / allValues.primaryAnnualIncome) * 100)
  //   }
  // },
  // {
  //   field: 'primaryAfterTaxPercent',
  //   updates: {
  //     primaryAfterTaxDollars: (primaryAfterTaxPercent, allValues) =>
  //       Math.round((primaryAfterTaxPercent / 100) * allValues.primaryAnnualIncome)
  //   }
  // },
  // {
  //   field: 'primaryAfterTaxDollars',
  //   updates: {
  //     primaryAfterTaxPercent: (primaryAfterTaxDollars, allValues) =>
  //       Math.round((primaryAfterTaxDollars / allValues.primaryAnnualIncome) * 100)
  //   }
  // },
  // {
  //   field: 'special457PreTaxPercent',
  //   updates: {
  //     special457PreTaxDollars: (special457PreTaxPercent, allValues) =>
  //       Math.round((special457PreTaxPercent / 100) * allValues.primaryAnnualIncome)
  //   }
  // },
  // {
  //   field: 'special457PreTaxDollars',
  //   updates: {
  //     special457PreTaxPercent: (special457PreTaxDollars, allValues) =>
  //       Math.round((special457PreTaxDollars / allValues.primaryAnnualIncome) * 100)
  //   }
  // },
  // {
  //   field: 'special457RothPercent',
  //   updates: {
  //     special457RothDollars: (special457RothPercent, allValues) =>
  //       Math.round((special457RothPercent / 100) * allValues.primaryAnnualIncome)
  //   }
  // },
  // {
  //   field: 'special457RothDollars',
  //   updates: {
  //     special457RothPercent: (special457RothDollars, allValues) =>
  //       Math.round((special457RothDollars / allValues.primaryAnnualIncome) * 100)
  //   }
  // },
  // {
  //   field: 'special403bPreTaxPercent',
  //   updates: {
  //     special403bPreTaxDollars: (special403bPreTaxPercent, allValues) =>
  //       Math.round((special403bPreTaxPercent / 100) * allValues.primaryAnnualIncome)
  //   }
  // },
  // {
  //   field: 'special403bPreTaxDollars',
  //   updates: {
  //     special403bPreTaxPercent: (special403bPreTaxDollars, allValues) =>
  //       Math.round((special403bPreTaxDollars / allValues.primaryAnnualIncome) * 100)
  //   }
  // },
  // {
  //   field: 'spousePreTaxPercent',
  //   updates: {
  //     spousePreTaxDollars: (spousePreTaxPercent, allValues) =>
  //       Math.round((spousePreTaxPercent / 100) * allValues.spouseAnnualIncome)
  //   }
  // },
  // {
  //   field: 'spousePreTaxDollars',
  //   updates: {
  //     spousePreTaxPercent: (spousePreTaxDollars, allValues) =>
  //       Math.round((spousePreTaxDollars / allValues.spouseAnnualIncome) * 100)
  //   }
  // },
  // {
  //   field: 'spouseRothPercent',
  //   updates: {
  //     spouseRothDollars: (spouseRothPercent, allValues) =>
  //       Math.round((spouseRothPercent / 100) * allValues.spouseAnnualIncome)
  //   }
  // },
  // {
  //   field: 'spouseRothDollars',
  //   updates: {
  //     spouseRothPercent: (spouseRothDollars, allValues) =>
  //       Math.round((spouseRothDollars / allValues.spouseAnnualIncome) * 100)
  //   }
  // },
  // {
  //   field: 'spouseAfterTaxPercent',
  //   updates: {
  //     spouseAfterTaxDollars: (spouseAfterTaxPercent, allValues) =>
  //       Math.round((spouseAfterTaxPercent / 100) * allValues.spouseAnnualIncome)
  //   }
  // },
  // {
  //   field: 'spouseAfterTaxDollars',
  //   updates: {
  //     spouseAfterTaxPercent: (spouseAfterTaxDollars, allValues) =>
  //       Math.round((spouseAfterTaxDollars / allValues.spouseAnnualIncome) * 100)
  //   }
  // },
  {
    field: 'additionalAnnualSavings',
    updates: {
      additionalMonthlySavings: additionalAnnualSavings => Math.round(additionalAnnualSavings / 12),
    },
  }
)
