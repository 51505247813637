import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import _ from 'lodash'

import { Page } from '../../spend-down/style'
import { TextInput, FormError } from '../../../../components'
import { LargeCheckbox } from '../../../../guided-toolbox/checkbox/index'
import GetGuideButtons from '../../confirm-email/GetGuideButtons'
import { API, API_URL } from '../../../../api/instances'

const GetYourGuide = props => {
  const {
    spendDownOffboarding: { setSpedDownOffboardingEmail },
    store: {
      contactInfo: { email },
    },
  } = props

  const submitEmail = async ({ email }) => {
    try {
      const { spendingAcceptedCaseId } = props.store

      await setSpedDownOffboardingEmail(email)

      // send email for the guide
      await API.post(`${API_URL}guide/email`, null, {
        params: { caseId: spendingAcceptedCaseId, email: email.trim() },
      })

      window.open(`${API_URL.slice(0, -4)}logout`, '_self')
    } catch (err) {
      console.error(err)
      return {
        [FORM_ERROR]:
          'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
      }
    }
  }

  const onSubmit = async values => {
    if (values.sendEmail) {
      return submitEmail(values)
    } else {
      window.open(`${API_URL.slice(0, -4)}logout`, '_self')
    }
  }

  const openGuide = () => {
    const { spendingAcceptedCaseId } = props.store
    window.open(`${API_URL}guide/generateGuide?caseId=${spendingAcceptedCaseId}`, '_blank')
  }

  return (
    <Page.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
        line-height: 1.5rem;
      `}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: email || 'example@example.com' }}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting, submitError }) => (
          <div>
            <Page.Heading
              css={css`
                color: #4a606a;
                font-weight: 400;
                text-align: start;
              `}>
              Get your guide
            </Page.Heading>

            <Page.Container>
              <div>
                Your guide contains not only the allocation we recommend for the investment accounts
                you told us about, but also how much to withdraw for the remainder of the calendar
                year and from which accounts.
                <Page.EmailContainer>
                  <Page.SubHeader
                    css={css`
                      font-size: 1.25rem;
                    `}>
                    Receive via email?
                  </Page.SubHeader>
                  <div
                    css={css`
                      display: flex;
                      align-items: flex-end;
                      gap: 10px;
                    `}>
                    <SendEmailField />
                    <EmailField />
                  </div>
                </Page.EmailContainer>
                {submitError && <FormError err={submitError} />}
              </div>
            </Page.Container>

            <GetGuideButtons openGuide={openGuide} handleNext={handleSubmit} />
          </div>
        )}
      />
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(GetYourGuide))

function SendEmailField() {
  const { input, meta } = useField('sendEmail', {
    subscription: { value: true, touched: true, error: true },
    initialValue: true,
  })

  return (
    <LargeCheckbox
      error={meta.error}
      name={input.name}
      checked={input.value}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={() => input.onChange(!input.value)}
    />
  )
}

function EmailField() {
  const { input, meta } = useField('email', {
    validate: value =>
      yup
        .string()
        .email()
        .required('Email is required')
        .max(128, 'Maximum character limit 128 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      error={meta.error}
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='name@email.com'
      showError={meta.touched}
      value={input.value}
      css={css`
        flex-grow: 1;
      `}
      width='100%'
    />
  )
}
