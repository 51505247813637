const SvgComponent = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 148 143.4'
    {...props}>
    <g fill='#fff' stroke='#8fbc3f' strokeMiterlimit='10'>
      <path d='M117.2 76.7h-11.1V75l11.1-.6zM116.5 85h-9.6c-4 0-7.2 3.2-7.2 7.2V112.8c0 .6.5 1.2 1.2 1.2h3.7v-2.4l14.5-2.7v5.1h3.7c.6 0 1.2-.5 1.2-1.2V92.2c-.3-4-3.5-7.2-7.5-7.2z' />
      <path d='M109.3 80.2V85c0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4v-4.8h-4.8z' />
      <path d='M111.7 70.6c-2.7 0-4.8 2.2-4.8 4.8v2.4c0 2.7 2.2 4.8 4.8 4.8s4.8-2.2 4.8-4.8v-2.4c0-2.7-2.2-4.8-4.8-4.8zM104.4 111.5V92.3M118.9 92.3v19.2M118.9 128.4v-19.6l-14.5 2.7v16.9' />
      <path d='M110.5 128.4v-10.8c0-.7.5-1.2 1.2-1.2s1.2.5 1.2 1.2v10.8M100.8 114v3.7c0 1 .8 1.8 1.8 1.8h1.8V114h-3.6zM122.6 114v3.7c0 1-.8 1.8-1.8 1.8H119V114h3.6zM116.5 76.2h0v2.4h0c.4 0 .7-.3.7-.7v-1c0-.4-.3-.7-.7-.7zM106.9 76.2h0c-.4 0-.7.3-.7.7v1c0 .4.3.7.7.7h0v-2.4zM107.5 75c3.1-.3 5.8-2 7.4-4.5-.9-.7-2-1-3.2-1-3.1 0-5.6 2.5-5.6 5.6h1.4z' />
      <path d='M117.2 74.4c-.3-2.8-2.6-5-5.5-5-2 0-3.7 1-4.7 2.6 1.6 1.7 3.7 2.8 6.2 3h1.6c.8-.1 1.6-.3 2.4-.6z' />
    </g>
    <g fill='none' strokeMiterlimit='10'>
      <path stroke='#0989b7' d='M29.1 39.2L14.6 28.1M29.1 39.2l-7.3-5.5' />
      <path stroke='#8fbc3f' d='M19.6 58.4l-8.8-2.3' />
      <path stroke='#0989b7' d='M18.2 79.8L.1 82.2M18.2 79.8L9.1 81M65.5 18.2L63.1.1' />
      <path stroke='#8fbc3f' d='M45.2 25.1l-4.6-7.9' />
    </g>
    <path fill='#fff' d='M101.9 130.9l17.5-10.8 7.8-7.8-1.1 16.9-7.9 5z' />
    <defs>
      <circle id='a' cx='87.6' cy='82.9' r='59.9' />
    </defs>
    <use xlinkHref='#a' overflow='visible' fill='none' stroke='#098bb8' strokeMiterlimit='10' />
    <path
      d='M41.8 90.7c2.6 16.5 13.8 31.2 30.6 37.4 24.7 9.1 52.1-3.5 61.2-28.2s-3.5-52.1-28.2-61.2c-5.6-2.1-11.4-3-17.1-2.9'
      fill='none'
      stroke='#098bb8'
      strokeMiterlimit='10'
    />
    <path
      d='M66.9 44.9s-8.2 4.5-15.5 15.6M61.4 43.3l6.1 1.4-1 5.2-.1.5'
      fill='none'
      stroke='#8fbc3f'
      strokeMiterlimit='10'
    />
    <path fill='none' stroke='#8fbc3f' strokeMiterlimit='10' d='M50.1 54.2l1.2 6.1 5.2-.8h.5' />
    <path fill='#fff' stroke='#098bb8' strokeMiterlimit='10' d='M87.5 83.4L49.3 43' />
    <circle cx='87.6' cy='82.9' r='7.4' fill='#fff' stroke='#098bb8' strokeMiterlimit='10' />
    <g>
      <defs>
        <circle id='b' cx='87.6' cy='83.4' r='54.1' />
      </defs>
      <use
        xlinkHref='#b'
        overflow='visible'
        fill='none'
        stroke='#098bb8'
        strokeWidth='.5'
        strokeMiterlimit='10'
        strokeDasharray='3,15'
      />
    </g>
  </svg>
)

export default SvgComponent
