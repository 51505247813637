/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import styled from '@emotion/styled'
import posed, { PoseGroup } from 'react-pose'
import _ from 'lodash'

import { Page, Header } from './elements'
import { TextInput, Button, HelpIcon, TooltipText, FormError } from '../../components'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function SetupEmail(props) {
  const {
    store: { initiateVerificationEmail },
  } = props
  const recordKeeperId = 3014
  const { location, history } = props
  const [status, setStatus] = useState('')
  const [email, setEmail] = useState('')

  const handleAPICall = async ({ email }) => {
    try {
      await initiateVerificationEmail({ email, recordKeeperId })
      setEmail(email)
      setStatus('Success')
    } catch (err) {
      console.error(err)
      return {
        [FORM_ERROR]:
          'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
      }
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleLogin = () => {
    history.push('/atmos/login')
  }

  const onSubmit = values => {
    return handleAPICall(values)
  }

  const SuccessMessage = () => {
    return (
      <Page.Text>
        An email with your setup link has been sent successfully to {email}. <br />
        <br />
        Please check your email to complete the registration process.
      </Page.Text>
    )
  }

  return (
    <PoseGroup animateOnMount>
      <RouteContainer key={location.key || location.pathname}>
        <Page.Container>
          <Header />
          <Page>
            <div
              css={css`
                font-size: 50px;
                line-height: 76px;
                max-width: 100%;
              `}>
              <Page.Heading title='Set up GuidedChoice account' />
            </div>
            {status === 'Success' ? (
              <SuccessMessage />
            ) : (
              <>
                <Form
                  onSubmit={onSubmit}
                  subscription={{ submitting: true, submitError: true }}
                  render={({ handleSubmit, submitting, submitError }) => (
                    <Page.Form>
                      <Page.AnimateGroup>
                        <Page.AnimateItem>
                          <Page.Text
                            css={css`
                              padding-left: 20px;
                            `}>
                            Enter the email address you'd like to use for logging in. Click Next and
                            GuidedChoice will send an email to the address entered so that you may
                            continue with your account setup.
                          </Page.Text>
                        </Page.AnimateItem>
                        <Page.AnimateItem
                          css={css`
                            padding-left: 20px;
                          `}>
                          <BorderedCard>
                            <div
                              css={css`
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;
                              `}>
                              <CardTitle>Login email</CardTitle> &nbsp; &nbsp;
                              <HelpIcon tooltip={TooltipText.atmosEmail()} />
                            </div>

                            <EmailField />

                            <div
                              css={css`
                                display: flex;
                                justify-content: space-between;
                              `}>
                              <div />
                              <Button
                                onClick={handleSubmit}
                                label='Next'
                                type='submit'
                                primary
                                style={{ width: 124 }}
                                disabled={submitting}
                              />
                            </div>
                          </BorderedCard>
                        </Page.AnimateItem>
                        {submitError && <FormError err={submitError} />}
                        <Page.AnimateItem>
                          <Page.Text
                            css={css`
                              padding-left: 20px;
                            `}>
                            Your GuidedChoice account is used to allow you to sign in securely and
                            access your data. See how your&nbsp;
                            <Page.Link
                              href='https://www.guidedchoice.com/privacy'
                              target='_blank'
                              rel='noreferrer noopener'>
                              privacy
                            </Page.Link>{' '}
                            and&nbsp;
                            <Page.Link
                              href='https://www.guidedchoice.com/security'
                              target='_blank'
                              rel='noreferrer noopener'>
                              security
                            </Page.Link>
                            &nbsp;are protected.
                            <br />
                            <br />
                            Already have an account? &nbsp;
                            <Page.Link
                              onClick={handleLogin}
                              target='_blank'
                              rel='noreferrer noopener'>
                              Login
                            </Page.Link>
                          </Page.Text>
                        </Page.AnimateItem>{' '}
                        <br /> <br />
                        <Button
                          style={{ width: 124 }}
                          onClick={handleCancel}
                          label='Cancel'
                          type='button'
                          secondary
                        />
                      </Page.AnimateGroup>
                    </Page.Form>
                  )}
                />
              </>
            )}
          </Page>
        </Page.Container>
      </RouteContainer>
    </PoseGroup>
  )
}

export default inject('store')(observer(SetupEmail))

const BorderedCard = styled.div`
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px 0;
  padding: 1rem;
  max-width: 461px;
`
const CardTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 36px;
  line-height: 46px;
  font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
  font-weight: 300;
`
function EmailField() {
  const { input, meta } = useField('email', {
    validate: value =>
      yup
        .string()
        .email()
        .required('Email is required')
        .max(128, 'Maximum character limit 128 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='email'
      error={meta.error}
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='name@email.com'
      showError={meta.touched}
      value={input.value}
      width='100%'
      css={css`
        padding-left: 20px;
      `}
    />
  )
}
