import { Component } from 'react'
import { Link } from 'react-router-dom'

import Add from '../../../../assets/icons/Add'
import { SVGWrapper } from '../../../../components'
import { AddButtonBox, AddIcon, StyledLink } from '../../styled'

class AddInvestmentButton extends Component {
  render() {
    const { linkAddress } = this.props
    return (
      <Link to={linkAddress}>
        <AddButtonBox>
          <AddIcon>
            <SVGWrapper svg={Add} size='medium' />
          </AddIcon>
          <StyledLink>Add new investment</StyledLink>
        </AddButtonBox>
      </Link>
    )
  }
}

export default AddInvestmentButton
