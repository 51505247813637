import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const RangeScaleContainer = styled.div`
  width: 100px;
`

class RangeScale extends Component {
  static propTypes = {
    rectangleActive: PropTypes.number,
    showTriangle: PropTypes.bool,
    theme: PropTypes.object,
  }

  render() {
    let { rectangleActive, showTriangle, theme } = this.props
    const activeColor = theme ? theme.chartBgColor : '#D4EBF2'

    if (rectangleActive > 2) {
      rectangleActive = 2
    } else if (rectangleActive < 0) {
      rectangleActive = 0
    } else {
      rectangleActive = Math.floor(rectangleActive)
    }

    return (
      <RangeScaleContainer>
        <svg width='92px' height={showTriangle ? '30px' : '16px'}>
          <rect
            x='1'
            y='1'
            width='30px'
            height='14px'
            fill={rectangleActive === 0 ? activeColor : '#FFFFFF'}
            stroke='#979797'
            strokeWidth='1px'
          />
          <rect
            x='31'
            y='1'
            width='30px'
            height='14px'
            fill={rectangleActive === 1 ? activeColor : '#FFFFFF'}
            stroke='#979797'
            strokeWidth='1px'
          />
          <rect
            x='61'
            y='1'
            width='30px'
            height='14px'
            fill={rectangleActive === 2 ? activeColor : '#FFFFFF'}
            stroke='#979797'
            strokeWidth='1px'
          />
          {showTriangle && (
            <path
              d={`M ${rectangleActive * 30 + 17} 25 L ${rectangleActive * 30 + 20} 19 L ${
                rectangleActive * 30 + 23
              } 25 z`}
              fill='#2198BF'
            />
          )}
        </svg>
      </RangeScaleContainer>
    )
  }
}

export default RangeScale
