import styled from '@emotion/styled'

export const Page = styled.div``

Page.MinSpaceInputs = styled.div`
  & [class*='InputWrapper'] {
    margin-top: 0;
    min-height: 0;
    padding: 0;
  }
`
