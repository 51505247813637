import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import { Spacer } from '../../../components'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'

function TotalWithdraw(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
      investmentWithdrawals,
      withdrawalData,
    },
    store: { person, spouse },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'withdraw' })
    willMount.current = false
  }

  const page = findByName('withdraw')
  return (
    <Page header={<PageHeader padding='20pt 40pt 16pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.Title>Total withdraw {withdrawalData.withdrawalBeginYear}</Wrapper.Title>
        <Wrapper.SubTitle>How much to withdraw from your investments</Wrapper.SubTitle>

        <Wrapper.Advice text='Withdrawal covers the remainder of this year:' />

        <Wrapper.InnerWrapper>
          <Wrapper.Income incomeName='For monthly income' amount={withdrawalData.monthlyIncome} />
          {!_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) && (
            <Wrapper.Income
              incomeName={`For annuity purchase (${person.displayName}’s)`}
              amount={withdrawalData.clientAnnuityPurchase}
            />
          )}
          {person.includeSpouse &&
            !_.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome) && (
              <Wrapper.Income
                incomeName={`For annuity purchase (${spouse.firstName}’s)`}
                amount={withdrawalData.spouseAnnuityPurchase}
              />
            )}
          {withdrawalData.otherFinancialGoals > 0 && (
            <Wrapper.Income
              incomeName='To fund this year’s other financial goals '
              amount={withdrawalData.otherFinancialGoals}
            />
          )}
          {withdrawalData.reqMinDistribution > 0 && (
            <Wrapper.Income
              incomeName='For required minimum distribution*'
              amount={withdrawalData.reqMinDistribution}
            />
          )}

          <Wrapper.TotalWithdrawal text='Total withdrawal' amount={withdrawalData.totalWithdraws} />
          {withdrawalData.totalWithdraws > withdrawalData.desiredMonthlyIncome &&
            withdrawalData.totalReinvestAmount !== 0 && (
              <>
                <Wrapper.WithdrawalText>
                  If the total withdrawal amount exceeds the amount needed, reinvest the difference:
                </Wrapper.WithdrawalText>
                <Wrapper.TotalWithdrawal
                  text='Total amount to reinvest'
                  amount={withdrawalData.totalReinvestAmount}
                />
              </>
            )}

          <Wrapper.GrayBox>
            <Wrapper.AdviceText>Be aware:</Wrapper.AdviceText>
            <Wrapper.GrayBoxInterior>
              <li>Estimated taxes have been taken into account.</li>
              {person.age < 59.5 && (
                <Wrapper.EarlyWithdrawalNote>
                  Withdrawing money from an employer retirement plan account before age 59-1/2 may
                  result in a 10% early withdrawal penalty tax in addition to income taxes. We
                  recommend consulting a tax advisor before doing so.
                </Wrapper.EarlyWithdrawalNote>
              )}
              <Spacer space='10pt' />
              <li>
                All funds that are not used to create monthly income must be reinvested for future
                years. In fact, our advice assumes it.
              </li>
            </Wrapper.GrayBoxInterior>
          </Wrapper.GrayBox>

          {withdrawalData.reqMinDistribution > 0 && (
            <>
              <Spacer space='10pt' />
              <Wrapper.RMDDisclaimer>
                *Please see Disclosures section at the end of this guide for additional information
                regarding required minimum distributions.
              </Wrapper.RMDDisclaimer>
            </>
          )}
        </Wrapper.InnerWrapper>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(TotalWithdraw))
