import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Filler,
  Legend,
} from 'chart.js'
import { numberToDollars } from '../../../utils/utils'
import _ from 'lodash'

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Filler, Legend)

const Chart = props => {
  const { spendingByAges } = props
  const years = () => {
    const arr = []
    const currentYear = _.get(spendingByAges[0], 'year', null) || new Date().getFullYear()
    for (let i = currentYear; i < currentYear + spendingByAges.length; i++) {
      arr.push(i + '')
    }
    return arr
  }

  const sum = spendingByAges.map(item => {
    const arr = []
    arr.push((item.investmentIncome + item.lifetimeIncome) / 12)
    let max = Math.max(...arr)
    max *= 1.1
    return max
  })

  const max = Math.floor(Math.max(...sum))

  const investmentAmounts = () => {
    const arr = []
    for (const i of spendingByAges) {
      const amount = i.investmentIncome + i.lifetimeIncome
      arr.push(Math.floor(amount / 12))
    }
    return arr
  }
  const incomeAmounts = () => {
    const arr = []
    for (const i of spendingByAges) {
      if (i.investmentIncome < 0) {
        const amount = i.lifetimeIncome < 0 ? 0 : i.lifetimeIncome
        const finalAmount = amount + i.investmentIncome
        arr.push(Math.floor(finalAmount / 12))
      } else {
        const amount = i.lifetimeIncome < 0 ? 0 : i.lifetimeIncome
        arr.push(Math.floor(amount / 12))
      }
    }
    return arr
  }

  return (
    <Line
      data={{
        labels: years(),
        datasets: [
          {
            label: 'Lifetime Income',
            data: incomeAmounts(),
            // borderColor: 'rgba(118, 189, 35, 1)',
            backgroundColor: 'rgba(118, 189, 35, 1)',
            fill: true,
          },
          {
            label: 'Investment Income',
            data: investmentAmounts(),
            // borderColor: 'rgba(0, 139, 172, 1)',
            backgroundColor: 'rgba(0, 139, 172, 1)',
            fill: true,
          },
        ],
      }}
      options={{
        scales: {
          y: {
            suggestedMin: 0,
            max: max,
            ticks: {
              stepSize: 1500,
              callback: function (value) {
                return '$' + numberToDollars(value, true)
              },
            },
          },
          xAxes: {
            grid: {
              display: false,
            },
          },
        },
        elements: {
          line: { tension: 0 },
        },
      }}
    />
  )
}

export default Chart
