const SvgComponent = props => (
  <svg viewBox='0 0 512 512' {...props}>
    <path
      fill='#7a8e96'
      d='M256 0C114.9 0 0 114.9 0 256s114.9 256 256 256 256-114.9 256-256S397.1 0 256 0zm0 459c-111.9 0-203-91.1-203-203S144.1 53 256 53s203 91.1 203 203-91.1 203-203 203z'
    />
    <path
      fill='#7a8e96'
      d='M299.7 241.6c-12.4-6.9-25.5-12-38.5-17.3-7.5-3.1-14.7-6.8-21.1-11.8-12.5-10-10.2-26.2 4.5-32.7 4.1-1.8 8.5-2.4 12.9-2.6 17-1 33.1 2.2 48.6 9.6 7.6 3.7 10.2 2.5 12.8-5.4 2.8-8.5 5.1-17 7.5-25.5 1.7-5.7-.4-9.5-5.8-11.8-9.8-4.3-20-7.5-30.7-9.1-13.9-2.2-13.9-2.2-14-16.2-.1-19.8-.1-19.8-19.8-19.7-2.9 0-5.7-.1-8.6 0-9.2.3-10.8 1.9-11 11.1-.1 4.1 0 8.4 0 12.5-.1 12.4-.1 12.2-12 16.4-28.6 10.4-46.2 29.8-48.1 61-1.7 27.6 12.7 46.2 35.3 59.8 14 8.4 29.4 13.3 44.2 19.9 5.8 2.5 11.3 5.5 16.1 9.5 14.3 11.7 11.6 31.3-5.3 38.7-9.1 3.9-18.6 5-28.4 3.7-15.1-1.9-29.6-5.8-43.2-12.9-7.9-4.1-10.3-3.1-13 5.6-2.3 7.5-4.4 15-6.5 22.5-2.8 10.2-1.8 12.6 7.9 17.2 12.3 5.9 25.5 9 38.9 11.2 10.5 1.7 10.9 2.1 11 13 0 5 .1 9.9.1 14.8.1 6.2 3.1 9.8 9.5 9.9 7.3.1 14.6.1 21.9 0 5.9-.1 9-3.4 9.1-9.4 0-6.8.3-13.5.1-20.3-.3-6.9 2.6-10.4 9.3-12.2 15.2-4.1 28.2-12.4 38.3-24.5 27.9-33.8 17.3-83.2-22-105z'
    />
  </svg>
)

export default SvgComponent
