import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'

import { Drawer, Button } from '../../../components'
import SelectionMenu from './SelectionMenu'
import CompareSelected from './CompareSelected'

const DrawerTitle = styled.div`
  font-size: 1.5rem;
  color: ${p => p.theme.mediumGray};
  font-weight: 600;
`
const BottomGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0;
  max-width: 1200px;
`

class CompareDrawer extends Component {
  state = { selected: false }

  toggleSelected = () => this.setState({ selected: !this.state.selected })

  setSelected = selected => this.setState({ selected })

  resetDrawer = () => {
    this.props.handleCancel()
    setTimeout(() => this.setState({ selected: false }), 450)
  }

  render() {
    const { selected } = this.state
    const { active } = this.props

    return (
      <Drawer active={active} width='80%' fullMobile nopad>
        <div
          css={css`
            padding: 20px 20px 80px 20px;
            @media (max-width: 800px) {
              padding: 1rem 1rem 80px 1rem;
            }
          `}>
          <DrawerTitle>Select scenarios for comparison</DrawerTitle>

          {selected ? (
            <CompareSelected selected={selected} toggleSelected={this.toggleSelected} />
          ) : (
            <SelectionMenu setSelected={this.setSelected} />
          )}

          <BottomGroup>
            <Button secondary width='120px' onClick={this.resetDrawer} label='Cancel' />
            {selected && <Button primary width='100px' label='Done' onClick={this.resetDrawer} />}
          </BottomGroup>
        </div>
      </Drawer>
    )
  }
}

export default CompareDrawer
