const help = `
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>
        .cls-1,
        .cls-2{isolation:isolate;}
        .cls-2{font-size:19px;font-family:Aleo;font-weight:700;}
      </style>
    </defs>
    <title>help</title>
    <g id="Symbols" class="icon-color" >
      <g id="icn_help-normal" data-name="icn/help-normal">
        <g id="Avatar">
          <path
            id="Shape"
            d="M21.6,12A9.6,9.6,0,0,0,2.4,12M0,12a12,12,0,0,1,24,0"
          />
        </g>
        <g id="Avatar-Copy">
          <path
            id="Shape-2"
            data-name="Shape"
            d="M2.4,12a9.6,9.6,0,0,0,19.2,0M24,12A12,12,0,0,1,0,12"
          />
        </g>
        <g id="Group-4">
          <g id="_" data-name="?" class="cls-1">
            <text class="cls-2" transform="translate(8 19)">?</text>
          </g>
        </g>
      </g>
    </g>
  </svg>
`

export default help
