import { css } from '@emotion/react'
import { TooltipText } from '../../../components/TooltipText'

const tooltipText = () => (
  <TooltipText
    css={css`
      padding: 1rem;
    `}>
    We get it. Adding other available options can be burdensome. So while it is recommended you
    provide this information, it is not required. The caveat, however, is that when we recommend an
    investment mix for you later in the service, we won't be able to name the investment option but
    will tell you simply the type of fund you should look for amongst your available options (e.g.
    Your Short Term Bond Fund, Your International Fund, etc.).
  </TooltipText>
)

export default tooltipText
