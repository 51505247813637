import styled from '@emotion/styled'

const Page = styled.div``

const ServerErrorStyle = styled.div`
  margin: 20px;
  color: crimson;
  line-height: 1.35;
`
Page.ServerError = () => (
  <ServerErrorStyle>Oops! Something went wrong, please try again later</ServerErrorStyle>
)

export default Page
