import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const TitleBox = styled.div`
  padding-bottom: 40px;
  @media (min-width: 1200px) {
    padding-bottom: 60px;
  }
`
const Title = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 3rem;
  max-width: 600px;
  width: 100%;
  color: ${p => p.theme.mediumGray};
  transition: 1s;
  font-weight: 300;
  line-height: 1.25;
  word-break: break-word;
  @media (max-width: 800px) {
    font-size: 2rem;
    max-width: 400px;
  }
  @media (min-width: 1200px) {
    font-size: 3.125rem;
    max-width: 800px;
  }
`
const SubTitle = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 1rem;
  padding-top: 1rem;
  line-height: 20px;
`

class PageTitle extends Component {
  static propTypes = {
    subtitle: PropTypes.string,
  }

  render() {
    return (
      <TitleBox>
        <Title>{this.props.children}</Title>
        {this.props.subtitle ? <SubTitle>{this.props.subtitle}</SubTitle> : null}
      </TitleBox>
    )
  }
}

export default PageTitle
