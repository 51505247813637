export const APP_BAR = 'RTAppBar'
export const AUTOCOMPLETE = 'RTAutocomplete'
export const AVATAR = 'RTAvatar'
export const BUTTON = 'RTButton'
export const CARD = 'RTCard'
export const CHIP = 'RTChip'
export const CHECKBOX = 'RTCheckbox'
export const DATE_PICKER = 'RTDatePicker'
export const DIALOG = 'RTDialog'
export const DRAWER = 'RTDrawer'
export const DROPDOWN = 'RTDropdown'
export const INPUT = 'RTInput'
export const LAYOUT = 'RTLayout'
export const LINK = 'RTLink'
export const LIST = 'RTList'
export const MENU = 'RTMenu'
export const NAVIGATION = 'RTNavigation'
export const OVERLAY = 'RTOverlay'
export const PROGRESS_BAR = 'RTProgressBar'
export const RADIO = 'RTRadio'
export const RIPPLE = 'RTRipple'
export const SLIDER = 'RTSlider'
export const SNACKBAR = 'RTSnackbar'
export const SWITCH = 'RTSwitch'
export const TABLE = 'RTTable'
export const TABS = 'RTTabs'
export const TOOLTIP = 'RTTooltip'
export const TIME_PICKER = 'RTTimePicker'
