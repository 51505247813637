import { Component } from 'react'
import styled from '@emotion/styled'
import { keyframes, css } from '@emotion/react'
import PropTypes from 'prop-types'

const progressHeight = '4px'
const progressMainColor = '#7A8E96'
const progressSecondaryColor = '#DADFE1'
const progressDisabledColor = 'rgba(0,0,0,.26)'
const circleWrapperWidth = 60
const circleRadius = 25
const scaleRatio = circleRadius / 20
const animationDuration = '0.35s'
const animationCurveDefault = 'cubic-bezier(0.4, 0, 0.2, 1)'

const linearIndeterminateBar = keyframes`
  0% {
    transform: translate(-50%) scaleX(0);
  }
  50% {
    transform: translate(-0%) scaleX(0.3);
  }
  100% {
    transform: translate(50%) scaleX(0);
  }
`

const circularIndeterminateBarRotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const circularIndeterminateBarDash = keyframes`
  0% {
    stroke-dasharray: ${scaleRatio * 1}, ${scaleRatio * 200};
    stroke-dashoffset: ${scaleRatio * 0};
  }
  50% {
    stroke-dasharray: ${scaleRatio * 89}, ${scaleRatio * 200};
    stroke-dashoffset: ${scaleRatio * -35};
  }
  100% {
    stroke-dasharray: ${scaleRatio * 89}, ${scaleRatio * 200};
    stroke-dashoffset: ${scaleRatio * -124};
  }
`

const colors = keyframes`
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
`

const calculateRatio = (value, min, max) => {
  if (value < min) return 0
  if (value > max) return 1
  return (value - min) / (max - min)
}

const linearStyle = p =>
  p.type === 'linear' &&
  css`
    background: rgb(238, 238, 238);
    display: inline-block;
    height: ${progressHeight};
    overflow: hidden;
    position: relative;
    width: 100%;
  `

const circularStyle = p =>
  p.type === 'circular' &&
  css`
    display: inline-block;
    height: ${circleWrapperWidth}px;
    position: relative;
    transform: rotate(-90deg);
    width: ${circleWrapperWidth}px;
  `

const ProgressBarStyle = styled.div`
  ${linearStyle};
  ${circularStyle};
`

const ValueBufferSharedStyle = css`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: left center;
  transition-duration: ${animationDuration};
  transition-timing-function: ${animationCurveDefault};
`

const LinearBufferStyle = p =>
  p.mode !== 'indeterminate' &&
  css`
    transform: scaleX(${calculateRatio(p.buffer, p.min, p.max)});
  `

const DisabledBufferStyle = p =>
  p.disabled &&
  css`
    background-image: linear-gradient(
        to right,
        ${progressSecondaryColor},
        ${progressSecondaryColor}
      ),
      linear-gradient(to right, ${progressDisabledColor}, ${progressDisabledColor});
  `

const LinearBuffer = styled.span`
  background-image: linear-gradient(to right, ${progressSecondaryColor}, ${progressSecondaryColor}),
    linear-gradient(to right, ${progressMainColor}, ${progressMainColor});
  ${ValueBufferSharedStyle};
  ${LinearBufferStyle};
  ${DisabledBufferStyle};
`

const LinearValueStyle = p =>
  (p.mode !== 'indeterminate' &&
    css`
      transform: scaleX(${calculateRatio(p.value, p.min, p.max)});
    `) ||
  css`
    animation: ${linearIndeterminateBar} 1s linear infinite;
    transform-origin: center center;
  `

const DisabledValueStyle = p =>
  p.disabled &&
  css`
    background-color: ${progressDisabledColor};
  `

const LinearValue = styled.span`
  background-color: ${progressMainColor};
  ${ValueBufferSharedStyle};
  ${LinearValueStyle};
  ${DisabledValueStyle};
`

const indeterminateCircleStyle = p =>
  p.mode === 'indeterminate' &&
  css`
    animation: ${circularIndeterminateBarRotate} 2s linear infinite;
  `

const CircleStyle = styled.svg`
  height: 100%;
  width: 100%;
  ${indeterminateCircleStyle};
`

const determinatePathStyle = p =>
  p.mode === 'determinate' &&
  css`
    stroke-dasharray: ${2 * Math.PI * 25 * calculateRatio(p.value, p.min, p.max)}, 400;
  `

const indeterminatePathStyle = p =>
  p.mode === 'indeterminate' &&
  css`
    animation: ${circularIndeterminateBarDash} 1.5s ease-in-out infinite;
    stroke-dasharray: ${scaleRatio * 1}, ${scaleRatio * 200};
    stroke-dashoffset: 0;
  `

const multicolorPathStyle = p =>
  p.multicolor &&
  css`
    animation: ${circularIndeterminateBarDash} 1.5s ease-in-out infinite,
      ${colors} ${1.5 * 4}s ease-in-out infinite;
  `

const PathStyle = styled.circle`
  fill: none;
  stroke: ${progressMainColor};
  stroke-dasharray: 0, ${scaleRatio * 200};
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-miterlimit: 20;
  stroke-width: 4;
  transition: stroke-dasharray ${animationDuration} ${animationCurveDefault};
  ${determinatePathStyle};
  ${indeterminatePathStyle};
  ${multicolorPathStyle};
`

class ProgressBar extends Component {
  static propTypes = {
    buffer: PropTypes.number,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    mode: PropTypes.oneOf(['determinate', 'indeterminate']),
    multicolor: PropTypes.bool,
    theme: PropTypes.shape({
      buffer: PropTypes.string,
      circle: PropTypes.string,
      circular: PropTypes.string,
      indeterminate: PropTypes.string,
      linear: PropTypes.string,
      multicolor: PropTypes.string,
      path: PropTypes.string,
      value: PropTypes.string,
    }),
    type: PropTypes.oneOf(['linear', 'circular']),
    value: PropTypes.number,
  }

  static defaultProps = {
    buffer: 0,
    className: '',
    max: 100,
    min: 0,
    mode: 'indeterminate',
    multicolor: false,
    type: 'linear',
    value: 0,
  }

  renderCircular() {
    const { value, mode, min, max, multicolor } = this.props
    return (
      <CircleStyle mode={mode} viewBox='0 0 60 60'>
        <PathStyle
          mode={mode}
          multicolor={multicolor}
          min={min}
          max={max}
          value={value}
          cx='30'
          cy='30'
          r='25'
        />
      </CircleStyle>
    )
  }

  renderLinear() {
    const { buffer, value, mode, min, max, disabled } = this.props
    return (
      <div>
        <LinearBuffer
          data-ref='buffer'
          mode={mode}
          buffer={buffer}
          min={min}
          max={max}
          disabled={disabled}
        />
        <LinearValue
          data-ref='value'
          mode={mode}
          value={value}
          min={min}
          max={max}
          disabled={disabled}
        />
      </div>
    )
  }

  render() {
    const { disabled, max, min, mode, multicolor, type, value } = this.props

    return (
      <ProgressBarStyle
        disabled={disabled}
        data-react-toolbox='progress-bar'
        aria-valuenow={value}
        aria-valuemin={min}
        aria-valuemax={max}
        type={type}
        indeterminate={mode === 'indeterminate'}
        multicolor={multicolor}>
        {type === 'circular' ? this.renderCircular() : this.renderLinear()}
      </ProgressBarStyle>
    )
  }
}

export default ProgressBar
