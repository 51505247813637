import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { FieldContainer, Title, BulletText } from '../styled'

class ClientPlan extends Component {
  render() {
    const { title, contribution, investmentMix, additionalText } = this.props
    return (
      <FieldContainer>
        {title && <Title>{title}</Title>}
        {contribution && <BulletText>{contribution}</BulletText>}
        {investmentMix && <BulletText>{investmentMix}</BulletText>}
        {additionalText && (
          <div
            css={css`
              color: #022a3a;
              font-size: 9pt;
              padding-top: 2px;
              padding-left: 26px;
              line-height: 1.35;
            `}>
            {additionalText}
          </div>
        )}
      </FieldContainer>
    )
  }
}

export default inject('store')(observer(ClientPlan))
