import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import dayjs from 'dayjs'

import { numberToDollars } from '../../../utils'
import { Card, CardField } from '../../../components'
import { stateKeyToValue } from '../../../constants/us-states'
import _ from 'lodash'

class AboutYouCard extends Component {
  genderValue(gender) {
    if (gender === 'M') {
      return 'Male'
    }
    if (gender === 'F') {
      return 'Female'
    }
    if (gender !== 'M' && gender !== 'F') {
      return ''
    }
  }

  maritalStatusValue() {
    const { person } = this.props.store
    if (person.maritalStatus === false) {
      return 'Single'
    }
    if (person.maritalStatus === true) {
      return 'Married'
    }
  }

  render() {
    const { features, person, account, primaryRetirementStatus } = this.props.store

    return (
      <Card title='About you' handleEdit={this.props.showAboutYou} cardWidth='900px'>
        <CardField type='profile' label='Your first name' value={person.firstName} />
        {features.enablePreferredName && (
          <CardField type='profile' label='Your preferred name' value={person.preferredName} />
        )}
        <CardField type='profile' label='Your last name' value={person.lastName} />

        <div
          css={css`
            padding-top: 32px;
          `}
        />
        <CardField type='profile' label='Your gender' value={this.genderValue(person.gender)} />
        <CardField
          type='profile'
          label='Your date of birth'
          value={person.birthDate ? dayjs(person.birthDate).format('MM/DD/YYYY') : ''}
        />

        <div
          css={css`
            padding-top: 32px;
          `}
        />
        <CardField
          type='profile'
          label='Your annual compensation before taxes'
          value={`$${numberToDollars(account.annualSalary, true)}`}
        />
        {features.enableOtherIncome && (
          <CardField
            type='profile'
            label='Your other earned income, annually'
            value={`$${numberToDollars(person.otherIncome, true)}`}
          />
        )}

        {/* The following condition is a styling difference for PTD+ to close white space gap between retirement age and annual salary */}
        {features.enableSSI && (
          <div
            css={css`
              padding-top: 32px;
            `}
          />
        )}

        <CardField type='profile' label='Your retirement age' value={person.retirementAge} />

        <>
          {_.get(this, 'props.store.account.productId', '') !== 5 ? (
            <CardField
              type='profile'
              label='Plan to work part-time after retiring?'
              value={primaryRetirementStatus.planningWorkPartTime ? 'Yes' : 'No'}
            />
          ) : null}
          {primaryRetirementStatus.planningWorkPartTime === 1 && (
            <>
              <CardField
                type='profile'
                label='Duration: starting age?'
                value={primaryRetirementStatus.durationStartingAge}
              />
              <CardField
                type='profile'
                label='Duration: stopping age?'
                value={primaryRetirementStatus.durationStoppingAge}
              />
              <CardField
                type='profile'
                label='Annual income from in-retirement job'
                value={`$${numberToDollars(primaryRetirementStatus.annualIncome, true)}`}
              />
            </>
          )}
        </>

        <div
          css={css`
            padding-top: 32px;
          `}
        />
        {features.enableTaxStateChange && (
          <CardField
            type='profile'
            label='State in which file taxes'
            value={stateKeyToValue[account.taxState]}
          />
        )}
        {/* Only display Marital status in the About you section when Spouse isn't an enabled feature */}
        {!features.enableSpouse && (
          <CardField type='profile' label='Marital status' value={this.maritalStatusValue()} />
        )}
      </Card>
    )
  }
}

export default inject('store')(observer(AboutYouCard))
