import { Component } from 'react'
import styled from '@emotion/styled'

const StyledDiv = styled.div`
  position: relative;
  height: ${p => (p.height ? `${p.height}px` : null)};
  width: ${p => (p.width ? `${p.width}px` : null)};
  padding: ${p => (p.padding ? `${p.padding}px` : null)};
`
const G = styled.g`
  transform: translateY(0.35em);
  vertical-align: middle;
`
const Text = styled.text`
  font-size: 20px;
  vertical-align: middle;
  line-height: 1;
  text-anchor: middle;
  text-align: center;
  cursor: default;
  user-select: none;
`
const DollarSign = styled.tspan`
  font-size: 20px;
  line-height: 1;
  text-anchor: middle;
  text-align: center;
  cursor: default;
  fill: ${({ isOld }) => (isOld ? '#7a8e96' : '#038ab7')};
  user-select: none;
`

class DoughnutChart extends Component {
  static defaultProps = {
    height: 100,
    percent: 0,
    isOld: true,
  }

  render() {
    const { percent, isOld, height } = this.props
    const centerWidth = height / 2
    const centerHeight = height / 2
    const radius = height / 2
    const zeroToOnePercent = percent < 1 && percent > 0
    const cleanPercent = zeroToOnePercent ? 1 : Math.floor(percent)
    const trigPercent = (cleanPercent / 100) * 2 * Math.PI
    const displayPercent = zeroToOnePercent ? '< 1' : cleanPercent
    const xPos = centerWidth + Math.cos(trigPercent - Math.PI / 2) * radius
    const yPos = centerHeight + Math.sin(trigPercent - Math.PI / 2) * radius

    const textFillColor = isOld ? '#022a3a' : '#038ab7'

    return (
      <StyledDiv width={height} height={height} padding={2} className={this.props.className}>
        <svg width='100%' height='100%' viewBox={`0 0 ${height} ${height}`}>
          {cleanPercent < 100 ? (
            <>
              <circle cx={centerWidth} cy={centerHeight} r={radius} fill='#7a8e96' />
              <path
                fill='#038ab7'
                d={`M ${centerWidth} 0 A ${radius} ${radius} 0 ${
                  percent > 50 ? 1 : 0
                } 1 ${xPos} ${yPos} L ${centerWidth} ${centerHeight}`}
              />
            </>
          ) : (
            <circle cx={centerWidth} cy={centerHeight} r={radius} fill='#038ab7' />
          )}

          <circle cx={centerWidth} cy={centerHeight} r={radius / 1.75} fill='#FFFFFF' />
          <G>
            <Text x={centerWidth} y={centerHeight} fill={textFillColor}>
              {displayPercent}
              <DollarSign isOld={isOld}>%</DollarSign>
            </Text>
          </G>
        </svg>
      </StyledDiv>
    )
  }
}

export default DoughnutChart
