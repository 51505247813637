import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { primary, primaryContrast, black },
} = colors
const circleRadius = 25

const progress = {
  height: reduceCSSCalc(`calc(0.4 * ${unit})`),
  mainColor: primary,
  secondaryColor: color.convert(`color(${primaryContrast} a(70%))`),
  disabledColor: color.convert(`color(${black} a(26%))`),
  circleWrapperWidth: 60,
  circleRadius,
  scaleRatio: `${circleRadius / 20}`,
}

export default progress
