import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Spacer, HelpIcon, HelpPersonIcon, FillGauge, TooltipText } from '../../../components'
import { numberToDollars } from '../../../utils'

const Amount = styled.div`
  color: ${p => p.theme.darkestGray};
  text-align: ${p => (p.alignRight ? 'right' : 'left')};
  font-size: 1.5rem;

  &::before {
    content: '$';
    color: ${p => (p.difference > 0 ? '#4d9927' : p.difference < 0 ? '#f48024' : '#7a8e96')};
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'space-between')};
  align-items: ${p => (p.alignItems ? p.alignItems : 'center')};

  margin: ${p => (p.childMargin ? '0 -8px' : null)};

  & > * {
    margin: ${p => (p.childMargin ? '0 8px' : null)};
  }
`

const SubAmount = styled.div`
  color: ${p => p.theme.darkestGray};
  margin-right: 6px;
  font-size: 1rem;
  font-weight: ${p => (p.bold ? '600' : null)};
  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`

const SubLabel = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 0.875rem;
  max-width: 400px;
`

const DescriptionText = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 1rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  max-width: 200px;
  padding-top: 24px;
  line-height: 1.4;
`

const DescriptionTextContainer = styled.div`
  min-width: ${p => (p.minWidth ? p.minWidth : '200px')};
`

const ChartLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1.125rem;
  padding-bottom: 16px;
`

const SVGHolder = styled.span`
  position: relative;
  top: 4px;
`

const Container = styled.div`
  min-width: ${p => (p.minWidth ? p.minWidth : null)};
`

class FillGaugeWithDescription extends Component {
  static defaultProps = {
    displayInflation: false,
  }

  static propTypes = {
    displayInflation: PropTypes.bool,
  }

  render() {
    const { displayInflation, store } = this.props
    const { modifiedCase, recommendedCase } = store
    const { retirementIncomeGoalPct } = store.person

    return (
      <div>
        <Spacer space='6px' />
        <FlexRow justifyContent='flex-start'>
          <DescriptionTextContainer>
            <ChartLabel>Recommended</ChartLabel>
          </DescriptionTextContainer>
          <DescriptionTextContainer minWidth='200px'>
            <ChartLabel>Modified</ChartLabel>
          </DescriptionTextContainer>
        </FlexRow>
        <FlexRow justifyContent='flex-start' alignItems='center'>
          <Container minWidth='200px'>
            <FillGauge id='recommendedIncomeGoal' fill={recommendedCase.retirementIncomeGoal} />
          </Container>
          <Container minWidth='150px'>
            <FillGauge id='modifiedIncomeGoal' fill={modifiedCase.retirementIncomeGoal} />
          </Container>
          <div>
            <DescriptionText>After-tax income strategy replaces after retiring</DescriptionText>
            <SVGHolder>
              <HelpIcon tooltip={TooltipText.replacementIncome()} />
            </SVGHolder>
            <Spacer space='4px' />
            {modifiedCase.retirementIncomeGoal < retirementIncomeGoalPct && (
              <DescriptionTextContainer>
                <HelpIcon
                  helpLabel='Income goal not met'
                  tooltip={TooltipText.incomeGoalNotMet()}
                  reverse
                />
              </DescriptionTextContainer>
            )}
          </div>
        </FlexRow>
        <Spacer space='12px' />
        <FlexRow justifyContent='flex-start' alignItems='flex-end'>
          <Container minWidth='200px'>
            <Amount>{numberToDollars(recommendedCase.projectedMonthlyRetirementInc, true)}</Amount>
          </Container>
          <Container minWidth='150px'>
            <Amount
              difference={
                modifiedCase.projectedMonthlyRetirementInc -
                recommendedCase.projectedMonthlyRetirementInc
              }>
              {numberToDollars(modifiedCase.projectedMonthlyRetirementInc, true)}
            </Amount>
          </Container>
          <DescriptionTextContainer>
            <SubLabel>Today's value</SubLabel>
          </DescriptionTextContainer>
        </FlexRow>
        <Spacer space='2px' />
        <FlexRow justifyContent='flex-start' alignItems='flex-start' maxWidth='380px'>
          <DescriptionTextContainer>
            <FlexRow justifyContent='flex-start' alignItems='flex-end'>
              <SubAmount>
                {numberToDollars(recommendedCase.projectedMonthlyRetirementInc * 12, true)}
              </SubAmount>
              <SubLabel>annual</SubLabel>
            </FlexRow>
          </DescriptionTextContainer>
          <Container minWidth='150px'>
            <FlexRow justifyContent='flex-start' alignItems='flex-end' maxWidth='380px'>
              <SubAmount>
                {numberToDollars(modifiedCase.projectedMonthlyRetirementInc * 12, true)}
              </SubAmount>
              <SubLabel>annual</SubLabel>
            </FlexRow>
          </Container>
          <DescriptionTextContainer>
            <HelpPersonIcon
              size='xLarge'
              helpLabel='View ranges'
              tooltip={TooltipText.ongoingMonthlyContributionsModifiedAdvice(displayInflation)}
            />
          </DescriptionTextContainer>
        </FlexRow>
        <Spacer space='12px' />
        {displayInflation && (
          <>
            <FlexRow justifyContent='flex-start'>
              <Container minWidth='200px'>
                <Amount>
                  {numberToDollars(
                    recommendedCase.projectedMonthlyInflationAdjustedRetirementInc,
                    true
                  )}
                </Amount>
              </Container>
              <Container minWidth='150px'>
                <Amount
                  difference={
                    modifiedCase.projectedMonthlyInflationAdjustedRetirementInc -
                    recommendedCase.projectedMonthlyInflationAdjustedRetirementInc
                  }>
                  {numberToDollars(
                    modifiedCase.projectedMonthlyInflationAdjustedRetirementInc,
                    true
                  )}
                </Amount>
              </Container>
              <DescriptionTextContainer>
                <SubLabel>Value at retirement</SubLabel>
              </DescriptionTextContainer>
            </FlexRow>
            <FlexRow justifyContent='flex-start' alignItems='flex-start'>
              <Container minWidth='200px'>
                <FlexRow justifyContent='flex-start' alignItems='flex-end'>
                  <SubAmount>
                    {numberToDollars(
                      recommendedCase.projectedMonthlyInflationAdjustedRetirementInc * 12,
                      true
                    )}
                  </SubAmount>
                  <SubLabel>annual</SubLabel>
                </FlexRow>
              </Container>
              <Container minWidth='150px'>
                <FlexRow justifyContent='flex-start' alignItems='flex-end'>
                  <SubAmount>
                    {numberToDollars(
                      modifiedCase.projectedMonthlyInflationAdjustedRetirementInc * 12,
                      true
                    )}
                  </SubAmount>
                  <SubLabel>annual</SubLabel>
                </FlexRow>
              </Container>
              <DescriptionTextContainer />
            </FlexRow>
          </>
        )}
      </div>
    )
  }
}

export default inject('store')(observer(FillGaugeWithDescription))
