import styled from '@emotion/styled'

const Wrapper = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
`

Wrapper.Title = styled.div`
  height: 38px;
  width: 533pt;

  color: ${p => p.theme.darkestGray};
  font-size: 28pt;
  font-weight: 300;
  line-height: 38px;
  margin-bottom: 21pt;
`

Wrapper.GrayBox = styled.div`
  width: 612pt;
  height: 199pt;

  position: absolute;
  left: 0;
  right: 0;
  background-color: #e6e9ea;
  padding: 14pt;
`

Wrapper.BlueText = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');
  color: #038ab7;
  font-family: 'Aleo';
  font-size: 16pt;
  line-height: 23pt;
  text-align: center;
  font-weight: 400;
`
Wrapper.BlueTextSmall = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');
  color: #038ab7;
  font-family: 'Aleo';
  font-size: 14pt;
  line-height: 20pt;
  text-align: center;
  font-weight: 400;
`

Wrapper.BlueTextBold = styled(Wrapper.BlueText)`
  font-weight: bold;
`

Wrapper.Text = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: Aleo;
  font-size: 14pt;
  line-height: 20pt;
  text-align: center;
`

Wrapper.BoldText = styled(Wrapper.Text)`
  font-weight: bold;
`

Wrapper.TextLeftAlign = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 14pt;
  line-height: 20pt;
`

Wrapper.RowBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 478pt;
  margin: 0 auto;
`

Wrapper.GreyBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

Wrapper.Box = styled.div`
  box-sizing: border-box;
  height: 94pt;
  width: 218pt;
  border: 3pt solid #e7f3f9;
  border-radius: 7pt;
  background-color: #ffffff;
  padding: 14pt;
`

Wrapper.MonthlyAmount = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 36pt;
  line-height: 38pt;
  text-align: center;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`

Wrapper.Divider = styled.div`
  margin-top: 5pt;
  width: 188pt;
  height: 1px;
  border: 1px solid #dadfe1;
`

Wrapper.ConsistencyText = styled.div`
  font-size: 14pt;
  line-height: 21pt;
  text-align: center;
`

Wrapper.AmountsBasedTitle = styled.div`
  font-size: 24pt;
  font-weight: 300;
  line-height: 38pt;
`

Wrapper.AmountsBasedWrapper = styled.div`
  display: flex;
`

Wrapper.AmountsBasedAmount = styled.div`
  font-size: 20pt;
  line-height: 32pt;
  flex: 1;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`

Wrapper.AmountsBasedSource = styled.div`
  flex: 3;
  color: ${p => p.theme.lightestGray};
  font-size: 20pt;
  line-height: 32pt;
`

Wrapper.AmountsBasedDivider = styled.div`
  height: 1px;
  width: 249pt;
  border: 1px solid ${p => p.theme.lightestGray};
  margin: 5pt 0;
`

Wrapper.AmountsBasedTotal = styled.div`
  height: 1px;
  width: 249pt;
  border: 1px solid ${p => p.theme.lightestGray};
  margin: 10pt 0;
`

Wrapper.ConversationIcon = styled.div`
  width: 61px;
  transform: scale(1.4);
  margin: -40px 8px;
  & > div {
    width: 200px;
  }
`
Wrapper.ReturnAnnuallyIcon = styled.div`
  width: 48px;
  transform: scale(1.4);
  margin: -50px 700px;
  & > div {
    width: 200px;
  }
`

export { Wrapper }
