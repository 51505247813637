import { toJS } from 'mobx'
import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'

import { API } from '../api'

const { model, optional } = types

const UserSettings = model({
  newChangesPageViewed: false,
  glidePath: false,
  riskRulerState: 'none',
})

const UserSettingsStore = model({
  userSettings: optional(UserSettings, {}),
}).actions(self => ({
  getUserSettings: flow(function* () {
    const userSettings = yield API.get('user-settings')
    self.userSettings = userSettings.data || {}
  }),

  syncUserSettings: _.debounce(function () {
    self.updateUserSettings()
  }, 500),

  updateUserSettings() {
    API.put('user-settings', toJS(self.userSettings))
  },

  handleNewChangesPageView() {
    self.userSettings.newChangesPageViewed = true
    self.updateUserSettings()
  },

  handleGlidePathChange() {
    self.userSettings.glidePath = !self.userSettings.glidePath
    self.syncUserSettings()
  },

  handleRiskRulerForward() {
    const { riskRulerStates } = self.config.template

    // Find the index of the current state in riskRulerStates
    const currentIndex = riskRulerStates.findIndex(
      state => state === self.userSettings.riskRulerState
    )
    const length = riskRulerStates.length

    // Increment by 1
    const newIndex = currentIndex + 1

    // Grab the new state by getting the value of the array at the new index or rolling over to the beginning at index 0
    if (!length) {
      self.userSettings.riskRulerState = 'none'
    } else if (newIndex >= length) {
      self.userSettings.riskRulerState = riskRulerStates[0]
    } else {
      self.userSettings.riskRulerState = riskRulerStates[newIndex]
    }

    self.syncUserSettings()
  },

  handleRiskRulerBack() {
    const { riskRulerStates } = self.config.template

    // Find the index of the current state in riskRulerStates
    const currentIndex = riskRulerStates.findIndex(
      state => state === self.userSettings.riskRulerState
    )
    const length = riskRulerStates.length

    // Decrement by 1
    const newIndex = currentIndex - 1

    // Grab the new state by getting the value of the array at the new index or rolling over to the end at index array length - 1
    if (!length) {
      self.userSettings.riskRulerState = 'none'
    } else if (newIndex < 0) {
      self.userSettings.riskRulerState = riskRulerStates[length - 1]
    } else {
      self.userSettings.riskRulerState = riskRulerStates[newIndex]
    }

    self.syncUserSettings()
  },
}))

export default UserSettingsStore
