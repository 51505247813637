import { API } from '../api'
import { types, flow } from 'mobx-state-tree'
const { model, maybeNull, number, string } = types

const ContactInfo = model({
  email: maybeNull(string),
  fax: maybeNull(string),
  id: maybeNull(number),
  mailingAddress1: maybeNull(string),
  mailingAddress2: maybeNull(string),
  mailingCity: maybeNull(string),
  mailingCountry: maybeNull(string),
  mailingState: maybeNull(string),
  mailingZip: maybeNull(string),
  mobilePhone: maybeNull(string),
  phone: maybeNull(string),
  phoneExtension: maybeNull(string),
})

const ContactInfoStore = model({
  contactInfo: maybeNull(ContactInfo),
}).actions(self => ({
  getContactInfo: flow(function* () {
    const contactInfo = yield API.get('person/contactInfo')
    self.contactInfo = contactInfo.data || {}
  }),
  updateContactInfo: flow(function* (values) {
    const contactInfo = yield API.put('person/contactInfo', values)
    self.contactInfo = contactInfo.data
  }),
}))

export default ContactInfoStore
