import { Component } from 'react'
import styled from '@emotion/styled'
import ProgressBar from './ProgressBar'

const LoadingView = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`

class Loading extends Component {
  render() {
    return (
      <LoadingView>
        <ProgressBar type='circular' mode='indeterminate' multicolor />
      </LoadingView>
    )
  }
}

export default Loading
