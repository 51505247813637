import { Component } from 'react'
import { inject, observer } from 'mobx-react'

import FRSChanges from './FRSChanges'
import InstitutionalChanges from './InstitutionalChanges'

const templateComponent = templateId => canTransact => {
  switch (templateId) {
    case 1:
      return canTransact ? <FRSChanges /> : null

    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    default:
      return <InstitutionalChanges />
  }
}

class AccountChanges extends Component {
  render() {
    const {
      config: { templateId, canTransact },
    } = this.props.store

    return templateComponent(templateId)(canTransact) || null
  }
}

export default inject('store')(observer(AccountChanges))
