import { css, useTheme } from '@emotion/react'
import { useState, useRef, useLayoutEffect } from 'react'
import styled from '@emotion/styled'
import tippy, { sticky } from 'tippy.js'
import 'tippy.js/dist/tippy.css'

import { Help } from '../assets/icons'
import { SVGWrapper } from './'

const HelpLabel = styled.span`
  font-size: 0.875rem;
  line-height: 1.45;
  padding: ${p => (p.paddingLabel ? p.paddingLabel : '0px 4px')};
  text-decoration: underline;
`

const StyledWrapper = styled.div`
  display: inline-block;
  color: ${p => (p.active ? p.theme.whatIsAGoalActiveColor : p.theme.lightestGray)};
  cursor: pointer;

  svg {
    .icon-color {
      fill: ${p => (p.active ? p.theme.whatIsAGoalActiveColor : p.theme.lightestGray)};
    }
  }

  &:hover {
    color: ${p => (p.active ? p.theme.whatIsAGoalActiveColor : p.theme.whatIsAGoalHoverColor)};
    svg {
      .icon-color {
        fill: ${p => (p.active ? p.theme.whatIsAGoalActiveColor : p.theme.whatIsAGoalHoverColor)};
      }
    }
  }

  &:active {
    color: ${p => p.theme.whatIsAGoalActiveColor};
    svg {
      .icon-color {
        fill: ${p => p.theme.whatIsAGoalActiveColor};
      }
    }
  }
`

function HelpIcon(props) {
  const [state, setState] = useState({ active: false })

  const clickableEl = useRef(null)
  const tooltipEl = useRef(null)

  const theme = useTheme()

  function showState() {
    setState({ active: true })
  }

  function hideState() {
    setState({ active: false })
  }

  useLayoutEffect(() => {
    tippy(clickableEl.current, {
      trigger: 'click focus',
      content: tooltipEl.current,
      theme: 'light',
      interactive: true,
      appendTo: () => document.body,
      sticky: true,
      plugins: [sticky],
      arrow: true,
      maxWidth: '550px',
      onShow: showState,
      onHide: hideState,
      placement: 'auto',
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            enabled: true,
            options: {
              flipBehavior: props.flipBehavior || 'flip',
            },
          },
        ],
      },
    })
  }, []) // eslint-disable-line

  const { className, onClick, helpLabel, size = 'large', children, reverse, tooltip } = props

  return reverse ? (
    <StyledWrapper active={state.active} className={className}>
      <span ref={clickableEl}>
        {helpLabel && <HelpLabel onClick={onClick}>{helpLabel}</HelpLabel>}
        <SVGWrapper
          active={state.active}
          onClick={onClick}
          size={size}
          fill={theme.whatIsAGoalColor}
          hoverFill={theme.whatIsAGoalHoverColor}
          activeFill={theme.whatIsAGoalActiveColor}
          svg={Help}
          css={css`
            & > * {
              pointer-events: none;
            }
          `}
        />
        {children && children}
      </span>
      <div style={{ display: 'block' }} ref={tooltipEl}>
        {tooltip}
      </div>
    </StyledWrapper>
  ) : (
    <StyledWrapper active={state.active}>
      <span
        ref={clickableEl}
        css={css`
          pointer-events: all;
        `}>
        <SVGWrapper
          active={state.active}
          onClick={onClick}
          size={size}
          fill={theme.whatIsAGoalColor}
          hoverFill={theme.whatIsAGoalHoverColor}
          activeFill={theme.whatIsAGoalActiveColor}
          svg={Help}
          css={css`
            & > * {
              pointer-events: none;
            }
          `}
        />
        {helpLabel && <HelpLabel onClick={onClick}>{helpLabel}</HelpLabel>}
        {children && children}
      </span>
      <div style={{ display: 'block', textAlign: 'left' }} ref={tooltipEl}>
        {tooltip}
      </div>
    </StyledWrapper>
  )
}

export { HelpIcon }
export default HelpIcon
