import variables from '../variables'
import colors from '../colors'
import reduceCSSCalc from 'reduce-css-calc'

const snackbar = {
  colorCancel: colors.rt.red500,
  colorAccept: colors.rt.green500,
  colorWarning: colors.rt.limea200,
  backgroundColor: colors.rt.text,
  borderRadius: reduceCSSCalc(`calc(0.2 * ${variables.unit})`),
  buttonOffset: reduceCSSCalc(`calc(4.8 * ${variables.unit})`),
  color: colors.rt.white,
  horizontalOffset: reduceCSSCalc(`calc(2.4 * ${variables.unit})`),
  verticalOffset: reduceCSSCalc(`calc(1.4 * ${variables.unit})`),
}

export default snackbar
