import * as yup from 'yup'

const schema = yup.object().shape({
  primaryIncludeSsi: yup.boolean().nullable().required('This field is required'),
  spouseIncludeSsi: yup
    .boolean()
    .nullable()
    .when('includeSpouse', (includeSpouse, schema) =>
      includeSpouse ? schema.required('This field is required') : schema
    ),
  primaryPaymentBeginAge: yup
    .number()
    .nullable()
    .when('primaryIncludeSsi', (primaryIncludeSsi, schema) =>
      primaryIncludeSsi ? schema.required('This field is required') : schema
    ),
  spousePaymentBeginAge: yup
    .number()
    .nullable()
    .when('spouseIncludeSsi', (spouseIncludeSsi, schema) =>
      spouseIncludeSsi ? schema.required('This field is required') : schema
    ),
  primaryReceivingSsi: yup
    .boolean()
    .nullable()
    .when(
      ['primaryPaymentBeginAge', 'primaryCurrentAge'],
      (primaryPaymentBeginAge, primaryCurrentAge, schema) =>
        parseInt(primaryPaymentBeginAge) === primaryCurrentAge
          ? schema.required('This field is required')
          : schema
    ),
  primaryMonthlyAmountCustom: yup
    .number()
    .nullable()
    .when(
      ['primaryIncludeSsi', 'primaryReceivingSsi', 'primaryUseGcEstimate'],
      (primaryIncludeSsi, primaryReceivingSsi, primaryUseGcEstimate, schema) => {
        if (
          primaryIncludeSsi &&
          (primaryReceivingSsi || (!primaryReceivingSsi && primaryUseGcEstimate === false))
        ) {
          return schema
            .min(1, 'Please enter an amount between $1 and $100,000,000')
            .max(100000000, 'Please enter an amount between $1 and $100,000,000')
            .required('Monthly amount is required')
        } else {
          return schema
        }
      }
    ),
  primaryUseGcEstimate: yup
    .boolean()
    .nullable()
    .when(
      ['primaryIncludeSsi', 'primaryReceivingSsi'],
      (primaryIncludeSsi, primaryReceivingSsi, schema) => {
        if (primaryIncludeSsi && primaryReceivingSsi === false) {
          return schema.required('This field is required')
        }
        return schema
      }
    ),
  spouseUseGcEstimate: yup
    .boolean()
    .nullable()
    .when(
      ['spouseIncludeSsi', 'spouseReceivingSsi'],
      (spouseIncludeSsi, spouseReceivingSsi, schema) => {
        if (spouseIncludeSsi && spouseReceivingSsi === false) {
          return schema.required('This field is required')
        }
        return schema
      }
    ),
  spouseMonthlyAmountCustom: yup
    .number()
    .nullable()
    .when(
      ['spouseIncludeSsi', 'spouseReceivingSsi', 'spouseUseGcEstimate'],
      (spouseIncludeSsi, spouseReceivingSsi, spouseUseGcEstimate, schema) => {
        if (
          spouseIncludeSsi &&
          (spouseReceivingSsi || (!spouseReceivingSsi && spouseUseGcEstimate === false))
        ) {
          return schema
            .min(1, 'Please enter an amount between $1 and $100,000,000')
            .max(100000000, 'Please enter an amount between $1 and $100,000,000')
            .required('Monthly amount is required')
        } else {
          return schema
        }
      }
    ),
})

export { schema }
