import styled from '@emotion/styled'

const TitleDisplayName = styled.div`
  word-break: break-word;
`

const MainBalance = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2.75rem;
  max-width: 600px;
  color: ${p => p.theme.darkestGray};
  font-weight: 300;
  line-height: 1.25;
  text-align: right;
`

const BalanceGroup = styled.div``

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const SectionTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.35;
`
const SecondaryText = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.125rem;
  text-align: right;
  line-height: 1.5;
`
const TotalBalanceText = styled(SecondaryText)`
  padding: 0.5rem 0 1.5rem;
  color: ${p => p.theme.darkestGray};
`

const BalanceText = styled.span`
  color: ${p => p.theme.totalBalanceTextColor};
  font-weight: 600;
`

const NoticeText = styled.span`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
`

const SectionBalance = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-size: 1.125rem;
  font-weight: 600;
  padding: 0 8px;
`

const DollarSign = styled.span`
  color: ${p => p.theme.lightestGray};
  font-weight: 300;
`

const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-top: 1px solid #979797;
  padding: ${p =>
    p.secondRow
      ? p.minimum_bottom_padding
        ? '8px 0 10px'
        : '8px 0 20px'
      : p.minimum_bottom_padding
      ? '8px 0 10px'
      : '8px 0 40px'};
`

const SectionLabel = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1.125rem;
  padding: 0 8px;
`

const SectionSubLabel = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 0.875rem;
  padding: 4px 8px;
`

const NameField = styled.div`
  display: flex;
`

const BalanceField = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
`

const EditButton = styled.a`
  color: ${p => p.theme.primaryColor};
  font-size: 0.75rem;
  text-decoration: underline;
  cursor: pointer;
  padding: 0 0 0 8px;
`

const SVGContainer = styled.div`
  padding-right: 8px;
`

const SVGPlaceholder = styled.div`
  height: 24px;
  width: 24px;
`

const AccountDashboardContainer = styled.div`
  max-width: 1100px;
`

const WarningContainer = styled.div`
  padding-left: 40px;
`

const WarningMessage = styled.div`
  color: #f48024;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
`

const ReviewButton = styled.a`
  color: ${p => p.theme.primaryColor};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`

export {
  TitleDisplayName,
  MainBalance,
  BalanceGroup,
  HeaderSection,
  SectionTitle,
  SecondaryText,
  TotalBalanceText,
  BalanceText,
  NoticeText,
  SectionBalance,
  DollarSign,
  SectionRow,
  SectionLabel,
  SectionSubLabel,
  NameField,
  BalanceField,
  EditButton,
  SVGContainer,
  SVGPlaceholder,
  AccountDashboardContainer,
  WarningContainer,
  WarningMessage,
  ReviewButton,
}
