import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { API } from '../../../api'
import { Spacer, Loading, WizardStep } from '../../../components'
import {
  SplitBarChartOffboard,
  SplitBarChartRiskOffboard,
  SplitBarChartLegendOffboard,
  SplitBarChartHeaderOffboard,
} from '../../shared'
import { Heading, Text } from '../elements/styled'
import { numberToDollars } from '../../../utils'
import {
  TabContainer,
  Tab,
  TabText,
  TabSubText,
  ContributionContainer,
  ContributionText,
  SubTitle,
  SubHeading,
  IndentContainer,
} from './AccountChangesStyles'

const templateConfig = templateId =>
  ({
    1: { nextPage: 'confirm-email' },
  }[templateId])

class Changes extends Component {
  state = {
    activeTab: 3,
    breakdownCategory: 'investmentHoldings',
    breakdownData: null,
    loading: true,
  }

  prepareData(activeData) {
    const data = {}

    if (activeData !== null) {
      for (let i = 0; i < activeData.length; i++) {
        const assetCategory = activeData[i].name
        if (Object.prototype.hasOwnProperty.call(data, assetCategory)) {
          for (let i = 0; i < activeData[i].assets.length; i++) {
            data[assetCategory].push(activeData[i].assets[i])
          }
        } else {
          data[assetCategory] = activeData[i].assets
        }
      }
    }

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        data[key] = _.orderBy(data[key], obj => [(obj.name || '').toLowerCase()], ['asc'])
      }
    }

    return data
  }

  componentDidMount() {
    const { store } = this.props

    const breakdownDataPromise = store.getBreakdown(store.acceptedCaseId)
    breakdownDataPromise
      .then(() => {
        this.setState({ breakdownData: store.breakdown })
      })
      .catch(error => {
        console.error(error)
        return Promise.reject(error)
      })

    const contributionRatePromise = API.get('accounts/institutionalAccount')
      .then(response => this.setState({ pretaxSavingsRate: response.data.pretaxSavingsRate }))
      .catch(error => {
        console.error(error)
        return Promise.reject(error)
      })

    Promise.all([breakdownDataPromise, contributionRatePromise])
      .then(() => this.setState({ loading: false }))
      .catch(err => {
        this.setState({ loading: false })
        console.error(err)
      })
  }

  handleBack() {
    this.props.history.goBack()
  }

  handleNext() {
    const { history, store } = this.props
    const { templateId } = store.config
    const { nextPage } = templateConfig(templateId)

    history.push(`/implement/${nextPage}`)
  }

  render() {
    const {
      person: { displayName },
      account: { annualSalary },
      config,
      baseCase,
      acceptedCase,
    } = this.props.store
    const { activeTab } = this.state
    const { theme } = this.props.offboarding

    const activeData =
      this.state.breakdownData !== null &&
      this.state.breakdownData[this.state.breakdownCategory] != null
        ? this.state.breakdownData[this.state.breakdownCategory]
        : []

    const data = this.prepareData(activeData)

    const { riskLevelTextShort, riskScaleIndex } = acceptedCase

    return (
      <WizardStep onBackClick={this.handleBack.bind(this)} onNextClick={this.handleNext.bind(this)}>
        <Heading>Let's put your plan into action</Heading>

        <Spacer space='24px' />

        <IndentContainer>
          <SubTitle>{` Changes to ${displayName}'s ${config.template.planName}`}</SubTitle>

          <Spacer space='12px' />

          <SubHeading>Contributions</SubHeading>

          <Spacer space='6px' />

          <Text>
            As a member of the {config.template.planName}, your contribution rate has been set and
            cannot be changed.
          </Text>

          <Spacer space='20px' />

          {this.state.loading ? (
            <Loading />
          ) : (
            <ContributionContainer>
              <ContributionText>Annual pre-tax total</ContributionText>
              <ContributionText>
                {`${numberToDollars(this.state.pretaxSavingsRate, true)}% | $${numberToDollars(
                  (this.state.pretaxSavingsRate / 100) * annualSalary,
                  true
                )}/year`}
              </ContributionText>
            </ContributionContainer>
          )}

          <Spacer space='24px' />

          <SubHeading>Investment Mix</SubHeading>

          <Spacer space='6px' />

          <Text>
            A big part of getting you on track to meet your goals is to ensure that you have the
            right mix of investments working for you. GuidedChoice recommends a personalized mix of
            investments for your employer plan account, tailored to you to help you reach your goals
            while minimizing the volatility (the ups and downs) of your account.
          </Text>

          <Spacer space='16px' />

          <TabContainer>
            <Tab
              isActive={activeTab === 1}
              onClick={() =>
                this.setState({
                  activeTab: 1,
                  breakdownCategory: 'investmentCategory',
                })
              }>
              <TabText isActive={activeTab === 1}>Investment category</TabText>
              <TabSubText isActive={activeTab === 1}>(fewer details)</TabSubText>
            </Tab>
            <Tab
              isActive={activeTab === 2}
              onClick={() =>
                this.setState({
                  activeTab: 2,
                  breakdownCategory: 'assetClassBreakdown',
                })
              }>
              <TabText isActive={activeTab === 2}>Asset class breakdown</TabText>
              <TabSubText isActive={activeTab === 2}>(some details)</TabSubText>
            </Tab>
            <Tab
              isActive={activeTab === 3}
              onClick={() =>
                this.setState({
                  activeTab: 3,
                  breakdownCategory: 'investmentHoldings',
                })
              }>
              <TabText isActive={activeTab === 3}>Investment holdings</TabText>
              <TabSubText isActive={activeTab === 3}>(more details)</TabSubText>
            </Tab>
          </TabContainer>

          <Spacer space='20px' />

          {this.state.loading ? (
            <Loading />
          ) : (
            <>
              {activeData.length ? (
                <div>
                  <SplitBarChartLegendOffboard />
                  <Spacer space='8px' />
                  <SplitBarChartHeaderOffboard hideOld={activeTab === 2} theme={theme} />
                  <Spacer space='6px' />
                  <SplitBarChartRiskOffboard
                    oldRiskText={baseCase.riskLevelTextShort}
                    newRiskText={riskLevelTextShort}
                    rangeIndexOld={activeTab === 2 ? -1 : baseCase.riskScaleIndex}
                    rangeIndexNew={riskScaleIndex}
                    hideOld={activeTab === 2}
                    theme={theme}
                  />
                  <Spacer space='8px' />
                  {data?.Cash?.length > 0 && (
                    <SplitBarChartOffboard
                      data={data.Cash}
                      title='Cash'
                      svg='Cash'
                      hideOld={activeTab === 2}
                      theme={theme}
                    />
                  )}
                  {data?.Bonds?.length > 0 && (
                    <>
                      <Spacer space='2px' />
                      <SplitBarChartOffboard
                        data={data.Bonds}
                        title='Bond'
                        svg='Bonds'
                        hideOld={activeTab === 2}
                        theme={theme}
                      />
                    </>
                  )}
                  {data?.Stocks?.length > 0 && (
                    <>
                      <Spacer space='2px' />
                      <SplitBarChartOffboard
                        data={data.Stocks}
                        title='Stock'
                        svg='Stock'
                        hideOld={activeTab === 2}
                        theme={theme}
                      />
                    </>
                  )}
                  {data?.Misc?.length > 0 && (
                    <>
                      <Spacer space='2px' />
                      <SplitBarChartOffboard
                        data={data.Misc}
                        title='Other'
                        svg='Misc'
                        hideOld={activeTab === 2}
                        theme={theme}
                      />
                    </>
                  )}
                </div>
              ) : (
                <Text>No data available at this time. Please try again later.</Text>
              )}
            </>
          )}
        </IndentContainer>
        <Spacer space='2px' />
      </WizardStep>
    )
  }
}

export default withRouter(inject('store', 'offboarding')(observer(Changes)))
