import * as yup from 'yup'

export const schema = yup.object().shape({
  paymentType: yup
    .string()
    .nullable()
    .oneOf(['L', 'M'], 'This field is required')
    .required('This field is required'),
  pensionStartAge: yup
    .number()
    .nullable()
    .min(20, 'Please enter an age of 20 or older')
    .max(99, 'Please enter an age between 20 and 99')
    .required('This field is required'),
  monthlyPensionIncome: yup.number().nullable().required('This field is required'),
  lumpsumreceived: yup
    .string()
    .nullable()
    .when('paymentType', (paymentType, schema) =>
      paymentType === 'L'
        ? schema.oneOf(['Y', 'N'], 'This field is required').required('This field is required')
        : schema
    ),
  survivorFraction: yup
    .number()
    .nullable()
    .when('paymentType', (paymentType, schema) =>
      paymentType === 'M'
        ? schema
            .min(0, 'Please enter a value between 0 and 100')
            .max(100, 'Please enter a value between 0 and 100')
        : schema
    ),
})
