import { useRef } from 'react'
import { observer, inject } from 'mobx-react'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'

function DisclosureSecondPage(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
      timeStamp,
    },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'disclosureSecondPage' })
    willMount.current = false
  }

  const page = findByName('disclosureSecondPage')
  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.DisclosureFirstPageTitle>The fine print</Wrapper.DisclosureFirstPageTitle>
        <Spacer space='10pt' />
        <Wrapper.DisclosureFirstTitle>Disclosures</Wrapper.DisclosureFirstTitle>
        <Spacer space='5pt' />

        <Wrapper.DisclosureFirstParagraph>
          The analysis done by GuidedChoice is based on information provided by you about your
          personal and financial circumstances. Please consult with your tax, estate, and/or
          financial advisor to review your decisions as your circumstances change. The information
          in this guide is not written or intended to be tax or legal advice.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='10pt' />
        <Wrapper.DisclosureFirstParagraph>
          When determining from which investments you should withdraw funds, we first consider which
          accounts provide the optimal tax benefits. Within the accounts themselves, our
          recommendation is to withdraw from investments proportionately to maintain the
          diversification of the account. Withdrawals from certain investments may be subject to
          pre-determined deferred sales charges, transaction fees, and/or other costs applied by
          your investment account provider. Deductions of such charges, fees, and other costs will
          result in lower monthly spending amounts.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='10pt' />
        <Wrapper.DisclosureFirstParagraph>
          GuidedChoice prioritizes meeting your monthly income requirements rather than providing
          for those amounts desired for inheritance and final expenses. Your ability to provide for
          these amounts, when applicable, will likely change from year to year depending upon your
          monthly income needs and changes in the market.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='10pt' />
        <Wrapper.DisclosureFirstParagraph>
          We assume retirement is a pre-requisite to the receipt of pension payments, regardless of
          the start age entered into GuidedChoice. Therefore, when applicable, pension payments are
          assumed to begin upon retirement or the payment start age, whichever is later.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='10pt' />
        <Wrapper.DisclosureFirstParagraph>
          GuidedChoice does not sell, market, endorse, or receive any compensation from any annuity
          or investment products.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='30pt' />

        <Wrapper.DisclosureSecondFootnoteText>{`Guide created: ${timeStamp}`}</Wrapper.DisclosureSecondFootnoteText>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(DisclosureSecondPage))
