import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Card, CardField, CardFieldError, Drawer, Spacer } from '../../../components'
import { numberToDollars } from '../../../utils'
import { planTypeIdToAccountKey } from '../../../constants'
import EditEmployerContributions from './EditEmployerContributions'

class EditEmployerContributionsCard extends Component {
  state = { editActive: false }

  handleEdit = () => this.setState({ editActive: true })

  handleCancel = () => this.setState({ editActive: false })

  render() {
    const {
      features: { editInstitutionalAccount },
    } = this.props.store
    const {
      account: {
        employerContrib,
        employerMatch,
        companyMatchRate,
        companyMatchRateMax,
        companyMatchDlrMax,
        profitSharing,
        profitSharingRate,
        type,
        planType,
        contributionTiers,
      },
      control: { employerContribution },
    } = this.props.account

    const canEditEmployerContributions = type !== 'Institutional' && employerContribution

    const trueEmployerMatch = type === 'Institutional' ? employerMatch : employerContrib

    const showProfitSharing = _.includes(['401k', '403b', '401a'], planTypeIdToAccountKey[planType])

    return (
      <Card
        title='Employer contributions'
        handleEdit={canEditEmployerContributions ? this.handleEdit : null}>
        {editInstitutionalAccount ? (
          <>
            {trueEmployerMatch && (
              <CardField label='Employer match?' value={trueEmployerMatch ? 'Yes' : 'No'} />
            )}

            {contributionTiers && contributionTiers.length > 1 ? (
              <>
                {contributionTiers.map((tier, index) => {
                  return (
                    <div key={index}>
                      <CardField
                        label={`Per dollar match rate (tier ${index + 1})`}
                        value={`${numberToDollars(tier?.percentContribution, true)}%`}
                      />

                      <CardField
                        label={`Max percent match (tier ${index + 1})`}
                        value={`${numberToDollars(tier?.to, true)}%`}
                      />
                    </div>
                  )
                })}
                <Spacer space='4px' />
              </>
            ) : (
              <>
                <div>
                  {trueEmployerMatch &&
                    contributionTiers &&
                    contributionTiers[0]?.percentContribution !== null && (
                      <CardField
                        label='Per dollar match rate'
                        value={`${numberToDollars(
                          contributionTiers[0]?.percentContribution,
                          true
                        )}%`}
                      />
                    )}

                  {trueEmployerMatch && contributionTiers && contributionTiers[0]?.to !== null && (
                    <CardField
                      label='Max percent match'
                      value={`${numberToDollars(contributionTiers[0]?.to, true)}%`}
                    />
                  )}
                </div>

                <Spacer space='4px' />
              </>
            )}
          </>
        ) : (
          <>
            {trueEmployerMatch !== null && (
              <CardField label='Employer match?' value={trueEmployerMatch ? 'Yes' : 'No'} />
            )}

            <div>
              {trueEmployerMatch && companyMatchRate !== null && (
                <CardField
                  label='Per dollar match rate'
                  value={`${numberToDollars(companyMatchRate, true)}%`}
                />
              )}

              {trueEmployerMatch && companyMatchRateMax !== null && (
                <CardField
                  label='Max percent match'
                  value={`${numberToDollars(companyMatchRateMax, true)}%`}
                />
              )}

              {trueEmployerMatch && companyMatchDlrMax !== null && (
                <CardField
                  label='Max dollar match'
                  value={`$${numberToDollars(companyMatchDlrMax, true)}`}
                />
              )}
            </div>

            <Spacer space='4px' />
          </>
        )}

        {showProfitSharing && profitSharing !== null && (
          <CardField
            label='Fixed employer contribution or profit sharing?'
            value={profitSharing ? 'Yes' : 'No'}
          />
        )}

        {showProfitSharing && profitSharing && profitSharingRate !== null && (
          <CardField
            label='Expected percentage'
            // round off trailing decimal 0's like 4.00 and
            // round rate to two decimal places
            value={`${
              Number.isInteger(profitSharingRate)
                ? Math.round(profitSharingRate)
                : (Math.round(profitSharingRate * 100) / 100).toFixed(2)
            }%`}
          />
        )}

        {trueEmployerMatch === null && (
          <CardFieldError>
            GuidedChoice does not have data on record for this account
          </CardFieldError>
        )}

        <Drawer
          active={this.state.editActive}
          title='Employer contributions'
          subtitle='Edit account'
          width='50%'>
          {/* eslint-disable-next-line */}
          <EditEmployerContributions handleCancel={this.handleCancel} />
        </Drawer>
      </Card>
    )
  }
}

export default inject('account', 'store')(observer(EditEmployerContributionsCard))
