const LineChart = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<style type="text/css">
  .st0-linechart-svg{fill:#7A8E96;}
</style>
<g>
  <g>
    <path class="st0-linechart-svg" d="M460.8,422.4H71.2V33.9c0-9.2-7.5-16.7-16.7-16.7s-16.7,7.5-16.7,16.7v388.5H16.7c-9.2,0-16.7,7.5-16.7,16.7
      s7.5,16.7,16.7,16.7h21.1v22.3c0,9.2,7.5,16.7,16.7,16.7s16.7-7.5,16.7-16.7v-22.3h389.6c9.2,0,16.7-7.5,16.7-16.7
      S470,422.4,460.8,422.4z"/>
  </g>
</g>
<g>
  <g>
    <path class="st0-linechart-svg" d="M471.4,41c-22.4,0-40.6,18.2-40.6,40.6c0,10.8,4.2,20.7,11.2,28l-62.6,153.2c-6.2,1.3-12,4-16.8,7.9
      l-100.1-50.7c0-0.6,0-1.2,0-1.7c0-22.4-18.2-40.6-40.6-40.6s-40.6,18.2-40.6,40.6c0,8.3,2.5,16,6.7,22.4l-39,61
      c-24.7-3.6-46.5,15.6-46.5,40.2c0,22.4,18.2,40.6,40.6,40.6s40.6-18.2,40.6-40.6c0-8.2-2.4-15.8-6.6-22.2l39.1-61.1
      c11.3,1.6,22.5-1.6,31.3-8.7l99.7,50.5c-1.3,23.5,17.4,42.9,40.6,42.9c22.4,0,40.6-18.2,40.6-40.6c0-13.2-6.3-24.8-16-32.3
      l60.5-148.1c21.8-0.8,39.2-18.6,39.2-40.6C512,59.2,493.8,41,471.4,41z"/>
  </g>
</g>
</svg>
`

export default LineChart
