import styled from '@emotion/styled'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  margin: 20px 24px 36px;

  & > a {
    margin: 12px;
  }
`
const PrimaryButton = styled.a`
  background: linear-gradient(180deg, #39a3c6 0%, #1291bb 100%);
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  color: white;
  font-size: 18px;
  line-height: 1.25;
  cursor: pointer;

  &:hover {
    background: linear-gradient(to bottom, #038ab7 0%, #02769d 100%);
  }

  &:active {
    background: linear-gradient(to bottom, #02607f, #02607f);
  }

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  & > div:nth-of-type(2) {
    font-size: 12px;
  }
`
const SecondaryButton = styled.a`
  display: inline-block;
  border: 1px solid #7a8e96;
  background-color: #7a8e96;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #71858d;
  }

  &:active {
    background-color: #697d84;
  }

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  & > div:nth-of-type(2) {
    font-size: 12px;
  }
`

const GetGuideButtons = ({ openGuide, handleNext }) => {
  return (
    <ButtonGroup>
      <SecondaryButton onClick={openGuide}>
        <div>Download | view now</div>
        <div>opens new tab</div>
      </SecondaryButton>
      <PrimaryButton onClick={handleNext}>
        <div>Logout</div>
        <div>send guide/save email as indicated</div>
      </PrimaryButton>
    </ButtonGroup>
  )
}

export default GetGuideButtons
