import { css, useTheme } from '@emotion/react'
import { BorderedCard, CardTitle } from './EditDrawerStyle'
import RiskInput from './RiskInput'
import RiskCompare from './RiskCompare'
import RiskRestore from './RiskRestore'

const MakeChangesRisk = ({ store, editable, form, values }) => {
  const theme = useTheme()
  return editable.riskIsEditable ? (
    <BorderedCard>
      <CardTitle>
        Risk{' '}
        <span
          css={css`
            font-weight: normal;
          `}>
          of accounts for which GuidedChoice is providing investment advice
        </span>
      </CardTitle>

      <RiskInput values={values} theme={theme} />

      <RiskRestore form={form} />

      <RiskCompare values={values} />
    </BorderedCard>
  ) : null
}

export default MakeChangesRisk
