import { Component } from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { ButtonInput, InputField, Spacer } from './index'

const ButtonsField = styled.div`
  margin: ${p => p.margin};
`

class MultiButtonInput extends Component {
  static displayName = 'MultiButtonInput'

  static defaultProps = {
    buttonsPerRow: 0,
    disabled: false,
    margin: '0 40px 0 0',
  }

  static propTypes = {
    error: PropTypes.string,
    showError: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    helpLabel: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    flexLabel: PropTypes.string,
    flexInput: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    buttons: PropTypes.array,
    buttonWidth: PropTypes.string,
    buttonsPerRow: PropTypes.number,
    disabled: PropTypes.bool,
  }

  renderRows() {
    const { buttons, buttonsPerRow } = this.props

    const rows = buttons
      .map((button, index) =>
        index % buttonsPerRow === 0 ? buttons.slice(index, index + buttonsPerRow) : null
      )
      .filter(button => button)

    return rows.map((row, index) => (
      <div key={index}>
        {this.renderButtons(row)}
        <Spacer space='10px' />
      </div>
    ))
  }

  renderButtons(buttons) {
    const { name, onChange, onBlur, buttonWidth, disabled, value } = this.props

    const btns = buttons || this.props.buttons

    return btns.map(button => (
      <ButtonInput
        key={button.value}
        name={name}
        text={button.text}
        value={button.value}
        isActive={value === button.value}
        onClick={onChange}
        onBlur={onBlur}
        width={buttonWidth}
        disabled={disabled}
      />
    ))
  }

  render() {
    const {
      error,
      showError,
      label,
      helpLabel,
      tooltip,
      flexLabel,
      flexInput,
      value,
      buttons,
      padding,
      children,
      buttonsPerRow,
    } = this.props

    return (
      <InputField
        label={label}
        helpLabel={helpLabel}
        tooltip={tooltip}
        flexLabel={flexLabel}
        flexInput={flexInput}
        padding={padding}
        error={error}
        showError={showError}>
        <ButtonsField {...this.props}>
          {buttonsPerRow ? this.renderRows() : this.renderButtons()}
        </ButtonsField>
        {value === buttons[0].value && children}
      </InputField>
    )
  }
}

export default observer(MultiButtonInput)
