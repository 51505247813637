import { css } from '@emotion/react'
import { useRef } from 'react'
import { observer, inject } from 'mobx-react'

import { Page, PageHeader } from '../elements'
import { Wrapper } from './style'
import { planTypeIdToAccountKey } from '../../../constants'

const TableRow = ({ className, left, right }) => (
  <div
    className={className}
    css={css`
      display: flex;
      color: #06293a;
      font-size: 10pt;
      line-height: 1.5;
    `}>
    <div
      css={css`
        flex: 0 1 40%;
        padding-right: 12px;
      `}>
      {left}
    </div>
    <div
      css={css`
        flex: 0 1 60%;
      `}>
      {right}
    </div>
  </div>
)

function Ira(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
    },
    store: { features, advicedNonGCAccounts },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'ira' })
    willMount.current = false
  }

  const page = findByName('ira')

  // Include this page only if Outside Advice feature is enabled AND included IRA account being adviced
  const includePage =
    features.outsideAdviceEnabled &&
    advicedNonGCAccounts.length > 0 &&
    advicedNonGCAccounts.find(account => planTypeIdToAccountKey[account.planType]?.includes('ira'))

  return includePage ? (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.Heading>A note re: IRA investment mix suggestions</Wrapper.Heading>
        <Wrapper.Intro>
          The funds listed are suggestions only. Depending upon where you have your IRA, our
          suggested fund may not be available to you. If you find yourself in this position, shop
          for the least expensive substitute that is available to you.
        </Wrapper.Intro>
        <TableRow
          css={css`
            padding: 12px 0;
          `}
          left={
            <span
              css={css`
                text-decoration: underline;
              `}>
              Instead of:
            </span>
          }
          right={
            <span
              css={css`
                text-decoration: underline;
              `}>
              Shop for:
            </span>
          }
        />

        <TableRow
          css={css`
            padding: 12px 0;
            border-bottom: 1px solid #69dee3;
          `}
          left={
            <span>
              Fidelity Flex Short-Term Bond
              <br />
              (FBSTX)
            </span>
          }
          right={<span>Short-term bond or cash fund whose expense ratio is 0.25% or lower</span>}
        />

        <TableRow
          css={css`
            padding: 12px 0;
            border-bottom: 1px solid #69dee3;
          `}
          left={
            <span>
              State Street Aggregate Bond Idx
              <br />
              (SSAFX)
            </span>
          }
          right={<span>Intermediate term bond fund whose expense ratio is 0.25% or lower</span>}
        />

        <TableRow
          css={css`
            padding: 12px 0;
            border-bottom: 1px solid #69dee3;
          `}
          left={
            <span>
              Vanguard 500 Index Admiral
              <br />
              (VFIAX)
            </span>
          }
          right={
            <div>
              <div
                css={css`
                  padding-bottom: 4px;
                `}>
                Large blend index fund that meets these criteria (listed by order of importance):
              </div>
              <ul>
                <li>Low cost—preferably whose expense ratio is 0.35% or lower</li>
                <li>Style neutral—avoid funds with the words “Growth” or “Value” in the name</li>
              </ul>
            </div>
          }
        />

        <TableRow
          css={css`
            padding: 12px 0;
            border-bottom: 1px solid #69dee3;
          `}
          left={
            <span>
              State Street Small/Mid Cap Equity Index
              <br />
              (SSMHX)
            </span>
          }
          right={
            <div>
              <div
                css={css`
                  padding-bottom: 4px;
                `}>
                Small blend index fund that meets these criteria (listed by order of importance):
              </div>
              <ul>
                <li>Low cost—preferably whose expense ratio is 0.35% or lower</li>
                <li>Style neutral—avoid funds with the words “Growth” or “Value” in the name</li>
              </ul>
            </div>
          }
        />

        <TableRow
          css={css`
            padding: 12px 0;
          `}
          left={
            <span>
              Fidelity Series Global ex US Index
              <br />
              (FSGEX)
            </span>
          }
          right={
            <div>
              <div
                css={css`
                  padding-bottom: 4px;
                `}>
                Low cost foreign large blend index funds allocated to developed and emerging market
                stock markets roughly proportionate to their capital market weights.
                <br />
                <br />
                <i>What kind of funds, you ask!?</i> Here are some other funds that meet these
                criteria, listed in alphabetical order:
              </div>
              <ul>
                <li>Fidelity Flex International Index</li>
                <li>Fidelity Global ex-US Index</li>
                <li>Fidelity Series Global ex-US Index</li>
                <li>Fidelity Total Intl Index</li>
                <li>iShares MSCI Total Intl Index</li>
                <li>State Street Global Equity ex-US Index</li>
                <li>Vanguard FTSE All-World ex-US Index</li>
                <li>Vanguard Total Intl Stock Index</li>
              </ul>
            </div>
          }
        />
      </Wrapper>
    </Page>
  ) : null
}

export default inject('store', 'guide')(observer(Ira))
