import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'
import dayjs from 'dayjs'
import { API } from '../api'

const { model, maybeNull, number, string, array, optional } = types

const Statement = model({
  accountId: maybeNull(number),
  fileName: maybeNull(string),
  id: maybeNull(number),
  timestamp: maybeNull(string),
})

const UserLog = model({
  accountId: maybeNull(number),
  dbUserId: maybeNull(number),
  eventDescription: maybeNull(string),
  eventLogId: maybeNull(number),
  eventType: maybeNull(string),
  participantId: maybeNull(number),
  timeStamp: maybeNull(string),
})

const UserLogs = model({
  guideUserLogs: array(UserLog),
  transactionUserLogs: array(UserLog),
  loginGuideLogs: array(UserLog),
  savingsRateChangeLogs: array(UserLog),
  statements: array(Statement),
})
  .views(self => ({
    get sortedGuideList() {
      return _.orderBy(self.guideUserLogs, [obj => dayjs(obj.timeStamp)], ['desc'])
    },
    get sortedLoginList() {
      return _.orderBy(self.loginGuideLogs, [obj => dayjs(obj.timeStamp)], ['desc'])
    },
    get sortedSavingList() {
      return _.orderBy(self.savingsRateChangeLogs, [obj => dayjs(obj.timeStamp)], ['desc'])
    },
    get sortedStatements() {
      return _.orderBy(self.statements, [obj => dayjs(obj.timestamp)], ['desc'])
    },
    get recentGuideList() {
      return self.sortedGuideList.slice(0, 3)
    },
    get recentLoginList() {
      return self.sortedLoginList.slice(0, 3)
    },
    get changeSavingsList() {
      return self.sortedSavingList.slice(0, 3)
    },
    get recentStatements() {
      return self.sortedStatements.slice(0, 3)
    },
    get transactionObject() {
      const obj = {}
      for (let i = 0; i < self.transactionUserLogs.length; i++) {
        const caseObj = JSON.parse(self.transactionUserLogs[i].eventDescription)
        obj[caseObj.caseId] = null
      }
      return obj
    },
  }))
  .actions(self => ({
    getGuideEventType: flow(function* () {
      const guideUserLogs = yield API.get('userLog/eventType', {
        params: { eventType: 'guide' },
      })
      self.guideUserLogs = guideUserLogs.data
    }),
    getTransactionEventType: flow(function* () {
      const transactionUserLogs = yield API.get('userLog/eventType', {
        params: { eventType: 'transactionSubmit' },
      })
      self.transactionUserLogs = transactionUserLogs.data
    }),
    getLoginEventType: flow(function* () {
      const loginGuideLogs = yield API.get('userLog/eventType', {
        params: { eventType: 'login' },
      })
      self.loginGuideLogs = loginGuideLogs.data
    }),
    getSavingsEventType: flow(function* () {
      const savingsRateChangeLogs = yield API.get('userLog/eventType', {
        params: { eventType: 'savings_rate_change' },
      })
      self.savingsRateChangeLogs = savingsRateChangeLogs.data
    }),
    getStatements: flow(function* () {
      const statements = yield API.get('statement/all')
      self.statements = statements.data
    }),
  }))

const UserLogsStore = model({
  userLogs: optional(UserLogs, {}),
}).actions(self => ({
  getUserLogs() {
    return Promise.all([
      self.userLogs.getGuideEventType(),
      self.userLogs.getTransactionEventType(),
      self.userLogs.getLoginEventType(),
      self.userLogs.getSavingsEventType(),
      self.userLogs.getStatements(),
    ])
  },
}))

export default UserLogsStore
