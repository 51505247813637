const LifePreserverSVG = `
  <svg width="112px" height="107px" viewBox="0 0 112 107" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Group 11</title>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Artboard" transform="translate(-391.000000, -610.000000)">
              <g id="Group-11" transform="translate(392.000000, 610.000000)">
                  <path d="M47,87.9574468 C30.2938389,85.2510638 17.5971564,70.5914894 17.5971564,53 L0,53 C0,80.5148936 20.6042654,103.068085 47,106" id="Shape-Copy-74" stroke="#4D9927"></path>
                  <path d="M59.1113744,19.0425532 C75.8175355,21.7489362 88.514218,36.4085106 88.514218,54 L106,54 C106,26.4851064 85.3957346,3.93191489 59,1" id="Shape-Copy-73" stroke="#4D9927"></path>
                  <path d="M19.0425532,47.8886256 C21.7489362,31.1824645 36.4085106,18.485782 54,18.485782 L54,1 C26.4851064,1 3.93191489,21.6042654 1,48" id="Shape-Copy-72" stroke="#0D8BB8"></path>
                  <path d="M87.9574468,59.1113744 C85.2510638,75.8175355 70.5914894,88.514218 53,88.514218 L53,106 C80.5148936,106 103.068085,85.3957346 106,59" id="Shape-Copy-71" stroke="#0D8BB8"></path>
                  <path d="M48.0940908,99.1338593 C64.1251905,99.1338593 87.4026482,109.553065 97.9858915,99.1338593 C108.776636,88.5103688 97.9858915,73.0026763 97.9858915,56.6704378 C97.9858915,42.9026549 115.921177,24.2942747 107.964252,14.2946459 C97.2246415,0.79796122 72.6787313,9.99822673 54.0811068,9.99822673 C39.2515348,9.99822673 19.5028498,-4.01586898 9.17848619,5.05652471 C-3.03771376,15.7913438 7.18281416,35.1475661 7.18281416,52.6803639 C7.18281416,68.5553869 -2.56403309,88.5840215 7.69946467,99.1338593 C18.3511123,110.082676 31.6054066,99.1338593 48.0940908,99.1338593 Z" id="Oval-Copy-13" stroke-opacity="0.65" stroke="#7A8E96" fill-rule="nonzero"></path>
                  <rect id="Rectangle-Copy-55" fill-opacity="0.65" fill="#7A8E96" fill-rule="nonzero" x="53" y="0" width="3" height="19"></rect>
                  <rect id="Rectangle-Copy-54" fill-opacity="0.65" fill="#7A8E96" fill-rule="nonzero" x="49" y="88" width="4" height="18"></rect>
                  <polygon id="Rectangle-Copy-53" fill-opacity="0.65" fill="#7A8E96" fill-rule="nonzero" transform="translate(98.156333, 56.033968) rotate(89.000000) translate(-98.156333, -56.033968) " points="96.2813335 46.9089676 100.031333 46.9089676 100.031333 65.1589676 96.2813335 65.1589676"></polygon>
                  <polygon id="Rectangle-Copy-52" fill-opacity="0.65" fill="#7A8E96" fill-rule="nonzero" transform="translate(9.656257, 51.042694) rotate(89.000000) translate(-9.656257, -51.042694) " points="7.78125733 41.4176938 11.5312573 41.4176938 11.5312573 60.6676938 7.78125733 60.6676938"></polygon>
              </g>
          </g>
      </g>
  </svg>
`
export default LifePreserverSVG
