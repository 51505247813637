import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { white },
} = colors

const tooltip = {
  background: color.convert(`color(rgb(97, 97, 97) a(90%))`),
  margin: reduceCSSCalc(`calc(0.5 * ${unit})`),
  borderRadius: reduceCSSCalc(`calc(0.2 * ${unit})`),
  color: white,
  fontSize: unit,
  maxWidth: reduceCSSCalc(`calc(17 * ${unit})`),
  padding: reduceCSSCalc(`calc(0.8 * ${unit})`),
  animationDuration: '200ms',
}

export default tooltip
