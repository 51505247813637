import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Button, SharedModal, Loading } from './'

const ButtonsBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 0 50px auto;
  max-width: 680px;
  width: 100%;

  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 0;
  }
`

const ButtonSpacer = styled.div`
  flex-grow: 1;
`

const ButtonWrapper = styled.div`
  margin: 0px 20px;
`

const SecondaryText = styled.div`
  color: #4a606a;
  font-size: 1.125rem;
  line-height: 33px;
`

class ConfirmModal extends Component {
  static defaultProps = {
    title: 'Delete This Information',
  }

  static propTypes = {
    isModalOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    title: PropTypes.string,
    handleConfirm: PropTypes.func,
    error: PropTypes.bool,
    loading: PropTypes.bool,
  }

  render() {
    const {
      handleConfirm,
      toggleModal,
      isModalOpen,
      title,
      secondaryText,
      confirmButtonText,
      denyButtonText,
      error,
      loading,
    } = this.props

    return (
      <SharedModal isModalOpen={isModalOpen} title={title} toggleModal={toggleModal}>
        {secondaryText && (
          <SecondaryText>
            <p>{secondaryText}</p>
          </SecondaryText>
        )}
        {error && (
          <div
            css={css`
              color: #e31e27;
              font-size: 14px;
              line-height: 1.5;
              text-align: center;
              padding: 0.25rem 0 0.75rem 0;
            `}>
            Something went wrong, please try again later.
          </div>
        )}
        {loading ? (
          <Loading />
        ) : (
          <ButtonsBox>
            <ButtonWrapper>
              <Button secondary label={denyButtonText} onClick={toggleModal} width='85px' />
            </ButtonWrapper>
            <ButtonSpacer />
            <ButtonWrapper>
              <Button primary label={confirmButtonText} onClick={handleConfirm} width='85px' />
            </ButtonWrapper>
          </ButtonsBox>
        )}
      </SharedModal>
    )
  }
}

export default ConfirmModal
