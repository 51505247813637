import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Label, InputBox } from '../../components'

const lightGreyText = css`
  color: #7a8e96;
`

const mediumGreyText = css`
  color: #9eacb0;
  svg {
    stroke: #9eacb0;
    fill: #9eacb0;
  }
`

const titleText = css`
  font-size: 18px;
  font-weight: 300;
`

const link = css`
  text-decoration: underline;
`

const flexRowBetween = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SectionBox = styled.div`
  border: ${p => p.theme.actionItemsBoxBorder};
  border-radius: 8px;
  background-color: #ffffff;
  padding: 10px;
  width: auto;
  position: relative;
`

export const PayoutAdditionalDetailsText = styled.span`
  ${lightGreyText};
  font-size: 14px;
`

export const LightGreyText = styled.span`
  ${lightGreyText};
  ${titleText};
`

export const GreyText = styled.span`
  display: inline-block;
  color: ${p => p.theme.greyTextColor};
  ${titleText};
`

export const InvestmentListItemContainer = styled.div`
  align-items: center;
  display: flex;
  line-height: 1.5;
  @media (max-width: 990px) {
    border: 1px solid #b6c0c4;
    padding: 10px;
    background-color: #fafeff;
  }
`

export const InvestmentListItemBox = styled.div`
  background-color: ${p => p.theme.investmentListItemBgColor};
  border: ${p => p.theme.investmentListItemBorder};
  padding: 10px;
  ${lightGreyText};
  ${titleText};
  ${flexRowBetween};
  width: 100%;
  a {
    ${link};
    color: ${p => p.theme.editColor};
    font-size: 12px;
  }
`

export const FlexRowBetween = styled.div`
  ${flexRowBetween};
`

export const AddButtonBox = styled.div`
  background-color: ${p => p.theme.investmentListItemBgColor};
  border: ${p => p.theme.investmentListItemBorder};
  padding: 10px;
  border-radius: 4px;
  text-align: center;
`

export const DeleteButtonBox = styled.div`
  border-radius: 4px;
  text-align: center;
  background-color: #fef7f1;
  border: 1px solid #b6c0c4;
  padding: 10px;
  color: red;
  &:hover {
    cursor: pointer;
  }
`
export const AddIcon = styled.div`
  ${mediumGreyText};
  float: left;
  margin: 0 10px;
`

export const DeleteIcon = styled.div`
  ${mediumGreyText};
  float: left;
  margin: 0 10px;
`

export const CloseIcon = styled.div`
  ${mediumGreyText};
  display: inline-block;
  padding-right: 9px;
`

export const StyledLink = styled.div`
  color: ${p => p.theme.editColor} !important;
  ${link};
  a {
    ${link};
    color: ${p => p.theme.editColor};
  }
`

export const StyledDeleteLink = styled.div`
  color: #eb436c;
  text-decoration: underline;
  a {
    color: #eb436c;
    text-decoration: underline;
  }
`

export const Description = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1rem;
  line-height: 1.4;
`

export const UpdateAllContainer = styled.div`
  max-width: 1100px;
`

export const StyledFactContainer = styled.div`
  min-width: 220px;
  max-width: 1200px;
  padding: 2rem;
  margin: auto;
  padding: 1rem;
`

export const BorderedStyledFactContainer = styled.div`
  min-width: 220px;
  max-width: 1200px;
  padding: 2rem;
  margin: auto;
  padding: 1rem;
  border: 2px solid #9eacb0;
  border-radius: 8px;
`

export const InvestmentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-left: 60px;
  margin-bottom: 10px;
  color: #9eacb0;
  font-size: 11pt;
  font-weight: lighter;
  @media (max-width: 990px) {
    display: none;
  }
`

export const ResponsiveLabel = styled.div`
  color: #9eacb0;
  font-size: 11pt;
  font-weight: lighter;
  @media (min-width: 991px) {
    display: none;
  }
`

export const AccountBalance = styled.div`
  text-align: right;
  max-width: 1200px;
  @media (max-width: 990px) {
    text-align: left;
  }
`

export const NameHeader = styled.div`
  flex: 0 1 320px;
  cursor: pointer;
  min-width: 175px;
  @media (max-width: 990px) {
    flex: 0 0 39%;
  }
`
export const DateHeader = styled.div`
  flex: 0 0 215px;
  @media (max-width: 990px) {
    flex: 0 0 100%;
  }
`
export const CostBasisHeader = styled.div`
  flex: 0 0 135px;
  @media (max-width: 990px) {
    flex: 0 0 100%;
  }
`
export const SharesHeader = styled.div`
  flex: 0 0 100px;
  min-width: 100px;
  @media (max-width: 990px) {
    width: 100%;
  }
`
export const BalanceHeader = styled.div`
  flex: 0 0 135px;
  cursor: pointer;
  min-width: 135px;
  @media (max-width: 990px) {
    width: 100%;
  }
`

const sortCss = css`
  font-size: 25px;
  border-width: 0 3px 3px 0;
  margin-left: 10px;
  display: inline-block;
  padding: 3px;
`

export const SortAvail = styled.i`
  border: solid #9eacb0;
  color: #9eacb0;
  ${sortCss};
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`

export const SortAsc = styled.i`
  border: solid black;
  color: black;
  ${sortCss};
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`
export const SortDesc = styled.div`
  border: solid black;
  color: black;
  ${sortCss};
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`

export const List = styled.div`
  width: ${p => (p.fullWidth ? '100%' : '100%')};
`

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`

export const InlineBlockContainer = styled.div`
  display: inline-block;
  min-width: 135px;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const FormLabel = props => (
  <Label paddingLeft={props.padding} fontSize='14px' isDrawer>
    {props.children}
  </Label>
)

export const FormText = props => (
  <InputBox
    css={css`
      color: #4a606a;
      font-size: 14px;
      height: 50px;
      padding: ${props.padding || ''};
      width: 100%;
    `}>
    {props.children}
  </InputBox>
)

export const AddInvestmentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

export const TotalBalanceBox = styled.div`
  text-align: right;
`

const RemoveIconStyles = styled.svg`
  fill: rgb(182, 192, 196);
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: fill 0.3s;
  &:hover {
    fill: black;
  }
  &:active {
    fill: rgb(4, 149, 191);
  }
`
export const RemoveIcon = props => (
  <RemoveIconStyles viewBox='0 0 24 24' {...props}>
    <title>Remove</title>
    <path d='M21.6 12a9.6 9.6 0 0 0-19.2 0M0 12a12 12 0 0 1 24 0M2.4 12a9.6 9.6 0 0 0 19.2 0m2.4 0a12 12 0 0 1-24 0m10.73.15l-3.44 3.44L8.7 17l3.44-3.44L15.58 17 17 15.59l-3.44-3.44L17 8.71l-1.42-1.42-3.44 3.44L8.7 7.29 7.29 8.71z' />
  </RemoveIconStyles>
)

export const ViewInvestments = styled.div`
  padding-left: 10px;
  color: ${p => p.theme.viewInvestmentsTextColor};
`

export const DisclaimerText = styled.div`
  padding: 0 20px;
  color: ${p => p.theme.cardFieldValueColor};
  font-size: 1rem;
`
