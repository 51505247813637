/* eslint-disable react/jsx-handler-names */
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 36px 0;

  & > button {
    margin: 1rem;
  }
`
const PrimaryButton = styled.a`
  display: inline-block;
  background: linear-gradient(180deg, #39a3c6 0%, #1291bb 100%);
  border-radius: 4px;
  padding: 12px 24px;
  text-align: center;
  color: white;
  font-family: 'Open Sans';
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover {
    background: linear-gradient(to bottom, #038ab7 0%, #02769d 100%);
  }

  &:active {
    background: linear-gradient(to bottom, #02607f, #02607f);
  }

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }
`
const SecondaryButton = styled.a`
  display: inline-block;
  border: 1px solid #7a8e96;
  background-color: #fff;
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  color: #4a606a;
  font-family: 'Open Sans';
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }

  &:active {
    background-color: #d9d9d9;
  }

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  & > div:nth-of-type(2) {
    font-size: 12px;
  }
`

const ContributionButtons = ({ history, handleNext, isDisabled }) => {
  return (
    <ButtonGroup>
      {/* eslint-disable-next-line */}
      <SecondaryButton disabled={false} onClick={history.goBack}>
        <div>Back</div>
      </SecondaryButton>
      <PrimaryButton disabled={isDisabled} onClick={handleNext}>
        <div>Next</div>
      </PrimaryButton>
    </ButtonGroup>
  )
}

export default withRouter(ContributionButtons)
