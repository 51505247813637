/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const list = 'rt-list-list'

const checkbox = 'rt-list-checkbox'

const checkboxItem = 'rt-list-checkboxItem'

const disabled = 'rt-list-disabled'

const item = 'rt-list-item'

const divider = 'rt-list-divider'

const inset = 'rt-list-inset'

const listItem = 'rt-list-listItem'

const itemAction = 'rt-list-itemAction'

const left = 'rt-list-left'

const right = 'rt-list-right'

const auto = 'rt-list-auto'

const itemContentRoot = 'rt-list-itemContentRoot'

const large = 'rt-list-large'

const normal = 'rt-list-normal'

const selectable = 'rt-list-selectable'

const itemText = 'rt-list-itemText'

const primary = 'rt-list-primary'

const subheader = 'rt-list-subheader'

const ripple = 'rt-list-ripple'

const _list = css`
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: ${ds.get('list.verticalPadding')} 0;
  position: relative;
  text-align: left;
  white-space: nowrap;
  width: 100%;

  ${ds.get('reset')};

  & + .${divider} {
    margin-top: ${reduceCSSCalc(`calc(-1 * ${ds.get('list.verticalPadding')})`)};
  }
`

const _subheader = css`
  color: ${ds.get('colors.rt.textSecondary')};
  font-size: ${ds.get('list.subheaderFontSize')};
  font-weight: ${ds.get('list.subheaderFontWeight')};
  line-height: ${ds.get('list.subheaderHeight')};
  margin: ${reduceCSSCalc(`calc(-1 * ${ds.get('list.verticalPadding')})`)} 0 0;
  padding-left: ${ds.get('list.horizontalPadding')};
`

const _divider = css`
  background-color: ${ds.get('colors.rt.divider')};
  border: 0;
  height: ${ds.get('list.dividerHeight')};
  margin: ${reduceCSSCalc(`calc(-1 * ${ds.get('list.dividerHeight')})`)} 0 0;

  &.${inset} {
    margin-left: ${ds.get('list.contentLeftSpacing')};
    margin-right: ${ds.get('list.horizontalPadding')};
  }
`

const _listItem = css`
  position: relative;

  & > [data-react-toolbox='ripple'] {
    overflow: hidden;
  }

  & .${ripple} {
    color: ${ds.get('colors.rt.textSecondary')};
  }

  & ~ .${divider} {
    margin-bottom: ${ds.get('list.verticalPadding')};
    margin-top: ${ds.get('list.verticalPadding')};
  }
`

const _item = css`
  align-items: center;
  color: ${ds.get('colors.rt.text')};
  display: flex;
  min-height: ${ds.get('list.itemMinHeight')};
  padding: 0 ${ds.get('list.horizontalPadding')};
  position: relative;
  transition-duration: 0.28s;
  transition-property: background-color;
  transition-timing-function: ${ds.get('animation.curveDefault')};

  &.${selectable}:not(.${disabled}):hover {
    background-color: ${ds.get('list.itemHoverColor')};
    cursor: pointer;
  }

  &.${disabled} {
    pointer-events: none;

    &:not(.${checkboxItem}) {
      opacity: 0.5;
    }

    & > .${checkbox} > [data-react-toolbox='label'] {
      opacity: 0.5;
    }
  }
`

const _leftRight = css`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
`

const _left = css`
  ${_leftRight};
  & [data-react-toolbox='font-icon'] {
    width: ${ds.get('list.itemIconSize')};
  }

  & :last-child {
    & > [data-react-toolbox='font-icon'] {
      margin-right: ${ds.get('list.itemRightIconMargin')};
    }
  }
`

const _right = css`
  ${_leftRight};
  & > :last-child {
    margin-right: 0;
  }

  & > :first-child {
    margin-left: ${ds.get('list.horizontalPadding')};
  }
`

const _itemAction = css`
  display: flex;
  margin: ${ds.get('list.itemChildMargin')} ${ds.get('list.horizontalPadding')}
    ${ds.get('list.itemChildMargin')} 0;

  & > :not(button) {
    padding: 0;
  }

  & > [data-react-toolbox='font-icon'] {
    color: ${ds.get('colors.rt.textSecondary')};
    font-size: ${ds.get('list.itemIconFontSize')};
  }
`

const _itemContentRoot = css`
  display: block;
  flex-grow: 1;

  &.${large} {
    display: flex;
    flex-direction: column;
    height: ${ds.get('list.itemMinHeightLegend')};
    justify-content: center;
  }
`

const _checkbox = css`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin: 0;
  min-height: ${ds.get('list.itemMinHeight')};
  width: 100%;

  & > [data-react-toolbox='check'] {
    margin-right: ${ds.get('list.itemRightCheckboxMargin')};
  }

  & > [data-react-toolbox='label'] {
    padding-left: 0;
  }
`

const _itemText = css`
  display: block;

  &:not(.${primary}) {
    color: ${ds.get('colors.rt.textSecondary')};
    font-size: ${ds.fs('s')};
    padding-top: ${ds.get('list.itemLegendMarginTop')};
    white-space: normal;
  }

  &.${primary} {
    color: ${ds.get('colors.rt.text')};
    font-size: ${ds.fs('base')};
  }
`

export default {
  list: cx(list, _list),
  checkbox: cx(checkbox, _checkbox),
  checkboxItem,
  disabled,
  item: cx(item, _item),
  divider: cx(divider, _divider),
  inset,
  listItem: cx(listItem, _listItem),
  itemAction: cx(itemAction, _itemAction),
  left: cx(left, _left),
  right: cx(right, _right),
  auto,
  itemContentRoot: cx(itemContentRoot, _itemContentRoot),
  large,
  normal,
  selectable,
  itemText: cx(itemText, _itemText),
  primary,
  subheader: cx(subheader, _subheader),
  ripple,
}
