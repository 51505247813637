import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'

import {
  HouseholdIncomeCard,
  SSIncomeCardPrimary,
  SSIncomeCardSpouse,
  ExpectRecCard,
  OtherGoalsCard,
  OtherSavingsCard,
} from './FactCard'

const FactCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledFactCard = styled.div`
  min-width: 260px;
`

const FactContainer = inject('store')(
  observer(
    class extends Component {
      generateEligibleCards = () => {
        const { store } = this.props
        const { primarySSIBenefits, spouseSSIBenefits } = store
        const personIncludeSSI = _.get(primarySSIBenefits, 'includeSsi', null)
        const spouseIncludeSSI = _.get(spouseSSIBenefits, 'includeSsi', null)
        const { baseCase, recommendedCase } = store
        const { includeSpouse } = store.person

        const { otherFinancialGoalsTotal, primary } = baseCase

        const eligibleCardArray = []
        eligibleCardArray.push('0')

        if (!!personIncludeSSI && !!baseCase.primary && !!primarySSIBenefits.monthlyAmount) {
          eligibleCardArray.push('1')
        }

        if (
          !!spouseIncludeSSI &&
          !!includeSpouse &&
          !!baseCase.spouse &&
          !!baseCase.spouse.preTaxSSIncome
        ) {
          eligibleCardArray.push('2')
        }

        if (
          recommendedCase.estimatedFutureValue > baseCase.estimatedFutureValue &&
          recommendedCase.estimatedFutureValue > 0
        ) {
          eligibleCardArray.push('3')
        }

        if (otherFinancialGoalsTotal) {
          eligibleCardArray.push('4')
        }

        if (!!primary && !!primary.ongoingAnnualContributions) {
          eligibleCardArray.push('5')
        }
        return eligibleCardArray
      }

      shuffledEligibleCards = cards => {
        for (let i = cards.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          const temp = cards[i]
          cards[i] = cards[j]
          cards[j] = temp
        }

        return cards.slice(0, 3)
      }

      render() {
        const cardMap = {
          0: <HouseholdIncomeCard />,
          1: <SSIncomeCardPrimary />,
          2: <SSIncomeCardSpouse />,
          3: <ExpectRecCard />,
          4: <OtherGoalsCard />,
          5: <OtherSavingsCard />,
        }

        return (
          <FactCardContainer>
            {this.shuffledEligibleCards(this.generateEligibleCards()).map(index => (
              <StyledFactCard key={index}>{cardMap[index]}</StyledFactCard>
            ))}
          </FactCardContainer>
        )
      }
    }
  )
)

export default FactContainer
