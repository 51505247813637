import { Component } from 'react'
import { NumericFormat } from 'react-number-format'
import PropTypes from 'prop-types'
import { Text } from './'

export class CurrencyText extends Component {
  static defaultProps = {
    prefix: '$',
  }

  static propTypes = {
    decimalScale: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    prefix: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  render() {
    const { decimalScale, fontSize, prefix, value } = this.props

    return (
      <Text fontSize={fontSize}>
        <NumericFormat
          value={value || (value === 0 ? 0 : undefined)}
          displayType='text'
          decimalScale={decimalScale}
          fixedDecimalScale
          thousandSeparator
          disabled
          readOnly
          prefix={prefix}
          renderText={value => `${value}`}
        />
      </Text>
    )
  }
}

export default CurrencyText
