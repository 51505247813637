import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { numberToDollars } from '../../../utils/utils'
import { Spacer } from '../../../components'

function FundsNeededWithdraw(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
      investmentWithdrawals,
      isWithdrawingFromCompanyStock,
    },
    store: { person },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'fundsNeededWithdraw' })
    willMount.current = false
  }

  const taxType = type => {
    switch (type) {
      case 1:
        return '—Pre-tax investments'
      case 2:
        return '—After-tax investments'
      case 5:
        return '—Roth investments'
      default:
        return ''
    }
  }

  const page = findByName('fundsNeededWithdraw')
  return (
    <Page header={<PageHeader padding='20pt 40pt 16pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.Title>How to withdraw funds needed</Wrapper.Title>
        <Wrapper.SubTitle>From which accounts to withdraw</Wrapper.SubTitle>
        <Spacer space='10pt' />
        {isWithdrawingFromCompanyStock && (
          <Wrapper.WithdrawalText>
            Important: Your withdrawal includes company stock. Please be sure to read the notice
            regarding the use of company stock for expenses located in The Fine Print area towards
            the end of this guide.
          </Wrapper.WithdrawalText>
        )}
        {investmentWithdrawals.livingExpensesWithdrawalAdvice.investmentAccountWithdrawals.map(
          account => {
            return (
              <div key={account.planName + account.totalAmount}>
                <Wrapper.FlexSpaceBetween>
                  <Wrapper.FundsNeededSellFromTitle>
                    {account.planName + taxType(account.taxType)}
                  </Wrapper.FundsNeededSellFromTitle>
                  <Wrapper.FundsNeededSellFromTypeAmount dark>
                    {numberToDollars(account.totalAmount, true)}
                  </Wrapper.FundsNeededSellFromTypeAmount>
                </Wrapper.FlexSpaceBetween>
                <Spacer space='10pt' />
              </div>
            )
          }
        )}

        <Wrapper.FlexSpaceBetween>
          <Wrapper.FundsNeededSellFromGrandTotal>
            Grand total from all accounts
          </Wrapper.FundsNeededSellFromGrandTotal>
          <Wrapper.FundsNeededSellFromGrandTotalAmount>
            {numberToDollars(investmentWithdrawals.livingExpensesWithdrawalAdvice.grandTotal, true)}
          </Wrapper.FundsNeededSellFromGrandTotalAmount>
        </Wrapper.FlexSpaceBetween>

        <Spacer space='40pt' />
        {(!_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) ||
          (person.includeSpouse &&
            !_.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome))) && (
          <Wrapper.GrandTotalDisclaimer>
            The grand total does <b>not</b> include the cost of your annuity. This information is
            coming up.
          </Wrapper.GrandTotalDisclaimer>
        )}
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(FundsNeededWithdraw))
