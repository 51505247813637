import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const ErrorTextBox = styled.div`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
  text-align: left;
`

export default class TextErrorField extends Component {
  static defaultProps = {
    showError: false,
  }

  static propTypes = {
    error: PropTypes.string,
    showError: PropTypes.bool,
  }

  render() {
    const { error, showError } = this.props

    return <ErrorTextBox>{showError && error}</ErrorTextBox>
  }
}
