export const accountTypeToLabel = {
  '401k': '401(k)',
  '403b': '403(b)',
  '457': '457',
  'payroll-deduct-ira': 'Payroll Deduct IRA',
  '401a': '401(a)',
  'keogh': 'Keogh',
  'sep-ira': 'SEP IRA',
  'simple-ira': 'SIMPLE IRA',
  'ira-rollover': 'IRA | Rollover',
  'ira-roth': 'IRA | Roth',
  'ira-deductible': 'IRA | Deductible',
  'ira-non-deductible': 'IRA | Non-deductible',
  'brokerage-account': 'Brokerage Account',
  'annuity-fixed': 'Annuity | Fixed',
  'pension': 'Pension',
  'other-asset': 'Other Asset',
}
