/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'

const ripple = 'rt-ripple-ripple'

const rippleActive = 'rt-ripple-rippleActive'

const rippleRestarting = 'rt-ripple-rippleRestarting'

const rippleWrapper = 'rt-ripple-rippleWrapper'

const _rippleWrapper = css`
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${ds.z('normal')};
`

const _ripple = css`
  background-color: currentColor;
  border-radius: 50%;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  transition-duration: ${ds.get('ripple.duration')};
  z-index: ${ds.z('high')};

  &.${rippleRestarting} {
    opacity: ${ds.get('ripple.finalOpacity')};
    transition-property: none;
  }

  &.${rippleActive} {
    opacity: ${ds.get('ripple.finalOpacity')};
    transition-property: transform;
  }

  &:not(.${rippleActive}):not(.${rippleRestarting}) {
    opacity: 0;
    transition-property: opacity, transform;
  }
`

export default {
  ripple: cx(ripple, _ripple),
  rippleActive,
  rippleRestarting,
  rippleWrapper: cx(rippleWrapper, _rippleWrapper),
}
