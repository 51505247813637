import styled from '@emotion/styled'

import logo from '../assets/images/GuidedChoice-logo.png'

export default function LoadingMessage() {
  return (
    <LoadingView>
      <LogoContainer>
        <Logo src={logo} alt='Logo' />
      </LogoContainer>
      <Message>
        The service will be up in just a minute — we're loading information from your service
        provider
      </Message>
    </LoadingView>
  )
}

const Message = styled.div`
  margin: 5% 0;
  color: #7a8e96;
  font-size: 1.4rem;
  padding: 4px 0;
  line-height: 1.4;
`
const LoadingView = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  text-align: center;
`

const LogoContainer = styled.div`
  max-width: 260px;
  min-width: 175px;
  padding: 20px;
  background-color: ${p => p.theme.themeColor};
`
const Logo = styled.img`
  width: 100%;
`
