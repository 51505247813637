/* eslint-disable import/no-anonymous-default-export */

import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const head = 'rt-table-head'

const table = 'rt-table-table'

const asc = 'rt-table-asc'

const headCell = 'rt-table-headCell'

const numeric = 'rt-table-numeric'

const rowCell = 'rt-table-rowCell'

const sorted = 'rt-table-sorted'

const sortIcon = 'rt-table-sortIcon'

const tableCell = 'rt-table-tableCell'

const checkboxCell = 'rt-table-checkboxCell'

const row = 'rt-table-row'

const selected = 'rt-table-selected'

const _table = css`
  background-color: ${ds.get('colors.rt.white')};
  border-collapse: collapse;
  font-size: ${ds.get('table.fontSize')};
  width: 100%;

  ${ds.get('reset')};
`

const _head = css`
  padding-bottom: ${reduceCSSCalc(`calc(0.3 * ${ds.get('unit')})`)};
  white-space: nowrap;
`

const _row = css`
  color: ${ds.get('table.rowColor')};
  height: ${ds.get('table.rowHeight')};
  transition-duration: 0.28s;
  transition-property: background-color;
  transition-timing-function: ${ds.get('animation.curveDefault')};

  &:hover,
  &.${selected}:hover {
    background-color: ${ds.get('table.hoverColor')};
  }
  &.${selected} {
    background-color: ${ds.get('table.selectionColor')};
  }
`

const _rowCellHeadCell = css`
  padding: 0 ${ds.get('table.columnPadding')} 12px ${ds.get('table.columnPadding')};
  text-align: left;

  &:first-of-type {
    padding-left: 24px;
  }
  &:last-of-type {
    padding-right: 24px;
  }
  &.${numeric} {
    text-align: right;
  }
`

const _rowCell = css`
  ${_rowCellHeadCell};
  border-bottom: ${ds.get('table.dividers')};
  border-top: ${ds.get('table.dividers')};
  height: ${ds.get('table.rowHeight')};
  padding-top: ${ds.get('table.cellTop')};
  vertical-align: middle;

  &.${checkboxCell} {
    padding-right: 5px;
    width: ${reduceCSSCalc(`calc(1.8 * ${ds.get('unit')})`)};

    & > * {
      margin: 0;
    }
  }
`

const _headCell = css`
  ${_rowCellHeadCell};
  color: ${ds.get('table.headerColor')};
  font-size: ${ds.get('table.headerFontSize')};
  font-weight: 500;
  height: ${ds.get('table.rowHeight')};
  line-height: ${reduceCSSCalc(`calc(2.4 * ${ds.get('unit')})`)};
  padding-bottom: 8px;
  text-overflow: ellipsis;
  vertical-align: bottom;

  &.${checkboxCell} {
    padding-right: 5px;
    width: ${reduceCSSCalc(`calc(1.8 * ${ds.get('unit')})`)};

    & > * {
      margin: 0 0 3px;
    }
  }

  &.${sorted} {
    color: ${ds.get('table.headerSortedColor')};
    cursor: pointer;

    &:hover .${sortIcon} {
      color: ${ds.get('table.headerSortedIconHoverColor')};
    }
  }
`

const _sortIcon = css`
  display: inline-block;
  font-size: ${reduceCSSCalc(`calc(1.6 * ${ds.get('unit')})`)};
  margin-right: 3px;
  transition: 0.28s transform ${ds.get('animation.curveDefault')};
  vertical-align: sub;

  &.${asc} {
    transform: rotate(180deg);
  }
`

export default {
  head: cx(head, _head),
  table: cx(table, _table),
  asc,
  headCell: cx(headCell, _headCell),
  numeric,
  rowCell: cx(rowCell, _rowCell),
  sorted,
  sortIcon: cx(sortIcon, _sortIcon),
  tableCell,
  checkboxCell,
  row: cx(row, _row),
  selected,
}
