import _ from 'lodash'
import { InvestmentTypeIdToKey, IndexMap } from './index'

export const groupMixByType = array => {
  const sortedArray = _.orderBy(array, obj => [(obj.name || '').toLowerCase()], ['asc'])
  const typeArray = [[], [], [], []]
  for (let i = 0; i < sortedArray.length; i++) {
    if (sortedArray[i].type) {
      if (sortedArray[i].assets) {
        const bucket = IndexMap[InvestmentTypeIdToKey[sortedArray[i].type]]
        for (let j = 0; j < sortedArray[i].assets.length; j++) {
          typeArray[bucket].push(sortedArray[i].assets[j])
        }
      }
    } else {
      if (sortedArray[i].assets) {
        for (let k = 0; k < sortedArray[i].assets.length; k++) {
          typeArray[3].push(sortedArray[i].assets[k])
        }
      }
    }
  }

  return typeArray
}
