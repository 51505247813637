import { css } from '@emotion/react'
import styled from '@emotion/styled'
import posed from 'react-pose'
import logo from '../../../assets/images/GuidedChoice-logo.png'
import { Loading } from '../../../components'

export const Page = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

Page.Container = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
`

Page.Loading = () => (
  <div
    css={css`
      margin: 20px;
    `}>
    <Loading />
  </div>
)

Page.Header = () => (
  <div
    css={css`
      display: flex;
      justify-content: flex-end;
      padding-bottom: 40px;
      max-width: 1200px;
      margin: auto;
      align-self: flex-start;
    `}>
    <div
      css={css`
        max-width: 220px;
        padding: 2px 10px;
      `}>
      <img
        css={css`
          width: 100%;
        `}
        src={logo}
        alt='Logo'
      />
    </div>
  </div>
)

Page.Text = styled.div`
  font-size: 1.125rem;
  color: ${p => p.theme.mediumGray};
  line-height: 1.35;
`

Page.LightText = styled.div`
  font-size: 1.125rem;
  color: ${p => p.theme.lightestGray};
  line-height: 1.35;
`

Page.WelcomeHeading = styled.div`
  text-align: center;
  max-width: 679px;
  margin: auto;
  padding: 20px 0 40px;
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 3.125rem;
  font-weight: bold;
  word-wrap: break-word;
  color: ${p => p.theme.darkestGray};
`

Page.WelcomeParagraph = styled(Page.Text)`
  margin: 20px auto;
  max-width: 679px;
`

Page.TermsSubText = styled(Page.Text)`
  font-size: 1rem;
  max-width: 679px;
  margin: auto;
  padding-left: 64px;
`

Page.AnimateHeading = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -15 },
})

const HeadingStyles = styled.div`
  width: 100%;
  display: flex;
  flex-basis: 100%;
  padding-top: 10px;
  margin-bottom: 30px;
  max-width: 1200px;
  align-self: flex-start;
  @media (min-width: 1200px) {
    margin-bottom: 40px;
  }

  /* Title */
  & > div:first-of-type {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3rem;
    width: 100%;
    color: ${p => p.theme.mediumGray};
    transition: 0.75s;
    font-weight: 300;
    line-height: 1.25;
    word-break: break-word;
    @media (max-width: 800px) {
      font-size: 2rem;
      /* max-width: 400px; */
    }
    @media (min-width: 1200px) {
      font-size: 3.125rem;
      /* max-width: 1200px; */
    }
  }

  /* SubTitle */
  & > div:nth-of-type(2) {
    color: ${p => p.theme.lightestGray};
    font-size: 1rem;
    padding-top: 1rem;
    line-height: 20px;
  }
`
Page.Heading = props => {
  const { title, children, subTitle } = props
  return (
    <HeadingStyles>
      <div>{title || children}</div>
      {subTitle && <div>{subTitle}</div>}
    </HeadingStyles>
  )
}

Page.Link = styled.a`
  cursor: pointer;
  color: rgb(31, 151, 190);
  text-decoration: underline;
`
Page.FeelGood = styled.div`
  font-size: 36px;
  color: ${p => p.theme.primaryColor};
  line-height: 49px;
  margin-bottom: 20px;
`

Page.Form = styled.form`
  margin: 20px 0;
  width: 100%;
`

const FieldStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* Field Label Container */
  & > div:first-of-type {
    flex: 1 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: right;
    margin-right: 40px;
    color: rgb(122, 142, 150);
    font-size: 1.125rem;
    line-height: 1.35;

    /* Field Label */
    & > div:first-of-type {
    }

    /* Field SubLabel (Optional) */
    & > div:nth-of-type(2) {
      font-size: 0.875rem;
    }
  }

  /* Field Input Container */
  & > div:nth-of-type(2) {
    flex: 1 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(74, 96, 106);
    font-size: 1.125rem;
    line-height: 1.35;
    word-break: break-word;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    margin: 20px 0;
    & > div:first-of-type {
      flex: 1 1 100%;
      text-align: left;
      margin: 12px 0;
    }
  }
`
Page.Field = props => {
  const { label, subLabel, children, ...rest } = props
  return (
    <FieldStyles {...rest}>
      <div>
        <div
          css={css`
            line-height: min(54px);
          `}>
          {label}
        </div>
        {subLabel && (
          <div
            css={css`
              line-height: max(10px);
              margin-bottom: 10px;
            `}>
            {subLabel}
          </div>
        )}
      </div>
      <div
        css={css`
          line-height: min(54px);
        `}>
        {children}
      </div>
    </FieldStyles>
  )
}

Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`

Page.AnimateGroup = posed.div({
  enter: { staggerChildren: 50 },
  exit: { staggerChildren: 20, staggerDirection: -1 },
})

Page.AnimateItem = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 20, opacity: 0 },
})

Page.Animate = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 20, opacity: 0 },
})

Page.SeeWhereContainer = styled.div`
  font-family: Aleo, 'Helvetica Neue', sans-serif;
  padding: 10px;
  word-wrap: break-word;
  max-width: 690px;
`
Page.SuccessBox = styled.div`
  max-width: 690px;
  color: rgb(58, 77, 87);
  font-size: 2.25rem;
  line-height: 1.25;
  background-color: ${p => p.theme.congratulationsContainerColor};
  padding: 20px;
  border: ${p => p.theme.congratulationsContainerBorder};
  border-radius: 8px;
`
Page.HeaderAmount = styled.div`
  color: ${p => p.theme.congratulationsContainerTextColor};
  font-family: 'Open Sans';
  font-size: 50px;
  font-weight: 600;
  line-height: 68px;

  &::before {
    content: '$';
    color: #4d9927;
    font-weight: 400;
  }
`
Page.BasedText = styled.div`
  color: ${p => p.theme.basedTextColor};
  font-family: 'Open Sans';
  font-size: 24px;
  line-height: 36px;
`
Page.DollarValue = styled.div`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`

Page.SecondaryText = styled.div`
  font-size: 1.15rem;
  margin-bottom: 10px;
`

Page.SmallText = styled.div`
  font-size: 0.75rem;
  color: #7a8e96;
  font-family: Aleo, 'Helvetica Neue', sans-serif;
  margin: 10px 0;
`
Page.TooltipLabel = styled.div`
  color: ${p => p.theme.tooltipLabelTextColor};
  font-family: 'Open Sans';
  font-size: 32px;
  font-weight: 300;
  line-height: 43px;
  padding: 0 18px;
  cursor: pointer;
  user-select: none;
`
Page.InTheBoxBelowText = styled.span`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  padding: 0 18px;
  cursor: pointer;
  user-select: none;
`
Page.ShowBoxContainer = styled.div`
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  margin-left: 43px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`
Page.Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  position: relative;
`
Page.EditButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: white;
  font-size: 0.75rem;

  text-align: center;
  vertical-align: middle;
  height: 32px;
  width: 83px;
  border-radius: 2px;
  background-color: #4a606a;
`
Page.SubSection = styled.div`
  display: flex;
  align-items: baseline;
`
Page.LabelHeader = styled.div`
  text-align: right;
  flex: 2;
  color: #022a3a;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  user-select: none;
`
Page.Label = styled.div`
  text-align: right;
  flex: 2;
  font-family: 'Open Sans';
  color: #4a606a;
  font-size: 14px;
  line-height: 19px;
`
Page.Status = styled(Page.Label)`
  color: #f48024;
`
Page.Value = styled.div`
  flex: 1.5;
  padding-left: 10px;
  color: #022a3a;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
`
Page.Amount = styled.div`
  flex: 1.5;
  padding-left: 10px;
  font-weight: 600;
  line-height: 27px;
  font-size: 20px;
  color: #022a3a;
  font-family: 'Open Sans';
  &::before {
    content: '$';
    color: #4d9927;
    padding-right: 3px;
  }
`
Page.ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1.75rem 0;
`
Page.SecondaryButton = styled.div`
  width: 240px;
  border-radius: 4px;
  background: #4a606a;
  cursor: pointer;
  padding: 8px;
  margin: 12px;
  &:hover {
    background: #42565e;
  }
  &:active {
    background: #324147;
  }
  & > div {
    color: #ffffff;
    text-align: center;
    font-size: 1.15rem;
    user-select: none;
    padding: 8px 0;
  }
`
Page.ButtonBox = styled.div`
  width: 240px;
  border-radius: 4px;
  background: linear-gradient(180deg, #39a3c6 0%, #1291bb 100%);
  cursor: pointer;
  padding: 8px;
  margin: 12px;
  &:hover {
    background: linear-gradient(to bottom, #038ab7 0%, #02769d 100%);
  }
  &:active {
    background: linear-gradient(to bottom, #02607f, #02607f);
  }
  & > div {
    color: #ffffff;
    text-align: center;
    font-size: 1.15rem;
    user-select: none;
    padding: 8px 0;
  }
`
Page.ButtonContainer = styled.span`
  margin-left: 10px;
  border: 1px solid #7a8e96;
  border-radius: 50px;
  padding: 3px 0px;
`
Page.CustomButton = styled.button`
  padding: 4px 15px;
  border: none;
  background-color: ${({ active }) => (active ? '#85c964bd' : 'transparent')};
  border: ${({ active }) => (active ? '1px solid #7a8e96' : 'none')};
  outline: none;
  border-radius: 50px;
  margin: 0px -1px;
  color: #7a8e96;
  cursor: pointer;
`

Page.SecondaryBox = styled.div`
  border: 1px solid rgb(230, 233, 234);
  background-color: rgb(240, 244, 245);
  box-shadow: rgba(74, 96, 106, 0.6) 0px 2px 2px 0px;
  padding: 1rem 1.5rem;
  font-size: 1.65rem;
  line-height: 1.25;
`
Page.WarningContainer = styled.div`
  border: 1px solid rgb(230, 233, 234);
  background-color: rgb(240, 244, 245);
  box-shadow: rgba(74, 96, 106, 0.6) 0px 2px 2px 0px;
  padding: 1rem 1.5rem;
  font-size: 1.15rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.25;
  display: flex;
  align-items: center;
  color: #f48024;
  max-width: 690px;
`
Page.CongratsText = styled.div`
  color: ${p => p.theme.congratsTextColor};
  font-family: 'Open Sans';
  font-size: 36px;
  line-height: 49px;
`
Page.SolidifyPlanHeading = styled.div`
  color: ${p => p.theme.solidifyPlanHeadingTextColor};
  font-family: 'Open Sans';
  font-size: 50px;
  font-weight: 300;
  line-height: 76px;
`
Page.ConsiderationBody = styled.div`
  margin-top: 25px;
`
Page.ConsiderationWrapper = styled.div`
  margin-left: 10px;
`
Page.ConsiderationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`
Page.ConsiderationLabel = styled.span`
  color: ${p => p.theme.considerationLabelColor};
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
`
Page.ReturnText = styled.div`
  font-size: 0.85rem;
  margin-top: 20px;
  padding-right: 22px;
  color: ${p => p.theme.considerationReturnTextColor};
`
Page.SliderLabel = styled.div`
  flex: 1;
  margin-right: 80px;
  color: ${p => p.theme.sliderLabelColor};
`
Page.SliderValue = styled.div`
  flex: 3;
  height: 40px;
  padding-top: 19px;
`
Page.SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
Page.EmergencyAmountText = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`
Page.BorderedCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${p => p.theme.solidifyPlanContainerBorder};
  border-radius: 8px;
  background-color: ${p => p.theme.solidifyPlanContainerBgColor};
  margin: 10px 20px;
  padding: 1rem;
  margin-bottom: 20px;
  min-height: 200px;
  justify-content: center;
  max-width: 680px;
`
Page.Review = styled.span`
  color: ${p => p.theme.reviewTextColor};
`
Page.CancelButton = styled.a`
  display: inline-block;
  border: ${p => p.theme.offboardingCancelButtonBorder};
  background-color: ${p => p.theme.offboardingCancelButtonBgColor};
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  color: ${p => p.theme.offboardingCancelButtonTextColor};
  font-family: 'Open Sans';
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.offboardingHoverCancelButtonBgColor};
  }

  &:active {
    background-color: ${p => p.theme.offboardingActiveCancelButtonBgColor};
  }

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }

  & > div:nth-of-type(2),
  div:nth-of-type(3) {
    font-size: 12px;
  }
`
Page.PrimaryButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.buttonColor};
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  color: white;
  font-family: 'Open Sans';
  cursor: pointer;
  height: 61px;
  min-width: 240.91px;

  &:hover {
    background: ${p => p.theme.hoverRecalculateButtonColor};
  }

  &:active {
    background: ${p => p.theme.activeRecalculateButtonColor};
  }

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }
`

Page.ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`

Page.ButtonBox = styled.div`
  display: flex;
  justify-content: right;
`
Page.Note = styled.div`
  color: ${p => p.theme.noteTextColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  margin-top: -10px;
  margin-bottom: 10px;
`
Page.CanAffordNeeds = styled.div`
  color: ${p => p.theme.canAffordNeedsColor};
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
`
Page.NotQuiteRight = styled.div`
  color: ${p => p.theme.notQuiteRightTextColor};
`
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ paddingRight }) => paddingRight || '0'};
  color: ${p => p.theme.amountNeededTextColor};
`
Section.SectionTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 300;
  line-height: 41px;
`

Section.SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`
Section.ContributionsWrapper = styled.div`
  display: flex;
`
Section.SvgContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 3px;
`
Section.SubTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 300;
  line-height: 33px;
`
Section.SecondaryText = styled.div`
  color: ${p => p.theme.secondaryTextColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 18px;
`
Section.PrimaryText = styled.div`
  color: ${p => p.theme.primaryColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 18px;
`
Section.SVGContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: grid;
  justify-content: center;
  text-align: center;
`
