import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import {
  Spacer,
  HelpIcon,
  HelpPersonIcon,
  FillGauge,
  FlexRow,
  TooltipText,
} from '../../../components'
import { numberToDollars } from '../../../utils'

const Amount = styled.div`
  color: ${p => p.theme.darkestGray};
  text-align: ${p => (p.alignRight ? 'right' : 'left')};
  font-size: 1.5rem;
  padding-right: 8px;

  &::before {
    content: '$';
    color: ${p => (p.isOnTrack ? '#4d9927' : '#f48024')};
  }
`

const SubAmount = styled.div`
  color: ${p => p.theme.mediumGray};
  margin-right: 2px;
  font-size: 1rem;
  font-weight: ${p => (p.bold ? '600' : null)};
  padding-right: 8px;
  &::before {
    content: '$';
    color: #7a8e96;
  }
`

const SubLabelContainer = styled.div`
  min-width: 200px;
  padding: ${p => (p.padding ? p.padding : null)};
`

const SubLabel = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 0.875rem;
  max-width: 400px;
`

const DescriptionText = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 1rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  max-width: 200px;
  padding-top: 24px;
  line-height: 1.4;
`

const SVGHolder = styled.span`
  position: relative;
  top: 4px;
`

const DescriptionContainer = styled.div`
  max-width: 440px;
`

const ChartLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1.125rem;
  padding-bottom: 16px;
`

const mapEngineTypeToCase = {
  base: 'baseCase',
  recommended: 'recommendedCase',
}

class FillGaugeWithDescription extends Component {
  static defaultProps = {
    displayInflation: false,
    engineType: 'base',
  }

  static propTypes = {
    displayInflation: PropTypes.bool,
    engineType: PropTypes.oneOf(['base', 'recommended']),
  }

  render() {
    const { displayInflation, engineType, id, store, label } = this.props
    const { baseCase, recommendedCase } = store

    // sometimes baseCase can load after recommendedCase causing a null property error in Scenario Planning
    if (!baseCase) {
      return null
    }

    const {
      retirementIncomeGoal,
      projectedMonthlyRetirementInc,
      projectedMonthlyInflationAdjustedRetirementInc,
    } = store[mapEngineTypeToCase[engineType]]

    const { isOnTrack } = baseCase || {}

    return (
      <DescriptionContainer>
        {label && <ChartLabel>{label}</ChartLabel>}
        <FlexRow justifyContent='space-between' alignItems='center' maxWidth='380px'>
          <FillGauge id={id} fill={retirementIncomeGoal} />

          <div>
            <DescriptionText>
              {engineType === 'recommended'
                ? 'After-tax Income strategy replaces after retiring'
                : 'Projected after-tax income your current strategy replaces after retiring'}
            </DescriptionText>
            <SVGHolder>
              <HelpIcon tooltip={TooltipText.replacementIncome()} />
            </SVGHolder>
            <Spacer space='4px' />
            {engineType === 'recommended' && !recommendedCase.isOnTrack && (
              <HelpIcon
                helpLabel='Income goal not met'
                tooltip={TooltipText.incomeGoalNotMet()}
                reverse
              />
            )}
          </div>
        </FlexRow>
        <Spacer space='12px' />
        <FlexRow justifyContent='space-between' alignItems='flex-end' maxWidth='380px'>
          <div>
            <Amount isOnTrack={engineType === 'base' ? isOnTrack : true}>
              {numberToDollars(projectedMonthlyRetirementInc, true)}
            </Amount>
          </div>
          <SubLabelContainer>
            <SubLabel>Today's value</SubLabel>
          </SubLabelContainer>
        </FlexRow>
        <FlexRow justifyContent='space-between' alignItems='flex-start' maxWidth='380px'>
          <FlexRow alignItems='flex-end'>
            <SubAmount>{numberToDollars(projectedMonthlyRetirementInc * 12, true)}</SubAmount>
            <SubLabel>annual</SubLabel>
          </FlexRow>
          <SubLabelContainer>
            {engineType === 'recommended' && (
              <HelpPersonIcon
                size='xLarge'
                helpLabel='View ranges'
                tooltip={TooltipText.ongoingMonthlyContributionsInitialAdvice(displayInflation)}
              />
            )}
          </SubLabelContainer>
        </FlexRow>
        <Spacer space='12px' />
        {displayInflation && (
          <>
            <FlexRow justifyContent='space-between' alignItems='flex-start' maxWidth='380px'>
              <div>
                <Amount isOnTrack={engineType === 'base' ? isOnTrack : true}>
                  {numberToDollars(projectedMonthlyInflationAdjustedRetirementInc, true)}
                </Amount>
              </div>
              <SubLabelContainer padding='2px 0 0 0'>
                <SubLabel>Value at retirement</SubLabel>
              </SubLabelContainer>
            </FlexRow>
            <FlexRow alignItems='flex-end'>
              <SubAmount>
                {numberToDollars(projectedMonthlyInflationAdjustedRetirementInc * 12, true)}
              </SubAmount>
              <SubLabel>annual</SubLabel>
            </FlexRow>
            <Spacer space='12px' />
          </>
        )}
      </DescriptionContainer>
    )
  }
}

export default inject('store')(observer(FillGaugeWithDescription))
