/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

import { Page } from '../elements'
import {
  Button,
  Spacer,
  Loading,
  ErrorPage,
  TextErrorField,
  ButtonInput,
  CurrencyInput,
  Dropdown,
} from '../../../components'

import { schema } from './validation'
import { reduceValidationError } from '../../../utils'

import { SSIPage } from './style'

const calculator = createDecorator(
  {
    field: 'primaryPaymentBeginAge',
    updates: {
      primaryReceivingSsi: (primaryPaymentBeginAge, allValues) =>
        primaryPaymentBeginAge === allValues.initialPrimaryPaymentBeginAge
          ? allValues.primaryReceivingSsi
          : primaryPaymentBeginAge > allValues.primaryCurrentAge
          ? false
          : null,
      primaryUseGcEstimate: (primaryPaymentBeginAge, allValues) =>
        primaryPaymentBeginAge === allValues.initialPrimaryPaymentBeginAge
          ? allValues.primaryUseGcEstimate
          : null,
      primaryMonthlyAmount: (primaryPaymentBeginAge, allValues) =>
        primaryPaymentBeginAge === allValues.initialPrimaryPaymentBeginAge
          ? allValues.primaryMonthlyAmount
          : null,
      primaryMonthlyAmountCustom: (primaryPaymentBeginAge, allValues) =>
        primaryPaymentBeginAge === allValues.initialPrimaryPaymentBeginAge
          ? allValues.primaryMonthlyAmountCustom
          : null,
    },
  },
  {
    field: 'spousePaymentBeginAge',
    updates: {
      spouseReceivingSsi: (spousePaymentBeginAge, allValues) =>
        spousePaymentBeginAge === allValues.initialSpousePaymentBeginAge
          ? allValues.spouseReceivingSsi
          : spousePaymentBeginAge > allValues.primaryCurrentAge
          ? false
          : null,
      spouseUseGcEstimate: (spousePaymentBeginAge, allValues) =>
        spousePaymentBeginAge === allValues.initialSpousePaymentBeginAge
          ? allValues.spouseUseGcEstimate
          : null,
      spouseMonthlyAmount: (spousePaymentBeginAge, allValues) =>
        spousePaymentBeginAge === allValues.initialSpousePaymentBeginAge
          ? allValues.spouseMonthlyAmount
          : null,
      spouseMonthlyAmountCustom: (spousePaymentBeginAge, allValues) =>
        spousePaymentBeginAge === allValues.initialSpousePaymentBeginAge
          ? allValues.spouseMonthlyAmountCustom
          : null,
    },
  }
)

function FirstSSIPage({
  history,
  store: {
    getPerson,
    getSSBenefits,
    getWorkerBenefits,
    saveSSBenefits,
    primarySSIBenefits,
    spouseSSIBenefits,
    workerBenefits,
  },
  onboarding: {
    spouse,
    person,
    person: { includeSpouse },
  },
}) {
  const [status, setStatus] = useState('loading')

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPerson()
        await getSSBenefits()
        await getWorkerBenefits()
        setStatus('success')
      } catch (err) {
        setStatus('error')
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return <ErrorPage />
  }

  let initialValues = {
    primaryIncludeSsi: null,
    primaryReceivingSsi: Boolean(_.get(primarySSIBenefits, 'receivingSsi', null)),
    primaryUseGcEstimate: null,
    primaryMonthlyAmount: _.get(primarySSIBenefits, 'monthlyAmount', null),
    primaryMonthlyAmountCustom: _.get(primarySSIBenefits, 'monthlyAmount', null),
    primaryPaymentBeginAge: _.get(primarySSIBenefits, 'paymentBeginAge', null),
    initialPrimaryPaymentBeginAge: _.get(primarySSIBenefits, 'paymentBeginAge', null),
    primaryCurrentAge: person.age,
    includeSpouse: person.includeSpouse,
  }

  let spouseInitialValues = {}
  if (person.includeSpouse) {
    spouseInitialValues = {
      spouseIncludeSsi: null,
      spouseReceivingSsi: Boolean(_.get(spouseSSIBenefits, 'receivingSsi', null)),
      spouseUseGcEstimate: null,
      spouseMonthlyAmount: _.get(spouseSSIBenefits, 'monthlyAmount', null),
      spouseMonthlyAmountCustom: _.get(spouseSSIBenefits, 'monthlyAmount', null),
      spousePaymentBeginAge: _.get(spouseSSIBenefits, 'paymentBeginAge', null),
      initialSpousePaymentBeginAge: _.get(spouseSSIBenefits, 'paymentBeginAge', null),
      spouseCurrentAge: spouse.age,
    }
    initialValues = {
      ...initialValues,
      ...spouseInitialValues,
    }
  }
  // initialValues.primaryIncludeSsi = null
  // initialValues.primaryReceivingSsi = null
  // initialValues.primaryUseGcEstimate = null
  // initialValues.primaryMonthlyAmountCustom = null
  // initialValues.primaryPaymentBeginAge = null

  const handleSave = async values => {
    let spouseSSIData
    let primarySSIData

    if (values.spouseIncludeSsi) {
      spouseSSIData = {
        ...spouseSSIBenefits,
        includeSsi: values.spouseIncludeSsi,
        receivingSsi: values.spouseReceivingSsi ? 1 : 0,
        useGcEstimate: values.spouseReceivingSsi ? 0 : values.spouseUseGcEstimate ? 1 : 0,
        monthlyAmount: values.spouseUseGcEstimate
          ? values.spouseReceivingSsi
            ? values.spouseMonthlyAmountCustom
            : workerBenefits.spouseSSBenefits[values.spousePaymentBeginAge]
          : values.spouseMonthlyAmountCustom,
        paymentBeginAge: values.spousePaymentBeginAge,
      }
    } else {
      spouseSSIData = {
        ...spouseSSIBenefits,
        includeSsi: values.spouseIncludeSsi,
      }
    }

    if (values.primaryIncludeSsi) {
      primarySSIData = {
        ...primarySSIBenefits,
        includeSsi: values.primaryIncludeSsi,
        receivingSsi: values.primaryReceivingSsi ? 1 : 0,
        useGcEstimate: values.primaryReceivingSsi ? 0 : values.primaryUseGcEstimate ? 1 : 0,
        monthlyAmount: values.primaryUseGcEstimate
          ? values.primaryReceivingSsi
            ? values.primaryMonthlyAmountCustom
            : workerBenefits.primarySSBenefits[values.primaryPaymentBeginAge]
          : values.primaryMonthlyAmountCustom,
        paymentBeginAge: values.primaryPaymentBeginAge,
      }
    } else {
      primarySSIData = {
        ...primarySSIBenefits,
        includeSsi: values.primaryIncludeSsi,
      }
    }

    let ssiData = { primarySSIBenefits: primarySSIData }
    if (includeSpouse) {
      ssiData = { ...ssiData, spouseSSIBenefits: spouseSSIData }
    }

    try {
      await saveSSBenefits(ssiData)
      await getSSBenefits()

      history.push('/welcome/social-security-income/2')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const onSubmit = values => {
    return handleSave(values)
  }

  const primaryBeginOptions = () => {
    const arr = []
    for (let i = 62; i <= 70; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }
  const spouseBeginOptions = () => {
    const arr = []
    for (let i = 62; i <= 70; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }

  const validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  return (
    <Page>
      <Page.Heading title='Social Security income (SSI)' />
      <Page.Container
        css={css`
          align-self: flex-start;
        `}>
        <p
          css={css`
            color: #9eacb0;
            margin-top: -30px;
          `}>
          Please note: Social Security estimates are before-tax
        </p>
        <SSIPage.ChangesInfoText
          css={css`
            max-width: 800px;
          `}>
          You can also visit the Social Security Administration's web site to get your actual
          figures vs. the GuidedChoice estimate:&nbsp;
          <br />
          <SSIPage.ChangesLink
            target='_blank'
            rel='noreferrer noopener'
            href='https://www.ssa.gov/benefits/retirement/estimator.html'>
            https://www.ssa.gov/benefits/retirement/estimator.html
          </SSIPage.ChangesLink>{' '}
        </SSIPage.ChangesInfoText>

        <Spacer space='30px' />
        <Form
          validate={validate}
          onSubmit={onSubmit}
          initialValues={initialValues}
          subscription={{ submitting: true, submitError: true, values: true }}
          decorators={[calculator]}
          render={({ handleSubmit, submitting, submitError, values }) => (
            <>
              <SSIPage>
                <SSIPage.QuestionaryWrapper>
                  <SSIPage.Question />
                  <SSIPage.Answer>{person.displayName}</SSIPage.Answer>
                </SSIPage.QuestionaryWrapper>

                <SSIPage.QuestionaryWrapper>
                  <SSIPage.Question>Social Security income</SSIPage.Question>
                  <SSIPage.Answer>
                    <Field
                      name='primaryIncludeSsi'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <div>
                          <SSIPage.ButtonWrapper>
                            <ButtonInput
                              isActive={input.value === true}
                              name={input.name}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onClick={(name, value) => input.onChange(value)}
                              text='Include'
                              value
                              width='110px'
                            />
                            <ButtonInput
                              isActive={input.value === false}
                              name={input.name}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onClick={(name, value) => input.onChange(value)}
                              text='Exclude'
                              value={false}
                              width='110px'
                            />
                          </SSIPage.ButtonWrapper>
                          <div>
                            <TextErrorField error={meta.error} showError={meta.touched} />
                          </div>
                        </div>
                      )}
                    />
                  </SSIPage.Answer>
                </SSIPage.QuestionaryWrapper>

                {values.primaryIncludeSsi && (
                  <SSIPage.QuestionaryWrapper>
                    <SSIPage.Question>SSI begin age?</SSIPage.Question>
                    <SSIPage.Answer>
                      <Field
                        name='primaryPaymentBeginAge'
                        render={({ input, meta }) => (
                          <Dropdown
                            id='accountTypeDropdown'
                            error={meta.error}
                            name={input.name}
                            onBlur={input.onBlur}
                            onChange={(name, value) => input.onChange(value)}
                            options={primaryBeginOptions()}
                            placeholder='Select'
                            selected={input.value}
                            showError={meta.touched}
                            width='110px'
                          />
                        )}
                      />
                    </SSIPage.Answer>
                  </SSIPage.QuestionaryWrapper>
                )}

                {values.primaryIncludeSsi && values.primaryPaymentBeginAge === person.age && (
                  <SSIPage.QuestionaryWrapper>
                    <SSIPage.Question>Already receiving</SSIPage.Question>
                    <SSIPage.Answer>
                      <Field
                        name='primaryReceivingSsi'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <div>
                            <SSIPage.ButtonWrapper>
                              <ButtonInput
                                isActive={input.value === true}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='Yes'
                                value
                                width='110px'
                              />
                              <ButtonInput
                                isActive={input.value === false}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='No'
                                value={false}
                                width='110px'
                              />
                            </SSIPage.ButtonWrapper>
                            <div>
                              <TextErrorField error={meta.error} showError={meta.touched} />
                            </div>
                          </div>
                        )}
                      />
                    </SSIPage.Answer>
                  </SSIPage.QuestionaryWrapper>
                )}

                {values.primaryIncludeSsi && values.primaryReceivingSsi === false && (
                  <SSIPage.QuestionaryWrapper>
                    <SSIPage.Question>Use GuidedChoice SSI estimate?</SSIPage.Question>
                    <SSIPage.Answer>
                      <Field
                        name='primaryUseGcEstimate'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <div>
                            <SSIPage.ButtonWrapper>
                              <ButtonInput
                                isActive={input.value === true}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='Yes, estimate for me'
                                value
                                width='155px'
                              />
                              <ButtonInput
                                isActive={input.value === false}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text="No, I'll enter my own"
                                value={false}
                                width='155px'
                              />
                            </SSIPage.ButtonWrapper>
                            <div>
                              <TextErrorField error={meta.error} showError={meta.touched} />
                            </div>
                          </div>
                        )}
                      />
                    </SSIPage.Answer>
                  </SSIPage.QuestionaryWrapper>
                )}

                {values.primaryIncludeSsi &&
                  (values.primaryReceivingSsi ||
                    (!values.primaryReceivingSsi && values.primaryUseGcEstimate === false)) && (
                    <SSIPage.QuestionaryWrapper>
                      <SSIPage.Question>Monthly amount</SSIPage.Question>
                      <SSIPage.Answer>
                        <Field
                          name='primaryMonthlyAmountCustom'
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <CurrencyInput
                              error={meta.error}
                              name={input.name}
                              placeholder='0'
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onChange={(name, value) => input.onChange(value)}
                              showError={meta.touched}
                              value={parseInt(input.value)}
                              marginTop='0px'
                            />
                          )}
                        />
                      </SSIPage.Answer>
                    </SSIPage.QuestionaryWrapper>
                  )}
              </SSIPage>

              <Spacer space='30px' />

              {person.maritalStatus && person.includeSpouse && (
                <SSIPage>
                  <SSIPage.QuestionaryWrapper>
                    <SSIPage.Question />
                    <SSIPage.Answer>{spouse.firstName}</SSIPage.Answer>
                  </SSIPage.QuestionaryWrapper>

                  <SSIPage.QuestionaryWrapper>
                    <SSIPage.Question>Social Security income</SSIPage.Question>
                    <SSIPage.Answer>
                      <Field
                        name='spouseIncludeSsi'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <div>
                            <SSIPage.ButtonWrapper>
                              <ButtonInput
                                isActive={input.value === true}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='Include'
                                value
                                width='110px'
                              />
                              <ButtonInput
                                isActive={input.value === false}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='Exclude'
                                value={false}
                                width='110px'
                              />
                            </SSIPage.ButtonWrapper>
                            <div>
                              <TextErrorField error={meta.error} showError={meta.touched} />
                            </div>
                          </div>
                        )}
                      />
                    </SSIPage.Answer>
                  </SSIPage.QuestionaryWrapper>

                  {values.spouseIncludeSsi && (
                    <SSIPage.QuestionaryWrapper>
                      <SSIPage.Question>SSI begin age?</SSIPage.Question>
                      <SSIPage.Answer>
                        <Field
                          name='spousePaymentBeginAge'
                          render={({ input, meta }) => (
                            <Dropdown
                              id='accountTypeDropdown'
                              error={meta.error}
                              name={input.name}
                              onBlur={input.onBlur}
                              onChange={(name, value) => input.onChange(value)}
                              options={spouseBeginOptions()}
                              placeholder='Select'
                              selected={input.value}
                              showError={meta.touched}
                              width='110px'
                            />
                          )}
                        />
                      </SSIPage.Answer>
                    </SSIPage.QuestionaryWrapper>
                  )}

                  {values.spouseIncludeSsi && values.spousePaymentBeginAge === spouse.age && (
                    <SSIPage.QuestionaryWrapper>
                      <SSIPage.Question>Already receiving</SSIPage.Question>
                      <SSIPage.Answer>
                        <Field
                          name='spouseReceivingSsi'
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <div>
                              <SSIPage.ButtonWrapper>
                                <ButtonInput
                                  isActive={input.value === true}
                                  name={input.name}
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                  onClick={(name, value) => input.onChange(value)}
                                  text='Yes'
                                  value
                                  width='110px'
                                />
                                <ButtonInput
                                  isActive={input.value === false}
                                  name={input.name}
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                  onClick={(name, value) => input.onChange(value)}
                                  text='No'
                                  value={false}
                                  width='110px'
                                />
                              </SSIPage.ButtonWrapper>
                              <div>
                                <TextErrorField error={meta.error} showError={meta.touched} />
                              </div>
                            </div>
                          )}
                        />
                      </SSIPage.Answer>
                    </SSIPage.QuestionaryWrapper>
                  )}

                  {values.spouseIncludeSsi && values.spouseReceivingSsi === false && (
                    <SSIPage.QuestionaryWrapper>
                      <SSIPage.Question>Use GuidedChoice SSI estimate?</SSIPage.Question>
                      <SSIPage.Answer>
                        <Field
                          name='spouseUseGcEstimate'
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <div>
                              <SSIPage.ButtonWrapper>
                                <ButtonInput
                                  isActive={input.value === true}
                                  name={input.name}
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                  onClick={(name, value) => input.onChange(value)}
                                  text='Yes, estimate for me'
                                  value
                                  width='155px'
                                />
                                <ButtonInput
                                  isActive={input.value === false}
                                  name={input.name}
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                  onClick={(name, value) => input.onChange(value)}
                                  text="No, I'll enter my own"
                                  value={false}
                                  width='155px'
                                />
                              </SSIPage.ButtonWrapper>
                              <div>
                                <TextErrorField error={meta.error} showError={meta.touched} />
                              </div>
                            </div>
                          )}
                        />
                      </SSIPage.Answer>
                    </SSIPage.QuestionaryWrapper>
                  )}

                  {values.spouseIncludeSsi &&
                    (values.spouseReceivingSsi ||
                      (!values.spouseReceivingSsi && values.spouseUseGcEstimate === false)) && (
                      <SSIPage.QuestionaryWrapper>
                        <SSIPage.Question>Monthly amount</SSIPage.Question>
                        <SSIPage.Answer>
                          <Field
                            name='spouseMonthlyAmountCustom'
                            subscription={{ value: true, touched: true, error: true }}
                            render={({ input, meta }) => (
                              <CurrencyInput
                                error={meta.error}
                                name={input.name}
                                placeholder='0'
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onChange={(name, value) => input.onChange(value)}
                                showError={meta.touched}
                                value={parseInt(input.value)}
                                marginTop='0px'
                              />
                            )}
                          />
                        </SSIPage.Answer>
                      </SSIPage.QuestionaryWrapper>
                    )}
                </SSIPage>
              )}

              <Page.ErrorMessage>{!submitting && submitError}</Page.ErrorMessage>

              <Spacer space='15px;' />

              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}>
                <Button
                  id='spouse-info-back-button'
                  type='button'
                  onClick={history.goBack}
                  secondary
                  label='Back'
                  disabled={submitting}
                  width='140px'
                />
                <Button
                  id='spouse-info-submit-button'
                  type='button'
                  onClick={handleSubmit}
                  primary
                  label='Next'
                  disabled={submitting}
                  width='140px'
                />
              </div>
            </>
          )}
        />
      </Page.Container>
    </Page>
  )
}

export default inject('onboarding', 'store')(observer(FirstSSIPage))
