import { Component } from 'react'
import styled from '@emotion/styled'
import InlineSVG from 'svg-inline-react'
import PropTypes from 'prop-types'

const AddIconSVGWrapper = styled.div`
  svg {
    fill: ${p => p.theme.addIconColor};
  }
`
const AddIconInline = `
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>add-normal-c</title><path id="icn_add-normal" data-name="icn/add-normal" d="M21.6,12A9.6,9.6,0,0,0,2.4,12M0,12a12,12,0,0,1,24,0M2.4,12a9.6,9.6,0,0,0,19.2,0M24,12A12,12,0,0,1,0,12m11-1H6V13h5v5H13V13h5V11H13V6H11v5Z" /></svg>
`
const ButtonContainer = styled.div`
  cursor: pointer;
  position: relative;
  height: 49px;
  width: ${p => (p.width ? p.width : '300px')};
  background-color: ${p => p.theme.buttonContainerColor};
  border: ${p => p.theme.buttonContainerBorder};
  border-radius: 3px;
  padding-left: 30px;
  white-space: nowrap;

  @media (max-width: 800px) {
    height: 35px;
    min-width: 200px;
    max-width: 400px;
    width: 100%;
  }
`

const ButtonIcon = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(0%, -50%);
  margin: 0 10px;
  & div {
    width: 100%;
    height: 100%;
  }
`

const ButtonText = styled.div`
  color: ${p => p.theme.buttonTextColor};
  text-decoration: ${p => (p.underline ? 'underline' : null)};
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  padding: 8px 0;

  @media (max-width: 800px) {
    padding: 2px 0;
  }
`

class AddButton extends Component {
  static propTypes = {
    width: PropTypes.string,
    label: PropTypes.string.isRequired,
    link: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    underline: PropTypes.bool,
  }

  render() {
    const { width, label, link, underline, onClick, className, id } = this.props
    return (
      <ButtonContainer id={id} className={className} width={width} onClick={onClick}>
        <ButtonIcon>
          <AddIconSVGWrapper>
            <InlineSVG src={AddIconInline} element='span' />
          </AddIconSVGWrapper>
        </ButtonIcon>
        <ButtonText link={link} underline={underline}>
          {label}
        </ButtonText>
      </ButtonContainer>
    )
  }
}

export default AddButton
