const smileyFace = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
  <style type="text/css">
  .st0{fill:#FFFFFF;stroke:#022A3A;stroke-width:0.3945;}
  .st1{fill:none;stroke:#022A3A;stroke-width:0.3945;}
  .st2{fill:#022A3A;}
  </style>
  <g id="Layer_1_1_">
  <title>Group</title>
  </g>
  <g id="Layer_2_1_">
  <ellipse class="st0" cx="10" cy="10" rx="9.8" ry="9.8"/>
  <path class="st1" d="M4.5,10.3c0,3,2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5"/>
  <path class="st2" d="M7.2,8.1c0.5,0,1,0.2,1.4,0.4c0-0.1,0-0.2,0-0.4C8.6,7,8,6.2,7.2,6.2s-1.4,0.9-1.4,2c0,0.1,0,0.2,0,0.4
    C6.2,8.3,6.7,8.1,7.2,8.1z"/>
  <path class="st2" d="M12.6,8.1c0.5,0,1,0.2,1.4,0.4c0-0.1,0-0.2,0-0.4c0-1.1-0.6-1.9-1.4-1.9s-1.4,0.9-1.4,1.9c0,0.1,0,0.2,0,0.4
    C11.7,8.3,12.1,8.1,12.6,8.1z"/>
  </g>
  </svg>
`
export default smileyFace
