const BulletCalculator = `
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"/>
            <path d="M10 10h30v30H10z"/>
            <path d="M31.669 13H18.33C17.046 13 16 14.036 16 15.309V34.69C16 35.964 17.046 37 18.331 37H31.67C32.954 37 34 35.964 34 34.691V15.31C34 14.036 32.954 13 31.669 13zm-12.474 3.989c0-.388.318-.703.71-.703h10.19c.392 0 .71.315.71.703v1.388a.707.707 0 0 1-.71.703h-10.19a.707.707 0 0 1-.71-.703V16.99zm2.39 15.788a.707.707 0 0 1-.71.703h-.97a.707.707 0 0 1-.71-.703v-.666c0-.388.318-.703.71-.703h.97c.392 0 .71.315.71.703v.666zm0-4.16a.707.707 0 0 1-.71.702h-.97a.707.707 0 0 1-.71-.703v-.666c0-.388.318-.703.71-.703h.97c.392 0 .71.315.71.703v.666zm4.668 4.16a.707.707 0 0 1-.71.703h-.97a.707.707 0 0 1-.71-.703v-.666c0-.388.318-.703.71-.703h.97c.392 0 .71.315.71.703v.666zm0-4.16a.707.707 0 0 1-.71.702h-.97a.707.707 0 0 1-.71-.703v-.666c0-.388.318-.703.71-.703h.97c.392 0 .71.315.71.703v.666zm0-4.162a.707.707 0 0 1-.71.703h-5.638a.707.707 0 0 1-.71-.703v-.666c0-.388.318-.703.71-.703h5.638c.392 0 .71.315.71.703v.666zm4.668 8.322a.707.707 0 0 1-.71.703h-.97a.707.707 0 0 1-.71-.703v-.666c0-.388.318-.703.71-.703h.97c.392 0 .71.315.71.703v.666zm0-4.16a.707.707 0 0 1-.71.702h-.97a.707.707 0 0 1-.71-.703v-.666c0-.388.318-.703.71-.703h.97c.392 0 .71.315.71.703v.666zm0-4.162a.707.707 0 0 1-.71.703h-.97a.707.707 0 0 1-.71-.703v-.666c0-.388.318-.703.71-.703h.97c.392 0 .71.315.71.703v.666z" fill="#02769D" fill-rule="nonzero"/>
        </g>
    </svg>
`

export default BulletCalculator
