import styled from '@emotion/styled'

const Layout = styled.div`
  font-family: 'Open Sans';
  font-weight: 500;
  color: #4a606a;
`

Layout.Container = styled(Layout)`
  max-width: 960px;
  margin: auto;
`

Layout.DisplayXLarge = styled(Layout)`
  font-size: 50px;
  line-height: 44px;

  @media only screen and (max-width: 775px) {
    line-height: 50px;
  }
`
Layout.DisplayLarge = styled(Layout)`
  font-size: 36px;
  line-height: 32px;
`
Layout.DisplayMedium = styled(Layout)`
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
`
Layout.DisplaySmall = styled(Layout)`
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
`
Layout.Header = styled(Layout)`
  font-size: 18px;
  line-height: 24px;
`
Layout.Subheader = styled(Layout)`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`
Layout.Body = styled(Layout)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`
Layout.Caption = styled(Layout)`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`

Layout.BrandText = styled(Layout)`
  color: #038ab7;
  font-size: 20px;
`

Layout.SelectionBox = styled(Layout)`
  padding: 16px 20px;
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
`

Layout.SelectionTitle = styled(Layout)`
  color: #022a3a;
  font-size: 24px;
`

Layout.SelectionField = styled(Layout)`
  margin: 16px 0;
`

Layout.SelectionText = styled.span`
  font-size: 20px;
`

Layout.ButtonGroup = styled(Layout)`
  display: flex;
  justify-content: space-between;
`

Layout.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`

export default Layout
