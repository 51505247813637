import { useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import { API } from '../api/instances.js'
import { types } from 'mobx-state-tree'
import logo from '../assets/images/GuidedChoice-logo.png'
const { model, maybeNull, string } = types

const ThemeWrapper = props => {
  const [themes, setThemes] = useState({})
  const [state, setState] = useState('loading')
  const isAuth = props.isAuth ?? true

  useEffect(() => {
    if (!isAuth) {
      try {
        getDefaultTheme()
      } catch (err) {
        console.error('err', err)
        setState('error')
      }
      return
    }

    try {
      getTheme()
    } catch (err) {
      console.error('err', err)
      setState('error')
    } // eslint-disable-next-line
  }, [isAuth])

  async function getDefaultTheme() {
    try {
      const themeObj = await API.get('theme/default')
      setThemes({ ...themeObj.data, logo: logo })
    } catch (err) {
      console.error(err)
    }
    setState('done')
  }

  async function getTheme() {
    try {
      const themeObj = await API.get('theme')
      setThemes(themeObj.data)
    } catch (err) {
      getDefaultTheme()
    }
    setState('done')
  }

  if (state === 'loading') {
    return null
  }
  if (state === 'done') {
    return <ThemeProvider theme={themes}>{props.children}</ThemeProvider>
  }
}

export const themeModel = model({
  darkestGray: maybeNull(string),
  mediumGray: maybeNull(string),
  lightestGray: maybeNull(string),
  primaryColor: maybeNull(string),
  secondaryColor: maybeNull(string),
  ternaryColor: maybeNull(string),
  homeBgColor: maybeNull(string),
  topNavColor: maybeNull(string),
  topNavTextColor: maybeNull(string),
  topNavBorder: maybeNull(string),
  logoutButtonColor: maybeNull(string),
  logoutButtonBorder: maybeNull(string),
  sidebarColor: maybeNull(string),
  sideBarBorder: maybeNull(string),
  sideBarBgColor: maybeNull(string),
  sideBarDrawerBorder: maybeNull(string),
  sideBarLabelColor: maybeNull(string),
  subLabelColor: maybeNull(string),
  svgSideBarDrawerFill: maybeNull(string),
  addButtonBgColor: maybeNull(string),
  addButtonTextColor: maybeNull(string),
  addButtonBorder: maybeNull(string),
  addButtonDisabledColor: maybeNull(string),
  addButtonDisabledTextColor: maybeNull(string),
  addButtonDisabledBorder: maybeNull(string),
  addButtonActiveColor: maybeNull(string),
  addButtonActiveTextColor: maybeNull(string),
  addButtonActiveBorder: maybeNull(string),
  addButtonHoverColor: maybeNull(string),
  addButtonHoverTextColor: maybeNull(string),
  addButtonHoverBorder: maybeNull(string),
  introTextColor: maybeNull(string),
  investmentAccountsBalanceTextColor: maybeNull(string),
  encouragementTextColor: maybeNull(string),
  actionItemsBoxColor: maybeNull(string),
  actionItemsBoxBorder: maybeNull(string),
  actionItemsTitleColor: maybeNull(string),
  actionItemsMessageTextColor: maybeNull(string),
  onTrackRowBgColor: maybeNull(string),
  notOnTrackRowBgColor: maybeNull(string),
  onTrackTextColor: maybeNull(string),
  amountNeededTextColor: maybeNull(string),
  secondaryTextColor: maybeNull(string),
  homeActionButtonBgColor: maybeNull(string),
  homeActionButtonBorder: maybeNull(string),
  homeActionButtonTextColor: maybeNull(string),
  homeActionButtonHoverColor: maybeNull(string),
  canAffordNeedsColor: maybeNull(string),
  congratulationsContainerColor: maybeNull(string),
  congratulationsContainerBorder: maybeNull(string),
  congratulationsContainerTextColor: maybeNull(string),
  congratsTextColor: maybeNull(string),
  headerAmountTextColor: maybeNull(string),
  basedTextColor: maybeNull(string),
  dollarValueTextColor: maybeNull(string),
  tooltipLabelTextColor: maybeNull(string),
  needsWantsHeadingColor: maybeNull(string),
  needsWantsSubHeadingColor: maybeNull(string),
  needsWantsContainerBgColor: maybeNull(string),
  needsWantsContainerBorder: maybeNull(string),
  needsWantsContainerTextColor: maybeNull(string),
  needsWantsContainerLabelTextColor: maybeNull(string),
  needsWantsContainerInputTextColor: maybeNull(string),
  needsWantsContainerTitleTextColor: maybeNull(string),
  backButtonTextColor: maybeNull(string),
  backButtonBorder: maybeNull(string),
  considerHeadingTextColor: maybeNull(string),
  considerMainTextColor: maybeNull(string),
  considerSectionTitleTextColor: maybeNull(string),
  considerationsMainWrapperTextColor: maybeNull(string),
  considerationsIncomeValueTextColor: maybeNull(string),
  considerContainerBgColor: maybeNull(string),
  considerContainerBorder: maybeNull(string),
  considerContainerCardLabelColor: maybeNull(string),
  considerContainerNoteColor: maybeNull(string),
  solidifyPlanHeadingTextColor: maybeNull(string),
  solidifyPlanContainerBgColor: maybeNull(string),
  solidifyPlanContainerBorder: maybeNull(string),
  reviewTextColor: maybeNull(string),
  circleIconColor: maybeNull(string),
  considerationLabelColor: maybeNull(string),
  considerationReturnTextColor: maybeNull(string),
  noteTextColor: maybeNull(string),
  sliderLabelColor: maybeNull(string),
  sliderValueUnderneathColor: maybeNull(string),
  reminderTextColor: maybeNull(string),
  dropdownTextColor: maybeNull(string),
  notQuiteRightTextColor: maybeNull(string),
  boxBelowTextColor: maybeNull(string),
  boxBelowActiveButtonColor: maybeNull(string),
  boxBelowButtonTextColor: maybeNull(string),
  boxBelowButtonBorder: maybeNull(string),
  seeWhereBoxBorder: maybeNull(string),
  labelTextColor: maybeNull(string),
  labelHeaderTextColor: maybeNull(string),
  valueTextColor: maybeNull(string),
  amountTextColor: maybeNull(string),
  whatIsAGoalColor: maybeNull(string),
  whatIsAGoalHoverColor: maybeNull(string),
  whatIsAGoalActiveColor: maybeNull(string),
  buttonContainerColor: maybeNull(string),
  buttonContainerBorder: maybeNull(string),
  buttonTextColor: maybeNull(string),
  addIconColor: maybeNull(string),
  cardViewLabelColor: maybeNull(string),
  cardViewValueColor: maybeNull(string),
  cardBgColor: maybeNull(string),
  cardHoverBgColor: maybeNull(string),
  cardBorder: maybeNull(string),
  cardTextColor: maybeNull(string),
  cardLabelTextColor: maybeNull(string),
  cardNameTextColor: maybeNull(string),
  cardAmountTextColor: maybeNull(string),
  cardEditButtonColor: maybeNull(string),
  cardFieldLabelColor: maybeNull(string),
  cardFieldValueColor: maybeNull(string),
  cardFieldValueOtherColor: maybeNull(string),
  editDrawerBgColor: maybeNull(string),
  editDrawerTextColor: maybeNull(string),
  totalBalanceTextColor: maybeNull(string),
  helpTextColor: maybeNull(string),
  viewInvestmentsTextColor: maybeNull(string),
  greyTextColor: maybeNull(string),
  balanceTextColor: maybeNull(string),
  investmentListItemBgColor: maybeNull(string),
  investmentListItemBorder: maybeNull(string),
  ssiHeadingTextColor: maybeNull(string),
  ssiPleaseNoteColor: maybeNull(string),
  ssiYourChoiceColor: maybeNull(string),
  ssiLabelColor: maybeNull(string),
  ssiValueColor: maybeNull(string),
  offboardingHeadingColor: maybeNull(string),
  offboardingPageContainerTextColor: maybeNull(string),
  offboardingSubHeaderColor: maybeNull(string),
  offboardingCancelButtonBgColor: maybeNull(string),
  offboardingHoverCancelButtonBgColor: maybeNull(string),
  offboardingActiveCancelButtonBgColor: maybeNull(string),
  offboardingCancelButtonBorder: maybeNull(string),
  offboardingCancelButtonTextColor: maybeNull(string),
  stepperActiveColor: maybeNull(string),
  stepperDefaultColor: maybeNull(string),
  stepperTriangleBorder: maybeNull(string),
  personalizedStratIconFirstColor: maybeNull(string),
  personalizedStratIconSecondColor: maybeNull(string),
  personalizedStratIconThirdColor: maybeNull(string),
  personalizedStratIconFourthColor: maybeNull(string),
  personalizedStratIconFifthColor: maybeNull(string),
  containerColor: maybeNull(string),
  buttonBorderColor: maybeNull(string),
  buttonColor: maybeNull(string),
  hoverButtonColor: maybeNull(string),
  activeButtonColor: maybeNull(string),
  nextButtonColor: maybeNull(string),
  disabledButtonColor: maybeNull(string),
  recalculateButtonColor: maybeNull(string),
  disabledRecalculateButtonColor: maybeNull(string),
  activeRecalculateButtonColor: maybeNull(string),
  hoverRecalculateButtonColor: maybeNull(string),
  sliderColor: maybeNull(string),
  activeSliderColor: maybeNull(string),
  hoverSliderColor: maybeNull(string),
  frozenSliderColor: maybeNull(string),
  sliderRiskBar: maybeNull(string),
  chartBgColor: maybeNull(string),
  assetChartHeader: maybeNull(string),
  canYouCoverCardColor: maybeNull(string),
  canYouCoverTitleColor: maybeNull(string),
  canYouCoverHeaderColor: maybeNull(string),
  editColor: maybeNull(string),
  bgColor: maybeNull(string),
  sectionTitleColor: maybeNull(string),
  customLinkColor: maybeNull(string),
  spendingPiggyColor: maybeNull(string),
  spendingHoverPiggyColor: maybeNull(string),
  piggyBankColor: maybeNull(string),
  piggyBankHoverColor: maybeNull(string),
  emailContainerBorder: maybeNull(string),
  mobileMenuIconFill: maybeNull(string),
  fillGaugeBg: maybeNull(string),
  logo: maybeNull(string),
  logoAlt: maybeNull(string),
  logoPath: maybeNull(string),
  backgroundPath: maybeNull(string),
  bgLeft: maybeNull(string),
  bgRight: maybeNull(string),
})

export default ThemeWrapper
