import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Spacer, HelpIcon, TooltipText, FlexRow } from '../../../components'
import { StackedChart, EmptyStackedChart } from './'
import { numberToDollars } from '../../../utils'

const Description = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1rem;
  max-width: 240px;
  line-height: 1.4;
`

const SubLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  text-align: ${p => (p.alignRight ? 'right' : 'left')};
  font-size: ${p => (p.large ? '1rem' : '.875rem')};
  line-height: 1.4;
`

const Amount = styled.div`
  color: ${p => p.theme.darkestGray};
  text-align: ${p => (p.alignRight ? 'right' : 'left')};
  font-size: 1.5rem;

  &::before {
    content: '$';
    color: ${p => (p.gray ? '#7a8e96' : '#4d9927')};
  }
`

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 240px;
  max-width: 360px;
  width: 100%;
  height: ${p => (p.empty ? '100px' : 'auto')};
  margin: 0 20px 0 0;
`

const ChartContainer = styled.div``

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 200px;
`

const Space = styled.div`
  display: flex;
  flex-grow: ${p => (p.space ? p.space : 1)};
`

const ChartLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1.125rem;
  padding-bottom: 16px;
`

const SubLabelContainer = styled.div`
  min-width: 200px;
  padding: ${p => (p.padding ? p.padding : null)};
`

const mapEngineTypeToCase = {
  base: 'baseCase',
  recommended: 'recommendedCase',
}

class StackedChartWithDescription extends Component {
  static defaultProps = {
    projectedValue: 0,
    projectedValueAdjusted: 0,
    currentValue: 0,
    displayInflation: false,
    label: '',
  }

  static propTypes = {
    label: PropTypes.string,
    displayInflation: PropTypes.bool,
  }

  render() {
    const { displayInflation, label, engineType } = this.props

    const {
      estimatedFutureValue: engineEstimatedFutureValue,
      estimatedInflationAdjustedValue: engineEstimatedInflationAdjustedValue,
    } = this.props.store[mapEngineTypeToCase[engineType]]

    let { currentTotalBalance } = this.props.store[mapEngineTypeToCase[engineType]]

    currentTotalBalance = Math.round(currentTotalBalance)

    const estimatedFutureValue = Math.max(Math.round(engineEstimatedFutureValue), 0)
    const estimatedInflationAdjustedValue = Math.max(
      Math.round(engineEstimatedInflationAdjustedValue),
      0
    )

    const hideCurrentBalance = estimatedFutureValue < currentTotalBalance
    const equalBalances = estimatedFutureValue === currentTotalBalance

    return (
      <div>
        {label && <ChartLabel>{label}</ChartLabel>}
        <FlexRow justifyContent='flex-start' alignItems='center'>
          {engineType === 'recommended' ? (
            <>
              <Description>What you can expect</Description>
              {'\u00A0\u00A0'}
              <HelpIcon tooltip={TooltipText.whatYouCanExpect()} />
            </>
          ) : (
            <Description>Future value of your investment accounts</Description>
          )}
        </FlexRow>
        <div>
          {equalBalances && (
            <>
              <Spacer space='4px' />
              <HelpIcon
                helpLabel='About your results'
                tooltip={TooltipText.aboutYourResults(currentTotalBalance)}
              />
              <Spacer space='4px' />
            </>
          )}
          <Spacer space='6px' />
          {engineType === 'recommended' ? (
            <FlexRow justifyContent='space-between' alignItems='flex-end' maxWidth='380px'>
              <Amount>{numberToDollars(estimatedFutureValue, true)}</Amount>
              <SubLabelContainer padding='0 0 2px 0'>
                <SubLabel>&nbsp;&nbsp;&nbsp;Today's value</SubLabel>
              </SubLabelContainer>
            </FlexRow>
          ) : (
            <>
              <FlexRow>
                <Amount>{numberToDollars(estimatedFutureValue, true)}</Amount>
              </FlexRow>
              <Spacer space='2px' />
              <FlexRow>
                <SubLabel>Today's value</SubLabel>
              </FlexRow>
            </>
          )}
          {displayInflation && (
            <div>
              <Spacer space='12px' />
              {engineType === 'recommended' ? (
                <FlexRow justifyContent='space-between' alignItems='flex-end' maxWidth='380px'>
                  <Amount>{numberToDollars(estimatedInflationAdjustedValue, true)}</Amount>
                  <SubLabelContainer padding='0 0 2px 0'>
                    <SubLabel>&nbsp;&nbsp;&nbsp;Value at retirement</SubLabel>
                  </SubLabelContainer>
                </FlexRow>
              ) : (
                <>
                  <FlexRow>
                    <Amount>{numberToDollars(estimatedInflationAdjustedValue, true)}</Amount>
                  </FlexRow>
                  <Spacer space='2px' />
                  <SubLabelContainer>
                    <SubLabel>Value at retirement</SubLabel>
                  </SubLabelContainer>
                </>
              )}
            </div>
          )}
          <Spacer space='12px' />
        </div>
        <ModuleContainer empty={!estimatedFutureValue}>
          <ChartContainer>
            <Spacer space='2px' />
            {!estimatedFutureValue ? (
              <EmptyStackedChart />
            ) : (
              <StackedChart
                currentPercentage={
                  hideCurrentBalance ? 0 : currentTotalBalance / estimatedFutureValue
                }
                barHeightPercentage={estimatedFutureValue / estimatedFutureValue}
              />
            )}
          </ChartContainer>
          <TextContainer>
            {!!estimatedFutureValue &&
              (hideCurrentBalance ? (
                <Space space={0.95} />
              ) : (
                <Space
                  space={0.95 - Math.min(currentTotalBalance / estimatedFutureValue || 0, 0.94)}
                />
              ))}
            {engineType === 'recommended' ? (
              <FlexRow justifyContent='flex-end' alignItems='flex-start'>
                {hideCurrentBalance ? (
                  <div>
                    <SubLabel large alignRight>
                      Current balance&nbsp;
                    </SubLabel>
                    <Spacer space='4px' />
                    <HelpIcon tooltip={TooltipText.balanceHidden()} helpLabel='Balance hidden' />
                  </div>
                ) : (
                  <>
                    <div>
                      <SubLabel large alignRight>
                        What you've saved
                      </SubLabel>
                      <Amount gray alignRight>
                        {numberToDollars(currentTotalBalance, true)}
                      </Amount>
                    </div>
                    <Spacer space='4px' />
                    <HelpIcon tooltip={TooltipText.whatYouSaved()} />
                  </>
                )}
              </FlexRow>
            ) : (
              <div>
                <SubLabel large>
                  Current balance&nbsp;
                  <span>
                    {!hideCurrentBalance && <HelpIcon tooltip={TooltipText.currentBalance()} />}
                  </span>
                </SubLabel>

                <Spacer space='4px' />
                {hideCurrentBalance ? (
                  <HelpIcon tooltip={TooltipText.balanceHidden()} helpLabel='Balance hidden' />
                ) : (
                  <Amount gray alignRight>
                    {numberToDollars(currentTotalBalance, true)}
                  </Amount>
                )}
              </div>
            )}
          </TextContainer>
        </ModuleContainer>
      </div>
    )
  }
}

export default inject('store')(observer(StackedChartWithDescription))
