import { useEffect } from 'react'
import { observer, inject } from 'mobx-react'

import ViewPension from './ViewPension'
import EditPensionMarried from './EditPensionMarried'
import EditPensionSingle from './EditPensionSingle'

function PensionDashboard(props) {
  const {
    history,
    handleCancel,
    store: {
      getSurvivorPensionOptions,
      pensionOptions,
      person: { includeSpouse },
    },
  } = props

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getSurvivorPensionOptions()
      } catch (err) {
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  if (Object.keys(pensionOptions).length < 2) {
    return <ViewPension handleCancel={handleCancel} history={history} />
  }

  if (!includeSpouse) {
    return <EditPensionSingle handleCancel={handleCancel} history={history} />
  }

  return <EditPensionMarried handleCancel={handleCancel} history={history} />
}

export default inject('store')(observer(PensionDashboard))
