import styled from '@emotion/styled'

const Page = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

Page.Header = styled.div`
  font-size: 1.25rem;
  color: ${p => p.theme.lightestGray};
  line-height: 1.35;
  margin: 15pt 0pt;
`

export default Page
