import { types } from 'mobx-state-tree'

const { model, boolean, number, string, array } = types

export const CompanyStock = model({
  amountToSell: number,
  percentToSell: number,
  positionId: number,
  restricted: boolean,
  securityId: number,
  securityName: string,
  totalValue: number,
  aggregatedPositionIds: array(number),
})
