/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { Component } from 'react'
import { Form, Field } from 'react-final-form'
import styled from '@emotion/styled'
import * as yup from 'yup'

import { Dropdown, WizardStep, Container } from '../../components'
import { BackButton, NextButton, Spacer } from '../../components/styled'
import { reduceValidationError } from '../../utils'
import { accountTypeToType } from './utils'

const Label = styled.div`
  color: #9eacb0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: '';
`
const ButtonsBox = styled.div`
  display: flex;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'space-between')};
  margin: 60px 0 20px 0;
  max-width: 1000px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 40px auto;
  }
`

const options = [
  { label: '401(k)', value: '401k' },
  { label: '403(b)', value: '403b' },
  { label: 'Pension', value: 'pension' },
  { label: '401(a)', value: '401a' },
  { label: '457', value: '457' },
  // { label: "SEP IRA", value: "sep-ira" },
  // { label: "SIMPLE IRA", value: "simple-ira" },
  // {label: "Payroll Deduct IRA", value: "payroll-deduct-ira"},
  { label: 'Keogh', value: 'keogh' },
  { label: 'IRA | Rollover', value: 'ira-rollover' },
  { label: 'IRA | Roth', value: 'ira-roth' },
  { label: 'IRA | Deductible', value: 'ira-deductible' },
  { label: 'IRA | Non-Deductible', value: 'ira-non-deductible' },
  { label: 'Brokerage Account', value: 'brokerage-account' },
  { label: 'Annuity | Fixed', value: 'annuity-fixed' },
  { label: 'Other Asset', value: 'other-asset' },
]

const schema = yup.object().shape({
  accountType: yup
    .string()
    .oneOf(
      [
        '401k',
        '403b',
        'pension',
        '401a',
        '457',
        'keogh',
        'ira-rollover',
        'ira-roth',
        'ira-deductible',
        'ira-non-deductible',
        'brokerage-account',
        'other-asset',
        'annuity-fixed',
      ],
      'Please tell us which type of account to add.'
    ),
})

class AddAccount extends Component {
  handleCancel = () => {
    this.props.history.goBack()
  }

  onSubmit = ({ accountType }) => {
    const type = accountTypeToType[accountType]
    this.props.history.push(`/accounts/add/${type}/${accountType}/intro`)
  }

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  render() {
    return (
      <WizardStep title="Let's add a new account">
        <Form
          onSubmit={this.onSubmit}
          validate={this.validate}
          initialValues={{ accountType: '' }}
          subscription={{}}
          render={({ handleSubmit }) => (
            <Container>
              <Label>What type of account do you want to add?</Label>

              <Spacer space='12px' />

              <Field
                name='accountType'
                render={({ input, meta }) => (
                  <Dropdown
                    id='accountTypeDropdown'
                    error={meta.error}
                    name={input.name}
                    onBlur={input.onBlur}
                    onChange={(name, value) => input.onChange(value)}
                    options={options}
                    placeholder='Select'
                    selected={input.value}
                    showError={meta.touched}
                    width='250px'
                  />
                )}
              />

              <ButtonsBox>
                <BackButton onClick={this.handleCancel}>Cancel</BackButton>
                <NextButton onClick={handleSubmit}>Next</NextButton>
              </ButtonsBox>
            </Container>
          )}
        />
      </WizardStep>
    )
  }
}

export default AddAccount
