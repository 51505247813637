import { Fragment, Component } from 'react'
import styled from '@emotion/styled'

const StepperContainer = styled.div`
  max-width: ${p => (p.maxWidth ? p.maxWidth : '500px')};
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Step = styled.div`
  color: ${p => (p.active ? p.theme.stepperActiveColor : p.theme.stepperDefaultColor)};
  font-size: 1.125rem;
  width: 160px;
  text-align: center;
  padding: 0 10px;
  transition: color 0.5s;
`
const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: ${p => p.theme.stepperTriangleBorder};
`

class Stepper extends Component {
  render() {
    const { currentStep, totalSteps, maxWidth } = this.props
    return (
      <StepperContainer maxWidth={maxWidth}>
        {totalSteps.map((step, i) => {
          return (
            <Fragment key={step}>
              {i !== 0 && <Triangle />}
              <Step active={currentStep === i + 1}>{step}</Step>
            </Fragment>
          )
        })}
      </StepperContainer>
    )
  }
}

export default Stepper
