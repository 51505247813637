const overallStrategyGreyLinesWhiteBGSVG = `
  <svg width="100%" height="100%" viewBox="0 0 108 134" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>OverallStrategy.menu</title>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="OverallStrategyIcon" transform="translate(-66.000000, -667.000000)">
              <g id="Group" transform="translate(66.000000, 667.000000)">
                  <rect id="Rectangle" stroke="#7A8E96" stroke-width="3" fill="#FFFFFF" x="1.5" y="9.5" width="105" height="123" rx="10"></rect>
                  <rect id="Rectangle" stroke="#FFFFFF" stroke-width="3" fill="#7A8E96" x="22.5" y="1.5" width="63" height="18" rx="1"></rect>
                  <line x1="12.5" y1="46.5" x2="33.5" y2="66.5" id="Line-2" stroke="#7A8E96" stroke-width="5" stroke-linecap="round"></line>
                  <line x1="33.7080835" y1="46.7231422" x2="12.2919165" y2="66.2768578" id="Line-2-Copy" stroke="#7A8E96" stroke-width="5" stroke-linecap="round"></line>
                  <line x1="73.5" y1="84.5" x2="94.5" y2="104.5" id="Line-2" stroke="#7A8E96" stroke-width="5" stroke-linecap="round"></line>
                  <line x1="94.7080835" y1="84.7231422" x2="73.2919165" y2="104.276858" id="Line-2-Copy" stroke="#7A8E96" stroke-width="5" stroke-linecap="round"></line>
                  <circle id="Oval" stroke="#7A8E96" stroke-width="4" cx="41" cy="110" r="13"></circle>
                  <path d="M16.25,111.429688 C16.3844314,75.3873982 49.2015563,83.4531061 73.9931398,67.1612519 C83.8775124,60.6657106 84.1493414,47.6678657 84.25,37.4296875" id="Path-2" stroke="#7A8E96" stroke-width="4" stroke-linecap="round"></path>
                  <line x1="84.5" y1="35.5" x2="95.5" y2="46.5" id="Line-3" stroke="#7A8E96" stroke-width="4" stroke-linecap="round"></line>
                  <line x1="83.8702629" y1="35.8970583" x2="72.1297371" y2="46.1029417" id="Line-3-Copy" stroke="#7A8E96" stroke-width="4" stroke-linecap="round"></line>
                  <line x1="17.5" y1="18.5" x2="90.5" y2="18.5" id="Line" stroke="#7A8E96" stroke-width="2" stroke-linecap="round"></line>
              </g>
          </g>
      </g>
  </svg>
`

export default overallStrategyGreyLinesWhiteBGSVG
