/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { flow } from 'mobx'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'
import * as yup from 'yup'
import dayjs from 'dayjs'

import { Page } from '../elements'
import {
  TextInput,
  Button,
  ButtonInput,
  NumberInput,
  DatePicker,
  CurrencyInput,
  TextErrorField,
  HelpIcon,
  TooltipText,
  Dropdown as DropdownElement,
  Spacer,
} from '../../../components'
import { reduceValidationError } from '../../../utils'

const schema = yup.object().shape({
  firstName: yup
    .string()
    .nullable()
    .trim()
    .required('Name required; must be from 1 to 20 characters')
    .min(1, 'Name required; must be from 1 to 20 characters')
    .max(20, 'Name required; must be from 1 to 20 characters'),
  gender: yup.string().nullable().oneOf(['M', 'F'], 'Select one'),
  birthDate: yup.date().nullable().required('Birthdate is invalid'),
  retirementAge: yup
    .number()
    .nullable()
    .when('birthDate', (birthDate, schema) => {
      const age = birthDate && dayjs().year() - dayjs(birthDate).year()
      const maxRetAge = Math.min(99, age + 55)
      const validAgesMessage = `Valid ages: 20 - ${maxRetAge}`
      return schema
        .required(validAgesMessage)
        .min(20, validAgesMessage)
        .max(maxRetAge, validAgesMessage)
    }),
  annualIncome: yup
    .number()
    .nullable()
    .min(0, 'Salary must be between $0 and $10,000,000')
    .max(9999999, 'Salary must be between $0 and $10,000,000'),
  otherIncome: yup
    .number()
    .nullable()
    .min(0, 'Amount must be between $0 and $10,000,000')
    .max(9999999, 'Amount must be between $0 and $10,000,000'),
  planningWorkPartTime: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  durationStartingAge: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Duration: starting age is a required field')
            .test(
              'is-not-less-than-retirement-age',
              'Starting age for part-time work during retirement may not begin before the retirement age',
              function (value) {
                return this.parent.retirementAge <= value
              }
            )
        : schema
    ),
  durationStoppingAge: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Duration: stopping age is a required field')
            .test(
              'is-not-less-than-starting-age',
              'Stopping age cannot be before starting age',
              function (value) {
                return this.parent.durationStartingAge <= value
              }
            )
        : schema
    ),
  incomeAfterRetirement: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Annual income is required')
            .min(1, 'Amount must be between $1 and $10,000,000')
            .max(9999999, 'Amount must be between $0 and $10,000,000')
        : schema
    ),
})

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

class SpouseInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onSubmit = flow(function* (values) {
    const { isSpendown } = this.props.store.config
    const { saveSpouseRetirementStatus } = this.props.store
    const {
      incomeAfterRetirement,
      durationStartingAge,
      durationStoppingAge,
      includeSsiEstimate,
      planningWorkPartTime,
    } = values

    // Trim trailing whitespace for spouse first name
    values.firstName = _.trim(values.firstName)

    try {
      const { spouse, createSpouse, updateSpouse } = this.props.onboarding

      const spousePatch = _.pickBy(values, (value, key) => value !== spouse[key])

      let spousePromise

      if (!spouse.id) {
        spousePromise = createSpouse(values)
      } else {
        spousePromise = _.isEmpty(spousePatch) ? Promise.resolve({}) : updateSpouse(spousePatch)
      }

      yield spousePromise

      saveSpouseRetirementStatus({
        annualIncome: incomeAfterRetirement,
        durationStartingAge,
        durationStoppingAge,
        id: this.state.id || null,
        includeSsiEstimate,
        planningWorkPartTime,
      })

      if (isSpendown) {
        return this.props.history.push('/welcome/social-security-income')
      }
      this.props.history.push('/welcome/dependents')
    } catch (err) {
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  })

  editable = () => ({
    // client specific editable config
    firstName: true,
    gender: true,
    birthDate: true,
    retirementAge: true,
    annualIncome: true,
    otherIncome: true,
  })

  initialValues = () => {
    const { onboarding } = this.props
    const editableKeys = _.pickBy(this.editable())
    const initialValues = _.mapValues(editableKeys, (value, key) => onboarding.spouse[key])

    const planningWorkPartTime = Boolean(this.state.planningWorkPartTime)
    const includeSsiEstimate = Boolean(this.state.includeSsiEstimate)
    const durationStartingAge = this.state.durationStartingAge || this.state.retirementAge
    const durationStoppingAge = this.state.durationStoppingAge || this.state.retirementAge
    const annualIncome = this.state.annualIncome || 0
    const id = this.state.id

    // test for null cases
    // initialValues.firstName = null
    // initialValues.gender = null
    // initialValues.birthDate = null
    // initialValues.retirementAge = null
    // initialValues.annualIncome = null
    // initialValues.otherIncome = null

    // return initialValues
    const newInitialValues = {
      ...initialValues,
      planningWorkPartTime,
      includeSsiEstimate,
      durationStartingAge,
      durationStoppingAge,
      id,
      incomeAfterRetirement: annualIncome,
    }
    return newInitialValues
  }

  validate = values => {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  async componentDidMount() {
    const { getSpouseRetirementStatus } = this.props.store
    try {
      const data = await getSpouseRetirementStatus()
      this.setState(data)
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    const { retirementAge } = this.props.onboarding.spouse

    const startingAge = () => {
      let result = 0
      if (!isNaN(retirementAge)) {
        result = retirementAge
      } else {
        result = 65
      }
      return result
    }

    return (
      <Page>
        <Page.Heading title='Tell us about your spouse' />

        <Form
          initialValues={this.initialValues()}
          validate={this.validate}
          onSubmit={this.onSubmit.bind(this)}
          subscription={{ submitting: true, submitError: true, values: true }}
          render={({ handleSubmit, submitting, submitError, values }) => (
            <Page.Form>
              <Page.AnimateGroup>
                <Page.AnimateItem>
                  <Page.Field
                    label={
                      <span>
                        Spouse's first name <HelpIcon tooltip={TooltipText.spouseFirstName()} />
                      </span>
                    }>
                    <Field
                      name='firstName'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <TextInput
                          id='spouse-first-name-field'
                          error={meta.error}
                          maxLength={21}
                          name={input.name}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          placeholder='first name'
                          showError={meta.touched}
                          value={input.value}
                          width='340px'
                          mobileWidth='220px'
                        />
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field
                    label="Spouse's birthday"
                    css={css`
                      & > div:nth-of-type(2) {
                        word-break: normal;
                      }
                    `}>
                    <Field
                      name='birthDate'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <div>
                          <div id='spouse-birth-date-field'>
                            <DatePicker
                              handleChange={(name, value) => input.onChange(value)}
                              maxYear={new Date().getFullYear()}
                              minYear={1910}
                              name={input.name}
                              date={input.value}
                            />
                          </div>
                          <div>
                            <TextErrorField error={meta.error} showError={meta.touched} />
                          </div>
                        </div>
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field label="Spouse's gender">
                    <Field
                      name='gender'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <div>
                          <div
                            id='spouse-gender-select-field'
                            css={css`
                              font-size: 1rem;
                            `}>
                            <ButtonInput
                              name={input.name}
                              text='Female'
                              value='F'
                              isActive={input.value === 'F'}
                              onClick={(name, value) => input.onChange(value)}
                            />
                            <ButtonInput
                              name={input.name}
                              text='Male'
                              value='M'
                              isActive={input.value === 'M'}
                              onClick={(name, value) => input.onChange(value)}
                            />
                          </div>
                          <div>
                            <TextErrorField error={meta.error} showError={meta.touched} />
                          </div>
                        </div>
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>
                <Spacer space='20px' />

                <Page.AnimateItem>
                  <Page.Field
                    id='spouse-annual-income-field'
                    label={
                      <div
                        css={css`
                          display: flex;
                          justify-content: flex-end;
                          align-items: baseline;
                          & > div {
                            line-height: 100%;
                          }
                        `}>
                        Annual income before taxes{' '}
                        {this.props.store.config.isSpendown && <span>&nbsp;</span>}
                        {this.props.store.config.isSpendown && (
                          <HelpIcon tooltip={TooltipText.annualSalary()} />
                        )}
                      </div>
                    }>
                    <Field
                      name='annualIncome'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <CurrencyInput
                          name={input.name}
                          onChange={(name, value) => input.onChange(value)}
                          placeholder='0'
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          value={input.value}
                          showError={meta.touched}
                          error={meta.error}
                        />
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field label='Other earned income annually'>
                    <Field
                      name='otherIncome'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <CurrencyInput
                          name={input.name}
                          onChange={(name, value) => input.onChange(value)}
                          placeholder='0'
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          value={input.value}
                          showError={meta.touched}
                          error={meta.error}
                        />
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field id='spouse-retirement-age-field' label="Spouse's retirement age">
                    <Field
                      name='retirementAge'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <NumberInput
                          name={input.name}
                          placeholder='age'
                          value={input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          showError={meta.touched}
                          error={meta.error}
                        />
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>
                <Spacer space='20px' />

                <>
                  <Page.AnimateItem>
                    <Page.Field label='Planning to work part-time during retirement?'>
                      <Field
                        name='planningWorkPartTime'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <div>
                            <div
                              id='planningWorkPartTime'
                              css={css`
                                font-size: 1rem;
                              `}>
                              <ButtonInput
                                isActive={input.value === true}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='Yes'
                                value
                                width='122px'
                              />
                              <ButtonInput
                                isActive={input.value === false}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='No'
                                value={false}
                                width='122px'
                              />
                            </div>
                            <div>
                              <TextErrorField error={meta.error} showError={meta.touched} />
                            </div>
                          </div>
                        )}
                      />
                    </Page.Field>
                  </Page.AnimateItem>

                  <Condition when='planningWorkPartTime' is>
                    <Page.Field label='Duration: starting age'>
                      <Field
                        name='durationStartingAge'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <Dropdown
                            startingAge={values.retirementAge || startingAge()}
                            initialValue={input.value}
                            input={input}
                            meta={meta}
                          />
                        )}
                      />
                    </Page.Field>
                  </Condition>

                  <Condition when='planningWorkPartTime' is>
                    <Page.Field label='Duration: stopping age'>
                      <Field
                        name='durationStoppingAge'
                        format={value => (value === null ? undefined : value)}
                        parse={v => v}
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <Dropdown
                            startingAge={values.retirementAge || startingAge()}
                            initialValue={input.value}
                            input={input}
                            meta={meta}
                          />
                        )}
                      />
                    </Page.Field>
                  </Condition>

                  <Condition when='planningWorkPartTime' is>
                    <Page.Field label='Annual income from job after retiring'>
                      <Field
                        name='incomeAfterRetirement'
                        format={value => (value === null ? undefined : value)}
                        parse={v => v}
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <CurrencyInput
                            error={meta.error}
                            name={input.name}
                            placeholder='0'
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onChange={(name, value) => input.onChange(value)}
                            showError={meta.touched}
                            value={input.value}
                          />
                        )}
                      />
                    </Page.Field>
                  </Condition>
                </>

                <Page.Animate pose={!submitting && submitError ? 'visible' : 'hidden'}>
                  <Page.ErrorMessage>{!submitting && submitError}</Page.ErrorMessage>
                </Page.Animate>

                <Page.AnimateItem>
                  <Page.ButtonGroup>
                    <div>
                      <Button
                        id='spouse-info-back-button'
                        type='button'
                        onClick={this.props.history.goBack}
                        secondary
                        label='Back'
                        disabled={submitting}
                        width='140px'
                      />
                    </div>
                    <div>
                      <Button
                        id='spouse-info-submit-button'
                        type='button'
                        onClick={handleSubmit}
                        primary
                        label='Next'
                        disabled={submitting}
                        width='140px'
                      />
                    </div>
                  </Page.ButtonGroup>
                </Page.AnimateItem>
              </Page.AnimateGroup>
            </Page.Form>
          )}
        />
      </Page>
    )
  }
}

export default inject('onboarding', 'store')(observer(SpouseInfo))

const Dropdown = ({ initialValue, input, meta, startingAge }) => {
  const ages = () => {
    const arr = []
    for (let i = startingAge; i <= 100; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }

  return (
    <DropdownElement
      error={meta.error}
      name={input.name}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      options={ages()}
      placeholder={initialValue + ''}
      selected={input.value}
      showError={meta.touched}
    />
  )
}
