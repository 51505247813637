import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'
import { SVGWrapper } from '../../../../components'
import {
  ArrowRight,
  Help,
  Home,
  OverallStrategyLive,
  BullsEye,
  Bank,
  Tools,
} from '../../../../assets/icons'
import { ArrowIconRow, FlexSpace } from './'

const LeftBarSVGWrapper = styled.div`
  padding: 0;
  height: 48px;
`

const LeftBarWrapper = styled.div`
  width: 64px;
  border: ${p => p.theme.sideBarBorder};
  background-color: ${p => p.theme.sidebarColor};
  box-shadow: 0 2px 2px 0 rgba(182, 192, 196, 0.15);
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
`

class LeftSideBar extends Component {
  render() {
    const {
      main: { sidebarActive, toggleSidebar },
      store: { features },
    } = this.props
    const defaultFill = '#ffffff'

    return (
      <LeftBarWrapper>
        <ArrowIconRow topArrow>
          <SVGWrapper
            svg={ArrowRight}
            size='large'
            transition='fill 0.3s'
            fill={sidebarActive ? 'rgba(255,255,255,0)' : defaultFill}
            onClick={toggleSidebar}
            css={css`${sidebarActive} && 'pointer-events:none;cursor:default;`}
          />
        </ArrowIconRow>
        {!sidebarActive && (
          <LeftBarSVGWrapper>
            <SVGWrapper
              svg={Home}
              size='large'
              fill={defaultFill}
              onClick={() => this.props.history.push('/home')}
            />
          </LeftBarSVGWrapper>
        )}
        {!sidebarActive && (
          <LeftBarSVGWrapper>
            <SVGWrapper
              svg={OverallStrategyLive}
              size='large'
              fill={defaultFill}
              onClick={() => this.props.history.push('/overall')}
            />
          </LeftBarSVGWrapper>
        )}
        {!sidebarActive && (
          <LeftBarSVGWrapper>
            <SVGWrapper
              svg={BullsEye}
              size='large'
              fill={defaultFill}
              onClick={() => this.props.history.push('/goals')}
            />
          </LeftBarSVGWrapper>
        )}
        {!sidebarActive && (
          <LeftBarSVGWrapper>
            <SVGWrapper
              svg={Bank}
              size='large'
              fill={defaultFill}
              onClick={() => this.props.history.push('/accounts')}
            />
          </LeftBarSVGWrapper>
        )}
        <FlexSpace />
        {features.enableTools && (
          <LeftBarSVGWrapper>
            <SVGWrapper
              svg={Tools}
              size='large'
              fill={defaultFill}
              onClick={() => this.props.history.push('/tools')}
            />
          </LeftBarSVGWrapper>
        )}
        {features.enableHelp && (
          <LeftBarSVGWrapper>
            <SVGWrapper
              svg={Help}
              size='large'
              fill={defaultFill}
              onClick={() => this.props.history.push('/help')}
            />
          </LeftBarSVGWrapper>
        )}
      </LeftBarWrapper>
    )
  }
}

export default inject('store', 'main')(observer(LeftSideBar))
