import { types, flow } from 'mobx-state-tree'

import { API } from '../api'
const { model, maybeNull, number, string, boolean } = types

const SSIBenefits = model({
  birthDate: maybeNull(string),
  fullRetirementAgeMonth: maybeNull(number),
  fullRetirementAgeMonthlyAmount: maybeNull(number),
  fullRetirementAgeYear: maybeNull(number),
  includeSsi: maybeNull(boolean),
  monthlyAmount: maybeNull(number),
  paymentBeginAge: maybeNull(number),
  personId: maybeNull(number),
  receivingSsi: maybeNull(number),
  useGcEstimate: maybeNull(number),
})

const SocialSecurityStore = model({
  spouseSSIBenefitsResult: maybeNull(SSIBenefits),
  primarySSIBenefitsResult: maybeNull(SSIBenefits),
  primaryOptimizedSSI: maybeNull(SSIBenefits),
  spouseOptimizedSSI: maybeNull(SSIBenefits),
})
  .views(self => ({
    get spouseSSIBenefits() {
      return self.spouseSSIBenefitsResult
    },
    get primarySSIBenefits() {
      return self.primarySSIBenefitsResult
    },
  }))
  .actions(self => ({
    getSSBenefits: flow(function* () {
      const result = yield API.get('social-security-income')
      if (!self.person) {
        yield self.getPerson()
      }
      if (self.includeSpouseInPlanning) {
        if (!self.spouse) {
          yield self.getSpouse()
        }
      }

      const { primarySSIBenefits } = result.data

      self.primarySSIBenefitsResult = primarySSIBenefits
      if (self.person.includeSpouse) {
        const { spouseSSIBenefits } = result.data
        self.spouseSSIBenefitsResult = spouseSSIBenefits
      }
      return result.data
    }),
    saveSSBenefits: flow(function* (data) {
      const result = yield API.post('social-security-income', data)

      const { primarySSIBenefits } = result.data
      self.primarySSIBenefitsResult = primarySSIBenefits

      if (self.person.includeSpouse) {
        const { spouseSSIBenefits } = result.data
        self.spouseSSIBenefitsResult = spouseSSIBenefits
      }
      return result.data
    }),
    getOptimizedSSI: flow(function* () {
      const result = yield API.get('social-security-income/optimize', {
        params: { personId: self.person.id, accountId: self.account.id },
      })

      const { primarySSIBenefits } = result.data

      self.primaryOptimizedSSI = primarySSIBenefits

      if (self.person.includeSpouse) {
        const { spouseSSIBenefits } = result.data
        self.spouseOptimizedSSI = spouseSSIBenefits
      }
      return result.data
    }),
  }))

export default SocialSecurityStore
