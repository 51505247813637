import React from 'react'
import { useTheme } from '@emotion/react'

const FillGauge = props => {
  let { fill, height, width, id } = props
  const theme = useTheme()

  if (!fill) {
    fill = 0
  }
  if (!height) {
    height = 120
  }
  if (!width) {
    width = 120
  }
  if (!id) {
    id = 0
  }

  const fillPercent = Math.min(Math.max(fill.toFixed(0), 0), 999)
  const centerHeight = height / 2
  const centerWidth = width / 2
  const padding = 3
  const radius = height / 2 - padding
  const scaleText = height / 120

  return (
    <svg version='1.1' width={width} height={height}>
      <defs>
        <linearGradient id={`${id}`} x1='0.5' y1='1' x2='0.5' y2='0'>
          <stop offset='0%' stopColor={theme.fillGaugeBg} />
          <stop offset={`${fillPercent}%`} stopColor={theme.fillGaugeBg} />
          <stop offset={`${fillPercent}%`} stopColor='#ffffff' />
          <stop offset='100%' stopColor='#ffffff' />
        </linearGradient>
      </defs>

      <circle
        cx={centerWidth}
        cy={centerHeight}
        r={radius}
        stroke={theme.primaryColor}
        strokeWidth='4px'
        fill='white'
      />
      <circle cx={centerWidth} cy={centerHeight} r={radius - 5} fill={`url(#${id})`} />
      <text x={centerWidth} y={centerHeight} textAnchor='middle' dy='0'>
        <tspan
          x={centerWidth}
          dx={fillPercent === 1 ? '.02em -.08em 0' : '0'}
          dy={`${scaleText * 16}px`}
          fill={theme.primaryColor}
          fontFamily="'Open Sans', 'Helvetica Neue', sans-serif"
          fontSize={fillPercent >= 1 ? `${scaleText * 2.35}em` : `${scaleText * 2.5}em`}>
          {`${Math.floor(fillPercent)}%`}
        </tspan>
        <tspan
          x={centerWidth}
          dy={`${scaleText * 18}px`}
          fill={theme.primaryColor}
          fontSize={`${scaleText * 0.75}em`}
          fontFamily="'Open Sans', 'Helvetica Neue', sans-serif">
          of income
        </tspan>
      </text>
    </svg>
  )
}

export default FillGauge
