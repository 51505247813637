import variables from '../variables'
import colors from '../colors'
import media from '../media'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const { mobile, desktop } = media.standardIncrement

const drawer = {
  backgroundColor: colors.rt.grey50,
  borderColor: colors.rt.grey300,
  textColor: colors.rt.grey800,
  width: reduceCSSCalc(`calc(24 * ${unit})`),
  desktopWidth: reduceCSSCalc(`calc(5 * ${desktop})`),
  mobileWidth: reduceCSSCalc(`calc(5 * ${mobile})`),
}

export default drawer
