const MoneyBagBlueEmptySVG = `
  <svg width="300" height="300" viewBox="0 0 118 109" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-387.000000, -410.000000)" stroke="#038AB7">
            <g id="Group-3" transform="translate(388.000000, 411.000000)">
                <g id="Group-Copy-5" fill="#FFFFFF" fill-rule="nonzero">
                    <path d="M75.5818985,23.7402597 C74.9884083,23.9592635 74.296003,24.1782673 73.5046827,24.1782673 L43.5334262,24.1782673 C42.7421059,24.1782673 42.0497006,24.0322648 41.4562104,23.7402597 C23.9482487,34.3254414 0.307554609,58.196851 0.307554609,73.3811116 C0.307554609,87.4703534 6.04462681,96.303505 18.5079216,103.895635 C22.167778,106.158674 26.8167848,107.399695 31.6636217,107.399695 L85.0777422,107.399695 C89.924579,107.399695 94.5735858,106.158674 98.2334422,103.895635 C110.696737,96.2305037 116.433809,87.3973521 116.433809,73.3811116 C116.730554,58.196851 93.1887753,34.3254414 75.5818985,23.7402597 Z" id="Shape-Copy-7"></path>
                    <path d="M71.5525129,15.9859044 C71.849258,15.9859044 72.1460031,15.9859044 72.4427482,16.0589057 L85.0049581,7.22575412 C85.9941084,6.49574159 85.7962784,5.54672531 84.5103829,5.10871779 L80.2570362,3.57569148 C78.9711407,3.13768397 76.8939249,2.99168146 75.5091144,3.35668773 L69.6731272,4.74371153 C68.2883166,5.03571654 66.3100159,4.81671278 65.1230354,4.23270276 L58.5946429,0.80164388 C57.4076625,0.217633858 55.5282767,0.217633858 54.4402113,0.80164388 L47.9118188,4.23270276 C46.7248384,4.81671278 44.7465376,5.10871779 43.3617271,4.74371153 L37.5257398,3.35668773 C36.1409293,3.06468271 34.0637135,3.13768397 32.777818,3.57569148 L28.5244714,5.10871779 C27.2385759,5.54672531 27.0407458,6.49574159 28.0298962,7.22575412 L40.592106,16.0589057 C40.8888511,15.9859044 41.1855962,15.9859044 41.4823413,15.9859044 L71.5525129,15.9859044 Z" id="Shape-Copy-6"></path>
                    <path d="M73.2418352,22.2991609 C74.0331555,22.2991609 74.7255607,22.1531584 75.319051,21.8611534 C76.6049465,21.3501447 77.4951818,20.3281271 77.4951818,19.0871058 C77.4951818,17.5540795 76.1103713,16.3130582 74.1320705,16.0210532 C73.8353254,15.9480519 73.5385803,15.9480519 73.2418352,15.9480519 L43.2705787,15.9480519 C42.9738335,15.9480519 42.6770884,15.9480519 42.3803433,16.0210532 C40.5009576,16.3130582 39.017232,17.5540795 39.017232,19.0871058 C39.017232,20.2551259 39.9074674,21.2771434 41.1933629,21.8611534 C41.7868531,22.0801572 42.4792584,22.2991609 43.2705787,22.2991609 L73.2418352,22.2991609 Z" id="Shape-Copy-5"></path>
                    <path d="M107.131238,70.4935065 C107.131238,82.0277044 102.976807,89.0358247 92.3928976,95.4599349 C90.4145968,96.627955 87.9417209,97.2849662 85.4688449,97.2849662" id="Shape-Copy-4"></path>
                </g>
                <g id="Group-3-Copy-3" transform="translate(54.000000, 3.000000)">
                    <path d="M0,7.08021828 C0,8.02564802 1.22382199,8.8 2.75,8.8 C4.2617801,8.8 5.5,8.03465211 5.5,7.08021828 C5.5,5.36043656 0.446335079,5.36043656 0.446335079,3.64065484 C0.446335079,2.86630286 1.2382199,2.2 2.75,2.2 C4.2617801,2.2 5.05366492,2.92032742 5.05366492,3.64065484" id="Shape-Copy-3"></path>
                    <line x1="2.2" y1="11" x2="2.2" y2="9.9" id="Shape-Copy-2"></line>
                    <line x1="2.2" y1="1.1" x2="2.2" y2="0" id="Shape-Copy"></line>
                </g>
            </g>
        </g>
    </g>
  </svg>
`
export default MoneyBagBlueEmptySVG
