import { css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'

import { Checkbox, TextErrorField } from '../../../components'
import { BackButton, ButtonsBox, NextButton, Spacer } from '../../../components/styled'
import RadioGroup from '../../../guided-toolbox/radio/RadioGroup'
import { useState } from 'react'

const DiscontinueRetail = props => {
  const { discontinueRetailSubscription } = props.store

  const [discontinue, setDiscontinue] = useState(undefined)

  const onSubmit = async () => {
    try {
      if (discontinue) {
        await discontinueRetailSubscription()
        props.onClose()
      } else {
        props.onClose()
      }
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ submitting: true, submitError: true }}
      render={({ handleSubmit, submitting, submitError }) => (
        <div
          css={css`
            padding: 20px;
          `}>
          <div
            css={css`
              color: #4a606a;
              font-size: 22px;
            `}>
            Are you sure you want to discontinue automatic renewal of the GuidedChoice Advisory
            Service?
          </div>

          <RadioGroup
            css={css`
              margin: 40px 20px;
            `}>
            <div
              css={css`
                color: #4a606a;
                font-size: 22px;
                display: -webkit-inline-box;
                &:hover {
                  cursor: pointer;
                }
              `}
              onClick={() => setDiscontinue(false)}>
              <Checkbox
                css={css`
                  margin-right: 20px;
                `}
                checked={!discontinue}
              />
              <div>
                No, keep my subscription active.
                <div
                  css={css`
                    color: #4a606a;
                    font-size: 18px;
                    font-weight: 300;
                    display: flex;
                  `}>
                  Your annual subscription will be renewed automatically, and you will be notified
                  by email approximately 10 days prior to renewal. Return to profile to see your
                  renewal date.
                </div>
              </div>
            </div>
            <Spacer space='20px' />
            <div
              css={css`
                color: #4a606a;
                font-size: 22px;
                display: -webkit-inline-box;
                &:hover {
                  cursor: pointer;
                }
              `}
              onClick={() => setDiscontinue(true)}>
              <Checkbox
                css={css`
                  margin-right: 20px;
                `}
                checked={discontinue}
              />
              <div>
                Yes, discontinue automatic renewal.
                <div
                  css={css`
                    color: #4a606a;
                    font-size: 18px;
                    font-weight: 300;
                    display: flex;
                  `}>
                  Your annual subscription will not be renewed. Return to profile to see your
                  subscription end date. You will continue to have access to the Advisory Service
                  until then.
                </div>
              </div>
            </div>
          </RadioGroup>

          <div
            css={css`
              color: #4a606a;
              font-size: 18px;
              font-weight: 300;
              display: flex;
            `}>
            Should you discontinue automatic renewal, you can always renew at a later date. In the
            meantime, it is important that you incorporate ongoing tax changes, market conditions,
            and personal circumstances into your financial planning to keep on track.
          </div>

          <Field
            name='discontinue'
            subscription={{ touched: true, error: true }}
            render={({ meta }) => (
              <div
                css={css`
                  padding: 20px;
                  text-align: center;
                `}>
                <TextErrorField showError={meta.touched} error={meta.error || submitError} />
              </div>
            )}
          />

          <ButtonsBox>
            <BackButton backgroundColor='#FFFFFF' onClick={props.onClose} disabled={submitting}>
              Cancel
            </BackButton>
            <NextButton onClick={handleSubmit} disabled={submitting}>
              Save
            </NextButton>
          </ButtonsBox>
        </div>
      )}
    />
  )
}

export default withRouter(inject('store')(DiscontinueRetail))
