/* eslint-disable react/jsx-handler-names */
import { useState } from 'react'
import Tabs from './Tabs'
import Legend from './Legend'
import MixLabels from './MixLabels'
import Risk from './Risk'
import Breakdown from './Breakdown'

// TODO: add options for hiding Old Mix column and disable tab options
function InvestmentMixChart(props) {
  const [active, setActive] = useState(props.defaultState || 'assetClassBreakdown')

  function handleChange(value) {
    setActive(value)
  }

  const { allTabsEnabled, store, ...restProps } = props
  // Hide the Old Mix for Asset Class Breakdown Tab
  const hideOld = active === 'assetClassBreakdown'

  return (
    <div {...restProps}>
      <Tabs
        store={store}
        active={active}
        handleChange={handleChange}
        allTabsEnabled={allTabsEnabled}
      />

      <Legend />

      <MixLabels hideOld={hideOld} />

      <Risk store={store} hideOld={hideOld} />

      <Breakdown
        store={store}
        active={active}
        hideOld={hideOld}
        selectedaccount={props.selectedaccount}
      />
    </div>
  )
}

export default InvestmentMixChart
