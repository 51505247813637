import styled from '@emotion/styled'
import { Button, ButtonInput } from '../../../components'

export const Page = styled.div``

Page.ScenarioContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 38px;
`
Page.Text = styled.div`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`
Page.SpanText = styled.span`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`
Page.OptionalText = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
`
Page.WhiteBox = styled.div`
  min-height: 86px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 15px 16px;
`
Page.NameInput = styled.input`
  flex: 1;
  display: inline-block;
  border: none;
  border-bottom: 1px solid #4a606a;
  outline: none;
  font-size: 14px;
  line-height: 19px;
`
Page.ScenarioDescription = styled.textarea`
  box-sizing: border-box;
  height: 100px;
  width: 100%;
  border: 1px solid #4a606a;
  outline: none;
  font-size: 14px;
  line-height: 19px;
  padding: 10px;
  margin-top: 10px;
`
Page.SaveScenarioBtn = styled(Button)`
  height: 41px;
  width: 112.34px;
  border-radius: 5px 4px 4px 4px;
  background-color: #7a8e96;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  justify-content: flex-end;
`
Page.Row = styled.div`
  display: flex;
`
Page.Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
`
Page.Error = styled.span`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
`
Page.Heading = styled.div`
  color: ${p => p.theme.considerHeadingTextColor};
  font-family: 'Open Sans';
  font-size: 50px;
  font-weight: 300;
  line-height: 76px;
  word-break: break-word;
  max-width: 630px;
  margin-bottom: 40px;
`
Page.Section = styled.div`
  font-size: 1.125rem;
  color: #4a606a;
  line-height: 1.35;
  font-weight: 600;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  max-width: 550px;
  align-items: center;
`
Page.SectionTitle = styled.div`
  color: ${p => p.theme.considerSectionTitleTextColor};
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
`
Page.SecondaryButton = styled.div`
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  user-select: none;

  border-radius: 4px;
  background: #4a606a;
  cursor: pointer;
  padding: 8px;
  &:hover {
    background: #42565e;
  }
  &:active {
    background: #324147;
  }
`
Page.MainText = styled.div`
  color: ${p => p.theme.considerMainTextColor};
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 24px;
`
Page.Container = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
`
Page.MainWrapper = styled.div`
  margin-left: 15px;
  color: ${p => p.theme.considerationsMainWrapperTextColor};
`
Page.Wrapper = styled.div`
  display: flex;
`
Page.PageTitle = styled.div`
  font-weight: 600;
  font-size: 1.15rem;
  margin-bottom: 20px;
`
Page.BorderedCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: ${p => p.theme.considerContainerBorder};
  border-radius: 8px;
  background-color: ${p => p.theme.considerContainerBgColor};
  margin: 10px 0;
  padding: 1rem;
  margin-bottom: 20px;
  min-height: 200px;
  justify-content: center;
`
Page.LeftSection = styled.div`
  flex: 1;
`
Page.IncomeExpectancyCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background-color: ${p => p.theme.containerColor};
  width: 90%;
  margin: 10px 0;
  &:after {
    position: absolute;
    content: '*Estimated after-tax spendable money';
    bottom: -10%;
    left: 0;
    font-size: 0.75rem;
    color: #7a8e96;
  }
`
Page.CanYouCoverCard = styled.div`
  width: 90%;
  margin-top: 60px;
  background-color: ${p => p.theme.canYouCoverCardColor};
  padding: 18px 26px;
  border: 1px solid #7a8e96;
`
Page.CanYouCoverCardTitle = styled.div`
  color: ${p => p.theme.canYouCoverTitleColor};
  font-family: 'Open Sans';
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
`
Page.IncomeValue = styled.div`
  font-size: 50px;
  line-height: 68px;
  color: ${p => p.theme.considerationsIncomeValueTextColor};
  margin: 10px 0;
  display: inline-flex;
  justify-content: center;

  &:before {
    content: '$';
    color: #4d9927;
    padding-right: 3px;
  }
`
Page.RightSection = styled.div`
  flex: 1;
`
Page.SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
Page.CardLabel = styled.div`
  margin-bottom: 50px;
  color: ${p => p.theme.considerContainerCardLabelColor};
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`
Page.Note = styled.div`
  color: ${p => p.theme.considerContainerNoteColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  margin-top: 5px;
`
Page.SliderLabel = styled.div`
  color: ${p => p.theme.sliderLabelColor};
  flex: 1;
  margin-right: 80px;
`
Page.SliderValue = styled.div`
  flex: 3;
  height: 40px;
  padding-top: 19px;
`
Page.EmergencyAmountText = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`
Page.ConsiderationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`
Page.ConsiderationLabel = styled.span`
  color: ${p => p.theme.considerationLabelColor};
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
`
Page.ConsiderationWrapper = styled.div`
  margin-left: 10px;
`
Page.ConsiderationBody = styled.div`
  margin-top: 25px;
`
Page.EmergencyFundInputFieldContainer = styled.div`
  width: 110px;
  position: relative;
  &:before {
    position: absolute;
    content: '$';
  }
  & > input {
    width: 110px;
  }
`
Page.EmergencyInput = styled.input`
  border: none;
  border-bottom: 1px solid #7a8e96;
  outline: none;
  text-indent: 20px;
`
Page.ConsiderationTryText = styled.span`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`
Page.ConsiderationDisclaimerText = styled.span`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`
Page.IncomeAfterRetirementContainer = styled.div`
  & > div {
    margin: 0;
    padding: 0;
    min-height: 0;
    width: 120px;
  }
`
Page.FieldWrapper = styled.div`
  display: flex;
  min-height: 56.5px;
`
Page.Label = styled.label`
  color: ${p => p.theme.labelTextColor};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  font-size: 16px;
  text-align: right;
`
Page.Value = styled.div`
  color: ${p => p.theme.valueTextColor};
  flex: 1;
  display: flex;
  align-items: center;
`
Page.CanYouCoverHeader = styled.div`
  color: ${p => p.theme.canYouCoverHeaderColor};
  font-family: 'Open Sans';
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
`
Page.Button = styled(ButtonInput)`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
`
Page.ThatsGreatText = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`
Page.CanCoverExpensesBody = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
`
Page.ReminderText = styled.div`
  color: ${p => p.theme.reminderTextColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`
Page.CanAffordNeeds = styled.div`
  color: ${p => p.theme.canAffordNeedsColor};
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
`

const MainPage = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

MainPage.Container = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
  color: ${p => p.theme.offboardingPageContainerTextColor};
`

export { MainPage }
