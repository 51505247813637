import axios from 'axios'
// import axiosRetry from 'axios-retry'

const domain = window.location.hostname.split('.').slice(-2).join('.')

const AUTH_URL = `https://auth.${domain}/api/`

const AUTH = axios.create({
  baseURL: AUTH_URL,
  withCredentials: true,
})

const API_URL = `https://api.${domain}/api/`

const API = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

const LOGOUT = axios.create({
  baseURL: `https://api.${domain}/`,
  withCredentials: true,
})

const LOGIN_URL = `https://app.${domain}/login`

// Axios Retry gets stuck in an infinite loop
// axiosRetry(API, { retries: 2, retryDelay: axiosRetry.exponentialDelay })

export { domain, AUTH, AUTH_URL, API, API_URL, LOGOUT, LOGIN_URL }
