import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import { useTheme } from '@emotion/react'
import { css } from '@emotion/css'
import posed, { PoseGroup } from 'react-pose'

import SetupEmail from './SetupEmail'
import SetupAccount from './SetupAccount'
import ResetPassword from './ResetPassword'
import EnterAuthCode from './EnterAuthCode'
import Login from './Login'
import { API_URL } from '../../api'
import { Page } from './elements'
import BGRight from '../../assets/images/BGRight.svg'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function LoginDashboard(props) {
  const { location } = props
  const theme = useTheme()

  useEffect(() => {
    document.documentElement.className = css`
      background: url(${API_URL && theme.bgRight ? API_URL + theme.bgRight : BGRight}) no-repeat top
        right fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    `
    return () => {
      document.documentElement.className = ''
    }
  })

  return (
    <PoseGroup animateOnMount>
      <RouteContainer key={location.key || location.pathname}>
        <Page.Container>
          <Switch location={location}>
            <Route path='/atmos/register/setup-account' component={SetupAccount} />
            <Route path='/atmos/register/enter-auth-code/:type' component={EnterAuthCode} />
            <Route path='/atmos/register/:ref?' component={SetupEmail} />
            <Route path='/atmos/reset-password' component={ResetPassword} />
            <Route path='/atmos/login' component={Login} />
            <Route component={RedirectToLogin} />
          </Switch>
        </Page.Container>
      </RouteContainer>
    </PoseGroup>
  )
}

export default inject('store')(observer(LoginDashboard))

function RedirectToLogin({ history }) {
  useEffect(() => {
    history.replace('/atmos/login')
  }, []) // eslint-disable-line

  return null
}
