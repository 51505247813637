/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import _ from 'lodash'

import { Page } from './style'
import { TextInput, FormError } from '../../../components'
import { BackButton, NextButton } from '../../../components/styled'

function ConfirmEmail(props) {
  const {
    history,
    spendDownOffboarding: { incrementStep, setSpedDownOffboardingEmail },
    store: {
      contactInfo: { email },
      person,
      config,
    },
  } = props

  const submitEmailForm = async ({ email }) => {
    try {
      await setSpedDownOffboardingEmail(email)
      incrementStep(history)
    } catch (err) {
      console.error(err)
      return {
        [FORM_ERROR]:
          'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
      }
    }
  }

  return (
    <Page.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
        line-height: 1.5rem;
      `}>
      <Form
        onSubmit={submitEmailForm}
        initialValues={{ email: email || 'example@example.com' }}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting, submitError }) => (
          <div>
            <Page.Heading
              css={css`
                color: #4a606a;
                font-weight: 400;
                text-align: start;
              `}>
              Let's put your plan into action
            </Page.Heading>

            <Page.Container>
              <div>
                {person.displayName}, you don't pay any fees for the advice you receive as a member
                of the {config.template?.planName}.{' '}
                {config?.sponsorShortHand === 'FRS'
                  ? 'The state has taken care of that on your behalf.'
                  : ''}
                <Page.EmailContainer>
                  <Page.SubHeader
                    css={css`
                      font-size: 1.25rem;
                    `}>
                    Confirm email
                  </Page.SubHeader>
                  <div
                    css={css`
                      margin: 10px 0;
                    `}>
                    Confirm the email address to which you would like your Personalized Retirement
                    Income Guide sent. (This will not change the email GuidedChoice{' '}
                    {config?.sponsorShortHand ? `or ${config?.sponsorShortHand}` : ''} has on record
                    for you, if different.)
                  </div>

                  <EmailField />
                </Page.EmailContainer>
                {submitError && <FormError err={submitError} />}
              </div>
            </Page.Container>

            <div
              css={css`
                display: flex;
                justify-content: space-between;
                margin: 30px 0;
              `}>
              <BackButton backgroundColor='#FFFFFF' onClick={() => history.goBack()}>
                Back
              </BackButton>
              <NextButton onClick={() => incrementStep(history, 'confirm-email')}>Next</NextButton>
            </div>
          </div>
        )}
      />
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(ConfirmEmail))

function EmailField() {
  const { input, meta } = useField('email', {
    validate: value =>
      yup
        .string()
        .email()
        .required('Email is required')
        .max(128, 'Maximum character limit 128 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      error={meta.error}
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='name@email.com'
      showError={meta.touched}
      value={input.value}
      width='70%'
    />
  )
}
