/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { inject, observer } from 'mobx-react'
import { flow } from 'mobx'
import * as yup from 'yup'

import { Page } from '../elements'
import { Checkbox, Button, Spacer } from '../../../components'
import { reduceValidationError } from '../../../utils'
import CCPANotice from '../accept-terms/CCPANotice'

const schema = yup.object().shape({
  terms: yup
    .boolean()
    .equals(
      [true],
      'Please check the box above to confirm your agreement to the terms and conditions'
    ),
})

class ReacceptTerms extends Component {
  onSubmit = flow(function* (values) {
    try {
      yield this.props.onboarding.acceptTerms()

      if (this.props.store.features.enablePersonalizedTargetDatePlus) {
        this.props.history.replace('/overall')
      } else {
        this.props.history.replace('/home')
      }
    } catch (err) {
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  })

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  render() {
    const {
      person: { displayName },
      account: { taxState },
      termsOfServiceLink,
      disclosureLink,
    } = this.props.onboarding

    return (
      <Page>
        <Page.WelcomeHeading>
          {displayName ? `Welcome back, ${displayName}!` : 'Welcome back!'}
        </Page.WelcomeHeading>

        <Page.WelcomeParagraph
          css={css`
            max-width: 1200px;
          `}>
          {"We've updated our standard "}
          <Page.Link href={termsOfServiceLink} target='_blank' rel='noreferrer noopener'>
            Terms of Service
          </Page.Link>
          {' and/or '}
          <Page.Link href={disclosureLink} target='_blank' rel='noreferrer noopener'>
            Disclosure
          </Page.Link>
          {' documents. Please take a few minutes to review and confirm your acceptance of them.'}
        </Page.WelcomeParagraph>

        <Form
          initialValues={{
            terms: false,
          }}
          onSubmit={this.onSubmit.bind(this)}
          validate={this.validate}
          subscription={{ touched: true, errors: true, submitting: true, submitError: true }}
          render={({ touched, errors, submitting, handleSubmit, submitError }) => (
            <form onSubmit={handleSubmit}>
              <div
                css={css`
                  display: flex;
                  margin: auto;
                  padding: 20px 0;
                  max-width: 1000px;
                `}>
                <div
                  css={css`
                    margin-top: 8px;
                    min-width: 64px;
                  `}>
                  <Field
                    name='terms'
                    subscription={{ value: true }}
                    render={({ input, meta }) => (
                      <Checkbox
                        id='checkbox'
                        checked={input.value}
                        onClick={
                          submitting
                            ? () => {}
                            : () => {
                                input.onFocus()
                                input.onChange(!input.value)
                                input.onBlur()
                              }
                        }
                        css={css`
                          margin: auto;
                        `}
                      />
                    )}
                  />
                </div>
                <Field
                  name='terms'
                  subscription={{ value: true }}
                  render={({ input }) => {
                    const handleTermsClick = submitting
                      ? () => {}
                      : () => {
                          input.onFocus()
                          input.onChange(!input.value)
                          input.onBlur()
                        }
                    return (
                      <Page.Text
                        onClick={handleTermsClick}
                        css={css`
                          cursor: pointer;
                          font-weight: 600;
                        `}>
                        {
                          'I have read and I agree to the terms and conditions as described in the GuidedChoice '
                        }
                        <Page.Link
                          href={termsOfServiceLink}
                          target='_blank'
                          rel='noreferrer noopener'
                          onClick={e => e.stopPropagation()}>
                          Terms of Service
                        </Page.Link>
                        {' and '}
                        <Page.Link
                          href={disclosureLink}
                          target='_blank'
                          rel='noreferrer noopener'
                          onClick={e => e.stopPropagation()}>
                          Disclosure
                        </Page.Link>
                        {' documents.'}
                      </Page.Text>
                    )
                  }}
                />
              </div>

              <Page.TermsSubText
                css={css`
                  max-width: 1000px;
                `}>
                The Terms and Conditions and Disclosure documents apply to the GuidedChoice program
                only. They are not to be construed as an offer to buy or sell, or the solicitation
                of an offer to buy or sell, any security or financial product or service to any
                person in any jurisdiction in which such offer, solicitation, purchase, or sale
                would be unlawful.
              </Page.TermsSubText>

              <Page.Animate
                pose={
                  (!submitting && touched.terms && errors.terms) || (!submitting && submitError)
                    ? 'visible'
                    : 'hidden'
                }>
                <Page.ErrorMessage>
                  {(!submitting && touched.terms && errors.terms) || (!submitting && submitError)}
                </Page.ErrorMessage>
              </Page.Animate>

              {(taxState === 'CA' || !taxState) && (
                <>
                  <Spacer space='30px' />
                  <CCPANotice />
                </>
              )}

              <div
                css={css`
                  text-align: center;
                  margin-top: 30px;
                `}>
                <Button
                  id='reaccept-terms-submit-button'
                  label='Ready To Plan'
                  primary
                  disabled={submitting || !!errors.terms}
                />
              </div>
            </form>
          )}
        />
      </Page>
    )
  }
}

export default inject('onboarding', 'store')(observer(ReacceptTerms))
