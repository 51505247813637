/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { flow } from 'mobx'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import * as yup from 'yup'
import _ from 'lodash'
import { PoseGroup } from 'react-pose'

import { Page } from '../elements'
import { TextInput, YearPicker, AddButton, Button } from '../../../components'

const schema = {
  name: yup
    .string()
    .required('Name required; must be from 1 to 20 characters')
    .min(1, 'Name required; must be from 1 to 20 characters')
    .max(20, 'Name required; must be from 1 to 20 characters'),
  birthDate: yup.string().required('Birthdate is invalid'),
}

const CloseIconStyles = styled.svg`
  fill: rgb(182, 192, 196);
  width: 28px;
  height: 28px;
  cursor: pointer;
  transition: fill 0.3s;
  &:hover {
    fill: black;
  }
  &:active {
    fill: rgb(4, 149, 191);
  }
`
const CloseIcon = props => (
  <CloseIconStyles viewBox='0 0 24 24' {...props}>
    <title>Remove</title>
    <path d='M21.6 12a9.6 9.6 0 0 0-19.2 0M0 12a12 12 0 0 1 24 0M2.4 12a9.6 9.6 0 0 0 19.2 0m2.4 0a12 12 0 0 1-24 0m10.73.15l-3.44 3.44L8.7 17l3.44-3.44L15.58 17 17 15.59l-3.44-3.44L17 8.71l-1.42-1.42-3.44 3.44L8.7 7.29 7.29 8.71z' />
  </CloseIconStyles>
)

const DependentFieldStyles = styled.div`
  display: flex;
  margin: 28px 0;
  & > div:first-of-type {
    margin-top: 14px;
    min-width: 48px;
    text-align: center;
    @media (max-width: 800px) {
      margin-top: 31px;
    }
  }
  & > div:nth-of-type(2) {
    width: 100%;
  }
  @media (max-width: 800px) {
    margin: 0;
  }
`

class DependentsInfo extends Component {
  state = { dependents: getSnapshot(this.props.onboarding.dependents) }

  onSubmit = flow(function* (values) {
    try {
      const { dependents, addDependent, updateDependent, deleteDependent } = this.props.onboarding
      const currentDependents = getSnapshot(dependents)

      if (_.isEqual(currentDependents, values.dependents)) {
        return this.props.history.push('/welcome/social-security-income')
      }

      const dependentsToUpdate = _.filter(
        values.dependents,
        dependent => typeof dependent.id === 'number'
      )

      const dependentsToAdd = _.filter(
        values.dependents,
        dependent => typeof dependent.id === 'string' && dependent.id.startsWith('add')
      )

      const dependentsToDelete = _.filter(
        currentDependents,
        existingDependent =>
          !_.find(dependentsToUpdate, dependent => existingDependent.id === dependent.id)
      )

      for (let i = 0; i < dependentsToUpdate.length; i++) {
        const { name, birthDate, id } = dependentsToUpdate[i]
        yield updateDependent({ name, birthDate, id })
      }

      for (let i = 0; i < dependentsToAdd.length; i++) {
        const { name, birthDate } = dependentsToAdd[i]
        yield addDependent({ name, birthDate })
      }

      for (let i = 0; i < dependentsToDelete.length; i++) {
        const { id } = dependentsToDelete[i]
        yield deleteDependent({ id })
      }

      this.props.history.push('/welcome/social-security-income')
    } catch (err) {
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  })

  validateName = name =>
    schema.name
      .validate(name)
      .then(valid => {})
      .catch(err => err.message)

  validateBirthDate = birthDate =>
    schema.birthDate
      .validate(birthDate)
      .then(valid => {})
      .catch(err => err.message)

  initialValues = () => {
    const { dependents } = this.state
    return { dependents }
  }

  render() {
    return (
      <Page>
        <Page.Heading
          title='Tell us about your dependents'
          subTitle='Add only those dependents you can claim for tax purposes'
        />
        <Form
          onSubmit={this.onSubmit.bind(this)}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={this.initialValues()}
          subscription={{ submitting: true, submitError: true }}
          render={({ handleSubmit, form, submitting, submitError, values }) => (
            <Page.Form>
              <Page.AnimateGroup>
                <Page.AnimateItem>
                  <FieldArray name='dependents' subscription={{ value: true }}>
                    {({ fields }) => (
                      <PoseGroup>
                        {fields.map((name, index) => (
                          <Page.AnimateItem key={fields.value[index].id}>
                            <DependentFieldStyles>
                              <div>
                                <CloseIcon
                                  id={'remove-dependent-' + index + '-button'}
                                  onClick={() => fields.remove(index)}
                                />
                              </div>
                              <div>
                                <Page.Field
                                  label="Dependent's first name"
                                  css={css`
                                    margin: 0;
                                  `}>
                                  <Field
                                    name={`${name}.name`}
                                    validate={this.validateName}
                                    subscription={{ value: true, touched: true, error: true }}
                                    render={({ input, meta }) => (
                                      <TextInput
                                        id={'dependent-' + index + '-first-name-field'}
                                        error={meta.error}
                                        maxLength={21}
                                        name={input.name}
                                        onFocus={input.onFocus}
                                        onBlur={input.onBlur}
                                        onChange={(name, value) => input.onChange(value)}
                                        placeholder='name'
                                        showError={meta.touched}
                                        value={input.value}
                                        width='340px'
                                        mobileWidth='220px'
                                      />
                                    )}
                                  />
                                </Page.Field>
                                <Page.Field
                                  label='Year of birth'
                                  css={css`
                                    margin: 0;
                                  `}>
                                  <Field
                                    name={`${name}.birthDate`}
                                    validate={this.validateBirthDate}
                                    subscription={{ value: true, touched: true, error: true }}
                                    render={({ input, meta }) => (
                                      <YearPicker
                                        id={'dependent-' + index + '-birth-year-field'}
                                        error={meta.error}
                                        maxYear={new Date().getUTCFullYear()}
                                        minYear={1900}
                                        name={input.name}
                                        onFocus={input.onFocus}
                                        onBlur={input.onBlur}
                                        onChange={(name, value) => input.onChange(value)}
                                        showError={meta.touched}
                                        value={input.value}
                                        reverse
                                      />
                                    )}
                                  />
                                </Page.Field>
                              </div>
                            </DependentFieldStyles>
                          </Page.AnimateItem>
                        ))}
                      </PoseGroup>
                    )}
                  </FieldArray>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                      margin-top: 42px;
                    `}>
                    <AddButton
                      id='add-dependent-button'
                      label='Add a dependent'
                      link
                      onClick={() =>
                        form.mutators.push('dependents', {
                          id: _.uniqueId('add_'),
                          name: undefined,
                          birthDate: undefined,
                        })
                      }
                      underline
                      width='50%'
                    />
                  </div>
                </Page.AnimateItem>

                <Page.Animate pose={!submitting && submitError ? 'visible' : 'hidden'}>
                  <Page.ErrorMessage>{!submitting && submitError}</Page.ErrorMessage>
                </Page.Animate>

                <Page.AnimateItem>
                  <Page.ButtonGroup>
                    <div>
                      <Button
                        id='dependents-info-back-button'
                        type='button'
                        onClick={this.props.history.goBack}
                        secondary
                        label='Back'
                        disabled={submitting}
                        width='140px'
                      />
                    </div>
                    <div>
                      <Button
                        id='dependents-info-submit-button'
                        type='button'
                        onClick={handleSubmit}
                        primary
                        label='Next'
                        disabled={submitting}
                        width='140px'
                      />
                    </div>
                  </Page.ButtonGroup>
                </Page.AnimateItem>
              </Page.AnimateGroup>
            </Page.Form>
          )}
        />
      </Page>
    )
  }
}

export default inject('onboarding')(observer(DependentsInfo))
