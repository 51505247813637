/* eslint-disable react/jsx-handler-names */
import { Component } from 'react'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'

import { Card } from '../elements'
import { DeleteModal, SVGWrapper } from '../../../components'
import { CircleX } from '../../../assets/icons'
import { numberToDollars } from '../../../utils'

class GoalCard extends Component {
  static propTypes = {
    goal: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  state = {
    deleteStatus: 'done',
    deleteActive: false,
  }

  toggleDeleteModal = e => {
    e.stopPropagation()

    this.setState({
      deleteActive: !this.state.deleteActive,
      deleteStatus: 'done',
    })
  }

  handleGoalClick = () => {
    const { history, goal } = this.props

    history.push('/goals/' + goal.id)
  }

  handleEditClick = e => {
    const { history, goal } = this.props

    e.stopPropagation()
    history.push('/goals/' + goal.id + '/edit')
  }

  async deleteGoal() {
    const { history, goal, store } = this.props
    this.setState({ deleteStatus: 'loading' })
    try {
      await store.deleteGoal(goal)
      history.push('/goals')
    } catch (err) {
      console.error(err)
      this.setState({ deleteStatus: 'error' })
    }
  }

  render() {
    const { earliestYear, latestYear, totalRemaining, isPast, name, type, id } = this.props.goal

    return (
      <div id={'goal-' + id}>
        <Card onClick={this.handleGoalClick}>
          <Card.RowHeader>
            <Card.DeleteSVGContainer>
              <SVGWrapper
                onClick={this.toggleDeleteModal}
                svg={CircleX}
                fill='#b6c0c4'
                hoverFill='#053240'
                activeFill='#0495bf'
                size='large'
              />
            </Card.DeleteSVGContainer>
            {!isPast && <Card.Edit onClick={this.handleEditClick}>Edit</Card.Edit>}
          </Card.RowHeader>
          <Card.Row>
            <Card.NameTextBox>
              <Card.NameText>{name}</Card.NameText>
            </Card.NameTextBox>
            <Card.Label>Name</Card.Label>
          </Card.Row>
          <Card.Row>
            <Card.Text>{earliestYear + (type ? '' : ' - ' + latestYear)}</Card.Text>
            <Card.Label>When</Card.Label>
          </Card.Row>
          <Card.Row>
            <Card.Amount>{numberToDollars(totalRemaining, true)}</Card.Amount>
            <Card.Label>Future total needed</Card.Label>
          </Card.Row>
          {isPast && <Card.GrayOverlay />}
        </Card>
        <DeleteModal
          handleConfirmation={() => this.deleteGoal()}
          isModalOpen={this.state.deleteActive}
          title='Delete goal'
          toggleModal={this.toggleDeleteModal}
          loading={this.state.deleteStatus === 'loading'}
          error={this.state.deleteStatus === 'error'}
        />
      </div>
    )
  }
}

export default inject('store')(GoalCard)
