const home = `
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="-icons/overall-strategy/normal"
        fill-rule="nonzero"
        class="icon-color"
      >
        <g id="home-copy" transform="translate(0.000000, 1.000000)">
          <path
            d="M14.5643477,6.37709466 L8.55235459,0.429994667 C7.97344452,
              -0.143023889 7.02711447,-0.143609827 6.44773053,0.429906777
              L0.435678206,6.37712396 C-0.14536424,6.95190033 -0.145245775,
              7.88219488 0.436152067,8.45685407 C0.890525543,8.90597593
              1.48954838,8.99122998 2.01020317,8.77443274 L2.01020317,
              14.5109757 C2.01020317,14.7810641 2.23155549,15 2.50455866,
              15 C2.74646471,15 11.3035324,15 12.443642,15 C12.7166748,15
              12.9347101,14.7810348 12.9347101,14.5109757 L12.9347101,
              8.77446203 C13.4552464,8.99122998 14.10953,8.90594663
              14.5638442,8.45682477 C15.1451532,7.88228278 15.1454494,
              6.95198822 14.5643477,6.37709466 Z M6.42857143,13.9285714
              L5.35714286,13.9285714 L5.35714286,9.64285714 L6.42857143,
              9.64285714 L6.42857143,13.9285714 Z M11.7857143,13.9211342
              L7.41411185,13.9229261 L7.41411185,9.12487829 C7.41411185,
              8.84878152 7.2054521,8.62497513 6.94415976,8.62497513
              L4.66789066,8.6306504 C4.40659833,8.6306504 4.19479253,8.85448674
               4.19479253,9.13055356 L4.19479253,13.9285714 L3.21428571,
               13.9285714 L3.21428571,7.71946384 C4.52185278,6.33547599
               6.62369552,4.16991776 7.52723586,3.21428571 C8.46229341,
               4.20259176 10.5919719,6.45613918 11.7857143,7.71949379
               L11.7857143,13.9211342 Z M13.7843977,7.36511028 C13.5931309,
               7.54268206 13.2848701,7.54648562 13.0922875,7.36731091
               C12.3325133,6.66017529 8.60095518,3.18771775 7.84416352,
               2.48560826 C7.65359858,2.30877002 7.34469447,2.30877002
               7.15412953,2.48585277 C6.37262904,3.21206051 2.69314955,
               6.63624004 1.9077015,7.36728374 C1.71874482,7.54314392
               1.41045478,7.54596942 1.21556214,7.36511028 C1.02344742,
               7.18672344 1.02335969,6.89795195 1.21544518,6.71948361
               L7.1514101,1.20427134 C7.34235517,1.02680823 7.65836489,
               1.02746027 7.84863742,1.20435285 L13.7845731,6.71948361
               C13.9766001,6.89795195 13.9766001,7.18672344 13.7843977,
               7.36511028 Z M8.57141493,9.17859871 L9.78572793,9.17859871
               C10.3380051,9.17859871 10.7857143,9.62630786 10.7857143,
               10.1785851 C10.7857143,10.7308623 10.3380051,11.1785714
               9.78572793,11.1785714 L8.57141493,11.1785714 C8.01913771,
               11.1785714 7.57142857,10.7308623 7.57142857,10.1785851
               C7.57142857,9.62630786 8.01913771,9.17859871 8.57141493,
               9.17859871 L8.57141493,9.17859871 Z M8.57142857,9.64285714
               L8.57142857,10.7142857 L9.64285714,10.7142857 L9.64285714,
               9.64285714 L8.57142857,9.64285714 Z"
              id="Combined-Shape"
            >
            </path>
        </g>
      </g>
    </g>
  </svg>
`

export default home
