/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form, Field, FormSpy } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-calculate'
import * as yup from 'yup'
import dayjs from 'dayjs'
import _ from 'lodash'

import {
  TextErrorField,
  Label,
  TextInput,
  ButtonInput,
  DatePicker,
  CurrencyInput,
  NumberInput,
  AddButton,
  SVGWrapper,
  Row,
  InputBox,
  YearPicker,
  Spacer,
  Dropdown as DropdownElement,
} from '../../../components'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { Close } from '../../../assets/icons'
import { reduceValidationError } from '../../../utils'
import { Page } from './style'

const filterValuesForPatch = (values, current) => {
  return _.pickBy(values, (value, key) => value !== current[key])
}

const calculator = createDecorator({
  field: 'maritalStatus',
  updates: {
    includeSpouse: (maritalStatus, allValues) =>
      maritalStatus === false ? false : allValues.includeSpouse,
  },
})

const schema = yup.object().shape({
  maritalStatus: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  includeSpouse: yup
    .boolean()
    .nullable()
    .when('maritalStatus', {
      is: true,
      then: yup.boolean().oneOf([true, false], 'Select one'),
    }),
  firstName: yup
    .string()
    .nullable()
    .trim()
    .when('includeSpouse', (includeSpouse, schema) =>
      includeSpouse
        ? schema
            .required('Name required; must be from 1 to 20 characters')
            .min(1, 'Name required; must be from 1 to 20 characters')
            .max(20, 'Name required; must be from 1 to 20 characters')
        : schema
    ),
  gender: yup
    .string()
    .nullable()
    .when('includeSpouse', (includeSpouse, schema) =>
      includeSpouse ? schema.oneOf(['M', 'F'], 'Select one') : schema
    ),
  birthDate: yup
    .date()
    .nullable()
    .when('includeSpouse', (includeSpouse, schema) =>
      includeSpouse ? schema.required('Birthdate is invalid') : schema
    ),
  retirementAge: yup
    .number()
    .nullable()
    .when(['includeSpouse', 'birthDate'], (includeSpouse, birthDate, schema) => {
      if (!includeSpouse) {
        return schema
      }
      const age = birthDate && dayjs().year() - dayjs(birthDate).year()
      const maxRetAge = Math.min(99, age + 55)
      const validAgesMessage = `Valid ages: 20 - ${maxRetAge}`
      return schema
        .required(validAgesMessage)
        .min(20, validAgesMessage)
        .max(maxRetAge, validAgesMessage)
    }),
  annualIncome: yup
    .number()
    .nullable()
    .when('includeSpouse', (includeSpouse, schema) =>
      includeSpouse
        ? schema
            .min(0, 'Salary must be between $0 and $10,000,000')
            .max(9999999, 'Salary must be between $0 and $10,000,000')
        : schema
    ),
  otherIncome: yup
    .number()
    .nullable()
    .when('includeSpouse', (includeSpouse, schema) =>
      includeSpouse
        ? schema
            .min(0, 'Amount must be between $0 and $10,000,000')
            .max(9999999, 'Amount must be between $0 and $10,000,000')
        : schema
    ),
  planningWorkPartTime: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  durationStartingAge: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Duration: starting age is a required field')
            .test(
              'is-not-less-than-retirement-age',
              'Starting age for part-time work during retirement may not begin before the retirement age',
              function (value) {
                return this.parent.retirementAge <= value
              }
            )
        : schema
    ),
  durationStoppingAge: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Duration: stopping age is a required field')
            .test(
              'is-not-less-than-starting-age',
              'Stopping age cannot be before starting age',
              function (value) {
                return this.parent.durationStartingAge <= value
              }
            )
        : schema
    ),
  retirementAnnualIncome: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Annual income is required')
            .min(1, 'Amount must be between $1 and $10,000,000')
            .max(9999999, 'Amount must be between $0 and $10,000,000')
        : schema
    ),
})

const depSchema = {
  name: yup
    .string()
    .required('Name required; must be from 1 to 20 characters')
    .min(1, 'Name required; must be from 1 to 20 characters')
    .max(20, 'Name required; must be from 1 to 20 characters'),
  birthDate: yup.string().required('Birthdate is invalid'),
}

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

class AboutFamilyEdit extends Component {
  state = { dependents: getSnapshot(this.props.store.dependents) }

  onSubmit = async values => {
    if (values.annualIncome === null) {
      values.annualIncome = 0
    }

    if (values.otherIncome === null) {
      values.otherIncome = 0
    }

    let refreshFlag = false
    const { store, onClose } = this.props
    if (values.includeSpouse !== this.props.store.person.includeSpouse) {
      //need to refresh page if they change includeSpouse
      refreshFlag = true
    }

    const {
      maritalStatus,
      includeSpouse,
      firstName,
      gender,
      birthDate,
      annualIncome,
      otherIncome,
      retirementAge,
      dependents,
      retirementAnnualIncome,
      durationStartingAge,
      durationStoppingAge,
      planningWorkPartTime,
    } = values

    try {
      const {
        person,
        spouse,
        dependents: dependentsSnap,
        updatePerson,
        createSpouse,
        updateSpouse,
        addDependent,
        updateDependent,
        deleteDependent,
        getAccounts,
        saveSpouseRetirementStatus,
        spouseRetirementStatus,
        getSSBenefits,
        getSpouse,
      } = store

      const personPatch = filterValuesForPatch(
        {
          maritalStatus,
          includeSpouse,
        },
        getSnapshot(person)
      )
      const personPromise = _.isEmpty(personPatch) ? Promise.resolve({}) : updatePerson(personPatch)

      const spousePatch = filterValuesForPatch(
        {
          firstName: _.trim(firstName),
          gender,
          birthDate,
          annualIncome,
          otherIncome,
          retirementAge,
        },
        getSnapshot(spouse)
      )
      let spousePromise
      if (includeSpouse && !spouse.id) {
        spousePromise = await createSpouse({
          firstName: _.trim(firstName),
          gender,
          birthDate,
          annualIncome,
          otherIncome,
          retirementAge,
        })

        spousePromise =
          !includeSpouse || _.isEmpty(spousePatch) ? Promise.resolve({}) : updateSpouse(spousePatch)

        await saveSpouseRetirementStatus({
          annualIncome: retirementAnnualIncome,
          durationStartingAge,
          durationStoppingAge,
          id: spouse.id,
          includeSsiEstimate: 0,
          planningWorkPartTime,
        })

        // if person's maritalStatus has updated fetch spouse's retirement status
        if (personPatch.maritalStatus && personPatch.includeSpouse) {
          await this.props.store.getSpouseRetirementStatus()
        }
      } else {
        spousePromise =
          !includeSpouse || _.isEmpty(spousePatch) ? Promise.resolve({}) : updateSpouse(spousePatch)
      }

      const dependentsPromises = []

      const currentDependents = getSnapshot(dependentsSnap)

      if (_.isEqual(currentDependents, dependents)) {
        // don't do anything if equal
      } else {
        const dependentsToUpdate = _.filter(
          dependents,
          dependent => typeof dependent.id === 'number'
        )

        const dependentsToAdd = _.filter(
          dependents,
          dependent => typeof dependent.id === 'string' && dependent.id.startsWith('add')
        )

        const dependentsToDelete = _.filter(
          currentDependents,
          existingDependent =>
            !_.find(dependentsToUpdate, dependent => existingDependent.id === dependent.id)
        )

        for (let i = 0; i < dependentsToUpdate.length; i++) {
          const { name, birthDate, id } = dependentsToUpdate[i]
          dependentsPromises.push(updateDependent({ name, birthDate, id }))
        }

        for (let i = 0; i < dependentsToAdd.length; i++) {
          const { name, birthDate } = dependentsToAdd[i]
          dependentsPromises.push(addDependent({ name, birthDate }))
        }

        for (let i = 0; i < dependentsToDelete.length; i++) {
          const { id } = dependentsToDelete[i]
          dependentsPromises.push(deleteDependent({ id }))
        }
      }

      await Promise.all([personPromise, spousePromise, ...dependentsPromises])

      const spouseRetirementUpdates = {
        ...spouseRetirementStatus,
        annualIncome: retirementAnnualIncome,
        durationStartingAge,
        durationStoppingAge,
        planningWorkPartTime: planningWorkPartTime ? 1 : 0,
      }

      if (!_.isEqual(spouseRetirementStatus, spouseRetirementUpdates)) {
        await saveSpouseRetirementStatus({
          ...spouseRetirementStatus,
          annualIncome: retirementAnnualIncome,
          durationStartingAge,
          durationStoppingAge,
          planningWorkPartTime,
        })
      }

      await getSSBenefits()
      await getAccounts()
      if (maritalStatus && includeSpouse) {
        await getSpouse()
      }
      onClose()
      if (refreshFlag) {
        //need to refresh so our account lists update accordingly
        window.location.reload()
      }
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  initialValues = () => {
    const {
      person: { maritalStatus, includeSpouse },
      spouse: { firstName, gender, birthDate, annualIncome, otherIncome, retirementAge },
      spouseRetirementStatus,
    } = this.props.store
    const { dependents } = this.state

    let initialValues = {
      maritalStatus,
      includeSpouse,
      firstName,
      gender,
      birthDate,
      annualIncome,
      otherIncome,
      retirementAge,
      dependents,
    }

    let spendingInitialValues = {}

    if (spouseRetirementStatus) {
      spendingInitialValues = {
        durationStartingAge: spouseRetirementStatus.durationStartingAge,
        durationStoppingAge: spouseRetirementStatus.durationStoppingAge,
        planningWorkPartTime: Boolean(spouseRetirementStatus.planningWorkPartTime),
        retirementAnnualIncome: spouseRetirementStatus.annualIncome,
      }
    }

    initialValues = {
      ...initialValues,
      ...spendingInitialValues,
    }

    // test for null cases
    // initialValues.firstName = null
    // initialValues.gender = null
    // initialValues.birthDate = null
    // initialValues.retirementAge = null
    // initialValues.annualIncome = null
    // initialValues.otherIncome = null
    // initialValues.planningWorkPartTime = null

    return initialValues
  }

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  validateDepName = name =>
    depSchema.name
      .validate(name)
      .then(valid => {})
      .catch(err => err.message)

  validateDepBirthDate = birthDate =>
    depSchema.birthDate
      .validate(birthDate)
      .then(valid => {})
      .catch(err => err.message)

  render() {
    const { onClose } = this.props
    const { isSpendown } = this.props.store.config
    const { retirementAge } = this.props.store.spouse

    const startingAge = () => {
      let result = 0
      if (!isNaN(retirementAge)) {
        result = retirementAge
      } else {
        result = 65
      }
      return result
    }

    return (
      <Form
        keepDirtyOnReinitialize
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={this.onSubmit}
        decorators={[calculator]}
        mutators={{
          ...arrayMutators,
        }}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ form, handleSubmit, submitting, values }) => (
          <div>
            <Row>
              <Label>Marital status</Label>
              <Field
                name='maritalStatus'
                render={({ input, meta }) => (
                  <InputBox>
                    <ButtonInput
                      isActive={input.value === false}
                      name={input.name}
                      text='Single'
                      value={false}
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                    />
                    <ButtonInput
                      isActive={input.value === true}
                      name={input.name}
                      text='Married'
                      value
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                    />
                    <TextErrorField showError={meta.touched} error={meta.error} />
                  </InputBox>
                )}
              />
            </Row>
            <div
              css={css`
                padding-top: 8px;
              `}
            />

            <FormSpy subscription={{ values: true }}>
              {({ values }) =>
                values.maritalStatus === true && (
                  <>
                    <Row>
                      <Label>Include a spouse in planning?</Label>
                      <Field
                        name='includeSpouse'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <InputBox>
                            <ButtonInput
                              isActive={input.value === true}
                              name={input.name}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              text='Yes'
                              value
                              width='126px'
                              onClick={(name, value) => input.onChange(value)}
                            />
                            <ButtonInput
                              isActive={input.value === false}
                              name={input.name}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              text='No/Not Now'
                              value={false}
                              width='126px'
                              onClick={(name, value) => input.onChange(value)}
                            />
                            <TextErrorField showError={meta.touched} error={meta.error} />
                          </InputBox>
                        )}
                      />
                    </Row>
                    <div
                      css={css`
                        padding-top: 36px;
                      `}
                    />
                  </>
                )
              }
            </FormSpy>

            <FormSpy subscription={{ values: true }}>
              {({ values }) =>
                values.maritalStatus === true &&
                values.includeSpouse === true && (
                  <>
                    <Row>
                      <Label>Spouse's first name</Label>
                      <Field
                        name='firstName'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <InputBox>
                            <TextInput
                              name={input.name}
                              value={input.value}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onChange={(name, value) => input.onChange(value)}
                              showError={meta.touched}
                              error={meta.error}
                            />
                          </InputBox>
                        )}
                      />
                    </Row>
                    <div
                      css={css`
                        padding-top: 8px;
                      `}
                    />
                    <Row>
                      <Label>Spouse's gender</Label>
                      <Field
                        name='gender'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <InputBox>
                            <ButtonInput
                              name={input.name}
                              value='F'
                              isActive={input.value === 'F'}
                              text='Female'
                              width='126px'
                              onClick={(name, value) => input.onChange(value)}
                              onBlur={input.onBlur}
                            />
                            <ButtonInput
                              name={input.name}
                              value='M'
                              isActive={input.value === 'M'}
                              text='Male'
                              width='126px'
                              onClick={(name, value) => input.onChange(value)}
                              onBlur={input.onBlur}
                            />
                            <TextErrorField showError={meta.touched} error={meta.error} />
                          </InputBox>
                        )}
                      />
                    </Row>
                    <div
                      css={css`
                        padding-top: 8px;
                      `}
                    />
                    <Row>
                      <Label>Spouse's date of birth</Label>
                      <InputBox>
                        <Field
                          name='birthDate'
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <DatePicker
                              handleChange={(name, value) => input.onChange(value)}
                              maxYear={new Date().getFullYear()}
                              minYear={1910}
                              name={input.name}
                              date={input.value}
                              showError
                              error={meta.error}
                            />
                          )}
                        />
                      </InputBox>
                    </Row>
                    <div
                      css={css`
                        padding-top: 48px;
                      `}
                    />
                    <Row>
                      <Label>Spouse's annual compensation before taxes</Label>
                      <InputBox>
                        <Field
                          name='annualIncome'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <CurrencyInput
                              name={input.name}
                              value={input.value}
                              onChange={(name, value) => input.onChange(value)}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              showError={meta.touched}
                              error={meta.error}
                            />
                          )}
                        />
                      </InputBox>
                    </Row>
                    <Row>
                      <Label>Spouse's other earned income, annually</Label>
                      <InputBox>
                        <Field
                          name='otherIncome'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <CurrencyInput
                              name={input.name}
                              value={input.value}
                              onChange={(name, value) => input.onChange(value)}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              showError={meta.touched}
                              error={meta.error}
                            />
                          )}
                        />
                      </InputBox>
                    </Row>
                    <div
                      css={css`
                        padding-top: 36px;
                      `}
                    />
                    <Page.MinSpaceInputs>
                      <Row>
                        <Label>Spouse's retirement age</Label>
                        <InputBox>
                          <Field
                            name='retirementAge'
                            format={value => (value === null ? undefined : value)}
                            parse={v => v}
                            subscription={{ value: true, touched: true, error: true }}
                            render={({ input, meta }) => (
                              <NumberInput
                                name={input.name}
                                value={input.value}
                                onChange={(name, value) => input.onChange(value)}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                showError={meta.touched}
                                error={meta.error}
                              />
                            )}
                          />
                        </InputBox>
                      </Row>
                      <div
                        css={css`
                          padding-top: 12px;
                        `}
                      />

                      <>
                        <Spacer space='6px' />
                        <Row>
                          <Label>Plan to work part-time after retiring?</Label>
                          <Field
                            name='planningWorkPartTime'
                            subscription={{ value: true, touched: true, error: true }}
                            render={({ input, meta }) => (
                              <InputBox>
                                <ButtonInput
                                  name={input.name}
                                  text='Yes'
                                  value
                                  isActive={input.value === true}
                                  onClick={(name, value) => input.onChange(value)}
                                  width='126px'
                                />
                                <ButtonInput
                                  name={input.name}
                                  text='No'
                                  value={false}
                                  isActive={input.value === false}
                                  onClick={(name, value) => input.onChange(value)}
                                  width='126px'
                                />
                                <TextErrorField showError={meta.touched} error={meta.error} />
                              </InputBox>
                            )}
                          />
                        </Row>

                        <Condition when='planningWorkPartTime' is>
                          <Spacer space='6px' />
                          <Row>
                            <Label>Duration: starting age</Label>
                            <Field
                              name='durationStartingAge'
                              subscription={{ value: true, touched: true, error: true }}
                              render={({ input, meta }) => (
                                <Dropdown
                                  startingAge={values.retirementAge || startingAge()}
                                  initialValue={input.value}
                                  input={input}
                                  meta={meta}
                                />
                              )}
                            />
                          </Row>
                        </Condition>

                        <Condition when='planningWorkPartTime' is>
                          <Spacer space='6px' />
                          <Row>
                            <Label>Duration: stopping age</Label>
                            <Field
                              name='durationStoppingAge'
                              subscription={{ value: true, touched: true, error: true }}
                              render={({ input, meta }) => (
                                <Dropdown
                                  startingAge={values.retirementAge || startingAge()}
                                  initialValue={input.value}
                                  input={input}
                                  meta={meta}
                                />
                              )}
                            />
                          </Row>
                        </Condition>

                        <Condition when='planningWorkPartTime' is>
                          <Spacer space='6px' />
                          <Row>
                            <Label>Annual income from in-retirement job</Label>
                            <Field
                              name='retirementAnnualIncome'
                              format={value => (value === null ? undefined : value)}
                              parse={v => v}
                              subscription={{ value: true, touched: true, error: true }}
                              render={({ input, meta }) => (
                                <InputBox>
                                  <CurrencyInput
                                    error={meta.error}
                                    name={input.name}
                                    placeholder='0'
                                    onFocus={input.onFocus}
                                    onBlur={input.onBlur}
                                    onChange={(name, value) => input.onChange(value)}
                                    showError={meta.touched}
                                    value={input.value}
                                    css={css`
                                      font-size: 1.125rem;
                                      line-height: 1.5rem;
                                    `}
                                  />
                                </InputBox>
                              )}
                            />
                          </Row>
                        </Condition>
                      </>
                    </Page.MinSpaceInputs>
                  </>
                )
              }
            </FormSpy>

            <div
              css={css`
                padding-top: 32px;
              `}
            />

            {!isSpendown && (
              <>
                <FieldArray name='dependents' subscription={{ value: true }}>
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <div key={fields.value[index].id}>
                        <div
                          css={css`
                            padding-top: 32px;
                          `}
                        />
                        <Row>
                          <Label>
                            <SVGWrapper
                              svg={Close}
                              size='large'
                              fill='#b6c0c4'
                              hoverFill='black'
                              activeFill='#b6c0c4'
                              onClick={() => fields.remove(index)}
                            />
                            {'\u00A0\u00A0\u00A0'} Dependent's name
                          </Label>
                          <InputBox>
                            <Field
                              name={`${name}.name`}
                              validate={this.validateDepName}
                              subscription={{ value: true, touched: true, error: true }}
                              render={({ input, meta }) => (
                                <TextInput
                                  error={meta.error}
                                  maxLength={21}
                                  name={input.name}
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                  onChange={(name, value) => input.onChange(value)}
                                  placeholder='name'
                                  showError={meta.touched}
                                  value={input.value}
                                />
                              )}
                            />
                          </InputBox>
                        </Row>
                        <Row>
                          <Label>Dependent's year of birth</Label>
                          <InputBox>
                            <Field
                              name={`${name}.birthDate`}
                              validate={this.validateDepBirthDate}
                              subscription={{ value: true, touched: true, error: true }}
                              render={({ input, meta }) => (
                                <YearPicker
                                  error={meta.error}
                                  maxYear={new Date().getUTCFullYear()}
                                  minYear={1900}
                                  name={input.name}
                                  onFocus={input.onFocus}
                                  onBlur={input.onBlur}
                                  onChange={(name, value) => input.onChange(value)}
                                  showError={meta.touched}
                                  value={input.value}
                                  reverse
                                />
                              )}
                            />
                          </InputBox>
                        </Row>
                      </div>
                    ))
                  }
                </FieldArray>
                <div
                  css={css`
                    padding-top: 32px;
                  `}
                />
                <Row>
                  <AddButton
                    label='Add a dependent'
                    link
                    onClick={() =>
                      form.mutators.push('dependents', {
                        id: _.uniqueId('add_'),
                        name: undefined,
                        birthDate: undefined,
                      })
                    }
                    underline
                    width='80%'
                  />
                </Row>
              </>
            )}
            <div
              css={css`
                padding-top: 32px;
              `}
            />
            <ButtonsBox>
              <BackButton backgroundColor='#FFFFFF' onClick={onClose} disabled={submitting}>
                Cancel
              </BackButton>
              <NextButton onClick={handleSubmit} disabled={submitting}>
                Save
              </NextButton>
            </ButtonsBox>
          </div>
        )}
      />
    )
  }
}

export default inject('store')(observer(AboutFamilyEdit))

const Dropdown = ({ initialValue, input, meta, startingAge }) => {
  const ages = () => {
    const arr = []
    for (let i = startingAge; i <= 100; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }

  return (
    <div
      css={css`
        flex: 3 1 340px;
        padding: 0 10px;

        & ul > li {
          text-align: start;
        }
      `}>
      <DropdownElement
        error={meta.error}
        name={input.name}
        onBlur={input.onBlur}
        onChange={(name, value) => input.onChange(value)}
        options={ages()}
        placeholder={initialValue ? initialValue + '' : input.value + ''}
        selected={input.value}
        showError={meta.touched}
      />
    </div>
  )
}
