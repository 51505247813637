import { Component } from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'
import BaseInput from './BaseInput'

export default class NumberInput extends Component {
  static defaultProps = {
    readonly: false,
    disabled: false,
    showError: false,
    thousandSeparator: true,
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.string,
    width: PropTypes.string,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    value: PropTypes.number,
    error: PropTypes.string,
    showError: PropTypes.bool,
    disabled: PropTypes.bool,
    allowNegative: PropTypes.bool,
    decimalScale: PropTypes.number,
    fontSize: PropTypes.string,
    thousandSeparator: PropTypes.oneOf([true, '']),
    maxLength: PropTypes.number,
  }

  handleBlur() {
    const { name, onBlur } = this.props
    if (onBlur) {
      onBlur(name)
    }
  }

  handleChange(values) {
    const { name, onChange } = this.props
    if (onChange) {
      onChange(name, values.floatValue)
    }
  }

  render() {
    const {
      allowNegative,
      decimalScale,
      disabled,
      error,
      fontSize,
      name,
      placeholder,
      readonly,
      showError,
      thousandSeparator,
      value,
      width,
      mobileWidth,
      maxLength,
    } = this.props

    return (
      <BaseInput
        error={error}
        fontSize={fontSize}
        readonly={readonly}
        showError={showError}
        width={width}
        mobileWidth={mobileWidth}>
        <NumericFormat
          allowNegative={allowNegative}
          decimalScale={decimalScale}
          disabled={disabled}
          name={name}
          onBlur={this.handleBlur.bind(this)}
          onValueChange={this.handleChange.bind(this)}
          placeholder={placeholder}
          thousandSeparator={thousandSeparator}
          value={value || (value === 0 ? 0 : undefined)}
          maxLength={maxLength}
        />
      </BaseInput>
    )
  }
}
