import { useRef } from 'react'
import { observer, inject } from 'mobx-react'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'

function AnnuityTutorial(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
    },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'annuityTutorial' })
    willMount.current = false
  }

  const page = findByName('annuityTutorial')
  return (
    <Page header={<PageHeader padding='20px 40pt 0pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.TutorialStep>Choice 1: When to start</Wrapper.TutorialStep>
        <Wrapper.TutorialGreyBox>
          <Wrapper.TutorialGreyBoxTitle>GuidedChoice recommendation:</Wrapper.TutorialGreyBoxTitle>
          <Wrapper.TutorialGreyBoxText>
            This choice depends on your needs—such as whether you want income throughout retirement
            or just “insurance” against running short late in life. Either way, you've already
            entered this decision into the GuidedChoice application.
          </Wrapper.TutorialGreyBoxText>
        </Wrapper.TutorialGreyBox>
        <Wrapper.TutorialWrapper>
          <Wrapper.TutorialTitle>Immediate</Wrapper.TutorialTitle>
          <Wrapper.TutorialText>
            Payments to you begin right away, typically within 60 days or less.
          </Wrapper.TutorialText>
          <Spacer space='5pt' />

          <Wrapper.TutorialTitle>Deferred</Wrapper.TutorialTitle>
          <Wrapper.TutorialText>
            Payments don't begin until a future starting date, such as when you retire or reach a
            given age. In the meantime, the money you've invested may continue to grow.
          </Wrapper.TutorialText>

          <Wrapper.TutorialSecondaryGreyBox>
            <Wrapper.TutorialSecondaryGreyBoxTitle>
              Surrender charges
            </Wrapper.TutorialSecondaryGreyBoxTitle>
            <Wrapper.TutorialSecondaryGreyBoxText>
              That's a fancy name for cancellation fees. If you buy a deferred annuity, then cancel
              the policy before the payments start, the insurance company may charge a hefty fee.
              However, these charges usually do not apply after seven or eight years.
            </Wrapper.TutorialSecondaryGreyBoxText>
          </Wrapper.TutorialSecondaryGreyBox>
        </Wrapper.TutorialWrapper>
        <Spacer space='10pt' />

        <Wrapper.TutorialStep>Choice 2: How much?</Wrapper.TutorialStep>
        <Wrapper.TutorialGreyBox>
          <Wrapper.TutorialGreyBoxTitle>GuidedChoice recommendation:</Wrapper.TutorialGreyBoxTitle>
          <Wrapper.TutorialGreyBoxText>
            GuidedChoice assumes that you'll choose a fixed annuity. We prefer these because,
            generally, they cost you less for the income you'll receive. While a variable annuity
            can help your retirement money keep up with inflation, we believe that other types of
            investments can do the same job at a lower total cost.
          </Wrapper.TutorialGreyBoxText>
        </Wrapper.TutorialGreyBox>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(AnnuityTutorial))
