import { observer, inject } from 'mobx-react'

import { numberToDollars } from '../../../utils/utils'
import { Spacer } from '../../../components'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { MainWrapper, Section, Label, Value, DollarSign } from './style'

function ViewPension(props) {
  const {
    handleCancel,
    store: { pensionOptions },
  } = props
  const age = Object.keys(pensionOptions)[0]
  const pension = pensionOptions[age][0]
  return (
    <MainWrapper>
      <div>Please note: Pension payments are before-tax</div>
      <Spacer space='40px' />

      <Section>
        <Label>Amount</Label>
        <Value>
          <DollarSign>$</DollarSign>
          {numberToDollars(pension.ownerPension, true)}
        </Value>
      </Section>

      <Section>
        <Label>Begins at age</Label>
        <Value>{pension.pensionStartAge}</Value>
      </Section>

      <Section>
        <Label>Payment type</Label>
        <Value>{pension.paymentType === 'M' ? 'monthly' : 'lump sum'}</Value>
      </Section>
      <Spacer space='20px' />

      <Section>
        <Label>Survivor benefit</Label>
        <Value>
          {pension.rate}
          <DollarSign>%</DollarSign>
        </Value>
      </Section>

      <Section>
        <Label>Survivor Amount</Label>
        <Value>
          <DollarSign>$</DollarSign>
          {numberToDollars(pension.amount, true)}
        </Value>
      </Section>
      <Spacer space='40px' />

      <ButtonsBox>
        <BackButton backgroundColor='#FFFFFF' onClick={handleCancel}>
          Cancel
        </BackButton>

        <NextButton onClick={handleCancel}>Done</NextButton>
      </ButtonsBox>
    </MainWrapper>
  )
}

export default inject('store')(observer(ViewPension))
