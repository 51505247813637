import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'

import { API } from '../api'

const { model, maybeNull, number, string, array } = types

const ValueModel = model({
  percent: maybeNull(number),
  dollar: maybeNull(number),
})

const AssetModel = model({
  name: maybeNull(string),
  oldValue: maybeNull(ValueModel),
  newValue: maybeNull(ValueModel),
})

const InvMixModel = model({
  name: maybeNull(string),
  assets: array(AssetModel),
})

const Breakdown = model({
  assetClassBreakdown: array(InvMixModel),
  investmentCategory: array(InvMixModel),
  investmentHoldings: array(InvMixModel),
})

const Asset = model('Asset', {
  name: maybeNull(string),
  newValue: maybeNull(ValueModel),
  oldValue: maybeNull(ValueModel),
  ticker: maybeNull(string),
})

const AssetClassBreakdown = model('AssetClassBreakdown', {
  assets: array(Asset),
  name: maybeNull(string),
  newDollars: maybeNull(number),
  newPercent: maybeNull(number),
  oldDollars: maybeNull(number),
  oldPercent: maybeNull(number),
  type: maybeNull(number),
})

const InvestmentCategory = model('InvestmentCategory', {
  assets: array(Asset),
  name: maybeNull(string),
  newDollars: maybeNull(number),
  newPercent: maybeNull(number),
  oldDollars: maybeNull(number),
  oldPercent: maybeNull(number),
  type: maybeNull(number),
})

const InvestmentHoldings = model('InvestmentHoldings', {
  assets: array(Asset),
  name: maybeNull(string),
  newDollars: maybeNull(number),
  newPercent: maybeNull(number),
  oldDollars: maybeNull(number),
  oldPercent: maybeNull(number),
  type: maybeNull(number),
})

const NewBreakdown = model('NewBreakdown', {
  assetClassBreakdown: array(AssetClassBreakdown),
  investmentCategory: array(InvestmentCategory),
  investmentHoldings: array(InvestmentHoldings),
})

function cleanBreakdownData(breakdownData) {
  // Clean Breakdown Category array data
  function cleanCategory(category) {
    if (!category) {
      return []
    }

    return category.map(cleanInvestmentMix)
  }

  // Clean the Investment Mix data
  function cleanInvestmentMix(investmentMix) {
    const { name, assets } = investmentMix
    return { name, assets: cleanAssets(assets) }
  }

  // Clean the Assets within each Investment Mix and filter out the completely null values (if Old Mix and New Mix are both null)
  function cleanAssets(assets) {
    if (!assets) {
      return []
    }

    return assets.map(cleanAsset).filter(asset => {
      return !(asset.oldValue === null && asset.newValue === null)
    })
  }

  // Clean each individual Asset by replacing $0.00 | 0% with null
  function cleanAsset(asset) {
    const { name, oldValue, newValue } = asset
    return {
      name,
      oldValue:
        oldValue !== null && oldValue.dollar === 0 && oldValue.percent === 0 ? null : oldValue,
      newValue:
        newValue !== null && newValue.dollar === 0 && newValue.percent === 0 ? null : newValue,
    }
  }

  return _.mapValues(breakdownData, cleanCategory)
}

const BreakdownStore = model({
  breakdown: maybeNull(Breakdown),
  allBreakdownData: types.map(NewBreakdown),
})
  .views(self => ({
    get allBreakdown() {
      return [...self.allBreakdownData].reduce((acc, [key, value]) => {
        const cleanBreakDown = cleanBreakdownData(value)

        acc.push({
          name: key,
          assetClassBreakdown: cleanBreakDown.assetClassBreakdown,
          investmentCategory: cleanBreakDown.investmentCategory,
          investmentHoldings: cleanBreakDown.investmentHoldings,
        })
        return acc
      }, [])
    },
  }))
  .actions(self => ({
    afterAttach: flow(function* () {
      yield self.fetchBreakdown()
    }),
    getBreakdown: flow(function* (caseId) {
      const breakdown = yield API.get('breakdown/institutional', { params: { caseId } })
      self.breakdown = cleanBreakdownData(breakdown.data)
    }),
    getAllBreakdown: flow(function* (caseId) {
      const allBreakdown = yield API.get('breakdown/all', { params: { caseId } })
      self.allBreakdownData = allBreakdown.data || {}
      return allBreakdown.data
    }),
  }))

export default BreakdownStore
