import styled from '@emotion/styled'

const Page = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 24px;
`

Page.LightText = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 13px;
  max-width: 475px;
`

Page.DistributionTypeButton = styled.button`
  height: 45px;
  background-color: white;
  color: ${p => p.theme.lightestGray};
  border: 1px solid ${p => p.theme.lightestGray};
  flex: 1;
`
Page.DistributionTypeButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 45px;
  width: ${p => (p.width ? p.width : '450px')};
  justify-content: flex-start;
  max-width: 475px;
  margin-bottom: 9px;
`
Page.Row = styled.div`
  display: flex;
`
Page.PaymentRow = styled.div`
  display: flex;
  align-items: baseline;
`
Page.AnnualRateIncreaseWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

export { Page }
