/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { Field, useField } from 'react-final-form'

import SaveScenario from '../SaveScenario'
import { Spacer } from '../../../../components'
import { Page } from '../style'

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

function CanYouCoverExpenses(props) {
  const { modifiedMonthlyIncome } = props
  return (
    <Page.CanYouCoverCard>
      <Page.CanYouCoverCardTitle>
        Can you cover basic living expenses with this monthly income?
      </Page.CanYouCoverCardTitle>

      <Spacer space='5px' />

      <CanCoverExpensesActions />

      {
        <Condition when='canCoverExpenses' is>
          <>
            <Spacer space='15px' />
            <Page.ThatsGreatText>That's great!</Page.ThatsGreatText>
            <Spacer space='6px' />
            <SaveScenario modifiedMonthlyIncome={modifiedMonthlyIncome} />
          </>
        </Condition>
      }
      {
        <Condition when='canCoverExpenses' is={false}>
          <Spacer space='15px' />
          <Page.CanCoverExpensesBody>
            Don't give up! Let's see if you can take other actions that will help you get where you
            want to be.
          </Page.CanCoverExpensesBody>
        </Condition>
      }
    </Page.CanYouCoverCard>
  )
}

export default CanYouCoverExpenses

const CanCoverExpensesActions = () => {
  const { input } = useField('canCoverExpenses', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <div>
      <Page.Button
        isActive={input.value === true}
        name={input.name}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        onClick={(name, value) => input.onChange(value)}
        text='Yes'
        value
        width='122px'
      />
      <Page.Button
        isActive={input.value === false}
        name={input.name}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        onClick={(name, value) => input.onChange(value)}
        text='No'
        value={false}
        width='122px'
      />
    </div>
  )
}
