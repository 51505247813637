import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { numberToDollars } from '../../../utils'
import { Slider, NumberInput, TextErrorField } from '../../../components'

const Amount = styled.span`
  font-size: 1rem;
  color: ${p => p.theme.darkestGray};

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
const FieldContainer = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`
const InputBox = styled.div`
  flex: 0 1 300px;
  @media (max-width: 720px) {
    flex: 0 1 100%;
  }
`
const ReadOnlyInput = styled.span`
  font-size: 1.125rem;
  color: ${p => p.theme.mediumGray};
  padding-right: 12px;
`
const SliderBox = styled.div`
  flex: 1 1 400px;
  @media (max-width: 720px) {
    flex: 0 1 100%;
    margin: 12px -12px;
  }
`
const PercentText = styled.span`
  font-size: 0.875rem;
  color: ${p => p.theme.lightestGray};
`
const Percent = styled.span`
  font-size: 1.25rem;
`
const InputLabel = styled.div`
  font-size: 0.875rem;
  color: ${p => p.theme.lightestGray};
  padding-top: 6px;
`
const InputContainer = styled.div`
  ${p =>
    p.editable &&
    css`
      display: flex;
      align-items: baseline;
      & > div:nth-of-type(1) {
        margin-right: 4px;
      }
    `};
  ${p =>
    p.invalid &&
    css`
      & span {
        color: red;
      }
    `};
`

class SliderInputField extends Component {
  render() {
    const {
      inputValue,
      onChange,
      inputOnChange,
      percentValue,
      store,
      readonly,
      minPercentValue,
      maxPercentValue,
      step,
    } = this.props

    // const { estAftTaxIncomeAtHundredPercent } = store.baseCase
    // cannot separate `estAftTax...` from `baseCase` due to value's observable nature
    // have to separate as a areference to call when needed
    const bc = store.baseCase

    return (
      <FieldContainer>
        <InputBox>
          <InputContainer editable={!readonly} invalid={inputValue < minPercentValue}>
            {!readonly && (
              <NumberInput
                name='input'
                width='50px'
                value={inputValue}
                onChange={inputOnChange}
                allowNegative={false}
                decimalScale={0}
                fontSize='1.25rem'
              />
            )}
            {readonly && <ReadOnlyInput>${inputValue}</ReadOnlyInput>}
            <PercentText>
              <Percent>%</Percent> of current income
            </PercentText>
          </InputContainer>
          <TextErrorField
            error='Please plan for an income replacement goal of at least 5%'
            showError={inputValue < minPercentValue}
          />
          <InputLabel>
            (Approximately{' '}
            <Amount>
              {numberToDollars(
                ((percentValue / 100) * bc.estAftTaxIncomeAtHundredPercent) / 12,
                true
              )}
            </Amount>
            /month or{' '}
            <Amount>
              {numberToDollars((percentValue / 100) * bc.estAftTaxIncomeAtHundredPercent, true)}
            </Amount>
            /year in today's after-tax dollars)
          </InputLabel>
        </InputBox>
        {!readonly && (
          <SliderBox>
            <Slider
              value={
                minPercentValue <= percentValue <= maxPercentValue ? percentValue : minPercentValue
              }
              onChange={onChange}
              step={step || 1}
              min={minPercentValue}
              max={maxPercentValue}
            />
          </SliderBox>
        )}
      </FieldContainer>
    )
  }
}

export default inject('store')(observer(SliderInputField))
