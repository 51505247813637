import { useRef } from 'react'
import { observer, inject } from 'mobx-react'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'

function DisclosureFirstPage(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
    },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'disclosureFirstPage' })
    willMount.current = false
  }

  const page = findByName('disclosureFirstPage')
  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.DisclosureFirstPageTitle>The fine print</Wrapper.DisclosureFirstPageTitle>
        <Spacer space='10pt' />
        <Wrapper.DisclosureFirstTitle>Disclosures</Wrapper.DisclosureFirstTitle>
        <Spacer space='5pt' />

        <Wrapper.DisclosureFirstParagraphTitle>
          <b>Required Minimum Distributions:</b>
        </Wrapper.DisclosureFirstParagraphTitle>
        <Wrapper.DisclosureFirstParagraph>
          Required minimum withdrawals (RMDs) must be withdrawn individually from each plan.* The
          estimated RMD amount shown on the Total Withdrawal page is a total from all accounts, so
          to obtain the precise RMD amount for each account, please consult each account's
          recordkeeper.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='10pt' />
        <Wrapper.DisclosureFirstParagraph>
          Your actual RMD amounts depend on your accounts' respective year-end values. At the time
          of this advice, we might not know those precise year-end values, so we estimate our RMD
          amount based on the account values we have. Please check with your recordkeeper(s) for
          your actual RMDs for each separate account.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='10pt' />
        <Wrapper.DisclosureFirstParagraph>
          *Except for IRAs, which permit you to withdraw the entire RMD across several IRAs from a
          single IRA.
        </Wrapper.DisclosureFirstParagraph>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(DisclosureFirstPage))
