import { css } from '@emotion/react'
import { Field, Form } from 'react-final-form'
import * as yup from 'yup'
import _ from 'lodash'

import Page from './style'
import { Spacer, SVGWrapper, Checkbox, Loading } from '../../../../components'
import { SmileyFace } from '../../../../assets/icons'
import { reduceValidationError } from '../../../../utils'
import { useEffect, useState } from 'react'
import { API, API_URL, AUTH } from '../../../../api'

const price = 25
const totalPrice = 299

const nextYear = new Date().getFullYear() + 1
const todaysDate = new Date().getDate()
const currentMonth = new Date().getMonth() + 1
const oneYearFromToday = `${currentMonth}/${todaysDate}/${nextYear}`

const schema = yup.object().shape({
  terms: yup
    .boolean()
    .equals(
      [true],
      'Please check the box above to confirm your agreement to the terms and conditions'
    ),
})

function InitialPaymentPage({ store }) {
  const [stripeUrl, setStripeUrl] = useState(null)
  const [termsOfServiceLink, setTermsOfServiceLink] = useState(null)

  useEffect(() => {
    const getStripeUrl = async () => {
      const res = await AUTH.get('/retail/new-register/stripe/payment/url')
      const url = res?.data?.url || ''
      setStripeUrl(url)
    }
    getStripeUrl()
  }, [])

  useEffect(() => {
    const getTermsLink = async () => {
      const res = await API.get('/termsAndConditions/docs/default')
      const termsObject = _.find(res.data, doc => {
        return doc.docDescription === 'Terms of Service'
      })
      const url = `${API_URL}termsAndConditions/default?docId=${termsObject.id}`
      setTermsOfServiceLink(url)
    }
    getTermsLink()
  }, [])

  const onSubmit = () => {
    window.open(stripeUrl, '_self')
  }
  const validate = async values => {
    return await schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  return !stripeUrl ? (
    <Loading />
  ) : (
    <Page.Container
      header="Congratulations, you're taking an important step on your retirement planning path!"
      subheader="Whether you're saving for retirement, just about to retire, or already retired, we can help.">
      <Spacer space='20px'></Spacer>
      <Page.SectionWrapper>
        <Page.PriceAction>
          <div>
            <div>
              <Page.PriceText>Less than</Page.PriceText>
            </div>
            <Page.Price>{price}</Page.Price>
            <Page.PriceText>/month billed annually</Page.PriceText> <br></br>
          </div>
          <Spacer space='17px'></Spacer>
          <Page.PriceTotalBox>
            <Page.PriceTotalBoxTitle>Total through {oneYearFromToday}</Page.PriceTotalBoxTitle>
            <Spacer space='10px'></Spacer>
            <Page.PriceTotalBoxAccessInfo>
              <div>Access for 12 months</div>
              <Page.PriceTotalBoxYearlyPrice>{totalPrice}</Page.PriceTotalBoxYearlyPrice>
            </Page.PriceTotalBoxAccessInfo>
          </Page.PriceTotalBox>
          <div>
            <Spacer space='20px'></Spacer>
            <Page.TextInfoTitle>
              This subscription renews each year, automatically.
            </Page.TextInfoTitle>
            <Form
              initialValues={{ terms: false }}
              onSubmit={onSubmit}
              validate={validate}
              subscription={{ touched: true, errors: true, submitting: true, submitError: true }}
              render={({ touched, errors, submitting, handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    css={css`
                      display: flex;
                      margin: auto;
                      padding: 20px 0;
                      max-width: 1000px;
                    `}>
                    <div
                      css={css`
                        margin-top: 8px;
                        min-width: 64px;
                      `}>
                      <Field
                        name='terms'
                        subscription={{ value: true }}
                        render={({ input }) => (
                          <Checkbox
                            id='checkbox'
                            checked={input.value}
                            onClick={
                              submitting
                                ? () => {}
                                : () => {
                                    input.onFocus()
                                    input.onChange(!input.value)
                                    input.onBlur()
                                  }
                            }
                            css={css`
                              margin: auto;
                            `}
                          />
                        )}
                      />
                    </div>
                    <Field
                      name='terms'
                      subscription={{ value: true }}
                      render={({ input }) => (
                        <div
                          css={css`
                            cursor: pointer;
                          `}
                          onClick={
                            submitting
                              ? () => {}
                              : () => {
                                  input.onFocus()
                                  input.onChange(!input.value)
                                  input.onBlur()
                                }
                          }>
                          <Page.TextInfoParagraph>
                            By checking the box, you affirm that you understand your subscription to
                            the GuidedChoice Advisory Service will be renewed each year,
                            automatically, until you cancel. You will be notified by email
                            approximately 10 days prior to renewal.
                          </Page.TextInfoParagraph>
                          <Spacer space='10px'></Spacer>
                          <Page.TextInfoParagraph>
                            You may cancel at any time. No refunds will be made for time remaining
                            on your current subscription period, though you will continue to have
                            access during the remaining time. See{' '}
                            <Page.Link
                              href='/retail/payment/cancellation-policy'
                              target='_blank'
                              onClick={event => event.stopPropagation()}>
                              Cancellation and Refund Policy
                            </Page.Link>{' '}
                            for more.
                          </Page.TextInfoParagraph>
                          <Spacer space='10px'></Spacer>
                          <Page.TextInfoParagraph>
                            To prevent automatic renewal, you must discontinue your subscription
                            from within the Advisory Service at least 24 hours before the end of the
                            current subscription period.
                          </Page.TextInfoParagraph>
                          <Spacer space='10px'></Spacer>
                          <Page.TextInfoParagraph>
                            Payments are powered by Stripe; see{' '}
                            <Page.Link
                              href={termsOfServiceLink}
                              target='_blank'
                              onClick={event => event.stopPropagation()}>
                              Terms of Service
                            </Page.Link>{' '}
                            for further details.
                          </Page.TextInfoParagraph>
                        </div>
                      )}
                    />
                  </div>
                  <Page.Animate
                    pose={
                      (!submitting && touched.terms && errors.terms) || (!submitting && submitError)
                        ? 'visible'
                        : 'hidden'
                    }>
                    <Page.ErrorMessage>
                      {(!submitting && touched.terms && errors.terms) ||
                        (!submitting && submitError)}
                    </Page.ErrorMessage>
                  </Page.Animate>
                  <Page.BuyNowButton id='accept-terms-submit-button'>Buy Now</Page.BuyNowButton>
                  <Spacer space='20px'></Spacer>
                </form>
              )}
            />
          </div>
          <Page.FooterWrapper>
            <SVGWrapper
              svg={SmileyFace}
              fill='#3A4D57'
              size='xLarge'
              css={css`
                margin-right: 30px;
              `}
            />
            <Page.FooterNote>
              * Please see our{' '}
              <Page.Link href={termsOfServiceLink} target='_blank'>
                Terms of Service
              </Page.Link>{' '}
              and{' '}
              <Page.Link href='/retail/payment/cancellation-policy' target='_blank'>
                Cancellation and Refund Policy
              </Page.Link>{' '}
              for details
            </Page.FooterNote>
          </Page.FooterWrapper>
          <Spacer space='10px'></Spacer>
          <Page.CancelButton>Cancel</Page.CancelButton>
        </Page.PriceAction>
        <Page.TextInfo>
          <Page.TextInfoTitle>WHAT YOU GET</Page.TextInfoTitle>
          <Page.TextInfoParagraphCentered>
            Access to scenario planning and a downloadable, printable guide that includes specific
            actionable advice with ongoing support.
          </Page.TextInfoParagraphCentered>
          <Spacer space='20px'></Spacer>
          <Page.TextInfoTitle>WHAT YOU NEED TO DO*</Page.TextInfoTitle>
          <Page.TextInfoParagraphCentered>
            Retrieve electronically or enter manually account and other information to get your
            strategy for the current year. At the beginning of each year hereafter—and throughout
            the year if needed—refresh your data and your strategy.
          </Page.TextInfoParagraphCentered>
          <Spacer space='20px'></Spacer>
          <Page.TextInfoTitle>WHAT YOU NEED TO KNOW*</Page.TextInfoTitle>
          <Page.TextInfoParagraphCentered>
            In our promise to you, we share the same goal: Create a strategy that will make your
            money last as long as you do. To fulfill that goal and our promise, we must re-analyze
            your data each new year, taking into account the latest changes in the market, tax laws,
            and your personal situation.
            <Spacer space='10px'></Spacer>
            <b>Your subscription is renewed annually automatically.</b> You may cancel at any time.
          </Page.TextInfoParagraphCentered>
          <Spacer space='20px'></Spacer>
        </Page.TextInfo>
      </Page.SectionWrapper>
      <Spacer space='50px'></Spacer>
    </Page.Container>
  )
}

export default InitialPaymentPage
