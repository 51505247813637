/* eslint-disable import/no-anonymous-default-export */
import { css } from '@emotion/css'
import ds from '../theme'

export default {
  avatar: css`
    background-color: ${ds.get('avatar.background')};
    border-radius: 50%;
    color: ${ds.get('avatar.color')};
    display: inline-block;
    font-size: ${ds.get('avatar.fontSize')};
    height: ${ds.get('avatar.size')};
    overflow: hidden;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: ${ds.get('avatar.size')};

    ${ds.get('reset')};

    & > svg {
      fill: currentColor;
      height: ${ds.get('avatar.size')};
      width: 1em;
    }

    & > img {
      height: auto;
      max-width: 100%;
    }
  `,
  image: css`
    background-color: transparent;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  `,
  letter: css`
    display: block;
    line-height: ${ds.get('avatar.size')};
    width: 100%;
  `,
}
