const arrowRight = `
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <title>rrow-pointing-right</title>
    <path
      class="icon-color"
      id="Symbols"
      d="M21.6,12A9.6,9.6,0,0,0,2.4,12M0,12a12,12,0,0,1,24,0M2.4,12a9.6,9.6,0,
      0,0,19.2,0M24,12A12,12,0,0,1,0,12m10.15,4.13,0,2.82,6.88-6.88-7-7,0,2.91
      ,4.12,4.12Z"
    />
  </svg>
`

export default arrowRight
