import { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Slider, CurrencyInput, HelpIcon, TooltipText } from '../../../components'
import { numberToDollars } from '../../../utils'

const FieldContainer = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`

const InputBox = styled.div`
  flex: 0 1 300px;
  @media (max-width: 720px) {
    flex: 0 1 100%;
  }
`

const SliderBox = styled.div`
  flex: 1 1 400px;
  @media (max-width: 720px) {
    flex: 0 1 100%;
    margin: 16px -12px 0;
  }
`

const PercentText = styled.span`
  font-size: 0.875rem;
  color: #677b84;
`

const InputLabel = styled.div`
  font-size: 0.875rem;
  color: #677b84;
`

const ReadOnlyInput = styled.span`
  font-size: 1.125rem;
  color: ${p => p.theme.darkestGray};
  padding-right: 12px;
`

const InputContainer = styled.div`
  ${p =>
    p.editable &&
    css`
      display: flex;
      align-items: baseline;
      & > div:nth-of-type(1) {
        margin-right: 4px;
      }
    `};
  ${p =>
    !p.editable &&
    css`
      padding-bottom: 10px;
    `};
  ${p =>
    p.invalid &&
    css`
      & span {
        color: red;
      }
    `};
`

class SliderInputField extends Component {
  render() {
    const { inputLabel, percentLabel, inputValue, percentValue, onChange, readonly, displayHelp } =
      this.props
    return (
      <FieldContainer>
        <InputBox>
          <InputContainer editable={!readonly} invalid={percentValue > 100}>
            {!readonly && (
              <CurrencyInput
                name='input'
                width='88px'
                value={inputValue}
                onChange={() => {}}
                disabled
              />
            )}
            {readonly && <ReadOnlyInput>${inputValue}</ReadOnlyInput>}
            <PercentText>
              ({numberToDollars(percentValue, true)}% {percentLabel})
              {displayHelp && (
                <span style={{ marginLeft: '8px' }}>
                  <HelpIcon
                    tooltip={TooltipText.whyDoesTheTotalContributionAppearToBeMoreThanWhatsAllowed()}
                  />
                </span>
              )}
            </PercentText>
          </InputContainer>
          <InputLabel>{inputLabel}</InputLabel>
        </InputBox>
        {!readonly && (
          <SliderBox>
            <Slider
              value={percentValue > 92 ? 92 : percentValue || 0}
              onChange={onChange}
              step={1}
              min={0}
              max={92}
            />
          </SliderBox>
        )}
      </FieldContainer>
    )
  }
}

export default SliderInputField
