import { types, flow, getRoot } from 'mobx-state-tree'
import { API } from '../api'
import { groupMixByType } from '../constants'
const { model, maybeNull, number, string, array } = types

const ValueModel = model({
  percent: maybeNull(number),
  dollar: maybeNull(number),
})

const AssetModel = model({
  name: maybeNull(string),
  oldValue: maybeNull(ValueModel),
  newValue: maybeNull(ValueModel),
})

const InvMixModel = model({
  name: maybeNull(string),
  assets: maybeNull(array(AssetModel)),
  type: maybeNull(number),
})

const IndividualAccount = model({
  id: maybeNull(number),
  participantId: maybeNull(number),
  name: maybeNull(string),
  type: maybeNull(string),
  invMix: maybeNull(array(InvMixModel)),
})

function cleanPortfolioConstruction(portfolioConstruction) {
  return portfolioConstruction.map(portfolio => {
    if (!portfolio.invMix) {
      return portfolio
    }

    const { invMix, ...rest } = portfolio

    const cleanedInvestmentMix = invMix.map(invMix => {
      const { assets, ...restInvMix } = invMix

      if (!assets) {
        return invMix
      }

      const cleanedAssets = assets
        .map(asset => {
          const { name, oldValue, newValue } = asset

          return {
            name,
            oldValue:
              oldValue !== null && oldValue.dollar === 0 && oldValue.percent === 0
                ? null
                : oldValue,
            newValue:
              newValue !== null && newValue.dollar === 0 && newValue.percent === 0
                ? null
                : newValue,
          }
        })
        .filter(asset => {
          return !(asset.oldValue === null && asset.newValue === null)
        })

      return { assets: cleanedAssets, ...restInvMix }
    })

    return { invMix: cleanedInvestmentMix, ...rest }
  })
}

const PortfolioConstruction = model({
  portfolioConstruction: array(IndividualAccount),
})
  .views(self => ({
    get filteredPortfolioConstructionList() {
      const filteredPortfolioConstruction = []

      for (let i = 0; i < self.portfolioConstruction.length; i++) {
        if (self.portfolioConstruction[i].invMix === null) {
          continue
        }
        for (let j = 0; j < self.portfolioConstruction[i].invMix.length; j++) {
          if (
            self.portfolioConstruction[i].invMix[j].assets &&
            self.portfolioConstruction[i].invMix[j].assets.length
          ) {
            filteredPortfolioConstruction.push(self.portfolioConstruction[i])
            j = self.portfolioConstruction[i].invMix.length
          }
        }
      }

      return cleanPortfolioConstruction(filteredPortfolioConstruction)
    },
    get institutionalPortfolioConstruction() {
      const { activeAccount } = self

      const activeId = activeAccount ? activeAccount.id : -1

      for (let i = 0; i < self.filteredPortfolioConstructionList.length; i++) {
        if (self.filteredPortfolioConstructionList[i].id === activeId) {
          return self.filteredPortfolioConstructionList[i]
        }
      }
      return null
    },
    get institutionalHoldingData() {
      if (self.institutionalPortfolioConstruction) {
        const investmentHoldingsArray = groupMixByType(
          self.institutionalPortfolioConstruction.invMix
        )

        return investmentHoldingsArray
      }
      return undefined
    },
    get accountIdToIndexMap() {
      const map = {}
      for (let i = 0; i < self.otherIncludedAccounts.length; i++) {
        const accountId = self.otherIncludedAccounts[i].id
        map[accountId] = i
      }
      return map
    },
    get sortedPortfolioConstructionListExcludeInstitutional() {
      const sortedAccounts = new Array(self.filteredPortfolioConstructionList.length)

      for (let i = 0; i < self.filteredPortfolioConstructionList.length; i++) {
        if (self.accountIdToIndexMap[self.filteredPortfolioConstructionList[i].id] != null) {
          sortedAccounts[self.accountIdToIndexMap[self.filteredPortfolioConstructionList[i].id]] =
            self.filteredPortfolioConstructionList[i]
        }
      }

      const cleanSortedAccounts = []
      for (let i = 0; i < sortedAccounts.length; i++) {
        if (sortedAccounts[i] != null) {
          cleanSortedAccounts.push(sortedAccounts[i])
        }
      }

      const cleanSortedAccountsContainsActive = cleanSortedAccounts.filter(
        account => account.id === getRoot(self)?.active?.id
      )

      if (cleanSortedAccountsContainsActive?.length === 1) {
        return cleanSortedAccounts.filter(account => account.id !== getRoot(self)?.active?.id)
      }

      return cleanSortedAccounts
    },
  }))
  .actions(self => ({
    getPortfolioConstruction: flow(function* () {
      const portfolioConstruction = yield API.get('breakdown')
      self.portfolioConstruction = portfolioConstruction.data
    }),
  }))

export default PortfolioConstruction
