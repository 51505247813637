import { types, flow } from 'mobx-state-tree'
import dayjs from 'dayjs'

import { API } from '../api'
const { model, maybeNull, number, string, boolean } = types

const Spouse = model({
  annualIncome: maybeNull(number),
  birthDate: maybeNull(string),
  expectedMortalityAge: maybeNull(number),
  firstName: maybeNull(string),
  gender: maybeNull(string),
  id: maybeNull(number),
  includeSsi: maybeNull(boolean),
  lastName: maybeNull(string),
  middleInitial: maybeNull(string),
  otherIncome: maybeNull(number),
  retirementAge: maybeNull(number),
  retirementIncomeGoalPct: maybeNull(number),
  socSecNum: maybeNull(string),
  strbirthdate: maybeNull(string),
  totalIncome: maybeNull(number),
}).views(self => ({
  get age() {
    return self.birthDate && dayjs().year() - dayjs(self.birthDate).year()
  },
}))

const SpouseStore = model({
  spouse: maybeNull(Spouse),
}).actions(self => ({
  getSpouse: flow(function* () {
    const spouse = yield API.get('person/spouse')
    self.spouse = spouse.data || {}
  }),
  createSpouse: flow(function* (values) {
    const spouse = yield API.post('person/spouse', values)
    self.spouse = spouse.data
  }),
  updateSpouse: flow(function* (values) {
    const spouse = yield API.patch('person/spouse', values)
    self.spouse = spouse.data
  }),
}))

export default SpouseStore
