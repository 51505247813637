/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const active = 'rt-dialog-active'

const body = 'rt-dialog-body'

const button = 'rt-dialog-button'

const dialog = 'rt-dialog-dialog'

const navigation = 'rt-dialog-navigation'

const overflow = 'rt-dialog-overflow'

const overlay = 'rt-dialog-overlay'

const title = 'rt-dialog-title'

const wrapper = 'rt-dialog-wrapper'

const small = 'rt-dialog-small'

const normal = 'rt-dialog-normal'

const large = 'rt-dialog-large'

const fullscreen = 'rt-dialog-fullscreen'

const _wrapper = css`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${ds.z('higher')};

  ${ds.get('reset')};
`

const _dialog = css`
  background-color: ${ds.get('dialog.colorWhite')};
  border-radius: ${ds.get('dialog.borderRadius')};
  box-shadow: ${ds.get('shadow.zdepth5')};
  display: flex;
  flex-direction: column;
  max-width: 96vw;
  opacity: 0;
  overflow: ${ds.get('dialog.overflow')};
  transform: translateY(${reduceCSSCalc(`calc(-1 * ${ds.get('dialog.translateY')})`)});
  transition: opacity ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')},
    transform ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')};
  transition-delay: ${ds.get('animation.delay')};

  &.${active} {
    opacity: 1;
    transform: translateY(0%);
  }
`

const _small = css`
  width: 30vw;

  @media screen and (max-width: ${ds.bp('smTablet')}) {
    width: 50vw;
  }

  @media screen and (max-width: ${ds.bp('xs')}) {
    width: 75vw;
  }
`

const _normal = css`
  width: 50vw;

  @media screen and (max-width: ${ds.bp('xs')}) {
    width: 96vw;
  }
`

const _large = css`
  width: 96vw;
`

const _fullscreen = css`
  width: 96vw;

  @media screen and (max-width: ${ds.bp('xs')}) {
    border-radius: 0;
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    width: 100vw;
  }
`

const _title = css`
  color: ${ds.get('dialog.colorTitle')};
  flex-grow: 0;
  font-size: ${reduceCSSCalc(`calc(2 * ${ds.get('unit')})`)};
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1;
  margin: 0 0 ${reduceCSSCalc(`calc(1.6 * ${ds.get('unit')})`)};
`

const _body = css`
  color: ${ds.get('colors.rt.textSecondary')};
  flex-grow: 2;
  padding: ${ds.get('dialog.contentPadding')};

  & p {
    font-size: ${reduceCSSCalc(`calc(1.4 * ${ds.get('unit')})`)};
    font-weight: 400;
    letter-spacing: 0;
    line-height: ${reduceCSSCalc(`calc(2.4 * ${ds.get('unit')})`)};
    margin: 0;
  }
`

const _navigation = css`
  flex-grow: 0;
  padding: ${ds.get('dialog.navigationPadding')};
  text-align: right;
`

const _button = css`
  margin-left: ${ds.get('dialog.navigationPadding')};
  min-width: 0;
  padding-left: ${ds.get('dialog.navigationPadding')};
  padding-right: ${ds.get('dialog.navigationPadding')};
`

export default {
  active,
  body: cx(body, _body),
  button: cx(button, _button),
  dialog: cx(dialog, _dialog),
  navigation: cx(navigation, _navigation),
  overflow,
  overlay,
  title: cx(title, _title),
  wrapper: cx(wrapper, _wrapper),
  small: cx(small, _small),
  normal: cx(normal, _normal),
  large: cx(large, _large),
  fullscreen: cx(fullscreen, _fullscreen),
}
