import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { Loading } from '../../../components'
import { LogoHeader } from '../elements'
import { API_URL } from '../../../api'

const TableRow = styled.div`
  display: flex;
  position: relative;
`
const PrimaryColumn = styled.div`
  flex: 0 0 52%;
`
const SecondaryColumn = styled.div`
  flex: 0 0 16%;
`

function CheckMark() {
  return (
    <div
      css={css`
        text-align: center;
      `}>
      <svg height='50' width='50'>
        <circle cx='25' cy='25' r='15' fill='#038AB7' />
      </svg>
    </div>
  )
}

function CompareItem(props) {
  const border =
    props.border &&
    css`
      &:after {
        position: absolute;
        bottom: 0;
        right: 10px;
        content: '';
        display: block;
        margin: 0 auto;
        width: 46%;
        border-bottom: 1px solid #7a8e96;
        opacity: 0.5;
      }
    `
  return (
    <TableRow
      css={css`
        ${border}
      `}>
      <PrimaryColumn
        css={css`
          display: flex;
          align-items: center;
        `}>
        <div
          css={css`
            color: #022a3a;
            font-size: 16px;
            line-height: 1.35;
            padding: 0.5rem 0;
            margin-right: 1rem;
          `}>
          {props.item}
        </div>
      </PrimaryColumn>
      <SecondaryColumn
        css={css`
          min-width: 160px;
          padding: 0 0.25rem;
          position: relative;
        `}>
        <div
          css={css`
            background-color: #e6e9ea;
            width: 100%;
            min-height: 50px;
            height: 100%;
          `}>
          {props.discontinue}
        </div>
      </SecondaryColumn>
      <SecondaryColumn
        css={css`
          min-width: 160px;
          padding: 0 0.25rem;
          position: relative;
        `}>
        <div
          css={css`
            background-color: rgba(231, 243, 249, 0.8);
            width: 100%;
            min-height: 50px;
            height: 100%;
          `}>
          {props.keep}
        </div>
      </SecondaryColumn>
      <SecondaryColumn
        css={css`
          min-width: 160px;
          padding: 0 0.25rem;
          position: relative;
        `}>
        <div
          css={css`
            background-color: rgba(79, 173, 204, 0.2);
            width: 100%;
            min-height: 50px;
            height: 100%;
          `}>
          {props.upgrade}
        </div>
      </SecondaryColumn>
    </TableRow>
  )
}

function UpgradeProduct(props) {
  // TO-DO: Check if they should be on this page, otherwise redirect them

  const [status, setStatus] = useState('idle')
  const [errorMessage, setErrorMessage] = useState('')
  const theme = useTheme()

  useEffect(() => {
    document.documentElement.style = css`
      background: url(${API_URL + theme.bgRight}) no-repeat top right fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    `.styles

    return () => {
      document.documentElement.className = ''
    }
  }, []) // eslint-disable-line

  async function handleEnrollManagedAccount() {
    try {
      setStatus('loading')
      setErrorMessage('')

      const {
        account: { acceptedCase },
        updateManagedAccountStatus,
      } = props.store

      await updateManagedAccountStatus({
        productId: 1,
        managedAccountStatus: true,
        caseId: acceptedCase,
      })
      window.location.reload()
    } catch (err) {
      console.error(err)
      const serverErrorMessage = _.get(
        err.response,
        'data.message',
        'Something went wrong with the request. Please try again later.'
      )
      setStatus('error')
      setErrorMessage(serverErrorMessage)
    }
  }

  async function handleDiscontinueTransaction() {
    try {
      setStatus('loading')
      setErrorMessage('')
      await props.store.updateManagedAccountStatus({ managedAccountStatus: false })
      props.history.push('/transaction-confirmed')
    } catch (err) {
      console.error(err)
      const serverErrorMessage = _.get(
        err.response,
        'data.message',
        'Something went wrong with the request. Please try again later.'
      )
      setStatus('error')
      setErrorMessage(serverErrorMessage)
    }
  }

  function handleCancel() {
    props.history.push('/cancel')
  }

  return (
    <div
      css={css`
        max-width: 1100px;
        margin: auto;
        padding: 2rem;
      `}>
      <LogoHeader />

      <div
        css={css`
          color: #4a606a;
          font-size: 50px;
          font-weight: 300;
          line-height: 1.35;
        `}>
        Confirm the right move for you
      </div>

      <div
        css={css`
          color: #4a606a;
          font-size: 16px;
          line-height: 1.35;
          margin: 50px 0 28px;
        `}>
        Sometimes quick and easy is best, other times, more is better, and then there are just those
        times you want to do it all yourself. Take a look and see what suits you best.
      </div>

      <div
        css={css`
          overflow-x: auto;
        `}>
        <TableRow>
          <PrimaryColumn>
            <div
              css={css`
                display: flex;
                align-items: flex-end;
                color: #022a3a;
                font-size: 36px;
                line-height: 1.35;
                margin-right: 1rem;
              `}>
              Compare features
            </div>
          </PrimaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
            `}>
            <div
              css={css`
                color: #022a3a;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.35;
                text-align: center;
                margin: 0 1rem 0.5rem;
              `}>
              <span
                css={css`
                  font-weight: bold;
                `}>
                Discontinue
              </span>{' '}
              GuidedChoice services & do it yourself
            </div>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
            `}>
            <div
              css={css`
                color: #022a3a;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.35;
                text-align: center;
                margin: 0 1rem 0.5rem;
              `}>
              <span
                css={css`
                  font-weight: bold;
                `}>
                Keep
              </span>{' '}
              your GuidedChoice Personalized Target Date+
            </div>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
            `}>
            <div
              css={css`
                color: #022a3a;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.35;
                text-align: center;
                margin: 0 1rem 0.5rem;
              `}>
              <span
                css={css`
                  font-weight: bold;
                `}>
                Upgrade
              </span>{' '}
              to a GuidedChoice Managed Account
            </div>
          </SecondaryColumn>
        </TableRow>

        <CompareItem
          item='Shows you how much to expect in retirement from your savings'
          keep={<CheckMark />}
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Invests and manages your account for you'
          keep={<CheckMark />}
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Recommends a savings rate based on the income you want during retirement'
          keep={<CheckMark />}
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Allows you to model different scenarios'
          keep={<CheckMark />}
          upgrade={<CheckMark />}
          border
        />

        <CompareItem item='Include a spouse in your planning' upgrade={<CheckMark />} border />

        <CompareItem
          item='Set other goals beyond just retirement income'
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Include other accounts outside of your employer plan(s)'
          upgrade={<CheckMark />}
          border
        />

        <CompareItem
          item='Get savings and investment advice on those accounts outside the employer plan(s)'
          upgrade={<CheckMark />}
        />

        <div
          css={css`
            color: #022a3a;
            font-size: 36px;
            line-height: 1.35;
            margin: 50px 0 8px;
          `}>
          Compare fees
        </div>

        <TableRow>
          <PrimaryColumn>
            <div
              css={css`
                color: #022a3a;
                font-size: 16px;
                line-height: 1.35;
                padding: 0.5rem 0;
                margin-right: 1rem;
              `}>
              What do these services cost annually?
            </div>
          </PrimaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: #e6e9ea;
                width: 100%;
                min-height: 50px;
                height: 100%;
                padding: 1rem 0.5rem;
                color: #4a606a;
                font-size: 16px;
                line-height: 1.35;
              `}>
              No GuidedChoice fees
            </div>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: rgba(231, 243, 249, 0.8);
                width: 100%;
                min-height: 50px;
                height: 100%;
                padding: 1rem 0.5rem;
                color: #4a606a;
                font-size: 16px;
                line-height: 1.35;
              `}>
              $1.50 per $1,000 GuidedChoice manages
            </div>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: rgba(79, 173, 204, 0.2);
                width: 100%;
                min-height: 50px;
                height: 100%;
              `}>
              <div
                css={css`
                  padding: 1rem 0.5rem;
                  color: #4a606a;
                  font-size: 16px;
                  line-height: 1.35;
                `}>
                $2.50 per $1,000 GuidedChoice manages
              </div>
              <div
                css={css`
                  padding: 0 0.5rem 1rem;
                  color: #038ab7;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 1.35;
                `}>
                Please note: Once enrolled in a GuidedChoice Managed Account, you cannot return to
                the GuidedChoice Personalized Target Date+.
              </div>
            </div>
          </SecondaryColumn>
        </TableRow>

        <div
          css={css`
            color: #022a3a;
            font-size: 36px;
            line-height: 1.35;
            margin: 50px 0 8px;
          `}>
          Make a selection
        </div>

        <TableRow>
          <PrimaryColumn>
            <div
              css={css`
                color: #022a3a;
                font-size: 16px;
                line-height: 1.35;
                padding: 0.5rem 0;
                margin-right: 1rem;
              `}>
              What happens once you've selected a service
            </div>
          </PrimaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: #e6e9ea;
                width: 100%;
                min-height: 50px;
                height: 100%;
              `}>
              <div
                css={css`
                  padding: 1rem 0.5rem;
                  color: #4a606a;
                  font-size: 16px;
                  line-height: 1.35;
                `}>
                GuidedChoice will send your plan recordkeeper notice to discontinue GuidedChoice
                services.
              </div>
              <div
                css={css`
                  padding: 0 0.5rem 1rem;
                  color: #4a606a;
                  font-size: 10px;
                  line-height: 1.35;
                `}>
                An email confirmation will be sent to you.
              </div>
            </div>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: rgba(231, 243, 249, 0.8);
                width: 100%;
                min-height: 50px;
                height: 100%;
                padding: 1rem 0.5rem;
                color: #4a606a;
                font-size: 16px;
                line-height: 1.35;
              `}>
              You don't need to do anything further. GuidedChoice services will continue
              uninterrupted.
            </div>
          </SecondaryColumn>
          <SecondaryColumn
            css={css`
              min-width: 160px;
              padding: 0 0.25rem;
              position: relative;
            `}>
            <div
              css={css`
                background-color: rgba(79, 173, 204, 0.2);
                width: 100%;
                min-height: 50px;
                height: 100%;
              `}>
              <div
                css={css`
                  padding: 1rem 0.5rem;
                  color: #4a606a;
                  font-size: 16px;
                  line-height: 1.35;
                `}>
                You will be given access into the Managed Account service where you can enter other
                personal and account information.
              </div>
              <div
                css={css`
                  padding: 0 0.5rem 1rem;
                  color: #4a606a;
                  font-size: 10px;
                  line-height: 1.35;
                `}>
                An email confirmation will be sent to you.
              </div>
            </div>
          </SecondaryColumn>
        </TableRow>

        <div
          css={css`
            margin: 1.5rem 0;
            color: crimson;
            text-align: center;
          `}>
          {errorMessage}
        </div>

        {status === 'loading' ? (
          <TableRow
            css={css`
              margin: 1rem 0 4rem;
            `}>
            <Loading />
          </TableRow>
        ) : (
          <TableRow
            css={css`
              margin: 1rem 0 4rem;
            `}>
            <PrimaryColumn
              css={css`
                display: flex;
                align-items: flex-end;
              `}>
              <div>
                <button
                  css={css`
                    height: 51px;
                    width: 78px;
                    border: 1px solid #b6c0c4;
                    border-radius: 4px;
                    background-color: #ffffff;
                    color: #4a606a;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 1.35;
                    cursor: pointer;
                  `}
                  onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </PrimaryColumn>
            <SecondaryColumn
              css={css`
                min-width: 160px;
                padding: 0 0.25rem;
                position: relative;
              `}>
              <button
                css={css`
                  width: 160px;
                  height: 98px;
                  border: 1px solid #7a8e96;
                  border-radius: 4px;
                  background-color: #ffffff;
                  color: #7a8e96;
                  font-size: 18px;
                  line-height: 1.35;
                  cursor: pointer;
                `}
                onClick={handleDiscontinueTransaction}>
                Discontinue GuidedChoice services
              </button>
            </SecondaryColumn>
            <SecondaryColumn
              css={css`
                min-width: 160px;
                padding: 0 0.25rem;
                position: relative;
              `}>
              <button
                css={css`
                  width: 160px;
                  height: 98px;
                  border: 1px solid #4a606a;
                  border-radius: 4px;
                  background-color: #4a606a;
                  color: #ffffff;
                  font-size: 18px;
                  line-height: 1.35;
                  cursor: pointer;
                `}
                onClick={handleCancel}>
                Keep: Personalized Target Date+
              </button>
            </SecondaryColumn>
            <SecondaryColumn
              css={css`
                min-width: 160px;
                padding: 0 0.25rem;
                position: relative;
              `}>
              <button
                css={css`
                  width: 160px;
                  height: 98px;
                  border: 1px solid #038ab7;
                  border-radius: 4px;
                  background-color: #038ab7;
                  color: #ffffff;
                  font-size: 18px;
                  line-height: 1.35;
                  cursor: pointer;
                `}
                onClick={handleEnrollManagedAccount}>
                Enroll: Managed Account
              </button>
            </SecondaryColumn>
          </TableRow>
        )}
      </div>
    </div>
  )
}

export default inject('store')(observer(UpgradeProduct))
