import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { observer, inject } from 'mobx-react'

import { HelpIcon, Spacer, AddButton, TooltipText, MainTitle } from '../../../components'
import { numberToDollars } from '../../../utils'
import {
  TitleDisplayName,
  MainBalance,
  BalanceGroup,
  HeaderSection,
  SectionTitle,
  SecondaryText,
  TotalBalanceText,
  BalanceText,
  DollarSign,
  AccountDashboardContainer,
  NoticeText,
} from './DashboardStyle'
import DashboardEmployerSponsoredAccount from './DashboardEmployerSponsoredAccount'
import DashboardStockOption from './DashboardStockOption'
import DashboardAccount from './DashboardAccount'
import DashboardSocialSecurity from './DashboardSocialSecurity'

const AccountsSection = ({
  title,
  text,
  tooltip,
  balance,
  children,
  managedAccountText,
  showLumpSumNotice,
}) => (
  <div>
    <SectionTitle>
      <div>{title}</div>
      <div
        css={css`
          font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
          font-weight: normal;
          font-size: 12px;
          color: #9eacb0;
        `}>
        {title === 'Employer-related accounts' && managedAccountText
          ? '*' + managedAccountText
          : ''}
      </div>
    </SectionTitle>
    <TotalBalanceText>
      {text}
      <HelpIcon tooltip={tooltip} />
      &nbsp;: <DollarSign>$</DollarSign>
      <BalanceText>{balance}</BalanceText>
    </TotalBalanceText>
    {children}
    {showLumpSumNotice && (
      <NoticeText>
        **We assume you have either invested the payout from this pension or used it for a purpose
        other than future retirement expenses. If you invested it, we assume you have updated the
        account balance where the money was invested or have added a new account.
      </NoticeText>
    )}
  </div>
)
const Dashboard = props => {
  const handleAddAccount = () => {
    const { history } = props
    history.push('/accounts/add')
  }

  const { store, history } = props
  const {
    person: { displayName },
    features,
    config,
    employerAccounts,
    employerAccountsBalance,
    otherAccounts,
    otherAccountsBalance,
    accountsBalance,
    account: { managedAccount },
    stockOptionsOverview,
    getAccounts,
    getAllPensions,
    isRequiredToReviewRetirementAccount,
    institutionalAccount,
  } = store

  const [showLumpSumNotice, setShowLumpSumNotice] = useState(false)
  const [allPensions, setAllPensions] = useState([])

  const showStockOptionsSection =
    features.stockOptionsEnabled &&
    (stockOptionsOverview.hasVested || stockOptionsOverview.hasNotVested)

  useEffect(() => {
    getAccounts()
  }, [getAccounts])

  useEffect(() => {
    const loadAllPensions = async () => {
      await getAllPensions().then(pensions => setAllPensions(pensions))
      setShowLumpSumNotice(false)
    }
    loadAllPensions()
  }, [getAllPensions, employerAccounts])

  const expectingLumpSum = pension => {
    const found = allPensions.find(
      p => p.id === pension.id && p.paymentType === 'L' && p.lumpsumreceived === 'Y'
    )
    if (found !== undefined) {
      if (!showLumpSumNotice) {
        setShowLumpSumNotice(true)
      }
      return true
    }
    return false
  }

  return (
    <div
      css={css`
        max-width: 1200px;
        margin: auto;
        padding: 1rem;
      `}>
      <AccountDashboardContainer>
        <HeaderSection>
          <MainTitle>
            <TitleDisplayName>{displayName ? `${displayName}'s` : ''}</TitleDisplayName>
            <div>Accounts</div>
          </MainTitle>
          <BalanceGroup>
            <SecondaryText>Accounts balance</SecondaryText>
            <MainBalance>
              <DollarSign>$</DollarSign>
              {numberToDollars(accountsBalance, true)}
            </MainBalance>{' '}
          </BalanceGroup>
        </HeaderSection>

        <Spacer space='20px' />

        <AccountsSection
          title='Employer-related accounts'
          text='Employer-related accounts balance '
          tooltip={TooltipText.employerRelatedAccountsBalance()}
          balance={numberToDollars(employerAccountsBalance, true)}
          managedAccountText={
            features.managedAccountAvailable &&
            managedAccount &&
            config.template.managedAccountLabel
          }
          showLumpSumNotice={showLumpSumNotice}>
          {employerAccounts
            .filter(account => account.type === 'Institutional')
            .map(account => (
              <DashboardEmployerSponsoredAccount
                history={history}
                account={account}
                key={'dashboard-account-' + account.type + '-' + account.id}
                id={'dashboard-account-' + account.type + '-' + account.id}
                displayWarning={institutionalAccount && isRequiredToReviewRetirementAccount}
              />
            ))}

          {employerAccounts
            .filter(account => account.type !== 'Institutional' && account.employerSponsoredAccount)
            .map(account => (
              <DashboardEmployerSponsoredAccount
                history={history}
                account={account}
                key={'dashboard-account-' + account.type + '-' + account.id}
                id={'dashboard-account-' + account.type + '-' + account.id}
              />
            ))}
          {showStockOptionsSection && <DashboardStockOption history={history} />}
          {employerAccounts
            .filter(
              account => account.type !== 'Institutional' && !account.employerSponsoredAccount
            )
            .map(account => {
              return expectingLumpSum(account) ? (
                <DashboardAccount
                  history={history}
                  account={account}
                  showLumpSumNotice={true}
                  key={'dashboard-account-' + account.type + '-' + account.id}
                  id={'dashboard-account-' + account.type + '-' + account.id}
                />
              ) : (
                <DashboardAccount
                  history={history}
                  account={account}
                  key={'dashboard-account-' + account.type + '-' + account.id}
                  id={'dashboard-account-' + account.type + '-' + account.id}
                />
              )
            })}
        </AccountsSection>

        <Spacer space='20px' />

        {!!otherAccounts && !!otherAccounts.length && (
          <>
            <AccountsSection
              title='Other types of accounts'
              text='Other types of accounts balance '
              tooltip={TooltipText.otherRelatedAccountsBalance()}
              balance={numberToDollars(otherAccountsBalance, true)}>
              {otherAccounts.map(account => (
                <DashboardAccount
                  history={history}
                  account={account}
                  key={'dashboard-account-' + account.type + '-' + account.id}
                  id={'dashboard-account-' + account.type + '-' + account.id}
                />
              ))}
            </AccountsSection>
            <Spacer space='20px' />
          </>
        )}

        <DashboardSocialSecurity history={history} />

        <Spacer space='12px' />

        <AddButton
          label='Add new account'
          link
          underline
          onClick={handleAddAccount}
          width='100%'
          css={css`
            @media (max-width: 800px) {
              margin: auto;
            }
          `}
        />
        <Spacer space='20px' />
      </AccountDashboardContainer>
    </div>
  )
}

export default inject('store')(observer(Dashboard))
