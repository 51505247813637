import styled from '@emotion/styled'
import { css } from '@emotion/react'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap-reverse;
  padding: 1.75rem 0;
  ${p =>
    p.shrink &&
    css`
      margin: 0 auto;
      max-width: 800px;
    `};
`

const ButtonBox = styled.div`
  width: 240px;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px;
  margin: 12px 24px 12px 0px;
`

const ButtonTopText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 1.25rem;
  user-select: none;
`

const ButtonBottomText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 0.875rem;
  user-select: none;
`

const IndentContainer = styled.div`
  padding-left: 36px;
`

export { ButtonGroup, ButtonBox, ButtonTopText, ButtonBottomText, IndentContainer }
