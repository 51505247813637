import { useRef } from 'react'
import { observer, inject } from 'mobx-react'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'

function AnnuityTutorialPage3(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
    },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'annuityTutorialPage3' })
    willMount.current = false
  }

  const page = findByName('annuityTutorialPage3')
  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.Tutorial3GreyBox>
          <Wrapper.Tutorial3GreyBoxTitle>
            GuidedChoice recommendation:
          </Wrapper.Tutorial3GreyBoxTitle>
          <Wrapper.Tutorial3GreyBoxParagraph>
            We generally recommend a life annuity for simplicity and low cost. If you wish to
            provide for a spouse, there are other ways that we believe work as well or better. For
            example, you could take the cost difference between a life annuity and a more expensive
            type and use it to buy a life insurance policy instead. Or you could just keep it
            invested in your retirement portfolio to generate income.
            <br />
            <br />
            Your decision should be based on how your needs match up with a huge range of specific
            annuity products. So we'll say it again: Before you make a decision, discuss your plans
            and your options with a professional.
          </Wrapper.Tutorial3GreyBoxParagraph>
        </Wrapper.Tutorial3GreyBox>
        <Spacer space='10pt' />
        <Wrapper.Tutorial3Wrapper>
          <Wrapper.Tutorial3Title>Life</Wrapper.Tutorial3Title>
          <Wrapper.Tutorial3Paragraph>
            This is the simplest form: you receive payments for as long as you live. When you die,
            payments stop. The cost is based on your age and life expectancy.
          </Wrapper.Tutorial3Paragraph>
          <Spacer space='10pt' />
          <Wrapper.Tutorial3Title>Joint and survivor</Wrapper.Tutorial3Title>
          <Wrapper.Tutorial3Paragraph>
            This option begins as a life annuity, but after your death keeps paying a reduced amount
            to a surviving beneficiary (generally your spouse) for as long as they live. This could
            be a good way to provide a lifetime income for a spouse. But because the cost depends on
            your ages, life expectancies, and the percentage paid to your survivor, it can be
            difficult to work out whether it represents good value for your money.
          </Wrapper.Tutorial3Paragraph>
          <Spacer space='10pt' />
          <Wrapper.Tutorial3Title>Period certain</Wrapper.Tutorial3Title>
          <Wrapper.Tutorial3Paragraph>
            This variation also adds a survivor benefit to a life annuity but with a twist: your
            beneficiary will receive 100% of your payment amount, but only if you die within a given
            period of time, and only until that period ends. This can protect against “losing” the
            money you've invested if you die soon after purchasing the annuity. But again, peace of
            mind comes at a cost.
          </Wrapper.Tutorial3Paragraph>
        </Wrapper.Tutorial3Wrapper>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(AnnuityTutorialPage3))
