import { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { FieldContainer, Title, BulletText } from '../styled'

class AdditionalSavings extends Component {
  render() {
    const { displayName } = this.props.store.person
    const { special457, special403b } = this.props.store
    const {
      client457PreTaxSaving: base457pretax,
      client457RothSaving: base457roth,
      client403bPreTaxSaving: base403bpretax,
    } = this.props.store.baseCase
    const {
      outOfPlanMonthlySavings,
      client457PreTaxSaving: case457pretax,
      client457RothSaving: case457roth,
      client403bPreTaxSaving: case403bpretax,
    } = this.props.store.acceptedCase

    return (
      <FieldContainer>
        <Title>Savings in addition to retirement & other accounts</Title>
        {special457 && (base457pretax !== case457pretax || base457roth !== case457roth) && (
          <BulletText>
            Update contribution to {displayName}
            's 457 account
          </BulletText>
        )}
        {special403b && base403bpretax !== case403bpretax && (
          <BulletText>
            Update contribution to {displayName}
            's 403(b) account
          </BulletText>
        )}
        {outOfPlanMonthlySavings > 0 && (
          <>
            <BulletText>
              Decide whether to save more in an existing account or to open a new account
            </BulletText>
            <BulletText>Make your first deposit</BulletText>
            <BulletText>
              Set up automatic ongoing deposits, if available, or reminders as needed
            </BulletText>
          </>
        )}
      </FieldContainer>
    )
  }
}

export default inject('store')(observer(AdditionalSavings))
