import * as R from 'ramda'

const range = (start = 0, stop = null, step = 1) => {
  let [_start, _stop] = [0, start]
  if (stop !== null) {
    ;[_start, _stop] = [start, stop]
  }
  const length = Math.max(Math.ceil((_stop - _start) / step), 0)
  const _range = Array(length)

  for (let idx = 0; idx < length; idx += 1, _start += step) {
    _range[idx] = _start
  }

  return _range
}

const round = (number, decimals) => {
  if (!isNaN(parseFloat(number)) && isFinite(number)) {
    const decimalPower = 10 ** decimals
    return Math.round(parseFloat(number) * decimalPower) / decimalPower
  }
  return NaN
}

const numberToDollars = (num, integer = false) => {
  if (isNaN(num) || num === null) {
    return integer ? 0 : (0).toFixed(2)
  }
  if (integer) {
    if (num < 0) {
      return (
        '-' +
        Math.abs(num)
          .toFixed(0)
          .replace(/./g, function (c, i, a) {
            return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
          })
      )
    }
    return num.toFixed(0).replace(/./g, function (c, i, a) {
      return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
    })
  }
  if (num < 0) {
    return (
      '-' +
      Math.abs(num)
        .toFixed(2)
        .replace(/./g, function (c, i, a) {
          return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
        })
    )
  }
  return num.toFixed(2).replace(/./g, function (c, i, a) {
    return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
  })
}

const numberToPercent = (num, integer = false) => {
  if (isNaN(num) || num === null) {
    return integer ? 0 : (0).toFixed(2)
  }
  num = num * 100

  if (integer) {
    return num.toFixed(0).replace(/./g, function (c, i, a) {
      return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
    })
  }
  return num.toFixed(2).replace(/./g, function (c, i, a) {
    return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
  })
}

const placeholderSymbolReplace = (stringToReplace, reverse) => {
  if (typeof stringToReplace !== 'string') {
    return ''
  }
  if (reverse) {
    return stringToReplace.replace(/&/g, '_and_').replace(/%/g, '_percent_')
  }
  return stringToReplace.replace(/_and_/g, '&').replace('_percent_', /%/g)
}

const isNilOrEmpty = R.either(R.isEmpty, R.isNil)
const notNilOrEmpty = R.complement(isNilOrEmpty)

// Build the initial contributions distribution object when given an engine case and their institutional account details
const buildInitialContributionDistribution = ({ acceptedCase, institutional }) => {
  function buildSavingsRate() {
    const mapTaxType = { preTax: 1, afterTax: 2, roth: 5 }

    const preTaxRate = acceptedCase.primary.preTaxAnnualContributions
    const preTaxAmount = acceptedCase.primary.preTaxAnnualContribDollars
    const rothRate = acceptedCase.primary.rothAnnualContributions
    const rothAmount = acceptedCase.primary.rothAnnualContribDollars
    const afterTaxRate = acceptedCase.primary.postTaxAnnualContributions
    const afterTaxAmount = acceptedCase.primary.postTaxAnnualContribDollars

    const preTaxValue = { P: preTaxRate, D: preTaxAmount }
    const rothValue = { P: rothRate, D: rothAmount }
    const afterTaxValue = { P: afterTaxRate, D: afterTaxAmount }

    return {
      [mapTaxType.preTax]: {
        taxType: mapTaxType.preTax,
        deferralType: institutional.contribMethod,
        value: preTaxValue[institutional.contribMethod],
      },

      [mapTaxType.roth]: institutional.rothContribAllowed
        ? {
            taxType: mapTaxType.roth,
            deferralType: institutional.contribMethod,
            value: rothValue[institutional.contribMethod],
          }
        : undefined,

      [mapTaxType.afterTax]: institutional.posttaxContribAllowed
        ? {
            taxType: mapTaxType.afterTax,
            deferralType: institutional.contribMethod,
            value: afterTaxValue[institutional.contribMethod],
          }
        : undefined,
    }
  }

  return { savingsRate: buildSavingsRate(), catchUp: undefined }
}

const companyStocksAt100PercentForSale = companyStocks =>
  companyStocks.map(stock => {
    return {
      ...stock,
      amountToSell: stock.totalValue,
      percentToSell: 100,
    }
  })

export {
  range,
  round,
  numberToDollars,
  numberToPercent,
  placeholderSymbolReplace,
  isNilOrEmpty,
  notNilOrEmpty,
  buildInitialContributionDistribution,
  companyStocksAt100PercentForSale,
}
