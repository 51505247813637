import styled from '@emotion/styled'

const Title = styled.div`
  color: #4a606a;
  font-size: 44px;
  margin: 56px 0 24px;
  line-height: 1.45;
`

const TabContainer = styled.div`
  border-bottom: 2px solid #7a8e96;
  display: flex;
  justify-content: center;
`

const Tab = styled.div`
  border: ${p => (p.isActive ? '2px solid #7A8E96' : null)};
  border-bottom: ${p => (p.isActive ? '1px solid #FFFFFF' : null)};
  background-color: ${p => (p.isActive ? '#FFFFFF' : 'transparent')};
  position: relative;
  top: 3px;
  padding: 6px 18px 12px 12px;
  margin-right: 6%;
  cursor: pointer;

  @media (max-width: 800px) {
    padding-right: 6px;
    margin-right: 0;
  }
`

const TabText = styled.div`
  color: ${p => (p.isActive ? p.theme.primaryColor : p.theme.lightestGray)};
  font-size: 1.125rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`

const TabSubText = styled.div`
  color: ${p => (p.isActive ? p.theme.primaryColor : p.theme.lightestGray)};
  font-size: 0.875rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`

const ContributionContainer = styled.div`
  margin: 0 auto;
  max-width: 200px;
`

const ContributionText = styled.div`
  text-align: center;
  color: ${p => p.theme.primaryColor};
  font-size: 1.125rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
`

const SubTitle = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2rem;
  padding: 8px 0;
  font-weight: 300;
`

const SubHeading = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  padding: 6px 0;
  font-weight: 300;
`

const IndentContainer = styled.div`
  padding-left: 36px;
`

export {
  Title,
  TabContainer,
  Tab,
  TabText,
  TabSubText,
  ContributionContainer,
  ContributionText,
  SubTitle,
  SubHeading,
  IndentContainer,
}
