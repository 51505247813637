import { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { MultiButtonInput } from '../../../../components'
import { LightGreyText, InlineBlockContainer } from '../../styled'

const Label = styled.div`
  display: inline-block;
  width: 250px;
`
/* eslint-disable */
const TAX_TYPES_TEXT = {
  0: 'Not Invested',
  1: 'pre-tax',
  2: 'after-tax',
  5: 'Roth',
}
/* eslint-enable */

class AddBalanceButtons extends Component {
  static displayName = 'AddBalanceButtons'
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    taxType: PropTypes.number.isRequired,
  }

  render() {
    const { onChange, taxType } = this.props

    return (
      <div>
        <Label>
          <LightGreyText>{`Add to ${TAX_TYPES_TEXT[taxType]} balance?`}</LightGreyText>
        </Label>
        <InlineBlockContainer>
          <MultiButtonInput
            name='participantId' // no idea why this has the name participantId
            value={this.props.value}
            onChange={(name, value) => onChange(name, value, taxType)}
            buttons={[
              { text: 'Yes', value: taxType },
              { text: 'No', value: false },
            ]}
          />
        </InlineBlockContainer>
      </div>
    )
  }
}

export default AddBalanceButtons
