const Bullet3nUniCoach = `
    <? xml version = "1.0" encoding = "UTF-8" ?>
    <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>1F185A9A-D801-4004-AFE0-76C5C1BC3FB3</title>
        <g id="Sign-up/Login" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="4.3B-Partner-Portal/Partner-Sign-Up" transform="translate(-679.000000, -421.000000)">
                <g id="IPM/bullet/3NickelsU-coach-2" transform="translate(679.000000, 421.000000)">
                    <circle id="Oval" stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"></circle>
                    <g id="IPM/icon/3NickelsU-coach" transform="translate(10.000000, 10.000000)">
                        <rect id="Rectangle-Copy-4" x="0" y="0" width="30" height="30"></rect>
                        <path d="M17.946624,18.7188267 C17.4741867,17.9200651 17.7725929,16.8605198 18.0814602,16.1408138 C18.867907,15.4271002 19.4803228,14.4451026 19.8224772,13.3072262 C19.9127998,13.4321447 20.0157032,13.5037981 20.1305005,13.5037981 C20.480015,13.5037981 20.7607374,12.8276834 20.7607374,11.9935161 C20.7607374,11.1615422 20.480015,10.4869549 20.1305005,10.4869549 C20.1245929,10.4869549 20.119431,10.4891482 20.1135233,10.4891482 C19.9475982,7.40210084 18.6286757,5 14.9985378,5 C11.2714238,5 10.0413716,7.40210084 9.88953846,10.4891482 C9.8821784,10.4891482 9.87550528,10.4869549 9.86663396,10.4869549 C9.52000464,10.4869549 9.23639705,11.1615422 9.23639705,11.9935161 C9.23639705,12.8276834 9.52000464,13.5037981 9.86663396,13.5037981 C9.9799396,13.5037981 10.0857871,13.4306369 10.1791322,13.305033 C10.5212081,14.4451026 11.1337024,15.4271002 11.9215819,16.1408138 C12.231882,16.8605002 12.5296013,17.9200651 12.0556527,18.7188267 C12.0556527,18.7188267 4,21.6351906 4,25 L26,25 C26,21.6351906 17.946624,18.7188267 17.946624,18.7188267 Z" id="Path" fill="#02769D" fill-rule="nonzero"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
`

export default Bullet3nUniCoach
