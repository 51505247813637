/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useForm, useField, useFormState } from 'react-final-form'
import _ from 'lodash'
import { API } from '../../../api'
import { MultiButtonInput, PercentInput, CurrencyInput, TooltipText } from '../../../components'
import { numberToDollars } from '../../../utils'

function CatchupContribStatusField() {
  const { input, meta } = useField('catchupContribStatus', {
    subscription: { value: true, error: true, touched: true },
  })

  return (
    <MultiButtonInput
      name={input.name}
      value={input.value}
      label='Do you want any portion of your contributions to go towards catch-up?'
      buttons={[
        { text: 'Yes', value: 'Y' },
        { text: 'No', value: 'N' },
      ]}
      tooltip={TooltipText.catchupContributions()}
      onChange={(name, value) => input.onChange(value)}
      onBlur={input.onBlur}
      error={meta.error}
      showError={meta.touched}
    />
  )
}

function CatchupPreTaxField() {
  const { values } = useFormState({ subscription: { values: true } })
  const { input: percentInput, meta: percentMeta } = useField('catchupPreTaxRate', {
    parse: v => v,
    format: v => v,
  })
  const { input: amountInput, meta: amountMeta } = useField('catchupPreTaxAmount', {
    parse: v => v,
    format: value => (value === null ? undefined : value),
  })

  if (values.contribMethod === 'P') {
    return (
      <PercentInput
        name={percentInput.name}
        value={percentInput.value === '' ? null : percentInput.value}
        onChange={(name, value) => percentInput.onChange(value)}
        onBlur={percentInput.onBlur}
        error={percentMeta.error}
        showError={percentMeta.touched}
        width='100px'
      />
    )
  }

  if (values.contribMethod === 'D') {
    return (
      <CurrencyInput
        name={amountInput.name}
        value={amountInput.value}
        placeholder='0'
        onChange={(name, value) => amountInput.onChange(value)}
        onBlur={amountInput.onBlur}
        error={amountMeta.error}
        showError={amountMeta.touched}
        decimalScale={0}
        width='125px'
      />
    )
  }

  return null
}

function CatchupRothField() {
  const { values } = useFormState({ subscription: { values: true } })
  const { input: percentInput, meta: percentMeta } = useField('catchupRothRate', {
    parse: v => v,
    format: v => v,
  })
  const { input: amountInput, meta: amountMeta } = useField('catchupRothAmount', {
    parse: v => v,
    format: value => (value === null ? undefined : value),
  })

  if (values.contribMethod === 'P') {
    return (
      <PercentInput
        name={percentInput.name}
        value={percentInput.value === '' ? null : percentInput.value}
        onChange={(name, value) => percentInput.onChange(value)}
        onBlur={percentInput.onBlur}
        error={percentMeta.error}
        showError={percentMeta.touched}
        width='100px'
      />
    )
  }

  if (values.contribMethod === 'D') {
    return (
      <CurrencyInput
        name={amountInput.name}
        value={amountInput.value}
        placeholder='0'
        onChange={(name, value) => amountInput.onChange(value)}
        onBlur={amountInput.onBlur}
        error={amountMeta.error}
        showError={amountMeta.touched}
        decimalScale={0}
        width='125px'
      />
    )
  }

  return null
}

function CatchupAfterTaxField() {
  const { values } = useFormState({ subscription: { values: true } })
  const { input: percentInput, meta: percentMeta } = useField('catchupAfterTaxRate', {
    parse: v => v,
    format: v => v,
  })
  const { input: amountInput, meta: amountMeta } = useField('catchupAfterTaxAmount', {
    parse: v => v,
    format: value => (value === null ? undefined : value),
  })

  if (values.contribMethod === 'P') {
    return (
      <PercentInput
        name={percentInput.name}
        value={percentInput.value === '' ? null : percentInput.value}
        onChange={(name, value) => percentInput.onChange(value)}
        onBlur={percentInput.onBlur}
        error={percentMeta.error}
        showError={percentMeta.touched}
        width='100px'
      />
    )
  }

  if (values.contribMethod === 'D') {
    return (
      <CurrencyInput
        name={amountInput.name}
        value={amountInput.value}
        placeholder='0'
        onChange={(name, value) => amountInput.onChange(value)}
        onBlur={amountInput.onBlur}
        error={amountMeta.error}
        showError={amountMeta.touched}
        decimalScale={0}
        width='125px'
      />
    )
  }

  return null
}

function CatchupContributionsForm(props) {
  const [state, setState] = useState('idle')
  const [recommendedCatchupAfterTax, setRecommendedCatchupAfterTax] = useState(false)
  const form = useForm()
  const { values } = useFormState({ subscription: { values: true } })

  function buildSavingsRate() {
    const mapTaxType = { preTax: 1, afterTax: 2, roth: 5 }
    const preTaxValue = { P: values.preTaxRate, D: values.preTaxAmount }
    const rothValue = { P: values.rothRate, D: values.rothAmount }
    const afterTaxValue = { P: values.afterTaxRate, D: values.afterTaxAmount }

    return {
      [mapTaxType.preTax]: {
        taxType: mapTaxType.preTax,
        deferralType: values.contribMethod,
        value: preTaxValue[values.contribMethod],
      },

      [mapTaxType.roth]: props.rothContribAllowed
        ? {
            taxType: mapTaxType.roth,
            deferralType: values.contribMethod,
            value: rothValue[values.contribMethod],
          }
        : undefined,

      [mapTaxType.afterTax]: props.posttaxContribAllowed
        ? {
            taxType: mapTaxType.afterTax,
            deferralType: values.contribMethod,
            value: afterTaxValue[values.contribMethod],
          }
        : undefined,
    }
  }

  async function fetchCatchupRecommendation() {
    try {
      setState('loading')

      const catchupRecommendation = await API.post(
        'offboard/catchup/recommend',
        buildSavingsRate(),
        {
          params: { salary: values.annualSalary },
        }
      )
      const catchupPreTaxValue = _.get(catchupRecommendation.data, 'catchUp.1.value', 0)
      const catchupRothValue = _.get(catchupRecommendation.data, 'catchUp.5.value', 0)
      const catchupAfterTaxValue = _.get(catchupRecommendation.data, 'catchUp.2.value', 0)

      if (values.contribMethod === 'P') {
        form.batch(() => {
          form.change('catchupPreTaxRate', catchupPreTaxValue)
          form.change('catchupRothRate', catchupRothValue)
          form.change('catchupAfterTaxRate', catchupAfterTaxValue)
          form.change('catchupPreTaxAmount', 0)
          form.change('catchupRothAmount', 0)
          form.change('catchupAfterTaxAmount', 0)
        })
      }

      if (values.contribMethod === 'D') {
        form.batch(() => {
          form.change('catchupPreTaxAmount', catchupPreTaxValue)
          form.change('catchupRothAmount', catchupRothValue)
          form.change('catchupAfterTaxAmount', catchupAfterTaxValue)
          form.change('catchupPreTaxRate', 0)
          form.change('catchupRothRate', 0)
          form.change('catchupAfterTaxRate', 0)
        })
      }

      if (catchupAfterTaxValue) {
        setRecommendedCatchupAfterTax(true)
      }

      setState('done')
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  useEffect(() => {
    fetchCatchupRecommendation()
  }, []) // eslint-disable-line

  if (state === 'done') {
    return (
      <div>
        <div
          css={css`
            color: #7a8e96;
            font-size: 18px;
            line-height: 1.35;
            margin-bottom: 2rem;
          `}>
          {recommendedCatchupAfterTax
            ? 'If you are absolutely certain that you want after-tax contributions included towards the IRS limits, complete the information below. Otherwise, we recommend you cancel this page and return to Overall Strategy to get advice.'
            : 'Our recommendation of how to split between catch-up and regular savings is below, which you can change if you want.'}
        </div>

        <div
          css={css`
            display: flex;
            color: #4a606a;
            font-weight: 300;
            font-size: 1.125rem;
            line-height: 1.25;
            margin: 1rem 0;
          `}>
          <div
            css={css`
              width: 300px;
            `}
          />
          <div
            css={css`
              width: 300px;
              color: #022a3a;
            `}>
            Portion to catch-up
          </div>
          <div
            css={css`
              width: 350px;
            `}>
            Portion to regular savings
          </div>
        </div>

        <div
          css={css`
            display: flex;
            align-items: baseline;
            color: #4a606a;
            font-weight: 300;
            font-size: 1.125rem;
            line-height: 1.25;
            margin: 1rem 0;
          `}>
          <div
            css={css`
              width: 300px;
            `}>
            Of pre-tax contributions (
            {values.contribMethod === 'P'
              ? numberToDollars(values.preTaxRate, true) + '%'
              : '$' + numberToDollars(values.preTaxAmount, true)}
            )
          </div>
          <div
            css={css`
              width: 300px;
            `}>
            <CatchupPreTaxField />
          </div>
          <div
            css={css`
              width: 350px;
            `}>
            {values.contribMethod === 'P' &&
              numberToDollars(values.preTaxRate - values.catchupPreTaxRate, true) + '%'}
            {values.contribMethod === 'D' &&
              '$' + numberToDollars(values.preTaxAmount - values.catchupPreTaxAmount, true)}
          </div>
        </div>

        {props.rothContribAllowed && (
          <div
            css={css`
              display: flex;
              align-items: baseline;
              color: #4a606a;
              font-weight: 300;
              font-size: 1.125rem;
              line-height: 1.25;
              margin: 1rem 0;
            `}>
            <div
              css={css`
                width: 300px;
              `}>
              Of Roth contributions (
              {values.contribMethod === 'P'
                ? numberToDollars(values.rothRate, true) + '%'
                : '$' + numberToDollars(values.rothAmount, true)}
              )
            </div>
            <div
              css={css`
                width: 300px;
              `}>
              <CatchupRothField />
            </div>
            <div
              css={css`
                width: 350px;
              `}>
              {values.contribMethod === 'P' &&
                numberToDollars(values.rothRate - values.catchupRothRate, true) + '%'}
              {values.contribMethod === 'D' &&
                '$' + numberToDollars(values.rothAmount - values.catchupRothAmount, true)}
            </div>
          </div>
        )}

        {props.posttaxContribAllowed && (
          <div
            css={css`
              display: flex;
              align-items: baseline;
              color: #4a606a;
              font-weight: 300;
              font-size: 1.125rem;
              line-height: 1.25;
              margin: 1rem 0;
            `}>
            <div
              css={css`
                width: 300px;
              `}>
              Of after-tax contributions (
              {values.contribMethod === 'P'
                ? numberToDollars(values.afterTaxRate, true) + '%'
                : '$' + numberToDollars(values.afterTaxAmount, true)}
              )
            </div>
            <div
              css={css`
                width: 300px;
              `}>
              <CatchupAfterTaxField />
            </div>
            <div
              css={css`
                width: 350px;
              `}>
              {values.contribMethod === 'P' &&
                numberToDollars(values.afterTaxRate - values.catchupAfterTaxRate, true) + '%'}
              {values.contribMethod === 'D' &&
                '$' + numberToDollars(values.afterTaxAmount - values.catchupAfterTaxAmount, true)}
            </div>
          </div>
        )}
      </div>
    )
  }

  return null
}

function CatchupContributions(props) {
  const { values } = useFormState({ subscription: { values: true } })
  const { rothContribAllowed, posttaxContribAllowed } = props.store.institutional

  return (
    <div>
      <div
        css={css`
          font-size: 36px;
          color: #4a606a;
          line-height: 1.25;
          margin: 2.5rem 0 0;
        `}>
        Catch-up contributions
      </div>

      <CatchupContribStatusField />

      {values.catchupContribStatus === 'Y' && (
        <CatchupContributionsForm
          rothContribAllowed={rothContribAllowed}
          posttaxContribAllowed={posttaxContribAllowed}
        />
      )}
    </div>
  )
}

export default inject('store')(observer(CatchupContributions))
