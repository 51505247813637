import styled from '@emotion/styled'

const HeaderRow = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 12pt;
  background-color: #e7f3f9;
  padding: 8px;
  color: ${p => p.theme.lightestGray};
`

const TableRow = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12pt;
  padding: 4px 8px;
  color: ${p => p.theme.darkestGray};
`

const PrimaryCurrency = styled.div`
  font-size: 16pt;
  &:before {
    content: '$';
    color: ${p => p.theme.lightestGray};
    font-size: 18pt;
  }
`

const Currency = styled.div`
  min-width: 100px;
  font-size: 12pt;
  text-align: right;
  font-weight: ${p => (p.bold ? 'bold' : null)};
  color: ${p => p.theme.darkestGray};
  &:before {
    content: '$';
    color: ${p => p.theme.lightestGray};
    font-size: 14pt;
  }
  padding-left: 8px;
`

export { HeaderRow, TableRow, PrimaryCurrency, Currency }
