import Page from './style'
import { Spacer } from '../../../../components'

function CancellationPolicy() {
  return (
    <Page.Container subheader='Cancellation and Refund Policy for the GuidedChoice Advisory Service'>
      <Spacer space='20px'></Spacer>
      <Page.PolicyText>
        You may cancel your subscription at any time, though no refunds will be made for time
        remaining on your current subscription period.
      </Page.PolicyText>
      <Spacer space='20px'></Spacer>
      <Page.PolicyText>
        Refunds on new subscriptions are available only if you have not yet completed the setup of
        your account.
      </Page.PolicyText>
      <Spacer space='20px'></Spacer>
      <Page.PolicyText>
        Please note, you may not need advice more than once during the annual subscription period.
        This does not lessen the value of having access to the Advisory Service for the duration of
        your subscription period or the advice you receive. And since the Advisory Service is
        digital, advice is available immediately upon account setup; therefore, you will be
        considered to have received advice as soon as your account is set up.
      </Page.PolicyText>
      <Spacer space='30px'></Spacer>
      <Page.PolicyText>
        If you have any questions or would like to request a refund, please contact GuidedChoice
        Customer Service at 1-800-774-7459, Monday thru Friday, 8:00 a.m. to 5:00 p.m. Pacific Time
        or help@guidedchoice.com.
      </Page.PolicyText>
    </Page.Container>
  )
}

export default CancellationPolicy
