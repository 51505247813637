import { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Card } from '../elements'
import { FillGauge } from '../../../components'

class FillGaugeCard extends Component {
  handleGoalClick = () => {
    this.props.history.push('/goals/replace-income')
  }

  handleEditClick = () => {
    this.props.history.push('/goals/replace-income')
  }

  render() {
    const { retirementIncomeGoalPct } = this.props.store.person
    return (
      <Card onClick={this.handleGoalClick}>
        <Card.Edit onClick={this.handleEditClick}>Edit</Card.Edit>
        <Card.NameTextBox>
          <Card.NameText>Percent of income after retiring</Card.NameText>
        </Card.NameTextBox>
        <Card.Label>Seeking to replace</Card.Label>
        <Card.SVGContainer>
          <FillGauge fill={retirementIncomeGoalPct} />
        </Card.SVGContainer>
      </Card>
    )
  }
}

export default inject('store')(observer(FillGaugeCard))
