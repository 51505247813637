import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { HelpIcon, TextErrorField } from '../components'

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: ${p => (p.sublabel ? 'baseline' : 'center')};
  padding: ${p => (p.padding ? p.padding : '3px 0')};
  width: 100%;
`

const Label = styled.div`
  flex: ${p => (p.flexLabel ? p.flexLabel : '0 1 100%')};
  color: ${p => p.theme.lightestGray};
  font-size: 1.125rem;
  line-height: ${p => (p.sublabel ? '1.15' : '1.45')};
  padding: ${p => (p.paddingLabel ? p.paddingLabel : '8px 0')};
  & > div > span {
    margin: 0 24px 0 0;
  }
`

const SubLabel = styled.span`
  font-size: 0.75rem;
`

const InputsGroup = styled.div`
  flex: ${p => (p.flexInput ? p.flexInput : '0 1 100%')};
  display: flex;
  flex-wrap: wrap;
  align-items: ${p => (p.flexAlign ? p.flexAlign : 'center')};
  padding: ${p => (p.paddingInput ? p.paddingInput : '8px 0')};
  color: ${p => p.theme.lightestGray};
`

class InputField extends Component {
  static propTypes = {
    error: PropTypes.string,
    showError: PropTypes.bool,
    label: PropTypes.string,
    sublabel: PropTypes.string,
    helpLabel: PropTypes.string,
    flexLabel: PropTypes.string,
    flexInput: PropTypes.string,
    flexAlign: PropTypes.string,
    tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    padding: PropTypes.string,
    paddingLabel: PropTypes.string,
    paddingInput: PropTypes.string,
  }

  render() {
    const {
      error,
      flexLabel,
      flexInput,
      flexAlign,
      label,
      sublabel,
      helpLabel,
      tooltip,
      padding,
      paddingLabel,
      paddingInput,
      showError,
      children,
    } = this.props

    return (
      <InputContainer padding={padding} sublabel={sublabel}>
        {label && (
          <Label flexLabel={flexLabel} sublabel={sublabel} paddingLabel={paddingLabel}>
            <div>
              <span>{label}</span>
              {tooltip && <HelpIcon tooltip={tooltip} helpLabel={helpLabel} />}
            </div>
            {sublabel && <SubLabel>{sublabel}</SubLabel>}
          </Label>
        )}
        <InputsGroup flexInput={flexInput} paddingInput={paddingInput} flexAlign={flexAlign}>
          {children}
        </InputsGroup>
        {showError && <TextErrorField showError={showError} error={error} />}
      </InputContainer>
    )
  }
}

export default InputField
