/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState, useEffect, memo } from 'react'
import styled from '@emotion/styled'
import {
  Drawer,
  Button,
  SVGWrapper,
  Spacer,
  HelpIcon,
  TooltipText,
  TextErrorField,
} from '../../../components'
import { Add } from '../../../assets/icons'
import { Page } from './style'

import Scenario from './Scenario'

const CompareDrawer = memo(({ handleCancel, active, allSpendingScenarios }) => {
  const [selected, setSelected] = useState({})
  const [compareValue, setCompareValue] = useState('')
  const [withValue, setWithValue] = useState('')
  const [submitAttempt, setSubmitAttempt] = useState(false)
  const [descriptionHeight, setDescriptionHeight] = useState(0)

  useEffect(() => {
    return setDescriptionHeight(0)
  }, [])

  const handleCompareChange = e => {
    if (e.target.value === withValue) {
      setCompareValue(e.target.value)
      setWithValue('e.target.value')
    } else {
      setCompareValue(e.target.value)
    }
  }

  const handleWithChange = e => {
    if (e.target.value === compareValue) {
      setCompareValue('')
      setWithValue(e.target.value)
    } else {
      setWithValue(e.target.value)
    }
  }

  const handleCompareSubmit = () => {
    if (!!compareValue && !!withValue) {
      setSelected({ compareValue, withValue })
    } else {
      setSubmitAttempt(true)
    }
  }

  const handleViewDifferentComparison = () => {
    setSelected({})
    setSubmitAttempt(false)
  }

  const handleClose = () => {
    handleCancel()
    setSelected({})
    setCompareValue('')
    setWithValue('')
  }

  return (
    <Drawer active={active} width='100%' fullMobile nopad>
      <div
        css={css`
          padding: 20px 20px 80px 20px;
          @media (max-width: 800px) {
            padding: 1rem 1rem 80px 1rem;
          }
        `}>
        <DrawerTitle>Select scenarios for comparison</DrawerTitle>

        <Spacer space='13px' />

        {selected.compareValue === undefined ? (
          <>
            <ComparisonBoxWrapper>
              <ComparisonBox>
                <CompareBox>
                  <CompareBoxLabel>Compare:</CompareBoxLabel>
                  <Spacer space='8px' />
                  {allSpendingScenarios.map((scenario, i) => {
                    return (
                      <CheckBoxLabel
                        key={i}
                        checked={compareValue === scenario.spendingScenario.scenarioName}>
                        <Checkbox
                          type='checkbox'
                          value={scenario.spendingScenario.scenarioName}
                          onChange={handleCompareChange}
                        />
                        <span>{scenario.spendingScenario.scenarioName}</span>
                      </CheckBoxLabel>
                    )
                  })}
                </CompareBox>

                <CompareBox>
                  <CompareBoxLabel>with:</CompareBoxLabel>
                  <Spacer space='8px' />
                  {allSpendingScenarios.map((scenario, i) => {
                    return (
                      <CheckBoxLabel
                        key={i}
                        checked={withValue === scenario.spendingScenario.scenarioName}>
                        <Checkbox
                          type='checkbox'
                          value={scenario.spendingScenario.scenarioName}
                          onChange={handleWithChange}
                        />
                        <span>{scenario.spendingScenario.scenarioName}</span>
                      </CheckBoxLabel>
                    )
                  })}
                </CompareBox>
              </ComparisonBox>
            </ComparisonBoxWrapper>

            <TextErrorField
              showError={submitAttempt}
              error='Please choose two options to compare'
            />

            <BottomGroup>
              <Button secondary width='120px' onClick={() => handleCancel()} label='Cancel' />
              <Button primary width='140px' label='Compare' onClick={() => handleCompareSubmit()} />
            </BottomGroup>
          </>
        ) : (
          <>
            <StyledButtonContainer onClick={handleViewDifferentComparison}>
              <SVGWrapper svg={Add} size='large' />
              <CompareBack>&nbsp;View a different comparison</CompareBack>
            </StyledButtonContainer>

            <div
              css={css`
                display: flex;
                justify-content: flex-end;
              `}>
              <HelpIcon
                tooltip={TooltipText.whyCantImplement()}
                helpLabel="Why can't I implement a saved scenario from here?"
              />
            </div>

            <Spacer space='0.75rem' />
            <Page.ScenarioDisclaimer>
              GuidedChoice reduces the impact of the market's ups and downs, giving you more
              consistent income over your lifetime.
              <br />
              <br />
              <b>Your spendable income in an average market:</b>
            </Page.ScenarioDisclaimer>

            <Spacer space='1rem' />

            <div
              css={css`
                display: flex;
                justify-content: space-between;
              `}>
              <Scenario
                scenarios={allSpendingScenarios}
                descriptionHeight={descriptionHeight}
                setDescriptionHeight={setDescriptionHeight}
                title={compareValue}
              />
              <Scenario
                scenarios={allSpendingScenarios}
                descriptionHeight={descriptionHeight}
                setDescriptionHeight={setDescriptionHeight}
                title={withValue}
              />
            </div>

            <div
              css={css`
                font-size: 0.75rem;
                color: #7a8e96;
                margin: 2rem 0;
              `}>
              *The dollar value shown above are after-tax. We estimate the values based on investing
              as recommended.
              <br></br>
              <br></br>
              **Proceeds from the sale of company stock are allocated proportionately among the
              other investments in your account.
            </div>

            <div
              css={css`
                display: flex;
                justify-content: flex-end;
              `}>
              <Button secondary width='120px' onClick={handleClose} label='Close' />
            </div>
          </>
        )}
      </div>
    </Drawer>
  )
})

export default CompareDrawer

const DrawerTitle = styled.div`
  color: #022a3a;
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
`
const BottomGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0;
  max-width: 1200px;
`
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 310px;
  color: ${p => p.theme.mediumGray};
  fill: ${p => p.theme.lightestGray};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.darkestGray};
    fill: ${p => p.theme.darkestGray};
  }
  &:active {
    color: #0495bf;
    fill: #0495bf;
  }
`
const CompareBack = styled.a`
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;
  color: #4a606a;
`
const ComparisonBoxWrapper = styled.div`
  padding: 20px 8px;
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px 0;
  min-width: 360px;
  max-width: 1200px;
`
const ComparisonBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CompareBox = styled.div`
  flex: 1 1 auto;
  margin-bottom: 16px;
`
const CompareBoxLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1.25rem;
  font-weight: 600;
`

const CheckBoxLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.125rem;

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: ${p => (p.checked ? '"\u2714"' : '"\u00A0"')};
    width: 20px;
    height: 20px;
    font-size: 16px;
    font-family: 'Open Sans', 'Segoe UI Symbol', 'Helvetica Neue', sans-serif;
    border: 2px solid #767676;
    border-radius: 4px;
    background-color: #ffffff;
    margin: 6px 28px 6px 0;
    color: ${p => (p.checked ? '#000000' : '#FFFFFF')};
  }
`
const Checkbox = styled.input`
  display: none;
`
