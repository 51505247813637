import { css } from '@emotion/react'
import styled from '@emotion/styled'
import posed from 'react-pose'
import logo from '../../../assets/images/GuidedChoice-logo.png'
import { Loading } from '../../../components'

export const MainWrapper = styled.div`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`
export const Section = styled.div`
  display: flex;
  align-items: center;
`
export const Label = styled.div`
  flex: 1;
  color: #7a8e96;
  text-align: right;
  padding-right: 20px;
`
export const DollarValue = styled.div`
  flex: 1;
  color: #022a3a;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`
export const Value = styled.div`
  flex: 1;
  color: #022a3a;
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 24px;
`
export const DollarSign = styled.span`
  color: #7a8e96;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`
export const SmallDollarSign = styled.span`
  color: #7a8e96;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
`

const Page = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

Page.Container = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
`

Page.Loading = () => (
  <div
    css={css`
      margin: 20px;
    `}>
    <Loading />
  </div>
)

Page.Header = () => (
  <div
    css={css`
      display: flex;
      justify-content: flex-end;
      padding-bottom: 40px;
      max-width: 1200px;
      margin: auto;
      align-self: flex-start;
    `}>
    <div
      css={css`
        max-width: 220px;
        padding: 2px 10px;
      `}>
      <img
        css={css`
          width: 100%;
        `}
        src={logo}
        alt='Logo'
      />
    </div>
  </div>
)

Page.Text = styled.div`
  font-size: 1.125rem;
  color: ${p => p.theme.mediumGray};
  line-height: 1.35;
`

Page.LightText = styled.div`
  font-size: 1.125rem;
  color: ${p => p.theme.lightestGray};
  line-height: 1.35;
`

Page.WelcomeHeading = styled.div`
  text-align: center;
  max-width: 679px;
  margin: auto;
  padding: 20px 0 40px;
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 3.125rem;
  font-weight: bold;
  word-wrap: break-word;
  color: ${p => p.theme.darkestGray};
`

Page.WelcomeParagraph = styled(Page.Text)`
  margin: 20px auto;
  max-width: 679px;
`

Page.TermsSubText = styled(Page.Text)`
  font-size: 1rem;
  max-width: 679px;
  margin: auto;
  padding-left: 64px;
`

Page.AnimateHeading = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -15 },
})

Page.Heading = styled.div`
  color: #7a8e96;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 18px;
`

Page.Link = styled.a`
  cursor: pointer;
  color: rgb(31, 151, 190);
  text-decoration: underline;
`

Page.Form = styled.form`
  margin: 20px 0;
  width: 100%;
`

const FieldStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* Field Label Container */
  & > div:first-of-type {
    flex: 1 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: right;
    margin-right: 40px;
    color: rgb(122, 142, 150);
    font-size: 1.125rem;
    line-height: 1.35;

    /* Field Label */
    & > div:first-of-type {
    }

    /* Field SubLabel (Optional) */
    & > div:nth-of-type(2) {
      font-size: 0.875rem;
    }
  }

  /* Field Input Container */
  & > div:nth-of-type(2) {
    flex: 1 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(74, 96, 106);
    font-size: 1.125rem;
    line-height: 1.35;
    word-break: break-word;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    margin: 20px 0;
    & > div:first-of-type {
      flex: 1 1 100%;
      text-align: left;
      margin: 12px 0;
    }
  }
`
Page.Field = props => {
  const { label, subLabel, children, ...rest } = props
  return (
    <FieldStyles {...rest}>
      <div>
        <div
          css={css`
            line-height: min(54px);
          `}>
          {label}
        </div>
        {subLabel && (
          <div
            css={css`
              line-height: max(10px);
              margin-bottom: 10px;
            `}>
            {subLabel}
          </div>
        )}
      </div>
      <div
        css={css`
          line-height: min(54px);
        `}>
        {children}
      </div>
    </FieldStyles>
  )
}

Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`

Page.ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px 0;
  flex-wrap: wrap-reverse;
`

Page.AnimateGroup = posed.div({
  enter: { staggerChildren: 50 },
  exit: { staggerChildren: 20, staggerDirection: -1 },
})

Page.AnimateItem = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 20, opacity: 0 },
})

Page.Animate = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 20, opacity: 0 },
})

export default Page
