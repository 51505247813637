import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import _ from 'lodash'
import styled from '@emotion/styled'
import * as yup from 'yup'

import { TextInput, Button, Loading, SVGWrapper } from '../../../components'
import { stringWhitelist, reduceValidationError } from '../../../utils'
import { CircleNegative, CirclePlus } from '../../../assets/icons'

const SaveBoxContainer = styled.div`
  margin: 18px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const TextHeader = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 0.75rem;
  line-height: 1.35;
  margin-bottom: 18px;
`

const SaveBox = styled.div`
  background-color: #ffffff;
  color: #4a606a;
  border: solid 6px #c6d6df;
  border-radius: 10px;
  padding: 18px;
  max-width: 425px;
`

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .min(1, 'Name must be between 1 and 25 characters')
    .max(25, 'Name must be between 1 and 25 characters')
    .test('string-whitelist', 'Special characters are not allowed', stringWhitelist)
    .when('$savedScenarios', (savedScenarios, schema) =>
      schema.test(
        'unique-name',
        'Name must be unique',
        value =>
          !_.includes(
            savedScenarios.map(scenario => scenario.name.toLowerCase()),
            (value || '').toLowerCase()
          )
      )
    ),
  scenarioDescription: yup
    .string()
    .nullable()
    .max(150, 'Description must be within 150 characters'),
})

class SaveScenario extends Component {
  state = { optionalOpen: false }

  toggleOptional = () => this.setState({ optionalOpen: !this.state.optionalOpen })

  validate = values => {
    const { savedScenarios } = this.props.store
    return schema
      .validate(values, { abortEarly: false, context: { savedScenarios } })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  handleSave = async values => {
    const { saveScenario } = this.props.store
    await saveScenario(values)
  }

  initialValues = () => {
    const { name, scenarioDescription } = this.props.store.modifiedCase
    return { name: name || '', scenarioDescription: scenarioDescription || '' }
  }

  render() {
    const { store } = this.props
    const { modifiedCase } = store

    return (
      <SaveBoxContainer>
        <Form
          validate={this.validate}
          onSubmit={this.handleSave}
          initialValues={this.initialValues()}
          render={({ handleSubmit, submitting, errors }) => (
            <div
              css={css`
                position: relative;
              `}>
              <TextHeader>
                Have access to this scenario plus four more for seven days. We'll keep the latest
                five you save. Just give it a name:
              </TextHeader>
              <SaveBox>
                <div>
                  {modifiedCase.name ? (
                    <div
                      css={css`
                        font-size: 1.125rem;
                        color: #022a3a;
                        margin-bottom: 1em;
                      `}>
                      {modifiedCase.name}
                    </div>
                  ) : (
                    <div
                      css={css`
                        display: flex;
                        font-size: 0.875rem;
                        margin-top: -13px;
                      `}>
                      <div
                        css={css`
                          padding-top: 1em;
                          margin-bottom: 1em;
                        `}>
                        Give it a name:
                      </div>
                      <Field
                        name='name'
                        render={({ input, meta }) => (
                          <TextInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            disabled={!!modifiedCase.name}
                            width='100%'
                            css={css`
                              padding: 0;
                              padding-left: 1.0625em;
                              flex: 1;
                            `}
                          />
                        )}
                      />
                    </div>
                  )}

                  <Field
                    name='name'
                    render={({ input, meta }) =>
                      !modifiedCase.name && (
                        <div
                          css={css`
                            font-size: 14px;
                            color: red;
                            margin: -4px 0;
                            margin-bottom: 18px;
                          `}>
                          {!!input.value && meta.error}
                        </div>
                      )
                    }
                  />
                </div>
                {!modifiedCase.name && (
                  <div
                    css={css`
                      color: #7a8e96;
                      cursor: pointer;
                      font-size: 18px;
                    `}
                    onClick={this.toggleOptional}>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}>
                      <SVGWrapper
                        svg={this.state.optionalOpen ? CircleNegative : CirclePlus}
                        fill='#7A8E96'
                        size='large'
                      />
                      <div
                        css={css`
                          padding-left: 10px;
                          color: #4a606a;
                          font-size: 0.875rem;
                        `}>
                        Optional: Add a description
                      </div>
                    </div>
                  </div>
                )}

                {this.state.optionalOpen && !modifiedCase.name && (
                  <div
                    css={css`
                      margin-top: 10px;
                    `}>
                    <Field
                      name='scenarioDescription'
                      render={({ input }) => (
                        <textarea
                          placeholder='Add details of scenario here'
                          value={input.value}
                          onChange={input.onChange}
                          disabled={Boolean(modifiedCase.scenarioDescription)}
                          css={css`
                            font-size: 0.875rem;
                            padding: 8px;
                            width: 100%;
                            min-width: 100%;
                            max-width: 100%;
                            height: 80px;
                            min-height: 80px;
                            max-height: 80px;
                          `}
                        />
                      )}
                    />
                    <Field
                      name='scenarioDescription'
                      render={({ meta }) => (
                        <div
                          css={css`
                            font-size: 14px;
                            color: red;
                            margin: 8px 0;
                          `}>
                          {meta.error}
                        </div>
                      )}
                    />
                  </div>
                )}
              </SaveBox>
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}>
                {modifiedCase.name ? (
                  <div
                    css={css`
                      padding: 12px;
                      color: #4a606a;
                      border: 1px solid #b6c0c4;
                      border-radius: 4px;
                      background-color: #ffffff;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 18px;
                      margin-top: 18px;
                    `}>
                    Scenario saved
                  </div>
                ) : submitting ? (
                  <div
                    css={css`
                      & div {
                        width: 35px;
                        height: 35px;
                        margin: 18px;
                      }
                    `}>
                    <Loading />
                  </div>
                ) : (
                  <Button
                    label={modifiedCase.name ? 'Scenario saved' : 'Save scenario'}
                    css={css`
                      font-size: 14px;
                      font-weight: 300;
                      line-height: 18px;
                      margin-top: 18px;
                      padding: 10px 9px;
                    `}
                    primary
                    width='127px'
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </div>
          )}
        />
      </SaveBoxContainer>
    )
  }
}

export default inject('store')(observer(SaveScenario))
