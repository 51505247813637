import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Spacer, HelpIcon, TooltipText } from '../../../components'
import { StackedChart, EmptyStackedChart } from './'
import { numberToDollars } from '../../../utils'

const Description = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1rem;
`

const Label = styled.div`
  color: ${p => (p.description ? '#4a606a' : '#8d9ca0')};
  text-align: ${p => (p.alignRight ? 'right' : 'left')};
  font-size: ${p => (p.large ? '1rem' : '.875rem')};
`

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: 240px;
  width: 100%;
  height: ${p => (p.empty ? '100px' : '330px')};
  margin: 0 20px 0 0;
`

const ChartContainer = styled.div`
  width: 100px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Space = styled.div`
  display: flex;
  flex-grow: ${p => (p.space ? p.space : 1)};
`

const ChartLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1.125rem;
  padding-bottom: 16px;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'space-between')};
  align-items: ${p => (p.alignItems ? p.alignItems : 'center')};

  margin: ${p => (p.childMargin ? '0 -8px' : null)};

  & > * {
    margin: ${p => (p.childMargin ? '0 8px' : null)};
  }
`

const Wrapper = styled.div`
  min-width: ${p => (p.minWidth ? p.minWidth : null)};
`

const SubText = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1rem;
`

const ColorDollar = styled.span`
  color: ${p => (p.difference > 0 ? '#4d9927' : p.difference < 0 ? '#f48024' : '#7a8e96')};
`

const Amount = styled.div`
  color: ${p => p.theme.darkestGray};
  text-align: ${p => (p.alignRight ? 'right' : 'left')};
  font-size: 1.5rem;

  &::before {
    content: '$';
    color: ${p => (p.difference > 0 ? '#4d9927' : p.difference < 0 ? '#f48024' : '#7a8e96')};
  }
`

class ComparisonStackedChartWithDescription extends Component {
  static defaultProps = {
    projectedValue: 0,
    projectedValueAdjusted: 0,
    currentValue: 0,
    displayInflation: false,
  }

  static propTypes = {
    displayInflation: PropTypes.bool,
  }

  render() {
    const { displayInflation } = this.props

    let { currentTotalBalance } = this.props.store.baseCase

    currentTotalBalance = Math.round(currentTotalBalance)

    const {
      estimatedFutureValue: engineEstimatedFutureValueREC,
      estimatedInflationAdjustedValue: engineEstimatedInflationAdjustedValueREC,
    } = this.props.store.recommendedCase

    const {
      estimatedFutureValue: engineEstimatedFutureValueMOD,
      estimatedInflationAdjustedValue: engineEstimatedInflationAdjustedValueMOD,
    } = this.props.store.modifiedCase

    const estimatedFutureValueREC = Math.max(Math.round(engineEstimatedFutureValueREC), 0)
    const estimatedFutureValueMOD = Math.max(Math.round(engineEstimatedFutureValueMOD), 0)
    const estimatedInflationAdjustedValueREC = Math.max(
      Math.round(engineEstimatedInflationAdjustedValueREC),
      0
    )
    const estimatedInflationAdjustedValueMOD = Math.max(
      Math.round(engineEstimatedInflationAdjustedValueMOD),
      0
    )

    const hideCurrentBalance =
      estimatedFutureValueMOD < currentTotalBalance || estimatedFutureValueREC < currentTotalBalance

    const equalBalancesMOD = estimatedFutureValueMOD === currentTotalBalance
    const equalBalancesREC = estimatedFutureValueREC === currentTotalBalance

    const equalBalances = !!equalBalancesMOD || !!equalBalancesREC

    return (
      <div>
        <FlexRow justifyContent='flex-start' childPadding='24px'>
          <Wrapper minWidth='200px'>
            <ChartLabel>Recommended</ChartLabel>
          </Wrapper>
          <Wrapper minWidth='150px'>
            <ChartLabel>Modified</ChartLabel>
          </Wrapper>
        </FlexRow>
        <FlexRow justifyContent='flex-start' alignItems='center'>
          <Description>What you can expect</Description>
          {'\u00A0\u00A0'}
          <HelpIcon tooltip={TooltipText.whatYouCanExpect()} />
        </FlexRow>
        {equalBalances && (
          <>
            <Spacer space='4px' />
            <HelpIcon
              helpLabel='About your results'
              tooltip={TooltipText.aboutYourResults(currentTotalBalance)}
            />
            <Spacer space='4px' />
          </>
        )}
        <Spacer space='12px' />
        <FlexRow justifyContent='flex-start' alignItems='flex-end'>
          <Wrapper minWidth='200px'>
            <Amount>{numberToDollars(estimatedFutureValueREC, true)}</Amount>
          </Wrapper>
          <Wrapper minWidth='150px'>
            <Amount difference={estimatedFutureValueMOD - estimatedFutureValueREC}>
              {numberToDollars(estimatedFutureValueMOD, true)}
            </Amount>
          </Wrapper>
          <Label description>Today's value</Label>
        </FlexRow>
        <FlexRow justifyContent='flex-start'>
          <Wrapper minWidth='200px' />
          <Wrapper minWidth='150px'>
            <SubText>
              ({estimatedFutureValueMOD - estimatedFutureValueREC >= 0 ? '+' : '-'}
              <ColorDollar difference={estimatedFutureValueMOD - estimatedFutureValueREC}>
                $
              </ColorDollar>
              {numberToDollars(Math.abs(estimatedFutureValueMOD - estimatedFutureValueREC), true)})
            </SubText>
          </Wrapper>
        </FlexRow>
        {displayInflation && (
          <div>
            <Spacer space='12px' />
            <FlexRow justifyContent='flex-start' alignItems='flex-end'>
              <Wrapper minWidth='200px'>
                <Amount>{numberToDollars(estimatedInflationAdjustedValueREC, true)}</Amount>
              </Wrapper>
              <Wrapper minWidth='150px'>
                <Amount
                  difference={
                    estimatedInflationAdjustedValueMOD - estimatedInflationAdjustedValueREC
                  }>
                  {numberToDollars(estimatedInflationAdjustedValueMOD, true)}
                </Amount>
              </Wrapper>
              <Label description>Value at retirement</Label>
            </FlexRow>
            <FlexRow justifyContent='flex-start'>
              <Wrapper minWidth='200px' />
              <Wrapper minWidth='150px'>
                <SubText>
                  (
                  {estimatedInflationAdjustedValueMOD - estimatedInflationAdjustedValueREC >= 0
                    ? '+'
                    : '-'}
                  <ColorDollar
                    difference={
                      estimatedInflationAdjustedValueMOD - estimatedInflationAdjustedValueREC
                    }>
                    $
                  </ColorDollar>
                  {numberToDollars(
                    Math.abs(
                      estimatedInflationAdjustedValueMOD - estimatedInflationAdjustedValueREC
                    ),
                    true
                  )}
                  )
                </SubText>
              </Wrapper>
            </FlexRow>
          </div>
        )}

        <Spacer space='6px' />
        <ModuleContainer empty={!estimatedFutureValueREC && !estimatedFutureValueMOD}>
          <Wrapper minWidth='200px'>
            <ChartContainer>
              <Spacer space='2px' />
              {!estimatedFutureValueREC ? (
                <>
                  {!!estimatedFutureValueMOD && <Spacer space='115px' />}
                  <EmptyStackedChart />
                </>
              ) : (
                <StackedChart
                  currentPercentage={
                    hideCurrentBalance
                      ? 0
                      : currentTotalBalance /
                        Math.max(estimatedFutureValueREC, estimatedFutureValueMOD || 0)
                  }
                  barHeightPercentage={
                    estimatedFutureValueREC /
                    Math.max(estimatedFutureValueREC, estimatedFutureValueMOD || 0)
                  }
                />
              )}
            </ChartContainer>
          </Wrapper>
          <Wrapper minWidth='150px'>
            <ChartContainer>
              <Spacer space='2px' />
              {!estimatedFutureValueMOD ? (
                <>
                  {!!estimatedFutureValueREC && <Spacer space='118px' />}
                  <EmptyStackedChart />
                </>
              ) : (
                <StackedChart
                  currentPercentage={
                    hideCurrentBalance
                      ? 0
                      : currentTotalBalance /
                        Math.max(estimatedFutureValueREC, estimatedFutureValueMOD || 0)
                  }
                  barHeightPercentage={
                    estimatedFutureValueMOD /
                    Math.max(estimatedFutureValueREC, estimatedFutureValueMOD || 0)
                  }
                />
              )}
            </ChartContainer>
          </Wrapper>
          <TextContainer>
            {(!!estimatedFutureValueMOD || !!estimatedFutureValueREC) &&
              (hideCurrentBalance ? (
                <Space space={1.001} />
              ) : (
                <Space
                  space={
                    1.001 -
                    Math.min(
                      currentTotalBalance /
                        Math.max(estimatedFutureValueREC, estimatedFutureValueMOD || 0),
                      1
                    )
                  }
                />
              ))}
            <FlexRow>
              <Label large description>
                What you've saved
                {'\u00A0\u00A0\u00A0'}
              </Label>
              <HelpIcon tooltip={TooltipText.whatYouSaved()} />
            </FlexRow>
            <Spacer space='4px' />
            {hideCurrentBalance ? (
              <HelpIcon tooltip={TooltipText.balanceHidden()} helpLabel='Balance hidden' />
            ) : (
              <Amount gray alignRight>
                {numberToDollars(currentTotalBalance, true)}
              </Amount>
            )}
          </TextContainer>
        </ModuleContainer>
      </div>
    )
  }
}

export default inject('store')(observer(ComparisonStackedChartWithDescription))
