/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { observer, inject } from 'mobx-react'
import { useField, useFormState } from 'react-final-form'

import { Dropdown as DropdownElement } from '../../../components'

function DurationStartingAndStoppingAge({ fieldName, initialValue = '', store: { person } }) {
  const { input, meta } = useField(fieldName, {
    subscription: { value: true, touched: true, error: true },
  })

  const { values } = useFormState({ subscription: { values: true } })

  const startingAge = () => {
    let result = 0
    if (!isNaN(person.retirementAge)) {
      result = person.retirementAge
    } else {
      result = 65
    }
    return result
  }

  let uiRetAge
  if (fieldName === 'durationStartingAgeClient' || fieldName === 'durationStoppingAgeClient') {
    if (values.clientRetirement) {
      uiRetAge = parseInt(values.clientRetirement)
    }
  }
  if (fieldName === 'durationStartingAgeSpouse' || fieldName === 'durationStoppingAgeSpouse') {
    if (values.spouseRetirement) {
      uiRetAge = parseInt(values.spouseRetirement)
    }
  }

  const ages = () => {
    const arr = []
    const start = uiRetAge || startingAge()
    for (let i = start; i <= 100; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }

  return (
    <DropdownElement
      error={meta.error}
      name={input.name}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      options={ages()}
      placeholder={initialValue ? initialValue + '' : input.value + ''}
      selected={input.value}
      showError={meta.touched}
    />
  )
}

export default inject('store')(observer(DurationStartingAndStoppingAge))
