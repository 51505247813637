import { Component } from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'
import * as R from 'ramda'

import { API_URL } from '../../../api'
import { SVGWrapper } from '../../../components/'
import { CircleNegative, CirclePlus } from '../../../assets/icons'

const TableHeader = styled.td`
  color: ${p => p.theme.lightestGray};
  width: ${p => (p.tooltip ? '100px' : '150px')};
  text-align: ${p => (p.align ? p.align : null)};
  white-space: ${p => (p.whitespace ? p.whitespace : null)};
  vertical-align: ${p => (p.verticalAlign ? p.verticalAlign : null)};
  cursor: ${p => (p.cursor ? p.cursor : null)};
  visibility: ${p => (p.hide ? 'hidden' : null)};
`
const Table = styled.table`
  table-layout: fixed;

  td {
    padding: 3px 6px;
  }
`
const ValueCell = styled.td`
  width: 450px;
  color: ${p => p.theme.darkestGray};
`
const StyledCell = styled.td`
  color: ${p => p.theme.darkestGray};
`
const SVGCell = styled.td`
  padding: 0;
  vertical-align: bottom;

  div {
    position: relative;
    top: 3px;
  }
`
const Link = styled.a`
  color: ${p => p.theme.darkestGray};
  text-decoration: underline;
  cursor: pointer;
`
const Padding = styled.span`
  display: inline-block;
  padding: 0 30px;
`

const GuideLink = ({ evtDesc, timeStamp }) => {
  const generateGuideLink = ({ guideId }) => `${API_URL}guide/fetch/${guideId}`
  const convertDate = timeStamp => dayjs(timeStamp).format('MMDDYYYY.HHmmss')

  return (
    <Link href={generateGuideLink(evtDesc)} target='_blank' rel='noreferrer noopener'>
      {`GuidedChoice-AdvisoryServiceGuide.${convertDate(timeStamp)}.pdf`}
    </Link>
  )
}

const generateReason = evtDesc => R.propOr('', 'reason', evtDesc)

/* eslint-disable */
const generateTransaction = (evtDesc, userLogs) =>
  userLogs.transactionObject.hasOwnProperty(evtDesc.caseId) ? 'Yes' : 'No'
/* eslint-enable */
class HistoricalDataGuideGroup extends Component {
  state = { expandedView: true }

  toggleView = () => this.setState({ expandedView: !this.state.expandedView })

  // transforms eventDescription
  // normalize to JSON, prep `reason` field for display
  parseDesc = desc => {
    const parseReason = desc => {
      switch (desc.reason) {
        case 'email':
          return { ...desc, reason: 'E' }
        case 'view':
          return { ...desc, reason: 'V' }
        default:
          return desc
      }
    }

    return R.o(parseReason, JSON.parse)(desc)
  }

  // date format
  formatTime = timeStamp => {
    return dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss')
  }

  /**
   * @desc transforms guide data
   *
   * @param {Array} acc - list of guides
   * @param {Object} curr - guide object
   *
   * @return {Array} acc - list of transformed guides
   */
  prepGuide = (acc, curr) => {
    // safely extract timeStamp
    // default (current) timeStamp to act as a functional placeholder
    const safeTime = R.propOr(dayjs().format('YYYY-MM-DD HH:mm:ss'), 'timeStamp')
    const updateGuideModel = { eventDescription: this.parseDesc, timeStamp: this.formatTime }
    const currGuide = R.evolve(updateGuideModel, curr)
    const { timeStamp } = currGuide
    const prevTimeStamp = R.o(safeTime, R.last)(acc)
    const sameActivity = dayjs(timeStamp).isSame(prevTimeStamp, 'second')
    const updateDesc = R.assocPath(['eventDescription', 'reason'], 'E V')
    const modifyLast = R.adjust(-1, updateDesc)

    // if same timestamp
    // then assume user viewed and emailed
    //  -> modify last guide meta data to include both events (E & V)
    // else append updated current guide to list
    return sameActivity ? modifyLast(acc) : [...acc, currGuide]
  }

  render() {
    const { expandedView } = this.state
    const { year, userLogs, sortedGuideList } = this.props
    const updatedGuideList = sortedGuideList.reduce(this.prepGuide, [])
    /* eslint-disable react/jsx-handler-names */
    return (
      <Table>
        <tbody>
          <tr>
            <SVGCell>
              <SVGWrapper
                svg={expandedView ? CircleNegative : CirclePlus}
                fill='#7a8e96'
                size='large'
                onClick={this.toggleView}
              />
            </SVGCell>
            <TableHeader
              align='right'
              whitespace='nowrap'
              verticalAlign='bottom'
              onClick={this.toggleView}
              cursor='pointer'
              tooltip>
              &nbsp;{year} Guides
            </TableHeader>
            {expandedView && <TableHeader verticalAlign='bottom'>PDFs</TableHeader>}
            {expandedView && (
              <TableHeader verticalAlign='bottom'>
                <Padding>
                  Email (E) <br /> View (V)
                </Padding>
              </TableHeader>
            )}
            {expandedView && <TableHeader verticalAlign='bottom'>Sent to recordkeeper</TableHeader>}
          </tr>
          {expandedView &&
            updatedGuideList.map(({ eventLogId, eventDescription: evtDesc, timeStamp }) => (
              <tr key={eventLogId}>
                <td />
                <td />
                <ValueCell>
                  <GuideLink evtDesc={evtDesc} timeStamp={timeStamp} />
                </ValueCell>
                <StyledCell>
                  <Padding>{generateReason(evtDesc)}</Padding>
                </StyledCell>
                <StyledCell>{generateTransaction(evtDesc, userLogs)}</StyledCell>
              </tr>
            ))}
        </tbody>
      </Table>
    )
  }
}

export default observer(HistoricalDataGuideGroup)
