/* eslint-disable import/no-anonymous-default-export */

import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const disabled = 'rt-switch-disabled'

const field = 'rt-switch-field'

const input = 'rt-switch-input'

const off = 'rt-switch-off'

const on = 'rt-switch-on'

const ripple = 'rt-switch-ripple'

const text = 'rt-switch-text'

const thumb = 'rt-switch-thumb'

const _field = css`
  display: block;
  margin-bottom: ${ds.get('switch.fieldMarginBottom')};
  position: relative;
  white-space: normal;

  ${ds.get('reset')};
`

const _text = css`
  color: ${ds.get('switch.textColor')};
  display: inline-block;
  font-size: ${ds.get('switch.fontSize')};
  line-height: ${ds.get('switch.totalHeight')};
  padding-left: ${ds.get('unit')};
  vertical-align: top;
  white-space: nowrap;
`

const _thumb = css`
  border-radius: 50%;
  cursor: pointer;
  height: ${ds.get('switch.thumbSize')};
  position: absolute;
  top: ${reduceCSSCalc(
    `calc((${ds.get('switch.trackHeight')} - ${ds.get('switch.thumbSize')}) / 2)`
  )};
  transition-duration: 0.28s;
  transition-property: left;
  transition-timing-function: ${ds.get('animation.curveDefault')};
  width: ${ds.get('switch.thumbSize')};

  ${ds.get('reset')};

  & .${ripple} {
    background-color: ${ds.get('switch.color')};
    opacity: 0.3;
    transition-duration: ${ds.get('switch.rippleDuration')};
  }
`

const _onOff = css`
  border-radius: ${ds.get('switch.trackHeight')};
  cursor: pointer;
  display: inline-block;
  height: ${ds.get('switch.trackHeight')};
  margin-top: ${reduceCSSCalc(
    `calc((${ds.get('switch.totalHeight')} - ${ds.get('switch.trackHeight')}) / 2)`
  )};
  position: relative;
  vertical-align: top;
  width: ${ds.get('switch.trackLength')};
`

const _on = css`
  ${_onOff};
  background: ${ds.get('switch.trackOnColor')};

  & .${thumb} {
    background: ${ds.get('switch.thumbOnColor')};
    box-shadow: ${ds.get('shadow[3]')};
    left: ${reduceCSSCalc(`calc(${ds.get('switch.trackLength')} - ${ds.get('switch.thumbSize')})`)};
  }
`

const _off = css`
  ${_onOff};
  background: ${ds.get('switch.trackOffColor')};

  & .${thumb} {
    background: ${ds.get('switch.thumbOffColor')};
    box-shadow: ${ds.get('shadow[2]')};
    left: 0;
  }

  & .${ripple} {
    background: ${ds.get('switch.offRippleColor')};
  }
`

const _input = css`
  height: 0;
  opacity: 0;
  overflow: hidden;
  width: 0;

  &:focus:not(:active) {
    & + .${on} > .${thumb}::before, & + .${off} > .${thumb}::before {
      background-color: transparent;
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      display: inline-block;
      height: ${ds.get('switch.focusInitSize')};
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(
        ${reduceCSSCalc(`calc(-1 * ${ds.get('switch.focusInitSize')} / 2)`)},
        ${reduceCSSCalc(`calc(-1 * ${ds.get('switch.focusInitSize')} / 2)`)}
      );
      width: ${ds.get('switch.focusInitSize')};
    }

    & + .${on} > .${thumb}::before {
      background-color: ${ds.get('switch.onFocusColor')};
      box-shadow: 0 0 0 ${ds.get('switch.focusDiff')} ${ds.get('switch.onFocusColor')};
    }

    & + .${off} > .${thumb}::before {
      background-color: ${ds.get('switch.offFocusColor')};
      box-shadow: 0 0 0 ${ds.get('switch.focusDiff')} ${ds.get('switch.offFocusColor')};
    }
  }
`

const _disabled = css`
  ${_field};

  & .${text} {
    color: ${ds.get('switch.disabledTextColor')};
  }

  & .${on}, & .${off} {
    background: ${ds.get('switch.disabledTrackColor')};
    cursor: auto;
  }

  & .${thumb} {
    background-color: ${ds.get('switch.disabledThumbColor')};
    border-color: transparent;
    cursor: auto;
  }
`

export default {
  disabled: cx(disabled, _disabled),
  field: cx(field, _field),
  input: cx(input, _input),
  off: cx(off, _off),
  on: cx(on, _on),
  ripple,
  text: cx(text, _text),
  thumb: cx(thumb, _thumb),
}
