import { Component } from 'react'
import styled from '@emotion/styled'

const Box = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  max-width: 1000px;
  width: 100%;
  margin: auto;
`

const TopPattern = styled.div`
  position: absolute;
  top: -500px;
  right: -650px;
  height: 1000px;
  width: 1000px;
  background-color: #f5fafc;
  opacity: 1;
  transform: rotate(45deg);
`

const BottomPattern = styled.div`
  position: absolute;
  top: 600px;
  right: -778px;
  height: 2000px;
  width: 2000px;
  background-color: #f5fafc;
  background: linear-gradient(135deg, #f5fafc, #fff, #fff, #fff, #fff, #fff);
  opacity: 1;
  transform: rotate(45deg);
`

const RightPattern = styled.div`
  position: absolute;
  top: -238px;
  right: -910px;
  height: 1000px;
  width: 1000px;
  background-color: #edf6f9;
  opacity: 1;
  transform: rotate(45deg);
`

class PageBackground extends Component {
  render() {
    return (
      <Box>
        <TopPattern />
        <BottomPattern />
        <RightPattern />
      </Box>
    )
  }
}

export default PageBackground
