import { observer, inject } from 'mobx-react'
import { FieldContainer, Title, BulletText } from '../styled'

function CalendarReminders() {
  return (
    <FieldContainer>
      <Title>Calendar reminder date(s) to rebalance your investment accounts</Title>
      <BulletText>
        We recommend returning to the advisory service each quarter—or at least annually—to receive
        a new guide containing investment advice that when implemented, will rebalance your account
      </BulletText>
    </FieldContainer>
  )
}

export default inject('store')(observer(CalendarReminders))
