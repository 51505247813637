import styled from '@emotion/styled'
import { css } from '@emotion/react'

const TableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #7a8e96;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 24px;

  & > div:first-of-type {
    color: #4a606a;
  }
`

const spacing = {
  deleteIcon: '42px',
  investmentName: '320px',
  date: '225px',
  costBasis: '135px',
  shares: '100px',
  balance: '145px',
}

const breakpoint = '1140px'

const TableHeader = styled.div`
  display: flex;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  color: #7a8e96;
  line-height: 1.5;
  overflow-wrap: break-word;

  @media (max-width: ${breakpoint}) {
    display: none;
  }

  /* Delete Icon */
  & > div:nth-of-type(1) {
    width: ${spacing.deleteIcon};
    padding: 6px 8px;
  }

  /* Investment name */
  & > div:nth-of-type(2) {
    width: ${spacing.investmentName};
    padding: 6px 8px;
    cursor: pointer;
  }

  /* As of date */
  & > div:nth-of-type(3) {
    width: ${spacing.date};
    padding: 6px 8px;
  }

  /* Cost basis */
  & > div:nth-of-type(4) {
    width: ${spacing.costBasis};
    padding: 6px 8px;
  }

  /* Shares */
  & > div:nth-of-type(5) {
    width: ${spacing.shares};
    padding: 6px 8px;
  }

  /* Balance */
  & > div:nth-of-type(6) {
    width: ${spacing.balance};
    padding: 6px 8px;
    cursor: pointer;
    text-align: right;
  }
`

const TableList = styled.div`
  & > div:first-of-type {
    margin-top: 0;
  }
`

const TableRow = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  color: #7a8e96;
  line-height: 1.5;
  border: 1px solid #b6c0c4;
  background-color: #fafeff;
  margin: 14px 0;
  overflow-wrap: break-word;

  @media (max-width: ${breakpoint}) {
    flex-wrap: wrap;
    padding: 4px 14px 16px;
  }
`

const DeleteIconColumn = styled.div`
  width: ${spacing.deleteIcon};
  padding: 8px 6px 0px;
`

const InvestmentNameColumn = styled.div`
  width: ${spacing.investmentName};
  padding: 12px 8px;
  @media (max-width: ${breakpoint}) {
    padding: 0 8px;
    width: calc(100% - 42px);
  }
`

const AsOfDateColumn = styled.div`
  width: ${spacing.date};
  padding: 12px 0px;
  @media (max-width: ${breakpoint}) {
    width: 100%;
    margin-left: 45px;
    padding: 0;
  }
`

const CostBasisColumn = styled.div`
  width: ${spacing.costBasis};
  padding: 12px 8px;
  @media (max-width: ${breakpoint}) {
    width: 100%;
    margin-left: 45px;
    padding: 0 8px;
  }
`

const SharesColumn = styled.div`
  width: ${spacing.shares};
  padding: 12px 8px;
  @media (max-width: ${breakpoint}) {
    width: 100%;
    margin-left: 45px;
    padding: 0 8px;
  }
`

const BalanceColumn = styled.div`
  width: ${spacing.balance};
  padding: 12px 8px;
  text-align: right;
  @media (max-width: ${breakpoint}) {
    text-align: left;
    width: 100%;
    margin-left: 45px;
    padding: 0 5px;
  }
`

const sortArrowStyle = ({ direction }) => {
  if (direction === 'neutral') {
    return css`
      border: solid #9eacb0;
      color: #9eacb0;
      transform: rotate(-45deg);
    `
  }
  if (direction === 'up') {
    return css`
      border: solid black;
      color: black;
      transform: rotate(-135deg);
    `
  }
  if (direction === 'down') {
    return css`
      border: solid black;
      color: black;
      transform: rotate(45deg);
    `
  }
}

const SortArrow = styled.i`
  ${sortArrowStyle};
  font-size: 25px;
  border-width: 0 3px 3px 0;
  margin-left: 10px;
  display: inline-block;
  padding: 3px;
  transition: border-color 0.35s, transform 0.25s;
`

const DeleteIconStyles = styled.svg`
  fill: rgb(182, 192, 196);
  width: 28px;
  height: 28px;
  cursor: pointer;
  transition: fill 0.3s;
  &:hover {
    fill: black;
  }
  &:focus {
    fill: rgb(4, 149, 191);
  }
  &:active {
    fill: rgb(4, 149, 191);
  }
`
const DeleteIcon = props => (
  <DeleteIconStyles viewBox='0 0 24 24' {...props}>
    <title>Delete</title>
    <path d='M21.6 12a9.6 9.6 0 0 0-19.2 0M0 12a12 12 0 0 1 24 0M2.4 12a9.6 9.6 0 0 0 19.2 0m2.4 0a12 12 0 0 1-24 0m10.73.15l-3.44 3.44L8.7 17l3.44-3.44L15.58 17 17 15.59l-3.44-3.44L17 8.71l-1.42-1.42-3.44 3.44L8.7 7.29 7.29 8.71z' />
  </DeleteIconStyles>
)

const ResponsiveLabel = styled.div`
  width: 100%;
  font-size: 0.875rem;
  margin-left: 50px;
  margin-top: 12px;
  @media (min-width: 1141px) {
    display: none;
  }
`

export {
  TableTitle,
  TableHeader,
  TableList,
  TableRow,
  SortArrow,
  DeleteIcon,
  DeleteIconColumn,
  InvestmentNameColumn,
  AsOfDateColumn,
  CostBasisColumn,
  SharesColumn,
  BalanceColumn,
  ResponsiveLabel,
  breakpoint,
}
