/* eslint-disable import/no-anonymous-default-export */
import { css, keyframes, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const linearIndeterminateBar = keyframes`
  0% {
    transform: translate(-50%) scaleX(0);
  }

  50% {
    transform: translate(-0%) scaleX(0.3);
  }

  100% {
    transform: translate(50%) scaleX(0);
  }
`

const circularIndeterminateBarRotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const circularIndeterminateBarDash = keyframes`
  0% {
    stroke-dasharray: ${ds.get('progress.scaleRatio') * 1}, ${ds.get('progress.scaleRatio') * 200} ;
    stroke-dashoffset: ${ds.get('progress.scaleRatio') * 0};
  }

  50% {
    stroke-dasharray: ${ds.get('progress.scaleRatio') * 89}, ${ds.get('progress.scaleRatio') * 200};
    stroke-dashoffset: ${ds.get('progress.scaleRatio') * -35};
  }

  100% {
    stroke-dasharray: ${ds.get('progress.scaleRatio') * 89}, ${ds.get('progress.scaleRatio') * 200};
    stroke-dashoffset: ${ds.get('progress.scaleRatio') * -124};
  }
`

const colors = keyframes`
  0% {
    stroke: #4285f4;
  }

  25% {
    stroke: #de3e35;
  }

  50% {
    stroke: #f7c223;
  }

  75% {
    stroke: #1b9a59;
  }

  100% {
    stroke: #4285f4;
  }
`

const buffer = 'rt-progressBar-buffer'

const circle = 'rt-progressBar-circle'

const circular = 'rt-progressBar-circular'

const indeterminate = 'rt-progressBar-indeterminate'

const linear = 'rt-progressBar-linear'

const multicolor = 'rt-progressBar-multicolor'

const path = 'rt-progressBar-path'

const value = 'rt-progressBar-value'

const _linear = css`
  background: ${ds.get('colors.rt.divider')};
  display: inline-block;
  height: ${ds.get('progress.height')};
  overflow: hidden;
  position: relative;
  width: 100%;

  &.${indeterminate} .${value} {
    animation: ${linearIndeterminateBar} 1s linear infinite;
    transform-origin: center center;
  }

  &[disabled] {
    & .${value} {
      background-color: ${ds.get('progress.disabledColor')};
    }

    & .${buffer} {
      background-image: linear-gradient(
          to right,
          ${ds.get('progress.secondaryColor')},
          ${ds.get('progress.secondaryColor')}
        ),
        linear-gradient(
          to right,
          ${ds.get('progress.disabledColor')},
          ${ds.get('progress.disabledColor')}
        );
    }
  }
`

const _value = css`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: left center;
  transition-duration: ${ds.get('animation.duration')};
  transition-timing-function: ${ds.get('animation.curveDefault')};
  background-color: ${ds.get('progress.mainColor')};
`

const _buffer = css`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: left center;
  transition-duration: ${ds.get('animation.duration')};
  transition-timing-function: ${ds.get('animation.curveDefault')};
  background-image: linear-gradient(
      to right,
      ${ds.get('progress.secondaryColor')},
      ${ds.get('progress.secondaryColor')}
    ),
    linear-gradient(to right, ${ds.get('progress.mainColor')}, ${ds.get('progress.mainColor')});
`

const _circular = css`
  display: inline-block;
  height: ${reduceCSSCalc(`calc(${ds.get('progress.circleWrapperWidth')} * 1px)`)};
  position: relative;
  transform: rotate(-90deg);
  width: ${reduceCSSCalc(`calc(${ds.get('progress.circleWrapperWidth')} * 1px)`)};

  &.${indeterminate} {
    & .${circle} {
      animation: ${circularIndeterminateBarRotate} 2s linear infinite;
    }

    & .${path} {
      animation: ${circularIndeterminateBarDash} 1.5s ease-in-out infinite;
      stroke-dasharray: ${reduceCSSCalc(`calc(${ds.get('progress.scaleRatio')} * 1)`)},
        ${reduceCSSCalc(`calc(${ds.get('progress.scaleRatio')} * 200)`)};
      stroke-dashoffset: 0;
    }

    &.${multicolor} .${path} {
      animation: ${circularIndeterminateBarDash} 1.5s ease-in-out infinite,
        ${colors} ${reduceCSSCalc('calc(1.5s * 4)')} ease-in-out infinite;
    }
  }

  &[disabled] {
    & .${value} {
      background-color: ${ds.get('progress.disabledColor')};
    }

    & .${buffer} {
      background-image: linear-gradient(
          to right,
          ${ds.get('progress.secondaryColor')},
          ${ds.get('progress.secondaryColor')}
        ),
        linear-gradient(
          to right,
          ${ds.get('progress.disabledColor')},
          ${ds.get('progress.disabledColor')}
        );
    }
  }
`

const _circle = css`
  height: 100%;
  width: 100%;
`

const _path = css`
  fill: none;
  stroke: ${ds.get('progress.mainColor')};
  stroke-dasharray: 0, ${ds.get('progress.scaleRatio') * 200};
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-miterlimit: 20;
  stroke-width: 4;
  transition: stroke-dasharray ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')};
`

export default {
  buffer: cx(buffer, _buffer),
  circle: cx(circle, _circle),
  circular: cx(circular, _circular),
  indeterminate,
  linear: cx(linear, _linear),
  multicolor,
  path: cx(path, _path),
  value: cx(value, _value),
}
