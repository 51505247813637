/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Card, CardField, CardFieldError, Drawer, Spacer } from '../../../components'
import { planTypeToAccountLabel, planTypeIdToAccountKey } from '../../../constants'
import EditBasics from './EditBasics'
import EditAnnuity from './EditAnnuity'
import { numberToDollars } from '../../../utils/utils'

const EditBasicsCard = props => {
  const [editActive, setEditActive] = useState(false)

  const handleEdit = () => setEditActive(true)

  const handleCancel = () => setEditActive(false)

  const { features, person } = props.store
  const {
    account: { name, planType, participantName, adviced, type },
    account,
  } = props.account
  const showAdviced = _.includes(
    [
      '401a',
      '401k',
      '403b',
      '457',
      'annuity-fixed',
      'ira-deductible',
      'ira-non-deductible',
      'ira-rollover',
      'ira-roth',
      'keogh',
      'payroll-deduct-ira',
      'sep-ira',
      'simple-ira',
    ],
    planTypeIdToAccountKey[planType]
  )

  const canEditBasics = type !== 'Institutional' && features.outsideAdviceEnabled && showAdviced

  if (planTypeToAccountLabel[planType] === 'Annuity | Fixed') {
    const endAge = account.endYear - (new Date().getFullYear() - person.age)
    let inflationProtectionFeatures
    if (account.colaWithCpi) {
      inflationProtectionFeatures = 'Inflation-indexed or COLA'
    } else if (account.colaWithCpi === false && account.colaAmtRate > 0) {
      inflationProtectionFeatures = 'Fixed annual increases'
    } else {
      inflationProtectionFeatures = 'None of these apply'
    }

    return (
      <>
        <Card title='Account basics'>
          {planTypeToAccountLabel[planType] !== null && (
            <CardField label='Account type' value={planTypeToAccountLabel[planType]} />
          )}

          {name !== null && <CardField label='Account name' value={name} />}

          {participantName !== null && <CardField label='Account owner' value={participantName} />}

          {!name && (
            <CardFieldError>
              GuidedChoice does not have data on record for this account
            </CardFieldError>
          )}
        </Card>
        <Card title='Payment details' handleEdit={handleEdit}>
          <CardField label='Distribution type' value={account.distOptionName} />
          <CardField
            label='Payment start year | age'
            value={`${account.beginYear} | ${account.annuityStartAge}`}
          />
          {(account.distOptionName === 'Life with Period Certain' ||
            account.distOptionName === 'Period Certain') && (
            <CardField label='Payment end year | age' value={`${account.endYear} | ${endAge}`} />
          )}
          <Spacer space='10px' />
          <CardField
            label='Monthly payment'
            value={`$${numberToDollars(account.monthlyIncome, true)}`}
          />
          {(account.distOptionName === 'Joint and Survivor' ||
            account.distOptionName === 'Joint Life') && (
            <CardField label='Survivor benefit' value={`${account.survivorFraction}%`} />
          )}
          <CardField label='Inflation protection features' value={inflationProtectionFeatures} />
          {inflationProtectionFeatures === 'Fixed annual increases' && (
            <CardField label='Annual rate increase' value={account.colaAmtRate + '%'} />
          )}
          <Drawer
            active={editActive}
            title={"Let's get details about payouts"}
            subtitle='Edit account'
            width='50%'>
            <EditAnnuity handleCancel={handleCancel} />
          </Drawer>
        </Card>
      </>
    )
  }

  return (
    <Card title='Account basics' handleEdit={canEditBasics ? handleEdit : null}>
      {planTypeToAccountLabel[planType] !== null && (
        <CardField label='Account type' value={planTypeToAccountLabel[planType]} />
      )}

      {name !== null && <CardField label='Account name' value={name} />}

      {participantName !== null && <CardField label='Account owner' value={participantName} />}

      {features.outsideAdviceEnabled && showAdviced && (
        <CardField label='Investment advice?' value={adviced ? 'Include' : 'Exclude'} />
      )}

      {!name && (
        <CardFieldError>GuidedChoice does not have data on record for this account</CardFieldError>
      )}

      <Drawer
        active={canEditBasics && editActive}
        title='Basics'
        subtitle='Edit account'
        width='50%'>
        <EditBasics handleCancel={handleCancel} />
      </Drawer>
    </Card>
  )
}

export default inject('store', 'account')(observer(EditBasicsCard))
