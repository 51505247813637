import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { Loading } from '../../../components'
import FactContainer from './FactContainer'

const StyledFactContainer = styled.div`
  min-width: 325px;
  border: 2px solid #9eacb0;
  border-radius: 8px;
  background: #e7f3f9;
  max-width: 1200px;
  padding: 2rem;
  margin: auto;
`

const FactTitle = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 2.25rem;
  padding-bottom: 36px;
`

class FastFactsModule extends Component {
  render() {
    const { isBaseLoading, isRecommendedLoading } = this.props.overallStrategy
    return (
      <StyledFactContainer>
        {isBaseLoading || isRecommendedLoading ? (
          <Loading />
        ) : (
          <>
            <FactTitle>Fast Facts</FactTitle>
            <FactContainer />
          </>
        )}
      </StyledFactContainer>
    )
  }
}

export default inject('store', 'overallStrategy')(observer(FastFactsModule))
