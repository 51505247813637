import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { inject, Observer } from 'mobx-react'
import styled from '@emotion/styled'

import { API, API_URL } from '../../../api'
import { Button, Loading } from '../../../components'
import ImplementChangesIcon from '../value-proposition/icons/ImplementChangesIcon'
import ManageRiskIcon from '../value-proposition/icons/ManageRiskIcon'
import AutoAdjustRiskIcon from './AutoAdjustRiskIcon'

const IconGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
  font-family: 'Open Sans';
  color: #4a606a;
  margin: 20px 0 56px;
  & > div {
    flex: 0 1 200px;
    margin: 10px 20px;
    line-height: 1.35;
  }
`

function ModificationsComplete(props) {
  const [state, setState] = useState('loading')

  async function fetchInitialState() {
    const {
      offboarding: { acceptedCaseSubmitted, setAcceptedCaseSubmitted },
      store: { acceptedCaseId },
    } = props

    try {
      // For managed accounts who haven't submitted the Update Accepted Case call, we do that for them here
      if (!acceptedCaseSubmitted) {
        await API.post('offboard/updateAcceptedCase', null, {
          headers: { 'content-type': 'application/json' },
          params: { caseId: acceptedCaseId },
        })
        setAcceptedCaseSubmitted(true)
      }
      setState('done')
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  useEffect(() => {
    fetchInitialState()
  }, []) // eslint-disable-line

  function handleLogout() {
    window.open(`${API_URL.slice(0, -4)}logout`, '_self')
  }

  function render() {
    if (state === 'loading') {
      return (
        <div
          css={css`
            padding: 20px;
          `}>
          <Loading />
        </div>
      )
    }

    if (state === 'error') {
      return (
        <div
          css={css`
            padding: 20px;
            color: crimson;
            text-align: center;
          `}>
          Something went wrong with the submission, please try again later
        </div>
      )
    }

    return (
      <div>
        <div
          css={css`
            color: #4a606a;
            font-size: 50px;
            font-weight: 300;
            line-height: 1.35;
          `}>
          Modifications: complete!
        </div>

        <div
          css={css`
            color: #038ab7;
            font-size: 24px;
            line-height: 1.35;
            margin: 90px 2rem 0;
          `}>
          GuidedChoice will now:
        </div>

        <IconGroup>
          <div>
            <div>
              <ImplementChangesIcon />
            </div>
            <div>
              Implement the changes requested to your account's savings rate and/or retirement age
            </div>
          </div>

          <div>
            <div>
              <ManageRiskIcon />
            </div>
            <div>
              Minimize risk — while maximizing reward — for your personalized, diversified portfolio
            </div>
          </div>

          <div>
            <div
              css={css`
                padding: 0 35px;
              `}>
              <AutoAdjustRiskIcon
                css={css`
                  width: 85%;
                  height: auto;
                  margin-bottom: 52px;
                `}
              />
            </div>
            <div>Make any necessary adjustments to your risk level </div>
          </div>
        </IconGroup>

        <div
          css={css`
            color: #4a606a;
            font-size: 16px;
            line-height: 1.35;
            margin: 2rem;
          `}>
          You should receive an email confirmation shortly.
        </div>

        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}>
          <Button primary label='Logout' onClick={handleLogout} width='135px' />
        </div>
      </div>
    )
  }

  return <Observer>{render}</Observer>
}

export default inject('store', 'offboarding')(ModificationsComplete)
