/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const active = 'rt-autocomplete-active'

const autocomplete = 'rt-autocomplete-autocomplete'

const focus = 'rt-autocomplete-focus'

const input = 'rt-autocomplete-input'

const suggestion = 'rt-autocomplete-suggestion'

const suggestions = 'rt-autocomplete-suggestions'

const up = 'rt-autocomplete-up'

const value = 'rt-autocomplete-value'

const values = 'rt-autocomplete-values'

const _autocomplete = css`
  padding: ${ds.get('unit')} 0;
  position: relative;

  ${ds.get('reset')};

  &.${focus} {
    & .${suggestions} {
      box-shadow: ${ds.get('shadow.zdepth1')};
      max-height: ${ds.get('autocomplete.overflowMaxHeight')};
      -ms-overflow-style: none;
      visibility: visible;
    }
  }
`

const _values = css`
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0 0 ${reduceCSSCalc(`calc(${ds.get('unit')} / 2)`)} 0;
`

const _value = css`
  margin: ${ds.get('autocomplete.valueMargin')};
`

const _suggestions = css`
  background-color: ${ds.get('autocomplete.suggestionsBackground')};
  list-style: none;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  transition-duration: ${ds.get('animation.duration')};
  transition-property: max-height, box-shadow;
  transition-timing-function: ${ds.get('animation.curveDefault')};
  visibility: hidden;
  width: 100%;
  z-index: ${ds.z('high')};

  &:not(.${up}) {
    margin-top: ${reduceCSSCalc(`calc(-1 * ${ds.get('input.padding')})`)};
  }

  &.${up} {
    bottom: 0;
  }

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`

const _suggestion = css`
  cursor: pointer;
  font-size: ${ds.get('input.fieldFontSize')};
  padding: ${ds.get('autocomplete.suggestionPadding')};

  &.${active} {
    background-color: ${ds.get('autocomplete.suggestionActiveBackground')};
  }
`

const _input = css`
  position: relative;

  &::after {
    border-left: ${ds.get('autocomplete.borderSize')} solid transparent;
    border-right: ${ds.get('autocomplete.borderSize')} solid transparent;
    border-top: ${ds.get('autocomplete.borderSize')} solid ${ds.get('input.textBottomBorderColor')};
    content: '';
    height: 0;
    pointer-events: none;
    position: absolute;
    right: ${ds.get('input.chevronOffset')};
    top: 50%;
    transition: transform ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')};
    width: 0;
  }
`

export default {
  active,
  autocomplete: cx(autocomplete, _autocomplete),
  focus,
  input: cx(input, _input),
  suggestion: cx(suggestion, _suggestion),
  suggestions: cx(suggestions, _suggestions),
  up,
  value: cx(value, _value),
  values: cx(values, _values),
}
