import { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import SpendDownOffboarding from '../offboarding/spend-down/Dashboard'
import Budget from './budget/Budget'
import Considerations from './considerations/ConsiderationDashboard'
import FinalizeChoices from './finalize-choices/FinalizeChoices'
import SpendDown from './SpendDown'

import { Loading, ErrorPage } from '../../components'

const Dashboard = function (props) {
  const {
    store: {
      setInitialInfoForSpendDown,
      isFirstTimeToSpendDown,
      isSpendDownSet,
      putFirstTimeToSpendDownFalse,
    },
  } = props

  const [status, setStatus] = useState('loading')

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isSpendDownSet) {
          await setInitialInfoForSpendDown()
        }

        setStatus('success')
      } catch (err) {
        setStatus('error')
        console.error(err)
      }
    }
    fetchData()

    const markFirstTimeAsFalse = async () => {
      try {
        putFirstTimeToSpendDownFalse()
      } catch (err) {
        console.error(err)
      }
    }

    // after component un-mounts we want to set first time to false
    return () => {
      if (isFirstTimeToSpendDown) {
        return markFirstTimeAsFalse()
      }
      return null
    }
  }, []) // eslint-disable-line

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return <ErrorPage />
  }

  return (
    <Switch>
      <Route path='/spending/budget' component={Budget} />
      <Route path='/spending/considerations' component={Considerations} />
      <Route path='/spending/finalize-choices' component={FinalizeChoices} />
      <Route path='/spending/implement' component={SpendDownOffboarding} />
      <Route path='/' component={SpendDown} />
    </Switch>
  )
}

export default inject('store')(observer(Dashboard))
