import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { BackButton, ButtonsBox, NextButton } from './styled'
import { Container, MainTitle, Spacer, Loading } from './'

class WizardStep extends Component {
  static displayName = 'WizardStep'

  static propTypes = {
    backButtonText: PropTypes.string,
    // children: PropTypes.node.isRequired,
    nextButtonText: PropTypes.string,
    onBackClick: PropTypes.func,
    onNextClick: PropTypes.func,
    showDefaultButtons: PropTypes.bool,
    title: PropTypes.string,
  }

  static defaultProps = {
    backButtonText: 'Back',
    nextButtonText: 'Next',
    showDefaultButtons: true,
  }

  render() {
    const {
      backButtonText,
      children,
      nextButtonText,
      onBackClick,
      onNextClick,
      showDefaultButtons,
      title,
      maxWidth,
      disableNextButton,
      disableBackButton,
      loading,
      serverError,
      store: {
        config: { template },
      },
    } = this.props

    return (
      <div
        css={css`
          max-width: ${maxWidth ? `${maxWidth}` : '1200px'};
          margin: auto;
          padding: 1rem;
        `}>
        <Container>
          {title && <MainTitle>{title}</MainTitle>}
          {!!serverError && (
            <>
              <Spacer space='8px' />
              <div
                css={css`
                  color: #e31e27;
                  font-size: 14px;
                  text-align: center;
                  line-height: 1.5;
                `}>
                We apologize for the inconvenience, but there appears to be a glitch that is
                preventing your information from updating to our database. Please try again. You may
                need to log out and re-enter. If you continue to receive this message, please let
                the{' '}
                {template.supportGroup ? template.supportGroup : 'GuidedChoice Customer Service'}{' '}
                know.
              </div>
            </>
          )}
          <Spacer space='8px' />
          {children}
        </Container>
        {loading ? (
          <ButtonsBox maxWidth={maxWidth}>
            <Loading />
          </ButtonsBox>
        ) : (
          showDefaultButtons && (
            <ButtonsBox maxWidth={maxWidth}>
              {
                onBackClick ? (
                  <BackButton
                    disabled={disableBackButton}
                    backgroundColor='#FFFFFF'
                    onClick={onBackClick}>
                    {backButtonText}
                  </BackButton>
                ) : (
                  <div />
                ) // need empty div to position next button properly
              }
              {onNextClick && (
                <NextButton disabled={disableNextButton} onClick={onNextClick}>
                  {nextButtonText}
                </NextButton>
              )}
            </ButtonsBox>
          )
        )}
      </div>
    )
  }
}

export default inject('store')(observer(WizardStep))
