const personalNormal = `
<?xml version="1.0" encoding="UTF-8"?>
<svg
viewBox="0 0 49 24"
version="1.1"
xmlns="http://www.w3.org/2000/svg"
xmlns:xlink="http://www.w3.org/1999/xlink"
>
    <title>-icons/help/2x-personal-normal</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
    id="Symbols"
    stroke="none"
    stroke-width="1"
    fill-rule="evenodd"
  >
  <g
  fill-rule="nonzero"
>
            <path d="M20.4852814,3.51471863 C18.3137085,1.34314575 15.3137085,0 12,0 L37,0 C43.627417,0 49,5.372583 49,12 C49,18.627417 43.627417,24 37,24 L12,24 C15.3137085,24 18.3137085,22.6568542 20.4852814,20.4852814 C18.3137085,22.6568542 15.3137085,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C15.3137085,0 18.3137085,1.34314575 20.4852814,3.51471863 Z M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z" id="Combined-Shape"></path>
            <text id="?" font-family="Aleo-Bold, Aleo" font-size="19" font-weight="bold" fill="#B6C0C4">
                <tspan x="8" y="19">?</tspan>
            </text>
            <g id="Group" transform="translate(26.000000, 4.000000)" fill-rule="nonzero" fill="#FFFFFF">
                <path d="M7.26014669,7.62246472 L7.26014669,7.62246472 C8.97905519,7.62246472 10.3725059,6.25738724 10.3725059,4.57347883 C10.3725059,2.88957042 8.97905519,1.52449294 7.26014669,1.52449294 C5.54123819,1.52449294 4.14778753,2.88957042 4.14778753,4.57347883 C4.14778753,6.25738724 5.54123819,7.62246472 7.26014669,7.62246472 L7.26014669,7.62246472 Z M7.26014669,9.14695766 L7.26014669,9.14695766 C4.68178394,9.14695766 2.59160794,7.09934144 2.59160794,4.57347883 C2.59160794,2.04761622 4.68178394,0 7.26014669,0 C9.83850945,0 11.9286854,2.04761622 11.9286854,4.57347883 C11.9286854,7.09934144 9.83850945,9.14695766 7.26014669,9.14695766 L7.26014669,9.14695766 Z" id="Shape"></path>
                <path d="M14.0056162,15.2449294 C14.0056162,11.8771126 11.2187149,9.14695766 7.78089791,9.14695766 C4.34308091,9.14695766 1.55617958,11.8771126 1.55617958,15.2449294 M0,15.2449294 C0,11.0351584 3.48362666,7.62246472 7.78089791,7.62246472 C12.0781692,7.62246472 15.5617958,11.0351584 15.5617958,15.2449294" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
`

export default personalNormal
