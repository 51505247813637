/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import { SecondaryButton, Drawer } from '../../../components'
import SliderInputField from './SliderInputField'

const Text = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.35;
  font-size: 1.5rem;
  padding: ${p => (p.padding ? p.padding : null)};
`
const BorderedCard = styled.div`
  border: ${p => p.theme.considerContainerBorder};
  border-radius: 8px;
  background-color: #${p => p.theme.considerContainerBgColor};
  margin: 10px 0;
  padding: 1rem;
`
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  padding: 1.75rem 0;
`
const ButtonBox = styled.div`
  width: 200px;
  border-radius: 4px;
  background: ${p => p.theme.buttonColor};
  cursor: pointer;
  padding: 8px;
  margin: 12px;
`
const ButtonText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 0.9rem;
  user-select: none;
  padding: 8px 0;
`
const Container = styled.div`
  max-width: 1200px;
`

const IncomeReplacementDrawer = props => {
  const { retirementIncomeGoalPct } = props.onboarding.person
  const { active, toggleDrawer, handleSave } = props
  const [percentValue, setPercentValue] = useState(retirementIncomeGoalPct?.percentValue ?? 80)
  const [inputValue, setInputValue] = useState(retirementIncomeGoalPct?.inputValue ?? 80)

  const handleInputChange = (name, value) => {
    value = parseInt(value, 10)
    if (isNaN(value)) {
      setPercentValue(0)
      setInputValue(null)
      return
    }
    setPercentValue(value)
    setInputValue(value)
  }

  const handleSlider = value => {
    setPercentValue(value)
    setInputValue(value)
  }

  return (
    <Drawer
      width='60%'
      title='Change the amount my strategy aims for'
      subtitle='Edit goal'
      active={active}
      fullMobile>
      <Container>
        <BorderedCard>
          <Text>Percent of income seeking after retiring</Text>
          <SliderInputField
            inputValue={inputValue}
            percentValue={percentValue}
            inputLabel='of current income'
            onChange={handleSlider}
            inputOnChange={handleInputChange}
            minPercentValue={5}
            maxPercentValue={150}
            step={5}
          />
        </BorderedCard>
        <ButtonGroup>
          <SecondaryButton onClick={toggleDrawer} label='Cancel' />
          <ButtonBox onClick={() => handleSave(inputValue)}>
            <ButtonText>Save & calculate results</ButtonText>
          </ButtonBox>
        </ButtonGroup>
      </Container>
    </Drawer>
  )
}

export default inject('onboarding')(observer(IncomeReplacementDrawer))
