import { css, useTheme } from '@emotion/react'
import { useState } from 'react'
import { inject, observer } from 'mobx-react'

import { Button, HelpIcon, TooltipText } from '../../../components'
import { Dialog, Checkbox } from '../../../guided-toolbox'

const RiskRestore = props => {
  const { savedScenarios } = props.store
  const [active, setActive] = useState(false)
  const [checked, setChecked] = useState('')
  const theme = useTheme()
  const openDialog = () => setActive(true)
  const closeDialog = () => {
    setActive(false)
    setChecked('')
  }

  const restoreRisk = () => {
    const { baseCase, recommendedCase, savedScenarios } = props.store
    const { form } = props
    if (checked === 'recommended') {
      form.change('riskLevel', recommendedCase.riskLevel)
      closeDialog()
    } else if (checked === 'base') {
      form.change('riskLevel', baseCase.riskLevel)
      closeDialog()
    } else if (checked !== '') {
      form.change('riskLevel', savedScenarios.slice()[checked].riskLevel)
      closeDialog()
    }
  }

  return (
    <div
      css={css`
        text-align: right;
      `}>
      <div>
        <Button
          secondary
          width='332px'
          label='Restore recommended or saved risk'
          size='small'
          onClick={openDialog}
        />
      </div>
      <Dialog active={active} onEscKeyDown={closeDialog} onOverlayClick={closeDialog}>
        <div
          css={css`
            font-size: 2rem;
            color: #4a606a;
            font-weight: 300;
            margin-bottom: 32px;
          `}>
          Select risk scenario to restore
        </div>
        <div
          css={css`
            padding: 0 20px;
          `}>
          <Checkbox
            checked={checked === 'recommended'}
            label='GuidedChoice recommendation'
            onChange={() => setChecked('recommended')}
          />
          <Checkbox
            checked={checked === 'base'}
            label='My current strategy'
            onChange={() => setChecked('base')}
          />
          {savedScenarios.slice().map((scenario, index) => (
            <Checkbox
              key={`saved_${index}`}
              checked={checked === `${index}`}
              label={scenario.name}
              onChange={() => setChecked(`${index}`)}
            />
          ))}
        </div>
        <div
          css={css`
            padding: 1rem 0 1rem 20px;
          `}>
          <HelpIcon helpLabel='Not listed' tooltip={TooltipText.scenarioNotListed()} />
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}>
          <Button secondary width='150px' label='Cancel' size='small' onClick={closeDialog} />
          <Button
            css={css`
              background: ${theme.recalculateButtonColor} !important;
            `}
            primary
            width='180px'
            label='Restore'
            size='small'
            onClick={restoreRisk}
            disabled={checked === ''}
          />
        </div>
      </Dialog>
    </div>
  )
}

export default inject('store')(observer(RiskRestore))
