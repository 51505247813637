import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import dayjs from 'dayjs'

import { Page } from '../elements'
import {
  Loading,
  CurrencyInput,
  TextInput,
  PrimaryButton,
  SecondaryButton,
  MultiButtonInput,
  HelpIcon,
  TooltipText,
  TextErrorField,
  YearPicker,
} from '../../../components'
import { reduceValidationError } from '../../../utils'
import {
  schema,
  calculator,
  initialize,
  calcTotalRemaining,
  prepareValuesForSubmit,
} from './GoalUtils'

class GoalForm extends Component {
  onSubmit = async values => {
    const requestValues = prepareValuesForSubmit(values)
    try {
      const {
        history,
        store: { updateGoal, addGoal },
        isEdit,
        handleClose,
      } = this.props

      if (isEdit) {
        await updateGoal(requestValues)
        handleClose()
      } else {
        await addGoal(requestValues)
        history.push('/goals')
      }
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  validate = values => {
    const {
      store,
      match: { params },
      isEdit,
    } = this.props
    const existingGoalNames = store.goalNames(params.id)
    const userCompletedGoal = isEdit && dayjs().isSame(values.endDate, 'year') && values.paid
    const totalRemainingValue = calcTotalRemaining(values)
    const totalRemaining =
      (totalRemainingValue > 0 && totalRemainingValue <= 100000000) || userCompletedGoal
        ? undefined
        : 'Amount must be from $1 to $100,000,000'
    return schema
      .validate(values, { abortEarly: false, context: { existingGoalNames } })
      .then(valid => ({ totalRemaining }))
      .catch(err => ({ ...reduceValidationError(err), totalRemaining }))
  }

  initialValues = () => {
    const { isEdit, foundGoal } = this.props
    return initialize({ foundGoal, isEdit })
  }

  render() {
    const { isEdit, handleClose } = this.props

    return (
      <Page.Container margin={isEdit ? '0' : '0 auto'}>
        <Form
          onSubmit={this.onSubmit}
          validate={this.validate}
          mutators={{ ...arrayMutators }}
          decorators={[calculator]}
          initialValues={this.initialValues()}
          subscription={{ submitting: true, submitError: true, values: true }}
          render={({ handleSubmit, submitting, submitError, values }) => {
            return (
              <Page.FormBox>
                <GoalName />
                <GoalType />

                {/* values deconstructed on call to handle observable updates */}
                <YearNeeded values={values} />
                <YearsNeeded values={values} />
                <SameAmount values={values} />
                <AmountPerYear values={values} />
                <Amounts values={values} />
                <TotalRemaining values={values} />
                <PaidField values={values} isEdit={isEdit} />
                <Page.ErrorMessage>{!submitting && submitError}</Page.ErrorMessage>

                <Field
                  name='totalRemaining'
                  subscription={{ touched: true, error: true }}
                  render={({ input, meta: { error, touched } }) => {
                    return (
                      <TextErrorField
                        error={error}
                        showError={
                          (values.isOneTime === true ||
                            (values.isOneTime === false && values.sameAmount !== null)) &&
                          touched
                        }
                      />
                    )
                  }}
                />

                {submitting ? (
                  <Loading />
                ) : (
                  <Page.ButtonsBox maxWidth='800px'>
                    <Page.LeftButtonBox>
                      <SecondaryButton
                        label='Cancel'
                        width='85px'
                        onClick={handleClose}
                        disabled={submitting}
                      />
                    </Page.LeftButtonBox>
                    <Page.RightButtonBox>
                      <PrimaryButton
                        label='Save'
                        width='85px'
                        onClick={handleSubmit}
                        disabled={submitting}
                      />
                    </Page.RightButtonBox>
                  </Page.ButtonsBox>
                )}
              </Page.FormBox>
            )
          }}
        />
      </Page.Container>
    )
  }
}

const GoalName = () => (
  <Page.FlexRow>
    <Page.Label>Goal name</Page.Label>

    <Field
      name='name'
      format={v => v}
      parse={v => v}
      subscription={{ value: true, touched: true, error: true }}
      render={({ input, meta }) => (
        <Page.ValueBox>
          <TextInput
            name={input.name}
            value={input.value}
            onChange={(name, value) => input.onChange(value)}
            onBlur={input.onBlur}
            expanded={input.value}
            error={meta.error}
            showError={meta.touched || (input.value || '').length > 32}
            disabled={false}
          />
        </Page.ValueBox>
      )}
    />
  </Page.FlexRow>
)

const GoalType = () => (
  <Page.FlexRow>
    <Page.Label>Goal type</Page.Label>

    <Page.ValueBox>
      <Field
        name='isOneTime'
        subscription={{ value: true, touched: true, error: true }}
        render={({ input, meta }) => (
          <MultiButtonInput
            name={input.name}
            value={input.value}
            onChange={(name, value) => input.onChange(value)}
            onBlur={input.onBlur}
            buttons={[
              { text: 'One-Time', value: true },
              { text: 'Multi-year', value: false },
            ]}
            error={meta.error}
            showError={meta.touched}
          />
        )}
      />
    </Page.ValueBox>
  </Page.FlexRow>
)

const YearNeeded = ({ values }) =>
  values.isOneTime === true ? (
    <Page.FlexRow>
      <Page.Label>Year needed</Page.Label>

      <Page.ValueBox>
        <Field
          name='startDate'
          render={({ input, meta }) => (
            <YearPicker
              name={input.name}
              value={input.value}
              onChange={(name, value) => input.onChange(value)}
              minYear={values.minYear}
              maxYear={dayjs().add(49, 'year').year()}
            />
          )}
        />
      </Page.ValueBox>
    </Page.FlexRow>
  ) : null

const YearsNeeded = ({ values }) =>
  values.isOneTime === false ? (
    <Page.FlexRow>
      <Page.Label>Years needed</Page.Label>

      <Page.ValueBox>
        <Page.Row>
          <Field
            name='startDate'
            render={({ input, meta }) => (
              <YearPicker
                name={input.name}
                value={input.value}
                onChange={(name, value) => input.onChange(value)}
                minYear={values.minYear}
                maxYear={dayjs().add(49, 'year').year()}
              />
            )}
          />
          <Page.SpanText>to&nbsp;</Page.SpanText>
          <Field
            name='endDate'
            render={({ input, meta }) => (
              <YearPicker
                name={input.name}
                value={input.value}
                onChange={(name, value) =>
                  input.onChange(dayjs(value).endOf('year').format('YYYY-MM-DD'))
                }
                minYear={dayjs(values.startDate).year()}
                maxYear={dayjs().add(49, 'year').year()}
              />
            )}
          />
        </Page.Row>
      </Page.ValueBox>
    </Page.FlexRow>
  ) : null

const SameAmount = ({ values }) =>
  values.isOneTime === false ? (
    <Page.FlexRow>
      <Page.Label>Is the amount needed the same each year?</Page.Label>
      <Page.ValueBox>
        <Field
          name='sameAmount'
          subscription={{ value: true, touched: true, error: true }}
          render={({ input, meta }) => (
            <MultiButtonInput
              name={input.name}
              value={input.value}
              onChange={(name, value) => input.onChange(value)}
              onBlur={input.onBlur}
              buttons={[
                { text: 'Same', value: true },
                { text: 'Varies', value: false },
              ]}
              error={meta.error}
              showError={meta.touched}
            />
          )}
        />
      </Page.ValueBox>
    </Page.FlexRow>
  ) : null

const AmountPerYear = ({ values }) =>
  values.isOneTime === true || (values.isOneTime === false && values.sameAmount === true) ? (
    <Page.FlexRow>
      <Page.Label>
        {values.isOneTime === true ? 'Future total needed' : 'Future amount needed per year'}
      </Page.Label>

      <Page.YearRow>
        <Field
          name='amountPerYear'
          format={value => (value === null ? undefined : value)}
          parse={v => v}
          validate={value => (!value ? 'Please enter an amount' : undefined)}
          render={({ input, meta }) => (
            <CurrencyInput
              name={input.name}
              value={input.value}
              placeholder='amount'
              onChange={(name, value) => input.onChange(value)}
              allowNegative={false}
              decimalPrecision={0}
              onBlur={input.onBlur}
              error={meta.error}
              showError={meta.touched}
              disabled={false}
            />
          )}
        />
      </Page.YearRow>
    </Page.FlexRow>
  ) : null

const Amounts = ({ values }) =>
  values.isOneTime === false && values.sameAmount === false ? (
    <FieldArray name='amounts'>
      {({ fields }) => (
        <Page.FlexRow>
          <Page.Label>Future amount needed per year</Page.Label>
          {fields.map((name, index) => (
            <Field
              key={name}
              name={name}
              render={({ input }) => (
                <Page.YearRow key={input.value.year}>
                  <Page.YearLabel>{input.value.year}</Page.YearLabel>
                  <Field
                    name={`${name}.amount`}
                    format={value => (value === null ? undefined : value)}
                    parse={v => v}
                    validate={value => (!value ? 'Please enter an amount' : undefined)}
                    render={({ input, meta }) => (
                      <CurrencyInput
                        name={input.name}
                        value={input.value}
                        placeholder='amount'
                        onChange={(name, value) => input.onChange(value)}
                        allowNegative={false}
                        decimalPrecision={0}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                        disabled={false}
                      />
                    )}
                  />
                </Page.YearRow>
              )}
            />
          ))}
        </Page.FlexRow>
      )}
    </FieldArray>
  ) : null

const TotalRemaining = ({ values }) =>
  values.isOneTime === false && values.sameAmount !== null ? (
    <Page.FlexRow>
      <Page.Label>Future total needed</Page.Label>
      <Page.ValueBox>
        <CurrencyInput
          name='totalRemaining'
          value={calcTotalRemaining(values)}
          onChange={() => {}}
          readonly
          disabled
        />
      </Page.ValueBox>
    </Page.FlexRow>
  ) : null

const PaidField = ({ values, isEdit }) =>
  isEdit && dayjs().isBetween(values.startDate, values.endDate, 'year', '[]') ? (
    <Page.FlexRow>
      <Page.Label>
        {values.isOneTime === true
          ? 'Has the amount needed been withdrawn?'
          : 'Has the amount needed for this year been withdrawn?'}
        <Page.SVGHelpContainer>
          <HelpIcon size='medium' tooltip={TooltipText.hasAmountBeenWithdrawn()} />
        </Page.SVGHelpContainer>
      </Page.Label>

      <Page.ValueBox>
        <Field
          name='paid'
          subscription={{ value: true, touched: true, error: true }}
          render={({ input, meta }) => (
            <MultiButtonInput
              name={input.name}
              value={input.value}
              onChange={(_, value) => input.onChange(value)}
              onBlur={input.onBlur}
              buttons={[
                { text: 'Yes', value: true },
                { text: 'No', value: false },
              ]}
              error={meta.error}
              showError={meta.touched}
            />
          )}
        />
      </Page.ValueBox>
    </Page.FlexRow>
  ) : null

export default withRouter(inject('store')(observer(GoalForm)))
