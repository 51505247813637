import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import dayjs from 'dayjs'
import _ from 'lodash'

import { API } from '../../../api'
import { Button, ButtonsBox, Spacer, Loading } from '../../../components'
import { placeholderSymbolReplace, reduceValidationError } from '../../../utils'
import { VALUATION_METHODS, TAX_TYPES, SECURITY_TYPES_TEXT } from '../../../constants'
import { typeToAPI } from '../utils'
import { GreyText } from '../styled'
import PreTaxForm, { preTaxSchema } from './forms/PreTaxForm'
import RothForm, { rothSchema } from './forms/RothForm'
import AfterTaxForm, { afterTaxSchema } from './forms/AfterTaxForm'
import { typeToInvestmentsAPI } from './investmentUtils'

class EditInvestment extends Component {
  static displayName = 'EditInvestment'

  state = {
    status: 'loading',
    investments: [],
    security: null,
    preTax: null,
    roth: null,
    afterTax: null,
  }

  componentDidMount() {
    const {
      investmentId,
      account: { findInvestmentById, findInvestmentsBySecurityId },
    } = this.props

    const investment = findInvestmentById(investmentId)

    if (investment) {
      const { securities: security } = investment
      const investments = findInvestmentsBySecurityId(security.id)
      const preTax = investments.find(investment => investment.isPreTax) || null
      const roth = investments.find(investment => investment.isRoth) || null
      const afterTax = investments.find(investment => investment.isAfterTax) || null

      this.setState({
        status: 'done',
        investments,
        security,
        preTax,
        roth,
        afterTax,
      })
    } else {
      this.onCancel()
    }
  }

  onCancel = () => {
    this.props.toggleEdit(null)
  }

  onSubmit = async values => {
    try {
      const { preTax, roth, afterTax } = this.state
      const { getInvestments } = this.props.account
      const { type, id } = this.props.match.params
      const { getAccounts } = this.props.store

      const isInstitutionalAccount = type === 'institutional'

      const initial = this.initialValues()

      // Compare the initial state values with the submitted values to determine what to do
      // if investment existed in initialValues but not in values, it means convert it to Not Invested,
      // though only do this once (i.e. multiple investments get switched to Not Invested)
      // delete the other investments normally, consistent behavior with update balances
      const deletePreTax = !!initial.preTax && values.preTax === null
      const deleteRoth = !!initial.roth && values.roth === null
      const deleteAfterTax = !!initial.afterTax && values.afterTax === null
      const NotInvested = [
        deletePreTax && preTax,
        deleteRoth && roth,
        deleteAfterTax && afterTax,
      ].filter(item => !!item)

      const ListOfPromises = []

      if (NotInvested.length > 0) {
        // convert Not Invested investment here and delete the rest
        NotInvested.reduce((accumulator, current) => {
          if (accumulator === false) {
            ListOfPromises.push(
              API.put(`${typeToAPI[type]}/${id}/investments`, {
                ...current,
                dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
                quantity: 0,
                totalCostBasis: 0,
                totalValue: 0,
                valuationMethod: VALUATION_METHODS.notInvested,
                taxType: TAX_TYPES.notInvested,
              })
            )
            return true
          } else {
            ListOfPromises.push(API.delete(`${typeToAPI[type]}/${id}/investments/${current.id}`))
            return accumulator
          }
        }, false)
      }

      // if investment was null before but is added now, add that new investment
      const addPreTax = initial.preTax === null && !!values.preTax
      const addRoth = initial.roth === null && !!values.roth
      const addAfterTax = initial.afterTax === null && !!values.afterTax
      const apiURL = typeToInvestmentsAPI({ type, id })

      if (addPreTax) {
        const {
          account: { participantId },
        } = this.props.account
        const security = getSnapshot(this.state.security)
        const { securityType, securityName: tickerName } = security
        const { purchaseDate, quantity, totalCostBasis, totalValue, valuationMethod } =
          values.preTax

        ListOfPromises.push(
          API.post(apiURL, {
            dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
            personId: participantId,
            securities: security,
            securityType,
            taxType: TAX_TYPES.preTax,
            tickerName,
            purchaseDate,
            quantity,
            totalCostBasis: totalCostBasis || 0,
            totalValue,
            valuationMethod,
          })
        )
      }

      if (addRoth) {
        const {
          account: { participantId },
        } = this.props.account

        const security = getSnapshot(this.state.security)
        const { securityType, securityName: tickerName } = security
        const { purchaseDate, quantity, totalCostBasis, totalValue, valuationMethod } = values.roth

        ListOfPromises.push(
          API.post(apiURL, {
            dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
            personId: participantId,
            securities: security,
            securityType,
            taxType: TAX_TYPES.roth,
            tickerName,
            purchaseDate,
            quantity,
            totalCostBasis: totalCostBasis || 0,
            totalValue,
            valuationMethod,
          })
        )
      }

      if (addAfterTax) {
        const {
          account: { participantId },
        } = this.props.account

        const security = getSnapshot(this.state.security)
        const { securityType, securityName: tickerName } = security
        const { purchaseDate, quantity, totalCostBasis, totalValue, valuationMethod } =
          values.afterTax

        ListOfPromises.push(
          API.post(apiURL, {
            dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
            personId: participantId,
            securities: security,
            securityType,
            taxType: TAX_TYPES.afterTax,
            tickerName,
            purchaseDate,
            quantity,
            totalCostBasis: totalCostBasis || 0,
            totalValue,
            valuationMethod,
          })
        )
      }

      // if investment existed in both initialValues and values, submit PUT update request if changed
      const updatePreTax = !!initial.preTax && !!values.preTax
      const updateRoth = !!initial.roth && !!values.roth
      const updateAfterTax = !!initial.afterTax && !!values.afterTax

      if (updatePreTax && !_.isEqual(initial.preTax, values.preTax)) {
        const { purchaseDate, quantity, totalCostBasis, totalValue, valuationMethod } =
          values.preTax
        const valuesToUpdate = {
          ...this.state.preTax,
          purchaseDate,
          quantity,
          totalCostBasis: totalCostBasis || 0,
          totalValue,
          valuationMethod,
        }
        isInstitutionalAccount
          ? ListOfPromises.push(API.put(`${typeToAPI[type]}/investments`, valuesToUpdate))
          : ListOfPromises.push(API.put(`${typeToAPI[type]}/${id}/investments`, valuesToUpdate))
      }

      if (updateRoth && !_.isEqual(initial.roth, values.roth)) {
        const { purchaseDate, quantity, totalCostBasis, totalValue, valuationMethod } = values.roth
        const valuesToUpdate = {
          ...this.state.roth,
          purchaseDate,
          quantity,
          totalCostBasis: totalCostBasis || 0,
          totalValue,
          valuationMethod,
        }
        isInstitutionalAccount
          ? ListOfPromises.push(API.put(`${typeToAPI[type]}/investments`, valuesToUpdate))
          : ListOfPromises.push(API.put(`${typeToAPI[type]}/${id}/investments`, valuesToUpdate))
      }

      if (updateAfterTax && !_.isEqual(initial.afterTax, values.afterTax)) {
        const { purchaseDate, quantity, totalCostBasis, totalValue, valuationMethod } =
          values.afterTax
        const valuesToUpdate = {
          ...this.state.afterTax,
          purchaseDate,
          quantity,
          totalCostBasis: totalCostBasis || 0,
          totalValue,
          valuationMethod,
        }
        isInstitutionalAccount
          ? ListOfPromises.push(API.put(`${typeToAPI[type]}/investments`, valuesToUpdate))
          : ListOfPromises.push(API.put(`${typeToAPI[type]}/${id}/investments`, valuesToUpdate))
      }

      await Promise.all(ListOfPromises)
      await Promise.all([getInvestments({ type, id }), getAccounts()])
      this.onCancel()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  initialValues = () => {
    let { preTax, roth, afterTax } = this.state

    if (preTax) {
      const { quantity, purchaseDate, totalCostBasis, totalValue, valuationMethod } = preTax
      preTax = { quantity, purchaseDate, totalCostBasis, totalValue, valuationMethod }
    }

    if (roth) {
      const { quantity, purchaseDate, totalCostBasis, totalValue, valuationMethod } = roth
      roth = { quantity, purchaseDate, totalCostBasis, totalValue, valuationMethod }
    }

    if (afterTax) {
      const { quantity, purchaseDate, totalCostBasis, totalValue, valuationMethod } = afterTax
      afterTax = { quantity, purchaseDate, totalCostBasis, totalValue, valuationMethod }
    }

    const initialValues = {
      preTax,
      roth,
      afterTax,
    }

    return initialValues
  }

  validate = async values => {
    const preTax = values.preTax
      ? await preTaxSchema
          .validate(values.preTax, { abortEarly: false })
          .then(valid => {})
          .catch(err => reduceValidationError(err))
      : undefined

    const roth = values.roth
      ? await rothSchema
          .validate(values.roth, { abortEarly: false })
          .then(valid => {})
          .catch(err => reduceValidationError(err))
      : undefined

    const afterTax = values.afterTax
      ? await afterTaxSchema
          .validate(values.afterTax, { abortEarly: false })
          .then(valid => {})
          .catch(err => reduceValidationError(err))
      : undefined

    return _.pickBy({ preTax, roth, afterTax }, _.identity)
  }

  render() {
    if (this.state.status === 'loading') {
      return <Loading />
    }

    const {
      account: { preTaxContribAllowed, rothContribAllowed, posttaxContribAllowed, type },
    } = this.props.account
    const { security } = this.state
    const { securityName, securityType, ticker } = security

    return (
      <div>
        <GreyText>{SECURITY_TYPES_TEXT[securityType]}</GreyText>

        <Spacer space='10px' />

        {ticker && (
          <div>
            <GreyText>{ticker}</GreyText>
            <Spacer space='10px' />
          </div>
        )}

        <GreyText>{placeholderSymbolReplace(securityName)}</GreyText>

        <Spacer space='20px' />

        <Form
          onSubmit={this.onSubmit}
          validate={this.validate}
          initialValues={this.initialValues()}
          subscription={{ values: true, submitting: true, submitError: true }}
          render={({ values, handleSubmit, submitting, submitError }) => (
            <div>
              {(preTaxContribAllowed || values.preTax) && (
                <PreTaxForm security={security} type={type} />
              )}

              {(rothContribAllowed || values.roth) && <RothForm security={security} type={type} />}

              {(posttaxContribAllowed || values.afterTax) && (
                <AfterTaxForm security={security} type={type} />
              )}

              <ButtonsBox>
                <Button
                  label='Cancel'
                  onClick={this.onCancel}
                  disabled={submitting}
                  secondary
                  width='85px'
                />
                <Button
                  label='Save'
                  onClick={handleSubmit}
                  disabled={submitting}
                  primary
                  width='85px'
                />
              </ButtonsBox>
            </div>
          )}
        />
      </div>
    )
  }
}

export default withRouter(inject('store', 'account')(observer(EditInvestment)))
