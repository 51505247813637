import { css } from '@emotion/react'
import styled from '@emotion/styled'

import logo from '../../../../assets/images/GuidedChoice-logo.png'
import { Loading } from '../../../../components'

const Page = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
`

Page.Loading = () => (
  <div
    css={css`
      margin: 20px;
    `}>
    <Loading />
  </div>
)

Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`

Page.Header = () => (
  <div
    css={css`
      display: flex;
      justify-content: flex-end;
      max-width: 800px;
      margin: 0 auto 40px;
    `}>
    <div
      css={css`
        max-width: 220px;
        padding: 2px 10px;
      `}>
      <img
        css={css`
          width: 100%;
        `}
        src={logo}
        alt='Logo'
      />
    </div>
  </div>
)

Page.Title = styled.div`
  color: #4a606a;
  font-size: 2.5rem;
  line-height: 1.35;
`

Page.ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 36px 0 60px;
  flex-wrap: wrap-reverse;
`

Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`

Page.WarningText = styled.div`
  color: #4a606a;
  font-size: 1.125rem;
  line-height: 33px;
  margin-top: 60px;
`

Page.ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 115px;
`

export default Page
