import { SECURITY_TYPES_TEXT } from '../../../../constants'
import { FlexRowBetween } from '../../update-balances/UpdateInvestmentListItemStyle'
import { Name, Ticker, Type } from './InvestmentGroupItem'
import { InvestmentListItemBox } from '../../styled'

function AvailableInvestment(props) {
  const { investment } = props

  return (
    <InvestmentListItemBox style={{ cursor: 'pointer' }}>
      <FlexRowBetween>
        <Type>{SECURITY_TYPES_TEXT[investment.securityType]}</Type>
        <Ticker>{investment.ticker}</Ticker>
        <Name>{investment.securityName}</Name>
      </FlexRowBetween>
    </InvestmentListItemBox>
  )
}

export default AvailableInvestment
