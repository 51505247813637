import styled from '@emotion/styled'

const Wrapper = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
  font-size: 14pt;
`

/// /////////////////////////////////////////////////////////////
// Before Purchase Page Styling
/// /////////////////////////////////////////////////////////////

Wrapper.BeforePurchaseTitle = styled.div`
  font-size: 28pt;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 38px;
`
Wrapper.BeforePurchaseWrapper = styled.div`
  padding: 0 24pt;
  min-width: 495pt;
`
Wrapper.BeforePurchaseBlueBox = styled.div`
  height: 107pt;
  width: 495pt;
  background-color: rgba(79, 173, 204, 0.2);
  margin: auto;
`
Wrapper.BeforePurchaseBlueBoxText = styled.div`
  font-size: 16pt;
  line-height: 20pt;
  text-align: center;
  max-width: 300pt;
  padding-top: 10pt;
  margin: auto;
`
Wrapper.BeforePurchaseBlueBoxAmount = styled.div`
  font-size: 24pt;
  line-height: 38pt;
  text-align: center;
  padding-top: 10pt;
  margin: auto;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.BeforePurchaseHowTo = styled.div`
  font-size: 20pt;
  line-height: 20pt;

  &::after {
    content: '…four choices to make, but first:';
    color: ${p => p.theme.lightestGray};
    font-size: 14pt;
  }
`
Wrapper.BeforePurchaseGreyBox = styled.div`
  margin: auto;
  width: 100%;
  background-color: #e6e9ea;
  padding: 10pt;
`
Wrapper.BeforePurchaseGreyBoxTitle = styled.div`
  color: #038ab7;
  font-size: 18pt;
  font-weight: 600;
  line-height: 18pt;
`
Wrapper.BeforePurchaseGreyBoxSubTitle = styled.div`
  padding-top: 5pt;
  color: #038ab7;
  font-size: 16pt;
  line-height: 18pt;
`
Wrapper.BeforePurchaseGreyBoxText = styled.p`
  padding-top: 5pt;
  color: ${p => p.theme.lightestGray};
  line-height: 18pt;
`

/// /////////////////////////////////////////////////////////////
// Annuity Tutorial Page Styling
/// /////////////////////////////////////////////////////////////

Wrapper.TutorialStep = styled.div`
  font-size: 20pt;
  font-weight: 600;
  line-height: 20pt;
`
Wrapper.TutorialGreyBox = styled.div`
  width: 532pt;
  background-color: #e6e9ea;
  padding: 10pt;
  margin-top: 8pt;
`
Wrapper.TutorialGreyBoxTitle = styled.div`
  color: #038ab7;
  font-size: 16pt;
  line-height: 18pt;
`
Wrapper.TutorialGreyBoxText = styled.div`
  padding-top: 2pt;
  color: ${p => p.theme.lightestGray};
  line-height: 18pt;
`
Wrapper.TutorialWrapper = styled.div`
  padding: 0 20pt;
`
Wrapper.TutorialTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 18pt;
  font-weight: 600;
  line-height: 18pt;
  padding-top: 8pt;
`
Wrapper.TutorialText = styled.div`
  color: ${p => p.theme.lightestGray};
  line-height: 18pt;
`
Wrapper.TutorialSecondaryGreyBox = styled.div`
  height: 117pt;
  width: 495pt;
  background-color: #e6e9ea;
  margin-top: 8pt;
  padding: 10pt;
`
Wrapper.TutorialSecondaryGreyBoxTitle = styled.div`
  color: #038ab7;
  font-size: 16pt;
  font-weight: 600;
  line-height: 18pt;
`
Wrapper.TutorialSecondaryGreyBoxText = styled.div`
  padding-top: 2pt;
  color: ${p => p.theme.lightestGray};
  line-height: 18pt;
`

/// /////////////////////////////////////////////////////////////
// Annuity Tutorial Page 2 Styling
/// /////////////////////////////////////////////////////////////

Wrapper.Tutorial2Title = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 19pt;
  font-weight: 600;
  font-size: 18pt;
  padding: 0 20pt;
`
Wrapper.Tutorial2Paragraph = styled.div`
  color: ${p => p.theme.mediumGray};
  padding: 0 20pt;
`
Wrapper.Tutorial2StepText = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
  padding: 0 20pt;
`
Wrapper.Tutorial2ListItem = styled.li`
  color: ${p => p.theme.mediumGray};
  line-height: 19pt;
  margin-left: 25pt;
`

/// /////////////////////////////////////////////////////////////
// Annuity Tutorial Page 3 Styling
/// /////////////////////////////////////////////////////////////

Wrapper.Tutorial3GreyBox = styled.div`
  width: 532pt;
  background-color: #e6e9ea;
  padding: 18pt;
`
Wrapper.Tutorial3Wrapper = styled.div`
  padding: 0 20pt;
`
Wrapper.Tutorial3GreyBoxTitle = styled.div`
  color: #038ab7;
  font-size: 16pt;
  line-height: 18pt;
  padding-bottom: 5pt;
`
Wrapper.Tutorial3GreyBoxParagraph = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
`
Wrapper.Tutorial3Title = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 18pt;
  font-weight: 600;
  line-height: 18pt;
  padding-bottom: 5pt;
`
Wrapper.Tutorial3Paragraph = styled.div`
  line-height: 18pt;
`

/// /////////////////////////////////////////////////////////////
// Annuity Tutorial About Page Styling
/// /////////////////////////////////////////////////////////////

Wrapper.AboutPageTitle = styled.div`
  font-size: 28pt;
  font-weight: 300;
  line-height: 38px;
  padding-bottom: 32pt;
`
Wrapper.AboutPageWrapper = styled.div`
  padding: 0 20pt;
`
Wrapper.AboutBlueBox = styled.div`
  width: 513pt;
  background-color: rgba(79, 173, 204, 0.2);
  padding: 18pt;
`
Wrapper.AboutBlueBoxTitle = styled.div`
  font-size: 18pt;
  font-weight: 600;
  line-height: 20pt;
  padding-bottom: 20pt;
`
Wrapper.AboutBlueBoxText = styled.div`
  font-size: 16pt;
  line-height: 20pt;
  text-align: center;
`
Wrapper.AboutBlueBoxAnnuityAmount = styled.div`
  font-size: 24pt;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  padding: 10pt 0;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.AboutBlueBoxAnnuityMonthlyAmount = styled.span`
  font-size: 20pt;
  line-height: 20px;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.AboutBlueBoxSecondaryText = styled.div`
  width: 475pt;
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
  text-align: center;
  padding-top: 10pt;
  color: ${p => p.theme.lightestGray};
`
Wrapper.AboutBlueBoxSecondaryTextAmount = styled.span`
  line-height: 18pt;
  color: ${p => p.theme.darkestGray};

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.AboutBlueBoxSecondaryTextAge = styled.span`
  line-height: 18pt;
  color: ${p => p.theme.darkestGray};
`
Wrapper.AboutDisclaimerTitle = styled.div`
  font-size: 16pt;
  line-height: 20pt;
  text-align: center;
  padding-bottom: 5pt;
`
Wrapper.AboutDisclaimer = styled.div`
  line-height: 20pt;
  text-align: center;
  color: ${p => p.theme.mediumGray};
`
/// /////////////////////////////////////////////////////////////
// Primary Annuity Page Styling
/// /////////////////////////////////////////////////////////////

Wrapper.PrimaryAnnuityPageTitle = styled.div`
  width: 533pt;
  font-size: 28pt;
  font-weight: 300;
  line-height: 38pt;
`
Wrapper.PrimaryAnnuityFromWhichAccounts = styled.div`
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
  padding-top: 5pt;
`
Wrapper.PrimaryAnnuityDisclaimer = styled.div`
  line-height: 20pt;
`
Wrapper.PrimaryAnnuityFundsNeededSellFromTitle = styled.div`
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
  margin-top: 15pt;

  &::before {
    content: 'From: ';
    color: ${p => p.theme.mediumGray};
  }
`
Wrapper.PrimaryAnnuityFlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & > * {
    padding-right: 20px;
  }
`
Wrapper.PrimaryAnnuityFundsNeededSellFromType = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 14pt;
  font-weight: 600;
  line-height: 38pt;
  padding-left: 51pt;
`
Wrapper.PrimaryAnnuityFundsNeededSellFromSubType = styled.div`
  font-size: 14pt;
  line-height: 24pt;
  padding-left: 40pt;
`
Wrapper.PrimaryAnnuityFundsNeededSellFromSubTypeAmount = styled.div`
  font-size: 14pt;
  line-height: 24pt;
  padding-left: 40pt;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.PrimaryAnnuityFundsNeededSellFromTypeAmount = styled.div`
  font-size: 14pt;
  font-weight: 600;
  line-height: 38pt;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.PrimaryAnnuityFundsNeededSellFromGrandTotal = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
`
Wrapper.PrimaryAnnuityFundsNeededSellFromGrandTotalAmount = styled.div`
  font-size: 16pt;
  font-weight: 600;
  line-height: 38pt;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.PrimaryAnnuityGrandTotalDisclaimer = styled.div`
  font-size: 16pt;
  line-height: 20pt;
  text-align: center;
  max-width: 430pt;
  margin: auto;
`

export { Wrapper }
