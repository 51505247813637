/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'

const icon = 'rt-menu-icon'

const iconMenu = 'rt-menu-iconMenu'

const active = 'rt-menu-active'

const bottomLeft = 'rt-menu-bottomLeft'

const bottomRight = 'rt-menu-bottomRight'

const menu = 'rt-menu-menu'

const menuInner = 'rt-menu-menuInner'

const outline = 'rt-menu-outline'

const ripple = 'rt-menu-ripple'

const rippled = 'rt-menu-rippled'

const staticClass = 'rt-menu-staticClass'

const topLeft = 'rt-menu-topLeft'

const topRight = 'rt-menu-topRight'

const menuDivider = 'rt-menu-menuDivider'

const caption = 'rt-menu-caption'

const disabled = 'rt-menu-disabled'

const menuItem = 'rt-menu-menuItem'

const selected = 'rt-menu-selected'

const shortcut = 'rt-menu-shortcut'

const _iconMenu = css`
  display: inline-block;
  position: relative;
  text-align: center;

  ${ds.get('reset')};

  & .${icon} {
    cursor: pointer;
  }
`

const _menu = css`
  display: inline-block;
  position: relative;

  &.${topLeft} {
    left: 0;
    position: absolute;
    top: 0;

    & > .${outline} {
      transform-origin: 0 0;
    }
  }

  &.${topRight} {
    position: absolute;
    right: 0;
    top: 0;

    & > .${outline} {
      transform-origin: 100% 0;
    }
  }

  &.${bottomLeft} {
    bottom: 0;
    left: 0;
    position: absolute;

    & > .${outline} {
      transform-origin: 0 100%;
    }
  }

  &.${bottomRight} {
    bottom: 0;
    position: absolute;
    right: 0;

    & > .${outline} {
      transform-origin: 100% 100%;
    }
  }

  &:not(.${staticClass}) {
    pointer-events: none;
    z-index: ${ds.z('higher')};

    & > .${outline} {
      opacity: 0;
      transform: scale(0);
      transition: transform ${ds.get('menu.expandDuration')} ${ds.get('animation.curveDefault')},
        opacity ${ds.get('menu.fadeDuration')} ${ds.get('animation.curveDefault')};
      will-change: transform;
    }

    & > .${menuInner} {
      left: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 0;
    }

    &.${rippled}:not(.${active}) {
      & > .${outline} {
        transition-delay: ${ds.get('menu.rippleDelay')};
      }

      & > .${menuInner} {
        transition-delay: ${ds.get('menu.rippleDelay')};
      }
    }

    &.${active} {
      pointer-events: all;

      & > .${outline} {
        opacity: 1;
        transform: scale(1);
      }

      & > .${menuInner} {
        opacity: 1;
        transition: opacity ${ds.get('menu.fadeDuration')} ${ds.get('animation.curveDefault')},
          clip ${ds.get('menu.expandDuration')} ${ds.get('animation.curveDefault')};
      }
    }
  }
`

const _outline = css`
  background-color: ${ds.get('menu.backgroundColor')};
  border-radius: ${ds.get('menu.outlineBorderRadius')};
  box-shadow: ${ds.get('shadow[2]')};
  display: block;
  left: 0;
  position: absolute;
  top: 0;
`

const _menuInner = css`
  display: block;
  list-style: none;
  padding: ${ds.get('menu.padding')};
  position: relative;
  text-align: left;
  white-space: nowrap;
`

const _menuItem = css`
  align-items: center;
  color: ${ds.get('colors.rt.text')};
  display: flex;
  font-size: ${ds.get('menu.itemFontSize')};
  height: ${ds.get('menu.itemHeight')};
  overflow: hidden;
  padding: 0 ${ds.get('menu.itemPadding')};
  position: relative;

  &:not(.${disabled}):hover {
    background-color: ${ds.get('menu.itemHoverBackground')};
    cursor: pointer;
  }

  &.${disabled} {
    opacity: 0.5;
    pointer-events: none;
  }

  &.${selected} {
    background-color: ${ds.get('menu.itemSelectedBackground')};
    font-weight: 500;
  }

  & .${ripple} {
    color: ${ds.get('colors.rt.textSecondary')};
  }

  & .${icon} {
    font-size: ${ds.get('menu.itemIconFontSize')};
    width: ${ds.get('menu.itemIconSize')};
  }
`

const _caption = css`
  flex-grow: 1;
  font-size: ${ds.fs('base')};
`

const _shortcut = css`
  margin-left: ${ds.get('menu.itemPadding')};
`

const _menuDivider = css`
  background-color: ${ds.get('colors.rt.divider')};
  border: 0;
  display: block;
  height: 1px;
  margin: ${ds.get('menu.dividerHeight')} 0;
  outline: 0;
  padding: 0;
  width: 100%;
`

export default {
  icon,
  iconMenu: cx(iconMenu, _iconMenu),
  active,
  bottomLeft,
  bottomRight,
  menu: cx(menu, _menu),
  menuInner: cx(menuInner, _menuInner),
  outline: cx(outline, _outline),
  rippled,
  static: staticClass,
  topLeft,
  topRight,
  menuDivider: cx(menuDivider, _menuDivider),
  caption: cx(caption, _caption),
  disabled,
  menuItem: cx(menuItem, _menuItem),
  selected,
  shortcut: cx(shortcut, _shortcut),
}
