const flatMoneyBagSVG = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#FFFFFF;stroke:#7A8E96;stroke-width:0.4;}
    .st1{fill:none;stroke:#7A8E96;stroke-width:0.4;}
  </style>
  <g>
    <g id="Layer_1_1_">
      <title>Group</title>
    </g>
    <path class="st0" d="M3.6,8.8c-1.4,1.6-4.2,0.5-3.2,3.8c0.7,2.6,4.1,1.9,7.5,2.6c2.5,0.5,5.4,0.6,8.2-1c2.7-1.5,5.3-1.4,2.7-5.3
      c-1.7-2.6-6.7-1.8-7.2-1.7C11,7.2,5.1,7.2,3.6,8.8z"/>
    <path id="Shape-Copy-6_3_" class="st0" d="M17.7,7.8l1.9-0.5c0.2,0,0.2-0.2,0-0.3l-0.5-0.3c-0.1-0.1-0.4-0.2-0.6-0.2l-0.8-0.1
      c-0.2,0-0.4-0.1-0.5-0.2l-0.7-0.6c-0.1-0.1-0.4-0.2-0.5-0.2l-1,0.2c-0.2,0-0.4,0-0.6-0.1l-0.7-0.4C13.6,5,13.4,5,13.2,5
      c-0.3,0-0.6,0-0.6,0c-0.2,0-0.2,0.1-0.1,0.2l1.2,1.5L17.7,7.8z"/>
    <path id="Shape-Copy-5_3_" class="st0" d="M17.6,8.6c0.2,0,0.3-0.1,0.4-0.2c0-0.2-0.1-0.4-0.3-0.5l-3.8-1.1h-0.1
      c-0.2,0-0.5,0.1-0.5,0.2s0,0.3,0.2,0.4S17.5,8.6,17.6,8.6z"/>
    <g>
      <path id="Shape-Copy-3_3_" class="st1" d="M9.9,12.8c0.6,0.3,1.4-0.1,1.8-0.9c0.4-0.7,0.3-1.5-0.3-1.8c-1-0.5-2.4,2-3.4,1.5
        c-0.5-0.2-0.7-0.8-0.2-1.5c0.3-0.7,0.9-0.9,1.4-0.7"/>
      <line id="Shape-Copy-2_3_" class="st1" x1="13.2" y1="12.7" x2="11.7" y2="12.1"/>
      <line id="Shape-Copy_3_" class="st1" x1="7.6" y1="10.2" x2="6" y2="9.4"/>
    </g>
    <path class="st1" d="M18.3,10.6c0,0,0.6,1.1-2.7,2.2"/>
    <path id="Shape-Copy-5_1_" class="st0" d="M13.5,7.4c0.2,0.1,3,0.9,4.1,1.2"/>
  </g>
  </svg>
`

export default flatMoneyBagSVG
