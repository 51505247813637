// Determines the API type when adding an account
export const accountTypeToType = {
  '401k': 'non-gc',
  '403b': 'non-gc',
  '457': 'non-gc',
  'payroll-deduct-ira': 'non-gc',
  '401a': 'non-gc',
  'keogh': 'non-gc',
  'sep-ira': 'non-gc',
  'simple-ira': 'non-gc',
  // nonGC accounts below used to be personal accounts
  'ira-rollover': 'non-gc',
  'ira-roth': 'non-gc',
  'ira-deductible': 'non-gc',
  'ira-non-deductible': 'non-gc',
  'brokerage-account': 'non-gc',
  'annuity-fixed': 'annuity',
  'pension': 'pension',
  'other-asset': 'non-gc',
}
