const red500 = 'rgb(244, 67, 54)'
const green500 = 'rgb(76, 175, 80)'
const limea200 = 'rgb(238, 255, 65)'

const grey50 = 'rgb(250, 250, 250)'
const grey200 = 'rgb(238, 238, 238)'
const grey300 = 'rgb(224, 224, 224)'
const grey400 = 'rgb(189, 189, 189)'
const grey500 = 'rgb(158, 158, 158)'
const grey600 = 'rgb(117, 117, 117)'
const grey800 = 'rgb(66, 66, 66)'
const grey900 = 'rgb(33, 33, 33)'

const indigo500 = 'rgb(63, 81, 181)'
const indigo700 = 'rgb(48, 63, 159)'
const pinka200 = 'rgb(255, 64, 129)'
const pink700 = 'rgb(194, 24, 91)'

const black = 'rgb(0, 0, 0)'
const white = 'rgb(255, 255, 255)'
const darkContrast = white

const colors = {
  // Used with `ds.color('colorName')`
  colorPalette: {},

  rt: {
    red500,
    green500,
    limea200,
    grey50,
    grey300,
    grey200,
    grey400,
    grey500,
    grey600,
    grey800,
    grey900,
    indigo500,
    indigo700,
    pinka200,
    pink700,
    black,
    white,
    darkContrast,
    lightContrast: black,
    divider: grey200,
    background: white,
    text: grey900,
    textSecondary: grey600,
    primary: 'rgb(0, 138, 182)' || indigo500,
    primaryDark: 'rgb(0, 138, 182)' || indigo700,
    accent: pinka200,
    accentDark: pink700,
    primaryContrast: darkContrast,
    accentContrast: darkContrast,
  },

  // Used with `ds.brand('colorName')`
  brand: {
    black: 'black',
  },
}

export default colors
