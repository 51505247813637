import { Component } from 'react'
import { NumericFormat } from 'react-number-format'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Big from 'big.js'
import BaseInput from './BaseInput'

const StyledCurrencyFormat = styled.div`
  input {
    text-indent: 12px;
    &:disabled {
      border-bottom: none !important;
    }
  }
  &:before {
    position: absolute;
    content: '$';
    color: ${p => p.theme.lightestGray};
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: ${p => p.fontSize || '1.125rem'};
    line-height: 1.5rem;
  }
`

export class CurrencyInput extends Component {
  static defaultProps = {
    readonly: false,
    disabled: false,
    showError: false,
    allowNegative: false,
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    width: PropTypes.string,
    value: PropTypes.number,
    error: PropTypes.string,
    showError: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    readonly: PropTypes.bool,
    allowNegative: PropTypes.bool,
    decimalScale: PropTypes.number,
    fontSize: PropTypes.string,
  }

  state = {
    value: this.props.value ? parseFloat(Big(this.props.value).round(2)) : this.props.value,
    floatValue: this.props.value ? parseFloat(Big(this.props.value).round(2)) : this.props.value,
  }

  handleBlurAndUpdate() {
    const { name, onBlur, onChange } = this.props
    if (onBlur) {
      if (!isNaN(this.state.floatValue)) {
        onChange(name, this.state.floatValue)
      }
      onBlur(name)
    }
  }

  handleChange(values) {
    const { name, onChange } = this.props
    if (values.floatValue === null || isNaN(values.floatValue)) {
      values.floatValue = 0
    }
    if (onChange) {
      onChange(name, values.floatValue)
      this.setState({ value: values.value, floatValue: values.floatValue })
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps
    if (value !== this.state.value) {
      const newValue = value ? parseFloat(Big(value).round(2)) : value === 0 ? 0 : null
      this.setState({ value: newValue, floatValue: newValue })
    }
  }

  render() {
    const {
      allowNegative,
      decimalScale,
      disabled,
      error,
      fontSize,
      name,
      placeholder,
      readonly,
      showError,
      width,
      mobileWidth,
      marginTop,
    } = this.props

    const currentValue = this.state.value

    return (
      <BaseInput
        error={error}
        fontSize={fontSize}
        showError={showError}
        readonly={readonly}
        width={width}
        mobileWidth={mobileWidth}
        marginTop={marginTop}>
        <StyledCurrencyFormat fontSize={fontSize}>
          <NumericFormat
            name={name}
            value={currentValue || (currentValue === 0 ? 0 : undefined)}
            allowNegative={allowNegative}
            decimalScale={decimalScale}
            fixedDecimalScale
            placeholder={placeholder}
            onBlur={this.handleBlurAndUpdate.bind(this)}
            onValueChange={this.handleChange.bind(this)}
            thousandSeparator
            disabled={disabled}
          />
        </StyledCurrencyFormat>
      </BaseInput>
    )
  }
}

export default CurrencyInput
