/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import dayjs from 'dayjs'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import _ from 'lodash'
import createDecorator from 'final-form-calculate'

import {
  UsStatesDropdown,
  TextErrorField,
  Label,
  TextInput,
  NumberInput,
  ButtonInput,
  CurrencyInput,
  Spacer,
  Dropdown as DropdownElement,
} from '../../../components'
import { reduceValidationError, stringWhitelist } from '../../../utils'
import { Row, InputBox, Text, BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { stateKeyToValue } from '../../../constants/us-states'
import { Page } from './style'

const filterValuesForPatch = (values, current) => {
  return _.pickBy(values, (value, key) => value !== current[key])
}

const schema = yup.object().shape({
  preferredName: yup
    .string()
    .nullable()
    .test(
      'is-preferred-valid',
      'Preferred name must be from 1 to 15 characters',
      value => value === null || value === '' || (value.length > 0 && value.length < 16)
    )
    .test(
      'letter-check',
      'Must contain at least one letter',
      value => value === null || value === '' || /[a-zA-Z]/.test(value)
    )
    .test(
      'string-whitelist',
      'Special characters are not allowed',
      value => value === null || value === '' || stringWhitelist(value)
    ),
  gender: yup.string().nullable().required('Select one').oneOf(['F', 'M'], 'Select one'),
  otherIncome: yup.number().nullable().max(9999999, 'Amount must be less than $10,000,000'),
  retirementAge: yup
    .number()
    .nullable()
    .when(['$age', '$spendingMode'], (age, spendingMode, schema) => {
      const maxRetAge = Math.min(99, age + 55)
      const validAgesMessage = `Valid ages: 20 - ${maxRetAge}`
      const noLongerRetiredMessage =
        'If you are no longer considered retired by the government and wish ' +
        'to start saving for retirement again in an employer plan, click Cancel and contact us for help.'

      return spendingMode
        ? schema
            .required(validAgesMessage)
            .min(20, validAgesMessage)
            .max(age, noLongerRetiredMessage)
        : schema
            .required(validAgesMessage)
            .min(20, validAgesMessage)
            .max(maxRetAge, validAgesMessage)
    }),
  annualSalary: yup
    .number()
    .nullable()
    .required('Annual salary is required')
    .min(1, 'Must be greater than $0')
    .max(9999999, 'Salary must be less than $10,000,000'),
  taxState: yup
    .string()
    .nullable()
    .required('Select one')
    .oneOf(_.keys(stateKeyToValue), 'Select one'),
  maritalStatus: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  includeSpouse: yup
    .boolean()
    .nullable()
    .when('maritalStatus', {
      is: true,
      then: yup.boolean().oneOf([true, false], 'Select one'),
    }),
  planningWorkPartTime: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  durationStartingAge: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Duration: starting age is a required field')
            .test(
              'is-not-less-than-retirement-age',
              'Starting age for part-time work during retirement may not begin before the retirement age',
              function (value) {
                return this.parent.retirementAge <= value
              }
            )
        : schema
    ),
  durationStoppingAge: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Duration: stopping age is a required field')
            .test(
              'is-not-less-than-starting-age',
              'Stopping age cannot be before starting age',
              function (value) {
                return this.parent.durationStartingAge <= value
              }
            )
        : schema
    ),
  annualIncome: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Annual income is required')
            .min(1, 'Amount must be between $1 and $10,000,000')
            .max(9999999, 'Amount must be between $0 and $10,000,000')
        : schema
    ),
})

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

const calculator = createDecorator({
  field: 'maritalStatus',
  updates: {
    includeSpouse: (maritalStatus, allValues) =>
      maritalStatus === false ? false : allValues.includeSpouse,
  },
})

class AboutYouEdit extends Component {
  onSubmit = async ({
    firstName,
    preferredName,
    lastName,
    gender,
    birthDate,
    otherIncome,
    retirementAge,
    annualSalary,
    taxState,
    annualIncome,
    durationStartingAge,
    durationStoppingAge,
    planningWorkPartTime,
  }) => {
    try {
      const { onClose } = this.props
      const {
        account,
        person,
        updateAccount,
        updatePerson,
        getFeatures,
        savePrimaryRetirementStatus,
        primaryRetirementStatus,
      } = this.props.store

      const accountPatch = filterValuesForPatch(
        {
          annualSalary,
          taxState,
        },
        getSnapshot(account)
      )
      const accountPromise = _.isEmpty(accountPatch)
        ? Promise.resolve({})
        : updateAccount(accountPatch)

      const personPatch = filterValuesForPatch(
        {
          firstName,
          preferredName,
          lastName,
          gender,
          birthDate,
          otherIncome,
          retirementAge,
        },
        getSnapshot(person)
      )
      const personPromise = _.isEmpty(personPatch) ? Promise.resolve({}) : updatePerson(personPatch)

      await Promise.all([accountPromise, personPromise])

      const primaryRetirementUpdates = {
        ...primaryRetirementStatus,
        annualIncome,
        durationStartingAge,
        durationStoppingAge,
        planningWorkPartTime: planningWorkPartTime ? 1 : 0,
      }

      if (!_.isEqual(primaryRetirementStatus, primaryRetirementUpdates)) {
        await savePrimaryRetirementStatus({
          ...primaryRetirementStatus,
          annualIncome,
          durationStartingAge,
          durationStoppingAge,
          planningWorkPartTime,
        })
      }

      // to update enableSpendDown
      await getFeatures()

      onClose()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  validate = values => {
    const {
      person: { age },
      config: { spendingMode },
    } = this.props.store

    return schema
      .validate(values, { abortEarly: false, context: { age, spendingMode } })
      .then(valid => {})
      .catch(err => {
        return reduceValidationError(err)
      })
  }

  initialValues = () => {
    const {
      person: {
        firstName,
        preferredName,
        lastName,
        gender,
        birthDate,
        otherIncome,
        retirementAge,
        maritalStatus,
        includeSpouse,
      },
      account: { annualSalary, taxState },
      primaryRetirementStatus,
    } = this.props.store

    let initialValues = {
      firstName,
      preferredName,
      lastName,
      gender,
      birthDate,
      otherIncome,
      retirementAge,
      annualSalary,
      taxState,
      maritalStatus,
      includeSpouse,
    }

    let spendingInitialValues = {}

    if (primaryRetirementStatus) {
      spendingInitialValues = {
        annualIncome: primaryRetirementStatus.annualIncome,
        durationStartingAge: primaryRetirementStatus.durationStartingAge,
        durationStoppingAge: primaryRetirementStatus.durationStoppingAge,
        planningWorkPartTime: Boolean(primaryRetirementStatus.planningWorkPartTime),
      }
    }

    initialValues = {
      ...initialValues,
      ...spendingInitialValues,
    }

    // test for null cases
    // initialValues.firstName = null
    // initialValues.preferredName = null
    // initialValues.lastName = null
    // initialValues.gender = null
    // initialValues.birthDate = null
    // initialValues.otherIncome = null
    // initialValues.retirementAge = null
    // initialValues.annualSalary = null
    // initialValues.taxState = null
    // initialValues.annualIncome = null
    // initialValues.durationStartingAge = null
    // initialValues.durationStoppingAge = null
    // initialValues.includeSsiEstimate = null
    // initialValues.planningWorkPartTime = null

    return initialValues
  }

  render() {
    const { store, onClose } = this.props
    const { firstName, lastName, birthDate, retirementAge } = store.person

    const startingAge = () => {
      let result = 0
      if (!isNaN(retirementAge)) {
        result = retirementAge
      } else {
        result = 65
      }
      return result
    }

    return (
      <Form
        keepDirtyOnReinitialize
        initialValues={this.initialValues()}
        decorators={[calculator]}
        validate={this.validate}
        onSubmit={this.onSubmit}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ handleSubmit, submitting, form, values }) => (
          <div>
            <Row>
              <Label>Your first name</Label>
              <InputBox>
                <Text>{firstName}</Text>
              </InputBox>
            </Row>

            <Spacer space='4px' />
            <Row>
              <Label>Your preferred name</Label>
              <InputBox>
                <Field
                  name='preferredName'
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      maxLength={16}
                      name={input.name}
                      value={input.value}
                      onChange={(name, value) => input.onChange(value)}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      showError={meta.touched}
                      error={meta.error}
                    />
                  )}
                />
              </InputBox>
            </Row>

            <Spacer space='4px' />
            <Row>
              <Label>Your last name</Label>
              <InputBox>
                <Text>{lastName}</Text>
              </InputBox>
            </Row>

            <div
              css={css`
                padding-top: 48px;
              `}
            />
            <Row>
              <Label>Your gender</Label>
              <Field
                name='gender'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <InputBox>
                    <ButtonInput
                      name={input.name}
                      value='F'
                      isActive={input.value === 'F'}
                      text='Female'
                      onClick={(name, value) => input.onChange(value)}
                      width='126px'
                    />
                    <ButtonInput
                      name={input.name}
                      value='M'
                      isActive={input.value === 'M'}
                      text='Male'
                      onClick={(name, value) => input.onChange(value)}
                      width='126px'
                    />
                    <TextErrorField showError={meta.touched} error={meta.error} />
                  </InputBox>
                )}
              />
            </Row>

            <Spacer space='6px' />
            <Row>
              <Label>Your date of birth</Label>
              <InputBox>
                <Text>{birthDate ? dayjs(birthDate).format('MM/DD/YYYY') : ''}</Text>
              </InputBox>
            </Row>

            <div
              css={css`
                padding-top: 48px;
              `}
            />
            <Row>
              <Label>Your annual compensation before taxes</Label>
              <InputBox>
                <Field
                  name='annualSalary'
                  format={value => (value === null ? undefined : value)}
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <CurrencyInput
                      name={input.name}
                      value={input.value}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      showError={meta.touched}
                      error={meta.error}
                      decimalScale={0}
                    />
                  )}
                />
              </InputBox>
            </Row>
            <Row>
              <Label>Your other earned income, annually</Label>
              <InputBox>
                <Field
                  name='otherIncome'
                  format={value => (value === null ? undefined : value)}
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <CurrencyInput
                      name={input.name}
                      value={input.value}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      showError={meta.touched}
                      error={meta.error}
                    />
                  )}
                />
              </InputBox>
            </Row>

            <Page.MinSpaceInputs>
              <div
                css={css`
                  padding-top: 36px;
                `}
              />
              <Row>
                <Label>Your retirement age</Label>
                <InputBox>
                  <Field
                    name='retirementAge'
                    format={value => (value === null ? undefined : value)}
                    parse={v => v}
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <NumberInput
                        name={input.name}
                        value={input.value}
                        onChange={(name, value) => input.onChange(value)}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        showError={meta.touched}
                        error={meta.error}
                      />
                    )}
                  />
                </InputBox>
              </Row>

              <Label>
                <Spacer space='6px' />
                <Row>
                  <Label>Plan to work part-time after retiring?</Label>
                  <Field
                    name='planningWorkPartTime'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <InputBox>
                        <ButtonInput
                          name={input.name}
                          text='Yes'
                          value
                          isActive={input.value === true}
                          onClick={(name, value) => input.onChange(value)}
                          width='126px'
                        />
                        <ButtonInput
                          name={input.name}
                          text='No'
                          value={false}
                          isActive={input.value === false}
                          onClick={(name, value) => input.onChange(value)}
                          width='126px'
                        />
                        <TextErrorField showError={meta.touched} error={meta.error} />
                      </InputBox>
                    )}
                  />
                </Row>

                <Condition when='planningWorkPartTime' is>
                  <Spacer space='6px' />
                  <Row>
                    <Label>Duration: starting age</Label>
                    <Field
                      name='durationStartingAge'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <Dropdown
                          startingAge={values.retirementAge || startingAge()}
                          initialValue={input.value}
                          input={input}
                          meta={meta}
                        />
                      )}
                    />
                  </Row>
                </Condition>

                <Condition when='planningWorkPartTime' is>
                  <Spacer space='6px' />
                  <Row>
                    <Label>Duration: stopping age</Label>
                    <Field
                      name='durationStoppingAge'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <Dropdown
                          startingAge={values.retirementAge || startingAge()}
                          initialValue={input.value}
                          input={input}
                          meta={meta}
                        />
                      )}
                    />
                  </Row>
                </Condition>

                <Condition when='planningWorkPartTime' is>
                  <Spacer space='6px' />
                  <Row>
                    <Label>Annual income from in-retirement job</Label>
                    <Field
                      name='annualIncome'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <InputBox>
                          <CurrencyInput
                            error={meta.error}
                            name={input.name}
                            placeholder='0'
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onChange={(name, value) => input.onChange(value)}
                            showError={meta.touched}
                            value={input.value}
                            css={css`
                              font-size: 1.125rem;
                              line-height: 1.5rem;
                            `}
                          />
                        </InputBox>
                      )}
                    />
                  </Row>
                </Condition>

                <div
                  css={css`
                    padding-top: 36px;
                  `}
                />
              </Label>
            </Page.MinSpaceInputs>

            <Row>
              <Label>State in which file taxes</Label>
              <InputBox>
                <Field
                  name='taxState'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <UsStatesDropdown
                      name={input.name}
                      onBlur={this.handleBlur}
                      onChange={(name, value) => input.onChange(value)}
                      placeholder='Select'
                      selected={input.value || ''}
                      showError={meta.touched}
                      error={meta.error}
                    />
                  )}
                />
              </InputBox>
            </Row>

            <Spacer space='6px' />
            <ButtonsBox>
              <BackButton backgroundColor='#FFFFFF' onClick={onClose} disabled={submitting}>
                Cancel
              </BackButton>
              <NextButton onClick={handleSubmit} disabled={submitting}>
                Save
              </NextButton>
            </ButtonsBox>
          </div>
        )}
      />
    )
  }
}

export default inject('store')(observer(AboutYouEdit))

const Dropdown = ({ initialValue, input, meta, startingAge }) => {
  const ages = () => {
    const arr = []
    for (let i = startingAge; i <= 100; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }

  return (
    <div
      css={css`
        flex: 3 1 340px;
        padding: 0 10px;

        & ul > li {
          text-align: start;
        }
      `}>
      <DropdownElement
        error={meta.error}
        name={input.name}
        onBlur={input.onBlur}
        onChange={(name, value) => input.onChange(value)}
        options={ages()}
        placeholder={initialValue ? initialValue + '' : input.value + ''}
        selected={input.value}
        showError={meta.touched}
      />
    </div>
  )
}
