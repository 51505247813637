import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { NumericFormat } from 'react-number-format'

import { Card, CardField, HelpIcon, TooltipText } from '../../../components'

class ContactInfoCard extends Component {
  render() {
    const {
      features,
      contactInfo,
      communicationPreferences,
      config: { isRetail },
    } = this.props.store

    return (
      <Card
        title='Contact & communications'
        handleEdit={this.props.showContactInfo}
        cardWidth='900px'>
        <CardField
          type='profile'
          label='Contact email'
          value={contactInfo.email}
          css={css`
            & div:nth-of-type(2) {
              word-break: break-all;
            }
          `}
        />

        {(features.enableMobilePhone || features.enableDaytimePhone) && (
          <div
            css={css`
              padding-top: 32px;
            `}
          />
        )}
        {features.enableMobilePhone && (
          <CardField
            type='profile'
            label='Mobile'
            value={
              <NumericFormat
                displayType='text'
                format='(###) ###-####'
                value={contactInfo.mobilePhone || ''}
              />
            }
          />
        )}
        {features.enableDaytimePhone && (
          <CardField
            type='profile'
            label='Daytime, extension'
            value={
              <NumericFormat
                displayType='text'
                format={`(###) ###-####${contactInfo.phoneExtension ? ' x######' : ''}`}
                value={contactInfo.phone + contactInfo.phoneExtension || ''}
              />
            }
          />
        )}

        {features.enableAddress && (
          <div
            css={css`
              padding-top: 32px;
            `}
          />
        )}
        {features.enableAddress && !isRetail && (
          <CardField
            type='profile'
            label='Address'
            value={
              <div>
                {contactInfo.mailingAddress1 && (
                  <>
                    {contactInfo.mailingAddress1}
                    <br />
                  </>
                )}
                {contactInfo.mailingAddress2 && (
                  <>
                    {contactInfo.mailingAddress2}
                    <br />
                  </>
                )}
                {contactInfo.mailingCity && (
                  <>
                    {contactInfo.mailingCity}
                    <br />
                  </>
                )}
                {contactInfo.mailingState && contactInfo.mailingZip && (
                  <>
                    {contactInfo.mailingState} {contactInfo.mailingZip}
                    <br />
                  </>
                )}
                {contactInfo.mailingCountry && (
                  <>
                    {contactInfo.mailingCountry}
                    <br />
                  </>
                )}
              </div>
            }
          />
        )}
        {features.enableAddress && !isRetail && (
          <div
            css={css`
              padding-top: 16px;
            `}
          />
        )}
        <CardField
          type='profile'
          label={
            <div>
              Electronic delivery consent{' '}
              <HelpIcon size='mediumLarge' tooltip={TooltipText.electronicDeliveryConsent()} />
            </div>
          }
          value=''
        />
        {!isRetail && (
          <CardField
            type='profile'
            label={<div>GuidedChoice annual summary </div>}
            value={communicationPreferences.RECEIVE_ELECTRONIC_STATEMENTS ? 'On' : 'Off'}
          />
        )}
        <CardField
          type='profile'
          label={<div>Email communications </div>}
          value={communicationPreferences.RECEIVE_MARKETING_EMAILS ? 'On' : 'Off'}
        />
      </Card>
    )
  }
}

export default inject('store')(observer(ContactInfoCard))
