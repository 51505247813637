import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const Page = styled.div`
  font-family: 'Open Sans';
`
Page.Container = styled.div`
  word-wrap: break-word;
  color: ${p => p.theme.mediumGray};
  max-width: ${p => (p.narrow ? '650px' : p.handy ? '700px' : '800px')};
`
Page.Paragraph = styled.div`
  line-height: '1.5rem';
  font-size: '14px !important';
`
Page.Heading = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2.3rem;
  width: 100%;
  color: #222;
  -webkit-transition: 0.75s;
  transition: 0.75s;
  font-weight: 600;
  line-height: 1.25;
  word-break: break-word;
  margin: 40px auto;
  text-align: center;
  line-height: 60px;
`
Page.CongratsUl = styled.ul`
  padding-left: 20px;
  & > li {
    margin: 10px 0;
  }
`
Page.SubHeader = styled.div`
  color: #4a606a;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  line-height: 1.25;
`
Page.CongratsCustomLink = styled(Link)`
  cursor: pointer;
  color: ${p => p.theme.customLinkColor};
  text-decoration: none;
`
