import { css, useTheme } from '@emotion/react'
import { API_URL } from '../../../api'

const LogoHeader = props => {
  const theme = useTheme()
  // TODO: need svgs of all the logos so dimensions don't get messed up? OR just change the design around having all pngs
  return (
    <div
      css={css`
        height: auto; /* was 90px, 320px when using svg */
        text-align: right;
      `}>
      <img
        src={API_URL + theme.logo}
        alt='logo'
        css={css`
          width: 100%;
          height: 100%;
          max-width: 255px;
        `}
      />
    </div>
  )
}

export default LogoHeader
