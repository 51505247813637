/* eslint-disable react/jsx-handler-names */
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'

import { API } from '../../../api'
import {
  ButtonsBox,
  Button,
  WizardStep,
  InputField,
  MultiButtonInput,
  NumberInput,
  CurrencyInput,
  PercentInput,
  TooltipText,
} from '../../../components'
import { reduceValidationError } from '../../../utils'
import { accountTypeKeyToPlanTypeId } from '../../../constants'
import { accountTypeToAPI } from '../utils'
import { schema } from './PayoutUtils'

class AddPayout extends Component {
  componentDidMount() {
    // check if previous pension form values in account model are valid
  }

  validate = values => {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  handleSubmit = async values => {
    const { type, accountType } = this.props.match.params
    const {
      store,
      history,
      account: { account },
    } = this.props
    const {
      paymentType,
      pensionStartAge,
      monthlyPensionIncome,
      lumpsumreceived,
      survivorFraction,
    } = values

    // clean pension data
    // if (paymentType === 'M') form.change('lumpsumreceived', 'N')
    // if (paymentType === 'L') form.change('survivorFraction', 0)
    try {
      const response = await API.post(accountTypeToAPI[accountType], {
        editable: true,
        pensionName: account.pensionName,
        ownerId: account.ownerId,
        planType: accountTypeKeyToPlanTypeId[accountType],
        paymentType,
        pensionStartAge,
        monthlyPensionIncome,
        lumpsumreceived: paymentType === 'L' ? lumpsumreceived : 'N',
        survivorFraction: paymentType === 'M' ? survivorFraction || 0 : 0,
      })

      const { id } = response.data
      await store.getAccounts()
      await store.getAllPensions()
      history.push(`/accounts/${type}/${accountType}/${id}`)
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  handleCancel = () => {
    this.props.history.go(-4)
  }

  initialValues = () => {
    const { account } = this.props.account
    const {
      paymentType,
      pensionStartAge,
      monthlyPensionIncome,
      lumpsumreceived,
      survivorFraction,
    } = account

    return { paymentType, pensionStartAge, monthlyPensionIncome, lumpsumreceived, survivorFraction }
  }

  render() {
    const { account } = this.props.account

    if (!account) {
      return null
    }

    const { participantName } = account

    return (
      <Form
        onSubmit={this.handleSubmit}
        validate={this.validate}
        initialValues={this.initialValues()}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ handleSubmit, submitting, submitError, values }) => (
          <WizardStep
            showDefaultButtons={false}
            title="Let's get details about payouts"
            serverError={submitError}>
            <div>
              <Field
                name='paymentType'
                render={({ input, meta }) => (
                  <MultiButtonInput
                    label='Is the payout a lump sum or a monthly amount?'
                    name={input.name}
                    value={input.value}
                    buttons={[
                      { value: 'L', text: 'Lump Sum' },
                      { value: 'M', text: 'Monthly' },
                    ]}
                    onChange={(name, value) => input.onChange(value)}
                    onBlur={input.onBlur}
                    error={meta.error}
                    showError={meta.touched}
                  />
                )}
              />

              {(values.paymentType === 'L' || values.paymentType === 'M') && (
                <div>
                  <InputField
                    label={`At what age will ${participantName} ${
                      values.paymentType === 'L'
                        ? 'receive the lump sum payout'
                        : 'begin receiving benefits'
                    }?`}
                    tooltip={values.paymentType === 'L' && TooltipText.multiplePayoutOptions()}
                    helpLabel='Multiple payout options'>
                    <Field
                      name='pensionStartAge'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      render={({ input, meta }) => (
                        <NumberInput
                          name={input.name}
                          value={input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                          maxLength={3}
                          width='100px'
                          placeholder='Age'
                        />
                      )}
                    />
                  </InputField>
                  <InputField
                    label={`What is the ${
                      values.paymentType === 'L' ? ' lump sum amount' : 'monthly payout'
                    }?`}>
                    <Field
                      name='monthlyPensionIncome'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      render={({ input, meta }) => (
                        <CurrencyInput
                          name={input.name}
                          value={input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                        />
                      )}
                    />
                  </InputField>
                </div>
              )}

              {values.paymentType === 'L' && (
                <Field
                  name='lumpsumreceived'
                  render={({ input, meta }) => (
                    <MultiButtonInput
                      label={`Has ${participantName} received the lump sum payout?`}
                      buttons={[
                        { value: 'Y', text: 'Yes' },
                        { value: 'N', text: 'No' },
                      ]}
                      name={input.name}
                      value={input.value}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                      tooltip={values.paymentType === 'L' && TooltipText.whyLumpSumMatters()}
                      helpLabel='Why it matters'
                    />
                  )}
                />
              )}

              {values.paymentType === 'M' && (
                <InputField label='If there is a survivor benefit, what is that percentage?'>
                  <Field
                    name='survivorFraction'
                    format={v => v}
                    parse={v => v}
                    render={({ input, meta }) => (
                      <PercentInput
                        name={input.name}
                        value={input.value === '' ? null : input.value}
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                        placeholder='0'
                      />
                    )}
                  />
                </InputField>
              )}
              <ButtonsBox>
                <Button secondary width='120px' onClick={this.handleCancel} label='Cancel' />
                <Button primary width='100px' label='Done' onClick={handleSubmit} />
              </ButtonsBox>
            </div>
          </WizardStep>
        )}
      />
    )
  }
}

export default inject('store', 'account')(observer(AddPayout))
