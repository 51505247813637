import { css } from '@emotion/css'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { Spacer, SVGWrapper, ButtonInput, FlexRow, MainTitle } from '../../../components'
import { OnTrack as OnTrackSVG, Warning } from '../../../assets/icons'
import { numberToDollars } from '../../../utils'
import StatusCard from './StatusCard'
import GlidePathSwitch from './GlidePathSwitch'

const Card = styled.div`
  max-width: 1200px;
  margin: auto;
`

const HeaderLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1rem;
  text-align: right;
`

const HeaderAmount = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2.75rem;
  color: ${p => p.theme.darkestGray};
  font-weight: 300;
  line-height: 1.25;
  text-align: right;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`

const Label = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
`

const SVGContainer = styled.span`
  padding: 0 4px 8px 4px;
`

class OverallStrategyModule extends Component {
  render() {
    const { store, displayInflation, setInflation } = this.props
    const { currentTotalBalance, isOnTrack } = store.baseCase
    const { displayName } = store.person

    return (
      <div
        className={css`
          min-width: 325px;
        `}>
        <Card>
          <GlidePathSwitch />
          <FlexRow justifyContent='space-between' alignItems='flex-start'>
            <div
              className={css`
                max-width: 100%;
              `}>
              {!!displayName && <MainTitle>{`${displayName}'s`}</MainTitle>}
              <div
                className={css`
                  max-width: 100%;
                `}>
                <MainTitle
                  className={css`
                    display: inline-block;
                  `}>
                  Overall Strategy
                </MainTitle>
                <SVGContainer>
                  <SVGWrapper
                    svg={isOnTrack ? OnTrackSVG : Warning}
                    size='small'
                    cursor='default'
                  />
                </SVGContainer>
              </div>
            </div>
            <div>
              <Spacer space='2px' />
              <HeaderLabel>Total saved</HeaderLabel>
              <Spacer space='3px' />
              <HeaderAmount>{numberToDollars(currentTotalBalance, true)}</HeaderAmount>
              <Spacer space='3px' />
            </div>
          </FlexRow>
          <Spacer space='16px' />
          <FlexRow maxWidth='450px' justifyContent='space-between'>
            <Label>Value at retirement</Label>
            <div>
              <ButtonInput
                name='display'
                value
                isActive={displayInflation === true}
                text='Display'
                onClick={setInflation}
                small
                width='130px'
              />
              <ButtonInput
                name='hide'
                value={false}
                isActive={displayInflation === false}
                text='Hide'
                onClick={setInflation}
                small
                width='130px'
              />
            </div>
          </FlexRow>
          <Spacer space='10px' />
          <StatusCard displayInflation={displayInflation} />
        </Card>
      </div>
    )
  }
}

export default inject('store')(observer(OverallStrategyModule))
