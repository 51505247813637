/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'
import * as yup from 'yup'

import { API } from '../../../api'
import {
  WizardStep,
  InputField,
  TextInput,
  MultiButtonInput,
  TooltipText,
} from '../../../components'
import { stringWhitelist, reduceValidationError } from '../../../utils'
import { accountTypeKeyToPlanTypeId } from '../../../constants'
import { accountTypeToAPI, typeToAPI } from '../utils'
import { accountTypeToAllowedContributions } from '../utils/accountTypeToAllowedContributions'

// The keys the API needs to create each account
const accountTypeAllowedKeys = {
  '401a': ['name', 'participantId', 'adviced'],
  '401k': ['name', 'participantId', 'adviced'],
  '403b': ['name', 'participantId', 'adviced'],
  '457': ['name', 'participantId', 'adviced'],
  'annuity-fixed': ['name', 'participantId'],
  'brokerage-account': ['name', 'participantId'],
  'ira-deductible': ['name', 'participantId', 'adviced'],
  'ira-non-deductible': ['name', 'participantId', 'adviced'],
  'ira-rollover': ['name', 'participantId', 'adviced'],
  'ira-roth': ['name', 'participantId', 'adviced'],
  'keogh': ['name', 'participantId', 'adviced'],
  'other-asset': ['name', 'participantId'],
  'payroll-deduct-ira': ['name', 'participantId', 'adviced'],
  'pension': ['name', 'participantId'],
  'sep-ira': ['name', 'participantId', 'adviced'],
  'simple-ira': ['name', 'participantId', 'adviced'],
}

// Calculate the next step after basics
const accountTypeNextStep = {
  '401a': 'paycheck-contributions',
  '401k': 'paycheck-contributions',
  '403b': 'paycheck-contributions',
  '457': 'paycheck-contributions',
  'annuity-fixed': 'annuity',
  'brokerage-account': 'investments-intro',
  'ira-deductible': 'contributions',
  'ira-non-deductible': 'contributions',
  'ira-rollover': 'contributions',
  'ira-roth': 'contributions',
  'keogh': 'paycheck-contributions',
  'other-asset': 'investments-intro',
  'payroll-deduct-ira': 'paycheck-contributions',
  'pension': 'payout',
  'sep-ira': 'paycheck-contributions',
  'simple-ira': 'paycheck-contributions',
}

// Pension account needs more data than basics for creation
const postponeAccountCreation = ['pension']

const schema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .min(3, 'Account name must be between 3 and 32 characters')
    .max(32, 'Account name must be between 3 and 32 characters')
    .required('Name is required')
    .test('string-whitelist', 'Special characters are not allowed', stringWhitelist)
    .when(
      ['$existingAccountNames', '$shouldNotCheckName'],
      (existingAccountNames, shouldNotCheckName, schema) => {
        if (shouldNotCheckName) {
          return schema
        }
        return schema.test(
          'unique-name',
          'Account name must be unique',
          value =>
            !_.includes(
              existingAccountNames.map(name => name.toLowerCase()),
              value.toLowerCase()
            )
        )
      }
    ),
  participantId: yup.number().nullable().required('This field is required'),
  adviced: yup.boolean().nullable().required('This field is required'),
})

const AddBasics = props => {
  const {
    store,
    match: {
      params: { type, accountType },
    },
    history,
    store: { institutionalAccountUnderReview, institutionalAccount },
    location,
  } = props

  const isInstitutionalAccount =
    institutionalAccount?.id && location?.state?.id === institutionalAccount?.id

  const handleBack = () => {
    props.history.goBack()
  }

  const onSubmit = async values => {
    const pickedKeys = _.pick(values, accountTypeAllowedKeys[accountType])

    try {
      if (_.includes(postponeAccountCreation, accountType)) {
        // push form values to state and redirect to next page
        props.account.setAccount({
          type: 'Pension',
          pensionName: values.name,
          ownerId: values.participantId,
        })
        return history.push(
          `/accounts/add/${type}/${accountType}/${accountTypeNextStep[accountType]}`
        )
      }

      // if adviced isn't allowed for the user to edit on an account type, default it to false
      if (!_.includes(accountTypeAllowedKeys[accountType], 'adviced')) {
        pickedKeys.adviced = false
      }

      let response

      if (type === 'annuity') {
        props.account.setAccount({
          type: 'Annuity',
        })
        try {
          response = await props.account.account.createAnnuity({
            annuityName: values.name,
            personId: values.participantId,
            type: accountType,
            adviced: false,
          })
        } catch (err) {
          console.error(err)
        }
      } else {
        if (institutionalAccountUnderReview && isInstitutionalAccount) {
          response = await API.patch(typeToAPI[type], {
            planType: accountTypeKeyToPlanTypeId[accountType],
            ...pickedKeys,
          })
          await store.getInstitutionalAccount()
        } else {
          const allowedContributions = accountTypeToAllowedContributions[accountType] // get default allowed contributions
          response = await API.post(accountTypeToAPI[accountType], {
            planType: accountTypeKeyToPlanTypeId[accountType],
            rothContribAllowed: allowedContributions?.rothContribAllowed,
            posttaxContribAllowed: allowedContributions?.posttaxContribAllowed,
            contributionEligibility: true,
            employeeContrib: true,
            ...pickedKeys,
          })
        }
      }

      // push response.data to the account store
      applySnapshot(props.account, { account: response.data })
      const { id } = response.data
      await store.getAccounts()
      history.push(`/accounts/add/${type}/${accountType}/${id}/${accountTypeNextStep[accountType]}`)
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const validate = values => {
    const { existingAccountNames } = props.store
    const shouldNotCheckName = institutionalAccountUnderReview && isInstitutionalAccount
    return schema
      .validate(values, {
        abortEarly: false,
        context: { existingAccountNames, shouldNotCheckName },
      })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  const editable = () => {
    const { accountType } = props.match.params
    const {
      person: { maritalStatus, includeSpouse },
      features,
    } = props.store
    const allowedKeys = accountTypeAllowedKeys[accountType]

    const nameIsEditable = _.includes(allowedKeys, 'name')
    const ownerIsEditable =
      maritalStatus && includeSpouse && _.includes(allowedKeys, 'participantId')
    const adviceIsEditable =
      !isInstitutionalAccount && features.outsideAdviceEnabled && _.includes(allowedKeys, 'adviced')

    return {
      nameIsEditable,
      ownerIsEditable,
      adviceIsEditable,
    }
  }

  const initialValues = ({ nameIsEditable, ownerIsEditable, adviceIsEditable }) => {
    const {
      person: { id },
      config: {
        template: { planName },
      },
      institutionalAccount,
    } = props.store

    const name = nameIsEditable
      ? institutionalAccountUnderReview && isInstitutionalAccount
        ? planName
        : ''
      : null
    const participantId =
      institutionalAccountUnderReview && isInstitutionalAccount ? id : ownerIsEditable ? null : id
    const adviced = adviceIsEditable
      ? institutionalAccountUnderReview && isInstitutionalAccount
        ? institutionalAccount?.adviced
        : null
      : false

    return {
      name,
      participantId,
      adviced,
    }
  }

  const {
    person: { id: personID, displayName },
    spouse: { id: spouseID, firstName: spouseFirstName },
  } = props.store

  const { nameIsEditable, ownerIsEditable, adviceIsEditable } = editable()

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues(editable())}
      subscription={{ submitting: true, submitError: true }}
      render={({ handleSubmit, submitting, submitError }) => (
        <WizardStep
          onBackClick={handleBack}
          onNextClick={handleSubmit}
          disableNextButton={submitting}
          disableBackButton={submitting}
          serverError={submitError}
          title="Let's get some account basics">
          <div>
            {nameIsEditable && (
              <InputField label='Give the account a name:'>
                <Field
                  name='name'
                  format={v => v}
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      readonly={institutionalAccountUnderReview && isInstitutionalAccount}
                      name={input.name}
                      value={input.value}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                      disabled={institutionalAccountUnderReview && isInstitutionalAccount}
                      css={css`
                        max-width: 450px;
                      `}
                      expanded={input.value}
                    />
                  )}
                />
              </InputField>
            )}
            {ownerIsEditable && !(institutionalAccountUnderReview && isInstitutionalAccount) && (
              <Field
                name='participantId'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <MultiButtonInput
                    label='Who owns the account?'
                    name={input.name}
                    value={input.value}
                    onChange={(name, value) => input.onChange(value)}
                    onBlur={input.onBlur}
                    error={meta.error}
                    showError={meta.touched}
                    buttons={[
                      { text: spouseFirstName || '', value: spouseID || 0 },
                      { text: displayName, value: personID || 0 },
                    ]}
                  />
                )}
              />
            )}
            {adviceIsEditable && (
              <Field
                name='adviced'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <MultiButtonInput
                    label='Would you also like investment advice for this account?'
                    name={input.name}
                    value={input.value}
                    onChange={(name, value) => input.onChange(value)}
                    onBlur={input.onBlur}
                    error={meta.error}
                    showError={meta.touched}
                    buttons={[
                      { text: 'Include', value: true },
                      { text: 'Exclude', value: false },
                    ]}
                    tooltip={TooltipText.includeInvestmentAdvice()}
                  />
                )}
              />
            )}
          </div>
        </WizardStep>
      )}
    />
  )
}

export default inject('store', 'account')(observer(AddBasics))
