import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

const NotFound = props => {
  const {
    store: {
      config: { isRetail },
    },
    onboarding: { chosenSpendingOrSaving },
    history,
  } = props

  useEffect(() => {
    if (isRetail && chosenSpendingOrSaving === false) {
      history.replace('/welcome/saving-or-spending')
      return
    }

    return history.replace('/welcome/accept-terms')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default inject('store', 'onboarding')(observer(NotFound))
