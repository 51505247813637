/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import { flow } from 'mobx'
import { Button } from '../../../components'
import IncomeReplacementDrawer from './IncomeReplacementDrawer'

const Container = styled.div`
  margin: 40px 0;
`
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`
const ButtonBox = styled.div`
  width: 200px;
  border-radius: 4px;
  background: ${p => p.theme.buttonColor};
  cursor: pointer;
  padding: 8px;
  margin: 12px;
  &:hover:not(:active) {
    background: ${p => p.theme.hoverButtonColor};
  }
  &:active {
    background: ${p => p.theme.activeButtonColor};
  }
`
const ButtonTopText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 1.125rem;
  user-select: none;
`
const ButtonBottomText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 0.75rem;
  user-select: none;
`

class IncomeGoalDecision extends Component {
  state = { submitting: false, drawerOpen: false }

  toggleDrawer = () => this.setState({ drawerOpen: !this.state.drawerOpen })

  handleSave = flow(function* (value) {
    try {
      const {
        person: { id },
        updatePerson,
        updateOnBoardingState,
      } = this.props.onboarding

      this.setState({ submitting: true })
      yield updatePerson({ retirementIncomeGoalPct: value, id })
      yield updateOnBoardingState(true)
      if (this.props.store.config.isRetail) {
        return this.props.history.push('/welcome/greeting')
      }
      this.props.history.push('/home')
    } catch (err) {
      console.error(err)
      this.setState({ submitting: false })
    }
  })

  render() {
    return (
      <Container>
        <ButtonGroup>
          <Button
            type='button'
            secondary
            css={css`
              background-color: #4a606a;
              color: #fff;
            `}
            label='Change goal'
            onClick={this.toggleDrawer}
          />
          <ButtonBox id='income-goal-sounds-good-button' onClick={() => this.handleSave(80)}>
            <ButtonTopText>Sounds good</ButtonTopText>
            <ButtonBottomText>save & calculate results</ButtonBottomText>
          </ButtonBox>
        </ButtonGroup>
        <IncomeReplacementDrawer
          active={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
          handleSave={this.handleSave.bind(this)}
          isWelcome
        />
      </Container>
    )
  }
}

export default inject('onboarding', 'store')(observer(IncomeGoalDecision))
