import { css, useTheme } from '@emotion/react'
import { matchPath } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
// import styled from '@emotion/styled'

import { SVGWrapper } from '../../../../components'
import { ArrowLeft, Home, OverallStrategyGreyLinesWhiteBGSVG } from '../../../../assets/icons'
import { QuickActionMenu, SideDrawerAccounts, SideDrawerGoals } from './'
import { ArrowIconRow, MainBar, MainSideBarLabel, OverallSub } from './styled'

function SideBarDrawer(props) {
  const {
    history,
    location,
    main: { sidebarActive, toggleSidebar, setDrawer },
    store: {
      features,
      config,
      config: { isSpendown, spendDownModeling, isRetail },
      institutional,
    },
  } = props

  const theme = useTheme()
  const defaultFill = theme.svgSideBarDrawerFill

  return (
    <MainBar id='sidebar' showSidebar={sidebarActive}>
      <ArrowIconRow
        css={css`
          margin: 0;
          display: block;
          justify-content: flex-end;
          flex: none;
          padding: 20px;
          width: 100%;
          text-align: right;
          box-shadow: 0 2px 2px 0 rgba(182, 192, 196, 0.15);
        `}>
        <SVGWrapper
          svg={ArrowLeft}
          size='large'
          onClick={toggleSidebar}
          transition='fill 0.3s'
          fill={sidebarActive ? defaultFill : 'rgba(255,255,255,0)'}
          css={css`${!sidebarActive} && 'pointer-events:none;cursor:default;`}
        />
      </ArrowIconRow>
      <div
        css={css`
          overflow-y: auto;
          height: 100%;
          width: 100%;
          overflow-x: hidden;
          white-space: nowrap;
          position: relative;
          ::-webkit-scrollbar {
            width: 0px; /* remove scrollbar space */
            background: transparent; /* optional: just make scrollbar invisible */
          }
          -ms-overflow-style: none;
        `}>
        {config.template.showQuickActions && <QuickActionMenu history={history} />}
        <MainSideBarLabel
          isHighlighted={!!matchPath(location.pathname, { path: '/home' })}
          onClick={() => history.push('/home')}>
          <SVGWrapper
            css={css`
              display: flex;
              justify-content: flex-start;
              align-items: center;
              & span {
                width: 20px;
                height: 24px;
              }
            `}
            margin='4px'
            svg={Home}
            size='mediumLarge'
            fill={defaultFill}
          />
          Home
        </MainSideBarLabel>

        <div
          css={css`
            padding-top: 34px;
          `}
        />

        <MainSideBarLabel
          isHighlighted={!!matchPath(location.pathname, { path: '/(overall|spending)' })}
          onClick={() => history.push('/overall')}>
          <SVGWrapper
            css={css`
              display: flex;
              justify-content: flex-start;
              align-items: center;
              & span {
                width: 20px;
                height: 24px;
              }
            `}
            margin='4px'
            svg={OverallStrategyGreyLinesWhiteBGSVG}
            size='mediumLarge'
            fill={defaultFill}
          />
          Overall Strategy
        </MainSideBarLabel>
        {isSpendown || spendDownModeling ? (
          <OverallSub
            onClick={() => {
              history.push('/spending/budget')
              setDrawer('edit')
            }}>
            Income need/want
          </OverallSub>
        ) : (
          <>
            <OverallSub
              onClick={() => {
                history.push('/overall')
                setDrawer('edit')
              }}>
              Make changes
            </OverallSub>
            <OverallSub
              onClick={() => {
                history.push('/overall')
                setDrawer('compare')
              }}>
              Compare scenarios
            </OverallSub>
          </>
        )}

        {features.enableSavingsRateUpdate &&
          institutional.rateChangeAllowed &&
          !spendDownModeling &&
          !isSpendown &&
          !isRetail && (
            <OverallSub
              onClick={() => {
                history.push('/update-contributions-only')
              }}
              css={css`
                color: ${theme.primaryColor};
              `}>
              Change how much I'm saving only
            </OverallSub>
          )}

        <div
          css={css`
            padding-top: 34px;
          `}
        />

        <SideDrawerGoals {...props} />

        <div
          css={css`
            padding-top: 48px;
          `}
        />

        <SideDrawerAccounts {...props} />

        <div
          css={css`
            padding-top: 48px;
          `}
        />
      </div>
    </MainBar>
  )
}

export default inject('store', 'main')(observer(SideBarDrawer))
