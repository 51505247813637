/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import dayjs from 'dayjs'
import * as yup from 'yup'
import createDecorator from 'final-form-calculate'

import { Page } from '../elements'
import {
  TextInput,
  NumberInput,
  TextErrorField,
  ButtonInput,
  Button,
  CurrencyInput,
  ModalConfirmation,
  DatePicker,
} from '../../../components'
import _ from 'lodash'
import { reduceValidationError } from '../../../utils'

const schema = yup.object().shape({
  birthDate: yup.date().nullable().required('Birth date is required'),
  retirementAge: yup
    .number()
    .nullable()
    .when('$age', (age, schema) => {
      const maxRetAge = Math.min(99, age + 55)
      const validAgesMessage = `Valid ages: 20 - ${maxRetAge}`
      return schema
        .required(validAgesMessage)
        .min(20, validAgesMessage)
        .max(maxRetAge, validAgesMessage)
    }),
  annualSalary: yup
    .number()
    .nullable()
    .required('Annual salary is required')
    .min(1, 'Must be greater than $0')
    .max(9999999, 'Salary must be less than $10,000,000'),
  gender: yup.string().nullable().required('Select one').oneOf(['F', 'M'], 'Select one'),
  maritalStatus: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  email: yup
    .string()
    .nullable()
    .required('Your email address is required')
    .email('A valid email is required'),
})

const SomethingWrong = () => {
  const [state, setState] = useState(false)

  return (
    <div>
      <Button type='button' secondary label='Something wrong?' onClick={() => setState(true)} />
      <ModalConfirmation
        bodyText={`
            Just make note to contact your employer about information on this
            page that appears to be incorrect.
          `}
        isModalOpen={state}
        toggleModal={() => setState(false)}
        title='You can still move forward...'
      />
    </div>
  )
}

const mapKeysToTree = {
  retirementAge: 'person',
  annualSalary: 'account',
  gender: 'person',
  maritalStatus: 'person',
}

const calculator = createDecorator({
  field: 'birthDate',
  updates: {
    age: (ignoredValue, allValues) => {
      return dayjs().year() - dayjs(parseInt(allValues.birthDate)).year()
    },
  },
})

const ProfileInfo = props => {
  const onSubmit = async values => {
    try {
      const { updateAccount, updatePerson, updateContactInfo, updateOnBoardingState } =
        props.onboarding

      // Patch Account
      const account = getSnapshot(props.onboarding.account)
      const accountPatch = _.pickBy(
        values,
        (value, key) => mapKeysToTree[key] === 'account' && value !== account[key]
      )
      const accountPromise = _.isEmpty(accountPatch)
        ? Promise.resolve({})
        : updateAccount({ id: account.id, ...accountPatch })

      // Patch Person
      const person = getSnapshot(props.onboarding.person)
      const personPatch = _.pickBy(
        values,
        (value, key) => mapKeysToTree[key] === 'person' && value !== person[key]
      )
      const personPromise = _.isEmpty(personPatch)
        ? Promise.resolve({})
        : updatePerson({ id: person.id, ...personPatch })

      // Put Contact Info
      const contactInfo = getSnapshot(props.onboarding.contactInfo)
      const contactInfoPromise =
        contactInfo.email === values.email
          ? Promise.resolve({})
          : updateContactInfo({
              ...contactInfo,
              email: values.email,
            })

      await Promise.all([accountPromise, personPromise, contactInfoPromise])
      await updateOnBoardingState(true)

      props.history.push('/overall')
    } catch (err) {
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const initialValues = () => {
    const {
      account: { annualSalary },
      person: { retirementAge, gender, maritalStatus },
      contactInfo: { email },
    } = props.onboarding

    const {
      person: { age, birthDate },
    } = props.onboarding

    const initialValues = {
      retirementAge,
      annualSalary,
      gender,
      maritalStatus,
      email,
      age,
      birthDate,
    }

    // test for null cases
    // initialValues.firstName = null
    // initialValues.lastName = null
    // initialValues.birthDate = null
    // initialValues.gender = null
    // initialValues.annualSalary = null
    return initialValues
  }

  const validate = values => {
    const {
      person: { age, birthDate },
    } = props.onboarding

    return schema
      .validate(values, { abortEarly: false, context: { age: birthDate ? birthDate : age } })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  const {
    person: { firstName, lastName, birthDate },
  } = props.onboarding

  return (
    <Page>
      <Page.Heading title='We need just a couple pieces of info about you' />

      <Form
        initialValues={initialValues()}
        decorators={[calculator]}
        validate={validate}
        onSubmit={onSubmit}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ handleSubmit, submitting, submitError, values }) => (
          <Page.Form>
            <Page.AnimateGroup>
              <Page.AnimateItem>
                <Page.Field label='Name'>{`${firstName} ${lastName}`}</Page.Field>
              </Page.AnimateItem>

              <Page.AnimateItem>
                <Page.Field label='Birthdate'>
                  {birthDate ? (
                    <div>{dayjs(birthDate).format('MM/DD/YYYY')}</div>
                  ) : (
                    <Field
                      name='birthDate'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <DatePicker
                          handleChange={(name, value) => input.onChange(value)}
                          maxYear={new Date().getFullYear()}
                          minYear={1910}
                          name={input.name}
                          date={input.value}
                          showError
                          error={meta.error}
                        />
                      )}
                    />
                  )}
                </Page.Field>
              </Page.AnimateItem>

              <Page.AnimateItem>
                <Page.Field id='retirement-age-field' label='Retirement age'>
                  <Field
                    name='retirementAge'
                    format={value => (value === null ? undefined : value)}
                    parse={v => v}
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <NumberInput
                        name={input.name}
                        value={input.value}
                        onChange={(name, value) => input.onChange(value)}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        placeholder='age'
                        showError={meta.touched}
                        error={meta.error}
                      />
                    )}
                  />
                </Page.Field>
              </Page.AnimateItem>

              <Page.AnimateItem>
                <Page.Field
                  id='annual-salary-field'
                  label='Annual salary'
                  subLabel='(before taxes)'>
                  <Field
                    name='annualSalary'
                    format={value => (value === null ? undefined : value)}
                    parse={v => v}
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <CurrencyInput
                        placeholder='Salary'
                        name={input.name}
                        value={input.value}
                        onBlur={input.onBlur}
                        onChange={(name, value) => input.onChange(value)}
                        error={meta.error}
                        showError={meta.touched}
                        decimalScale={0}
                      />
                    )}
                  />
                </Page.Field>
              </Page.AnimateItem>

              <Page.AnimateItem>
                <Page.Field label='Gender'>
                  <Field
                    name='gender'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <div>
                        <div
                          id='gender-select-field'
                          css={css`
                            font-size: 1rem;
                          `}>
                          <ButtonInput
                            name={input.name}
                            value='F'
                            isActive={input.value === 'F'}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onClick={(name, value) => input.onChange(value)}
                            text='Female'
                          />
                          <ButtonInput
                            name={input.name}
                            value='M'
                            isActive={input.value === 'M'}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onClick={(name, value) => input.onChange(value)}
                            text='Male'
                          />
                        </div>
                        <div>
                          <TextErrorField showError={meta.touched} error={meta.error} />
                        </div>
                      </div>
                    )}
                  />
                </Page.Field>
              </Page.AnimateItem>

              <Page.AnimateItem>
                <Page.Field label='Marital status'>
                  <Field
                    name='maritalStatus'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <div>
                        <div
                          id='marital-status-select-field'
                          css={css`
                            font-size: 1rem;
                          `}>
                          <ButtonInput
                            isActive={input.value === false}
                            name={input.name}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onClick={(name, value) => input.onChange(value)}
                            text='Single'
                            value={false}
                          />
                          <ButtonInput
                            isActive={input.value === true}
                            name={input.name}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onClick={(name, value) => input.onChange(value)}
                            text='Married'
                            value
                          />
                        </div>
                        <div>
                          <TextErrorField error={meta.error} showError={meta.touched} />
                        </div>
                      </div>
                    )}
                  />
                </Page.Field>
              </Page.AnimateItem>

              <Page.AnimateItem>
                <Page.Field label='Email'>
                  <Field
                    name='email'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <TextInput
                        id='email-field'
                        error={meta.error}
                        maxLength={129}
                        name={input.name}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onChange={(name, value) => input.onChange(value)}
                        placeholder='email'
                        showError={meta.touched}
                        type='email'
                        value={input.value}
                        width='340px'
                        mobileWidth='220px'
                      />
                    )}
                  />
                </Page.Field>
              </Page.AnimateItem>

              <Page.Animate pose={!submitting && submitError ? 'visible' : 'hidden'}>
                <Page.ErrorMessage>{!submitting && submitError}</Page.ErrorMessage>
              </Page.Animate>

              <Page.AnimateItem>
                <Page.ButtonGroup>
                  <SomethingWrong />
                  <div>
                    <Button
                      id='profile-info-submit-button'
                      type='button'
                      onClick={handleSubmit}
                      primary
                      label='Next'
                      disabled={submitting}
                    />
                  </div>
                </Page.ButtonGroup>
              </Page.AnimateItem>
            </Page.AnimateGroup>
          </Page.Form>
        )}
      />
    </Page>
  )
}

export default inject('onboarding')(observer(ProfileInfo))
