import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Loading } from '../../../components'
import { numberToDollars } from '../../../utils'
import {
  SectionTitle,
  SectionBalance,
  DollarSign,
  SectionRow,
  SectionLabel,
  SectionSubLabel,
  NameField,
  BalanceField,
  EditButton,
  SVGContainer,
  SVGPlaceholder,
} from './DashboardStyle'

const SocialSecurity = ({ name, balance, include, handleClick }) => (
  <SectionRow secondRow>
    <NameField>
      <SVGContainer>
        <SVGPlaceholder />
      </SVGContainer>
      <div>
        <SectionLabel>{name}</SectionLabel>
        <SectionSubLabel>{include ? 'Include' : 'Exclude'}</SectionSubLabel>
      </div>
    </NameField>
    <BalanceField>
      <SectionBalance>
        <DollarSign>$</DollarSign>
        {numberToDollars(include ? balance : 0, true)}
      </SectionBalance>
      <EditButton onClick={handleClick}>Edit</EditButton>
    </BalanceField>
  </SectionRow>
)

class DashboardSocialSecurity extends Component {
  state = { loading: true }
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this._isMounted = true
    this.props.store
      .getBaseCase()
      .then(() => {
        if (this._isMounted) {
          this.setState({ loading: false })
        }
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ loading: false })
        }
      })
  }

  handleSocialSecurityEdit = () => {
    const { history } = this.props
    history.push('/social-security')
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { store } = this.props
    const { displayName, maritalStatus, includeSpouse } = store.person
    const { firstName } = store.spouse
    const { primary, spouse } = store.baseCase || {}
    const dataAvailable = !!primary || !!spouse
    const showSpouseSS = maritalStatus && includeSpouse && spouse

    const { primarySSIBenefits, spouseSSIBenefits } = store

    return this.state.loading ? (
      <Loading />
    ) : (
      dataAvailable && (
        <div>
          <SectionTitle>Social Security income (monthly)</SectionTitle>
          {primary && (
            <SocialSecurity
              name={displayName}
              balance={_.get(primarySSIBenefits, 'monthlyAmount', null)}
              include={_.get(primarySSIBenefits, 'includeSsi', null)}
              handleClick={this.handleSocialSecurityEdit} // eslint-disable-line
            />
          )}
          {showSpouseSS && (
            <SocialSecurity
              name={firstName}
              balance={_.get(spouseSSIBenefits, 'monthlyAmount', null)}
              include={_.get(spouseSSIBenefits, 'includeSsi', null)}
              handleClick={this.handleSocialSecurityEdit} // eslint-disable-line
            />
          )}
        </div>
      )
    )
  }
}

export default inject('store')(observer(DashboardSocialSecurity))
