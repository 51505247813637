import PropTypes from 'prop-types'
import { themr } from '../react-css-themr'
import { cx } from '@emotion/css'
import { CARD } from '../identifiers'

const CardText = ({ children, className, theme, ...other }) => (
  <div className={cx(theme.cardText, className)} {...other}>
    {typeof children === 'string' ? <p>{children}</p> : children}
  </div>
)

CardText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.shape({
    cardText: PropTypes.string,
  }),
}

export default themr(CARD)(CardText)
export { CardText }
