import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import { withRouter } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'

import { API } from '../../../api'
import { WizardStep, InputField, CurrencyInput, HelpIcon, TooltipText } from '../../../components'
import { reduceValidationError } from '../../../utils'
import { typeToAPI } from '../utils'
import { getIraContributionType } from '../utils/accountTypeToAllowedContributions'

// Calculate the next step after contributions
const accountTypeNextStep = {
  'ira-deductible': 'investments-intro',
  'ira-non-deductible': 'investments-intro',
  'ira-rollover': 'investments-intro',
  'ira-roth': 'investments-intro',
}

const schema = yup.object().shape({
  annualPlannedContribDlrs: yup
    .number()
    .nullable()
    .max(100000000, 'Please enter an amount between $0 and $100,000,000'),
})

class AddContributions extends Component {
  componentDidMount() {
    // add logic determining access permissions for this page
  }

  onSubmit = async values => {
    try {
      const {
        store,
        account: { account },
        match: {
          params: { type, accountType },
        },
        history,
      } = this.props
      const { planType, id, participantId } = account
      const employeeContrib = (values.annualPlannedContribDlrs ?? 0) > 0
      const contributionType = getIraContributionType(planType)
      const payload = {
        planType,
        id,
        participantId,
        contribMethod: 'D',
        employeeContrib,
        contributionEligibility: true,
      }
      payload[contributionType] = values.annualPlannedContribDlrs ?? 0
      const response = await API.patch(typeToAPI[type], payload)

      applySnapshot(account, response.data)
      store.getAccounts()
      history.push(`/accounts/add/${type}/${accountType}/${id}/${accountTypeNextStep[accountType]}`)
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  initialValues = () => {
    const {
      account: { annualPlannedContribDlrs },
    } = this.props.account
    return { annualPlannedContribDlrs }
  }

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  render() {
    const { account } = this.props.account

    if (!account) {
      return null
    }

    const { participantName } = account

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={this.validate}
        initialValues={this.initialValues()}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting, submitError }) => (
          <WizardStep
            title="Let's look at contributions"
            onNextClick={handleSubmit}
            disableNextButton={submitting}
            disableBackButton={submitting}
            serverError={submitError}>
            <div>
              <InputField
                label={`What is the annual amount ${participantName} commits to contribute to this account?`}
                helpLabel='IRA contribution limit'
                tooltip={TooltipText.iraContributionLimit()}>
                <span
                  css={css`
                    padding-right: 12px;
                  `}>
                  <Field
                    name='annualPlannedContribDlrs'
                    format={value => (value === null ? undefined : value)}
                    parse={v => v}
                    render={({ input, meta }) => (
                      <CurrencyInput
                        name={input.name}
                        value={input.value}
                        placeholder='0'
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                        decimalScale={0}
                        width='125px'
                      />
                    )}
                  />
                </span>
                <HelpIcon
                  helpLabel='Why commitment matters'
                  tooltip={TooltipText.whyCommitmentMatters()}
                />
              </InputField>
            </div>
          </WizardStep>
        )}
      />
    )
  }
}

export default withRouter(inject('store', 'account')(observer(AddContributions)))
