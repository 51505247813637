const SvgComponent = props => (
  <svg
    id='phone_support_icon'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 300 300'
    {...props}>
    <g fill='none' strokeMiterlimit={10}>
      <path stroke='#0d8bb8' d='M227.3 91.9h-87.2m0 18.4h87.2m-87.2 18.3h87.2' />
      <path stroke='#90be3f' d='M72.7 171.2h87.2m-87.2 18.4h87.2' />
      <path
        d='M111.3 71.5v77.7c0 3 2.4 5.4 5.4 5.4h118.8c1.4 0 2.8.6 3.8 1.6l16.9 16.9V71.5c0-3-2.4-5.4-5.4-5.4h-134c-3.1 0-5.5 2.4-5.5 5.4z'
        stroke='#0d8bb8'
      />
      <path
        d='M188.7 154.5V210c0 3-2.4 5.4-5.4 5.4H64.5c-1.4 0-2.8.6-3.8 1.6l-16.9 16.9V132.3c0-3 2.4-5.4 5.4-5.4h62.1'
        stroke='#90be3f'
      />
    </g>
    <path fill='none' d='M-362-864.5H662v1358H-362z' />
    <defs>
      <ellipse id='phone_support_icon_5_' cx={150.1} cy={-185.1} rx={64.9} ry={63.9} />
    </defs>
    <clipPath id='phone_support_icon_2_'>
      <use xlinkHref='#phone_support_icon_5_' overflow='visible' />
    </clipPath>
    <g clipPath='url(#phone_support_icon_2_)'>
      <defs>
        <path id='phone_support_icon_7_' d='M-605.5-575.6H433V801.6H-605.5z' />
      </defs>
      <clipPath id='phone_support_icon_4_'>
        <use xlinkHref='#phone_support_icon_7_' overflow='visible' />
      </clipPath>
    </g>
    <path fill='none' d='M-363-861.5H661v1358H-363z' />
    <defs>
      <path
        id='phone_support_icon_9_'
        d='M803.1-202.8c-.5.2-1 .5-1.7.5h-23.9c-.6 0-1.2-.2-1.7-.5-14 11.3-32.9 36.7-32.9 52.9 0 15 4.6 24.4 14.5 32.5 2.9 2.4 6.6 3.7 10.5 3.7h42.7c3.9 0 7.6-1.3 10.5-3.7 10-8.2 14.5-17.6 14.5-32.5.4-16.2-18.4-41.7-32.5-52.9z'
      />
    </defs>
    <clipPath id='phone_support_icon_6_'>
      <use xlinkHref='#phone_support_icon_9_' overflow='visible' />
    </clipPath>
    <g clipPath='url(#phone_support_icon_6_)'>
      <defs>
        <path id='phone_support_icon_11_' d='M55.7-1650.4h1926.4V904.3H55.7z' />
      </defs>
      <clipPath id='phone_support_icon_8_'>
        <use xlinkHref='#phone_support_icon_11_' overflow='visible' />
      </clipPath>
    </g>
    <defs>
      <path
        id='phone_support_icon_13_'
        d='M801.5-209.1c.2 0 .5 0 .7.1l10-9.4c.8-.8.6-1.8-.4-2.3l-3.4-1.6c-1-.5-2.7-.6-3.8-.2l-4.7 1.5c-1.1.3-2.7.1-3.6-.5l-5.2-3.7c-.9-.6-2.5-.6-3.3 0l-5.2 3.7c-.9.6-2.5.9-3.6.5l-4.7-1.5c-1.1-.3-2.8-.2-3.8.2l-3.4 1.6c-1 .5-1.2 1.5-.4 2.3l10 9.4c.2-.1.5-.1.7-.1h24.1z'
      />
    </defs>
    <clipPath id='phone_support_icon_10_'>
      <use xlinkHref='#phone_support_icon_13_' overflow='visible' />
    </clipPath>
    <g clipPath='url(#phone_support_icon_10_)'>
      <defs>
        <path id='phone_support_icon_15_' d='M55.7-1650.4h1926.4V904.3H55.7z' />
      </defs>
      <clipPath id='phone_support_icon_12_'>
        <use xlinkHref='#phone_support_icon_15_' overflow='visible' />
      </clipPath>
    </g>
    <defs>
      <path
        id='phone_support_icon_17_'
        d='M801.5-202.4c.6 0 1.2-.2 1.7-.5 1-.5 1.7-1.6 1.7-3 0-1.6-1.1-3-2.7-3.3-.2-.1-.5-.1-.7-.1h-23.9c-.2 0-.5 0-.7.1-1.5.3-2.7 1.6-2.7 3.3 0 1.2.7 2.3 1.7 3 .5.2 1 .5 1.7.5h23.9z'
      />
    </defs>
    <clipPath id='phone_support_icon_14_'>
      <use xlinkHref='#phone_support_icon_17_' overflow='visible' />
    </clipPath>
    <g clipPath='url(#phone_support_icon_14_)'>
      <defs>
        <path id='phone_support_icon_19_' d='M55.7-1650.4h1926.4V904.3H55.7z' />
      </defs>
      <clipPath id='phone_support_icon_16_'>
        <use xlinkHref='#phone_support_icon_19_' overflow='visible' />
      </clipPath>
    </g>
    <g>
      <defs>
        <path
          id='phone_support_icon_21_'
          d='M828.2-149.9c0 12.3-3.3 19.8-11.8 26.6-1.6 1.2-3.6 1.9-5.5 1.9'
        />
      </defs>
      <clipPath id='phone_support_icon_18_'>
        <use xlinkHref='#phone_support_icon_21_' overflow='visible' />
      </clipPath>
      <g clipPath='url(#phone_support_icon_18_)'>
        <defs>
          <path id='phone_support_icon_23_' d='M55.7-1650.4h1926.4V904.3H55.7z' />
        </defs>
        <clipPath id='phone_support_icon_20_'>
          <use xlinkHref='#phone_support_icon_23_' overflow='visible' />
        </clipPath>
      </g>
    </g>
  </svg>
)

export default SvgComponent
