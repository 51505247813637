import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import DisclosureFirstPage from './DisclosureFirstPage'
import DisclosureSecondPage from './DisclosureSecondPage'
import DisclosureThirdPage from './DisclosureThirdPage'

function Disclosures(props) {
  const {
    guide: { withdrawalData, investmentWithdrawals },
  } = props
  return (
    <>
      {withdrawalData.reqMinDistribution > 0 && <DisclosureFirstPage />}
      <DisclosureSecondPage />
      {(!_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) ||
        !_.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome)) && (
        <DisclosureThirdPage />
      )}
    </>
  )
}

export default inject('store', 'guide')(observer(Disclosures))
