import { Component } from 'react'
import { matchPath } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import InlineSVG from 'svg-inline-react'
import _ from 'lodash'

import avatar from '../../../assets/icons/AvatarSVG'
import { API_URL } from '../../../api'
import { MobileDrawer } from './sidebar'

const Box = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  border: ${p => p.theme.topNavBorder};
  box-shadow: 0 2px 2px 0 rgba(182, 192, 196, 0.15);
  @media (max-width: 800px) {
    height: 64px;
    justify-content: flex-start;
  }
  background-color: ${p => p.theme.topNavColor};
`
const LogoContainer = styled.div`
  * {
    max-width: 220px;
    min-width: 175px;
  }
  padding: 20px;
`

const Logo = styled.img`
  width: 100%;
  content: url(${p => API_URL + p.theme.logo});
`

const MenuBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  @media (max-width: 800px) {
    display: none;
  }
`
const MenuItem = styled.div`
  cursor: pointer;
  padding: 20px;
  white-space: nowrap;
  transition: color 0.35s, padding 0.35s;
  @media (max-width: 900px) {
    padding: 10px;
  }
  color: ${p => (p.isActive ? p.theme.primaryColor : p.theme.topNavTextColor)};
`
const ProfileBox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px;
  transition: 0.35s;
  color: ${p => (p.isActive ? p.theme.primaryColor : p.theme.topNavTextColor)};
  min-width: 90px;
`
const AvatarBox = styled.div`
  min-width: 28px;
  width: 28px;
  height: 28px;
  padding: 4px;
  & svg {
    transition: 0.35s;
    stroke: ${p => (p.isActive ? p.theme.primaryColor : `#7a8e96`)};
    fill: ${p => (p.isActive ? p.theme.primaryColor : `#7a8e96`)};
  }
`
const Logout = styled.button`
  cursor: pointer;
  min-width: 66px;
  width: 92px;
  height: 47px;
  border: ${p => p.theme.logoutButtonBorder};
  border-radius: 4px;
  background-color: ${p => p.theme.logoutButtonColor};
  margin: 15px;
  color: ${p => (p.isActive ? p.theme.primaryColor : p.theme.topNavTextColor)};
`

class TopNav extends Component {
  handleLogout = () => {
    window.open(`${API_URL.slice(0, -4)}logout`, '_self')
  }

  render() {
    const location = this.props.location.pathname
    const {
      history,
      store: { features, person, config },
    } = this.props

    if (this.props.main.enableTopNav !== undefined && this.props.main.enableTopNav === false) {
      return null
    }

    return (
      <Box>
        <MobileDrawer />
        <LogoContainer>
          <Logo alt='Logo' />
        </LogoContainer>
        <MenuBox>
          {features.enableHomePage && !features.enablePersonalizedTargetDatePlus && (
            <MenuItem isActive={matchPath(location, '/home')} onClick={() => history.push('/home')}>
              Home
            </MenuItem>
          )}

          {_.includes(
            ['Overall', 'Spending', 'Simplified'],
            config.template.overallPageComponent
          ) && (
            <MenuItem
              isActive={matchPath(location, ['/overall', '/spending'])}
              onClick={() => history.push('/overall')}>
              Overall Strategy
            </MenuItem>
          )}

          {features.enableGoals && (
            <MenuItem
              isActive={matchPath(location, '/goals')}
              onClick={() => history.push('/goals')}>
              Goals
            </MenuItem>
          )}

          {features.enableAccounts && (
            <MenuItem
              isActive={matchPath(location, '/accounts')}
              onClick={() => history.push('/accounts')}>
              Accounts
            </MenuItem>
          )}

          {features.enableTools && (
            <MenuItem
              isActive={matchPath(location, '/tools')}
              onClick={() => history.push('/tools')}>
              Tools
            </MenuItem>
          )}

          {features.enableHelp && (
            <MenuItem isActive={matchPath(location, '/help')} onClick={() => history.push('/help')}>
              Help
            </MenuItem>
          )}

          <ProfileBox
            isActive={matchPath(location, '/profile')}
            onClick={() => history.push('/profile')}>
            <AvatarBox isActive={matchPath(location, '/profile')}>
              <InlineSVG src={avatar} element='span' />
            </AvatarBox>
            {features.enablePreferredName ? (
              <span
                css={css`
                  text-overflow: ellipsis;
                `}>
                {person.displayName}
              </span>
            ) : (
              <span
                css={css`
                  text-overflow: ellipsis;
                `}>
                {_.get(this, 'props.store.account.productId', '') === 5
                  ? person.firstName
                  : 'Profile'}
              </span>
            )}
          </ProfileBox>

          <Logout onClick={this.handleLogout}>Logout</Logout>
        </MenuBox>
      </Box>
    )
  }
}

export default inject('store', 'main')(observer(TopNav))
