const SvgComponent = props => (
  <svg viewBox='0 0 1792 1792' {...props}>
    <path
      d='M768 890l546 546c-70.7 72-153.2 128-247.5 168s-193.8 60-298.5 60c-139.3 0-267.8-34.3-385.5-103S171.7 1399.2 103 1281.5 0 1035.3 0 896s34.3-267.8 103-385.5S264.8 299.7 382.5 231 628.7 128 768 128v762zm187 6h773c0 104.7-20 204.2-60 298.5s-96 176.8-168 247.5L955 896zm709-128H896V0c139.3 0 267.8 34.3 385.5 103s210.8 161.8 279.5 279.5 103 246.2 103 385.5z'
      fill='#7a8e96'
    />
  </svg>
)

export default SvgComponent
