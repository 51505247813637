import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { Spacer } from '../../../components'
import { Page, PageHeader } from '../elements'
import {
  ContentMainHeader,
  ContentText,
  FootnoteText,
  ListPadding,
  InvestmentsListItem,
} from '../elements/styled'

const otherPageConfig = ({ config, employerAccount }) => {
  switch (config.templateId) {
    case 1:
      return {
        content: `In addition to getting advice on your ${config.template.planName} account, you have specified that you would like investment advice on other accounts. We apply the same risk measurement to these accounts that we apply to your ${config.template.planName} account. Wherever possible, we provide advice at the investment holdings level; otherwise, advice will be at the asset class level.
        The following accounts have been included for investment advice:*`,

        footNote: '',

        asterisk:
          '* To receive investment advice on a particular investment account, you must have indicated a desire for investment advice. In addition, those investment accounts associated with an employer plan must have a balance greater than $0 before GuidedChoice can provide investment advice on that account.',
      }

    case 2:
      return {
        content:
          'A big part of getting you on track to meet your goals is to ensure that you have the right mix of investments working for you. GuidedChoice recommends a personalized mix of investments for your investment accounts, tailored to you to help you reach your goals while minimizing the volatility (the ups and downs) of your account. Wherever possible, we provide advice at the investment holdings level; otherwise, advice will be at the asset class level. The following accounts have been included for investment advice:*',

        footNote:
          'Please be aware: While we provide percentages and dollar amounts associated with this and any other recommended investment mix, the dollar amounts are based on the account balance in our database the day this guide is produced. Because investment balances shift, when implementing our advice, more often than not, percentages will be required.',

        asterisk:
          '* To receive investment advice on a particular investment account, you must have indicated a desire for investment advice. In addition, those investment accounts associated with an employer plan must have a balance greater than $0 before GuidedChoice can provide investment advice on that account.',
      }
    case 19:
      return {
        content: `In addition to getting advice on your retirement plan account (${
          employerAccount.length ? employerAccount[0].name : ''
        }), you have specified that you would like investment advice on other accounts. We apply the same risk measurement to these accounts that we apply to your retirement plan account. Wherever possible, we provide advice at the investment holdings level; otherwise, advice will be at the asset class level. The following accounts have been included for investment advice:*`,
        footNote: '',
        asterisk:
          '* To receive investment advice on a particular investment account, you must have indicated a desire for investment advice. In addition, those investment accounts associated with an employer plan must have a balance greater than $0 before GuidedChoice can provide investment advice on that account.',
      }

    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    default:
      return {
        content: `In addition to getting advice on your retirement plan account with ${config.template.sponsorName}, you have specified that you would like investment advice on other accounts. We apply the same risk measurement to these accounts that we apply to your retirement plan account with ${config.template.sponsorName}. Wherever possible, we provide advice at the investment holdings level; otherwise, advice will be at the asset class level. The following accounts have been included for investment advice:*`,

        footNote: '',

        asterisk:
          '* To receive investment advice on a particular investment account, you must have indicated a desire for investment advice. In addition, those investment accounts associated with an employer plan must have a balance greater than $0 before GuidedChoice can provide investment advice on that account.',
      }
  }
}

const ListElement = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store, name: accountName, accountId } = this.props

        const { firstName: spouseName } = store.spouse

        const { spouseHasEmployer, spouseEmployerAccount } = store
        const spouseEmployerAccountId = spouseHasEmployer ? spouseEmployerAccount.id : -1

        return accountId === spouseEmployerAccountId ? (
          <InvestmentsListItem>
            <ListPadding>{`${spouseName}'s current employer plan account (${accountName})`}</ListPadding>
          </InvestmentsListItem>
        ) : (
          <InvestmentsListItem>
            <ListPadding>{accountName}</ListPadding>
          </InvestmentsListItem>
        )
      }
    }
  )
)

class OtherPage1 extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'other1' })
  }

  render() {
    const { outsideAdviceEnabled } = this.props.store.features
    if (!outsideAdviceEnabled) {
      return null
    }

    const page = this.props.guide.guidePages.pages.findByName('other1')
    const { config, otherIncludedAccounts, employerAccounts } = this.props.store
    const eligibleEmployerAccounts = employerAccounts.filter(acct => acct.contributionEligibility)
    const { isRetail } = config

    // Must follow this order: 401k, 401a, 403b, 457, Keogh
    let employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 1) // 401k
    if (employerAccount.length === 0) {
      employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 23) // 401a
      if (employerAccount.length === 0) {
        employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 2) // 403b
        if (employerAccount.length === 0) {
          employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 4) // 457
          if (employerAccount.length === 0) {
            employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 6) // Keogh
          }
        }
      }
    }

    const otherPage = otherPageConfig({ config, employerAccount })

    return (
      <Page header={<PageHeader text='Other Investment Accounts' />} page={page}>
        <ContentMainHeader>INVESTMENTS</ContentMainHeader>

        <Spacer space='12px' />

        {otherIncludedAccounts.length || (isRetail && employerAccount.length > 0) ? (
          <div>
            <ContentText>{otherPage.content}</ContentText>

            <Spacer space='8px' />

            <ContentText>
              <ul>
                {otherIncludedAccounts.map(account => (
                  <ListElement
                    key={account.name}
                    accountId={account.id}
                    participantId={account.participantId}
                    name={account.name}
                  />
                ))}
              </ul>
            </ContentText>

            {Boolean(otherPage.footNote) && (
              <FootnoteText
                css={css`
                  padding: 8px 0;
                `}>
                {otherPage.footNote}
              </FootnoteText>
            )}

            {Boolean(otherPage.asterisk) && (
              <FootnoteText
                css={css`
                  padding: 8px 0;
                `}>
                {otherPage.asterisk}
              </FootnoteText>
            )}
          </div>
        ) : (
          <ContentText>
            Did you know GuidedChoice can give you advice on other investment accounts you may have?
            Ensure you have the right mix for all your investment accounts and get advice that's
            tailored to help you reach your goals while minimizing the volatility (the ups and
            downs). You can return to our Advisory Service to add other accounts and get advice on
            them at anytime.
          </ContentText>
        )}
      </Page>
    )
  }
}

export default inject('store', 'guide')(observer(OtherPage1))
