import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { InputBox } from '../../../components'

const amountWrapsAtWidth = 990
const sidebarWidth = 0

const breakpoint = p =>
  p.sidebarOpen ? `${amountWrapsAtWidth}px` : `${amountWrapsAtWidth - sidebarWidth}px`

const FlexRowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${breakpoint}) {
    display: block;
  }
`

const Name = styled.div`
  flex: 0 1 320px;
  min-width: 175px;
  padding: 0 8px;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: #4a606a;

  @media (max-width: ${breakpoint}) {
    width: 100%;
    flex-basis: auto;
    padding: 0;
    margin-bottom: 10px;
  }
`

const InvestmentListItemBox = styled.div`
  padding: 0px;
  color: #9eacb0;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const FormText = props => (
  <InputBox
    css={css`
      color: #022a3a;
      font-size: 14px;
      height: 50px;
      width: 100%;
      font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
      line-height: 1.5rem;
      font-weight: 400;
      padding: ${props.padding || '0'};
      @media (max-width: 990px) {
        padding-left: 0;
      }
    `}>
    {props.children}
  </InputBox>
)

export { FlexRowBetween, Name, InvestmentListItemBox, FormText }
