import variables from '../variables'
import colors from '../colors'
import reduceCSSCalc from 'reduce-css-calc'

const dialog = {
  borderRadius: reduceCSSCalc(`calc(0.2 * ${variables.unit})`),
  colorTitle: colors.rt.black,
  colorWhite: colors.rt.white,
  contentPadding: reduceCSSCalc(`calc(2.4 * ${variables.unit})`),
  navigationPadding: reduceCSSCalc(`calc(0.8 * ${variables.unit})`),
  translateY: reduceCSSCalc(`calc(4 * ${variables.unit})`),
  overflow: 'inherit',
}

export default dialog
