import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import BeforePurchaseAnnuity from './BeforePurchaseAnnuity'
import AnnuityTutorial from './AnnuityTutorial'
import AnnuityTutorialPage2 from './AnnuityTutorialPage2'
import AnnuityTutorialPage3 from './AnnuityTutorialPage3'
import AboutYourAnnuity from './AboutYourAnnuity'
import WithdrawFromAnnuities from './WithdrawFromAnnuities'

function Annuity(props) {
  const {
    guide: { investmentWithdrawals },
    store: { person },
  } = props

  return (
    <>
      <BeforePurchaseAnnuity />
      <AnnuityTutorial />
      <AnnuityTutorialPage2 />
      <AnnuityTutorialPage3 />
      {(_.get(investmentWithdrawals, 'primaryLifetimeInvestmentIncome.monthlyAmount', 0) > 0 ||
        (person.includeSpouse &&
          _.get(investmentWithdrawals, 'spouseLifetimeInvestmentIncome.monthlyAmount', 0) > 0)) && (
        <AboutYourAnnuity />
      )}
      {(!_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) ||
        (person.includeSpouse &&
          !_.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome))) && (
        <WithdrawFromAnnuities />
      )}
    </>
  )
}

export default inject('store', 'guide')(observer(Annuity))
