/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { inject, observer } from 'mobx-react'
import { Route, Switch } from 'react-router-dom'
import SendPasswordResetMail from './SendPasswordResetMail'
import ResetPasswordForm from './ResetPasswordForm'

function ResetPassword(props) {
  const { location } = props

  return (
    <Switch location={location}>
      <Route exact path='/atmos/reset-password' component={SendPasswordResetMail} />
      <Route exact path='/atmos/reset-password/2' component={ResetPasswordForm} />
    </Switch>
  )
}

export default inject('store')(observer(ResetPassword))
