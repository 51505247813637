import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'

function SliderWithValueUnderneath({
  min,
  max,
  value,
  onChange,
  displayValue = true,
  step,
  disabled,
  frozenValue,
}) {
  const rangeRef = useRef(null)
  const valueRef = useRef(null)

  const rangeRef2 = useRef(null)
  const valueRef2 = useRef(null)
  useEffect(() => {
    const myRange = ReactDOM.findDOMNode(rangeRef.current)
    const myValue = ReactDOM.findDOMNode(valueRef.current)

    const newValue = Number(
      ((myRange.valueAsNumber - myRange.min) * 100) / (myRange.max - myRange.min)
    )
    const newPosition = 10 - newValue * 0.2

    if (displayValue) {
      myValue.innerHTML = `<span>${myRange.value}</span>`
      myValue.style.left = `calc(${newValue}% + (${newPosition}px))`
      myValue.style.bottom = '-20px'
    }

    myRange.oninput = () => {
      const newValue = Number(
        ((myRange.valueAsNumber - myRange.min) * 100) / (myRange.max - myRange.min)
      )
      const newPosition = 10 - newValue * 0.2

      if (displayValue) {
        myValue.innerHTML = `<span>${myRange.value}</span>`
        myValue.style.left = `calc(${newValue}% + (${newPosition}px))`
        myValue.style.bottom = '-20px'
      }
    }
  })

  useEffect(() => {
    if (frozenValue) {
      const myRange = ReactDOM.findDOMNode(rangeRef2.current)
      const myValue = ReactDOM.findDOMNode(valueRef2.current)

      const newValue = Number(
        ((myRange.valueAsNumber - myRange.min) * 100) / (myRange.max - myRange.min)
      )
      const newPosition = 10 - newValue * 0.2

      if (displayValue) {
        myValue.innerHTML = `<span>${myRange.value}</span>`
        myValue.style.left = `calc(${newValue}% + (${newPosition}px))`
        myValue.style.bottom = '-20px'
      }

      myRange.oninput = () => {
        const newValue = Number(
          ((myRange.valueAsNumber - myRange.min) * 100) / (myRange.max - myRange.min)
        )
        const newPosition = 10 - newValue * 0.2

        if (displayValue) {
          myValue.innerHTML = `<span>${myRange.value}</span>`
          myValue.style.left = `calc(${newValue}% + (${newPosition}px))`
          myValue.style.bottom = '-24px'
        }
      }
    }
  }, []) // eslint-disable-line

  return (
    <SliderContainer>
      {frozenValue > 0 && (
        <>
          {displayValue && <FrozenValue ref={valueRef2}>0</FrozenValue>}
          <FrozenSlider
            ref={rangeRef2}
            type='range'
            min={min}
            max={max}
            value={frozenValue}
            disabled
          />
        </>
      )}
      {displayValue && <Value ref={valueRef}>0</Value>}
      <Slider
        type='range'
        min={min}
        max={max}
        value={value || min}
        onChange={onChange}
        ref={rangeRef}
        step={step}
        disabled={disabled}
      />
    </SliderContainer>
  )
}

export default SliderWithValueUnderneath

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 30px;
`

const Slider = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: #d3d3d3;
  outline: none;
  border: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 40px;
    background: ${p => p.theme.primaryColor};
    cursor: pointer;
    border-radius: 5px;
    border: none;
  }
  &::-moz-range-thumb {
    width: 20px;
    height: 40px;
    background: ${p => p.theme.primaryColor};
    cursor: pointer;
    border-radius: 5px;
    border: none;
  }
  &:hover {
    opacity: 1;
  }
`

const Value = styled.span`
  position: absolute;

  & > span {
    color: ${p => p.theme.sliderValueUnderneathColor};
    width: 20px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;

      top: 100%;
      left: 50%;
      margin-left: -5px;
      margin-top: -1px;
    }
  }
`

const FrozenSlider = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 40px;
    background: ${p => p.theme.frozenSliderColor};
    cursor: pointer;
    border-radius: 5px;
    border: none;
  }
  &::-moz-range-thumb {
    width: 20px;
    height: 40px;
    background: ${p => p.theme.frozenSliderColor};
    cursor: pointer;
    border-radius: 5px;
    border: none;
  }
  &:hover {
    opacity: 1;
  }
`

const FrozenValue = styled.span`
  position: absolute;

  & > span {
    color: rgba(2, 42, 58, 0.4);
    width: 20px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;

      top: 100%;
      left: 50%;
      margin-left: -5px;
      margin-top: -1px;
    }
  }
`
