// * https://github.com/jquense/yup
// managing validation errors for yup
const reduceValidationError = validationError =>
  validationError.inner.reduce((total, currentValue) => {
    total[currentValue.path] = currentValue.message
    return total
  }, {})

/**
 * @desc disallow special characters
 * @return String -> Boolean
 */
const stringWhitelist = str => !/[^a-zA-Z0-9-, .()']+/g.test(str)

export { reduceValidationError, stringWhitelist }
