import { css, useTheme } from '@emotion/react'
import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import {
  Button,
  HelpPersonIcon,
  Loading,
  HelpIcon,
  TooltipText,
  Spacer,
  Dropdown,
} from '../../../components'
import { Dialog } from '../../../guided-toolbox'
import InvestmentMixChart from './investment-mix-chart/InvestmentMixChart'

const Heading = styled.div`
  font-family: Aleo;
  color: #022a3a;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  margin: 36px 0;
`

function AssetClassBreakdown(props) {
  const [active, setActive] = useState(false)
  const [status, setStatus] = useState('loading')
  const [selectedAccount, setSelectedAccount] = useState(null)
  const theme = useTheme()

  function handleOpen() {
    const { modifiedCase, recommendedCase } = props.store
    const { caseId } = modifiedCase || recommendedCase
    fetchBreakdown(caseId)
    setActive(true)
  }

  async function fetchBreakdown(caseId) {
    try {
      setStatus('loading')
      const { getAllBreakdown } = props.store
      await getAllBreakdown(caseId)
      setSelectedAccount(props.store.allBreakdown[0]?.name || '')
      setStatus('done')
    } catch (err) {
      console.error(err)
      setStatus('error')
    }
  }

  function handleClose() {
    setActive(false)
  }

  const { baseCase, recommendedCase, modifiedCase, features } = props.store

  function getOptions() {
    const { allBreakdown } = props.store
    return (
      allBreakdown.reduce((acc, curr) => {
        acc.push({
          label: curr.name,
          value: curr.name,
        })
        return acc
      }, []) || []
    )
  }

  return (
    <div>
      <HelpPersonIcon size='xLarge' helpLabel='View asset class breakdown' onClick={handleOpen} />
      <Dialog
        active={active}
        onEscKeyDown={handleClose}
        onOverlayClick={handleClose}
        css={css`
          width: 92%;
          max-width: 1000px;
          max-height: 92vh;
          overflow-y: auto;
        `}>
        <Heading>
          <div>Asset class breakdown of your</div>
          <div>{modifiedCase ? 'selected' : 'recommended'} portfolio</div>
        </Heading>

        {status === 'loading' && <Loading />}

        {status === 'error' && (
          <div
            css={css`
              color: crimson;
              text-align: center;
            `}>
            Something went wrong, please try again later.
          </div>
        )}

        {status === 'done' && !!baseCase && !!recommendedCase && (
          <>
            <div
              css={css`
                margin: 0 1.5rem;
                display: flex;
                align-items: baseline;
              `}>
              <div
                css={css`
                  margin-right: 1rem;
                `}>
                Select investment account{' '}
                <HelpIcon size='small' tooltip={TooltipText.selectInvestmentAccount()} />
              </div>

              <Dropdown
                name={'accounts'}
                onChange={(name, value) => {
                  setSelectedAccount(value)
                }}
                options={getOptions()}
                placeholder={selectedAccount || ''}
                selected={selectedAccount}
                width={'300px'}
              />
            </div>
            <Spacer space='15px' />

            <InvestmentMixChart
              allTabsEnabled={features.allBreakdownChartsEnabled}
              store={props.store}
              selectedaccount={selectedAccount || props.store.allBreakdown[0]?.name}
              css={css`
                margin: 0 1.5rem;
              `}
              theme={theme}
            />
          </>
        )}

        <div
          css={css`
            text-align: right;
            margin: 48px 0 24px;
          `}>
          <Button secondary label='Close' onClick={handleClose} width='85px' />
        </div>
      </Dialog>
    </div>
  )
}

export default inject('store')(observer(AssetClassBreakdown))
