import variables from './variables'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables

const media = {
  portrait: 'orientation: portrait',
  landscape: 'orientation: landscape',

  // Breakpoints
  // viewports defined by max-width, larger-than defined by min-width
  // Used with `ds.bp()`
  breakpoints: {
    xxs: '480px',
    xs: '600px',
    smTablet: '720px',
    sm: '840px',
    md: '960px',
    lgTablet: '1024px',
    lg: '1280px',
    xl: '1440px',
    xxl: '1600px',
    xxxl: '1920px',
  },

  standardIncrement: {
    mobile: reduceCSSCalc(`calc(5.6 * ${unit})`),
    desktop: reduceCSSCalc(`calc(6.4 * ${unit})`),
  },
}

export default media
