import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { Button, Spacer, HelpIcon, TextErrorField, TooltipText } from '../../../components'

const ComparisonBoxWrapper = styled.div`
  padding: 20px 8px;
  border: ${p => p.theme.considerContainerBorder};
  border-radius: 8px;
  background-color: ${p => p.theme.considerContainerBgColor};
  margin: 20px 0;
  min-width: 360px;
  max-width: 1200px;
`
const ComparisonBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CompareBox = styled.div`
  flex: 1 1 auto;
  margin-bottom: 16px;
`
const CompareBoxLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1.25rem;
  font-weight: 600;
`
const BottomGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0;
  max-width: 1200px;
`
const CheckBoxLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.125rem;

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: ${p => (p.checked ? '"\u2714"' : '"\u00A0"')};
    width: 20px;
    height: 20px;
    font-size: 16px;
    font-family: 'Open Sans', 'Segoe UI Symbol', 'Helvetica Neue', sans-serif;
    border: 2px solid #767676;
    border-radius: 4px;
    background-color: #ffffff;
    margin: 6px 28px 6px 0;
    color: ${p => (p.checked ? '#000000' : '#FFFFFF')};
  }
`
const Checkbox = styled.input`
  display: none;
`

class SelectionMenu extends Component {
  state = {
    compareValue: '',
    withValue: '',
    submitAttempt: false,
  }

  handleCompareChange = e => {
    if (e.target.value === this.state.withValue) {
      this.setState({ compareValue: e.target.value, withValue: '' })
    } else {
      this.setState({ compareValue: e.target.value })
    }
  }

  handleWithChange = e => {
    if (e.target.value === this.state.compareValue) {
      this.setState({ compareValue: '', withValue: e.target.value })
    } else {
      this.setState({ withValue: e.target.value })
    }
  }

  handleSubmit = () => {
    if (!!this.state.compareValue && !!this.state.withValue) {
      this.props.setSelected({
        compareValue: this.state.compareValue,
        withValue: this.state.withValue,
      })
    } else {
      this.setState({ submitAttempt: true })
    }
  }

  render() {
    const { modifiedCase, savedScenarios } = this.props.store
    const { compareValue, withValue } = this.state

    return (
      <div>
        <ComparisonBoxWrapper>
          <ComparisonBox>
            <CompareBox>
              <CompareBoxLabel>Compare:</CompareBoxLabel>

              <Spacer space='8px' />

              {modifiedCase && (
                <div>
                  <CheckBoxLabel checked={compareValue === 'modified'}>
                    <Checkbox
                      type='checkbox'
                      value='modified'
                      onChange={this.handleCompareChange}
                    />
                    <span>My most recent modifications</span>
                  </CheckBoxLabel>
                </div>
              )}

              <div>
                <CheckBoxLabel checked={compareValue === 'recommended'}>
                  <Checkbox
                    type='checkbox'
                    value='recommended'
                    onChange={this.handleCompareChange}
                  />
                  <span>GuidedChoice recommendation</span>
                </CheckBoxLabel>
              </div>

              <div>
                <CheckBoxLabel checked={compareValue === 'base'}>
                  <Checkbox type='checkbox' value='base' onChange={this.handleCompareChange} />
                  <span>My current strategy</span>
                </CheckBoxLabel>
              </div>

              <div>
                {savedScenarios.map((scenario, i) => {
                  return (
                    <CheckBoxLabel
                      key={_.uniqueId('saved_')}
                      checked={compareValue === `saved_${i}`}>
                      <Checkbox
                        type='checkbox'
                        value={`saved_${i}`}
                        onChange={this.handleCompareChange}
                      />
                      <span>{scenario.name}</span>
                    </CheckBoxLabel>
                  )
                })}
              </div>
            </CompareBox>
            <CompareBox>
              <CompareBoxLabel>with:</CompareBoxLabel>

              <Spacer space='8px' />

              {modifiedCase && (
                <div>
                  <CheckBoxLabel checked={withValue === 'modified'}>
                    <Checkbox type='checkbox' value='modified' onChange={this.handleWithChange} />
                    <span>My most recent modifications</span>
                  </CheckBoxLabel>
                </div>
              )}

              <div>
                <CheckBoxLabel checked={withValue === 'recommended'}>
                  <Checkbox type='checkbox' value='recommended' onChange={this.handleWithChange} />
                  <span>GuidedChoice recommendation</span>
                </CheckBoxLabel>
              </div>

              <div>
                <CheckBoxLabel checked={withValue === 'base'}>
                  <Checkbox type='checkbox' value='base' onChange={this.handleWithChange} />
                  <span>My current strategy</span>
                </CheckBoxLabel>
              </div>

              {savedScenarios.map((scenario, i) => {
                return (
                  <CheckBoxLabel key={_.uniqueId('saved_')} checked={withValue === `saved_${i}`}>
                    <Checkbox
                      type='checkbox'
                      value={`saved_${i}`}
                      onChange={this.handleWithChange}
                    />
                    <span>{scenario.name}</span>
                  </CheckBoxLabel>
                )
              })}
            </CompareBox>
          </ComparisonBox>

          <TextErrorField
            showError={this.state.submitAttempt}
            error='Please choose two options to compare'
          />

          <BottomGroup>
            <HelpIcon helpLabel='Not listed' tooltip={TooltipText.scenarioNotListed()} />
            <div>
              <Button primary label='Compare' width='162px' onClick={this.handleSubmit} />
            </div>
          </BottomGroup>
        </ComparisonBoxWrapper>
      </div>
    )
  }
}

export default inject('store')(observer(SelectionMenu))
