import { types, flow } from 'mobx-state-tree'

import { API } from '../api'
const { model, array, maybeNull, number, string, boolean } = types

export const StockOption = model({
  currentPrice: number,
  exercisePrice: number,
  expirationDate: string,
  expired: boolean,
  grantDate: string,
  id: number,
  included: maybeNull(boolean),
  optionGrantNo: string,
  percentVestedPeriod: number,
  quantity: number,
  rkPptId: string,
  securityId: number,
  ticker: maybeNull(string),
  timingOfSale: number,
  vested: boolean,
  vestedDate: string,
}).views(self => ({
  get availableToExercise() {
    return self.vested && !self.expired
  },
  get isUnderWater() {
    return self.exercisePrice > self.currentPrice
  },
}))

const StockOptions = model({
  stockOptions: array(StockOption),
})
  .views(self => ({
    get vestedStockOptions() {
      return self.stockOptions.filter(stockOption => stockOption.vested)
    },
    get unvestedStockOptions() {
      return self.stockOptions.filter(stockOption => !stockOption.vested)
    },
  }))
  .actions(self => ({
    getStockOptions: flow(function* () {
      const stockOptions = yield API.get('stock-options')
      self.stockOptions = stockOptions.data
    }),
  }))

export default StockOptions
