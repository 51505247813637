import { Component } from 'react'
import ReactDOM from 'react-dom'

const portal = document.getElementById('portal')

class Portal extends Component {
  constructor() {
    super()
    this.el = document.createElement('div')
  }

  componentDidMount() {
    portal.appendChild(this.el)
  }

  componentWillUnmount() {
    portal.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default Portal
