import { Field } from 'react-final-form'
import styled from '@emotion/styled'
import _ from 'lodash'

import SliderInputField from './SliderInputField'
import { BorderedCard, CardTitle, ContribLimitText } from './EditDrawerStyle'
import { numberToDollars } from '../../../utils'

const Container = styled.div`
  padding-bottom: ${p => (p.moreFields ? '27px' : '0')};
`

const MakeChangesContribution = ({ store, editable, form, values }) => {
  const {
    account: { annualSalary },
    person: { displayName },
    spouse: { firstName, annualIncome },
    primaryEmployerConfig,
    spouseEmployerConfig,
    active,
    spouseAccountName,
    special457,
    special403b,
    plan: {
      limits: { annualContributionLimit },
    },
  } = store

  const {
    primaryEmployer: primaryEmployerEditable,
    special457: special457Editable,
    special403b: special403bEditable,
    spouseEmployer: spouseEmployerEditable,
  } = editable

  const atLeastOneSectionIsAvailable =
    primaryEmployerEditable || special457Editable || special403bEditable || spouseEmployerEditable

  const brand = _.get(store, 'store.config.template.brandName', 'GuidedChoice')

  function primaryEmployerSection() {
    return (
      primaryEmployerEditable && (
        <Container moreFields={special457Editable || special403bEditable || spouseEmployerEditable}>
          <ContribLimitText>
            Contribution amounts shown are annualized and rounded up to the next whole percentage
            equivalent. The actual contribution total (and what {brand} uses when projecting
            results) will be limited to IRS and plan rule maximums.
          </ContribLimitText>
          <CardTitle>
            {displayName ? `${displayName}'s` : ''} {active.name || 'employer plan account '}{' '}
            contributions
          </CardTitle>

          <SliderInputField
            readonly
            inputValue={numberToDollars(
              Math.round(
                values.primaryPreTaxDollars +
                  values.primaryRothDollars +
                  values.primaryAfterTaxDollars
              ),
              true
            )}
            percentValue={
              values.primaryPreTaxPercent +
              values.primaryRothPercent +
              values.primaryAfterTaxPercent
            }
            onChange={() => {}}
            percentLabel={`of ${displayName}'s income`}
            inputLabel='Annual total'
            displayHelp={
              annualContributionLimit <
              Math.round(
                values.primaryPreTaxDollars +
                  values.primaryRothDollars +
                  values.primaryAfterTaxDollars
              )
            }
          />

          <Field
            name='primaryPreTaxPercent'
            format={v => v}
            parse={v => v}
            render={({ input }) => (
              <SliderInputField
                inputValue={Math.round(values.primaryPreTaxDollars)}
                percentValue={input.value || 0}
                onChange={value => {
                  form.batch(() => {
                    form.change('primaryPreTaxPercent', value)
                    form.change(
                      'primaryPreTaxDollars',
                      Math.round((value * annualSalary) / 100) || 0
                    )
                  })
                }}
                percentLabel='of income'
                inputLabel='Pre-tax annual contribution'
              />
            )}
          />

          {primaryEmployerConfig.rothContribAllowed && (
            <Field
              name='primaryRothPercent'
              format={v => v}
              parse={v => v}
              render={({ input }) => (
                <SliderInputField
                  inputValue={Math.round(values.primaryRothDollars)}
                  percentValue={input.value || 0}
                  onChange={value => {
                    form.batch(() => {
                      form.change('primaryRothPercent', value)
                      form.change(
                        'primaryRothDollars',
                        Math.round((value * annualSalary) / 100) || 0
                      )
                    })
                  }}
                  percentLabel='of income'
                  inputLabel='Roth annual contribution'
                />
              )}
            />
          )}

          {primaryEmployerConfig.posttaxContribAllowed && (
            <Field
              name='primaryAfterTaxPercent'
              format={v => v}
              parse={v => v}
              render={({ input }) => (
                <SliderInputField
                  inputValue={Math.round(values.primaryAfterTaxDollars)}
                  percentValue={input.value || 0}
                  onChange={value => {
                    form.batch(() => {
                      form.change('primaryAfterTaxPercent', value)
                      form.change(
                        'primaryAfterTaxDollars',
                        Math.round((value * annualSalary) / 100) || 0
                      )
                    })
                  }}
                  percentLabel='of income'
                  inputLabel='After-tax annual contribution'
                />
              )}
            />
          )}
        </Container>
      )
    )
  }

  function special457Section() {
    return (
      special457Editable && (
        <Container moreFields={special403bEditable || spouseEmployerEditable}>
          <CardTitle>
            {`${displayName ? `${displayName}'s` : ''} ${
              special457.name ? special457.name : 'employer plan account '
            } contributions`}
          </CardTitle>

          <SliderInputField
            readonly
            inputValue={numberToDollars(
              Math.round(values.primary457PreTaxDollars + values.primary457RothDollars) || 0,
              true
            )}
            percentValue={values.primary457PreTaxPercent + values.primary457RothPercent || 0}
            onChange={() => {}}
            percentLabel={`of ${displayName}'s income`}
            inputLabel='Annual total'
          />

          <Field
            name='primary457PreTaxPercent'
            format={v => v}
            parse={v => v}
            render={({ input }) => (
              <SliderInputField
                inputValue={Math.round(values.primary457PreTaxDollars)}
                percentValue={input.value || 0}
                onChange={value => {
                  form.batch(() => {
                    form.change('primary457PreTaxPercent', value)
                    form.change(
                      'primary457PreTaxDollars',
                      Math.round((value * annualSalary) / 100) || 0
                    )
                  })
                }}
                percentLabel='of income'
                inputLabel='Pre-tax annual contribution'
              />
            )}
          />
        </Container>
      )
    )
  }

  function special403bSection() {
    return (
      special403bEditable && (
        <Container moreFields={spouseEmployerEditable}>
          <CardTitle>
            {`${displayName ? `${displayName}'s` : ''} ${
              special403b.name ? special403b.name : 'employer plan account '
            } contributions`}
          </CardTitle>

          <SliderInputField
            readonly
            inputValue={numberToDollars(Math.round(values.primary403bPreTaxDollars) || 0, true)}
            percentValue={values.primary403bPreTaxPercent}
            onChange={() => {}}
            percentLabel={`of ${displayName}'s income`}
            inputLabel='Annual total'
          />

          <Field
            name='primary403bPreTaxPercent'
            format={v => v}
            parse={v => v}
            render={({ input }) => (
              <SliderInputField
                inputValue={Math.round(values.primary403bPreTaxDollars)}
                percentValue={input.value || 0}
                onChange={value => {
                  form.batch(() => {
                    form.change('primary403bPreTaxPercent', value)
                    form.change(
                      'primary403bPreTaxDollars',
                      Math.round((value * annualSalary) / 100) || 0
                    )
                  })
                }}
                percentLabel='of income'
                inputLabel='Pre-tax annual contribution'
              />
            )}
          />
        </Container>
      )
    )
  }

  function spouseSection() {
    return (
      spouseEmployerEditable && (
        <div>
          <CardTitle>
            {firstName ? `${firstName}'s` : ''} {spouseAccountName} contributions
          </CardTitle>

          <SliderInputField
            readonly
            inputValue={numberToDollars(
              Math.round(
                values.spousePreTaxDollars + values.spouseRothDollars + values.spouseAfterTaxDollars
              ) || 0,
              true
            )}
            percentValue={
              values.spousePreTaxPercent + values.spouseRothPercent + values.spouseAfterTaxPercent
            }
            onChange={() => {}}
            percentLabel={`of ${firstName}'s income`}
            inputLabel='Annual total'
          />

          <Field
            name='spousePreTaxPercent'
            format={v => v}
            parse={v => v}
            render={({ input }) => (
              <SliderInputField
                inputValue={Math.round(values.spousePreTaxDollars)}
                percentValue={input.value || 0}
                onChange={value => {
                  form.batch(() => {
                    form.change('spousePreTaxPercent', value)
                    form.change(
                      'spousePreTaxDollars',
                      Math.round((value * annualIncome) / 100) || 0
                    )
                  })
                }}
                percentLabel='of income'
                inputLabel='Pre-tax annual contribution'
              />
            )}
          />

          {spouseEmployerConfig.rothContribAllowed && (
            <Field
              name='spouseRothPercent'
              format={v => v}
              parse={v => v}
              render={({ input }) => (
                <SliderInputField
                  inputValue={Math.round(values.spouseRothDollars)}
                  percentValue={input.value || 0}
                  onChange={value => {
                    form.batch(() => {
                      form.change('spouseRothPercent', value)
                      form.change(
                        'spouseRothDollars',
                        Math.round((value * annualIncome) / 100) || 0
                      )
                    })
                  }}
                  percentLabel='of income'
                  inputLabel='Roth annual contribution'
                />
              )}
            />
          )}

          {spouseEmployerConfig.posttaxContribAllowed && (
            <Field
              name='spouseAfterTaxPercent'
              format={v => v}
              parse={v => v}
              render={({ input }) => (
                <SliderInputField
                  inputValue={Math.round(values.spouseAfterTaxDollars)}
                  percentValue={input.value || 0}
                  onChange={value => {
                    form.batch(() => {
                      form.change('spouseAfterTaxPercent', value)
                      form.change(
                        'spouseAfterTaxDollars',
                        Math.round((value * annualIncome) / 100) || 0
                      )
                    })
                  }}
                  percentLabel='of income'
                  inputLabel='After-tax annual contribution'
                />
              )}
            />
          )}
        </div>
      )
    )
  }

  return (
    atLeastOneSectionIsAvailable && (
      <BorderedCard>
        {primaryEmployerSection()}

        {special457Section()}

        {special403bSection()}

        {spouseSection()}
      </BorderedCard>
    )
  )
}

export default MakeChangesContribution
