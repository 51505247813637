import styled from '@emotion/styled'

export const AddressBox = styled.div`
  padding-left: 10px;
  display: flex;
  flex: 3 1 340px;
  position: relative;

  @media (max-width: 800px) {
    padding-left: 0;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: ${p => (p.justifyContent ? p.jusifyContent : 'center')};
  align-items: ${p => (p.alignItems ? p.alignItems : 'center')};
  transition: 0.8s;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`
