import styled from '@emotion/styled'

import CircleProgressBarBase from './CircleProgressBarBase'

const CircleProgressBar = styled(CircleProgressBarBase)`
  max-width: ${props => props.maxSize};
  vertical-align: middle;
  .chart-text {
    fill: ${props => props.textColor};
    transform: translateY(0.1em);
  }
  .chart-number {
    font-size: 0.33em;
    line-height: 1;
    text-anchor: middle;
    transform: translateY(-0.25em);
    text-align: center;
    cursor: pointer;
    fill: #022a3a;
  }
  .chart-dollar-sign {
    font-size: 0.33em;
    line-height: 1;
    text-anchor: middle;
    transform: translateY(-0.25em);
    text-align: center;
    cursor: pointer;
    fill: #7a8e96;
  }
  .chart-number::before {
    display: block;
    content: '$';
    fill: #7a8e96;
  }
  .chart-label {
    font-size: 0.33em;
    text-anchor: middle;
    transform: translateY(0.7em);
    fill: #7a8e96;
  }
  .figure-key [class*='shape-'] {
    margin-right: 8px;
  }
  .figure-key-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  .figure-key-list li {
    margin: 5px auto;
  }
  .shape-circle {
    display: inline-block;
    vertical-align: middle;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: ${props => props.strokeColor};
    text-transform: capitalize;
  }
`

CircleProgressBar.defaultProps = {
  textColor: '#7a8e96',
  strokeColor: '#39a3c6',
  maxSize: '100vh',
}

export default CircleProgressBar
