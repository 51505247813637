/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'
import * as yup from 'yup'

import { Page } from '../elements'
import {
  Button,
  ButtonInput,
  CurrencyInput,
  HelpIcon,
  NumberInput,
  TextErrorField,
  TooltipText,
  Dropdown as DropdownElement,
  Spacer,
} from '../../../components'
import { reduceValidationError } from '../../../utils'

const schema = yup.object().shape({
  otherIncome: yup.number().nullable().max(9999999, 'Amount must be less than $10,000,000'),
  retirementAge: yup
    .number()
    .nullable()
    .when(
      ['$age', '$isRetail', '$isSpendown', '$history'],
      (age, isRetail, isSpendown, history, schema, { value: retirementAge }) => {
        const maxRetAge = Math.min(99, age + 55)
        const validAgesMessage = `Valid ages: 20 - ${maxRetAge}`
        const retailError = (
          <>
            You selected the service for those who are already retired, yet entered a retirement age
            in the future.
            <br />
            <br />
            Please either change your retirement age or{' '}
            <Page.Link onClick={() => history.push('/welcome/saving-or-spending')}>
              return to the service selection page
            </Page.Link>{' '}
            and choose the service for those still accumulating money for retirement.
          </>
        )
        return schema
          .required(validAgesMessage)
          .test('is-not-less-than-current-age-for-retail', retailError, function () {
            if (isRetail && isSpendown && retirementAge > age) {
              return false
            }
            return true
          })
          .min(20, validAgesMessage)
          .max(maxRetAge, validAgesMessage)
      }
    ),
  maritalStatus: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  includeSpouse: yup
    .boolean()
    .nullable()
    .when('maritalStatus', {
      is: true,
      then: yup.boolean().oneOf([true, false], 'Select one'),
    }),
  autoRiskLevelAdjustmentStatus: yup
    .boolean()
    .nullable()
    .when('$autoRiskAdjustEnabled', (autoRiskAdjustEnabled, schema) =>
      autoRiskAdjustEnabled ? schema.required('Select one') : schema
    ),
  planningWorkPartTime: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  durationStartingAge: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Duration: starting age is a required field')
            .test(
              'is-not-less-than-retirement-age',
              'Starting age for part-time work during retirement may not begin before the retirement age',
              function (value) {
                return this.parent.retirementAge <= value
              }
            )
        : schema
    ),
  durationStoppingAge: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Duration: stopping age is a required field')
            .test(
              'is-not-less-than-starting-age',
              'Stopping age cannot be before starting age',
              function (value) {
                return this.parent.durationStartingAge <= value
              }
            )
        : schema
    ),
  annualIncome: yup
    .number()
    .nullable()
    .when('planningWorkPartTime', (planningWorkPartTime, schema) =>
      planningWorkPartTime
        ? schema
            .required('Annual income is required')
            .min(1, 'Amount must be between $1 and $10,000,000')
            .max(9999999, 'Amount must be between $0 and $10,000,000')
        : schema
    ),
})

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

class AccountInfoMore extends Component {
  onSubmit = async values => {
    const { isSpendown } = this.props.store.config
    const { savePrimaryRetirementStatus } = this.props.store
    const {
      annualIncome,
      durationStartingAge,
      durationStoppingAge,
      includeSsiEstimate,
      planningWorkPartTime,
    } = values

    if (!values.maritalStatus) {
      values.includeSpouse = false
    }

    if (values.durationStoppingAge < values.durationStartingAge) {
      return { [FORM_ERROR]: 'Duration starting age cannot be greater than duration stopping age ' }
    }

    try {
      const mapKeysToTree = {
        otherIncome: 'person',
        retirementAge: 'person',
        maritalStatus: 'person',
        includeSpouse: 'person',
        autoRiskLevelAdjustmentStatus: 'account',
      }

      const { updatePerson, updateAutoRiskLevelAdjustmentStatus, updateOnBoardingState } =
        this.props.onboarding

      const account = getSnapshot(this.props.onboarding.account)
      const accountPromise =
        account.autoRiskLevelAdjustmentStatus === values.autoRiskLevelAdjustmentStatus
          ? Promise.resolve({})
          : updateAutoRiskLevelAdjustmentStatus({
              accountId: account.id,
              autoRiskLevelAdjustmentStatus: values.autoRiskLevelAdjustmentStatus,
            })

      const person = getSnapshot(this.props.onboarding.person)
      const personPatch = _.pickBy(
        values,
        (value, key) => mapKeysToTree[key] === 'person' && value !== person[key]
      )
      const personPromise = _.isEmpty(personPatch)
        ? Promise.resolve({})
        : updatePerson({ id: person.id, ...personPatch })

      await Promise.all([accountPromise, personPromise])

      if (isSpendown) {
        await updateOnBoardingState(true)
      }

      savePrimaryRetirementStatus({
        ...this.props.store.primaryRetirementStatus,
        annualIncome,
        durationStartingAge,
        durationStoppingAge,
        includeSsiEstimate,
        planningWorkPartTime,
      })

      if (values.maritalStatus && values.includeSpouse) {
        this.props.history.push('/welcome/spouse')
      } else if (isSpendown) {
        this.props.history.push('/welcome/social-security-income')
      } else {
        this.props.history.push('/welcome/dependents')
      }
    } catch (err) {
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  initialValues = () => {
    const { onboarding } = this.props
    const { otherIncome, retirementAge, maritalStatus, includeSpouse } = onboarding.person
    const { autoRiskLevelAdjustmentStatus } = onboarding.account
    const { primaryRetirementStatus } = this.props.store

    // test for null cases
    // initialValues.otherIncome = null
    // initialValues.retirementAge = null
    // initialValues.maritalStatus = null
    // initialValues.includeSpouse = null

    return {
      otherIncome,
      retirementAge,
      maritalStatus,
      includeSpouse,
      autoRiskLevelAdjustmentStatus,
      planningWorkPartTime: Boolean(_.get(primaryRetirementStatus, 'planningWorkPartTime', null)),
      includeSsiEstimate: Boolean(_.get(primaryRetirementStatus, 'includeSsiEstimate', null)),
      durationStartingAge: _.get(primaryRetirementStatus, 'durationStartingAge', null),
      durationStoppingAge: _.get(primaryRetirementStatus, 'durationStoppingAge', null),
      annualIncome: _.get(primaryRetirementStatus, 'annualIncome', null),
    }
  }

  validate = values => {
    const {
      features: { autoRiskAdjustEnabled },
      person: { age },
    } = this.props.onboarding
    const { history } = this.props

    const { isRetail, isSpendown } = this.props.store.config

    return schema
      .validate(values, {
        abortEarly: false,
        context: { autoRiskAdjustEnabled, age, isRetail, isSpendown, history },
      })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  async componentDidMount() {
    const { getPrimaryRetirementStatus } = this.props.store
    try {
      await getPrimaryRetirementStatus()
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    const {
      features: { autoRiskAdjustEnabled },
    } = this.props.onboarding

    const { retirementAge } = this.props.onboarding.person

    const startingAge = () => {
      let result = 0
      if (!isNaN(retirementAge)) {
        result = retirementAge
      } else {
        result = 65
      }
      return result
    }

    return (
      <Page>
        <Page.Heading title='Just a bit more about you' />

        <Form
          initialValues={this.initialValues()}
          validate={this.validate}
          onSubmit={this.onSubmit.bind(this)}
          subscription={{ submitting: true, submitError: true, values: true }}
          render={({ handleSubmit, submitting, submitError, values }) => (
            <Page.Form>
              <Page.AnimateGroup>
                <Page.AnimateItem>
                  <Page.Field
                    id='other-income-field'
                    label={
                      <div
                        css={css`
                          display: flex;
                          justify-content: flex-end;
                          align-items: baseline;
                          & > div {
                            line-height: 100%;
                          }
                        `}>
                        Other earned income annually, if any &nbsp;
                        <HelpIcon
                          css={css`
                            line-height: 100%;
                          `}
                          tooltip={TooltipText.otherEarnedIncome()}
                        />
                      </div>
                    }>
                    <Field
                      name='otherIncome'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <CurrencyInput
                          error={meta.error}
                          name={input.name}
                          placeholder='0'
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          showError={meta.touched}
                          value={input.value}
                        />
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field id='retirement-age-field' label='Retirement age'>
                    <Field
                      name='retirementAge'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <NumberInput
                          name={input.name}
                          value={input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          placeholder='age'
                          showError={meta.touched}
                          error={meta.error}
                        />
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>
                <Spacer space='20px' />

                {autoRiskAdjustEnabled && (
                  <Page.AnimateItem>
                    <Page.Field
                      label={
                        <span>
                          Automative risk level adjustment{' '}
                          <HelpIcon tooltip={TooltipText.autoRiskAdjustment()} />
                        </span>
                      }>
                      <Field
                        name='autoRiskLevelAdjustmentStatus'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <div>
                            <div
                              id='auto-risk-level-adjustment-status-select-field'
                              css={css`
                                font-size: 1rem;
                              `}>
                              <ButtonInput
                                isActive={input.value === true}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='Include'
                                value
                                width='122px'
                              />
                              <ButtonInput
                                isActive={input.value === false}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='Exclude'
                                value={false}
                                width='122px'
                              />
                            </div>
                            <div>
                              <TextErrorField error={meta.error} showError={meta.touched} />
                            </div>
                          </div>
                        )}
                      />
                    </Page.Field>
                  </Page.AnimateItem>
                )}

                <>
                  <Page.AnimateItem>
                    <Page.Field label='Planning to work part-time during retirement?'>
                      <Field
                        name='planningWorkPartTime'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <div>
                            <div
                              id='planningWorkPartTime'
                              css={css`
                                font-size: 1rem;
                              `}>
                              <ButtonInput
                                isActive={input.value === true}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='Yes'
                                value
                                width='122px'
                              />
                              <ButtonInput
                                isActive={input.value === false}
                                name={input.name}
                                onFocus={input.onFocus}
                                onBlur={input.onBlur}
                                onClick={(name, value) => input.onChange(value)}
                                text='No'
                                value={false}
                                width='122px'
                              />
                            </div>
                            <div>
                              <TextErrorField error={meta.error} showError={meta.touched} />
                            </div>
                          </div>
                        )}
                      />
                    </Page.Field>
                  </Page.AnimateItem>

                  <Condition when='planningWorkPartTime' is>
                    <Page.Field label='Duration: starting age'>
                      <Field
                        name='durationStartingAge'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <Dropdown
                            startingAge={values.retirementAge || startingAge()}
                            initialValue={input.value}
                            input={input}
                            meta={meta}
                          />
                        )}
                      />
                    </Page.Field>
                  </Condition>

                  <Condition when='planningWorkPartTime' is>
                    <Page.Field label='Duration: stopping age'>
                      <Field
                        name='durationStoppingAge'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <Dropdown
                            startingAge={values.retirementAge || startingAge()}
                            initialValue={input.value}
                            input={input}
                            meta={meta}
                          />
                        )}
                      />
                    </Page.Field>
                  </Condition>

                  <Condition when='planningWorkPartTime' is>
                    <Page.Field label='Annual income from job after retiring'>
                      <Field
                        name='annualIncome'
                        format={value => (value === null ? undefined : value)}
                        parse={v => v}
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <CurrencyInput
                            error={meta.error}
                            name={input.name}
                            placeholder='0'
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onChange={(name, value) => input.onChange(value)}
                            showError={meta.touched}
                            value={input.value}
                          />
                        )}
                      />
                    </Page.Field>
                  </Condition>
                  <Spacer space='20px' />
                </>

                <Page.AnimateItem>
                  <Page.Field label='Marital status'>
                    <Field
                      name='maritalStatus'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <div>
                          <div
                            id='marital-status-select-field'
                            css={css`
                              font-size: 1rem;
                            `}>
                            <ButtonInput
                              isActive={input.value === false}
                              name={input.name}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onClick={(name, value) => input.onChange(value)}
                              text='Single'
                              value={false}
                              width='122px'
                            />
                            <ButtonInput
                              isActive={input.value === true}
                              name={input.name}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onClick={(name, value) => input.onChange(value)}
                              text='Married'
                              value
                              width='122px'
                            />
                          </div>
                          <div>
                            <TextErrorField error={meta.error} showError={meta.touched} />
                          </div>
                        </div>
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Field
                    name='maritalStatus'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input }) => (
                      <Page.Animate pose={input.value ? 'visible' : 'hidden'}>
                        <Page.Field
                          label='Include your spouse in your planning?'
                          css={css`${input.value} ? 'pointer-events: auto;' : 'pointer-events: none;`}>
                          <Field
                            name='includeSpouse'
                            render={({ input, meta }) => (
                              <div>
                                <div
                                  id='include-spouse-select-field'
                                  css={css`
                                    font-size: 1rem;
                                  `}>
                                  <ButtonInput
                                    isActive={input.value === true}
                                    name={input.name}
                                    onFocus={input.onFocus}
                                    onBlur={input.onBlur}
                                    onClick={(name, value) => input.onChange(value)}
                                    text='Yes'
                                    value
                                    width='122px'
                                  />
                                  <ButtonInput
                                    isActive={input.value === false}
                                    name={input.name}
                                    onFocus={input.onFocus}
                                    onBlur={input.onBlur}
                                    onClick={(name, value) => input.onChange(value)}
                                    text='No/Not Now'
                                    value={false}
                                    width='122px'
                                  />
                                </div>
                                <div>
                                  <TextErrorField error={meta.error} showError={meta.touched} />
                                </div>
                              </div>
                            )}
                          />
                        </Page.Field>
                      </Page.Animate>
                    )}
                  />
                </Page.AnimateItem>

                <Page.Animate pose={!submitting && submitError ? 'visible' : 'hidden'}>
                  <Page.ErrorMessage>{!submitting && submitError}</Page.ErrorMessage>
                </Page.Animate>

                <Page.AnimateItem>
                  <Page.ButtonGroup>
                    <div>
                      <Button
                        id='account-info-more-back-button'
                        type='button'
                        onClick={this.props.history.goBack}
                        secondary
                        label='Back'
                        disabled={submitting}
                        width='140px'
                      />
                    </div>
                    <div>
                      <Button
                        id='account-info-more-submit-button'
                        type='button'
                        onClick={handleSubmit}
                        primary
                        label='Next'
                        disabled={submitting}
                        width='140px'
                      />
                    </div>
                  </Page.ButtonGroup>
                </Page.AnimateItem>
              </Page.AnimateGroup>
            </Page.Form>
          )}
        />
      </Page>
    )
  }
}

export default inject('onboarding', 'store')(observer(AccountInfoMore))

const Dropdown = ({ initialValue, input, meta, startingAge }) => {
  const ages = () => {
    const arr = []
    for (let i = startingAge; i <= 100; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }

  return (
    <DropdownElement
      error={meta.error}
      name={input.name}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      options={ages()}
      placeholder={initialValue + ''}
      selected={input.value}
      showError={meta.touched}
    />
  )
}
