import { Children, cloneElement, Component } from 'react'
import PropTypes from 'prop-types'
import { cx } from '@emotion/css'
import { themr } from '../react-css-themr'
import { LIST } from '../identifiers'
import InjectListItem from './ListItem'

const mergeProp = (propName, child, parent) =>
  child[propName] !== undefined ? child[propName] : parent[propName]

const factory = ListItem => {
  class List extends Component {
    static propTypes = {
      children: PropTypes.node,
      className: PropTypes.string,
      theme: PropTypes.shape({
        list: PropTypes.string,
      }),
    }

    static defaultProps = {
      className: '',
      ripple: false,
      selectable: false,
    }

    renderItems() {
      return Children.map(this.props.children, item => {
        if (item === null || item === undefined) {
          return item
        } else if (item.type === ListItem) {
          const selectable = mergeProp('selectable', item.props, this.props)
          const ripple = mergeProp('ripple', item.props, this.props)
          return cloneElement(item, { selectable, ripple })
        }
        return cloneElement(item)
      })
    }

    render() {
      return (
        <ul data-react-toolbox='list' className={cx(this.props.theme.list, this.props.className)}>
          {this.renderItems()}
        </ul>
      )
    }
  }

  return List
}

const List = factory(InjectListItem)
export default themr(LIST)(List)
export { factory as listFactory }
export { List }
