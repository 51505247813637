import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'

const ButtonGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'space-between')};
  max-width: ${p => (p.maxWidth ? p.maxWidth : '1200px')};
  margin: 60px auto;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 40px auto;
  }
`

const ButtonBox = styled.div`
  width: 190px;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px;
  margin: 12px 24px 12px 0px;
  opacity: ${props => (props.disabled ? '0.2' : '1')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  transition: 0.25s;
`

const ButtonTopText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 1.25rem;
  user-select: none;
  transition: 0.25s;
`

const ButtonBottomText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 0.875rem;
  user-select: none;
`

const CancelButton = ({ onClick, primaryText, secondaryText, disabled }) => (
  <ButtonBox
    onClick={onClick}
    disabled={disabled}
    css={css`
      border: 1px solid #b6c0c4;
      background-color: #ffffff;
      width: 125px;
      &:hover {
        background-color: #f3f4f5;
      }
      &:active {
        background-color: #9eacb0;
        & > div {
          color: #ffffff;
        }
      }
    `}>
    <ButtonTopText
      css={css`
        color: #4a606a;
      `}>
      {primaryText}
    </ButtonTopText>
    <ButtonBottomText
      css={css`
        color: #4a606a;
      `}>
      {secondaryText}
    </ButtonBottomText>
  </ButtonBox>
)

const SecondaryButton = ({ onClick, primaryText, secondaryText, disabled }) => (
  <ButtonBox
    onClick={onClick}
    disabled={disabled}
    css={css`
      background-color: #4a606a;
      &:hover {
        background-color: #40525b;
      }
      &:active {
        background-color: #35454c;
      }
    `}>
    <ButtonTopText>{primaryText}</ButtonTopText>
    <ButtonBottomText>{secondaryText}</ButtonBottomText>
  </ButtonBox>
)

const PrimaryButton = ({ onClick, primaryText, secondaryText, disabled }) => {
  const theme = useTheme()
  return (
    <ButtonBox
      onClick={onClick}
      disabled={disabled}
      css={css`
        background: ${theme.buttonColor};
        &:hover {
          background: ${theme.hoverButtonColor};
        }
        &:active {
          background: ${theme.activeButtonColor};
        }
      `}>
      <ButtonTopText>{primaryText}</ButtonTopText>
      <ButtonBottomText>{secondaryText}</ButtonBottomText>
    </ButtonBox>
  )
}

export { ButtonGroup, CancelButton, SecondaryButton, PrimaryButton }
