import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import ImplementChangesIcon from './icons/ImplementChangesIcon'
import ManageRiskIcon from './icons/ManageRiskIcon'
import AutoRebalanceIcon from './icons/AutoRebalanceIcon'
import PhoneSupportIcon from './icons/PhoneSupportIcon'

const Heading = styled.div`
  font-family: 'Open Sans';
  color: #038ab7;
  font-size: 20px;
  text-align: center;
  margin: 28px 20px 0;
`
const IconGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
  font-family: 'Open Sans';
  color: #4a606a;
  margin: 0 0 56px;
  & > div {
    flex: 0 1 200px;
    margin: 10px 20px;
    line-height: 1.35;
  }
`

function createProps({ config }) {
  switch (config.templateId) {
    // McDonald's (foundation)
    // NXP
    case 3:
    case 7:
      return {
        heading: 'When we manage your account, GuidedChoice:',
        implementChangesText: 'Implements changes to your savings rate and investments for you',
        manageRiskText:
          'Minimizes risk — while maximizing reward — for your personalized, diversified portfolio',
        automaticRebalanceText: 'Automatically rebalances your account to keep you on track',
        phoneSupportText: 'Is a phone call away whenever you need help',
      }

    // Atmos Energy
    // Cascade Sawing & Drilling
    case 4:
    case 5:
      return {
        heading: 'When we manage your account, GuidedChoice:',
        implementChangesText: 'Implements changes to your savings rate and investments for you',
        manageRiskText:
          'Minimizes risk — while maximizing reward — for your personalized, diversified portfolio',
        automaticRebalanceText: 'Automatically rebalances your account to keep you on track',
        phoneSupportText: 'Is a phone call away whenever you need help',
      }

    // GuidedChoice
    case 6:
      return {
        heading: 'When we manage your account, GuidedChoice:',
        implementChangesText: 'Implements changes to your savings rate and investments for you',
        manageRiskText:
          'Minimizes risk — while maximizing reward — for your personalized, diversified portfolio',
        automaticRebalanceText: 'Automatically rebalances your account to keep you on track',
        phoneSupportText: 'Is a phone call away whenever you need help',
      }

    // Paychex
    case 8:
      return {
        heading: 'As a managed account holder, GuidedChoice already:',
        implementChangesText: 'Implements changes to your savings rate and investments for you',
        manageRiskText:
          'Minimizes risk — while maximizing reward — for your personalized, diversified portfolio',
        automaticRebalanceText: 'Automatically rebalances your account to keep you on track',
        phoneSupportText: 'Is a phone call away whenever you need help',
      }

    // Schwab Legacy
    case 9:
      return {
        heading: 'When you accept point-in-time advice, GuidedChoice:',
        implementChangesText: 'Implements changes to your savings rate and investments for you',
        manageRiskText:
          'Minimizes risk — while maximizing reward — for your personalized, diversified portfolio',
        automaticRebalanceText: 'Automatically rebalances your account to keep you on track',
        phoneSupportText: 'Schwab is a phone call away whenever you need help',
      }

    // Schwab SIA
    case 10:
      return {
        heading: 'When we manage your account, GuidedChoice:',
        implementChangesText: 'Implements changes to your savings rate and investments for you',
        manageRiskText:
          'Minimizes risk — while maximizing reward — for your personalized, diversified portfolio',
        automaticRebalanceText: 'Automatically rebalances your account to keep you on track',
        phoneSupportText: 'Schwab is a phone call away whenever you need help',
      }
    default:
      return null
  }
}

function ManagedBenefits(props) {
  const { config } = props.store
  const renderProps = createProps({ config })

  return (
    <div>
      <Heading>{renderProps.heading}</Heading>

      <IconGroup>
        <div>
          <div>
            <ImplementChangesIcon />
          </div>
          <div>{renderProps.implementChangesText}</div>
        </div>

        <div>
          <div>
            <ManageRiskIcon />
          </div>
          <div>{renderProps.manageRiskText}</div>
        </div>

        <div>
          <div
            css={css`
              padding: 0 20px;
            `}>
            <AutoRebalanceIcon
              css={css`
                width: 90%;
                height: auto;
                margin-bottom: 25px;
              `}
            />
          </div>
          <div>{renderProps.automaticRebalanceText}</div>
        </div>

        <div>
          <div
            css={css`
              padding: 0 20px;
            `}>
            <PhoneSupportIcon
              css={css`
                margin-bottom: 15px;
              `}
            />
          </div>
          <div>{renderProps.phoneSupportText}</div>
        </div>
      </IconGroup>
    </div>
  )
}

export default inject('store')(observer(ManagedBenefits))
