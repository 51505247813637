import { useTheme } from '@emotion/react'

const SvgComponent = props => {
  const theme = useTheme()
  return (
    <svg
      id='implement_changes_icon'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 300 300'
      {...props}>
      <g fill='none' strokeMiterlimit={10}>
        <path stroke='#0d8bb8' d='M142.1 76.7V60.4m0 16.3v-8.2' />
        <path stroke='#90be3f' d='M123.4 80.4l-3.1-7.5' />
        <path stroke='#0d8bb8' d='M107.6 91L96.1 79.5M107.6 91l-5.7-5.8m74.7 5.8l11.5-11.5' />
        <path stroke='#90be3f' d='M160.8 80.4l3.1-7.5' />
      </g>
      <g strokeMiterlimit={10}>
        <path
          d='M260.4 174.7c1.3 0 2.4-1 2.6-2.4.2-2 .3-4.1.3-6.2 0-1.8-.1-3.6-.3-5.3-.1-1.3-1.2-2.4-2.6-2.4h-5.7c-4.6 0-8.8-2.8-10.4-7-.1-.4-.3-.7-.4-1.1-1.8-4.2-.7-9 2.5-12.2l4.2-4.2c.9-.9 1-2.5.2-3.5-2.4-3-5.1-5.7-8.1-8.2-1-.9-2.6-.8-3.5.2l-4.1 4.1c-3.2 3.2-8.1 4.2-12.3 2.4-.4-.2-.8-.4-1.3-.5-4.2-1.7-6.9-5.8-6.9-10.4v-5.9c0-1.3-1-2.5-2.4-2.6a56.33 56.33 0 0 0-11.6 0c-1.3.1-2.4 1.3-2.4 2.6v5.9c0 4.5-2.7 8.7-6.9 10.4-.4.2-.8.3-1.3.5-4.2 1.8-9.1.8-12.3-2.4l-4.1-4.1c-1-1-2.5-1-3.5-.2-3 2.5-5.7 5.2-8.1 8.2-.8 1-.8 2.6.2 3.5l4.2 4.2c3.2 3.2 4.2 8 2.5 12.2-.1.4-.3.7-.4 1.1-1.7 4.3-5.8 7-10.4 7h-5.7c-1.3 0-2.5 1-2.6 2.4-.2 1.8-.3 3.5-.3 5.3 0 2.1.1 4.2.3 6.2.1 1.3 1.3 2.4 2.6 2.4h6c4.5 0 8.6 2.7 10.4 6.8.1.3.2.5.3.8 1.8 4.2.9 9.1-2.4 12.4l-4.1 4.1c-1 1-1 2.5-.2 3.5 2.5 3 5.2 5.7 8.2 8.1 1 .8 2.6.8 3.5-.2l4.2-4.2c3.2-3.2 8-4.2 12.2-2.5l.4.2c4.4 1.8 7.2 6 7.2 10.8v5.6c0 1.3 1 2.5 2.4 2.6a56.33 56.33 0 0 0 11.6 0c1.3-.1 2.4-1.3 2.4-2.6v-5.9c0-4.6 2.8-8.7 7-10.4l.4-.2c4.4-1.8 9.4-.8 12.7 2.5l4 4c.9.9 2.5 1 3.5.2 3-2.4 5.7-5.1 8.2-8.1.9-1 .8-2.6-.2-3.5l-4.1-4.1c-3.3-3.3-4.2-8.2-2.4-12.4.1-.3.2-.5.3-.8 1.7-4.2 5.8-6.8 10.4-6.8h6.1zM234.8 206.5l11.5-11.5m-25.6 9.3l24-24m4.1-4.1l14.4-14.4m-12.2-4l-32.8 32.7m25.2-41.5l-13 13m18-34.2l-23 23m-7.2-9.1l12.6-12.6'
          fill={theme.homeBgColor}
          stroke='#0d8bb8'
        />
        <circle cx={206.4} cy={166.1} r={16.3} fill={theme.homeBgColor} stroke='#0d8bb8' />
        <path
          d='M206.4 190.5c-13.5 0-24.4-10.9-24.4-24.4 0-13.5 10.9-24.4 24.4-24.4'
          fill='none'
          stroke='#90be3f'
        />
      </g>
      <defs>
        <circle id='implement_changes_icon_1_' cx={81.5} cy={170.2} r={52.9} />
      </defs>
      <clipPath id='implement_changes_icon_2_'>
        <use xlinkHref='#implement_changes_icon_1_' overflow='visible' />
      </clipPath>
      <g
        clipPath='url(#implement_changes_icon_2_)'
        fill='none'
        stroke='#90be3f'
        strokeMiterlimit={10}>
        <path d='M105.8 222.8l32.5-32.6m-32.5 16.3l32.5-32.5m-32.5 16.2l32.5-32.5M105.8 174l32.5-32.5' />
      </g>
      <use
        xlinkHref='#implement_changes_icon_1_'
        overflow='visible'
        fill='none'
        stroke='#90be3f'
        strokeMiterlimit={10}
      />
      <path
        d='M120.9 148.3c-7.7-13.8-22.4-23.1-39.4-23.1-24.9 0-45 20.2-45 45s20.2 45 45 45c5.7 0 11.1-1.1 16.1-3'
        fill='none'
        stroke='#90be3f'
        strokeMiterlimit={10}
      />
      <path
        d='M84.6 165.2c-5.6-2.3-6.5-3.4-6.5-5.1 0-.7.3-3.2 4.5-3.2 3.7 0 6 1.3 7 1.9l1.4.8c.5.3 1.1.1 1.3-.5l2.6-6.7c.2-.4 0-.9-.4-1.1l-1-.6c-2.2-1.2-4.5-2-7.2-2.4v-5.4c0-.5-.4-.9-.9-.9h-6.7c-.5 0-.9.4-.9.9v5.9c-6 1.6-9.7 6.2-9.7 12 0 7.4 6.1 10.8 12 13.1 5.1 2 5.8 3.8 5.8 5.4 0 2.4-2.2 3.9-5.4 3.9-2.8 0-5.8-.9-8-2.4l-1.4-.9c-.5-.3-1.1-.1-1.4.5l-2.5 6.9c-.1.4 0 .8.3 1.1l.9.6c2.2 1.5 5.5 2.7 8.9 3.1v5.6c0 .5.4.9.9.9H85c.5 0 .9-.4.9-.9v-6.1C92 189.9 96 185 96 179c-.1-8.2-6.1-11.7-11.4-13.8z'
        fill='none'
        stroke='#90be3f'
        strokeMiterlimit={10}
      />
      <path fill='none' d='M-698-528.5H326v1358H-698z' />
      <defs>
        <ellipse id='implement_changes_icon_5_' cx={-185.9} cy={150.9} rx={64.9} ry={63.9} />
      </defs>
      <clipPath id='implement_changes_icon_4_'>
        <use xlinkHref='#implement_changes_icon_5_' overflow='visible' />
      </clipPath>
      <g clipPath='url(#implement_changes_icon_4_)'>
        <defs>
          <path id='implement_changes_icon_7_' d='M-941.5-239.6H97v1377.2H-941.5z' />
        </defs>
        <clipPath id='implement_changes_icon_6_'>
          <use xlinkHref='#implement_changes_icon_7_' overflow='visible' />
        </clipPath>
      </g>
      <path fill='none' d='M90-621h1024V737H90z' />
      <path fill='none' d='M-699-525.5H325v1358H-699z' />
      <path fill='none' d='M90-621h1024V737H90z' />
      <path fill='none' d='M90-621h1024V737H90z' />
      <g>
        <defs>
          <path
            id='implement_changes_icon_9_'
            d='M467.1 133.2c-.5.2-1 .5-1.7.5h-23.9c-.6 0-1.2-.2-1.7-.5-14 11.3-32.9 36.7-32.9 52.9 0 15 4.6 24.4 14.5 32.5 2.9 2.4 6.6 3.7 10.5 3.7h42.7c3.9 0 7.6-1.3 10.5-3.7 10-8.2 14.5-17.6 14.5-32.5.4-16.2-18.4-41.7-32.5-52.9z'
          />
        </defs>
        <clipPath id='implement_changes_icon_8_'>
          <use xlinkHref='#implement_changes_icon_9_' overflow='visible' />
        </clipPath>
        <g clipPath='url(#implement_changes_icon_8_)'>
          <defs>
            <path id='implement_changes_icon_11_' d='M-280.3-1314.4h1926.4v2554.7H-280.3z' />
          </defs>
          <clipPath id='implement_changes_icon_10_'>
            <use xlinkHref='#implement_changes_icon_11_' overflow='visible' />
          </clipPath>
        </g>
        <defs>
          <path
            id='implement_changes_icon_13_'
            d='M465.5 126.9c.2 0 .5 0 .7.1l10-9.4c.8-.8.6-1.8-.4-2.3l-3.4-1.6c-1-.5-2.7-.6-3.8-.2l-4.7 1.5c-1.1.3-2.7.1-3.6-.5l-5.2-3.7c-.9-.6-2.5-.6-3.3 0l-5.2 3.7c-.9.6-2.5.9-3.6.5l-4.7-1.5c-1.1-.3-2.8-.2-3.8.2l-3.4 1.6c-1 .5-1.2 1.5-.4 2.3l10 9.4c.2-.1.5-.1.7-.1h24.1z'
          />
        </defs>
        <clipPath id='implement_changes_icon_12_'>
          <use xlinkHref='#implement_changes_icon_13_' overflow='visible' />
        </clipPath>
        <g clipPath='url(#implement_changes_icon_12_)'>
          <defs>
            <path id='implement_changes_icon_15_' d='M-280.3-1314.4h1926.4v2554.7H-280.3z' />
          </defs>
          <clipPath id='implement_changes_icon_14_'>
            <use xlinkHref='#implement_changes_icon_15_' overflow='visible' />
          </clipPath>
        </g>
        <defs>
          <path
            id='implement_changes_icon_17_'
            d='M465.5 133.6c.6 0 1.2-.2 1.7-.5 1-.5 1.7-1.6 1.7-3 0-1.6-1.1-3-2.7-3.3-.2-.1-.5-.1-.7-.1h-23.9c-.2 0-.5 0-.7.1-1.5.3-2.7 1.6-2.7 3.3 0 1.2.7 2.3 1.7 3 .5.2 1 .5 1.7.5h23.9z'
          />
        </defs>
        <clipPath id='implement_changes_icon_16_'>
          <use xlinkHref='#implement_changes_icon_17_' overflow='visible' />
        </clipPath>
        <g clipPath='url(#implement_changes_icon_16_)'>
          <defs>
            <path id='implement_changes_icon_19_' d='M-280.3-1314.4h1926.4v2554.7H-280.3z' />
          </defs>
          <clipPath id='implement_changes_icon_18_'>
            <use xlinkHref='#implement_changes_icon_19_' overflow='visible' />
          </clipPath>
        </g>
        <g>
          <defs>
            <path
              id='implement_changes_icon_21_'
              d='M492.2 186.1c0 12.3-3.3 19.8-11.8 26.6-1.6 1.2-3.6 1.9-5.5 1.9'
            />
          </defs>
          <clipPath id='implement_changes_icon_20_'>
            <use xlinkHref='#implement_changes_icon_21_' overflow='visible' />
          </clipPath>
          <g clipPath='url(#implement_changes_icon_20_)'>
            <defs>
              <path id='implement_changes_icon_23_' d='M-280.3-1314.4h1926.4v2554.7H-280.3z' />
            </defs>
            <clipPath id='implement_changes_icon_22_'>
              <use xlinkHref='#implement_changes_icon_23_' overflow='visible' />
            </clipPath>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
