import { useState } from 'react'
import { Provider, inject, observer } from 'mobx-react'
import _ from 'lodash'

import { Pdf } from './elements'
import SpendingGuideStore from './SpendingGuideStore'

import {
  CoverPage,
  MonthlyRetirementPage,
  TodoListPage,
  InvestmentsPage,
  IraPage,
  WithdrawPage,
  AnnuityPage,
  AssumptionsPage,
  DisclosuresPage,
  CompanyStockForExpenses,
} from './index'

import ThemeWrapper from '../../themes/ThemeWrapper'

function SpendingGuide(props) {
  const { store, location, match } = props

  const [state] = useState(() => SpendingGuideStore.create({}, { store, location, match }))

  if (state.status === 'loading') {
    return <div>Loading</div>
  }

  if (state.status === 'error') {
    return <div>{state.error}</div>
  }

  const { fetchComplete } = state.guidePages

  if (!fetchComplete) return []

  return (
    <Provider guide={state}>
      <ThemeWrapper>
        <Pdf>
          <CoverPage />
          <MonthlyRetirementPage />
          <TodoListPage />
          <InvestmentsPage />
          <IraPage />
          <WithdrawPage />
          {(!_.isEmpty(state.investmentWithdrawals.primaryLifetimeInvestmentIncome) ||
            !_.isEmpty(state.investmentWithdrawals.spouseLifetimeInvestmentIncome)) && (
            <AnnuityPage />
          )}
          <AssumptionsPage />
          {(_.get(state, 'investmentWithdrawals.sellingCompanyStockForAnnuities', null) ||
            _.get(state, 'isWithdrawingFromCompanyStock', null)) && <CompanyStockForExpenses />}
          <DisclosuresPage />
        </Pdf>
      </ThemeWrapper>
    </Provider>
  )
}

export default inject('store')(observer(SpendingGuide))
