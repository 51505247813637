import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'

import { Spacer } from '../../../components'
import { StackedChartWithDescription, ComparisonStackedChartWithDescription } from '../elements'

const LeftSection = styled.div`
  flex: 0 1 575px;
  margin-bottom: ${p => (p.marginBottom ? p.marginBottom : '24px')};
`
const SubHeader = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  padding-bottom: 8px;
  line-height: 1.25;
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : null)};
  align-items: ${p => (p.alignItems ? p.alignItems : 'center')};
  flex-wrap: wrap;

  margin: ${p => (p.childMargin ? '0 -8px' : null)};

  & > * {
    margin: ${p => (p.childMargin ? '0 8px' : null)};
  }
`

const InvestmentAccounts = ({ store, displayInflation }) => (
  <LeftSection>
    <SubHeader>
      Investment accounts: what you've saved <br /> and what you can expect
    </SubHeader>

    <Spacer space='6px' />

    <FlexRow childMargin='0 20px' alignItems='stretch'>
      {store.modifiedCase ? (
        <ComparisonStackedChartWithDescription displayInflation={displayInflation} />
      ) : (
        <StackedChartWithDescription
          displayInflation={displayInflation}
          engineType='recommended'
          label='Recommended'
        />
      )}
    </FlexRow>
  </LeftSection>
)

export default inject('store')(observer(InvestmentAccounts))
