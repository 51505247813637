import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'
import { numberToDollars } from '../../../utils/utils'

function AboutYourAnnuity(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
      investmentWithdrawals,
    },
    store: { person, spouse },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'aboutYourAnnuity' })
    willMount.current = false
  }

  const page = findByName('aboutYourAnnuity')
  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.AboutPageTitle>About your annuity</Wrapper.AboutPageTitle>
        <Wrapper.AboutPageWrapper>
          {_.get(investmentWithdrawals, 'primaryLifetimeInvestmentIncome.monthlyAmount', 0) > 0 && (
            <>
              <Wrapper.AboutBlueBox>
                <Wrapper.AboutBlueBoxTitle>
                  ...in {person.displayName}'s name
                </Wrapper.AboutBlueBoxTitle>
                <Wrapper.AboutBlueBoxText>
                  GuidedChoice estimates the cost of your annuity to be:
                </Wrapper.AboutBlueBoxText>
                <Wrapper.AboutBlueBoxAnnuityAmount>
                  {numberToDollars(
                    _.get(
                      investmentWithdrawals,
                      'primaryLifetimeInvestmentIncome.costOfInvestment',
                      0
                    ),
                    true
                  )}
                </Wrapper.AboutBlueBoxAnnuityAmount>
                <Wrapper.AboutBlueBoxText>
                  This will buy you{' '}
                  <Wrapper.AboutBlueBoxAnnuityMonthlyAmount>
                    {numberToDollars(
                      _.get(
                        investmentWithdrawals,
                        'primaryLifetimeInvestmentIncome.monthlyAmount',
                        0
                      ),
                      true
                    )}{' '}
                  </Wrapper.AboutBlueBoxAnnuityMonthlyAmount>
                  monthly income for life.
                </Wrapper.AboutBlueBoxText>
                <Wrapper.AboutBlueBoxSecondaryText>
                  If your payments begin at age{' '}
                  <Wrapper.AboutBlueBoxSecondaryTextAge>
                    {_.get(
                      investmentWithdrawals,
                      'primaryLifetimeInvestmentIncome.startPaymentAge',
                      0
                    )}
                  </Wrapper.AboutBlueBoxSecondaryTextAge>{' '}
                  and you live your full life expectancy
                  <br />(
                  <Wrapper.AboutBlueBoxSecondaryTextAge>
                    {_.get(investmentWithdrawals, 'primary.lifeExpectancy', 0)}
                  </Wrapper.AboutBlueBoxSecondaryTextAge>
                  ), you will have received{' '}
                  <Wrapper.AboutBlueBoxSecondaryTextAmount>
                    {numberToDollars(
                      _.get(investmentWithdrawals, 'primary.lifetimeAnnuityPayoff', 0),
                      true
                    )}
                  </Wrapper.AboutBlueBoxSecondaryTextAmount>{' '}
                  for your{' '}
                  <Wrapper.AboutBlueBoxSecondaryTextAmount>
                    {numberToDollars(
                      _.get(
                        investmentWithdrawals,
                        'primaryLifetimeInvestmentIncome.costOfInvestment',
                        0
                      ),
                      true
                    )}
                  </Wrapper.AboutBlueBoxSecondaryTextAmount>
                  .
                  <br />
                  If you live to age 100, you will have received{' '}
                  <Wrapper.AboutBlueBoxSecondaryTextAmount>
                    {numberToDollars(
                      _.get(investmentWithdrawals, 'primary.oneHundredYearsOfAgePayoff', 0),
                      true
                    )}
                  </Wrapper.AboutBlueBoxSecondaryTextAmount>
                  .
                </Wrapper.AboutBlueBoxSecondaryText>
              </Wrapper.AboutBlueBox>
              <Spacer space='10pt' />
            </>
          )}

          {person.includeSpouse &&
            _.get(investmentWithdrawals, 'spouseLifetimeInvestmentIncome.monthlyAmount', 0) > 0 && (
              <>
                <Wrapper.AboutBlueBox>
                  <Wrapper.AboutBlueBoxTitle>
                    ...in {spouse.firstName}'s name
                  </Wrapper.AboutBlueBoxTitle>
                  <Wrapper.AboutBlueBoxText>
                    GuidedChoice estimates the cost of your spouse's annuity to be:
                  </Wrapper.AboutBlueBoxText>
                  <Wrapper.AboutBlueBoxAnnuityAmount>
                    {numberToDollars(
                      _.get(
                        investmentWithdrawals,
                        'spouseLifetimeInvestmentIncome.costOfInvestment',
                        0
                      ),
                      true
                    )}
                  </Wrapper.AboutBlueBoxAnnuityAmount>
                  <Wrapper.AboutBlueBoxText>
                    This will buy your spouse{' '}
                    <Wrapper.AboutBlueBoxAnnuityMonthlyAmount>
                      {numberToDollars(
                        _.get(
                          investmentWithdrawals,
                          'spouseLifetimeInvestmentIncome.monthlyAmount',
                          0
                        ),
                        true
                      )}{' '}
                    </Wrapper.AboutBlueBoxAnnuityMonthlyAmount>
                    monthly income for life.
                  </Wrapper.AboutBlueBoxText>
                  <Wrapper.AboutBlueBoxSecondaryText>
                    If your spouse's payments begin at age{' '}
                    <Wrapper.AboutBlueBoxSecondaryTextAge>
                      {_.get(
                        investmentWithdrawals,
                        'spouseLifetimeInvestmentIncome.startPaymentAge',
                        0
                      )}
                    </Wrapper.AboutBlueBoxSecondaryTextAge>{' '}
                    and they live their full life
                    <br />
                    expectancy (
                    <Wrapper.AboutBlueBoxSecondaryTextAge>
                      {_.get(investmentWithdrawals, 'spouse.lifeExpectancy', 0)}
                    </Wrapper.AboutBlueBoxSecondaryTextAge>
                    ), they will have received{' '}
                    <Wrapper.AboutBlueBoxSecondaryTextAmount>
                      {numberToDollars(
                        _.get(investmentWithdrawals, 'spouse.lifetimeAnnuityPayoff', 0),
                        true
                      )}
                    </Wrapper.AboutBlueBoxSecondaryTextAmount>{' '}
                    for their{' '}
                    <Wrapper.AboutBlueBoxSecondaryTextAmount>
                      {numberToDollars(
                        _.get(
                          investmentWithdrawals,
                          'spouseLifetimeInvestmentIncome.costOfInvestment',
                          0
                        ),
                        true
                      )}
                    </Wrapper.AboutBlueBoxSecondaryTextAmount>
                    .
                    <br />
                    If they live to age 100, they will have received{' '}
                    <Wrapper.AboutBlueBoxSecondaryTextAmount>
                      {numberToDollars(
                        _.get(investmentWithdrawals, 'spouse.oneHundredYearsOfAgePayoff', 0),
                        true
                      )}
                    </Wrapper.AboutBlueBoxSecondaryTextAmount>
                    .
                  </Wrapper.AboutBlueBoxSecondaryText>
                </Wrapper.AboutBlueBox>
                <Spacer space='20pt' />
              </>
            )}
          <Wrapper.AboutDisclaimerTitle>
            <b>Change your mind?</b>
          </Wrapper.AboutDisclaimerTitle>
          <Wrapper.AboutDisclaimer>
            If you're no longer sure that you want to buy an annuity, just log in to the
            GuidedChoice service again, update your plans, and get another Guide to plan for a
            lifetime of retirement income with no annuity.
          </Wrapper.AboutDisclaimer>
        </Wrapper.AboutPageWrapper>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(AboutYourAnnuity))
