import styled from '@emotion/styled'

const Card = styled.div`
  display: inline-block;
  position: relative;
  min-height: 300px;
  width: 250px;

  margin: 20px 5px;
  padding: 1rem;
  border: ${p => p.theme.cardBorder};
  background-color: ${p => p.theme.cardBgColor};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;

  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.cardHoverBgColor};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
`

Card.Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 80px;
  overflow: hidden;
`
Card.RowHeader = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
`

Card.Amount = styled.div`
  display: inline-block;
  color: ${p => p.theme.cardAmountTextColor};
  font-size: 1.125rem;
  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
    font-size: 1.125rem;
  }
`

Card.Text = styled.div`
  color: ${p => p.theme.cardTextColor};
  font-size: 1.125rem;
  line-height: 1.25;
`

Card.Edit = styled.div`
  height: 30px;
  font-size: 0.875rem;
  cursor: pointer;
  color: ${p => p.theme.editColor};
  text-decoration: underline;
  text-align: right;
`

Card.NameTextBox = styled.div`
  line-height: 1.25rem;
  display: flex;
`

Card.NameText = styled.span`
  max-width: 100%;
  overflow: hidden;
  align-self: flex-end;
  color: ${p => p.theme.cardNameTextColor};
  word-break: break-word;
  vertical-align: top;
  font-size: 1.125rem;
  line-height: 1.25;
`

Card.Label = styled.div`
  color: ${p => p.theme.cardLabelTextColor};
  font-size: 0.875rem;

  text-decoration: ${p => (p.underline ? 'underline' : null)};
  padding: 4px 0;
  line-height: 1.4;
`

Card.GrayOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom left, #d8d8d8 49%, #a8b4b9, #d8d8d8 51%);
  background-color: ${p => p.theme.lightestGray};
  opacity: 0.35;
  overflow: hidden;
  pointer-events: none;
`
Card.SVGContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 165px;
`
Card.DeleteSVGContainer = styled.div`
  padding-right: 8px;
`

export default Card
