import { types, getEnv, getParent } from 'mobx-state-tree'
import _ from 'lodash'
import { accountTypeKeyToPlanTypeId, planTypeIdToAccountKey } from '../constants'
import Investments from './Investments'
import { accountTypeToAllowedContributions } from '../views/accounts/utils/accountTypeToAllowedContributions'
const { model, maybeNull, number, string, boolean, optional, literal } = types

export const NonGCAccount = model({
  id: maybeNull(number),

  // basics
  participantId: maybeNull(number),
  planType: maybeNull(number), // account Type
  name: maybeNull(string), // accountName
  adviced: maybeNull(boolean),

  // paycheck contributions
  contributionEligibility: maybeNull(boolean),
  employeeContrib: maybeNull(boolean),
  contribMethod: maybeNull(string),

  pretaxSavingsRate: maybeNull(number),
  preTaxSavingsAmt: maybeNull(number),
  rothContribAllowed: maybeNull(boolean),
  rothSavingsRate: maybeNull(number),
  rothSavingsAmt: maybeNull(number),
  posttaxContribAllowed: maybeNull(boolean),
  posttaxSavingsRate: maybeNull(number),
  postTaxSavingsAmt: maybeNull(number),

  // employer Contribution
  employerContrib: maybeNull(boolean),
  employerMatch: maybeNull(boolean),
  companyMatchRate: maybeNull(number),
  companyMatchRateMax: maybeNull(number),
  companyMatchDlrMax: maybeNull(number),
  profitSharing: maybeNull(boolean),
  profitSharingRate: maybeNull(number),

  balance: maybeNull(number),
  investments: optional(Investments, {}),
  type: literal('NonGc'),
})
  .views(self => ({
    get preTaxContribAllowed() {
      const accountKey = planTypeIdToAccountKey[self.planType]
      const allowedContributions = accountTypeToAllowedContributions[accountKey]
      return allowedContributions?.preTaxContribAllowed ?? true
    },
    get employerMatchDisplayValue() {
      return self.employerMatch ? 'Yes' : 'No'
    },
    get isSpouseOwned() {
      const {
        spouse: { id },
      } = getEnv(getParent(self)).store
      return self.participantId === id
    },
    get isPrimaryOwned() {
      const {
        person: { id },
      } = getEnv(getParent(self)).store
      return self.participantId === id
    },
    get participantName() {
      const {
        spouse: { id, firstName },
        person: { displayName },
      } = getEnv(getParent(self)).store
      if (self.participantId === id) {
        return firstName
      } else {
        return displayName
      }
    },
    get participantSalary() {
      const {
        spouse: { id, annualIncome },
        account: { annualSalary },
      } = getEnv(getParent(self)).store

      if (self.participantId === id) {
        return annualIncome
      } else {
        return annualSalary
      }
    },
    get isBasicsNull() {
      const basics = ['participantId', 'planType', 'name', 'adviced']
      for (let i = 0; i < basics.length; i++) {
        if (self[basics[i]] !== null) {
          return false
        }
      }
      return true
    },
    get isPaycheckNull() {
      const paycheck = [
        'contributionEligibility',
        'employeeContrib',
        'contribMethod',
        'pretaxSavingsRate',
        'preTaxSavingsAmt',
        'rothContribAllowed',
        'rothSavingsRate',
        'rothSavingsAmt',
        'posttaxContribAllowed',
        'posttaxSavingsRate',
        'postTaxSavingsAmt',
      ]

      for (let i = 0; i < paycheck.length; i++) {
        if (self[paycheck[i]] !== null) {
          return false
        }
      }
      return true
    },
    get isEmployerNull() {
      const employer = [
        'employerContrib',
        'companyMatchRate',
        'companyMatchRateMax',
        'companyMatchDlrMax',
        'profitSharing',
        'profitSharingRate',
      ]

      for (let i = 0; i < employer.length; i++) {
        if (self[employer[i]] !== null) {
          return false
        }
      }
      return true
    },
    get trackInvestmentsByTaxType() {
      return _.includes(
        [
          accountTypeKeyToPlanTypeId['401a'],
          accountTypeKeyToPlanTypeId['401k'],
          accountTypeKeyToPlanTypeId['403b'],
          accountTypeKeyToPlanTypeId['457'],
          accountTypeKeyToPlanTypeId['annuity-fixed'],
          accountTypeKeyToPlanTypeId.keogh,
          // accountTypeKeyToPlanTypeId["payroll-deduct-ira"],
          accountTypeKeyToPlanTypeId.pension,
          accountTypeKeyToPlanTypeId['sep-ira'],
          accountTypeKeyToPlanTypeId['simple-ira'],
        ],
        self.planType
      )
    },
    get oneContributionOnly() {
      return self.contributionEligibility && !self.posttaxContribAllowed && !self.rothContribAllowed
    },
    get totalContributionRate() {
      return (
        self.pretaxSavingsRate +
        self.posttaxContribAllowed * self.posttaxSavingsRate +
        self.rothContribAllowed * self.rothSavingsRate
      )
    },
  }))
  .actions(self => ({}))
