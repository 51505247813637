/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { SECURITY_TYPES_TEXT, TAX_TYPES } from '../../../../constants'
import Close from '../../../../assets/icons/Close'
import { CurrencyText, DeleteModal, SVGWrapper } from '../../../../components'
import { placeholderSymbolReplace } from '../../../../utils'
import { CloseIcon, InvestmentListItemContainer, InvestmentListItemBox } from '../../styled'

const amountWrapsAtWidth = 990
const sidebarWidth = 0
const breakpoint = p =>
  p.sidebarOpen ? `${amountWrapsAtWidth}px` : `${amountWrapsAtWidth - sidebarWidth}px`

const FlexRowBetween = styled.div`
  display: flex;
  flex: wrap;
  flex-direction: row;
  width: 100%;

  @media (max-width: ${breakpoint}) {
    flex-direction: column;
  }
`

export const Type = styled.div`
  flex: 0 0 20%;
  padding: 0 8px;
  word-wrap: break-word;
  white-space: pre-wrap;

  @media (max-width: ${breakpoint}) {
    width: 100%;
    flex-basis: auto;
  }
`

export const Ticker = styled.div`
  flex: 0 0 20%;
  padding: 0 8px;
  word-wrap: break-word;
  white-space: pre-wrap;

  @media (max-width: ${breakpoint}) {
    width: 100%;
    flex-basis: auto;
  }
`

export const Name = styled.div`
  flex: 1 1 40%;
  padding: 0 8px;
  word-wrap: break-word;
  white-space: pre-wrap;

  @media (max-width: ${breakpoint}) {
    width: 100%;
    flex-basis: auto;
  }
`

const Amount = styled.div`
  flex: 1 0 auto;
  padding: 0 8px;
  text-align: right;
  word-wrap: break-word;
  white-space: pre-wrap;

  @media (max-width: ${breakpoint}) {
    flex-basis: auto;
    text-align: left;
    width: 100%;
  }
`

const Edit = styled.div`
  color: ${p => p.theme.editColor};
  @media (max-width: ${breakpoint}) {
    padding: 0 8px;
  }
`

const InvestmentGroupItem = props => {
  const [deleteActive, setDeleteActive] = useState(false)
  const [status, setStatus] = useState('done')

  const toggleDeleteModal = e => {
    e.stopPropagation()
    setDeleteActive(!deleteActive)
    setStatus('done')
  }

  const handleDelete = async e => {
    e.stopPropagation()
    setStatus('loading')

    try {
      const {
        features,
        store,
        store: { institutionalAccount },
        match: {
          params: { type, id },
        },
        account: { deleteInvestment, getAvailableInvestments },
        investment,
      } = props
      const isInstitutionalAccount = props.account?.account?.id === institutionalAccount?.id
      await deleteInvestment({ type, id, investment })
      await store.getAccounts()
      if (isInstitutionalAccount && features.editInstitutionalAccount) {
        await getAvailableInvestments()
      }
    } catch (err) {
      console.error(err)
      setStatus('error')
    }
  }

  const { canEdit, toggleEdit } = props
  const { taxType, value, securities: security, id } = props.investment
  const { securityType, securityName, ticker } = security
  const securityTypeName = SECURITY_TYPES_TEXT[securityType]

  return (
    <InvestmentListItemContainer>
      {canEdit && (
        <CloseIcon onClick={toggleDeleteModal}>
          <SVGWrapper margin='9px' size='large' svg={Close} />
        </CloseIcon>
      )}
      <InvestmentListItemBox>
        <FlexRowBetween sidebarOpen={false}>
          <Type>{securityTypeName}</Type>
          <Ticker>{ticker}</Ticker>
          <Name>{placeholderSymbolReplace(securityName)}</Name>
          {taxType !== TAX_TYPES.notInvested && (
            <>
              <Amount sidebarOpen={false}>
                <CurrencyText decimalScale={2} value={value} />
              </Amount>
              {canEdit && (
                <Edit sidebarOpen={false}>
                  <a
                    href='#'
                    onClick={() => toggleEdit(id)}
                    css={css`
                      cursor: pointer;
                    `}>
                    Edit
                  </a>
                </Edit>
              )}
            </>
          )}
        </FlexRowBetween>
      </InvestmentListItemBox>
      <DeleteModal
        handleConfirmation={handleDelete}
        isModalOpen={deleteActive}
        title='Delete Investment'
        toggleModal={toggleDeleteModal}
        loading={status === 'loading'}
        error={status === 'error'}
      />
    </InvestmentListItemContainer>
  )
}

InvestmentGroupItem.propTypes = {
  investment: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  toggleEdit: PropTypes.func,
}

export default withRouter(inject('store', 'account')(observer(InvestmentGroupItem)))
