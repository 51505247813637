import styled from '@emotion/styled'

const Header = styled.div`
  background-color: #032939;
  height: 75%;
`

const CoverTitle = styled.div`
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 3.75rem;
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 150px 0 0;
`

const CoverText = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  color: white;
  text-align: center;
  padding: 64px 0 48px;
  line-height: 1.35;
`

const ContentsBox = styled.div`
  background-color: #038ab7;
  color: white;
  text-align: center;
  font-size: 18px;
  width: 580px;
  margin: auto;
  height: 278px;
`

const ContentsTitle = styled.div`
  font-size: 1.167rem;
  padding: 20px;
`

const ContentCard = styled.div`
  background-color: #67cd36;
  padding: 20px;
  color: white;
  width: 110%;
  margin-left: -5%;
  margin-bottom: 14px;
`

ContentCard.Title = styled.div`
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 1.75rem;
  text-align: center;
`

ContentCard.Item = styled.li`
  font-size: 1.08rem;
  font-weight: bold;
  text-align: left;
  width: 400px;
  margin: auto;
  line-height: 1.35;
`

const LogoContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  /*padding-top: 48px;*/
  margin-top: 48px;
  & img {
    width: 100%;
  }
`

export { Header, CoverTitle, CoverText, ContentsBox, ContentsTitle, ContentCard, LogoContainer }
