const SvgComponent = props => (
  <svg
    id='auto_rebalance_icon'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 300 300'
    {...props}>
    <g fill='none' strokeMiterlimit={10}>
      <g stroke='#0d8bb8'>
        <path d='M130.4 56.9C89.9 56.9 57 89.7 57 130.3m72.7-61.6c-20.2 13.1-33.6 35.8-33.6 61.6' />
        <path d='M130.4 56.9h39.2c40.5 0 73.4 32.9 73.4 73.4h17.1L223.4 167l-36.7-36.7h17.1c0-40.6-32.8-73.4-73.4-73.4zm39.2 186c40.5 0 73.4-32.9 73.4-73.4m-72.8 61.6c20.2-13.1 33.6-35.8 33.6-61.6M169.6 242.9c-40.5 0-73.4-32.9-73.4-73.4h17.1l-36.7-36.7-36.7 36.7H57c0 40.5 32.9 73.4 73.4 73.4h39.2z' />
      </g>
      <path
        d='M129.7 167.4c0 10.5 8.5 19.1 19.1 19.1 10.5 0 19.1-8.5 19.1-19.1 0-19.1-35.1-19.1-35.1-38.2 0-8.6 5.5-16 16-16s16 8 16 16m-16 73.5v-16.2m0-73.3V97'
        stroke='#90be3f'
      />
    </g>
    <path fill='none' d='M-582-621H442V737H-582z' />
    <path fill='none' d='M-582-621H442V737H-582z' />
    <path fill='none' d='M-582-621H442V737H-582z' />
    <defs>
      <path
        id='auto_rebalance_icon_9_'
        d='M-204.9 133.2c-.5.2-1 .5-1.7.5h-23.9c-.6 0-1.2-.2-1.7-.5-14 11.3-32.9 36.7-32.9 52.9 0 15 4.6 24.4 14.5 32.5 2.9 2.4 6.6 3.7 10.5 3.7h42.7c3.9 0 7.6-1.3 10.5-3.7 10-8.2 14.5-17.6 14.5-32.5.4-16.2-18.4-41.7-32.5-52.9z'
      />
    </defs>
    <clipPath id='auto_rebalance_icon_2_'>
      <use xlinkHref='#auto_rebalance_icon_9_' overflow='visible' />
    </clipPath>
    <g clipPath='url(#auto_rebalance_icon_2_)'>
      <defs>
        <path id='auto_rebalance_icon_11_' d='M-952.3-1314.4H974.1v2554.7H-952.3z' />
      </defs>
      <clipPath id='auto_rebalance_icon_4_'>
        <use xlinkHref='#auto_rebalance_icon_11_' overflow='visible' />
      </clipPath>
    </g>
    <defs>
      <path
        id='auto_rebalance_icon_13_'
        d='M-206.5 126.9c.2 0 .5 0 .7.1l10-9.4c.8-.8.6-1.8-.4-2.3l-3.4-1.6c-1-.5-2.7-.6-3.8-.2l-4.7 1.5c-1.1.3-2.7.1-3.6-.5l-5.2-3.7c-.9-.6-2.5-.6-3.3 0l-5.2 3.7c-.9.6-2.5.9-3.6.5l-4.7-1.5c-1.1-.3-2.8-.2-3.8.2l-3.4 1.6c-1 .5-1.2 1.5-.4 2.3l10 9.4c.2-.1.5-.1.7-.1h24.1z'
      />
    </defs>
    <clipPath id='auto_rebalance_icon_6_'>
      <use xlinkHref='#auto_rebalance_icon_13_' overflow='visible' />
    </clipPath>
    <g clipPath='url(#auto_rebalance_icon_6_)'>
      <defs>
        <path id='auto_rebalance_icon_15_' d='M-952.3-1314.4H974.1v2554.7H-952.3z' />
      </defs>
      <clipPath id='auto_rebalance_icon_8_'>
        <use xlinkHref='#auto_rebalance_icon_15_' overflow='visible' />
      </clipPath>
    </g>
    <defs>
      <path
        id='auto_rebalance_icon_17_'
        d='M-206.5 133.6c.6 0 1.2-.2 1.7-.5 1-.5 1.7-1.6 1.7-3 0-1.6-1.1-3-2.7-3.3-.2-.1-.5-.1-.7-.1h-23.9c-.2 0-.5 0-.7.1-1.5.3-2.7 1.6-2.7 3.3 0 1.2.7 2.3 1.7 3 .5.2 1 .5 1.7.5h23.9z'
      />
    </defs>
    <clipPath id='auto_rebalance_icon_10_'>
      <use xlinkHref='#auto_rebalance_icon_17_' overflow='visible' />
    </clipPath>
    <g clipPath='url(#auto_rebalance_icon_10_)'>
      <defs>
        <path id='auto_rebalance_icon_19_' d='M-952.3-1314.4H974.1v2554.7H-952.3z' />
      </defs>
      <clipPath id='auto_rebalance_icon_12_'>
        <use xlinkHref='#auto_rebalance_icon_19_' overflow='visible' />
      </clipPath>
    </g>
    <g>
      <defs>
        <path
          id='auto_rebalance_icon_21_'
          d='M-179.8 186.1c0 12.3-3.3 19.8-11.8 26.6-1.6 1.2-3.6 1.9-5.5 1.9'
        />
      </defs>
      <clipPath id='auto_rebalance_icon_14_'>
        <use xlinkHref='#auto_rebalance_icon_21_' overflow='visible' />
      </clipPath>
      <g clipPath='url(#auto_rebalance_icon_14_)'>
        <defs>
          <path id='auto_rebalance_icon_23_' d='M-952.3-1314.4H974.1v2554.7H-952.3z' />
        </defs>
        <clipPath id='auto_rebalance_icon_16_'>
          <use xlinkHref='#auto_rebalance_icon_23_' overflow='visible' />
        </clipPath>
      </g>
    </g>
  </svg>
)

export default SvgComponent
