import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import { withRouter } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'
import * as yup from 'yup'

import { API } from '../../../api'
import { WizardStep, InputField, CurrencyInput, HelpIcon, TooltipText } from '../../../components'
import { reduceValidationError } from '../../../utils'
import { typeToAPI } from '../utils'
import { getIraContributionType } from '../utils/accountTypeToAllowedContributions'

const schema = yup.object().shape({
  annualPlannedContribDlrs: yup
    .number()
    .nullable()
    .max(9999999, 'Please enter an amount between $0 and $100,000,000'),
})

class EditContributions extends Component {
  handleSubmit = async values => {
    try {
      // if different from initialValues, submit PATCH request
      if (!_.isEqual(this.initialValues(), values)) {
        const {
          store,
          account: { account },
          match: {
            params: { type },
          },
        } = this.props
        const { planType, id, participantId } = account
        const payload = {
          planType,
          id,
          participantId,
        }
        const contributionType = getIraContributionType(planType)
        payload[contributionType] = values.annualPlannedContribDlrs
        const response = await API.patch(typeToAPI[type], payload)
        applySnapshot(account, response.data)
        store.getAccounts()
      }
      this.props.handleCancel()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  initialValues = () => {
    const {
      account: { planType },
      account,
    } = this.props.account
    const contributionType = getIraContributionType(planType)
    const amount = account[contributionType]
    return { annualPlannedContribDlrs: amount }
  }

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        validate={this.validate}
        initialValues={this.initialValues()}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting, submitError }) => (
          <WizardStep
            onBackClick={this.props.handleCancel}
            onNextClick={handleSubmit}
            backButtonText='Cancel'
            nextButtonText='Save'
            loading={submitting}
            serverError={submitError}>
            <div>
              <InputField
                label='Annual contribution'
                helpLabel='IRA contribution limit'
                tooltip={TooltipText.iraContributionLimit()}>
                <span
                  css={css`
                    padding-right: 12px;
                  `}>
                  <Field
                    name='annualPlannedContribDlrs'
                    format={value => (value === null ? undefined : value)}
                    parse={v => v}
                    render={({ input, meta }) => (
                      <CurrencyInput
                        name={input.name}
                        value={input.value}
                        placeholder='0'
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                        decimalScale={0}
                        width='125px'
                      />
                    )}
                  />
                </span>
                <HelpIcon
                  helpLabel='Why commitment matters'
                  tooltip={TooltipText.whyCommitmentMatters()}
                />
              </InputField>
            </div>
          </WizardStep>
        )}
      />
    )
  }
}

export default withRouter(inject('store', 'account')(observer(EditContributions)))
