import { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Drawer } from '../../../components'
import GoalForm from './GoalForm'
import ViewGoal from './ViewGoal'
// import DeleteGoal from './DeleteGoal'

class Goal extends Component {
  state = { editActive: false }

  toggleEdit = () => {
    this.setState(state => ({ editActive: !state.editActive }))
    // The following is supposed to address a strange Safari scroll bug
    setTimeout(
      () =>
        document.getElementById('drawer')
          ? (document.getElementById('drawer').style.overflowWrap = 'break-word')
          : null,
      500
    )
  }

  componentDidMount() {
    const {
      history,
      match: { params },
      store,
    } = this.props
    const id = Number(params.id)
    const foundGoal = store.viewGoalById(id)

    if (!foundGoal) {
      history.replace('/goals')
    }
  }

  render() {
    const {
      history,
      match: { params },
      store,
    } = this.props
    const id = Number(params.id)
    const foundGoal = store.viewGoalById(id)

    // handle no foundGoal from improper id in URL case
    if (!foundGoal) {
      return null
    }

    return (
      <div>
        <ViewGoal
          foundGoal={foundGoal}
          history={history}
          toggleEdit={foundGoal.isPast ? null : this.toggleEdit}
        />
        {/* <DeleteGoal history={history} goal={foundGoal} /> */}

        <Drawer
          active={this.state.editActive}
          subtitle='Edit goal'
          title={foundGoal.name}
          width='60%'>
          <GoalForm handleClose={this.toggleEdit} isEdit foundGoal={foundGoal} />
        </Drawer>
      </div>
    )
  }
}

export default inject('store')(observer(Goal))
