/* eslint-disable react/jsx-indent */

import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Page, PageHeader, PageFooter } from '../elements'
import {
  ContentMainHeader,
  ContentSubHeader,
  ContentText,
  FlexContainer,
  FootnoteText,
} from '../elements/styled'
import { Spacer } from '../../../components/'
import { numberToDollars } from '../../../utils'
import { HeaderRow, TableRow, PrimaryCurrency, Currency } from './AccountPageStyle'

const createConfig = templateId => {
  switch (templateId) {
    // Florida template
    case 1:
    case 2:
      return {
        introText:
          'You added the following employer-related and other types of accounts for consideration in the projected income figures and the savings and investment recommendations provided.',
        showRetirementBalance: false,
      }

    // Institutional template
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 19:
    default:
      return {
        introText:
          'You added the following accounts for consideration in the projected income figures and savings and investment recommendations.',
        showRetirementBalance: true,
      }
  }
}

class TableHeader extends Component {
  render() {
    const { text, balance, isSocialSecurity } = this.props

    return (
      <HeaderRow>
        <FlexContainer justifyContent='space-between'>
          <div>{text}</div>
          <FlexContainer justifyContent='space-between'>
            {isSocialSecurity ? null : <div>Balance:&nbsp;</div>}
            {isSocialSecurity ? null : <Currency bold>{numberToDollars(balance, true)}</Currency>}
          </FlexContainer>
        </FlexContainer>
      </HeaderRow>
    )
  }
}

class AccountPage extends Component {
  generateList = list => {
    const partitionData = []
    let index = 0
    while (index < list.length) {
      partitionData.push(list.slice(index, index + 12))
      index = index + 12
    }

    return partitionData.map(partition =>
      partition.map(account => (
        <div key={_.uniqueId(account.name)}>
          <TableRow>
            <FlexContainer justifyContent='space-between'>
              <ContentSubHeader>{account.name} </ContentSubHeader>
              <Currency>{numberToDollars(account.balance, true)}</Currency>
            </FlexContainer>
          </TableRow>
        </div>
      ))
    )
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({
      name: 'account',
    })
  }

  render() {
    const { store, guide } = this.props
    const {
      acceptedCase,
      totalBalanceExcludingPensionAndAnnuity,
      employerAccounts,
      otherAccounts,
      employerAccountsBalance,
      otherAccountsBalance,
      config,
    } = store

    // Only Non-FL Institutional should see this for now
    const { introText, showRetirementBalance } = createConfig(config.templateId)

    const { primary, spouse, estimatedTotalSSIIncome } = store.baseCase
    const { primarySSIBenefits, spouseSSIBenefits } = store

    const primarySSIIncome = primary ? primarySSIBenefits.monthlyAmount : 0
    const spouseSSIIncome = spouse ? spouseSSIBenefits.monthlyAmount : 0

    const { displayName } = store.person
    const { firstName: spouseName } = store.spouse

    const clientIncludeSSI = _.get(primarySSIBenefits, 'includeSsi', null)
    const spouseIncludeSSI = _.get(spouseSSIBenefits, 'includeSsi', null)

    const page = guide.guidePages.pages.findByName('account')

    return (
      <Page
        header={<PageHeader text='Snapshot' />}
        footer={
          showRetirementBalance ? null : (
            <PageFooter
              css={css`
                padding: 37pt 100pt;
                font-size: 14pt;
              `}>
              Your plan and the income you can expect at retirement rely on you making the changes
              you've modeled, then returning each year to update your balances and letting us know
              of any changes affecting your plan so we can update our advice.
            </PageFooter>
          )
        }
        footerOnlyOnLastPage
        page={page}>
        <ContentMainHeader>Your Accounts</ContentMainHeader>
        <Spacer space='6px' />
        <ContentSubHeader>ACCOUNTS INCLUDED IN PROJECTIONS</ContentSubHeader>
        <Spacer space='6px' />
        <ContentText>{introText}</ContentText>
        <Spacer space='8px' />
        <TableRow>
          <FlexContainer justifyContent='flex-end'>
            <FlexContainer justifyContent='space-between'>
              <ContentMainHeader>Total balance*:&nbsp;&nbsp;</ContentMainHeader>
              <PrimaryCurrency>
                {numberToDollars(totalBalanceExcludingPensionAndAnnuity, true)}
              </PrimaryCurrency>
            </FlexContainer>
          </FlexContainer>
        </TableRow>
        <TableHeader text='Employer-related accounts' balance={employerAccountsBalance} />
        {_.flatten(this.generateList(employerAccounts))}
        <Spacer space='12px' />
        <TableHeader text='Other types of accounts' balance={otherAccountsBalance} />
        {_.flatten(this.generateList(otherAccounts))}
        <Spacer space='12px' />
        {(clientIncludeSSI || spouseIncludeSSI) && (
          <div>
            <TableHeader
              text='Social Security'
              balance={estimatedTotalSSIIncome}
              isSocialSecurity
            />
            {clientIncludeSSI && (
              <TableRow>
                <FlexContainer justifyContent='space-between'>
                  <ContentSubHeader>{displayName} </ContentSubHeader>
                  <Currency>{numberToDollars(primarySSIIncome, true)}</Currency>
                </FlexContainer>
              </TableRow>
            )}
            {spouseIncludeSSI && (
              <TableRow>
                <FlexContainer justifyContent='space-between'>
                  <ContentSubHeader>{spouseName} </ContentSubHeader>
                  <Currency>{numberToDollars(spouseSSIIncome, true)}</Currency>
                </FlexContainer>
              </TableRow>
            )}
            <Spacer space='2px' />
          </div>
        )}
        <FootnoteText>*investment accounts only</FootnoteText>

        {showRetirementBalance && <Spacer space='40px' />}
        {showRetirementBalance && (
          <div
            css={css`
              border: 3px solid #e7f3f9;
              border-radius: 7px;
              background-color: #ffffff;
              width: 640px;
              margin: 0 auto;
              padding: 1rem;
            `}>
            <div
              css={css`
                color: #022a3a;
                font-size: 1rem;
              `}>
              How might your total balance grow by the time you retire?
            </div>
            <div
              css={css`
                color: #4a606a;
                font-size: 1rem;
                line-height: 1.35;
                padding: 20px;
              `}>
              <div>
                <span
                  css={css`
                    display: inline-block;
                    width: 110px;
                  `}>
                  ${numberToDollars(acceptedCase.estimatedFutureValue, true)}
                </span>{' '}
                Today’s value**
              </div>
              <div>
                <span
                  css={css`
                    display: inline-block;
                    width: 110px;
                  `}>
                  ${numberToDollars(acceptedCase.estimatedInflationAdjustedValue, true)}
                </span>{' '}
                Value at retirement**
              </div>
            </div>
            <div
              css={css`
                color: #4a606a;
                font-size: 12px;
                line-height: 1.35;
              `}>
              **Projected values do not include income from sources such as Social Security, a
              monthly pension, annuity payouts, discretionary matching or discretionary profit
              sharing programs, etc. However, these income sources, if applicable to you, are
              considered in the monthly/annual income projections.
            </div>
          </div>
        )}
      </Page>
    )
  }
}

export default inject('store', 'guide')(observer(AccountPage))
