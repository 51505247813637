import { types, flow } from 'mobx-state-tree'
import dayjs from 'dayjs'

import { API } from '../api'
const { model, maybeNull, number, string, boolean } = types

const Person = model({
  annualIncome: maybeNull(number),
  birthDate: maybeNull(string),
  contactInfoId: maybeNull(number),
  expectedMortalityAge: maybeNull(number),
  filingStatus: maybeNull(number),
  firstName: maybeNull(string),
  gender: maybeNull(string),
  id: maybeNull(number),
  includeSpouse: maybeNull(boolean),
  includeSsi: maybeNull(boolean),
  lastName: maybeNull(string),
  maritalStatus: maybeNull(boolean),
  middleInitial: maybeNull(string),
  otherIncome: maybeNull(number),
  preferredName: maybeNull(string),
  retirementAge: maybeNull(number),
  retirementIncomeGoalPct: maybeNull(number),
  salary: maybeNull(number),
  socSecNum: maybeNull(string),
  spouseId: maybeNull(number),
  strbirthdate: maybeNull(string),
  totalIncome: maybeNull(number),
}).views(self => ({
  get fullName() {
    return `${self.firstName || ''} ${self.lastName || ''}`
  },
  get displayName() {
    const { preferredName, firstName } = self
    return preferredName || firstName || ''
  },
  get fullDisplayName() {
    return `${self.displayName || ''} ${self.lastName || ''}`
  },
  get retirementYear() {
    return dayjs(self.birthDate).year() + self.retirementAge || null
  },
  get age() {
    return self.birthDate && dayjs().year() - dayjs(self.birthDate).year()
  },
  get personExists() {
    return self.id !== null
  },
}))

const PersonStore = model({
  person: maybeNull(Person),
})
  .views(self => ({
    get includeSpouseInPlanning() {
      const { maritalStatus, includeSpouse } = self.person
      return maritalStatus && includeSpouse
    },
  }))
  .actions(self => ({
    getPerson: flow(function* () {
      const person = yield API.get('person')
      self.person = person.data
    }),
    updatePerson: flow(function* (values) {
      const person = yield API.patch('person', values)
      self.person = person.data
    }),
  }))

export default PersonStore
