import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { Spacer } from '../../../components'
import { numberToDollars } from '../../../utils'
import { Page, PageHeader } from '../elements'
import {
  ContentSubHeader,
  ContentText,
  FlexContainer,
  HorizontalLine,
  FootnoteText,
  GoalTable,
  GoalTableLabel,
  GoalTableCurrency,
} from '../elements/styled'

const otherGoalsContent = templateId => {
  switch (templateId) {
    case 1:
    case 2:
      return "You've also entered other financial goals you want to satisfy, which, in effect, will use money from investment account(s) you told us about. Therefore, the range of income you can expect during retirement has taken into account the expenditures listed below."

    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 19:
    default:
      return 'You’re planning on using money from your retirement accounts to fund the following goal(s). We have accounted for any withdrawal(s) in your projections. Should the time frame or amount change, please return to update and get new projections.'
  }
}

class GoalPage2 extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({
      name: 'goals2',
    })
  }

  render() {
    const { store } = this.props
    const page = this.props.guide.guidePages.pages.findByName('goals2')

    const { config, primarySSIBenefits, spouseSSIBenefits } = store

    const {
      estimatedTotalMonthlyIncomeMin,
      estimatedTotalMonthlyIncomeMax,
      estimatedTotalMonthlyIncomeInflationAdjustedMin,
      estimatedTotalMonthlyIncomeInflationAdjustedMax,
      primary,
      spouse,
      includedGoals,
    } = store.acceptedCase

    const { displayName, includeSpouse, maritalStatus } = store.person

    const { firstName: spouseName } = store.spouse

    const clientIncludeSsi = _.get(primarySSIBenefits, 'includeSsi', null)
    const spouseIncludeSsi = _.get(spouseSSIBenefits, 'includeSsi', null)

    return (
      <Page header={<PageHeader text='Snapshot' />} page={page}>
        <IndentContainer paddingLeft='.75in'>
          <ContentText>
            Those numbers are based on average market results. But when you retire, the market may
            be weaker or stronger. So, really, what you can expect will not likely be the exact
            number above, but one that falls within a range of income:
          </ContentText>
        </IndentContainer>
        <IndentContainer paddingLeft='.75in'>
          <FlexContainer justifyContent='space-between' childPadding='20px'>
            <GoalTable>
              <TableRow>
                <div>
                  <GoalTableCurrency>
                    {numberToDollars(estimatedTotalMonthlyIncomeMin, true)}
                  </GoalTableCurrency>{' '}
                  <StyledDash>-</StyledDash>{' '}
                  <GoalTableCurrency>
                    {numberToDollars(estimatedTotalMonthlyIncomeMax, true)}
                  </GoalTableCurrency>
                </div>
              </TableRow>
              <HorizontalLine />
              <TableRow>
                <GoalTableLabel>Today's monthly value</GoalTableLabel>
              </TableRow>
            </GoalTable>
            <GoalTable>
              <TableRow>
                <div>
                  <GoalTableCurrency>
                    {numberToDollars(estimatedTotalMonthlyIncomeInflationAdjustedMin, true)}
                  </GoalTableCurrency>{' '}
                  <StyledDash>-</StyledDash>{' '}
                  <GoalTableCurrency>
                    {numberToDollars(estimatedTotalMonthlyIncomeInflationAdjustedMax, true)}
                  </GoalTableCurrency>
                </div>
              </TableRow>
              <HorizontalLine />
              <TableRow>
                <GoalTableLabel>Monthly value at retirement</GoalTableLabel>
              </TableRow>
            </GoalTable>
          </FlexContainer>
        </IndentContainer>
        <IndentContainer paddingLeft='.75in'>
          <FlexContainer justifyContent='space-between' childPadding='40px'>
            <GoalTable>
              <TableRow
                justifyContent='space-between'
                css={css`
                  padding: 12pt 2px 0;
                  margin-bottom: 12pt;
                  word-break: break-word;
                `}>
                <GoalTableLabel
                  fontSize='11pt'
                  css={css`
                    max-width: 342px;
                  `}>
                  {`${displayName || ''}'s retirement age: ${
                    primary && primary.retAge ? primary.retAge : 'NA'
                  }`}
                </GoalTableLabel>
                {clientIncludeSsi !== null && (
                  <GoalTableLabel
                    fontSize='11pt'
                    css={css`
                      min-width: 230px;
                    `}>
                    Estimated Social Security: {`${clientIncludeSsi ? 'Included' : 'Excluded'}`}
                  </GoalTableLabel>
                )}
              </TableRow>
              {includeSpouse && (
                <TableRow
                  justifyContent='space-between'
                  css={css`
                    padding: 0 2px 12pt;
                    margin-top: 12pt;
                    word-break: break-word;
                  `}>
                  <GoalTableLabel
                    fontSize='11pt'
                    css={css`
                      max-width: 342px;
                    `}>
                    {`${spouseName || ''}'s retirement age: ${
                      spouse && spouse.retAge ? spouse.retAge : 'NA'
                    }`}
                  </GoalTableLabel>
                  {spouseIncludeSsi !== null && (
                    <GoalTableLabel
                      fontSize='11pt'
                      css={css`
                        min-width: 230px;
                      `}>
                      Estimated Social Security: {`${spouseIncludeSsi ? 'Included' : 'Excluded'}`}
                    </GoalTableLabel>
                  )}
                </TableRow>
              )}
            </GoalTable>
          </FlexContainer>
          <Spacer space='8px' />
          {maritalStatus &&
            (includeSpouse ? (
              <FootnoteText>
                *Projected income based on {displayName && `${displayName}'s`} retirement age
              </FootnoteText>
            ) : (
              <FootnoteText>
                *You chose to exclude your spouse's information in the planning process
              </FootnoteText>
            ))}
        </IndentContainer>

        <div
          css={css`
            padding-top: 48px;
          `}
        />

        {!!includedGoals.length && <ContentSubHeader>OTHER GOALS</ContentSubHeader>}
        {!!includedGoals.length && (
          <ContentText>{otherGoalsContent(config.templateId)}</ContentText>
        )}
        {includedGoals.map((goal, i) => (
          <GoalRow
            key={goal.id}
            name={goal.name}
            startDate={goal.disbursements[0].startDate}
            endDate={goal.disbursements[goal.disbursements.length - 1].endDate}
            totalNeeded={goal.totalRemaining}
            isFirstGoal={i === 0}
            isLastGoal={i === includedGoals.slice().length - 1}
          />
        ))}
      </Page>
    )
  }
}

class GoalRow extends Component {
  render() {
    const { name, startDate, endDate, totalNeeded, isFirstGoal, isLastGoal } = this.props
    const isSingleYear = new Date(startDate).getUTCFullYear() === new Date(endDate).getUTCFullYear()
    return (
      <GoalBox isFirstGoal={isFirstGoal} isLastGoal={isLastGoal}>
        <GoalText>
          <GoalDataLabel>Goal: </GoalDataLabel>
          <GoalDataValue>{`${name}`}</GoalDataValue>
        </GoalText>
        <GoalText>
          <GoalDataLabel>Timeframe: </GoalDataLabel>
          {isSingleYear ? (
            <GoalDataValue>{`${new Date(startDate).getUTCFullYear()}`}</GoalDataValue>
          ) : (
            <GoalDataValue>
              {`${new Date(startDate).getUTCFullYear()}-${new Date(endDate).getUTCFullYear()}`}
            </GoalDataValue>
          )}
        </GoalText>
        <GoalText>
          <GoalDataLabel>Total future amount needed: $</GoalDataLabel>
          <GoalDataValue>{`${numberToDollars(totalNeeded, true)}`}</GoalDataValue>
        </GoalText>
      </GoalBox>
    )
  }
}

const IndentContainer = styled.div`
  padding-top: 12px;
  padding-left: ${p => (p.paddingLeft ? p.paddingLeft : null)};
`

const TableRow = styled.div`
  padding: 12pt 2px;
  display: flex;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'center')};
  align-items: center;
`

const GoalBox = styled.div`
  line-height: 1.5;
  padding: 20px 0 20px 60px;
  ${p => p.isFirstGoal && 'padding: 40px 0 20px 60px'};
  ${p => p.isLastGoal && 'padding: 20px 0 0 60px'};
`

const GoalText = styled.div`
  text-align: left;
`

const GoalDataValue = styled.span`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  color: ${p => p.theme.darkestGray};
`

const GoalDataLabel = styled.span`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  color: ${p => p.theme.lightestGray};
`

const StyledDash = styled.span`
  font-size: 21pt;
  padding: 0 2px;
`

export default inject('store', 'guide')(observer(GoalPage2))
