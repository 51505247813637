import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'

import { Page } from './style'
import { Spacer } from '../../../../components'
import { BackButton, NextButton } from '../../../../components/styled'

function Congratulations(props) {
  const { history } = props

  return (
    <Page.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
        line-height: 1.5rem;
      `}>
      <Page.Heading
        css={css`
          color: #4a606a;
          font-weight: 400;
          text-align: start;
          line-height: 2.75rem;
        `}>
        Congratulations! You've got a solid <Spacer space='5px' />
        plan in the works
      </Page.Heading>
      <div>
        <Page.Paragraph>
          Retiring is not that far away, but creating a guide that tells you how much to withdraw
          and from what accounts isn't yet applicable. Feel free to return to the spend-down
          modeling mode anytime to update your plan with new information and, most importantly, in
          the months before retiring.
        </Page.Paragraph>
        <Spacer space='10px' />
        <Page.Paragraph>
          In the meantime, there are a few other options to keep in mind.
        </Page.Paragraph>
        <Spacer space='20px' />
        <Page.SubHeader>Additional options to think about</Page.SubHeader>
        <Page.CongratsUl>
          <li>
            Consider{' '}
            <Page.CongratsCustomLink
              to='../insuring-final-expenses'
              target='_blank'
              rel='noreferrer noopener'>
              insuring final expenses
            </Page.CongratsCustomLink>
          </li>
          <li>
            Tap your home equity and opt for a{' '}
            <Page.CongratsCustomLink
              to='../reverse-mortgage'
              target='_blank'
              rel='noreferrer noopener'>
              reverse mortgage{' '}
            </Page.CongratsCustomLink>{' '}
            if your home is paid off
          </li>
          <li>Consider a move to a less expensive area</li>
          <li>
            Make certain your finances are in order—
            <Page.CongratsCustomLink
              to='../handy-checklist'
              target='_blank'
              rel='noreferrer noopener'>
              handy checklist
            </Page.CongratsCustomLink>
          </li>
        </Page.CongratsUl>
      </div>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin: 30px 0;
        `}>
        <BackButton backgroundColor='#FFFFFF' onClick={() => history.goBack()}>
          Back
        </BackButton>
        <NextButton onClick={() => history.replace('/home')}>Home</NextButton>
      </div>
    </Page.Container>
  )
}

export default inject('store')(observer(Congratulations))
