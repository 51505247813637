/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import Cookies from 'js-cookie'
import { API_URL } from '../../../api'

import { Page } from './style'

function Download(props) {
  const {
    history,
    store: {
      contactInfo: { email },
      spendingAcceptedCaseId,
      config: { canTransact },
    },
  } = props

  const transactionForm = useRef(null)

  const handleDone = async () => {
    try {
      history.push('/home')
    } catch (err) {
      console.error(err)
    }
  }

  const handleOpenGuide = async () => {
    try {
      // history.push(`/implement/guide/${spendingAcceptedCaseId}`)
      window.open(`${API_URL}guide/generateGuide?caseId=${spendingAcceptedCaseId}`, '_blank')
    } catch (err) {
      console.error(err)
    }
  }

  const emailGuideAndSendForm = () => {
    transactionForm.current.submit()
  }

  return (
    <Page.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
        line-height: 1.5rem;
      `}>
      <Page.Heading
        css={css`
          color: #4a606a;
          font-weight: 400;
          text-align: start;
        `}>
        Let's put your plan into action
      </Page.Heading>

      <Page.Container>
        {canTransact ? (
          <div>
            Download your guide and view it right away! When you are ready, click the Final
            confirmation button to be redirected automatically to your plan administrator. It is
            important that you confirm the new allocation of your FRS Investment Plan account as
            sent by GuidedChoice so that you can act upon the withdrawal instructions in your guide.
          </div>
        ) : (
          <div>
            Download your guide and view it right away! When you are ready, click Done and we will
            return you to your GuidedChoice home page for further planning or where you may log off.
          </div>
        )}
      </Page.Container>

      <div
        css={css`
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
        `}>
        <Page.SecondaryButton
          css={css`
            margin: 40px 0;
          `}
          onClick={handleOpenGuide}>
          <div>Download | view now</div>
          <div>opens new tab</div>
        </Page.SecondaryButton>

        {canTransact ? (
          <Page.PrimaryButton
            css={css`
              margin: 40px 0;
            `}
            onClick={emailGuideAndSendForm}>
            <div>Final confirmation</div>
            <div>with plan admin | email guide</div>
          </Page.PrimaryButton>
        ) : (
          <Page.PrimaryButton
            css={css`
              margin: 40px 0;
            `}
            onClick={handleDone}>
            <div>Done</div>
            <div>return to GuidedChoice home</div>
          </Page.PrimaryButton>
        )}
        <form
          action={`${API_URL.slice(0, -4)}florida/transaction`}
          method='post'
          ref={transactionForm}
          css={css`
            display: none;
          `}>
          <input type='number' name='caseId' value={spendingAcceptedCaseId} readOnly />
          <input type='text' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
          <input type='text' name='email' value={email} readOnly />
        </form>
      </div>
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(Download))
