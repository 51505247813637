export const CoverPageConfig = ({
  config,
  baseCase,
  acceptedCase,
  otherIncludedAccounts,
  isSpouseContributingButExcludeAdvice,
}) => {
  const otherAccountsExist = Boolean(
    otherIncludedAccounts.length > 0 ||
      acceptedCase.outOfPlanMonthlySavings > 0 ||
      isSpouseContributingButExcludeAdvice
  )

  const onTrack = baseCase.isOnTrack

  const defaultCoverPage = {
    guideTitle: 'Advisory Service Guide',
    guideAuthor: 'GuidedChoice',
    includeFinancialGoalsText: '',
    includeTodoListText: '',
    includePersonalizedPlanText: '',
    includePersonalizedPlanEmployerText: '',
    includeClientPlanChangesText: '',
    includeOtherAccountsChangesText: '',
    includeComfortableRetirementText: '',
  }

  switch (config.templateId) {
    // FRS Investment / Hybrid Plan
    case 1:
      return {
        ...defaultCoverPage,
        includeFinancialGoalsText: 'Your financial goals',
        includeTodoListText: 'To-do list to help you get on track',
        includePersonalizedPlanText: 'Your personalized plan',
        includeClientPlanChangesText: `Changes to your ${config.template.planName} account`,
        includeOtherAccountsChangesText: otherAccountsExist && 'Changes to other accounts',
      }

    // FRS Pension Only Plan
    case 2:
      return {
        ...defaultCoverPage,
        includeFinancialGoalsText: 'Your financial goals',
        includeTodoListText: 'To-do list to help you get on track',
        includePersonalizedPlanText: 'Your personalized plan',
        includeClientPlanChangesText: '',
        includeOtherAccountsChangesText: otherAccountsExist && 'Changes to investment accounts',
      }

    // McDonald's (foundation)
    // Atmos Energy
    // Cascade Sawing & Drilling
    // GuidedChoice
    // NXP
    // Paychex
    // Schwab Legacy
    // Schwab SIA
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
      return {
        ...defaultCoverPage,
        includeFinancialGoalsText: 'Your financial goals and accounts',
        includeTodoListText: onTrack
          ? 'To-do list to help you stay on track'
          : 'To-do list to help you get on track',
        includePersonalizedPlanText: 'Your personalized plan',
        includePersonalizedPlanEmployerText: config.template.sponsorName,
        includeComfortableRetirementText: 'YOUR PLAN TO A MORE COMFORTABLE RETIREMENT',
      }
    case 19:
      return {
        ...defaultCoverPage,
        includeFinancialGoalsText: 'Your financial goals and accounts',
        includeTodoListText: onTrack
          ? 'To-do list to help you stay on track'
          : 'To-do list to help you get on track',
        includePersonalizedPlanText: 'Your financial goals',
        includeComfortableRetirementText: 'YOUR PLAN TO A MORE COMFORTABLE RETIREMENT',
      }
    default:
      return defaultCoverPage
  }
}
