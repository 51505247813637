const ArtRetirementSmall = `
    <svg width="89" height="76" viewBox="0 0 89 76" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path d="M2.794 2.608h-.291l-.32 2.563C.943 4.948 0 3.888 0 2.608 0 1.168 1.192 0 2.663 0h.44a2.71 2.71 0 0 1 1.37.376h.004a2.6 2.6 0 0 1 1.275 1.976c.008.08.013.164.013.246v.01a2.813 2.813 0 0 1-.068.578c-.075.324-.21.628-.398.893-.026.037-.055.072-.08.107-.022.026-.04.055-.063.08a2.61 2.61 0 0 1-.649.553 2.68 2.68 0 0 1-1.406.396h-.44c-.066 0-.13-.005-.194-.009l.324-2.598h.003z" id="ki271f2cka"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <ellipse fill="#000" fill-rule="nonzero" opacity=".15" cx="44.5" cy="73.667" rx="44.5" ry="2.333"/>
            <g fill-rule="nonzero">
                <path fill="#E5B045" d="M15.434 46.872 1.168 45.84 24.496 5.688zM66.206 15.994l5.381-12.98-47.09 2.674zM24.496 5.688 50.942 28.97 34.077 39.225z"/>
                <path fill="#E9E7DD" d="m24.496 5.688 41.71 10.306L50.942 28.97zM24.496 5.688l-9.062 41.184 18.643-7.647z"/>
                <path d="M20.952.097a.665.665 0 0 1 .906.208l46.245 72.936h-1.55L20.74.985a.637.637 0 0 1 .213-.888z" fill="#FFF"/>
                <path d="M34.124 20.872c-14.015 8.523-25.429 17.733-31.7 25.059l-1.256-.09 5.169-6.623 16.41-18.733 16.067-9.77 24.42-6.098 8.353-1.603-.473 1.142c-9.487 2.256-22.975 8.191-36.99 16.714v.002z" fill="#000" opacity=".2"/>
                <path fill="#FFF" d="m1.168 45.84 5.169-6.622 18.16-33.53zM71.587 3.014l-8.35 1.603-38.74 1.071z"/>
                <path fill="#FFF" d="m24.496 5.688 14.318 5.028-16.065 9.77z"/>
                <path fill="#F6C760" d="m24.496 5.688 38.74-1.071-24.422 6.099zM24.496 5.688 6.337 39.218l16.412-18.733z"/>
                <g>
                    <g transform="translate(33.024 39.64)">
                        <g>
                            <path fill="#7A4315" d="m8.626 30.382 3.442-19.971 1.335.22L9.96 30.604z"/>
                            <path fill="#613511" d="m12.737 13.263-.88-.611.43-2.41.493.078.43.299z"/>
                            <path fill="#8C4D18" d="m2.616 31.336 47.144-15.78.79 2.265-47.143 15.78z"/>
                            <circle fill="#5E3411" cx="5.282" cy="31.694" r="1"/>
                            <path fill="#7A4315" d="m28.682 25.14-2.396-1.728 2.815-.943 2.396 1.73z"/>
                            <path fill="#8C4D18" d="M0 2.369 1.45.44 44.747 31.67l-1.45 1.927z"/>
                            <circle fill="#5E3411" cx="42.938" cy="31.832" r="1"/>
                        </g>
                        <circle fill="#5E3411" cx="43.784" cy="31.832" r="1"/>
                        <path fill="#703E13" d="m9.472 30.383 3.442-19.972 1.335.221-3.442 19.971z"/>
                        <path fill="#542E0F" d="M13.585 13.263h-.88l.43-3.022.493.08.43.298z"/>
                        <path fill="#522D0E" d="m4.254 33.602 47.144-15.783-.79-2.265-47.146 15.78-.055 2.268z"/>
                        <circle fill="#5E3411" cx="6.128" cy="31.694" r="1"/>
                        <path fill="#703E13" d="M29.528 25.14h-.846l-1.55-1.728 2.816-.943 2.395 1.73z"/>
                        <path fill="#9C561B" d="m.846 2.37 43.299 31.232 1.448-1.928-11.277-8.136h-.846l.38-.336L2.295.442H1.45z"/>
                        <g>
                            <path fill="#733F14" d="m8.626 30.382 3.442-19.971 1.335.22L9.96 30.604z"/>
                            <path fill="#613511" d="m12.737 13.263-.88-.611.43-2.41.493.078.43.299z"/>
                            <path fill="#784114" d="m2.616 31.336 47.144-15.78.79 2.265-47.143 15.78z"/>
                            <circle fill="#5E3411" cx="5.282" cy="31.694" r="1"/>
                            <path fill="#733F14" d="m28.682 25.14-2.396-1.728 2.815-.943 2.396 1.73z"/>
                            <path fill="#824716" d="M0 2.369 1.45.44 44.747 31.67l-1.45 1.927z"/>
                            <circle fill="#5E3411" cx="42.938" cy="31.832" r="1"/>
                        </g>
                        <path fill="#9C561B" d="M50.608 15.554h-.846l.79 2.265h.846z"/>
                        <path fill="#7A4315" d="M45.593 31.674h-.847L43.3 33.602h.846z"/>
                        <g fill="#5E3411">
                            <path d="M47.721 17.15c0 .576.476 1.041 1.063 1.041.586 0 1.062-.465 1.062-1.04s-.476-1.04-1.062-1.04c-.587 0-1.063.465-1.063 1.04zM1.232 2.57c0 .574.476 1.04 1.062 1.04.587 0 1.063-.466 1.063-1.04 0-.575-.476-1.041-1.063-1.041-.586 0-1.062.466-1.062 1.04z"/>
                        </g>
                    </g>
                    <path fill="#1B7690" d="M62.134 55.752h3.28v2.08h-3.28z"/>
                    <path fill="#FFF" d="M65.413 55.752h3.278v2.08h-3.278z"/>
                    <path fill="#1B7690" d="M68.691 55.752h3.28v2.08h-3.28z"/>
                    <path fill="#FFF" d="M71.97 55.752h3.278v2.08H71.97z"/>
                    <path fill="#1B7690" d="M75.248 55.752h3.28v2.08h-3.28z"/>
                    <path d="M80.745 56.79c0 .575.476 1.04 1.062 1.04.587 0 1.063-.465 1.063-1.04 0-.574-.476-1.04-1.063-1.04-.586 0-1.062.466-1.062 1.04z" fill="#FFF"/>
                    <path d="M61.868 66.521c4.236 0 8.66-1.281 13.018-3.799 4.503-2.602 7.31-5.552 7.427-5.676h-3.28c-.027.03-3.102 2.385-6.556 4.555-3.93 2.466-8.323 5.09-15.059 3.486-10.114-2.409-21.577-22.89-21.692-23.096h-3.28c3.684 7.636 11.166 21.333 24.749 23.99 1.534.301 3.082.542 4.669.542l.004-.002z" fill="#FFF"/>
                    <path fill="#FFF" d="M78.527 55.752h3.278v2.08h-3.278zM32.038 41.17h3.278v2.08h-3.278z"/>
                    <path fill="#1B7690" d="M28.76 41.17h3.278v2.08H28.76z"/>
                    <path fill="#FFF" d="M25.481 41.17h3.278v2.08h-3.278z"/>
                    <path fill="#1B7690" d="M22.202 41.17h3.28v2.08h-3.28z"/>
                    <path fill="#FFF" d="M18.924 41.17h3.278v2.08h-3.278z"/>
                    <path fill="#1B7690" d="M15.645 41.17h3.28v2.08h-3.28z"/>
                    <path d="M34.256 42.209c0 .574.475 1.04 1.062 1.04.587 0 1.062-.466 1.062-1.04 0-.575-.475-1.04-1.062-1.04-.587 0-1.062.465-1.062 1.04z" fill="#FFF"/>
                    <path d="M30.976 42.209c0 .574.476 1.04 1.062 1.04.587 0 1.063-.466 1.063-1.04 0-.575-.476-1.04-1.063-1.04-.586 0-1.062.465-1.062 1.04zM77.466 56.79c0 .575.475 1.04 1.062 1.04.587 0 1.062-.465 1.062-1.04 0-.574-.475-1.04-1.062-1.04-.587 0-1.062.466-1.062 1.04z" fill="#1B7690"/>
                    <path d="M58.588 66.521c4.237 0 8.661-1.281 13.018-3.799 4.504-2.602 7.311-5.552 7.428-5.676h-3.278c-.028.03-2.104 1.938-5.72 4.308-3.88 2.543-9.161 5.335-15.895 3.73-10.115-2.408-21.578-22.888-21.693-23.094h-3.277c.476.858 11.578 21.255 22.095 23.762 1.521.362 5.733.77 7.32.77h.002z" fill="#1B7690"/>
                    <path d="M27.698 42.209c0 .574.476 1.04 1.063 1.04s1.062-.466 1.062-1.04c0-.575-.475-1.04-1.062-1.04-.587 0-1.063.465-1.063 1.04zM74.188 56.79c0 .575.476 1.04 1.062 1.04.587 0 1.063-.465 1.063-1.04 0-.574-.476-1.04-1.063-1.04-.586 0-1.062.466-1.062 1.04z" fill="#FFF"/>
                    <path d="M55.31 66.521c4.237 0 8.662-1.281 13.019-3.799 4.503-2.602 7.31-5.552 7.427-5.676h-3.28c-.027.03-1.904 2.022-6.295 4.555-4.022 2.318-8.584 5.09-15.318 3.486-10.114-2.409-21.577-22.89-21.692-23.096h-3.28c.377 1.37 11.322 21.322 21.841 23.829 1.522.363 5.99.705 7.577.705l.001-.004z" fill="#FFF"/>
                    <path d="M24.42 42.209c0 .574.475 1.04 1.061 1.04.587 0 1.063-.466 1.063-1.04 0-.575-.476-1.04-1.063-1.04-.586 0-1.062.465-1.062 1.04zM70.909 56.79c0 .575.475 1.04 1.062 1.04.587 0 1.062-.465 1.062-1.04 0-.574-.475-1.04-1.062-1.04-.587 0-1.062.466-1.062 1.04z" fill="#1B7690"/>
                    <path d="M52.031 66.521c4.237 0 8.661-1.281 13.018-3.799 4.504-2.602 7.311-5.552 7.428-5.676h-3.278c-.028.03-1.611 1.638-6.002 4.17-4.022 2.32-8.877 5.473-15.611 3.869-10.115-2.409-21.578-22.89-21.693-23.095h-3.277c.475.858 11.235 21.124 21.754 23.63 1.522.364 6.076.903 7.663.903l-.002-.002z" fill="#1B7690"/>
                    <path d="M21.141 42.209c0 .574.476 1.04 1.063 1.04s1.062-.466 1.062-1.04c0-.575-.475-1.04-1.062-1.04-.587 0-1.063.465-1.063 1.04zM67.631 56.79c0 .575.476 1.04 1.062 1.04.587 0 1.063-.465 1.063-1.04 0-.574-.476-1.04-1.063-1.04-.586 0-1.062.466-1.062 1.04z" fill="#FFF"/>
                    <path d="M48.753 66.521c4.237 0 8.662-1.281 13.019-3.799 4.503-2.602 7.31-5.552 7.427-5.676h-3.28c-.027.03-2.243 2.006-6.633 4.538-4.023 2.32-8.246 5.105-14.982 3.5C34.19 62.677 22.727 42.197 22.612 41.99h-3.28c.476.858 11.287 21.51 21.806 24.014 1.521.363 6.025.517 7.612.517h.003z" fill="#FFF"/>
                    <path d="M17.862 42.209c0 .574.476 1.04 1.062 1.04.587 0 1.063-.466 1.063-1.04 0-.575-.476-1.04-1.063-1.04-.586 0-1.062.465-1.062 1.04zM64.352 56.79c0 .575.475 1.04 1.062 1.04.587 0 1.062-.465 1.062-1.04 0-.574-.475-1.04-1.062-1.04-.587 0-1.062.466-1.062 1.04z" fill="#1B7690"/>
                    <path d="M45.474 66.521c4.237 0 8.661-1.281 13.018-3.799 4.504-2.602 7.311-5.552 7.428-5.676h-3.278c-.028.03-2.224 2.04-5.863 4.34-3.92 2.478-9.018 5.305-15.752 3.699-10.115-2.409-21.578-22.89-21.693-23.095h-3.277c.476.858 11.087 21.003 21.604 23.507 1.521.363 6.226 1.024 7.811 1.024h.002z" fill="#1B7690"/>
                    <path d="M42.196 66.521c4.237 0 8.662-1.281 13.019-3.799 4.503-2.602 7.31-5.552 7.427-5.676l-.69-.624c-.028.03-2.847 2.989-7.238 5.521-4.022 2.32-10.229 4.748-16.963 3.144-10.115-2.409-21.578-22.89-21.692-23.096l-.826.44c.476.859 11.777 21.046 22.296 23.55 1.522.364 3.082.542 4.67.542l-.003-.002z" fill="#DB1B1B"/>
                    <path d="M36.378 42.353c0 .356-.067.687-.182.96H16.183v-1.325h20.171c.015.115.024.237.024.362v.003z" fill="#000" opacity=".2"/>
                    <path d="M46.157 65.713c2.476-.4 6.91-2.38 6.91-2.38l.055-.226c.109.072.216.142.325.21 1.198-.611 2.305-1.284 3.334-1.934 2.629-1.663 4.505-3.173 5.355-3.893v-.7H82.87c0 .575-.476 1.04-1.063 1.04h-.293a37.23 37.23 0 0 1-6.627 4.892c-4.356 2.518-8.781 3.8-13.018 3.8-.517 0-1.03-.028-1.542-.074-.58.048-1.16.073-1.737.073-.395 0-.952-.025-1.587-.068-.566.044-1.13.068-1.69.068-.403 0-.992-.022-1.67-.06-.54.04-1.076.06-1.608.06-.365 0-.89-.03-1.497-.077-.598.05-1.192.077-1.783.077-.466 0-1.184-.013-2.006-.042-.425.026-.85.042-1.273.042-.908 0-2.834-.217-4.601-.47 1.263-.007 3.572-.064 5.284-.338h-.002z" fill="#000" opacity=".4"/>
                    <path d="M81.806 58.116H62.134V56.79h20.734c0 .733-.476 1.326-1.062 1.326z" fill="#000" opacity=".1"/>
                    <path d="M42.196 66.521c4.237 0 8.662-1.281 13.019-3.799 4.503-2.602 7.31-5.552 7.427-5.676l-.69-.624c-.028.03-2.847 2.989-7.238 5.521-4.022 2.32-10.229 4.748-16.963 3.144-10.115-2.409-21.578-22.89-21.692-23.096l-.826.44c.476.859 11.777 21.046 22.296 23.55 1.522.364 3.082.542 4.67.542l-.003-.002z" fill="#106275"/>
                    <path d="M14.584 42.209c0 .574.476 1.04 1.063 1.04s1.062-.466 1.062-1.04c0-.575-.475-1.04-1.062-1.04-.587 0-1.063.465-1.063 1.04zM61.074 56.79c0 .575.476 1.04 1.062 1.04.587 0 1.063-.465 1.063-1.04 0-.574-.476-1.04-1.063-1.04-.586 0-1.062.466-1.062 1.04z" fill="#D61A1A"/>
                    <g transform="translate(12.508 39.639)">
                        <path d="M48.566 17.152c0 .574.476 1.04 1.062 1.04.587 0 1.063-.466 1.063-1.04 0-.575-.476-1.04-1.063-1.04-.586 0-1.062.465-1.062 1.04zM2.076 2.57c0 .575.476 1.04 1.063 1.04S4.2 3.146 4.2 2.57 3.726 1.53 3.14 1.53c-.587 0-1.063.465-1.063 1.04z" fill="#1B7690"/>
                        <path fill="#FF1B1B" d="M1.883 1.933h1.354v1.326H1.883z"/>
                        <path fill="#7A4315" d="m8.625 30.383 3.441-19.972 1.335.221L9.96 30.603z"/>
                        <path fill="#613511" d="m12.736 13.264-.881-.611.431-2.41.493.078.43.299z"/>
                        <path fill="#8C4D18" d="m2.613 31.337 47.144-15.78.791 2.265-47.144 15.78z"/>
                        <circle fill="#5E3411" cx="5.281" cy="31.695" r="1"/>
                        <path fill="#7A4315" d="m28.68 25.14-2.395-1.727 2.815-.943 2.396 1.73z"/>
                        <path fill="#8C4D18" d="M0 2.369 1.45.44 44.747 31.67l-1.45 1.927z"/>
                        <circle fill="#5E3411" cx="42.936" cy="31.833" r="1"/>
                        <circle fill="#5E3411" cx="43.782" cy="31.833" r="1"/>
                        <path fill="#703E13" d="m9.47 30.383 3.442-19.971 1.335.22-3.441 19.972z"/>
                        <path fill="#542E0F" d="M13.583 13.264h-.88l.43-3.022.493.08.43.298z"/>
                        <path fill="#7A4315" d="M4.25 33.603 51.396 17.82l-.79-2.265L3.46 31.335l-.056 2.268z"/>
                        <circle fill="#5E3411" cx="6.127" cy="31.695" r="1"/>
                        <path fill="#703E13" d="M29.527 25.14h-.846l-1.55-1.727 2.815-.943 2.396 1.73z"/>
                        <path fill="#9C561B" d="m.845 2.371 43.298 31.232 1.448-1.929-11.277-8.136h-.846l.38-.335L2.295.443h-.847z"/>
                        <g>
                            <path fill="#7A4315" d="m8.625 30.383 3.441-19.972 1.335.221L9.96 30.603z"/>
                            <path fill="#613511" d="m12.736 13.264-.881-.611.431-2.41.493.078.43.299z"/>
                            <path fill="#8C4D18" d="m2.613 31.337 47.144-15.78.791 2.265-47.144 15.78z"/>
                            <circle fill="#5E3411" cx="5.281" cy="31.695" r="1"/>
                            <path fill="#7A4315" d="m28.68 25.14-2.395-1.727 2.815-.943 2.396 1.73z"/>
                            <path fill="#8C4D18" d="M0 2.369 1.45.44 44.747 31.67l-1.45 1.927z"/>
                            <circle fill="#5E3411" cx="42.936" cy="31.833" r="1"/>
                        </g>
                        <path fill="#9C561B" d="M50.606 15.555h-.846l.79 2.265h.846z"/>
                        <path fill="#7A4315" d="M45.591 31.674h-.846l-1.448 1.929h.846z"/>
                    </g>
                </g>
                <g>
                    <g fill="#A16F31">
                        <path d="m18.438 63.555 6.327-8.287 1.795 1.315-6.327 8.287z"/>
                        <path d="m24.37 56.12.81-1.061 1.795 1.315-.81 1.061z"/>
                    </g>
                    <path d="M19.65 63.073a.654.654 0 0 0 .136.928c.299.22.724.158.948-.135a.654.654 0 0 0-.137-.927.684.684 0 0 0-.945.134h-.003z" fill="#BE7A2F"/>
                    <path fill="#C4883C" d="m17.983 63.555 6.327-8.287 1.795 1.314-6.327 8.287z"/>
                    <path fill="#C4883C" d="m23.913 56.122.81-1.062 1.796 1.315-.81 1.062zM19.193 63.073a.653.653 0 0 0 .137.928c.299.22.725.158.947-.135a.653.653 0 0 0-.137-.927.689.689 0 0 0-.95.134h.003z"/>
                    <path fill="#453021" d="M12.085 73.27h14.969l1.177-9.084H10.908z"/>
                    <path fill="#A8631F" d="M21.35 64.187h3.56v2.27h-3.56zM14.23 64.187h3.56v2.27h-3.56z"/>
                    <path fill="#A35714" d="M27.722 68.728H26.69v-2.271h1.354z"/>
                    <path fill="#A66B29" d="M10.662 62.658h17.817v1.529H10.662z"/>
                    <path fill="#332318" d="M10.909 62.415H28.23v1.326H10.909z"/>
                    <path fill="#BD934E" d="M10.662 61.732h17.817v1.326H10.662z"/>
                    <path fill="#A35714" d="M12.45 66.457h3.56v2.27h-3.56zM19.57 66.457h3.56v2.27h-3.56z"/>
                    <path fill="#A8631F" d="M21.35 68.728h3.56v2.27h-3.56zM14.23 68.728h3.56v2.27h-3.56z"/>
                    <path fill="#A35714" d="m28.043 71-.606 2.27h-.748V71zM12.45 71h3.56v2.27h-3.56zM19.57 71h3.56v2.27h-3.56z"/>
                    <g fill="#AD6F2B">
                        <path d="M24.91 66.457v-2.27h3.32l-.293 2.27zM11.202 66.457l-.294-2.27h3.323v2.27zM17.79 64.186h3.56v2.271h-3.56zM23.13 66.457h3.56v2.271h-3.56zM11.202 66.457h1.354v2.27h-1.034z"/>
                        <path d="M16.01 66.457h3.56v2.271h-3.56zM27.349 71h-2.44v-2.272h2.733zM11.497 68.728h2.734V71h-2.439z"/>
                        <path d="M17.79 68.727h3.56v2.271h-3.56zM23.13 71h3.56v2.271h-3.56zM13.146 71v2.27h-.75L11.792 71zM16.01 71h3.56v2.271h-3.56z"/>
                    </g>
                    <path fill="#000" opacity=".3" d="M28.148 64.83h-7.85l-.52.682-.929-.682h-7.858l-.083-.644h17.323z"/>
                    <path fill="#000" opacity=".1" d="M24.806 58.28h-2.798l1.05-1.374h2.798z"/>
                    <path fill="#453021" d="M6.273 73.27h14.968l1.177-9.084H5.096z"/>
                    <path fill="#BE7A2F" d="M19.097 66.457v-2.27h3.321l-.293 2.27z"/>
                    <path fill="#B86C22" d="M15.538 64.186h3.56v2.271h-3.56z"/>
                    <path fill="#BE7A2F" d="m5.39 66.457-.294-2.27h3.323v2.27zM11.978 64.186h3.56v2.271h-3.56z"/>
                    <path fill="#B86C22" d="M8.419 64.186h3.56v2.271h-3.56z"/>
                    <path fill="#B15F15" d="M21.91 68.728h-1.034v-2.271h1.354z"/>
                    <path fill="#BE7A2F" d="M4.848 62.658h17.817v1.529H4.848z"/>
                    <path fill="#453021" d="M5.096 62.415h17.322v1.326H5.096z"/>
                    <path fill="#D1A356" d="M4.848 61.732h17.817v1.326H4.848z"/>
                    <path fill="#BE7A2F" d="M17.317 66.457h3.56v2.27h-3.56z"/>
                    <path fill="#B15F15" d="M6.638 66.457h3.56v2.27h-3.56z"/>
                    <path fill="#BE7A2F" d="M5.39 66.457h1.353v2.27H5.71z"/>
                    <path fill="#B15F15" d="M13.757 66.457h3.56v2.27h-3.56z"/>
                    <path fill="#BE7A2F" d="M10.198 66.457h3.56v2.27h-3.56zM21.536 71h-2.439v-2.272h2.733z"/>
                    <path fill="#B86C22" d="M15.538 68.728h3.56v2.27h-3.56z"/>
                    <path fill="#BE7A2F" d="M5.684 68.728H8.42V71h-2.44zM11.979 68.728h3.56v2.27h-3.56z"/>
                    <path fill="#B86C22" d="M8.419 68.728h3.56v2.27h-3.56z"/>
                    <path fill="#B15F15" d="m22.23 71-.605 2.27h-.749V71z"/>
                    <path fill="#BE7A2F" d="M17.317 71h3.56v2.27h-3.56z"/>
                    <path fill="#B15F15" d="M6.638 71h3.56v2.27h-3.56z"/>
                    <path fill="#BE7A2F" d="M7.334 71v2.27h-.75L5.98 71z"/>
                    <path fill="#B15F15" d="M13.757 71h3.56v2.27h-3.56z"/>
                    <path fill="#000" opacity=".3" d="M14.231 61.731h2.8l-1.875 2.455h-2.798z"/>
                    <path fill="#BE7A2F" d="M10.198 71h3.56v2.27h-3.56z"/>
                    <path fill="#000" opacity=".3" d="M22.336 64.83h-7.851l-.52.682-.928-.682H5.179l-.083-.644h17.322z"/>
                    <g fill="#A16F31">
                        <path d="m12.17 63.553 6.328-8.286 1.795 1.314-6.328 8.287z"/>
                        <path d="m18.1 56.126.811-1.062 1.795 1.315-.81 1.062z"/>
                    </g>
                    <path d="M13.38 63.073a.654.654 0 0 0 .137.928c.298.22.724.158.948-.135a.654.654 0 0 0-.137-.927.687.687 0 0 0-.948.134z" fill="#BE7A2F"/>
                    <path fill="#C4883C" d="m11.714 63.55 6.328-8.286 1.795 1.315-6.328 8.287z"/>
                    <path fill="#C4883C" d="m17.645 56.12.811-1.061 1.795 1.314-.81 1.062z"/>
                    <path d="M12.925 63.073a.653.653 0 0 0 .137.928c.3.22.726.158.947-.135a.653.653 0 0 0-.137-.927.686.686 0 0 0-.947.134z" fill="#BE7A2F"/>
                    <path fill="#A16F31" d="M26.06 57.905h-6.724l.502-1.326h6.725z"/>
                    <path fill="#D19040" d="M24.767 55.264h-6.724l1.795 1.326h6.725z"/>
                    <path fill="#A16F31" d="M19.793 57.234h-.457l-5.825 7.632h.455z"/>
                </g>
                <g>
                    <path d="M24.784 59.466h3.57c.768 0 1.394.612 1.394 1.365 0 .09-.075.164-.167.164h-.096v-.032a.163.163 0 0 1-.072-.134c0-.57-.473-1.037-1.058-1.037h-3.571a.166.166 0 0 1-.167-.164c0-.09.075-.164.167-.164v.002z" fill="#7A8FA1"/>
                    <path d="M21.362 60.805h.24c0-.585.307-1.062.685-1.062.378 0 .684.475.684 1.062h.24c0-.73-.415-1.326-.924-1.326-.51 0-.925.595-.925 1.326zM24.694 59.466h3.57c.768 0 1.394.612 1.394 1.365 0 .09-.075.164-.167.164a.166.166 0 0 1-.168-.164c0-.571-.474-1.037-1.058-1.037h-3.571a.166.166 0 0 1-.168-.164c0-.09.075-.164.168-.164z" fill="#A3BFD6"/>
                    <g opacity=".9">
                        <g fill="#0E0E1A">
                            <path d="M22.704 59.908c0 1.008.86 1.823 1.92 1.823s1.92-.815 1.92-1.823c0-1.007-.86-1.18-1.92-1.18s-1.92.171-1.92 1.18zM18.033 59.908c0 1.008.86 1.823 1.92 1.823s1.92-.815 1.92-1.823c0-1.007-.86-1.18-1.92-1.18s-1.92.171-1.92 1.18z"/>
                        </g>
                        <g fill="#191A2E">
                            <path d="M22.542 59.908c0 1.008.86 1.823 1.92 1.823s1.92-.815 1.92-1.823c0-1.007-.86-1.18-1.92-1.18s-1.92.171-1.92 1.18zM17.871 59.908c0 1.008.86 1.823 1.92 1.823s1.92-.815 1.92-1.823c0-1.007-.86-1.18-1.92-1.18s-1.92.171-1.92 1.18z"/>
                        </g>
                        <path d="M26.219 60.641c-.297.643-.973 1.092-1.759 1.092-.93 0-1.707-.63-1.882-1.467 1.326.058 2.56.19 3.64.375zM20.993 60.23c.231 0 .459.002.686.008-.163.85-.946 1.495-1.888 1.495s-1.647-.582-1.86-1.37c.962-.085 1.99-.132 3.062-.132z" fill="#0E0E1A"/>
                        <path d="m25.502 58.84.472.638a.38.38 0 0 1-.047.499.301.301 0 0 1-.203.079.314.314 0 0 1-.25-.13l-.88-1.196c.335.006.645.035.91.11h-.002z" fill="#46565C" opacity=".3"/>
                        <path d="m22.855 59.107 1.4 1.694a.311.311 0 0 1-.047.444.327.327 0 0 1-.453-.046l-1.203-1.457c.03-.28.137-.486.304-.635h-.001z" fill="#617280" opacity=".3"/>
                        <path d="M24.056 59.324a.449.449 0 0 1 .623.065l1.325 1.604c-.178.23-.415.418-.686.549l-1.328-1.606a.427.427 0 0 1 .066-.61v-.002z" fill="#7A90A1" opacity=".3"/>
                        <path d="M19.791 58.73c.286 0 .557.014.8.056l.537.71a.36.36 0 0 1-.047.483.307.307 0 0 1-.453-.052l-.908-1.195h.07l.001-.002z" fill="#46565C" opacity=".3"/>
                        <path d="M17.871 59.908c0-.296.076-.52.207-.689l1.334 1.614a.311.311 0 0 1-.047.444.323.323 0 0 1-.204.07.323.323 0 0 1-.25-.118l-1.038-1.256v-.067l-.002.002z" fill="#617280" opacity=".3"/>
                        <path d="M19.214 59.358a.449.449 0 0 1 .622.064l1.398 1.69c-.2.216-.45.386-.734.492l-1.352-1.635a.427.427 0 0 1 .066-.61v-.001z" fill="#7A90A1" opacity=".3"/>
                    </g>
                </g>
            </g>
            <g transform="translate(7.058 50.367)">
                <path fill="#4C86BD" fill-rule="nonzero" d="M1.842 10.638h3.721v1.326H1.842z"/>
                <path fill="#5597D4" fill-rule="nonzero" d="M1.842 10.638h3.721l.797-6.405H1.043z"/>
                <path fill="#7092B3" fill-rule="nonzero" d="m6.07 4.233-.763 6.122H2.096l-.762-6.122z"/>
                <path fill="#7092B3" fill-rule="nonzero" d="m6.07 4.233-.763 6.122H2.096l-.762-6.122z"/>
                <path fill="#F7A016" fill-rule="nonzero" d="m5.947 5.224-.64 5.13H2.096l-.64-5.13z"/>
                <path fill="#F79216" fill-rule="nonzero" d="M4.185 5.224h1.762l-.64 5.13H4.185z"/>
                <path fill="#E37F05" fill-rule="nonzero" d="M5.322 5.224h.96l-.85 5.13h-.504z"/>
                <path fill="#FFAF36" fill-rule="nonzero" d="M2.071 5.224h1.354v5.13H2.58zM4.83 6.39c0 .364.3.663.677.663a.667.667 0 0 0 .677-.663c0-.368-.301-.663-.677-.663a.667.667 0 0 0-.677.663zm.12.118c0-.366.304-.663.677-.663.373 0 .677.297.677.663a.671.671 0 0 1-.677.663.671.671 0 0 1-.677-.663z"/>
                <path d="M3.397 7.314c0 .365.3.663.677.663a.667.667 0 0 0 .677-.663c0-.368-.301-.663-.677-.663a.667.667 0 0 0-.677.663zm.12.118c0-.365.304-.663.677-.663.373 0 .677.298.677.663a.671.671 0 0 1-.677.663.671.671 0 0 1-.677-.663z" fill="#FFAF36" fill-rule="nonzero"/>
                <path d="M2.466 9.567c0 .364.301.663.677.663a.667.667 0 0 0 .677-.663c0-.369-.3-.663-.677-.663a.667.667 0 0 0-.677.663zm.12.117c0-.365.304-.663.677-.663.373 0 .677.298.677.663a.671.671 0 0 1-.677.663.671.671 0 0 1-.677-.663zM1.808 6.178a.67.67 0 0 0 .677.663.67.67 0 0 0 .677-.663.67.67 0 0 0-.677-.663.67.67 0 0 0-.677.663zm.173.17c0-.365.305-.663.677-.663.372 0 .677.298.677.662a.672.672 0 0 1-.677.663.672.672 0 0 1-.677-.663z" fill="#E37F05" fill-rule="nonzero"/>
                <path d="M4.332 8.421a.67.67 0 0 0 .676.663.67.67 0 0 0 .677-.663.67.67 0 0 0-.677-.663.67.67 0 0 0-.676.663zm.173.17c0-.37.304-.663.676-.663.373 0 .677.298.677.663a.672.672 0 0 1-.677.662.672.672 0 0 1-.676-.662zM4.706 7.184c0 .318.258.57.583.57a.572.572 0 0 0 .582-.57.58.58 0 0 0-.582-.57.58.58 0 0 0-.583.57z" fill="#FFAF36" fill-rule="nonzero"/>
                <path d="M4.625 6.02a.67.67 0 0 0 .681.663.662.662 0 1 0 0-1.326.675.675 0 0 0-.681.663zM5.212 9.515c0 .365.304.663.677.663a.672.672 0 0 0 .676-.663.672.672 0 0 0-.676-.663.672.672 0 0 0-.677.663z" fill="#FFAF36" fill-rule="nonzero"/>
                <circle fill="#FFAF36" fill-rule="nonzero" cx="4.375" cy="9.699" r="1"/>
                <path d="M3.04 10.638c0 .363.306.663.677.663.37 0 .677-.3.677-.663a.675.675 0 0 0-.677-.663c-.37 0-.677.3-.677.663zM1.898 6.07c0 .265.216.49.5.49.285 0 .501-.225.501-.49 0-.266-.23-.491-.5-.491-.271 0-.501.212-.501.49zM3.245 6.316c0 .365.304.663.677.663a.671.671 0 0 0 .677-.663.671.671 0 0 0-.677-.663.671.671 0 0 0-.677.663z" fill="#FFAF36" fill-rule="nonzero"/>
                <path d="M2.634 7.745a.67.67 0 0 0 .676.663.665.665 0 0 0 .677-.663.67.67 0 0 0-.677-.663.665.665 0 0 0-.676.663z" fill="#E37F05" fill-rule="nonzero"/>
                <path d="M3.459 8.75a.6.6 0 0 0 .61.597.6.6 0 0 0 .609-.596.6.6 0 0 0-.61-.597.6.6 0 0 0-.609.597z" fill="#E37F05" fill-rule="nonzero"/>
                <path d="M5.708 7.14c-1.26-.242-2.213-1.328-2.213-2.634 0-.092.005-.184.015-.273h2.56L5.709 7.14z" fill="#A15A0E" fill-rule="nonzero" opacity=".4"/>
                <path d="M2.167 8.977c0 .367.302.663.677.663a.669.669 0 0 0 .677-.663.669.669 0 0 0-.677-.663.669.669 0 0 0-.677.663z" fill="#E37F05" fill-rule="nonzero"/>
                <g opacity=".95" fill-rule="nonzero">
                    <path d="M3.824 8.876h.82c.182 0 .33.145.33.324v.803a.328.328 0 0 1-.33.324h-.82a.328.328 0 0 1-.331-.324V9.2c0-.179.148-.324.33-.324z" fill="#74ACC4"/>
                    <path d="M5.456 9.558V10.201l-1.354-.866.135-.459h.149l1.067.682h.003z" fill="#8ACEEB"/>
                    <path fill="#6B9FB5" d="m5.358 10.51-1.354-.743.202-.583 1.152.63z"/>
                    <path d="M3.369 8.876h.82c.182 0 .33.145.33.324v.803a.328.328 0 0 1-.33.324h-.82a.328.328 0 0 1-.331-.324V9.2c0-.179.148-.324.33-.324z" fill="#86C7E3"/>
                    <path d="M4.28 10.349h.391c.19 0 .341-.091.341-.2v-.923c0-.112-.154-.2-.34-.2h-.777c-.133 0-.237.06-.237.139 0 .078.104.14.237.14h.647v.767h-.259c-.13 0-.237.061-.237.14 0 .078.108.14.237.14l-.003-.003z" fill="#97E0FF"/>
                    <path d="M3.576 10.35c.219 0 .39-.152.39-.344V9.71h.19c.218 0 .39-.151.39-.344 0-.192-.172-.343-.39-.343h-.402c-.314 0-.562.224-.562.494v.49c0 .186.177.342.39.342h-.006z" fill="#97E0FF"/>
                </g>
                <path fill="#779CBF" fill-rule="nonzero" d="M2.061 5.56h1.227V4.232H1.934z"/>
                <g opacity=".8" fill-rule="nonzero">
                    <path d="m3.83 8.138.302-.747a.334.334 0 0 1 .43-.183l.762.295c.17.066.253.254.186.42l-.3.747a.334.334 0 0 1-.43.183l-.763-.295a.322.322 0 0 1-.186-.42z" fill="#74ACC4"/>
                    <path d="M6.101 8.25a.278.278 0 0 1-.016.048l-.162.374-1.176-1.08.27-.246.155.057.926.85.003-.003z" fill="#8ACEEB"/>
                    <path fill="#6B9FB5" d="M5.623 8.942 4.49 7.956l.39-.34.964.84z"/>
                    <path d="m3.406 7.984.302-.746a.334.334 0 0 1 .429-.183l.763.295c.17.066.253.255.186.42l-.302.748a.334.334 0 0 1-.429.182l-.762-.295a.322.322 0 0 1-.187-.42z" fill="#86C7E3"/>
                    <path d="m4.275 8.56.316.08c.153.037.325-.01.385-.107l.5-.799c.06-.096-.016-.204-.169-.243l-.628-.156c-.107-.025-.225.006-.266.073-.04.067.01.142.116.17l.522.129-.416.665-.21-.053c-.106-.026-.225.006-.268.073-.041.066.009.141.115.169h.003z" fill="#97E0FF"/>
                    <path d="M3.989 8.478c.171.073.363-.017.43-.207l.105-.297.147.067c.171.073.363-.016.434-.207.066-.19-.015-.402-.187-.48l-.323-.14c-.248-.107-.525.027-.621.302l-.177.486c-.066.19.015.403.187.481l.005-.005z" fill="#97E0FF"/>
                </g>
                <g fill-rule="nonzero">
                    <path fill="#E5EEF6" d="M0 .53 1.276.086l3.603 9.937-1.276.444z"/>
                    <path fill="#CFD7DE" d="M.011.589 1.287.145l3.603 9.937-1.276.444z"/>
                    <path fill="#C93E47" d="M.42.476 1.697.032l3.61 9.934-1.275.445z"/>
                    <path fill="#AB2E36" d="M.112.586 1.388.142l3.6 9.938-1.276.444z"/>
                </g>
                <g opacity=".85" fill-rule="nonzero">
                    <path d="m2.5 8.044.691-.431a.336.336 0 0 1 .457.1l.44.677a.32.32 0 0 1-.102.448l-.692.43a.336.336 0 0 1-.457-.1l-.44-.677a.32.32 0 0 1 .102-.447z" fill="#74ACC4"/>
                    <path d="M3.956 7.683s.018.058.026.09l.186.729-1.307.507-.047-.748.113-.175 1.031-.403h-.002z" fill="#8ACEEB"/>
                    <path fill="#6B9FB5" d="m4.303 8.877-1.284.44-.07-.958 1.093-.369z"/>
                    <path d="m2.119 8.28.692-.432a.336.336 0 0 1 .457.1l.44.677a.32.32 0 0 1-.102.448l-.692.43a.336.336 0 0 1-.457-.099l-.44-.677a.32.32 0 0 1 .102-.448z" fill="#86C7E3"/>
                    <path d="m3.537 9.25.21-.126c.1-.06.13-.189.066-.286L3.28 8.04a.224.224 0 0 0-.298-.063l-.418.252a.137.137 0 0 0-.046.197.154.154 0 0 0 .208.043l.347-.208.445.663-.14.084a.137.137 0 0 0-.045.197.154.154 0 0 0 .208.043h-.003z" fill="#97E0FF"/>
                    <path d="M3.161 9.596c.207-.091.267-.275.134-.418l-.207-.225.18-.079c.207-.092.267-.276.14-.418-.133-.143-.4-.184-.608-.092l-.387.17c-.294.129-.38.4-.194.603l.334.363c.134.142.401.184.608.092v.004z" fill="#97E0FF"/>
                </g>
                <path fill="#6583A1" fill-rule="nonzero" d="M6.377 5.56H5.221l.09-1.327h1.264z"/>
                <g transform="translate(3.566 1.626)">
                    <mask id="uh3cb8lhpb" fill="#fff">
                        <use xlink:href="#ki271f2cka"/>
                    </mask>
                    <g mask="url(#uh3cb8lhpb)">
                        <g transform="translate(-.666 -.727)">
                            <path d="M1.158 3.334c0-1.267.942-2.314 2.178-2.515V.78h.43c1.44 0 2.608 1.144 2.608 2.554 0 1.411-1.167 2.555-2.608 2.555h-.43V5.85C2.1 5.65 1.158 4.6 1.158 3.334z" fill="#FF9500" fill-rule="nonzero"/>
                            <path d="M6.58 3.742c0-.294.23-.537.568-.658.054.435.081.88.095 1.326-.38-.101-.65-.354-.65-.678l-.013.01z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M6.111 3.469a.475.475 0 0 1 .948 0 .475.475 0 0 1-.948 0z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M6.147 3.091a.475.475 0 0 0 .948 0 .475.475 0 0 0-.948 0z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M5.79 2.58c0 .304.256.556.554.556a.575.575 0 0 0 .569-.557.566.566 0 0 0-.569-.557.555.555 0 0 0-.555.557z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M5.684 2.125a.297.297 0 0 0 .596 0 .297.297 0 0 0-.299-.292.297.297 0 0 0-.297.292z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M5.632 2.199a.6.6 0 0 1-.609-.597.57.57 0 0 1 .149-.384c.352.172.69.371 1.029.57a.602.602 0 0 1-.569.398v.013z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M4.5 1.638c0 .358.298.663.677.663a.664.664 0 1 0 0-1.326.664.664 0 0 0-.677.663z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M5.3 1.318c0 .226-.19.411-.42.411a.418.418 0 0 1-.42-.41.36.36 0 0 1 .04-.173c.271.053.528.106.8.172z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M5.007 2.077a.67.67 0 0 0 .677.663.67.67 0 0 0 .677-.663.67.67 0 0 0-.677-.663.67.67 0 0 0-.677.663zM5.746 4.123c0 .37.307.663.677.663.37 0 .677-.3.677-.663a.674.674 0 0 0-.677-.663c-.37 0-.677.3-.677.663z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M5.927 4.642c0 .36.297.663.677.663a.662.662 0 1 0 0-1.326c-.38 0-.677.29-.677.663z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M5.923 5.021a.6.6 0 0 0 .61.597.6.6 0 0 0 .609-.597.6.6 0 0 0-.61-.596.6.6 0 0 0-.609.596z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M6.644 4.762c.149 0 .27.037.393.099-.312.421-.65.83-1.002 1.227a.746.746 0 0 1-.244-.546c0-.433.38-.78.853-.78z" fill="#FA8C16" fill-rule="nonzero"/>
                            <circle fill="#FA8C16" fill-rule="nonzero" cx="6.211" cy="5.445" r="1"/>
                            <path d="M5.582 5.694a.446.446 0 0 0 .894 0 .446.446 0 0 0-.448-.438.446.446 0 0 0-.446.438z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M4.964 5.725c0 .365.304.663.677.663a.671.671 0 0 0 .677-.663.671.671 0 0 0-.677-.663.671.671 0 0 0-.677.663z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M5.65 5.487c.322 0 .59.226.657.53-.362.226-.724.452-1.113.65a.653.653 0 0 1-.241-.503c0-.372.308-.677.684-.677h.013z"/>
                            <path d="M4.833 6.178c0 .239.203.437.447.437a.446.446 0 0 0 .447-.437.446.446 0 0 0-.447-.438.446.446 0 0 0-.447.438z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M4.296 6.27c0 .345.285.61.623.61a.624.624 0 0 0 .623-.61.615.615 0 0 0-.623-.61.624.624 0 0 0-.623.61zM7.226 3.686c-.067.453-.162.895-.284 1.326-.244-.136-.406-.351-.406-.612 0-.351.298-.634.69-.714z" fill="#FA8C16" fill-rule="nonzero"/>
                            <path d="M6.416 3.975c0 .212.176.385.393.385a.389.389 0 0 0 .392-.385.389.389 0 0 0-.392-.384.389.389 0 0 0-.393.384zM6.26 2.478c0-.133.042-.252.096-.358.23.371.433.742.636 1.127-.406-.04-.731-.358-.731-.77z" fill="#FA8C16" fill-rule="nonzero"/>
                            <g fill-rule="nonzero">
                                <ellipse fill="#E07E14" cx="3.336" cy="3.334" rx="2.608" ry="2.554"/>
                                <circle fill="#FF9E16" transform="rotate(-45 3.335 3.335)" cx="3.335" cy="3.335" r="2.358"/>
                                <path d="M1.038 3.334c0 1.244 1.028 2.25 2.298 2.25 1.269 0 2.298-1.006 2.298-2.25 0-1.243-1.029-2.25-2.298-2.25-1.27 0-2.298 1.007-2.298 2.25z" fill="#F7E6B4"/>
                                <g fill="#F7A016">
                                    <path d="M3.352 3.071a.135.135 0 0 0 .125-.085l.545-1.53a.125.125 0 0 0-.083-.16 1.89 1.89 0 0 0-1.174 0 .125.125 0 0 0-.083.16l.544 1.53a.13.13 0 0 0 .126.085zM3.352 5.467c.198 0 .395-.031.586-.094a.125.125 0 0 0 .084-.16l-.545-1.53a.135.135 0 0 0-.251 0l-.544 1.53a.126.126 0 0 0 .08.16c.192.063.39.094.588.094h.002z"/>
                                </g>
                                <g fill="#F7A016">
                                    <path d="M3.526 3.148a.13.13 0 0 0 .148.024l1.48-.714a.123.123 0 0 0 .058-.168c-.086-.173-.199-.33-.336-.462a1.767 1.767 0 0 0-.472-.33.127.127 0 0 0-.171.057l-.73 1.45a.124.124 0 0 0 .025.145l-.002-.002zM1.796 4.843c.135.134.295.245.472.33a.13.13 0 0 0 .173-.058l.73-1.45a.124.124 0 0 0-.025-.145.13.13 0 0 0-.149-.024l-1.48.715a.123.123 0 0 0-.058.168c.087.173.2.33.337.462v.002z"/>
                                </g>
                                <g fill="#F7A016">
                                    <path d="M3.603 3.355c0 .056.035.105.086.124l1.563.536a.129.129 0 0 0 .163-.081 1.8 1.8 0 0 0 0-1.157.129.129 0 0 0-.163-.081l-1.563.536a.129.129 0 0 0-.086.123zM1.158 3.355c0 .196.032.39.096.578a.129.129 0 0 0 .164.082L2.98 3.48a.129.129 0 0 0 .087-.124.13.13 0 0 0-.087-.123l-1.562-.536a.13.13 0 0 0-.164.08 1.8 1.8 0 0 0-.096.578v.001z"/>
                                </g>
                                <g fill="#F7A016">
                                    <path d="M3.526 3.52a.124.124 0 0 0-.025.146l.73 1.45c.032.062.109.088.17.056a1.726 1.726 0 0 0 .81-.792.126.126 0 0 0-.059-.167l-1.48-.715a.131.131 0 0 0-.148.024l.002-.002zM1.796 1.826c-.136.133-.25.29-.337.462a.122.122 0 0 0 .058.168l1.48.715c.05.023.11.014.149-.024a.122.122 0 0 0 .024-.144l-.73-1.45a.128.128 0 0 0-.17-.056 1.767 1.767 0 0 0-.472.33h-.002z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <path fill="#4578AB" fill-rule="nonzero" d="M3.446 10.638h1.991v1.326H3.446z"/>
                <path fill="#4171A1" fill-rule="nonzero" d="M4.538 10.638h1.354v1.326H4.538z"/>
                <path fill="#518FC9" fill-rule="nonzero" d="M2.124 10.638h1.354v1.326H2.124z"/>
                <path fill="#F6F6F6" fill-rule="nonzero" opacity=".2" d="M5.307 10.355H2.096l-.762-6.122h4.737z"/>
                <path d="M4.503 6.802c.016-.195.26-.342.549-.331.285.013.503.18.484.376l-.318 3.51H4.183l.323-3.555h-.003zM2.966 4.233l.208 2.083c.02.195-.22.363-.534.376-.318.013-.584-.136-.605-.332L1.82 4.233H2.966z" fill="#F6F6F6" fill-rule="nonzero" opacity=".4"/>
                <path fill="#F6F6F6" fill-rule="nonzero" opacity=".2" d="M3.397 4.233h2.674l-.764 6.122h-1.91z"/>
            </g>
        </g>
    </svg>
`
export default ArtRetirementSmall
