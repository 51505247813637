import { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Logo from '../../../assets/images/GuidedChoice-logo.png'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5in 0 0.5in 0;
  width: 100%;
  min-width: 100%;
`

const Banner = styled.div`
  background-color: #038ab7;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  height: 40pt;
`

const BannerText = styled.div`
  color: white;
  padding: 0 42pt;
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 15pt;
  font-weight: bold;
`

const LogoContainer = styled.div`
  margin-right: 0.5in;
  padding: 6px 0;
  & img {
    height: 36px;
  }
`

class PageHeader extends Component {
  static displayName = 'PageHeader'
  static propTypes = {
    text: PropTypes.string,
  }

  render() {
    const { text } = this.props
    return (
      <Container>
        {text ? (
          <Banner>
            <BannerText>{text}</BannerText>
          </Banner>
        ) : (
          <div />
        )}
        <LogoContainer>
          <img src={Logo} alt='Logo' />
        </LogoContainer>
      </Container>
    )
  }
}

export default PageHeader
