import { css } from '@emotion/react'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'
import Grid from './Grid'

const computeRiskRatingText = (riskLevel, min, max) => {
  if (riskLevel < min) {
    return 'Lower than'
  }

  if (riskLevel >= min && riskLevel <= max) {
    return 'Within'
  }

  if (riskLevel > max) {
    return 'Greater than'
  }
}

const RiskRatingStyle = styled.div`
  display: flex;
  align-items: center;
  min-height: 56px;
  height: 100%;
  font-size: 14px;
  color: ${({ isOld }) => (isOld ? '#7A8E96' : '#038ab7')};
  justify-content: ${({ isOld }) => (isOld ? 'flex-end' : 'flex-start')};
`
const RatingText = styled.span`
  padding: 0 1rem;
`
const RatingChart = styled.span`
  width: 92px;
  height: 14px;
  border: 1px solid #979797;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  & > span {
    display: inline-block;
    height: 100%;
    width: 33.33%;
  }
  & > span:nth-of-type(2) {
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
  }
`
const RiskRating = observer(({ store, riskLevel, isOld }) => {
  const { acceptedCase } = store
  let recomMinRiskLevel
  let recomMaxRiskLevel

  if (!store.config.spendingMode) {
    recomMinRiskLevel = acceptedCase.recomMinRiskLevel
    recomMaxRiskLevel = acceptedCase.recomMaxRiskLevel
  }

  if (store.config.spendingMode) {
    recomMinRiskLevel = 9
    recomMaxRiskLevel = 21
  }

  const ratingText = computeRiskRatingText(riskLevel, recomMinRiskLevel, recomMaxRiskLevel)

  const minMaxThird = (recomMaxRiskLevel - recomMinRiskLevel) / 3

  const centerSection = _.inRange(
    riskLevel,
    recomMinRiskLevel + minMaxThird,
    recomMinRiskLevel + 2 * minMaxThird
  )

  const minSection =
    !centerSection && _.inRange(riskLevel, recomMinRiskLevel, recomMinRiskLevel + minMaxThird)
  const maxSection =
    (!centerSection &&
      _.inRange(riskLevel, recomMinRiskLevel + 2 * minMaxThird, recomMaxRiskLevel)) ||
    riskLevel === recomMaxRiskLevel

  return (
    <RiskRatingStyle isOld={isOld}>
      <RatingText>{ratingText}</RatingText>
      {ratingText === 'Within' && (
        <RatingChart>
          <span
            css={
              minSection &&
              css`
                background-color: #d4ebf2;
              `
            }
          />
          <span
            css={
              centerSection &&
              css`
                background-color: #d4ebf2;
              `
            }
          />
          <span
            css={
              maxSection &&
              css`
                background-color: #d4ebf2;
              `
            }
          />
        </RatingChart>
      )}
    </RiskRatingStyle>
  )
})

const Risk = ({ store, hideOld }) => {
  let baseRiskLevel
  let acceptedRiskLevel

  if (!store.config.spendingMode) {
    baseRiskLevel = store.baseCase.riskLevel
    acceptedRiskLevel = store.acceptedCase.riskLevel
  }

  if (store.config.spendingMode) {
    baseRiskLevel = store.spendingBaseCases.averageMarketSpendingAdvice.riskLevel
    acceptedRiskLevel = store.spendingAcceptedCase.averageMarketSpendingAdvice.riskLevel
  }

  return (
    <Grid
      name={
        <div
          css={css`
            color: #7a8e96;
            font-size: 14px;
            margin: 0.5rem 1rem 0.5rem 0;
          `}>
          Risk - relative to our preferred range
        </div>
      }
      oldValue={!hideOld && <RiskRating store={store} riskLevel={baseRiskLevel} isOld />}
      newValue={<RiskRating store={store} riskLevel={acceptedRiskLevel} />}
      css={css`
        margin: 1rem 0;
      `}
    />
  )
}

export default observer(Risk)
