import { types, flow, getParent } from 'mobx-state-tree'
import _ from 'lodash'

import { API } from '../api'
const { model, array, optional, maybeNull, number, string, boolean } = types

const ContributionItem = model({
  active: maybeNull(boolean),
  accountId: maybeNull(number),
  accountName: maybeNull(string),
  annualContributionDollars: maybeNull(number),
  participantId: maybeNull(number),
  planType: maybeNull(number),
})

const AccountInsight = model({
  aggregateMonthlyContribution: maybeNull(number),
  itemizedMonthlyContribution: array(ContributionItem),
}).views(self => ({
  get noEmployerContributionList() {
    const {
      institutionalAccount,
      spouseEmployerAccount,
      special457,
      special403b,
      config: { isRetail },
    } = getParent(self)

    let filteredArray = self.itemizedMonthlyContribution.filter(contributionItem => {
      // Account Name + Account ID is used as the unique identifier for now, should be Account Type + Account ID in the future for safety
      const uniqueIdentifier = contributionItem.accountName + contributionItem.accountId
      const institutionalIdentifier =
        _.get(institutionalAccount, 'name') + _.get(institutionalAccount, 'id')
      const spouseEmployerIdentifier =
        _.get(spouseEmployerAccount, 'name') + _.get(spouseEmployerAccount, 'id')
      const special457Identifier = _.get(special457, 'name') + _.get(special457, 'id')
      const special403bIdentifier = _.get(special403b, 'name') + _.get(special403b, 'id')

      // Exclude the client's Institutional Account
      if (uniqueIdentifier === institutionalIdentifier) {
        return false
      }

      // Exclude the spouse's Employer Account
      if (uniqueIdentifier === spouseEmployerIdentifier) {
        return false
      }

      // Exclude the client's special 457 account
      if (uniqueIdentifier === special457Identifier) {
        return false
      }

      // Exclude the client's special 403b account
      if (uniqueIdentifier === special403bIdentifier) {
        return false
      }

      // return true for everything else
      return true
    })

    if (isRetail) {
      filteredArray = filteredArray.filter(account => account.active !== false)
    }

    return _.orderBy(
      filteredArray,
      [
        obj => (obj.annualContributionDollars === null ? 0 : obj.annualContributionDollars),
        obj => (obj.accountName || '').toLowerCase(),
      ],
      ['desc', 'asc']
    )
  },
  get sumNoEmployerContributionList() {
    let total = 0
    self.noEmployerContributionList.forEach(acct => (total += acct.annualContributionDollars))
    return total
  },
  get sortedContributionList() {
    const { institutionalAccount, spouseEmployerAccount, special457, special403b } = getParent(self)
    const institutionalIdentifier =
      _.get(institutionalAccount, 'name') + _.get(institutionalAccount, 'id')
    const special457Identifier = _.get(special457, 'name') + _.get(special457, 'id')
    const special403bIdentifier = _.get(special403b, 'name') + _.get(special403b, 'id')
    const spouseEmployerIdentifier =
      _.get(spouseEmployerAccount, 'name') + _.get(spouseEmployerAccount, 'id')

    const foundInstitutional = self.itemizedMonthlyContribution.find(
      item => item.accountName + item.accountId === institutionalIdentifier
    )

    const found457 = self.itemizedMonthlyContribution.find(
      item => item.accountName + item.accountId === special457Identifier
    )

    const found403b = self.itemizedMonthlyContribution.find(
      item => item.accountName + item.accountId === special403bIdentifier
    )

    const foundSpouseEmployer = self.itemizedMonthlyContribution.find(
      item => item.accountName + item.accountId === spouseEmployerIdentifier
    )

    const employerList = []

    // Include the client's Institutional Account
    if (foundInstitutional) {
      employerList.push(foundInstitutional)
    }

    // Include the client's special 457 account
    if (found457) {
      employerList.push(found457)
    }

    // Include the client's special 403b account
    if (found403b) {
      employerList.push(found403b)
    }

    // Include the spouse's Employer Account
    if (foundSpouseEmployer) {
      employerList.push(foundSpouseEmployer)
    }

    return employerList.concat(self.noEmployerContributionList)
  },
  get sortedContributionListNoEmployerSpouseBrokerage() {
    const { special457, special403b } = getParent(self)
    const special457Identifier = _.get(special457, 'name') + _.get(special457, 'id')
    const special403bIdentifier = _.get(special403b, 'name') + _.get(special403b, 'id')

    const found457 = self.itemizedMonthlyContribution.find(
      item => item.accountName + item.accountId === special457Identifier
    )

    const found403b = self.itemizedMonthlyContribution.find(
      item => item.accountName + item.accountId === special403bIdentifier
    )

    const employerList = []

    // Include the client's special 457 account
    if (found457) {
      employerList.push(found457)
    }

    // Include the client's special 403b account
    if (found403b) {
      employerList.push(found403b)
    }

    // Exclude all other employer accounts and brokerage accounts
    const noEmployerBrokerage = _.filter(
      self.noEmployerContributionList,
      account => account.planType !== 15
    )

    return employerList.concat(noEmployerBrokerage)
  },
}))

const AccountInsightStore = model({
  accountInsight: optional(AccountInsight, {}),
}).actions(self => ({
  getAccountInsight: flow(function* () {
    const [aggregateMonthlyContribution, itemizedMonthlyContribution] = yield Promise.all([
      API.get('accounts/accountInsight'),
      API.get('accounts/accountInsight/ItemizedContributions'),
    ])

    self.accountInsight.aggregateMonthlyContribution = aggregateMonthlyContribution.data
    self.accountInsight.itemizedMonthlyContribution = itemizedMonthlyContribution.data
  }),
}))

export default AccountInsightStore
