import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'

import { Page } from '../elements'
import { Button } from '../../../components'

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 36px solid #7a8e96;
`
const ButtonGroup = styled.div`
  text-align: center;
  margin: 50px 0;
`

const newChanges = [
  // {
  //   key: 'new-shortcuts',
  //   title: 'New shortcuts',
  //   text: 'Make your contribution changes quickly and easily'
  // },
  {
    key: 'improved-scenario-modeling',
    title: 'Improved scenario modeling',
    text: 'Understand the impact on your retirement and make more informed decisions',
  },
  {
    key: 'save-scenarios',
    title: 'Ability to save scenarios',
    text: 'Compare saved scenarios to see how different factors affect your future retirement income',
  },
  {
    key: 'mobile-responsive',
    title: 'Mobile-responsive interface',
    text: 'Plan on your tablet or mobile device',
  },
  {
    key: 'printable-guide',
    title: 'Printable investment and planning guide',
    text: 'Includes a to-do list to make it easier to implement your optimized retirement plan',
  },
  {
    key: 'personalized-portfolios',
    title: 'Even more personalized investment portfolios',
    text: 'All designed to help you meet your goals',
  },
  {
    key: 'electronic-delivery',
    title: 'Electronic delivery of account communications',
    text: `We're working towards “no snail mail” within our operations; your consent is by default, though you may opt out`,
  },
]

class NewChanges extends Component {
  handleNext = () => {
    this.props.onboarding.handleNewChangesPageView()
    this.props.history.push('/welcome/account')
  }

  componentDidMount() {
    const {
      onboarding: { config, userSettings },
      history,
    } = this.props

    if (!config.template.isMigrating || userSettings.newChangesPageViewed) {
      history.replace('/welcome/account')
    }
  }

  render() {
    return (
      <Page>
        <Page.Heading title="Welcome—we've made some changes!" />

        <Page.AnimateGroup>
          <Page.AnimateItem>
            <Page.Text>
              At GuidedChoice, we want to help make your retirement planning simple and easy. Our
              new interface was developed to do just that—it is everything you loved before but
              better!
            </Page.Text>
          </Page.AnimateItem>

          {newChanges.map(item => (
            <Page.AnimateItem key={item.key}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  margin: 20px 0;
                `}>
                <Triangle />
                <div
                  css={css`
                    margin: 0 20px;
                    color: #4a606a;
                    font-size: 1.125rem;
                    line-height: 1.35;
                  `}>
                  <div>{item.title}</div>
                  <div
                    css={css`
                      font-weight: 300;
                    `}>
                    {item.text}
                  </div>
                </div>
              </div>
            </Page.AnimateItem>
          ))}

          <Page.AnimateItem>
            <ButtonGroup>
              <Button
                id='new-changes-ok-button'
                primary
                label='Ok'
                width='100px'
                onClick={this.handleNext}>
                Ok
              </Button>
            </ButtonGroup>
          </Page.AnimateItem>
        </Page.AnimateGroup>
      </Page>
    )
  }
}

export default inject('onboarding')(observer(NewChanges))
