import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'
import dayjs from 'dayjs'

import { API } from '../api'
import { TAX_TYPES, VALUATION_METHODS } from '../constants'
const { model, maybeNull, number, string, boolean, array, compose } = types

export const Security = model('Security', {
  changeAmt: maybeNull(number, 0),
  changePercent: maybeNull(number, 0),
  cusip: maybeNull(string, ''),
  datafeed: maybeNull(boolean, true),
  expenseRatio: maybeNull(number, 0),
  id: maybeNull(number),
  msCategory: maybeNull(number, 0),
  msrating: maybeNull(number, 0),
  price: maybeNull(number, 0),
  price_date: maybeNull(string),
  securityName: maybeNull(string, ''),
  securityType: maybeNull(number, 0),
  source: maybeNull(string, ''),
  strExposureIds: maybeNull(string, ''),
  ticker: maybeNull(string, ''),
})

const Fund = compose(
  Security,
  model('Fund', {
    publiclyTraded: maybeNull(boolean),
    rkSecurityId: maybeNull(string),
  })
)

const Investment = model('Investment', {
  id: maybeNull(number),
  personId: maybeNull(number),
  dateOfUserInfo: maybeNull(string),
  dateUnrestricted: maybeNull(string),
  purchaseDate: maybeNull(string),
  msCategory: maybeNull(number),
  pctnotallowedtotrade: maybeNull(number),
  quantity: maybeNull(number),
  redemptionFee: maybeNull(number),
  restrictedForParticipant: maybeNull(boolean),
  securities: maybeNull(Security),
  securityType: maybeNull(number),
  taxType: maybeNull(number),
  tickerName: maybeNull(string),
  totalCostBasis: maybeNull(number),
  totalValue: maybeNull(number),
  userSuppliedPrice: maybeNull(number),
  valuationMethod: maybeNull(number),
})
  .views(self => ({
    get isPreTax() {
      return self.taxType === TAX_TYPES.preTax
    },
    get isRoth() {
      return self.taxType === TAX_TYPES.roth
    },
    get isAfterTax() {
      return self.taxType === TAX_TYPES.afterTax
    },
    get isNotInvested() {
      return self.taxType === TAX_TYPES.notInvested
    },
    get balanceByShareCount() {
      const { price } = self.securities
      return (self.quantity || 0) * (price || 1)
    },
    get shareCountByBalance() {
      const { price } = self.securities
      return (self.totalValue || 0) / (price || 1)
    },
    get value() {
      return self.valuationMethod === VALUATION_METHODS.marketPrice
        ? self.balanceByShareCount
        : self.totalValue
    },
  }))
  .actions(self => ({
    setAttributes(name, value) {
      const changes = _.isString(name) ? { [name]: value } : name
      _.merge(self, changes)
    },
    handleSharesChange(name, value) {
      self.setAttributes(name, value)
      self.setAttributes({ totalValue: self.balanceByShareCount })
    },
    handleBalanceChange(name, value) {
      self.setAttributes(name, value)
      self.setAttributes({ quantity: self.shareCountByBalance })
    },
    setValuationMethod(value) {
      const quantity = VALUATION_METHODS.marketPrice ? self.quantity : 0
      _.merge(self, {
        dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
        quantity: quantity,
        valuationMethod: value,
      })
    },
    setAsNotInvested() {
      _.merge(self, {
        dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
        quantity: 0,
        totalCostBasis: 0,
        totalValue: 0,
        valuationMethod: VALUATION_METHODS.notInvested,
        taxType: TAX_TYPES.notInvested,
      })
    },
  }))

export const TrustFamily = model('TrustFamily', {
  id: maybeNull(number),
  effectiveDate: maybeNull(string),
  trustFamilyName: maybeNull(string),
  fundList: array(Fund),
})

const Investments = model('Investments', {
  investments: array(Investment),
  trustFamilies: array(TrustFamily),
})
  .views(self => ({
    get availableInvestments() {
      return self.availableInvestmentsResult ?? []
    },
    get preTaxInvestments() {
      return self.investments.filter(investment => investment.isPreTax)
    },
    get rothInvestments() {
      return self.investments.filter(investment => investment.isRoth)
    },
    get afterTaxInvestments() {
      return self.investments.filter(investment => investment.isAfterTax)
    },
    get preTaxBalance() {
      return _.sumBy(self.preTaxInvestments, investment => investment.value || 0)
    },
    get rothBalance() {
      return _.sumBy(self.rothInvestments, investment => investment.value || 0)
    },
    get afterTaxBalance() {
      return _.sumBy(self.afterTaxInvestments, investment => investment.value || 0)
    },
    get investedInvestments() {
      return self.investments.filter(investment => !investment.isNotInvested)
    },
    get totalBalance() {
      // make sure we ignore any orphaned balances on "not invested" investments
      return _.sumBy(self.investedInvestments, investment => investment.value || 0)
    },
    get notInvestedInvestments() {
      return self.investments.filter(investment => {
        const notInvestedAndAlreadyHasInvested =
          investment.isNotInvested &&
          _.findIndex(
            self.investedInvestments,
            investedInvestment => investment.securities.id === investedInvestment.securities.id
          ) !== -1

        const notInvestedAlreadyAvailable =
          _.findIndex(
            self.availableInvestmentsResult,
            availableInvestment => availableInvestment.id === investment.securities.id
          ) !== -1

        return (
          investment.isNotInvested &&
          !notInvestedAndAlreadyHasInvested &&
          !notInvestedAlreadyAvailable
        )
      })
    },
    findInvestmentById(investmentId) {
      return self.investments.find(investment => investment.id === investmentId)
    },
    findInvestmentsBySecurityId(securityId) {
      return self.investments.filter(
        investment => _.get(investment, 'securities.id') === securityId
      )
    },
    findPreTaxInvestmentsBySecurityId(securityId) {
      return self.preTaxInvestments.filter(
        investment => _.get(investment, 'securities.id') === securityId
      )
    },
    findRothInvestmentsBySecurityId(securityId) {
      return self.rothInvestments.filter(
        investment => _.get(investment, 'securities.id') === securityId
      )
    },
    findAfterTaxInvestmentsBySecurityId(securityId) {
      return self.afterTaxInvestments.filter(
        investment => _.get(investment, 'securities.id') === securityId
      )
    },
    findNotInvestedBySecurityId(securityId) {
      return self.notInvestedInvestments.filter(
        investment => _.get(investment, 'securities.id') === securityId
      )
    },
    notInvestedInvestmentsByType(securityType) {
      return self.notInvestedInvestments.filter(
        investment => investment.securityType === securityType
      )
    },
    forSecurityId(id, includeNotInvested = false) {
      return self.investments.filter(
        investment =>
          investment.securities &&
          investment.securities.id === id &&
          (includeNotInvested ? true : investment.taxType !== TAX_TYPES.notInvested)
      )
    },
  }))
  .actions(self => ({
    getInvestments: flow(function* ({ type, id }) {
      const typeToInvestmentsAPI = ({ type, id }) =>
        ({
          'institutional': 'accounts/institutionalAccount/investments',
          'non-gc': `accounts/nonGCAccount/${id}/investments`,
        }[type])
      const investments = yield API.get(typeToInvestmentsAPI({ type, id }))
      self.investments = investments.data || []
      return investments.data || []
    }),
    deleteInvestment: flow(function* ({ type, id, investment }) {
      const typeToInvestmentsAPI = ({ type, id, investment }) =>
        ({
          'institutional': `accounts/institutionalAccount/investments/${investment.id}`,
          'non-gc': `accounts/nonGCAccount/${id}/investments/${investment.id}`,
        }[type])

      yield API.delete(typeToInvestmentsAPI({ type, id, investment }))
      self.investments.remove(investment)
    }),
    replaceInvestment(data) {
      const index = _.findIndex(self.investments, investment => {
        return investment.id === data.id
      })
      if (index === -1) {
        //
      }
      self.investments[index] && self.investments[index].setAttributes(data)
    },
    getTrustFamilies: flow(function* () {
      const res = yield API.get('available-investments')
      const trustFamilies = _.get(res, 'data', [])

      self.trustFamilies = trustFamilies
    }),
    getAvailableInvestments: flow(function* () {
      if (_.isEmpty(self.trustFamilies)) {
        yield self.getTrustFamilies()
      }

      const availableInvestments = _.get(self.trustFamilies, '[0]fundList', []).filter(
        investment => {
          const availableInvestmentAndAlreadyHasInvested =
            _.findIndex(self.investedInvestments, investedInvestment => {
              return investment.id === investedInvestment.securities.id
            }) !== -1

          return !availableInvestmentAndAlreadyHasInvested
        }
      )

      self.availableInvestmentsResult = availableInvestments
      return availableInvestments
    }),
  }))

export default Investments
