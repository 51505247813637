/* eslint-disable react/jsx-indent */

import { css } from '@emotion/react'
import { Component } from 'react'
import { withRouter, matchPath } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { Link } from '../../../../assets/icons'
import { API_URL } from '../../../../api'
import { Drawer, SVGWrapper } from '../../../../components'
import { numberToDollars } from '../../../../utils'
import { planTypeIdToAccountKey } from '../../../../constants'
import MenuIcon from './MenuIcon.js'
import { ArrowBox } from './styled'

const ArrowLeft = props => (
  <svg data-name='Layer 1' viewBox='0 0 24 24' {...props}>
    <title>arrow-pointing-left</title>
    <path
      data-name='icn/backward-selected'
      d='M21.6 12a9.6 9.6 0 0 0-19.2 0M0 12a12 12 0 0 1 24 0M2.4 12a9.6 9.6 0 0 0 19.2 0m2.4 0a12 12 0 0 1-24 0m13.93 4.13v2.82L7 12.07l7-7V8l-4.1 4.09z'
    />
  </svg>
)

const HomeIcon = ({ active, ...props }) => (
  <svg viewBox='0 0 16 16' {...props}>
    <path
      d='M14.564 7.377L8.552 1.43a1.503 1.503 0 0 0-2.104 0L.436 7.377a1.458 1.458 0 0 0 0 2.08c.455.449 1.054.534 1.574.317v5.737c0 .27.222.489.495.489h9.939a.489.489 0 0 0 .49-.489V9.774c.521.217 1.176.132 1.63-.317a1.458 1.458 0 0 0 0-2.08zM6.43 14.93H5.357v-4.286H6.43v4.286zm5.357-.008l-4.372.002v-4.798c0-.276-.209-.5-.47-.5l-2.276.006a.487.487 0 0 0-.473.5v4.798h-.98v-6.21c1.307-1.384 3.409-3.55 4.312-4.505l4.259 4.505v6.202zm1.998-6.556a.514.514 0 0 1-.692.002c-.76-.707-4.491-4.18-5.248-4.881a.515.515 0 0 0-.69 0L1.908 8.367a.515.515 0 0 1-.692-.002.434.434 0 0 1 0-.646L7.15 2.204a.524.524 0 0 1 .698 0l5.936 5.515a.434.434 0 0 1 0 .646zM8.571 10.18h1.215a1 1 0 0 1 0 2H8.57a1 1 0 1 1 0-2zm0 .464v1.071h1.072v-1.071H8.57z'
      fillRule='nonzero'
      fill={active ? '#038ab7' : '#7A8E96'}
    />
  </svg>
)

const GoalsIcon = ({ active, ...props }) => (
  <svg viewBox='0 0 16 16' {...props}>
    <g fill={active ? '#038ab7' : '#8599A0'} fillRule='evenodd'>
      <path d='M6.5 14.5a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-1a4 4 0 1 0 0-8 4 4 0 0 0 0 8z' />
      <path d='M6.5 12.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm.025-1.1a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75z' />
      <circle cx={6.5} cy={9.5} r={1} />
      <path d='M6 9.45l4.95-4.95.521.521-4.95 4.95z' />
      <path d='M10.1 3.5l2.919-3 .558 1.808 1.773.6-2.907 2.886-1.792-.583-.551-1.71zm.8.2l.312.97 1.016.33 1.647-1.635-1.004-.34L12.554 2 10.9 3.7z' />
    </g>
  </svg>
)

const AccountsIcon = ({ active, ...props }) => (
  <svg viewBox='0 0 16 16' {...props}>
    <g stroke={active ? '#038ab7' : '#7A8E96'} fill='none' fillRule='evenodd'>
      <path d='M14 12v-.432a.391.391 0 0 0-.142-.288.547.547 0 0 0-.349-.132h-.427V6h-1.85v5.148h-.93V6h-1.85v5.148h-.917V6h-1.85v5.148h-.918V6H2.918v5.148h-.427a.545.545 0 0 0-.349.132.392.392 0 0 0-.142.288V12h12zM1 15.5h14v-1.327a.704.704 0 0 0-.144-.467c-.104-.131-.222-.206-.353-.206H1.51c-.144 0-.262.075-.366.206a.785.785 0 0 0-.144.467V15.5zM2.435 4.5c.004.005-.008 0-.009 0h.01zm0 0H13.56a.106.106 0 0 0 .012-.045v-.5h.929v-.306L8 .554 1.5 3.649v.306h.93v.5c0 .02.01.048.004.044l.001.001z' />
    </g>
  </svg>
)

const ProfileIcon = props => (
  <svg id='avatar_normal_c' data-name='avatar_normal_c' viewBox='0 0 25 24.5' {...props}>
    <defs />
    <title>avatar-normal-c</title>
    <g id='Symbols'>
      <g id='icn_avatar-normal' data-name='icn/avatar-normal'>
        <g id='Avatar'>
          <path
            id='Shape'
            className='avatar_normal_c_cls-1'
            d='M12 12a4.8 4.8 0 1 0-4.8-4.8A4.8 4.8 0 0 0 12 12zm0 2.4a7.2 7.2 0 1 1 7.2-7.2 7.2 7.2 0 0 1-7.2 7.2z'
            transform='translate(.5 .5)'
          />
          <path
            id='Shape-2'
            data-name='Shape'
            className='avatar_normal_c_cls-1'
            d='M21.6 24a9.6 9.6 0 1 0-19.2 0M0 24a12 12 0 0 1 24 0'
            transform='translate(.5 .5)'
          />
        </g>
      </g>
    </g>
  </svg>
)

const HelpIcon = props => (
  <svg data-name='Layer 1' viewBox='0 0 24 24' {...props}>
    <title>help-normal-c</title>
    <g data-name='icn/help-normal'>
      <path d='M21.6 12a9.6 9.6 0 0 0-19.2 0M0 12a12 12 0 0 1 24 0' />
      <path data-name='Shape' d='M2.4 12a9.6 9.6 0 0 0 19.2 0m2.4 0a12 12 0 0 1-24 0' />
      <text
        transform='translate(8 19)'
        style={{ isolation: 'isolate' }}
        fontSize={19}
        fontFamily='Aleo'
        fontWeight={700}
        data-name='?'>
        ?
      </text>
    </g>
  </svg>
)

const PiggyBank = ({ active, ...props }) => (
  <svg viewBox='0 0 16 16' {...props}>
    <g fillRule='nonzero' fill={active ? '#038ab7' : '#7A8E96'}>
      <path d='M8.532 0C7.358 0 6.404.936 6.404 2.087c0 1.15.954 2.087 2.128 2.087 1.174 0 2.129-.936 2.129-2.087C10.66.937 9.706 0 8.532 0zm0 3.13a1.055 1.055 0 0 1-1.064-1.043c0-.575.477-1.044 1.064-1.044.587 0 1.064.469 1.064 1.044 0 .575-.477 1.043-1.064 1.043zM10.129 7.652H6.936a.527.527 0 0 0-.532.522c0 .288.238.522.532.522h3.193a.527.527 0 0 0 .532-.522.527.527 0 0 0-.532-.522z' />
      <path d='M14.386 8.696c-.27 0-.522.065-.745.18-.604-1.915-2.427-3.31-4.577-3.31H5.871c-.114 0-.23.003-.343.011a4.32 4.32 0 0 0-2.774-1.572.529.529 0 0 0-.607.517v2.787c-.342.415-.61.886-.792 1.387h-.273c-.587 0-1.064.468-1.064 1.043v1.044c0 .575.477 1.043 1.064 1.043h.273a4.752 4.752 0 0 0 1.856 2.34v.79c0 .576.477 1.044 1.064 1.044h1.064c.587 0 1.065-.468 1.065-1.044h2.128c0 .576.477 1.044 1.064 1.044h1.065c.586 0 1.064-.468 1.064-1.044v-.79a4.69 4.69 0 0 0 2.128-3.905c0-.288.24-.522.533-.522.293 0 .532.234.532.522 0 .288.238.522.532.522a.527.527 0 0 0 .532-.522c0-.863-.716-1.565-1.596-1.565zm-3.46 4.729a.52.52 0 0 0-.265.451v1.08H9.596v-.521a.527.527 0 0 0-.532-.522H5.871a.527.527 0 0 0-.532.522v.522H4.275v-1.08a.52.52 0 0 0-.266-.452 3.686 3.686 0 0 1-1.745-2.25.53.53 0 0 0-.515-.392h-.667V9.739h.667a.53.53 0 0 0 .515-.392 3.637 3.637 0 0 1 .814-1.502.516.516 0 0 0 .133-.345V5.183a3.23 3.23 0 0 1 1.624 1.234.536.536 0 0 0 .516.227c.17-.023.346-.035.52-.035h3.193c2.054 0 3.725 1.638 3.725 3.652 0 1.3-.714 2.512-1.863 3.164z' />
      <ellipse cx={4.275} cy={8.696} rx={1} ry={1} />
    </g>
  </svg>
)

const typeToPathKey = {
  Annuity: 'annuity',
  Institutional: 'institutional',
  NonGc: 'non-gc',
  Pension: 'pension',
}

class MobileDrawer extends Component {
  state = { active: false }

  handleOpenDrawer = () => this.setState({ active: true })

  handleCloseDrawer = () => this.setState({ active: false })

  handleRouteClick = path => {
    if (
      matchPath(this.props.location.pathname, {
        path,
        exact: true,
      })
    ) {
      this.props.history.replace(path)
    } else {
      this.props.history.push(path)
    }
    this.handleCloseDrawer()
  }

  handleLogout = () => {
    window.open(`${API_URL.slice(0, -4)}logout`, '_self')
  }

  render() {
    const {
      location,
      store: {
        person,
        spouse,
        primarySSIBenefits,
        spouseSSIBenefits,
        features,
        config,
        currentGoals,
        employerAccounts,
        otherAccounts,
        account: { managedAccount },
        stockOptionsOverview: { hasVested, hasNotVested },
      },
      main: { setDrawer },
    } = this.props

    return (
      <div
        css={css`
          padding-left: 20px;
          @media (min-width: 801px) {
            display: none;
          }
        `}>
        <SVGWrapper
          svg={MenuIcon}
          onClick={this.handleOpenDrawer}
          css={css`
            cursor: pointer;
          `}
        />
        <Drawer
          type='left'
          active={this.state.active}
          nopad
          onOverlayClick={this.handleCloseDrawer}>
          <div
            css={css`
              padding: 20px 40px 20px 20px;
            `}>
            <ArrowBox>
              <div
                css={css`
                  height: 24px;
                  width: 24px;
                  margin-left: auto;
                  cursor: pointer;
                `}
                onClick={this.handleCloseDrawer}>
                <ArrowLeft
                  css={css`
                    height: 100%;
                    width: 100%;
                    fill: #7a8e96;
                  `}
                />
              </div>
            </ArrowBox>

            <div
              css={css`
                padding-top: 48px;
              `}
            />

            <PrimaryBox
              css={css`
                padding-bottom: 16px;
              `}>
              <PrimaryRoute
                active={Boolean(matchPath(location.pathname, { path: '/home' }))}
                onClick={() => this.handleRouteClick('/home')}>
                <div
                  css={css`
                    height: 20px;
                    width: 20px;
                    cursor: pointer;
                    margin-right: 12px;
                  `}>
                  <HomeIcon
                    active={Boolean(matchPath(location.pathname, { path: '/home' }))}
                    css={css`
                      height: 100%;
                      width: 100%;
                      fill: #7a8e96;
                    `}
                  />
                </div>
                <div>Home</div>
              </PrimaryRoute>
            </PrimaryBox>

            <PrimaryBox
              css={css`
                padding-bottom: 16px;
              `}>
              <PrimaryRoute
                active={Boolean(matchPath(location.pathname, { path: '/(overall|spending)' }))}
                onClick={() => this.handleRouteClick('/overall')}>
                <div
                  css={css`
                    height: 20px;
                    width: 20px;
                    cursor: pointer;
                    margin-right: 12px;
                  `}>
                  <HomeIcon
                    active={Boolean(matchPath(location.pathname, { path: '/(overall|spending)' }))}
                    css={css`
                      height: 100%;
                      width: 100%;
                      fill: #7a8e96;
                    `}
                  />
                </div>
                <div>Overall Strategy</div>
              </PrimaryRoute>
              {config.template.overallPageComponent === 'Overall' && (
                <SecondaryRoute
                  onClick={() => {
                    this.handleRouteClick('/overall')
                    setDrawer('edit')
                  }}>
                  Make changes
                </SecondaryRoute>
              )}
              {config.template.overallPageComponent === 'Overall' && (
                <SecondaryRoute
                  onClick={() => {
                    this.handleRouteClick('/overall')
                    setDrawer('compare')
                  }}>
                  Compare scenarios
                </SecondaryRoute>
              )}
            </PrimaryBox>

            {features.enableGoals && (
              <PrimaryBox
                css={css`
                  padding-bottom: 30px;
                `}>
                <PrimaryRoute
                  active={Boolean(matchPath(location.pathname, { path: '/goals', exact: true }))}
                  onClick={() => this.handleRouteClick('/goals')}>
                  <div
                    css={css`
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                      margin-right: 12px;
                    `}>
                    <GoalsIcon
                      active={Boolean(
                        matchPath(location.pathname, { path: '/goals', exact: true })
                      )}
                      css={css`
                        height: 100%;
                        width: 100%;
                      `}
                    />
                  </div>
                  <div>Goals</div>
                </PrimaryRoute>
                <List>
                  <SecondaryRouteWithAmount
                    active={Boolean(
                      matchPath(location.pathname, { path: '/goals/replace-income' })
                    )}
                    onClick={() => this.handleRouteClick('/goals/replace-income')}>
                    <div>Percent of income seeking after retiring</div>
                    <div>{numberToDollars(person.retirementIncomeGoalPct, true)}%</div>
                  </SecondaryRouteWithAmount>
                  {currentGoals.map(goal => (
                    <SecondaryRouteWithAmount
                      key={goal.id}
                      active={Boolean(matchPath(location.pathname, { path: `/goals/${goal.id}` }))}
                      onClick={() => this.handleRouteClick(`/goals/${goal.id}`)}>
                      <div>{goal.name}</div>
                      <div>${numberToDollars(goal.totalRemaining, true)}</div>
                    </SecondaryRouteWithAmount>
                  ))}
                </List>
                {features.allowAddGoals && (
                  <ButtonContainer>
                    <Button onClick={() => this.handleRouteClick('/goals/add')}>
                      + Add a new goal
                    </Button>
                  </ButtonContainer>
                )}
              </PrimaryBox>
            )}

            {features.enableAccounts && (
              <PrimaryBox
                css={css`
                  padding-bottom: 30px;
                `}>
                <PrimaryRoute
                  active={Boolean(matchPath(location.pathname, { path: '/accounts', exact: true }))}
                  onClick={() => this.handleRouteClick('/accounts')}>
                  <div
                    css={css`
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                      margin-right: 12px;
                    `}>
                    <AccountsIcon
                      active={Boolean(
                        matchPath(location.pathname, { path: '/accounts', exact: true })
                      )}
                      css={css`
                        height: 100%;
                        width: 100%;
                      `}
                    />
                  </div>
                  <div>Accounts</div>
                </PrimaryRoute>

                {employerAccounts.length > 0 && (
                  <>
                    <SecondaryLabel>
                      <div>Employer-related accounts</div>
                      <div
                        css={css`
                          font-size: 12px;
                          margin-top: 4px;
                        `}>
                        {features.managedAccountAvailable && managedAccount
                          ? '* ' + config.template.managedAccountLabel
                          : ''}
                      </div>
                    </SecondaryLabel>
                    {employerAccounts
                      .filter(
                        account =>
                          account.type === 'Institutional' || account.employerSponsoredAccount
                      )
                      .map(account => (
                        <SecondaryRouteWithAmount
                          key={account.id}
                          active={Boolean(
                            matchPath(location.pathname, {
                              path: `/accounts/${typeToPathKey[account.type]}/${
                                planTypeIdToAccountKey[account.planType]
                              }/${account.id}`,
                            })
                          )}
                          onClick={() =>
                            this.handleRouteClick(
                              `/accounts/${typeToPathKey[account.type]}/${
                                planTypeIdToAccountKey[account.planType]
                              }/${account.id}`
                            )
                          }>
                          <div>
                            {account.name}
                            {account.type === 'Institutional' &&
                            features.managedAccountAvailable &&
                            managedAccount
                              ? '*'
                              : ''}
                          </div>
                          <div>
                            {account.contributing && (
                              <PiggyBank
                                active={Boolean(
                                  matchPath(location.pathname, {
                                    path: `/accounts/${typeToPathKey[account.type]}/${
                                      planTypeIdToAccountKey[account.planType]
                                    }/${account.id}`,
                                  })
                                )}
                                css={css`
                                  width: 22px;
                                  height: 14px;
                                  display: inline-block;
                                  padding-right: 8px;
                                `}
                              />
                            )}
                            ${numberToDollars(account.balance, true)}
                          </div>
                        </SecondaryRouteWithAmount>
                      ))}

                    {features.stockOptionsEnabled && (hasVested || hasNotVested) && (
                      <SecondaryRouteWithAmount
                        active={Boolean(
                          matchPath(location.pathname, { path: '/accounts/stock-options' })
                        )}
                        onClick={() => this.handleRouteClick('/accounts/stock-options')}>
                        <div>{person.displayName}'s Stock Options</div>
                        <div>
                          {hasVested && <div>Vested</div>}
                          {hasNotVested && <div>Unvested</div>}
                        </div>
                      </SecondaryRouteWithAmount>
                    )}

                    {employerAccounts
                      .filter(
                        account =>
                          account.type !== 'Institutional' && !account.employerSponsoredAccount
                      )
                      .map(account => (
                        <SecondaryRouteWithAmount
                          key={account.id}
                          active={Boolean(
                            matchPath(location.pathname, {
                              path: `/accounts/${typeToPathKey[account.type]}/${
                                planTypeIdToAccountKey[account.planType]
                              }/${account.id}`,
                            })
                          )}
                          onClick={() =>
                            this.handleRouteClick(
                              `/accounts/${typeToPathKey[account.type]}/${
                                planTypeIdToAccountKey[account.planType]
                              }/${account.id}`
                            )
                          }>
                          <div>{account.name}</div>
                          <div>
                            {account.plaidLinked && (
                              <span
                                css={css`
                                  font-size: 14px;
                                  margin: 0 6px;
                                `}>
                                <Link />
                              </span>
                            )}
                            {account.contributing && (
                              <PiggyBank
                                active={Boolean(
                                  matchPath(location.pathname, {
                                    path: `/accounts/${typeToPathKey[account.type]}/${
                                      planTypeIdToAccountKey[account.planType]
                                    }/${account.id}`,
                                  })
                                )}
                                css={css`
                                  width: 22px;
                                  height: 14px;
                                  display: inline-block;
                                  padding-right: 8px;
                                `}
                              />
                            )}
                            ${numberToDollars(account.balance, true)}
                          </div>
                        </SecondaryRouteWithAmount>
                      ))}
                  </>
                )}

                {otherAccounts.length > 0 && (
                  <>
                    <SecondaryLabel>Other types of accounts</SecondaryLabel>
                    {otherAccounts.map(account => (
                      <SecondaryRouteWithAmount
                        key={account.id}
                        active={Boolean(
                          matchPath(location.pathname, {
                            path: `/accounts/${typeToPathKey[account.type]}/${
                              planTypeIdToAccountKey[account.planType]
                            }/${account.id}`,
                          })
                        )}
                        onClick={() =>
                          this.handleRouteClick(
                            `/accounts/${typeToPathKey[account.type]}/${
                              planTypeIdToAccountKey[account.planType]
                            }/${account.id}`
                          )
                        }>
                        <div>{account.name}</div>
                        <div>
                          {account.contributing && (
                            <PiggyBank
                              active={Boolean(
                                matchPath(location.pathname, {
                                  path: `/accounts/${typeToPathKey[account.type]}/${
                                    planTypeIdToAccountKey[account.planType]
                                  }/${account.id}`,
                                })
                              )}
                              css={css`
                                width: 22px;
                                height: 14px;
                                display: inline-block;
                                padding-right: 8px;
                              `}
                            />
                          )}
                          ${numberToDollars(account.balance, true)}
                        </div>
                      </SecondaryRouteWithAmount>
                    ))}
                  </>
                )}

                <SecondaryLabel>Social Security</SecondaryLabel>
                <SecondaryRouteWithAmount
                  css={css`
                    cursor: default;
                  `}>
                  <div>{person.displayName}</div>
                  <div>{_.get(primarySSIBenefits, 'includeSsi', null) ? 'Include' : 'Exclude'}</div>
                </SecondaryRouteWithAmount>
                {person.maritalStatus && person.includeSpouse && (
                  <SecondaryRouteWithAmount
                    css={css`
                      cursor: default;
                    `}>
                    <div>{spouse.firstName}</div>
                    <div>
                      {_.get(spouseSSIBenefits, 'includeSsi', null) ? 'Include' : 'Exclude'}
                    </div>
                  </SecondaryRouteWithAmount>
                )}

                {features.allowAddAccounts && (
                  <ButtonContainer onClick={() => this.handleRouteClick('/accounts/add')}>
                    <Button>+ Add a new account</Button>
                  </ButtonContainer>
                )}
              </PrimaryBox>
            )}

            {features.enableHelp && (
              <PrimaryBox
                css={css`
                  padding-bottom: 20px;
                `}>
                <PrimaryRoute
                  active={Boolean(matchPath(location.pathname, { path: '/help' }))}
                  onClick={() => this.handleRouteClick('/help')}>
                  <div
                    css={css`
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                      margin-right: 12px;
                    `}>
                    <HelpIcon
                      css={css`
                        height: 100%;
                        width: 100%;
                        fill: ${matchPath(location.pathname, { path: '/help' })
                          ? '#038ab7'
                          : '#7a8e96'};
                      `}
                    />
                  </div>
                  <div>Help</div>
                </PrimaryRoute>
              </PrimaryBox>
            )}

            <PrimaryBox
              css={css`
                padding-bottom: 40px;
              `}>
              <PrimaryRoute
                active={Boolean(matchPath(location.pathname, { path: '/profile' }))}
                onClick={() => this.handleRouteClick('/profile')}>
                <div
                  css={css`
                    height: 20px;
                    width: 20px;
                    cursor: pointer;
                    margin-right: 12px;
                  `}>
                  <ProfileIcon
                    css={css`
                      height: 100%;
                      width: 100%;
                      fill: ${matchPath(location.pathname, { path: '/profile' })
                        ? '#038ab7'
                        : '#7a8e96'};
                    `}
                  />
                </div>
                <div>Profile</div>
              </PrimaryRoute>
            </PrimaryBox>

            <PrimaryBox
              css={css`
                padding-bottom: 80px;
              `}>
              <Button onClick={this.handleLogout}>Logout</Button>
            </PrimaryBox>
          </div>
        </Drawer>
      </div>
    )
  }
}

const PrimaryBox = styled.div``

const PrimaryRoute = styled.div`
  color: ${p => (p.active ? '#038ab7' : p.theme.topNavTextColor)};
  background-color: ${p => (p.active ? 'rgba(79,173,204,0.2)' : 'rgba(79,173,204,0)')};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  cursor: pointer;
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-left: -20px;
  transition: color 0.15s, background-color 0.15s;
  &:hover {
    background-color: ${p => !p.active && 'rgba(79, 173, 204, 0.05)'};
  }
`

const SecondaryLabel = styled.div`
  font-size: 14px;
  color: #9eacb0;
  padding: 8px 0px;
`

const SecondaryRoute = styled.div`
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 8px 8px 20px;
  margin-left: -20px;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`

const SecondaryRouteWithAmount = styled.div`
  color: ${p => (p.active ? '#038ab7' : p.theme.topNavTextColor)};
  background-color: ${p => (p.active ? 'rgba(79,173,204,0.2)' : 'rgba(79,173,204,0)')};
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 8px 8px 20px;
  margin-left: -20px;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: color 0.15s, background-color 0.15s;
  & div:nth-of-type(2) {
    text-align: right;
  }
  &:hover {
    background-color: ${p => !p.active && 'rgba(79, 173, 204, 0.05)'};
  }
`

const List = styled.div``

const ButtonContainer = styled.div`
  padding: 20px 0;
`

const Button = styled.button`
  cursor: pointer;
  width: 100%;
  height: 42px;
  border: ${p => p.theme.addButtonBorder};
  border-radius: 4px;
  color: ${p => p.theme.addButtonTextColor};
  background-color: ${p => p.theme.addButtonBgColor};
  transition: color 0.25s, background-color 0.25s, border 0.25s;
  &:hover {
    color: ${p => p.theme.addButtonHoverTextColor};
    background-color: ${p => p.theme.addButtonHoverColor};
  }
  &:active {
    color: ${p => p.theme.addButtonActiveTextColor};
    background-color: ${p => p.theme.addButtonActiveColor};
    border: ${p => p.theme.addButtonActiveBorder};
  }
`

export default withRouter(inject('store', 'main')(observer(MobileDrawer)))
