const onTrack = `
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 19"
  >
    <defs><style>.icon-color-green{fill:#67cd36;}</style></defs>
    <title>On track check-c</title>
    <g id="Symbols">
      <g id="icn_On-track-check" data-name="icn/On-track-check">
        <polygon
          id="Path-2"
          class="icon-color-green"
          points="23 17 23 0 6 0 23 17"
        />
        <polygon
          id="Path-4"
          class="icon-color-green"
          points="0 19 13.4 7 16 9.61 5.41 19 0 19"
        />
      </g>
    </g>
  </svg>
`

export default onTrack
