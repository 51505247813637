import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { API } from '../../../../api'
import { HelpIcon, MultiButtonInput, Spacer, TooltipText, Dropdown } from '../../../../components'
import {
  accountTypeKeyToPlanTypeId,
  getSecurityOptionsList,
  SECURITY_TYPES,
  SECURITY_TYPES_TEXT,
} from '../../../../constants'
import { trackInvestmentsByTaxType } from '../../utils'
import { LightGreyText, SectionBox } from '../../styled'
import SecuritySearch from './SecuritySearch'

const SecuritySelector = props => {
  const { security } = props
  const [securityType, setSecurityType] = useState(null)

  const handleSecuritySelect = async id => {
    // fetch the security by id, then set it through props.newInvestment.setSecurity
    const { setSecurity } = props.newInvestment
    try {
      setSecurity(null)
      const security = await API.get(`security/${id}`)
      setSecurity(security.data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (security) {
      setSecurityType(SECURITY_TYPES_TEXT[security.securityType])
      handleSecuritySelect(security.id)
    }
  }, []) // eslint-disable-line

  const handleSecurityTypeChange = (name, value) => {
    if (value !== securityType) {
      const { setSecurity } = props.newInvestment
      setSecurity(null)
      setSecurityType(value)
    }
  }

  const notInvestedOptionsFunc = () => {
    return props.account
      .notInvestedInvestmentsByType(SECURITY_TYPES[securityType])
      .map(investment => ({
        value: investment.securities.id,
        label: investment.securities.securityName,
      }))
  }

  const { accountType } = props.match.params
  const notInvestedOptions = notInvestedOptionsFunc()
  const showNotInvestedOptions =
    trackInvestmentsByTaxType(accountType) && notInvestedOptions.length > 0

  return (
    <div
      css={css`
        max-width: 540px;
      `}>
      <MultiButtonInput
        buttons={[
          { text: 'Stock', value: 'Stock' },
          { text: 'Mutual Fund', value: 'Mutual Fund' },
          { text: 'ETF', value: 'ETF' },
          { text: 'Bond', value: 'Bond' },
          { text: 'Cash', value: 'Cash' },
          { text: 'Other', value: 'Other' },
        ]}
        buttonsPerRow={3}
        error=''
        label='Investment type'
        name='selectedSecurityType'
        onChange={(name, value) => handleSecurityTypeChange(name, value)}
        showError={false}
        tooltip={TooltipText.investmentTypes()}
        value={securityType}
      />

      <Spacer space='15px' />

      {securityType !== null && (
        <div>
          <LightGreyText>Find investment</LightGreyText>

          <span
            css={css`
              padding-left: 10px;
            `}>
            <HelpIcon tooltip={TooltipText.findInvestment()} />
          </span>

          <Spacer space='5px' />

          <SectionBox>
            {/* Display if trackInvestmentsByTaxType and Not Invested Investments exist for the same securityType */}
            {showNotInvestedOptions && (
              <div>
                <LightGreyText>
                  Select from a list of other available plan investment options:
                </LightGreyText>
                <Dropdown
                  onChange={(name, value) => handleSecuritySelect(value)}
                  options={notInvestedOptions}
                  placeholder=''
                  selected={_.get(props.newInvestment, 'security.id')}
                  width='100%'
                />
                <Spacer space='15px' />
              </div>
            )}

            {/* Search for the security if 'Stock', 'Mutual Fund', or 'ETF' */}
            {_.includes(['Stock', 'Mutual Fund', 'ETF'], securityType) && (
              <SecuritySearch
                investment={props.newInvestment}
                showOrLabelText={showNotInvestedOptions}
                onChange={(name, value) => handleSecuritySelect(value)}
                securityType={securityType}
              />
            )}

            {/* Show pre-loaded options for 'Bond', 'Cash', or 'Other' */}
            {_.includes(['Bond', 'Cash', 'Other'], securityType) && (
              <div>
                <LightGreyText>
                  {showNotInvestedOptions ? 'Or' : 'Select'} from a list of options related to this
                  investment type:
                </LightGreyText>
                <Dropdown
                  name='OtherOptions'
                  onChange={(name, value) => handleSecuritySelect(value)}
                  options={getSecurityOptionsList(
                    accountTypeKeyToPlanTypeId[accountType],
                    SECURITY_TYPES[securityType]
                  )}
                  selected={_.get(props.newInvestment, 'security.id')}
                  placeholder=''
                  width='100%'
                />
              </div>
            )}
          </SectionBox>
        </div>
      )}
    </div>
  )
}

export default withRouter(inject('newInvestment', 'account')(observer(SecuritySelector)))
