import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'
import dayjs from 'dayjs'
import uuid from 'uuid/v4'

import { FieldContainer, Title, BulletText, CircleBulletText } from '../styled'

function UpdateInformation(props) {
  const {
    store: { accounts, person, spouse, allPensions, goals },
    getInvestments,
  } = props

  const [showGoalWithdrawal, setShowGoalWithdrawal] = useState(false)
  const [showLumpSum, setShowLumpSum] = useState(false)
  const [showOutdated, setShowOutdated] = useState(false)
  const isMounted = useRef(true)

  const currentYearStartOfDate = dayjs().startOf('year').format('YYYY-MM-DD')
  const shortListAccounts = accounts.filter(
    account => account.type === 'Institutional' || account.type === 'NonGc'
  )
  let outdatedAccounts = useMemo(() => [], [])

  const getAllInvestments = useCallback(async () => {
    for (const account of shortListAccounts) {
      const id = account.id
      let type = account.type

      switch (type) {
        case 'Institutional':
          type = 'institutional'
          break
        case 'NonGc':
          type = 'non-gc'
          break
        default:
          break
      }
      const accountInv = await getInvestments({ type, id })

      accountInv.every(investment => {
        if (parseInt(dayjs(investment.dateOfUserInfo).toNow(true)) >= 185) {
          outdatedAccounts.push(toJS(account))
          return false
        } else {
          return true
        }
      })
    }
    if (outdatedAccounts.length > 0) {
      setShowOutdated(true)
    }
  }, [getInvestments, outdatedAccounts, shortListAccounts])

  const showGoalWithdraw = useCallback(() => {
    goals?.every(goal => {
      if (
        goal.disbursements.map(disbursement => {
          if (disbursement.startDate === currentYearStartOfDate) {
            if (!disbursement.paid) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        })
      ) {
        setShowGoalWithdrawal(true)
        return false
      } else {
        return true
      }
    })
  }, [currentYearStartOfDate, goals])

  const showLump = useCallback(() => {
    const pensions = allPensions
      .filter(account => account.paymentType === 'L')
      .filter(account => account.lumpsumreceived === 'N')

    if (person.includeSpouse) {
      const spousePensions = pensions
        .filter(account => account.ownerId === spouse.id)
        .filter(account => account.pensionStartAge <= spouse.age)
      const primaryPensions = pensions
        .filter(account => account.ownerId === person.id)
        .filter(account => account.pensionStartAge <= person.age)

      if (spousePensions.length > 0 || primaryPensions.length > 0) {
        setShowLumpSum(true)
      }
    } else {
      const primaryPensions = pensions.filter(account => account.pensionStartAge === person.age)

      if (primaryPensions.length > 0) {
        setShowLumpSum(true)
      }
    }
  }, [allPensions, person.age, person.id, person.includeSpouse, spouse?.age, spouse?.id])

  useEffect(() => {
    try {
      if (isMounted.current) {
        getAllInvestments()
        showGoalWithdraw()
        showLump()
      }
    } catch (err) {
      console.log(err)
    }

    return () => {
      isMounted.current = false
    }
  }, [getAllInvestments, showGoalWithdraw, showLump])

  const showOutdate = () => {
    return (
      <>
        <BulletText>
          Outdated investment balances—locate your most recent statement or log into each of the
          account below to obtain the latest balances, then log in and let us know
        </BulletText>
        {outdatedAccounts.map(acct => {
          return (
            <CircleBulletText key={uuid()}>
              {acct.name} (
              {acct.participantId === person.id
                ? person.preferredName
                  ? person.preferredName
                  : person.firstName
                : spouse.firstName}
              )
            </CircleBulletText>
          )
        })}
      </>
    )
  }

  return (
    <>
      <FieldContainer>
        {(showGoalWithdrawal || showLumpSum || showOutdated) && (
          <Title>Update information to clear alerts and to be more certain of results</Title>
        )}
        {showGoalWithdrawal && (
          <BulletText>
            Goal withdrawal—when you've withdrawn this year's amount, log in and let us know
          </BulletText>
        )}
        {showLumpSum && (
          <BulletText>
            Lump sum pension payout—when you've received the payout and have deposited it into a
            tax-advantaged account, log in and let us know
          </BulletText>
        )}
        {showOutdated && showOutdate()}
      </FieldContainer>
    </>
  )
}

export default inject('store')(observer(UpdateInformation))
