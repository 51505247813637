import { useLayoutEffect } from 'react'
import { inject, observer } from 'mobx-react'

import CompleteOverallStrategy from './CompleteOverallStrategy'
import SimplifiedOverallStrategy from './SimplifiedOverallStrategy'

function Overall(props) {
  const {
    config,
    config: { isSpendown, spendDownModeling },
  } = props.store
  useLayoutEffect(() => {
    if (isSpendown || spendDownModeling) {
      return props.history.replace('/spending')
    }
  }, []) // eslint-disable-line

  if (isSpendown) {
    return null
  }

  if (config.template.overallPageComponent === 'Overall') {
    return <CompleteOverallStrategy />
  }

  if (config.template.overallPageComponent === 'Simplified') {
    return <SimplifiedOverallStrategy />
  }

  return null
}

export default inject('store')(observer(Overall))
