import { AUTH, API } from '../api'
import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'
const { model, maybeNull, number, string, optional } = types

const LoginAccount = model({
  mobilePhone: maybeNull(string),
  password: maybeNull(string),
  recordKeeperId: maybeNull(number),
  ssn: maybeNull(string),
  twoFactorType: maybeNull(string),
  username: maybeNull(string),
  birthDate: maybeNull(string),
  hireDate: maybeNull(string),
  code: maybeNull(number),
})

const LoginStore = model({
  loginStore: optional(LoginAccount, {}),
  newPassword: maybeNull(string),
})
  .views(self => ({
    get loginAccount() {
      return self.loginStore
    },
  }))
  .actions(self => ({
    setLoginAccount(account) {
      self.loginStore = account
      self.loginStore.username = account.email
    },
    initiateVerificationEmail: flow(function* ({ email, recordKeeperId = 3014 }) {
      // makes it possible to make requests to auth endpoints
      yield AUTH.get('web-security/csrf-token')

      // sends a verification link to the email
      const res = yield AUTH.post('registration/initiate-verification-email', {
        email,
        recordKeeperId,
      })
      return res
    }),
    registerAccount: flow(function* ({
      mobilePhone,
      password,
      recordKeeperId = 3014,
      ssn,
      twoFactorType,
      username,
      birthDate,
      hireDate,
    }) {
      const addDay = hireDate + '-01'
      const formatMobile = mobilePhone
        ? mobilePhone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
        : mobilePhone

      // registers an account
      const res = yield AUTH.post('registration/activate', {
        mobilePhone: formatMobile,
        password,
        recordKeeperId,
        ssn,
        twoFactorType,
        username,
        birthDate,
        hireDate: addDay,
      })
      return res
    }),
    setup2FA: flow(function* ({ twoFactorType = 'email', code }) {
      yield AUTH.post('/two-factor/set-up', {
        twoFactorType,
        code,
      })
    }),
    login: flow(function* ({ email, password, twoFactorType = 'email' }) {
      self.loginStore.twoFactorType = twoFactorType
      yield AUTH.get('web-security/csrf-token')
      const res = yield AUTH.post('/login', {
        username: email,
        password,
        twoFactorType,
      })
      return res
    }),
    // for manual refresh
    initiateLogin2FA: flow(function* (twoFactorType = 'email') {
      yield AUTH.post('/two-factor', {
        twoFactorType,
      })
    }),
    verify2FA: flow(function* ({ twoFactorType = 'email', code }) {
      const res = yield AUTH.post('/two-factor/verify', {
        twoFactorType,
        code,
      })
      return res
    }),
    initiatePasswordReset: flow(function* ({ email }) {
      yield AUTH.get('web-security/csrf-token')
      const res = yield AUTH.post('reset-password/initiate', {
        username: email,
      })
      return res
    }),
    initiateResetPassword2FA: flow(function* ({ twoFactorType = 'email', password, birthDate }) {
      const res = yield AUTH.post('/two-factor', {
        twoFactorType,
      })
      self.loginStore.birthDate = birthDate
      self.newPassword = password
      return res
    }),
    verifyResetPassword2FA: flow(function* ({ code }) {
      const res = yield AUTH.post('/two-factor/verify-reset-password', {
        code,
      })
      return res
    }),
    resetPassword: flow(function* () {
      const res = yield AUTH.post('/reset-password', {
        password: self.newPassword,
        birthDate: self.loginStore.birthDate,
      })
      return res
    }),
    changePassword: flow(function* ({ password, newPassword, _csrf }) {
      yield API.post('/ui/authorize')

      const res = yield AUTH.post('/login/change-password', {
        password,
        newPassword,
        _csrf,
      })
      return res
    }),
    changeUsername: flow(function* ({ email }) {
      yield API.post('/ui/authorize')

      const res = yield AUTH.post('/login/change-username/initiate', {
        username: email,
      })
      return res
    }),
    getLoginEmail: flow(function* () {
      yield API.post('/ui/authorize')

      const res = yield AUTH.get('user')
      const data = _.get(res, 'data.username', '')
      self.loginStore.username = data
      return data
    }),
  }))

export default LoginStore
