import styled from '@emotion/styled'

export const Heading = styled.div`
  color: ${p => p.theme.darkestGray};
  font-weight: 300;
  font-size: 3rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`

export const Text = styled.div`
  color: #3a4d57;
  font-size: 1.125rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  line-height: 1.5;
`

export const SubText = styled.div`
  color: #3a4d57;
  font-size: 0.875rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`

export const SubHeading = styled.div`
  color: #051f2c;
  font-size: 1.375rem;
  font-weight: ${p => (p.fontWeight ? p.fontWeight : 300)};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`

export const OrangeText = styled.span`
  color: #ffa500;
`
