import reduceCSSCalc from 'reduce-css-calc'

const unit = '10px'
const preferredFont = "'Open Sans', 'Helvetica Neue', 'Arial', sans-serif"
const duration = '0.35s'
const curveFastOutSlowIn = 'cubic-bezier(0.4, 0, 0.2, 1)'

const variables = {
  type: {
    baseFontSize: '16px',

    // Used with ds.fontSize('xl') or ds.fs('xl')
    // the values below use modular-scale
    sizes: {
      xs: '12px', // tiny 12px
      s: '14px', // small 14px
      base: '16px', // normal 16px
      m: '18px', // big 18px
      l: '20px',
      xl: '22px',
      xxl: '24px',
    },
  },

  // Z-index
  // Used with `ds.z()`
  zIndex: {
    highest: 300,
    higher: 200,
    high: 100,
    normal: 1,
    low: -100,
    lower: -200,
  },

  // Spacing
  // Used with `ds.spacing()` or `ds.space()`
  spacing: {
    scale: [0, 4, 8, 12, 16, 20],
  },

  unit,
  preferredFont,

  fontWeight: {
    thin: 300,
    normal: 400,
    semiBold: 500,
    bold: 700,
  },

  shadow: {
    2: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
    3: '0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12)',
    4: '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
    6: '0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2)',
    8: '0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
    16: '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    keyUmbraOpacity: 0.2,
    keyPenumbraOpacity: 0.14,
    ambientShadowOpacity: 0.12,
    zdepth1: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)',
    zdepth2: '0 3px 10px rgba(0, 0, 0, 0.16), 0 3px 10px rgba(0, 0, 0, 0.23)',
    zdepth3: '0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23)',
    zdepth4: '0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22)',
    zdepth5: '0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22)',
  },

  animation: {
    duration,
    delay: reduceCSSCalc(`calc(${duration} / 5)`),
    curveFastOutSlowIn,
    curveLinearOutSlowIn: 'cubic-bezier(0, 0, 0.2, 1)',
    curveFastOutLinearIn: 'cubic-bezier(0.4, 0, 1, 1)',
    curveDefault: curveFastOutSlowIn,
  },

  reset: `
    box-sizing: border-box;
    font-family: ${preferredFont};
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-size-adjust: 100%;

    & *,
    & *::after,
    & *::before {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
      text-size-adjust: 100%;
      -webkit-touch-callout: none;
    }`,
}

export default variables
