import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { observer, inject } from 'mobx-react'
import { useEffect } from 'react'
import _ from 'lodash'
import { Card, HelpIcon, TooltipText } from '../../../components'

const ServiceRow = styled.div`
  font-size: 1rem;
  color: #022a3a;
  line-height: 1.35;
  margin: 12px 0;
  display: flex;
  flex-wrap: wrap;

  & > div:nth-of-type(1) {
    flex: 1 0 400px;
  }

  & > div:nth-of-type(2) {
    flex: 1 0 400px;
  }
`

const ServiceButton = styled.button`
  color: #038ab7;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`

function ServicesCard(props) {
  const {
    person,
    account,
    features,
    config,
    getDefaultPayment,
    defaultPaymentInfo,
    subscriptionStatus,
    subscriptionExpiration,
  } = props.store
  const { isRetail } = props

  useEffect(() => {
    getDefaultPayment()
  }, [getDefaultPayment])

  if (
    !features.managedAccountAvailable &&
    !features.autoRiskAdjustEnabled &&
    !features.enablePersonalizedTargetDatePlus &&
    !isRetail
  ) {
    return null
  }

  return isRetail ? (
    <Card title='GuidedChoice services' cardWidth='900px'>
      <ServiceRow>
        <div>Advisory services automatic renewal</div>
        <div
          css={css`
            padding: 0 20px;
          `}>
          {subscriptionStatus === 'active' || subscriptionStatus === 'trialing' ? (
            <>
              Active (automatic renewal on {subscriptionExpiration})
              {defaultPaymentInfo.lastDigits && (
                <>
                  -<ServiceButton onClick={props.showDiscontinueRetail}>Edit</ServiceButton>
                </>
              )}
            </>
          ) : (
            `Active (subscription ends on ${subscriptionExpiration})`
          )}
        </div>
      </ServiceRow>
      <ServiceRow>
        {defaultPaymentInfo.lastDigits ? <div>Update payment information</div> : <div />}
        <div
          css={css`
            padding: 0 20px;
          `}>
          {defaultPaymentInfo.lastDigits
            ? _.capitalize(defaultPaymentInfo.type) + ' ending in ' + defaultPaymentInfo.lastDigits
            : 'Your organization is paying for your subscription'}
        </div>
      </ServiceRow>
    </Card>
  ) : (
    <Card title='GuidedChoice services' cardWidth='900px'>
      <div
        css={css`
          color: #7a8e96;
          font-size: 18px;
          line-height: 1.35;
          margin: 0 0 1.75rem;
        `}>
        {person.displayName}'s investment plan account with {config.template.sponsorName}
      </div>

      {features.managedAccountAvailable && (
        <ServiceRow>
          <div>
            {config.template.managedAccountType}{' '}
            <HelpIcon
              size='mediumLarge'
              tooltip={
                config.template.managedAccountType === 'Managed account'
                  ? TooltipText.managedAccount()
                  : TooltipText.pointInTimeAdvice()
              }
            />
          </div>
          <div
            css={css`
              padding: 0 20px;
            `}>
            {account.managedAccount ? 'Active—' : 'Not enrolled'}
            {account.managedAccount && (
              <ServiceButton onClick={props.showDiscontinue}>Discontinue</ServiceButton>
            )}
          </div>
        </ServiceRow>
      )}

      {features.autoRiskAdjustEnabled && (
        <ServiceRow>
          <div>
            Automatic risk level adjustment{' '}
            <HelpIcon size='mediumLarge' tooltip={TooltipText.autoRiskAdjustment()} />
          </div>
          <div
            css={css`
              padding: 0 20px;
            `}>
            {account.autoRiskLevelAdjustmentStatus ? 'Include' : 'Exclude'}—
            <ServiceButton onClick={props.showAutoRiskLevelAdjust}>Edit</ServiceButton>
          </div>
        </ServiceRow>
      )}

      {features.enablePersonalizedTargetDatePlus && (
        <ServiceRow>
          <div>
            Personalized Target Date+{' '}
            <HelpIcon size='mediumLarge' tooltip={TooltipText.personalizedTargetDatePlus()} />
          </div>
          <div
            css={css`
              padding: 0 20px;
            `}>
            {account.managedAccount ? 'Active—' : 'Not enrolled'}
            {account.managedAccount && (
              <ServiceButton onClick={props.showDiscontinuePTDPlus}>Discontinue</ServiceButton>
            )}
          </div>
        </ServiceRow>
      )}
    </Card>
  )
}

export default inject('store')(observer(ServicesCard))
