import styled from '@emotion/styled'

import { Row, Spacer } from '../../../components'

function Tiers(props) {
  const { contributionTiers } = props
  return contributionTiers.map((tier, index) => (
    <TierItemsBox key={index}>
      <TierTitle>Tier {index + 1}</TierTitle>
      <Row style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <KeyTitle>
          Match per dollar
          <Spacer space='1px' />
          <KeyTitleExample>(e.g. 50% or $0.50)</KeyTitleExample>
        </KeyTitle>
        <Value>
          {tier.percentContribution}% &nbsp; &nbsp; <Or>or</Or> &nbsp; &nbsp; $
          {(tier.percentContribution / 100.0).toFixed(2)}
        </Value>
      </Row>
      <Spacer space='10px' />
      <Row style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <KeyTitle>
          Maximum match percent
          <Spacer space='1px' />
          <KeyTitleExample>(e.g. up to 6%)</KeyTitleExample>
        </KeyTitle>
        <Value>{tier.to}%</Value>
      </Row>
    </TierItemsBox>
  ))
}

export default Tiers

const TierItemsBox = styled.div`
  box-sizing: border-box;
  min-height: 111px;
  max-width: 622px;
  border: ${p => p.theme.actionItemsBoxBorder};
  border-radius: 8px;
  background-color: ${p => p.theme.actionItemsBoxColor};
  padding: 10px;
  align-items: baseline;
  margin: 20px 0;
  margin-left: -20px;
`

const TierTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  padding-bottom: 30px;
  height: 24px;
  font-size: 18px;
  line-height: 1.33;
  font-weight: 600;
`

const KeyTitle = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 18px;
  font-weight: normal;
  flex: 1;
  line-height: 1.33;
`

const KeyTitleExample = styled.div`
  height: 24px;
  color: ${p => p.theme.addButtonActiveColor};
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
`

const Value = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 16px;
  font-weight: normal;
  flex: 1;
  text-align: start;
`

const Or = styled.span`
  color: ${p => p.theme.lightestGray};
  font-size: 18px;
  font-weight: normal;

  line-height: 1.33;
`
