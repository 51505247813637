import styled from '@emotion/styled'
import BGRight from '../../../../assets/images/BGRight.svg'
import logo from '../../../../assets/images/GuidedChoice-logo.png'
import { css } from '@emotion/react'
import { Spacer } from '../../../../components'
import posed from 'react-pose'

const Logo = () => (
  <img
    css={css`
      max-width: 220px;
    `}
    src={logo}
    alt='Logo'
  />
)

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

const Page = styled.div`
  background: url(${BGRight}) no-repeat top right fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  margin: auto;
  padding: 0 20px;
  color: #4a606a;
  font-family: 'Open Sans';
`
Page.Header = styled.div`
  max-width: 990px;
  color: #4a606a;
  font-size: 50px;
  font-weight: 300;
  line-height: 76px;
`
Page.PriceText = styled.span`
  color: #4a606a;
  font-size: 24px;
`
Page.Subheader = styled.div`
  color: #4a606a;
  font-size: 30px;
  line-height: 38px;
`

const Container = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 16px;
  position: relative;
  width: 100%;
  color: #4a606a;
`

Page.Container = ({ header, subheader, children }) => {
  return (
    <Container>
      <LogoContainer>
        <Logo></Logo>
      </LogoContainer>
      {header && <Page.Header>{header}</Page.Header>}
      <Spacer space='26px'></Spacer>
      {subheader && <Page.Subheader>{subheader}</Page.Subheader>}
      {children}
    </Container>
  )
}
Page.Animate = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 20, opacity: 0 },
})
Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`
Page.SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
Page.PriceAction = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 567px;
  margin-bottom: 20px;
`
Page.Price = styled.span`
  color: #022a3a;
  font-size: 36px;
  font-weight: 500;
  &::before {
    color: #7a8e96;
    content: '$';
  }
`
Page.PriceTotalBox = styled.div`
  min-width: 567px;
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 24px 32px;
`
Page.PromotionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
Page.PriceTotalBoxTitle = styled.div`
  color: #038ab7;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
`
Page.PriceTotalBoxAccessInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  line-height: 34px;
`
Page.PriceTotalBoxYearlyPrice = styled.div`
  font-size: 30px;
  line-height: 34px;
  &::before {
    color: #7a8e96;
    content: '$';
  }
`

Page.PromotionButton = styled.button`
  padding: 13px 20px;
  max-width: 170px;
  border-radius: 4px;
  background-color: #4a606a;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;
`
Page.BuyNowButton = styled.button`
  text-align: center;
  font-size: 36px;
  padding: 20px 0;
  width: 100%;
  border-radius: 1px;
  background-color: #038ab7;
  color: #fff;
  border: none;
  cursor: pointer;
`
Page.TextInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 491px;
  padding: 25px;
  box-shadow: 0 2px 2px 0 rgba(74, 96, 106, 0.6);
  background-color: #f0f4f5;
  margin-bottom: 20px;
  height: fit-content;
`
Page.TextInfoTitle = styled.div`
  color: #038ab7;
  font-size: 24px;
  line-height: 46px;
  text-align: center;
`
Page.TextInfoParagraph = styled.div`
  color: #4a606a;
  font-size: 18px;
  line-height: 34px;
`
Page.TextInfoParagraphCentered = styled.div`
  color: #4a606a;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
`
Page.Link = styled.a`
  cursor: pointer;
  color: rgb(31, 151, 190);
  text-decoration: none;
`
Page.FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
Page.FooterNote = styled.span`
  font-size: 16px;
  line-height: 24px;
`
Page.CancelButton = styled.button`
  height: 41px;
  width: 70px;
  background-color: #fff;
  color: #4a606a;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  border: 1px solid #b6c0c4;
  border-radius: 4px;
`

//////////////////////////////////////////////////////////////
// Setup Payment
//////////////////////////////////////////////////////////////
Page.SetupCardBox = styled.div`
  border: 6px solid rgb(196, 215, 224);
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  padding: 41px 44px;
  -webkit-box-align: baseline;
  align-items: baseline;
`
Page.SetupCardSubheader = styled.div`
  font-size: 16px;
  line-height: 26px;
`
Page.SetupCardBoxTitle = styled.div`
  font-size: 36px;
  line-height: 24px;
`
Page.SetupCardCardTypes = styled.img`
  width: 365px;
`
Page.SetupCardBoxWrapper = styled.div`
  display: flex;
`
Page.SetupCardBoxLabel = styled.div`
  flex: 2;
  color: #7a8e96;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
`
Page.SetupCardBoxValue = styled.div`
  padding-left: 10px;
  flex: 3;
  color: #022a3a;
  font-size: 18px;
  line-height: 24px;
`
const FieldStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* Field Label Container */
  & > div:first-of-type {
    flex: 1 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: right;
    margin-right: 40px;
    color: rgb(122, 142, 150);
    font-size: 1.125rem;
    line-height: 1.35;

    /* Field Label */
    & > div:first-of-type {
    }

    /* Field SubLabel (Optional) */
    & > div:nth-of-type(2) {
      font-size: 0.875rem;
    }
  }

  /* Field Input Container */
  & > div:nth-of-type(2) {
    flex: 1 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(74, 96, 106);
    font-size: 1.125rem;
    line-height: 1.35;
    word-break: break-word;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    margin: 20px 0;
    & > div:first-of-type {
      flex: 1 1 100%;
      text-align: left;
      margin: 12px 0;
    }
  }
`
Page.Field = props => {
  const { label, subLabel, children, ...rest } = props
  return (
    <FieldStyles {...rest}>
      <div>
        <div
          css={css`
            line-height: min(54px);
          `}>
          {label}
        </div>
        {subLabel && (
          <div
            css={css`
              line-height: max(10px);
              margin-bottom: 10px;
            `}>
            {subLabel}
          </div>
        )}
      </div>
      <div
        css={css`
          line-height: min(54px);
        `}>
        {children}
      </div>
    </FieldStyles>
  )
}

Page.SetupFooter = styled.div`
  font-size: 18px;
  line-height: 24px;
`

Page.SetupButtonsBox = styled.div`
  display: flex;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'space-between')};
  margin: 60px 0 20px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 40px auto;
  }
`
Page.SetupEmailWrapper = styled.div`
  display: flex;
  align-items: baseline;
`
Page.SetupEmailText = styled.div`
  color: #7a8e96;
  font-size: 18px;
  line-height: 24px;
`

//////////////////////////////////////////////////////////////
// Setup Payment
//////////////////////////////////////////////////////////////
Page.PolicyText = styled.div`
  font-size: 24px;
`

export default Page
