/* eslint-disable-next-line */
/* eslint-disable jsx-a11y/aria-role */

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Portal from './Portal'
import ActivableRenderer from './ActivableRenderer'
import InjectOverlay from './Overlay'

const DialogOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1001;
`
const active_ = props =>
  props.active &&
  css`
    opacity: 1;
    transform: translateY(0%);
  `
const DialogStyled = styled.div`
  background-color: #fdfdf1;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(33, 152, 191, 0.5);
  display: flex;
  flex-direction: column;
  max-width: 96vw;
  opacity: 0;
  overflow: inherit;
  transform: translateY(-4px);
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.07s;
  ${active_};
  width: 60vw;
  max-height: 90vh;
  overflow-y: auto;
  padding: 2rem 1rem 1rem;
  border-radius: 13px;
  @media (max-width: 800px) {
    width: 96vw;
  }
`
const CloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`

const factory = Overlay => {
  const Dialog = props => {
    return (
      <Portal>
        <Overlay
          active={props.active}
          onClick={props.onOverlayClick}
          onEscKeyDown={props.onEscKeyDown}
          onMouseDown={props.onOverlayMouseDown}
          onMouseMove={props.onOverlayMouseMove}
          onMouseUp={props.onOverlayMouseUp}
          css={css`
            z-index: 1000;
          `}
        />
        <DialogOverlay>
          <DialogStyled active={props.active}>
            <CloseButton onClick={props.onOverlayClick}>✖</CloseButton>
            <section role='body'>{props.children}</section>
          </DialogStyled>
        </DialogOverlay>
      </Portal>
    )
  }

  Dialog.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    onEscKeyDown: PropTypes.func,
    onOverlayClick: PropTypes.func,
    onOverlayMouseDown: PropTypes.func,
    onOverlayMouseMove: PropTypes.func,
    onOverlayMouseUp: PropTypes.func,
    type: PropTypes.string,
  }

  Dialog.defaultProps = {
    active: false,
    type: 'normal',
  }

  return ActivableRenderer()(Dialog)
}

const Dialog = factory(InjectOverlay)
export default Dialog
