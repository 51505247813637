import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const {
  unit,
  type: {
    sizes: { s: small },
  },
} = variables
const {
  rt: { white, black },
} = colors

const card = {
  colorWhite: white,
  textOverlay: color.convert(`color(${black} a(35%))`),
  backgroundColor: white,
  paddingSm: reduceCSSCalc(`calc(0.8 * ${unit})`),
  padding: reduceCSSCalc(`calc(1.6 * ${unit})`),
  paddingLg: reduceCSSCalc(`calc(2 * ${unit})`),
  fontSize: small,
}

export default card
