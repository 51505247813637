function MoneyBagFullBlue() {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 88 81'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Group</title>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Artboard'
          transform='translate(-401.000000, -805.000000)'
          stroke='#038AB7'
          strokeWidth='2'>
          <g id='Group' transform='translate(402.000000, 806.000000)'>
            <path
              d='M55.8933922,17.1428571 C55.4581661,17.3034599 54.9504022,17.4640627 54.3701007,17.4640627 L32.3911792,17.4640627 C31.8108777,17.4640627 31.3031138,17.3569942 30.8678876,17.1428571 C18.0287157,24.9053237 0.692206713,42.4110241 0.692206713,53.5461485 C0.692206713,63.8782591 4.899393,70.3559036 14.0391425,75.9234658 C16.7230372,77.5830277 20.1323088,78.4931099 23.6866559,78.4931099 L62.8570109,78.4931099 C66.411358,78.4931099 69.8206296,77.5830277 72.5045243,75.9234658 C81.6442738,70.3023694 85.8514601,63.8247249 85.8514601,53.5461485 C86.0690732,42.4110241 68.8051019,24.9053237 55.8933922,17.1428571 Z'
              id='Shape-Copy-7'
              fill='#FFFFFF'
              fillRule='nonzero'></path>
            <path
              d='M52.9385095,11.4563299 C53.1561225,11.4563299 53.3737356,11.4563299 53.5913487,11.5098642 L62.8036359,5.03221969 C63.5290129,4.49687717 63.3839375,3.80093189 62.4409474,3.47972638 L59.3218266,2.35550709 C58.3788365,2.03430158 56.855545,1.92723307 55.8400172,2.19490433 L51.5602933,3.21205512 C50.5447655,3.42619213 49.0940116,3.26558937 48.2235593,2.83731536 L43.4360715,0.321205512 C42.5656191,-0.107068504 41.1874029,-0.107068504 40.3894883,0.321205512 L35.6020005,2.83731536 C34.7315481,3.26558937 33.2807942,3.47972638 32.2652665,3.21205512 L27.9855425,2.19490433 C26.9700148,1.98076732 25.4467232,2.03430158 24.5037332,2.35550709 L21.3846123,3.47972638 C20.4416223,3.80093189 20.2965469,4.49687717 21.0219239,5.03221969 L30.2342111,11.5098642 C30.4518242,11.4563299 30.6694372,11.4563299 30.8870503,11.4563299 L52.9385095,11.4563299 Z'
              id='Shape-Copy-6'
              fill='#FFFFFF'
              fillRule='nonzero'></path>
            <path
              d='M54.1773458,16.0860514 C54.7576473,16.0860514 55.2654112,15.9789828 55.7006374,15.7648458 C56.6436274,15.3901061 57.2964667,14.6406265 57.2964667,13.7305443 C57.2964667,12.606325 56.2809389,11.6962427 54.830185,11.4821057 C54.612572,11.4285714 54.3949589,11.4285714 54.1773458,11.4285714 L32.1984244,11.4285714 C31.9808113,11.4285714 31.7631982,11.4285714 31.5455851,11.4821057 C30.1673689,11.6962427 29.0793035,12.606325 29.0793035,13.7305443 C29.0793035,14.5870923 29.7321427,15.3365718 30.6751328,15.7648458 C31.1103589,15.9254486 31.6181228,16.0860514 32.1984244,16.0860514 L54.1773458,16.0860514 Z'
              id='Shape-Copy-5'
              fill='#FFFFFF'
              fillRule='nonzero'></path>
            <path
              d='M79.0295747,51.4285714 C79.0295747,59.8869832 75.9829915,65.0262714 68.2214582,69.7372856 C66.7707043,70.5938336 64.957262,71.0756419 63.1438196,71.0756419'
              id='Shape-Copy-4'
              fill='#FFFFFF'
              fillRule='nonzero'></path>
            <path
              d='M34.7567228,55.0381092 C34.7567228,58.5049016 38.7201335,61.3443696 43.6627398,61.3443696 C48.5587177,61.3443696 52.5687567,58.5379187 52.5687567,55.0381092 C52.5687567,48.7318488 36.202202,48.7318488 36.202202,42.4255884 C36.202202,39.5861203 38.7667619,37.1428571 43.6627398,37.1428571 C48.5587177,37.1428571 51.1232775,39.7842228 51.1232775,42.4255884'
              id='Shape-Copy-3'></path>
            <line
              x1='43.2728519'
              y1='67.9521981'
              x2='43.2728519'
              y2='62.8571429'
              id='Shape-Copy-2'></line>
            <line
              x1='43.2728519'
              y1='36.5236267'
              x2='43.2728519'
              y2='31.4285714'
              id='Shape-Copy'></line>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MoneyBagFullBlue
