import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Spacer, Loading } from '../../../components'
import { buildInitialContributionDistribution } from '../../../utils'
import StrategyResultsSection from './StrategyResults'
import StrategySeeksSection from './StrategySeeks'
import InvestmentAccountsSection from './InvestmentAccounts'
import HowToGetThereSection from './HowToGetThere'
import EditDrawer from '../make-changes/EditDrawer'
import CompareDrawer from '../compare-scenarios/CompareDrawer'
import SaveScenario from './SaveScenario'

const RightSection = styled.div`
  flex: 0 1 425px;
  margin-bottom: ${p => (p.marginBottom ? p.marginBottom : '24px')};
`

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const SubHeader = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  padding-bottom: 8px;
  line-height: 1.25;
`

const Container = styled.div`
  border: 1px solid #b6c0c4;
  background-color: #ffffff;
  padding: 2rem;
  min-width: 325px;
  max-width: 1200px;
  margin: auto;
`

const ModuleName = styled.div`
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 2.25rem;
  color: ${p => p.theme.darkestGray};
  padding-bottom: 2rem;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 1.75rem 0;
`

const ButtonBox = styled.div`
  width: 240px;
  border-radius: 4px;
  background: ${p => p.theme.buttonColor};
  cursor: pointer;
  padding: 8px;
  margin: 12px;
  &:hover {
    background: ${p => p.theme.hoverButtonColor};
  }
  &:active {
    background: ${p => p.theme.activeButtonColor};
  }
`

const ButtonTopText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 1.375rem;
  user-select: none;
`

const ButtonBottomText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 0.875rem;
  user-select: none;
`

const SecondaryButton = styled.div`
  width: 240px;
  border-radius: 4px;
  background: #4a606a;
  cursor: pointer;
  padding: 8px;
  margin: 12px;
  &:hover {
    background: #42565e;
  }
  &:active {
    background: #324147;
  }
  & > div {
    color: #ffffff;
    text-align: center;
    font-size: 1.375rem;
    user-select: none;
    padding: 8px 0;
  }
`

class ScenarioPlanningModule extends Component {
  state = {
    drawer: '',
    acceptLoading: false,
    acceptError: '',
  }

  handleCompare = () => this.setState({ drawer: 'compare' })

  handleEdit = () => this.setState({ drawer: 'edit' })

  resetDrawer = () => {
    this.setState({ drawer: '' })
    this.scenarioNode.scrollIntoView()
  }

  handleImplement = () => {
    this.setState({ acceptLoading: true, acceptError: '' })
    const {
      institutional,
      modifiedCase,
      recommendedCase,
      setAcceptedCaseId,
      setAcceptedContributionDistribution,
    } = this.props.store

    const acceptedCase = modifiedCase !== null ? modifiedCase : recommendedCase
    const caseId = acceptedCase.caseId

    // Set the Accepted Case ID and Accepted Contribution in the Store and push them to the Offboarding process
    // TO-DO: Remove the acceptLoading and acceptError references as they shouldn't be relevant anymore
    try {
      setAcceptedCaseId(caseId)
      setAcceptedContributionDistribution(
        buildInitialContributionDistribution({ acceptedCase, institutional })
      )
      this.props.history.push('/implement')
    } catch (err) {
      this.setState({
        acceptLoading: false,
        acceptError: 'Something went wrong, please refresh or try again later',
      })
      console.error(err)
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { strategyActionQueue: pushedDrawer, setDrawer } = nextProps.main
    const { strategyActionQueue: currentDrawer } = this.state
    if (pushedDrawer !== currentDrawer && pushedDrawer === 'edit') {
      this.resetDrawer()
      setDrawer('')
      this.handleEdit()
    }
    if (pushedDrawer !== currentDrawer && pushedDrawer === 'compare') {
      this.resetDrawer()
      setDrawer('')
      this.handleCompare()
    }
  }

  componentDidMount() {
    const { strategyActionQueue: pushedDrawer, setDrawer } = this.props.main
    const { strategyActionQueue: currentDrawer } = this.state
    const { isRetail } = this.props.store.config

    if (pushedDrawer !== currentDrawer && pushedDrawer === 'edit') {
      this.resetDrawer()
      setDrawer('')
      this.handleEdit()
      this.scenarioNode.scrollIntoView()
    }
    if (pushedDrawer !== currentDrawer && pushedDrawer === 'compare') {
      this.resetDrawer()
      setDrawer('')
      this.handleCompare()
      this.scenarioNode.scrollIntoView()
    }
    if (this.props.location?.from ?? '' === 'home') {
      if (isRetail) {
        this.scenarioNode.scrollIntoView()
      } else {
        this.handleEdit()
      }
    }
  }

  render() {
    const { store, displayInflation } = this.props
    const {
      modifiedCase,
      sortedAccounts,
      config: { isSpendown, isRetail },
    } = store

    return (
      <Container ref={node => (this.scenarioNode = node)}>
        <ModuleName>Scenario Planning</ModuleName>
        <SectionRow>
          <StrategyResultsSection displayInflation={displayInflation} />
          <StrategySeeksSection />
        </SectionRow>

        <Spacer space='24px' />

        <SectionRow>
          <InvestmentAccountsSection displayInflation={displayInflation} />
          <RightSection>
            <HowToGetThereSection />
            {modifiedCase && <SaveScenario />}
          </RightSection>
        </SectionRow>

        <Spacer space='24px' />

        <SubHeader>Choose your next step</SubHeader>
        {!!this.state.acceptError && (
          <div
            css={css`
              padding-top: 28px;
              color: #e31e27;
              text-align: center;
            `}>
            {this.state.acceptError}
          </div>
        )}
        <ButtonGroup>
          {this.state.acceptLoading ? (
            <Loading />
          ) : (
            <>
              <SecondaryButton onClick={this.handleEdit}>
                <div>Make changes</div>
              </SecondaryButton>

              <SecondaryButton onClick={this.handleCompare}>
                <div>Compare scenarios</div>
              </SecondaryButton>
              {isRetail && !isSpendown && sortedAccounts.length === 0 ? null : (
                <ButtonBox onClick={this.handleImplement}>
                  <ButtonTopText>Review</ButtonTopText>
                  <ButtonBottomText>before implementing</ButtonBottomText>
                </ButtonBox>
              )}
            </>
          )}
        </ButtonGroup>
        <CompareDrawer active={this.state.drawer === 'compare'} handleCancel={this.resetDrawer} />
        <EditDrawer active={this.state.drawer === 'edit'} handleCancel={this.resetDrawer} />
      </Container>
    )
  }
}

export default withRouter(inject('store', 'main')(observer(ScenarioPlanningModule)))
