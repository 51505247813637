import { Component } from 'react'
import styled from '@emotion/styled'

const ErrorMessage = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  padding: 2rem;
`

class ErrorPage extends Component {
  render() {
    return <ErrorMessage>Oops, something went wrong! Please try again.</ErrorMessage>
  }
}

export default ErrorPage
