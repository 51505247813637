import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { SECURITY_TYPES_TEXT } from '../../../../constants'
import { HelpIcon, TooltipText } from '../../../../components'
import { placeholderSymbolReplace, numberToDollars } from '../../../../utils'
import {
  TableTitle,
  TableHeader,
  TableList,
  TableRow,
  SortArrow,
  BalanceLabel,
  Balance,
} from './InstitutionalInvestmentsGroupStyle'
import InvestmentGroupItem from './InvestmentGroupItem'

class InstitutionalInvestmentsGroup extends Component {
  state = { sortBy: 'balance', sortOrder: 'desc' }

  toggleSortOrder = () => {
    let { sortOrder } = this.state
    sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    this.setState({ sortOrder })
  }

  handleSortChange = sortBy => {
    const { sortBy: currentSortBy } = this.state
    if (sortBy === currentSortBy) {
      this.toggleSortOrder()
    } else {
      this.setState({ sortBy, sortOrder: 'desc' })
    }
  }

  sortFunction = investment => {
    const { sortBy } = this.state

    if (sortBy === 'balance') {
      return investment.totalValue
    }

    if (sortBy === 'name') {
      return investment.securities.securityName
    }
  }

  sortActive = (active, sortOrder) => {
    if (!active) {
      return 'neutral'
    }

    if (sortOrder === 'asc') {
      return 'up'
    }

    if (sortOrder === 'desc') {
      return 'down'
    }
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteActive: !this.state.deleteActive,
      status: 'done',
    })
  }

  handleDelete = async () => {
    this.setState({ status: 'loading' })

    try {
      const {
        store,
        match: {
          params: { type, id },
        },
        account: { deleteInvestment },
        investment,
      } = this.props
      await deleteInvestment({ type, id, investment })
      await store.getAccounts()
    } catch (err) {
      console.error(err)
      this.setState({ status: 'error' })
    }
  }

  render() {
    const { sortBy, sortOrder } = this.state
    const {
      store: {
        features: { editInstitutionalAccount },
      },
    } = this.props
    const { title, balanceLabel, balance, investments } = this.props
    const sortedInvestments = _.orderBy(investments, this.sortFunction, [sortOrder])

    return (
      <div>
        <TableTitle>
          <div>
            {title}{' '}
            {title === 'After-tax investments' && <HelpIcon tooltip={TooltipText.costBasis()} />}
            &nbsp;&nbsp;&nbsp;
          </div>
          <BalanceLabel>
            {balanceLabel} <Balance>${numberToDollars(balance)}</Balance>
          </BalanceLabel>
        </TableTitle>

        <TableHeader>
          <div>Investment type</div>
          <div>Ticker</div>
          <div onClick={() => this.handleSortChange('name')}>
            Investment name <SortArrow direction={this.sortActive(sortBy === 'name', sortOrder)} />
          </div>
          <div onClick={() => this.handleSortChange('balance')}>
            Balance <SortArrow direction={this.sortActive(sortBy === 'balance', sortOrder)} />
          </div>
        </TableHeader>

        <TableList>
          {sortedInvestments.map(investment => {
            const {
              totalValue,
              id,
              securities: { securityType, ticker, securityName },
            } = investment

            return (
              <div style={{ cursor: 'pointer' }} key={id}>
                {editInstitutionalAccount ? (
                  <InvestmentGroupItem
                    {...{
                      accountId: this.props.accountId,
                      canEdit: this.props.canEdit,
                      investment,
                      toggleEdit: this.props.toggleEdit,
                    }}
                  />
                ) : (
                  <TableRow key={id}>
                    <div>{SECURITY_TYPES_TEXT[securityType]}</div>
                    <div>{ticker}</div>
                    <div>{placeholderSymbolReplace(securityName)}</div>
                    <div>${numberToDollars(totalValue)}</div>
                  </TableRow>
                )}
              </div>
            )
          })}
        </TableList>
      </div>
    )
  }
}

export default withRouter(inject('store')(observer(InstitutionalInvestmentsGroup)))
