import { types, flow, getRoot } from 'mobx-state-tree'
import _ from 'lodash'

import { API } from '../api'
import {
  planTypeIdToDashboardCategory,
  planTypeIdToAccountKey,
  planTypeToAccountLabel,
} from '../constants'
import { InstitutionalAccount } from './InstitutionalAccount'
import { NonGCAccount } from './NonGCAccount'

const { model, array, optional, maybeNull, number, string, boolean } = types

const mapAccountTypeToAPI = {
  Institutional: 'accounts/institutionalAccount',
  NonGc: 'accounts/nonGCAccount',
  Personal: 'accounts/personal',
  Pension: 'pension',
  Annuity: 'annuity',
}

const GenericAccount = model({
  active: maybeNull(boolean),
  adviced: maybeNull(boolean),
  id: maybeNull(number),
  contributing: maybeNull(boolean),
  contributionEligibility: maybeNull(boolean),
  employerSponsoredAccount: maybeNull(boolean),
  type: maybeNull(string),
  balance: maybeNull(number),
  name: maybeNull(string),
  planType: maybeNull(number),
  participantId: maybeNull(number),
}).views(self => ({
  get plaidLinked() {
    return _.includes(_.get(getRoot(self), 'plaidLinkedAccountIds'), self.id)
  },
}))

const StockOptionsOverview = model({
  hasVested: false,
  hasNotVested: false,
})

const AccountsStore = model('Accounts', {
  active: optional(InstitutionalAccount, { type: 'Institutional' }),
  accounts: array(GenericAccount),
  institutional: optional(InstitutionalAccount, { type: 'Institutional' }),
  nonGC: array(NonGCAccount),
  stockOptionsOverview: optional(StockOptionsOverview, {}),
})
  .views(self => ({
    get filteredAccounts() {
      const {
        person: { id },
        includeSpouseInPlanning,
      } = self
      let genericAccounts
      genericAccounts = self.accounts.map(account => ({
        ...account,
        plaidLinked: account.plaidLinked,
      }))
      genericAccounts = genericAccounts.filter(account => account.active !== false)

      return _.filter(genericAccounts, account =>
        includeSpouseInPlanning ? true : account.participantId === id
      )
    },
    get sortedAccounts() {
      const { person, spouse, includeSpouseInPlanning } = self

      function accountSort(account) {
        // Institutional account
        if (account.type === 'Institutional') return 1

        // Additional employer sponsored accounts (e.g. FRS Pension, special 403b, or special 457)
        if (
          account.employerSponsoredAccount &&
          planTypeIdToAccountKey[account.planType] === 'pension'
        ) {
          return 2
        }

        // Special 457 account
        if (
          planTypeIdToAccountKey[account.planType] === '457' &&
          account.participantId === person.id &&
          account.contributionEligibility === true &&
          account.type === 'NonGc'
        ) {
          return 3
        }

        // Special 403b account
        if (
          planTypeIdToAccountKey[account.planType] === '403b' &&
          account.participantId === person.id &&
          account.contributionEligibility === true &&
          account.type === 'NonGc'
        ) {
          return 4
        }

        // Spouse's Employer account
        if (
          includeSpouseInPlanning &&
          account.participantId === spouse.id &&
          account.contributionEligibility === true &&
          account.type === 'NonGc' &&
          planTypeIdToDashboardCategory[account.planType] === 'employer'
        ) {
          return 5
        }

        // Employer-related accounts
        if (planTypeIdToDashboardCategory[account.planType] === 'employer') return 6

        // Pension accounts
        if (account.type === 'Pension') return 7

        // Other non-employer related accounts
        if (planTypeIdToDashboardCategory[account.planType] === 'other') return 8

        // Annuity accounts
        if (account.type === 'Annuity') return 9

        // Catch-all for any accounts that don't fit the above descriptions
        return 10
      }

      const orderedAccounts = _.orderBy(
        self.filteredAccounts,
        [
          accountSort,
          account => Boolean(account.contributing),
          account => Number(account.balance),
          account => (account.name || '').toLowerCase(),
        ],
        ['asc', 'desc', 'desc', 'asc']
      )

      return orderedAccounts
    },
    get employerAccounts() {
      if (self.config?.isRetail) {
        return _.filter(
          self.sortedAccounts,
          account =>
            account.type === 'Institutional' ||
            account.type === 'Pension' ||
            planTypeIdToDashboardCategory[account.planType] === 'employer'
        ).sort((a, b) => a.participantId - b.participantId)
      }
      return _.filter(
        self.sortedAccounts,
        account =>
          account.type === 'Institutional' ||
          account.type === 'Pension' ||
          planTypeIdToDashboardCategory[account.planType] === 'employer'
      )
    },
    get additionalEmployerSponsoredAccounts() {
      return _.filter(
        self.employerAccounts,
        account => account.type !== 'Institutional' && account.employerSponsoredAccount
      )
    },
    get employerAccountsBalance() {
      const total = self.employerAccounts.reduce((result, account) => {
        return account.type === 'Pension' ? result : result + Math.round(account.balance)
      }, 0)
      return total
    },
    get otherAccounts() {
      const other = _.filter(
        self.sortedAccounts,
        account => planTypeIdToDashboardCategory[account.planType] === 'other'
      )

      const annuity = _.filter(self.sortedAccounts, account => account.type === 'Annuity')

      return other.concat(annuity)
    },
    get otherAccountsBalance() {
      const total = self.otherAccounts.reduce((result, account) => {
        return account.type === 'Annuity' ? result : result + Math.round(account.balance)
      }, 0)
      return total
    },
    get accountsBalance() {
      return self.employerAccountsBalance + self.otherAccountsBalance
    },
    get institutionalAccount() {
      return self.sortedAccounts.find(
        account => account.employerSponsoredAccount && account.type === 'Institutional'
      )
    },
    get activeAccount() {
      if (self.institutional.active) {
        return self.institutional
      }
      return self.active
    },
    get isInstitutional() {
      return self.sortedAccounts.slice().filter(account => account.type === 'Institutional').length
    },
    get spouseHasEmployer() {
      const spouse = self.spouse
      return !!_.find(
        self.employerAccounts.slice(),
        account =>
          account.participantId === spouse.id &&
          account.contributionEligibility === true &&
          account.type === 'NonGc'
      )
    },
    get spouseEmployerAccount() {
      const spouse = self.spouse
      return _.find(
        self.employerAccounts.slice(),
        account =>
          account.participantId === spouse.id &&
          account.contributionEligibility === true &&
          account.type === 'NonGc'
      )
    },
    get spouseEmployerAccountTypeLabel() {
      if (self.spouseEmployerAccount) {
        return planTypeToAccountLabel[self.spouseEmployerAccount.planType] || ''
      }
      return ''
    },
    get spouseAccountName() {
      const spouse = self.spouse
      const foundAccount = _.find(
        self.employerAccounts.slice(),
        account =>
          account.participantId === spouse.id &&
          account.contributionEligibility === true &&
          account.type === 'NonGc'
      )
      if (foundAccount) {
        return foundAccount.name || ''
      }
      return ''
    },
    get special457() {
      const person = self.person
      return _.find(
        self.sortedAccounts.slice(),
        account =>
          planTypeIdToAccountKey[account.planType] === '457' &&
          account.participantId === person.id &&
          account.contributionEligibility === true &&
          account.type === 'NonGc'
      )
    },
    get special457Name() {
      return self.special457 ? self.special457.name : ''
    },
    get special403b() {
      const person = self.person
      return _.find(
        self.sortedAccounts.slice(),
        account =>
          planTypeIdToAccountKey[account.planType] === '403b' &&
          account.participantId === person.id &&
          account.contributionEligibility === true &&
          account.type === 'NonGc'
      )
    },
    get special403bName() {
      return self.special403b ? self.special403b.name : ''
    },
    get otherInvestmentAccounts() {
      return self.sortedAccounts.filter(account => account.type === 'NonGc')
    },
    get existingAccountNames() {
      return self.accounts.map(account => account.name)
    },
    // Computed values from this section used in the Guide
    get advicedNonGCAccounts() {
      return _.filter(self.nonGC, account => account.adviced === true)
    },
    get spouseIncludedAccount() {
      const { includeSpouse } = self.person
      return _.find(
        self.advicedNonGCAccounts,
        account => includeSpouse && _.get(self.spouseEmployerAccount, 'id') === account.id
      )
    },
    get special457IncludedAccount() {
      const { includeSpouse } = self.person
      return _.find(
        self.advicedNonGCAccounts,
        account => includeSpouse && _.get(self.special457, 'id') === account.id
      )
    },
    get special403bIncludedAccount() {
      const { includeSpouse } = self.person
      return _.find(
        self.advicedNonGCAccounts,
        account => includeSpouse && _.get(self.special403b, 'id') === account.id
      )
    },
    get nonEmployerAdvicedAccountList() {
      return _.orderBy(
        _.filter(
          [...self.advicedNonGCAccounts],
          account =>
            account.type === 'NonGc' &&
            _.get(self.spouseIncludedAccount, 'id') !== account.id &&
            _.get(self.special457IncludedAccount, 'id') !== account.id &&
            _.get(self.special403bIncludedAccount, 'id') !== account.id
        ),
        [
          obj => (obj.type || '').toLowerCase(),
          obj => (obj.balance === null ? 0 : obj.balance),
          obj => (obj.name || '').toLowerCase(),
        ],
        ['asc', 'desc', 'asc']
      )
    },
    get otherIncludedAccounts() {
      return _.filter([
        self.spouseIncludedAccount || null,
        self.special457IncludedAccount || null,
        self.special403bIncludedAccount || null,
        ...self.nonEmployerAdvicedAccountList,
      ])
    },
    get includedSpouseEmployerAccount() {
      const { includeSpouse } = self.person
      const { id } = self.spouse
      if (includeSpouse) {
        return _.find(self.nonGC, account => {
          return (
            account.participantId === id &&
            account.contributionEligibility &&
            planTypeIdToDashboardCategory[account.planType] === 'employer'
          )
        })
      }
      return null
    },
    get isSpouseContributingButExcludeAdvice() {
      return (
        self.includedSpouseEmployerAccount &&
        self.includedSpouseEmployerAccount.adviced === false &&
        self.includedSpouseEmployerAccount.contributionEligibility
      )
    },
    get totalBalanceExcludingPensionAndAnnuity() {
      let total = 0
      self.filteredAccounts.forEach(obj => {
        if (obj.planType !== 24 && obj.planType !== 25) {
          total += obj.balance
        }
      })
      return total
    },
    get totalPrimaryBalanceExcludingPensionAndAnnuity() {
      const person = self.person
      let total = 0
      self.sortedAccounts.forEach(obj => {
        if (obj.participantId === person.id && obj.planType !== 24 && obj.planType !== 25) {
          total += obj.balance
        }
      })
      return total
    },
    get totalSpouseBalanceExcludingPensionAndAnnuity() {
      const spouse = self.spouse
      let total = 0
      self.sortedAccounts.forEach(obj => {
        if (obj.participantId === spouse.id && obj.planType !== 24 && obj.planType !== 25) {
          total += obj.balance
        }
      })
      return total
    },
    get otherContributingAccountsWithoutClientSpouseEmployerBrokerage() {
      const { id: personId } = self.person
      const accountList = _.filter(self.accounts, account => {
        return (
          !(account.id === self.institutional.id) &&
          !(account.participantId === self.spouse.id && account.contributionEligibility) &&
          !(account.planType === 15) &&
          !!account.contributing
        )
      })

      return _.orderBy(
        accountList,
        [obj => obj.participantId === personId, obj => (obj.name || '').toLowerCase()],
        ['desc', 'asc']
      )
    },
    get otherIncludedAccountsWithoutSpouseEmployer() {
      const nonGcWithoutSpouse = _.orderBy(
        _.filter(self.advicedNonGCAccounts.slice(), account => {
          return !(account.participantId === self.spouse.id && account.contributionEligibility)
        }),
        [obj => (obj.name || '').toLowerCase()],
        ['asc']
      )
      return [...nonGcWithoutSpouse]
    },
    findAccount(type, id) {
      const found = self.accounts.find(account => {
        return account.type === type && account.id === id
      })
      return found
    },
    get anotherRetirementAccountExists() {
      const activelyContributingAccount = self.accounts.some(
        account =>
          account.participantId === self.person.id &&
          account.contributionEligibility &&
          account.type !== 'Institutional'
      )
      return activelyContributingAccount && self.institutionalAccountUnderReview
    },
    get anotherRetirementAccount() {
      const activelyContributingAccount = self.accounts.filter(
        account => account.contributionEligibility && account.type !== 'Institutional'
      )
      return activelyContributingAccount.length > 0 ? activelyContributingAccount[0] : null
    },
  }))
  .actions(self => ({
    initializeActiveAccount: flow(function* () {
      yield Promise.all([
        self.getInstitutionalAccount(),
        self.getNonGCAccounts(),
        getRoot(self).getPerson(),
      ])
      if (self.institutional.active) {
        self.active = self.institutional
        return self.institutional
      } else {
        const account401KOr401A = self.nonGC.filter(
          account =>
            account.contributionEligibility &&
            account.participantId === getRoot(self).person.id &&
            (account.planType === 1 || account.planType === 23)
        )[0]
        return self.convertToActiveAccount(account401KOr401A || null)
      }
    }),
    getAccounts: flow(function* () {
      const isSpendown = _.get(getRoot(self), 'config.isSpendown', null)
      let Overview
      if (!isSpendown) {
        Overview = yield API.get('accounts/uiAccounts')
      }
      if (isSpendown) {
        Overview = yield API.get('accounts/uiAccounts/spending')
      }
      const { accountsOverview, stockOptionsOverview } = Overview.data
      self.accounts = accountsOverview
      self.stockOptionsOverview = stockOptionsOverview
    }),
    getInstitutionalAccount: flow(function* () {
      const institutional = yield API.get('accounts/institutionalAccount')
      self.institutional = institutional.data
    }),
    getNonGCAccounts: flow(function* () {
      const nonGC = yield API.get('accounts/nonGCAccount')
      self.nonGC = nonGC.data
    }),
    deleteAccount: flow(function* (type, id) {
      const foundAccount = self.findAccount(type, id)
      if (foundAccount) {
        yield API.delete(`${mapAccountTypeToAPI[type]}/${id}`)
        self.accounts.remove(foundAccount)
      }
    }),
    convertToActiveAccount(account) {
      self.active = { ...account, type: 'Institutional', active: true, rateChangeAllowed: true } // TODO: remove rateChangeAllowed once it's fixed on the backend
      return InstitutionalAccount.create({
        ...account,
        type: 'Institutional',
        employerSponsoredAccount: true,
      })
    },
  }))

export default AccountsStore
