import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import Cookies from 'js-cookie'

import { API, API_URL } from '../../../api'
import { WizardStep, Spacer, Loading } from '../../../components'
import { Heading, Text } from '../elements/styled'
import { ButtonGroup, IndentContainer } from './ConfirmationStyle'
import { CancelButton, SecondaryButton, PrimaryButton } from './ConfirmationButtons'

class Confirmation extends Component {
  state = { status: 'done' }

  confirmationConfig = () => {
    const { config, acceptedCaseId } = this.props.store
    const { email } = this.props.offboarding

    const templates = {
      1: config.canTransact
        ? {
            paragraphForm: (
              <div>
                <form
                  action={`${API_URL.slice(0, -4)}florida/transaction`}
                  method='post'
                  ref={form => {
                    this.transactionForm = form
                  }}
                  css={css`
                    display: none;
                  `}>
                  <input type='number' name='caseId' value={acceptedCaseId} readOnly />
                  <input type='text' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
                  <input type='text' name='email' value={email} readOnly />
                </form>
                <Text>
                  Choose to implement your new investment mix below and you'll be one step closer to
                  having the right allocation working for you. Final confirmation of your{' '}
                  {config.template.planName} changes take place through your plan administrator. You
                  will be redirected automatically if you choose to finalize. Otherwise, we will
                  return you to your Overall Strategy for further planning or where you may log off.
                </Text>
              </div>
            ),
            buttons: ['cancel', 'emailOnly', config.canSubmitTrans ? 'finalize' : null],
            shrinkButtonGroup: false,
          }
        : {
            paragraphForm: (
              <Text>
                Download your guide and view it right away! When you are ready, click Done and we
                will return you to your Overall Strategy for further planning or where you may log
                off.
              </Text>
            ),
            buttons: ['download', 'done'],
            shrinkButtonGroup: true,
          },

      2: {
        paragraphForm: (
          <Text>
            Download your guide and view it right away! When you are ready, click Done and we will
            return you to your Overall Strategy for further planning or where you may log off.
          </Text>
        ),
        buttons: ['download', 'done'],
        shrinkButtonGroup: true,
      },
    }

    return templates[config.templateId]
  }

  handleImplementAndEmailClick() {
    // make API call to email guide and redirect to Alight
    this.transactionForm.submit()
  }

  handleEmailOnlyClick() {
    const { acceptedCaseId } = this.props.store
    const { email } = this.props.offboarding
    // make API call to email guide
    this.setState({ status: 'loading' })
    API.post('guide/email', null, {
      params: {
        caseId: acceptedCaseId,
        email,
      },
    })
      .then(res => {
        this.props.history.push('/overall')
      })
      .catch(err => {
        this.setState({ status: 'error' })
        console.error(err)
      })
  }

  handleViewGuideClick() {
    const { acceptedCaseId } = this.props.store
    // this.props.history.push(`/implement/guide/${caseId}`);
    window.open(`${API_URL}guide/generateGuide?caseId=${acceptedCaseId}`, '_blank')
  }

  handleNext() {
    // To fix the undefined error
  }

  handleCancelClick() {
    this.props.history.push('/overall')
  }

  render() {
    const { paragraphForm, buttons, shrinkButtonGroup } = this.confirmationConfig()

    return (
      <WizardStep onNextClick={this.handleNext.bind(this)} showDefaultButtons={false}>
        <Heading>Let's put your plan into action</Heading>

        <Spacer space='24px' />

        <IndentContainer>
          {paragraphForm}
          <Spacer space='16px' />
        </IndentContainer>

        <div
          css={css`
            text-align: center;
            color: crimson;
          `}>
          {this.state.status === 'error' && 'Oops! Something went wrong, please try again later'}
        </div>

        {this.state.status === 'loading' ? (
          <Loading />
        ) : (
          <ButtonGroup shrink={shrinkButtonGroup}>
            {_.includes(buttons, 'cancel') && (
              <CancelButton
                onClick={this.handleCancelClick.bind(this)}
                primaryText='Cancel'
                secondaryText='return to Overall Strategy'
              />
            )}

            {_.includes(buttons, 'emailOnly') && (
              <SecondaryButton
                onClick={this.handleEmailOnlyClick.bind(this)}
                primaryText='Email guide only'
                secondaryText='no changes sent | return to Overall Strategy'
              />
            )}

            {_.includes(buttons, 'finalize') && (
              <PrimaryButton
                onClick={this.handleImplementAndEmailClick.bind(this)}
                primaryText='Finalize'
                secondaryText='with plan admin | email guide'
              />
            )}

            {_.includes(buttons, 'download') && (
              <SecondaryButton
                onClick={this.handleViewGuideClick.bind(this)}
                primaryText='Download | view now'
                secondaryText='opens new tab'
              />
            )}

            {_.includes(buttons, 'done') && (
              <PrimaryButton
                onClick={this.handleCancelClick.bind(this)}
                primaryText='Done'
                secondaryText='return to Overall Strategy'
              />
            )}
          </ButtonGroup>
        )}
      </WizardStep>
    )
  }
}

export default inject('store', 'offboarding')(observer(Confirmation))
