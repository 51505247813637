const bullsEye = `
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="-icons/goals/normal" class="icon-color">
        <path
          d="M6.5,14.5 C3.73857625,14.5 1.5,12.2614237 1.5,9.5 C1.5,6.73857625
            3.73857625,4.5 6.5,4.5 C9.26142375,4.5 11.5,6.73857625 11.5,9.5
            C11.5,12.2614237 9.26142375,14.5 6.5,14.5 Z M6.5,13.5 C8.709139,13.5
            10.5,11.709139 10.5,9.5 C10.5,7.290861 8.709139,5.5 6.5,5.5
            C4.290861,5.5 2.5,7.290861 2.5,9.5 C2.5,11.709139 4.290861,13.5
            6.5,13.5 Z"
          id="Combined-Shape"
        >
        </path>
        <path
          d="M6.5,12.5 C4.84314575,12.5 3.5,11.1568542 3.5,9.5 C3.5,7.84314575
            4.84314575,6.5 6.5,6.5 C8.15685425,6.5 9.5,7.84314575 9.5,9.5 C9.5,
            11.1568542 8.15685425,12.5 6.5,12.5 Z M6.5250001,11.4000001
            C7.560534,11.4000001 8.4000001,10.560534 8.4000001,9.5250001
            C8.4000001,8.48946619 7.560534,7.6500001 6.5250001,7.6500001
            C5.48946619,7.6500001 4.6500001,8.48946619 4.6500001,9.5250001
            C4.6500001,10.560534 5.48946619,11.4000001 6.5250001,11.4000001 Z"
          id="Combined-Shape"
        >
        </path>
        <circle id="Oval" cx="6.5" cy="9.5" r="1"></circle>
        <polygon
          id="Rectangle"
          transform="
            translate(8.828427, 7.328427)
            rotate(-45.000000)
            translate(-8.828427, -7.328427)
          "
          points="5.32842712 6.82842712 12.3284271 6.82842712 12.3284271
            7.56586399 5.32842712 7.56586399"
        >
        </polygon>
        <path
          d="M10.1000004,3.50056057 L13.0188985,0.5 L13.5770949,2.30815962
            L15.3500004,2.90850038 L12.4428742,5.79356564 L10.6510745,5.21104588
            L10.1000004,3.50056057 Z M10.8999996,3.70049496 L11.2123075,
            4.66987105 L12.2277666,5 L13.8753098,3.3649592 L12.8705587,
            3.02473063 L12.5542144,2 L10.8999996,3.70049496 Z"
          id="Combined-Shape"
        >
        </path>
      </g>
    </g>
  </svg>
`

export default bullsEye
