import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import dayjs from 'dayjs'

import { Card, CurrencyText, HelpIcon, TooltipText } from '../../../components'
import { Page } from '../elements'

class ViewGoal extends Component {
  render() {
    const { foundGoal, toggleEdit } = this.props
    const { name, totalRemaining, disbursements } = foundGoal

    const NameField = () => (
      <Page.ViewRow justifyContent='center' flex>
        <Page.ViewLabel>Goal name</Page.ViewLabel>
        <Page.ViewValueBox>
          <Page.Text>{name}</Page.Text>
        </Page.ViewValueBox>
        <Page.ViewLabelWithdrawn />
      </Page.ViewRow>
    )

    const AmountField = ({ amount, year, paid }) => (
      <Page.ViewRow>
        <Page.ViewLabel>{year}</Page.ViewLabel>
        <Page.ViewValueBox>
          <CurrencyText value={amount} />
        </Page.ViewValueBox>
        <Page.ViewLabelWithdrawn>{paid ? 'Yes' : 'No'}</Page.ViewLabelWithdrawn>
      </Page.ViewRow>
    )

    const WithdrawnHeader = () => (
      <Page.ViewRow>
        <Page.ViewLabel />
        <Page.ViewValueBox />
        <Page.ViewLabelWithdrawn>
          Money withdrawn?
          <Page.SVGHelpContainer>
            <HelpIcon size='medium' tooltip={TooltipText.hasAmountBeenWithdrawn()} />
          </Page.SVGHelpContainer>
        </Page.ViewLabelWithdrawn>
      </Page.ViewRow>
    )

    const FutureAmountTotal = () => (
      <Page.ViewRow>
        <Page.ViewLabel>Future total needed</Page.ViewLabel>
        <Page.ViewValueBox>
          <CurrencyText value={totalRemaining} />
        </Page.ViewValueBox>
        <Page.ViewLabelWithdrawn />
      </Page.ViewRow>
    )

    return (
      <Page.Container>
        <Page.MainTitle>{`Goal: ${name}`}</Page.MainTitle>
        <Card title='Goal details' handleEdit={toggleEdit}>
          <NameField />
          <FutureAmountTotal />

          <WithdrawnHeader />
          {disbursements.map(({ amount, startDate, paid }) => (
            <AmountField
              key={startDate}
              amount={amount}
              year={dayjs(startDate).year()}
              paid={paid}
            />
          ))}
        </Card>
      </Page.Container>
    )
  }
}

export default inject('store')(observer(ViewGoal))
