const circleX = `
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <title>close-normal-c</title>
    <path
      class="icon-color"
      d="M21.6,12A9.6,9.6,0,0,0,2.4,12M0,12a12,12,0,0,1,24,0M2.4,12a9.6,9.6,0,
      0,0,19.2,0M24,12A12,12,0,0,1,0,12m10.73,0.15L7.29,15.59,8.7,
      17l3.44-3.44L15.58,17,17,15.59l-3.44-3.44L17,8.71,15.58,7.29l-3.44,
      3.44L8.7,7.29,7.29,8.71Z"
    />
  </svg>
`

export default circleX
