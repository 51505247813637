import { useState, useEffect, useCallback } from 'react'
import { Provider, observer, inject } from 'mobx-react'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled from '@emotion/styled'
import _ from 'lodash'
import { useTheme } from '@emotion/react'
import { css } from '@emotion/css'
import { API_URL } from '../../../api'

import NoGuide from './NoGuide'
import CompanyStock from './CompanyStock'
import AcceptRecommendation from './AcceptRecommendation'
import AcceptManagedAccount from './AcceptManagedAccount'
import ImplementChanges from './ImplementChanges'
import Congratulations from './Congratulations'
import ConfirmEmail from './ConfirmEmail'
import Download from './Download'
import SaveEmailDownloadGuide from './SaveEmailDownloadGuide'
import InsuringFinalExpenses from './static-pages/InsuringFinalExpenses'
import ReverseMortgage from './static-pages/ReverseMortgage'
import HandyChecklist from './static-pages/HandyChecklist'
import SpendDownOffboardingStore from './SpendDownOffboardingStore'
import { LogoHeader, Stepper, Page } from '../elements'
import { Loading } from '../../../components'
import GetYourGuide from './spend-down-retail/GetYourGuide'
import SaveModeCongratulationsRetail from './spend-down-retail/Congratulations'

function Dashboard(props) {
  const { location } = props
  const [state, setState] = useState('loading')
  const [spendDownOffboarding] = useState(() =>
    SpendDownOffboardingStore.create({}, { store: props.store })
  )
  const theme = useTheme()

  const initializeSpedDownOffboarding = useCallback(async () => {
    try {
      setState('loading')
      await Promise.all([
        props.store.getPerson(),
        props.store.getSpouse(),
        props.store.getDependents(),
        props.store.getContactInfo(),
        props.store.getDocuments(),
        props.store.getInstitutionalAccount(),
        props.store.getPlanOverview(),
      ])

      spendDownOffboarding.initializeSpedDownOffboarding()

      setState('done')
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }, [props.store, spendDownOffboarding])

  useEffect(() => {
    const spendingAcceptedCase = _.get(props, 'store.spendingAcceptedCase', {})
    if (
      location.pathname !== '/spending/implement/insuring-final-expenses' &&
      location.pathname !== '/spending/implement/reverse-mortgage' &&
      location.pathname !== '/spending/implement/handy-checklist'
    ) {
      if (_.isEmpty(spendingAcceptedCase)) {
        if (_.get(props, 'store.config.spendingMode', null)) {
          return props.history.replace('/spending/finalize-choices')
        }
      }
    }
    initializeSpedDownOffboarding()
    props.main.setSidebar(false)
    props.main.setTopNav(false)

    document.documentElement.className = css`
      background: url(${API_URL + theme.bgLeft}) no-repeat top right fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    `

    return () => {
      props.main.setSidebar(true)
      props.main.setTopNav(true)
      document.documentElement.className = ''
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (state === 'loading') {
    return (
      <div
        css={css`
          margin: 20px;
        `}>
        <Loading />
      </div>
    )
  }

  if (state === 'error') {
    return <Page.ServerError>Oops! Something went wrong, please try again later</Page.ServerError>
  }

  const DetermineInitialPage = () => {
    return (
      <Redirect
        to={
          '/spending/implement/' +
          spendDownOffboarding.spendingRoutes[spendDownOffboarding.currentStep]
        }
      />
    )
  }

  return (
    <Provider spendDownOffboarding={spendDownOffboarding}>
      <Container>
        {location.pathname !== '/spending/implement/insuring-final-expenses' &&
          location.pathname !== '/spending/implement/reverse-mortgage' &&
          location.pathname !== '/spending/implement/handy-checklist' && (
            <>
              <LogoHeader />
              {spendDownOffboarding.enableSpendingStepper && (
                <Stepper
                  maxWidth='100%'
                  currentStep={spendDownOffboarding.activeSpendingStep(
                    props.location.pathname.slice(20)
                  )}
                  totalSteps={spendDownOffboarding.spendingRoutes.map(
                    (path, i) => 'Step ' + (i + 1)
                  )}
                />
              )}
            </>
          )}

        <Switch>
          <Route exact path='/spending/implement/no-guide' component={NoGuide} />
          <Route exact path='/spending/implement/company-stock' component={CompanyStock} />
          <Route
            exact
            path='/spending/implement/accept-recommendation'
            component={AcceptRecommendation}
          />
          <Route
            exact
            path='/spending/implement/accept-managed-account'
            component={AcceptManagedAccount}
          />
          <Route exact path='/spending/implement/implement-changes' component={ImplementChanges} />
          <Route exact path='/spending/implement/congratulations' component={Congratulations} />
          <Route
            exact
            path='/spending/implement/congratulations/2'
            component={SaveModeCongratulationsRetail}
          />
          <Route exact path='/spending/implement/confirm-email' component={ConfirmEmail} />
          <Route exact path='/spending/implement/get-your-guide' component={GetYourGuide} />
          <Route exact path='/spending/implement/download' component={Download} />
          <Route
            exact
            path='/spending/implement/save-email-download-guide'
            component={SaveEmailDownloadGuide}
          />
          <Route
            exact
            path='/spending/implement/insuring-final-expenses'
            component={InsuringFinalExpenses}
          />
          <Route exact path='/spending/implement/reverse-mortgage' component={ReverseMortgage} />
          <Route exact path='/spending/implement/handy-checklist' component={HandyChecklist} />
          <Route component={DetermineInitialPage} />
        </Switch>
      </Container>
    </Provider>
  )
}

export default inject('store', 'main')(observer(Dashboard))
const Container = styled.div`
  max-width: 1200px;
  padding: 8px;
  margin: auto;
`
