import hasOwnProperty from './hasOwnProperty'

const dateLocales = {
  'de': {
    months:
      'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split(
        '_'
      ),
    monthsShort: 'Jan_Feb_März_Apr_Mai_Juni_Juli_Aug_Sept_Okt_Nov_Dez'.split('_'),
    weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
    weekdaysShort: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
    weekdaysLetter: 'S_M_D_M_D_F_S'.split('_'),
  },
  'no': {
    months:
      'januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember'.split(
        '_'
      ),
    monthsShort: 'jan._feb._mars_april_mai_juni_juli_aug._sep._okt._nov._des.'.split('_'),
    weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
    weekdaysShort: 'søn_man_tir_ons_tor_fre_lør'.split('_'),
    weekdaysLetter: [],
  },
  'en': {
    months:
      'January_February_March_April_May_June_July_August_September_October_November_December'.split(
        '_'
      ),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
    weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    weekdaysLetter: [],
  },
  'es': {
    months:
      'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split(
        '_'
      ),
    monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_'),
    weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
    weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
    weekdaysLetter: 'D_L_M_X_J_V_S'.split('_'),
  },
  'af': {
    months:
      'Januarie_Februarie_Maart_April_Mei_Junie_Julie_Augustus_September_Oktober_November_Desember'.split(
        '_'
      ),
    monthsShort: 'Jan_Feb_Mrt_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Des'.split('_'),
    weekdays: 'Sondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrydag_Saterdag'.split('_'),
    weekdaysShort: 'Son_Maa_Din_Woe_Don_Vry_Sat'.split('_'),
    weekdaysLetter: [],
  },
  'ar': {
    months: [
      'كانون الثاني يناير',
      'شباط فبراير',
      'آذار مارس',
      'نيسان أبريل',
      'أيار مايو',
      'حزيران يونيو',
      'تموز يوليو',
      'آب أغسطس',
      'أيلول سبتمبر',
      'تشرين الأول أكتوبر',
      'تشرين الثاني نوفمبر',
      'كانون الأول ديسمبر',
    ],
    monthsShort: [
      'كانون الثاني يناير',
      'شباط فبراير',
      'آذار مارس',
      'نيسان أبريل',
      'أيار مايو',
      'حزيران يونيو',
      'تموز يوليو',
      'آب أغسطس',
      'أيلول سبتمبر',
      'تشرين الأول أكتوبر',
      'تشرين الثاني نوفمبر',
      'كانون الأول ديسمبر',
    ],
    weekdays: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت'.split('_'),
    weekdaysShort: 'أحد_إثنين_ثلاثاء_أربعاء_خميس_جمعة_سبت'.split('_'),
    weekdaysLetter: [],
  },
  'be': {
    months:
      'студзень_люты_сакавік_красавік_травень_чэрвень_ліпень_жнівень_верасень_кастрычнік_лістапад_снежань'.split(
        '_'
      ),
    monthsShort: 'студ_лют_сак_крас_трав_чэрв_ліп_жнів_вер_каст_ліст_снеж'.split('_'),
    weekdays: 'нядзеля_панядзелак_аўторак_серада_чацвер_пятніца_субота'.split('_'),
    weekdaysShort: 'нд_пн_ат_ср_чц_пт_сб'.split('_'),
    weekdaysLetter: [],
  },
  'bg': {
    months:
      'януари_февруари_март_април_май_юни_юли_август_септември_октомври_ноември_декември'.split(
        '_'
      ),
    monthsShort: 'янр_фев_мар_апр_май_юни_юли_авг_сеп_окт_ное_дек'.split('_'),
    weekdays: 'неделя_понеделник_вторник_сряда_четвъртък_петък_събота'.split('_'),
    weekdaysShort: 'нед_пон_вто_сря_чет_пет_съб'.split('_'),
    weekdaysLetter: [],
  },
  'bn': {
    months:
      'জানুয়ারী_ফেবুয়ারী_মার্চ_এপ্রিল_মে_জুন_জুলাই_অগাস্ট_সেপ্টেম্বর_অক্টোবর_নভেম্বর_ডিসেম্বর'.split(
        '_'
      ),
    monthsShort: 'জানু_ফেব_মার্চ_এপর_মে_জুন_জুল_অগ_সেপ্ট_অক্টো_নভ_ডিসেম্'.split('_'),
    weekdays: 'রবিবার_সোমবার_মঙ্গলবার_বুধবার_বৃহস্পত্তিবার_শুক্রবার_শনিবার'.split('_'),
    weekdaysShort: 'রবি_সোম_মঙ্গল_বুধ_বৃহস্পত্তি_শুক্র_শনি'.split('_'),
    weekdaysLetter: [],
  },
  'bo': {
    months:
      'ཟླ་བ་དང་པོ_ཟླ་བ་གཉིས་པ_ཟླ་བ་གསུམ་པ_ཟླ་བ་བཞི་པ_ཟླ་བ་ལྔ་པ_ཟླ་བ་དྲུག་པ_ཟླ་བ་བདུན་པ_ཟླ་བ་བརྒྱད་པ_ཟླ་བ་དགུ་པ_ཟླ་བ་བཅུ་པ_ཟླ་བ་བཅུ་གཅིག་པ_ཟླ་བ་བཅུ་གཉིས་པ'.split(
        '_'
      ),
    monthsShort:
      'ཟླ་བ་དང་པོ_ཟླ་བ་གཉིས་པ_ཟླ་བ་གསུམ་པ_ཟླ་བ་བཞི་པ_ཟླ་བ་ལྔ་པ_ཟླ་བ་དྲུག་པ_ཟླ་བ་བདུན་པ_ཟླ་བ་བརྒྱད་པ_ཟླ་བ་དགུ་པ_ཟླ་བ་བཅུ་པ_ཟླ་བ་བཅུ་གཅིག་པ_ཟླ་བ་བཅུ་གཉིས་པ'.split(
        '_'
      ),
    weekdays: 'གཟའ་ཉི་མ་_གཟའ་ཟླ་བ་_གཟའ་མིག་དམར་_གཟའ་ལྷག་པ་_གཟའ་ཕུར་བུ_གཟའ་པ་སངས་_གཟའ་སྤེན་པ་'.split(
      '_'
    ),
    weekdaysShort: 'ཉི་མ་_ཟླ་བ་_མིག་དམར་_ལྷག་པ་_ཕུར་བུ_པ་སངས་_སྤེན་པ་'.split('_'),
    weekdaysLetter: [],
  },
  'br': {
    months: "Genver_C'hwevrer_Meurzh_Ebrel_Mae_Mezheven_Gouere_Eost_Gwengolo_Here_Du_Kerzu".split(
      '_'
    ),
    monthsShort: "Gen_C'hwe_Meu_Ebr_Mae_Eve_Gou_Eos_Gwe_Her_Du_Ker".split('_'),
    weekdays: "Sul_Lun_Meurzh_Merc'her_Yaou_Gwener_Sadorn".split('_'),
    weekdaysShort: 'Sul_Lun_Meu_Mer_Yao_Gwe_Sad'.split('_'),
    weekdaysLetter: [],
  },
  'bs': {
    months:
      'januar_februar_mart_april_maj_juni_juli_august_septembar_oktobar_novembar_decembar'.split(
        '_'
      ),
    monthsShort: 'jan._feb._mar._apr._maj._jun._jul._aug._sep._okt._nov._dec.'.split('_'),
    weekdays: 'nedjelja_ponedjeljak_utorak_srijeda_četvrtak_petak_subota'.split('_'),
    weekdaysShort: 'ned._pon._uto._sri._čet._pet._sub.'.split('_'),
    weekdaysLetter: [],
  },
  'ca': {
    months:
      'gener_febrer_març_abril_maig_juny_juliol_agost_setembre_octubre_novembre_desembre'.split(
        '_'
      ),
    monthsShort: 'gen._febr._mar._abr._mai._jun._jul._ag._set._oct._nov._des.'.split('_'),
    weekdays: 'diumenge_dilluns_dimarts_dimecres_dijous_divendres_dissabte'.split('_'),
    weekdaysShort: 'dg._dl._dt._dc._dj._dv._ds.'.split('_'),
    weekdaysLetter: 'Dg_Dl_Dt_Dc_Dj_Dv_Ds'.split('_'),
  },
  'gl': {
    months:
      'Xaneiro_Febreiro_Marzo_Abril_Maio_Xuño_Xullo_Agosto_Setembro_Outubro_Novembro_Decembro'.split(
        '_'
      ),
    monthsShort: 'Xan._Feb._Mar._Abr._Mai._Xuñ._Xul._Ago._Set._Out._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Luns_Martes_Mércores_Xoves_Venres_Sábado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mér._Xov._Ven._Sáb.'.split('_'),
    weekdaysLetter: 'Do_Lu_Ma_Mé_Xo_Ve_Sá'.split('_'),
  },
  'eu': {
    months:
      'urtarrila_otsaila_martxoa_apirila_maiatza_ekaina_uztaila_abuztua_iraila_urria_azaroa_abendua'.split(
        '_'
      ),
    monthsShort: 'urt._ots._mar._api._mai._eka._uzt._abu._ira._urr._aza._abe.'.split('_'),
    weekdays: 'igandea_astelehena_asteartea_asteazkena_osteguna_ostirala_larunbata'.split('_'),
    weekdaysShort: 'ig._al._ar._az._og._ol._lr.'.split('_'),
    weekdaysLetter: 'ig_al_ar_az_og_ol_lr'.split('_'),
  },
  'pt': {
    months:
      'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split(
        '_'
      ),
    monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_'),
    weekdays:
      'Domingo_Segunda-Feira_Terça-Feira_Quarta-Feira_Quinta-Feira_Sexta-Feira_Sábado'.split('_'),
    weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
    weekdaysLetter: [],
  },
  'it': {
    months:
      'gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre'.split(
        '_'
      ),
    monthsShort: 'gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic'.split('_'),
    weekdays: 'Domenica_Lunedì_Martedì_Mercoledì_Giovedì_Venerdì_Sabato'.split('_'),
    weekdaysShort: 'Dom_Lun_Mar_Mer_Gio_Ven_Sab'.split('_'),
    weekdaysLetter: [],
  },
  'fr': {
    months:
      'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split(
        '_'
      ),
    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysLetter: [],
  },
  'ru': {
    months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split(
      '_'
    ),
    monthsShort: 'Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split('_'),
    weekdays: 'Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота'.split('_'),
    weekdaysShort: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
    weekdaysLetter: [],
  },
  'ua': {
    months:
      'Січень_Лютий_Березень_Квітень_Травень_Червень_Липень_Серпень_Вересень_Жовтень_Листопад_Грудень'.split(
        '_'
      ),
    monthsShort: 'Січ_Лют_Берез_Квіт_Трав_Черв_Лип_Серп_Верес_Жовт_Листоп_Груд'.split('_'),
    weekdays: 'Неділя_Понеділок_Вівторок_Середа_Четвер_П’ятниця_Субота'.split('_'),
    weekdaysShort: 'Нд_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
    weekdaysLetter: [],
  },
  'zh-cn': {
    months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
    weekdaysLetter: '日_一_二_三_四_五_六'.split('_'),
  },
  'zh-hk': {
    months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '週日_週一_週二_週三_週四_週五_週六'.split('_'),
    weekdaysLetter: '日_一_二_三_四_五_六'.split('_'),
  },
  'zh-tw': {
    months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '週日_週一_週二_週三_週四_週五_週六'.split('_'),
    weekdaysLetter: '日_一_二_三_四_五_六'.split('_'),
  },
}

const time = {
  getDaysInMonth(d) {
    const resultDate = this.getFirstDayOfMonth(d)
    resultDate.setMonth(resultDate.getMonth() + 1)
    resultDate.setDate(resultDate.getDate() - 1)
    return resultDate.getDate()
  },

  getFirstDayOfMonth(d) {
    return new Date(d.getFullYear(), d.getMonth(), 1)
  },

  getFirstWeekDay(d) {
    return this.getFirstDayOfMonth(d).getDay()
  },

  getTimeMode(d) {
    return d.getHours() >= 12 ? 'pm' : 'am'
  },

  getFullMonth(d, locale = 'en') {
    const month = d.getMonth()
    const l = (typeof locale === 'string' ? dateLocales[locale] : locale) || dateLocales.en
    return hasOwnProperty(l, 'months') ? l.months[month] || 'Unknown' : 'Unknown'
  },

  getShortMonth(d, locale = 'en') {
    const month = d.getMonth()
    const l = (typeof locale === 'string' ? dateLocales[locale] : locale) || dateLocales.en
    return hasOwnProperty(l, 'monthsShort') ? l.monthsShort[month] || 'Unknown' : 'Unknown'
  },

  getFullDayOfWeek(day, locale = 'en') {
    const l = (typeof locale === 'string' ? dateLocales[locale] : locale) || dateLocales.en
    return hasOwnProperty(l, 'weekdays') ? l.weekdays[day] || 'Unknown' : 'Unknown'
  },

  getShortDayOfWeek(day, locale = 'en') {
    const l = (typeof locale === 'string' ? dateLocales[locale] : locale) || dateLocales.en
    return hasOwnProperty(l, 'weekdaysShort') ? l.weekdaysShort[day] || 'Unknown' : 'Unknown'
  },

  getDayOfWeekLetter(day, locale = 'en') {
    const l = (typeof locale === 'string' ? dateLocales[locale] : locale) || dateLocales.en
    return hasOwnProperty(l, 'weekdaysLetter')
      ? l.weekdaysLetter[day] || this.getFullDayOfWeek(day, locale).charAt(0)
      : 'Unknown'
  },

  clone(d) {
    return new Date(d.getTime())
  },

  cloneAsDate(d) {
    const clonedDate = this.clone(d)
    clonedDate.setHours(0, 0, 0, 0)
    return clonedDate
  },

  isDateObject(d) {
    return d instanceof Date
  },

  addDays(d, days) {
    const newDate = this.clone(d)
    newDate.setDate(d.getDate() + days)
    return newDate
  },

  addMonths(d, months) {
    const newDate = this.clone(d)
    newDate.setMonth(d.getMonth() + months, 1)
    return newDate
  },

  addYears(d, years) {
    const newDate = this.clone(d)
    newDate.setFullYear(d.getFullYear() + years)
    return newDate
  },

  setDay(d, day) {
    const newDate = this.clone(d)
    newDate.setDate(day)
    return newDate
  },

  setMonth(d, month) {
    const newDate = this.clone(d)
    newDate.setMonth(month)
    return newDate
  },

  setYear(d, year) {
    const newDate = this.clone(d)
    newDate.setFullYear(year)
    return newDate
  },

  setHours(d, hours) {
    const newDate = this.clone(d)
    newDate.setHours(hours)
    return newDate
  },

  setMinutes(d, minutes) {
    const newDate = this.clone(d)
    newDate.setMinutes(minutes)
    return newDate
  },

  toggleTimeMode(d) {
    const newDate = this.clone(d)
    const hours = newDate.getHours()

    newDate.setHours(hours - (hours > 12 ? -12 : 12))
    return newDate
  },

  formatTime(date, format) {
    let hours = date.getHours()
    let mins = date.getMinutes().toString()

    if (format === 'ampm') {
      const isAM = hours < 12
      const additional = isAM ? ' am' : ' pm'

      hours %= 12
      hours = (hours || 12).toString()
      if (mins.length < 2) mins = `0${mins}`

      return hours + (mins === '00' ? '' : `:${mins}`) + additional
    }

    hours = hours.toString()
    if (hours.length < 2) hours = `0${hours}`
    if (mins.length < 2) mins = `0${mins}`
    return `${hours}:${mins}`
  },

  dateOutOfRange(date, minDate, maxDate) {
    return (minDate && !(date >= minDate)) || (maxDate && !(date <= maxDate))
  },

  closestDate(to, date1, date2) {
    const toTime = to.getTime()

    const diff1 = Math.abs(toTime - date1.getTime())
    const diff2 = Math.abs(toTime - date2.getTime())

    return diff1 < diff2 ? date1 : date2
  },

  formatDate(date, locale = 'en') {
    if (locale === 'en') {
      return `${date.getDate()} ${time.getFullMonth(date, locale)} ${date.getFullYear()}`
    }
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  },
}

export default time
