/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { useEffect, useMemo } from 'react'
import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import _ from 'lodash'

import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import createDecorator from 'final-form-calculate'
import Page from './style'
import { API_URL } from '../../../api'

import { BackButton, NextButton } from '../../../components/styled'
import { HelpIcon, TooltipText, Spacer, CurrencyInput } from '../../../components'
import { numberToDollars } from '../../../utils/utils'

const Budget = function (props) {
  const {
    history,
    store: {
      account,
      setModifiedPlus,
      processModifiedCase,
      budget = {},
      postBudget,
      saveBequest,
      bequest,
      getMediaIndex,
      getBudgetPDF,
      getBudgetEXCEL,
    },
  } = props
  const theme = useTheme()

  const allEqualsToNeed = allValues =>
    allValues.totalDebts +
      allValues.housing +
      allValues.transportation +
      allValues.medical +
      allValues.grocery +
      allValues.childcare +
      allValues.otherExpenses ===
    allValues.need

  const sum = allValues =>
    allValues.totalDebts +
    allValues.housing +
    allValues.transportation +
    allValues.medical +
    allValues.grocery +
    allValues.childcare +
    allValues.otherExpenses

  const calculator = useMemo(
    () =>
      createDecorator(
        {
          field: 'totalDebts',
          updates: {
            need: (currentFieldValue, allValues) =>
              sum(allValues) === 0 ? allValues.need : sum(allValues),
          },
        },
        {
          field: 'housing',
          updates: {
            need: (currentFieldValue, allValues) =>
              sum(allValues) === 0 ? allValues.need : sum(allValues),
          },
        },
        {
          field: 'transportation',
          updates: {
            need: (currentFieldValue, allValues) =>
              sum(allValues) === 0 ? allValues.need : sum(allValues),
          },
        },
        {
          field: 'medical',
          updates: {
            need: (currentFieldValue, allValues) =>
              sum(allValues) === 0 ? allValues.need : sum(allValues),
          },
        },
        {
          field: 'grocery',
          updates: {
            need: (currentFieldValue, allValues) =>
              sum(allValues) === 0 ? allValues.need : sum(allValues),
          },
        },
        {
          field: 'childcare',
          updates: {
            need: (currentFieldValue, allValues) =>
              sum(allValues) === 0 ? allValues.need : sum(allValues),
          },
        },
        {
          field: 'otherExpenses',
          updates: {
            need: (currentFieldValue, allValues) =>
              sum(allValues) === 0 ? allValues.need : sum(allValues),
          },
        },
        {
          field: 'need',
          updates: {
            need: (currentNeed, allValues) => {
              if (sum(allValues) === 0) {
                return allValues.need
              }
              return allValues.need
            },
            totalDebts: (currentNeed, allValues) => {
              if (allEqualsToNeed(allValues)) {
                return allValues.totalDebts
              }
              if (!allEqualsToNeed(allValues)) {
                return 0
              }
              return allValues.totalDebts
            },
            housing: (currentNeed, allValues) => {
              if (allEqualsToNeed(allValues)) {
                return allValues.housing
              }
              if (!allEqualsToNeed(allValues)) {
                return 0
              }
              return allValues.housing
            },
            transportation: (currentNeed, allValues) => {
              if (allEqualsToNeed(allValues)) {
                return allValues.transportation
              }
              if (!allEqualsToNeed(allValues)) {
                return 0
              }
              return allValues.transportation
            },
            medical: (currentNeed, allValues) => {
              if (allEqualsToNeed(allValues)) {
                return allValues.medical
              }
              if (!allEqualsToNeed(allValues)) {
                return 0
              }
              return allValues.medical
            },
            grocery: (currentNeed, allValues) => {
              if (allEqualsToNeed(allValues)) {
                return allValues.grocery
              }
              if (!allEqualsToNeed(allValues)) {
                return 0
              }
              return allValues.grocery
            },
            childcare: (currentNeed, allValues) => {
              if (allEqualsToNeed(allValues)) {
                return allValues.childcare
              }
              if (!allEqualsToNeed(allValues)) {
                return 0
              }
              return allValues.childcare
            },
            otherExpenses: (currentNeed, allValues) => {
              if (allEqualsToNeed(allValues)) {
                return allValues.otherExpenses
              }
              if (!allEqualsToNeed(allValues)) {
                return 0
              }
              return allValues.otherExpenses
            },
          },
        }
      ),
    []
  ) // eslint-disable-line

  useEffect(() => {
    props.main.setSidebar(false)
    props.main.setTopNav(false)

    return () => {
      props.main.setSidebar(true)
      props.main.setTopNav(true)
    }
  }, []) // eslint-disable-line

  React.useEffect(() => {
    document.documentElement.className = css`
      background: url(${API_URL + theme.bgRight}) no-repeat top right fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    `.name
    return () => {
      document.documentElement.className = ''
    }
  }, []) // eslint-disable-line

  const handlePost = async values => {
    try {
      const res = await postBudget({
        dollarAmountNeeded: parseInt(values.need, 10),
        totalDebts: parseInt(values.totalDebts, 10),
        housing: parseInt(values.housing, 10),
        transportation: parseInt(values.transportation, 10),
        medical: parseInt(values.medical, 10),
        grocery: parseInt(values.grocery, 10),
        childcare: parseInt(values.childcare, 10),
        otherExpenses: parseInt(values.otherExpenses, 10),
        dollarAmountDesired: parseInt(values.want, 10) + parseInt(values.need, 10),
        accountId: account.id,
        personId: account.participantId,
      })

      await saveBequest({
        totalBequestExpense: values.bequest ? parseInt(values.bequest, 10) : 0,
        totalEndOfLifeExpense: values.endOfLife ? parseInt(values.endOfLife, 10) : 0,
      })

      setModifiedPlus()
      await processModifiedCase({
        retirementDollarDesired: res.dollarAmountDesired,
        retirementDollarNeeded: res.dollarAmountNeeded,
      })
      history.push('/spending')
    } catch (err) {
      console.error(err)
    }
  }

  const onSubmit = values => handlePost(values)

  const initialValues = {
    want: parseInt(_.get(budget, 'dollarAmountDesired', 0), 10)
      ? parseInt(_.get(budget, 'dollarAmountDesired', 0), 10) -
        parseInt(_.get(budget, 'dollarAmountNeeded', 0))
      : 0,
    need: parseInt(_.get(budget, 'dollarAmountNeeded', 0)),
    totalDebts: parseInt(_.get(budget, 'totalDebts', 0)),
    housing: parseInt(_.get(budget, 'housing', 0)),
    transportation: parseInt(_.get(budget, 'transportation', 0)),
    medical: parseInt(_.get(budget, 'medical', 0)),
    grocery: parseInt(_.get(budget, 'grocery', 0)),
    childcare: parseInt(_.get(budget, 'childcare', 0)),
    otherExpenses: parseInt(_.get(budget, 'otherExpenses', 0)),

    bequest: parseInt(_.get(bequest, 'totalBequestExpense', 0)),
    endOfLife: parseInt(_.get(bequest, 'totalEndOfLifeExpense', 0)),
  }

  const handleOpen = async docName => {
    try {
      const res = await getMediaIndex()

      if (docName === 'BUDGET WORKSHEET PDF') {
        const PDFUrl = res.filter(document => document.name === docName)[0].uri
        await getBudgetPDF(PDFUrl).then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' }) // eslint-disable-line
          const objectUrl = window.URL.createObjectURL(blob)
          window.open(objectUrl)
        })
        return
      }

      const EXCELUrl = res.filter(document => document.name === docName)[0].uri
      await getBudgetEXCEL(EXCELUrl).then(response => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' }) // eslint-disable-line

        const anchor = document.createElement('a')
        document.body.appendChild(anchor)
        anchor.style = 'display: none'

        const testURL = window.URL.createObjectURL(blob)
        anchor.href = testURL
        anchor.download = EXCELUrl

        const linkText = document.createTextNode('View File')
        anchor.target = '_blank'
        anchor.appendChild(linkText)

        anchor.click()
        window.URL.revokeObjectURL(testURL)
      })
      return
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Page.Container>
      <Page.Header />
      <div
        css={css`
          padding: 0 40px;
        `}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          decorators={[calculator]}
          subscription={{ submitting: true, submitError: true, values: true }}
          render={({ handleSubmit, submitting, values }) => (
            <Page.Container>
              <Heading>Before we can plan, we need to know where we're headed: your budget</Heading>
              <div
                css={css`
                  padding-left: 20px;
                  max-width: 1000px;
                `}>
                <Page.Text
                  css={css`
                    font-size: 1rem;
                  `}>
                  <i>Ugh</i>. We hear you. For most folks, estimating your financial needs vs. wants
                  ranks right up there with filing taxes and visiting your dentist... but{' '}
                  <b>a budget is the only way to get more meaningful advice</b>. Download our
                  budgeting worksheet (
                  <Page.Link onClick={() => handleOpen('BUDGET WORKSHEET PDF')}>PDF</Page.Link> |{' '}
                  <Page.Link onClick={() => handleOpen('BUDGET WORKSHEET EXCEL')}>Excel</Page.Link>)
                  to keep details of what you considered.
                </Page.Text>
                <Spacer space='10px' />

                <BorderedCard>
                  <Wrapper>
                    <Label>
                      <div>
                        <Title>Desired monthly spending total</Title>
                        <LightText>Need + Want</LightText>
                      </div>
                    </Label>
                    <Value>
                      <Total values={values} />
                    </Value>
                  </Wrapper>
                  <Hr />

                  <Wrapper>
                    <Label>
                      <div
                        css={css`
                          display: flex;
                          justify-content: flex-end;
                        `}>
                        <Title>Need</Title>
                        <div
                          css={css`
                            padding-left: 10px;
                          `}>
                          <HelpIcon tooltip={TooltipText.budgetNeed()} />
                        </div>
                      </div>
                    </Label>
                    <Value>
                      <Need values={values} />
                    </Value>
                  </Wrapper>

                  <Wrapper>
                    <Label>
                      <LightText>Debt</LightText>
                    </Label>
                    <Value>
                      <Debt values={values} />
                    </Value>
                  </Wrapper>

                  <Wrapper>
                    <Label>
                      <LightText>Housing &amp; utilities</LightText>
                    </Label>
                    <Value>
                      <Housing values={values} />
                    </Value>
                  </Wrapper>

                  <Wrapper>
                    <Label>
                      <LightText>Transportation</LightText>
                    </Label>
                    <Value>
                      <Transportation />
                    </Value>
                  </Wrapper>

                  <Wrapper>
                    <Label>
                      <LightText>Medical</LightText>
                    </Label>
                    <Value>
                      <Medical />
                    </Value>
                  </Wrapper>

                  <Wrapper>
                    <Label>
                      <LightText>Grocery, household &amp; personal care</LightText>
                    </Label>
                    <Value>
                      <Grocery />
                    </Value>
                  </Wrapper>

                  <Wrapper>
                    <Label>
                      <LightText>Child care &amp; support of relatives</LightText>
                    </Label>
                    <Value>
                      <ChildCare />
                    </Value>
                  </Wrapper>

                  <Wrapper>
                    <Label>
                      <LightText>Other mandatory expenses</LightText>
                    </Label>
                    <Value>
                      <Other />
                    </Value>
                  </Wrapper>

                  <Spacer space='10px' />

                  <Wrapper>
                    <Label>
                      <div
                        css={css`
                          display: flex;
                          justify-content: flex-end;
                        `}>
                        <Title>
                          Want{' '}
                          <span
                            css={css`
                              font-size: 14px;
                            `}>
                            (in addition to Need)
                          </span>
                        </Title>
                        <div
                          css={css`
                            padding-left: 10px;
                          `}>
                          <HelpIcon tooltip={TooltipText.budgetWant()} />
                        </div>
                      </div>
                    </Label>
                    <Value>
                      <Want />
                    </Value>
                  </Wrapper>
                </BorderedCard>

                <Spacer space='10px' />

                <BorderedCard>
                  <Wrapper>
                    <Label>
                      <div
                        css={css`
                          display: flex;
                          justify-content: flex-end;
                        `}>
                        <Title>Other budgeting considerations</Title>
                        <div
                          css={css`
                            padding-left: 10px;
                          `}>
                          <HelpIcon tooltip={TooltipText.budgetConsiderations()} />
                        </div>
                      </div>
                    </Label>
                    <Value />
                  </Wrapper>

                  <Wrapper>
                    <Label>
                      <LightText>End-of-life expenses</LightText>
                    </Label>
                    <Value>
                      <EndOfLife />
                    </Value>
                  </Wrapper>

                  <Wrapper>
                    <Label>
                      <LightText>Bequest</LightText>
                    </Label>
                    <Value>
                      <Bequest />
                    </Value>
                  </Wrapper>
                </BorderedCard>
              </div>

              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  margin: 50px 0;
                `}>
                <BackButton backgroundColor='#FFFFFF' onClick={() => history.goBack()}>
                  Back
                </BackButton>
                <NextButton onClick={handleSubmit} disabled={submitting}>
                  Next
                </NextButton>
              </div>
            </Page.Container>
          )}
        />
      </div>
    </Page.Container>
  )
}

const Total = function ({ values }) {
  return (
    <TotalContainer>
      <b>{numberToDollars(parseInt(values.want) + parseInt(values.need) || 0, true)}</b>
    </TotalContainer>
  )
}

const Need = function () {
  const { input, meta } = useField('need', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <PerMonthContainer>
      <Title>
        <CurrencyInput
          name={input.name}
          value={parseInt(input.value)}
          onChange={(name, value) => input.onChange(value)}
          onBlur={input.onBlur}
          error={meta.error}
          showError={meta.touched}
          width='115px'
        />
      </Title>
    </PerMonthContainer>
  )
}

const Debt = function () {
  const { input, meta } = useField('totalDebts', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <PerMonthContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </PerMonthContainer>
  )
}

const Housing = function ({ values }) {
  const { input, meta } = useField('housing', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <PerMonthContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </PerMonthContainer>
  )
}

const Transportation = function () {
  const { input, meta } = useField('transportation', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <PerMonthContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </PerMonthContainer>
  )
}

const Medical = function () {
  const { input, meta } = useField('medical', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <PerMonthContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </PerMonthContainer>
  )
}

const Grocery = function () {
  const { input, meta } = useField('grocery', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <PerMonthContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </PerMonthContainer>
  )
}

const ChildCare = function () {
  const { input, meta } = useField('childcare', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <PerMonthContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </PerMonthContainer>
  )
}

const Other = function () {
  const { input, meta } = useField('otherExpenses', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <PerMonthContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </PerMonthContainer>
  )
}

const Want = function () {
  const { input, meta } = useField('want', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <PerMonthContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </PerMonthContainer>
  )
}

const EndOfLife = function () {
  const { input, meta } = useField('endOfLife', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TotalStyledContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </TotalStyledContainer>
  )
}

const Bequest = function () {
  const { input, meta } = useField('bequest', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TotalStyledContainer>
      <CurrencyInput
        name={input.name}
        value={parseInt(input.value)}
        onChange={(name, value) => input.onChange(value)}
        onBlur={input.onBlur}
        error={meta.error}
        showError={meta.touched}
        width='115px'
      />
    </TotalStyledContainer>
  )
}

export default inject('store', 'main')(observer(Budget))

const BorderedCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  border: ${p => p.theme.needsWantsContainerBorder};
  border-radius: 8px;
  background-color: ${p => p.theme.needsWantsContainerBgColor};
  margin: 10px 0;
  padding: 1rem;
  padding-right: 32px;
  margin-bottom: 20px;
  max-width: 700px;
  color: ${p => p.theme.needsWantsContainerTextColor};
`
const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  height: 45px;
`
const Label = styled.div`
  flex: 3;
  text-align: right;
  padding-right: 30px;
`
const LightText = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.needsWantsContainerLabelTextColor};
  line-height: 1.35;
  font-size: 16px;
`
const Value = styled.div`
  flex: 2;
`
const TotalContainer = styled.div`
  & ::before {
    content: '$';
    padding-right: 10px;
    color: ${p => p.theme.needsWantsContainerLabelTextColor};
    font-size: 1.125rem;
  }
`
const Hr = styled.hr`
  width: 100%;
  margin: 5px 0;
`
const Heading = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2.3rem;
  width: 100%;
  color: ${p => p.theme.needsWantsHeadingColor};
  -webkit-transition: 0.75s;
  transition: 0.75s;
  font-weight: 300;
  line-height: 1.25;
  word-break: break-word;
  max-width: 630px;
  margin-bottom: 40px;
`
const PerMonthContainer = styled.div`
  display: flex;
  align-items: baseline;
  max-width: 200px;

  & > div > div > div::before {
    padding-right: 10px;
    font-size: 20px;
  }
  &::after {
    content: '/month';
    padding-right: 10px;
    color: #7a8e96;
    font-size: 14px;
  }
`
const TotalStyledContainer = styled.div`
  display: flex;
  align-items: baseline;
  max-width: 200px;

  & > div > div > div::before {
    padding-right: 10px;
    font-size: 20px;
  }
  &::after {
    content: 'total';
    padding-right: 10px;
    color: #7a8e96;
    font-size: 14px;
  }
`
const Title = styled.b`
  color: ${p => p.theme.needsWantsContainerTitleTextColor};
  font-family: 'Open Sans';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
`
