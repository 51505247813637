/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { ClassNames, css } from '@emotion/react'
import { useEffect, useMemo } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import styled from '@emotion/styled'
import createDecorator from 'final-form-calculate'
import _ from 'lodash'

import { CircleNegative, CirclePlus } from '../../../assets/icons'
import {
  SVGWrapper,
  Button,
  CircleProgressBar,
  Spacer,
  SliderWithValueUnderneath,
  FormError,
  Dropdown as DropdownElement,
  SharedModal,
  HelpIcon,
  TooltipText,
} from '../../../components'
import { numberToDollars } from '../../../utils/utils'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { RadioGroup, RadioButton } from '../../../guided-toolbox'
import { Page } from './style'
import { API } from '../../../api'

const Condition = ({ when, includes, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value.includes(includes) ? children : null)}
  </Field>
)

let primaryInvestment = []
let spouseInvestment = []

function MarriedOrSingleAnnuity({
  setDrawer,
  handleCancel,
  store: {
    totalPrimaryBalanceExcludingPensionAndAnnuity,
    totalSpouseBalanceExcludingPensionAndAnnuity,
    setRemainingInvestmentBalance,
    setSpouseLifetimeInvestmentIncome,
    setPrimaryLifetimeInvestmentIncome,
    processModifiedCase,
    spouse,
    spouse: { age: spouseAge, retirementAge: spouseRetirementAge },
    person: { displayName, retirementAge, age },
  },
}) {
  const calculator = useMemo(
    () =>
      createDecorator(
        {
          field: 'primaryStartingAge',
          updates: {
            primarySlider: (ignoredValue, allValues) => {
              return parseInt(allValues.primaryStartingAge)
            },
            steps: (ignoredValue, allValues) => {
              // Get new set of investment data
              allValues.getPrimaryNewInvestmentIncome(allValues.primaryStartingAge)

              return [1, 2]
            },
            primaryAmountSelection: (ignoredValue, allValues) => {
              return null
            },
          },
        },
        {
          field: 'primarySlider',
          updates: {
            primaryStartingAge: (ignoredValue, allValues) => {
              return parseInt(allValues.primarySlider)
            },
          },
        },
        {
          field: 'spouseStartingAge',
          updates: {
            spouseSlider: (ignoredValue, allValues) => {
              return parseInt(allValues.spouseStartingAge)
            },
            steps: (ignoredValue, allValues) => {
              // Get new set of investment data
              allValues.getSpouseNewInvestmentIncome(allValues.spouseStartingAge)

              if (allValues.accounts === '2') {
                return [1, 4]
              } else if (allValues.accounts === 'both') {
                return [1, 2, 3, 4]
              }
              return allValues.steps
            },
            step: (ignoredValue, allValues) => {
              if (allValues.accounts === '2') {
                return 4
              } else if (allValues.accounts === 'both') {
                return 4
              }
              return allValues.step
            },
            spouseAmountSelection: (ignoredValue, allValues) => {
              return null
            },
          },
        },
        {
          field: 'spouseSlider',
          updates: {
            spouseStartingAge: (ignoredValue, allValues) => {
              return parseInt(allValues.spouseSlider)
            },
          },
        },
        {
          field: 'accounts',
          updates: {
            steps: (ignoredValue, allValues) => {
              return [1]
            },
          },
        },
        {
          field: 'primaryCustomPercentage',
          updates: {
            primaryCustomAmountSlider: (ignoredValue, allValues) => {
              const item = primaryInvestment.filter(
                item => item.balanceUsedPercent === parseInt(allValues.primaryCustomPercentage)
              )[0]
              return item ? item.balanceUsedPercent : null
            },
          },
        },
        {
          field: 'primaryCustomAmountSlider',
          updates: {
            primaryCustomPercentage: (ignoredValue, allValues) => {
              return allValues.primaryCustomAmountSlider
            },
            displayWarning: (ignoredValue, allValues) => {
              return allValues.primaryCustomAmountSlider > 79
            },
          },
        },
        {
          field: 'spouseCustomPercentage',
          updates: {
            spouseCustomAmountSlider: (ignoredValue, allValues) => {
              const item = spouseInvestment.filter(
                item => item.balanceUsedPercent === parseInt(allValues.spouseCustomPercentage)
              )[0]
              return item ? item.balanceUsedPercent : null
            },
          },
        },
        {
          field: 'spouseCustomAmountSlider',
          updates: {
            spouseCustomPercentage: (ignoredValue, allValues) => {
              return allValues.spouseCustomAmountSlider
            },
          },
        },
        {
          field: 'primaryAmountSelection',
          updates: {
            displayWarning: (ignoredValue, allValues) => {
              return allValues.primaryCustomAmountSlider > 79
            },
          },
        },
        {
          field: 'spouseAmountSelection',
          updates: {
            displaySpouseWarning: (ignoredValue, allValues) => {
              return allValues.spouseCustomAmountSlider > 79
            },
          },
        }
      ),
    []
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const primaryResult = await API.get('/investment-income', {
          params: {
            currentAge: age,
            startPaymentAge: retirementAge,
            investBalance: totalPrimaryBalanceExcludingPensionAndAnnuity,
          },
        })
        primaryInvestment = [...primaryResult.data]
        const spouseResult = await API.get('/investment-income', {
          params: {
            currentAge: spouseAge,
            startPaymentAge: spouseRetirementAge,
            investBalance: totalSpouseBalanceExcludingPensionAndAnnuity,
          },
        })
        spouseInvestment = [...spouseResult.data]
      } catch (err) {
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  const handlePost = async values => {
    const primary = primaryInvestment.filter(
      item => item.monthlyAmount + '' === values.primaryAmountSelection
    )[0]
    const spouse = spouseInvestment.filter(
      item => item.monthlyAmount + '' === values.spouseAmountSelection
    )[0]

    const primaryLifetimeInvestmentIncome =
      values.accounts === '1' || values.accounts === 'both'
        ? {
            balanceUsedPercent: primary.balanceUsedPercent,
            costOfInvestment: primary.costOfInvestment,
            monthlyAmount: primary.monthlyAmount,
            recipient: 1,
            remainingInvestmentBalance: primary.remainingInvestmentBalance,
            startPaymentAge: values.primaryStartingAge,
          }
        : null

    const spouseLifetimeInvestmentIncome =
      values.accounts === '2' || values.accounts === 'both'
        ? {
            balanceUsedPercent: spouse.balanceUsedPercent,
            costOfInvestment: spouse.costOfInvestment,
            monthlyAmount: spouse.monthlyAmount,
            recipient: 2,
            remainingInvestmentBalance: spouse.remainingInvestmentBalance,
            startPaymentAge: values.spouseStartingAge,
          }
        : null

    try {
      await processModifiedCase({ primaryLifetimeInvestmentIncome, spouseLifetimeInvestmentIncome })
      setSpouseLifetimeInvestmentIncome(spouseLifetimeInvestmentIncome)
      setPrimaryLifetimeInvestmentIncome(primaryLifetimeInvestmentIncome)
      setRemainingInvestmentBalance(
        (spouse
          ? _.get(spouseLifetimeInvestmentIncome, 'remainingInvestmentBalance', 0)
          : totalSpouseBalanceExcludingPensionAndAnnuity) +
          (primary
            ? _.get(primaryLifetimeInvestmentIncome, 'remainingInvestmentBalance', 0)
            : totalPrimaryBalanceExcludingPensionAndAnnuity)
      )
      setDrawer('')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const onSubmit = values => {
    return handlePost(values)
  }

  const percentage = []

  for (let i = 1; i <= 100; i += 1) {
    percentage.push(i + '')
  }

  const primaryAges = (() => {
    const arr = []
    const primaryMaxAge = age > 80 ? age : 80
    for (let i = parseInt(age < 60 ? 60 : age); i <= primaryMaxAge; i++) {
      arr.push(i)
    }
    return arr
  })()

  const spouseAges = (() => {
    const spouseMaxAge = spouseAge > 80 ? spouseAge : 80
    const arr = []
    for (let i = parseInt(spouse.age < 60 ? 60 : spouse.age); i <= spouseMaxAge; i++) {
      arr.push(i)
    }
    return arr
  })()

  const validate = values => {
    const errors = {}
    if (!values.accounts) {
      errors.accounts = 'Selection Required'
    }
    if (values.accounts !== '2' && !values.primaryAmountSelection) {
      errors.primaryAmountSelection = 'Selection Required'
    }
    if ((values.accounts === '2' || values.accounts === 'both') && !values.spouseAmountSelection) {
      errors.spouseAmountSelection = 'Selection Required'
    }

    return errors
  }

  const initialValues = {
    accounts: '',
    steps: [1],
    step: 1,
    primarySlider: age < 60 ? 60 : age,
    primaryStartingAge: age < 60 ? 60 : age,
    spouseSlider: spouse.age < 60 ? 60 : spouse.age,
    spouseStartingAge: spouse.age < 60 ? 60 : spouse.age,
    primaryAmountSelection: null,
    spouseAmountSelection: null,
    primaryExpandedView: false,
    spouseExpandedView: false,
    primaryCustomPercentage: null,
    primaryCustomAmountSlider: null,
    spouseCustomPercentage: null,
    spouseCustomAmountSlider: null,
    displayWarning: false,
    displayedMoreThanEightyWarning: false,
    displaySpouseWarning: false,
    displayedSpouseMoreThanEightyWarning: false,
    getPrimaryNewInvestmentIncome: _.debounce(async function (primaryStartingAge) {
      try {
        const result = await API.get('/investment-income', {
          params: {
            currentAge: age,
            startPaymentAge: primaryStartingAge,
            investBalance: totalPrimaryBalanceExcludingPensionAndAnnuity,
          },
        })
        primaryInvestment = [...result.data]
      } catch (err) {
        console.error(err)
      }
    }, 200),
    getSpouseNewInvestmentIncome: _.debounce(async function (spouseStartingAge) {
      try {
        const spouseResult = await API.get('/investment-income', {
          params: {
            currentAge: spouse.age,
            startPaymentAge: spouseStartingAge,
            investBalance: totalSpouseBalanceExcludingPensionAndAnnuity,
          },
        })
        spouseInvestment = [...spouseResult.data]
      } catch (err) {
        console.error(err)
      }
    }, 200),
  }

  const mutators = {
    moveToStepTwo: (args, state, utils) => {
      utils.changeValue(state, 'step', value => 2)
      utils.changeValue(state, 'steps', values => [...values, 2])
    },
    moveToStepThree: (args, state, utils) => {
      utils.changeValue(state, 'step', value => 3)
      utils.changeValue(state, 'steps', values => [...values, 3])
    },
    moveToStepFour: (args, state, utils) => {
      utils.changeValue(state, 'step', value => 4)
      utils.changeValue(state, 'steps', values => [...values, 4])
    },
    moveToStepFive: (args, state, utils) => {
      utils.changeValue(state, 'step', value => 5)
      utils.changeValue(state, 'steps', values => [...values, 5])
    },
    togglePrimaryMoreOptions: (args, state, utils) => {
      utils.changeValue(
        state,
        'primaryExpandedView',
        values => !state.formState.values.primaryExpandedView
      )
    },
    toggleSpouseMoreOptions: (args, state, utils) => {
      utils.changeValue(
        state,
        'spouseExpandedView',
        values => !state.formState.values.spouseExpandedView
      )
    },
    toggleWarningModal: (args, state, utils) => {
      utils.changeValue(state, 'displayWarning', values => !state.formState.values.displayWarning)
      utils.changeValue(
        state,
        'displayedMoreThanEightyWarning',
        values => !state.formState.values.displayedMoreThanEightyWarning
      )
    },
    toggleSpouseWarningModal: (args, state, utils) => {
      utils.changeValue(
        state,
        'displaySpouseWarning',
        values => !state.formState.values.displaySpouseWarning
      )
      utils.changeValue(
        state,
        'displayedSpouseMoreThanEightyWarning',
        values => !state.formState.values.displayedSpouseMoreThanEightyWarning
      )
    },
  }

  return (
    <Form
      mutators={mutators}
      keepDirtyOnReinitialize
      decorators={[calculator]}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      subscription={{ submitting: true, submitError: true, values: true }}
      render={({ handleSubmit, submitting, values, form, submitError }) => (
        <MainWrapper>
          <Condition when='steps' includes={1}>
            <div>
              {values.accounts ? (
                values.accounts === '1' || values.accounts === '2' ? (
                  <StepTitle>Step 1 of 3</StepTitle>
                ) : (
                  <StepTitle>Step 1 of 5</StepTitle>
                )
              ) : null}
              <Page.CardTitle>
                In whose name do you want an account providing more stable lifetime income?
              </Page.CardTitle>
              <BorderedCard>
                <Accounts spouse={spouse} displayName={displayName} />

                {values.accounts === '1' || values.accounts === 'both' ? (
                  <Button
                    type='button'
                    onClick={form.mutators.moveToStepTwo}
                    disabled={!values.accounts}
                    primary
                    label='Next'
                    width='140px'
                    css={css`
                      height: 45px;
                      align-self: flex-end;
                    `}
                  />
                ) : (
                  <Button
                    type='button'
                    onClick={form.mutators.moveToStepFour}
                    disabled={!values.accounts}
                    primary
                    label='Next'
                    width='140px'
                    css={css`
                      height: 45px;
                      align-self: flex-end;
                      z-index: 20;
                    `}
                  />
                )}
              </BorderedCard>
            </div>
          </Condition>

          {values.displayWarning &&
            !values.displayedMoreThanEightyWarning &&
            values.primaryAmountSelection > 0 &&
            values.primaryCustomAmountSlider > 79 && (
              <SharedModal
                title='Looks like you want to use 80% or more...'
                isModalOpen={values.displayWarning && !values.displayedMoreThanEightyWarning}
                toggleModal={form.mutators.toggleWarningModal}>
                <Page.WarningText>
                  <p>
                    With rare exception, we recommend that you leave at least 20% of your nest egg
                    invested to enable you to keep up with inflation or to use for any unexpected
                    need that comes along.
                  </p>
                </Page.WarningText>

                <Page.ButtonWrapper>
                  <Button
                    primary
                    label='Got it!'
                    onClick={form.mutators.toggleWarningModal}
                    width='100px'
                  />
                </Page.ButtonWrapper>
              </SharedModal>
            )}

          {/* spouse warning modal */}
          {values.displaySpouseWarning &&
            !values.displayedSpouseMoreThanEightyWarning &&
            parseInt(values.spouseAmountSelection) > 0 &&
            values.spouseCustomAmountSlider > 79 && (
              <SharedModal
                title='Looks like you want to use 80% or more...'
                isModalOpen={
                  values.displaySpouseWarning && !values.displayedSpouseMoreThanEightyWarning
                }
                toggleModal={form.mutators.toggleSpouseWarningModal}>
                <Page.WarningText>
                  <p>
                    With rare exception, we recommend that you leave at least 20% of your nest egg
                    invested to enable you to keep up with inflation or to use for any unexpected
                    need that comes along.
                  </p>
                </Page.WarningText>

                <Page.ButtonWrapper>
                  <Button
                    primary
                    label='Got it!'
                    onClick={form.mutators.toggleSpouseWarningModal}
                    width='100px'
                  />
                </Page.ButtonWrapper>
              </SharedModal>
            )}

          <Condition when='steps' includes={2} and>
            <div>
              {values.accounts ? (
                values.accounts === '1' || values.accounts === '2' ? (
                  <>
                    <StepTitle>Step 2 of 3</StepTitle>
                    <Page.CardTitle>
                      When would you like to begin receiving payments? You can buy more lifetime
                      income to start now or later. Starting later will cost less.
                    </Page.CardTitle>
                  </>
                ) : (
                  <>
                    <StepTitle>Step 2 of 5</StepTitle>
                    <Page.CardTitle>
                      Let's start with {displayName}. You can buy more lifetime income to start now
                      or later. Starting later will cost less. When would you like to start?
                    </Page.CardTitle>
                  </>
                )
              ) : null}

              <BorderedCard>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}>
                  <Page.Field style={{ margin: 0, marginRight: 20 }}>
                    <Field
                      name='primaryStartingAge'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <Dropdown meta={meta} ages={primaryAges} input={input} />
                      )}
                    />
                  </Page.Field>

                  <div
                    css={css`
                      margin-top: 27px;
                      margin-left: 20px;
                      width: 100%;
                    `}>
                    <Field
                      name='primarySlider'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <SliderWithValueUnderneath
                          min={age < 60 ? 60 : age}
                          max={age > 80 ? age : 80}
                          value={input.value}
                          onChange={input.onChange}
                          displayValue={false}
                          step='1'
                        />
                      )}
                    />
                  </div>
                </div>

                <Button
                  type='button'
                  onClick={form.mutators.moveToStepThree}
                  primary
                  label='Next'
                  width='140px'
                  css={css`
                    height: 45px;
                    align-self: flex-end;
                  `}
                />
              </BorderedCard>
            </div>
          </Condition>
          <Condition when='steps' includes={3}>
            <div>
              {values.accounts ? (
                values.accounts === '1' ? (
                  <>
                    <StepTitle>Step 3 of 3</StepTitle>
                    <Page.CardTitle>
                      Let's look at investing some of your balance ($
                      {numberToDollars(totalPrimaryBalanceExcludingPensionAndAnnuity, true)}) to get
                      more lifetime income. Amounts are before tax.
                    </Page.CardTitle>
                  </>
                ) : (
                  <>
                    <div
                      css={css`
                        display: flex;
                        align-items: flex-start;
                      `}>
                      <StepTitle
                        css={css`
                          margin-right: 8px;
                        `}>
                        Step 3 of 5
                      </StepTitle>
                      <HelpIcon tooltip={TooltipText.fundingAnnuity()} />
                    </div>
                    <Page.CardTitle>
                      Let's look at investing some of your balance ($
                      {numberToDollars(totalPrimaryBalanceExcludingPensionAndAnnuity, true)}) to get
                      more lifetime income. Amounts are before tax.
                    </Page.CardTitle>
                  </>
                )
              ) : null}

              <BorderedCard>
                <Field
                  name='primaryAmountSelection'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <ClassNames>
                      {({ css, cx }) => (
                        <RadioGroup
                          css={css`
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                          `}
                          value={input.value}
                          onChange={value => input.onChange(value)}>
                          {primaryInvestment.map(item => {
                            if (
                              item.balanceUsedPercent === 75 ||
                              item.balanceUsedPercent === 50 ||
                              item.balanceUsedPercent === 25
                            ) {
                              const label = () => {
                                return (
                                  <>
                                    <Page.UseOfBalance>
                                      Use{' '}
                                      <Page.Percentage>
                                        {item.balanceUsedPercent}
                                        <Page.PercentSign>%</Page.PercentSign>
                                      </Page.Percentage>{' '}
                                      of balance
                                    </Page.UseOfBalance>
                                    <SVGText>
                                      {' '}
                                      (${numberToDollars(
                                        item.remainingInvestmentBalance,
                                        true
                                      )}{' '}
                                      remains){' '}
                                    </SVGText>
                                  </>
                                )
                              }
                              return (
                                <RadioButton
                                  key={item.balanceUsedPercent}
                                  label={label()}
                                  value={item.monthlyAmount + ''}
                                  className={cx(
                                    css`
                                      position: relative;
                                      margin-bottom: 60px;
                                      width: 200px;
                                      margin-top: 145px;
                                    `,
                                    'radio-button'
                                  )}>
                                  <div
                                    css={css`
                                      position: absolute;
                                      left: 0px;
                                      top: -150px;
                                      bottom: 0px;
                                      right: 0px;
                                    `}>
                                    <SVGContainer>
                                      <CircleProgressBar
                                        amount={item.monthlyAmount}
                                        percentage={item.balanceUsedPercent}
                                        speed={50}
                                      />
                                    </SVGContainer>
                                  </div>
                                </RadioButton>
                              )
                            }
                            return null
                          })}
                        </RadioGroup>
                      )}
                    </ClassNames>
                  )}
                />

                <Spacer size='12px' />

                <div
                  css={css`
                    display: flex;
                    z-index: 20;
                  `}>
                  <SVGWrapper
                    svg={values.primaryExpandedView ? CircleNegative : CirclePlus}
                    fill='#7a8e96'
                    size='large'
                    onClick={form.mutators.togglePrimaryMoreOptions}
                    css={css`
                      margin-right: 10px;
                    `}
                  />
                  <Page.Text
                    css={css`
                      cursor: pointer;
                    `}
                    onClick={form.mutators.togglePrimaryMoreOptions}>
                    <MoreOptionsText>More options</MoreOptionsText>
                  </Page.Text>
                </div>

                {values.primaryExpandedView && (
                  <>
                    <div
                      css={css`
                        display: flex;
                        margin: 20px 0;
                      `}>
                      <Page.Field style={{ margin: 0 }}>
                        <Field
                          name='primaryCustomPercentage'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <Dropdown meta={meta} percentage={percentage} input={input} />
                          )}
                        />
                      </Page.Field>
                      <div
                        css={css`
                          margin-top: 54px;
                          margin-left: 20px;
                          width: 100%;
                        `}>
                        <Field
                          name='primaryCustomAmountSlider'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <SliderWithValueUnderneath
                              min='5'
                              max='100'
                              value={input.value}
                              onChange={input.onChange}
                              displayValue={false}
                              step='1'
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <Field
                        name='primaryAmountSelection'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <ClassNames>
                            {({ css, cx }) => (
                              <RadioGroup
                                className={css`
                                  display: flex;
                                  justify-content: space-between;
                                  flex-wrap: wrap;
                                `}
                                value={input.value}
                                onChange={input.onChange}>
                                {primaryInvestment.map(item => {
                                  if (
                                    item.balanceUsedPercent === values.primaryCustomAmountSlider
                                  ) {
                                    const label = () => {
                                      return (
                                        <>
                                          <Page.UseOfBalance>
                                            Use{' '}
                                            <Page.Percentage>
                                              {item.balanceUsedPercent}
                                              <Page.PercentSign>%</Page.PercentSign>
                                            </Page.Percentage>{' '}
                                            of balance
                                          </Page.UseOfBalance>
                                          <SVGText>
                                            {' '}
                                            ($
                                            {numberToDollars(
                                              item.remainingInvestmentBalance,
                                              true
                                            )}{' '}
                                            remains){' '}
                                          </SVGText>
                                        </>
                                      )
                                    }
                                    return (
                                      <RadioButton
                                        key={item.balanceUsedPercent}
                                        label={label()}
                                        value={item.monthlyAmount + ''}
                                        className={cx(
                                          css`
                                            position: relative;
                                            margin-bottom: 60px;
                                            width: 200px;
                                            margin-top: 145px;
                                          `,
                                          'radio-button'
                                        )}>
                                        <div
                                          css={css`
                                            position: absolute;
                                            left: 0px;
                                            top: -150px;
                                            bottom: 0px;
                                            right: 0px;
                                          `}>
                                          <SVGContainer>
                                            <CircleProgressBar
                                              amount={item.monthlyAmount}
                                              percentage={item.balanceUsedPercent}
                                              speed={50}
                                            />
                                          </SVGContainer>
                                        </div>
                                      </RadioButton>
                                    )
                                  }
                                  return null
                                })}
                              </RadioGroup>
                            )}
                          </ClassNames>
                        )}
                      />
                    </div>
                  </>
                )}
                {values.accounts === '2' || values.accounts === 'both' ? (
                  <Button
                    type='button'
                    onClick={form.mutators.moveToStepFour}
                    primary
                    label='Next'
                    width='140px'
                    css={css`
                      height: 45px;
                      align-self: flex-end;
                      z-index: 20;
                    `}
                  />
                ) : null}
              </BorderedCard>
            </div>
          </Condition>

          <Condition when='steps' includes={4}>
            <div>
              {values.accounts ? (
                values.accounts === '2' ? (
                  <>
                    <StepTitle>Step 2 of 3</StepTitle>
                    <Page.CardTitle>
                      When would you like {spouse.firstName} to begin receiving payments? You can
                      buy more lifetime income to start now or later. Starting later will cost less.
                    </Page.CardTitle>
                  </>
                ) : (
                  <>
                    <StepTitle>Step 4 of 5</StepTitle>
                    <Page.CardTitle>
                      Now let's model adding an account for your other half. When would you like
                      that to start?
                    </Page.CardTitle>
                  </>
                )
              ) : null}

              <BorderedCard>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}>
                  <Page.Field style={{ margin: 0, marginRight: 20 }}>
                    <Field
                      name='spouseStartingAge'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <Dropdown meta={meta} ages={spouseAges} input={input} />
                      )}
                    />
                  </Page.Field>

                  <div
                    css={css`
                      margin-top: 27px;
                      margin-left: 20px;
                      width: 100%;
                    `}>
                    <Field
                      name='spouseSlider'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <SliderWithValueUnderneath
                          min={spouse.age < 60 ? 60 : spouse.age}
                          max={spouse.age > 80 ? spouse.age : 80}
                          value={input.value}
                          onChange={input.onChange}
                          displayValue={false}
                          step='1'
                        />
                      )}
                    />
                  </div>
                </div>
                {values.accounts === '2' || values.accounts === 'both' ? (
                  <Button
                    type='button'
                    onClick={form.mutators.moveToStepFive}
                    primary
                    label='Next'
                    width='140px'
                    css={css`
                      height: 45px;
                      align-self: flex-end;
                    `}
                  />
                ) : null}
              </BorderedCard>
            </div>
          </Condition>

          <Condition when='steps' includes={5}>
            <div>
              {values.accounts ? (
                values.accounts === '2' ? (
                  <>
                    <StepTitle>Step 3 of 3</StepTitle>
                    <Page.CardTitle>
                      Let's look at investing some of the balance in {spouse.firstName}'s accounts
                      ($
                      {numberToDollars(totalSpouseBalanceExcludingPensionAndAnnuity, true)}) to get
                      more lifetime income. Amounts are before tax.
                    </Page.CardTitle>
                  </>
                ) : (
                  <>
                    <div
                      css={css`
                        display: flex;
                        align-items: flex-start;
                      `}>
                      <StepTitle
                        css={css`
                          margin-right: 8px;
                        `}>
                        Step 5 of 5
                      </StepTitle>
                      <HelpIcon tooltip={TooltipText.fundingAnnuity()} />
                    </div>
                    <Page.CardTitle>
                      Let's look at investing some of the balance in {spouse.firstName}'s accounts
                      ($
                      {numberToDollars(totalSpouseBalanceExcludingPensionAndAnnuity, true)}) to get
                      more lifetime income. Amounts are before tax.
                    </Page.CardTitle>
                  </>
                )
              ) : null}

              <BorderedCard>
                <Field
                  name='spouseAmountSelection'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <ClassNames>
                      {({ css, cx }) => (
                        <RadioGroup
                          className={css`
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                          `}
                          value={input.value}
                          onChange={input.onChange}>
                          {spouseInvestment.map(item => {
                            if (
                              item.balanceUsedPercent === 75 ||
                              item.balanceUsedPercent === 50 ||
                              item.balanceUsedPercent === 25
                            ) {
                              const label = () => {
                                return (
                                  <>
                                    <Page.UseOfBalance>
                                      Use{' '}
                                      <Page.Percentage>
                                        {item.balanceUsedPercent}
                                        <Page.PercentSign>%</Page.PercentSign>
                                      </Page.Percentage>{' '}
                                      of balance
                                    </Page.UseOfBalance>
                                    <SVGText>
                                      {' '}
                                      (${numberToDollars(
                                        item.remainingInvestmentBalance,
                                        true
                                      )}{' '}
                                      remains){' '}
                                    </SVGText>
                                  </>
                                )
                              }
                              return (
                                <RadioButton
                                  key={item.balanceUsedPercent}
                                  label={label()}
                                  value={item.monthlyAmount + ''}
                                  className={cx(
                                    css`
                                      position: relative;
                                      margin-bottom: 60px;
                                      width: 200px;
                                      margin-top: 145px;
                                    `,
                                    'radio-button'
                                  )}>
                                  <div
                                    css={css`
                                      position: absolute;
                                      left: 0px;
                                      top: -150px;
                                      bottom: 0px;
                                      right: 0px;
                                    `}>
                                    <SVGContainer>
                                      <CircleProgressBar
                                        amount={item.monthlyAmount}
                                        percentage={item.balanceUsedPercent}
                                        speed={50}
                                      />
                                    </SVGContainer>
                                  </div>
                                </RadioButton>
                              )
                            }
                            return null
                          })}
                        </RadioGroup>
                      )}
                    </ClassNames>
                  )}
                />

                <Spacer size='12px' />

                <div
                  css={css`
                    display: flex;
                    z-index: 20;
                  `}>
                  <SVGWrapper
                    svg={values.spouseExpandedView ? CircleNegative : CirclePlus}
                    fill='#7a8e96'
                    size='large'
                    onClick={form.mutators.toggleSpouseMoreOptions}
                    css={css`
                      margin-right: 10px;
                    `}
                  />
                  <Page.Text
                    css={css`
                      cursor: pointer;
                    `}
                    onClick={form.mutators.toggleSpouseMoreOptions}>
                    <MoreOptionsText>More options</MoreOptionsText>
                  </Page.Text>
                </div>

                {values.spouseExpandedView && (
                  <>
                    <div
                      css={css`
                        display: flex;
                        margin: 20px 0;
                      `}>
                      <Page.Field style={{ margin: 0 }}>
                        <Field
                          name='spouseCustomPercentage'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <Dropdown meta={meta} percentage={percentage} input={input} />
                          )}
                        />
                      </Page.Field>
                      <div
                        css={css`
                          margin-top: 54px;
                          margin-left: 20px;
                          width: 100%;
                        `}>
                        <Field
                          name='spouseCustomAmountSlider'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <SliderWithValueUnderneath
                              style={{ paddingTop: 12 }}
                              min='5'
                              max='100'
                              value={input.value}
                              onChange={input.onChange}
                              displayValue={false}
                              step='1'
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <Field
                        name='spouseAmountSelection'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <ClassNames>
                            {({ css, cx }) => (
                              <RadioGroup
                                className={css`
                                  display: flex;
                                  justify-content: space-between;
                                  flex-wrap: wrap;
                                `}
                                value={input.value}
                                onChange={input.onChange}>
                                {spouseInvestment.map(item => {
                                  if (item.balanceUsedPercent === values.spouseCustomAmountSlider) {
                                    const label = () => {
                                      return (
                                        <>
                                          <Page.UseOfBalance>
                                            Use{' '}
                                            <Page.Percentage>
                                              {item.balanceUsedPercent}
                                              <Page.PercentSign>%</Page.PercentSign>
                                            </Page.Percentage>{' '}
                                            of balance
                                          </Page.UseOfBalance>
                                          <SVGText>
                                            {' '}
                                            ($
                                            {numberToDollars(
                                              item.remainingInvestmentBalance,
                                              true
                                            )}{' '}
                                            remains){' '}
                                          </SVGText>
                                        </>
                                      )
                                    }
                                    return (
                                      <RadioButton
                                        key={item.balanceUsedPercent}
                                        label={label()}
                                        value={item.monthlyAmount + ''}
                                        className={cx(
                                          css`
                                            position: relative;
                                            margin-bottom: 60px;
                                            width: 200px;
                                            margin-top: 145px;
                                          `,
                                          'radio-button'
                                        )}>
                                        <div
                                          css={css`
                                            position: absolute;
                                            left: 0px;
                                            top: -150px;
                                            bottom: 0px;
                                            right: 0px;
                                          `}>
                                          <SVGContainer>
                                            <CircleProgressBar
                                              amount={item.monthlyAmount}
                                              percentage={item.balanceUsedPercent}
                                              speed={50}
                                            />
                                          </SVGContainer>
                                        </div>
                                      </RadioButton>
                                    )
                                  }
                                  return null
                                })}
                              </RadioGroup>
                            )}
                          </ClassNames>
                        )}
                      />
                    </div>
                  </>
                )}
              </BorderedCard>
            </div>
          </Condition>

          {submitError && <FormError err={submitError} />}
          {values.primaryAmountSelection && totalPrimaryBalanceExcludingPensionAndAnnuity === 0 && (
            <FormError
              err={
                'Your investment balance must be greater than $0 to model the purchase of an annuity.'
              }
            />
          )}
          {values.spouseAmountSelection && totalSpouseBalanceExcludingPensionAndAnnuity === 0 && (
            <FormError
              err={
                'Your investment balance must be greater than $0 to model the purchase of an annuity.'
              }
            />
          )}

          <ButtonsBox>
            <BackButton backgroundColor='#FFFFFF' onClick={handleCancel}>
              Cancel
            </BackButton>
            {/* primary only */}
            {values.accounts === '1' ? (
              <NextButton
                onClick={handleSubmit}
                disabled={
                  !values.primaryAmountSelection ||
                  totalPrimaryBalanceExcludingPensionAndAnnuity === 0 ||
                  submitting
                }>
                Recalculate
              </NextButton>
            ) : null}
            {/* spouse only */}
            {values.accounts === '2' ? (
              <NextButton
                onClick={handleSubmit}
                disabled={
                  !values.spouseAmountSelection ||
                  totalSpouseBalanceExcludingPensionAndAnnuity === 0 ||
                  submitting
                }>
                Recalculate
              </NextButton>
            ) : null}
            {values.accounts === 'both' ? (
              <NextButton
                onClick={handleSubmit}
                disabled={
                  !values.primaryAmountSelection ||
                  !values.spouseAmountSelection ||
                  submitting ||
                  totalPrimaryBalanceExcludingPensionAndAnnuity === 0
                }>
                Recalculate
              </NextButton>
            ) : null}
          </ButtonsBox>
        </MainWrapper>
      )}
    />
  )
}

export default inject('store')(observer(MarriedOrSingleAnnuity))

const MainWrapper = styled.div`
  margin-left: 15px;
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;

  .radio-button {
    & > div {
      border-color: #4a606a;
    }
  }

  .percentageDiv > div > div > div > div::after {
    position: absolute;
    content: '%';
    color: #7a8e96;
    transform: rotate(0deg);
    right: 0px;
    top: 2px;
    font-size: 18px;
  }
`
const Error = styled.span`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
`
const BorderedCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  border: ${p => p.theme.actionItemsBoxBorder};
  border-radius: 8px;
  background-color: #ffffff;
  margin: 10px 0;
  padding: 1rem;
  padding-right: 32px; // fix
  margin-bottom: 20px; // fix
`
const SVGContainer = styled.div`
  position: relative;
  height: 160px;
  width: 200px;
  margin-right: 20px;
  cursor: pointer;
`
const SVGText = styled.span`
  font-size: 0.85rem;
  color: #7a8e96;
  position: absolute;
  left: 16%;
`
const StepTitle = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 20px;
`
const MoreOptionsText = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`
const Dropdown = ({ ages = [], input, percentage, meta }) => {
  if (percentage) {
    const percentageOptions = () => {
      const arr = []
      percentage.map(percent => arr.push({ label: percent, value: percent }))
      return arr
    }
    return (
      <div className='percentageDiv'>
        <DropdownElement
          error={meta.error}
          name={input.name}
          onBlur={input.onBlur}
          onChange={(name, value) => input.onChange(value)}
          options={percentageOptions()}
          placeholder=''
          selected={input.value + ''}
          showError={meta.touched}
          width='75px'
        />
      </div>
    )
  }

  const agesOption = () => {
    const arr = []
    ages.map(age => arr.push({ label: age, value: age }))
    return arr
  }

  return (
    <div
      css={css`
        margin-top: -29px;
      `}>
      <DropdownElement
        error={meta.error}
        name={input.name}
        onBlur={input.onBlur}
        onChange={(name, value) => input.onChange(value)}
        options={agesOption()}
        placeholder={input.value + ''}
        selected={input.value}
        showError={meta.touched}
        width='75px'
      />
    </div>
  )
}

const Accounts = ({ spouse, displayName }) => {
  const labelForPerson = `In ${displayName}'s name only`
  const labelForSpouse = `In ${spouse.firstName}'s name only`
  const labelForBoth = `One in ${displayName}'s name; a second in ${spouse.firstName}'s`

  return (
    <Field
      name='accounts'
      subscription={{ value: true, touched: true, error: true }}
      render={({ input, meta }) => {
        return (
          <RadioGroup value={input.value} onChange={input.onChange}>
            <RadioButton className='radio-button' label={labelForBoth} value='both' />
            <RadioButton className='radio-button' label={labelForPerson} value='1' />
            <RadioButton className='radio-button' label={labelForSpouse} value='2' />
            {(meta.error || meta.submitError) && meta.touched && (
              <Error>{meta.error || meta.submitError}</Error>
            )}
          </RadioGroup>
        )
      }}
    />
  )
}
