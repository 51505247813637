import { css, useTheme } from '@emotion/react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { HelpPersonIcon, Spacer, TooltipText, HelpIcon } from '../../../components'
import { numberToDollars } from '../../../utils'
import { RangeScale } from '../elements'
import AssetClassBreakdown from './AssetClassBreakdown'

const SubHeader = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  line-height: 1.25;
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : null)};
  align-items: ${p => (p.alignItems ? p.alignItems : 'center')};
  flex-wrap: wrap;

  margin: ${p => (p.childMargin ? '0 -8px' : null)};

  & > * {
    margin: ${p => (p.childMargin ? '0 8px' : null)};
  }
`
const SubLabel = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 1.125rem;
  max-width: 400px;
  padding: 2px 0;
  line-height: 1.4;
`
const RightSideContainer = styled.div`
  border: 1px solid #e6e9ea;
  background-color: #f0f4f5;
  box-shadow: 0 2px 2px 0 rgba(74, 96, 106, 0.6);
  padding: 1rem 1.5rem;
`
const RSValue = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1.25rem;
`
const RSLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1.25rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  padding: 4px 0px 6px 0px;
`
const RangeScaleContainer = styled.span`
  position: relative;
  top: 3px;
`
const RSAmount = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1.375rem;
  font-weight: ${p => (p.bold ? '600' : null)};

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`

const ContributionsSubsection = observer(({ store }) => {
  const {
    person: { displayName: clientName, maritalStatus, includeSpouse, id },
    spouse: { firstName },
    spouseAccountName,
    spouseHasEmployer,
    special457,
    special403b,
    institutionalAccount,
    additionalEmployerSponsoredAccounts,
    config: { isRetail },
    employerAccounts,
    plan: {
      limits: { annualContributionLimit },
    },
  } = store

  const {
    totalAnnualSavings,
    totalAnnualPercentage,
    total403bAmount,
    total403bSavings,
    total457Amount,
    total457Savings,
    totalAnnualSavingsSpouse,
    totalAnnualPercentageSpouse,
  } = store.modifiedCase || store.recommendedCase

  const showInstitutionalAccount =
    institutionalAccount && institutionalAccount.contributionEligibility

  const account401KOr401A = employerAccounts.filter(
    account =>
      account.contributionEligibility &&
      account.participantId === id &&
      (account.planType === 1 || account.planType === 23)
  )[0]

  return (
    <>
      {!isRetail && showInstitutionalAccount && (
        <div>
          <RSLabel>
            {clientName ? `${clientName}'s` : ''} {institutionalAccount.name} contributions
          </RSLabel>
          <FlexRow childMargin>
            <RSAmount bold gray>
              {numberToDollars(totalAnnualSavings, true)}
            </RSAmount>
            <SubLabel>
              {`(${numberToDollars(totalAnnualPercentage, true)}% of ${
                clientName ? `${clientName}'s` : 'your'
              } income)`}
            </SubLabel>
          </FlexRow>
          <Spacer space='2px' />
          <SubLabel>Annual total</SubLabel>
        </div>
      )}

      {isRetail && (
        <div>
          <RSLabel>
            {clientName ? `${clientName}'s` : ''} {account401KOr401A?.name} contributions
          </RSLabel>
          <FlexRow childMargin>
            <RSAmount bold gray>
              {numberToDollars(totalAnnualSavings, true)}
            </RSAmount>
            <SubLabel>
              {`(${numberToDollars(totalAnnualPercentage, true)}% of ${
                clientName ? `${clientName}'s` : 'your'
              } income)`}{' '}
              {totalAnnualSavings > annualContributionLimit && (
                <HelpIcon
                  tooltip={TooltipText.whyDoesTheTotalContributionAppearToBeMoreThanWhatsAllowed()}
                />
              )}
            </SubLabel>
          </FlexRow>
          <Spacer space='2px' />
          <SubLabel>Annual total</SubLabel>
        </div>
      )}

      {additionalEmployerSponsoredAccounts.map(account => (
        <RSLabel
          key={account.type + account.id}
          css={css`
            margin: 1rem 0;
          `}>
          {clientName ? `${clientName}'s` : 'Your'} {account.name}
        </RSLabel>
      ))}

      <div
        css={css`
          border-bottom: 1px solid #7a8e96;
          margin: 1.5rem 0;
        `}
      />

      {special403b && (
        <>
          <RSLabel>{`${clientName ? `${clientName}'s` : 'Your'} 403(b) contributions`}</RSLabel>
          <FlexRow childMargin>
            <RSAmount bold gray>
              {numberToDollars(total403bAmount, true)}
            </RSAmount>
            <SubLabel>
              {`(${numberToDollars(total403bSavings, true)}% of ${
                clientName ? `${clientName}'s` : 'your'
              } income)`}
            </SubLabel>
          </FlexRow>
          <Spacer space='2px' />
          <SubLabel>Annual total</SubLabel>
          <div
            css={css`
              border-bottom: 1px solid #7a8e96;
              margin: 1.5rem 0;
            `}
          />
        </>
      )}

      {special457 && (
        <>
          <RSLabel>{`${clientName ? `${clientName}'s` : 'Your'} 457 contributions`}</RSLabel>
          <FlexRow childMargin>
            <RSAmount bold gray>
              {numberToDollars(total457Amount, true)}
            </RSAmount>
            <SubLabel>
              {`(${numberToDollars(total457Savings, true)}% of ${
                clientName ? `${clientName}'s` : 'your'
              } income)`}
            </SubLabel>
          </FlexRow>
          <Spacer space='2px' />
          <SubLabel>Annual total</SubLabel>
          <div
            css={css`
              border-bottom: 1px solid #7a8e96;
              margin: 1.5rem 0;
            `}
          />
        </>
      )}

      {maritalStatus && includeSpouse && spouseHasEmployer && (
        <>
          <RSLabel>
            {`${firstName ? `${firstName}'s` : 'Your'} ${spouseAccountName} contributions`}
          </RSLabel>
          <FlexRow childMargin>
            <RSAmount bold gray>
              {numberToDollars(totalAnnualSavingsSpouse, true)}
            </RSAmount>
            <SubLabel>
              {`(${numberToDollars(totalAnnualPercentageSpouse, true)}% of ${
                firstName ? `${firstName}'s` : 'your'
              } income)`}
            </SubLabel>
          </FlexRow>
          <Spacer space='2px' />
          <SubLabel>Annual total</SubLabel>
          <div
            css={css`
              border-bottom: 1px solid #7a8e96;
              margin: 1.5rem 0;
            `}
          />
        </>
      )}
    </>
  )
})

const AdditionalSavingsSubsection = observer(({ store }) => {
  const { outOfPlanMonthlySavings } = store.modifiedCase || store.recommendedCase

  return (
    <>
      <RSLabel>Savings in addition to retirement & other accounts</RSLabel>
      <FlexRow childMargin>
        <RSAmount bold gray>
          {numberToDollars(outOfPlanMonthlySavings * 12, true)}
        </RSAmount>
        <SubLabel>
          ($
          {numberToDollars(outOfPlanMonthlySavings, true)} per month)
        </SubLabel>
      </FlexRow>
      <Spacer space='2px' />
      {!!outOfPlanMonthlySavings && (
        <FlexRow childMargin>
          <HelpPersonIcon
            size='xLarge'
            helpLabel='Already saving to other accounts'
            tooltip={TooltipText.additionalSavings()}
          />
        </FlexRow>
      )}
      <div
        css={css`
          border-bottom: 1px solid #7a8e96;
          margin: 1.5rem 0;
        `}
      />
    </>
  )
})

const CompanyStockSubsection = observer(({ store }) => {
  const { features } = store
  const { displayName } = store.person
  const { companyStocks, unrestrictedCompanyStocks, restrictedCompanyStocks } =
    store.modifiedCase || store.recommendedCase

  if (!features.companyStockEnabled || !companyStocks || companyStocks.length === 0) {
    return null
  }

  return (
    <>
      <RSLabel>{displayName}'s company stock</RSLabel>

      {unrestrictedCompanyStocks.length > 0 && (
        <div
          css={css`
            color: #7a8e96;
            font-size: 14px;
            margin: 8px 0;
          `}>
          <div>UNRESTRICTED</div>
          {unrestrictedCompanyStocks.map(companyStock => (
            <div
              key={companyStock.positionId}
              css={css`
                margin: 8px 0 8px 20px;
              `}>
              <div>
                Sell{' '}
                <span
                  css={css`
                    color: #022a3a;
                  `}>
                  {companyStock.percentToSell}
                </span>
                % ($
                <span
                  css={css`
                    color: #022a3a;
                  `}>
                  {numberToDollars(companyStock.amountToSell, true)}
                </span>
                )
              </div>
              <div>{companyStock.securityName}</div>
            </div>
          ))}
        </div>
      )}

      {restrictedCompanyStocks.length > 0 && (
        <div
          css={css`
            color: #7a8e96;
            font-size: 14px;
            margin: 8px 0;
          `}>
          <div>RESTRICTED</div>
          {restrictedCompanyStocks.map(companyStock => (
            <div
              key={companyStock.positionId}
              css={css`
                margin: 8px 0 8px 20px;
              `}>
              <div>
                Sell{' '}
                <span
                  css={css`
                    color: #022a3a;
                  `}>
                  {companyStock.percentToSell}
                </span>
                % ($
                <span
                  css={css`
                    color: #022a3a;
                  `}>
                  {numberToDollars(companyStock.amountToSell, true)}
                </span>
                )
              </div>
              <div>{companyStock.securityName}</div>
            </div>
          ))}
        </div>
      )}

      <div
        css={css`
          border-bottom: 1px solid #7a8e96;
          margin: 1.5rem 0;
        `}
      />
    </>
  )
})

const StockOptionsSubsection = observer(({ store }) => {
  const { features } = store
  const { stockOptions, includedStockOptions } = store.modifiedCase || store.recommendedCase

  if (!features.stockOptionsEnabled || !stockOptions || stockOptions.length === 0) {
    return null
  }

  const vestedOptionsIncludedInProjections = _.sumBy(includedStockOptions, stockOption =>
    stockOption.vested ? stockOption.quantity : 0
  )

  return (
    <>
      <RSLabel>Vested stock options</RSLabel>

      <div>
        <span
          css={css`
            color: #022a3a;
            font-size: 18px;
          `}>
          {vestedOptionsIncludedInProjections}
        </span>{' '}
        <span
          css={css`
            color: #7a8e96;
            font-size: 14px;
            font-weight: 300;
          `}>
          No. of options included in projections
        </span>
      </div>

      <div
        css={css`
          border-bottom: 1px solid #7a8e96;
          margin: 1.5rem 0;
        `}
      />
    </>
  )
})

const RiskSubsection = observer(({ store }) => {
  const { riskLevelText, withinRange, riskScaleIndex } = store.modifiedCase || store.recommendedCase
  const theme = useTheme()

  return (
    <>
      <RSLabel>Risk</RSLabel>
      <FlexRow childMargin>
        <RSValue>{riskLevelText}</RSValue>
        {riskLevelText && withinRange && (
          <RangeScaleContainer>
            <RangeScale rectangleActive={riskScaleIndex} theme={theme} />
          </RangeScaleContainer>
        )}
      </FlexRow>
      <SubLabel
        css={css`
          margin: 4px 0;
        `}>
        All accounts for which GuidedChoice is providing investment advice
      </SubLabel>
      <FlexRow childMargin>
        <HelpPersonIcon
          size='xLarge'
          helpLabel='Preferred range of investment mix'
          tooltip={TooltipText.preferredRangeInvestmentMix()}
        />
      </FlexRow>
      <FlexRow childMargin>
        <AssetClassBreakdown />
      </FlexRow>
    </>
  )
})

const HowToGetThere = ({ store }) => {
  return (
    <div>
      <div
        css={css`
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        `}>
        <SubHeader>How to get there</SubHeader>
        <div
          css={css`
            padding-bottom: 16px;
          `}>
          <HelpIcon helpLabel="What's changed?" tooltip={TooltipText.whatsChanged()} />
        </div>
      </div>

      <RightSideContainer>
        <ContributionsSubsection store={store} />

        <AdditionalSavingsSubsection store={store} />

        <CompanyStockSubsection store={store} />

        <StockOptionsSubsection store={store} />

        <RiskSubsection store={store} />
      </RightSideContainer>
    </div>
  )
}

export default inject('store')(observer(HowToGetThere))
