import { observer, inject } from 'mobx-react'

import SingleAnnuity from './SingleAnnuity'
import MarriedOrSingleAnnuity from './MarriedOrSingleAnnuity'

function AnnuityDashboard({
  setDrawer,
  handleCancel,
  store: {
    totalSpouseBalanceExcludingPensionAndAnnuity,
    person: { includeSpouse, maritalStatus },
  },
}) {
  if (includeSpouse && maritalStatus && totalSpouseBalanceExcludingPensionAndAnnuity > 0) {
    return <MarriedOrSingleAnnuity setDrawer={setDrawer} handleCancel={handleCancel} />
  }
  return <SingleAnnuity setDrawer={setDrawer} handleCancel={handleCancel} />
}

export default inject('store')(observer(AnnuityDashboard))
