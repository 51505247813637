import { css } from '@emotion/react'
import Grid from './Grid'

// Breakdown Mix Labels
const MixLabels = ({ hideOld, theme }) => {
  return (
    <Grid
      oldValue={
        !hideOld && (
          <div
            css={css`
              font-size: 18px;
              margin: 0.5rem 1rem 0.25rem 0;
            `}>
            Old Mix
          </div>
        )
      }
      newValue={
        <div
          css={css`
            font-size: 18px;
            margin: 0.5rem 0 0.25rem 1rem;
            color: ${theme.primaryColor};
          `}>
          New Mix
        </div>
      }
      separator={false}
      hideOld={hideOld}
    />
  )
}

export default MixLabels
