import { useRef } from 'react'
import { observer, inject } from 'mobx-react'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'
import { numberToDollars } from '../../../utils/utils'
import { Conversation, ReturnAnnually } from '../../../assets/icons'

function MonthlyRetirementIncomeForYear(props) {
  const {
    guide: {
      date,
      guidePages: {
        pages: { findByName, addPage },
      },
      spendingRetirementIncome,
    },
    store: {
      config: {
        template: { brandName, supportGroup, supportLine, supportName },
        isRetail,
      },
    },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'monthlyRetirementIncome' })
    willMount.current = false
  }
  const page = findByName('monthlyRetirementIncome')

  const config = ({ date }) => {
    const fullYear = new Date().getFullYear()
    return {
      title: `Your monthly retirement income for ${fullYear}`,
      from: date,
      to: fullYear,
      salary: spendingRetirementIncome?.salary,
      monthly: spendingRetirementIncome?.monthlyIncome,
      annually: spendingRetirementIncome?.annuallyIncome,
      annuity: spendingRetirementIncome?.annuities,
      ssi: spendingRetirementIncome?.socialSecurity,
      investments: spendingRetirementIncome?.investments,
      pensions: spendingRetirementIncome?.pensions,
      totalIncome: spendingRetirementIncome?.totalIncome,
    }
  }

  const {
    title,
    from,
    to,
    annuity,
    monthly,
    annually,
    ssi,
    salary,
    investments,
    pensions,
    totalIncome,
  } = config({ date })

  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.Title>{title}</Wrapper.Title>
        <Wrapper.GrayBox>
          <Wrapper.BlueText>
            Your Personalized Retirement Income Guide is intended to be used{' '}
          </Wrapper.BlueText>
          <Wrapper.BlueTextBold>
            from {from} to December 31, {to}.
          </Wrapper.BlueTextBold>
          <Wrapper.BlueTextSmall>
            Please visit {brandName} for an updated Guide <br /> when the new year comes around.
          </Wrapper.BlueTextSmall>
          <Wrapper.ReturnAnnuallyIcon>
            <ReturnAnnually />
          </Wrapper.ReturnAnnuallyIcon>
          <Spacer space='40pt' />

          <Wrapper.GreyBoxWrapper>
            <Wrapper.ConversationIcon>
              <Conversation />
            </Wrapper.ConversationIcon>
            <Wrapper.BoldText>
              If you need any assistance with this Guide,
              <br />
              please call {supportGroup ? (isRetail ? supportGroup : 'the ' + supportGroup) : ''}
              {!isRetail && ` via ${supportName} `} at {supportLine}.
            </Wrapper.BoldText>
            <Wrapper.ConversationIcon />
          </Wrapper.GreyBoxWrapper>
        </Wrapper.GrayBox>
        <Spacer space='95pt' />
        <Spacer space='20pt' />

        <Wrapper.TextLeftAlign>
          This year's monthly/annual amount you can spend safely.
        </Wrapper.TextLeftAlign>
        <Spacer space='10pt' />

        <Wrapper.RowBox>
          <Wrapper.Box>
            <Wrapper.MonthlyAmount>
              {numberToDollars(monthly, true)}
              <Wrapper.Divider />
              <Wrapper.ConsistencyText>Monthly</Wrapper.ConsistencyText>
            </Wrapper.MonthlyAmount>
          </Wrapper.Box>
          <Wrapper.Box>
            <Wrapper.MonthlyAmount>
              {numberToDollars(annually, true)}
              <Wrapper.Divider />
              <Wrapper.ConsistencyText>Annually</Wrapper.ConsistencyText>
            </Wrapper.MonthlyAmount>
          </Wrapper.Box>
        </Wrapper.RowBox>
        <Spacer space='10pt' />

        <Wrapper.AmountsBasedTitle>
          Where the money comes from each month for the after-tax spending amount above:
        </Wrapper.AmountsBasedTitle>

        {salary > 0 && (
          <Wrapper.AmountsBasedWrapper>
            <Wrapper.AmountsBasedAmount>{numberToDollars(salary, true)}</Wrapper.AmountsBasedAmount>
            <Wrapper.AmountsBasedSource>Salary</Wrapper.AmountsBasedSource>
          </Wrapper.AmountsBasedWrapper>
        )}

        {annuity > 0 && (
          <Wrapper.AmountsBasedWrapper>
            <Wrapper.AmountsBasedAmount>
              {numberToDollars(annuity, true)}
            </Wrapper.AmountsBasedAmount>
            <Wrapper.AmountsBasedSource>Annuities</Wrapper.AmountsBasedSource>
          </Wrapper.AmountsBasedWrapper>
        )}

        {ssi > 0 && (
          <Wrapper.AmountsBasedWrapper>
            <Wrapper.AmountsBasedAmount>{numberToDollars(ssi, true)}</Wrapper.AmountsBasedAmount>
            <Wrapper.AmountsBasedSource>Social Security</Wrapper.AmountsBasedSource>
          </Wrapper.AmountsBasedWrapper>
        )}

        {pensions > 0 && (
          <Wrapper.AmountsBasedWrapper>
            <Wrapper.AmountsBasedAmount>
              {numberToDollars(pensions, true)}
            </Wrapper.AmountsBasedAmount>
            <Wrapper.AmountsBasedSource>Pensions</Wrapper.AmountsBasedSource>
          </Wrapper.AmountsBasedWrapper>
        )}

        {investments > 0 && (
          <Wrapper.AmountsBasedWrapper>
            <Wrapper.AmountsBasedAmount>
              {numberToDollars(investments, true)}
            </Wrapper.AmountsBasedAmount>
            <Wrapper.AmountsBasedSource>Investments</Wrapper.AmountsBasedSource>
          </Wrapper.AmountsBasedWrapper>
        )}

        <Wrapper.AmountsBasedDivider />

        <Wrapper.AmountsBasedWrapper>
          <Wrapper.AmountsBasedAmount>
            {numberToDollars(totalIncome, true)}
          </Wrapper.AmountsBasedAmount>
          <Wrapper.AmountsBasedSource>Total</Wrapper.AmountsBasedSource>
        </Wrapper.AmountsBasedWrapper>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(MonthlyRetirementIncomeForYear))
