/* eslint-disable react/jsx-indent */

import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'

import { Button, Drawer, Loading } from '../../components'

import AboutYouCard from './about-you/AboutYouCard'
import AboutYouEdit from './about-you/AboutYouEdit'
import SimpleAboutYouEdit from './about-you/SimpleAboutYouEdit'

import AboutFamilyCard from './about-your-family/AboutFamilyCard'
import AboutFamilyEdit from './about-your-family/AboutFamilyEdit'

import ContactInfoCard from './contact-info/ContactInfoCard'
import ContactInfoEdit from './contact-info/ContactInfoEdit'
import SimpleContactInfoEdit from './contact-info/SimpleContactInfoEdit'

import HistoricalDataCard from './historical-data/HistoricalDataCard'
import HistoricalDataDrawer from './historical-data/HistoricalDataDrawer'

import LoginCard from './login/LoginCard'

import ServicesCard from './services/ServicesCard'
import AutoRiskAdjustEdit from './services/AutoRiskAdjustEdit'
import DiscontinueForm from './services/DiscontinueForm'
import DiscontinuePTDPlusForm from './services/DiscontinuePTDPlusForm'
import DiscontinueRetail from './services/DiscontinueRetail'
import ReinstateRetail from './services/ReinstateRetail'

const PageTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-weight: 300;
  font-size: 3.125rem;
`

const mapDrawerToTitle = {
  aboutYou: 'About you',
  aboutFamily: 'About your family',
  contactInfo: 'Contact & communications',
  historicalData: 'Historical data',
  autoRiskLevelAdjust: 'GuidedChoice services',
  discontinue: 'GuidedChoice services',
  discontinuePTDPlus: 'GuidedChoice services',
  discontinueRetail: 'Discontinue subscription',
  reinstateRetail: 'Reinstate automatic renewal',
}

class Profile extends Component {
  state = { drawer: '', drawerWidth: '0%', status: 'loading' }

  renderChildren = type => {
    const { features } = this.props.store

    const drawerContentMap = {
      aboutYou: features.enablePreferredName ? (
        <AboutYouEdit onClose={this.handleClose} />
      ) : (
        <SimpleAboutYouEdit onClose={this.handleClose} />
      ),
      aboutFamily: <AboutFamilyEdit onClose={this.handleClose} />,
      contactInfo: features.enableMobilePhone ? (
        <ContactInfoEdit onClose={this.handleClose} />
      ) : (
        <SimpleContactInfoEdit onClose={this.handleClose} />
      ),
      historicalData: <HistoricalDataDrawer onClose={this.handleClose} />,
      autoRiskLevelAdjust: <AutoRiskAdjustEdit onClose={this.handleClose} />,
      discontinue: <DiscontinueForm onClose={this.handleClose} />,
      discontinuePTDPlus: <DiscontinuePTDPlusForm onClose={this.handleClose} />,
      discontinueRetail: (
        <DiscontinueRetail
          onClose={() => {
            this.props.store.getSubscriptions()
            this.handleClose()
          }}
        />
      ),
      reinstateRetail: <ReinstateRetail onClose={this.handleClose} />,
    }

    return drawerContentMap[type] || null
  }

  showAboutYou = () => this.setState({ drawer: 'aboutYou', drawerWidth: '80%' })

  showAboutFamily = () => this.setState({ drawer: 'aboutFamily', drawerWidth: '80%' })

  showContactInfo = () => this.setState({ drawer: 'contactInfo', drawerWidth: '80%' })

  showHistoricalData = () => this.setState({ drawer: 'historicalData', drawerWidth: '80%' })

  showAutoRiskLevelAdjust = () =>
    this.setState({ drawer: 'autoRiskLevelAdjust', drawerWidth: '80%' })

  showDiscontinue = () => this.setState({ drawer: 'discontinue', drawerWidth: '50%' })

  showDiscontinuePTDPlus = () => this.setState({ drawer: 'discontinuePTDPlus', drawerWidth: '80%' })

  showDiscontinueRetail = () => this.setState({ drawer: 'discontinueRetail', drawerWidth: '50%' })

  showReinstateRetail = () => this.setState({ drawer: 'reinstateRetail', drawerWidth: '50%' })

  handleClose = () => this.setState({ drawer: '', drawerWidth: '0%' })

  fetchRetirementStatusesForSpendDown = async () => {
    try {
      await this.props.store.getPrimaryRetirementStatus()
      if (this.props.store.person.includeSpouse) {
        await this.props.store.getSpouseRetirementStatus()
      }
      this.setState({
        ...this.state,
        status: 'done',
      })
    } catch (err) {
      console.error(err)
      this.setState({ status: 'error' })
    }
  }

  componentDidMount() {
    this.fetchRetirementStatusesForSpendDown()
    this.props.store.getSubscriptions()
  }

  render() {
    const { features, config } = this.props.store
    const { changeSavingsList } = this.props.store.userLogs

    const { isRetail } = config

    if (this.state.status === 'loading') {
      return <Page.Loading />
    }

    if (this.state.status === 'error') {
      return (
        <Page.ErrorMessage>Oops! Something went wrong, please try again later</Page.ErrorMessage>
      )
    }

    return (
      <div
        css={css`
          max-width: 1200px;
          margin: auto;
          padding: 1rem;
        `}>
        <PageTitle>Profile</PageTitle>

        <AboutYouCard showAboutYou={this.showAboutYou} />

        {(features.enableSpouse || features.enableDependents) && (
          <AboutFamilyCard showAboutFamily={this.showAboutFamily} />
        )}

        <ContactInfoCard showContactInfo={this.showContactInfo} />

        <ServicesCard
          showAutoRiskLevelAdjust={this.showAutoRiskLevelAdjust}
          showDiscontinue={this.showDiscontinue}
          showDiscontinuePTDPlus={this.showDiscontinuePTDPlus}
          showDiscontinueRetail={this.showDiscontinueRetail}
          showReinstateRetail={this.showReinstateRetail}
          isRetail={isRetail}
        />

        {(features.enableLoginHistory ||
          features.enablePreviousGuides ||
          changeSavingsList.length > 0) && (
          <HistoricalDataCard showHistoricalData={this.showHistoricalData} />
        )}

        {features.editLoginInfo && <LoginCard />}

        {config.template.overallPageComponent === 'Simplified' && (
          <div
            css={css`
              text-align: right;
              margin-bottom: 45px;
              max-width: 900px;
            `}>
            <Button
              primary
              width='100px'
              label='Done'
              onClick={() => this.props.history.push('/overall')}
            />
          </div>
        )}

        <Drawer
          active={Boolean(this.state.drawer)}
          title={mapDrawerToTitle[this.state.drawer]}
          subtitle='Edit profile'
          width={this.state.drawerWidth}>
          {this.state.drawer && this.renderChildren(this.state.drawer)}
        </Drawer>
      </div>
    )
  }
}

export default inject('store')(observer(Profile))

const Page = styled.div`
  /* max-width: 1200px;
  margin: auto;
  padding: 1rem; */
`

Page.Loading = () => (
  <div
    css={css`
      margin: 20px;
    `}>
    <Loading />
  </div>
)

Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`

Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`
