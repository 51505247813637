import { types, getEnv } from 'mobx-state-tree'
import { Security } from '../../../models/Investments'
const { model, enumeration, maybeNull } = types

const AddNewInvestmentState = model({
  status: enumeration(['loading', 'done', 'error']),
  security: maybeNull(Security),
})
  .views(self => ({
    // TODO: Move these into initialValues calculation
    get showPreTaxForm() {
      const { security } = self
      const {
        account: { preTaxContribAllowed },
        findPreTaxInvestmentsBySecurityId,
        preTaxBalance,
      } = getEnv(self).account

      return (
        security &&
        findPreTaxInvestmentsBySecurityId(security.id).length === 0 &&
        (preTaxContribAllowed || Boolean(preTaxBalance))
      )
    },
    get showRothForm() {
      const { security } = self
      const {
        account: { rothContribAllowed },
        findRothInvestmentsBySecurityId,
        rothBalance,
      } = getEnv(self).account

      return (
        security &&
        findRothInvestmentsBySecurityId(security.id).length === 0 &&
        (rothContribAllowed || Boolean(rothBalance))
      )
    },
    get showAfterTaxForm() {
      const { security } = self
      const {
        account: { posttaxContribAllowed },
        findAfterTaxInvestmentsBySecurityId,
        afterTaxBalance,
      } = getEnv(self).account

      return (
        security &&
        findAfterTaxInvestmentsBySecurityId(security.id).length === 0 &&
        (posttaxContribAllowed || Boolean(afterTaxBalance))
      )
    },
    get investmentAlreadyExistsForSecurity() {
      const { security } = self
      const {
        findPreTaxInvestmentsBySecurityId,
        findRothInvestmentsBySecurityId,
        findAfterTaxInvestmentsBySecurityId,
      } = getEnv(self).account

      return (
        security &&
        (Boolean(findPreTaxInvestmentsBySecurityId(security.id).length) ||
          Boolean(findRothInvestmentsBySecurityId(security.id).length) ||
          Boolean(findAfterTaxInvestmentsBySecurityId(security.id).length))
      )
    },
    get notInvestedAlreadyExistsForSecurity() {
      const { security } = self
      const { findNotInvestedBySecurityId } = getEnv(self).account
      return findNotInvestedBySecurityId(security.id).length > 0
    },
    get singleFormOnly() {
      const { showPreTaxForm, showRothForm, showAfterTaxForm } = self
      return [showPreTaxForm, showRothForm, showAfterTaxForm].filter(value => value).length === 1
    },
    get isEmployerAccount() {
      const { employerAccounts } = getEnv(self).store
      const {
        account: { type, id },
      } = getEnv(self).account
      return !!employerAccounts.find(account => account.type === type && account.id === id)
    },
    get showAddBalanceButton() {
      const {
        security,
        investmentAlreadyExistsForSecurity,
        notInvestedAlreadyExistsForSecurity,
        isEmployerAccount,
      } = self

      return (
        security &&
        !notInvestedAlreadyExistsForSecurity &&
        !investmentAlreadyExistsForSecurity &&
        isEmployerAccount
      )
    },
  }))
  .actions(self => ({
    setStatus(value) {
      self.status = value
    },
    setSecurity(security) {
      self.security = security
    },
  }))

export default AddNewInvestmentState
