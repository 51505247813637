/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'

import { Page } from '../style'
import { Spacer, Button } from '../../../../components'

function ReverseMortgage() {
  return (
    <Page.Container narrow>
      <Page.Header
        css={css`
          line-height: 2.75rem;
          font-size: 2.3rem;
        `}>
        Reverse mortgages
      </Page.Header>
      <Spacer space='21px;' />

      <Page.Paragraph>
        In a regular mortgage, you borrow money and pay it back with interest. In a reverse
        mortgage, you receive money from a lender, live in your home, and typically do not have to
        pay any money back until you sell your home, move out of your home, or pass away. Usually
        there are no income limits and the money received is not subject to income tax. However,
        they are <i>available only if your home is paid off</i>.
      </Page.Paragraph>
      <Spacer space='10px;' />

      <Page.Paragraph>There are three types of reverse mortgages:</Page.Paragraph>
      <Spacer space='1px;' />

      <Page.Ul>
        <Page.Li small>Single purpose</Page.Li>
        <Page.Li small>Federally insured home equity conversion, and</Page.Li>
        <Page.Li small>Proprietary</Page.Li>
      </Page.Ul>

      <Page.Paragraph>
        <b>Single purpose reverse mortgages</b> are the least expensive. They are not available
        everywhere and can be used for only one purpose, which is specified by the government or
        non-profit lender. For example, the lender might specify that the loan may be used to pay
        for home repairs, improvements, or property taxes. Most homeowners with low or moderate
        income can qualify for these loans.
      </Page.Paragraph>
      <Spacer space='8px;' />

      <Page.Paragraph>
        <b>Home Equity Conversion Mortgages</b> (HECMs) backed by the US Department of Housing and
        Urban Development (HUD) and <b>proprietary reverse mortgages</b> are more expensive than
        traditional home loans, and the up-front costs can be high. That's an important
        consideration, especially if you plan to stay in your home for just a short time or borrow a
        small amount. HECM loans are widely available, have no income or medical requirements, and
        can be used for any purpose.
      </Page.Paragraph>
      <Spacer space='8px;' />

      <Page.Paragraph>
        There are plenty of scams in the reverse mortgage market place, so be sure to do your
        homework before signing on the dotted line. You may be better off selling your home and
        renting something smaller.
      </Page.Paragraph>

      <p>For more information, search “reverse mortgage” on the following sites:</p>

      <Page.Ul>
        <Page.Li small>
          <Page.Link href='https://www.aarp.org/'>www.aarp.org</Page.Link>
        </Page.Li>
        <Page.Li small>
          <Page.Link href='https://www.hud.gov'>www.hud.gov</Page.Link>
        </Page.Li>
        <Page.Li small>
          <Page.Link href='https://www.consumer.ftc.gov'>www.consumer.ftc.gov</Page.Link>
        </Page.Li>
      </Page.Ul>
      <Spacer space='20px' />

      <Button
        type='button'
        onClick={() => window.close()}
        secondary
        label='Close'
        width='73px'
        css={css`
          height: 41px;
          font-size: 14px;
          text-align: center;
        `}
      />
      <Spacer space='20px' />
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(ReverseMortgage))
