import styled from '@emotion/styled'

export const Page = styled.div``
Page.Container = styled.div`
  font-family: Aleo, 'Helvetica Neue', sans-serif;
  padding: 10px;
  word-wrap: break-word;
`
Page.SeeWhereContainer = styled(Page.Container)`
  max-width: 700px;
`
Page.SuccessBox = styled.div`
  max-width: 690px;
  color: ${p => p.theme.congratulationsContainerTextColor};
  font-size: 2.25rem;
  line-height: 1.25;
  background-color: ${p => p.theme.congratulationsContainerColor};
  padding: 20px;
  border: ${p => p.theme.congratulationsContainerBorder};
  border-radius: 8px;
`
Page.HeaderAmount = styled.div`
  color: ${p => p.theme.headerAmountTextColor};
  font-family: 'Open Sans';
  font-size: 50px;
  font-weight: 600;
  line-height: 68px;

  &::before {
    content: '$';
    color: #4d9927;
    font-weight: 400;
  }
`
Page.BasedText = styled.div`
  color: ${p => p.theme.basedTextColor};
  font-family: 'Open Sans';
  font-size: 24px;
  line-height: 36px;
`
Page.DollarValue = styled.div`
  color: ${p => p.theme.dollarValueTextColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`

Page.SecondaryText = styled.div`
  font-size: 1.15rem;
  margin-bottom: 10px;
`

Page.SmallText = styled.div`
  font-size: 0.75rem;
  color: #7a8e96;
  font-family: Aleo, 'Helvetica Neue', sans-serif;
  margin: 10px 0;
`
Page.TooltipLabel = styled.div`
  color: ${p => p.theme.tooltipLabelTextColor};
  font-family: 'Open Sans';
  font-size: 32px;
  font-weight: 300;
  line-height: 43px;
  padding: 0 18px;
  cursor: pointer;
  user-select: none;
`
Page.InTheBoxBelowText = styled.span`
  color: ${p => p.theme.boxBelowTextColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
  padding: 0 18px;
  cursor: pointer;
  user-select: none;
`
Page.ShowBoxContainer = styled.div`
  border: ${p => p.theme.seeWhereBoxBorder};
  border-radius: 8px;
  margin-left: 43px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`
Page.Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  position: relative;
`
Page.EditButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: white;
  font-size: 0.75rem;

  text-align: center;
  vertical-align: middle;
  height: 32px;
  width: 83px;
  border-radius: 2px;
  background-color: #4a606a;
`
Page.SubSection = styled.div`
  display: flex;
  align-items: baseline;
`
Page.LabelHeader = styled.div`
  text-align: right;
  flex: 2;
  color: ${p => p.theme.labelHeaderTextColor};
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  user-select: none;
`
Page.Label = styled.div`
  text-align: right;
  flex: 2;
  font-family: 'Open Sans';
  color: ${p => p.theme.labelTextColor};
  font-size: 14px;
  line-height: 19px;
`
Page.LabelWrapper = styled.div`
  text-align: right;
  flex: 2;
  font-family: 'Open Sans';
  color: #4a606a;
  font-size: 14px;
  line-height: 19px;
`
Page.Status = styled(Page.Label)`
  color: #f48024;
`
Page.Value = styled.div`
  flex: 1.5;
  padding-left: 10px;
  color: ${p => p.theme.valueTextColor};
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
`
Page.Amount = styled.div`
  flex: 1.5;
  padding-left: 10px;
  font-weight: 600;
  line-height: 27px;
  font-size: 20px;
  color: ${p => p.theme.amountTextColor};
  font-family: 'Open Sans';
  &::before {
    content: '$';
    color: #4d9927;
    padding-right: 3px;
  }
`
Page.ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1.75rem 0;
`
Page.SecondaryButton = styled.div`
  width: 240px;
  border-radius: 4px;
  background: #4a606a;
  cursor: pointer;
  padding: 8px;
  margin: 12px;
  &:hover {
    background: #42565e;
  }
  &:active {
    background: #324147;
  }
  & > div {
    color: #ffffff;
    text-align: center;
    font-size: 1.15rem;
    user-select: none;
    padding: 8px 0;
  }
`
Page.ButtonBox = styled.div`
  width: 240px;
  border-radius: 4px;
  background: ${p => p.theme.buttonColor};
  cursor: pointer;
  padding: 8px;
  margin: 12px;
  &:hover {
    background: ${p => p.theme.hoverButtonColor};
  }
  &:active {
    background: ${p => p.theme.activeButtonColor};
  }
  & > div {
    color: #ffffff;
    text-align: center;
    font-size: 1.15rem;
    user-select: none;
    padding: 8px 0;
  }
`
Page.ButtonContainer = styled.span`
  margin-left: 10px;
  border: 1px solid #7a8e96;
  border-radius: 50px;
  padding: 3px 0px;
`
Page.CustomButton = styled.button`
  padding: 4px 15px;
  border: none;
  background-color: ${({ active }) =>
    active ? p => p.theme.boxBelowActiveButtonColor : 'transparent'};
  border: ${({ active }) => (active ? p => p.theme.boxBelowButtonBorder : 'none')};
  outline: none;
  border-radius: 50px;
  margin: 0px -1px;
  color: ${p => p.theme.boxBelowButtonTextColor};
  cursor: pointer;
`

Page.SecondaryBox = styled.div`
  border: 1px solid rgb(230, 233, 234);
  background-color: rgb(240, 244, 245);
  box-shadow: rgba(74, 96, 106, 0.6) 0px 2px 2px 0px;
  padding: 1rem 1.5rem;
  font-size: 1.65rem;
  line-height: 1.25;
`
Page.WarningContainer = styled.div`
  border: 1px solid rgb(230, 233, 234);
  background-color: rgb(240, 244, 245);
  box-shadow: rgba(74, 96, 106, 0.6) 0px 2px 2px 0px;
  padding: 1rem 1.5rem;
  font-size: 1.15rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.25;
  display: flex;
  align-items: center;
  color: #f48024;
  max-width: 690px;
`
Page.CongratsText = styled.div`
  color: ${p => p.theme.congratsTextColor};
  font-family: 'Open Sans';
  font-size: 36px;
  line-height: 49px;
`
Page.CanAffordNeeds = styled.div`
  color: ${p => p.theme.canAffordNeedsColor};
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
`
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ paddingRight }) => paddingRight || '0'};
  color: ${p => p.theme.amountNeededTextColor};
`
Section.SectionTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 300;
  line-height: 41px;
`

Section.SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`
Section.ContributionsWrapper = styled.div`
  display: flex;
`
Section.SvgContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 3px;
`
Section.SvgContainerWithTitle = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 3px;
`
Section.SubTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 300;
  line-height: 33px;
`
Section.SecondaryText = styled.div`
  color: ${p => p.theme.secondaryTextColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 18px;
`
Section.PrimaryText = styled.div`
  color: ${p => p.theme.primaryColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 18px;
`
Section.SVGContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: grid;
  justify-content: center;
  text-align: center;
`
