import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { API_URL } from '../../../api'
import { NextButton } from '../../../components/styled'
import { LogoHeader } from '../../offboarding/elements'

const Container = styled.div`
  max-width: 1200px;
  padding: 8px;
  margin: auto;
`

function Discontinue() {
  return (
    <Container>
      <LogoHeader />

      <div
        css={css`
          color: #4a606a;
          font-size: 40px;
        `}>
        You're all set!
      </div>

      <div
        css={css`
          color: #4a606a;
          font-size: 18px;
          font-weight: 300;
          margin: 56px 0 40px;
        `}>
        Your transaction has been submitted. You should receive a confirmation email shortly.
      </div>

      <div
        css={css`
          text-align: right;
          margin: 40px 0 60px;
        `}>
        <NextButton onClick={() => window.open(`${API_URL.slice(0, -4)}logout`, '_self')}>
          Log off
        </NextButton>
      </div>
    </Container>
  )
}

export default Discontinue
