import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import styled from '@emotion/styled'

import { HelpIcon, Spacer, Text, WizardStep, TooltipText, SVGWrapper } from '../../../components'
import { Warning } from '../../../assets/icons'
import { planTypeToAccountLabel, typeToAPI } from '../../../constants'
import { API } from '../../../api'
import { css } from '@emotion/react'

const accountTypeToItems = {
  '401k': ['basics', 'contributions', 'investments'],
  '403b': ['basics', 'contributions', 'investments'],
  '457': ['basics', 'contributions457', 'investments'],
  'payroll-deduct-ira': ['basics', 'contributions', 'investments'],
  '401a': ['basics', 'contributions', 'investments'],
  'keogh': ['basics', 'contributions', 'investments'],
  'sep-ira': ['basics', 'contributions', 'investments'],
  'simple-ira': ['basics', 'contributions', 'investments'],
  'ira-rollover': ['basics', 'contributionsIRA', 'investments'],
  'ira-roth': ['basics', 'contributionsIRA', 'investments'],
  'ira-deductible': ['basics', 'contributionsIRA', 'investments'],
  'ira-non-deductible': ['basics', 'contributionsIRA', 'investments'],
  'brokerage-account': ['basics', 'investments'],
  'other-asset': ['basics', 'investments'],
  'pension': ['basics', 'payout'],
  'annuity-fixed': ['basics', 'payout'],
}

const DuplicateAccount = props => {
  const { match, history, store, location } = props
  const { anotherRetirementAccount, deleteAccount, getAllPensions } = store
  const { accountType, type } = match.params

  useEffect(() => {
    // check that the type is actually correct
    if (!_.includes(_.keys(accountTypeToItems), accountType)) {
      history.replace('/accounts/add')
    }
  }, [accountType, history])

  const handleDeleteAccount = async () => {
    await deleteAccount(anotherRetirementAccount.type, anotherRetirementAccount.id)
    await getAllPensions()
    props.history.push(`/accounts/add/${type}/${accountType}/basics`, { id: location?.state?.id })
  }

  const handleNext = async () => {
    try {
      await API.patch(typeToAPI[anotherRetirementAccount.type], {
        ...anotherRetirementAccount,
        contributionEligibility: false,
      })
      await props.store.getAccounts()
      await props.store.initializeActiveAccount()

      props.history.push(`/accounts/add/${type}/${accountType}/basics`, { id: location?.state?.id })
    } catch (err) {
      console.error(err)
    }
  }

  const title = `Is this a duplicate account?`

  return (
    <WizardStep title={title}>
      <Text
        style={{
          marginLeft: 15,
        }}>
        <div
          css={css`
            display: flex;
            & > div:nth-child(2) > span {
              display: flex;
              flex-direction: row-reverse;
            }
          `}>
          <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
          <HelpIcon tooltip={TooltipText.whyDoesThisMatter()}>
            <Text style={{ color: '#F48024', margin: '0px 8px' }}>Why does this matter?</Text>{' '}
          </HelpIcon>
        </div>
        <Spacer space='8px' />

        <Text>We think we may have found a duplicate account. Would you like to remove it?</Text>
        <Spacer space='8px' />

        <Text>
          {anotherRetirementAccount?.name}
          <br />
          {planTypeToAccountLabel[anotherRetirementAccount?.planType]}
        </Text>
        <Spacer space='28px' />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ButtonBox secondaryButton onClick={handleDeleteAccount}>
            <ButtonTopText>Delete</ButtonTopText>
            <ButtonBottomText>it's a duplicate</ButtonBottomText>
          </ButtonBox>

          <ButtonBox onClick={handleNext}>
            <ButtonTopText>Keep both</ButtonTopText>
            <ButtonBottomText>it's not a duplicate</ButtonBottomText>
          </ButtonBox>
        </div>

        <Spacer space='8px' />
      </Text>
    </WizardStep>
  )
}

const ButtonBox = styled.div`
  width: 240px;
  border-radius: 4px;
  background: ${p => (p.secondaryButton ? p.theme.lightestGray : p.theme.buttonColor)};
  cursor: pointer;
  padding: 12px 8px;
  margin: 12px;
  &:hover {
    background: ${p => (p.secondaryButton ? p.theme.secondaryColor : p.theme.hoverButtonColor)};
  }
  &:active {
    background: ${p => (p.secondaryButton ? p.theme.darkestGray : p.theme.activeButtonColor)};
  }
  min-height: 76px;
`

const ButtonTopText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 1.375rem;
  user-select: none;
`

const ButtonBottomText = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 0.875rem;
  user-select: none;
`

export default inject('store', 'account')(observer(DuplicateAccount))
