import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import { withRouter } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

import { API } from '../../../api'
import { WizardStep, MultiButtonInput, TooltipText } from '../../../components'
import { typeToAPI } from '../utils'

class EditBasics extends Component {
  handleSubmit = async values => {
    try {
      const {
        store,
        account: { account },
        match: {
          params: { type },
        },
      } = this.props

      // if different from initialValues, submit PATCH request
      if (!_.isEqual(this.initialValues(), values)) {
        const { planType, id, participantId } = account
        const response = await API.patch(typeToAPI[type], {
          planType,
          id,
          participantId,
          ...values,
        })
        applySnapshot(account, response.data)
      }

      await store.getAccounts()

      this.props.handleCancel()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  initialValues = () => {
    const {
      account: { adviced },
    } = this.props.account
    return { adviced }
  }

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={this.initialValues()}
        render={({ handleSubmit, submitting, submitError }) => (
          <div>
            <WizardStep
              onBackClick={this.props.handleCancel}
              onNextClick={handleSubmit}
              backButtonText='Cancel'
              nextButtonText='Save'
              loading={submitting}
              serverError={submitError}>
              <Field
                name='adviced'
                render={({ input, meta }) => (
                  <MultiButtonInput
                    name={input.name}
                    value={input.value}
                    label='Investment advice?'
                    onChange={(name, value) => input.onChange(value)}
                    onBlur={input.onBlur}
                    error={meta.error}
                    showError={meta.touched}
                    buttons={[
                      { text: 'Include', value: true },
                      { text: 'Exclude', value: false },
                    ]}
                    tooltip={TooltipText.includeInvestmentAdvice()}
                  />
                )}
              />
            </WizardStep>
          </div>
        )}
      />
    )
  }
}

export default withRouter(inject('store', 'account')(observer(EditBasics)))
