import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'

function CompanyStockForExpenses(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
    },
    store: { allPensions, selectedInstitutionalPension, spendingAcceptedCase },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'companyStockForExpenses' })
    willMount.current = false
  }

  let primaryInstitutionalPension = allPensions.filter(
    pension => pension.id === selectedInstitutionalPension.pensionId
  )[0]
  if (primaryInstitutionalPension === undefined) {
    primaryInstitutionalPension = {}
  }

  const listOfCompanyStocks = _.get(spendingAcceptedCase, 'companyStockNames', [])
    .map(stock => stock)
    .join(', ')

  const page = findByName('companyStockForExpenses')
  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.CompanyStockPageTitle>The fine print</Wrapper.CompanyStockPageTitle>
        <Spacer space='10pt' />
        <Wrapper.CompanyStockTitle>Using company stock for expenses</Wrapper.CompanyStockTitle>
        <Spacer space='5pt' />

        <Wrapper.CompanyStockIndentedText>
          Your withdrawal instructions include the use of company stock within your{' '}
          {listOfCompanyStocks} account(s) to meet your expenses for {new Date().getFullYear()}.
          Whether you should withdraw or transfer your company stock is up to you and your tax
          advisor, as there may be tax benefits depending on how you treat it.
        </Wrapper.CompanyStockIndentedText>
        <Spacer space='10pt' />

        <Wrapper.CompanyStockParagraphTitle>
          <b>Withdraw</b>
        </Wrapper.CompanyStockParagraphTitle>
        <Spacer space='2pt' />
        <Wrapper.CompanyStockIndentedText>
          While simply withdrawing your company stock from your account would be easiest, it may not
          be the most advantageous for you, as you would be required to pay ordinary income tax on
          the entire amount withdrawn.
        </Wrapper.CompanyStockIndentedText>
        <Spacer space='10pt' />

        <Wrapper.CompanyStockParagraphTitle>
          <b>Transfer</b>
        </Wrapper.CompanyStockParagraphTitle>
        <Spacer space='2pt' />
        <Wrapper.CompanyStockIndentedText>
          An alternative is to transfer your in-plan company stock holdings to a brokerage account
          in order to take advantage of Net Unrealized Appreciation (NUA) treatment. When you do
          this, you will pay ordinary income tax on the cost basis, but you won't have to pay on the
          appreciation until you actually sell your stock. As you do, you will pay capital gains tax
          on the appreciation, which will be at a rate that is likely to be much lower than your
          ordinary income tax rate.
        </Wrapper.CompanyStockIndentedText>
        <Spacer space='10pt' />

        <Wrapper.CompanyStockIndentedText>
          Because of the possibility for advantageous tax treatment, many people choose to transfer
          their company stock before they reach the age of having to make Required Minimum
          Distributions.
        </Wrapper.CompanyStockIndentedText>
        <Spacer space='10pt' />

        <Wrapper.CompanyStockIndentedText>
          We encourage you to consult a tax advisor to decide whether to take advantage of the NUA
          election.
        </Wrapper.CompanyStockIndentedText>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(CompanyStockForExpenses))
