import { observer, inject } from 'mobx-react'

import InstitutionalInvestments from './InstitutionalInvestments'
import OtherInvestments from './OtherInvestments'

function InvestmentsPage(props) {
  const {
    config: { isRetail },
  } = props.store

  return (
    <>
      {!isRetail && <InstitutionalInvestments />}
      <OtherInvestments />
    </>
  )
}

export default inject('store', 'guide')(observer(InvestmentsPage))
