import { observer, inject } from 'mobx-react'

import { SVGWrapper } from '../../../components'
import { PiggyBank, Warning } from '../../../assets/icons'
import { numberToDollars } from '../../../utils'
import { planTypeIdToAccountKey } from '../../../constants'
import {
  SectionBalance,
  DollarSign,
  SectionRow,
  SectionLabel,
  SectionSubLabel,
  NameField,
  BalanceField,
  EditButton,
  SVGContainer,
  SVGPlaceholder,
  WarningContainer,
  WarningMessage,
  ReviewButton,
} from './DashboardStyle'

const typeToPathKey = {
  Annuity: 'annuity',
  Institutional: 'institutional',
  NonGc: 'non-gc',
  Pension: 'pension',
}

function DashboardEmployerSponsoredAccount(props) {
  const {
    store: { institutionalAccountUnderReview },
  } = props

  function handleViewButton() {
    const { history, account } = props
    const { type, id, planType } = account
    const accountType = planTypeIdToAccountKey[planType]
    history.push(`/accounts/${typeToPathKey[type]}/${accountType}/${id}`)
  }

  function handleRetirementAccountReview() {
    const {
      history,
      account,
      store: { institutionalAccountUnderReview, institutionalAccount },
    } = props
    const { type, id, planType } = account
    const accountType = planTypeIdToAccountKey[planType]

    const isInstitutionalAccount = id === institutionalAccount?.id

    if (institutionalAccountUnderReview && isInstitutionalAccount) {
      history.push(`/accounts/add/${typeToPathKey[type]}/${accountType}/intro`, { id: id })
    } else {
      history.push(`/accounts/${typeToPathKey[type]}/${accountType}/${id}`)
    }
  }

  function findNamebyId() {
    const { store } = props
    const { participantId } = props.account
    const { displayName, id: personId } = store.person
    const { firstName, id: spouseId } = store.spouse

    if (participantId === personId) {
      return displayName
    } else if (participantId === spouseId) {
      return firstName
    } else {
      return ''
    }
  }

  const {
    features,
    account: { managedAccount },
    config: { templateId },
  } = props.store
  const { name, balance, type, contributing } = props.account

  // only atmos institutional pensions
  const canEdit = templateId === 4 && type === 'Pension'

  return (
    <div>
      <SectionRow id={props.id} minimum_bottom_padding={props.displayWarning}>
        <NameField>
          <SVGContainer>
            <SVGPlaceholder />
          </SVGContainer>
          <div>
            <SectionLabel>
              {name}
              {type === 'Institutional' && features.managedAccountAvailable && managedAccount
                ? '*'
                : ''}
            </SectionLabel>
            <SectionSubLabel>{findNamebyId()}</SectionSubLabel>
          </div>
        </NameField>
        <BalanceField>
          {contributing && (
            <SVGWrapper
              svg={PiggyBank}
              fill='#b6c0c4'
              hoverFill='#053240'
              size='medium'
              cursor='default'
            />
          )}
          <SectionBalance>
            <DollarSign>$</DollarSign>
            {numberToDollars(balance, true)}
          </SectionBalance>
          <EditButton onClick={handleViewButton}>{canEdit ? 'Edit' : 'View'}</EditButton>
        </BalanceField>
      </SectionRow>
      {institutionalAccountUnderReview && (
        <WarningContainer>
          <WarningMessage>
            <SVGContainer>
              <SVGWrapper svg={Warning} fill='#3A4D57' size='large' />{' '}
            </SVGContainer>
            <div>
              Please review and complete any missing information for this account.{' '}
              <ReviewButton onClick={handleRetirementAccountReview}>Review account</ReviewButton>
            </div>
          </WarningMessage>
        </WarningContainer>
      )}
    </div>
  )
}

export default inject('store')(observer(DashboardEmployerSponsoredAccount))
