/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

import { API } from '../../../api'
import {
  HelpIcon,
  WizardStep,
  Loading,
  CurrencyInput,
  InputField,
  PercentInput,
  MultiButtonInput,
  TooltipText,
} from '../../../components'

import { reduceValidationError, suffixWrapper } from '../../../utils'
import { accountTypeToAPI, filterValuesForPatch, typeToAPI } from '../utils'
import {
  schema,
  calculator,
  accountTypeAllowedKeys,
  accountTypeNextStep,
  initializeValues,
  createEditable,
  showZeroContributionsError,
} from './PaycheckContributionsUtils'

const AddPaycheckContributions = props => {
  const [status, setStatus] = useState('loading')
  const [institutionalContribMethod, setInstitutionalContribMethod] = useState(null)
  const {
    store,
    account: { account, control },
    match: {
      params: { type, accountType },
    },
    store: {
      institutionalAccountUnderReview,
      institutionalAccount,
      institutional: { rothContribAllowed, posttaxContribAllowed },
      features: { editInstitutionalAccount },
      plan: { contribMethodChangeAllowed, allowedContribMethod },
    },
    history,
  } = props

  const fetchInitialState = useCallback(async () => {
    try {
      const { id: accountId, participantId: personId } = account
      const accountControl = await API.post('account-control/edit', null, {
        params: { accountId, personId },
      })

      applySnapshot(control, accountControl.data)
      setStatus('done')

      setInstitutionalContribMethod(allowedContribMethod)
    } catch (err) {
      console.error(err)
      setStatus('error')
    }
  }, [account, allowedContribMethod, control])

  const isInstitutionalAccount = account.id === institutionalAccount?.id

  useEffect(() => {
    // If there's not a next step available for the account type, then they shouldn't be here
    const allowedAccountTypes = _.keys(accountTypeNextStep)
    if (!_.includes(allowedAccountTypes, accountType) || !_.get(account, 'id')) {
      return history.replace('/accounts/add')
    }

    fetchInitialState()
  }, [account, accountType, fetchInitialState, history])

  const onSubmit = async values => {
    const { planType, id, participantId } = account
    const pickedKeys = _.pick(values, accountTypeAllowedKeys[accountType])
    const requestValues = filterValuesForPatch(pickedKeys, account)

    try {
      let response
      if (isInstitutionalAccount && institutionalAccountUnderReview) {
        response = await API.patch(typeToAPI[type], {
          planType,
          id,
          participantId,
          ...requestValues,
        })
      } else {
        response = await API.patch(accountTypeToAPI[accountType], {
          planType,
          id,
          participantId,
          ...requestValues,
        })
      }

      // update account store with response.data
      applySnapshot(props.account, { account: response.data })
      store.getAccounts()
      history.push(`/accounts/add/${type}/${accountType}/${id}/${accountTypeNextStep[accountType]}`)
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const validate = values => {
    const {
      account: { isSpouseOwned },
    } = props.account

    return schema
      .validate(values, { abortEarly: false, context: { isSpouseOwned } })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  const editable = () => {
    const {
      match: {
        params: { accountType },
      },
      account: {
        account: { planType, isPrimaryOwned, contribMethod },
        control,
      },
    } = props
    return createEditable({
      accountType,
      planType,
      isPrimaryOwned,
      control,
      institutionalContribMethod: isInstitutionalAccount
        ? institutionalContribMethod
        : contribMethod,
    })
  }

  const initialValues = editable => {
    const { account, control } = props.account
    return initializeValues({
      editable,
      account,
      control,
      institutionalContribMethod,
    })
  }

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return (
      <div
        css={css`
          color: crimson;
        `}>
        Something went wrong, please try again later.
      </div>
    )
  }

  const {
    account: { participantName, isSpouseOwned },
  } = props.account

  const {
    contributionEligibilityIsEditable,
    employeeContribIsEditable,
    contribMethodIsEditable,
    pretaxSavingsRateIsEditable,
    preTaxSavingsAmtIsEditable,
    rothContribAllowedIsEditable,
    rothSavingsRateIsEditable,
    rothSavingsAmtIsEditable,
    posttaxContribAllowedIsEditable,
    posttaxSavingsRateIsEditable,
    postTaxSavingsAmtIsEditable,
  } = editable()

  const accountsThatNeedContributionLimit = ['401k', '403b', '457', '401a']
  const needHelpIcon = _.includes(accountsThatNeedContributionLimit, accountType)

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues(editable())}
      decorators={[calculator]}
      subscription={{ submitting: true, submitError: true, values: true, touched: true }}
      render={({ handleSubmit, submitting, submitError, values, touched }) => {
        const showRothAllowed =
          rothContribAllowedIsEditable &&
          ((values.contributionEligibility === true &&
            values.employeeContrib === true &&
            values.contribMethod !== null) ||
            (values.contributionEligibility === true && values.employeeContrib === false) ||
            values.contributionEligibility === false)

        const showPostTaxAllowed =
          posttaxContribAllowedIsEditable &&
          ((values.contributionEligibility === true &&
            values.employeeContrib === true &&
            values.contribMethod !== null) ||
            (values.contributionEligibility === true && values.employeeContrib === false) ||
            values.contributionEligibility === false)

        return (
          <WizardStep
            onNextClick={handleSubmit}
            title="Let's look at paycheck contributions"
            disableNextButton={submitting}
            disableBackButton={submitting}
            serverError={submitError}>
            <div>
              {contributionEligibilityIsEditable &&
                !(isInstitutionalAccount && institutionalAccountUnderReview) && (
                  <Field
                    name='contributionEligibility'
                    render={({ input, meta }) => (
                      <MultiButtonInput
                        name={input.name}
                        value={input.value}
                        label={`Is ${participantName} eligible to make contributions?`}
                        buttons={[
                          { text: 'Yes', value: true },
                          { text: 'No', value: false },
                        ]}
                        tooltip={TooltipText.eligibleContributions(true)}
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                        disabled={!contributionEligibilityIsEditable}
                      />
                    )}
                  />
                )}

              {employeeContribIsEditable && values.contributionEligibility === true && (
                <Field
                  name='employeeContrib'
                  render={({ input, meta }) => (
                    <MultiButtonInput
                      name={input.name}
                      value={input.value}
                      label={`Is ${participantName} making paycheck contributions to this account currently?`}
                      buttons={[
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]}
                      tooltip={TooltipText.currentlyContributing()}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                    />
                  )}
                />
              )}

              {showZeroContributionsError(editable, values, touched) && (
                <div
                  css={css`
                    width: 100%;
                    color: #e31e27;
                    margin-top: 20px;
                    line-height: 1.35;
                  `}>
                  You've indicated you are contributing currently, but your contributions are set to
                  zero. Please change your answer or enter a contribution amount.
                </div>
              )}

              {!isSpouseOwned &&
                (isInstitutionalAccount
                  ? editInstitutionalAccount &&
                    contribMethodChangeAllowed &&
                    values.employeeContrib === true
                  : contribMethodIsEditable && values.employeeContrib === true) && (
                  <Field
                    name='contribMethod'
                    render={({ input, meta }) => (
                      <MultiButtonInput
                        name={input.name}
                        value={input.value}
                        label='Are contributions a percentage of pay or a fixed dollar amount?'
                        buttons={[
                          { text: '% of Pay', value: 'P' },
                          { text: 'Fixed $ Amount', value: 'D' },
                        ]}
                        tooltip={TooltipText.contributionType()}
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                      />
                    )}
                  />
                )}

              {isSpouseOwned && contribMethodIsEditable && values.employeeContrib === true && (
                <Field
                  name='contribMethod'
                  render={({ input, meta }) => (
                    <MultiButtonInput
                      name={input.name}
                      value={input.value}
                      label='Are contributions a percentage of pay or a fixed dollar amount?'
                      buttons={[
                        { text: '% of Pay', value: 'P' },
                        { text: 'Fixed $ Amount', value: 'D' },
                      ]}
                      tooltip={TooltipText.contributionType()}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                    />
                  )}
                />
              )}

              {pretaxSavingsRateIsEditable && (
                <div
                  css={css`
                    width: 100%;
                  `}>
                  {values.employeeContrib === true && values.contribMethod === 'P' && (
                    <InputField
                      label='Contributing pre-tax? Enter percentage:'
                      flexLabel='0 1 340px'
                      flexInput='0 1 auto'>
                      <div
                        css={css`
                          display: flex;
                          align-items: baseline;
                        `}>
                        <Field
                          name='pretaxSavingsRate'
                          format={v => v}
                          parse={v => v}
                          render={({ input, meta }) => (
                            <PercentInput
                              name={input.name}
                              value={input.value === '' ? null : input.value}
                              placeholder='0'
                              onChange={(name, value) => input.onChange(value)}
                              onBlur={name => input.onBlur()}
                              error={meta.error}
                              showError={
                                !showZeroContributionsError(editable, values, touched) &&
                                meta.touched
                              }
                              width='125px'
                            />
                          )}
                        />
                        {needHelpIcon && (
                          <div
                            css={css`
                              padding-left: 20px;
                            `}>
                            <HelpIcon
                              helpLabel='Contribution limits'
                              tooltip={TooltipText.contributionLimits()}
                            />
                          </div>
                        )}
                      </div>
                    </InputField>
                  )}
                </div>
              )}

              {preTaxSavingsAmtIsEditable && (
                <div
                  css={css`
                    width: 100%;
                  `}>
                  {values.employeeContrib === true && values.contribMethod === 'D' && (
                    <InputField
                      label='Contributing pre-tax? Enter amount:'
                      flexLabel='0 1 340px'
                      flexInput='0 1 auto'>
                      <div
                        css={css`
                          display: flex;
                          align-items: baseline;
                        `}>
                        <Field
                          name='preTaxSavingsAmt'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          render={({ input, meta }) =>
                            suffixWrapper(
                              <CurrencyInput
                                name={input.name}
                                value={input.value}
                                placeholder='0'
                                onChange={(name, value) => input.onChange(value)}
                                onBlur={input.onBlur}
                                error={meta.error}
                                showError={
                                  !showZeroContributionsError(editable, values, touched) &&
                                  meta.touched
                                }
                                decimalScale={0}
                                width='125px'
                              />,
                              '/year'
                            )
                          }
                        />
                        {needHelpIcon && (
                          <div
                            css={css`
                              padding-left: 20px;
                            `}>
                            <HelpIcon
                              helpLabel='Contribution limits'
                              tooltip={TooltipText.contributionLimits()}
                            />
                          </div>
                        )}
                      </div>
                    </InputField>
                  )}
                </div>
              )}

              {values.contributionEligibility === false && (
                <div>
                  <InputField
                    label={`When ${
                      isSpouseOwned ? `${participantName} was` : 'you were'
                    } eligible to contribute:`}
                    flexLabel='0 1 340px'
                    flexInput='0 1 200px'
                  />
                </div>
              )}

              {isInstitutionalAccount && institutionalAccountUnderReview ? (
                <div>
                  <div
                    css={css`
                      width: 100%;
                    `}>
                    {rothContribAllowed &&
                      rothSavingsRateIsEditable &&
                      values.employeeContrib === true &&
                      values.contributionEligibility === true &&
                      values.contribMethod === 'P' && (
                        <InputField
                          label='Contributing Roth? Enter percentage:'
                          flexLabel='0 1 340px'
                          flexInput='0 1 auto'>
                          <div>
                            <div
                              css={css`
                                margin-top: 20px;
                              `}>
                              <Field
                                name='rothSavingsRate'
                                format={v => v}
                                parse={v => v}
                                render={({ input, meta }) => (
                                  <PercentInput
                                    name={input.name}
                                    value={input.value === '' ? null : input.value}
                                    placeholder='0'
                                    onChange={(name, value) => input.onChange(value)}
                                    onBlur={input.onBlur}
                                    error={meta.error}
                                    showError={
                                      !showZeroContributionsError(editable, values, touched) &&
                                      meta.touched
                                    }
                                    width='125px'
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </InputField>
                      )}
                    {rothContribAllowed &&
                      rothSavingsRateIsEditable &&
                      values.employeeContrib === true &&
                      values.contributionEligibility === true &&
                      values.contribMethod === 'D' && (
                        <InputField
                          label='Contributing Roth? Enter amount:'
                          flexLabel='0 1 340px'
                          flexInput='0 1 auto'>
                          <div>
                            <div
                              css={css`
                                margin-top: 20px;
                              `}>
                              {suffixWrapper(
                                <Field
                                  name='rothSavingsAmt'
                                  format={value => (value === null ? undefined : value)}
                                  parse={v => v}
                                  render={({ input, meta }) => (
                                    <CurrencyInput
                                      name={input.name}
                                      value={input.value}
                                      placeholder='0'
                                      onChange={(name, value) => input.onChange(value)}
                                      onBlur={input.onBlur}
                                      error={meta.error}
                                      showError={
                                        !showZeroContributionsError(editable, values, touched) &&
                                        meta.touched
                                      }
                                      decimalScale={0}
                                      width='125px'
                                    />
                                  )}
                                />,
                                '/year'
                              )}
                            </div>
                          </div>
                        </InputField>
                      )}
                  </div>
                  <div
                    css={css`
                      width: 100%;
                    `}>
                    {posttaxContribAllowed &&
                      posttaxSavingsRateIsEditable &&
                      values.employeeContrib === true &&
                      values.contributionEligibility === true &&
                      values.contribMethod === 'P' && (
                        <InputField
                          label='Contributing after-tax? Enter percentage:'
                          flexLabel='0 1 340px'
                          flexInput='0 1 auto'>
                          <div>
                            <div
                              css={css`
                                margin-top: 20px;
                              `}>
                              {suffixWrapper(
                                <Field
                                  name='posttaxSavingsRate'
                                  format={value => (value === null ? undefined : value)}
                                  parse={v => v}
                                  render={({ input, meta }) => (
                                    <PercentInput
                                      name={input.name}
                                      value={input.value === '' ? null : input.value}
                                      placeholder='0'
                                      onChange={(name, value) => input.onChange(value)}
                                      onBlur={input.onBlur}
                                      error={
                                        !showZeroContributionsError(editable, values, touched) &&
                                        meta.error
                                      }
                                      showError={meta.touched}
                                      width='125px'
                                    />
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        </InputField>
                      )}
                    {posttaxContribAllowed &&
                      posttaxSavingsRateIsEditable &&
                      values.employeeContrib === true &&
                      values.contributionEligibility === true &&
                      values.contribMethod === 'D' && (
                        <InputField
                          label='Contributing after-tax? Enter amount:'
                          flexLabel='0 1 340px'
                          flexInput='0 1 auto'>
                          <div>
                            <div
                              css={css`
                                margin-top: 20px;
                              `}>
                              {suffixWrapper(
                                <Field
                                  name='postTaxSavingsAmt'
                                  format={value => (value === null ? undefined : value)}
                                  parse={v => v}
                                  render={({ input, meta }) => (
                                    <CurrencyInput
                                      name={input.name}
                                      value={input.value}
                                      placeholder='0'
                                      onChange={(name, value) => input.onChange(value)}
                                      onBlur={input.onBlur}
                                      error={meta.error}
                                      showError={
                                        !showZeroContributionsError(editable, values, touched) &&
                                        meta.touched
                                      }
                                      decimalScale={0}
                                      width='125px'
                                    />
                                  )}
                                />,
                                '/year'
                              )}
                            </div>
                          </div>
                        </InputField>
                      )}
                  </div>
                </div>
              ) : (
                <div>
                  {showRothAllowed && (
                    <Field
                      name='rothContribAllowed'
                      render={({ input, meta }) => (
                        <MultiButtonInput
                          name={input.name}
                          value={input.value}
                          label={
                            values.contributionEligibility
                              ? 'Are Roth contributions permitted?'
                              : 'Were Roth contributions permitted?'
                          }
                          buttons={[
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                          ]}
                          tooltip={TooltipText.rothContributions()}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}>
                          {rothSavingsRateIsEditable &&
                            values.contributionEligibility === true &&
                            values.employeeContrib === true &&
                            values.rothContribAllowed === true &&
                            values.contribMethod === 'P' && (
                              <div
                                css={css`
                                  margin-top: 20px;
                                `}>
                                <Field
                                  name='rothSavingsRate'
                                  format={v => v}
                                  parse={v => v}
                                  render={({ input, meta }) => (
                                    <PercentInput
                                      name={input.name}
                                      value={input.value === '' ? null : input.value}
                                      placeholder='0'
                                      onChange={(name, value) => input.onChange(value)}
                                      onBlur={input.onBlur}
                                      error={
                                        !showZeroContributionsError(editable, values, touched) &&
                                        meta.error
                                      }
                                      showError={meta.touched}
                                      width='125px'
                                    />
                                  )}
                                />
                              </div>
                            )}

                          {rothSavingsAmtIsEditable &&
                            values.contributionEligibility === true &&
                            values.employeeContrib === true &&
                            values.rothContribAllowed === true &&
                            values.contribMethod === 'D' && (
                              <div
                                css={css`
                                  margin-top: 20px;
                                `}>
                                {suffixWrapper(
                                  <Field
                                    name='rothSavingsAmt'
                                    format={value => (value === null ? undefined : value)}
                                    parse={v => v}
                                    render={({ input, meta }) => (
                                      <CurrencyInput
                                        name={input.name}
                                        value={input.value}
                                        placeholder='0'
                                        onChange={(name, value) => input.onChange(value)}
                                        onBlur={input.onBlur}
                                        error={meta.error}
                                        showError={
                                          !showZeroContributionsError(editable, values, touched) &&
                                          meta.touched
                                        }
                                        decimalScale={0}
                                        width='125px'
                                      />
                                    )}
                                  />,
                                  '/year'
                                )}
                              </div>
                            )}
                        </MultiButtonInput>
                      )}
                    />
                  )}

                  {showPostTaxAllowed && (
                    <Field
                      name='posttaxContribAllowed'
                      render={({ input, meta }) => (
                        <MultiButtonInput
                          name={input.name}
                          value={input.value}
                          label={
                            values.contributionEligibility
                              ? 'Are after-tax contributions permitted?'
                              : 'Were after-tax contributions permitted?'
                          }
                          buttons={[
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                          ]}
                          tooltip={TooltipText.afterTaxContributions()}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}>
                          {posttaxSavingsRateIsEditable &&
                            values.contributionEligibility === true &&
                            values.employeeContrib === true &&
                            values.posttaxContribAllowed === true &&
                            values.contribMethod === 'P' && (
                              <div
                                css={css`
                                  margin-top: 20px;
                                `}>
                                <Field
                                  name='posttaxSavingsRate'
                                  format={v => v}
                                  parse={v => v}
                                  render={({ input, meta }) => (
                                    <PercentInput
                                      name={input.name}
                                      value={input.value === '' ? null : input.value}
                                      placeholder='0'
                                      onChange={(name, value) => input.onChange(value)}
                                      onBlur={input.onBlur}
                                      error={meta.error}
                                      showError={
                                        !showZeroContributionsError(editable, values, touched) &&
                                        meta.touched
                                      }
                                      width='125px'
                                    />
                                  )}
                                />
                              </div>
                            )}

                          {postTaxSavingsAmtIsEditable &&
                            values.contributionEligibility === true &&
                            values.employeeContrib === true &&
                            values.posttaxContribAllowed === true &&
                            values.contribMethod === 'D' && (
                              <div
                                css={css`
                                  margin-top: 20px;
                                `}>
                                {suffixWrapper(
                                  <Field
                                    name='postTaxSavingsAmt'
                                    format={value => (value === null ? undefined : value)}
                                    parse={v => v}
                                    render={({ input, meta }) => (
                                      <CurrencyInput
                                        name={input.name}
                                        value={input.value}
                                        placeholder='0'
                                        onChange={(name, value) => input.onChange(value)}
                                        onBlur={input.onBlur}
                                        error={meta.error}
                                        showError={
                                          !showZeroContributionsError(editable, values, touched) &&
                                          meta.touched
                                        }
                                        decimalScale={0}
                                        width='125px'
                                      />
                                    )}
                                  />,
                                  '/year'
                                )}
                              </div>
                            )}
                        </MultiButtonInput>
                      )}
                    />
                  )}
                </div>
              )}

              {isSpouseOwned &&
                values.contributionEligibility &&
                values.employeeContrib !== null &&
                values.contribMethod && <ContributionAsterisk />}
            </div>
          </WizardStep>
        )
      }}
    />
  )
}

const ContributionAsterisk = () => (
  <div
    css={css`
      color: #7a8e96;
      font-size: 1.125rem;
      line-height: 1.45;
    `}>
    * Current Roth and/or after-tax contributions are not taken into account; however, investment
    balances can be entered.
  </div>
)

export default inject('store', 'account')(observer(AddPaycheckContributions))
