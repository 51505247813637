import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { text, primary },
} = colors
const fieldPadding = reduceCSSCalc(`calc(0.8 * ${unit})`)
const fieldFontSize = reduceCSSCalc(`calc(1.6 * ${unit})`)
const textBottomBorderColor = color.convert(`color(${text} a(12%))`)
const textLabelColor = color.convert(`color(${text} a(26%))`)
const iconFontSize = reduceCSSCalc(`calc(2.4 * ${unit})`)

const input = {
  padding: reduceCSSCalc(`calc(2 * ${unit})`),
  fieldPadding,
  fieldFontSize,
  fieldHeight: reduceCSSCalc(`calc(${fieldPadding} * 2 + ${fieldFontSize} * 1.4)`),
  labelFontSize: reduceCSSCalc(`calc(1.2 * ${unit})`),
  focusLabelTop: reduceCSSCalc(`calc(0.6 * ${unit})`),
  textBackgroundColor: 'transparent',
  textLabelColor,
  textInputElementColor: text,
  textBottomBorderColor,
  textHighlightColor: primary,
  textDisabledColor: textBottomBorderColor,
  textDisabledTextColor: textLabelColor,
  textErrorColor: 'rgb(222, 50, 38)',
  textRequiredColor: 'rgb(222, 50, 38)',
  underlineHeight: reduceCSSCalc(`calc(2 * ${unit})`),
  iconFontSize,
  iconSize: reduceCSSCalc(`calc(2 * ${iconFontSize})`),
  iconOffset: reduceCSSCalc(`calc(1.6 * ${unit})`),
  iconRightSpace: reduceCSSCalc(`calc(2 * ${unit})`),
  chevronOffset: reduceCSSCalc(`calc(0.8 * ${unit})`),
  hintOpacity: 1,
}

export default input
