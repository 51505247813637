import { useState } from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'

import { API_URL } from '../../../api'
import { SVGWrapper } from '../../../components/'
import { CircleNegative, CirclePlus } from '../../../assets/icons'

const HistoricalDataStatementsGroup = observer(function (props) {
  const [expandedView, setExpandedView] = useState(true)

  const toggleView = () => {
    setExpandedView(!expandedView)
  }

  const parseDate = date => {
    if (date === null) {
      return ''
    }
    return dayjs(date).format('MM/DD/YYYY') || ''
  }

  const { sortedStatements } = props

  return (
    <Table>
      <tbody>
        <tr>
          <SVGCell>
            <SVGWrapper
              svg={expandedView ? CircleNegative : CirclePlus}
              fill='#7a8e96'
              size='large'
              onClick={toggleView}
            />
          </SVGCell>
          <TableHeader
            align='right'
            whitespace='nowrap'
            verticalAlign='bottom'
            onClick={toggleView}
            cursor='pointer'
            tooltip>
            Statements
          </TableHeader>
        </tr>
        {expandedView &&
          sortedStatements.map(statement => {
            return (
              <tr key={statement.id}>
                <td />
                <StyledCell />
                <StyledCell>{parseDate(statement.timestamp)}</StyledCell>
                <ValueCell>
                  <Link
                    href={`${API_URL}statement/pdf?itemId=${statement.id}`}
                    target='_blank'
                    rel='noreferrer noopener'>
                    AnnualStatement.pdf
                  </Link>
                </ValueCell>
              </tr>
            )
          })}
      </tbody>
    </Table>
  )
})

export default HistoricalDataStatementsGroup

const TableHeader = styled.td`
  color: ${p => p.theme.lightestGray};
  width: ${p => (p.tooltip ? '100px' : '150px')};
  text-align: ${p => (p.align ? p.align : null)};
  white-space: ${p => (p.whitespace ? p.whitespace : null)};
  vertical-align: ${p => (p.verticalAlign ? p.verticalAlign : null)};
  cursor: ${p => (p.cursor ? p.cursor : null)};
  visibility: ${p => (p.hide ? 'hidden' : null)};
`
const Table = styled.table`
  table-layout: fixed;

  td {
    padding: 3px 6px;
  }
`
const ValueCell = styled.td`
  width: 450px;
  color: ${p => p.theme.darkestGray};
`
const StyledCell = styled.td`
  color: ${p => p.theme.darkestGray};
`
const SVGCell = styled.td`
  padding: 0;
  vertical-align: bottom;

  div {
    position: relative;
    top: 3px;
  }
`
const Link = styled.a`
  color: ${p => p.theme.darkestGray};
  text-decoration: underline;
  cursor: pointer;
  word-wrap: break-word;
`
