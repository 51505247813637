import { css } from '@emotion/react'
import { useState } from 'react'
import { inject, Observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import styled from '@emotion/styled'

import { API } from '../../../api'
import { Loading } from '../../../components'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  margin: 36px 0;
  & > a {
    margin: 1rem;
  }
`

const CancelButton = styled.a`
  display: inline-block;
  border: 1px solid #7a8e96;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  color: #7a8e96;
  font-family: 'Open Sans';
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }

  &:active {
    background-color: #d9d9d9;
  }

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }

  & > div:nth-of-type(2),
  div:nth-of-type(3) {
    font-size: 12px;
  }
`
const PrimaryButton = styled.a`
  display: inline-block;
  background: linear-gradient(180deg, #39a3c6 0%, #1291bb 100%);
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  color: white;
  font-family: 'Open Sans';
  cursor: pointer;

  &:hover {
    background: linear-gradient(to bottom, #038ab7 0%, #02769d 100%);
  }

  &:active {
    background: linear-gradient(to bottom, #02607f, #02607f);
  }

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }

  & > div:nth-of-type(2),
  div:nth-of-type(3) {
    font-size: 12px;
  }
`

// TO-DO: Change managed account text to point-in-time advice based on templateId
function ImplementChangesButtons({ history, store, offboarding }) {
  const [state, setState] = useState('idle')

  // Do this check in case they get to this page through the back button when they've already submitted a transaction
  if (offboarding.acceptedCaseSubmitted) {
    handleNext()
  }

  function handleNext() {
    history.push(`/implement/${offboarding.nextRoute('implement-changes')}`)
  }

  async function acceptManagedAccount() {
    try {
      setState('loading')

      const { acceptedCaseId, acceptedContributionDistribution } = store

      await API.post(
        'offboard/updateAcceptedCase',
        acceptedContributionDistribution.catchUp ? acceptedContributionDistribution : null,
        {
          headers: { 'content-type': 'application/json' },
          params: { caseId: acceptedCaseId },
        }
      )
      offboarding.setAcceptedCaseSubmitted(true)

      handleNext()
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  function render() {
    if (state === 'loading') {
      return (
        <ButtonGroup>
          <Loading />
        </ButtonGroup>
      )
    }

    const { features } = store

    return (
      <div>
        {state === 'error' && (
          <div
            css={css`
              color: crimson;
              text-align: center;
            `}>
            Oops! Something went wrong, please try again later
          </div>
        )}
        <ButtonGroup>
          <CancelButton onClick={() => history.push('/overall')}>
            <div>Cancel</div>
          </CancelButton>

          {features.managedAccountAvailable && (
            <PrimaryButton onClick={() => acceptManagedAccount()}>
              <div>Implement changes</div>
            </PrimaryButton>
          )}
        </ButtonGroup>
      </div>
    )
  }

  return <Observer>{render}</Observer>
}

export default withRouter(inject('store', 'offboarding')(ImplementChangesButtons))
