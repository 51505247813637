import { Component } from 'react'
import DropDown from './Dropdown'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import TextErrorField from './TextErrorField'

const InputWrapper = styled.div`
  min-height: 48px;
`

const YearPickerBox = styled.div`
  display: flex;
`

export default class YearPicker extends Component {
  static defaultProps = {
    width: '80px',
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    maxYear: PropTypes.number.isRequired,
    minYear: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    onBlur: PropTypes.func,
    showError: PropTypes.bool,
    value: PropTypes.string,
    width: PropTypes.string,
  }

  handleYearChange(name, selectedYear) {
    this.props.onChange(name, selectedYear + '-01-01')
  }

  years() {
    const { minYear, maxYear, reverse } = this.props
    const range = [...Array(1 + maxYear - minYear).keys()].map(v => minYear + v)
    if (reverse) {
      return range
        .map(value => {
          return { value: value, label: value }
        })
        .reverse()
    }
    return range.map(value => {
      return { value: value, label: value }
    })
  }

  render() {
    const { disabled, error, name, onBlur, showError, value, id } = this.props

    return (
      <div>
        <InputWrapper>
          <YearPickerBox>
            <span id={id} className='year'>
              <DropDown
                disabled={disabled}
                onBlur={onBlur}
                name={name}
                options={this.years()}
                onChange={this.handleYearChange.bind(this)}
                placeholder='yyyy'
                selected={dayjs(value).year()}
                width={this.props.width}
                nowrap
              />
            </span>
          </YearPickerBox>
          <TextErrorField showError={showError} error={error} />
        </InputWrapper>
      </div>
    )
  }
}
