import styled from '@emotion/styled'

const InvestmentsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #9eacb0;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 300;
  width: 100%;
`
const InvestmentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex: 1 1 700px;
  margin: 5px 0px;
  border: solid 1px #b6c0c4;
  background-color: #fafeff;
  cursor: pointer;
`

const TypeOfInvestment = styled.div`
  color: #4a606a;
  padding: 10px;
`

const Ticker = styled.div`
  color: #4a606a;
  padding: 10px;
`

const InvestmentName = styled.div`
  color: #4a606a;
  padding: 10px;
`

export { InvestmentsContainer, InvestmentContainer, TypeOfInvestment, Ticker, InvestmentName }
