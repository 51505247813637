import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTheme } from '@emotion/react'
import { css } from '@emotion/css'
import posed, { PoseGroup } from 'react-pose'

import { GCLogoColor } from '../../assets/icons'
import { Button } from '../../components'
import { Page } from './elements'
import { API_URL } from '../../api'

function LogoHeader(props) {
  return (
    <div
      css={css`
        height: 90px;
        text-align: right;
      `}>
      <img
        src={GCLogoColor}
        alt='logo'
        css={css`
          width: 100%;
          height: 100%;
          max-width: 320px;
        `}
      />
    </div>
  )
}

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function SuccessMessage(props) {
  const { location, history } = props
  const theme = useTheme()

  useEffect(() => {
    document.documentElement.className = css`
      background: url(${API_URL + theme.bgRight}) no-repeat top right fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    `
    return () => {
      document.documentElement.className = ''
    }
  }, []) // eslint-disable-line

  return (
    <PoseGroup animateOnMount>
      <RouteContainer key={location.key || location.pathname}>
        <Page.Container>
          <Page>
            <LogoHeader />
            <Page.Text
              css={css`
                margin: 40px 0;
              `}>
              <span
                css={css`
                  margin: 0 40px 0 0;
                  line-height: 1.5;
                `}>
                Your new login email is now in effect.
              </span>
              <Button
                onClick={() => history.push('/')}
                label='Got it'
                primary
                style={{ width: 124 }}
              />
            </Page.Text>
          </Page>
        </Page.Container>
      </RouteContainer>
    </PoseGroup>
  )
}

export default inject('store')(observer(SuccessMessage))
