import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import posed, { PoseGroup } from 'react-pose'

import { Loading } from '../../../components'

import TwoLongLives from './TwoLongLives'
import OneLongLife from './OneLongLife'
import ClientDies from './ClientDies'
import SpouseDies from './SpouseDies'
import EmergencyFund from './EmergencyFund'
import CompanyStock from './CompanyStock'
import Main from './Main'

import { MainPage } from './style'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function ConsiderationDashboard(props) {
  const {
    location,
    history,
    store: {
      person,
      spouse,
      setInitialInfoForSpendDown,
      getPrimaryRetirementStatus,
      getSpouseRetirementStatus,
    },
  } = props
  const [status, setState] = useState('loading')
  const [primaryRetirementStatus, setPrimaryRetirementStatus] = useState({})
  const [spouseRetirementStatus, setSpouseRetirementStatus] = useState({})

  useEffect(() => {
    props.main.setSidebar(false)
    return () => {
      props.main.setSidebar(true)
    }
  }, []) // eslint-disable-line

  const fetchRetirementStatusesForSpendDown = async () => {
    const resultPrimary = await getPrimaryRetirementStatus()
    if (person.includeSpouse) {
      const resultSpouse = await getSpouseRetirementStatus()
      setSpouseRetirementStatus(resultSpouse)
    }
    setPrimaryRetirementStatus(resultPrimary)
  }

  useEffect(() => {
    fetchRetirementStatusesForSpendDown()
    if (_.isEmpty(person || spouse)) {
      handleFetch()
    } else {
      setState('success')
    }
  }, []) // eslint-disable-line

  const handleFetch = async () => {
    try {
      setState('loading')
      await setInitialInfoForSpendDown()
      setState('success')
    } catch (err) {
      setState('error')
      console.error(err)
    }
  }

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return (
      <div
        css={css`
          color: #e31e27;
          font-size: 14px;
          line-height: 1.5;
          text-align: center;
          padding: 0.25rem 0 0.75rem 0;
        `}>
        Something went wrong, please try again later.
      </div>
    )
  }

  return (
    <PoseGroup animateOnMount>
      <RouteContainer key={location.key || location.pathname}>
        <MainPage.Container>
          <Switch location={location}>
            <Route path='/spending/considerations/two-long-lives'>
              <TwoLongLives
                primaryRetirementStatus={primaryRetirementStatus}
                spouseRetirementStatus={spouseRetirementStatus}
                history={history}
              />
            </Route>
            <Route path='/spending/considerations/one-long-life'>
              <OneLongLife
                primaryRetirementStatus={primaryRetirementStatus}
                spouseRetirementStatus={spouseRetirementStatus}
                history={history}
              />
            </Route>
            <Route path='/spending/considerations/client-dies'>
              <ClientDies
                primaryRetirementStatus={primaryRetirementStatus}
                spouseRetirementStatus={spouseRetirementStatus}
                history={history}
              />
            </Route>
            <Route path='/spending/considerations/spouse-dies'>
              <SpouseDies
                primaryRetirementStatus={primaryRetirementStatus}
                spouseRetirementStatus={spouseRetirementStatus}
                history={history}
              />
            </Route>
            <Route path='/spending/considerations/emergency-fund'>
              <EmergencyFund
                primaryRetirementStatus={primaryRetirementStatus}
                spouseRetirementStatus={spouseRetirementStatus}
                history={history}
              />
            </Route>
            <Route path='/spending/considerations/company-stock'>
              <CompanyStock
                primaryRetirementStatus={primaryRetirementStatus}
                spouseRetirementStatus={spouseRetirementStatus}
                history={history}
              />
            </Route>
            <Route component={Main} />
          </Switch>
        </MainPage.Container>
      </RouteContainer>
    </PoseGroup>
  )
}

export default inject('store', 'main')(observer(ConsiderationDashboard))
