/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'
import { Form, useField, useFormState } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import * as yup from 'yup'

import { API, API_URL } from '../../../api'
import {
  Checkbox,
  Button,
  MultiButtonInput,
  TooltipText,
  PercentInput,
  CurrencyInput,
  SharedModal,
} from '../../../components'
import { numberToDollars, reduceValidationError } from '../../../utils'
import { Page } from './elements'
import CatchupContributions from './CatchupContributions'

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 180px;
  height: 56px;
  border-radius: 4px;
  background: linear-gradient(180deg, #39a3c6 0%, #1291bb 100%);
  cursor: pointer;
  padding: 8px;
  margin: 12px 40px;
  &:hover {
    background: linear-gradient(to bottom, #038ab7 0%, #02769d 100%);
  }
  &:active {
    background: linear-gradient(to bottom, #02607f, #02607f);
  }
`
const ButtonTopText = styled.div`
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 1.125rem;
  user-select: none;
`

const schema = yup.object().shape({
  catchupContribStatus: yup
    .string()
    .nullable()
    .when('$enableCatchupContributions', (enableCatchupContributions, schema) =>
      enableCatchupContributions ? schema.oneOf(['Y', 'N'], 'Please select an option') : schema
    ),
  terms: yup.boolean().equals([true], 'Please check the box above to confirm'),
})

const calculator = createDecorator(
  {
    field: 'contribMethod',
    updates: {
      preTaxRate: () => 0,
      preTaxAmount: () => 0,
      rothRate: () => 0,
      rothAmount: () => 0,
      afterTaxRate: () => 0,
      afterTaxAmount: () => 0,
      catchupContribStatus: () => null,
      catchupPreTaxRate: () => 0,
      catchupPreTaxAmount: () => 0,
      catchupRothRate: () => 0,
      catchupRothAmount: () => 0,
      catchupAfterTaxRate: () => 0,
      catchupAfterTaxAmount: () => 0,
    },
  },
  {
    field: 'preTaxRate',
    updates: {
      preTaxAmount: () => 0,
      catchupContribStatus: () => null,
      catchupPreTaxRate: () => 0,
      catchupPreTaxAmount: () => 0,
      catchupRothRate: () => 0,
      catchupRothAmount: () => 0,
      catchupAfterTaxRate: () => 0,
      catchupAfterTaxAmount: () => 0,
    },
  },
  {
    field: 'preTaxAmount',
    updates: {
      preTaxRate: () => 0,
      catchupContribStatus: () => null,
      catchupPreTaxRate: () => 0,
      catchupPreTaxAmount: () => 0,
      catchupRothRate: () => 0,
      catchupRothAmount: () => 0,
      catchupAfterTaxRate: () => 0,
      catchupAfterTaxAmount: () => 0,
    },
  },
  {
    field: 'rothRate',
    updates: {
      rothAmount: () => 0,
      catchupContribStatus: () => null,
      catchupPreTaxRate: () => 0,
      catchupPreTaxAmount: () => 0,
      catchupRothRate: () => 0,
      catchupRothAmount: () => 0,
      catchupAfterTaxRate: () => 0,
      catchupAfterTaxAmount: () => 0,
    },
  },
  {
    field: 'rothAmount',
    updates: {
      rothRate: () => 0,
      catchupContribStatus: () => null,
      catchupPreTaxRate: () => 0,
      catchupPreTaxAmount: () => 0,
      catchupRothRate: () => 0,
      catchupRothAmount: () => 0,
      catchupAfterTaxRate: () => 0,
      catchupAfterTaxAmount: () => 0,
    },
  },
  {
    field: 'afterTaxRate',
    updates: {
      afterTaxAmount: () => 0,
      catchupContribStatus: () => null,
      catchupPreTaxRate: () => 0,
      catchupPreTaxAmount: () => 0,
      catchupRothRate: () => 0,
      catchupRothAmount: () => 0,
      catchupAfterTaxRate: () => 0,
      catchupAfterTaxAmount: () => 0,
    },
  },
  {
    field: 'afterTaxAmount',
    updates: {
      afterTaxRate: () => 0,
      catchupContribStatus: () => null,
      catchupPreTaxRate: () => 0,
      catchupPreTaxAmount: () => 0,
      catchupRothRate: () => 0,
      catchupRothAmount: () => 0,
      catchupAfterTaxRate: () => 0,
      catchupAfterTaxAmount: () => 0,
    },
  },
  {
    field: 'catchupContribStatus',
    updates: {
      catchupPreTaxRate: (catchupContribStatus, allValues) =>
        catchupContribStatus === 'N' ? 0 : allValues.catchupPreTaxRate,
      catchupPreTaxAmount: (catchupContribStatus, allValues) =>
        catchupContribStatus === 'N' ? 0 : allValues.catchupPreTaxAmount,
      catchupRothRate: (catchupContribStatus, allValues) =>
        catchupContribStatus === 'N' ? 0 : allValues.catchupRothRate,
      catchupRothAmount: (catchupContribStatus, allValues) =>
        catchupContribStatus === 'N' ? 0 : allValues.catchupRothAmount,
      catchupAfterTaxRate: (catchupContribStatus, allValues) =>
        catchupContribStatus === 'N' ? 0 : allValues.catchupAfterTaxRate,
      catchupAfterTaxAmount: (catchupContribStatus, allValues) =>
        catchupContribStatus === 'N' ? 0 : allValues.catchupAfterTaxAmount,
    },
  }
)

function ContribMethodField() {
  const { input, meta } = useField('contribMethod', {
    subscription: { value: true, error: true, touched: true },
  })

  return (
    <MultiButtonInput
      name={input.name}
      value={input.value}
      label='Contribution method'
      buttons={[
        { text: '% of Pay', value: 'P' },
        { text: 'Fixed $ Amount', value: 'D' },
      ]}
      tooltip={TooltipText.contributionType()}
      onChange={(name, value) => input.onChange(value)}
      onBlur={input.onBlur}
      error={meta.error}
      showError={meta.touched}
    />
  )
}

function PreTaxSavingsField() {
  const { values } = useFormState({ subscription: { values: true } })
  const { input: percentInput, meta: percentMeta } = useField('preTaxRate', {
    parse: v => v,
    format: v => v,
  })
  const { input: amountInput, meta: amountMeta } = useField('preTaxAmount', {
    parse: v => v,
    format: value => (value === null ? undefined : value),
  })

  if (values.contribMethod === 'P') {
    return (
      <div
        css={css`
          display: flex;
          align-items: baseline;
        `}>
        <PercentInput
          name={percentInput.name}
          value={percentInput.value === '' ? null : percentInput.value}
          onChange={(name, value) => percentInput.onChange(value)}
          onBlur={percentInput.onBlur}
          error={percentMeta.error}
          showError={percentMeta.touched}
          width='100px'
        />
        &nbsp;&nbsp;|&nbsp;&nbsp;$
        {numberToDollars((values.annualSalary * percentInput.value) / 100, true)}/year
      </div>
    )
  }

  if (values.contribMethod === 'D') {
    return (
      <div
        css={css`
          display: flex;
          align-items: baseline;
        `}>
        {numberToDollars((amountInput.value / values.annualSalary) * 100, true)}
        %&nbsp;&nbsp;|&nbsp;&nbsp;
        <CurrencyInput
          name={amountInput.name}
          value={amountInput.value}
          placeholder='0'
          onChange={(name, value) => amountInput.onChange(value)}
          onBlur={amountInput.onBlur}
          error={amountMeta.error}
          showError={amountMeta.touched}
          decimalScale={0}
          width='125px'
        />
      </div>
    )
  }

  return (
    <div>
      {numberToDollars(percentInput.value, true)}%&nbsp;&nbsp;|&nbsp;&nbsp;$
      {numberToDollars(amountInput.value, true)}/year
    </div>
  )
}

function RothSavingsField() {
  const { values } = useFormState({ subscription: { values: true } })
  const { input: percentInput, meta: percentMeta } = useField('rothRate', {
    parse: v => v,
    format: v => v,
  })
  const { input: amountInput, meta: amountMeta } = useField('rothAmount', {
    parse: v => v,
    format: value => (value === null ? undefined : value),
  })

  if (values.contribMethod === 'P') {
    return (
      <div
        css={css`
          display: flex;
          align-items: baseline;
        `}>
        <PercentInput
          name={percentInput.name}
          value={percentInput.value === '' ? null : percentInput.value}
          onChange={(name, value) => percentInput.onChange(value)}
          onBlur={percentInput.onBlur}
          error={percentMeta.error}
          showError={percentMeta.touched}
          width='100px'
        />
        &nbsp;&nbsp;|&nbsp;&nbsp;$
        {numberToDollars((values.annualSalary * percentInput.value) / 100, true)}/year
      </div>
    )
  }

  if (values.contribMethod === 'D') {
    return (
      <div
        css={css`
          display: flex;
          align-items: baseline;
        `}>
        {numberToDollars((amountInput.value / values.annualSalary) * 100, true)}
        %&nbsp;&nbsp;|&nbsp;&nbsp;
        <CurrencyInput
          name={amountInput.name}
          value={amountInput.value}
          placeholder='0'
          onChange={(name, value) => amountInput.onChange(value)}
          onBlur={amountInput.onBlur}
          error={amountMeta.error}
          showError={amountMeta.touched}
          decimalScale={0}
          width='125px'
        />
      </div>
    )
  }

  return (
    <div>
      {numberToDollars(percentInput.value, true)}%&nbsp;&nbsp;|&nbsp;&nbsp;$
      {numberToDollars(amountInput.value, true)}/year
    </div>
  )
}

function AfterTaxSavingsField() {
  const { values } = useFormState({ subscription: { values: true } })
  const { input: percentInput, meta: percentMeta } = useField('afterTaxRate', {
    parse: v => v,
    format: v => v,
  })
  const { input: amountInput, meta: amountMeta } = useField('afterTaxAmount', {
    parse: v => v,
    format: value => (value === null ? undefined : value),
  })

  if (values.contribMethod === 'P') {
    return (
      <div
        css={css`
          display: flex;
          align-items: baseline;
        `}>
        <PercentInput
          name={percentInput.name}
          value={percentInput.value === '' ? null : percentInput.value}
          onChange={(name, value) => percentInput.onChange(value)}
          onBlur={percentInput.onBlur}
          error={percentMeta.error}
          showError={percentMeta.touched}
          width='100px'
        />
        &nbsp;&nbsp;|&nbsp;&nbsp;$
        {numberToDollars((values.annualSalary * percentInput.value) / 100, true)}/year
      </div>
    )
  }

  if (values.contribMethod === 'D') {
    return (
      <div
        css={css`
          display: flex;
          align-items: baseline;
        `}>
        {numberToDollars((amountInput.value / values.annualSalary) * 100, true)}
        %&nbsp;&nbsp;|&nbsp;&nbsp;
        <CurrencyInput
          name={amountInput.name}
          value={amountInput.value}
          placeholder='0'
          onChange={(name, value) => amountInput.onChange(value)}
          onBlur={amountInput.onBlur}
          error={amountMeta.error}
          showError={amountMeta.touched}
          decimalScale={0}
          width='125px'
        />
      </div>
    )
  }

  return (
    <div>
      {numberToDollars(percentInput.value, true)}%&nbsp;&nbsp;|&nbsp;&nbsp;$
      {numberToDollars(amountInput.value, true)}/year
    </div>
  )
}

function TermsField() {
  const { submitting } = useFormState({ subscription: { submitting: true } })
  const { input, meta } = useField('terms', {
    subscription: { value: true, error: true, touched: true },
  })

  function handleOnClick() {
    if (submitting) {
      //
    } else {
      input.onFocus()
      input.onChange(!input.value)
      input.onBlur()
    }
  }

  return (
    <div
      css={css`
        border: 6px solid #c4d7e0;
        border-radius: 8px;
        padding: 20px;
        margin-top: 40px;
      `}>
      <div
        css={css`
          color: #022a3a;
          font-size: 24px;
          line-height: 1.35;
        `}>
        Confirmation
      </div>
      <div
        css={css`
          display: flex;
          margin: auto;
          margin: 16px 0;
        `}>
        <div
          css={css`
            margin-top: 8px;
            min-width: 42px;
          `}>
          <Checkbox
            id='checkbox'
            checked={input.value}
            onClick={handleOnClick}
            css={css`
              margin: auto;
            `}
          />
        </div>

        <div
          onClick={handleOnClick}
          css={css`
            cursor: pointer;
            color: #4a606a;
            font-size: 1.125rem;
            font-weight: 300;
            line-height: 1.5;
            padding-left: 12px;
          `}>
          I want to change my contribution rate only at this time. I do not wish to review any other
          information, including the impact of this change on my projected retirement income.
        </div>
      </div>
      <div
        css={css`
          color: #e31e27;
          font-size: 14px;
        `}>
        {meta.touched && meta.error}
      </div>
    </div>
  )
}

function UpdateContributionsOnly(props) {
  const [state, setState] = useState('loading')
  const [isModalOpen, setIsModalOpen] = useState(false)

  async function fetchInitialState() {
    try {
      await props.store.getInstitutionalAccount()
      setState('done')
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  useEffect(() => {
    fetchInitialState()
  }, []) // eslint-disable-line

  if (state === 'loading') {
    return <Page.Loading />
  }

  if (state === 'error') {
    return <Page.ErrorMessage>Oops! Something went wrong, please try again later</Page.ErrorMessage>
  }

  if (state === 'submitted') {
    return (
      <Page>
        <Page.Header />
        <Page.Title>You're all set!</Page.Title>
        <div
          css={css`
            color: #4a606a;
            font-size: 1.125rem;
            font-weight: 300;
            line-height: 1.35;
            margin: 48px 0 24px;
          `}>
          Your transaction has been submitted. You should receive a confirmation email shortly.
        </div>
        <div
          css={css`
            color: #4a606a;
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 1.45;
            margin: 24px 0 40px;
          `}>
          Be aware: Depending upon the arrangement between GuidedChoice and your plan, your
          transaction could take 24-48 hours to process, or as many as 10 business days.
        </div>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            @media (max-width: 768px) {
              flex-wrap: wrap;
              justify-content: center;
            }
          `}>
          <ButtonBox onClick={() => window.open(`${API_URL.slice(0, -4)}logout`, '_self')}>
            <ButtonTopText>Log off</ButtonTopText>
          </ButtonBox>
        </div>
      </Page>
    )
  }

  const { enableCatchupContributions } = props.store.config

  const {
    name,
    contribMethod,
    pretaxSavingsRate,
    preTaxSavingsAmt,
    rothContribAllowed,
    rothSavingsRate,
    rothSavingsAmt,
    posttaxContribAllowed,
    posttaxSavingsRate,
    postTaxSavingsAmt,
  } = props.store.institutional

  const { annualSalary } = props.store.account

  const { contribMethodChangeAllowed, limits } = props.store.plan

  function initialValues() {
    return {
      contribMethod,
      annualSalary,
      preTaxRate: 0,
      preTaxAmount: 0,
      rothRate: 0,
      rothAmount: 0,
      afterTaxRate: 0,
      afterTaxAmount: 0,
      catchupContribStatus: null,
      catchupPreTaxRate: 0,
      catchupPreTaxAmount: 0,
      catchupRothRate: 0,
      catchupRothAmount: 0,
      catchupAfterTaxRate: 0,
      catchupAfterTaxAmount: 0,
      terms: false,
    }
  }

  function validate(values) {
    return schema
      .validate(values, { abortEarly: false, context: { enableCatchupContributions } })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  function onSubmit(values) {
    if (values.catchupPreTaxRate > values.preTaxRate) {
      setIsModalOpen(true)
    } else {
      return changeSavingsRate(values)
    }
  }

  function buildSavingsRate(values) {
    const mapTaxType = { preTax: 1, afterTax: 2, roth: 5 }
    const preTaxValue = { P: values.preTaxRate, D: values.preTaxAmount }
    const rothValue = { P: values.rothRate, D: values.rothAmount }
    const afterTaxValue = { P: values.afterTaxRate, D: values.afterTaxAmount }
    const catchupPreTaxValue = { P: values.catchupPreTaxRate, D: values.catchupPreTaxAmount }
    const catchupRothValue = { P: values.catchupRothRate, D: values.catchupRothAmount }
    const catchupAfterTaxValue = { P: values.catchupAfterTaxRate, D: values.catchupAfterTaxAmount }

    return {
      [mapTaxType.preTax]: {
        taxType: mapTaxType.preTax,
        deferralType: values.contribMethod,
        value: preTaxValue[values.contribMethod] - catchupPreTaxValue[values.contribMethod],
      },

      [mapTaxType.roth]: rothContribAllowed
        ? {
            taxType: mapTaxType.roth,
            deferralType: values.contribMethod,
            value: rothValue[values.contribMethod] - catchupRothValue[values.contribMethod],
          }
        : undefined,

      [mapTaxType.afterTax]: posttaxContribAllowed
        ? {
            taxType: mapTaxType.afterTax,
            deferralType: values.contribMethod,
            value: afterTaxValue[values.contribMethod] - catchupAfterTaxValue[values.contribMethod],
          }
        : undefined,
    }
  }

  function buildCatchUp(values) {
    const mapTaxType = { preTax: 1, afterTax: 2, roth: 5 }
    const preTaxValue = { P: values.catchupPreTaxRate, D: values.catchupPreTaxAmount }
    const rothValue = { P: values.catchupRothRate, D: values.catchupRothAmount }
    const afterTaxValue = { P: values.catchupAfterTaxRate, D: values.catchupAfterTaxAmount }

    return {
      [mapTaxType.preTax]: {
        taxType: mapTaxType.preTax,
        deferralType: values.contribMethod,
        value: preTaxValue[values.contribMethod],
      },

      [mapTaxType.roth]: rothContribAllowed
        ? {
            taxType: mapTaxType.roth,
            deferralType: values.contribMethod,
            value: rothValue[values.contribMethod],
          }
        : undefined,

      [mapTaxType.afterTax]: posttaxContribAllowed
        ? {
            taxType: mapTaxType.afterTax,
            deferralType: values.contribMethod,
            value: afterTaxValue[values.contribMethod],
          }
        : undefined,
    }
  }

  async function changeSavingsRate(values) {
    try {
      await API.post('transactions/change-savings-rate', {
        savingsRate: buildSavingsRate(values),
        catchUp: buildCatchUp(values),
      })

      setState('submitted')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Page>
      <Page.Header />

      <Page.Title
        css={css`
          margin: 40px 0 50px;
        `}>
        Change how much I'm saving only
      </Page.Title>

      <div
        css={css`
          color: #4a606a;
          font-size: 24px;
          line-height: 1.25;
        `}>
        {name}
      </div>

      <Form
        initialValues={initialValues()}
        decorators={[calculator]}
        validate={validate}
        onSubmit={onSubmit}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting, submitError }) => (
          <div>
            {contribMethodChangeAllowed && (
              <div
                css={css`
                  margin: 1.5rem 0 0;
                `}>
                <ContribMethodField />
              </div>
            )}

            <div
              css={css`
                display: flex;
                color: #4a606a;
                font-weight: 300;
                font-size: 1.125rem;
                line-height: 1.25;
                margin: 1rem 0;
              `}>
              <div
                css={css`
                  width: 300px;
                `}
              />
              <div
                css={css`
                  width: 300px;
                `}>
                Contributing now
              </div>
              <div
                css={css`
                  width: 350px;
                `}>
                Change to
              </div>
            </div>

            <div
              css={css`
                display: flex;
                align-items: baseline;
                color: #4a606a;
                font-weight: 300;
                font-size: 1.125rem;
                line-height: 1.25;
                margin: 1rem 0;
              `}>
              <div
                css={css`
                  width: 300px;
                `}>
                Pre-tax rate
              </div>
              <div
                css={css`
                  width: 300px;
                `}>
                {numberToDollars(pretaxSavingsRate, true)}% | $
                {numberToDollars(preTaxSavingsAmt, true)}
                /year
              </div>
              <div
                css={css`
                  width: 350px;
                `}>
                <PreTaxSavingsField />
              </div>
            </div>

            {rothContribAllowed && (
              <div
                css={css`
                  display: flex;
                  align-items: baseline;
                  color: #4a606a;
                  font-weight: 300;
                  font-size: 1.125rem;
                  line-height: 1.25;
                  margin: 1rem 0;
                `}>
                <div
                  css={css`
                    width: 300px;
                  `}>
                  Roth rate
                </div>
                <div
                  css={css`
                    width: 300px;
                  `}>
                  {numberToDollars(rothSavingsRate, true)}% | $
                  {numberToDollars(rothSavingsAmt, true)}/year
                </div>
                <div
                  css={css`
                    width: 350px;
                  `}>
                  <RothSavingsField />
                </div>
              </div>
            )}

            {posttaxContribAllowed && (
              <div
                css={css`
                  color: #4a606a;
                  font-size: 18px;
                  line-height: 1.35;
                  margin: 2.5rem 0;
                `}>
                <span
                  css={css`
                    font-weight: 600;
                  `}>
                  IMPORTANT:
                </span>{' '}
                Before entering an after-tax contribution rate, be aware that in nearly all cases,
                it will be to your tax advantage to save up to the maximum allowed using pre-tax
                {rothContribAllowed ? ' and/or Roth' : ''} contributions before saving more with
                after-tax contributions.
              </div>
            )}

            {posttaxContribAllowed && (
              <div
                css={css`
                  display: flex;
                  align-items: baseline;
                  color: #4a606a;
                  font-weight: 300;
                  font-size: 1.125rem;
                  line-height: 1.25;
                  margin: 1rem 0;
                `}>
                <div
                  css={css`
                    width: 300px;
                  `}>
                  After-tax rate
                </div>
                <div
                  css={css`
                    width: 300px;
                  `}>
                  {numberToDollars(posttaxSavingsRate, true)}% | $
                  {numberToDollars(postTaxSavingsAmt, true)}/year
                </div>
                <div
                  css={css`
                    width: 350px;
                  `}>
                  <AfterTaxSavingsField />
                </div>
              </div>
            )}

            <div
              css={css`
                color: #7a8e96;
                font-size: 12px;
                line-height: 1.5;
              `}>
              *Contribution amounts shown are annualized and rounded up. The actual contribution
              total will be limited to IRS and plan rule maximums. In {limits.year}, the limit is $
              {numberToDollars(limits.dlr401k, true)}; if age {limits.catchupAge}+ in the calendar
              year, you may add up to an additional $
              {numberToDollars(limits.catchupLimitAmount, true)} in catch-up contributions.
            </div>

            {enableCatchupContributions && <CatchupContributions />}

            <TermsField />

            <SharedModal
              isModalOpen={isModalOpen}
              title={`Uh Oh!`}
              toggleModal={() => setIsModalOpen(modal => !modal)}>
              <Page.WarningText>
                It looks like the value you entered for catch-up contributions exceeds the value
                you're deducting from your paycheck. Please check the values you entered and try
                again.
              </Page.WarningText>
              <Page.ButtonWrapper>
                <Button
                  primary
                  label='Got it!'
                  onClick={() => setIsModalOpen(false)}
                  width='100px'
                />
              </Page.ButtonWrapper>
            </SharedModal>

            <Page.ButtonGroup>
              <div>
                <Button
                  type='button'
                  onClick={props.history.goBack}
                  secondary
                  label='Cancel'
                  disabled={submitting}
                  width='140px'
                />
              </div>
              <div>
                <Button
                  type='button'
                  onClick={handleSubmit}
                  primary
                  label='Next'
                  disabled={submitting}
                  width='140px'
                />
              </div>
            </Page.ButtonGroup>
          </div>
        )}
      />
    </Page>
  )
}

export default inject('store')(observer(UpdateContributionsOnly))
