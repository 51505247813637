import dayjs from 'dayjs'
import _ from 'lodash'

import { SECURITY_TYPES, VALUATION_METHODS } from '../../../constants'
import { trackInvestmentsByTaxType } from '../utils'
import { getIraTaxType } from '../utils/accountTypeToAllowedContributions'

export const initializeValues = ({
  accountType,
  price,
  securityType,
  showAddBalanceButton,
  showPreTaxForm,
  showRothForm,
  showAfterTaxForm,
  notInvestedAlreadyExistsForSecurity,
}) => {
  const enableSharesControl =
    price >= 1 && _.includes([SECURITY_TYPES.Stock, SECURITY_TYPES['Mutual Fund']], securityType)

  const preTax = {
    quantity: undefined,
    purchaseDate: dayjs().format('YYYY-MM-DD'),
    totalCostBasis: 0,
    totalValue: undefined,
    valuationMethod: enableSharesControl
      ? VALUATION_METHODS.marketPrice
      : VALUATION_METHODS.accountBalance,
  }

  const roth = {
    quantity: undefined,
    purchaseDate: dayjs().format('YYYY-MM-DD'),
    totalCostBasis: 0,
    totalValue: undefined,
    valuationMethod: enableSharesControl
      ? VALUATION_METHODS.marketPrice
      : VALUATION_METHODS.accountBalance,
  }

  const afterTax = {
    quantity: undefined,
    purchaseDate: dayjs().format('YYYY-MM-DD'),
    totalCostBasis: undefined,
    totalValue: undefined,
    valuationMethod: enableSharesControl
      ? VALUATION_METHODS.marketPrice
      : VALUATION_METHODS.accountBalance,
  }

  // If account is an ira
  if (
    _.includes(['ira-non-deductible', 'ira-deductible', 'ira-rollover', 'ira-roth'], accountType)
  ) {
    const taxTypes = { preTax, roth, afterTax }
    const values = {
      addBalance: true,
      showAddBalanceButton,
      preTax: null,
      roth: null,
      afterTax: null,
    }
    const taxType = getIraTaxType(accountType)
    values[taxType] = taxTypes[taxType]
    return values
  }
  if (
    !notInvestedAlreadyExistsForSecurity &&
    trackInvestmentsByTaxType(accountType) &&
    showPreTaxForm &&
    (showRothForm || showAfterTaxForm)
  ) {
    // If more than one form available for NonGC case (roth or after-tax is allowed), ask to add each individually
    return {
      addBalance: showAddBalanceButton ? null : true,
      showAddBalanceButton,
      preTax: showAddBalanceButton === false && showPreTaxForm ? preTax : null,
      roth: showAddBalanceButton === false && showRothForm ? roth : null,
      afterTax: showAddBalanceButton === false && showAfterTaxForm ? roth : null,
    }
  }

  // If only single form for NonGC account model planTypes (preTax only), don't ask if they want to add to PreTax, assume true after Add Balance
  if (
    !notInvestedAlreadyExistsForSecurity &&
    trackInvestmentsByTaxType(accountType) &&
    showPreTaxForm &&
    !showRothForm &&
    !showAfterTaxForm
  ) {
    return { addBalance: true, showAddBalanceButton, preTax, roth: null, afterTax: null }
  }

  // If any form is available
  if (
    !notInvestedAlreadyExistsForSecurity &&
    trackInvestmentsByTaxType(accountType) &&
    (showPreTaxForm || showRothForm || showAfterTaxForm)
  ) {
    return {
      addBalance: showAddBalanceButton ? null : true,
      showAddBalanceButton,
      preTax: null,
      roth: null,
      afterTax: null,
    }
  }

  // If only single form for afterTax only account types, assume both Add Balance and add to afterTax is true
  if (!trackInvestmentsByTaxType(accountType)) {
    return { addBalance: true, showAddBalanceButton, preTax: null, roth: null, afterTax }
  }

  // If Not Invested already exists for this security, assume Add Balance and ask them to add tax types
  if (notInvestedAlreadyExistsForSecurity) {
    return {
      addBalance: true,
      showAddBalanceButton: false,
      preTax: null,
      roth: null,
      afterTax: null,
    }
  }

  // The following case should only run when investments already added and available for edit
  if (!showPreTaxForm && !showRothForm && !showAfterTaxForm) {
    return {
      addBalance: showAddBalanceButton ? null : true,
      showAddBalanceButton,
      preTax: null,
      roth: null,
      afterTax: null,
    }
  }
}

export const typeToInvestmentsAPI = ({ type, id }) =>
  ({
    'institutional': 'accounts/institutionalAccount/investments',
    'non-gc': `accounts/nonGCAccount/${id}/investments`,
  }[type])
