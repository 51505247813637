/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const card = 'rt-card-card'

const raised = 'rt-card-raised'

const cardActions = 'rt-card-cardActions'

const cardMedia = 'rt-card-cardMedia'

const content = 'rt-card-content'

const contentOverlay = 'rt-card-contentOverlay'

const square = 'rt-card-square'

const wide = 'rt-card-wide'

const cardTitle = 'rt-card-cardTitle'

const cardText = 'rt-card-cardText'

const large = 'rt-card-large'

const title = 'rt-card-title'

const small = 'rt-card-small'

const subtitle = 'rt-card-subtitle'

const _card = css`
  background: ${ds.get('card.backgroundColor')};
  border-radius: ${reduceCSSCalc(`calc(0.2 * ${ds.get('unit')})`)};
  box-shadow: ${ds.get('shadow[2]')};
  display: flex;
  flex-direction: column;
  font-size: ${ds.get('card.fontSize')};
  overflow: hidden;
  width: 100%;

  ${ds.get('reset')};

  &.${raised} {
    box-shadow: ${ds.get('shadow[8]')};
  }
`

const _cardMedia = css`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &.${wide}, &.${square} {
    width: 100%;

    & .${content} {
      height: 100%;
      position: absolute;
    }

    & .${content} > iframe,
    & .${content} > video,
    & .${content} > img {
      max-width: 100%;
    }
  }

  &::after {
    content: '';
    display: block;
    height: 0;
  }

  &.${wide}::after {
    padding-top: 56.25%;
  }

  &.${square}::after {
    padding-top: 100%;
  }

  & .${content} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    overflow: hidden;
    position: relative;
    top: 0;
    width: 100%;
  }

  & .${contentOverlay} {
    & .${cardTitle}, & .${cardActions}, & .${cardText} {
      background-color: ${ds.get('card.textOverlay')};
    }
  }

  & .${cardTitle} {
    & .${title}, & .${subtitle} {
      color: ${ds.get('card.colorWhite')};
    }
  }
`

const _cardTitleCardText = css`
  padding: ${reduceCSSCalc(`calc(${ds.get('card.padding')} - 0.2 * ${ds.get('unit')})`)}
    ${ds.get('card.padding')};

  & p {
    font-size: ${reduceCSSCalc(`calc(1.4 * ${ds.get('unit')})`)};
    font-weight: 400;
    letter-spacing: 0;
    line-height: ${reduceCSSCalc(`calc(2.4 * ${ds.get('unit')})`)};
    margin: 0;
  }

  &:last-child {
    padding-bottom: ${ds.get('card.paddingLg')};
  }

  & + .${cardText} {
    padding-top: 0;
  }
`

const _cardTitle = css`
  ${_cardTitleCardText};

  align-items: center;
  display: flex;

  & [data-react-toolbox='avatar'] {
    margin-right: ${reduceCSSCalc(`calc(1.3 * ${ds.get('unit')})`)};
  }

  & .${title} {
    font-size: ${reduceCSSCalc(`calc(2 * ${ds.get('unit')})`)};
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  & .${subtitle} {
    color: ${ds.get('colors.rt.textSecondary')};
    font-size: ${reduceCSSCalc(`calc(1.4 * ${ds.get('unit')})`)};
    font-weight: 400;
    letter-spacing: 0;
    line-height: ${reduceCSSCalc(`calc(2.4 * ${ds.get('unit')})`)};
    margin: 0;
    padding: 0;
  }

  &.${large} {
    padding: ${ds.get('card.paddingLg')} ${ds.get('card.padding')}
      ${reduceCSSCalc(`calc(${ds.get('card.padding')} - 0.2 * ${ds.get('unit')})`)};

    & .${title} {
      font-size: ${reduceCSSCalc(`calc(2.4 * ${ds.get('unit')})`)};
      -moz-osx-font-smoothing: grayscale;
      font-weight: 400;
      line-height: 1.25;
    }
  }

  &.${small} {
    padding: ${ds.get('card.padding')};

    & .${title} {
      font-size: 1.4rem;
      letter-spacing: 0;
      line-height: 1.4;
    }

    & .${subtitle} {
      font-weight: 500;
      line-height: 1.4;
    }
  }
`

const _cardText = css`
  ${_cardTitleCardText};
`

const _cardActions = css`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: ${ds.get('card.paddingSm')};

  & [data-react-toolbox='button'] {
    margin: 0 ${reduceCSSCalc(`calc(${ds.get('card.paddingSm')} / 2)`)};
    min-width: 0;
    padding: 0 ${ds.get('card.paddingSm')};

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export default {
  card: cx(card, _card),
  raised,
  cardActions: cx(cardActions, _cardActions),
  cardMedia: cx(cardMedia, _cardMedia),
  content,
  contentOverlay,
  square,
  wide,
  cardTitle: cx(cardTitle, _cardTitle),
  cardText: cx(cardText, _cardText),
  large,
  title,
  small,
  subtitle,
}
