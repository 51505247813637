import styled from '@emotion/styled'

const Wrapper = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
  font-size: 14pt;
`

/// /////////////////////////////////////////////////////////////
/// Company Stock Styling
/// /////////////////////////////////////////////////////////////

Wrapper.CompanyStockPageTitle = styled.div`
  font-size: 28pt;
  font-weight: 300;
  line-height: 20pt;
`
Wrapper.CompanyStockTitle = styled.div`
  font-size: 20pt;
  font-weight: 600;
  line-height: 20pt;
`
Wrapper.CompanyStockText = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
`
Wrapper.CompanyStockListItem = styled.li`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
  padding-bottom: 15pt;
`
Wrapper.CompanyStockListItemItalic = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
  padding-left: 30pt;
  font-style: italic;
`
Wrapper.CompanyStockSubListItem = styled.li`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
  &::marker {
    color: ${p => p.theme.mediumGray};
  }
`
Wrapper.CompanyStockAge = styled.span`
  line-height: 18pt;
  color: ${p => p.theme.darkestGray};
`
Wrapper.CompanyStockAmount = styled.span`
  line-height: 18pt;
  color: ${p => p.theme.darkestGray};

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.CompanyStockIndentedText = styled.div`
  line-height: 18pt;
  color: ${p => p.theme.mediumGray};
`
Wrapper.CompanyStockParagraphTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 16pt;
  line-height: 18pt;
`

export { Wrapper }
