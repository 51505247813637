import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import { withRouter } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

import { API } from '../../../api'
import {
  WizardStep,
  InputField,
  MultiButtonInput,
  NumberInput,
  CurrencyInput,
  PercentInput,
  TooltipText,
} from '../../../components'
import { reduceValidationError } from '../../../utils'
import { typeToAPI } from '../utils'
import { schema } from './PayoutUtils'

class EditPayout extends Component {
  handleSubmit = async values => {
    try {
      // if different from initialValues, submit PATCH request
      if (!_.isEqual(this.initialValues(), values)) {
        const {
          account: { account },
          match: {
            params: { type },
          },
        } = this.props
        const { planType, id, participantId } = account
        const {
          paymentType,
          pensionStartAge,
          monthlyPensionIncome,
          lumpsumreceived,
          survivorFraction,
        } = values
        const response = await API.patch(typeToAPI[type], {
          editable: true,
          planType,
          id,
          participantId,
          pensionName: account.pensionName,
          ownerId: account.ownerId,
          paymentType,
          pensionStartAge,
          monthlyPensionIncome,
          lumpsumreceived: paymentType === 'L' ? lumpsumreceived : 'N',
          survivorFraction: paymentType === 'M' ? survivorFraction || 0 : 0,
        })
        // API is returning editable: null when patching, so we reset it back to true here
        applySnapshot(account, { ...response.data, editable: true })
        await this.props.store.getAccounts()
      }
      this.props.handleCancel()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  validate = values => {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  initialValues = () => {
    const { account } = this.props.account
    const {
      paymentType,
      pensionStartAge,
      monthlyPensionIncome,
      lumpsumreceived,
      survivorFraction,
    } = account

    return { paymentType, pensionStartAge, monthlyPensionIncome, lumpsumreceived, survivorFraction }
  }

  render() {
    const { account } = this.props.account
    const {
      config: { templateId },
    } = this.props.store

    if (!account) {
      return null
    }

    const { participantName } = account

    // only for atmos pension
    const editingAtmosPension = templateId === 4 && account.accountId

    return (
      <Form
        onSubmit={this.handleSubmit}
        validate={this.validate}
        initialValues={this.initialValues()}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ handleSubmit, submitting, submitError, values }) => (
          <WizardStep
            onBackClick={this.props.handleCancel}
            onNextClick={handleSubmit}
            backButtonText='Cancel'
            nextButtonText='Save'
            loading={submitting}
            serverError={submitError}>
            {editingAtmosPension ? (
              <div>
                {values.paymentType === 'L' && (
                  <Field
                    name='lumpsumreceived'
                    render={({ input, meta }) => (
                      <MultiButtonInput
                        label='Received lump sum payout?'
                        buttons={[
                          { value: 'Y', text: 'Yes' },
                          { value: 'N', text: 'No' },
                        ]}
                        name={input.name}
                        value={input.value}
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                        tooltip={values.paymentType === 'L' && TooltipText.whyLumpSumMatters()}
                        helpLabel='Why it matters'
                      />
                    )}
                  />
                )}
              </div>
            ) : (
              <div>
                <Field
                  name='paymentType'
                  render={({ input, meta }) => (
                    <MultiButtonInput
                      label='Payout type'
                      name={input.name}
                      value={input.value}
                      buttons={[
                        { value: 'L', text: 'Lump Sum' },
                        { value: 'M', text: 'Monthly' },
                      ]}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                    />
                  )}
                />

                {(values.paymentType === 'L' || values.paymentType === 'M') && (
                  <div>
                    <InputField
                      label={
                        values.paymentType === 'L'
                          ? `At what age will ${participantName} receive the lump sum payout?`
                          : 'Age begin receiving payouts'
                      }
                      tooltip={values.paymentType === 'L' && TooltipText.multiplePayoutOptions()}
                      helpLabel='Multiple payout options'>
                      <Field
                        name='pensionStartAge'
                        format={value => (value === null ? undefined : value)}
                        parse={v => v}
                        render={({ input, meta }) => (
                          <NumberInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onBlur={input.onBlur}
                            error={meta.error}
                            showError={meta.touched}
                            maxLength={3}
                            width='100px'
                            placeholder='Age'
                          />
                        )}
                      />
                    </InputField>
                    <InputField
                      label={
                        values.paymentType === 'L'
                          ? 'What is the lump sum amount'
                          : 'Monthly payout'
                      }>
                      <Field
                        name='monthlyPensionIncome'
                        format={value => (value === null ? undefined : value)}
                        parse={v => v}
                        render={({ input, meta }) => (
                          <CurrencyInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onBlur={input.onBlur}
                            error={meta.error}
                            showError={meta.touched}
                          />
                        )}
                      />
                    </InputField>
                  </div>
                )}

                {values.paymentType === 'L' && (
                  <Field
                    name='lumpsumreceived'
                    render={({ input, meta }) => (
                      <MultiButtonInput
                        label='Received lump sum payout?'
                        buttons={[
                          { value: 'Y', text: 'Yes' },
                          { value: 'N', text: 'No' },
                        ]}
                        name={input.name}
                        value={input.value}
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                        tooltip={values.paymentType === 'L' && TooltipText.whyLumpSumMatters()}
                        helpLabel='Why it matters'
                      />
                    )}
                  />
                )}

                {values.paymentType === 'M' && (
                  <InputField label='Survivor benefit, if any'>
                    <Field
                      name='survivorFraction'
                      format={v => v}
                      parse={v => v}
                      render={({ input, meta }) => (
                        <PercentInput
                          name={input.name}
                          value={input.value === '' ? null : input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                          placeholder='0'
                        />
                      )}
                    />
                  </InputField>
                )}
              </div>
            )}
          </WizardStep>
        )}
      />
    )
  }
}

export default withRouter(inject('store', 'account')(observer(EditPayout)))
