/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const appBar = 'rt-appBar-appBar'

const inner = 'rt-appBar-inner'

const fixed = 'rt-appBar-fixed'

const flat = 'rt-appBar-flat'

const leftIcon = 'rt-appBar-leftIcon'

const rightIcon = 'rt-appBar-rightIcon'

const scrollHide = 'rt-appBar-scrollHide'

const title = 'rt-appBar-title'

const _appBar = css`
  background: ${ds.get('appbar.color')};
  color: ${ds.get('appbar.contrast')};
  font-family: ${ds.get('preferredFont')};
  height: ${ds.get('appbar.height')};
  padding: 0 ${ds.get('appbar.hPadding')};
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  ${ds.get('reset')};

  &.${scrollHide} {
    transform: translateY(-100%);
  }

  &:not(.${flat}) {
    box-shadow: ${ds.get('shadow[4]')};
  }

  &.${fixed} {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${ds.get('zIndex.highest')};
  }

  & .${inner} {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
  }

  & a {
    color: ${ds.get('appbar.contrast')};
  }

  @media screen and (max-width: ${ds.bp('xxs')}) and (${ds.get('portrait')}) {
    height: ${ds.get('appbar.heightMPortrait')};
  }

  @media screen and (max-width: ${ds.bp('xs')}) and (${ds.get('landscape')}) {
    height: ${ds.get('appbar.heightMLandscape')};
  }
`

const _title = css`
  flex-grow: 1;
  font-size: ${ds.get('appbar.titleFontSize')};
  font-weight: ${ds.get('appbar.titleFontWeight')};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const _leftIcon = css`
  margin-left: ${reduceCSSCalc(`calc(-1.2 * ${ds.get('unit')})`)};
`

const _rightIcon = css`
  margin-left: auto;
  margin-right: ${reduceCSSCalc(`calc(-1.2 * ${ds.get('unit')})`)};
`

export default {
  appBar: cx(appBar, _appBar),
  inner,
  fixed,
  flat,
  leftIcon: cx(leftIcon, _leftIcon),
  rightIcon: cx(rightIcon, _rightIcon),
  scrollHide,
  title: cx(title, _title),
}
