import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { HelpIcon, TooltipText } from '../../../../components'

// Breakdown Tabs
const TabsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  color: #7a8e96;
  font-size: 14px;
  line-height: 1.35;
  text-align: center;
  margin: 28px 0;
`
const activeTab = ({ active }) =>
  active
    ? css`
        color: #038ab7;
        border-top: 2px solid #7a8e96;
        border-left: 2px solid #7a8e96;
        border-right: 2px solid #7a8e96;
        cursor: default;
      `
    : css`
        border-bottom: 2px solid #7a8e96;
        padding: 14px 38px;
      `
const TabStyle = styled.div`
  flex: 1 1 auto;
  padding: 12px 36px;
  cursor: pointer;
  ${activeTab};
`

const Tabs = ({ store, active, allTabsEnabled, handleChange }) => (
  <TabsStyle>
    <TabStyle
      active={active === 'investmentCategory'}
      onClick={() => (allTabsEnabled ? handleChange('investmentCategory') : null)}
      css={
        allTabsEnabled
          ? css``
          : css`
              position: relative;
              &:hover {
                & > div {
                  opacity: 0.2;
                }
                & > span {
                  opacity: 1;
                }
              }
            `
      }>
      <div
        css={css`
          transition: 0.35s;
        `}>
        Investment category
      </div>
      <div
        css={css`
          transition: 0.35s;
        `}>
        (fewer details)
      </div>
      <span
        css={
          allTabsEnabled
            ? css`
                display: none;
              `
            : css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: 0.5s;
                color: #022a3a;
                font-size: 16px;
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
              `
        }>
        Available upon implementation
      </span>
    </TabStyle>
    <TabStyle
      active={active === 'assetClassBreakdown'}
      onClick={() => handleChange('assetClassBreakdown')}>
      <div>
        Asset class breakdown{' '}
        <HelpIcon size='small' tooltip={TooltipText.assetClassBreakdownChart(store.modifiedCase)} />
      </div>
      <div>(some details)</div>
    </TabStyle>
    <TabStyle
      active={active === 'investmentHoldings'}
      onClick={() => (allTabsEnabled ? handleChange('investmentHoldings') : null)}
      css={
        allTabsEnabled
          ? css``
          : css`
              position: relative;
              &:hover {
                & > div {
                  opacity: 0.2;
                }
                & > span {
                  opacity: 1;
                }
              }
            `
      }>
      <div
        css={css`
          transition: 0.35s;
        `}>
        Investment holdings
      </div>
      <div
        css={css`
          transition: 0.35s;
        `}>
        (more details)
      </div>
      <span
        css={
          allTabsEnabled
            ? css`
                display: none;
              `
            : css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: 0.5s;
                color: #022a3a;
                font-size: 16px;
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
              `
        }>
        Available upon implementation
      </span>
    </TabStyle>
  </TabsStyle>
)

export default observer(Tabs)
