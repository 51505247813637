import styled from '@emotion/styled'

const Wrapper = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
  font-size: 14pt;
`

/// /////////////////////////////////////////////////////////////
/// Assumptions First Page Styling
/// /////////////////////////////////////////////////////////////

Wrapper.AssumptionsFirstPageTitle = styled.div`
  font-size: 28pt;
  font-weight: 300;
  line-height: 20pt;
`
Wrapper.AssumptionsFirstTitle = styled.div`
  font-size: 20pt;
  font-weight: 600;
  line-height: 20pt;
`
Wrapper.AssumptionsFirstText = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
`
Wrapper.AssumptionsFirstListItem = styled.li`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
  padding-bottom: 15pt;
`
Wrapper.AssumptionsFirstListItemItalic = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
  padding-left: 30pt;
  font-style: italic;
`
Wrapper.AssumptionsFirstSubListItem = styled.li`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
  &::marker {
    color: ${p => p.theme.mediumGray};
  }
`
Wrapper.AssumptionsFirstAge = styled.span`
  line-height: 18pt;
  color: ${p => p.theme.darkestGray};
`
Wrapper.AssumptionsFirstAmount = styled.span`
  line-height: 18pt;
  color: ${p => p.theme.darkestGray};

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.AssumptionsFirstIndentedText = styled.div`
  line-height: 18pt;
  color: ${p => p.theme.mediumGray};
`

export { Wrapper }
