import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'

function TodoList(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
      investmentWithdrawals,
      withdrawalData,
      isWithdrawingFromCompanyStock,
    },
    store: {
      config: {
        template: { brandName },
      },
      selectedInstitutionalPension,
      allPensions,
      person,
      spouse,
      accounts,
      primarySSIBenefits,
      spouseSSIBenefits,
    },
  } = props
  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'todo' })
    willMount.current = false
  }

  const primaryInstitutionalAccount = accounts.filter(
    account =>
      account.active !== false &&
      account.participantId === person.id &&
      account.employerSponsoredAccount &&
      account.type === 'Institutional'
  )
  const otherAdvicedAccounts = accounts.filter(
    account =>
      account.active !== false &&
      account.adviced === true &&
      account.name !==
        (primaryInstitutionalAccount.length > 0 ? primaryInstitutionalAccount[0].name : '')
  )

  const page = findByName('todo')

  let primaryInstitutionalPension = allPensions.filter(
    pension => pension.id === selectedInstitutionalPension.pensionId
  )[0]
  if (primaryInstitutionalPension === undefined) {
    primaryInstitutionalPension = {}
  }

  let otherAcctsMinusAnnuities = []
  otherAdvicedAccounts.forEach(account => {
    if (account.type !== 'Annuity') {
      otherAcctsMinusAnnuities.push(account)
    }
  })

  let showSsiTodo = false
  if (person.includeSpouse) {
    if (
      (person.age < primarySSIBenefits.paymentBeginAge && primarySSIBenefits.includeSsi) ||
      (spouse.age < spouseSSIBenefits.paymentBeginAge && spouseSSIBenefits.includeSsi)
    ) {
      // either person or spouse isn't receiving SSI yet
      showSsiTodo = true
    }
  } else {
    if (person.age < primarySSIBenefits.paymentBeginAge && primarySSIBenefits.includeSsi) {
      // person isn't receiving ssi yet
      showSsiTodo = true
    }
  }

  let showWithdrawTodo = false
  if (
    investmentWithdrawals.primaryLifetimeInvestmentIncome ||
    investmentWithdrawals.spouseLifetimeInvestmentIncome
  ) {
    // if either have modeled annuity
    showWithdrawTodo = true
  } else {
    if (investmentWithdrawals.livingExpensesWithdrawalAdvice.grandTotal > 0) {
      showWithdrawTodo = true
    }
  }

  return (
    <Page
      header={<PageHeader padding='20px 40pt 20pt 434pt' />}
      page={page}
      footer={
        <Wrapper.Footer>
          <Wrapper.FooterTitle>COME BACK NEXT YEAR</Wrapper.FooterTitle>
          <Wrapper.FooterText>
            Over time, changes in investment markets, tax laws, Social Security rules, and other
            factors can all affect your retirement income. Please visit {brandName} every year to
            update your Guide.
          </Wrapper.FooterText>
        </Wrapper.Footer>
      }
      footerOnlyOnLastPage>
      <Wrapper>
        <Wrapper.LetsGetStarted>Let's get started:</Wrapper.LetsGetStarted>
        <Wrapper.HowTo>How to turn your investments into your desired monthly income</Wrapper.HowTo>

        <Wrapper.QuoteText>
          Do it now…While there are no absolute deadlines to take these steps,
          <br />
          this guide will get less accurate over time. If it takes a while to get
          <br />
          started, no worries, just log in again for a fresh version.
        </Wrapper.QuoteText>
        <Wrapper.BlueDivider />

        {showSsiTodo && (
          <Wrapper.BulletText>
            Apply for Social Security four months before retirement date
          </Wrapper.BulletText>
        )}

        {primaryInstitutionalPension.pensionName && (
          <Wrapper.BulletText>
            Apply for {primaryInstitutionalPension.pensionName} payouts to begin as of age{' '}
            {primaryInstitutionalPension.pensionStartAge}
          </Wrapper.BulletText>
        )}

        <Wrapper.BulletText>
          Access your accounts and change how you are invested to our recommendations:
        </Wrapper.BulletText>
        <Wrapper.Ul>
          {/* Institutional Account */}
          {primaryInstitutionalAccount.length > 0 &&
            primaryInstitutionalAccount.map(account => (
              <Wrapper.Li key={account.name}>{account.name}</Wrapper.Li>
            ))}

          {/* Other Accounts */}
          {otherAcctsMinusAnnuities.length > 0 &&
            otherAcctsMinusAnnuities.map(account => (
              <Wrapper.Li key={account.name}>{account.name}</Wrapper.Li>
            ))}
        </Wrapper.Ul>

        {isWithdrawingFromCompanyStock && showWithdrawTodo && (
          <Wrapper.BulletText>
            Your withdrawal instructions include using company stock. Check with your tax advisor as
            to whether you should withdraw or transfer your stock.
          </Wrapper.BulletText>
        )}

        {_.get(withdrawalData, 'totalWithdraws', 0) > 0 && showWithdrawTodo && (
          <Wrapper.BulletText>Withdraw the money needed</Wrapper.BulletText>
        )}

        {(_.get(investmentWithdrawals, 'primaryLifetimeInvestmentIncome', null) ||
          _.get(investmentWithdrawals, 'spouseLifetimeInvestmentIncome', null)) && (
          <Wrapper.BulletText>
            {_.get(investmentWithdrawals, 'primaryLifetimeInvestmentIncome', null) &&
            _.get(investmentWithdrawals, 'spouseLifetimeInvestmentIncome', null)
              ? `Buy annuities (one in ${person.displayName}'s name and one in ${spouse.firstName}'s name)`
              : `Buy annuity (in ${person.displayName}'s name)`}
          </Wrapper.BulletText>
        )}

        {(_.get(investmentWithdrawals, 'primaryLifetimeInvestmentIncome', null) ||
          _.get(investmentWithdrawals, 'spouseLifetimeInvestmentIncome', null)) && (
          <Wrapper.BulletText>
            Update actual annuity information into the {brandName} application and print an updated
            Guide for your records
          </Wrapper.BulletText>
        )}

        {(person.age > 70 || spouse.age > 70) && (
          <Wrapper.BulletText>Get total of minimum required distribution</Wrapper.BulletText>
        )}

        <Wrapper.BulletText>Invest any excess amounts</Wrapper.BulletText>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(TodoList))
