/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'
import { API_URL } from '../../../api'
import { Field, Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import _ from 'lodash'
import { LargeCheckbox } from '../../../guided-toolbox/checkbox'

import { TextInput } from '../../../components'
import { Page, StyledSelectionBox, StyledReceiveEmail, StyledConfirmEmail } from './style'

function SaveEmailDownloadGuide(props) {
  const {
    history,
    spendDownOffboarding: { setSpedDownOffboardingEmail },
    store: {
      contactInfo: { email },
      spendingAcceptedCaseId,
      config,
    },
  } = props

  const handleOpenGuide = async () => {
    try {
      window.open(`${API_URL}guide/generateGuide?caseId=${spendingAcceptedCaseId}`, '_blank')
    } catch (err) {
      console.error(err)
    }
  }

  const submitEmailForm = async ({ email, sendEmail }) => {
    try {
      if (sendEmail) {
        await setSpedDownOffboardingEmail(email)
      }
      history.push('/home')
    } catch (err) {
      console.error(err)
      return {
        [FORM_ERROR]:
          'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
      }
    }
  }

  return (
    <Page.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
        line-height: 1.5rem;
      `}>
      <Page.Heading
        css={css`
          color: #4a606a;
          font-weight: 400;
          text-align: start;
        `}>
        Let's put your plan into action
      </Page.Heading>

      <Page.Container>
        <div
          css={css`
            margin: 10px 0;
          `}>
          Confirm the email address to which you would like your Personalized Retirement Income
          Guide sent. (This will not change the email GuidedChoice{' '}
          {config?.sponsorShortHand ? `or ${config?.sponsorShortHand}` : ''} has on record for you,
          if different.)
        </div>
      </Page.Container>

      <Form
        onSubmit={submitEmailForm}
        initialValues={{ email: email || 'example@example.com', sendEmail: true }}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting, submitError }) => (
          <div>
            <StyledSelectionBox>
              <StyledReceiveEmail>Receive via email?</StyledReceiveEmail>
              <StyledConfirmEmail>
                <Field
                  name='sendEmail'
                  render={({ input, meta }) => (
                    <LargeCheckbox
                      error={meta.error}
                      name={input.name}
                      checked={input.value}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={() => input.onChange(!input.value)}
                      css={css`
                        * {
                          margin-right: 10px;
                          font-size: 1.5rem;
                        }
                      `}
                    />
                  )}
                />
                <EmailField />
              </StyledConfirmEmail>
            </StyledSelectionBox>

            <Page.Container>
              <div>
                You don't have to wait! Download your guide, and view it right away. When you're
                ready, click Done and we will return you to your GuidedChoice home page for further
                planning or where you may log off.
              </div>
            </Page.Container>

            <div
              css={css`
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
              `}>
              <Page.SecondaryButton
                css={css`
                  margin: 40px 0;
                `}
                onClick={handleOpenGuide}>
                <div>Download | view now</div>
                <div>opens new tab</div>
              </Page.SecondaryButton>

              <Page.PrimaryButton
                css={css`
                  margin: 40px 0;
                `}
                onClick={handleSubmit}>
                <div>Done</div>
                <div>send guide | return home</div>
              </Page.PrimaryButton>
            </div>
          </div>
        )}
      />
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(SaveEmailDownloadGuide))

function EmailField() {
  const { input, meta } = useField('email', {
    validate: value =>
      yup
        .string()
        .email()
        .required('Email is required')
        .max(128, 'Maximum character limit 128 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      error={meta.error}
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='name@email.com'
      showError={meta.touched}
      value={input.value}
      width='100%'
      css={css`
        flex: 1;
      `}
    />
  )
}
