import { Form, Field } from 'react-final-form'
import { css } from '@emotion/react'

import Page from './style'
import {
  Spacer,
  TextInput,
  HelpIcon,
  TooltipText,
  DatePicker,
  UsStatesDropdown,
} from '../../../../components'
import { BackButton, NextButton } from '../../../../components/styled'
import cardTypes from '../../../../assets/images/card-types.png'

function SetupPayment(props) {
  const { history } = props
  const onSubmit = () => {}
  const validate = () => {}
  return (
    <Page.Container header='Set up payment'>
      <Spacer space='5px'></Spacer>

      <Form
        initialValues={{}}
        validate={validate}
        onSubmit={onSubmit}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting, submitError }) => (
          <>
            <Page.SetupCardSubheader>
              Submit payment information, then create your account.<br></br>
              *Required information
            </Page.SetupCardSubheader>
            <Spacer space='5px'></Spacer>
            <Page.SetupCardBox>
              <Page.SetupCardBoxTitle>Card info</Page.SetupCardBoxTitle>
              <Spacer space='10px'></Spacer>
              <Page.SetupCardCardTypes
                src={cardTypes}
                alt='supported banks logos'></Page.SetupCardCardTypes>
              <Spacer space='10px'></Spacer>

              <Page.Field label='Name on card*'>
                <Field
                  name='nameOnCard'
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      id='nameOnCard'
                      error={meta.error}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      placeholder='John Smith'
                      showError={meta.touched}
                      value={input.value}
                      width='220px'
                    />
                  )}
                />
              </Page.Field>

              <Page.Field label='Card number*'>
                <Field
                  name='cardNumber'
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      id='cardNumber'
                      error={meta.error}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      placeholder='0123 4567 8901 2345'
                      showError={meta.touched}
                      value={input.value}
                      width='220px'
                    />
                  )}
                />
              </Page.Field>

              <Page.Field label='Expiry*'>
                <Field
                  name='expiry'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <DatePicker
                      handleChange={(name, value) => input.onChange(value)}
                      maxYear={new Date().getFullYear()}
                      minYear={1910}
                      name={input.name}
                      date={input.value}
                      showError={meta.touched}
                      error={meta.error}
                      hideDay
                    />
                  )}
                />
              </Page.Field>

              <Page.Field
                id='cvv'
                label={
                  <div
                    css={css`
                      display: flex;
                      justify-content: flex-end;
                      align-items: baseline;
                      & > div {
                        line-height: 100%;
                      }
                    `}>
                    CVV*&nbsp; <HelpIcon tooltip={TooltipText.cvv()} />
                  </div>
                }>
                <Field
                  name='cvv'
                  format={value => (value === null ? undefined : value)}
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      id='cvv'
                      error={meta.error}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      maxLength={3}
                      placeholder='123'
                      showError={meta.touched}
                      value={input.value}
                      width='220px'
                    />
                  )}
                />
              </Page.Field>
            </Page.SetupCardBox>
            <Spacer space='15px'></Spacer>

            <Page.SetupCardBox>
              <Page.SetupCardBoxTitle>Billing address</Page.SetupCardBoxTitle>
              <Spacer space='5px'></Spacer>
              <Page.Field label='Street address (line 1)*'>
                <Field
                  name='streetAddressLine1'
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      id='streetAddressLine1'
                      error={meta.error}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      placeholder='123 Main street'
                      showError={meta.touched}
                      value={input.value}
                      width='220px'
                    />
                  )}
                />
              </Page.Field>

              <Page.Field label='Street address (line 2)*'>
                <Field
                  name='streetAddressLine2'
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      id='streetAddressLine2'
                      error={meta.error}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      placeholder='Apt 1'
                      showError={meta.touched}
                      value={input.value}
                      width='220px'
                    />
                  )}
                />
              </Page.Field>

              <Page.Field label='City*'>
                <Field
                  name='city'
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      id='city'
                      error={meta.error}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      placeholder='Virginia City'
                      showError={meta.touched}
                      value={input.value}
                      width='220px'
                    />
                  )}
                />
              </Page.Field>

              <Page.Field label='State/Province*'>
                <Field
                  name='state'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <UsStatesDropdown
                      id='state'
                      name={input.name}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      placeholder='Select'
                      selected={input.value || ''}
                      showError={meta.touched}
                      error={meta.error}
                    />
                  )}
                />
              </Page.Field>

              <Page.Field label='Zip*'>
                <Field
                  name='zip'
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      id='zip'
                      error={meta.error}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      placeholder='12345'
                      maxLength={5}
                      showError={meta.touched}
                      value={input.value}
                      width='220px'
                    />
                  )}
                />
              </Page.Field>
            </Page.SetupCardBox>
            <Spacer space='15px'></Spacer>

            <Page.SetupCardBoxTitle>Authorize</Page.SetupCardBoxTitle>
            <Spacer space='15px'></Spacer>
            <Page.SetupFooter>
              Enter the email address of the GuidedChoice account holder (which may or may not be
              the payer's email). Then click Agree & Continue to send payment for the current year.
              By doing so, you also agree that you will be charged automatically the full-year State
              of Florida price of $10.00 on or about your subscription end date until you cancel.
              You will be notified about 10 days prior to renewal. Payments powered by Stripe; see
              Terms of Service for further details.
            </Page.SetupFooter>

            <Page.SetupEmailWrapper>
              <Page.SetupEmailText>
                Email of GuidedChoice account holder* &nbsp;
                <HelpIcon tooltip={TooltipText.accountHolderEmail()} />
                &nbsp;&nbsp;
              </Page.SetupEmailText>

              <Field
                name='email'
                parse={v => v}
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <TextInput
                    error={meta.error}
                    name={input.name}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    onChange={(name, value) => input.onChange(value)}
                    placeholder='name@email.com'
                    showError={meta.touched}
                    value={input.value}
                  />
                )}
              />
            </Page.SetupEmailWrapper>

            <Page.SetupButtonsBox>
              <BackButton onClick={() => {}}>Cancel</BackButton>
              <NextButton
                width='200px'
                onClick={() => {
                  history.push('/register/enter-auth-code/verify-2FA')
                }}>
                Agree & Continue
              </NextButton>
            </Page.SetupButtonsBox>
          </>
        )}
      />
    </Page.Container>
  )
}

export default SetupPayment
