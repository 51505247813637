/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { inject, observer } from 'mobx-react'
import MarriedSSI from './MarriedSSI'
import SingleSSI from './SingleSSI'

const SocialSecurityDashboard = function (props) {
  const {
    history,
    store: { person },
  } = props

  if (person.maritalStatus && person.includeSpouse) {
    return <MarriedSSI history={history} />
  }

  if (!person.maritalStatus || !person.includeSpouse) {
    return <SingleSSI history={history} />
  }

  return null
}

export default inject('store', 'main')(observer(SocialSecurityDashboard))
