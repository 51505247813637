import styled from '@emotion/styled'
import { FillGauge } from '../../../components'

const SuggestionStyles = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 28px 0;

  & > div:first-of-type {
    color: ${p => p.theme.darkestGray};
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.5rem;
    max-width: 232px;
  }

  & > svg:first-of-type {
    margin: 20px 0;
  }
`
const Suggestion = () => (
  <SuggestionStyles>
    <div>We suggest you seek to replace</div>
    <FillGauge fill={80} />
  </SuggestionStyles>
)

export default Suggestion
