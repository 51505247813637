import styled from '@emotion/styled'

const Wrapper = styled.div``

Wrapper.Header = styled.div`
  background-color: #038ab7;
  height: 521pt;
`

Wrapper.CoverTitle = styled.div`
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  /* font-size: 3.75rem; */
  font-size: 45pt;
  line-height: 54pt;
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 150px 0 0;
  white-space: pre-line;
`

Wrapper.CoverText = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  color: white;
  text-align: center;
  padding: 56pt 0 38pt;
  line-height: 1.35;
`

Wrapper.ContentsBox = styled.div`
  color: white;
  text-align: center;
  width: 458pt;
  margin: auto;
`

Wrapper.ContentsTitle = styled.div`
  font-size: 1.167rem;
  padding: 20px;
`

Wrapper.ContentCard = styled.div`
  padding: 10pt;
  color: white;

  height: 201pt;
  width: 458pt;
  background-color: #67cd36;
`

Wrapper.ContentCard.Title = styled.div`
  /* font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 1.75rem;
  text-align: center; */

  white-space: pre-line;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 28pt;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 38pt;
  text-align: center;
`

Wrapper.LogoContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  /*padding-top: 48px;*/
  margin-top: 48px;
  & img {
    width: 100%;
  }
`

Wrapper.AdviceBox = styled.div`
  background-color: ${p => p.theme.darkestGray};
  width: 524pt;
  height: 82pt;
  white-space: pre-line;
  padding: 20px;

  color: #ffffff;
  font-family: Aleo;
  font-size: 21pt;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25pt;
  text-align: center;
  margin-left: -33pt;
  margin-top: -119pt;
`

export { Wrapper }
