/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const button = 'rt-navigation-button'

const horizontal = 'rt-navigation-horizontal'

const link = 'rt-navigation-link'

const vertical = 'rt-navigation-vertical'

const _verticalHorizontal = css`
  padding: ${reduceCSSCalc(`calc(${ds.get('navigation.space')} / 2)`)};

  ${ds.get('reset')};

  & > [data-react-toolbox='link'] {
    color: ${ds.get('navigation.color')};
  }
`

const _horizontal = css`
  ${_verticalHorizontal};
  & > [data-react-toolbox='button'],
  & > [data-react-toolbox='link'] {
    display: inline-block;
    margin: 0 ${reduceCSSCalc(`calc(${ds.get('navigation.space')} / 2)`)};
  }
`

const _vertical = css`
  ${_verticalHorizontal};
  & > [data-react-toolbox='button'],
  & > [data-react-toolbox='link'] {
    display: block;
    margin: ${reduceCSSCalc(`calc(${ds.get('navigation.space')} / 2)`)};
  }
`

export default {
  button,
  horizontal: cx(horizontal, _horizontal),
  link,
  vertical: cx(vertical, _vertical),
}
