import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Field } from 'react-final-form'
import styled from '@emotion/styled'

import { Slider, Spacer, TooltipText, HelpPersonIcon, HelpIcon } from '../../../components'
import { RangeScale } from '../elements'
import { riskLevelTextCalc, riskScaleIndexCalc, withinRangeCalc } from './EditDrawerUtils'

const FieldContainer = styled.div`
  display: flex;
  padding: 20px 0 12px;
  @media (max-width: 720px) {
    flex-wrap: wrap;
  }
`

const InputBox = styled.div`
  flex: 0 1 300px;
  min-height: 55px;
  @media (max-width: 720px) {
    flex: 0 1 100%;
  }
`

const SliderBox = styled.div`
  position: relative;
  flex: 1 1 400px;
  padding: 0 8px;
  margin-bottom: 12px;
  @media (max-width: 720px) {
    flex: 0 1 100%;
    margin-top: 20px;
  }
`

const Ruler = styled.div`
  position: relative;
  height: 48px;
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
  border-top: 2px solid #a09cff;
`

const RulerArrowLeft = styled.div`
  position: absolute;
  cursor: pointer;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 16px solid #a09cff;
  left: 2px;
  top: 2px;
`

const RulerArrowRight = styled.div`
  position: absolute;
  cursor: pointer;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 16px solid #a09cff;
  right: 2px;
  top: 2px;
`

const RulerItem = styled.div`
  flex-basis: auto;
  position: relative;
  border: 1px solid #a09cff;
  height: ${p => (p.half ? '10px' : '20px')};
`

const RulerBreakPoints7 = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7]

const RulerBreakPoints100 = [
  0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
]

const RulerText = styled.span`
  position: absolute;
  color: #716fb2;
  font-size: 12px;
  top: 20px;
  transform: translateX(-50%);
`

const PercentText = styled.span`
  font-size: 0.875rem;
  color: #677b84;
`

// const InputLabel = styled.div`
//   font-size: 0.875rem;
//   color: #677b84;
//   padding-top: 6px;
// `;

// const ReadOnlyInput = styled.span`
//   font-size: 1.125rem;
//   color: #3a4d57;
//   padding-right: 12px;
// `;

const SliderText = styled.div`
  display: flex;
  justify-content: space-between;
  color: #677b84;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  & > div:nth-of-type(1) {
    max-width: 80px;
  }
  & > div:nth-of-type(2) {
    max-width: 45px;
    text-align: right;
  }
`

class RiskInput extends Component {
  generateRuler() {
    const {
      userSettings: { riskRulerState },
    } = this.props.store

    switch (riskRulerState) {
      case 'none':
        return [0, 100].map(item => item % 10 === 0 && <RulerItem key={item} />)

      case 'scale7':
        return RulerBreakPoints7.map(
          item =>
            (item % 1 === 0 && (
              <RulerItem key={item}>
                <RulerText>{item !== 0 && item}</RulerText>
              </RulerItem>
            )) || <RulerItem key={item} half />
        )

      case 'scale100':
        return RulerBreakPoints100.map(
          item =>
            (item % 10 === 0 && (
              <RulerItem key={item}>
                <RulerText>{item !== 0 && item}</RulerText>
              </RulerItem>
            )) || <RulerItem key={item} half />
        )

      default:
        return [0, 100].map(item => item % 10 === 0 && <RulerItem key={item} />)
    }
  }

  render() {
    const {
      features: { autoRiskAdjustEnabled },
      account: { autoRiskLevelAdjustmentStatus },
      handleRiskRulerForward,
      handleRiskRulerBack,
      modifiedCase,
      recommendedCase,
    } = this.props.store
    const { values, theme } = this.props
    const { riskLevel: ghostValue } = modifiedCase || recommendedCase

    const riskLevelText = riskLevelTextCalc({
      riskLevel: values.riskLevel,
      recomMaxRiskLevel: values.recomMaxRiskLevel,
      recomMinRiskLevel: values.recomMinRiskLevel,
    })

    const riskScaleIndex = riskScaleIndexCalc({
      riskLevel: values.riskLevel,
      recomMaxRiskLevel: values.recomMaxRiskLevel,
      recomMinRiskLevel: values.recomMinRiskLevel,
    })

    const withinRange = withinRangeCalc(riskScaleIndex)

    return (
      <FieldContainer>
        <InputBox>
          <div
            css={css`
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            `}>
            <div
              css={css`
                font-size: 14px;
                margin-bottom: 1rem;
                color: #4a606a;
                font-weight: bold;
              `}>
              {modifiedCase && recommendedCase.riskLevel !== modifiedCase.riskLevel
                ? 'Modified'
                : 'Recommended'}{' '}
              risk
            </div>
            <div>
              <div
                css={css`
                  height: 55px;
                `}>
                <PercentText>{riskLevelText}</PercentText>
                <Spacer space='2px' />
                {withinRange ? (
                  <RangeScale rectangleActive={riskScaleIndex} showTriangle theme={theme} />
                ) : null}
              </div>
              <HelpPersonIcon
                size='xLarge'
                helpLabel='Preferred range of investment mix'
                tooltip={TooltipText.preferredRangeInvestmentMix()}
              />
              {autoRiskAdjustEnabled && (
                <div
                  css={css`
                    font-size: 12px;
                    color: #677b84;
                  `}>
                  * Automatic risk level adjustment is{' '}
                  {autoRiskLevelAdjustmentStatus ? 'included ' : 'excluded '}{' '}
                  <HelpIcon size='medium' tooltip={TooltipText.autoRiskAdjustment()} />
                </div>
              )}
            </div>
          </div>
        </InputBox>
        <SliderBox>
          <div
            css={css`
              position: absolute;
              background-color: rgba(113, 111, 178, 0.2);
              top: 0;
              left: 24px;
              height: 100%;
              width: calc(100% - 48px);
            `}
          />

          <RulerArrowLeft onClick={handleRiskRulerBack} />

          <RulerArrowRight onClick={handleRiskRulerForward} />

          <Ruler>{this.generateRuler()}</Ruler>

          <Field
            name='riskLevel'
            render={({ input }) => (
              <Slider
                min={101}
                max={600}
                value={input.value || 101}
                onChange={input.onChange}
                step={1}
                minRisk={values.recomMinRiskLevel}
                maxRisk={values.recomMaxRiskLevel}
                ghostValue={ghostValue}
              />
            )}
          />

          <SliderText>
            <div>More cash/bonds</div>
            <div>More stock</div>
          </SliderText>
        </SliderBox>
      </FieldContainer>
    )
  }
}

export default inject('store')(observer(RiskInput))
