const avatar = `
<svg id="avatar_normal_c" data-name="avatar_normal_c" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24.5">
<defs>
    <style>.avatar_normal_c_cls-1{stroke:#fff;stroke-miterlimit:10;}</style>
</defs>
<title>avatar-normal-c</title>
<g id="Symbols" class="icon-color">
    <g id="icn_avatar-normal" data-name="icn/avatar-normal">
        <g id="Avatar">
            <path id="Shape" class="avatar_normal_c_cls-1" d="M12,12h0A4.8,4.8,0,1,0,7.2,7.2,4.8,4.8,0,0,0,12,12h0Zm0,2.4h0a7.2,7.2,0,1,1,7.2-7.2A7.2,7.2,0,0,1,12,14.4h0Z" transform="translate(0.5 0.5)" />
            <path id="Shape-2" data-name="Shape" class="avatar_normal_c_cls-1" d="M21.6,24A9.6,9.6,0,1,0,2.4,24M0,24a12,12,0,0,1,24,0" transform="translate(0.5 0.5)" />
        </g>
    </g>
</g>
</svg>
`

export default avatar
