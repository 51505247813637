const BulletPiggyBank = `
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"/>
            <path d="M10 10h30v30H10z"/>
            <g fill="#02769D" fill-rule="nonzero">
                <path d="M18.848 13.425a5.663 5.663 0 0 0-5.655 5.655 5.663 5.663 0 0 0 5.655 5.655c3.107 0 5.655-2.548 5.655-5.655a5.663 5.663 0 0 0-5.655-5.655z"/>
                <path d="m35.794 23.48-2.355-.405a7.33 7.33 0 0 0-1.042-1.274 5 5 0 0 1 1.756-2.895l.135-.116a.66.66 0 0 0 .213-.753.685.685 0 0 0-.637-.463H32.05a4.321 4.321 0 0 0-3.803 2.297c-.25-.02-.482-.039-.714-.039h-1.718c-.386 3.513-3.358 6.254-6.967 6.254a7.01 7.01 0 0 1-5.037-2.123 7.11 7.11 0 0 0-.637 2.895 7.013 7.013 0 0 0 2.721 5.539v3.513c0 .366.309.675.676.675h2.72c.29 0 .56-.193.638-.463l.753-2.258h6.35l.752 2.258c.097.27.348.463.637.463h2.721a.684.684 0 0 0 .676-.675v-3.513a7.178 7.178 0 0 0 2.142-2.76l1.969-.791a.677.677 0 0 0 .425-.637v-4.073c.019-.328-.232-.598-.56-.656zm-5.308 1.563a.684.684 0 0 1-.675-.675c0-.367.308-.676.675-.676.367 0 .676.31.676.676a.684.684 0 0 1-.676.675z"/>
            </g>
        </g>
    </svg>
`

export default BulletPiggyBank
