import styled from '@emotion/styled'
import { css } from '@emotion/react'

const TableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #9eacb0;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 24px;
  font-weight: 300;

  & > div:first-of-type {
    color: ${p => p.theme.greyTextColor};
  }
`

const TableHeader = styled.div`
  display: flex;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  color: #7a8e96;
  line-height: 1.5;
  overflow-wrap: break-word;

  /* Investment type */
  & > div:nth-of-type(1) {
    width: 20%;
    padding: 6px 8px;
  }

  /* Ticker */
  & > div:nth-of-type(2) {
    width: 20%;
    padding: 6px 8px;
  }

  /* Investment name */
  & > div:nth-of-type(3) {
    width: 40%;
    padding: 6px 8px;
    cursor: pointer;
  }

  /* Balance */
  & > div:nth-of-type(4) {
    width: 20%;
    padding: 6px 8px;
    cursor: pointer;
    text-align: right;
  }
`

const TableList = styled.div`
  & > div:first-of-type {
    margin-top: 0;
  }
`

const TableRow = styled.div`
  display: flex;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  color: #7a8e96;
  line-height: 1.5;
  border: ${p => p.theme.investmentListItemBorder};
  background-color: ${p => p.theme.investmentListItemBgColor};
  margin: 14px 0;
  overflow-wrap: break-word;
  font-weight: 300;

  /* Investment type */
  & > div:nth-of-type(1) {
    width: 20%;
    padding: 12px 8px;
  }

  /* Ticker */
  & > div:nth-of-type(2) {
    width: 20%;
    padding: 12px 8px;
  }

  /* Investment name */
  & > div:nth-of-type(3) {
    width: 40%;
    padding: 12px 8px;
  }

  /* Balance */
  & > div:nth-of-type(4) {
    width: 20%;
    padding: 12px 8px;
    text-align: right;
    color: ${p => p.theme.balanceTextColor};
  }
`

const sortArrowStyle = ({ direction }) => {
  if (direction === 'neutral') {
    return css`
      border: solid #9eacb0;
      color: #9eacb0;
      transform: rotate(-45deg);
    `
  }
  if (direction === 'up') {
    return css`
      border: solid black;
      color: black;
      transform: rotate(-135deg);
    `
  }
  if (direction === 'down') {
    return css`
      border: solid black;
      color: black;
      transform: rotate(45deg);
    `
  }
}

const SortArrow = styled.i`
  ${sortArrowStyle};
  font-size: 25px;
  border-width: 0 3px 3px 0;
  margin-left: 10px;
  display: inline-block;
  padding: 3px;
  transition: border-color 0.35s, transform 0.25s;
`
const BalanceLabel = styled.div`
  color: #7a8e96;
`
const Balance = styled.a`
  color: ${p => p.theme.balanceTextColor};
`

export { TableTitle, TableHeader, TableList, TableRow, SortArrow, BalanceLabel, Balance }
