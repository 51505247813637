import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import dayjs from 'dayjs'
import _ from 'lodash'

import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { Spacer } from '../../../components/'
import HistoricalDataLoginGroup from './HistoricalDataLoginGroup'
import HistoricalDataGuideGroup from './HistoricalDataGuideGroup'
import HistoricalDataChangeSavingsGroup from './HistoricalDataChangeSavingsGroup'
import HistoricalDataStatementsGroup from './HistoricalDataStatementsGroup'

class HistoricalDataDrawer extends Component {
  yearlySort = sortedList => {
    const sortedListWithYear = sortedList.map(item => ({
      ...item,
      year: dayjs(item.timeStamp).format('YYYY'),
    }))

    const uniqueYears = _.sortedUniq(sortedListWithYear.map(item => item.year))

    return uniqueYears.map(year => ({
      year,
      data: sortedListWithYear.filter(login => login.year === year),
    }))
  }

  render() {
    const {
      onClose,
      store: { features, userLogs },
    } = this.props
    const {
      sortedLoginList,
      sortedGuideList,
      sortedSavingList,
      changeSavingsList,
      sortedStatements,
    } = userLogs

    return (
      <div>
        <p
          css={css`
            color: #4a606a;
            font-size: 1rem;
            margin: 12px;
          `}>
          Advisory Service
        </p>

        {features.enableLoginHistory &&
          this.yearlySort(sortedLoginList).map(login => (
            <HistoricalDataLoginGroup
              key={login.year}
              year={login.year}
              sortedLoginList={login.data}
            />
          ))}

        <Spacer space='16px' />

        {features.enablePreviousGuides &&
          this.yearlySort(sortedGuideList).map(guide => (
            <HistoricalDataGuideGroup
              key={guide.year}
              year={guide.year}
              userLogs={userLogs}
              sortedGuideList={guide.data}
            />
          ))}

        <Spacer space='16px' />

        {changeSavingsList.length > 0 &&
          this.yearlySort(sortedSavingList).map(changeSaving => (
            <HistoricalDataChangeSavingsGroup
              key={changeSaving.year}
              year={changeSaving.year}
              userLogs={userLogs}
              sortedSavingList={changeSaving.data}
            />
          ))}

        <Spacer space='16px' />

        {sortedStatements.length > 0 && (
          <HistoricalDataStatementsGroup sortedStatements={sortedStatements} />
        )}

        <ButtonsBox>
          <BackButton backgroundColor='#FFFFFF' onClick={onClose}>
            Cancel
          </BackButton>
          <NextButton onClick={onClose}>Done</NextButton>
        </ButtonsBox>
      </div>
    )
  }
}

export default inject('store')(observer(HistoricalDataDrawer))
