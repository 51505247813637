import { Component } from 'react'
import { Provider, inject, observer } from 'mobx-react'

import { Pdf } from './elements'
import CoverPage from './CoverPage'
import { GoalPage1, GoalPage2 } from './GoalsPage'
import AccountPage from './AccountPage'
import CompanyStockPage from './CompanyStockPage/CompanyStockPage'
import VestedStockOptions from './AccountPage/VestedStockOptions'
import TodoPage from './TodoPage'
import MoreTodoPage from './MoreTodoPage'
import DataAlertPage from './DataAlertPage'
import {
  HoldingPage1,
  CatchupContributions,
  HoldingPage2,
  HoldingChartPage,
  OtherPage1,
  OtherChartPage,
  IRANote,
} from './InvestmentsPage'
import { SavingPage1, SavingPage2 } from './SavingPage'
import Disclosure from './DisclosurePage'
import GuideStore from './GuideStore'

class Guide extends Component {
  constructor(props) {
    super(props)
    const { store, location, match } = this.props
    this.state = GuideStore.create({}, { store, location, match })
  }

  static displayName = 'Guide'
  static propTypes = {}

  render() {
    const { isRetail } = this.props.store.config

    if (this.state.status === 'loading') {
      return <div>Loading</div>
    }

    if (this.state.status === 'error') {
      return <div>{this.state.error}</div>
    }

    const { fetchComplete } = this.state.guidePages

    if (!fetchComplete) return []

    return (
      <Provider guide={this.state}>
        <Pdf>
          <CoverPage />
          <GoalPage1 />
          <GoalPage2 />
          <AccountPage />
          {isRetail && <DataAlertPage />}
          {!isRetail && <VestedStockOptions />}
          <TodoPage />
          {isRetail && <MoreTodoPage />}
          <HoldingPage1 />
          {!isRetail && <CatchupContributions />}
          <CompanyStockPage />
          <HoldingPage2 />
          <HoldingChartPage />
          <SavingPage1 />
          <SavingPage2 />
          <OtherPage1 />
          <OtherChartPage />
          <IRANote />
          <Disclosure />
        </Pdf>
      </Provider>
    )
  }
}

export default inject('store')(observer(Guide))
