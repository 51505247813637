import { types, flow } from 'mobx-state-tree'

import { API } from '../api'
const { model, maybeNull, number, string } = types

const Limits = model({
  catchupAge: maybeNull(number),
  catchupLimitAmount: maybeNull(number),
  overallLimit: maybeNull(number),
  rothPreTaxLimitAmount: maybeNull(number),
  year: maybeNull(number),
  dlr401k: maybeNull(number),
  iraAmount: maybeNull(number),
  catchupIraAmount: maybeNull(number),
  annualContributionLimit: maybeNull(number),
}).views(self => ({
  get iraTotalCatchupLimit() {
    return self.iraAmount + self.catchupIraAmount
  },
}))

const Plan = model({
  // One of 'P', 'D', or 'B'
  allowedContribMethod: string,
  feeRate: maybeNull(number),
  id: maybeNull(number),
  limits: Limits,
  planName: maybeNull(string),
  recordKeeperName: maybeNull(string),
  sponsorName: maybeNull(string),
}).views(self => ({
  get contribMethodChangeAllowed() {
    return self.allowedContribMethod === 'B'
  },
  get feeRateAmount() {
    return self.feeRate / 10
  },
}))

const PlanOverview = model({
  plan: maybeNull(Plan),
}).actions(self => ({
  getPlanOverview: flow(function* () {
    const plan = yield API.get('plan')
    self.plan = plan.data || {}
  }),
}))

export default PlanOverview
