import { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { FieldContainer, Title, BulletText } from '../styled'

class SpousePlan extends Component {
  render() {
    const { title, contribution, investmentMix } = this.props
    return (
      <FieldContainer>
        {title && <Title>{title}</Title>}
        {contribution && <BulletText>{contribution}</BulletText>}
        {investmentMix && <BulletText>{investmentMix}</BulletText>}
      </FieldContainer>
    )
  }
}

export default inject('store')(observer(SpousePlan))
