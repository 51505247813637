import variables from '../variables'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const tableColumnSpacing = reduceCSSCalc(`calc(3.6 * ${unit})`)

const tableCellPadding = reduceCSSCalc(`calc(2.4 * ${unit})`)

const table = {
  fontSize: reduceCSSCalc(`calc(1.3 * ${unit})`),
  headerFontSize: reduceCSSCalc(`calc(1.2 * ${unit})`),
  headerSortIconSize: reduceCSSCalc(`calc(1.6 * ${unit})`),
  headerColor: color.convert('color(#000 a(54%))'),
  headerSortedColor: color.convert('color(#000 a(87%))'),
  headerSortedIconHoverColor: color.convert('color(#000 a(26%))'),
  dividers: '1px solid #e6e6e6',
  rowColor: color.convert('color(#000 a(87%))'),
  hoverColor: '#eee',
  selectionColor: '#f5f5f5',
  rowHeight: reduceCSSCalc(`calc(4.8 * ${unit})`),
  columnSpacing: tableColumnSpacing,
  columnPadding: reduceCSSCalc(`calc(${tableColumnSpacing} / 2)`),
  cellPadding: tableCellPadding,
  cellTop: reduceCSSCalc(`calc(${tableCellPadding} / 2)`),
}

export default table
