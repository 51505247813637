import * as yup from 'yup'

export const addSchema = yup.object().shape({
  distOptionName: yup
    .string()
    .nullable()
    .oneOf(
      [
        'Single Life',
        'Joint and Survivor',
        'Joint Life',
        'Life with Period Certain',
        'Period Certain',
      ],
      'This field is required'
    )
    .required('This field is required'),
  beginYear: yup.number().required('This field is required'),
  annuityStartAge: yup.number().required('This field is required'),
  endYear: yup.number().when('distOptionName', {
    is: val => val === 'Life with Period Certain' || val === 'Period Certain',
    then: schema =>
      schema
        .required('This field is required')
        .moreThan(yup.ref('beginYear'), 'End year must come after start year'),
    otherwise: schema => schema,
  }),
  paymentEndAge: yup.number().when('distOptionName', {
    is: val => val === 'Life with Period Certain' || val === 'Period Certain',
    then: schema =>
      schema
        .required('This field is required')
        .moreThan(yup.ref('annuityStartAge'), 'End age must be greater than start age'),
    otherwise: schema => schema,
  }),
  monthlyIncome: yup
    .number()
    .required('This field is required')
    .moreThan(0, 'Monthly payment must be greater than 0'),
  survivorFraction: yup.number().when('distOptionName', {
    is: val => val === 'Joint and Survivor' || val === 'Joint Life',
    then: schema => schema.required('This field is required'),
    otherwise: schema => schema,
  }),
  colaWithCpi: yup
    .mixed()
    .oneOf([false, true, 'None of these apply'], 'This field is required')
    .required('This field is required'),
  colaAmtRate: yup.number().when('colaWithCpi', {
    is: false,
    then: schema => schema.required('This field is required'),
    otherwise: schema => schema,
  }),
  taxtype: yup.number().required('This field is required'),
  cost: yup.number().when('taxtype', {
    is: 1,
    then: schema =>
      schema.required('This field is required').moreThan(0, 'Cost must be greater than 0'),
    otherwise: schema => schema,
  }),
})

export const editSchema = yup.object().shape({
  distOptionName: yup
    .string()
    .nullable()
    .oneOf(
      [
        'Single Life',
        'Joint and Survivor',
        'Joint Life',
        'Life with Period Certain',
        'Period Certain',
      ],
      'This field is required'
    )
    .required('This field is required'),
  beginYear: yup.number().required('This field is required').moreThan(0, 'This field is required'),
  paymentStartAge: yup
    .number()
    .required('This field is required')
    .moreThan(0, 'This field is required'),
  endYear: yup.number().when('distOptionName', {
    is: val => val === 'Life with Period Certain' || val === 'Period Certain',
    then: schema =>
      schema
        .required('This field is required')
        .moreThan(yup.ref('beginYear'), 'End year must come after start year'),
    otherwise: schema => schema,
  }),
  paymentEndAge: yup.number().when('distOptionName', {
    is: val => val === 'Life with Period Certain' || val === 'Period Certain',
    then: schema =>
      schema
        .required('This field is required')
        .moreThan(yup.ref('paymentStartAge'), 'End age must be greater than start age'),
    otherwise: schema => schema,
  }),
  monthlyIncome: yup
    .number()
    .required('This field is required')
    .moreThan(0, 'Monthly payment must be greater than 0'),
  survivorFraction: yup.number().when('distOptionName', {
    is: val => val === 'Joint and Survivor' || val === 'Joint Life',
    then: schema => schema.required('This field is required'),
    otherwise: schema => schema,
  }),
  colaWithCpi: yup
    .mixed()
    .oneOf([false, true, 'None of these apply'], 'This field is required')
    .required('This field is required'),
  colaAmtRate: yup.number().when('colaWithCpi', {
    is: false,
    then: schema => schema.required('This field is required'),
    otherwise: schema => schema,
  }),
  taxtype: yup.number().required('This field is required'),
  cost: yup.number().when('taxtype', {
    is: 1,
    then: schema =>
      schema.required('This field is required').moreThan(0, 'Cost must be greater than 0'),
    otherwise: schema => schema,
  }),
})
