import PropTypes from 'prop-types'
import { cx } from '@emotion/css'
import { themr } from '../react-css-themr'
import { LIST } from '../identifiers'

const ListItemText = ({ className, primary, children, theme, ...other }) => {
  const _className = cx(theme.itemText, { [theme.primary]: primary }, className)
  return (
    <span data-react-toolbox='list-item-text' className={_className} {...other}>
      {children}
    </span>
  )
}

ListItemText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  primary: PropTypes.bool,
  theme: PropTypes.shape({
    itemText: PropTypes.string,
    primary: PropTypes.string,
  }),
}

ListItemText.defaultProps = {
  primary: false,
}

export default themr(LIST)(ListItemText)
export { ListItemText }
