import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { types, flow, getEnv } from 'mobx-state-tree'
import URLSearchParams from 'url-search-params'
import _ from 'lodash'

import { API } from './api'
import { Logout } from './views'
import { Loading, LoadingMessage } from './components'

const { model, enumeration } = types

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const authCheck = async () => {
  let checkStatus = false
  let retryCount = 0

  while (checkStatus === false) {
    const response = await API.get('authTracker')
    checkStatus = _.get(response.data, 'complete', false)

    if (!checkStatus) {
      retryCount = retryCount + 1
      await sleep(1100)
    }

    if (retryCount > 30) {
      throw new Error('Retry limit exceeded (30)')
    }
  }
}

const State = model({
  status: enumeration(['loading', 'done', 'error']),
  tokenExists: false,
}).actions(self => ({
  afterCreate: flow(function* () {
    self.status = 'loading'
    const { store, history, location } = getEnv(self)
    const params = new URLSearchParams(location.search)
    const { auth, getConfig, getFeatures, getAccount } = store

    try {
      if (params.get('sso') && params.get('signature') && params.get('recordKeeper')) {
        self.tokenExists = true

        yield auth.authenticate(
          params.get('sso'),
          params.get('signature'),
          params.get('recordKeeper')
        )
      }

      yield authCheck()

      yield Promise.all([getConfig(), getFeatures(), getAccount()])

      const { config } = store

      // Landing page for Discontinue or Upgrade to Managed Account
      if (config.template.landingPage === 'productSelect') {
        history.replace('/product-select')
        auth.authorize()
        self.status = 'done'
        return
      }

      // Standard Application Entry Point
      if (!config.onBoarded) {
        history.replace('/welcome')
      }

      if (config.onBoarded && config.requireToAcceptTerms) {
        history.replace('/welcome/reaccept-terms')
      }

      auth.authorize()
      self.status = 'done'
    } catch (err) {
      console.error(err)
      self.status = 'error'
    }
  }),
}))

class AuthCheck extends Component {
  constructor(props) {
    super(props)
    const { store, history, location } = props
    this.state = State.create({ status: 'loading' }, { store, history, location })
  }

  render() {
    if (this.state.status === 'error') {
      return <Logout />
    }

    return (
      <div
        css={css`
          padding: 20px;
        `}>
        <LoadingMessage />
        <Loading />
      </div>
    )
  }
}

export default inject('store')(observer(AuthCheck))
