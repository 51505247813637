import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import _ from 'lodash'

import { Coin, Bank, LineChart, PieChart } from '../../../../components/icons'
import { numberToDollars } from '../../../../utils'
import Grid from './Grid'

const mapNameToIcon = {
  Cash: (
    <Coin
      css={css`
        width: 100%;
        height: auto;
      `}
    />
  ),
  Bonds: (
    <Bank
      css={css`
        width: 100%;
        height: auto;
      `}
    />
  ),
  Stocks: (
    <LineChart
      css={css`
        width: 100%;
        height: auto;
      `}
    />
  ),
  Misc: (
    <PieChart
      css={css`
        width: 100%;
        height: auto;
      `}
    />
  ),
}
const ItemName = styled.div`
  margin: 0.5rem 1rem 1.5rem 0;
  color: #022a3a;
  font-size: 14px;
`
const Graph = styled.div`
  min-height: 56px;
  width: 200px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ isOld }) =>
    isOld &&
    css`
      color: #7a8e96;
      border-left: none;
    `};
`
const Amount = styled.div`
  margin: 0.5rem 0 0.25rem 1rem;
  ${({ isOld }) =>
    isOld &&
    css`
      text-align: right;
      margin: 0.5rem 1rem 0.25rem 0;
    `};
`
const Bar = styled.div`
  height: 12px;
  width: ${({ percentage }) => percentage || 0}%;
  margin-bottom: 1rem;
  ${({ isOld }) =>
    isOld &&
    css`
      background-color: #7a8e96;
      border-left: none;
      margin-left: auto;
    `};
`

const showOld = oldValue => oldValue !== null
const showNew = newValue => newValue !== null

const OldValue = ({ oldValue, hideOld }) =>
  !hideOld &&
  showOld(oldValue) && (
    <Graph isOld>
      <Amount isOld>
        ${numberToDollars(oldValue.dollar)}
        &nbsp;&nbsp;|&nbsp;&nbsp;
        {numberToDollars(oldValue.percent, true)}%
      </Amount>
      <Bar isOld percentage={numberToDollars(oldValue.percent, true)} />
    </Graph>
  )

const NewValue = ({ newValue, theme }) =>
  showNew(newValue) && (
    <Graph
      css={css`
        color: ${theme.primaryColor};
      `}>
      <Amount>
        {numberToDollars(newValue.percent, true)}
        %&nbsp;&nbsp;|&nbsp;&nbsp;$
        {numberToDollars(newValue.dollar)}
      </Amount>
      <Bar
        percentage={numberToDollars(newValue.percent, true)}
        css={css`
          background-color: ${theme.primaryColor};
        `}
      />
    </Graph>
  )

const categoryOrder = { 'Cash': 1, 'Bonds': 2, 'Stocks': 3, 'Misc': 4, 'Target-Date': 5 }

function Breakdown({ store, active, hideOld, theme, selectedaccount }) {
  const { allBreakdown } = store
  const selectedBreakdown =
    allBreakdown.filter(account => account.name === selectedaccount)[0] || []
  const sortedBreakdown = _.sortBy(selectedBreakdown[active], [
    category => categoryOrder[category.name],
  ])

  return sortedBreakdown.map(({ name, assets }) => {
    const categoryName = name
    const filteredAssets = assets.filter(asset => {
      if (hideOld && !asset.newValue) {
        return false
      } else {
        return true
      }
    })
    return assets.length > 0
      ? _.sortBy(filteredAssets, ['name']).map(({ name, oldValue, newValue }, index) => {
          return (
            (showOld(oldValue) || showNew(newValue)) && (
              <Grid
                key={active + name + index}
                icon={index === 0 && mapNameToIcon[categoryName]}
                name={<ItemName>{name}</ItemName>}
                oldValue={<OldValue oldValue={oldValue} hideOld={hideOld} />}
                newValue={<NewValue newValue={newValue} theme={theme} />}
                underline
                css={
                  index === 0
                    ? css`
                        margin-top: 40px;
                      `
                    : css`
                        margin-top: 0;
                      `
                }
              />
            )
          )
        })
      : null
  })
}

export default observer(Breakdown)
