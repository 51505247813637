/* eslint-disable react/jsx-handler-names */
import { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import InlineSVG from 'svg-inline-react'
import PropTypes from 'prop-types'

const helpIcon = `
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1,.cls-2{isolation:isolate;}.cls-2{font-size:19px;font-family:Aleo;font-weight:700;}</style></defs><title>help-normal-c</title><g id="Symbols"><g id="icn_help-normal" data-name="icn/help-normal"><g id="Avatar"><path id="Shape" d="M21.6,12A9.6,9.6,0,0,0,2.4,12M0,12a12,12,0,0,1,24,0"/></g><g id="Avatar-Copy"><path id="Shape-2" data-name="Shape" d="M2.4,12a9.6,9.6,0,0,0,19.2,0M24,12A12,12,0,0,1,0,12"/></g><g id="Group-4"><g id="_" data-name="?" class="cls-1"><text class="cls-2" transform="translate(8 19)">?</text></g></g></g></g></svg>
`

const TooltipWrapper = styled.span`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
`

const TooltipBox = styled.span`
  position: relative;
`
const TooltipIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => (p.iconWidth ? p.iconWidth : '24px')};
  height: ${p => (p.iconHeight ? p.iconHeight : '24px')};
  user-select: none;
  cursor: pointer;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    fill: ${p => (p.isActive ? '#0495BF !important' : '#B6C0C4')};
    width: 24px;
    height: 24px;
    transition: fill 0.5s;
    vertical-align: middle;

    &:hover {
      fill: #053240;
    }
  }
`

const TooltipUp = css`
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  box-shadow: 0px 10px 0px 0px rgba(218, 223, 225, 1);
  margin-bottom: 20px;

  &:before {
    position: absolute;
    content: '';
    border-left: 10px solid transparent;
    border-top: 20px solid rgba(218, 223, 225, 1);
    border-right: 10px solid transparent;
    top: 101%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  &:after {
    position: absolute;
    content: '';
    border-left: 7px solid transparent;
    border-top: 14px solid #fdfdf1;
    border-right: 7px solid transparent;
    top: 99.5%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`

const TooltipDown = css`
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  box-shadow: 0px -10px 0px 0px rgba(218, 223, 225, 1);
  margin-top: 20px;

  &:before {
    position: absolute;
    content: '';
    border-left: 10px solid transparent;
    border-bottom: 20px solid rgba(218, 223, 225, 1);
    border-right: 10px solid transparent;
    bottom: 101.5%;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  &:after {
    position: absolute;
    content: '';
    border-left: 7px solid transparent;
    border-bottom: 14px solid #fdfdf1;
    border-right: 7px solid transparent;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`
const TooltipLeft = css`
  right: 100%;
  top: 50%;
  transform: translate(0%, -50%);
  box-shadow: 4px 0px 0px 0px rgba(218, 223, 225, 1);
  margin-right: 16px;

  &:before {
    position: absolute;
    content: '';
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 14px solid rgba(218, 223, 225, 1);
    left: 101%;
    top: 50%;
    transform: translate(0, -50%);
  }

  &:after {
    position: absolute;
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 12px solid #fdfdf1;
    left: 100%;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const TooltipRight = css`
  left: 100%;
  top: 50%;
  transform: translate(0%, -50%);
  box-shadow: -4px 0px 0px 0px rgba(218, 223, 225, 1);
  margin-left: 16px;

  &:before {
    position: absolute;
    content: '';
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 14px solid rgba(218, 223, 225, 1);
    right: 101%;
    top: 50%;
    transform: translate(0%, -50%);
  }

  &:after {
    position: absolute;
    content: '';
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 12px solid #fdfdf1;
    right: 100%;
    top: 50%;
    transform: translate(0%, -50%);
  }
`
function reposition(position) {
  if (position === 'up') {
    return TooltipUp
  } else if (position === 'down') {
    return TooltipDown
  } else if (position === 'left') {
    return TooltipLeft
  } else if (position === 'right') {
    return TooltipRight
  } else {
    return TooltipUp
  }
}

const StyledTooltip = styled.div`
  background-color: #fdfdf1;
  color: #4a4a4a;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  line-height: 17px;
  position: absolute;
  padding: 10px;
  padding-top: 15px;
  width: ${p => (p.width ? p.width : '35%')};
  height: ${p => (p.height ? p.height : '50px')};
  min-height: 50px;
  border: 1px solid #e6e9ea;
  border-radius: 13px;
  @media (min-width: 800px) {
    ${p => p.position && reposition(p.position)};
  }

  @media (max-width: 800px) {
    width: ${p => (p.width ? p.width : '75%')};

    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    min-height: 40%;
    max-height: 80%;
    border: solid 10px rgba(218, 223, 225, 1);
    z-index: 1;
  }
`

const XButton = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 5px;
  color: #9eacb0;
  line-height: 11px;
`

class Tooltip extends Component {
  constructor(props) {
    super(props)
    this.onResize = this.onResize.bind(this)
    this.toggleTooltip = this.toggleTooltip.bind(this)

    this.state = {
      originalPosition: this.props.position,
      currentPosition: null,
      isTooltipOpen: false,
    }
  }

  toggleTooltip() {
    this.setState({ isTooltipOpen: !this.state.isTooltipOpen })
  }

  componentDidUpdate() {
    if (this.state.isTooltipOpen) {
      window.addEventListener('resize', this.onResize.bind(this, this.tooltip))
      window.addEventListener('scroll', this.onResize.bind(this, this.tooltip))
    } else {
      window.removeEventListener('resize', this.onResize.bind(this, this.tooltip))
      window.removeEventListener('scroll', this.onResize.bind(this, this.tooltip))
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize.bind(this, this.tooltip))
    window.removeEventListener('scroll', this.onResize.bind(this, this.tooltip))
  }

  onResize(tooltipRef) {
    const boundingRect = tooltipRef.getBoundingClientRect()
    const distanceFromTop = boundingRect.y
    const distanceFromLeft = boundingRect.x
    const distanceFromRight = window.innerWidth - boundingRect.x - boundingRect.width
    const distanceFromBottom = window.innerHeight - boundingRect.y - boundingRect.height

    if (this.state.currentPosition !== this.state.originalPosition) {
      if (
        this.state.originalPosition === 'right' &&
        distanceFromRight > boundingRect.width + 30 &&
        distanceFromTop > boundingRect.height / 2 &&
        distanceFromBottom > boundingRect.height / 2
      ) {
        this.setState({ currentPosition: 'right' })
      } else if (
        this.state.originalPosition === 'left' &&
        distanceFromLeft > boundingRect.width + 30 &&
        distanceFromTop > boundingRect.height / 2 &&
        distanceFromBottom > boundingRect.height / 2
      ) {
        this.setState({ currentPosition: 'left' })
      } else if (
        this.state.originalPosition === 'up' &&
        distanceFromTop > boundingRect.height + 30 &&
        distanceFromRight > boundingRect.width / 2 &&
        distanceFromLeft > boundingRect.width / 2
      ) {
        this.setState({ currentPosition: 'up' })
      } else if (
        this.state.originalPosition === 'down' &&
        distanceFromBottom > boundingRect.height + 30 &&
        distanceFromRight > boundingRect.width / 2 &&
        distanceFromLeft > boundingRect.width / 2
      ) {
        this.setState({ currentPosition: 'down' })
      }
    }

    if (distanceFromTop < 0) {
      if (this.state.currentPosition === 'up') {
        if (distanceFromBottom > boundingRect.height + 10) {
          this.setState({ currentPosition: 'down' })
        }
      } else if (this.state.currentPosition === 'left' || this.state.currentPosition === 'right') {
        if (distanceFromBottom + boundingRect.height / 2 > boundingRect.height + 10) {
          this.setState({ currentPosition: 'down' })
        }
      }
    } else if (distanceFromLeft < 0) {
      if (this.state.currentPosition === 'left') {
        if (distanceFromRight > boundingRect.width + 10) {
          this.setState({ currentPosition: 'right' })
        } else if (
          distanceFromTop > boundingRect.height + 20 &&
          distanceFromRight > boundingRect.width / 2 + 10
        ) {
          this.setState({ currentPosition: 'up' })
        } else if (
          distanceFromBottom > boundingRect.height + 20 &&
          distanceFromRight > boundingRect.width / 2 + 10
        ) {
          this.setState({ currentPosition: 'down' })
        }
      } else if (this.state.currentPosition === 'up' || this.state.currentPosition === 'down') {
        if (distanceFromRight + boundingRect.width / 2 > boundingRect.width + 10) {
          this.setState({ currentPosition: 'right' })
        }
      }
    } else if (distanceFromRight < 0) {
      if (this.state.currentPosition === 'right') {
        if (distanceFromLeft > boundingRect.width + 10) {
          this.setState({ currentPosition: 'left' })
        } else if (
          distanceFromTop > boundingRect.height + 20 &&
          distanceFromLeft > boundingRect.width / 2 + 10
        ) {
          this.setState({ currentPosition: 'top' })
        } else if (
          distanceFromBottom > boundingRect.height + 20 &&
          distanceFromLeft > boundingRect.width / 2 + 10
        ) {
          this.setState({ currentPosition: 'down' })
        }
      } else if (this.state.currentPosition === 'up' || this.state.currentPosition === 'down') {
        if (distanceFromLeft + boundingRect.width / 2 > boundingRect.width + 10) {
          this.setState({ currentPosition: 'left' })
        }
      }
    } else if (distanceFromBottom < 0 && distanceFromTop > boundingRect.height) {
      if (this.state.currentPosition === 'down' && distanceFromTop > boundingRect.height + 10) {
        this.setState({ currentPosition: 'up' })
      } else if (this.state.currentPosition === 'left' || this.state.currentPosition === 'right') {
        if (distanceFromTop + boundingRect.height / 2 > boundingRect.height) {
          this.setState({ currentPosition: 'up' })
        }
      }
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    if (this.state.currentPosition == null) {
      if (this.state.originalPosition == null) {
        this.setState({ currentPosition: 'up' })
      } else {
        this.setState({ currentPosition: this.state.originalPosition })
      }
    }
  }

  static defaultProps = {
    position: 'up',
  }

  static propTypes = {
    position: PropTypes.string,
  }

  render() {
    const { children } = this.props

    return (
      <TooltipWrapper>
        <TooltipBox onClick={this.toggleTooltip}>
          <TooltipIcon isActive={this.state.isTooltipOpen}>
            <InlineSVG src={helpIcon} element='span' />
          </TooltipIcon>
          {this.state.isTooltipOpen && (
            <StyledTooltip
              ref={node => (this.tooltip = node)}
              position={this.state.currentPosition}>
              <div>{children}</div>
              <XButton>&#10006;</XButton>
            </StyledTooltip>
          )}
        </TooltipBox>
      </TooltipWrapper>
    )
  }
}

const LabelWithTooltipWrapper = styled.div`
  display: flex;
  flex: 1 1 300px;
  justify-content: flex-start;
  display: relative;

  color: #b6c0c4;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  transition: 0.8s;
  @media (max-width: 800px) {
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: left;
    padding: 0;
  }

  & > :first-child {
    text-align: right;
    padding-right: 10px;

    @media (max-width: 800px) {
      text-align: left;
    }
  }
`

const LabelWithTooltip = ({ onClick, src, position, children, label }) => {
  return (
    <LabelWithTooltipWrapper>
      <span>{label}</span>
      <Tooltip onClick={onClick} src={src} position={position}>
        {children}{' '}
      </Tooltip>
    </LabelWithTooltipWrapper>
  )
}

export { Tooltip as default, LabelWithTooltip }
