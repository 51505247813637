import { themr } from '../react-css-themr'
import { CHECKBOX } from '../identifiers'
import themedRippleFactory from '../ripple'
import { checkboxFactory } from './Checkbox'
import checkFactory from './Check'
import theme from './theme'

const ThemedCheck = checkFactory(themedRippleFactory({ centered: true, spread: 2.6 }))
const ThemedCheckbox = themr(CHECKBOX, theme)(checkboxFactory(ThemedCheck))

const LargeCheck = checkFactory(themedRippleFactory({ centered: true, spread: 1.6 }))
const LargeCheckbox = themr(CHECKBOX, theme)(checkboxFactory(LargeCheck))

export default ThemedCheckbox
export { ThemedCheckbox as Checkbox, LargeCheckbox }
