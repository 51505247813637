/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import dayjs from 'dayjs'

import { SVGWrapper } from '../../../components'
import { CircleX, PiggyBank, Link } from '../../../assets/icons'
import { numberToDollars } from '../../../utils'
import { planTypeIdToAccountKey } from '../../../constants'
import { PlaidRefresh } from '../investments/components/PlaidLink'
import {
  SectionBalance,
  DollarSign,
  SectionRow,
  SectionLabel,
  SectionSubLabel,
  NameField,
  BalanceField,
  EditButton,
  SVGContainer,
  SVGPlaceholder,
} from './DashboardStyle'
import DashboardDeleteAccount from './DashboardDeleteAccount'

const typeToPathKey = {
  Annuity: 'annuity',
  Institutional: 'institutional',
  NonGc: 'non-gc',
  Pension: 'pension',
}

class DashboardAccount extends Component {
  state = {
    deleteStatus: 'done',
    deleteActive: false,
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteActive: !this.state.deleteActive,
      deleteStatus: 'done',
    })
  }

  handleEdit = () => {
    const { history, account } = this.props
    const { type, id, planType } = account
    const accountType = planTypeIdToAccountKey[planType]
    history.push(`/accounts/${typeToPathKey[type]}/${accountType}/${id}`)
  }

  findNamebyId = () => {
    const { store } = this.props
    const { participantId } = this.props.account
    const { displayName, id: personId } = store.person
    const { firstName, id: spouseId } = store.spouse

    if (participantId === personId) {
      return displayName
    } else if (participantId === spouseId) {
      return firstName
    } else {
      return ''
    }
  }

  async deleteAccount(type, id) {
    const { deleteAccount, getAllPensions } = this.props.store
    this.setState({ deleteStatus: 'loading' })

    try {
      await deleteAccount(type, id)
      await getAllPensions()
    } catch (err) {
      console.error(err)
      this.setState({ deleteStatus: 'error' })
    }
  }

  handlePlaidRefreshSuccess = () => {
    this.props.store.getExistingPlaidLinks()
    this.props.store.getAccounts()
  }

  render() {
    const {
      features,
      account: { managedAccount },
      findPlaidAccountById,
    } = this.props.store
    const { name, balance, type, id, contributing, employerSponsoredAccount, plaidLinked } =
      this.props.account
    const { showLumpSumNotice } = this.props

    const plaidAccount = findPlaidAccountById(Number(id))
    const plaidAccountLastRefreshDate = _.get(plaidAccount, 'lastRefreshedOn', null)

    return (
      <SectionRow id={this.props.id}>
        <NameField>
          {!employerSponsoredAccount ? (
            <SVGContainer>
              <SVGWrapper
                onClick={this.toggleDeleteModal}
                svg={CircleX}
                fill='#b6c0c4'
                hoverFill='#053240'
                activeFill='#0495bf'
                size='large'
              />
            </SVGContainer>
          ) : (
            <SVGContainer>
              <SVGPlaceholder />
            </SVGContainer>
          )}
          <div>
            <SectionLabel>
              {name}
              {type === 'Institutional' && features.managedAccountAvailable && managedAccount
                ? '*'
                : ''}
              {type === 'Pension' && showLumpSumNotice && '**'}
            </SectionLabel>
            <SectionSubLabel>{this.findNamebyId()}</SectionSubLabel>
          </div>
        </NameField>
        {plaidLinked && (
          <div
            css={css`
              font-size: 14px;
              color: #7a8e96;
              margin-top: 23px;
            `}>
            <Link
              css={css`
                margin: 0 6px;
              `}
            />
            Linked account last refreshed{' '}
            {plaidAccountLastRefreshDate
              ? `${dayjs(plaidAccountLastRefreshDate).format('MM/DD/YYYY')} (${dayjs(
                  plaidAccountLastRefreshDate
                ).fromNow()})`
              : 'Never'}
          </div>
        )}
        <BalanceField>
          {contributing && (
            <SVGWrapper
              svg={PiggyBank}
              fill='#b6c0c4'
              hoverFill='#053240'
              size='medium'
              cursor='default'
            />
          )}
          <SectionBalance>
            <DollarSign>$</DollarSign>
            {numberToDollars(balance, true)}
          </SectionBalance>
          <EditButton onClick={this.handleEdit}>Edit</EditButton>
          {plaidLinked && (
            <PlaidRefresh
              css={css`
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 12px;
                color: #038ab7;
                cursor: pointer;
                background-color: transparent;
                text-decoration: underline;
                border: none;
              `}
              accountId={id}
              onSuccess={this.handlePlaidRefreshSuccess}
            />
          )}
        </BalanceField>
        <DashboardDeleteAccount
          handleConfirmation={() => this.deleteAccount(type, id)}
          isModalOpen={this.state.deleteActive}
          toggleModal={this.toggleDeleteModal}
          loading={this.state.deleteStatus === 'loading'}
          error={this.state.deleteStatus === 'error'}
        />
      </SectionRow>
    )
  }
}

export default inject('store')(observer(DashboardAccount))
