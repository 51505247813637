import DesignSystem from 'design-system-utils'
import colors from './colors'
import media from './media'
import variables from './variables'
import appbar from './app_bar/config'
import autocomplete from './autocomplete/config'
import avatar from './avatar/config'
import button from './button/config'
import card from './card/config'
import chip from './chip/config'
import checkbox from './checkbox/config'
import dialog from './dialog/config'
import drawer from './drawer/config'
import dropdown from './dropdown/config'
import input from './input/config'
import layout from './layout/config'
import list from './list/config'
import menu from './menu/config'
import navigation from './navigation/config'
import overlay from './overlay/config'
import progress from './progress_bar/config'
import radio from './radio/config'
import slider from './slider/config'
import snackbar from './snackbar/config'
import table from './table/config'
import tab from './tabs/config'
import tooltip from './tooltip/config'
import switchConfig from './switch/config'
import ripple from './ripple/config'

export const myDesignSystem = {
  colors,
  ...media,
  ...variables,
  appbar,
  autocomplete,
  avatar,
  button,
  card,
  checkbox,
  chip,
  dialog,
  drawer,
  dropdown,
  input,
  layout,
  list,
  menu,
  navigation,
  overlay,
  progress,
  radio,
  slider,
  snackbar,
  switch: switchConfig,
  table,
  tab,
  tooltip,
  ripple,
}

export const ds = new DesignSystem(myDesignSystem, {
  fontSizeUnit: 'rem',
})

export default ds
