import styled from '@emotion/styled'
import { forwardRef } from 'react'

const Container = styled.div`
  background-color: ${p => p.backgroundColor || 'transparent'};
  margin: ${p => p.margin || '0 0.5in'};
`

function PageContent(props, ref) {
  PageContent.displayName = 'PageContent'

  return (
    <Container ref={ref} {...props}>
      {props.children}
    </Container>
  )
}

export default forwardRef(PageContent)
