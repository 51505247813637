import _ from 'lodash'
import * as yup from 'yup'

export const schema = yup.object().shape({
  employerContrib: yup.boolean().nullable().required('This field is required'),
  companyMatchRate: yup
    .number()
    .nullable()
    .min(0, 'Please enter a value between 0 and 100')
    .max(100, 'Please enter a value between 0 and 100')
    .when('employerContrib', (employerContrib, schema) =>
      employerContrib ? schema.required('This field is required') : schema
    ),
  companyMatchRateMax: yup
    .number()
    .nullable()
    .min(0, 'Please enter a value between 0 and 100')
    .max(100, 'Please enter a value between 0 and 100')
    .when('employerContrib', (employerContrib, schema) =>
      employerContrib ? schema.required('This field is required') : schema
    ),
  companyMatchDlrMax: yup
    .number()
    .nullable()
    .min(0, 'Please enter an amount between $0 and $100,000,000')
    .max(100000000, 'Please enter an amount between $0 and $100,000,000')
    .when('employerContrib', (employerContrib, schema) =>
      employerContrib ? schema.required('This field is required') : schema
    ),
  profitSharing: yup.boolean().nullable().required('This field is required'),
  profitSharingRate: yup
    .number()
    .nullable()
    .min(0, 'Please enter a value between 0 and 100')
    .max(100, 'Please enter a value between 0 and 100')
    .when('profitSharing', (profitSharing, schema) =>
      profitSharing ? schema.required('This field is required') : schema
    ),
})

export const accountTypeNextStep = {
  '401a': 'investments-intro',
  '401k': 'investments-intro',
  '403b': 'investments-intro',
  'keogh': 'investments-intro',
  'simple-ira': 'investments-intro',
}

export const accountTypeAllowedKeys = {
  '401a': [
    'employerContrib',
    'companyMatchRate',
    'companyMatchRateMax',
    'companyMatchDlrMax',
    'profitSharing',
    'profitSharingRate',
  ],
  '401k': [
    'employerContrib',
    'companyMatchRate',
    'companyMatchRateMax',
    'companyMatchDlrMax',
    'profitSharing',
    'profitSharingRate',
  ],
  '403b': [
    'employerContrib',
    'companyMatchRate',
    'companyMatchRateMax',
    'companyMatchDlrMax',
    'profitSharing',
    'profitSharingRate',
  ],
  'keogh': ['employerContrib', 'companyMatchRate', 'companyMatchRateMax', 'companyMatchDlrMax'],
  'simple-ira': [
    'employerContrib',
    'companyMatchRate',
    'companyMatchRateMax',
    'companyMatchDlrMax',
  ],
}

export const createEditable = ({ accountType, planType, isPrimaryOwned, control }) => {
  const allowedKeys = accountTypeAllowedKeys[accountType]
  // const is403b = planType === 2
  // const is457 = planType === 4

  // Combination of account type specific rules, account-control API results, primary/spouse owner, special 457 and 403b cases
  const employerContribIsEditable =
    _.includes(allowedKeys, 'employerContrib') && control.employerContribution
  const profitSharingIsEditable =
    _.includes(allowedKeys, 'profitSharing') && control.employerContribution

  return {
    employerContribIsEditable,
    profitSharingIsEditable,
  }
}

export const initializeValues = ({
  account,
  editable,
  control,
  editInstitutionalAccount,
  isSpouseOwned,
}) => {
  let {
    employerContrib,
    employerMatch,
    companyMatchRate,
    companyMatchRateMax,
    companyMatchDlrMax,
    profitSharing,
    profitSharingRate,
    contributionTiers,
  } = account

  // if account control API returns false
  if (!control.employerContribution) {
    employerContrib = false
    profitSharing = false
  }

  // if account type isn't allowed to edit profitSharing
  if (!editable.profitSharingIsEditable) {
    profitSharing = false
  }

  return {
    employerContrib,
    employerMatch,
    companyMatchRate: isSpouseOwned
      ? companyMatchRate
      : editInstitutionalAccount
      ? contributionTiers[0]?.percentContribution ?? null
      : companyMatchRate,
    companyMatchRateMax: isSpouseOwned
      ? companyMatchRateMax
      : editInstitutionalAccount
      ? contributionTiers[0]?.to ?? null
      : companyMatchRateMax,
    companyMatchDlrMax,
    profitSharing,
    profitSharingRate,
  }
}

export const cleanEmployerData = (values, account) => {
  if (typeof values.employerContrib === 'boolean' && values.employerContrib === false) {
    // preserve null values if account data is null
    values.companyMatchRate = account.companyMatchRate === null ? null : 0
    values.companyMatchRateMax = account.companyMatchRateMax === null ? null : 0
    values.companyMatchDlrMax = account.companyMatchDlrMax === null ? null : 0
  }

  if (typeof values.profitSharing === 'boolean' && values.profitSharing === false) {
    // preserve null values if account data is null
    values.profitSharingRate = account.profitSharingRate === null ? null : 0
  }

  return values
}
