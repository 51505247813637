/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const active = 'rt-dropdown-active'

const disabled = 'rt-dropdown-disabled'

const dropdown = 'rt-dropdown-dropdown'

const error = 'rt-dropdown-error'

const errored = 'rt-dropdown-errored'

const field = 'rt-dropdown-field'

const label = 'rt-dropdown-label'

const required = 'rt-dropdown-required'

const selected = 'rt-dropdown-selected'

const templateValue = 'rt-dropdown-templateValue'

const up = 'rt-dropdown-up'

const value = 'rt-dropdown-value'

const values = 'rt-dropdown-values'

const _dropdown = css`
  position: relative;

  ${ds.get('reset')};

  &:not(.${active}) {
    & > .${values} {
      max-height: 0;
      visibility: hidden;
    }
  }

  &.${active} {
    & > .${label}, & > .${value} {
      opacity: 0.5;
    }

    & > .${values} {
      box-shadow: ${ds.get('shadow.zdepth1')};
      max-height: ${ds.get('dropdown.overflowMaxHeight')};
      visibility: visible;
    }
  }

  &:not(.${up}) > .${values} {
    bottom: auto;
    top: 0;
  }

  &.${up} > .${values} {
    bottom: 0;
    top: auto;
  }

  &.${disabled} {
    cursor: normal;
    pointer-events: none;
  }
`

const _value = css`
  & > input {
    cursor: pointer;
  }

  &::after {
    border-left: ${ds.get('dropdown.valueBorderSize')} solid transparent;
    border-right: ${ds.get('dropdown.valueBorderSize')} solid transparent;
    border-top: ${ds.get('dropdown.valueBorderSize')} solid ${ds.get('input.textBottomBorderColor')};
    content: '';
    height: 0;
    pointer-events: none;
    position: absolute;
    right: ${ds.get('input.chevronOffset')};
    top: 50%;
    transition: transform ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')};
    width: 0;
  }
`

const _field = css`
  cursor: pointer;
  padding: ${ds.get('input.padding')} 0;
  position: relative;

  &.${errored} {
    padding-bottom: 0;

    & > .${label} {
      color: ${ds.get('input.textErrorColor')};
    }

    & > .${templateValue} {
      border-bottom: 1px solid ${ds.get('input.textErrorColor')};
    }

    & > .${label} > .${required} {
      color: ${ds.get('input.textErrorColor')};
    }
  }

  &.${disabled} {
    cursor: normal;
    pointer-events: none;

    & > .${templateValue} {
      border-bottom-style: dotted;
      opacity: 0.7;
    }
  }
`

const _templateValue = css`
  background-color: ${ds.get('input.textBackgroundColor')};
  border-bottom: 1px solid ${ds.get('input.textBottomBorderColor')};
  color: ${ds.get('colors.rt.text')};
  min-height: ${ds.get('input.fieldHeight')};
  padding: ${ds.get('input.fieldPadding')} 0;
  position: relative;
`

const _label = css`
  color: ${ds.get('input.textLabelColor')};
  font-size: ${ds.get('input.labelFontSize')};
  left: 0;
  line-height: ${ds.get('input.fieldFontSize')};
  position: absolute;
  top: ${ds.get('input.focusLabelTop')};

  & .${required} {
    color: ${ds.get('input.textErrorColor')};
  }
`

const _error = css`
  color: ${ds.get('input.textErrorColor')};
  font-size: ${ds.get('input.labelFontSize')};
  line-height: ${ds.get('input.underlineHeight')};
  margin-bottom: ${reduceCSSCalc(`calc(-1 * ${ds.get('input.underlineHeight')})`)};
`

const _values = css`
  background-color: ${ds.get('dropdown.colorWhite')};
  border-radius: ${ds.get('dropdown.valueBorderRadius')};
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  transition-duration: ${ds.get('animation.duration')};
  transition-property: max-height, box-shadow;
  transition-timing-function: ${ds.get('animation.curveDefault')};
  width: 100%;
  z-index: ${ds.z('high')};

  & > * {
    cursor: pointer;
    overflow: hidden;
    padding: ${ds.get('unit')};
    position: relative;

    &:hover:not(.${disabled}) {
      background-color: ${ds.get('dropdown.valueHoverBackground')};
    }

    &.${selected} {
      color: ${ds.get('dropdown.colorPrimary')};
    }

    &.${disabled} {
      color: ${ds.get('dropdown.colorDisabled')};
      cursor: not-allowed;
    }
  }

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`

export default {
  active,
  disabled,
  dropdown: cx(dropdown, _dropdown),
  error: cx(error, _error),
  errored,
  field: cx(field, _field),
  label: cx(label, _label),
  required,
  selected,
  templateValue: cx(templateValue, _templateValue),
  up,
  value: cx(value, _value),
  values: cx(values, _values),
}
