/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Wrapper, Page } from './style'
import { Spacer } from '../../../components'
import ImplementChangesIcon from './icons/ImplementChangesIcon'
import ManageRiskIcon from './icons/ManageRiskIcon'
import PhoneSupportIcon from './icons/PhoneSupportIcon'
import PersonalizedStrategy from '../value-proposition/PersonalizedStrategy'

function AcceptRecommendation(props) {
  const {
    history,
    spendDownOffboarding: {
      modeledAnnuity,
      isHybridMember,
      acceptSpendDownRecommendations,
      incrementStep,
    },
    store: {
      updateSpendingCaseById,
      spendingAcceptedCase,
      config: { isRetail, isRetailFrs },
    },
    store,
  } = props

  const isPensionMember = _.get(store, 'config.canTransact', null) === false
  const isInvestmentOrHybridMember = _.get(store, 'config.canTransact', null)

  useEffect(() => {
    if (_.isEmpty(spendingAcceptedCase)) {
      history.replace('/spending/finalize-choices')
    }
  }, [history, spendingAcceptedCase])

  const handleAcceptRecommendation = async () => {
    try {
      await updateSpendingCaseById()
      incrementStep(history, 'accept-recommendation')
    } catch (err) {
      console.error(err)
    }
    await acceptSpendDownRecommendations()
  }

  return (
    <Wrapper.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
        line-height: 1.5rem;
      `}>
      <div>
        <Page.Heading
          css={css`
            margin: 0 auto;
            padding-bottom: 16px;
            font-family: Aleo;
          `}>
          Before we go any further: <br />
          Investment changes will be required
        </Page.Heading>
        <Wrapper.Container>
          <div
            css={css`
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            `}>
            <div
              css={css`
                flex: 2 250px;
                text-align: center;
                padding: 0 30px 0 85px;
                font-size: 18px;
              `}>
              Your guide contains specific instructions for withdrawing funds needed this calendar
              year for living expenses{modeledAnnuity && ' and for the purchase of an annuity'}.
              <Spacer space='10px' />
              These withdrawal instructions will be actionable only if you make all the investment
              changes we advise.
              <Spacer space='10px' />
              {isHybridMember && !isRetail && (
                <b>
                  GuidedChoice will send your plan recordkeeper, Alight, a new allocation for your
                  FRS Investment Plan account. It is important to approve that allocation as is.
                </b>
              )}
            </div>
            <div
              css={css`
                flex: 0 1 auto;
                padding: 0 85px 0 20px;
              `}>
              <PersonalizedStrategy />
            </div>
          </div>
          <Spacer space='20px' />
          <hr />
          <Spacer space='10px' />
          {isHybridMember && !isRetail ? (
            <Page.Section>
              <Page.LeftSide style={{ flex: '2' }}>
                <Page.SectionTitle>If you accept those terms, GuidedChoice:</Page.SectionTitle>
                <Page.Section>
                  <Page.IconAndTextWrapper
                    css={css`
                      flex: 1;
                      padding-right: 20px;
                    `}>
                    <Page.AcceptIcon>
                      <ImplementChangesIcon />
                    </Page.AcceptIcon>
                    <Page.Text>
                      Sends your investment changes to Alight for implementation
                    </Page.Text>
                  </Page.IconAndTextWrapper>
                  <Page.IconAndTextWrapper
                    css={css`
                      flex: 1;
                    `}>
                    <Page.AcceptIcon>
                      <ManageRiskIcon />
                    </Page.AcceptIcon>
                    <Page.Text>
                      Minimizes risk — while maximizing rewards — for your personalized, diversified
                      portfolio
                    </Page.Text>
                  </Page.IconAndTextWrapper>
                </Page.Section>
              </Page.LeftSide>

              <Page.RightSide>
                <Page.SectionTitle>And don't forget, EY:</Page.SectionTitle>
                <Page.Section>
                  <Page.IconAndTextWrapper>
                    <Page.AcceptIcon>
                      <PhoneSupportIcon
                        css={css`
                          width: 90%;
                          height: auto;
                          margin: 10px 0;
                        `}
                      />
                    </Page.AcceptIcon>
                    <Page.Text>Is a phone call away whenever you need help</Page.Text>
                  </Page.IconAndTextWrapper>
                </Page.Section>
              </Page.RightSide>
            </Page.Section>
          ) : isRetail && !isRetailFrs ? (
            <Page.Section>
              <Page.LeftSide>
                <Page.SectionTitle>And remember, GuidedChoice:</Page.SectionTitle>
                <Page.Section>
                  <div
                    css={css`
                      flex: 2;
                    `}>
                    <Page.IconAndTextWrapper>
                      <Page.AcceptIcon>
                        <ManageRiskIcon />
                      </Page.AcceptIcon>
                      <Page.Text>
                        Minimizes risk — while maximizing rewards — for your personalized,
                        diversified portfolio
                      </Page.Text>
                    </Page.IconAndTextWrapper>
                  </div>
                </Page.Section>
              </Page.LeftSide>

              <Page.RightSide>
                <Page.Section
                  css={css`
                    margin-top: 44px;
                  `}>
                  <Page.IconAndTextWrapper>
                    <Page.AcceptIcon>
                      <PhoneSupportIcon
                        css={css`
                          width: 80%;
                          height: auto;
                          margin-top: 22px;
                          margin-bottom: 18px;
                        `}
                      />
                    </Page.AcceptIcon>
                    <Page.Text>Is a phone call away whenever you need help</Page.Text>
                  </Page.IconAndTextWrapper>
                </Page.Section>
              </Page.RightSide>
            </Page.Section>
          ) : (
            <Page.Section>
              <Page.LeftSide>
                <Page.SectionTitle>If you accept those terms, GuidedChoice:</Page.SectionTitle>
                <Page.Section>
                  <div
                    css={css`
                      flex: 2;
                    `}>
                    <Page.IconAndTextWrapper>
                      <Page.AcceptIcon>
                        <ManageRiskIcon />
                      </Page.AcceptIcon>
                      <Page.Text>
                        Minimizes risk — while maximizing rewards — for your personalized,
                        diversified portfolio
                      </Page.Text>
                    </Page.IconAndTextWrapper>
                  </div>
                </Page.Section>
              </Page.LeftSide>

              <Page.RightSide>
                <Page.SectionTitle>And don't forget, EY:</Page.SectionTitle>
                <Page.Section>
                  <Page.IconAndTextWrapper>
                    <Page.AcceptIcon>
                      <PhoneSupportIcon
                        css={css`
                          width: 80%;
                          height: auto;
                          margin-top: 22px;
                          margin-bottom: 18px;
                        `}
                      />
                    </Page.AcceptIcon>
                    <Page.Text>Is a phone call away whenever you need help</Page.Text>
                  </Page.IconAndTextWrapper>
                </Page.Section>
              </Page.RightSide>
            </Page.Section>
          )}
          <Spacer space='10px' />
          {isRetail ? (
            <div
              css={css`
                display: flex;
                justify-content: center;
                margin: 40px 0;
              `}>
              <Page.CancelButton
                css={css`
                  padding: 12px 30px;
                  margin-right: 60px;
                `}
                onClick={() => history.push('/spending')}>
                <div>Cancel</div>
              </Page.CancelButton>

              <Page.PrimaryButton
                css={css`
                  padding: 12px 30px;
                `}
                onClick={handleAcceptRecommendation}>
                <div>Accept recommendation</div>
              </Page.PrimaryButton>
            </div>
          ) : (
            <div
              css={css`
                display: flex;
                justify-content: center;
                margin: 40px 0;
              `}>
              <Page.CancelButton
                css={css`
                  padding: 12px 30px;
                  margin-right: 60px;
                `}
                onClick={() => history.push('/spending')}>
                <div>Cancel</div>
                <div>no action | return to</div>
                <div>Overall Strategy</div>
              </Page.CancelButton>

              <Page.PrimaryButton
                css={css`
                  padding: 12px 30px;
                `}
                onClick={handleAcceptRecommendation}>
                <div>Accept recommendation</div>
                <div>including investment changes to</div>
                {isPensionMember && <div>investment accounts</div>}
                {isInvestmentOrHybridMember && (
                  <div>FRS investment Plan & other investment accounts</div>
                )}
              </Page.PrimaryButton>
            </div>
          )}
        </Wrapper.Container>
      </div>
    </Wrapper.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(AcceptRecommendation))
