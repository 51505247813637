import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'

import { API } from '../api'

const { model, array, number, string, boolean, maybeNull } = types

const PlaidAccount = model({
  financialAccountId: maybeNull(number),
  primaryAccountId: maybeNull(number),
  accessId: maybeNull(number),
  accountId: maybeNull(string),
  institutionId: maybeNull(string),
  institutionName: maybeNull(string),
  name: maybeNull(string),
  subtype: maybeNull(string),
  type: maybeNull(string),
  lastRefreshedOn: maybeNull(string),
  requiresRelink: boolean,
  publicToken: maybeNull(string),
})

const Plaid = model({
  plaidLinkToken: '',
  plaidLinkedAccounts: array(PlaidAccount),
})
  .views(self => ({
    get plaidLinkedAccountIds() {
      return self.plaidLinkedAccounts.map(({ financialAccountId }) => financialAccountId)
    },
    findPlaidAccountById(financialAccountId) {
      return _.find(self.plaidLinkedAccounts, ['financialAccountId', Number(financialAccountId)])
    },
  }))
  .actions(self => ({
    getLinkToken: flow(function* () {
      const products = ['INVESTMENTS']
      const response = yield API.post('aggregation/link-token', products)
      self.plaidLinkToken = response.data.linkToken
      return response.data.linkToken
    }),

    getUpdateLinkToken: flow(function* (accessId) {
      const response = yield API.get(`aggregation/link-token/${accessId}`)
      return response.data.linkToken
    }),

    // Used to fetch list of accounts currently linked with Plaid
    getExistingPlaidLinks: flow(function* () {
      const existingPlaidLinks = yield API.get('aggregation/bind')
      const existingLinkedAccounts = _.get(existingPlaidLinks, 'data', []).map(
        ({ aggAccount, lastRefreshedOn, linkedAccount, publicToken }) => ({
          financialAccountId: _.get(linkedAccount, 'linkedAccountId.financialAccountId', null),
          primaryAccountId: _.get(linkedAccount, 'linkedAccountId.primaryAccountId', null),
          accessId: _.get(aggAccount, 'accessId', null),
          accountId: _.get(aggAccount, 'accountId', null),
          institutionId: _.get(aggAccount, 'institutionId', null),
          institutionName: _.get(aggAccount, 'institutionName', null),
          name: _.get(aggAccount, 'name', null),
          subtype: _.get(aggAccount, 'subtype', null),
          type: _.get(aggAccount, 'type', null),
          lastRefreshedOn,
          requiresRelink: Boolean(publicToken),
          publicToken,
        })
      )

      self.plaidLinkedAccounts = existingLinkedAccounts
    }),

    // Used to unbind an account with Plaid, financialAccountId = nonGC account ID
    unlinkPlaidAccount: flow(function* (financialAccountId) {
      yield API.post(`aggregation/bind/unbind/${financialAccountId}`)
    }),

    // Used to refresh Plaid account data with linked account
    refreshPlaidAccount: flow(function* (financialAccountId) {
      yield API.post(`aggregation/refresh/${financialAccountId}`)
    }),
  }))

export default Plaid
