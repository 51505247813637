/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { ClassNames } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import { RadioGroup, RadioButton } from '../../../guided-toolbox'
import styled from '@emotion/styled'
import { Form, Field, useFormState } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-calculate'

import Page from './style'
import {
  SVGWrapper,
  Spacer,
  Button,
  FormError,
  Dropdown as DropdownElement,
} from '../../../components'
import { numberToDollars } from '../../../utils/utils'

import { MoneyBagBlueEmptySVG } from '../../../assets/icons'

const calculator = createDecorator({
  field: 'startingAge',
  updates: {
    selectedPension: (ignoredValue, allValues) => {
      return allValues.allAvailablePensionOptions[allValues.startingAge]
        ? allValues.allAvailablePensionOptions[allValues.startingAge][0].pensionId.toString()
        : null
    },
  },
})

function EditPensionSingle({
  setStatus,
  history,
  handleCancel,
  store: {
    pensionOptions,
    savePensionOption,
    person,
    allPensions,
    selectedInstitutionalPension,
    processModifiedCase,
    config: { isSpendown },
  },
}) {
  const primaryInstitutionalPension = allPensions.filter(
    pension => pension.id === selectedInstitutionalPension.pensionId
  )[0]
  const handleSave = async values => {
    const pension = pensionOptions[values.startingAge].filter(
      pension => pension.pensionId === parseInt(values.selectedPension)
    )[0]
    try {
      await savePensionOption(pension)
      if (isSpendown) {
        await processModifiedCase()
      }
      handleCancel()
      history.push('/spending')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const onSubmit = values => {
    if (
      pensionOptions[values.startingAge].some(
        pension => pension.pensionId === parseInt(values.selectedPension)
      )
    ) {
      return handleSave(values)
    } else {
      return { [FORM_ERROR]: 'Select the radio button to save your new start age and payment' }
    }
  }

  const initialValues = {
    startingAge: primaryInstitutionalPension.pensionStartAge + '',
    selectedPension: selectedInstitutionalPension.pensionId + '',
    allAvailablePensionOptions: pensionOptions,
  }

  const validate = values => {
    const errors = {}
    if (values.startingAge === null) {
      errors.startingAge = 'Please select age'
    }

    if (values.selectedPension === null) {
      errors.selectedPension = 'Please select payment option'
    }
    return errors
  }

  const ages = Object.keys(pensionOptions)

  return (
    <>
      <Page.Heading>Select a new start age to change the payment option</Page.Heading>
      <Page>
        <Form
          decorators={[calculator]}
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          subscription={{ submitting: true, submitError: true }}
          render={({ handleSubmit, submitting, submitError }) => (
            <Page.Form>
              <Page.Text>Monthly benefit start age and payment</Page.Text>
              <Spacer space='12px' />

              <Field
                name='startingAge'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => <Dropdown ages={ages} meta={meta} input={input} />}
              />
              <Spacer space='12px' />

              <PaymentOptions person={person} pensionOptions={pensionOptions} />

              <Page.ButtonGroup>
                <div>
                  <Button
                    type='button'
                    onClick={handleCancel}
                    secondary
                    label='Back'
                    disabled={submitting}
                    width='140px'
                  />
                </div>
                <div>
                  <Button
                    type='button'
                    onClick={handleSubmit}
                    primary
                    label='Save'
                    disabled={submitting}
                    width='140px'
                  />
                </div>
              </Page.ButtonGroup>
              {submitError && <FormError err={submitError} />}
            </Page.Form>
          )}
        />
      </Page>
    </>
  )
}

export default inject('store')(observer(EditPensionSingle))

const PaymentOptions = ({ person, spouse, pensionOptions }) => {
  useFormState({ subscription: { values: true } })
  const {
    values: { startingAge },
  } = useFormState({ subscription: { values: true } })

  return startingAge ? (
    <>
      <Field
        name='selectedPension'
        subscription={{ value: true, touched: true, error: true }}
        render={({ input, meta }) => (
          <>
            <ClassNames>
              {({ css, cx }) => (
                <RadioGroup
                  className={css`
                    display: flex;
                    flex-wrap: wrap;
                  `}
                  value={input.value}
                  onChange={input.onChange}>
                  {pensionOptions[startingAge].map((pension, index) => {
                    return (
                      <RadioButton
                        key={index}
                        value={pension.pensionId + ''}
                        className={css`
                          position: relative;
                          height: 160px;
                          margin-left: 20px;
                          margin-right: 30px;
                        `}>
                        <SVGContainer>
                          <SVGWrapper
                            svg={MoneyBagBlueEmptySVG}
                            fill='#7a8e96'
                            hoverFill='black'
                            activeFill='#b6c0c4'
                            size='custom120'
                            css={css`
                              position: absolute;
                              left: 0px;
                              top: 0px;
                              bottom: 0px;
                              right: 0px;
                            `}
                          />
                          <SVGText>
                            <Amount>{numberToDollars(pension.ownerPension, true)}</Amount>
                            <Name>{person.displayName}</Name>
                          </SVGText>
                        </SVGContainer>
                      </RadioButton>
                    )
                  })}
                </RadioGroup>
              )}
            </ClassNames>
            {meta.touched && (meta.error || meta.submitError) && (
              <Error>{meta.error || meta.submitError}</Error>
            )}
          </>
        )}
      />
      <Spacer space='12px' />
    </>
  ) : null
}

const SVGContainer = styled.div`
  position: relative;
  height: 120px;
  width: 120px;
  margin-right: 20px;
`
const SVGText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
`
const Amount = styled.div`
  text-align: center;
  cursor: pointer;
  &::before {
    content: '$';
    color: #838383;
  }
`
const Name = styled.div`
  color: #838383;
  text-align: center;
  cursor: pointer;
`
const Error = styled.div`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
`
const Dropdown = ({ ages, input, meta }) => {
  const ageOptions = () => {
    const arr = []
    ages.map(age => arr.push({ label: age, value: age }))
    return arr
  }

  return (
    <DropdownElement
      error={meta.error}
      name={input.name}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      options={ageOptions()}
      placeholder={input.value}
      selected={input.value}
      showError={meta.touched}
    />
  )
}
