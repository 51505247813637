/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

import {
  Spacer,
  ButtonInput,
  TextErrorField,
  CurrencyInput,
  Loading,
  ErrorPage,
  Dropdown,
} from '../../../components'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { SSIPage } from './style'

import { schema } from './validation'
import { reduceValidationError } from '../../../utils'

const calculator = createDecorator(
  {
    field: 'primaryPaymentBeginAge',
    updates: {
      primaryReceivingSsi: (primaryPaymentBeginAge, allValues) =>
        primaryPaymentBeginAge === allValues.initialPrimaryPaymentBeginAge
          ? allValues.primaryReceivingSsi
          : primaryPaymentBeginAge > allValues.primaryCurrentAge
          ? false
          : null,
      primaryUseGcEstimate: (primaryPaymentBeginAge, allValues) =>
        primaryPaymentBeginAge === allValues.initialPrimaryPaymentBeginAge
          ? allValues.primaryUseGcEstimate
          : null,
      primaryMonthlyAmount: (primaryPaymentBeginAge, allValues) =>
        primaryPaymentBeginAge === allValues.initialPrimaryPaymentBeginAge
          ? allValues.primaryMonthlyAmount
          : null,
      primaryMonthlyAmountCustom: (primaryPaymentBeginAge, allValues) =>
        primaryPaymentBeginAge === allValues.initialPrimaryPaymentBeginAge
          ? allValues.primaryMonthlyAmountCustom
          : null,
    },
  },
  {
    field: 'spousePaymentBeginAge',
    updates: {
      spouseReceivingSsi: (spousePaymentBeginAge, allValues) =>
        spousePaymentBeginAge === allValues.initialSpousePaymentBeginAge
          ? allValues.spouseReceivingSsi
          : spousePaymentBeginAge > allValues.primaryCurrentAge
          ? false
          : null,
      spouseUseGcEstimate: (spousePaymentBeginAge, allValues) =>
        spousePaymentBeginAge === allValues.initialSpousePaymentBeginAge
          ? allValues.spouseUseGcEstimate
          : null,
      spouseMonthlyAmount: (spousePaymentBeginAge, allValues) =>
        spousePaymentBeginAge === allValues.initialSpousePaymentBeginAge
          ? allValues.spouseMonthlyAmount
          : null,
      spouseMonthlyAmountCustom: (spousePaymentBeginAge, allValues) =>
        spousePaymentBeginAge === allValues.initialSpousePaymentBeginAge
          ? allValues.spouseMonthlyAmountCustom
          : null,
    },
  }
)

function SSIChanges({
  handleCancel,
  history,
  store: {
    saveSSBenefits,
    getSSBenefits,
    primarySSIBenefits,
    spouseSSIBenefits,
    getWorkerBenefits,
    workerBenefits,
    config: { isSpendown },
    spouse,
    person,
  },
  store,
}) {
  const [status, setStatus] = useState('loading')

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getSSBenefits()
        await getWorkerBenefits()
        setStatus('success')
      } catch (err) {
        setStatus('error')
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return <ErrorPage />
  }

  let spouseInitialValues = {}
  let initialValues = {
    primaryIncludeSsi: Boolean(_.get(primarySSIBenefits, 'includeSsi', null)),
    primaryReceivingSsi: Boolean(_.get(primarySSIBenefits, 'receivingSsi', null)),
    primaryUseGcEstimate: Boolean(_.get(primarySSIBenefits, 'useGcEstimate', null)),
    primaryMonthlyAmount: _.get(primarySSIBenefits, 'monthlyAmount', null),
    primaryMonthlyAmountCustom: _.get(primarySSIBenefits, 'monthlyAmount', null),
    primaryPaymentBeginAge: primarySSIBenefits.paymentBeginAge,
    initialPrimaryPaymentBeginAge: _.get(primarySSIBenefits, 'paymentBeginAge', null),
    primaryCurrentAge: person.age,
    workerBenefits: workerBenefits,
  }

  if (person.includeSpouse) {
    spouseInitialValues = {
      spouseIncludeSsi: Boolean(_.get(spouseSSIBenefits, 'includeSsi', null)),
      spouseReceivingSsi: Boolean(_.get(spouseSSIBenefits, 'receivingSsi', null)),
      spouseUseGcEstimate: Boolean(_.get(spouseSSIBenefits, 'useGcEstimate', null)),
      spouseMonthlyAmount: _.get(spouseSSIBenefits, 'monthlyAmount', null),
      spouseMonthlyAmountCustom: _.get(spouseSSIBenefits, 'monthlyAmount', null),
      spousePaymentBeginAge: _.get(spouseSSIBenefits, 'paymentBeginAge', null),
      initialSpousePaymentBeginAge: _.get(spouseSSIBenefits, 'paymentBeginAge', null),
      spouseCurrentAge: spouse.age,
    }
    initialValues = {
      ...initialValues,
      ...spouseInitialValues,
    }
  }

  const onSubmit = async values => {
    let spouseSSIData
    let primarySSIData

    if (person.includeSpouse) {
      if (values.spouseIncludeSsi) {
        spouseSSIData = {
          ...spouseSSIBenefits,
          includeSsi: values.spouseIncludeSsi,
          receivingSsi: values.spouseReceivingSsi ? 1 : 0,
          useGcEstimate: values.spouseReceivingSsi ? 0 : values.spouseUseGcEstimate ? 1 : 0,
          monthlyAmount: values.spouseUseGcEstimate
            ? values.spouseReceivingSsi
              ? values.spouseMonthlyAmountCustom || 0
              : workerBenefits.spouseSSBenefits[values.spousePaymentBeginAge]
            : values.spouseMonthlyAmountCustom || 0,
          paymentBeginAge: parseInt(values.spousePaymentBeginAge),
        }
      } else {
        spouseSSIData = {
          ...spouseSSIBenefits,
          includeSsi: values.spouseIncludeSsi,
        }
      }
    }

    if (values.primaryIncludeSsi) {
      primarySSIData = {
        ...primarySSIBenefits,
        includeSsi: values.primaryIncludeSsi,
        receivingSsi: values.primaryReceivingSsi ? 1 : 0,
        useGcEstimate: values.primaryReceivingSsi ? 0 : values.primaryUseGcEstimate ? 1 : 0,
        monthlyAmount: values.primaryUseGcEstimate
          ? values.primaryReceivingSsi
            ? values.primaryMonthlyAmountCustom || 0
            : workerBenefits.primarySSBenefits[values.primaryPaymentBeginAge]
          : values.primaryMonthlyAmountCustom || 0,
        paymentBeginAge: parseInt(values.primaryPaymentBeginAge),
      }
    } else {
      primarySSIData = {
        ...primarySSIBenefits,
        includeSsi: values.primaryIncludeSsi,
      }
    }

    let ssiData = { primarySSIBenefits: primarySSIData }
    if (person.includeSpouse) {
      ssiData = { ...ssiData, spouseSSIBenefits: spouseSSIData }
    }

    try {
      await saveSSBenefits(ssiData)
      await getSSBenefits()

      if (store.config.isRetail) {
        if (store.config.onBoarded) {
          await store.getRecommendedAdvicesForSpending()
          await store.processModifiedCase()
          return history.push({ pathname: '/spending', state: { from: 'SSI' } })
        } else {
          if (!store.config.isSpendown) {
            return history.push('/welcome/income-goal')
          }
          if (store.config.isSpendown) {
            return history.push('/welcome/greeting')
          }
        }
      }

      if (history.location.pathname === '/welcome/social-security-income/2') {
        history.push('/welcome/pension-options')
      } else {
        if (isSpendown) {
          await store.getRecommendedAdvicesForSpending()
          await store.processModifiedCase()
          return history.push({ pathname: '/spending', state: { from: 'SSI' } })
        }
        history.push('/overall')
      }
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const primaryBeginOptions = () => {
    const arr = []
    for (let i = 62; i <= 70; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }
  const spouseBeginOptions = () => {
    const arr = []
    for (let i = 62; i <= 70; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }

  const validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  return (
    <Form
      validate={validate}
      onSubmit={onSubmit}
      subscription={{ submitting: true, submitError: true, values: true }}
      initialValues={initialValues}
      decorators={[calculator]}
      render={({ handleSubmit, submitting, values }) => (
        <div>
          <SSIPage.ChangesSubTitle>
            Please note: Social Security estimates are before-tax
          </SSIPage.ChangesSubTitle>
          <Spacer space='10px' />

          <SSIPage.ChangesInfoText>
            You can also visit the Social Security Administration's web site to get your actual
            figures vs. the GuidedChoice estimate:&nbsp;
            <SSIPage.ChangesLink
              target='_blank'
              rel='noreferrer noopener'
              href='https://www.ssa.gov/benefits/retirement/estimator.html'>
              https://www.ssa.gov/benefits/retirement/estimator.html
            </SSIPage.ChangesLink>{' '}
          </SSIPage.ChangesInfoText>
          <Spacer space='12px' />

          <SSIPage.ChangesSSIHeader>{person.displayName}'s SSI</SSIPage.ChangesSSIHeader>
          <Spacer space='5px' />
          <Field
            name='primaryIncludeSsi'
            subscription={{ value: true, touched: true, error: true }}
            render={({ input, meta }) => (
              <div>
                <div
                  css={css`
                    font-size: 14px;
                  `}>
                  <ButtonInput
                    isActive={input.value === true}
                    name={input.name}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    onClick={(name, value) => input.onChange(value)}
                    text='Include'
                    value
                    width='122px'
                  />
                  <ButtonInput
                    isActive={input.value === false}
                    name={input.name}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    onClick={(name, value) => input.onChange(value)}
                    text='Exclude'
                    value={false}
                    width='122px'
                  />
                </div>
                <div>
                  <TextErrorField error={meta.error} showError={meta.touched} />
                </div>
              </div>
            )}
          />
          <Spacer space='10px' />

          {values.primaryIncludeSsi && (
            <>
              <SSIPage.ChangesLabel>SSI begin age?</SSIPage.ChangesLabel>
              <SSIPage.ChangesRemoveExtraHight>
                <Field
                  name='primaryPaymentBeginAge'
                  render={({ input, meta }) => (
                    <Dropdown
                      id='accountTypeDropdown'
                      error={meta.error}
                      name={input.name}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      options={primaryBeginOptions()}
                      placeholder='Select'
                      selected={input.value}
                      showError={meta.touched}
                      width='110px'
                    />
                  )}
                />
              </SSIPage.ChangesRemoveExtraHight>
            </>
          )}
          <Spacer space='10px' />

          {values.primaryIncludeSsi && parseInt(values.primaryPaymentBeginAge) <= person.age && (
            <>
              <SSIPage.ChangesLabel>Already receiving?</SSIPage.ChangesLabel>
              <Field
                name='primaryReceivingSsi'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <div>
                    <ButtonInput
                      isActive={input.value === true}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='Yes'
                      value
                      width='110px'
                    />
                    <ButtonInput
                      isActive={input.value === false}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='No'
                      value={false}
                      width='110px'
                    />
                    <div>
                      <TextErrorField error={meta.error} showError={meta.touched} />
                    </div>
                  </div>
                )}
              />
              <Spacer space='10px' />
            </>
          )}

          {values.primaryIncludeSsi && values.primaryReceivingSsi === false && (
            <>
              <SSIPage.ChangesLabel>
                Use GuidedChoice Social Security income estimate?
              </SSIPage.ChangesLabel>
              <Field
                name='primaryUseGcEstimate'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <div>
                    <SSIPage.ButtonWrapper>
                      <ButtonInput
                        isActive={input.value === true}
                        name={input.name}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onClick={(name, value) => input.onChange(value)}
                        text='Yes, estimate for me'
                        value
                        width='155px'
                      />
                      <ButtonInput
                        isActive={input.value === false}
                        name={input.name}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onClick={(name, value) => input.onChange(value)}
                        text="No, I'll enter my own"
                        value={false}
                        width='155px'
                      />
                    </SSIPage.ButtonWrapper>
                    <div>
                      <TextErrorField error={meta.error} showError={meta.touched} />
                    </div>
                  </div>
                )}
              />
              <Spacer space='10px' />
            </>
          )}

          {values.primaryIncludeSsi &&
            (values.primaryReceivingSsi ||
              (!values.primaryReceivingSsi && values.primaryUseGcEstimate === false)) && (
              <>
                <SSIPage.ChangesLabel>Monthly amount</SSIPage.ChangesLabel>
                <SSIPage.ChangesRemoveExtraHight>
                  <Field
                    name='primaryMonthlyAmountCustom'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <CurrencyInput
                        error={meta.error}
                        name={input.name}
                        placeholder='0'
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onChange={(name, value) => input.onChange(value)}
                        showError={meta.touched}
                        value={parseInt(input.value)}
                        marginTop='0px'
                      />
                    )}
                  />
                </SSIPage.ChangesRemoveExtraHight>
              </>
            )}

          {person.maritalStatus && person.includeSpouse && (
            <>
              <Spacer space='25px' />
              <SSIPage.ChangesSSIHeader>{spouse.firstName}'s SSI</SSIPage.ChangesSSIHeader>
              <Spacer space='5px' />
              <Field
                name='spouseIncludeSsi'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <div>
                    <div
                      css={css`
                        font-size: 14px;
                      `}>
                      <ButtonInput
                        isActive={input.value === true}
                        name={input.name}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onClick={(name, value) => input.onChange(value)}
                        text='Include'
                        value
                        width='122px'
                      />
                      <ButtonInput
                        isActive={input.value === false}
                        name={input.name}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onClick={(name, value) => input.onChange(value)}
                        text='Exclude'
                        value={false}
                        width='122px'
                      />
                    </div>
                    <div>
                      <TextErrorField error={meta.error} showError={meta.touched} />
                    </div>
                  </div>
                )}
              />
              <Spacer space='10px' />

              {values.spouseIncludeSsi && (
                <>
                  <SSIPage.ChangesLabel>SSI begin age?</SSIPage.ChangesLabel>
                  <SSIPage.ChangesRemoveExtraHight>
                    <Field
                      name='spousePaymentBeginAge'
                      render={({ input, meta }) => (
                        <Dropdown
                          id='accountTypeDropdown'
                          error={meta.error}
                          name={input.name}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          options={spouseBeginOptions()}
                          placeholder='Select'
                          selected={input.value}
                          showError={meta.touched}
                          width='110px'
                        />
                      )}
                    />
                  </SSIPage.ChangesRemoveExtraHight>
                </>
              )}
              <Spacer space='10px' />

              {values.spouseIncludeSsi && values.spousePaymentBeginAge <= spouse.age && (
                <>
                  <SSIPage.ChangesLabel>Already receiving?</SSIPage.ChangesLabel>
                  <Field
                    name='spouseReceivingSsi'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <div>
                        <ButtonInput
                          isActive={input.value === true}
                          name={input.name}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onClick={(name, value) => input.onChange(value)}
                          text='Yes'
                          value
                          width='110px'
                        />
                        <ButtonInput
                          isActive={input.value === false}
                          name={input.name}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onClick={(name, value) => input.onChange(value)}
                          text='No'
                          value={false}
                          width='110px'
                        />
                        <div>
                          <TextErrorField error={meta.error} showError={meta.touched} />
                        </div>
                      </div>
                    )}
                  />
                  <Spacer space='10px' />
                </>
              )}

              {values.spouseIncludeSsi && values.spouseReceivingSsi === false && (
                <>
                  <SSIPage.ChangesLabel>
                    Use GuidedChoice Social Security income estimate?
                  </SSIPage.ChangesLabel>
                  <Field
                    name='spouseUseGcEstimate'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <div>
                        <SSIPage.ButtonWrapper>
                          <ButtonInput
                            isActive={input.value === true}
                            name={input.name}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onClick={(name, value) => input.onChange(value)}
                            text='Yes, estimate for me'
                            value
                            width='155px'
                          />
                          <ButtonInput
                            isActive={input.value === false}
                            name={input.name}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onClick={(name, value) => input.onChange(value)}
                            text="No, I'll enter my own"
                            value={false}
                            width='155px'
                          />
                        </SSIPage.ButtonWrapper>
                        <div>
                          <TextErrorField error={meta.error} showError={meta.touched} />
                        </div>
                      </div>
                    )}
                  />
                  <Spacer space='10px' />
                </>
              )}

              {values.spouseIncludeSsi &&
                (values.spouseReceivingSsi ||
                  (!values.spouseReceivingSsi && values.spouseUseGcEstimate === false)) && (
                  <>
                    <SSIPage.ChangesLabel>Monthly amount</SSIPage.ChangesLabel>
                    <SSIPage.ChangesRemoveExtraHight>
                      <Field
                        name='spouseMonthlyAmountCustom'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <CurrencyInput
                            error={meta.error}
                            name={input.name}
                            placeholder='0'
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            onChange={(name, value) => input.onChange(value)}
                            showError={meta.touched}
                            value={parseInt(input.value)}
                            marginTop='0px'
                          />
                        )}
                      />
                    </SSIPage.ChangesRemoveExtraHight>
                  </>
                )}
            </>
          )}

          <ButtonsBox>
            <BackButton backgroundColor='#FFFFFF' onClick={handleCancel}>
              Cancel
            </BackButton>
            <NextButton onClick={handleSubmit} disabled={submitting}>
              Save
            </NextButton>
          </ButtonsBox>
        </div>
      )}
    />
  )
}

export default inject('store')(observer(SSIChanges))
