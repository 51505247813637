import { css } from '@emotion/react'
import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import { IndexToSVGMap, groupMixByType } from '../../../constants'

import { Wrapper } from './style'
import { numberToDollars } from '../../../utils/utils'
import { Page, PageHeader } from '../elements'
import HoldingRow from './HoldingRow'
import { SplitBarChartLegend } from '../../shared'

function OtherInvestments(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
    },
  } = props

  function generateChartSection(dataObject, idx) {
    if (dataObject.invMix === null) {
      return null
    }

    const newInvMix = groupMixByType(dataObject.invMix)

    const balance = newInvMix.reduce((acc, cur) => {
      return (
        acc +
        cur.reduce((total, item) => {
          return total + _.get(item, 'oldValue.dollar', 0)
        }, 0)
      )
    }, 0)

    return (
      <div
        key={idx}
        css={css`
          min-height: 480px;
        `}>
        <Wrapper.RecommendedText>Recommended investments for:</Wrapper.RecommendedText>
        <Wrapper.PlanName>{dataObject.name}</Wrapper.PlanName>
        <Wrapper.AdviceText>
          Before making your withdrawal, be sure to change your investments.
        </Wrapper.AdviceText>
        <Wrapper.BalanceText>
          Balance:
          <Wrapper.BalanceAmount>{numberToDollars(balance, false)}</Wrapper.BalanceAmount>
        </Wrapper.BalanceText>

        <div
          css={css`
            margin-left: -55px;
          `}>
          <SplitBarChartLegend />
        </div>
        {newInvMix.map((mix, inx) => renderRows(mix, IndexToSVGMap[inx]))}
      </div>
    )
  }

  function renderRows(data, svg) {
    return data
      .filter(item => item.newValue !== null)
      .map((item, i) => {
        return (
          <HoldingRow
            key={item.name}
            title={item.name}
            i={i}
            svg={svg}
            oldValue={item.oldValue}
            newValue={item.newValue}
          />
        )
      })
  }

  const { sortedPortfolioConstructionListExcludeInstitutional } = props.store

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'otherChart' })
    willMount.current = false
  }

  const page = findByName('otherChart')

  return (
    !!sortedPortfolioConstructionListExcludeInstitutional &&
    !!sortedPortfolioConstructionListExcludeInstitutional.length && (
      <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
        {_.flatten(
          sortedPortfolioConstructionListExcludeInstitutional.map((account, idx) => {
            return generateChartSection(account, idx)
          })
        )}
      </Page>
    )
  )
}

export default inject('store', 'guide')(observer(OtherInvestments))
