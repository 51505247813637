import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import { IndexToSVGMap, groupMixByType } from '../../../constants'

import { Spacer } from '../../../components'
import { SplitBarChartLegend, SplitBarChartHeader } from '../../shared'
import { Page, PageHeader } from '../elements'
import { ContentMainHeaderChart } from '../elements/styled'
import HoldingRow from './HoldingRow'

class OtherChartPage extends Component {
  generateChartSection(dataObject, idx) {
    let isEmployerAccount = false
    let userName = ''

    if (dataObject.invMix === null) {
      return null
    }

    const { includedSpouseEmployerAccount } = this.props.store
    const { id: clientId, displayName } = this.props.store.person
    const { id: spouseId, firstName } = this.props.store.spouse

    const spouseEmployerAccountId =
      includedSpouseEmployerAccount != null ? includedSpouseEmployerAccount.id : null

    if (dataObject.participantId === clientId) {
      if (dataObject.type === 'Institutional') {
        isEmployerAccount = true
      }
    } else if (dataObject.participantId === spouseId) {
      if (dataObject.id === spouseEmployerAccountId) {
        isEmployerAccount = true
      }
    }

    if (dataObject.participantId === clientId) {
      userName = displayName
    } else if (dataObject.participantId === spouseId) {
      userName = firstName
    }

    const newInvMix = groupMixByType(dataObject.invMix)

    return (
      <div
        key={idx}
        css={css`
          min-height: 480px;
        `}>
        <ContentMainHeaderChart>
          {`Investment mix for: ${userName}'s ${
            isEmployerAccount ? `Current employer plan (${dataObject.name})` : `${dataObject.name}`
          }`}
        </ContentMainHeaderChart>
        <Spacer space='6px' />
        <SplitBarChartLegend paddingLeft={45} />
        <Spacer space='4px' />
        <SplitBarChartHeader />
        {newInvMix.map((mix, inx) => this.renderRows(mix, IndexToSVGMap[inx]))}
      </div>
    )
  }

  renderRows(data, svg) {
    return data
      .filter(item => item.newValue !== null || item.oldValue !== null)
      .map((item, i) => {
        return (
          <HoldingRow
            key={item.name}
            title={item.name}
            i={i}
            svg={svg}
            oldValue={item.oldValue}
            newValue={item.newValue}
          />
        )
      })
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'otherChart' })
  }

  render() {
    const page = this.props.guide.guidePages.pages.findByName('otherChart')

    const { sortedPortfolioConstructionListExcludeInstitutional } = this.props.store

    return (
      !!sortedPortfolioConstructionListExcludeInstitutional &&
      !!sortedPortfolioConstructionListExcludeInstitutional.length && (
        <Page header={<PageHeader text='Other Investment Accounts' />} page={page}>
          {_.flatten(
            sortedPortfolioConstructionListExcludeInstitutional.map((account, idx) => {
              return this.generateChartSection(account, idx)
            })
          )}
        </Page>
      )
    )
  }
}

export default inject('store', 'guide')(observer(OtherChartPage))
