import styled from '@emotion/styled'

const Container = styled.div`
  color: #022a3a;
  font-family: 'Open Sans';
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ paddingRight }) => paddingRight || '0'};
  color: ${p => p.theme.amountNeededTextColor};
`
Section.Name = styled.div`
  font-size: 40px;
  font-weight: 300;
  line-height: 58px;
  color: ${p => p.theme.introTextColor};
`
Section.IndentedSection = styled.div`
  padding-left: 34px;
`
Section.IndentedHomeActionSection = styled.div`
  padding-left: 34px;
  display: flex;
  flex-direction: column;
`
Section.SectionTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 300;
  line-height: 41px;
`
Section.SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`
Section.NameInvestmentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
Section.InvestmentBalance = styled.div`
  color: ${p => p.theme.investmentAccountsBalanceTextColor};
  font-size: 40px;
  font-weight: 300;
  line-height: 58px;
`
Section.InvestmentTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  font-weight: 300;
`
Section.InvestmentAmount = styled.div`
  font-family: 'Open Sans';
  font-size: 40px;
  font-weight: 300;
  line-height: 58px;
  text-align: right;
`
Section.EncouragementText = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 22px;
  align-items: baseline;
  & > div {
    margin-right: 10px;
  }
  color: ${p => p.theme.encouragementTextColor};
`
Section.ActionItemsBox = styled.div`
  box-sizing: border-box;
  min-height: 111px;
  max-width: 836px;
  border: ${p => p.theme.actionItemsBoxBorder};
  border-radius: 8px;
  background-color: ${p => p.theme.actionItemsBoxColor};
  padding: 10px;
  align-items: baseline;
`
Section.ActionItemsTitle = styled.div`
  color: ${p => p.theme.actionItemsTitleColor};
  font-size: 30px;
  font-weight: 300;
  line-height: 41px;
`
Section.Triangle = styled.span`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 20px solid #7a8e96;
`
Section.ActionItemsMessage = styled.div`
  color: ${p => (p.warning ? '#F48024' : p.theme.actionItemsMessageTextColor)};
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: space-between;
`
Section.ActionItemMessageText = styled.span`
  width: 95%;
`
Section.OnTrackRow = styled.div`
  background-color: ${p =>
    p.isOnTrack ? p.theme.onTrackRowBgColor : p.theme.notOnTrackRowBgColor};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  max-width: 836px;
  width: 808px;
  padding: 10px 20px;
  color: #4a606a;
`
Section.OnTrackWrapper = styled.div`
  display: flex;
  align-items: center;
`
Section.OnTrackText = styled.span`
  color: ${p => p.theme.onTrackTextColor};
`
Section.GoalStatisticBox = styled.span`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 836px;
`
Section.GoalStatisticWrapper = styled.div`
  text-indent: 48px;
`
Section.SecondaryText = styled.div`
  color: ${p => p.theme.secondaryTextColor};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 18px;
  margin-left: ${p => (p.marginLeft ? p.marginLeft : 0)};
`
Section.EmployerPlanAccountContainer = styled.div`
  max-width: 836px;
  padding: 20px 25px;
  background-color: ${p => p.theme.containerColor};
`
Section.SvgContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 3px;
`
Section.SubTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 300;
  line-height: 33px;
`
Section.ContributionsWrapper = styled.div`
  display: flex;
`
Section.ContributionsText = styled.div`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 22px;
  margin-left: 46px;
`
Section.ContributionsAnnualText = styled.div`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  margin-left: 46px;
`
Section.AnnualTotal = styled.div`
  color: ${p => p.theme.mediumGray};
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
`
Section.DarkGreyText = styled.span`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
`
Section.HomeActionButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  min-height: 51px;
  max-width: 450px;
  border: ${p => p.theme.homeActionButtonBorder};
  border-radius: 4px;
  background-color: ${p => p.theme.homeActionButtonBgColor};
  margin: 10px 0;

  color: ${p => p.theme.homeActionButtonTextColor};
  font-family: 'Open Sans';
  font-size: 21px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;

  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.homeActionButtonHoverColor};
  }
`
Section.SpendDownModelingBtn = styled.button`
  box-sizing: border-box;
  border: 1px solid #dadfe1;
  border-radius: 4px;
  background-color: #f7f8f9;
  padding: 10px;
  margin-right: 10px;
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 21px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;

  outline: none;
  cursor: pointer;

  &:hover {
    background-color: #d2d7dc;
  }
`

Section.ButtonContainer = styled.span`
  box-sizing: border-box;
  border: 1px solid #7a8e96;
  border-radius: 50px;
  padding: 0px 0px;
  width: 80px;
  height: 35px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`
Section.CustomButton = styled.button`
  box-sizing: border-box;
  padding: 4px 8px;
  border: none;
  background-color: ${({ active }) => (active ? '#85c964bd' : 'transparent')};
  border: ${({ active }) => (active ? '1px solid #7a8e96' : 'none')};
  outline: none;
  border-radius: 50px;
  margin: 0px -1px;
  color: #7a8e96;
  cursor: pointer;
  transform: ${p => (p.flipped ? 'scale(-1, -1)' : 'scale(1, 1)')};
  height: 38px;
`
Section.ButtonAndHelpWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  & > span {
    margin-right: 10px;
  }
`
Section.AmountNeeded = styled.div`
  color: ${p => p.theme.amountNeededTextColor};
  font-family: 'Open Sans';
  font-size: 28px;
  letter-spacing: 0;
  line-height: 38px;

  &::before {
    content: '$';
    color: #4d9927;
    padding-right: 3px;
  }

  &.testingNew {
    fill: red;
  }
`
Section.ModalText = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 30px;
`
Section.OrangeText = styled.b`
  color: #f48024;
`
Section.BoldText = styled.b`
  font-weight: bold;
`
Section.ButtonsBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 0 50px auto;
  max-width: 114px;

  & > button {
    font-size: 14px;
  }
`

Section.LightLabel = styled.span`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
`
Section.DarkLabel = styled.span`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
`
Section.SVGContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-left: 43px;
  margin-top: 20px;
  margin-bottom: 10px;
`

Section.CanYouRetire = styled.div`
  margin: 16px 10px 16px 0px;
`

Section.Link = styled.a`
  cursor: pointer;
  color: ${p => p.theme.editColor};
  text-decoration: underline;
`
Section.UncoloredLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`
Section.ActionMessageLink = styled.a`
  cursor: pointer;
  color: ${p => p.theme.editColor};
  text-decoration: underline;
`

const Page = styled.div``

Page.ActionMessage = styled.div`
  font-size: 14px;
  color: ${p => p.theme.lightestGray};
`
Page.ActionMessage = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 18px;
`
Page.SubActionMessage = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 18px;
`

export { Section, Container, Page }
