import { css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'

import { API_URL } from '../../../api'
import { TextErrorField, Checkbox, HelpIcon, TooltipText } from '../../../components'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { reduceValidationError } from '../../../utils'

const schema = yup.object().shape({
  discontinue: yup.boolean().nullable().required('Select an option'),
})

function DiscontinueForm(props) {
  function initialValues() {
    return { discontinue: null }
  }

  function validate(values) {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  async function handleDiscontinueTransaction() {
    try {
      await props.store.updateManagedAccountStatus({ managedAccountStatus: false })
      await props.store.getFeatures()
      props.history.push('/transaction-confirmed')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  function onSubmit({ discontinue }) {
    if (!discontinue) {
      props.onClose()
    } else {
      return handleDiscontinueTransaction()
    }
  }

  const { features } = props.store

  const RedirectActive = () => (
    <div
      css={css`
        padding: 20px;
      `}>
      <div
        css={css`
          color: #4a606a;
          font-size: 22px;
          font-weight: 300;
        `}>
        Are you sure you want to discontinue GuidedChoice advice services?
      </div>
      <div
        css={css`
          font-size: 18px;
          font-weight: 300;
          color: #4a606a;
          margin: 24px;
          line-height: 1.35;
        `}>
        Schwab prefers that we direct you to their representatives to discontinue advisory services
        with GuidedChoice. Please contact them at (877) 285-4929.
      </div>
      <ButtonsBox>
        <BackButton backgroundColor='#FFFFFF' onClick={props.onClose}>
          Cancel
        </BackButton>
        <NextButton onClick={() => window.open(`${API_URL.slice(0, -4)}logout`, '_self')}>
          Log Off
        </NextButton>
      </ButtonsBox>
    </div>
  )

  const DiscontinueActive = () => (
    <Form
      initialValues={initialValues()}
      validate={validate}
      onSubmit={onSubmit}
      subscription={{ submitting: true, submitError: true }}
      render={({ handleSubmit, submitting, submitError }) => (
        <div
          css={css`
            padding: 20px;
          `}>
          <div
            css={css`
              color: #4a606a;
              font-size: 22px;
              font-weight: 300;
            `}>
            Are you sure you want to discontinue GuidedChoice advice services?&nbsp;
            <HelpIcon size='mediumLarge' tooltip={TooltipText.discontinueManagedAccount()} />
          </div>

          <div
            css={css`
              display: flex;
              margin: auto;
              margin: 40px 0 1rem;
            `}>
            <Field
              name='discontinue'
              subscription={{ value: true }}
              render={({ input }) => (
                <div
                  css={css`
                    margin-top: 8px;
                    min-width: 42px;
                  `}>
                  <Checkbox
                    id='checkbox'
                    checked={input.value === false}
                    onClick={() => input.onChange(false)}
                    css={css`
                      margin: auto;
                    `}
                  />
                </div>
              )}
            />
            <Field
              name='discontinue'
              subscription={{ value: true }}
              render={({ input }) => (
                <div>
                  <div
                    onClick={() => input.onChange(false)}
                    css={css`
                      cursor: pointer;
                      color: #4a606a;
                      font-size: 1.125rem;
                      font-weight: 600;
                      line-height: 1.5;
                      padding-left: 12px;
                    `}>
                    No, keep managed account.
                  </div>
                  <div
                    css={css`
                      color: #4a606a;
                      font-size: 0.875rem;
                      font-weight: 300;
                      line-height: 1.5;
                      padding-top: 12px;
                      padding-left: 12px;
                    `}>
                    (Selecting this option will return you to your profile upon clicking Next.)
                  </div>
                </div>
              )}
            />
          </div>

          <div
            css={css`
              display: flex;
              margin: auto;
              margin: 1rem 0 40px;
            `}>
            <Field
              name='discontinue'
              subscription={{ value: true }}
              render={({ input }) => (
                <div
                  css={css`
                    margin-top: 8px;
                    min-width: 42px;
                  `}>
                  <Checkbox
                    id='checkbox'
                    checked={input.value === true}
                    onClick={() => input.onChange(true)}
                    css={css`
                      margin: auto;
                    `}
                  />
                </div>
              )}
            />
            <Field
              name='discontinue'
              subscription={{ value: true }}
              render={({ input }) => (
                <div>
                  <div
                    onClick={() => input.onChange(true)}
                    css={css`
                      cursor: pointer;
                      color: #4a606a;
                      font-size: 1.125rem;
                      font-weight: 600;
                      line-height: 1.5;
                      padding-left: 12px;
                    `}>
                    Yes, discontinue managed account.
                  </div>
                  <div
                    css={css`
                      color: #4a606a;
                      font-size: 0.875rem;
                      font-weight: 300;
                      line-height: 1.5;
                      padding-top: 12px;
                      padding-left: 12px;
                    `}>
                    Your investments will remain in the GuidedChoice allocation and your
                    contributions will stay as they are now until you change them.
                  </div>
                </div>
              )}
            />
          </div>

          <Field
            name='discontinue'
            subscription={{ touched: true, error: true }}
            render={({ meta }) => (
              <div
                css={css`
                  padding: 20px;
                  text-align: center;
                `}>
                <TextErrorField showError={meta.touched} error={meta.error || submitError} />
              </div>
            )}
          />

          <ButtonsBox
            css={css`
              margin-top: 30px;
            `}>
            <BackButton backgroundColor='#FFFFFF' onClick={props.onClose} disabled={submitting}>
              Cancel
            </BackButton>
            <NextButton onClick={handleSubmit} disabled={submitting}>
              Next
            </NextButton>
          </ButtonsBox>
        </div>
      )}
    />
  )

  return features.discontinueRedirect ? <RedirectActive /> : <DiscontinueActive />
}

export default withRouter(inject('store')(DiscontinueForm))
