/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'

import { Spacer } from '../../../components'
import { numberToDollars } from '../../../utils'
import { Page, PageHeader } from '../elements'
import {
  ContentMainHeader,
  ContentSubHeader,
  ContentText,
  FlexContainer,
  StyledBar,
} from '../elements/styled'

const ChartMainLabel = styled.div`
  color: ${p => (p.old ? '#7a8e96' : '#038ab7')};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 11pt;
`

const ChartSubLabel = styled.div`
  color: ${p => p.theme.darkestGray};
  text-align: center;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: ${p => (p.bold ? 500 : null)};
  font-size: 11pt;
  padding: 12px 0 8px 0;
`

const TextHolder = styled.div`
  min-width: 200px;
`

const createPageConfig = ({ config, currentEmployerAcct, employerAccount }) => {
  switch (config.templateId) {
    // FRS Investment
    case 1:
      return {
        introText: `The two main elements over which you have control in reaching your goals are how much you save and where you invest. We'll start with your ${config.template.planName} account, then look at other investment accounts.`,
        contributionRateText:
          'Your contribution rate has been set by Florida statutes and cannot be changed. Your employer also makes contributions on your behalf, the amounts of which are determined by your membership class.',
      }

    // FRS Pension Only
    case 2:
      return {
        introText: `The two main elements over which you have control in reaching your goals are how much you save and where you invest. As an ${config.template.planName} member, we need to look at what you can do outside of the ${config.template.sponsorName}'s plan.`,
        contributionRateText: '',
      }

    // McDonald's (foundation)
    // Atmos Energy
    // Cascade Sawing & Drilling
    // GuidedChoice
    // NXP
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return {
        introText: `The two main elements over which you have control in reaching your goals are how much you save and where you invest. One of the best places to do so is in your employer's retirement plan account. As a GuidedChoice managed account holder, GuidedChoice sends, automatically, any changes to your retirement account with ${config.template.sponsorName} to the Plan recordkeeper, ${config.template.recordKeeperName}.`,
        showCompanyMatchAnnualAmount: false,
      }

    // Paychex
    // Schwab Legacy
    // Schwab SIA
    case 8:
    case 9:
    case 10:
    default:
      return {
        introText: `The two main elements over which you have control in reaching your goals are how much you save and where you invest. One of the best places to do so is in your employer's retirement plan account. As a GuidedChoice managed account holder, GuidedChoice sends, automatically, any changes to your retirement account with ${config.template.sponsorName} to the Plan recordkeeper, ${config.template.recordKeeperName}.`,
        showCompanyMatchAnnualAmount: true,
      }
    //Retail
    case 19:
      return {
        introText: `The two main elements over which you have control in reaching your goals are how much you save and where you invest. One of the best places to do so is in your employer's retirement plan account. 
          ${
            currentEmployerAcct
              ? `We'll start with your ${
                  employerAccount.length ? employerAccount[0].name : ''
                } account, then look at other investment accounts.`
              : 'Since it appears you are not currently eligible to participate in an employer plan, we need to look at what you can do in other investment accounts.'
          }`,
      }
  }
}

function BarRow(props) {
  const { label, percentageOld, amountOld, percentageNew, amountNew } = props

  return (
    <div
      css={css`
        display: flex;
        align-items: baseline;
      `}>
      <div
        css={css`
          flex: 0 0 200px;
          font-size: 13pt;
          color: #022a3a;
          font-weight: 600;
        `}>
        {label}
      </div>

      <div
        css={css`
          flex: 1 1 250px;
          text-align: center;
          padding: 0 6px;
        `}>
        <div
          css={css`
            background-color: #f3f4f5;
            color: #7a8e96;
            font-size: 15pt;
            height: 40pt;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          {amountOld
            ? `${numberToDollars(percentageOld, true)}% | $${numberToDollars(amountOld, true)}/year`
            : '\u2014'}
        </div>
      </div>

      <div
        css={css`
          flex: 1 1 250px;
          text-align: center;
          padding: 0 6px;
        `}>
        <div
          css={css`
            background-color: #e7f3f9;
            color: #038ab7;
            font-size: 15pt;
            height: 40pt;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          {amountNew
            ? `${numberToDollars(percentageNew, true)}% | $${numberToDollars(amountNew, true)}/year`
            : '\u2014'}
        </div>
      </div>
    </div>
  )
}

function BarRowSub(props) {
  const { label, percentageOld, amountOld, percentageNew, amountNew } = props

  return (
    <div
      css={css`
        display: flex;
        align-items: baseline;
      `}>
      <div
        css={css`
          flex: 0 0 200px;
          font-size: 10pt;
          color: #022a3a;
          font-weight: 600;
        `}>
        {label}
      </div>

      <div
        css={css`
          flex: 1 1 250px;
          text-align: center;
          padding: 0 6px;
        `}>
        <div
          css={css`
            background-color: #f3f4f5;
            color: #7a8e96;
            font-size: 10pt;
            height: 20pt;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          {amountOld
            ? `${numberToDollars(percentageOld, true)}% | $${numberToDollars(amountOld, true)}/year`
            : '\u2014'}
        </div>
      </div>

      <div
        css={css`
          flex: 1 1 250px;
          text-align: center;
          padding: 0 6px;
        `}>
        <div
          css={css`
            background-color: #e7f3f9;
            color: #038ab7;
            font-size: 10pt;
            height: 20pt;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          {amountNew
            ? `${numberToDollars(percentageNew, true)}% | $${numberToDollars(amountNew, true)}/year`
            : '\u2014'}
        </div>
      </div>
    </div>
  )
}

const ContributionSubSection = inject(
  'store',
  'guide'
)(
  observer(
    class extends Component {
      renderContributionText() {
        const { hasEmployerMatch, contributeMax, maximumContributionText } = this.props.store.active

        const { displayName } = this.props.store.person

        if (!hasEmployerMatch || !maximumContributionText) {
          return ''
        }

        const sentencePart1 = contributeMax
          ? 'Good news, '
          : `Consider increasing account contributions to at
    least ${maximumContributionText} so that `
        const sentencePart2 = ` ${displayName} is
    contributing enough to receive the maximum annual employer match of ${maximumContributionText}.`

        return `${sentencePart1}${sentencePart2}`
      }

      maxMatchMessage = (totalContributionRate, companyMatchRateMax) => {
        let message
        if (totalContributionRate >= companyMatchRateMax) {
          message = 'Good job! You are contributing enough to receive the maximum annual match.'
        } else {
          message = `Consider increasing contributions to at least ${companyMatchRateMax}% so that you can receive the maximum annual match.`
        }
        return message
      }

      render() {
        const { pageConfig, employerAccount, currentEmployerAcct } = this.props
        const {
          config: { templateId, isRetail },
          active,
        } = this.props.store
        const { displayName } = this.props.store.person
        const { annualSalary } = this.props.store.account
        const { name: clientInstitutionName, profitSharing } = active

        if (templateId === 1) {
          return (
            <>
              <Spacer space='8px' />
              <ContentMainHeader>
                {`${displayName}'s ${
                  clientInstitutionName ? `${clientInstitutionName}` : ''
                } account`}
              </ContentMainHeader>
              <Spacer space='6px' />
              <ContentSubHeader>CONTRIBUTIONS</ContentSubHeader>
              <Spacer space='6px' />
              <ContentText>{pageConfig.contributionRateText}</ContentText>
              <Spacer space='4px' />
              <ContentText>{this.renderContributionText()}</ContentText>
              <Spacer space='4px' />
              <ChartSubLabel bold>Annual pre-tax total</ChartSubLabel>
              <FlexContainer justifyContent='center' childPadding='8px'>
                <StyledBar>
                  <TextHolder>
                    {active.totalContributionAmt
                      ? `${numberToDollars(
                          active.totalContributionRate,
                          true
                        )}% | $${numberToDollars(active.totalContributionAmt, true)}/year`
                      : '\u2014'}
                  </TextHolder>
                </StyledBar>
              </FlexContainer>
              {!!profitSharing && (
                <div>
                  <Spacer space='4px' />
                  <ChartSubLabel bold>Annual total from employer</ChartSubLabel>
                  <FlexContainer justifyContent='center' childPadding='8px'>
                    <StyledBar>
                      <TextHolder>
                        {active.profitSharingRate
                          ? `${(Math.round(active.profitSharingRate * 100) / 100).toFixed(
                              2
                            )}% | $${numberToDollars(
                              (active.profitSharingRate / 100) * annualSalary,
                              true
                            )}/year`
                          : '\u2014'}
                      </TextHolder>
                    </StyledBar>
                  </FlexContainer>
                </div>
              )}
            </>
          )
        } else if (templateId === 2 && active.balance) {
          return (
            <>
              <Spacer space='8px' />
              <ContentMainHeader>{`${displayName}'s ${clientInstitutionName} account`}</ContentMainHeader>
              <Spacer space='6px' />
              <ContentSubHeader>CONTRIBUTIONS</ContentSubHeader>
              <Spacer space='6px' />
              <ContentText>{pageConfig.contributionRateText}</ContentText>
              <Spacer space='4px' />
              <ContentText>{this.renderContributionText()}</ContentText>
              <Spacer space='4px' />
              <ChartSubLabel bold>Annual pre-tax total</ChartSubLabel>
              <FlexContainer justifyContent='center' childPadding='8px'>
                <StyledBar>
                  <TextHolder>0% | $0</TextHolder>
                </StyledBar>
              </FlexContainer>
            </>
          )
        } else if (templateId > 2 || (templateId === 19 && currentEmployerAcct)) {
          // Assume Institutional or Retail at this point
          const { store } = this.props
          const { oneContributionOnly } = store.active
          const { totalAnnualPercentage, totalAnnualSavings, primary } = store.acceptedCase

          const preTaxAnnualContributionsNEW = primary ? primary.preTaxAnnualContributions : 0
          const preTaxAnnualContribDollarsNEW = primary ? primary.preTaxAnnualContribDollars : 0

          const rothAnnualContributionsNEW = primary ? primary.rothAnnualContributions : 0
          const rothAnnualContribDollarsNEW = primary ? primary.rothAnnualContribDollars : 0

          const postTaxAnnualContributionsNEW = primary ? primary.postTaxAnnualContributions : 0
          const postTaxAnnualContribDollarsNEW = primary ? primary.postTaxAnnualContribDollars : 0

          const showCompanyMatch = store.active.companyMatchRateMax > 0
          const isMatchingCompanyMax =
            store.active.totalContributionRate >= store.active.companyMatchRateMax

          return (
            <div>
              <div
                css={css`
                  font-family: Aleo;
                  color: #06293a;
                  font-size: 18pt;
                  padding-top: 42px;
                `}>
                {displayName}'s retirement plan account{' '}
                {isRetail ? (employerAccount.length ? employerAccount[0].name : '') : ''}
                {!isRetail ? `with ${store.config.template.sponsorName}` : ''}
              </div>

              <div
                css={css`
                  padding: 24px 0 18px;
                  color: #06293a;
                `}>
                YOUR CONTRIBUTIONS
              </div>

              <div
                css={css`
                  display: flex;
                `}>
                <div
                  css={css`
                    flex: 0 0 200px;
                  `}
                />
                <ChartMainLabel
                  old
                  css={css`
                    flex: 1 1 250px;
                    text-align: center;
                  `}>
                  OLD CONTRIBUTION
                </ChartMainLabel>
                <ChartMainLabel
                  css={css`
                    flex: 1 1 250px;
                    text-align: center;
                  `}>
                  NEW CONTRIBUTION
                </ChartMainLabel>
              </div>

              <Spacer space='4px' />

              <BarRow
                label={oneContributionOnly ? 'Annual pre-tax total' : 'Annual total'}
                percentageOld={active.totalContributionRate}
                amountOld={active.totalContributionAmt}
                percentageNew={totalAnnualPercentage}
                amountNew={totalAnnualSavings}
              />

              <Spacer space='4px' />

              {!oneContributionOnly && (
                <div>
                  <div
                    css={css`
                      display: flex;
                    `}>
                    <div
                      css={css`
                        flex: 0 0 200px;
                      `}
                    />
                    <div
                      css={css`
                        flex: 1 1 250px;
                        text-align: center;
                        padding: 18px 0 12px;
                        color: #022a3a;
                        font-size: 13pt;
                        font-weight: 600;
                      `}>
                      Annual total includes:
                    </div>
                  </div>

                  {(!!active.preTaxSavingsAmt || !!preTaxAnnualContribDollarsNEW) && (
                    <div>
                      <BarRowSub
                        label='Pre-tax Contribution'
                        percentageOld={active.pretaxSavingsRate}
                        amountOld={active.preTaxSavingsAmt}
                        percentageNew={preTaxAnnualContributionsNEW}
                        amountNew={preTaxAnnualContribDollarsNEW}
                      />
                      <Spacer space='4px' />
                    </div>
                  )}

                  {!!active.rothContribAllowed &&
                    (!!active.rothSavingsAmt || !!rothAnnualContribDollarsNEW) && (
                      <div>
                        <BarRowSub
                          label='Roth Contribution'
                          percentageOld={active.rothSavingsRate}
                          amountOld={active.rothSavingsAmt}
                          percentageNew={rothAnnualContributionsNEW}
                          amountNew={rothAnnualContribDollarsNEW}
                        />
                        <Spacer space='4px' />
                      </div>
                    )}

                  {!!active.posttaxContribAllowed &&
                    (!!active.postTaxSavingsAmt || !!postTaxAnnualContribDollarsNEW) && (
                      <div>
                        <BarRowSub
                          label='After-tax Contribution'
                          percentageOld={active.posttaxSavingsRate}
                          amountOld={active.postTaxSavingsAmt}
                          percentageNew={postTaxAnnualContributionsNEW}
                          amountNew={postTaxAnnualContribDollarsNEW}
                        />
                      </div>
                    )}
                </div>
              )}

              {showCompanyMatch && (
                <div
                  css={css`
                    padding-top: 40px;
                  `}>
                  <div
                    css={css`
                      padding: 24px 0 18px;
                      color: #06293a;
                    `}>
                    COMPANY MATCH
                  </div>

                  <div
                    css={css`
                      display: flex;
                      align-items: baseline;
                    `}>
                    <div
                      css={css`
                        flex: 0 0 200px;
                        padding-right: 20px;
                        color: #022a3a;
                        font-size: 13pt;
                        font-weight: 600;
                      `}>
                      Maxing the match?
                    </div>
                    <div
                      css={css`
                        flex: 0 0 60px;
                        background-color: #e7f3f9;
                        width: 60px;
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        color: #022a3a;
                        font-size: 13pt;
                      `}>
                      {isMatchingCompanyMax ? 'Yes' : 'No'}
                    </div>
                    <div
                      css={css`
                        flex: 1 1 auto;
                        padding-left: 20px;
                        color: #022a3a;
                        font-size: 13pt;
                      `}>
                      {isMatchingCompanyMax
                        ? isRetail
                          ? 'Good job!'
                          : 'Good job! You are contributing enough to receive the maximum annual match.'
                        : `Consider increasing your contributions to at least ${store.active.companyMatchRateMax}% so that you can receive the maximum annual match.`}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        } else {
          return null
        }
      }
    }
  )
)

class HoldingPage1 extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'holding1' })
  }

  render() {
    const { store, guide } = this.props
    const { config, employerAccounts } = store
    const eligibleEmployerAccounts = employerAccounts.filter(acct => acct.contributionEligibility)

    // Must follow this order: 401k, 401a, 403b, 457, Keogh
    let employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 1) // 401k
    if (employerAccount.length === 0) {
      employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 23) // 401a
      if (employerAccount.length === 0) {
        employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 2) // 403b
        if (employerAccount.length === 0) {
          employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 4) // 457
          if (employerAccount.length === 0) {
            employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 6) // Keogh
          }
        }
      }
    }

    const currentEmployerAcct = employerAccount.length > 0 // if we have at least one account, it will be true
    const page = guide.guidePages.pages.findByName('holding1')
    const pageConfig = createPageConfig({ config, currentEmployerAcct, employerAccount })

    return (
      <Page header={<PageHeader text='Your Plan' />} page={page}>
        <ContentText>{pageConfig.introText}</ContentText>
        <ContributionSubSection
          pageConfig={pageConfig}
          employerAccount={employerAccount}
          currentEmployerAcct={currentEmployerAcct}
        />
      </Page>
    )
  }
}

export default inject('store', 'guide')(observer(HoldingPage1))
