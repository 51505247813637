import { API } from '../api'
import { types, flow, applySnapshot } from 'mobx-state-tree'
const { model, array, maybeNull, number, string } = types

const Dependent = model({
  birthDate: maybeNull(string),
  gender: maybeNull(string),
  id: maybeNull(number),
  name: maybeNull(string),
  participantId: maybeNull(number),
})

const DependentsStore = model({
  dependents: array(Dependent),
}).actions(self => ({
  getDependents: flow(function* () {
    const dependents = yield API.get('person/dependent')
    self.dependents = dependents.data
  }),
  addDependent: flow(function* ({ name, birthDate }) {
    const dependent = yield API.post('person/dependent', { name, birthDate })
    self.dependents.push(dependent.data)
  }),
  updateDependent: flow(function* ({ name, birthDate, id }) {
    const updated = yield API.put('person/dependent', { name, birthDate, id })
    applySnapshot(
      self.dependents.find(dependent => dependent.id === id),
      updated.data
    )
  }),
  deleteDependent: flow(function* ({ id }) {
    yield API.delete(`person/dependent/${id}`)
    self.dependents.remove(self.dependents.find(dependent => dependent.id === id))
  }),
}))

export default DependentsStore
