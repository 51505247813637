/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { useField } from 'react-final-form'

import { CircleNegative, CirclePlus } from '../../../../assets/icons'
import { SVGWrapper, SliderWithValueUnderneath, Spacer } from '../../../../components'
import { Page } from '../style'
import { numberToDollars } from '../../../../utils/utils'
import HelpIcon from '../../../../components/HelpIcon'
import TooltipText from '../../../../components/TooltipText'

function AddAnEmergencyFund(props) {
  const {
    store: { totalPrimaryBalanceExcludingPensionAndAnnuity, emergencyFund },
  } = props
  const [open, setOpen] = useState(false)
  return (
    <Page.ConsiderationWrapper>
      <Page.ConsiderationContainer onClick={() => setOpen(!open)}>
        <SVGWrapper
          css={css`
            margin-right: 10px;
          `}
          svg={open ? CircleNegative : CirclePlus}
          fill='#3A4D57'
          size='large'
        />
        <Page.ConsiderationLabel>Add an emergency fund</Page.ConsiderationLabel>
      </Page.ConsiderationContainer>
      {open && (
        <>
          <Spacer space='5px' />
          <Page.EmergencyAmountText>
            (amount taken from investment balance &nbsp;
            <HelpIcon size='medium' tooltip={TooltipText.emergencyFundHelp()} />)
          </Page.EmergencyAmountText>
          <Page.ConsiderationBody>
            <Page.SliderContainer
              css={css`
                margin: 20px 0;
                align-items: flex-start;
              `}>
              <Page.SliderLabel>
                <EmergencyFundInputField fieldName='emergencyFundInput' />
              </Page.SliderLabel>
              <Page.SliderValue>
                <EmergencyFundSliderField
                  totalPrimaryBalanceExcludingPensionAndAnnuity={
                    totalPrimaryBalanceExcludingPensionAndAnnuity
                  }
                  fieldName='emergencyFundSlider'
                  emergencyFund={emergencyFund.amount}
                />
              </Page.SliderValue>
            </Page.SliderContainer>
          </Page.ConsiderationBody>
        </>
      )}
    </Page.ConsiderationWrapper>
  )
}

export default inject('store', 'main')(observer(AddAnEmergencyFund))

const EmergencyFundSliderField = ({
  fieldName,
  totalPrimaryBalanceExcludingPensionAndAnnuity,
  emergencyFund,
}) => {
  const { input } = useField(fieldName, {
    subscription: { value: true, touched: true, error: true },
  })
  return (
    <SliderWithValueUnderneath
      min='0'
      max={totalPrimaryBalanceExcludingPensionAndAnnuity}
      value={input.value}
      onChange={input.onChange}
      step='100'
      frozenValue={emergencyFund}
      displayValue={false}
    />
  )
}

const EmergencyFundInputField = ({ fieldName }) => {
  const { input } = useField(fieldName, {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <Page.EmergencyFundInputFieldContainer>
      <Page.EmergencyInput {...input} value={numberToDollars(input.value, true)} />
    </Page.EmergencyFundInputFieldContainer>
  )
}
