/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'

import { Spacer } from '../../../components'
import { numberToDollars } from '../../../utils'
import { Page, PageHeader } from '../elements'
import { ContentMainHeader, ContentText, FlexContainer, StyledBar } from '../elements/styled'

const ChartMainLabel = styled.div`
  color: ${p => (p.old ? '#7a8e96' : '#038ab7')};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
`

const ChartSubLabel = styled.div`
  color: ${p => p.theme.darkestGray};
  text-align: center;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: ${p => (p.bold ? 500 : null)};
  font-size: ${p => (p.bold ? '1.25rem' : '1.125rem')};
  padding: 12px 0 8px 0;
`

const SubChartContainer = styled.div`
  min-width: 320px;
  max-width: 80%;
  padding: 8px 0 0 0;
  margin: 0 auto;
`

const TextHolder = styled.div`
  min-width: 200px;
`

class BarRow extends Component {
  render() {
    const { percentageOld, amountOld, percentageNew, amountNew } = this.props

    return (
      <FlexContainer justifyContent='center' childPadding='8px'>
        <StyledBar isGray>
          <TextHolder>
            {amountOld
              ? `${numberToDollars(percentageOld, true)}% | $${numberToDollars(
                  amountOld,
                  true
                )}/year`
              : '\u2014'}
          </TextHolder>
        </StyledBar>
        <StyledBar>
          <TextHolder>
            {amountNew
              ? `${numberToDollars(percentageNew, true)}% | $${numberToDollars(
                  amountNew,
                  true
                )}/year`
              : '\u2014'}
          </TextHolder>
        </StyledBar>
      </FlexContainer>
    )
  }
}

// The new design for BarRow
function BarRowNew(props) {
  const { label, percentageOld, amountOld, percentageNew, amountNew } = props

  return (
    <div
      css={css`
        display: flex;
        align-items: baseline;
      `}>
      <div
        css={css`
          flex: 0 0 200px;
          font-size: 13pt;
          color: #022a3a;
          font-weight: 600;
        `}>
        {label}
      </div>

      <div
        css={css`
          flex: 1 1 250px;
          text-align: center;
          padding: 0 6px;
        `}>
        <div
          css={css`
            background-color: #f3f4f5;
            color: #7a8e96;
            font-size: 15pt;
            height: 40pt;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          {amountOld
            ? `${numberToDollars(percentageOld, true)}% | $${numberToDollars(amountOld, true)}/year`
            : '\u2014'}
        </div>
      </div>

      <div
        css={css`
          flex: 1 1 250px;
          text-align: center;
          padding: 0 6px;
        `}>
        <div
          css={css`
            background-color: #e7f3f9;
            color: #038ab7;
            font-size: 15pt;
            height: 40pt;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          {amountNew
            ? `${numberToDollars(percentageNew, true)}% | $${numberToDollars(amountNew, true)}/year`
            : '\u2014'}
        </div>
      </div>
    </div>
  )
}

function BarRowSub(props) {
  const { label, percentageOld, amountOld, percentageNew, amountNew } = props

  return (
    <div
      css={css`
        display: flex;
        align-items: baseline;
      `}>
      <div
        css={css`
          flex: 0 0 200px;
          font-size: 10pt;
          color: #022a3a;
          font-weight: 600;
        `}>
        {label}
      </div>

      <div
        css={css`
          flex: 1 1 250px;
          text-align: center;
          padding: 0 6px;
        `}>
        <div
          css={css`
            background-color: #f3f4f5;
            color: #7a8e96;
            font-size: 10pt;
            height: 20pt;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          {amountOld
            ? `${numberToDollars(percentageOld, true)}% | $${numberToDollars(amountOld, true)}/year`
            : '\u2014'}
        </div>
      </div>

      <div
        css={css`
          flex: 1 1 250px;
          text-align: center;
          padding: 0 6px;
        `}>
        <div
          css={css`
            background-color: #e7f3f9;
            color: #038ab7;
            font-size: 10pt;
            height: 20pt;
            display: flex;
            align-items: center;
            justify-content: center;
          `}>
          {amountNew
            ? `${numberToDollars(percentageNew, true)}% | $${numberToDollars(amountNew, true)}/year`
            : '\u2014'}
        </div>
      </div>
    </div>
  )
}

class SavingPage1 extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'savings1' })
  }

  renderContributionText = () => {
    const { employerContrib, companyMatchRate, companyMatchRateMax } =
      this.props.store.includedSpouseEmployerAccount
    const { firstName } = this.props.store.spouse

    if (!employerContrib || !companyMatchRateMax) {
      return ''
    }

    const isMeetingMatchMax = companyMatchRate >= companyMatchRateMax
    const hasMaxMatchPercent = companyMatchRateMax > 0
    const maxMatchPercentString = hasMaxMatchPercent ? companyMatchRateMax + '%' : ''

    const meetingMatchMaxText = isMeetingMatchMax
      ? 'Good news— '
      : `Consider increasing account contributions to at least ${maxMatchPercentString} so that `

    const meetingMatchMaxText2 = `${firstName} is contributing enough to receive the maximum annual employer match.`

    return `${meetingMatchMaxText}${meetingMatchMaxText2}`
  }

  hideSavingsPage = () => {
    const { store } = this.props
    const { maritalStatus, includeSpouse } = store.person

    const { totalAnnualPercentageSpouse: totalAnnualPercentageSpouseOLD } = store.baseCase

    const { totalAnnualPercentageSpouse: totalAnnualPercentageSpouseNEW, outOfPlanMonthlySavings } =
      store.acceptedCase

    const {
      includedSpouseEmployerAccount: account,
      otherContributingAccountsWithoutClientSpouseEmployerBrokerage,
    } = store

    if (maritalStatus && includeSpouse) {
      return (
        !totalAnnualPercentageSpouseNEW &&
        !otherContributingAccountsWithoutClientSpouseEmployerBrokerage.length &&
        !outOfPlanMonthlySavings &&
        account &&
        !totalAnnualPercentageSpouseOLD
      )
    } else {
      return (
        !otherContributingAccountsWithoutClientSpouseEmployerBrokerage.length &&
        !outOfPlanMonthlySavings
      )
    }
  }

  maxMatchMessage = (totalContributionRate, companyMatchRateMax, spouseName) => {
    let message
    if (totalContributionRate >= companyMatchRateMax) {
      message = `Good job! ${spouseName} is contributing enough to receive the maximum annual match.`
    } else {
      message = `Consider increasing contributions to at least ${companyMatchRateMax}% so that ${spouseName} can receive the maximum annual match.`
    }
    return message
  }

  render() {
    const { store } = this.props

    const { maritalStatus, includeSpouse } = store.person
    const { firstName: spouseName } = store.spouse

    const { config, includedSpouseEmployerAccount: account } = store

    const {
      totalAnnualPercentageSpouse: totalAnnualPercentageSpouseOLD,
      totalAnnualSavingsSpouse: totalAnnualSavingsSpouseOLD,
      spouse: spouseOLD,
    } = store.baseCase

    const {
      totalAnnualPercentageSpouse: totalAnnualPercentageSpouseNEW,
      totalAnnualSavingsSpouse: totalAnnualSavingsSpouseNEW,
      spouse: spouseNEW,
    } = store.acceptedCase

    const preTaxAnnualContributionsOLD = spouseOLD ? spouseOLD.preTaxAnnualContributions : 0
    const preTaxAnnualContribDollarsOLD = spouseOLD ? spouseOLD.preTaxAnnualContribDollars : 0

    const postTaxAnnualContributionsOLD = spouseOLD ? spouseOLD.postTaxAnnualContributions : 0
    const postTaxAnnualContribDollarsOLD = spouseOLD ? spouseOLD.postTaxAnnualContribDollars : 0

    const rothAnnualContributionsOLD = spouseOLD ? spouseOLD.rothAnnualContributions : 0
    const rothAnnualContribDollarsOLD = spouseOLD ? spouseOLD.rothAnnualContribDollars : 0

    const preTaxAnnualContributionsNEW = spouseNEW ? spouseNEW.preTaxAnnualContributions : 0
    const preTaxAnnualContribDollarsNEW = spouseNEW ? spouseNEW.preTaxAnnualContribDollars : 0

    const postTaxAnnualContributionsNEW = spouseNEW ? spouseNEW.postTaxAnnualContributions : 0
    const postTaxAnnualContribDollarsNEW = spouseNEW ? spouseNEW.postTaxAnnualContribDollars : 0

    const rothAnnualContributionsNEW = spouseNEW ? spouseNEW.rothAnnualContributions : 0
    const rothAnnualContribDollarsNEW = spouseNEW ? spouseNEW.rothAnnualContribDollars : 0

    const page = this.props.guide.guidePages.pages.findByName('savings1')

    const hideSavingsPage = this.hideSavingsPage()

    // Florida Only
    if (!hideSavingsPage && (config.templateId === 1 || config.templateId === 2)) {
      return (
        <Page header={<PageHeader text='Other Investment Accounts' />} page={page}>
          <ContentMainHeader>SAVINGS</ContentMainHeader>
          <Spacer space='8px' />
          <ContentText>
            Keep up the good work! We know it can be tough to make that decision to save when you
            have other expenses and opportunities vying for your money. Balance is important, but
            your future self will be thankful for the sacrifices made today.
          </ContentText>
          <Spacer space='8px' />
          {maritalStatus && includeSpouse && account ? (
            <div>
              <ContentMainHeader>
                {`${spouseName}'s current employer plan account (${account.name}) contributions`}
              </ContentMainHeader>

              <Spacer space='8px' />

              {this.renderContributionText() && (
                <ContentText
                  css={css`
                    padding-bottom: 32px;
                  `}>
                  {this.renderContributionText()}
                </ContentText>
              )}

              <FlexContainer justifyContent='space-around'>
                <ChartMainLabel old>OLD CONTRIBUTION</ChartMainLabel>
                <ChartMainLabel>NEW CONTRIBUTION</ChartMainLabel>
              </FlexContainer>
              <Spacer space='4px' />
              <ChartSubLabel bold>
                {account.oneContributionOnly ? 'Annual pre-tax total' : 'Annual total'}
              </ChartSubLabel>
              <BarRow
                percentageOld={totalAnnualPercentageSpouseOLD}
                amountOld={totalAnnualSavingsSpouseOLD}
                percentageNew={totalAnnualPercentageSpouseNEW}
                amountNew={totalAnnualSavingsSpouseNEW}
              />
              {!account.oneContributionOnly && (
                <SubChartContainer>
                  {(!!preTaxAnnualContributionsOLD || !!preTaxAnnualContribDollarsNEW) && (
                    <div>
                      <ChartSubLabel>Pre-tax Contribution</ChartSubLabel>
                      <BarRow
                        percentageOld={preTaxAnnualContributionsOLD}
                        amountOld={preTaxAnnualContribDollarsOLD}
                        percentageNew={preTaxAnnualContributionsNEW}
                        amountNew={preTaxAnnualContribDollarsNEW}
                      />
                      <Spacer space='4px' />
                    </div>
                  )}

                  {!!account.rothContribAllowed &&
                    (!!rothAnnualContributionsOLD || !!rothAnnualContribDollarsNEW) && (
                      <div>
                        <ChartSubLabel>Roth Contribution</ChartSubLabel>
                        <BarRow
                          percentageOld={rothAnnualContributionsOLD}
                          amountOld={rothAnnualContribDollarsOLD}
                          percentageNew={rothAnnualContributionsNEW}
                          amountNew={rothAnnualContribDollarsNEW}
                        />
                      </div>
                    )}

                  {!!account.posttaxContribAllowed &&
                    (!!postTaxAnnualContributionsOLD || !!postTaxAnnualContribDollarsNEW) && (
                      <div>
                        <ChartSubLabel>After-tax Contribution</ChartSubLabel>
                        <BarRow
                          percentageOld={postTaxAnnualContributionsOLD}
                          amountOld={postTaxAnnualContribDollarsOLD}
                          percentageNew={postTaxAnnualContributionsNEW}
                          amountNew={postTaxAnnualContribDollarsNEW}
                        />
                        <Spacer space='4px' />
                      </div>
                    )}
                </SubChartContainer>
              )}
            </div>
          ) : null}
        </Page>
      )
    }

    // Migrating Institutional Clients
    if (!hideSavingsPage && config.templateId > 2 && config.templateId <= 11) {
      return (
        <Page header={<PageHeader text='Other Investment Accounts' />} page={page}>
          <ContentMainHeader>SAVINGS</ContentMainHeader>
          <Spacer space='8px' />
          <ContentText>
            Keep up the good work! We know it can be tough to make that decision to save when you
            have other expenses and opportunities vying for your money. Balance is important, but
            your future self will be thankful for the sacrifices made today.
          </ContentText>
          <Spacer space='8px' />
          {maritalStatus && includeSpouse && Boolean(account) && (
            <div>
              <div
                css={css`
                  font-family: Aleo;
                  color: #06293a;
                  font-size: 18pt;
                  padding-top: 42px;
                `}>
                {spouseName}'s current employer plan account ({account.name}) contributions
              </div>

              <div
                css={css`
                  padding: 24px 0 18px;
                  color: #06293a;
                `}>
                YOUR CONTRIBUTIONS
              </div>

              <div
                css={css`
                  display: flex;
                `}>
                <div
                  css={css`
                    flex: 0 0 200px;
                  `}
                />
                <ChartMainLabel
                  old
                  css={css`
                    flex: 1 1 250px;
                    text-align: center;
                  `}>
                  OLD CONTRIBUTION
                </ChartMainLabel>
                <ChartMainLabel
                  css={css`
                    flex: 1 1 250px;
                    text-align: center;
                  `}>
                  NEW CONTRIBUTION
                </ChartMainLabel>
              </div>

              <Spacer space='4px' />

              <BarRowNew
                label={account.oneContributionOnly ? 'Annual pre-tax total' : 'Annual total'}
                percentageOld={totalAnnualPercentageSpouseOLD}
                amountOld={totalAnnualSavingsSpouseOLD}
                percentageNew={totalAnnualPercentageSpouseNEW}
                amountNew={totalAnnualSavingsSpouseNEW}
              />

              <Spacer space='4px' />

              {!account.oneContributionOnly && (
                <div>
                  <div
                    css={css`
                      display: flex;
                    `}>
                    <div
                      css={css`
                        flex: 0 0 200px;
                      `}
                    />
                    <div
                      css={css`
                        flex: 1 1 250px;
                        text-align: center;
                        padding: 18px 0 12px;
                        color: #022a3a;
                        font-size: 13pt;
                        font-weight: 600;
                      `}>
                      Annual total includes:
                    </div>
                  </div>

                  {(!!preTaxAnnualContributionsOLD || !!preTaxAnnualContribDollarsNEW) && (
                    <div>
                      <BarRowSub
                        label='Pre-tax Contribution'
                        percentageOld={preTaxAnnualContributionsOLD}
                        amountOld={preTaxAnnualContribDollarsOLD}
                        percentageNew={preTaxAnnualContributionsNEW}
                        amountNew={preTaxAnnualContribDollarsNEW}
                      />
                      <Spacer space='4px' />
                    </div>
                  )}

                  {!!account.rothContribAllowed &&
                    (!!rothAnnualContributionsOLD || !!rothAnnualContribDollarsNEW) && (
                      <div>
                        <BarRowSub
                          label='Roth Contribution'
                          percentageOld={rothAnnualContributionsOLD}
                          amountOld={rothAnnualContribDollarsOLD}
                          percentageNew={rothAnnualContributionsNEW}
                          amountNew={rothAnnualContribDollarsNEW}
                        />
                        <Spacer space='4px' />
                      </div>
                    )}

                  {!!account.posttaxContribAllowed &&
                    (!!postTaxAnnualContributionsOLD || !!postTaxAnnualContribDollarsNEW) && (
                      <div>
                        <BarRowSub
                          label='After-tax Contribution'
                          percentageOld={postTaxAnnualContributionsOLD}
                          amountOld={postTaxAnnualContribDollarsOLD}
                          percentageNew={postTaxAnnualContributionsNEW}
                          amountNew={postTaxAnnualContribDollarsNEW}
                        />
                      </div>
                    )}
                </div>
              )}

              {account.employerContrib && account.companyMatchRateMax > 0 && (
                <div
                  css={css`
                    padding-top: 40px;
                  `}>
                  <div
                    css={css`
                      padding: 24px 0 18px;
                      color: #06293a;
                    `}>
                    COMPANY MATCH
                  </div>

                  <div
                    css={css`
                      display: flex;
                      align-items: baseline;
                    `}>
                    <div
                      css={css`
                        flex: 0 0 200px;
                        padding-right: 20px;
                        color: #022a3a;
                        font-size: 13pt;
                        font-weight: 600;
                      `}>
                      Maxing the match?
                    </div>
                    <div
                      css={css`
                        flex: 0 0 60px;
                        background-color: #e7f3f9;
                        width: 60px;
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        color: #022a3a;
                        font-size: 13pt;
                      `}>
                      {account.totalContributionRate >= account.companyMatchRateMax ? 'Yes' : 'No'}
                    </div>
                    <div
                      css={css`
                        flex: 1 1 auto;
                        padding-left: 20px;
                        color: #022a3a;
                        font-size: 13pt;
                      `}>
                      {this.maxMatchMessage(
                        account.totalContributionRate,
                        account.companyMatchRateMax,
                        spouseName
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Page>
      )
    }
    // Retail
    if (!hideSavingsPage && config.templateId === 20) {
      return (
        <Page header={<PageHeader text='Other Investment Accounts' />} page={page}>
          <ContentMainHeader>SAVINGS</ContentMainHeader>
          <Spacer space='8px' />
          <ContentText>
            Keep up the good work! We know it can be tough to make that decision to save when you
            have other expenses and opportunities vying for your money. Balance is important, but
            your future self will be thankful for the sacrifices made today.
          </ContentText>
          <Spacer space='8px' />
          {maritalStatus && includeSpouse && account ? (
            <div>
              <ContentMainHeader>
                {`${spouseName}'s retirement plan account (${account.name})`}
              </ContentMainHeader>

              <Spacer space='8px' />
              <ContentText
                css={css`
                  padding-bottom: 32px;
                  font-size: 12pt;
                `}>
                CONTRIBUTIONS
              </ContentText>

              <FlexContainer
                justifyContent='space-around'
                css={css`
                  margin-left: 200px;
                `}>
                <ChartMainLabel old>OLD CONTRIBUTION</ChartMainLabel>
                <ChartMainLabel>NEW CONTRIBUTION</ChartMainLabel>
              </FlexContainer>
              <Spacer space='4px' />
              <BarRowNew
                percentageOld={totalAnnualPercentageSpouseOLD}
                amountOld={totalAnnualSavingsSpouseOLD}
                percentageNew={totalAnnualPercentageSpouseNEW}
                amountNew={totalAnnualSavingsSpouseNEW}
                label={account.oneContributionOnly ? 'Annual pre-tax total' : 'Annual total'}
              />
              <Spacer space='10px' />
              <div
                css={css`
                  font-size: 13pt;
                  color: #022a3a;
                  font-weight: 600;
                  margin-right: 175px;
                  text-align: right;
                `}>
                Annual total includes:
              </div>
              {!account.oneContributionOnly && (
                <div>
                  {(!!preTaxAnnualContributionsOLD || !!preTaxAnnualContribDollarsNEW) && (
                    <div>
                      <Spacer space='4px' />
                      <BarRowSub
                        percentageOld={preTaxAnnualContributionsOLD}
                        amountOld={preTaxAnnualContribDollarsOLD}
                        percentageNew={preTaxAnnualContributionsNEW}
                        amountNew={preTaxAnnualContribDollarsNEW}
                        label={'Pre-tax Contribution'}
                      />
                      <Spacer space='4px' />
                    </div>
                  )}

                  {!!account.rothContribAllowed &&
                    (!!rothAnnualContributionsOLD || !!rothAnnualContribDollarsNEW) && (
                      <div>
                        <BarRowSub
                          percentageOld={rothAnnualContributionsOLD}
                          amountOld={rothAnnualContribDollarsOLD}
                          percentageNew={rothAnnualContributionsNEW}
                          amountNew={rothAnnualContribDollarsNEW}
                          label={'Roth Contribution'}
                        />
                        <Spacer space='4px' />
                      </div>
                    )}

                  {!!account.posttaxContribAllowed &&
                    (!!postTaxAnnualContributionsOLD || !!postTaxAnnualContribDollarsNEW) && (
                      <div>
                        <BarRowSub
                          percentageOld={postTaxAnnualContributionsOLD}
                          amountOld={postTaxAnnualContribDollarsOLD}
                          percentageNew={postTaxAnnualContributionsNEW}
                          amountNew={postTaxAnnualContribDollarsNEW}
                          label={'After-tax Contribution'}
                        />
                        <Spacer space='4px' />
                      </div>
                    )}
                </div>
              )}
            </div>
          ) : null}
          {maritalStatus &&
            includeSpouse &&
            account.employerContrib &&
            account.companyMatchRateMax > 0 && (
              <div
                css={css`
                  padding-top: 40px;
                `}>
                <div
                  css={css`
                    padding: 24px 0 18px;
                    color: #06293a;
                  `}>
                  COMPANY MATCH
                </div>

                <div
                  css={css`
                    display: flex;
                    align-items: baseline;
                  `}>
                  <div
                    css={css`
                      flex: 0 0 200px;
                      padding-right: 20px;
                      color: #022a3a;
                      font-size: 13pt;
                      font-weight: 600;
                    `}>
                    Maxing the match?
                  </div>
                  <div
                    css={css`
                      flex: 0 0 60px;
                      background-color: #e7f3f9;
                      width: 60px;
                      height: 52px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      color: #022a3a;
                      font-size: 13pt;
                    `}>
                    {account.totalContributionRate >= account.companyMatchRateMax ? 'Yes' : 'No'}
                  </div>
                  <div
                    css={css`
                      flex: 1 1 auto;
                      padding-left: 20px;
                      color: #022a3a;
                      font-size: 13pt;
                    `}>
                    {this.maxMatchMessage(
                      account.totalContributionRate,
                      account.companyMatchRateMax,
                      spouseName
                    )}
                  </div>
                </div>
              </div>
            )}
        </Page>
      )
    }

    return null
  }
}

export default inject('store', 'guide')(observer(SavingPage1))
