import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { Page } from '../elements'
import Logo from '../../../assets/images/GuidedChoice-logo.png'
import {
  Header,
  CoverTitle,
  CoverText,
  ContentsBox,
  ContentsTitle,
  ContentCard,
  LogoContainer,
} from './CoverPageStyle'
import { CoverPageConfig } from './CoverPageConfig'

class CoverPage extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'cover' })
  }

  render() {
    const {
      person: { fullDisplayName },
      config,
      baseCase,
      acceptedCase,
      otherIncludedAccounts,
      isSpouseContributingButExcludeAdvice,
    } = this.props.store

    const {
      date,
      guidePages: {
        pages: { findByName },
      },
    } = this.props.guide

    const page = findByName('cover')

    const {
      guideTitle,
      guideAuthor,
      includeFinancialGoalsText,
      includeTodoListText,
      includePersonalizedPlanText,
      includePersonalizedPlanEmployerText,
      includeClientPlanChangesText,
      includeOtherAccountsChangesText,
      includeComfortableRetirementText,
    } = CoverPageConfig({
      config,
      baseCase,
      acceptedCase,
      otherIncludedAccounts,
      isSpouseContributingButExcludeAdvice,
    })

    return (
      <Page
        header={
          <Header>
            <CoverTitle>
              {guideTitle}
              <br />
              by {guideAuthor}
            </CoverTitle>

            <CoverText>
              For: {fullDisplayName}
              <br />
              Created: {date}
            </CoverText>

            <ContentsBox>
              <ContentsTitle>WHAT'S INSIDE</ContentsTitle>

              {includeFinancialGoalsText && (
                <ContentCard>
                  <ContentCard.Title>{includeFinancialGoalsText}</ContentCard.Title>
                </ContentCard>
              )}

              {includeTodoListText && (
                <ContentCard>
                  <ContentCard.Title>{includeTodoListText}</ContentCard.Title>
                </ContentCard>
              )}

              {includePersonalizedPlanText && (
                <ContentCard>
                  <ContentCard.Title>
                    {includePersonalizedPlanText}
                    {includePersonalizedPlanEmployerText && ','}
                    {includePersonalizedPlanEmployerText && <br />}
                    {includePersonalizedPlanEmployerText &&
                      `including your retirement plan account with ${includePersonalizedPlanEmployerText}`}
                  </ContentCard.Title>

                  <div style={{ padding: 5 }} />

                  <ul>
                    {includeClientPlanChangesText && (
                      <ContentCard.Item>{includeClientPlanChangesText}</ContentCard.Item>
                    )}
                    {includeOtherAccountsChangesText && (
                      <ContentCard.Item>{includeOtherAccountsChangesText}</ContentCard.Item>
                    )}
                  </ul>
                </ContentCard>
              )}

              <LogoContainer>
                <img src={Logo} alt='Logo' />
              </LogoContainer>

              {includeComfortableRetirementText && (
                <div
                  css={css`
                    color: #022a3a;
                    font-size: 14pt;
                    line-height: 1.35;
                    margin-top: 28px;
                  `}>
                  {includeComfortableRetirementText}
                </div>
              )}
            </ContentsBox>
          </Header>
        }
        page={page}>
        <div />
      </Page>
    )
  }
}

export default inject('store', 'guide')(observer(CoverPage))
