const BulletBook = `
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"/>
            <path d="M10 10h30v30H10z"/>
            <g fill="#02769D" fill-rule="nonzero">
                <path d="M15.977 15h-.002a.936.936 0 0 0-.926.937v14.047c0 .515.418.935.932.936 2.166.006 5.794.46 8.297 3.097v-14.7a.902.902 0 0 0-.128-.475c-2.054-3.33-6.002-3.837-8.173-3.842zM34.95 29.984V15.937a.936.936 0 0 0-.925-.937h-.002c-2.17.005-6.118.511-8.173 3.842a.902.902 0 0 0-.128.475v14.7c2.503-2.637 6.132-3.092 8.297-3.097a.937.937 0 0 0 .932-.936z"/>
                <path d="M37.07 18.24h-.675v11.744a2.389 2.389 0 0 1-2.372 2.39c-1.837.005-4.866.367-7.011 2.41 3.71-.914 7.62-.32 9.85.192A.934.934 0 0 0 38 34.063V19.176a.935.935 0 0 0-.93-.936zM13.605 29.984V18.24h-.675c-.513 0-.93.42-.93.936v14.887a.934.934 0 0 0 1.138.913c2.23-.512 6.14-1.106 9.85-.191-2.145-2.044-5.174-2.406-7.01-2.41a2.388 2.388 0 0 1-2.373-2.391z"/>
            </g>
        </g>
    </svg>
`

export default BulletBook
