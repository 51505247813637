import { css } from '@emotion/react'
import { Provider, inject, observer } from 'mobx-react'
import posed, { PoseGroup } from 'react-pose'

import { Spacer, Button } from '../../../components'
import { Page } from './elements'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function WelcomeSpendingMode(props) {
  const { location, history } = props.props
  const handleSubmit = () => {
    history.push('/home')
  }

  return (
    <Provider>
      <PoseGroup animateOnMount>
        <RouteContainer key={location.key || location.pathname}>
          <Page.Container Container>
            <Page>
              <Page.Heading title='Welcome to your GuidedChoice account!' />
              <Page.Container>
                <Page.Text>
                  The key to getting started is to add information about your financial resources
                  related to retirement planning—such as work-related investment accounts and
                  IRAs—and plan for specific goals
                </Page.Text>
                <Spacer space='10px'></Spacer>
                <Page.Text>
                  If you don't yet have any accounts added, not all the numbers will make sense on
                  the page, which is your home page. Your action item is to add at least one
                  account. After that, it's up to you: get projected results based on this one
                  account, add other accounts, set financial goals, or do some scenario planning. No
                  matter what, we'll help you set a confident path for your retirement.
                </Page.Text>
                <Spacer space='10px'></Spacer>

                <div
                  css={css`
                    display: flex;
                    justify-content: end;
                    margin-top: 30px;
                  `}>
                  <Button
                    onClick={handleSubmit}
                    label='Next'
                    type='submit'
                    primary
                    style={{ width: 200 }}
                  />
                </div>
              </Page.Container>
            </Page>
          </Page.Container>
        </RouteContainer>
      </PoseGroup>
    </Provider>
  )
}

export default inject('store')(observer(WelcomeSpendingMode))
