/* eslint-disable import/no-anonymous-default-export */
const TRANSITIONS = {
  transition: 'transitionend',
  OTransition: 'oTransitionEnd',
  MozTransition: 'transitionend',
  WebkitTransition: 'webkitTransitionEnd',
}

function transitionEventNamesFor(elem) {
  return Object.keys(TRANSITIONS).reduce(
    (result, transition) =>
      !result && elem && elem.style[transition] !== undefined ? TRANSITIONS[transition] : result,
    null
  )
}

export default {
  getMousePosition(evt) {
    return {
      x: evt.pageX - (window.scrollX || window.pageXOffset),
      y: evt.pageY - (window.scrollY || window.pageYOffset),
    }
  },

  getTouchPosition(evt) {
    return {
      x: evt.touches[0].pageX - (window.scrollX || window.pageXOffset),
      y: evt.touches[0].pageY - (window.scrollY || window.pageYOffset),
    }
  },

  pauseEvent(evt) {
    evt.stopPropagation()
    evt.preventDefault()
  },

  addEventsToDocument(evtMap) {
    Object.keys(evtMap).forEach(key => {
      document.addEventListener(key, evtMap[key], false)
    })
  },

  removeEventsFromDocument(evtMap) {
    Object.keys(evtMap).forEach(key => {
      document.removeEventListener(key, evtMap[key], false)
    })
  },

  targetIsDescendant(evt, parent) {
    let node = evt.target
    while (node !== null) {
      if (node === parent) return true
      node = node.parentNode
    }
    return false
  },

  addEventListenerOnTransitionEnded(elem, fn) {
    const evtName = transitionEventNamesFor(elem)
    if (!evtName) return false
    elem.addEventListener(evtName, fn)
    return true
  },

  removeEventListenerOnTransitionEnded(elem, fn) {
    const evtName = transitionEventNamesFor(elem)
    if (!evtName) return false
    elem.removeEventListener(evtName, fn)
    return true
  },
}
