/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import styled from '@emotion/styled'
import * as yup from 'yup'
import _ from 'lodash'
import { FORM_ERROR } from 'final-form'
import Cookies from 'js-cookie'

import { TextInput, FormError } from '../../../components'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import Page from './style'
import CardField from '../../../components/CardField'

function EditPassword({
  handleCancel,
  store: { changePassword, config, retailInitiateChangePassword, retailFinalizeChangePassword },
  store,
}) {
  const [status, setStatus] = useState('')
  const [oneUpperCase, setOneUpperCase] = useState(false)
  const [oneLowerCase, setOneLowerCase] = useState(false)
  const [oneNumber, setOneNumber] = useState(false)
  const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false)
  const [minlength, setMinLength] = useState(false)

  const onSubmit = values => {
    return handlePasswordChange(values)
  }

  const handlePasswordChange = async values => {
    try {
      if (config.isRetail) {
        await retailInitiateChangePassword(values.password)
        await retailFinalizeChangePassword(values.newPassword)
      } else {
        await changePassword({
          password: values.password,
          newPassword: values.newPassword,
          _csrf: Cookies.get('XSRF-TOKEN'),
        })
      }
      setStatus('success')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const validate = values => {
    const errors = {}
    setOneUpperCase(false)
    setOneLowerCase(false)
    setOneNumber(false)
    setOneSpecialCharacter(false)
    setMinLength(false)

    if (values.newPassword && values.newPassword.length > 7) {
      setMinLength(true)
    }

    if (values.newPassword) {
      const result = values.newPassword.match(/[0-9]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneNumber(true)
      }
    }

    if (values.newPassword) {
      const result = values.newPassword.match(/[a-z]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneLowerCase(true)
      }
    }

    if (values.newPassword) {
      const result = values.newPassword.match(/[A-Z]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneUpperCase(true)
      }
    }

    if (values.newPassword) {
      const result = values.newPassword.match(/[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneSpecialCharacter(true)
      }
    }

    if (!oneUpperCase || !oneLowerCase || !oneNumber || !oneSpecialCharacter || !minlength) {
      errors.newPassword = 'Your password must meet all the above requirements'
    }

    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm password must match password'
    }

    return errors
  }
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      subscription={{ submitting: true, submitError: true }}
      render={({ handleSubmit, submitting, submitError }) => (
        <>
          {status === '' && (
            <div>
              <Page.Header>Change password</Page.Header>
              <FieldContainer>
                <CardField
                  label='Re-enter current password'
                  value={
                    <Value>
                      <OldPasswordField />
                    </Value>
                  }
                />
                <CardField
                  label='Enter new password'
                  value={
                    <Value>
                      <PasswordField />
                    </Value>
                  }
                />
                <CardField
                  label='Re-enter new password'
                  value={
                    <Value>
                      <ConfirmPasswordField />
                    </Value>
                  }
                />
              </FieldContainer>

              <ButtonsBox>
                <BackButton backgroundColor='#FFFFFF' onClick={handleCancel}>
                  Cancel
                </BackButton>
                <NextButton onClick={handleSubmit} disabled={submitting}>
                  Save
                </NextButton>
              </ButtonsBox>
              {submitError && <FormError err={submitError} />}
            </div>
          )}
          {status === 'success' && (
            <div>
              <Label>
                We received your request to change the password associated with your GuidedChoice
                account to this email address.
                <br />
                Your new password is now in effect. <br />
                <br />
                <br />
              </Label>
              <BackButton backgroundColor='#FFFFFF' onClick={handleCancel}>
                Got it
              </BackButton>
            </div>
          )}
        </>
      )}
    />
  )
}

export default inject('store')(observer(EditPassword))

const Label = styled.div`
  color: #7a8e96;
  font-size: 1rem;
  word-break: break-word;
`
const Value = styled.div`
  color: #051f2c;
  font-size: 1rem;
  word-break: break-word;
`
const FieldContainer = styled.div`
  margin-left: 125px;
`

function OldPasswordField() {
  const { input, meta } = useField('password', {
    validate: value =>
      yup
        .string()
        .required('Old password is required')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='password'
      error={meta.error}
      type='password'
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='Current password'
      showError={meta.touched}
      value={input.value}
      width='220px'
      fontSize='16px'
    />
  )
}

function PasswordField() {
  const { input, meta } = useField('newPassword', {
    validate: value =>
      yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(32, 'Password cannot exceed 32 characters')
        .matches(/[0-9]/g, 'Password must include at least one number')
        .matches(/[a-z]/g, 'Password must include at least one lower case letter')
        .matches(/[A-Z]/g, 'Password must include at least one upper case letter')
        .matches(
          /[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g,
          'Password must include at least one special character'
        )
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='newPassword'
      error={meta.error}
      type='password'
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='New password'
      showError={meta.touched}
      value={input.value}
      width='220px'
      fontSize='16px'
    />
  )
}

function ConfirmPasswordField() {
  const { input, meta } = useField('confirmPassword', {
    validate: value =>
      yup
        .string()
        .required('Confirm password is required')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='confirmPassword'
      error={meta.error}
      type='password'
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='Confirm password'
      showError={meta.touched}
      value={input.value}
      width='220px'
      fontSize='16px'
    />
  )
}
