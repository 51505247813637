const tools = `
  <svg
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill-rule="evenodd"
      class="icon-color"
    >
      <g id="-icons/tools">
        <g id="Group" transform="translate(1.500000, 0.500000)">
          <path
            d="M2.64196442,6.23403236 C1.53178657,5.70664439 0.764243362,
              4.57507854 0.764243362,3.26424336 C0.764243362,1.95340819
              1.53178657,0.821842333 2.64196442,0.294454359 L2.64196442,
              2.73367363 C2.64196442,3.28595838 3.08967967,3.73367363
              3.64196442,3.73367363 L4.45854602,3.73367363 C5.01083077,
              3.73367363 5.45854602,3.28595838 5.45854602,2.73367363
              L5.45854602,0.294454359 C6.56872387,0.821842333 7.33626708,
              1.95340819 7.33626708,3.26424336 C7.33626708,4.57507854
              6.56872387,5.70664439 5.45854602,6.23403236 L5.45854602,14
              C5.45854602,14.5522847 5.01083077,15 4.45854602,15 L3.64196442,
              15 C3.08967967,15 2.64196442,14.5522847 2.64196442,14
              L2.64196442,6.23403236 Z"
            id="Combined-Shape"
          >
          </path>
          <path
            d="M11.2125123,0.917092034 L9.09942215,0.917092034 L8.27512761,
              3.79174873 L8.27512761,4.67253416 L12.0305697,4.67253416
              L12.0305697,3.76999735 L11.2125123,0.917092034 Z M8.80569734,
              6.55025522 L11.5,6.55025522 L11.5,6.55025522 C12.0522847,
              6.55025522 12.5,6.99797047 12.5,7.55025522 L12.5,14 L12.5,14
              C12.5,14.5522847 12.0522847,15 11.5,15 L8.80569734,15 L8.80569734,
              15 C8.25341259,15 7.80569734,14.5522847 7.80569734,14 L7.80569734,
              7.55025522 C7.80569734,6.99797047 8.25341259,6.55025522
              8.80569734,6.55025522 L8.80569734,6.55025522 Z M8.74455788,
              8.42797628 L8.74455788,14.0611395 L9.68341841,14.0611395
              L9.68341841,8.42797628 L8.74455788,8.42797628 Z M10.6222789,
              8.42797628 L10.6222789,14.0611395 L11.5611395,14.0611395
              L11.5611395,8.42797628 L10.6222789,8.42797628 Z M9.21398814,
              4.67253416 L11.0917092,4.67253416 L11.0917092,6.55025522
              L9.21398814,6.55025522 L9.21398814,4.67253416 Z"
            id="Combined-Shape"
          >
          </path>
        </g>
      </g>
    </g>
  </svg>
`

export default tools
