import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer, Observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import styled from '@emotion/styled'

import {
  Button,
  Loading,
  ErrorPage,
  HelpPersonIcon,
  FillGauge,
  TooltipText,
  HelpIcon,
} from '../../components'
import { numberToDollars } from '../../utils'
import SimpleEditDrawer from './make-changes/SimpleEditDrawer'

const PrimaryButton = styled.div`
  width: 260px;
  border-radius: 4px;
  background: #038ab7;
  border: 1px solid #7a8e96;
  cursor: pointer;
  padding: 20px 0;
  margin: auto;
  &:hover {
    background: #03779e;
  }
  &:active {
    background: #026485;
  }
  & > div {
    color: #ffffff;
    text-align: center;
    font-size: 26px;
    user-select: none;
  }
`
const SecondaryButton = styled.div`
  width: 240px;
  border-radius: 4px;
  background: #4a606a;
  cursor: pointer;
  padding: 4px;
  margin-left: auto;
  &:hover {
    background: #42565e;
  }
  &:active {
    background: #324147;
  }
  & > div {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    user-select: none;
    padding: 8px 0;
  }
`

function EnrollmentForm(props) {
  return (
    <div>
      <div
        css={css`
          color: #038ab7;
          font-size: 50px;
          font-weight: 600;
          line-height: 1.35;
          text-align: center;
          margin: 85px 8px 20px;
        `}>
        Invest
      </div>
      <div
        css={css`
          color: #4a606a;
          font-size: 20px;
          line-height: 1.35;
          text-align: center;
          max-width: 800px;
          margin: auto;
        `}>
        Allow us to invest your account contributions using the personalized investment mix
        GuidedChoice has created just for you.
      </div>

      <div
        css={css`
          display: flex;
          justify-content: center;
          line-height: 1.35;
          margin: 20px 20px 20px 55px;
        `}>
        <PrimaryButton
          onClick={props.onClick}
          css={css`
            margin: 0 8px 0 0;
          `}>
          <div>Yes, invest for me*</div>
        </PrimaryButton>
        <div>
          <HelpIcon size='large' tooltip={TooltipText.yesInvestForMe()} />
        </div>
      </div>

      <div
        css={css`
          color: #7a8e96;
          font-size: 14px;
          line-height: 1.35;
          text-align: center;
          margin-bottom: 45px;
        `}>
        *An annual fee of ${numberToDollars(1.5)} per $1,000 GuidedChoice manages applies when you
        enroll in GuidedChoice Personalized Target Date+
      </div>
    </div>
  )
}

const ImplementChanges = inject('store')(observer(ImplementChangesForm))
function ImplementChangesForm(props) {
  const { modifiedCase } = props.store

  return (
    <div
      css={css`
        margin-top: 85px;
      `}>
      <div
        css={css`
          color: #4a606a;
          font-size: 20px;
          line-height: 1.35;
          text-align: center;
          max-width: 800px;
          margin: auto;
        `}>
        Since we're already managing your account, we'll implement any changes you make—and if they
        affect how you should be invested, we'll take care of that too.
      </div>

      <Form
        onSubmit={props.onClick}
        initialValues={{ modifiedCase }}
        render={({ handleSubmit }) => (
          <Field
            name='modifiedCase'
            render={({ input, meta }) => (
              <div
                css={css`
                  margin: 60px auto 30px;
                  line-height: 1.35;
                `}>
                <div
                  css={css`
                    color: #f15d7f;
                    font-size: 18px;
                    line-height: 1.35;
                    text-align: center;
                    margin: 12px 0;
                  `}>
                  {meta.touched && meta.error}
                </div>
                <PrimaryButton onClick={handleSubmit}>
                  <div>Implement changes</div>
                </PrimaryButton>
              </div>
            )}
          />
        )}
      />
    </div>
  )
}

function SimplifiedOverallStrategy(props) {
  const [state, setState] = useState('loading')
  const [drawerActive, setDrawer] = useState(false)

  async function fetchCase() {
    try {
      setState('loading')
      await Promise.all([props.store.getRecommendedCase(), props.store.getPrimaryEmployerConfig()])
      setState('done')
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  useEffect(() => {
    fetchCase()
  }, []) // eslint-disable-line

  function handleInvestButton() {
    const { history, store } = props
    const caseId =
      store.modifiedCase !== null ? store.modifiedCase.caseId : store.recommendedCase.caseId

    store.setAcceptedCaseId(caseId)

    history.push('/implement/enrollment-confirmed')
  }

  function handleImplementChangesButton() {
    const { history, store } = props
    const caseId =
      store.modifiedCase !== null ? store.modifiedCase.caseId : store.recommendedCase.caseId

    store.setAcceptedCaseId(caseId)

    history.push('/implement')
  }

  function handleFullPlanning() {
    const { history, store } = props
    const caseId =
      store.modifiedCase !== null ? store.modifiedCase.caseId : store.recommendedCase.caseId

    store.setAcceptedCaseId(caseId)

    if (store.account.managedAccount) {
      return history.push('/product-select')
    }

    history.push('/implement/enrollment-selection')
  }

  function handleCancel() {
    const { history } = props
    history.push('/cancel')
  }

  function render() {
    if (state === 'loading') {
      return (
        <div
          css={css`
            padding: 20px;
          `}>
          <Loading />
        </div>
      )
    }

    if (state === 'error') {
      return <ErrorPage />
    }

    const { account, person, config, recommendedCase, modifiedCase, institutionalAccount } =
      props.store

    const retirementAge = modifiedCase
      ? modifiedCase.primary.retAge
      : recommendedCase.primary.retAge

    const estimatedTotalMonthlyIncome = modifiedCase
      ? modifiedCase.estimatedTotalMonthlyIncome
      : recommendedCase.estimatedTotalMonthlyIncome

    const retirementIncomeGoal = modifiedCase
      ? modifiedCase.retirementIncomeGoal
      : recommendedCase.retirementIncomeGoal

    const totalAnnualPercentage = modifiedCase
      ? modifiedCase.totalAnnualPercentage
      : recommendedCase.totalAnnualPercentage

    const totalAnnualSavings = modifiedCase
      ? modifiedCase.totalAnnualSavings
      : recommendedCase.totalAnnualSavings

    const currentTotalBalance = modifiedCase
      ? modifiedCase.currentTotalBalance
      : recommendedCase.currentTotalBalance

    return (
      <div
        css={css`
          margin-bottom: 60px;
        `}>
        <div
          css={css`
            text-align: center;
            margin-top: 50px;
            margin-bottom: 60px;
          `}>
          <div
            css={css`
              color: #7a8e96;
              font-size: 1.25rem;
              font-weight: 300;
              line-height: 1.5;
            `}>
            Total saved
          </div>
          <div>
            <span
              css={css`
                color: #7a8e96;
                font-size: 50px;
                font-weight: 300;
                line-height: 1.35;
              `}>
              $
            </span>
            <span
              css={css`
                color: #4a606a;
                font-size: 50px;
                font-weight: 300;
                line-height: 1.35;
              `}>
              {numberToDollars(currentTotalBalance, true)}
            </span>
          </div>
        </div>

        <div
          css={css`
            color: #022a3a;
            line-height: 1.35;
            text-align: center;
            margin: 20px;
          `}>
          <div
            css={css`
              font-size: 30px;
            `}>
            {person.firstName}, at{' '}
            <span
              css={css`
                color: #038ab7;
                font-size: 50px;
                font-weight: 600;
              `}>
              age
            </span>{' '}
            <span
              css={css`
                font-size: 50px;
                font-weight: 600;
              `}>
              {retirementAge}
            </span>
            , you'll have
          </div>
          <div
            css={css`
              font-size: 30px;
            `}>
            <span
              css={css`
                color: #4d9927;
                font-size: 50px;
                font-weight: 600;
              `}>
              $
            </span>
            <span
              css={css`
                font-size: 50px;
                font-weight: 600;
              `}>
              {numberToDollars(estimatedTotalMonthlyIncome, true)}
            </span>{' '}
            to{' '}
            <span
              css={css`
                color: #4d9927;
                font-size: 45px;
                font-weight: 600;
              `}>
              spend each month
            </span>
          </div>
          <div
            css={css`
              width: 250px;
              margin: auto;
            `}>
            <HelpPersonIcon
              size='xLarge'
              helpLabel='Projected income and sources'
              tooltip={TooltipText.projectedIncomeAndSources(config)}
            />
          </div>
        </div>

        <div
          css={css`
            margin: 52px 0 90px;
            display: flex;
            flex-direction: column;
          `}>
          <div
            css={css`
              display: inline-flex;
              justify-content: center;
              color: #022a3a;
            `}>
            <div
              css={css`
                font-size: 24px;
              `}>
              This replaces
            </div>
            <div
              css={css`
                margin-left: 8px;
                margin-top: 4px;
                line-height: 1;
              `}>
              <HelpIcon size='mediumLarge' tooltip={TooltipText.replacementIncomePTDPlus()} />
            </div>
          </div>
          <div
            css={css`
              text-align: center;
              margin: 8px 0;
            `}>
            <FillGauge fill={retirementIncomeGoal} />
          </div>
        </div>

        <div
          css={css`
            color: #4a606a;
            font-size: 28px;
            font-weight: 600;
            line-height: 1.35;
            text-align: center;
          `}>
          How to get there
        </div>

        <div
          css={css`
            color: #038ab7;
            font-size: 50px;
            font-weight: 600;
            line-height: 1.35;
            text-align: center;
            margin: 8px;
          `}>
          Save
        </div>

        <div
          css={css`
            max-width: 600px;
            border: 2px solid #038ab7;
            background-color: #ffffff;
            margin: 8px auto;
            padding: 1rem;
          `}>
          <div
            css={css`
              color: #4a606a;
              font-size: 20px;
              font-weight: 600;
              line-height: 1.35;
              text-align: center;
            `}>
            {institutionalAccount.name}
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: baseline;
            `}>
            <div
              css={css`
                color: #4a606a;
                font-size: 20px;
                font-weight: 600;
                line-height: 1.35;
              `}>
              Annual total
            </div>
            <div
              css={css`
                color: #038ab7;
                font-size: 20px;
                line-height: 1.35;
                text-align: center;
                background-color: #e7f3f9;
                padding: 1rem;
                margin-top: 1rem;
                flex: 0 1 50%;
              `}>
              {numberToDollars(totalAnnualPercentage, true)}
              %&nbsp;&nbsp;|&nbsp;&nbsp;$
              {numberToDollars(totalAnnualSavings, true)}
            </div>
          </div>
          {recommendedCase.totalAnnualPercentage === 0 && recommendedCase.totalAnnualSavings === 0 && (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 1rem;
              `}>
              <div
                css={css`
                  margin-right: 0.5rem;
                  color: #7a8e96;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 1.35;
                  text-decoration: underline;
                `}>
                Why contribution rate recommendation is at 0%|$0
              </div>
              <HelpIcon tooltip={TooltipText.whyContributionRecIsZero()} />
            </div>
          )}
        </div>
        <div
          css={css`
            max-width: 600px;
            margin: auto;
            text-align: right;
          `}>
          <SecondaryButton onClick={() => setDrawer(true)}>
            <div>See details | make changes</div>
          </SecondaryButton>
        </div>

        {account.managedAccount ? (
          <ImplementChanges onClick={handleImplementChangesButton} />
        ) : (
          <EnrollmentForm onClick={handleInvestButton} />
        )}

        <div
          css={css`
            max-width: 1000px;
            margin: auto;
          `}>
          <div
            css={css`
              color: #022a3a;
              font-size: 24px;
              font-weight: 600;
              line-height: 1.35;
              margin: 40px 0 1rem;
            `}>
            Other options:
          </div>

          <div
            css={css`
              display: flex;
              align-items: baseline;
            `}>
            <div>
              <Button
                secondary
                width='150px'
                label='Full planning service'
                onClick={handleFullPlanning}
                css={css`
                  color: #7a8e96;
                  font-size: 14px;
                  height: 34px;
                `}
              />
            </div>
            <div
              css={css`
                color: #4a606a;
                font-size: 14px;
                line-height: 1.35;
                margin-left: 20px;
              `}>
              I want more comprehensive advice that takes into consideration additional retirement
              assets <HelpIcon size='mediumLarge' tooltip={TooltipText.fullPlanningService()} />
            </div>
          </div>

          <div
            css={css`
              display: flex;
              align-items: baseline;
            `}>
            <div
              css={css`
                color: #7a8e96;
                font-size: 14px;
              `}>
              <Button
                secondary
                width='150px'
                label='Cancel'
                onClick={handleCancel}
                css={css`
                  color: #7a8e96;
                  font-size: 14px;
                  height: 34px;
                `}
              />
            </div>
            <div
              css={css`
                color: #4a606a;
                font-size: 14px;
                line-height: 1.35;
                margin-left: 20px;
              `}>
              {account.managedAccount
                ? "I'm not interested in making changes at this time after all"
                : "I'm not interested in help—I'll select my own investments"}
            </div>
          </div>
        </div>

        <SimpleEditDrawer active={drawerActive} handleCancel={() => setDrawer(false)} />
      </div>
    )
  }

  return <Observer>{render}</Observer>
}

export default withRouter(inject('store')(SimplifiedOverallStrategy))
