import { Component } from 'react'
import styled from '@emotion/styled'
import { CurrencyText } from './'
import PropTypes from 'prop-types'

const Box = styled.div`
  display: flex;
  align-items: baseline;
  line-height: 1.25;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const Label = styled.div`
  text-align: right;
  flex: ${p => (p.type === 'profile' ? '1 1 400px' : '0 0 260px')};
  padding: 2px 20px;
  color: ${p => p.theme.cardFieldLabelColor};
  font-size: 1rem;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    text-align: left;
    padding: 2px 0;
  }
`

const Value = styled.div`
  flex: ${p => (p.type === 'profile' ? '1 1 400px' : p.subValue ? '0 0 200px' : '1 0 200px')};
  padding: 0 20px;
  color: ${p =>
    p.type === 'profile' ? p.theme.cardFieldValueColor : p.theme.cardFieldValueOtherColor};
  font-size: 1rem;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    padding: 0 0 2px;
  }
`

const SubValue = styled.div`
  flex: 0 0 200px;
  padding: 0 20px;
  color: ${p => p.theme.cardFieldValueOtherColor};
  font-size: 0.875rem;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    padding: 0 0 2px;
  }
`

class CardField extends Component {
  static propTypes = {
    isCurrency: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subValue: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  }

  render() {
    const { isCurrency, label, subValue, value, type } = this.props
    const val = isCurrency ? <CurrencyText decimalScale={2} value={value} /> : <span>{value}</span>
    return (
      <Box className={this.props.className}>
        <Label type={type}>{label}</Label>
        <Value type={type} subValue={subValue}>
          {val}
        </Value>
        {subValue && <SubValue>{subValue}</SubValue>}
      </Box>
    )
  }
}

export const CardFieldError = styled.div`
  color: #eb436c;
  padding: 1.5rem 3rem;
  line-height: 1.35;
  text-align: center;
`

export default CardField
