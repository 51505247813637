/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css, useTheme } from '@emotion/react'
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Field } from 'react-final-form'
import _ from 'lodash'

import { CircleNegative, CirclePlus } from '../../../../assets/icons'
import { SVGWrapper, SliderWithValueUnderneath, Spacer } from '../../../../components'
import { Page } from '../style'

function ChangeRetirementAge({ store: { person, spouse } }) {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  return (
    <Page.ConsiderationWrapper>
      <Page.ConsiderationContainer onClick={() => setOpen(!open)}>
        <SVGWrapper
          css={css`
            margin-right: 10px;
          `}
          svg={open ? CircleNegative : CirclePlus}
          fill={theme.circleIconColor}
          size='large'
        />
        <Page.ConsiderationLabel>Change retirement age</Page.ConsiderationLabel>
      </Page.ConsiderationContainer>
      {open && (
        <Page.ConsiderationBody
          css={css`
            display: flex;
            flex-direction: column;
          `}>
          <div>
            <Field
              name='clientRetirement'
              subscription={{ value: true, touched: true, error: true }}
              render={({ input, meta }) => (
                <Page.SliderContainer>
                  <Page.SliderLabel>{person.displayName}</Page.SliderLabel>
                  <Page.SliderValue>
                    <SliderWithValueUnderneath
                      min={20}
                      max={person.age + 50 > 99 ? '99' : (person.age + 50).toString()}
                      value={input.value}
                      onChange={input.onChange}
                      frozenValue={_.get(person, 'retirementAge', null)}
                    />
                  </Page.SliderValue>
                </Page.SliderContainer>
              )}
            />
            {!person.includeSpouse && <Spacer space='12px' />}
          </div>
          {person.includeSpouse && (
            <div
              css={css`
                margin: 25px 0;
              `}>
              <Spacer space='12px;' />
              <Field
                name='spouseRetirement'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <Page.SliderContainer>
                    <Page.SliderLabel>{spouse.firstName}</Page.SliderLabel>
                    <Page.SliderValue>
                      <SliderWithValueUnderneath
                        min={20}
                        max={spouse.age + 50 > 99 ? '99' : (spouse.age + 50).toString()}
                        value={input.value}
                        onChange={input.onChange}
                        frozenValue={_.get(spouse, 'retirementAge', null)}
                      />
                    </Page.SliderValue>
                  </Page.SliderContainer>
                )}
              />
            </div>
          )}
        </Page.ConsiderationBody>
      )}
    </Page.ConsiderationWrapper>
  )
}

export default inject('store')(observer(ChangeRetirementAge))
