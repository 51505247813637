import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Spacer, CurrencyText, HelpIcon, TooltipText } from '../../../../components'
import { GreyText, LightGreyText } from '../../styled'
import InvestmentGroupItem from './InvestmentGroupItem'

const amountWrapsAtWidth = 891
const sidebarWidth = 250

const breakpoint = p =>
  p.sidebarOpen ? `${amountWrapsAtWidth}px` : `${amountWrapsAtWidth - sidebarWidth}px`

const List = styled.div`
  width: ${p => (p.fullWidth ? '100%' : '100%')};
`

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: ${breakpoint}) {
    flex-direction: column;
  }
`

function WarningIcon(props) {
  return (
    <svg viewBox='0 0 24 24' width='1em' height='1em' {...props}>
      <title>warning</title>
      <g data-name='icn/warning' fill='#f48024'>
        <path d='M23.83 21.83L12.95.61a1 1 0 00-1.87 0L.15 21.92a1.31 1.31 0 000 1.22 1.17 1.17 0 00.93.86h21.86a1.12 1.12 0 001-.88 1.88 1.88 0 00-.11-1.29zm-20.52.22L12 4l9 18.07H3.31z' />
        <path
          data-name='!'
          d='M13 10.15h-2v4.14a10.67 10.67 0 00.09 1.29q.08.63.21 1.35h1.42q.13-.72.21-1.35a10.67 10.67 0 00.07-1.29v-4.14zm-2 8.94a1.1 1.1 0 00.07.43 1 1 0 00.21.33 1.08 1.08 0 00.33.23.91.91 0 00.4.09.9.9 0 00.38-.09 1 1 0 00.32-.23 1 1 0 00.21-.33 1.1 1.1 0 00.08-.44 1.15 1.15 0 00-.08-.43 1.11 1.11 0 00-.21-.34 1 1 0 00-.32-.23A.9.9 0 0012 18a.91.91 0 00-.4.09 1.08 1.08 0 00-.33.23 1.11 1.11 0 00-.21.34 1.15 1.15 0 00-.06.42z'
        />
      </g>
    </svg>
  )
}

function SplitBalanceWarning() {
  return (
    <span
      css={css`
        margin: 0 0.75rem;
        color: #f48024;
        font-size: 14px;
        font-weight: 300;
      `}>
      <WarningIcon /> Edit investments to split balances between pre-tax and other tax types{' '}
      <HelpIcon size='medium' tooltip={TooltipText.plaidSplitBalances()} />
    </span>
  )
}

const InvestmentGroup = ({
  accountId,
  balance,
  balanceLabel,
  canEdit,
  hideBalance,
  investments,
  title,
  toggleEdit,
  showSplitBalanceWarning,
  history,
  match,
}) => {
  const { type, accountType } = match.params
  const handleInvestmentClick = security => {
    history.push(`/accounts/add/${type}/${accountType}/${accountId}/investment`, {
      security: { ...security },
    })
  }
  return (
    <List fullWidth={!hideBalance}>
      {!!title && (
        <div>
          <TitleBar sidebarOpen={false}>
            <GreyText>
              <span>{title}</span> {showSplitBalanceWarning && <SplitBalanceWarning />}
            </GreyText>
            {!hideBalance && (
              <div>
                <LightGreyText>{balanceLabel + ' '}</LightGreyText>
                <GreyText>
                  <CurrencyText decimalScale={2} value={balance || 0} />
                </GreyText>
              </div>
            )}
          </TitleBar>
          <Spacer space='10px' />
        </div>
      )}

      {investments.map(investment => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => handleInvestmentClick(investment)}
          key={investment.id}>
          <InvestmentGroupItem {...{ accountId, canEdit, investment, toggleEdit }} />
          <Spacer space='6px' />
        </div>
      ))}
    </List>
  )
}
export default InvestmentGroup
