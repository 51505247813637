import variables from '../variables'
import colors from '../colors'
import reduceCSSCalc from 'reduce-css-calc'
import input from '../input/config'

const { unit } = variables

const autocomplete = {
  borderSize: reduceCSSCalc(`calc(${input.fieldHeight} / 7)`),
  colorPrimaryContrast: colors.rt.primaryContrast,
  colorPrimary: colors.rt.primary,
  overflowMaxHeight: '45vh',
  suggestionActiveBackground: colors.rt.grey200,
  suggestionPadding: unit,
  suggestionsBackground: colors.rt.white,
  valueMargin: `${reduceCSSCalc(`calc(${unit} * 0.25)`)} ${reduceCSSCalc(
    `calc(${unit} * 0.5)`
  )} ${reduceCSSCalc(`calc(${unit} * 0.25)`)} 0`,
}

export default autocomplete
