import { Component } from 'react'
import { inject, observer, Provider } from 'mobx-react'
import { types, flow, getEnv } from 'mobx-state-tree'

import { Spacer, Loading, Container, ErrorPage } from '../../components'
import OverallStrategyModule from './current-strategy/OverallStrategyModule'
import FastFactsModule from './fast-facts/FastFactsModule'
import ScenarioPlanningModule from './scenario-planning/ScenarioPlanningModule'

const { model, enumeration } = types

const OverallStrategyState = model({
  status: enumeration(['loading', 'done', 'error']),
  baseStatus: enumeration(['loading', 'done', 'error']),
  recommendedStatus: enumeration(['loading', 'done', 'error']),
  displayInflation: false,
})
  .views(self => ({
    get isBaseLoading() {
      return self.baseStatus !== 'done'
    },
    get isRecommendedLoading() {
      return self.recommendedStatus !== 'done'
    },
  }))
  .actions(self => ({
    getBaseCase: flow(function* () {
      const { getBaseCase } = getEnv(self).store
      yield getBaseCase()
      self.baseStatus = 'done'
    }),
    getRecommendedCase: flow(function* () {
      const { getRecommendedCase } = getEnv(self).store
      yield getRecommendedCase()
      self.recommendedStatus = 'done'
    }),
    afterCreate: flow(function* () {
      const {
        getSavedScenarios,
        getAccountInsight,
        getPrimaryEmployerConfig,
        getSpouseEmployerConfig,
      } = getEnv(self).store

      try {
        yield Promise.all([
          self.getBaseCase(),
          self.getRecommendedCase(),
          getSavedScenarios(),
          getAccountInsight(),
          getPrimaryEmployerConfig(),
          getSpouseEmployerConfig(),
        ])

        self.status = 'done'
      } catch (err) {
        console.error(err)
        self.status = 'error'
      }
    }),
    setInflation(name, value) {
      self.displayInflation = value
    },
  }))

class CompleteOverallStrategy extends Component {
  constructor(props) {
    super(props)
    const { store } = props
    this.state = OverallStrategyState.create(
      { status: 'loading', baseStatus: 'loading', recommendedStatus: 'loading' },
      { store }
    )
  }

  componentWillUnmount() {
    this.props.store.resetModifiedCase()
    this.props.main.setDrawer('')
  }

  render() {
    const { isBaseLoading, isRecommendedLoading, modifiedCase } = this.state

    if (this.state.status === 'error') {
      return <ErrorPage />
    }

    return (
      <Provider overallStrategy={this.state}>
        <Container padding='1rem' minWidth={modifiedCase ? '600px' : '325px'}>
          {isBaseLoading ? (
            <Loading />
          ) : (
            <OverallStrategyModule
              displayInflation={this.state.displayInflation}
              setInflation={this.state.setInflation}
            />
          )}

          <Spacer space='24px' />

          {this.props.store.features.enableFastFacts && !isBaseLoading && <FastFactsModule />}

          {this.props.store.features.enableFastFacts && !isBaseLoading && <Spacer space='24px' />}

          {isBaseLoading || isRecommendedLoading ? null : (
            <ScenarioPlanningModule displayInflation={this.state.displayInflation} />
          )}

          <Spacer space='24px' />
        </Container>
      </Provider>
    )
  }
}

export default inject('store', 'main')(observer(CompleteOverallStrategy))
