import { useRef, useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'
import { numberToDollars } from '../../../utils/utils'

function AssumptionsFirstPage(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
      investmentWithdrawals,
    },
    store: {
      spendingAcceptedCase,
      person,
      spouse,
      primaryRetirementStatus,
      primarySSIBenefits,
      spouseSSIBenefits,
      allPensions,
      accounts,
      selectedInstitutionalPension,
      config: { template },
      currentGoals,
    },
  } = props
  const [pensionDisclaimers, setPensionDisclaimers] = useState([])

  useEffect(() => {
    setPensionDisclaimers(
      template?.disclaimer?.filter(disclaimer => {
        const now = new Date()
        const lessThan = new Date(disclaimer.lessThan)
        const greaterThan = new Date(disclaimer.greaterThan)
        return disclaimer.module === 'PENSION' && now < lessThan && now > greaterThan
      })
    )
  }, [template.disclaimer])

  const totalRemaining = goal => {
    let sum = 0
    goal.disbursements.map(disbursement => {
      if (!disbursement.paid) {
        sum += disbursement.amount
      }
      return sum
    })
    return sum
  }

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'assumptionsFirstPage' })
    willMount.current = false
  }

  const allMonthlyPensions = allPensions
    .filter(pension => pension.paymentType === 'M')
    .filter(pension => pension.accountId === null)
  const pensionsFromAccounts = accounts.filter(account => account.type === 'Pension')

  const availableMonthlyTypesOfPensions = allMonthlyPensions.filter(pension => {
    return pensionsFromAccounts.filter(monthlyPension => {
      const include = pension.id === monthlyPension.id
      return include
    })
  })

  let primaryInstitutionalPension = allPensions.filter(
    pension => pension.id === selectedInstitutionalPension.pensionId
  )[0]
  if (primaryInstitutionalPension === undefined) {
    primaryInstitutionalPension = {}
  }

  const page = findByName('assumptionsFirstPage')
  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.AssumptionsFirstPageTitle>The fine print</Wrapper.AssumptionsFirstPageTitle>
        <Spacer space='10pt' />
        <Wrapper.AssumptionsFirstTitle>Assumptions</Wrapper.AssumptionsFirstTitle>
        <Spacer space='5pt' />
        <Wrapper.AssumptionsFirstText>
          This Personalized Retirement Income Guide
          {_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) &&
          _.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome)
            ? ':'
            : ' '}
          {(!_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) ||
            !_.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome)) &&
            'with Annuity assumes:'}
        </Wrapper.AssumptionsFirstText>
        <Spacer space='5pt' />
        <ul>
          <Wrapper.AssumptionsFirstListItem>
            You have identified all your significant retirement assets through the GuidedChoice
            service, and that you've followed GuidedChoice's advice.
            <Wrapper.AssumptionsFirstListItemItalic>
              Note: Included in this guide are the recommended investment allocations for each of
              your investment accounts. Your withdrawal advice will not be useable unless the
              recommended changes are made.
            </Wrapper.AssumptionsFirstListItemItalic>
          </Wrapper.AssumptionsFirstListItem>
        </ul>

        <ul>
          <Wrapper.AssumptionsFirstListItem>
            We use a combined federal and state tax estimate on withdrawals of{' '}
            {numberToDollars(
              _.get(spendingAcceptedCase, 'averageMarketSpendingAdvice.firstYearTaxRate', 0) * 100,
              false
            )}
            %. We suggest double checking this rate with your tax advisor.
          </Wrapper.AssumptionsFirstListItem>
        </ul>

        <ul>
          <Wrapper.AssumptionsFirstListItem>
            Retirement age
            <ul>
              <Wrapper.AssumptionsFirstSubListItem>
                {person.displayName}:{' '}
                <Wrapper.AssumptionsFirstAge>
                  {spendingAcceptedCase.averageMarketSpendingAdvice.primary.retAge}
                </Wrapper.AssumptionsFirstAge>
              </Wrapper.AssumptionsFirstSubListItem>
              {person.maritalStatus && person.includeSpouse && (
                <Wrapper.AssumptionsFirstSubListItem>
                  {spouse.firstName}:{' '}
                  <Wrapper.AssumptionsFirstAge>
                    {spendingAcceptedCase.averageMarketSpendingAdvice.spouse.retAge}
                  </Wrapper.AssumptionsFirstAge>
                </Wrapper.AssumptionsFirstSubListItem>
              )}
            </ul>
          </Wrapper.AssumptionsFirstListItem>
        </ul>

        <ul>
          <Wrapper.AssumptionsFirstListItem>
            Desired after-tax retirement income (Need + Want):{' '}
            <Wrapper.AssumptionsFirstAmount>
              {numberToDollars(
                spendingAcceptedCase.spendingScenario?.budget?.dollarAmountDesired ?? 0,
                true
              )}
            </Wrapper.AssumptionsFirstAmount>
          </Wrapper.AssumptionsFirstListItem>
        </ul>

        <ul>
          <Wrapper.AssumptionsFirstListItem>
            Life expectancy age GuidedChoice planned to
            <ul>
              <Wrapper.AssumptionsFirstSubListItem>
                {person.displayName}:{' '}
                <Wrapper.AssumptionsFirstAge>
                  {person.includeSpouse
                    ? spendingAcceptedCase.averageMarketSpendingAdvice.primary.lifeExpectancyYear >=
                      spendingAcceptedCase.averageMarketSpendingAdvice.spouse.lifeExpectancyYear
                      ? spendingAcceptedCase.averageMarketSpendingAdvice.primary.lifeExpectancyAge +
                        10
                      : spendingAcceptedCase.averageMarketSpendingAdvice.primary.lifeExpectancyAge
                    : spendingAcceptedCase.averageMarketSpendingAdvice.primary.lifeExpectancyAge +
                      10}
                </Wrapper.AssumptionsFirstAge>
              </Wrapper.AssumptionsFirstSubListItem>
              {person.maritalStatus && person.includeSpouse && (
                <Wrapper.AssumptionsFirstSubListItem>
                  {spouse.firstName}:{' '}
                  <Wrapper.AssumptionsFirstAge>
                    {!(
                      spendingAcceptedCase.averageMarketSpendingAdvice.primary.lifeExpectancyYear >=
                      spendingAcceptedCase.averageMarketSpendingAdvice.spouse.lifeExpectancyYear
                    )
                      ? spendingAcceptedCase.averageMarketSpendingAdvice.spouse.lifeExpectancyAge +
                        10
                      : spendingAcceptedCase.averageMarketSpendingAdvice.spouse.lifeExpectancyAge}
                  </Wrapper.AssumptionsFirstAge>
                </Wrapper.AssumptionsFirstSubListItem>
              )}
            </ul>
          </Wrapper.AssumptionsFirstListItem>
        </ul>

        <ul>
          {(primaryRetirementStatus.planningWorkPartTime === 1 ||
            (person.includeSpouse &&
              props.store.spouseRetirementStatus.planningWorkPartTime === 1)) && (
            <Wrapper.AssumptionsFirstListItem>
              You've included annual income from an in-retirement job
              <ul>
                {primaryRetirementStatus.planningWorkPartTime === 1 && (
                  <Wrapper.AssumptionsFirstSubListItem>
                    {person.displayName}: annual payments of{' '}
                    <Wrapper.AssumptionsFirstAmount>
                      {numberToDollars(primaryRetirementStatus.annualIncome, true)}
                    </Wrapper.AssumptionsFirstAmount>{' '}
                    from ages{' '}
                    <Wrapper.AssumptionsFirstAge>
                      {primaryRetirementStatus.durationStartingAge}
                    </Wrapper.AssumptionsFirstAge>
                    -
                    <Wrapper.AssumptionsFirstAge>
                      {primaryRetirementStatus.durationStoppingAge}
                    </Wrapper.AssumptionsFirstAge>
                  </Wrapper.AssumptionsFirstSubListItem>
                )}
                {person.maritalStatus &&
                  person.includeSpouse &&
                  props.store.spouseRetirementStatus.planningWorkPartTime === 1 && (
                    <Wrapper.AssumptionsFirstSubListItem>
                      {spouse.firstName}: annual payments of{' '}
                      <Wrapper.AssumptionsFirstAmount>
                        {numberToDollars(props.store.spouseRetirementStatus.annualIncome, true)}
                      </Wrapper.AssumptionsFirstAmount>{' '}
                      from ages{' '}
                      <Wrapper.AssumptionsFirstAge>
                        {props.store.spouseRetirementStatus.durationStartingAge}
                      </Wrapper.AssumptionsFirstAge>
                      -
                      <Wrapper.AssumptionsFirstAge>
                        {props.store.spouseRetirementStatus.durationStoppingAge}
                      </Wrapper.AssumptionsFirstAge>
                    </Wrapper.AssumptionsFirstSubListItem>
                  )}
              </ul>
            </Wrapper.AssumptionsFirstListItem>
          )}
        </ul>

        <ul>
          {(primarySSIBenefits.includeSsi ||
            (person.includeSpouse && spouseSSIBenefits.includeSsi)) && (
            <Wrapper.AssumptionsFirstListItem>
              Social Security status
              <ul>
                {primarySSIBenefits.includeSsi && (
                  <Wrapper.AssumptionsFirstSubListItem>
                    {person.displayName} (
                    <Wrapper.AssumptionsFirstAge>
                      {primarySSIBenefits.useGcEstimate === 1
                        ? 'Using GuidedChoice estimates'
                        : 'Using own values'}
                    </Wrapper.AssumptionsFirstAge>
                    ):
                    <Wrapper.AssumptionsFirstIndentedText>
                      monthly payments of{' '}
                      <Wrapper.AssumptionsFirstAmount>
                        {numberToDollars(primarySSIBenefits.monthlyAmount, true)}
                      </Wrapper.AssumptionsFirstAmount>{' '}
                      beginning at age{' '}
                      <Wrapper.AssumptionsFirstAge>
                        {primarySSIBenefits.paymentBeginAge}
                      </Wrapper.AssumptionsFirstAge>
                    </Wrapper.AssumptionsFirstIndentedText>
                  </Wrapper.AssumptionsFirstSubListItem>
                )}

                {person.maritalStatus && person.includeSpouse && spouseSSIBenefits.includeSsi && (
                  <Wrapper.AssumptionsFirstSubListItem>
                    {spouse.firstName} (
                    <Wrapper.AssumptionsFirstAge>
                      {spouseSSIBenefits.useGcEstimate === 1
                        ? 'Using GuidedChoice estimates'
                        : 'Using own values'}
                    </Wrapper.AssumptionsFirstAge>
                    ):
                    <Wrapper.AssumptionsFirstIndentedText>
                      monthly payments of{' '}
                      <Wrapper.AssumptionsFirstAmount>
                        {numberToDollars(spouseSSIBenefits.monthlyAmount, true)}
                      </Wrapper.AssumptionsFirstAmount>{' '}
                      beginning at age{' '}
                      <Wrapper.AssumptionsFirstAge>
                        {spouseSSIBenefits.paymentBeginAge}
                      </Wrapper.AssumptionsFirstAge>
                    </Wrapper.AssumptionsFirstIndentedText>
                  </Wrapper.AssumptionsFirstSubListItem>
                )}
              </ul>
            </Wrapper.AssumptionsFirstListItem>
          )}
        </ul>

        <ul>
          {(!_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) ||
            (person.includeSpouse &&
              !_.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome))) && (
            <Wrapper.AssumptionsFirstListItem>
              You've modeled the purchase of a fixed deferred annuity
              <ul>
                {/* handles primaryLifetimeInvestmentIncome === null but person.include === true and spouseLifetimeInvestmentIncome === true */}
                {!_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) && (
                  <Wrapper.AssumptionsFirstSubListItem>
                    {person.displayName}: monthly payments of{' '}
                    <Wrapper.AssumptionsFirstAmount>
                      {numberToDollars(
                        investmentWithdrawals.primaryLifetimeInvestmentIncome.monthlyAmount,
                        true
                      )}
                    </Wrapper.AssumptionsFirstAmount>{' '}
                    beginning at age{' '}
                    <Wrapper.AssumptionsFirstAge>
                      {investmentWithdrawals.primaryLifetimeInvestmentIncome.startPaymentAge}
                    </Wrapper.AssumptionsFirstAge>
                  </Wrapper.AssumptionsFirstSubListItem>
                )}
                {/* handles primaryLifetimeInvestmentIncome === true but person.includeSpouse or spouseLifetimeInvestmentIncome are not */}
                {person.maritalStatus &&
                  person.includeSpouse &&
                  !_.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome) && (
                    <Wrapper.AssumptionsFirstSubListItem>
                      {spouse.firstName}: monthly payments of{' '}
                      <Wrapper.AssumptionsFirstAmount>
                        {numberToDollars(
                          investmentWithdrawals.spouseLifetimeInvestmentIncome.monthlyAmount,
                          true
                        )}
                      </Wrapper.AssumptionsFirstAmount>{' '}
                      beginning at age{' '}
                      <Wrapper.AssumptionsFirstAge>
                        {investmentWithdrawals.spouseLifetimeInvestmentIncome.startPaymentAge}
                      </Wrapper.AssumptionsFirstAge>
                    </Wrapper.AssumptionsFirstSubListItem>
                  )}
              </ul>
            </Wrapper.AssumptionsFirstListItem>
          )}
        </ul>

        {(availableMonthlyTypesOfPensions.length > 0 ||
          primaryInstitutionalPension.pensionName) && (
          <ul>
            <Wrapper.AssumptionsFirstListItem>
              You've included monthly income from pension
              <ul>
                {primaryInstitutionalPension.pensionName && (
                  <>
                    <Wrapper.AssumptionsFirstSubListItem>
                      {primaryInstitutionalPension.ownerId === person.id
                        ? person.displayName
                        : spouse.firstName}
                      : monthly payment of{' '}
                      <Wrapper.AssumptionsFirstAmount>
                        {numberToDollars(primaryInstitutionalPension.monthlyPensionIncome, true)}
                      </Wrapper.AssumptionsFirstAmount>{' '}
                      beginning at age{' '}
                      <Wrapper.AssumptionsFirstAge>
                        {primaryInstitutionalPension.pensionStartAge}
                      </Wrapper.AssumptionsFirstAge>
                    </Wrapper.AssumptionsFirstSubListItem>
                    {pensionDisclaimers
                      ?.filter(
                        disclaimer =>
                          disclaimer.objectName.toLowerCase() ===
                          primaryInstitutionalPension.pensionName.toLowerCase()
                      )
                      ?.map(disclaimer => (
                        <Wrapper.AssumptionsFirstSubListItem
                          dangerouslySetInnerHTML={{ __html: disclaimer.textToDisplay }}
                        />
                      ))}
                  </>
                )}
                {availableMonthlyTypesOfPensions.map(pension => {
                  return (
                    <>
                      <Wrapper.AssumptionsFirstSubListItem key={pension.id}>
                        {pension.ownerId === person.id ? person.displayName : spouse.firstName}:
                        monthly payment of{' '}
                        <Wrapper.AssumptionsFirstAmount>
                          {numberToDollars(pension.monthlyPensionIncome, true)}
                        </Wrapper.AssumptionsFirstAmount>{' '}
                        beginning at age{' '}
                        <Wrapper.AssumptionsFirstAge>
                          {pension.pensionStartAge}
                        </Wrapper.AssumptionsFirstAge>
                      </Wrapper.AssumptionsFirstSubListItem>
                      {pensionDisclaimers
                        ?.filter(
                          disclaimer =>
                            disclaimer.objectName.toLowerCase() ===
                            pension.pensionName.toLowerCase()
                        )
                        ?.map(disclaimer => (
                          <Wrapper.AssumptionsFirstSubListItem
                            dangerouslySetInnerHTML={{ __html: disclaimer.textToDisplay }}
                          />
                        ))}
                    </>
                  )
                })}
              </ul>
            </Wrapper.AssumptionsFirstListItem>
          </ul>
        )}

        <ul>
          {allPensions.filter(pension => pension.paymentType === 'L').length > 0 && (
            <Wrapper.AssumptionsFirstListItem>
              A lump sum pension payout is expected that will be rolled into a tax-advantaged
              account for retirement income purposes
              <ul>
                {allPensions
                  .filter(pension => pension.paymentType === 'L')
                  .map(pension => {
                    return (
                      <>
                        <Wrapper.AssumptionsFirstSubListItem key={pension.id}>
                          {pension.pensionName}:{' '}
                          <Wrapper.AssumptionsFirstAmount>
                            {numberToDollars(pension.monthlyPensionIncome, true)}
                          </Wrapper.AssumptionsFirstAmount>{' '}
                          at age{' '}
                          <Wrapper.AssumptionsFirstAge>
                            {pension.pensionStartAge}
                          </Wrapper.AssumptionsFirstAge>
                        </Wrapper.AssumptionsFirstSubListItem>
                        {pensionDisclaimers
                          ?.filter(
                            disclaimer =>
                              disclaimer.objectName.toLowerCase() ===
                              pension.pensionName.toLowerCase()
                          )
                          ?.map(disclaimer => (
                            <Wrapper.AssumptionsFirstSubListItem
                              dangerouslySetInnerHTML={{ __html: disclaimer.textToDisplay }}
                            />
                          ))}
                      </>
                    )
                  })}
              </ul>
            </Wrapper.AssumptionsFirstListItem>
          )}
        </ul>

        <ul>
          {(_.get(spendingAcceptedCase, 'spendingScenario.emergencyFund', 0) > 0 ||
            _.get(
              spendingAcceptedCase,
              'spendingScenario.oneTimeExpense.totalEndOfLifeExpense',
              0
            ) > 0 ||
            _.get(spendingAcceptedCase, 'spendingScenario.oneTimeExpense.totalBequestExpense', 0) >
              0) && (
            <Wrapper.AssumptionsFirstListItem>
              You want to reserve
              <ul>
                {_.get(spendingAcceptedCase, 'spendingScenario.emergencyFund', 0) > 0 && (
                  <Wrapper.AssumptionsFirstSubListItem>
                    Emergency fund*:{' '}
                    <Wrapper.AssumptionsFirstAmount>
                      {numberToDollars(spendingAcceptedCase.spendingScenario.emergencyFund, true)}
                    </Wrapper.AssumptionsFirstAmount>
                  </Wrapper.AssumptionsFirstSubListItem>
                )}
                {_.get(
                  spendingAcceptedCase,
                  'spendingScenario.oneTimeExpense.totalEndOfLifeExpense',
                  0
                ) > 0 && (
                  <Wrapper.AssumptionsFirstSubListItem>
                    Burial expenses:{' '}
                    <Wrapper.AssumptionsFirstAmount>
                      {numberToDollars(
                        spendingAcceptedCase.spendingScenario.oneTimeExpense.totalEndOfLifeExpense,
                        true
                      )}
                    </Wrapper.AssumptionsFirstAmount>
                  </Wrapper.AssumptionsFirstSubListItem>
                )}
                {_.get(
                  spendingAcceptedCase,
                  'spendingScenario.oneTimeExpense.totalBequestExpense',
                  0
                ) > 0 && (
                  <Wrapper.AssumptionsFirstSubListItem>
                    Bequest:{' '}
                    <Wrapper.AssumptionsFirstAmount>
                      {numberToDollars(
                        spendingAcceptedCase.spendingScenario.oneTimeExpense.totalBequestExpense,
                        true
                      )}
                    </Wrapper.AssumptionsFirstAmount>
                  </Wrapper.AssumptionsFirstSubListItem>
                )}
                {_.get(spendingAcceptedCase, 'spendingScenario.emergencyFund', 0) > 0 && (
                  <>
                    <Spacer space='10px' />
                    <Wrapper.AssumptionsFirstSubListItem style={{ listStyleType: 'none' }}>
                      *We treat your emergency fund amount as reserves within your retirement funds,
                      through the end of your planning period, without changing the allocation. This
                      is the same way we treat bequests. Should you require the emergency fund at
                      any time during your retirement, return to the GuidedChoice service and enter
                      the amount you need as a goal payable this calendar year. Your new guide will
                      contain withdrawal instructions that include the amount you need immediately.
                    </Wrapper.AssumptionsFirstSubListItem>
                  </>
                )}
              </ul>
            </Wrapper.AssumptionsFirstListItem>
          )}
        </ul>

        {currentGoals.length > 0 && (
          <ul>
            <Wrapper.AssumptionsFirstListItem>
              You're planning on using money from your retirement accounts to fund the following
              goal(s)
              <ul>
                {currentGoals.map(goal => {
                  return (
                    <Wrapper.AssumptionsFirstSubListItem key={goal.name}>
                      {goal.name}:{' '}
                      {goal.disbursements.length > 1
                        ? `${goal.disbursements[0].startDate.substring(0, 4)}-${goal.disbursements[
                            goal.disbursements.length - 1
                          ].endDate.substring(0, 4)};`
                        : `${goal.disbursements[0].startDate.substring(0, 4)};`}{' '}
                      total future amount needed:{' '}
                      <Wrapper.AssumptionsFirstAmount>
                        {numberToDollars(totalRemaining(goal), true)}
                      </Wrapper.AssumptionsFirstAmount>
                    </Wrapper.AssumptionsFirstSubListItem>
                  )
                })}
              </ul>
            </Wrapper.AssumptionsFirstListItem>
          </ul>
        )}

        {
          // only show this section for Atmos
          template.recordKeeperId === 3014 ? (
            _.get(spendingAcceptedCase, 'companyStocks', []).reduce(
              (acc, current) => acc + current.percentToSell,
              0
            ) > 0 ? (
              <ul>
                <Wrapper.AssumptionsFirstListItem>
                  {' '}
                  Your plan included the decision to sell{' '}
                  {_.get(spendingAcceptedCase, 'companyStocks', []).reduce(
                    (acc, current) => acc + current.percentToSell,
                    0
                  )}
                  % of your{' '}
                  {_.get(spendingAcceptedCase, 'companyStocks', [])
                    .map(stock => stock.securityName)
                    .join(', ')}{' '}
                  that you hold in your account and to have the proceeds from the sale allocated
                  proportionately amongst your other account investments.
                </Wrapper.AssumptionsFirstListItem>
              </ul>
            ) : (
              <ul>
                <Wrapper.AssumptionsFirstListItem>
                  Certain investments, such as company stock, cannot be sold unless you give
                  GuidedChoice permission to do so on your behalf. You decided to keep all of the{' '}
                  {_.get(spendingAcceptedCase, 'companyStocks', [])
                    .map(stock => stock.securityName)
                    .join(', ')}{' '}
                  in your account.
                </Wrapper.AssumptionsFirstListItem>
              </ul>
            )
          ) : null
        }
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(AssumptionsFirstPage))
