/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import styled from '@emotion/styled'
import posed from 'react-pose'
import _ from 'lodash'

import { Eye } from '../../assets/icons'
import { TextInput, Button, Spacer, SVGWrapper, LoginError } from '../../components'
import { RadioGroup, RadioButton } from '../../guided-toolbox'
import { Page, Header } from './elements'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function Login(props) {
  const [enableAuthMethodSelection] = useState(false)

  const {
    location,
    history,
    store: { setLoginAccount, login },
  } = props
  const [type, setType] = useState('password')

  const toggleView = () => {
    setType(type === 'password' ? 'text' : 'password')
  }

  const onSubmit = async values => {
    return handleLogin(values)
  }

  const initialValues = {
    twoFactorType: 'email',
    email: undefined,
    password: undefined,
  }

  const validate = values => {
    const errors = {}

    if (!values.twoFactorType) {
      errors.twoFactorType = 'Required'
    }
    return errors
  }

  const handleLogin = async values => {
    try {
      await login(values)
      setLoginAccount(values)
      history.push('/atmos/register/enter-auth-code/verify-2FA')
    } catch (err) {
      console.error(err)
      return {
        [FORM_ERROR]: 'Our database does not contain that email and password combination.',
      }
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <RouteContainer key={location.key || location.pathname}>
      <Page.Container>
        <Header />
        <Page>
          <Page.Heading
            style={{ fontSize: 50, lineHeight: 76, fontFamily: 'Open Sans Light' }}
            title='Account login'
          />
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            subscription={{ submitting: true, submitError: true }}
            validate={validate}
            render={({ handleSubmit, submitting, submitError }) => (
              <Page.Form
                css={css`
                  padding-left: 20px;
                `}>
                <Page.AnimateGroup>
                  <Page.AnimateItem>
                    <BorderedCard
                      css={css`
                        padding-left: 20px;
                      `}>
                      <CardTitle>Login email</CardTitle>
                      <EmailField />

                      <CardTitle
                        css={css`
                          margin: 25px 0 0;
                        `}>
                        Password
                      </CardTitle>
                      <PasswordField toggleView={toggleView} type={type} />
                      <Link
                        css={css`
                          padding-left: 12px;
                          cursor: pointer;
                          color: rgb(31, 151, 190);
                          text-decoration: underline;
                        `}
                        to='/atmos/reset-password'>
                        Forgot password
                      </Link>
                      {submitError && <LoginError err={submitError} />}

                      {enableAuthMethodSelection && (
                        <>
                          <CardTitle
                            css={css`
                              margin: 25px 0;
                            `}>
                            Send authentication <br /> code via:
                          </CardTitle>
                          <AuthenticationRadioGroup />
                        </>
                      )}

                      <div
                        css={css`
                          display: flex;
                          justify-content: space-between;
                        `}>
                        <div />
                        <Button
                          onClick={handleSubmit}
                          label='Login'
                          type='submit'
                          primary
                          style={{ width: 124 }}
                          disabled={submitting}
                        />
                      </div>
                    </BorderedCard>

                    <Page.AnimateItem
                      css={css`
                        margin-bottom: 10px;
                      `}>
                      <Link
                        css={css`
                          padding-left: 10px;
                          cursor: pointer;
                          color: rgb(31, 151, 190);
                          text-decoration: underline;
                        `}
                        to='/atmos/register'>
                        Don't have an account?
                      </Link>
                    </Page.AnimateItem>
                  </Page.AnimateItem>
                  <Spacer space='10px' />
                </Page.AnimateGroup>
              </Page.Form>
            )}
          />
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-content: flex-start;
              width: 100%;
            `}>
            <Button
              style={{ width: 124 }}
              label='Cancel'
              onClick={handleCancel}
              type='button'
              secondary
            />
          </div>
        </Page>
      </Page.Container>
    </RouteContainer>
  )
}

export default inject('store')(observer(Login))

const BorderedCard = styled.div`
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px 0;
  padding: 1rem;
  max-width: 461px;
`
const CardTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 36px;
  line-height: 46px;
  font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
  font-weight: 300;
  overflow-wrap: break-word;
`

const Error = styled.span`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
`

function EmailField() {
  const { input, meta } = useField('email', {
    validate: value =>
      yup
        .string()
        .email()
        .required('Email is required')
        .max(128, 'Maximum character limit 128 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      css={css`
        padding-left: 12px;
      `}
      id='email'
      error={meta.error}
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='name@email.com'
      showError={meta.touched}
      value={input.value}
      width='90%'
    />
  )
}

function PasswordField(props) {
  const { input, meta } = useField('password', {
    validate: value =>
      yup
        .string()
        .required('Password is required')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <div
      css={css`
        position: relative;
        padding-left: 12px;
      `}>
      <TextInput
        id='password'
        error={meta.error}
        type={props.type}
        name={input.name}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        onChange={(name, value) => input.onChange(value)}
        placeholder='Password'
        showError={meta.touched}
        value={input.value}
        width='90%'
      />
      <div
        css={css`
          position: absolute;
          right: 19%;
          top: 16px;
        `}>
        <SVGWrapper
          svg={Eye}
          fill='#7a8e96'
          hoverFill='black'
          activeFill='#b6c0c4'
          size='large'
          onClick={props.toggleView}
        />
      </div>
    </div>
  )
}

function AuthenticationRadioGroup() {
  const { input, meta } = useField('twoFactorType', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <div
      css={css`
        margin: 16px;
      `}>
      <RadioGroup name='twoFactorType' value={input.value} onChange={input.onChange}>
        <RadioButton label='Send email to login email' value='email' />
        <RadioButton label='Send text to mobile' value='phone' />
      </RadioGroup>
      {meta.touched && (meta.error || meta.submitError) && (
        <Error>{meta.error || meta.submitError}</Error>
      )}
    </div>
  )
}
