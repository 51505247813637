import _ from 'lodash'
import { BOND_CASH_OTHER_SECURITIES } from './bond-cash-other-securities'

export const accountTypeKeyToPlanTypeId = {
  '401a': 23,
  '401k': 1,
  '403b': 2,
  '457': 4,
  'annuity-fixed': 24,
  'brokerage-account': 15,
  'ira-deductible': 12,
  'ira-non-deductible': 11,
  'ira-rollover': 13,
  'ira-roth': 14,
  'keogh': 6,
  'other-asset': 16,
  'payroll-deduct-ira': 12,
  'pension': 25,
  'sep-ira': 20,
  'simple-ira': 21,
}

export const planTypeToAccountLabel = {
  23: '401(a)',
  1: '401(k)',
  2: '403(b)',
  4: '457',
  24: 'Annuity | Fixed',
  15: 'Brokerage Account',
  16: 'Other Asset',
  12: 'IRA | Deductible',
  11: 'IRA | Non-deductible',
  13: 'IRA | Rollover',
  14: 'IRA | Roth',
  6: 'Keogh',
  /* "12": "Payroll Deduct IRA", */
  25: 'Pension',
  20: 'SEP IRA',
  21: 'SIMPLE IRA',
}

export const planTypeIdToAccountKey = {
  23: '401a',
  1: '401k',
  2: '403b',
  4: '457',
  24: 'annuity-fixed',
  15: 'brokerage-account',
  12: 'ira-deductible',
  11: 'ira-non-deductible',
  13: 'ira-rollover',
  14: 'ira-roth',
  6: 'keogh',
  16: 'other-asset',
  /* "12": "payroll-deduct-ira", */
  25: 'pension',
  20: 'sep-ira',
  21: 'simple-ira',
}

export const planTypeIdToDashboardCategory = {
  23: 'employer',
  1: 'employer',
  2: 'employer',
  4: 'employer',
  24: 'annuity',
  15: 'other',
  12: 'other',
  11: 'other',
  13: 'other',
  14: 'other',
  6: 'employer',
  16: 'other',
  /* "12": "payroll-deduct-ira", */
  25: 'pension',
  20: 'employer',
  21: 'employer',
}

export const typeToAPIStoreKey = {
  Annuity: 'annuityAPI',
  Institutional: 'institutionalAPI',
  NonGc: 'nonGcPlanAPI',
  Pension: 'pensionAccountAPI',
}

export const typeToAPI = {
  Annuity: 'annuity/',
  Institutional: 'accounts/institutionalAccount/',
  NonGc: 'accounts/nonGCAccount/',
  Pension: 'pension/',
}

/* Investments */

export const SECURITY_TYPES = {
  'Stock': 1,
  'Bond': 2,
  'Cash': 3,
  'ETF': 4,
  'Mutual Fund': 4,
  'Trust': 5,
  'Other': 6,
  'Restricted': 7,
}

export const SECURITY_TYPES_TEXT = {
  1: 'Stock',
  2: 'Bond',
  3: 'Cash',
  4: 'Mutual Fund',
  5: 'Trust',
  6: 'Other',
  7: 'Restricted',
}

export const TAX_TYPES = {
  notInvested: 0,
  preTax: 1,
  afterTax: 2,
  roth: 5,
}

export const TAX_TYPES_TEXT = {
  0: 'Not Invested',
  1: 'Pre-tax',
  2: 'After-tax',
  5: 'Roth',
}

export const VALUATION_METHODS = {
  marketPrice: 1,
  accountBalance: 3,
  notInvested: null,
}

const SECURITIES_BY_ACCOUNT_INVESTMENT = [
  {
    accountTypes: [
      accountTypeKeyToPlanTypeId['401a'],
      accountTypeKeyToPlanTypeId['401k'],
      accountTypeKeyToPlanTypeId['403b'],
      accountTypeKeyToPlanTypeId['457'],
      accountTypeKeyToPlanTypeId['ira-deductible'],
      accountTypeKeyToPlanTypeId['ira-non-deductible'],
      accountTypeKeyToPlanTypeId['ira-rollover'],
      accountTypeKeyToPlanTypeId['ira-roth'],
      accountTypeKeyToPlanTypeId.keogh,
      accountTypeKeyToPlanTypeId['payroll-deduct-ira'],
      accountTypeKeyToPlanTypeId['sep-ira'],
      accountTypeKeyToPlanTypeId['simple-ira'],
    ],
    investmentType: SECURITY_TYPES.Bond,
    securityIds: [16, 17, 18, 2538003, 2855131, 2855235, 2855230],
  },
  {
    accountTypes: [
      accountTypeKeyToPlanTypeId['brokerage-account'],
      accountTypeKeyToPlanTypeId['other-asset'],
    ],
    investmentType: SECURITY_TYPES.Bond,
    securityIds: [16, 17, 18, 2538003, 2855131, 2855235, 2855230, 19, 20, 21],
  },
  {
    accountTypes: [
      accountTypeKeyToPlanTypeId['401a'],
      accountTypeKeyToPlanTypeId['401k'],
      accountTypeKeyToPlanTypeId['403b'],
      accountTypeKeyToPlanTypeId['457'],
      accountTypeKeyToPlanTypeId.keogh,
      // accountTypeKeyToPlanTypeId["payroll-deduct-ira"], commented out because it has the same plan type as ira - deductible
      accountTypeKeyToPlanTypeId['sep-ira'],
      accountTypeKeyToPlanTypeId['simple-ira'],
    ],
    investmentType: SECURITY_TYPES.Cash,
    securityIds: [22, 24, 25, 8, 27, 23],
  },
  {
    accountTypes: [
      accountTypeKeyToPlanTypeId['ira-deductible'],
      accountTypeKeyToPlanTypeId['ira-non-deductible'],
      accountTypeKeyToPlanTypeId['ira-rollover'],
      accountTypeKeyToPlanTypeId['ira-roth'],
      accountTypeKeyToPlanTypeId['brokerage-account'],
      accountTypeKeyToPlanTypeId['other-asset'],
    ],
    investmentType: SECURITY_TYPES.Cash,
    securityIds: [22, 8, 27, 23],
  },
  {
    accountTypes: [
      accountTypeKeyToPlanTypeId['401a'],
      accountTypeKeyToPlanTypeId['401k'],
      accountTypeKeyToPlanTypeId['403b'],
      accountTypeKeyToPlanTypeId['457'],
      accountTypeKeyToPlanTypeId['brokerage-account'],
      accountTypeKeyToPlanTypeId['ira-deductible'],
      accountTypeKeyToPlanTypeId['ira-non-deductible'],
      accountTypeKeyToPlanTypeId['ira-rollover'],
      accountTypeKeyToPlanTypeId['ira-roth'],
      accountTypeKeyToPlanTypeId.keogh,
      accountTypeKeyToPlanTypeId['other-asset'],
      accountTypeKeyToPlanTypeId['payroll-deduct-ira'],
      accountTypeKeyToPlanTypeId['sep-ira'],
      accountTypeKeyToPlanTypeId['simple-ira'],
    ],
    investmentType: SECURITY_TYPES.Other,
    securityIds: [
      28, 29, 30, 31, 32, 2855233, 2855234, 2855252, 2538004, 2538005, 2538006, 2538007,
      // 40,
      // 39,
      38, 33, 34, 35, 2855236, 36, 37, 45, 46, 47, 1838518, 1838519, 1838531, 1838522, 1838532,
      1838524, 1838533, 1838526, 1838527, 1838528, 48, 51, 2855237, 49, 50, 52,
    ],
  },
]

export const getSecurityOptionsList = (accountType, investmentType) => {
  const securitiesGroup = _.find(
    SECURITIES_BY_ACCOUNT_INVESTMENT,
    securitiesGroup =>
      securitiesGroup.investmentType === investmentType &&
      _.includes(securitiesGroup.accountTypes, accountType)
  )

  return _.map(securitiesGroup.securityIds, id => {
    const security = _.find(BOND_CASH_OTHER_SECURITIES, security => security.id === id)
    const { id: value, securityName: label } = security
    return { label, value }
  })
}

export const InvestmentTypeIdToKey = {
  1: 'Stock',
  2: 'Bonds',
  3: 'Cash',
  4: 'Misc',
  6: 'Misc',
}

export const IndexMap = {
  Cash: 0,
  Bonds: 1,
  Stock: 2,
  Misc: 3,
  undefined: 3,
}

export const IndexToSVGMap = {
  0: 'Cash',
  1: 'Bonds',
  2: 'Stock',
  3: 'Misc',
}
