/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import styled from '@emotion/styled'
import posed from 'react-pose'
import _ from 'lodash'

import { Page, Header } from './elements'
import { Button, FormError, Text } from '../../components'

import { Refresh } from '../../assets/icons'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function EnterAuthCode(props) {
  const [resendState, setResendState] = useState('idle')
  const {
    location,
    history,
    store: {
      setup2FA,
      verify2FA,
      initiateLogin2FA,
      verifyResetPassword2FA,
      resetPassword,
      loginStore: { twoFactorType },
    },
    match: {
      params: { type },
    },
  } = props

  const onSubmit = values => {
    return handleAPICall(values)
  }

  const handleAPICall = async values => {
    if (type === 'set-up-2FA') {
      try {
        await setup2FA(values)
        history.push('/atmos/login')
      } catch (err) {
        console.error(err)
        return {
          [FORM_ERROR]:
            'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
        }
      }
    }

    if (type === 'verify-2FA') {
      try {
        await verify2FA(values)
        history.push('/')
      } catch (err) {
        console.error(err)
        return {
          [FORM_ERROR]:
            'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
        }
      }
    }

    if (type === 'password-2FA') {
      try {
        await verifyResetPassword2FA(values)
        await resetPassword()
        history.push('/atmos/login')
      } catch (err) {
        console.error(err)
        return {
          [FORM_ERROR]:
            'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
        }
      }
    }
  }

  const resend = async () => {
    try {
      setResendState('loading')
      await initiateLogin2FA(twoFactorType || 'email')
      setResendState('success')
    } catch (err) {
      console.error(err)
      setResendState('error')
    }
  }

  const inputRef = useRef(null)
  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <RouteContainer key={location.key || location.pathname}>
      <Page.Container>
        <Header />
        <Page>
          <Page.Heading title='Enter authentication code' />
          <Form
            onSubmit={onSubmit}
            subscription={{ submitting: true, submitError: true }}
            render={({ handleSubmit, submitting, submitError }) => (
              <Page.Form>
                <Page.AnimateGroup
                  css={css`
                    padding-left: 20px;
                  `}>
                  <Page.AnimateItem>
                    <Text>
                      Please check the email address of the GuidedChoice account holder for the
                      authentication code.
                    </Text>
                    <BorderedCard>
                      <Field
                        name='code'
                        parse={v => v}
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <>
                            <Code ref={inputRef} {...input} autoComplete='off' maxLength='6' />
                            <LineWrapper>
                              <Line />
                              <Line />
                              <Line />
                              <Line />
                              <Line />
                              <Line />
                            </LineWrapper>
                          </>
                        )}
                      />

                      <div
                        css={css`
                          display: flex;
                          justify-content: space-between;
                        `}>
                        <div />
                        <Button
                          onClick={handleSubmit}
                          label='Submit'
                          type='submit'
                          primary
                          style={{ width: 124 }}
                          disabled={submitting}
                        />
                      </div>
                    </BorderedCard>
                    {submitError && <FormError err={submitError} />}
                    <Page.AnimateItem>
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                        `}>
                        <Page.Link onClick={resendState === 'loading' ? _.noop : resend}>
                          <Refresh />
                        </Page.Link>
                        &nbsp;
                        <Page.Link onClick={resendState === 'loading' ? _.noop : resend}>
                          Resend
                        </Page.Link>
                        {resendState === 'success' && (
                          <span
                            css={css`
                              margin: 0 24px;
                              color: #4d9927;
                            `}>
                            New code sent!
                          </span>
                        )}
                        {resendState === 'error' && (
                          <span
                            css={css`
                              margin: 0 24px;
                              color: #e31e27;
                            `}>
                            There was an issue with your request, please try again.
                          </span>
                        )}
                      </div>
                    </Page.AnimateItem>{' '}
                    <br /> <br />
                  </Page.AnimateItem>
                </Page.AnimateGroup>
              </Page.Form>
            )}
          />
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-content: flex-start;
              width: 100%;
            `}>
            <Button
              style={{ width: 124 }}
              label='Cancel'
              onClick={handleCancel}
              type='button'
              secondary
            />
          </div>
        </Page>
      </Page.Container>
    </RouteContainer>
  )
}

export default inject('store')(observer(EnterAuthCode))

const BorderedCard = styled.div`
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px 0;
  padding: 1rem;
  max-width: 461px;
`

const Line = styled.div`
  border-bottom: 2px solid #7a8e96;
  width: 30px;
  margin: 6px;
`
const LineWrapper = styled.div`
  display: flex;
`

const Code = styled.input`
  border: none;
  letter-spacing: 25px;
  font-size: 30px;
  outline: none;
  margin-left: 10px;
  background-color: transparent;
  max-width: 100%;
`
