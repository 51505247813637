function Conversation() {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 168 133'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Group 7</title>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Artboard' transform='translate(-362.000000, -1816.000000)' strokeWidth='2.025'>
          <g id='Group-7' transform='translate(364.000000, 1818.000000)'>
            <line
              x1='22.275'
              y1='79.875'
              x2='91.125'
              y2='79.875'
              id='Shape'
              stroke='#90BE3F'></line>
            <line
              x1='22.275'
              y1='66.825'
              x2='91.125'
              y2='66.825'
              id='Shape'
              stroke='#90BE3F'></line>
            <line x1='22.275' y1='93.15' x2='91.125' y2='93.15' id='Shape' stroke='#90BE3F'></line>
            <path
              d='M52.65,4.08785047 L52.65,62.9074766 C52.65,65.1785047 54.4947205,66.9953271 56.8006211,66.9953271 L148.114286,66.9953271 C149.190373,66.9953271 150.26646,67.4495327 151.035093,68.2065421 L164.025,81 L164.025,66.9953271 L164.025,4.08785047 C164.025,1.81682243 162.18028,0 159.874379,0 L56.8774845,0 C54.4947205,0 52.65,1.81682243 52.65,4.08785047 Z'
              id='Shape'
              stroke='#0D8BB8'
              fill='#FFFFFF'></path>
            <line x1='139.725' y1='20.25' x2='72.9' y2='20.25' id='Shape' stroke='#0D8BB8'></line>
            <line x1='72.9' y1='32.625' x2='139.725' y2='32.625' id='Shape' stroke='#0D8BB8'></line>
            <line x1='72.9' y1='44.55' x2='139.725' y2='44.55' id='Shape' stroke='#0D8BB8'></line>
            <path
              d='M111.375,65.9657944 L111.375,109.030164 C111.375,111.357967 109.53028,113.22021 107.224379,113.22021 L15.9107143,113.22021 C14.8346273,113.22021 13.7585404,113.685771 12.9899068,114.461706 L0,127.575 L0,113.22021 L0,48.7400467 C0,46.412243 1.8447205,44.55 4.15062112,44.55 L51.882764,44.55'
              id='Shape'
              stroke='#90BE3F'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Conversation
