import { css } from '@emotion/react'

export default function FormError({ err }) {
  return (
    <div
      css={css`
        color: #e31e27;
        font-size: 14px;
        text-align: center;
        line-height: 1.5;
        margin-bottom: 20px;
      `}>
      {err || 'Oops! Something went wrong, please try again later'}
    </div>
  )
}
