export const CLIENT_OVERRIDES = {
  config: {
    // templateId: 13,
    // onBoarded: true,
    // requireToAcceptTerms: false,
    // enableCatchupContributions: false,
    template: {
      // planName: 'PLAN_NAME',
      // recordKeeperName: 'RECORD_KEEPER_NAME',
      // sponsorName: 'SPONSOR_NAME',
      // sponsorState: 'SPONSOR_STATE',
      // sponsorShortHand: 'SPONSOR_SHORT_HAND',
      // supportGroup: 'SUPPORT_GROUP',
      // supportName: 'SUPPORT_NAME',
      // supportLine: 'SUPPORT_LINE',
      // supportLinkOverName: 'SUPPORT_LINK_OVER_NAME',
      // supportLinkOverDomain: 'SUPPORT_LINK_OVER_DOMAIN',
      // supportLinkOverDomainDisplay: 'SUPPORT_LINK_OVER_DOMAIN_DISPLAY',
      // brandName: 'GuidedChoice',
      // brandSupportEmail: 'help@guidedchoice.com',
      // brandSite: 'www.guidedchoice.com',
      // managedAccountType: 'Managed account',
      // managedAccountLabel: 'managed by GuidedChoice',
      // isMigrating: false,
      // riskRulerStates: ['none', 'scale7', 'scale100'],
      // overallPageComponent: 'Simplified',
      // changeContributionShortcutEnabled: false,
      // showQuickActions: false,
      // landingPage: null
    },
  },
  features: {
    // enableGoals: false,
    // enableAccounts: false,
    // enableSpouse: false,
    // enableDependents: false,
    // enableMobilePhone: false,
    // enableDaytimePhone: false,
    // enableAddress: false,
    // enableLoginHistory: false,
    // enablePreviousGuides: false,
    // enablePreferredName: false,
    // enableOtherIncome: false,
    // enableTaxStateChange: false,
    // enableSSI: false,
    // enableAdditionalSavings: false,
    // enableRiskLevelAdjustment: false,
    // enableTools: false,
    // enableHelp: false,
    // allBreakdownChartsEnabled: false,
    // allowAddGoals: false,
    // allowAddAccounts: false,
    // showSideBar: false,
    // discontinueOptionEnabled: false,
    // discontinueRedirect: false,
    // managedAccountAvailable: false,
    // autoRiskAdjustEnabled: false,
    // oneTimeAdviceEnabled: false,
    // outsideAdviceEnabled: false,
    // companyStockEnabled: false,
    // stockOptionsEnabled: false,
    // companyInsiderEnabled: false,
    // enablePersonalizedTargetDatePlus: false,
    // enableSavingsRateUpdate: false
  },
  account: {
    // managedAccount: false,
    // autoRiskLevelAdjustmentStatus: false
  },
}
