/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'
import * as yup from 'yup'
import { isInt, isMobilePhone } from 'validator'

import { Page } from '../elements'
import { TextInput, PhoneInput, Button } from '../../../components'
import { reduceValidationError } from '../../../utils'

const schema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .required('Your email address is required')
    .email('A valid email is required'),
  mobilePhone: yup
    .string()
    .nullable()
    .test(
      'is-mobile-phone',
      'Please enter a valid phone number',
      value =>
        value === undefined || value === null || value === '' || isMobilePhone(value || '', 'en-US')
    ),
  phone: yup
    .string()
    .nullable()
    .test(
      'is-mobile-phone',
      'Please enter a valid phone number',
      value =>
        value === undefined || value === null || value === '' || isMobilePhone(value, 'en-US')
    )
    .when('phoneExtension', (phoneExtension, schema) =>
      phoneExtension ? schema.required('Please enter a valid phone number') : schema
    ),
  phoneExtension: yup
    .string()
    .nullable()
    .test(
      'is-phone-extension',
      'Please enter a valid extension',
      value =>
        value === undefined ||
        value === null ||
        value === '' ||
        isInt(value, { min: 0, max: 999999 })
    ),
})

const ContactInfo = props => {
  const onSubmit = async values => {
    try {
      const { contactInfo, updateContactInfo } = props.onboarding

      if (!_.isEqual(values, getSnapshot(contactInfo))) {
        await updateContactInfo(values)
      }

      if (props.store.config.isRetail) {
        return props.history.push('/welcome/account')
      }

      const { isSpendown } = props.store.config
      if (isSpendown) {
        // if it's a spendown case send user to account page'
        return props.history.push('/welcome/account')
      }

      props.history.push('/welcome/greeting')
    } catch (err) {
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const initialValues = () => {
    const { onboarding } = props
    return getSnapshot(onboarding.contactInfo)
  }

  const validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  return (
    <Page>
      <Page.Heading title='Let us know how to contact you' />

      {props.store.config.templateId === 4 && (
        <p
          css={css`
            color: #9eacb0;
            margin-top: -20px;
          `}>
          Note: Email is required so that we may provide services such as your personalized Advisory
          Guide. While not required, your phone number, if provided, is used only when we are
          responding to a request from you.
        </p>
      )}

      <Form
        initialValues={initialValues()}
        validate={validate}
        onSubmit={onSubmit}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting, submitError }) => (
          <Page.Form>
            <Page.AnimateGroup>
              <Page.AnimateItem>
                <Page.Field label='Contact email'>
                  <Field
                    name='email'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <TextInput
                        id='email-field'
                        error={meta.error}
                        maxLength={129}
                        name={input.name}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onChange={(name, value) => input.onChange(value)}
                        placeholder='email'
                        showError={meta.touched}
                        type='email'
                        value={input.value}
                        width='340px'
                        mobileWidth='220px'
                      />
                    )}
                  />
                </Page.Field>
              </Page.AnimateItem>

              <Page.AnimateItem>
                <Page.Field id='mobile-phone-field' label='Mobile phone'>
                  <Field
                    name='mobilePhone'
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <PhoneInput
                        error={meta.error}
                        name={input.name}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onChange={(name, value) => input.onChange(value)}
                        placeholder='mobile'
                        showError={meta.touched}
                        value={input.value}
                      />
                    )}
                  />
                </Page.Field>
              </Page.AnimateItem>

              <Page.AnimateItem>
                <Page.Field label='Daytime phone'>
                  <div
                    id='daytime-phone-field'
                    css={css`
                      display: flex;
                      & > div:first-of-type {
                        padding-right: 15px;
                      }
                    `}>
                    <Field
                      name='phone'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <PhoneInput
                          error={meta.error}
                          name={input.name}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          placeholder='daytime'
                          showError={meta.touched}
                          value={input.value}
                        />
                      )}
                    />
                    <Field
                      name='phoneExtension'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <TextInput
                          error={meta.error}
                          placeholder='ext'
                          name={input.name}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          showError={meta.touched}
                          value={input.value}
                          width='80px'
                          maxLength={6}
                        />
                      )}
                    />
                  </div>
                </Page.Field>
              </Page.AnimateItem>

              <Page.Animate pose={!submitting && submitError ? 'visible' : 'hidden'}>
                <Page.ErrorMessage>{!submitting && submitError}</Page.ErrorMessage>
              </Page.Animate>

              <Page.AnimateItem>
                <Page.ButtonGroup>
                  <div>
                    <Button
                      id='contact-info-back-button'
                      type='button'
                      onClick={props.history.goBack}
                      secondary
                      label='Back'
                      disabled={submitting}
                      width='140px'
                    />
                  </div>
                  <div>
                    <Button
                      id='contact-info-submit-button'
                      type='button'
                      onClick={handleSubmit}
                      primary
                      label='Next'
                      disabled={submitting}
                      width='140px'
                    />
                  </div>
                </Page.ButtonGroup>
              </Page.AnimateItem>
            </Page.AnimateGroup>
          </Page.Form>
        )}
      />
    </Page>
  )
}

export default inject('onboarding', 'store')(observer(ContactInfo))
