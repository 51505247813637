/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const active = 'rt-drawer-active'

const drawer = 'rt-drawer-drawer'

const left = 'rt-drawer-left'

const right = 'rt-drawer-right'

const wrapper = 'rt-drawer-wrapper'

const _wrapper = css`
  position: relative;
  z-index: ${ds.z('higher')};
`

const _drawer = css`
  background-color: ${ds.get('drawer.backgroundColor')};
  box-shadow: ${ds.get('shadow[2]')};
  color: ${ds.get('drawer.textColor')};
  display: block;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform-style: preserve-3d;
  transition: transform ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')};
  transition-delay: 0s;
  width: ${ds.get('drawer.mobileWidth')};
  will-change: transform;

  ${ds.get('reset')};

  &.${active} {
    pointer-events: all;
    transform: translateX(0);
    transition-delay: ${ds.get('animation.delay')};
  }

  &.${right} {
    border-left: 1px solid ${ds.get('drawer.borderColor')};
    right: 0;

    &:not(.${active}) {
      transform: translateX(100%);
    }
  }

  &.${left} {
    border-right: 1px solid ${ds.get('drawer.borderColor')};
    left: 0;

    &:not(.${active}) {
      transform: translateX(${reduceCSSCalc('calc(-1 * 100%)')});
    }
  }

  @media screen and (min-width: ${ds.bp('xs')}) {
    width: ${ds.get('drawer.desktopWidth')};
  }
`

export default {
  active,
  drawer: cx(drawer, _drawer),
  left,
  right,
  wrapper: cx(wrapper, _wrapper),
}
