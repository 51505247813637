import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import { SVGWrapper, Spacer, FlexRow, HelpIcon, TooltipText } from '../../../components'
import { CircleNegative, CirclePlus } from '../../../assets/icons/'
import { numberToDollars } from '../../../utils'

const TooltipLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.35;
  font-size: 1.25rem;
  padding: 0 18px;
  cursor: pointer;
`
const Container = styled.div`
  padding-left: 20px;
`
const Text = styled.div`
  color: ${p => p.theme.mediumGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.35;
  font-size: ${p => (p.fontSize ? p.fontSize : '1.125rem')};
  padding: ${p => (p.padding ? p.padding : null)};
`
const Amount = styled.span`
  font-size: ${p => (p.lightDollar ? '1.125rem' : '1.25rem')};
  color: ${p => p.theme.darkestGray};

  &::before {
    content: '$';
    color: ${p => (p.lightDollar ? p.theme.lightestGray : '#4d9927')};
  }
`
const Percent = styled.span`
  font-size: 1.125rem;
  color: ${p => p.theme.darkestGray};

  &::after {
    content: '%';
    color: ${p => p.theme.lightestGray};
  }
`
const Indent = styled.div`
  padding-left: 24px;
`

class TellMeMore extends Component {
  state = { show: false }

  toggleShow = () => {
    this.setState({ show: !this.state.show })
  }

  render() {
    const {
      account: { annualSalary },
      person: { maritalStatus, includeSpouse, otherIncome },
      spouse: { annualIncome, firstName, otherIncome: spouseOtherIncome },
      baseCase: {
        incomeBeforeTax,
        currentTaxRate,
        taxAmount,
        clientContributions,
        spouseContributions,
      },
      config: { isRetail },
    } = this.props.onboarding
    const spouseIncluded = maritalStatus && includeSpouse
    const taxRate = Math.round(currentTaxRate * 10000) / 100

    return (
      <div
        css={css`
          margin: 40px 0;
        `}>
        <FlexRow childMargin='24px'>
          <FlexRow onClick={this.toggleShow}>
            {this.state.show ? (
              <SVGWrapper
                svg={CircleNegative}
                onClick={this.toggleShow}
                fill='#7a8e96'
                size='large'
              />
            ) : (
              <SVGWrapper svg={CirclePlus} onClick={this.toggleShow} fill='#7a8e96' size='large' />
            )}
            <TooltipLabel
              css={css`
                padding-right: 0px !important;
              `}>
              Tell me more
            </TooltipLabel>
          </FlexRow>
          <HelpIcon tooltip={TooltipText.tellMeMoreHelp()} />
        </FlexRow>
        {this.state.show && (
          <Container>
            <Spacer space='10px' />
            <Text padding='5px 24px'>
              You earn{' '}
              <Amount lightDollar>{numberToDollars(annualSalary + otherIncome, true)}</Amount> a
              year.
            </Text>
            {spouseIncluded && (
              <Text padding='5px 24px'>
                {`${firstName}`} earns{' '}
                <Amount lightDollar>
                  {numberToDollars(annualIncome + spouseOtherIncome, true)}
                </Amount>{' '}
                a year. Together, this is a total of{' '}
                <Amount lightDollar>{numberToDollars(incomeBeforeTax, true)}</Amount>
                {'.'}
              </Text>
            )}
            <Text padding='5px 24px'>
              We estimate a tax rate of <Percent>{taxRate}</Percent>
              {/* round percent to 2 decimal places. If decimal is .00 it drops off */}
              {', '}
              or <Amount lightDollar>{numberToDollars(taxAmount, true)}</Amount>
              {'.'}
            </Text>
            <Text padding='5px 24px'>
              This leaves you with{' '}
              <Amount lightDollar>{numberToDollars(incomeBeforeTax - taxAmount, true)}</Amount> in
              {spouseIncluded && ' combined'} take-home pay after taxes without considering your
              retirement plan contributions.
            </Text>

            {(!isRetail && maritalStatus) ||
              (!isRetail && includeSpouse && (
                <>
                  <Spacer space='15px' />
                  <Text padding='5px 24px'>
                    Based on your current contribution rate, you are on target to have contributed a
                    total of{' '}
                    <Amount lightDollar>{numberToDollars(clientContributions, true)}</Amount> by the
                    end of the year.
                  </Text>
                </>
              ))}
            {!isRetail && spouseIncluded && (
              <Text padding='5px 24px'>
                {`${firstName}`} is on target to have contributed a total of{' '}
                <Amount lightDollar>{numberToDollars(spouseContributions, true)}</Amount>
                {'. '}
                This is a combined total of{' '}
                <Amount lightDollar>
                  {numberToDollars(clientContributions + spouseContributions, true)}
                </Amount>
                {'.'}
              </Text>
            )}
            {(!isRetail && maritalStatus) ||
              (!isRetail && includeSpouse && (
                <Text padding='5px 24px'>
                  Subtract this from your previous{spouseIncluded && ' combined'} take-home pay,{' '}
                  <Amount lightDollar>{numberToDollars(incomeBeforeTax - taxAmount, true)}</Amount>
                  {', '}
                  and you are left with{' '}
                  <Amount lightDollar>
                    {numberToDollars(
                      incomeBeforeTax - taxAmount - clientContributions - spouseContributions,
                      true
                    )}
                  </Amount>
                  {'.'}
                </Text>
              ))}
            <Spacer space='15px' />
            <Text padding='5px 24px'>
              Divide by 12 months to get your{spouseIncluded && ' combined'} monthly take-home pay:{' '}
              {
                <Amount lightDollar>
                  {numberToDollars(
                    (incomeBeforeTax - taxAmount - clientContributions - spouseContributions) / 12,
                    true
                  )}
                </Amount>
              }
              {'.'}
            </Text>
            <Text padding='5px 24px 15px 24px'>
              <Percent>80</Percent> of this is{' '}
              <Amount lightDollar>
                {numberToDollars(
                  ((incomeBeforeTax - taxAmount - clientContributions - spouseContributions) / 12) *
                    0.8,
                  true
                )}
              </Amount>{' '}
              each month in spendable income.
            </Text>
            <Spacer space='4px' />
            <Indent>
              <HelpIcon tooltip={TooltipText.why80()} helpLabel='Why 80? Will it be enough?' />
            </Indent>
          </Container>
        )}
      </div>
    )
  }
}

export default inject('onboarding')(observer(TellMeMore))
