import { types, getEnv } from 'mobx-state-tree'
import { css } from '@emotion/react'
import _ from 'lodash'

import BGLeft from '../../assets/images/BGLeft.svg'
import BGRight from '../../assets/images/BGRight.svg'
import { themeModel } from '../../themes/ThemeWrapper'

const { model, maybeNull, string, boolean } = types

const leftBackground = css`
  background: url(${BGLeft}) no-repeat top left fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`
const rightBackground = css`
  background: url(${BGRight}) no-repeat top right fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

const OffboardingStore = model({
  acceptedCaseSubmitted: false,
  email: maybeNull(string),
  isRetailMember: maybeNull(boolean),
  theme: maybeNull(themeModel),
})
  .volatile(self => ({
    // We set routes here so that observable data doesn't change it throughout the offboarding process
    routes: [[]],
  }))
  .views(self => ({
    get background() {
      const { templateId } = getEnv(self).store.config

      switch (templateId) {
        case 1:
        case 2:
          return rightBackground

        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          return leftBackground

        case 13:
          return rightBackground
        default:
          return leftBackground
      }
    },

    get isRetailMemberMember() {
      return self.retailMember
    },

    get initialPage() {
      const { templateId } = getEnv(self).store.config

      if (this.isRetailMember) {
        return '/implement/' + self.routes[0][0]
      }

      switch (templateId) {
        case 1:
        case 2:
          return '/implement/intro'

        // case 13 covers PTD+ Product, either confirm-paycheck-contributions or enrollment-selection
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 13:
          return '/implement/' + self.routes[0][0]

        default:
          return '/overall'
      }
    },

    get enableStepper() {
      const { templateId } = getEnv(self).store.config

      if (self.isRetailMember) {
        return true
      }

      switch (templateId) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          return true

        // disable for PTD+ Product
        case 13:
          return false
        default:
          return true
      }
    },

    createRoutes() {
      // Compute business logic for offboarding steps here
      // Each offboarding array contains another array that determines the Step number (some routes can be the same step)
      // Within those Step arrays contains the page route(s) relevant to the Step

      const retailRoutes = () => {
        return [['intro'], ['get-your-guide']]
      }

      function transactionRoutes() {
        const { canTransact } = getEnv(self).store.config

        // FL Institutional
        return canTransact
          ? [['account-changes'], ['confirm-email'], ['confirmation']]
          : [['confirm-email'], ['confirmation']]
      }

      function institutionalRoutes() {
        const {
          features: { managedAccountAvailable },
          account: { managedAccount },
        } = getEnv(self).store

        // Institutional
        const contributionChanged =
          _.get(getEnv(self).store.baseCase, 'totalAnnualPercentage', 0) !==
          _.get(getEnv(self).store.acceptedCase, 'totalAnnualPercentage', 0)

        const isSellingCompanyStock = getEnv(self).store.acceptedCase.companyStockSellPercentage > 0

        return [
          [contributionChanged && 'contribution', isSellingCompanyStock && 'company-stock'].filter(
            item => item
          ),
          managedAccountAvailable && !managedAccount && ['value-proposition'],
          managedAccountAvailable && managedAccount && ['implement-changes'],
          ['account-changes'],
          ['confirm-email'],
        ].filter(item => item && item.length !== 0)
      }

      // PTD+ Product
      function enrollmentRoutes() {
        // Check if we show paycheck contributions confirmation before enrollment upgrade page or implement modifications
        // This is relevant for skipping directly to the enrollment selection page

        const { managedAccount } = getEnv(self).store.account

        if (!managedAccount) {
          // If not yet enrolled into PTD+ product
          return [
            ['confirm-paycheck-contributions'],
            ['enrollment-selection'],
            ['enrollment-confirmed'],
          ]
        } else {
          // If they're already enrolled into PTD+
          // Implement changes / modified path
          return [['confirm-paycheck-contributions'], ['modifications-complete']]
        }
      }

      const { templateId } = getEnv(self).store.config

      if (self.isRetailMember) {
        return retailRoutes()
      }

      switch (templateId) {
        case 1:
        case 2:
          return transactionRoutes()

        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
          return institutionalRoutes()

        case 13:
          return enrollmentRoutes()

        default:
          return [[null]]
      }
    },

    findRoute(route) {
      const parentIndex = _.findIndex(
        self.routes,
        value => !!_.find(value, routeValue => routeValue === route)
      )
      const childIndex = _.findIndex(self.routes[parentIndex], value => value === route)
      return [parentIndex, childIndex]
    },

    activeStep(route) {
      const found = self.findRoute(route)[0]
      return found + 1
    },

    nextRoute(currentPath) {
      // this function determines what the next path should be from the multi-dimensional array defined above
      const current = self.findRoute(currentPath)
      const currentStep = self.routes[current[0]]
      const currentRouteIndex = current[1]
      if (currentStep[currentRouteIndex] === _.last(currentStep)) {
        return self.routes[current[0] + 1][0]
      } else {
        return self.routes[current[0]][currentRouteIndex + 1]
      }
    },
  }))
  .actions(self => ({
    setRoutes() {
      self.routes = self.createRoutes()
    },

    setEmail(value) {
      self.email = value
    },

    setTheme(value) {
      self.theme = value
    },

    setAcceptedCaseSubmitted(value) {
      self.acceptedCaseSubmitted = value
    },

    setIsRetailMember() {
      const { store } = getEnv(self)
      self.isRetailMember = store.config.isRetail
    },
  }))

export default OffboardingStore
