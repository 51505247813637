/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { observer, inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import styled from '@emotion/styled'

import { MultiButtonInput } from '../../../components/index'
import EditPassword from './EditPassword'
import EditEmail from './EditEmail'

function EditLoginInfo(props) {
  const onSubmit = values => {
    return
  }

  const {
    handleCancel,
    store: {
      config: { isRetail },
    },
  } = props

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ submitting: true, submitError: true }}
      initialValues={{ emailPasswordToggle: 'password' }}
      render={({ handleSubmit, submitting }) => (
        <div>
          <PrimaryLabel>What do you want to change?</PrimaryLabel>
          <Label>
            If you want to make changes to both your login email and password, you'll need to do so
            one at a time.
          </Label>
          <Field
            name='emailPasswordToggle'
            render={({ input, meta }) => (
              <>
                <MultiButtonInput
                  name={input.name}
                  value={input.value}
                  onChange={(name, value) => input.onChange(value)}
                  buttons={
                    !isRetail
                      ? [
                          { text: 'Password', value: 'password' },
                          { text: 'Login email', value: 'email' },
                        ]
                      : [{ text: 'Password', value: 'password' }]
                  }
                />
                {input.value === 'password' && <EditPassword handleCancel={handleCancel} />}
                {!isRetail && input.value === 'email' && <EditEmail handleCancel={handleCancel} />}
              </>
            )}
          />
        </div>
      )}
    />
  )
}

export default inject('store')(observer(EditLoginInfo))

const PrimaryLabel = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1.25rem;
  word-break: break-word;
  margin-top: 10px;
`

const Label = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 1.25rem;
  word-break: break-word;
  margin-bottom: 10px;
`
