import { inject, observer } from 'mobx-react'
import { Spacer } from '../../components'
import { Section, Page } from './styles'
import _ from 'lodash'

const HomeActions = ({
  history,
  mode,
  store: {
    config: { spendingMode, isRetail },
    plan,
    account,
  },
  store,
}) => {
  const isManagedAccount = account.managedAccount
  const isStateOfFlorida =
    plan.sponsorName === 'State of Florida' || plan.sponsorName === 'FRS Investment Plan'

  const updateInvestmentAccount = () => {
    history.push('/accounts')
  }
  const getIncomeResults = () => {
    if (spendingMode) {
      return history.push('/spending')
    }
    history.push('/overall')
  }
  const updateProfile = () => {
    history.push('/profile')
  }
  const handleScenarioPlanning = () => {
    if (plan.sponsorName === 'Paychex') {
      return history.push({ pathname: '/overall', from: 'home' })
    }
    if (!spendingMode) {
      return history.push({ pathname: '/overall', from: 'home' })
    }
    history.push('/spending/budget')
  }

  const handleSellCompanyStock = () => {
    if (spendingMode) {
      return history.push('/spending/considerations')
    }
    history.push({ pathname: '/overall', from: 'home' })
  }

  const handleHowMuchSaving = () => {
    history.push('/update-contributions-only')
  }

  if (mode === 'retMoreThanThreeYearsAway') {
    return (
      <Section>
        <Section.SectionTitle>Not sure where to go?</Section.SectionTitle>
        <Spacer space='5px' />
        <Page.ActionMessage>A good starting point:</Page.ActionMessage>
        <Spacer space='5px' />
        <Section.IndentedHomeActionSection>
          <Section.HomeActionButton onClick={getIncomeResults}>
            Get income results from recommendation
          </Section.HomeActionButton>
        </Section.IndentedHomeActionSection>
        <Spacer space='12px' />
        <Page.SubActionMessage>Some other things you can do:</Page.SubActionMessage>
        <Section.IndentedHomeActionSection>
          {isManagedAccount && !isStateOfFlorida && !isRetail && (
            <Section.HomeActionButton onClick={handleHowMuchSaving}>
              Change how much you're saving only
            </Section.HomeActionButton>
          )}
          <Section.HomeActionButton onClick={updateInvestmentAccount}>
            Update investment account data
          </Section.HomeActionButton>
          <Section.HomeActionButton onClick={updateProfile}>
            Update personal info
          </Section.HomeActionButton>
          <Section.HomeActionButton onClick={handleScenarioPlanning}>
            Scenario planning
          </Section.HomeActionButton>
          {_.get(store, 'baseCase.companyStocks', []).filter(stock => !stock.restricted).length >
          0 ? (
            <Section.HomeActionButton onClick={handleSellCompanyStock}>
              Sell company stock
            </Section.HomeActionButton>
          ) : null}
        </Section.IndentedHomeActionSection>
        <Spacer space='50px' />
      </Section>
    )
  }

  if (mode === 'retWithinThreeYears') {
    return (
      <Section>
        <Section.SectionTitle>Not sure where to go?</Section.SectionTitle>
        <Spacer space='5px' />
        <Page.ActionMessage>A good starting point:</Page.ActionMessage>
        <Spacer space='5px' />
        <Section.IndentedHomeActionSection>
          <Section.HomeActionButton onClick={getIncomeResults}>
            Get income results from recommendation
          </Section.HomeActionButton>
        </Section.IndentedHomeActionSection>
        <Spacer space='12px' />
        <Page.SubActionMessage>Some other things you can do:</Page.SubActionMessage>
        <Section.IndentedHomeActionSection>
          {isManagedAccount && !isStateOfFlorida && !isRetail && (
            <Section.HomeActionButton onClick={handleHowMuchSaving}>
              Change how much you're saving only
            </Section.HomeActionButton>
          )}
          <Section.HomeActionButton onClick={updateInvestmentAccount}>
            Update investment account data
          </Section.HomeActionButton>
          <Section.HomeActionButton onClick={updateProfile}>
            Update personal info
          </Section.HomeActionButton>
          <Section.HomeActionButton onClick={handleScenarioPlanning}>
            Scenario planning
          </Section.HomeActionButton>
          {_.get(store, 'baseCase.companyStocks', []).filter(stock => !stock.restricted).length >
          0 ? (
            <Section.HomeActionButton onClick={handleSellCompanyStock}>
              Sell company stock
            </Section.HomeActionButton>
          ) : null}
        </Section.IndentedHomeActionSection>
        <Spacer space='50px' />
      </Section>
    )
  }

  return (
    <Section>
      <Section.SectionTitle>Not sure where to go?</Section.SectionTitle>
      <Spacer space='5px' />
      <Page.ActionMessage>A good starting point:</Page.ActionMessage>
      <Spacer space='5px' />
      <Section.IndentedHomeActionSection>
        <Section.HomeActionButton onClick={getIncomeResults}>
          Get income results from recommendation
        </Section.HomeActionButton>
      </Section.IndentedHomeActionSection>
      <Spacer space='12px' />
      <Page.SubActionMessage>Some other things you can do:</Page.SubActionMessage>
      <Spacer space='5px' />
      <Section.IndentedHomeActionSection>
        <Section.HomeActionButton onClick={updateInvestmentAccount}>
          Update investment account data
        </Section.HomeActionButton>
        <Section.HomeActionButton onClick={updateProfile}>
          Update personal info
        </Section.HomeActionButton>
        <Section.HomeActionButton onClick={handleScenarioPlanning}>
          Scenario planning
        </Section.HomeActionButton>
        {_.get(store, 'allSpendingRecommendedCases.companyStocks', []).filter(
          stock => !stock.restricted
        ).length > 0 ? (
          <Section.HomeActionButton onClick={handleSellCompanyStock}>
            Sell company stock
          </Section.HomeActionButton>
        ) : null}
      </Section.IndentedHomeActionSection>
      <Spacer space='50px' />
    </Section>
  )
}

export default inject('store')(observer(HomeActions))
