import { useTheme } from '@emotion/react'

const OverallStrategyLiveSVG = () => {
  const theme = useTheme()

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <defs>
        <clipPath id='a'>
          <rect
            x='3.8261'
            y='2.6989'
            width='24.3477'
            height='28.4057'
            rx='2.2544'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='b'>
          <path
            d='M25.92,3.3752H6.0805A1.5781,1.5781,0,0,0,4.5034,4.8991l-.0009.0542V28.85a1.5781,1.5781,0,0,0,1.5238,1.5772l.0542.0009H25.92a1.578,1.578,0,0,0,1.5771-1.5238l.0009-.0543V4.9533a1.578,1.578,0,0,0-1.5238-1.5771Z'
            fill='#fff'
          />
        </clipPath>
        <clipPath id='c'>
          <rect
            x='8.5604'
            y='0.8954'
            width='14.8792'
            height='4.7343'
            rx='0.2254'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='d'>
          <rect x='9.2367' y='1.5717' width='13.5265' height='3.6071' fill='#fff' />
        </clipPath>
        <clipPath id='e'>
          <path
            d='M6.236,10.99a.5636.5636,0,0,1,.7667-.0461l.03.0267,4.7343,4.5089a.5636.5636,0,0,1-.7472.8429l-.03-.0267L6.2555,11.7866A.5638.5638,0,0,1,6.236,10.99Z'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='f'>
          <path
            d='M11.0453,11.0125a.5636.5636,0,0,1,.7888.8043l-.0287.0282L6.9773,16.2532a.5636.5636,0,0,1-.7888-.8043l.0287-.0281Z'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='g'>
          <path
            d='M19.988,19.5565a.5636.5636,0,0,1,.7667-.0461l.03.0267,4.7343,4.5089a.5636.5636,0,0,1-.7473.8429l-.03-.0267-4.7343-4.5088A.5637.5637,0,0,1,19.988,19.5565Z'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='h'>
          <path
            d='M24.7973,19.5793a.5636.5636,0,0,1,.7888.8043l-.0288.0282L20.7292,24.82a.5636.5636,0,0,1-.7888-.8043l.0288-.0281Z'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='i'>
          <path
            d='M13.0693,23.2141a2.48,2.48,0,1,0,2.48,2.48A2.48,2.48,0,0,0,13.0693,23.2141Zm0-.9017A3.3816,3.3816,0,1,1,9.6876,25.694,3.3816,3.3816,0,0,1,13.0693,22.3124Z'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='j'>
          <path
            d='M22.8241,8.8827a.4511.4511,0,0,1,.4465.4553l-.0052.4056-.0074.3774-.0064.2373-.0121.3364-.01.2125-.0115.204-.0133.1964-.0152.1894-.0171.1834-.0193.1782q-.01.0879-.0216.1737l-.0238.17-.0264.1673c-.0231.1384-.0489.2748-.0778.4112l-.0361.1637-.0391.1647a5.2321,5.2321,0,0,1-2.1735,3.3039,16.0978,16.0978,0,0,1-3.5432,1.6984l-.2648.0924-.135.0459-.277.0922-.1428.0465-.296.0945-.3122.0973-.61.1853-.7757.2324-.3882.1181-.2467.0767-.3527.1123-.224.0733-.2156.0723-.2073.0714-.1007.0355-.1957.07-.095.0351-.1846.07q-.0906.0349-.1777.07l-.1711.07-.0831.0349-.1617.07q-.1191.0528-.2318.106l-.1476.0716-.072.0361-.14.0729-.0684.0368-.1334.0747q-.0986.0566-.1925.1147l-.1238.0784-.12.08-.059.0409-.116.0834q-.086.0633-.1693.1294l-.11.0893-.1085.092C8.5734,22.15,7.9491,23.6845,7.94,26.018a.4509.4509,0,1,1-.9018-.0034c.01-2.5808.7355-4.3646,2.1714-5.6114l.12-.102.06-.05.1222-.0973c.1231-.096.2489-.1875.3791-.2756l.1315-.087a7.8712,7.8712,0,0,1,.7113-.4063l.1552-.0769c.1839-.089.3781-.176.585-.2621l.18-.0737.1869-.0734.1938-.0735.1-.0368.2045-.0738.2121-.0742.1091-.0373.3393-.1132.2369-.0767.2457-.0779.255-.0792,1.7108-.5164.3747-.1165.1465-.0465.282-.0912.1363-.045.2649-.09.2571-.09A15.2233,15.2233,0,0,0,20.26,15.66a4.3505,4.3505,0,0,0,1.7931-2.766l.0358-.1508c.0287-.1255.0546-.2508.0778-.3778l.0267-.1534.0242-.1559.0221-.159.02-.1631.0179-.1677.0159-.1732.0072-.0889.0132-.1828.006-.0941.0108-.1942.0092-.2027.0079-.2119.0095-.337.005-.2384.007-.5134A.451.451,0,0,1,22.8241,8.8827Z'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='k'>
          <path
            d='M22.5572,8.58a.4508.4508,0,0,1,.6089-.0264l.0287.0264,2.48,2.48a.4509.4509,0,0,1-.609.664l-.0287-.0263-2.48-2.48A.4508.4508,0,0,1,22.5572,8.58Z'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='l'>
          <path
            d='M22.4382,8.6478a.4509.4509,0,0,1,.62.6538l-.0283.0268L20.383,11.6292a.4509.4509,0,0,1-.62-.6538l.0283-.0268Z'
            fill={theme.sidebarColor}
          />
        </clipPath>
        <clipPath id='m'>
          <path
            d='M24.2286,5.2915a.2255.2255,0,0,1,.0263.4494l-.0263.0015H7.7714a.2255.2255,0,0,1-.0263-.4494l.0263-.0015Z'
            fill={theme.sidebarColor}
          />
        </clipPath>
      </defs>
      <rect
        x='3.8261'
        y='2.6989'
        width='24.3477'
        height='28.4057'
        rx='2.2544'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#a)'>
        <rect x='2.6989' y='1.5717' width='26.6022' height='30.6601' fill={theme.sidebarColor} />
      </g>
      <path
        d='M25.92,3.3752H6.0805A1.5781,1.5781,0,0,0,4.5034,4.8991l-.0009.0542V28.85a1.5781,1.5781,0,0,0,1.5238,1.5772l.0542.0009H25.92a1.578,1.578,0,0,0,1.5771-1.5238l.0009-.0543V4.9533a1.578,1.578,0,0,0-1.5238-1.5771Z'
        fill='#fff'
      />
      <g clipPath='url(#b)'>
        <rect x='3.3752' y='2.248' width='25.2495' height='29.3075' fill='#fff' />
      </g>
      <rect
        x='8.5604'
        y='0.8954'
        width='14.8792'
        height='4.7343'
        rx='0.2254'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#c)'>
        <rect x='7.4332' y='-0.2318' width='17.1336' height='6.9887' fill={theme.sidebarColor} />
      </g>
      <rect x='9.2367' y='1.5717' width='13.5265' height='3.6071' fill='#fff' />
      <g clipPath='url(#d)'>
        <rect x='8.1095' y='0.4445' width='15.7809' height='5.8615' fill='#fff' />
      </g>
      <path
        d='M6.236,10.99a.5636.5636,0,0,1,.7667-.0461l.03.0267,4.7343,4.5089a.5636.5636,0,0,1-.7472.8429l-.03-.0267L6.2555,11.7866A.5638.5638,0,0,1,6.236,10.99Z'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#e)'>
        <rect x='4.9533' y='9.6876' width='8.1159' height='7.8905' fill={theme.sidebarColor} />
      </g>
      <path
        d='M11.0453,11.0125a.5636.5636,0,0,1,.7888.8043l-.0287.0282L6.9773,16.2532a.5636.5636,0,0,1-.7888-.8043l.0287-.0281Z'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#f)'>
        <rect x='4.9064' y='9.7379' width='8.2097' height='7.7899' fill={theme.sidebarColor} />
      </g>
      <path
        d='M19.988,19.5565a.5636.5636,0,0,1,.7667-.0461l.03.0267,4.7343,4.5089a.5636.5636,0,0,1-.7473.8429l-.03-.0267-4.7343-4.5088A.5637.5637,0,0,1,19.988,19.5565Z'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#g)'>
        <rect x='18.7053' y='18.2544' width='8.1159' height='7.8905' fill={theme.sidebarColor} />
      </g>
      <path
        d='M24.7973,19.5793a.5636.5636,0,0,1,.7888.8043l-.0288.0282L20.7292,24.82a.5636.5636,0,0,1-.7888-.8043l.0288-.0281Z'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#h)'>
        <rect x='18.6584' y='18.3047' width='8.2097' height='7.7899' fill={theme.sidebarColor} />
      </g>
      <path
        d='M13.0693,23.2141a2.48,2.48,0,1,0,2.48,2.48A2.48,2.48,0,0,0,13.0693,23.2141Zm0-.9017A3.3816,3.3816,0,1,1,9.6876,25.694,3.3816,3.3816,0,0,1,13.0693,22.3124Z'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#i)'>
        <rect x='8.5604' y='21.1852' width='9.0177' height='9.0177' fill={theme.sidebarColor} />
      </g>
      <path
        d='M22.8241,8.8827a.4511.4511,0,0,1,.4465.4553l-.0052.4056-.0074.3774-.0064.2373-.0121.3364-.01.2125-.0115.204-.0133.1964-.0152.1894-.0171.1834-.0193.1782q-.01.0879-.0216.1737l-.0238.17-.0264.1673c-.0231.1384-.0489.2748-.0778.4112l-.0361.1637-.0391.1647a5.2321,5.2321,0,0,1-2.1735,3.3039,16.0978,16.0978,0,0,1-3.5432,1.6984l-.2648.0924-.135.0459-.277.0922-.1428.0465-.296.0945-.3122.0973-.61.1853-.7757.2324-.3882.1181-.2467.0767-.3527.1123-.224.0733-.2156.0723-.2073.0714-.1007.0355-.1957.07-.095.0351-.1846.07q-.0906.0349-.1777.07l-.1711.07-.0831.0349-.1617.07q-.1191.0528-.2318.106l-.1476.0716-.072.0361-.14.0729-.0684.0368-.1334.0747q-.0986.0566-.1925.1147l-.1238.0784-.12.08-.059.0409-.116.0834q-.086.0633-.1693.1294l-.11.0893-.1085.092C8.5734,22.15,7.9491,23.6845,7.94,26.018a.4509.4509,0,1,1-.9018-.0034c.01-2.5808.7355-4.3646,2.1714-5.6114l.12-.102.06-.05.1222-.0973c.1231-.096.2489-.1875.3791-.2756l.1315-.087a7.8712,7.8712,0,0,1,.7113-.4063l.1552-.0769c.1839-.089.3781-.176.585-.2621l.18-.0737.1869-.0734.1938-.0735.1-.0368.2045-.0738.2121-.0742.1091-.0373.3393-.1132.2369-.0767.2457-.0779.255-.0792,1.7108-.5164.3747-.1165.1465-.0465.282-.0912.1363-.045.2649-.09.2571-.09A15.2233,15.2233,0,0,0,20.26,15.66a4.3505,4.3505,0,0,0,1.7931-2.766l.0358-.1508c.0287-.1255.0546-.2508.0778-.3778l.0267-.1534.0242-.1559.0221-.159.02-.1631.0179-.1677.0159-.1732.0072-.0889.0132-.1828.006-.0941.0108-.1942.0092-.2027.0079-.2119.0095-.337.005-.2384.007-.5134A.451.451,0,0,1,22.8241,8.8827Z'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#j)'>
        <rect x='5.9113' y='7.7555' width='18.4865' height='19.8391' fill={theme.sidebarColor} />
      </g>
      <path
        d='M22.5572,8.58a.4508.4508,0,0,1,.6089-.0264l.0287.0264,2.48,2.48a.4509.4509,0,0,1-.609.664l-.0287-.0263-2.48-2.48A.4508.4508,0,0,1,22.5572,8.58Z'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#k)'>
        <rect x='21.2979' y='7.3205' width='5.6361' height='5.6361' fill={theme.sidebarColor} />
      </g>
      <path
        d='M22.4382,8.6478a.4509.4509,0,0,1,.62.6538l-.0283.0268L20.383,11.6292a.4509.4509,0,0,1-.62-.6538l.0283-.0268Z'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#l)'>
        <rect x='18.5091' y='7.41' width='5.803' height='5.457' fill={theme.sidebarColor} />
      </g>
      <path
        d='M24.2286,5.2915a.2255.2255,0,0,1,.0263.4494l-.0263.0015H7.7714a.2255.2255,0,0,1-.0263-.4494l.0263-.0015Z'
        fill={theme.sidebarColor}
      />
      <g clipPath='url(#m)'>
        <rect x='6.4187' y='4.1643' width='19.1626' height='2.7053' fill={theme.sidebarColor} />
      </g>
    </svg>
  )
}

export default OverallStrategyLiveSVG
