import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { UpdateInfoAlert } from './blocks'
import Investments from '../../../models/Investments'

class DataAlertPage extends Component {
  static displayName = 'DataAlertPage'
  static propTypes = {}
  constructor(props) {
    super(props)
    this.state = Investments.create({ investments: [] })
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'dataAlert' })
  }

  updateInfoAlert = () => {
    const { getInvestments } = this.state
    return (
      <UpdateInfoAlert
        getInvestments={getInvestments}
        page={this.props.guide.guidePages.pages.findByName('dataAlert')}
      />
    )
  }

  render() {
    return this.updateInfoAlert()
  }
}

export default inject('store', 'guide')(observer(DataAlertPage))
