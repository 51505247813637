const warning = `
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <defs>
      <style>.icon-color-orange{fill:#f48024;}</style>
    </defs>
    <title>warning</title>
    <g id="Symbols">
      <g id="icn_warning" class="icon-color-orange" data-name="icn/warning">
        <g id="warning" >
          <path
            id="Shape"
            d="M23.83,21.83L12.95,0.61a1,1,0,0,0-1.87,0L0.15,21.92a1.31,1.31,0,
              0,0,0,1.22,1.17,1.17,0,0,0,.93.86H22.94a1.12,1.12,0,0,0,1-.88A1.88,
              1.88,0,0,0,23.83,21.83Zm-20.52.22L12,4l9,18.07H3.31Z"
            transform="translate(0)"
          />
        </g>
        <path
          id="_"
          data-name="!"
          d="M13,10.15H11v4.14a10.67,10.67,0,0,0,.09,1.29q0.08,0.63.21,
            1.35h1.42q0.13-.72.21-1.35A10.67,10.67,0,0,0,13,14.29V10.15Zm-2,
            8.94a1.1,1.1,0,0,0,.07.43,1,1,0,0,0,.21.33,1.08,1.08,0,0,0,.33.23,
            0.91,0.91,0,0,0,.4.09,0.9,0.9,0,0,0,.38-0.09,1,1,0,0,0,.32-0.23,1,1,
            0,0,0,.21-0.33A1.1,1.1,0,0,0,13,19.08a1.15,1.15,0,0,0-.08-0.43,1.11,
            1.11,0,0,0-.21-0.34,1,1,0,0,0-.32-0.23A0.9,0.9,0,0,0,12,18a0.91,0.91,
            0,0,0-.4.09,1.08,1.08,0,0,0-.33.23,1.11,1.11,0,0,0-.21.34A1.15,1.15,
            0,0,0,11,19.08Z"
          transform="translate(0)"
        />
      </g>
    </g>
  </svg>
`

export default warning
