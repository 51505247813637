import { observer, inject } from 'mobx-react'
import { Route, Switch } from 'react-router-dom'
import CancellationPolicy from './CancellationPolicy'

import InitialPaymentPage from './InitialPaymentPage'
import SetupPayment from './SetupPayment'
import Page from './style'

function RetailPayment() {
  return (
    <Page>
      <Switch>
        <Route path='/retail/payment/setup-payment' component={SetupPayment} />
        <Route path='/retail/payment/cancellation-policy' component={CancellationPolicy} />
        <Route path='/retail/payment' component={InitialPaymentPage} />
      </Switch>
    </Page>
  )
}

export default inject('store')(observer(RetailPayment))
