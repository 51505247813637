import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import posed from 'react-pose'
import { Loading } from '../../components'
import { API_URL } from '../../api'

export const Page = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 0 20px;
`

Page.Container = styled.div`
  padding: 16px;
  position: relative;
`

Page.PleaseNote = styled.p`
  color: ${p => p.theme.ssiPleaseNoteColor};
  margin-top: -30px;
  font-size: 14px;
`

Page.Loading = () => (
  <div
    css={css`
      margin: 20px;
    `}>
    <Loading />
  </div>
)

export const Header = props => {
  const theme = useTheme()

  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
        padding-bottom: 40px;
        max-width: 1200px;
        margin: auto;
      `}>
      <div
        css={css`
          max-width: 220px;
          padding: 2px 10px;
        `}>
        <img
          css={css`
            width: 100%;
          `}
          src={API_URL + theme.logo}
          alt='Logo'
        />
      </div>
    </div>
  )
}

Page.Text = styled.div`
  font-size: 1.125rem;
  color: ${p => p.theme.mediumGray};
  line-height: 1.35;
`

Page.LightText = styled.div`
  font-size: 1.125rem;
  color: ${p => p.theme.lightestGray};
  line-height: 1.35;
`

Page.WelcomeHeading = styled.div`
  text-align: center;
  max-width: 679px;
  margin: auto;
  padding: 20px 0 40px;
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 3.125rem;
  font-weight: bold;
  word-wrap: break-word;
  color: ${p => p.theme.darkestGray};
`

Page.WelcomeParagraph = styled(Page.Text)`
  margin: 20px auto;
  max-width: 679px;
`

Page.TermsSubText = styled(Page.Text)`
  font-size: 1rem;
  max-width: 679px;
  margin: auto;
  padding-left: 64px;
`

Page.AnimateHeading = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -15 },
})

const HeadingStyles = styled.div`
  padding-top: 10px;
  margin-bottom: 40px;
  max-width: 600px;
  @media (min-width: 1200px) {
    margin-bottom: 60px;
  }

  /* Title */
  & > div:first-of-type {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3rem;
    width: 100%;
    color: ${p => p.theme.ssiHeadingTextColor};
    transition: 0.75s;
    font-weight: 300;
    line-height: 1.25;
    word-break: break-word;
    @media (max-width: 800px) {
      font-size: 2rem;
      max-width: 400px;
    }
    @media (min-width: 1200px) {
      font-size: 3.125rem;
      max-width: 800px;
    }
  }

  /* SubTitle */
  & > div:nth-of-type(2) {
    color: ${p => p.theme.lightestGray};
    font-size: 1rem;
    padding-top: 1rem;
    line-height: 20px;
  }
`
Page.Heading = props => {
  const { title, children, subTitle, ...rest } = props
  return (
    <Page.AnimateHeading>
      <HeadingStyles {...rest}>
        <div>{title || children}</div>
        {subTitle && <div>{subTitle}</div>}
      </HeadingStyles>
    </Page.AnimateHeading>
  )
}

Page.Link = styled.a`
  cursor: pointer;
  color: ${p => p.theme.editColor};
  text-decoration: underline;
`

Page.Form = styled.form`
  margin: 20px 0;
`

const FieldStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 28px 0;

  /* Field Label Container */
  & > div:first-of-type {
    flex: 1 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: right;
    margin-right: 20px;
    color: rgb(122, 142, 150);
    font-size: 1.125rem;
    line-height: 1.35;

    /* Field Label */
    & > div:first-of-type {
    }

    /* Field SubLabel (Optional) */
    & > div:nth-of-type(2) {
      font-size: 0.875rem;
    }
  }

  /* Field Input Container */
  & > div:nth-of-type(2) {
    flex: 3 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(74, 96, 106);
    font-size: 1.125rem;
    line-height: 1.35;
    word-break: break-word;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    margin: 20px 0;
    & > div:first-of-type {
      flex: 1 1 100%;
      text-align: left;
      margin: 12px 0;
    }
  }
`
Page.Field = props => {
  const { label, subLabel, children, ...rest } = props
  return (
    <FieldStyles {...rest}>
      <div>
        <div>{label}</div>
        {subLabel && <div>{subLabel}</div>}
      </div>
      <div>{children}</div>
    </FieldStyles>
  )
}

Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`

Page.ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px 0;
  flex-wrap: wrap-reverse;
`

Page.AnimateGroup = posed.div({
  enter: { staggerChildren: 50 },
  exit: { staggerChildren: 20, staggerDirection: -1 },
})

Page.AnimateItem = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 20, opacity: 0 },
})

Page.Animate = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 20, opacity: 0 },
})

Page.Subtitle = styled.div`
  color: #4a606a;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 17px;
`
Page.IfMessage = styled.div`
  color: #4a606a;
  font-family: Helvetica;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  padding-left: 20px;
`
Page.CongratulationsMessage = styled.div`
  min-height: 124px;
  min-width: 862px;
  background-color: rgba(79, 173, 204, 0.2);
  margin-left: 20px;
  padding: 18px;
`
Page.YourChoice = styled.div`
  color: ${p => p.theme.ssiYourChoiceColor};
  font-family: 'Open Sans';
  font-size: 30px;
  line-height: 41px;
`
Page.MakeChangesSubtitle = styled.div`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`
Page.MakeChangesInfo = styled.div`
  color: #4a606a;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 17px;
`
Page.MakeChangesName = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 6px;
`

export default Page
