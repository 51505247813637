import { Component } from 'react'
import { NumericFormat } from 'react-number-format'
import PropTypes from 'prop-types'
import BaseInput from './BaseInput'

export class PhoneInput extends Component {
  static defaultProps = {
    readonly: false,
    disabled: false,
    showError: false,
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    width: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    showError: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    readonly: PropTypes.bool,
    mobileWidth: PropTypes.string,
  }

  handleBlur = () => {
    const { name, onBlur } = this.props
    if (onBlur) {
      onBlur(name)
    }
  }

  handleChange(values) {
    this.props.onChange(this.props.name, values.value)
  }

  render() {
    const { placeholder, value, error, name, disabled, showError, readonly, width, mobileWidth } =
      this.props

    return (
      <BaseInput
        error={error}
        showError={showError}
        readonly={readonly}
        width={width}
        mobileWidth={mobileWidth}>
        <NumericFormat
          name={name}
          value={value || undefined}
          placeholder={placeholder}
          onValueChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur}
          disabled={disabled}
          format='(###) ###-####'
        />
      </BaseInput>
    )
  }
}

export default PhoneInput
