import { Component } from 'react'
import PropTypes from 'prop-types'
import { SplitBarChartHeader, SplitBarChartRisk, SplitBarChartLegend } from './'
import styled from '@emotion/styled'

const StyledBarChartContainer = styled.div`
  min-width: 700px;
`

class SplitBarChartContainer extends Component {
  static defaultProps = {
    oldRiskText: '',
    newRiskText: '',
  }

  static propTypes = {
    oldRiskText: PropTypes.string,
    newRiskText: PropTypes.string,
    legendLeftPadding: PropTypes.number,
  }

  render() {
    const { oldRiskText, newRiskText, rangeIndex, legendLeftPadding } = this.props

    return (
      <StyledBarChartContainer>
        <SplitBarChartLegend paddingLeft={legendLeftPadding} />
        <SplitBarChartHeader />
        {oldRiskText && newRiskText && (
          /* eslint-disable */
          <SplitBarChartRisk
            oldRiskText={oldRiskText}
            newRiskText={newRiskText}
            rangeIndex={rangeIndex}
          />
          /* eslint-enable */
        )}
        {this.props.children}
      </StyledBarChartContainer>
    )
  }
}

export default SplitBarChartContainer
