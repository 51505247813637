const negCircle = `
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 24 24"
		style="enable-background:new 0 0 24 24;"
		xml:space="preserve"
	>
		<title>subtract-normal-c</title>
		<g class="icon-color" id="Symbols">
			<g id="icn_subtract-normal">
				<path
					d="M21.6,12c0-5.3-4.3-9.6-9.6-9.6c-5.3,0-9.6,4.3-9.6,9.6 M0,12C0,5.4,
						5.4,0,12,0s12,5.4,12,12 M2.4,12 c0,5.3,4.3,9.6,9.6,9.6c5.3,0,9.6-4.3
						,9.6-9.6 M24,12c0,6.6-5.4,12-12,12S0,18.6,0,12"
				/>
				<polygon
					id="Combined-Shape"
					points="6,13 18,13 18,11 6,11"
				/>
			</g>
		</g>
	</svg>
`

export default negCircle
