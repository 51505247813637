import styled from '@emotion/styled'
import { Text } from '../../../components'

const StepContainer = styled.div`
  padding: 0 0 36px 36px;
`

const StepHeader = styled(Text)`
  font-size: 1.5rem;
  padding: 0 0 24px 0;
`

const StepRow = styled.div`
  display: flex;
  padding-bottom: 1rem;
`

const StepNumber = styled(Text)`
  flex: 0 0 80px;
`

const StepText = styled(Text)`
  flex: 1 1 auto;
`

export { StepContainer, StepHeader, StepRow, StepNumber, StepText }
