function BarChartGain() {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 101 74'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Group 15</title>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Artboard' transform='translate(-397.000000, -1273.000000)'>
          <g id='Group-15' transform='translate(397.000000, 1275.000000)'>
            <line
              x1='0'
              y1='70.6642964'
              x2='95.5473913'
              y2='70.6642964'
              id='Shape'
              stroke='#0D8BB8'
              strokeWidth='1.9125'></line>
            <polygon
              id='Rectangle-path'
              stroke='#0D8BB8'
              strokeWidth='1.9125'
              points='1 37.5780464 10.2465217 37.5780464 10.2465217 65.1180464 1 65.1180464'></polygon>
            <polygon
              id='Rectangle-path'
              stroke='#0D8BB8'
              strokeWidth='1.9125'
              points='85.3008696 22.2780464 94.5473913 22.2780464 94.5473913 65.1180464 85.3008696 65.1180464'></polygon>
            <polygon
              id='Rectangle-path'
              stroke='#0D8BB8'
              strokeWidth='1.9125'
              points='30.8217391 22.2780464 40.0682609 22.2780464 40.0682609 65.1180464 30.8217391 65.1180464'></polygon>
            <polygon
              id='Rectangle-path'
              stroke='#0D8BB8'
              strokeWidth='1.9125'
              points='55.4791304 37.5780464 64.7256522 37.5780464 64.7256522 65.1180464 55.4791304 65.1180464'></polygon>
            <ellipse
              id='Oval'
              stroke='#90BE3F'
              strokeWidth='1.9125'
              cx='60.1845652'
              cy='22.2780464'
              rx='4.62326087'
              ry='3.06'></ellipse>
            <path
              d='M10.2465217,25.3380464 C10.2465217,23.6580464 8.16152174,22.2780464 5.62326087,22.2780464 C3.085,22.2780464 1,23.6580464 1,25.3380464 C1,27.0180464 3.085,28.3980464 5.62326087,28.3980464 C8.25217391,28.3380464 10.2465217,27.0180464 10.2465217,25.3380464 Z'
              id='Shape'
              stroke='#90BE3F'
              strokeWidth='1.9125'></path>
            <line
              x1='9.24652174'
              y1='23.2780464'
              x2='29.5309893'
              y2='13.2087783'
              id='Shape'
              stroke='#90BE3F'
              strokeWidth='1.9125'></line>
            <line
              x1='41.1504348'
              y1='12.0980464'
              x2='56.5613043'
              y2='21.2780464'
              id='Shape'
              stroke='#90BE3F'
              strokeWidth='1.9125'></line>
            <line
              x1='65.7256522'
              y1='22.2780464'
              x2='84.2186952'
              y2='13.0980466'
              id='Shape'
              stroke='#90BE3F'
              strokeWidth='1.9125'></line>
            <ellipse
              id='Oval'
              stroke='#90BE3F'
              strokeWidth='1.91250247'
              transform='translate(36.197582, 8.890546) rotate(-67.622981) translate(-36.197582, -8.890546) '
              cx='36.1975815'
              cy='8.89054642'
              rx='6.53948549'
              ry='6.89054642'></ellipse>
            <ellipse
              id='Oval'
              stroke='#90BE3F'
              strokeWidth='1.91250247'
              transform='translate(90.116766, 8.890546) rotate(-67.622981) translate(-90.116766, -8.890546) '
              cx='90.1167663'
              cy='8.89054642'
              rx='6.53948549'
              ry='6.89054642'></ellipse>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BarChartGain
