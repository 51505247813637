import { observer, inject } from 'mobx-react'
import TotalWithdraw from './TotalWithdraw'
import FundsNeededWithdraw from './FundsNeededWithdraw'

function Withdraw(props) {
  const {
    guide: { investmentWithdrawals },
  } = props
  return (
    <>
      <TotalWithdraw />
      {investmentWithdrawals.livingExpensesWithdrawalAdvice.investmentAccountWithdrawals.length >
        0 && <FundsNeededWithdraw />}
    </>
  )
}

export default inject('store', 'guide')(observer(Withdraw))
