const Bullet3nUniQA = `
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"/>
            <path d="M10 10h30v30H10z"/>
            <g fill-rule="nonzero">
                <path d="M14 22.423c0-.765.644-1.385 1.434-1.385h11.22c.794 0 1.437.62 1.437 1.385v6.51c0 .765-.643 1.386-1.437 1.386H20.02L18.35 32l-1.422-1.68h-1.494c-.79 0-1.434-.622-1.434-1.387v-6.51z" stroke="#02769D" stroke-width="1.2" fill="#FFF"/>
                <path d="M36 26.506C36 27.33 35.245 28 34.312 28h-1.76l-1.672 1.806L28.913 28H21.1c-.934 0-1.69-.67-1.69-1.494v-7.014c0-.823.756-1.492 1.69-1.492h13.212c.933 0 1.688.669 1.688 1.492v7.014z" fill="#02769D"/>
            </g>
        </g>
    </svg>
`

export default Bullet3nUniQA
