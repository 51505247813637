import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const Spacer = styled.div`
  padding: ${p => p.space};
`

export const ContentBox = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 10px 15px;
`

const rowMedia = css`
  flex-wrap: wrap;
`

const row = css`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.8s;
  @media (max-width: 800px) {
    ${rowMedia};
  }
`

export const Row = styled.div`
  ${row};
`

export const RowDrawer = styled.div`
  ${row};
  flex-wrap: wrap;
`

export const QuestionText = styled.div`
  color: #9eacb0;
  font-size: 1.125rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`

export const Text = styled.div`
  color: ${p => p.theme.cardViewValueColor};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: ${p => (p.fontSize ? p.fontSize : '1.125rem')};
  line-height: 1.35;
  padding: ${p => (p.padding ? p.padding : null)};
  word-break: break-word;
`

export const OrderedList = styled.ol`
  padding-left: 22px;
  li {
    padding-left: 30px;
  }
`

export const InputBox = styled.div`
  flex: 3 1 340px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 10px;
  > * {
    padding-right: ${p => (p.childrenPadding ? p.childrenPadding : '')};
  }

  @media (max-width: 800px) {
    padding-top: 8px;
    padding-left: 0;
    ${p => p.styledMedia800 || ''};
  }
`

export const ButtonsBox = styled.div`
  display: flex;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'space-between')};
  max-width: ${p => (p.maxWidth ? p.maxWidth : '1200px')};
  margin: 60px auto;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 40px auto;
  }
`

const button = css`
  cursor: pointer;
  font-size: 1.2rem;
  /*margin: 20px;*/
  border-radius: 4px;
  @media (max-width: 800px) {
    margin: 20px auto;
  }
`

export const BackButton = styled.button`
  ${button};
  width: ${p => (p.width ? p.width : '140px')};
  height: 51px;
  color: ${p => p.theme.backButtonTextColor};
  background-color: ${p => p.theme.offboardingCancelButtonBgColor};
  border: ${p => p.theme.backButtonBorder};
  &:disabled {
    cursor: default;
    color: #b6c0c4;
    background-color: #ffffff;
    border: 1px solid #dadfe1;
  }
`
export const PrimaryButton = styled.a`
  display: inline-block;
  background: ${p => p.theme.buttonColor};
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  color: white;
  font-family: 'Open Sans';
  cursor: pointer;

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }

  & > div:nth-of-type(2),
  div:nth-of-type(3) {
    font-size: 12px;
  }
`
export const SecondaryButton = styled.a`
  display: inline-block;
  color: white;
  background-color: ${p => p.theme.secondaryColor}
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  font-family: 'Open Sans';
  cursor: pointer;

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }

  & > div:nth-of-type(2),
  div:nth-of-type(3) {
    font-size: 12px;
  }
`

export const NextButton = styled.button`
  ${button};
  width: ${p => (p.width ? p.width : '140px')};
  height: 51px;
  color: white;
  background: ${p => p.theme.nextButtonColor};
  border: none;

  &:disabled {
    cursor: default;
    background: ${p => p.theme.disabledButtonColor};
  }
`

export const InlineLink = styled.a`
  cursor: pointer;
  color: #1f97be;
  text-decoration: ${p => (p.noUnderline ? '' : 'underline')};
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : null)};
  align-items: ${p => (p.alignItems ? p.alignItems : 'center')};
  flex-wrap: ${p => (p.wrap ? p.wrap : 'wrap')};
  max-width: ${p => (p.maxWidth ? p.maxWidth : null)};
  margin: ${p => (p.childMargin ? '0 -8px' : null)};

  & > * {
    margin: ${p => (p.childMargin ? '0 8px' : null)};
  }
`

export const TextSpan = styled.span`
  display: inline-block;
`

export const Container = styled.div`
  max-width: ${p => (p.maxWidth ? p.maxWidth : '1200px')};
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: ${p => (p.padding ? p.padding : null)};
  min-width: ${p => (p.minWidth ? p.minWidth : null)};
`

export const MainTitle = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 3rem;
  ${p => (p.maxWidth ? p.maxWidth : null)};
  color: ${p => p.theme.mediumGray};
  font-weight: 300;
  line-height: 1.25;
  transition: 0.25s;
  word-wrap: break-word;
  white-space: pre-wrap;

  @media (max-width: 800px) {
    font-size: ${p => (p.shrink ? '2rem' : null)};
  }
`
