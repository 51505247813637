import { Component } from 'react'
import BaseInput from './BaseInput'
import PropTypes from 'prop-types'

class TextInput extends Component {
  static defaultProps = {
    disabled: false,
    readonly: false,
    showError: false,
    type: 'text',
  }

  static propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.string,
    inputRef: PropTypes.func,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    showError: PropTypes.bool,
    value: PropTypes.string,
    width: PropTypes.string,
    fontSize: PropTypes.string,
  }

  handleBlur(name, onBlur) {
    if (onBlur) onBlur(name)
  }

  handleChange(e) {
    this.props.onChange(e.target.name, e.target.value)
  }

  handleLoadFocus(input) {
    this.props.inputRef(input)
  }

  render() {
    const {
      className,
      disabled,
      error,
      inputRef,
      maxLength,
      name,
      onBlur,
      placeholder,
      showError,
      type,
      value,
      width,
      mobileWidth,
      expanded,
      id,
      fontSize,
      passwordRules,
      readonly,
    } = this.props

    const boundHandleBlur = this.handleBlur.bind(this, name, onBlur)
    const boundHandleChange = this.handleChange.bind(this)
    const boundHandleLoadFocus = this.handleLoadFocus.bind(this)
    return (
      <BaseInput
        readonly={readonly}
        error={error}
        showError={showError}
        width={width}
        mobileWidth={mobileWidth}
        className={className}
        expanded={expanded}
        fontSize={fontSize}>
        {passwordRules ? (
          <input
            id={id}
            disabled={disabled}
            maxLength={maxLength}
            name={name}
            onBlur={boundHandleBlur}
            onChange={boundHandleChange}
            placeholder={placeholder}
            ref={inputRef && boundHandleLoadFocus}
            type={type}
            value={value || ''}
            passwordrules='required: lower; required: upper; required: digit; required: [!#$%&()*.@[^{}]; minlength: 8; maxlength: 32; max-consecutive: 2'
          />
        ) : (
          <input
            id={id}
            disabled={disabled}
            maxLength={maxLength}
            name={name}
            onBlur={boundHandleBlur}
            onChange={boundHandleChange}
            placeholder={placeholder}
            ref={inputRef && boundHandleLoadFocus}
            type={type}
            value={value || ''}
          />
        )}
      </BaseInput>
    )
  }
}

export default TextInput
