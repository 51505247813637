import { css, useTheme } from '@emotion/react'
import { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'
import dayjs from 'dayjs'

import { numberToDollars } from '../../../utils'
import { Spacer, HelpIcon, TooltipText, SVGWrapper } from '../../../components'
import { Add } from '../../../assets/icons'
import { RangeScale } from '../elements'
import { CompareRow, CompareRowDualData } from './CompareRows'

const TableCard = styled.div`
  border: 1px solid #e6e9ea;
  background-color: #f0f4f5;
  box-shadow: 0 2px 2px 0 rgba(74, 96, 106, 0.6);
  padding: 0.75rem 1rem 1rem 1rem;
  margin: 14px 0;
  max-width: 1200px;
  overflow: auto;
`
const Table = styled.table`
  line-height: 1.35;
  overflow: auto;
  min-width: 650px;
  max-width: 1200px;
`
const TableTitle = styled.th`
  font-size: 20px;
  color: ${p => p.theme.mediumGray};
  text-align: left;
  font-weight: normal;
  vertical-align: top;
`
const TableHeader = styled.th`
  font-size: 16px;
  color: ${p => p.theme.mediumGray};
  text-align: left;
  padding: 0 12px 0 0;
  min-width: 180px;
  font-weight: normal;
  vertical-align: top;
`
const TableSideHeader = styled.th`
  font-size: 16px;
  color: ${p => p.theme.mediumGray};
  text-align: left;
  padding: 20px 0 8px;
  min-width: 350px;
  font-weight: normal;
  vertical-align: top;
`
const TableSpaceHeader = styled.th`
  padding: 8px 0;
`
const TableData = styled.td`
  color: ${p => p.theme.lightestGray};
  font-size: 12px;
  text-align: left;
  padding: 1px 0;
  min-width: 350px;
  vertical-align: top;
`
const TableValue = styled.td`
  color: ${p => p.theme.darkestGray};
  font-size: 12px;
  opacity: ${p => (p.difference === 0 ? '0' : null)};
  width: 25%;
  min-width: 180px;
  padding-right: 12px;
  vertical-align: top;
`
const CardTitle = styled.div`
  font-size: 20px;
  color: ${p => p.theme.mediumGray};
  text-align: left;
  font-weight: normal;
`
const CardSideHeader = styled.div`
  font-size: 16px;
  color: ${p => p.theme.mediumGray};
  text-align: left;
  margin: 6px 0;
  min-width: 350px;
  width: 350px;
  font-weight: normal;
  word-break: break-word;
  line-height: 1.35;
`
const DescriptionBox = styled.div`
  width: 350px;
  height: ${props => (props.scenarioDescription ? '80px' : '0px')};
  margin: 6px 0;
  word-break: break-word;
  line-height: 1.35;
  font-size: 0.875rem;
  padding: 8px;
  border: ${props => (props.scenarioDescription ? '1px solid #7a8e96' : '1px solid transparent')};
`
const ColorText = styled.span`
  color: ${p => (p.difference > 0 ? '#3F8B1E' : p.difference < 0 ? '#EF6B1D' : '#022a3a')};

  &::before {
    content: ${p => (p.prefix ? `'${p.prefix}'` : null)};
    color: #051f2c;
  }
  &::first-letter {
    color: ${p => (p.prefix && p.difference < 0 ? '#EF6B1D' : '#022a3a')};
  }

  &::after {
    content: ${p => (p.suffix ? `'${p.suffix}'` : null)};
    color: #051f2c;
  }
`
const FootnoteText = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 12px;
  line-height: 1.25;
  margin: 12px 0;
`
const CompareBack = styled.a`
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;
`
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 310px;
  color: ${p => p.theme.mediumGray};
  fill: ${p => p.theme.lightestGray};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.darkestGray};
    fill: ${p => p.theme.darkestGray};
  }
  &:active {
    color: #0495bf;
    fill: #0495bf;
  }
`

const selectedToText = (selected, saved) => {
  if (selected === 'modified') {
    return 'My most recent modifications'
  }
  if (selected === 'recommended') {
    return 'GuidedChoice recommendation'
  }
  if (selected === 'base') {
    return 'My current strategy'
  }
  if (_.startsWith(selected, 'saved')) {
    return saved[Number(selected[selected.length - 1])].name
  }
}

const CompareSelected = props => {
  const { selected, toggleSelected, store } = props
  const { compareValue, withValue } = selected
  const { savedScenarios, institutionalAccount, additionalEmployerSponsoredAccounts } = store

  const { displayName, includeSpouse } = store.person
  const { firstName } = store.spouse
  const theme = useTheme()

  const showInstitutionalAccount =
    institutionalAccount && institutionalAccount.contributionEligibility

  let compareData, withData
  if (_.startsWith(compareValue, 'saved')) {
    compareData = savedScenarios[Number(compareValue[compareValue.length - 1])]
  } else {
    compareData = store[compareValue + 'Case']
  }
  if (_.startsWith(withValue, 'saved')) {
    withData = savedScenarios[Number(withValue[withValue.length - 1])]
  } else {
    withData = store[withValue + 'Case']
  }

  const { riskLevelText: riskLevelTextCompare, riskScaleIndex: riskScaleIndexCompare } = compareData
  const { riskLevelText: riskLevelTextWith, riskScaleIndex: riskScaleIndexWith } = withData

  const comparePrimaryPostTax =
    !!compareData.primary.postTaxAnnualContribDollars ||
    !!compareData.primary.postTaxAnnualContributions ||
    !!withData.primary.postTaxAnnualContribDollars ||
    !!withData.primary.postTaxAnnualContributions

  const comparePrimaryRoth =
    !!compareData.primary.rothAnnualContribDollars ||
    !!compareData.primary.rothAnnualContributions ||
    !!withData.primary.rothAnnualContribDollars ||
    !!withData.primary.rothAnnualContributions

  const compare403b =
    !!compareData.total403bAmount ||
    !!compareData.total403bSavings ||
    !!withData.total403bAmount ||
    !!withData.total403bSavings

  const compare457 =
    !!compareData.total457Amount ||
    !!compareData.total457Savings ||
    !!withData.total457Amount ||
    !!withData.total457Savings

  const compareSpouse = includeSpouse && compareData.spouse && withData.spouse

  const compareSpousePostTax =
    compareSpouse &&
    (!!compareData.spouse.postTaxAnnualContribDollars ||
      !!compareData.spouse.postTaxAnnualContributions ||
      !!withData.spouse.postTaxAnnualContribDollars ||
      !!withData.spouse.postTaxAnnualContributions)

  const compareSpouseRoth =
    compareSpouse &&
    (!!compareData.spouse.rothAnnualContribDollars ||
      !!compareData.spouse.rothAnnualContributions ||
      !!withData.spouse.rothAnnualContribDollars ||
      !!withData.spouse.rothAnnualContributions)

  const compareCompanyStocks = compareData.companyStocks.map(
    ({
      amountToSell,
      percentToSell,
      positionId,
      restricted,
      securityId,
      securityName,
      totalValue,
    }) => ({
      amountToSell,
      percentToSell,
      positionId,
      restricted,
      securityId,
      securityName,
      totalValue,
    })
  )
  const withCompanyStocks = withData.companyStocks
    .map(
      ({
        amountToSell,
        percentToSell,
        positionId,
        restricted,
        securityId,
        securityName,
        totalValue,
      }) => ({
        amountToSell,
        percentToSell,
        positionId,
        restricted,
        securityId,
        securityName,
        totalValue,
      })
    )
    .filter(
      withStock =>
        !_.includes(
          compareCompanyStocks.map(compareStock => compareStock.positionId),
          withStock.positionId
        )
    )
  // Build Company Stocks list in case there might be some differing stocks between them
  const companyStocksList = compareCompanyStocks.concat(withCompanyStocks)
  const unrestrictedCompanyStocksList = companyStocksList.filter(
    companyStock => !companyStock.restricted
  )
  const restrictedCompanyStocksList = companyStocksList.filter(
    companyStock => companyStock.restricted
  )

  const compareStockOptions = compareData.stockOptions.map(
    ({ id, optionGrantNo, quantity, vestedDate }) => ({ id, optionGrantNo, quantity, vestedDate })
  )
  const withStockOptions = withData.stockOptions
    .map(({ id, optionGrantNo, quantity, vestedDate }) => ({
      id,
      optionGrantNo,
      quantity,
      vestedDate,
    }))
    .filter(
      withOption =>
        !_.includes(
          compareStockOptions.map(compareOption => compareOption.id),
          withOption.id
        )
    )
  // Build Stock Options list in case there might be some differing options between them
  const stockOptionsList = compareStockOptions.concat(withStockOptions)

  return (
    <div>
      <Spacer space='1rem' />

      <StyledButtonContainer onClick={toggleSelected}>
        <SVGWrapper svg={Add} size='large' />
        <CompareBack>&nbsp;View a different comparison</CompareBack>
      </StyledButtonContainer>

      <Spacer space='.75rem' />

      <div
        css={css`
          max-width: 1200px;
          text-align: right;
        `}>
        <HelpIcon
          tooltip={TooltipText.whyCantImplement()}
          helpLabel="Why can't I implement my saved scenario from here?"
        />
      </div>

      <Spacer space='.25rem' />

      <TableCard>
        <Table>
          <thead>
            <tr>
              <TableTitle>Strategy seeks</TableTitle>
              <TableHeader>{selectedToText(compareValue, savedScenarios.slice())}</TableHeader>
              <TableHeader>{selectedToText(withValue, savedScenarios.slice())}</TableHeader>
              <TableHeader>Difference</TableHeader>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableSideHeader>Monthly income after retiring</TableSideHeader>
            </tr>
            <CompareRow
              label="Spendable income (today's value)"
              compareCell={compareData.targetedAftTaxIncome}
              withCell={withData.targetedAftTaxIncome}
            />
            <CompareRow
              label='Percent of income replaced'
              compareCell={compareData.targetGoalPercent}
              withCell={withData.targetGoalPercent}
              unit='P'
            />

            <tr>
              <TableSideHeader>Retirement age</TableSideHeader>
            </tr>
            <CompareRow
              label={`${displayName || ''}`}
              compareCell={compareData.primary.retAge}
              withCell={withData.primary.retAge}
              reverseColor
              unit='none'
            />
            {compareSpouse && (
              <CompareRow
                label={`${firstName || ''}`}
                compareCell={compareData.spouse.retAge}
                withCell={withData.spouse.retAge}
                reverseColor
                unit='none'
              />
            )}

            <tr>
              <TableSideHeader>Other goals satisfying</TableSideHeader>
            </tr>
            <CompareRow
              label='Future amount needed for all included goals'
              compareCell={compareData.otherFinancialGoalsTotal}
              withCell={withData.otherFinancialGoalsTotal}
            />
            <tr>
              <TableData>Included goals</TableData>
              {compareData.goalNameList.length ? (
                <TableValue>{`${compareData.goalNameList}`}</TableValue>
              ) : (
                <td />
              )}
              {withData.goalNameList.length ? (
                <TableValue>{`${withData.goalNameList}`}</TableValue>
              ) : (
                <td />
              )}
              {/* <TableValue>{compareData}</TableValue>
                <TableValue>{withData}</TableValue> */}
            </tr>
          </tbody>
        </Table>
      </TableCard>

      <TableCard>
        <Table>
          <thead>
            <tr>
              <TableTitle>Strategy results</TableTitle>
              <TableHeader>{selectedToText(compareValue, savedScenarios.slice())}</TableHeader>
              <TableHeader>{selectedToText(withValue, savedScenarios.slice())}</TableHeader>
              <TableHeader>Difference</TableHeader>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableSideHeader>After-tax income strategy replaces after retiring</TableSideHeader>
            </tr>
            <CompareRow
              label='Percent of after-tax income strategy replaces'
              compareCell={compareData.retirementIncomeGoal}
              withCell={withData.retirementIncomeGoal}
              unit='P'
            />
            <CompareRowDualData
              label="Today's value annually | monthly"
              compareCellFirst={compareData.projectedMonthlyRetirementInc * 12}
              compareCellSecond={compareData.projectedMonthlyRetirementInc}
              withCellFirst={withData.projectedMonthlyRetirementInc * 12}
              withCellSecond={withData.projectedMonthlyRetirementInc}
            />
            <CompareRowDualData
              label='Value at retirement annually | monthly'
              compareCellFirst={compareData.projectedMonthlyInflationAdjustedRetirementInc * 12}
              compareCellSecond={compareData.projectedMonthlyInflationAdjustedRetirementInc}
              withCellFirst={withData.projectedMonthlyInflationAdjustedRetirementInc * 12}
              withCellSecond={withData.projectedMonthlyInflationAdjustedRetirementInc}
            />

            <tr>
              <TableSideHeader>Social Security</TableSideHeader>
            </tr>
            <tr>
              <TableData>{`${displayName}`}</TableData>
              {compareData.primary && compareData.primary.preTaxSSIncome !== null ? (
                <TableValue>
                  {`${compareData.primary.preTaxSSIncome ? 'Include' : 'Exclude'}`}
                </TableValue>
              ) : (
                <TableValue />
              )}
              {withData.primary && withData.primary.preTaxSSIncome !== null ? (
                <TableValue>
                  {`${withData.primary.preTaxSSIncome ? 'Include' : 'Exclude'}`}
                </TableValue>
              ) : (
                <TableValue />
              )}
            </tr>
            {includeSpouse && (
              <tr>
                <TableData>{`${firstName}`}</TableData>
                {compareData.spouse && compareData.spouse.preTaxSSIncome !== null ? (
                  <TableValue>
                    {`${compareData.spouse.preTaxSSIncome ? 'Include' : 'Exclude'}`}
                  </TableValue>
                ) : (
                  <TableValue />
                )}
                {withData.spouse && withData.spouse.preTaxSSIncome !== null ? (
                  <TableValue>
                    {`${withData.spouse.preTaxSSIncome ? 'Include' : 'Exclude'}`}
                  </TableValue>
                ) : (
                  <TableValue />
                )}
              </tr>
            )}
          </tbody>
        </Table>
      </TableCard>

      <TableCard>
        <Table>
          <thead>
            <tr>
              <TableTitle>Investment accounts outlook</TableTitle>
              <TableHeader>{selectedToText(compareValue, savedScenarios.slice())}</TableHeader>
              <TableHeader>{selectedToText(withValue, savedScenarios.slice())}</TableHeader>
              <TableHeader>Difference</TableHeader>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TableSideHeader />
            </tr>
            <CompareRow
              label="What you can expect (today's value)"
              compareCell={compareData.estimatedFutureValue}
              withCell={withData.estimatedFutureValue}
            />
            <CompareRow
              label='What you can expect (value at retirement)'
              compareCell={compareData.estimatedInflationAdjustedValue}
              withCell={withData.estimatedInflationAdjustedValue}
            />
            <CompareRow
              label="What you've saved (today's value)"
              compareCell={compareData.currentTotalBalance}
              withCell={withData.currentTotalBalance}
            />
          </tbody>
        </Table>
      </TableCard>

      {compareData.primary && withData.primary && (
        <TableCard>
          <Table>
            <thead>
              <tr>
                <TableTitle>How to get there</TableTitle>
                <TableHeader>{selectedToText(compareValue, savedScenarios.slice())}</TableHeader>
                <TableHeader>{selectedToText(withValue, savedScenarios.slice())}</TableHeader>
                <TableHeader>Difference</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableSideHeader>Employer plan account contributions*</TableSideHeader>
              </tr>

              {showInstitutionalAccount && (
                <>
                  <CompareRowDualData
                    label={`${displayName ? `${displayName}'s` : ''} ${
                      institutionalAccount.name
                    } annual total`}
                    compareCellFirst={compareData.totalAnnualSavings}
                    compareCellSecond={compareData.totalAnnualPercentage}
                    withCellFirst={withData.totalAnnualSavings}
                    withCellSecond={withData.totalAnnualPercentage}
                    unit='DP'
                  />
                  <CompareRowDualData
                    label='Pre-tax annual savings | percent of income'
                    compareCellFirst={compareData.primary.preTaxAnnualContribDollars}
                    compareCellSecond={compareData.primary.preTaxAnnualContributions}
                    withCellFirst={withData.primary.preTaxAnnualContribDollars}
                    withCellSecond={withData.primary.preTaxAnnualContributions}
                    unit='DP'
                  />
                  {comparePrimaryPostTax && (
                    <CompareRowDualData
                      label='After-tax annual savings | percent of income'
                      compareCellFirst={compareData.primary.postTaxAnnualContribDollars}
                      compareCellSecond={compareData.primary.postTaxAnnualContributions}
                      withCellFirst={withData.primary.postTaxAnnualContribDollars}
                      withCellSecond={withData.primary.postTaxAnnualContributions}
                      unit='DP'
                    />
                  )}
                  {comparePrimaryRoth && (
                    <CompareRowDualData
                      label='Roth annual savings | percent of income'
                      compareCellFirst={compareData.primary.rothAnnualContribDollars}
                      compareCellSecond={compareData.primary.rothAnnualContributions}
                      withCellFirst={withData.primary.rothAnnualContribDollars}
                      withCellSecond={withData.primary.rothAnnualContributions}
                      unit='DP'
                    />
                  )}
                </>
              )}

              {additionalEmployerSponsoredAccounts.map(account => (
                <tr key={account.type + account.id}>
                  <TableData
                    css={
                      showInstitutionalAccount
                        ? css`
                            padding-top: 1rem;
                          `
                        : css``
                    }>
                    {displayName ? `${displayName}'s` : ''} {account.name}
                  </TableData>
                </tr>
              ))}

              {compare403b && (
                <>
                  <tr>
                    <TableSpaceHeader />
                  </tr>
                  <CompareRowDualData
                    label={displayName ? `${displayName}'s 403b annual total` : '403b annual total'}
                    compareCellFirst={compareData.total403bAmount}
                    compareCellSecond={compareData.total403bSavings}
                    withCellFirst={withData.total403bAmount}
                    withCellSecond={withData.total403bSavings}
                    unit='DP'
                  />
                  <CompareRowDualData
                    label='Pre-tax annual savings | percent of income'
                    compareCellFirst={compareData.primary.preTax403bAnnualContribDollars}
                    compareCellSecond={compareData.primary.preTax403bAnnualContributions}
                    withCellFirst={withData.primary.preTax403bAnnualContribDollars}
                    withCellSecond={withData.primary.preTax403bAnnualContributions}
                    unit='DP'
                  />
                </>
              )}

              {compare457 && (
                <>
                  <tr>
                    <TableSpaceHeader />
                  </tr>
                  <CompareRowDualData
                    label={displayName ? `${displayName}'s 457 annual total` : '457 annual total'}
                    compareCellFirst={compareData.total457Amount}
                    compareCellSecond={compareData.total457Savings}
                    withCellFirst={withData.total457Amount}
                    withCellSecond={withData.total457Savings}
                    unit='DP'
                  />
                  <CompareRowDualData
                    label='Pre-tax annual savings | percent of income'
                    compareCellFirst={compareData.primary.preTax457AnnualContribDollars}
                    compareCellSecond={compareData.primary.preTax457AnnualContributions}
                    withCellFirst={withData.primary.preTax457AnnualContribDollars}
                    withCellSecond={withData.primary.preTax457AnnualContributions}
                    unit='DP'
                  />
                </>
              )}

              <tr>
                <TableSpaceHeader />
              </tr>

              {compareSpouse && (
                <>
                  <CompareRowDualData
                    label={
                      firstName
                        ? `${firstName}'s annual total`
                        : "Spouse's contributions annual total"
                    }
                    compareCellFirst={compareData.totalAnnualSavingsSpouse}
                    compareCellSecond={compareData.totalAnnualPercentageSpouse}
                    withCellFirst={withData.totalAnnualSavingsSpouse}
                    withCellSecond={withData.totalAnnualPercentageSpouse}
                    unit='DP'
                  />
                  <CompareRowDualData
                    label='Pre-tax annual savings | percent of income'
                    compareCellFirst={compareData.spouse.preTaxAnnualContribDollars}
                    compareCellSecond={compareData.spouse.preTaxAnnualContributions}
                    withCellFirst={withData.spouse.preTaxAnnualContribDollars}
                    withCellSecond={withData.spouse.preTaxAnnualContributions}
                    unit='DP'
                  />
                  {compareSpousePostTax && (
                    <CompareRowDualData
                      label='After-tax annual savings | percent of income'
                      compareCellFirst={compareData.spouse.postTaxAnnualContribDollars}
                      compareCellSecond={compareData.spouse.postTaxAnnualContributions}
                      withCellFirst={withData.spouse.postTaxAnnualContribDollars}
                      withCellSecond={withData.spouse.postTaxAnnualContributions}
                      unit='DP'
                    />
                  )}
                  {compareSpouseRoth && (
                    <CompareRowDualData
                      label='Roth annual savings | percent of income'
                      compareCellFirst={compareData.spouse.rothAnnualContribDollars}
                      compareCellSecond={compareData.spouse.rothAnnualContributions}
                      withCellFirst={withData.spouse.rothAnnualContribDollars}
                      withCellSecond={withData.spouse.rothAnnualContributions}
                      unit='DP'
                    />
                  )}
                </>
              )}

              <tr>
                <TableSideHeader>
                  Savings in addition to retirement & other accounts
                </TableSideHeader>
              </tr>
              <CompareRowDualData
                label='Total additional annual | per month savings'
                compareCellFirst={compareData.outOfPlanMonthlySavings * 12}
                compareCellSecond={compareData.outOfPlanMonthlySavings}
                withCellFirst={withData.outOfPlanMonthlySavings * 12}
                withCellSecond={withData.outOfPlanMonthlySavings}
              />

              {companyStocksList.length > 0 && (
                <tr>
                  <TableSideHeader>{displayName}'s company stock**</TableSideHeader>
                </tr>
              )}
              {unrestrictedCompanyStocksList.length > 0 && (
                <tr>
                  <TableData>UNRESTRICTED</TableData>
                </tr>
              )}
              {unrestrictedCompanyStocksList.map(companyStock => (
                <CompareRowDualData
                  key={companyStock.positionId}
                  label={companyStock.securityName + ' percent to sell | approximate worth'}
                  unit='PD'
                  compareCellFirst={_.get(
                    compareData.companyStocks.find(
                      stock => stock.positionId === companyStock.positionId
                    ),
                    'percentToSell'
                  )}
                  compareCellSecond={_.get(
                    compareData.companyStocks.find(
                      stock => stock.positionId === companyStock.positionId
                    ),
                    'amountToSell'
                  )}
                  withCellFirst={_.get(
                    withData.companyStocks.find(
                      stock => stock.positionId === companyStock.positionId
                    ),
                    'percentToSell'
                  )}
                  withCellSecond={_.get(
                    withData.companyStocks.find(
                      stock => stock.positionId === companyStock.positionId
                    ),
                    'amountToSell'
                  )}
                />
              ))}

              {restrictedCompanyStocksList.length > 0 && (
                <tr>
                  <TableData>RESTRICTED</TableData>
                </tr>
              )}
              {restrictedCompanyStocksList.map(companyStock => (
                <CompareRowDualData
                  key={companyStock.positionId}
                  label={companyStock.securityName + ' percent to sell | approximate worth'}
                  unit='PD'
                  compareCellFirst={_.get(
                    compareData.companyStocks.find(
                      stock => stock.positionId === companyStock.positionId
                    ),
                    'percentToSell'
                  )}
                  compareCellSecond={_.get(
                    compareData.companyStocks.find(
                      stock => stock.positionId === companyStock.positionId
                    ),
                    'amountToSell'
                  )}
                  withCellFirst={_.get(
                    withData.companyStocks.find(
                      stock => stock.positionId === companyStock.positionId
                    ),
                    'percentToSell'
                  )}
                  withCellSecond={_.get(
                    withData.companyStocks.find(
                      stock => stock.positionId === companyStock.positionId
                    ),
                    'amountToSell'
                  )}
                />
              ))}

              {stockOptionsList.length > 0 && (
                <tr>
                  <TableSideHeader>Vested stock options</TableSideHeader>
                </tr>
              )}
              {stockOptionsList.map(({ id, optionGrantNo, quantity, vestedDate }) => (
                <Fragment key={id}>
                  <tr>
                    <TableData>
                      Grant {optionGrantNo} | {numberToDollars(quantity, true)} options | Vested{' '}
                      {dayjs(vestedDate).format('M/D/YYYY')}
                    </TableData>
                    <TableValue>
                      {_.get(
                        compareData.stockOptions.find(option => option.id === id),
                        'included'
                      )
                        ? 'Include'
                        : 'Exclude'}
                    </TableValue>
                    <TableValue>
                      {_.get(
                        withData.stockOptions.find(option => option.id === id),
                        'included'
                      )
                        ? 'Include'
                        : 'Exclude'}
                    </TableValue>
                  </tr>
                </Fragment>
              ))}

              <tr>
                <TableSideHeader>Risk</TableSideHeader>
              </tr>
              <tr>
                <TableData>All accounts for which providing investment advice</TableData>
                <TableValue>{riskLevelTextCompare}</TableValue>
                <TableValue>
                  <ColorText difference={riskScaleIndexWith >= 0}>{riskLevelTextWith}</ColorText>
                </TableValue>
                <TableValue />
              </tr>
              <tr>
                <TableData />
                <TableValue>
                  {riskScaleIndexCompare >= 0 && (
                    <RangeScale rectangleActive={riskScaleIndexCompare} theme={theme} />
                  )}
                </TableValue>
                <TableValue>
                  {riskScaleIndexWith >= 0 && (
                    <RangeScale rectangleActive={riskScaleIndexWith} theme={theme} />
                  )}
                </TableValue>
                <TableValue />
              </tr>
            </tbody>
          </Table>
          <div
            css={css`
              margin: 24px 0 0;
            `}>
            <FootnoteText>
              * Contribution amounts shown are annualized and rounded up to the next whole
              percentage equivalent. The actual contribution total (and what GuidedChoice uses when
              projecting results) will be limited to IRS and plan rule maximums.
            </FootnoteText>
            {companyStocksList.length > 0 && (
              <FootnoteText>
                ** Proceeds from the sale of company stock are allocated proportionately among the
                other investments in your account.
              </FootnoteText>
            )}
          </div>
        </TableCard>
      )}

      {(Boolean(compareData.scenarioDescription) || Boolean(withData.scenarioDescription)) && (
        <TableCard>
          <CardTitle>Scenario descriptions</CardTitle>

          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              margin-top: 14px;
            `}>
            <CardSideHeader>{selectedToText(compareValue, savedScenarios.slice())}</CardSideHeader>
            <DescriptionBox scenarioDescription={Boolean(compareData.scenarioDescription)}>
              {compareData.scenarioDescription}
            </DescriptionBox>
          </div>

          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              margin-top: 14px;
            `}>
            <CardSideHeader>{selectedToText(withValue, savedScenarios.slice())}</CardSideHeader>
            <DescriptionBox scenarioDescription={Boolean(withData.scenarioDescription)}>
              {withData.scenarioDescription}
            </DescriptionBox>
          </div>
        </TableCard>
      )}
    </div>
  )
}

export default inject('store')(observer(CompareSelected))
