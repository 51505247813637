import { css } from '@emotion/react'
import { FieldArray } from 'react-final-form-arrays'
import dayjs from 'dayjs'

import { ButtonInput } from '../../../components'
import { Fields, numberToDollars } from '../../../utils'
import { BorderedCard, CardTitle } from './EditDrawerStyle'

const StockOption = ({
  optionGrantNo,
  quantity,
  vestedDate,
  grantDate,
  expirationDate,
  exercisePrice,
  currentPrice,
  included,
}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        margin: 36px 0;
      `}>
      <div
        css={css`
          flex: 0 1 650px;
        `}>
        <div
          css={css`
            color: #4a606a;
            font-size: 1.25rem;
            line-height: 1.35;
          `}>
          Grant {optionGrantNo.input.value}
          &nbsp;&nbsp;|&nbsp;&nbsp;
          {numberToDollars(quantity.input.value, true)} options &nbsp;&nbsp;|&nbsp;&nbsp; Vested{' '}
          {dayjs(vestedDate.input.value).format('M/D/YYYY')}
        </div>
        <div
          css={css`
            color: #7a8e96;
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 1.5;
          `}>
          Grant date {dayjs(grantDate.input.value).format('M/D/YYYY')}
          &nbsp;&nbsp;|&nbsp;&nbsp;Expiration date{' '}
          {dayjs(expirationDate.input.value).format('M/D/YYYY')}
        </div>
        <div
          css={css`
            color: #7a8e96;
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 1.5;
          `}>
          Exercise price ${numberToDollars(exercisePrice.input.value)}
          &nbsp;&nbsp;|&nbsp;&nbsp;Current price ${numberToDollars(currentPrice.input.value)}
        </div>
      </div>
      <div
        css={css`
          flex: 1 1 450px;
          margin: 12px 0 0;
        `}>
        <ButtonInput
          name='include'
          text='Include'
          isActive={included.input.value}
          value
          onClick={(name, value) => included.input.onChange(value)}
          width='200px'
        />
        <ButtonInput
          name='exclude'
          text='Exclude'
          isActive={!included.input.value}
          value={false}
          onClick={(name, value) => included.input.onChange(value)}
          width='200px'
        />
      </div>
    </div>
  )
}

const StockOptions = ({ fields }) => (
  <div
    css={css`
      & > div:first-of-type {
        margin-top: 12px;
      }
      & > div:last-of-type {
        margin-bottom: 1rem;
      }
    `}>
    {fields.map((name, index) => {
      const fieldNames = [
        `${name}.optionGrantNo`,
        `${name}.quantity`,
        `${name}.vestedDate`,
        `${name}.grantDate`,
        `${name}.expirationDate`,
        `${name}.exercisePrice`,
        `${name}.currentPrice`,
        `${name}.included`,
      ]

      return (
        <Fields key={name} names={fieldNames}>
          {fieldsState => {
            const [
              optionGrantNo,
              quantity,
              vestedDate,
              grantDate,
              expirationDate,
              exercisePrice,
              currentPrice,
              included,
            ] = fieldNames

            const stockOptionProps = {
              optionGrantNo: fieldsState[optionGrantNo],
              exercisePrice: fieldsState[exercisePrice],
              currentPrice: fieldsState[currentPrice],
              expirationDate: fieldsState[expirationDate],
              vestedDate: fieldsState[vestedDate],
              grantDate: fieldsState[grantDate],
              quantity: fieldsState[quantity],
              included: fieldsState[included],
            }

            return <StockOption {...stockOptionProps} />
          }}
        </Fields>
      )
    })}
  </div>
)

const MakeChangesStockOptions = ({ store, editable }) => {
  return editable.stockOptionsAreEditable ? (
    <BorderedCard>
      <CardTitle>Vested stock options</CardTitle>

      <FieldArray name='stockOptions' component={StockOptions} />
    </BorderedCard>
  ) : null
}

export default MakeChangesStockOptions
