import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Page, PageHeader } from '../elements'
import { CalendarReminders } from './blocks'
import { MainTitle, FootNote, ContentContainer, TodoBox } from './styled'

class MoreTodoPage extends Component {
  static displayName = 'MoreTodoPage'
  static propTypes = {}

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'moreTodo' })
  }

  calendarReminderDates = () => {
    return <CalendarReminders />
  }

  render() {
    const page = this.props.guide.guidePages.pages.findByName('moreTodo')

    return (
      <Page header={<PageHeader />} page={page}>
        <TodoBox data-todo-box>
          <ContentContainer>
            <MainTitle>MORE TO-DOS</MainTitle>
            <div
              css={css`
                padding: 10px 0;
              `}
            />
            {this.calendarReminderDates() || null}
          </ContentContainer>
        </TodoBox>
        <FootNote>
          Your plan and the income you can expect at retirement rely on you making the changes
          you've modeled, then returning each year to update your balances and letting us know of
          any changes affecting your plan so that we can update our advice.
        </FootNote>
      </Page>
    )
  }
}

export default inject('store', 'guide')(observer(MoreTodoPage))
