import { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import GoalsStore from '../../models/Goals'
import { Goal, AddGoal } from './forms'
import { GoalsIncomeReplacement } from './IncomeReplacement'
import { GoalsDashboard } from './dashboard'
import { Page } from './elements'

class Goals extends Component {
  constructor(props) {
    super(props)
    const { store } = props
    this.state = GoalsStore.create({}, { store })
  }

  render() {
    const { location } = this.props

    return (
      <Page.Container>
        <Switch location={location}>
          <Route path='/goals/add' component={AddGoal} />
          <Route path='/goals/replace-income' component={GoalsIncomeReplacement} />
          <Route path='/goals/:id/:edit?' component={Goal} />
          <Route path='/goals' component={GoalsDashboard} />
        </Switch>
      </Page.Container>
    )
  }
}

export default inject('store')(observer(Goals))
