const BulletInvestment = `
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"/>
            <path d="M10 10h30v30H10z"/>
            <g fill="#02769D" fill-rule="nonzero">
                <path d="M14.82 23.786h3.663c.725 0 1.313.587 1.313 1.313v6.03h-6.29V25.1c0-.726.587-1.313 1.313-1.313zM23.217 19.587h3.664c.725 0 1.313.587 1.313 1.313v10.23h-6.29V20.9c0-.726.588-1.313 1.313-1.313zM31.598 15.388h3.663c.726 0 1.314.587 1.314 1.313V31.13h-6.29V16.7c0-.725.587-1.312 1.313-1.312zM12.452 33.48c0-.432.345-.795.795-.795H36.85c.432 0 .795.345.795.795a.793.793 0 0 1-.795.795H13.247a.804.804 0 0 1-.795-.795z"/>
            </g>
        </g>
    </svg>
`

export default BulletInvestment
