import { cloneElement, Children, Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import styled from '@emotion/styled'
import uuid from 'uuid/v4'
import { PageBackground, PageContent } from './'

const getSizes = nodes =>
  _.map(nodes, node => ({
    children: node.children.length ? getSizes(node.children) : [],
    height: node.offsetHeight,
    width: node.offsetWidth,
  }))

const getPageContentMapping = (contentSizes, availableContentHeight) =>
  _.reduce(
    contentSizes,
    (indexMap, size, index) => {
      const map = indexMap[indexMap.length - 1]

      const newHeight = map.height + size.height
      if (newHeight < availableContentHeight) {
        map.height = newHeight
        map.indexes.push(index)
      } else {
        const willOverflow = size.height > availableContentHeight
        // remove default value on first iteration
        if (index === 0) indexMap.shift()
        indexMap.push({
          height: size.height,
          indexes: [index],
          pages: getPageContentMapping(size.children, availableContentHeight),
          willOverflow,
        })
      }

      return indexMap
    },
    [{ height: 0, indexes: [] }]
  )

const Container = styled.div`
  /*
    Height is fixed at 11" based on Chrome default 72 points per inch print
    setting. This works out to roughly 960px.
  */
  height: 792pt;
  max-height: 792pt;
  page-break-before: always;
  /* TODO set background image */
`

const Relative = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

class Page extends Component {
  constructor(props) {
    super(props)
    this.contentContainer = createRef()
    this.pageContainer = createRef()
    this.headerContainer = createRef()
    this.footerContainer = createRef()
  }
  static displayName = 'Page'

  static propTypes = {
    footer: PropTypes.node,
    footerOnlyOnLastPage: PropTypes.bool,
    header: PropTypes.node,
    page: PropTypes.object.isRequired,
  }

  static defaultProps = {
    footerOnlyOnLastPage: false,
    headerOnlyOnLastPage: false,
  }

  state = { contentSizes: [] }

  componentDidMount() {
    this.setSizes()
  }

  setSizes() {
    // check height of first child due to `position: absolute` on child.
    const footerContainer = this.footerContainer.current?.children[0]
    const contentSizes = getSizes(this.contentContainer.current?.children)
    let wrapperSize = null

    if (contentSizes.length === 1) {
      const wrapperFullHeight = contentSizes[0].height
      const wrapperChildrenHeight = _.reduce(
        contentSizes[0].children,
        (height, child) => height + child.height,
        0
      )
      wrapperSize = wrapperFullHeight - wrapperChildrenHeight
    }

    this.setState({ contentSizes })
    this.props.page.setSizes(
      {
        height: this.pageContainer.current?.offsetHeight,
        width: this.pageContainer.current?.offsetWidth,
      },
      {
        height: this.headerContainer.current?.offsetHeight,
        width: this.headerContainer.current?.offsetWidth,
      },
      {
        height: footerContainer ? footerContainer.current?.offsetHeight : 0,
        width: footerContainer ? footerContainer.current?.offsetWidth : 0,
      },
      _.sumBy(contentSizes, size => size.height),
      wrapperSize
    )
  }

  getPageContent(content, pageContentMapping, wrapper) {
    return _.reduce(
      pageContentMapping,
      (pages, contentMap, index) => {
        const pageContent = contentMap.indexes.map(idx => content[idx])
        const isLastPage = index === pageContentMapping.length - 1
        if (pageContent.length) {
          const newContent = wrapper
            ? [cloneElement(wrapper, wrapper.props, pageContent)]
            : pageContent

          const newPages = contentMap.willOverflow
            ? this.getPageContent(
                Children.toArray(pageContent[0].props.children),
                contentMap.pages,
                wrapper
              ) // eslint-disable-next-line
            : this.renderPage.call(this, newContent, isLastPage)

          pages = pages.concat(newPages)
        }
        return pages
      },
      []
    )
  }

  renderAllContent() {
    // eslint-disable-next-line
    return this.renderPage.call(this, this.props.children, true)
  }

  renderContentWithPageBreaks() {
    const {
      page: { availableContentHeight },
    } = this.props
    const { contentSizes } = this.state
    const children = Children.toArray(this.props.children)
    const wrapper = children.length === 1 ? children[0] : null
    const pageContentMapping = getPageContentMapping(contentSizes, availableContentHeight)
    // eslint-disable-next-line
    const pages = this.getPageContent.call(this, children, pageContentMapping, wrapper)
    return pages
  }

  renderPage(content, isLastPage) {
    const { footer, footerOnlyOnLastPage, header } = this.props
    const showFooter = footerOnlyOnLastPage ? (isLastPage ? footer : false) : footer

    return (
      <Container key={uuid()} data-paging-key={uuid()} ref={this.pageContainer}>
        <Relative>
          <PageBackground />
          <div ref={this.headerContainer}>{header && header}</div>
          <PageContent ref={this.contentContainer}>{content}</PageContent>
          <div ref={this.footerContainer}>{showFooter && footer}</div>
        </Relative>
      </Container>
    )
  }

  render() {
    const {
      page: { calculatingSizes, contentIsOverflowing },
    } = this.props

    const renderFn =
      calculatingSizes || (!calculatingSizes && !contentIsOverflowing)
        ? this.renderAllContent.bind(this)
        : this.renderContentWithPageBreaks.bind(this)

    return renderFn()
  }
}

export default inject('store')(observer(Page))
