/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useEffect, useState, useMemo } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

import { Button, Loading, Drawer, Spacer, FormError } from '../../../components'
import { numberToDollars, companyStocksAt100PercentForSale } from '../../../utils/utils'
import UnderstandingYourResults from '../understanding-your-results/UnderstandingYourResults'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { Page } from './style'

import CanYouCoverExpenses from './shared-components/CanYouCoverExpenses'
import ExtendedLifeExpectancy from './shared-components/ExtendedLifeExpectancy'
import WorkPartTimeAfterRetiring from './shared-components/WorkPartTimeAfterRetiring'
import ChangeRetirementAge from './shared-components/ChangeRetirementAge'
import AddAnEmergencyFund from './shared-components/AddAnEmergencyFund'
import SellCompanyStock from './shared-components/SellCompanyStock'
import { emergencyFundCalculator } from './utils/util'
import { schema } from './utils/validate'
import { reduceValidationError } from '../../../utils'

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

function OneLongLife(props) {
  const {
    history,
    store: {
      processModifiedCaseWithoutSaving,
      allSpendingRecommendedCases,
      spendingModifiedCases,
      totalPrimaryBalanceExcludingPensionAndAnnuity,
      emergencyFund,
      allSpendingBaseCases,
    },
  } = props

  const [modifiedMonthlyIncome, setModifiedMonthlyIncome] = useState({})
  const [status, setStatus] = useState('loading')
  const [numberStatus, setNumberStatus] = useState('idle')

  useEffect(() => {
    const fetchData = async () => {
      try {
        setModifiedMonthlyIncome(spendingModifiedCases)
        setStatus('success')
      } catch (err) {
        setStatus('error')
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  const handlePost = async (values, form) => {
    try {
      setNumberStatus('loading')
      const companyStocksToBeSold = values.companyStocks.filter(stock => stock.percentToSell > 0)
      const formatCompanyStockForSubmit =
        companyStocksToBeSold.length > 0
          ? companyStocksToBeSold
              .map(stock => {
                return stock.aggregatedPositionIds.map(positionId => ({
                  percentToSell: stock.percentToSell,
                  positionId: positionId,
                }))
              })
              .flat()
          : null
      let primaryUpdates = {}
      if (values.clientRetirement) {
        primaryUpdates = {
          primary: {
            retAge: values.clientRetirement ? parseInt(values.clientRetirement) : null,
          },
        }
      }
      const result = await processModifiedCaseWithoutSaving({
        emergencyFund: values.emergencyFundInput ? values.emergencyFundInput : null,
        ...primaryUpdates,
        primaryRetirementWork: {
          ...props.primaryRetirementStatus,
          durationStartingAge: values.durationStartingAgeClient
            ? values.durationStartingAgeClient
            : null,
          durationStoppingAge: values.durationStoppingAgeClient
            ? values.durationStoppingAgeClient
            : null,
          annualIncome: values.clientIncome,
          planningWorkPartTime: values.planningWorkPartTimeClient ? 1 : 0,
        },
        participantExpectedLifespan: parseInt(values.primarySlider),
        companyStocks: formatCompanyStockForSubmit,
      })
      setNumberStatus('success')
      setModifiedMonthlyIncome(result)
      form.mutators.resetCanCoverExpenses()
      form.mutators.hideCanCoverExpenses()
      form.mutators.displayCanCoverExpenses()
    } catch (err) {
      setNumberStatus('error')
      console.error(err)
      return { [FORM_ERROR]: 'Something went wrong, please try again later.' }
    }
  }

  const onSubmit = (values, form) => {
    document.getElementById('main-app') && (document.getElementById('main-app').scrollTop = 0)
    return handlePost(values, form)
  }

  const companyStocks = _.orderBy(
    _.get(allSpendingBaseCases, 'companyStocks', null).filter(
      companyStock => !companyStock.restricted
    ),
    [stock => stock.percentToSell, stock => stock.securityName.toLowerCase()],
    ['desc', 'asc']
  )

  const initialValues = useMemo(
    () => {
      return {
        seeWhy: false,
        canCoverExpenses: null,
        displayCanCoverExpenses: false,
        primarySlider: _.get(
          allSpendingRecommendedCases,
          'averageMarketSpendingAdvice.primary.lifeExpectancyAge',
          null
        ),
        clientRetirement: _.get(
          spendingModifiedCases,
          'averageMarketSpendingAdvice.primary.retAge',
          null
        ),
        planningWorkPartTimeClient: Boolean(
          _.get(props, 'primaryRetirementStatus.planningWorkPartTime', null)
        ),
        durationStartingAgeClient: _.get(
          props,
          'primaryRetirementStatus.durationStartingAge',
          null
        ),
        durationStoppingAgeClient: _.get(
          props,
          'primaryRetirementStatus.durationStoppingAge',
          null
        ),
        clientIncome: _.get(props, 'primaryRetirementStatus.annualIncome', null),
        emergencyFundInput: _.get(emergencyFund, 'amount', 0),
        maximumEmergencyFundInput: totalPrimaryBalanceExcludingPensionAndAnnuity,
        companyStocks: companyStocksAt100PercentForSale(companyStocks),
      }
    },
    [] // eslint-disable-line
  )

  const mutators = {
    toggleSeeWhy: (args, state, utils) => {
      utils.changeValue(state, 'seeWhy', values => !state.formState.values.seeWhy)
    },
    hideCanCoverExpenses: (args, state, utils) => {
      utils.changeValue(state, 'displayCanCoverExpenses', () => false)
    },
    displayCanCoverExpenses: (args, state, utils) => {
      utils.changeValue(state, 'displayCanCoverExpenses', () => true)
    },
    resetCanCoverExpenses: (args, state, utils) => {
      utils.changeValue(state, 'canCoverExpenses', () => null)
    },
  }

  const validate = values => {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  if (status === 'error') {
    return (
      <div
        css={css`
          color: #e31e27;
          font-size: 14px;
          line-height: 1.5;
          text-align: center;
          padding: 0.25rem 0 0.75rem 0;
        `}>
        Something went wrong, please try again later.
      </div>
    )
  }

  return status === 'success' ? (
    <Page.Container>
      <Form
        mutators={{ ...mutators, ...arrayMutators }}
        decorators={[emergencyFundCalculator]}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ form, handleSubmit, submitting, values, submitError }) => (
          <Page.MainWrapper>
            <span
              css={css`
                font-size: 14px;
              `}>
              Considerations
            </span>
            <Page.PageTitle>If you live a long happy life...</Page.PageTitle>
            <Page.Wrapper>
              <Page.LeftSection>
                <Page.IncomeExpectancyCard>
                  <div
                    css={css`
                      font-size: 32px;
                      font-weight: 300;
                    `}>
                    Monthly income you can expect
                  </div>
                  <Page.IncomeValue>
                    {numberStatus === 'loading' ? (
                      <span>
                        <Loading />
                      </span>
                    ) : (
                      numberToDollars(
                        _.get(
                          modifiedMonthlyIncome,
                          'averageMarketSpendingAdvice.monthlyIncome',
                          null
                        ),
                        true
                      )
                    )}
                  </Page.IncomeValue>
                  {_.get(
                    modifiedMonthlyIncome,
                    'spendingScenario.budget.dollarAmountDesired',
                    null
                  ) <=
                    _.get(
                      modifiedMonthlyIncome,
                      'averageMarketSpendingAdvice.monthlyIncome',
                      null
                    ) && (
                    <Page.CanAffordNeeds>
                      Well done, you are meeting your desired monthly spending total, which includes
                      your income need and want!
                    </Page.CanAffordNeeds>
                  )}
                  <Button
                    type='button'
                    secondary
                    label='See why'
                    css={css`
                      background-color: #7a8e96;
                      color: #fff;
                      width: 200px;
                      height: 51px;
                    `}
                    onClick={form.mutators.toggleSeeWhy}
                  />
                </Page.IncomeExpectancyCard>
                <Drawer active={values.seeWhy} width='100%'>
                  <UnderstandingYourResults
                    setDrawer={form.mutators.toggleSeeWhy}
                    handleCancel={form.mutators.toggleSeeWhy}
                    modifiedMonthlyIncome={modifiedMonthlyIncome}
                  />
                </Drawer>
                {values.displayCanCoverExpenses && (
                  <CanYouCoverExpenses modifiedMonthlyIncome={modifiedMonthlyIncome} />
                )}
              </Page.LeftSection>
              <Page.RightSection>
                <Page.BorderedCard>
                  <Page.CardLabel>
                    Extend life expectancy to see the impact on income
                    <Spacer space='5px' />
                    <Page.Note>
                      Please note: GuidedChoice plans for 10 years beyond the life expectancy age to
                      help prevent running out of money.
                    </Page.Note>
                  </Page.CardLabel>
                  <ExtendedLifeExpectancy
                    primaryFrozenValue={_.get(
                      allSpendingRecommendedCases,
                      'averageMarketSpendingAdvice.primary.lifeExpectancyAge',
                      null
                    )}
                  />

                  <Spacer space='20px' />

                  <Condition when='canCoverExpenses' is={false}>
                    <div
                      css={css`
                        margin-top: 10px;
                        font-size: 18px;
                        line-height: 24px;
                        color: #4a606a;
                        position: relative;
                      `}>
                      <Page.ConsiderationTryText>
                        Try adjusting some other considerations to see what helps
                      </Page.ConsiderationTryText>
                      <Spacer space='5px' />

                      <ChangeRetirementAge modifiedMonthlyIncome={modifiedMonthlyIncome} />

                      <WorkPartTimeAfterRetiring />

                      {!_.get(emergencyFund, 'amount', 0) > 0 && <AddAnEmergencyFund />}

                      <SellCompanyStock />

                      <Spacer space='10px' />
                    </div>
                  </Condition>

                  <Button
                    type='button'
                    onClick={handleSubmit}
                    disabled={submitting}
                    primary
                    label='Recalculate'
                    width='140px'
                    css={css`
                      height: 45px;
                      align-self: flex-end;
                      font-family: 'Open Sans';
                      line-height: 18px;
                      text-align: center;
                    `}
                  />

                  <Condition when='canCoverExpenses' is={false}>
                    <Spacer space='15px' />
                    <Page.ConsiderationDisclaimerText>
                      Return to Overall Strategy to review Social Security, pension, and other
                      lifetime income selections, or click "income needs & wants" to review your
                      budget
                    </Page.ConsiderationDisclaimerText>
                  </Condition>
                </Page.BorderedCard>
              </Page.RightSection>
            </Page.Wrapper>

            {submitError && <FormError err={submitError} />}

            {values.canCoverExpenses === null ? (
              <ButtonsBox
                css={css`
                  max-width: 100%;
                `}>
                <BackButton
                  css={css`
                    font-family: 'Open Sans';
                    line-height: 18px;
                    text-align: center;
                  `}
                  backgroundColor='#FFFFFF'
                  onClick={() => history.goBack()}>
                  Cancel
                </BackButton>
              </ButtonsBox>
            ) : (
              <ButtonsBox
                css={css`
                  max-width: 100%;
                  justify-content: flex-end;
                `}>
                <NextButton
                  css={css`
                    color: #ffffff;
                    font-family: 'Open Sans';
                    line-height: 18px;
                    text-align: center;
                    width: 330px;
                  `}
                  onClick={() => history.push('/spending/considerations')}>
                  Return to other considerations
                </NextButton>
              </ButtonsBox>
            )}
          </Page.MainWrapper>
        )}
      />
    </Page.Container>
  ) : (
    <Loading />
  )
}

export default inject('store')(observer(OneLongLife))
