import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import { FlexRow, HelpIcon, TooltipText } from '../../../components'
import { numberToDollars } from '../../../utils'

const Cell = styled.div`
  width: ${p => (p.width ? p.width : '180px')};
  min-width: 180px;
  padding: ${p => (p.wrapPadding ? p.warpPadding : '4px 0')};

  @media (max-width: 751px) {
    order: ${p => (p.order ? p.order : null)};
  }
`
const Text = styled.div`
  color: ${p => p.theme.mediumGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.35;
  font-size: ${p => (p.fontSize ? p.fontSize : '1.125rem')};
  padding: ${p => (p.padding ? p.padding : null)};
`
const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : null)};
  align-items: ${p => (p.alignItems ? p.alignItems : 'center')};
  flex-wrap: wrap;
  padding-bottom: 24px;
  }
`
const Amount = styled.span`
  font-size: ${p => (p.lightDollar ? '1.125rem' : '1.25rem')};
  color: ${p => p.theme.darkestGray};

  &::before {
    content: '$';
    color: ${p => (p.lightDollar ? p.theme.lightestGray : '#4d9927')};
  }
`
const SubAmount = styled.span`
  font-size: 0.9rem;
  color: ${p => p.theme.mediumGray};
  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
const LightText = styled.span`
  color: ${p => p.theme.lightestGray};
`
const ValueLabel = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.35;
  font-size: ${p => (p.fontSize ? p.fontSize : '1.125rem')};
`

class ProjectedValues extends Component {
  render() {
    const {
      baseCase: {
        estAftTaxIncomeAtHundredPercent,
        projectedMonthlyInflationAdjustedRetirementIncFactor,
      },
    } = this.props.onboarding

    return (
      <FlexRow alignItems='flex-start'>
        <Cell>
          <Text fontSize='1.25rem'>Which is about:</Text>
        </Cell>
        <div>
          <TableRow alignItems='flex-start'>
            <Cell order={2}>
              <Cell wrapPadding='2px 0'>
                <Amount>
                  {numberToDollars((0.8 * estAftTaxIncomeAtHundredPercent) / 12, true)}
                </Amount>
              </Cell>
              <Cell wrapPadding='2px 0'>
                <SubAmount>
                  {`${numberToDollars(0.8 * estAftTaxIncomeAtHundredPercent, true)}`}
                  <LightText>&nbsp;annual</LightText>
                </SubAmount>
              </Cell>
            </Cell>
            <Cell width='290px' order={1}>
              <ValueLabel>Today's monthly value</ValueLabel>
            </Cell>
          </TableRow>
          <TableRow alignItems='flex-start'>
            <Cell order={2}>
              <Cell wrapPadding='2px 0'>
                <Amount>
                  {numberToDollars(
                    ((0.8 * estAftTaxIncomeAtHundredPercent) / 12) *
                      projectedMonthlyInflationAdjustedRetirementIncFactor,
                    true
                  )}
                </Amount>
              </Cell>
              <Cell wrapPadding='2px 0'>
                <SubAmount>
                  {`${numberToDollars(
                    0.8 *
                      estAftTaxIncomeAtHundredPercent *
                      projectedMonthlyInflationAdjustedRetirementIncFactor,
                    true
                  )} `}
                  <LightText>&nbsp;annual</LightText>
                </SubAmount>
              </Cell>
            </Cell>
            <Cell width='290px' order={1}>
              <FlexRow>
                <ValueLabel>Monthly value at retirement</ValueLabel>
                &nbsp;&nbsp;
                <HelpIcon tooltip={TooltipText.valueAtRetirement()} />
              </FlexRow>
            </Cell>
          </TableRow>
        </div>
      </FlexRow>
    )
  }
}

export default inject('onboarding')(observer(ProjectedValues))
