import { css } from '@emotion/react'
import styled from '@emotion/styled'
import posed from 'react-pose'
import { Loading } from '../../../components'
import { API_URL } from '../../../api'
import logo from '../../../assets/images/GuidedChoice-logo.png'
import BGRight from '../../../assets/images/BGRight.svg'

const Page = styled.div`
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
Page.Background = styled.div`
  background: url(${p => (API_URL && p.theme.bgRight ? API_URL + p.theme.bgRight : BGRight)})
    no-repeat top right fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

Page.Container = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
  color: ${p => p.theme.offboardingPageContainerTextColor};
`

Page.Column = styled.div`
  display: flex;
  flex-direction: column;
`

Page.Loading = () => (
  <div
    css={css`
      margin: 20px;
    `}>
    <Loading />
  </div>
)

export const Header = props => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
        max-width: 1200px;
        margin: auto;
        align-self: flex-start;
        height: 75px;
      `}>
      {props.organizationLogo && (
        <div
          css={css`
            flex-grow: 1;
          `}>
          <img
            css={css`
              height: 60%;
            `}
            src={props.organizationLogo}
            alt='Organization Logo'
          />
        </div>
      )}
      <div
        css={css`
          max-width: 220px;
          padding: 2px 10px;
        `}>
        <img
          css={css`
            width: 100%;
          `}
          src={logo}
          alt='Logo'
        />
      </div>
    </div>
  )
}

Page.Text = styled.div`
  font-size: 1.125rem;
  color: ${p => p.theme.mediumGray};
  line-height: 1.35;
`

Page.LightText = styled.div`
  font-size: 1.125rem;
  color: ${p => p.theme.lightestGray};
  line-height: 1.35;
`

Page.PrimaryText = styled.div`
  font-size: 1.125rem;
  color: ${p => p.theme.primaryColor};
  line-height: 1.35;
`
Page.WhoPaysText = styled.div`
  font-size: 0.875rem;
  color: ${p => p.theme.lightestGray};
`
Page.PricebookNameText = styled.div`
  font-size: 1.875rem;
  color: ${p => p.theme.mediumGray};
`

Page.PricebookHeading = styled.div`
  font-size: 20px;
  color: ${p => (p.selected ? 'white' : p.theme.darkestGray)};
`

Page.WelcomeHeading = styled.div`
  text-align: center;
  max-width: 679px;
  margin: auto;
  padding: 20px 0 40px;
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 3.125rem;
  font-weight: bold;
  word-wrap: break-word;
  color: ${p => p.theme.darkestGray};
`

Page.WelcomeParagraph = styled(Page.Text)`
  margin: 20px auto;
  max-width: 679px;
`

Page.TermsSubText = styled(Page.Text)`
  font-size: 1rem;
  max-width: 679px;
  margin: auto;
  padding-left: 64px;
`

Page.AnimateHeading = posed.div({
  enter: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -15 },
})

const HeadingStyles = styled.div`
  width: 100%;
  display: flex;
  flex-basis: 100%;
  padding-top: 10px;
  margin-bottom: 30px;
  max-width: 1200px;
  align-self: flex-start;
  @media (min-width: 1200px) {
    margin-bottom: 40px;
  }

  /* Title */
  & > div:first-of-type {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3rem;
    width: 100%;
    color: ${p => p.theme.ssiHeadingTextColor};
    transition: 0.75s;
    font-weight: 300;
    line-height: 1.25;
    word-break: break-word;
    @media (max-width: 800px) {
      font-size: 2rem;
      /* max-width: 400px; */
    }
    @media (min-width: 1200px) {
      font-size: 3.125rem;
      /* max-width: 1200px; */
    }
  }

  /* SubTitle */
  & > div:nth-of-type(2) {
    color: ${p => p.theme.lightestGray};
    font-size: 1rem;
    padding-top: 1rem;
    line-height: 20px;
  }
`

Page.SubHeading = styled.div`
  display: flex;
  max-width: 1200px;
  align-self: flex-start;

  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  color: ${p => p.theme.lightestGray};
  transition: 0.75s;
  font-weight: 300;
  line-height: 1.25;
  word-break: break-word;
`

Page.PricebookSubHeading = styled.div`
  display: flex;
  max-width: 1200px;

  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  color: ${p => (p.selected ? 'white' : p.theme.mediumGray)};
  font-weight: 600;
  line-height: 1.25;
  word-break: break-word;
  justify-content: center;
`

Page.PricebookMetaSubHeader = styled.div`
  display: flex;
  max-width: 1200px;

  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  color: ${p => p.theme.sectionTitleColor};
  font-weight: 600;
  line-height: 1.25;
  word-break: break-word;
`

const BulletTextStyles = styled.div`
  display: flex;
  color: ${p => (p.selected ? 'white' : '')};
`
Page.Heading = props => {
  const { title, children, subTitle } = props
  return (
    <HeadingStyles>
      <div>{title || children}</div>
      {subTitle && <div>{subTitle}</div>}
    </HeadingStyles>
  )
}

Page.BulletText = props => {
  const { children, selected } = props
  return (
    <BulletTextStyles selected={selected}>
      <div
        css={css`
          margin-right: 20px;
        `}>
        •
      </div>
      <div>{children}</div>
    </BulletTextStyles>
  )
}

Page.PackageBulletText = styled.div`
  font-size: 0.875rem;
  color: ${p => p.theme.lightestGray};
`
Page.DescriptionTitleText = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${p => p.theme.mediumGray};
  margin-bottom: 10px;
`
Page.DescriptionText = styled.div`
  font-size: 1rem;
  color: ${p => p.theme.lightestGray};
`

Page.PricebookCard = styled.div`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: ${p => (p.selected ? '#4D9927' : p.theme.cardBgColor)};
  border-radius: 8px;
  border: ${p => p.theme.cardBorder ?? '1px black solid'};
  padding: 20px;
  width: 538px;
  height: 295px;
`

Page.PricebookSubCard = styled.div`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  border-radius: 8px;
  border: ${p => (p.selected ? 'white 1px solid' : 'none')};
  background-color: ${p => (p.selected ? '#4D9927' : 'rgba(79, 173, 204, 0.2)')};
  color: ${p => (p.selected ? 'white' : 'rgb(74, 96, 106)')};
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 196px;
  padding: 15px 0;
`

Page.Link = styled.a`
  cursor: pointer;
  color: ${p => p.theme.customLinkColor};
  text-decoration: underline;
`

Page.FooterNote = styled.span`
  font-size: 16px;
  line-height: 24px;
`

Page.Form = styled.form`
  margin: 20px 0;
  width: 100%;
`

const FieldStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* Field Label Container */
  & > div:first-of-type {
    flex: 1 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: right;
    margin-right: 40px;
    color: rgb(122, 142, 150);
    font-size: 1.125rem;
    line-height: 1.35;

    /* Field Label */
    & > div:first-of-type {
    }

    /* Field SubLabel (Optional) */
    & > div:nth-of-type(2) {
      font-size: 0.875rem;
    }
  }

  /* Field Input Container */
  & > div:nth-of-type(2) {
    flex: 1 1 340px;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(74, 96, 106);
    font-size: 1.125rem;
    line-height: 1.35;
    word-break: break-word;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    margin: 20px 0;
    & > div:first-of-type {
      flex: 1 1 100%;
      text-align: left;
      margin: 12px 0;
    }
  }
`
Page.Field = props => {
  const { label, subLabel, children, ...rest } = props
  return (
    <FieldStyles {...rest}>
      <div>
        <div
          css={css`
            line-height: min(54px);
          `}>
          {label}
        </div>
        {subLabel && (
          <div
            css={css`
              line-height: max(10px);
              margin-bottom: 10px;
            `}>
            {subLabel}
          </div>
        )}
      </div>
      <div
        css={css`
          line-height: min(54px);
        `}>
        {children}
      </div>
    </FieldStyles>
  )
}

Page.ErrorMessage = styled.div`
  color: crimson;
  text-align: center;
  margin: 20px 0;
`

Page.ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px 0;
  flex-wrap: wrap-reverse;
`
Page.BundleCard = styled.div`
  max-width: 690px;
  color: ${p => p.theme.darkestGray};
  background-color: ${p => p.theme.canYouCoverCardColor};
  padding: 20px;
  border: none;
  border-radius: 8px;
  display: flex;
`
Page.InnerCard = styled.div`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  border: 'none';
  border-radius: 8px;
  background-color: rgba(79, 173, 204, 0.2);
  color: ${p => p.theme.mediumGray};
  align-self: 'center';
  min-width: '150px';
  justify-content: 'center';
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
`

Page.AnimateGroup = posed.div({
  enter: { staggerChildren: 50 },
  exit: { staggerChildren: 20, staggerDirection: -1 },
})

Page.AnimateItem = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 20, opacity: 0 },
})

Page.Animate = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 20, opacity: 0 },
})

export default Page
