import { useTheme } from '@emotion/react'

const SvgComponent = props => {
  const theme = useTheme()

  return (
    <svg
      id='manage_risk_icon'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 300 300'
      {...props}>
      <path fill='none' d='M-246-621H778V737H-246z' />
      <path fill='none' d='M-246-621H778V737H-246z' />
      <path fill='none' d='M-246-621H778V737H-246z' />
      <path
        d='M180.2 159.2c0 3.8 3.1 6.9 6.9 6.9 3.8 0 6.9-3.1 6.9-6.9 0-6.9-12.7-6.9-12.7-13.8 0-3.1 2-5.7 5.7-5.7 3.8 0 5.7 2.9 5.7 5.7m-5.7 24.1V166m0-26.4v-3.4'
        fill='none'
        stroke='#90be40'
        strokeMiterlimit={10}
      />
      <path
        d='M182.4 181.2c-13.7-2.2-24-14-24-28.2H144c0 22.2 16.9 40.4 38.4 42.8'
        fill='none'
        stroke='#0e8cb9'
        strokeMiterlimit={10}
      />
      <path
        d='M191.6 124.6c13.7 2.2 24 14 24 28.2H230c0-22.2-16.9-40.4-38.4-42.8'
        fill='none'
        stroke='#90be40'
        strokeMiterlimit={10}
      />
      <path
        d='M158.7 148.3c2.2-13.7 14-24 28.2-24V110c-22.2 0-40.4 16.9-42.8 38.4m71.2 9.1c-2.2 13.7-14 24-28.2 24v14.3c22.2 0 40.4-16.9 42.8-38.4'
        fill='none'
        stroke='#0e8cb9'
        strokeMiterlimit={10}
      />
      <path
        fill='none'
        stroke='#90be40'
        strokeMiterlimit={10}
        d='M199.2 111.6l-7.6 7.5m36.5 20.7l-12.4 12.3m-17.3-25.6l10.6-10.7m8.1 6.3L207 132.3m16.5-2.3l-10.6 10.5'
      />
      <defs>
        <path
          id='manage_risk_icon_9_'
          d='M131.1 133.2c-.5.2-1 .5-1.7.5h-23.9c-.6 0-1.2-.2-1.7-.5-14 11.3-32.9 36.7-32.9 52.9 0 15 4.6 24.4 14.5 32.5 2.9 2.4 6.6 3.7 10.5 3.7h42.7c3.9 0 7.6-1.3 10.5-3.7 10-8.2 14.5-17.6 14.5-32.5.4-16.2-18.4-41.7-32.5-52.9z'
        />
      </defs>
      <clipPath id='manage_risk_icon_2_'>
        <use xlinkHref='#manage_risk_icon_9_' overflow='visible' />
      </clipPath>
      <g clipPath='url(#manage_risk_icon_2_)'>
        <defs>
          <path id='manage_risk_icon_11_' d='M-616.3-1314.4h1926.4v2554.7H-616.3z' />
        </defs>
        <clipPath id='manage_risk_icon_4_'>
          <use xlinkHref='#manage_risk_icon_11_' overflow='visible' />
        </clipPath>
        <path
          clipPath='url(#manage_risk_icon_4_)'
          fill={theme.homeBgColor}
          d='M61.6 123.8h111.6v108H61.6z'
        />
      </g>
      <path
        d='M131.1 133.2c-.5.2-1 .5-1.7.5h-23.9c-.6 0-1.2-.2-1.7-.5-14 11.3-32.9 36.7-32.9 52.9 0 15 4.6 24.4 14.5 32.5 2.9 2.4 6.6 3.7 10.5 3.7h42.7c3.9 0 7.6-1.3 10.5-3.7 10-8.2 14.5-17.6 14.5-32.5.4-16.2-18.4-41.7-32.5-52.9z'
        fill='none'
        stroke='#90be3e'
        strokeMiterlimit={10}
      />
      <defs>
        <path
          id='manage_risk_icon_13_'
          d='M129.5 126.9c.2 0 .5 0 .7.1l10-9.4c.8-.8.6-1.8-.4-2.3l-3.4-1.6c-1-.5-2.7-.6-3.8-.2l-4.7 1.5c-1.1.3-2.7.1-3.6-.5l-5.2-3.7c-.9-.6-2.5-.6-3.3 0l-5.2 3.7c-.9.6-2.5.9-3.6.5l-4.7-1.5c-1.1-.3-2.8-.2-3.8.2l-3.4 1.6c-1 .5-1.2 1.5-.4 2.3l10 9.4c.2-.1.5-.1.7-.1h24.1z'
        />
      </defs>
      <clipPath id='manage_risk_icon_6_'>
        <use xlinkHref='#manage_risk_icon_13_' overflow='visible' />
      </clipPath>
      <g clipPath='url(#manage_risk_icon_6_)'>
        <defs>
          <path id='manage_risk_icon_15_' d='M-616.3-1314.4h1926.4v2554.7H-616.3z' />
        </defs>
        <clipPath id='manage_risk_icon_8_'>
          <use xlinkHref='#manage_risk_icon_15_' overflow='visible' />
        </clipPath>
        <path
          clipPath='url(#manage_risk_icon_8_)'
          fill={theme.homeBgColor}
          d='M84.8 100.8h65.3v35.5H84.8z'
        />
      </g>
      <path
        d='M129.5 126.9c.2 0 .5 0 .7.1l10-9.4c.8-.8.6-1.8-.4-2.3l-3.4-1.6c-1-.5-2.7-.6-3.8-.2l-4.7 1.5c-1.1.3-2.7.1-3.6-.5l-5.2-3.7c-.9-.6-2.5-.6-3.3 0l-5.2 3.7c-.9.6-2.5.9-3.6.5l-4.7-1.5c-1.1-.3-2.8-.2-3.8.2l-3.4 1.6c-1 .5-1.2 1.5-.4 2.3l10 9.4c.2-.1.5-.1.7-.1h24.1z'
        fill='none'
        stroke='#90be3e'
        strokeMiterlimit={10}
      />
      <defs>
        <path
          id='manage_risk_icon_17_'
          d='M129.5 133.6c.6 0 1.2-.2 1.7-.5 1-.5 1.7-1.6 1.7-3 0-1.6-1.1-3-2.7-3.3-.2-.1-.5-.1-.7-.1h-23.9c-.2 0-.5 0-.7.1-1.5.3-2.7 1.6-2.7 3.3 0 1.2.7 2.3 1.7 3 .5.2 1 .5 1.7.5h23.9z'
        />
      </defs>
      <clipPath id='manage_risk_icon_10_'>
        <use xlinkHref='#manage_risk_icon_17_' overflow='visible' />
      </clipPath>
      <g clipPath='url(#manage_risk_icon_10_)'>
        <defs>
          <path id='manage_risk_icon_19_' d='M-616.3-1314.4h1926.4v2554.7H-616.3z' />
        </defs>
        <clipPath id='manage_risk_icon_12_'>
          <use xlinkHref='#manage_risk_icon_19_' overflow='visible' />
        </clipPath>
        <path
          clipPath='url(#manage_risk_icon_12_)'
          fill={theme.homeBgColor}
          d='M92.7 117.4h49.6V143H92.7z'
        />
      </g>
      <path
        d='M129.5 133.6c.6 0 1.2-.2 1.7-.5 1-.5 1.7-1.6 1.7-3 0-1.6-1.1-3-2.7-3.3-.2-.1-.5-.1-.7-.1h-23.9c-.2 0-.5 0-.7.1-1.5.3-2.7 1.6-2.7 3.3 0 1.2.7 2.3 1.7 3 .5.2 1 .5 1.7.5h23.9z'
        fill='none'
        stroke='#90be3e'
        strokeMiterlimit={10}
      />
      <defs>
        <path
          id='manage_risk_icon_21_'
          d='M156.2 186.1c0 12.3-3.3 19.8-11.8 26.6-1.6 1.2-3.6 1.9-5.5 1.9'
        />
      </defs>
      <clipPath id='manage_risk_icon_14_'>
        <use xlinkHref='#manage_risk_icon_21_' overflow='visible' />
      </clipPath>
      <g clipPath='url(#manage_risk_icon_14_)'>
        <defs>
          <path id='manage_risk_icon_23_' d='M-616.3-1314.4h1926.4v2554.7H-616.3z' />
        </defs>
        <clipPath id='manage_risk_icon_16_'>
          <use xlinkHref='#manage_risk_icon_23_' overflow='visible' />
        </clipPath>
        <path
          clipPath='url(#manage_risk_icon_16_)'
          fill={theme.homeBgColor}
          d='M129.5 176.7h36.1v47.4h-36.1z'
        />
      </g>
      <path
        d='M156.2 186.1c0 12.3-3.3 19.8-11.8 26.6-1.6 1.2-3.6 1.9-5.5 1.9'
        fill='none'
        stroke='#90be3e'
        strokeMiterlimit={10}
      />
      <path
        d='M108 188.8c0 5.1 4.2 9.3 9.4 9.3s9.4-4.1 9.4-9.3c0-9.3-17.3-9.3-17.3-18.6 0-4.2 2.7-7.8 7.9-7.8s7.9 3.9 7.9 7.8m-7.9 37.3v-7.6m0-37.6v-7.5'
        fill='none'
        stroke='#90be40'
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default SvgComponent
