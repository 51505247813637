/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'

import { Page } from '../elements'
import { Row, Spacer } from '../../../components'

const CCPANotice = props => {
  const { privacyPolicyLink } = props.onboarding
  return (
    <div>
      <Page.Text
        css={css`
          font-weight: bold;
        `}>
        California Consumer Privacy Act (CCPA) Notice at Collection
      </Page.Text>
      <Page.Text>
        Under the California Consumer Privacy Act (CCPA), California consumers may be entitled to
        certain notices and disclosures regarding the collection and use of their Personal
        Information.
      </Page.Text>
      <Spacer space='5px' />
      <Page.BlueCard
        css={css`
          width: 100%;
        `}>
        <Row
          css={css`
            justify-content: space-between;
            align-items: start;
          `}>
          <Page.Column
            css={css`
              width: 45%;
            `}>
            <Page.Text
              css={css`
                font-weight: bold;
              `}>
              CATEGORIES OF PERSONAL INFORMATION COLLECTED
            </Page.Text>
            <Page.BulletText>
              Identifiers including but not limited to name, date of birth, gender, address/phone,
              marital status and tax filing status
            </Page.BulletText>
            <Page.BulletText>
              Financial information including salary, investments, and account balances
            </Page.BulletText>
            <Page.BulletText>
              Professional and employment-related information including company name
            </Page.BulletText>
          </Page.Column>
          <Page.Column
            css={css`
              width: 45%;
            `}>
            <Page.Text
              css={css`
                font-weight: bold;
              `}>
              PURPOSES FOR WHICH IT WILL BE USED
            </Page.Text>
            <Page.BulletText>Providing financial advice and guidance,</Page.BulletText>
            <Page.BulletText>
              Responding to your requests and communicating with you,
            </Page.BulletText>
            <Page.BulletText>Tracking our visitors use of our websites,</Page.BulletText>
            <Page.BulletText>
              Improving the content, appearance, and utility of our websites,
            </Page.BulletText>
            <Page.BulletText>
              Compliance, audits, and providing assistance to law enforcement, and
            </Page.BulletText>
            <Page.BulletText>
              Complete a corporate transaction, such as a merger, sale, joint venture, or
              disposition of all or any portion of our business, assets or stock.
            </Page.BulletText>
          </Page.Column>
        </Row>
      </Page.BlueCard>
      <Spacer space='5px' />
      <Page.Text>
        In cases where investment advice has been provided, by law we must keep record of that
        advice and who it was for, as well as the information used to determine it. Any other data
        collected will either be deleted or obfuscated so that it cannot be associated with a
        particular user.
      </Page.Text>
      <Spacer space='15px' />
      <Page.Text>
        GuidedChoice is committed to protecting the personal information that our customers provide
        to us. We do not sell, reveal, or share any of your personal information with any third
        parties and do not monitor, edit, or disclose your data except primarily to provide
        financial advice and guidance to you.{' '}
        <span
          css={css`
            font-weight: bold;
          `}>
          Please review our{' '}
          <Page.Link
            href={privacyPolicyLink}
            target='_blank'
            rel='noreferrer noopener'
            onClick={e => e.stopPropagation()}>
            Privacy Policy
          </Page.Link>
          , which includes our general and California-specific privacy information.
        </span>
      </Page.Text>
    </div>
  )
}

export default inject('onboarding', 'store')(observer(CCPANotice))
