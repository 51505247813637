import { types } from 'mobx-state-tree'
import _ from 'lodash'
const { model, optional, string, number, boolean, array } = types

const Size = model('Size', {
  height: 0,
  width: 0,
}).actions(self => ({
  setAttributes(attributes) {
    _.merge(self, attributes)
  },
}))

const PageHeader = types
  .model('PageHeader', {
    size: optional(Size, {}),
  })
  .actions(self => ({
    setSize(size) {
      self.size.setAttributes(size)
    },
  }))

const PageFooter = types
  .model('PageFooter', {
    size: optional(Size, {}),
  })
  .actions(self => ({
    setSize(size) {
      self.size.setAttributes(size)
    },
  }))

const Page = types
  .model('Page', {
    calculatingSizes: optional(boolean, true),
    contentHeight: optional(number, 0),
    footer: optional(PageFooter, {}),
    header: optional(PageHeader, {}),
    name: optional(string, ''),
    pageSize: optional(Size, {}),
    wrapperHeight: optional(number, 0),
  })
  .views(self => ({
    get availableContentHeight() {
      const {
        pageSize,
        footer: { size: footerSize },
        header: { size: headerSize },
        wrapperHeight,
      } = self

      return pageSize.height - headerSize.height - wrapperHeight - (footerSize.height || 50)
    },
    get availableContentHeightWithoutHeader() {
      const {
        pageSize,
        footer: { size: footerSize },
      } = self
      return pageSize.height - (footerSize.height || 50)
    },
    get availableContentHeightWithoutFooter() {
      const {
        pageSize,
        header: { size: headerSize },
      } = self
      return pageSize.height - headerSize.height - 50
    },
    get contentIsOverflowing() {
      return self.availableContentHeight < self.contentHeight
    },
    get contentIsOverflowingWithoutFooter() {
      return self.availableContentHeightWithoutFooter < self.contentHeight
    },
    get contentIsOverflowingWithoutHeader() {
      return self.availableContentHeightWithoutHeader < self.contentHeight
    },
  }))
  .actions(self => ({
    setContentHeight(contentHeight) {
      self.contentHeight = contentHeight
    },
    setFooterSize(size) {
      self.footer.setSize(size)
    },
    setHeaderSize(size) {
      self.header.setSize(size)
    },
    setPageSize(size) {
      self.pageSize = size
    },
    setSizes(pageSize, headerSize, footerSize, contentHeight, wrapperHeight) {
      self.pageSize.setAttributes(pageSize)
      self.header.setSize(headerSize)
      self.footer.setSize(footerSize)
      self.contentHeight = contentHeight
      self.wrapperHeight = wrapperHeight || 0
      self.calculatingSizes = false
    },
  }))

const Pages = types
  .model('Pages', {
    data: array(Page),
  })
  .actions(self => ({
    addPage(page = {}) {
      _.merge(page, { contentSizes: [] })
      self.data.push(page)
      return self.data[self.data.length - 1]
    },
    findByName(name) {
      return self.data.find(page => page.name === name)
    },
  }))

const SpendingGuidePages = types
  .model('SpendingGuidePages', {
    fetchComplete: optional(boolean, false),
    pages: optional(Pages, {}),
  })
  .actions(self => ({
    setFetchComplete(value) {
      self.fetchComplete = value
    },
  }))

export default SpendingGuidePages
