import { types, flow, getEnv } from 'mobx-state-tree'
import dayjs from 'dayjs'
import URLSearchParams from 'url-search-params'
import months from '../../constants/months'
import SpendingGuidePages from './SpendingGuidePages'
import { API } from '../../api/instances'
const { model, optional, maybeNull } = types

const SpendingGuideStore = model({
  status: 'loading',
  error: '',
  guidePages: optional(SpendingGuidePages, {}),
  generationDate: maybeNull(types.Date),
})
  .views(self => ({
    get date() {
      const now = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
      }).format(self.generationDate)
      const date = dayjs(now)

      const month = months[date.format('M')]
      const day = date.format('D')
      const year = date.format('YYYY')
      return `${month} ${day}, ${year}`
    },
    get fromDate() {
      const now = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
      }).format(self.generationDate)
      const date = dayjs(now)

      const month = months[date.format('M')]
      const day = date.format('D')
      const year = date.format('YYYY')
      return `${month} ${day}, ${year}`
    },
    get timeStamp() {
      const now = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'America/Los_Angeles',
      }).format(self.generationDate)

      const date = dayjs(now)

      const formattedDate = date.format('MMDDYYYY HHmmss')
      return formattedDate + ' PST'
    },
    get isWithdrawingFromCompanyStock() {
      const { store } = getEnv(self)
      const companyStockNames = store?.spendingAcceptedCase?.companyStockNames

      return (
        companyStockNames
          ?.map(companyStock => {
            return self.investmentWithdrawals.livingExpensesWithdrawalAdvice.investmentAccountWithdrawals.map(
              account => {
                return account.investments.filter(type => type.name === companyStock)
              }
            )
          })
          .flat()
          .flat().length > 0
      )
    },
    get isWithdrawingFromCompanyStockForAnnuity() {
      const { store } = getEnv(self)
      const companyStockNames = store?.spendingAcceptedCase?.companyStockNames

      return companyStockNames.map(companyStock => {
        return self.investmentWithdrawals.primaryAnnuitiesWithdrawalAdvice.investmentAccountWithdrawals.map(
          account => {
            return account.investments.filter(type => type.name === companyStock)
          }
        )
      })
    },
  }))
  .actions(self => ({
    afterCreate: flow(function* () {
      const { store, location, match } = getEnv(self)
      const { caseId } = match.params
      const token = new URLSearchParams(location.search).get('token')

      try {
        if (caseId) {
          if (token !== null) {
            store.auth.setGuideToken(token)
          }

          yield store.getPerson()
          yield store.getSpouse()
          yield store.setInitialInfoForSpendDown()

          yield Promise.all([
            store.getConfig(),
            store.getFeatures(),
            store.getAccount(),
            store.getAccountInsight(),
            store.getContactInfo(),
            store.getDependents(),
            store.getAccounts(),
            store.getGoals(),
            // store.getBaseCase(), don't need it //probably won't need it for spending
            store.getSpendingCaseById(caseId), // different from saving guide
            // store.getAcceptedContributionDistribution(caseId), //uncomment //probably won't need it for spending
            store.getInstitutionalAccount(),
            // store.getBreakdown(caseId),
            // store.getBreakDownForPDF(caseId),
            store.getNonGCAccounts(),
            store.getPortfolioConstruction(),
            store.getPlanOverview(),
            store.getSSBenefits(),
            store.getAllPensions(),
            store.getPrimaryRetirementStatus(),
          ])

          if (store.person.maritalStatus && store.person.includeSpouse) {
            yield store.getSpouseRetirementStatus()
          }

          yield self.getRetirementIncome()
          yield self.getWithdrawalData()
          yield self.getInvestmentWithdrawals()

          self.guidePages.setFetchComplete(true)
          self.generationDate = new Date()
          self.status = 'done'
        } else {
          self.error = 'Missing token or case'
          self.status = 'error'
        }
      } catch (err) {
        self.error = 'Something went wrong, please try again later'
        self.error = JSON.stringify(err, Object.getOwnPropertyNames(err))
        self.status = 'error'
        throw new Error(err)
      }
      self.status = 'done'
    }),
    getRetirementIncome: flow(function* () {
      const {
        store: { spendingAcceptedCaseId },
      } = getEnv(self)
      const res = yield API.get('retirement-income', { params: { caseId: spendingAcceptedCaseId } })

      self.spendingRetirementIncome = res.data || {}
    }),
    getWithdrawalData: flow(function* () {
      const {
        store: { spendingAcceptedCaseId },
      } = getEnv(self)
      const res = yield API.get('expenditures', { params: { caseId: spendingAcceptedCaseId } })

      self.withdrawalData = res.data || {}
    }),
    getInvestmentWithdrawals: flow(function* () {
      const {
        store: { spendingAcceptedCaseId },
      } = getEnv(self)
      const res = yield API.get('investment/withdrawals', {
        params: { caseId: spendingAcceptedCaseId },
      })

      self.investmentWithdrawals = res.data || {}
    }),
  }))

export default SpendingGuideStore
