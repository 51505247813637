import styled from '@emotion/styled'
import InlineSVG from 'svg-inline-react'
import { Coin, LineChart, PieChart } from '../../../assets/icons'
import { numberToDollars } from '../../../utils'

const svgMap = {
  Bonds:
    '<svg viewBox="0 0 16 16" class="css-ducv57"><g stroke="#7A8E96" fill="none" fill-rule="evenodd"><path d="M14 12v-.432a.391.391 0 0 0-.142-.288.547.547 0 0 0-.349-.132h-.427V6h-1.85v5.148h-.93V6h-1.85v5.148h-.917V6h-1.85v5.148h-.918V6H2.918v5.148h-.427a.545.545 0 0 0-.349.132.392.392 0 0 0-.142.288V12h12zM1 15.5h14v-1.327a.704.704 0 0 0-.144-.467c-.104-.131-.222-.206-.353-.206H1.51c-.144 0-.262.075-.366.206a.785.785 0 0 0-.144.467V15.5zM2.435 4.5c.004.005-.008 0-.009 0h.01zm0 0H13.56a.106.106 0 0 0 .012-.045v-.5h.929v-.306L8 .554 1.5 3.649v.306h.93v.5c0 .02.01.048.004.044l.001.001z"></path></g></svg>',
  Cash: Coin,
  Stock: LineChart,
  Misc: PieChart,
}

export default function HoldingRow({ title, svg, i, newValue }) {
  let newDollar
  let newPercent

  if (newValue !== null) {
    newDollar = newValue.dollar
    newPercent = newValue.percent
    newPercent = Math.min(newPercent, 100)
  }

  return (
    <Wrapper>
      <Container first={i}>
        <Svg>
          {i === 0 ? <InlineSVG className='icon' src={svgMap[svg]} element='span' /> : null}
        </Svg>
        <Text>{title}</Text>
        <NewDollar>
          {newDollar !== undefined
            ? `$${numberToDollars(newDollar)} | ${numberToDollars(newPercent, true)}%`
            : ''}
        </NewDollar>
        {newDollar !== undefined ? <NewPercentBar width={newPercent} /> : ''}
        <VerticalDivider first={i} />
      </Container>
      <DottedLine />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 40px;
  padding-left: 45px;
`
const Container = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  display: flex;
  position: relative;
  width: 675px;
  min-height: 40px;
  padding: ${props => (props.first === 0 ? '45px 0 10px 0' : '0 0 0 0')};
`

const Svg = styled.div`
  color: #022a3a;
  height: 30px;
  width: 30px;
`

const Text = styled.div`
  font-family: 'Open Sans';
  color: #022a3a;
  font-size: 14px;
  padding: 10px 0 0 20px;
  width: 45%;
`

const DottedLine = styled.div`
  margin-left: 50px;
  width: 600px;
  border-bottom: 1px dotted rgb(105, 222, 227);
`

const NewDollar = styled.div`
  color: #038ab7;
  position: absolute;
  left: 520px;
  padding-top: 7px;
`

const NewPercentBar = styled.div`
  height: 12px;
  width: ${p => (p.width > 0 ? p.width + 'px' : 0)};
  background-color: #038ab7;
  position: absolute;
  left: 496px;
  bottom: 0px;
`

const VerticalDivider = styled.div`
  border-left: 0.7px solid #7a8e96;
  position: absolute;
  right: 179px;
  bottom: -13px;
  height: ${props => (props.first === 0 ? '60px' : '104%')};
`
