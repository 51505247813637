/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const avatar = 'rt-chip-avatar'

const chip = 'rt-chip-chip'

const deletable = 'rt-chip-deletable'

const deleteClass = 'rt-chip-deleteClass'

const deleteIcon = 'rt-chip-deleteIcon'

const deleteX = 'rt-chip-deleteX'

const _chip = css`
  background-color: ${ds.get('chip.background')};
  border-radius: ${ds.get('chip.height')};
  color: ${ds.get('chip.color')};
  display: inline-block;
  font-size: ${ds.get('chip.fontSize')};
  line-height: ${ds.get('chip.height')};
  margin-right: ${ds.get('chip.marginRight')};
  max-width: 100%;
  overflow: hidden;
  padding: 0 ${ds.get('chip.padding')};
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${ds.get('reset')};
`

const _avatar = css`
  padding-left: 0;

  & > [data-react-toolbox='avatar'] {
    height: ${ds.get('chip.height')};
    margin-right: ${ds.get('chip.iconMarginRight')};
    vertical-align: middle;
    width: ${ds.get('chip.height')};

    & > span {
      font-size: ${ds.get('chip.iconFontSize')};
      line-height: ${ds.get('chip.height')};
    }
  }
`

const _deletable = css`
  padding-right: ${reduceCSSCalc(`calc(
      ${ds.get('chip.removeSize')} + 2 * ${ds.get('chip.removeMargin')}
    )`)};
`

const _delete = css`
  cursor: pointer;
  display: inline-block;
  height: ${ds.get('chip.removeSize')};
  margin: ${ds.get('chip.removeMargin')};
  padding: ${ds.get('chip.removeMargin')};
  position: absolute;
  right: 0;
  width: ${ds.get('chip.removeSize')};
  &:hover .${deleteIcon} {
    background: ${ds.get('chip.removeBackgroundHover')};
  }
`

const _deleteIcon = css`
  background: ${ds.get('chip.removeBackground')};
  border-radius: ${ds.get('chip.removeSize')};
  vertical-align: top;

  & .${deleteX} {
    fill: transparent;
    stroke: ${ds.get('chip.removeColor')};
    stroke-width: ${ds.get('chip.removeStrokeWidth')};
  }
`

export default {
  avatar: cx(avatar, _avatar),
  chip: cx(chip, _chip),
  deletable: cx(deletable, _deletable),
  delete: cx(deleteClass, _delete),
  deleteIcon: cx(deleteIcon, _deleteIcon),
  deleteX,
}
