import { css } from '@emotion/react'
import { Fragment, Component } from 'react'
import { observer, inject } from 'mobx-react'
import dayjs from 'dayjs'
import _ from 'lodash'

import { numberToDollars } from '../../../utils'
import { Card, CardField } from '../../../components'

class AboutFamilyCard extends Component {
  genderValue(gender) {
    if (gender === 'M') {
      return 'Male'
    }
    if (gender === 'F') {
      return 'Female'
    }
    if (gender !== 'M' && gender !== 'F') {
      return ''
    }
  }

  maritalStatusValue() {
    const { person } = this.props.store
    if (person.maritalStatus === false) {
      return 'Single'
    }
    if (person.maritalStatus === true) {
      return 'Married'
    }
  }

  render() {
    const {
      person,
      spouse,
      dependents,
      config: { isSpendown },
      spouseRetirementStatus,
    } = this.props.store

    return (
      <Card title='About your family' handleEdit={this.props.showAboutFamily} cardWidth='900px'>
        <CardField type='profile' label='Marital status' value={this.maritalStatusValue()} />
        {person.maritalStatus === true && (
          <CardField
            type='profile'
            label='Include a spouse in planning'
            value={person.includeSpouse ? 'Yes' : 'No'}
          />
        )}
        {person.maritalStatus === true && person.includeSpouse === true && (
          <>
            <div
              css={css`
                padding-top: 32px;
              `}
            />
            <CardField type='profile' label="Spouse's first name" value={spouse.firstName} />
            <CardField
              type='profile'
              label="Spouse's gender"
              value={this.genderValue(spouse.gender)}
            />
            <CardField
              type='profile'
              label="Spouse's date of birth"
              value={spouse.birthDate ? dayjs(spouse.birthDate).format('MM/DD/YYYY') : ''}
            />
            <div
              css={css`
                padding-top: 32px;
              `}
            />
            <CardField
              type='profile'
              label="Spouse's annual compensation before taxes"
              value={`$${numberToDollars(spouse.annualIncome, true)}`}
            />
            <CardField
              type='profile'
              label="Spouse's other earned income, annually"
              value={`$${numberToDollars(spouse.otherIncome, true)}`}
            />
            <div
              css={css`
                padding-top: 32px;
              `}
            />
            <CardField
              type='profile'
              label="Spouse's retirement age"
              value={spouse.retirementAge}
            />
            {person.includeSpouse && (
              <>
                <CardField
                  type='profile'
                  label='Spouse plan to work part-time after retiring?'
                  value={_.get(spouseRetirementStatus, 'planningWorkPartTime', null) ? 'Yes' : 'No'}
                />
                {_.get(spouseRetirementStatus, 'planningWorkPartTime', null) === 1 && (
                  <>
                    <CardField
                      type='profile'
                      label='Duration: starting age?'
                      value={_.get(spouseRetirementStatus, 'durationStartingAge', null)}
                    />
                    <CardField
                      type='profile'
                      label='Duration: stopping age?'
                      value={_.get(spouseRetirementStatus, 'durationStoppingAge', null)}
                    />
                    <CardField
                      type='profile'
                      label='Spouse annual income from in-retirement job'
                      value={`$${numberToDollars(
                        _.get(spouseRetirementStatus, 'annualIncome', null),
                        true
                      )}`}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        <div
          css={css`
            padding-top: 32px;
          `}
        />
        {!isSpendown &&
          dependents.map(({ id, name, birthDate }) => {
            return (
              <Fragment key={id}>
                <div
                  css={css`
                    padding-top: 32px;
                  `}
                />
                <CardField type='profile' label="Dependent's name" value={name} />
                <CardField
                  type='profile'
                  label="Dependent's year of birth"
                  value={birthDate ? dayjs(birthDate).format('YYYY') : ''}
                />
              </Fragment>
            )
          })}
      </Card>
    )
  }
}

export default inject('store')(observer(AboutFamilyCard))
