/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import dayjs from 'dayjs'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import _ from 'lodash'

import {
  TextErrorField,
  Label,
  NumberInput,
  ButtonInput,
  CurrencyInput,
  Spacer,
} from '../../../components'
import { reduceValidationError } from '../../../utils'
import { Row, InputBox, Text, BackButton, ButtonsBox, NextButton } from '../../../components/styled'

const filterValuesForPatch = (values, current) => {
  return _.pickBy(values, (value, key) => value !== current[key])
}

const schema = yup.object().shape({
  gender: yup.string().nullable().required('Select one').oneOf(['F', 'M'], 'Select one'),
  annualSalary: yup
    .number()
    .nullable()
    .required('Annual salary is required')
    .min(1, 'Must be greater than $0')
    .max(9999999, 'Salary must be less than $10,000,000'),
  retirementAge: yup
    .number()
    .nullable()
    .when('$age', (age, schema) => {
      const maxRetAge = Math.min(99, age + 55)
      const validAgesMessage = `Valid ages: 20 - ${maxRetAge}`
      return schema
        .required(validAgesMessage)
        .min(20, validAgesMessage)
        .max(maxRetAge, validAgesMessage)
    }),
  maritalStatus: yup.boolean().nullable().oneOf([true, false], 'Select one'),
})

class AboutYouEdit extends Component {
  onSubmit = async ({ gender, retirementAge, annualSalary, maritalStatus }) => {
    try {
      const { onClose } = this.props
      const { account, person, updateAccount, updatePerson } = this.props.store

      const accountPatch = filterValuesForPatch(
        {
          annualSalary,
        },
        getSnapshot(account)
      )
      const accountPromise = _.isEmpty(accountPatch)
        ? Promise.resolve({})
        : updateAccount(accountPatch)

      const personPatch = filterValuesForPatch(
        {
          gender,
          retirementAge,
          maritalStatus,
        },
        getSnapshot(person)
      )
      const personPromise = _.isEmpty(personPatch) ? Promise.resolve({}) : updatePerson(personPatch)

      await Promise.all([accountPromise, personPromise])

      onClose()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  validate = values => {
    const {
      person: { age },
    } = this.props.store

    return schema
      .validate(values, { abortEarly: false, context: { age } })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  initialValues = () => {
    const {
      person: { gender, retirementAge, maritalStatus },
      account: { annualSalary },
    } = this.props.store

    const initialValues = {
      gender,
      retirementAge,
      annualSalary,
      maritalStatus,
    }

    // test for null cases
    // initialValues.gender = null
    // initialValues.retirementAge = null
    // initialValues.annualSalary = null
    // initialValues.maritalStatus = null

    return initialValues
  }

  render() {
    const { store, onClose } = this.props
    const { firstName, lastName, birthDate } = store.person

    return (
      <Form
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={this.onSubmit}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting }) => (
          <div>
            <Row>
              <Label>Your first name</Label>
              <InputBox>
                <Text>{firstName}</Text>
              </InputBox>
            </Row>

            <Spacer space='4px' />
            <Row>
              <Label>Your last name</Label>
              <InputBox>
                <Text>{lastName}</Text>
              </InputBox>
            </Row>

            <div
              css={css`
                padding-top: 48px;
              `}
            />
            <Row>
              <Label>Your gender</Label>
              <Field
                name='gender'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <InputBox>
                    <ButtonInput
                      name={input.name}
                      value='F'
                      isActive={input.value === 'F'}
                      text='Female'
                      onClick={(name, value) => input.onChange(value)}
                      width='126px'
                    />
                    <ButtonInput
                      name={input.name}
                      value='M'
                      isActive={input.value === 'M'}
                      text='Male'
                      onClick={(name, value) => input.onChange(value)}
                      width='126px'
                    />
                    <TextErrorField showError={meta.touched} error={meta.error} />
                  </InputBox>
                )}
              />
            </Row>

            <Spacer space='6px' />
            <Row>
              <Label>Your date of birth</Label>
              <InputBox>
                <Text>{birthDate ? dayjs(birthDate).format('MM/DD/YYYY') : ''}</Text>
              </InputBox>
            </Row>

            <div
              css={css`
                padding-top: 48px;
              `}
            />
            <Row>
              <Label>Your annual compensation before taxes</Label>
              <InputBox>
                <Field
                  name='annualSalary'
                  format={value => (value === null ? undefined : value)}
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <CurrencyInput
                      name={input.name}
                      value={input.value}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      showError={meta.touched}
                      error={meta.error}
                      decimalScale={0}
                    />
                  )}
                />
              </InputBox>
            </Row>
            <Row>
              <Label>Your retirement age</Label>
              <InputBox>
                <Field
                  name='retirementAge'
                  format={value => (value === null ? undefined : value)}
                  parse={v => v}
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <NumberInput
                      name={input.name}
                      value={input.value}
                      onChange={(name, value) => input.onChange(value)}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      showError={meta.touched}
                      error={meta.error}
                    />
                  )}
                />
              </InputBox>
            </Row>

            <div
              css={css`
                padding-top: 48px;
              `}
            />
            <Row>
              <Label>Marital status</Label>
              <Field
                name='maritalStatus'
                render={({ input, meta }) => (
                  <InputBox>
                    <ButtonInput
                      isActive={input.value === false}
                      name={input.name}
                      text='Single'
                      value={false}
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                    />
                    <ButtonInput
                      isActive={input.value === true}
                      name={input.name}
                      text='Married'
                      value
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                    />
                    <TextErrorField showError={meta.touched} error={meta.error} />
                  </InputBox>
                )}
              />
            </Row>

            <Spacer space='6px' />
            <ButtonsBox>
              <BackButton backgroundColor='#FFFFFF' onClick={onClose} disabled={submitting}>
                Cancel
              </BackButton>
              <NextButton onClick={handleSubmit} disabled={submitting}>
                Save
              </NextButton>
            </ButtonsBox>
          </div>
        )}
      />
    )
  }
}

export default inject('store')(observer(AboutYouEdit))
