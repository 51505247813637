import { useState, useEffect } from 'react'
import { observer, inject } from 'mobx-react'

import { Card, CardField, CardFieldError, Drawer } from '../../../components'
import { numberToDollars } from '../../../utils'
import EditPayout from './EditPayout'
import { Spacer } from '../../../components/styled'
import { PayoutAdditionalDetailsText, DisclaimerText } from '../styled'

const EditPayoutCard = props => {
  const {
    account: {
      editable,
      paymentType,
      pensionStartAge,
      pensionName,
      monthlyPensionIncome,
      lumpsumreceived,
      survivorFraction,
      type,
      accountId,
    },
  } = props.account
  const {
    config: { template, templateId },
  } = props.store
  const [editActive, setEditActive] = useState(false)
  const [pensionDisclaimers, setPensionDisclaimers] = useState([])

  useEffect(() => {
    setPensionDisclaimers(
      template?.disclaimer?.filter(disclaimer => {
        const now = new Date()
        const lessThan = new Date(disclaimer.lessThan)
        const greaterThan = new Date(disclaimer.greaterThan)
        return disclaimer.module === 'PENSION' && now < lessThan && now > greaterThan
      })
    )
  }, [template.disclaimer])

  const canEditPayout =
    (type !== 'Institutional' && editable) || (templateId === 4 && type === 'Pension' && accountId)

  const handleEdit = () => setEditActive(true)

  const handleCancel = () => setEditActive(false)

  return (
    <Card title='Payout details' handleEdit={canEditPayout ? handleEdit : null}>
      {paymentType !== null && (
        <CardField
          label='Payout type'
          value={`${paymentType === 'L' ? 'Lump Sum' : paymentType === 'M' ? 'Monthly' : ''}`}
        />
      )}

      {paymentType === 'L' && (
        <div>
          {(pensionStartAge !== null || monthlyPensionIncome !== null) && (
            <CardField
              label={
                pensionStartAge === null
                  ? 'Payout amount'
                  : monthlyPensionIncome === null
                  ? 'Payout age'
                  : 'Payout age | amount'
              }
              value={
                pensionStartAge === null
                  ? `$${numberToDollars(monthlyPensionIncome, true)}`
                  : monthlyPensionIncome === null
                  ? `${pensionStartAge}`
                  : `${pensionStartAge} | $${numberToDollars(monthlyPensionIncome, true)}`
              }
            />
          )}

          {lumpsumreceived !== null && (
            <>
              <CardField
                label='Received lump sum payout?'
                value={lumpsumreceived === 'Y' ? 'Yes' : 'No'}
              />
              {lumpsumreceived === 'Y' && (
                <>
                  <Spacer space='15px' />
                  <PayoutAdditionalDetailsText>
                    We assume you have either invested the payout from this pension or used it for a
                    purpose other than future retirement expenses. If you invested it, we assume you
                    have updated the account balance where the money was invested or have added a
                    new account.
                  </PayoutAdditionalDetailsText>
                </>
              )}
            </>
          )}
        </div>
      )}

      {paymentType === 'M' && (
        <div>
          {pensionStartAge !== null && (
            <CardField label='Age begin receiving' value={pensionStartAge} />
          )}

          {monthlyPensionIncome !== null && (
            <CardField
              label='Monthly payout'
              value={
                monthlyPensionIncome ? `$${numberToDollars(monthlyPensionIncome, true)}` : '$0'
              }
            />
          )}

          {survivorFraction !== null && (
            <CardField
              label='Survivor benefit'
              value={survivorFraction ? `${numberToDollars(survivorFraction, true)}%` : '0%'}
            />
          )}
        </div>
      )}

      {/* only show for atmos TEMPORARY */}
      {templateId === 4 && accountId && lumpsumreceived !== 'Y' && (
        <DisclaimerText style={{ marginTop: '16px' }}>
          <b>Be aware:</b> Regardless of the payout age selected, the payment shown reflects the
          most recent cash value of your pension balance. This amount will change on an annual
          basis.
        </DisclaimerText>
      )}

      {pensionDisclaimers
        ?.filter(disclaimer => disclaimer.objectName.toLowerCase() === pensionName.toLowerCase())
        ?.map(disclaimer => (
          <DisclaimerText
            style={{ marginTop: '16px' }}
            dangerouslySetInnerHTML={{ __html: disclaimer.textToDisplay }}
          />
        ))}

      {paymentType === null && (
        <CardFieldError>GuidedChoice does not have data on record for this account</CardFieldError>
      )}

      <Drawer active={editActive} title='Pension payouts' subtitle='Edit account' width='50%'>
        <EditPayout handleCancel={handleCancel} />
      </Drawer>
    </Card>
  )
}

export default inject('account', 'store')(observer(EditPayoutCard))
