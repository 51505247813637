import { types, flow } from 'mobx-state-tree'
import { API, API_URL } from '../../api'
import BGRight from '../../assets/images/BGRight.svg'
import { css } from '@emotion/css'
import {
  Account,
  Config,
  Person,
  Spouse,
  Dependents,
  ContactInfo,
  Documents,
  Engine,
  Features,
  Plan,
  UserSettings,
} from '../../models'
import { themeModel } from '../../themes/ThemeWrapper'
const { model, compose, enumeration, maybeNull, boolean } = types

const rightBackground = theme => css`
  background-image: url(${API_URL && theme.bgRight ? API_URL + theme.bgRight : BGRight});
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
  -webkit-background-size: 'cover';
  -moz-background-size: 'cover';
  -o-background-size: 'cover';
  background-size: 'cover';
`

const Onboarding = model({
  status: enumeration(['loading', 'done', 'error']),
  hasChosenSpendingOrSaving: maybeNull(boolean),
  theme: maybeNull(themeModel),
})
  .views(self => ({
    get chosenSpendingOrSaving() {
      return self.hasChosenSpendingOrSaving
    },
    get background() {
      return rightBackground(self.theme)
    },
  }))
  .actions(self => ({
    afterCreate: flow(function* () {
      try {
        yield Promise.all([
          self.getAccount(),
          self.getConfig(),
          self.getFeatures(),
          self.getUserSettings(),
          self.getPerson(),
          self.getSpouse(),
          self.getDependents(),
          self.getContactInfo(),
          self.getDocuments(),
          self.getPlanOverview(),
          self.getHasChosenSpendingOrSaving(),
        ])

        self.status = 'done'
      } catch (err) {
        self.status = 'error'
      }
    }),
    updateOnBoardingState(state) {
      const url = 'ui/updateOnBoardingState'
      return API.post(url, null, { params: { state } })
    },
    getHasChosenSpendingOrSaving: flow(function* () {
      const res = yield API.get('user-settings')
      self.hasChosenSpendingOrSaving = res?.data?.hasChosenSpendingOrSaving || false
      return res?.data?.hasChosenSpendingOrSaving || null
    }),
    setHasChosenSpendingOrSaving: flow(function* (boolean) {
      const res = yield API.put('user-settings', { hasChosenSpendingOrSaving: boolean })
      self.hasChosenSpendingOrSaving = boolean
      return res?.data?.hasChosenSpendingOrSaving || null
    }),
    setTheme(value) {
      self.theme = value
    },
  }))

const OnboardingStore = compose(
  Account,
  Config,
  UserSettings,
  Person,
  Spouse,
  Dependents,
  ContactInfo,
  Documents,
  Engine,
  Features,
  Plan,
  Onboarding
)

export default OnboardingStore
