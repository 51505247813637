import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { numberToDollars } from '../../../utils/utils'
import { Spacer } from '../../../components'

function BeforePurchaseAnnuity(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
      investmentWithdrawals,
    },
    store: { person, spouse },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'beforePurchaseAnnuity' })
    willMount.current = false
  }

  const page = findByName('beforePurchaseAnnuity')
  return (
    <Page header={<PageHeader padding='20px 40pt 0pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.BeforePurchaseTitle>
          Before you purchase or convert/withdraw funds to purchase your annuity
        </Wrapper.BeforePurchaseTitle>
        <Spacer space='10pt' />

        {_.get(investmentWithdrawals, 'primaryLifetimeInvestmentIncome.monthlyAmount', 0) > 0 && (
          <>
            <Wrapper.BeforePurchaseBlueBox>
              <Wrapper.BeforePurchaseBlueBoxText>
                Monthly payment needed from annuity in {person.displayName}'s name
              </Wrapper.BeforePurchaseBlueBoxText>
              <Wrapper.BeforePurchaseBlueBoxAmount>
                {numberToDollars(
                  _.get(investmentWithdrawals, 'primaryLifetimeInvestmentIncome.monthlyAmount', 0),
                  true
                )}
              </Wrapper.BeforePurchaseBlueBoxAmount>
            </Wrapper.BeforePurchaseBlueBox>
            <Spacer space='8pt' />
          </>
        )}

        <Wrapper.BeforePurchaseWrapper>
          {person.includeSpouse &&
            _.get(investmentWithdrawals, 'spouseLifetimeInvestmentIncome.monthlyAmount', 0) > 0 && (
              <>
                <Wrapper.BeforePurchaseBlueBox>
                  <Wrapper.BeforePurchaseBlueBoxText>
                    Monthly payment needed from annuity in {spouse.firstName}'s name
                  </Wrapper.BeforePurchaseBlueBoxText>
                  <Wrapper.BeforePurchaseBlueBoxAmount>
                    {numberToDollars(
                      _.get(
                        investmentWithdrawals,
                        'spouseLifetimeInvestmentIncome.monthlyAmount',
                        0
                      ),
                      true
                    )}
                  </Wrapper.BeforePurchaseBlueBoxAmount>
                </Wrapper.BeforePurchaseBlueBox>
                <Spacer space='15pt' />
              </>
            )}

          <Wrapper.BeforePurchaseHowTo>How to choose an annuity</Wrapper.BeforePurchaseHowTo>
          <Spacer space='5pt' />
          <Wrapper.BeforePurchaseGreyBox>
            <Wrapper.BeforePurchaseGreyBoxTitle>
              Don't try this at home!
            </Wrapper.BeforePurchaseGreyBoxTitle>
            <Wrapper.BeforePurchaseGreyBoxSubTitle>
              GuidedChoice recommendation:
            </Wrapper.BeforePurchaseGreyBoxSubTitle>
            <Wrapper.BeforePurchaseGreyBoxText>
              This guide can point you in the right direction, but choosing the best option takes
              expert knowledge and some number-crunching. Be sure to get help from an annuity
              professional. Keep in mind that there are all kinds of companies out there selling all
              kinds of annuities. So, as you would for any major purchase, shop around and ask a lot
              of questions before you buy.
            </Wrapper.BeforePurchaseGreyBoxText>
            <Spacer space='10pt' />

            <Wrapper.BeforePurchaseGreyBoxTitle>
              Access to your money
            </Wrapper.BeforePurchaseGreyBoxTitle>
            <Wrapper.BeforePurchaseGreyBoxSubTitle>
              GuidedChoice recommendation:
            </Wrapper.BeforePurchaseGreyBoxSubTitle>
            <Wrapper.BeforePurchaseGreyBoxText>
              Most annuities don't allow access to the money you've invested once payments begin,
              but some do under certain conditions. If this is a concern for you, make sure you
              understand clearly if, when, and how you can access your money.
            </Wrapper.BeforePurchaseGreyBoxText>
          </Wrapper.BeforePurchaseGreyBox>
        </Wrapper.BeforePurchaseWrapper>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(BeforePurchaseAnnuity))
