import styled from '@emotion/styled'

const breakpoint = 'max-width: 767px'
const Row = styled.div`
  display: flex;
`
const Icon = styled.div`
  flex: 0 0 64px;
  min-height: 32px;
  height: 32px;
  padding: 0 16px;
`
const Item = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (${breakpoint}) {
    flex-wrap: wrap;
  }
`
const Name = styled.div`
  width: calc(100% - 402px);
  @media (${breakpoint}) {
    width: 100%;
  }
`
const Graph = styled.div`
  display: flex;
  width: 402px;
  @media (${breakpoint}) {
    width: 100%;
    justify-content: flex-end;
  }
`
const OldValue = styled.div`
  text-align: right;
  width: 200px;
  color: #7a8e96;
`
const Separator = styled.div`
  height: 100%;
  border-left: ${({ hide }) => (hide ? '2px solid transparent' : '2px solid #7a8e96')};
`
const NewValue = styled.div`
  width: 200px;
`
const DottedLine = styled.div`
  position: absolute;
  border-bottom: 1px dotted #69dee3;
  width: 100%;
  bottom: 12px;
  left: 0;
  right: 0;
`

const Grid = ({ icon, name, oldValue, newValue, underline, separator = true, ...props }) => (
  <Row {...props}>
    <Icon>{icon}</Icon>
    <Item>
      <Name>{name}</Name>
      <Graph>
        <OldValue>{oldValue}</OldValue>
        <Separator hide={!separator} />
        <NewValue>{newValue}</NewValue>
      </Graph>
      {underline && <DottedLine />}
    </Item>
  </Row>
)

export default Grid
