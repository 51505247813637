import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { numberToDollars } from '../../../utils'
import { Layout } from '../elements'

const ComparisonContainer = styled(Layout)`
  display: flex;
  flex-direction: column;
  margin: 34px auto;
`
const RowHeader = styled(Layout.DisplaySmall)`
  color: #4a606a;
`
const RowTitle = styled(Layout.DisplaySmall)``
const RowSubject = styled(Layout.DisplaySmall)``
const ComparisonRow = styled(Layout.DisplaySmall)`
  display: flex;
  flex: 0 1 auto;
  justify-content: space-evenly;
  margin-bottom: 24px;

  & > div {
    width: 225px;

    &:first-of-type {
      min-width: 35%;
    }
    &:nth-of-type(2) {
      color: #7a8e96;
    }
    &:last-of-type {
      color: #038ab7;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin: 0 7vw;

    &:first-of-type {
      display: none;
    }
    & > div {
      width: 100%;

      &:nth-of-type(2),
      &:nth-of-type(3) {
        text-align: center;
      }
    }
  }
`

const ContributionComparison = props => {
  const { baseCase, acceptedCase, institutional } = props.store

  return (
    <ComparisonContainer>
      <ComparisonRow>
        <RowHeader />
        <RowHeader>Contributing now</RowHeader>
        <RowHeader>If implemented</RowHeader>
      </ComparisonRow>

      <ComparisonRow
        css={css`
          margin-bottom: 48px;
        `}>
        <RowTitle>
          {institutional.oneContributionOnly ? 'Annual pre-tax total' : 'Annual total'}
        </RowTitle>
        <RowSubject>
          {baseCase.totalAnnualPercentage}% | ${numberToDollars(baseCase.totalAnnualSavings, true)}
          /year
        </RowSubject>
        <RowSubject>
          {acceptedCase.totalAnnualPercentage}% | $
          {numberToDollars(acceptedCase.totalAnnualSavings, true)}/year
        </RowSubject>
      </ComparisonRow>

      {!institutional.oneContributionOnly && (
        <div>
          <ComparisonRow>
            <RowTitle>Includes pre-tax contribution of:</RowTitle>
            <RowSubject>
              {baseCase.primary.preTaxAnnualContributions}% | $
              {numberToDollars(baseCase.primary.preTaxAnnualContribDollars, true)}/year
            </RowSubject>
            <RowSubject>
              {acceptedCase.primary.preTaxAnnualContributions}% | $
              {numberToDollars(acceptedCase.primary.preTaxAnnualContribDollars, true)}/year
            </RowSubject>
          </ComparisonRow>

          {institutional.rothContribAllowed && (
            <ComparisonRow>
              <RowTitle>Includes Roth contribution of:</RowTitle>
              <RowSubject>
                {baseCase.primary.rothAnnualContributions}% | $
                {numberToDollars(baseCase.primary.rothAnnualContribDollars, true)}/year
              </RowSubject>
              <RowSubject>
                {acceptedCase.primary.rothAnnualContributions}% | $
                {numberToDollars(acceptedCase.primary.rothAnnualContribDollars, true)}/year
              </RowSubject>
            </ComparisonRow>
          )}

          {institutional.posttaxContribAllowed && (
            <ComparisonRow>
              <RowTitle>Includes after-tax contribution of:</RowTitle>
              <RowSubject>
                {baseCase.primary.postTaxAnnualContributions}% | $
                {numberToDollars(baseCase.primary.postTaxAnnualContribDollars, true)}
                /year
              </RowSubject>
              <RowSubject>
                {acceptedCase.primary.postTaxAnnualContributions}% | $
                {numberToDollars(acceptedCase.primary.postTaxAnnualContribDollars, true)}/year
              </RowSubject>
            </ComparisonRow>
          )}
        </div>
      )}
    </ComparisonContainer>
  )
}

export default inject('store')(observer(ContributionComparison))
