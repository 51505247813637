import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import HoldingRow from './HoldingRow'

import { Spacer } from '../../../components'
import { SplitBarChartHeader, SplitBarChartRisk, SplitBarChartLegend } from '../../shared'
import { Page, PageHeader } from '../elements'
import { ContentMainHeaderChart } from '../elements/styled'

const IndexToSVGMap = {
  0: 'Cash',
  1: 'Bonds',
  2: 'Stock',
  3: 'Misc',
}
class HoldingChartPage extends Component {
  renderRows(data, svg) {
    const sortedData = _.orderBy(data, obj => [(obj?.name || '').toLowerCase()], ['asc'])

    return sortedData
      .filter(item => item.newValue !== null || item.oldValue !== null)
      .map((item, i) => {
        return (
          <HoldingRow
            key={item.name}
            title={item.name}
            i={i}
            svg={svg}
            oldValue={item.oldValue}
            newValue={item.newValue}
          />
        )
      })
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'holdingChart' })
  }

  render() {
    const { store, guide } = this.props
    const { displayName } = store.person
    const {
      active,
      employerAccounts,
      config,
      institutionalHoldingData,
      config: { isRetail },
    } = store
    const { name: clientInstitutionName } = active
    const page = guide.guidePages.pages.findByName('holdingChart')

    const eligibleEmployerAccounts = employerAccounts.filter(acct => acct.contributionEligibility)

    // Must follow this order: 401k, 401a, 403b, 457, Keogh
    let employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 1) // 401k
    if (employerAccount.length === 0) {
      employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 23) // 401a
      if (employerAccount.length === 0) {
        employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 2) // 403b
        if (employerAccount.length === 0) {
          employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 4) // 457
          if (employerAccount.length === 0) {
            employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 6) // Keogh
          }
        }
      }
    }

    const accountName = () => {
      let name = ''
      if (isRetail && employerAccount.length > 0) {
        name = employerAccount.filter(acct => acct.participantId === store.person.id)[0]?.name || ''
      } else {
        name = employerAccount[0]?.name
      }

      switch (config.templateId) {
        case 1:
        case 2:
          return `${displayName}'s ${clientInstitutionName} account`

        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 19:
          return `${displayName}'s retirement plan account (${name})`
        default:
          return `${displayName}'s retirement plan account with ${config.template.sponsorName}`
      }
    }

    const { riskLevelTextShort: oldRiskText, riskScaleIndex } = store.baseCase
    const { riskLevelTextShort: newRiskText, riskScaleIndex: riskScaleIndexNew } =
      store.acceptedCase

    return active && active.balance && !!institutionalHoldingData ? (
      <Page header={<PageHeader text='Your Plan' />} page={page}>
        <ContentMainHeaderChart>Investment mix for: {accountName()}</ContentMainHeaderChart>
        <Spacer space='6px' />
        <SplitBarChartLegend paddingLeft={45} />
        <Spacer space='4px' />
        <SplitBarChartHeader />
        <SplitBarChartRisk
          oldRiskText={oldRiskText}
          newRiskText={newRiskText}
          rangeIndexOld={riskScaleIndex}
          rangeIndexNew={riskScaleIndexNew}
        />

        {institutionalHoldingData.map((mix, inx) => this.renderRows(mix, IndexToSVGMap[inx]))}
      </Page>
    ) : null
  }
}

export default inject('store', 'guide')(observer(HoldingChartPage))
