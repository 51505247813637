import { Field } from 'react-final-form'

import { NumberInput } from '../../../components'
import { BorderedCard, CardTitle, RetAgeContainer, InputLabel } from './EditDrawerStyle'

const MakeChangesRetirementAge = ({ store, editable }) => {
  const {
    person: { displayName },
    spouse: { firstName },
  } = store
  const { primaryRetAgeEditable, spouseRetAgeEditable } = editable

  return (
    (primaryRetAgeEditable || spouseRetAgeEditable) && (
      <BorderedCard>
        <CardTitle>Retirement age</CardTitle>
        <RetAgeContainer>
          {primaryRetAgeEditable && (
            <div>
              <Field
                name='primaryRetAge'
                format={value => (value === null ? undefined : value)}
                parse={v => v}
                render={({ input, meta }) => (
                  <NumberInput
                    name={input.name}
                    onChange={(name, value) => input.onChange(value)}
                    value={input.value}
                    width='92px'
                    error={meta.error}
                    showError
                  />
                )}
              />
              <InputLabel>{displayName}</InputLabel>
            </div>
          )}

          {spouseRetAgeEditable && (
            <div>
              <Field
                name='spouseRetAge'
                format={value => (value === null ? undefined : value)}
                parse={v => v}
                render={({ input, meta }) => (
                  <NumberInput
                    name={input.name}
                    onChange={(name, value) => input.onChange(value)}
                    value={input.value}
                    width='92px'
                    error={meta.error}
                    showError
                  />
                )}
              />
              <InputLabel>{firstName}</InputLabel>
            </div>
          )}
        </RetAgeContainer>
      </BorderedCard>
    )
  )
}

export default MakeChangesRetirementAge
