export const BOND_CASH_OTHER_SECURITIES = [
  // {
  //   ticker: "gco13",
  //   securityName: "Balanced Fund (60 percent Stocks/40 percent Bonds)",
  //   id: 39
  // },
  {
    ticker: 'gco07',
    securityName: 'Large Cap Fund (e.g., S&P 500 or Russell 1000)',
    id: 33,
  },
  {
    ticker: 'gco08',
    securityName: 'Mid Cap Fund',
    id: 34,
  },
  {
    ticker: 'gco09',
    securityName: 'Small Cap Fund (e.g., Russell 2000 or S&P 600)',
    id: 35,
  },
  {
    ticker: 'gco10',
    securityName: 'International Stock Fund',
    id: 36,
  },

  {
    ticker: 'gco11',
    securityName: 'Emerging Markets Stock Fund',
    id: 37,
  },
  {
    ticker: 'gco22',
    securityName: 'Real Estate',
    id: 48,
  },
  {
    ticker: 'gco25',
    securityName: 'Stock Options',
    id: 51,
  },
  {
    ticker: 'gco19',
    securityName: 'Individual Large Cap Stock',
    id: 45,
  },
  {
    ticker: 'gco20',
    securityName: 'Individual Mid Cap Stock',
    id: 46,
  },
  {
    ticker: 'gco21',
    securityName: 'Individual Small Cap Stock',
    id: 47,
  },
  {
    ticker: 'gco23',
    securityName: 'Precious Metals',
    id: 49,
  },
  {
    ticker: 'gco24',
    securityName: 'Collectibles',
    id: 50,
  },
  {
    ticker: 'gco26',
    securityName: 'Other',
    id: 52,
  },
  {
    ticker: '',
    securityName: 'Short-term Bond (1-3 years to maturity)',
    id: 16,
  },
  {
    ticker: '',
    securityName: 'Intermediate Term Bond (4-10 years to maturity)',
    id: 17,
  },
  {
    ticker: '',
    securityName: 'Long-term Bond (11+ years to maturity)',
    id: 18,
  },
  {
    ticker: '',
    securityName: 'High Yield Bond (Investment Grade less than A)',
    id: 2538003,
  },
  {
    ticker: '',
    securityName: 'Municipal Short-term Bond (1-3 years to maturity)',
    id: 19,
  },
  {
    ticker: '',
    securityName: 'Municipal Intermediate Term Bond (4-10 years to maturity)',
    id: 20,
  },
  {
    ticker: '',
    securityName: 'Municipal Long-term Bond (11+ years to maturity)',
    id: 21,
  },
  {
    ticker: '',
    securityName: 'Money Market',
    id: 22,
  },
  {
    ticker: '',
    securityName: 'Stable Value Fund',
    id: 24,
  },
  {
    ticker: '',
    securityName: 'Guaranteed Investment Contract (GIC)',
    id: 25,
  },
  {
    ticker: '',
    securityName: 'Cash or equivalent',
    id: 8,
  },
  {
    ticker: '',
    securityName: 'Passbook Savings Account',
    id: 27,
  },
  {
    ticker: '',
    securityName: 'Certificate of Deposit (CD)',
    id: 23,
  },
  {
    ticker: '',
    securityName: 'Allocation | 15% to 30% Equity (a.k.a. Conservative or Income)',
    id: 2538004,
  },
  {
    ticker: '',
    securityName: 'Allocation | 30% to 50% Equity (a.k.a. Moderate risk)',
    id: 2538005,
  },
  {
    ticker: '',
    securityName: 'Allocation | 50% to 70% Equity (a.k.a. Balanced)',
    id: 2538006,
  },
  {
    ticker: '',
    securityName: 'Allocation | 70% to 85% Equity (a.k.a. Aggressive or Growth & Income)',
    id: 2538007,
  },
  {
    ticker: 'gco12',
    securityName: 'Allocation | 85%+ Equity',
    id: 38,
  },
  // {
  //   ticker: "",
  //   securityName: "Growth and Income Fund (80% stocks/20% bonds)",
  //   id: 38
  // },
  {
    ticker: 'GCBD8',
    securityName: 'Inflation-Protected Bond',
    id: 2855131,
  },
  {
    ticker: 'GCBD9',
    securityName: 'International Bond (Developed countries)',
    id: 2855235,
  },
  {
    ticker: 'GCBD10',
    securityName: 'Emerging Markets Bond',
    id: 2855230,
  },
  {
    ticker: 'GCOBF2',
    securityName: 'Inflation-Protected Bond Fund',
    id: 2855233,
  },
  {
    ticker: 'GCOBF3',
    securityName: 'International Bond Fund (Developed countries)',
    id: 2855234,
  },
  {
    ticker: 'GCOBF4',
    securityName: 'Emerging Markets Bond Fund',
    id: 2855252,
  },
  {
    ticker: 'GCOREIT1',
    securityName: 'REIT Fund',
    id: 2855236,
  },
  {
    ticker: 'gco27',
    securityName: 'Target Date Retirement (a.k.a. Retirement Income Fund)',
    id: 1838518,
  },
  {
    ticker: 'gco28',
    securityName: 'Target Date 2020',
    id: 1838519,
  },
  {
    ticker: 'gco29',
    securityName: 'Target Date 2025',
    id: 1838531,
  },
  {
    ticker: 'gco30',
    securityName: 'Target Date 2030',
    id: 1838522,
  },
  {
    ticker: 'gco31',
    securityName: 'Target Date 2035',
    id: 1838532,
  },
  {
    ticker: 'gco32',
    securityName: 'Target Date 2040',
    id: 1838524,
  },
  {
    ticker: 'gco33',
    securityName: 'Target Date 2045',
    id: 1838533,
  },
  {
    ticker: 'gco34',
    securityName: 'Target Date 2050',
    id: 1838526,
  },
  {
    ticker: 'gco35',
    securityName: 'Target Date 2055',
    id: 1838527,
  },
  {
    ticker: 'gco36',
    securityName: 'Target Date 2060 or later',
    id: 1838528,
  },
  {
    ticker: 'GCOCMDF1',
    securityName: 'Commodities Fund',
    id: 2855237,
  },
  {
    ticker: 'gco01',
    securityName: 'Money Market Fund',
    id: 28,
  },
  {
    ticker: 'gco02',
    securityName: 'Short-term Bond Fund (1-3 years)',
    id: 29,
  },
  {
    ticker: 'gco03',
    securityName: 'Intermediate Term Bond Fund (4-10 years)',
    id: 30,
  },
  {
    ticker: 'gco05',
    securityName: 'Long-term Bond Fund (11+ years)',
    id: 31,
  },
  {
    ticker: 'gco06',
    securityName: 'High Yield Bond Fund',
    id: 32,
  },
]
