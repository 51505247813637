/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'
import * as yup from 'yup'

import {
  Label,
  TextInput,
  TooltipText,
  ButtonInput,
  TextErrorField,
  HelpIcon,
} from '../../../components'
import { InputBox, BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { reduceValidationError } from '../../../utils'
import { Row } from '../styled'

const schema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .required('Your email address is required')
    .email('A valid email is required'),
  electronicDeliveryConsent: yup.boolean().nullable().oneOf([true, false], 'Select one'),
})

class ContactInfoEdit extends Component {
  onSubmit = async values => {
    try {
      const { store, onClose } = this.props
      const {
        contactInfo,
        updateContactInfo,
        communicationPreferences,
        updateCommunicationPreferences,
      } = store
      const { receiveElectronicStatements, receiveMarketingStatements, ...configValues } = values

      if (!_.isEqual(configValues, getSnapshot(contactInfo))) {
        await updateContactInfo(configValues)
      }

      if (
        !_.isEqual(
          receiveElectronicStatements,
          communicationPreferences.RECEIVE_ELECTRONIC_STATEMENTS
        )
      ) {
        await updateCommunicationPreferences({
          RECEIVE_ELECTRONIC_STATEMENTS: receiveElectronicStatements,
          RECEIVE_MARKETING_EMAILS: receiveMarketingStatements,
        })
      }

      if (
        !_.isEqual(receiveMarketingStatements, communicationPreferences.RECEIVE_MARKETING_EMAILS)
      ) {
        await updateCommunicationPreferences({
          RECEIVE_ELECTRONIC_STATEMENTS: receiveElectronicStatements,
          RECEIVE_MARKETING_EMAILS: receiveMarketingStatements,
        })
      }

      onClose()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  initialValues = () => {
    const { store } = this.props
    const {
      RECEIVE_ELECTRONIC_STATEMENTS: receiveElectronicStatements,
      RECEIVE_MARKETING_EMAILS: receiveMarketingStatements,
    } = store.communicationPreferences

    return {
      ...getSnapshot(store.contactInfo),
      receiveElectronicStatements,
      receiveMarketingStatements,
    }
  }

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  render() {
    const { onClose } = this.props

    return (
      <Form
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={this.onSubmit}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting }) => (
          <div>
            <Row>
              <Label>Email</Label>
              <InputBox>
                <Field
                  name='email'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      name={input.name}
                      value={input.value}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      showError={meta.touched}
                      error={meta.error}
                      expanded={input.value}
                      css={css`
                        max-width: 100%;
                      `}
                    />
                  )}
                />
              </InputBox>
            </Row>
            <Row>
              <Label>
                Electronic delivery consent{' '}
                <HelpIcon size='mediumLarge' tooltip={TooltipText.electronicDeliveryConsent()} />
              </Label>
              <InputBox />
            </Row>
            <Row>
              <Label>GuidedChoice annual summary</Label>
              <Field
                name='receiveElectronicStatements'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <InputBox>
                    <ButtonInput
                      isActive={input.value === true}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      text='On'
                      value
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                    />
                    <ButtonInput
                      isActive={input.value === false}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      text='Off'
                      value={false}
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                    />
                    <TextErrorField showError={meta.touched} error={meta.error} />
                  </InputBox>
                )}
              />
            </Row>
            <Row>
              <Label>Email Communications</Label>
              <Field
                name='receiveMarketingStatements'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <InputBox>
                    <ButtonInput
                      isActive={input.value === true}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      text='On'
                      value
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                    />
                    <ButtonInput
                      isActive={input.value === false}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      text='Off'
                      value={false}
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                    />
                    <TextErrorField showError={meta.touched} error={meta.error} />
                  </InputBox>
                )}
              />
            </Row>
            <div
              css={css`
                padding-top: 32px;
              `}
            />
            <ButtonsBox>
              <BackButton backgroundColor='#FFFFFF' onClick={onClose} disabled={submitting}>
                Cancel
              </BackButton>
              <NextButton onClick={handleSubmit} disabled={submitting}>
                Save
              </NextButton>
            </ButtonsBox>
          </div>
        )}
      />
    )
  }
}

export default inject('store')(observer(ContactInfoEdit))
