/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const accept = 'rt-snackbar-accept'

const active = 'rt-snackbar-active'

const button = 'rt-snackbar-button'

const cancel = 'rt-snackbar-cancel'

const label = 'rt-snackbar-label'

const snackbar = 'rt-snackbar-snackbar'

const warning = 'rt-snackbar-warning'

const _snackbar = css`
  align-items: center;
  background-color: ${ds.get('snackbar.backgroundColor')};
  border-radius: ${ds.get('snackbar.borderRadius')};
  bottom: 0;
  color: ${ds.get('snackbar.color')};
  display: flex;
  left: ${ds.get('snackbar.horizontalOffset')};
  margin: ${ds.get('snackbar.verticalOffset')} auto 0;
  padding: ${ds.get('snackbar.verticalOffset')} ${ds.get('snackbar.horizontalOffset')};
  position: fixed;
  right: ${ds.get('snackbar.horizontalOffset')};
  transition: all ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')}
    ${ds.get('animation.duration')};
  z-index: ${ds.z('higher')};

  ${ds.get('reset')};

  &.${accept} .${button} {
    color: ${ds.get('snackbar.colorAccept')};
  }

  &.${warning} .${button} {
    color: ${ds.get('snackbar.colorWarning')};
  }

  &.${cancel} .${button} {
    color: ${ds.get('snackbar.colorCancel')};
  }

  &:not(.${active}) {
    transform: translateY(100%);
  }

  &.${active} {
    transform: translateY(0%);
  }
`

const _label = css`
  flex-grow: 1;
  font-size: ${ds.fs('s')};
`

const _button = css`
  margin: ${reduceCSSCalc(`calc(-1 * ${ds.get('snackbar.verticalOffset')} / 2)`)}
    ${reduceCSSCalc(`calc(-1 * ${ds.get('snackbar.horizontalOffset')} / 2)`)}
    ${reduceCSSCalc(`calc(-1 * ${ds.get('snackbar.verticalOffset')} / 2)`)}
    ${ds.get('snackbar.buttonOffset')};
  min-width: inherit;
`

export default {
  accept,
  active,
  button: cx(button, _button),
  cancel,
  label: cx(label, _label),
  snackbar: cx(snackbar, _snackbar),
  warning,
}
