/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'

import { Page } from '../elements'
import { Spacer, Button } from '../../../components'

export default function Success({ props, history }) {
  const {
    config: { isRetail },
  } = props.onboarding

  return (
    <Page>
      <Page.Heading>Let's get planning!</Page.Heading>

      <Spacer space='10px' />
      <Page.Container
        css={css`
          padding-right: 75px;
        `}>
        {isRetail ? (
          <>
            <Page.Text>
              The key to getting started is to add information about your financial resources
              related to retirement planning—such as work-related investment accounts and IRAs—and
              plan for specific financial goals.
            </Page.Text>
            <Spacer space='10px' />
            <Page.Text>
              If you don't yet have any accounts added, not all the numbers will make sense on the
              next page, which is your home page. Your action item is to add at least one account.
              After that, it's up to you: get projected results based on this one account, add other
              accounts, set financial goals, or do some scenario planning. No matter what, we'll
              help you set a confident path for your retirement.
            </Page.Text>
          </>
        ) : (
          <>
            <Page.Text>
              For the most accurate picture, we recommend adding information about any other
              financial resources related to retirement planning—such as work-related investment
              accounts and IRAs—and planning for specific financial goals.
            </Page.Text>
            <Spacer space='10px' />
            <Page.Text>
              But you don't have to. What's been provided by your employer is enough to give you an
              idea of how you're doing. From the next page, which is your home page, you can get
              projected results based on your employer plan account, add other accounts, set
              financial goals, or do some scenario planning. No matter what, we'll help you set a
              confident path for your retirement.
            </Page.Text>
          </>
        )}
        <Spacer space='20px' />
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 90%;
          `}>
          <Button
            type='button'
            onClick={() => history.push('/home')}
            primary
            label='Ok'
            width='140px'
          />
        </div>
      </Page.Container>
    </Page>
  )
}
