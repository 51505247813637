/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import dayjs from 'dayjs'

import { CircleNegative, CirclePlus, Link } from '../../../assets/icons'
import {
  Card,
  CardField,
  CardFieldError,
  SVGWrapper,
  HelpIcon,
  TooltipText,
} from '../../../components'
import { accountTypeKeyToPlanTypeId } from '../../../constants'
import { trackInvestmentsByTaxType } from '../utils'
import AddInvestmentButton from './components/AddInvestmentButton'
import InstitutionalInvestmentsDisplay from './components/InstitutionalInvestmentsDisplay'
import InvestmentsDisplay from './components/InvestmentsDisplay'
import PlaidLink, { PlaidRelink, PlaidUnlink, PlaidRefresh } from './components/PlaidLink'
import { ViewInvestments } from '../styled'

function WarningIcon(props) {
  return (
    <svg viewBox='0 0 24 24' width='1em' height='1em' {...props}>
      <title>warning</title>
      <g data-name='icn/warning' fill='#f48024'>
        <path d='M23.83 21.83L12.95.61a1 1 0 00-1.87 0L.15 21.92a1.31 1.31 0 000 1.22 1.17 1.17 0 00.93.86h21.86a1.12 1.12 0 001-.88 1.88 1.88 0 00-.11-1.29zm-20.52.22L12 4l9 18.07H3.31z' />
        <path
          data-name='!'
          d='M13 10.15h-2v4.14a10.67 10.67 0 00.09 1.29q.08.63.21 1.35h1.42q.13-.72.21-1.35a10.67 10.67 0 00.07-1.29v-4.14zm-2 8.94a1.1 1.1 0 00.07.43 1 1 0 00.21.33 1.08 1.08 0 00.33.23.91.91 0 00.4.09.9.9 0 00.38-.09 1 1 0 00.32-.23 1 1 0 00.21-.33 1.1 1.1 0 00.08-.44 1.15 1.15 0 00-.08-.43 1.11 1.11 0 00-.21-.34 1 1 0 00-.32-.23A.9.9 0 0012 18a.91.91 0 00-.4.09 1.08 1.08 0 00-.33.23 1.11 1.11 0 00-.21.34 1.15 1.15 0 00-.06.42z'
        />
      </g>
    </svg>
  )
}

const EditInvestmentsCard = props => {
  const {
    features,
    config,
    account: { managedAccount },
    plaidLinkedAccountIds,
    findPlaidAccountById,
  } = props.store
  const {
    account: { rothContribAllowed, posttaxContribAllowed },
    investments,
    investedInvestments,
    totalBalance,
    preTaxBalance,
    preTaxInvestments,
    rothBalance,
    rothInvestments,
    afterTaxBalance,
    afterTaxInvestments,
    availableInvestments,
  } = props.account
  const { type, accountType, id: accountId } = props.match.params
  const [expandedView, setExpandedView] = useState(false)
  const canEdit = type !== 'institutional' || features.editInstitutionalAccount
  const investmentsExist =
    investments.length > 0 || (type === 'institutional' && availableInvestments.length > 0)

  const showManagedAccountView =
    type === 'institutional' && features.managedAccountAvailable && managedAccount

  const toggleSummary = () => setExpandedView(previous => !previous)

  const handlePlaidLinkSuccess = () => {
    const { type, id } = props.match.params
    props.store.getExistingPlaidLinks()
    props.store.getAccounts()
    props.account.getInvestments({ type, id })
  }

  useEffect(() => {
    const getAvailableInvestments = async () => await props.account.getAvailableInvestments()
    if (
      props.match.params.type === 'institutional' &&
      props.store.features.editInstitutionalAccount
    ) {
      getAvailableInvestments()
    }
  }, [props.account, props.match.params.type, props.store.features.editInstitutionalAccount])

  const ManagedAccountView = showManagedAccountView && (
    <div
      css={css`
        margin-top: -12px;
        font-size: 12px;
        color: #7a8e96;
        margin-bottom: 16px;
      `}>
      {config.template.managedAccountLabel}
    </div>
  )

  const renderSummaryToggle = (
    <div
      css={css`
        color: #3a4d57;
        cursor: pointer;
        font-size: 18px;
        margin: 36px 0;
      `}
      onClick={toggleSummary}>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}>
        <SVGWrapper svg={expandedView ? CircleNegative : CirclePlus} fill='#3A4D57' size='large' />
        <ViewInvestments>
          {canEdit ? 'View/update investments' : 'View investments'}
        </ViewInvestments>
      </div>
    </div>
  )

  const renderAddButton = canEdit && (
    <div
      css={css`
        margin-top: 16px;
      `}>
      <AddInvestmentButton
        linkAddress={`/accounts/add/${type}/${accountType}/${accountId}/investment`}
      />
    </div>
  )

  const SummaryView = investmentsExist && !expandedView && (
    <div>
      <CardField
        isCurrency
        label='Account balance'
        subValue={
          investedInvestments.length === 1
            ? '(1 investment)'
            : `(${investedInvestments.length} investments)`
        }
        value={totalBalance}
      />

      {trackInvestmentsByTaxType(accountType) && (
        <div
          css={css`
            margin-top: 20px;
          `}>
          {/* Should these be based off of preTaxAllowed, rothAllowed, and afterTaxAllowed instead of non-zero balance? */}

          {preTaxBalance > 0 && (
            <CardField
              isCurrency
              label='Pre-tax balance'
              subValue={
                preTaxInvestments.length === 1
                  ? '(1 investment)'
                  : `(${preTaxInvestments.length} investments)`
              }
              value={preTaxBalance}
            />
          )}

          {rothBalance > 0 && (
            <CardField
              isCurrency
              label='Roth balance'
              subValue={
                rothInvestments.length === 1
                  ? '(1 investment)'
                  : `(${rothInvestments.length} investments)`
              }
              value={rothBalance}
            />
          )}

          {afterTaxBalance > 0 && (
            <CardField
              isCurrency
              label='After-tax balance'
              subValue={
                afterTaxInvestments.length === 1
                  ? '(1 investment)'
                  : `(${afterTaxInvestments.length} investments)`
              }
              value={afterTaxBalance}
            />
          )}
        </div>
      )}
      {renderSummaryToggle}
    </div>
  )

  const showInstitutionalInvestmentsView =
    type === 'institutional' && investmentsExist && expandedView

  const InstitutionalInvestmentsView = showInstitutionalInvestmentsView && (
    <div>
      {renderSummaryToggle}
      <InstitutionalInvestmentsDisplay history={props.history} match={props.match} />
    </div>
  )

  const InvestmentsView = type !== 'institutional' && investmentsExist && expandedView && (
    <div>
      {renderSummaryToggle}
      <InvestmentsDisplay />
    </div>
  )

  const NoDataView = !investmentsExist && (
    <div>
      <CardFieldError>GuidedChoice does not have data on record for this account</CardFieldError>
      {renderAddButton}
    </div>
  )

  const plaidAccountLinked = _.includes(plaidLinkedAccountIds, Number(accountId))
  const plaidAccount = findPlaidAccountById(Number(accountId))
  const plaidAccountLastRefreshDate = _.get(plaidAccount, 'lastRefreshedOn', null)
  const requiresRelink = _.get(plaidAccount, 'requiresRelink', false)

  const PlaidAccountLinkView = features.enablePlaidLink &&
    (type === 'non-gc' || (type === 'institutional' && features.editInstitutionalAccount)) && (
      <div
        css={css`
          background: transparent;
        `}>
        {!plaidAccountLinked && (
          <div
            css={css`
              text-align: right;
            `}>
            <PlaidLink
              onSuccess={handlePlaidLinkSuccess}
              accountId={accountId}
              planType={accountTypeKeyToPlanTypeId[accountType]}
            />
          </div>
        )}
        {plaidAccountLinked && (
          <div
            css={css`
              text-align: right;
            `}>
            <div
              css={css`
                font-size: 14px;
                color: #7a8e96;
              `}>
              <Link
                css={css`
                  margin: 0 6px;
                `}
              />
              Linked account: last refreshed{' '}
              {plaidAccountLastRefreshDate
                ? `${dayjs(plaidAccountLastRefreshDate).format('MM/DD/YYYY')} (${dayjs(
                    plaidAccountLastRefreshDate
                  ).fromNow()})`
                : 'Never'}
            </div>
            <div
              css={css`
                margin: 8px 0 20px;
              `}>
              {requiresRelink && (
                <PlaidRelink accessId={plaidAccount.accessId} onSuccess={handlePlaidLinkSuccess} />
              )}
              <PlaidUnlink
                css={css`
                  font-size: 14px;
                  color: #038ab7;
                  cursor: pointer;
                  background-color: transparent;
                  text-decoration: underline;
                  border: none;
                  margin: 0 16px;
                `}
                accountId={accountId}
                onSuccess={handlePlaidLinkSuccess}
              />
              <PlaidRefresh
                css={css`
                  font-size: 14px;
                  color: #038ab7;
                  cursor: pointer;
                  background-color: transparent;
                  text-decoration: underline;
                  border: none;
                `}
                accountId={accountId}
                onSuccess={handlePlaidLinkSuccess}
              />
            </div>
          </div>
        )}
        {plaidAccountLinked && (rothContribAllowed || posttaxContribAllowed) && (
          <div
            css={css`
              margin: 0 0 24px;
              color: #f48024;
              font-size: 14px;
              font-weight: 300;
            `}>
            <WarningIcon /> Edit investments to split balances between pre-tax and other tax types{' '}
            <HelpIcon size='medium' tooltip={TooltipText.plaidSplitBalances()} />
          </div>
        )}
      </div>
    )

  return (
    <Card title='Investments'>
      {PlaidAccountLinkView}
      {ManagedAccountView}
      {SummaryView}
      {NoDataView}
      {InstitutionalInvestmentsView}
      {InvestmentsView}
    </Card>
  )
}

export default withRouter(inject('store', 'account')(observer(EditInvestmentsCard)))
