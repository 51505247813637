import { css } from '@emotion/react'
import { useState } from 'react'
import { inject, Observer } from 'mobx-react'

import { DoughnutChart } from '../../shared'
import { Page, PageHeader } from '../elements'

function CompanyStockPage(props) {
  useState(() => {
    props.guide.guidePages.pages.addPage({
      name: 'companyStock',
    })
  })

  const { features, config, acceptedCase, employerAccounts } = props.store

  const {
    companyStocks,
    companyStockNamesToKeep,
    companyStockSellPercentage,
    companyStockKeepPercentage,
    companyStockKeepAmount,
  } = acceptedCase

  const page = props.guide.guidePages.pages.findByName('companyStock')

  const percentageOfAccount = Math.round(
    companyStocks ? (companyStockKeepAmount / (props.store.active.balance || 1)) * 100 : 0
  )
  const { isRetail } = config
  const eligibleEmployerAccounts = employerAccounts.filter(acct => acct.contributionEligibility)

  // Find the employer acct they are eligible to contribute to
  // Must follow this order: 401k, 401a, 403b, 457, Keogh
  let employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 1) // 401k
  if (employerAccount.length === 0) {
    employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 23) // 401a
    if (employerAccount.length === 0) {
      employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 2) // 403b
      if (employerAccount.length === 0) {
        employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 4) // 457
        if (employerAccount.length === 0) {
          employerAccount = eligibleEmployerAccounts.filter(acct => acct.planType === 6) // Keogh
        }
      }
    }
  }

  function render() {
    return (
      features.companyStockEnabled &&
      companyStocks.length > 0 && (
        <Page header={<PageHeader text='Your Plan' />} page={page}>
          <div>
            <div
              css={css`
                color: #022a3a;
                font-size: 1rem;
                padding-bottom: 12px;
              `}>
              YOUR COMPANY STOCK
            </div>

            <div
              css={css`
                color: #06293a;
                font-size: 15px;
                line-height: 1.35;
              `}>
              {companyStockSellPercentage > 0
                ? `Your plan included the decision to sell some of the company
          stock you hold in your account and to have the proceeds from the sale allocated
          proportionately amongst your other account investments.`
                : `Certain investments, such as company stock, cannot be
          sold unless you give GuidedChoice permission to do so on your behalf. You decided to
          keep all of the ${companyStockNamesToKeep} in your account.`}
            </div>

            <div
              css={css`
                padding: 28px 0 16px;
              `}>
              {companyStocks.map(stock => (
                <div
                  key={stock.positionId}
                  css={css`
                    padding-bottom: 12px;
                  `}>
                  <div
                    css={css`
                      width: 70%;
                      margin: 0 auto;
                      padding: 16px 38px;
                      background-color: #e7f3f9;
                      color: #038ab7;
                      font-size: 21px;
                      text-align: center;
                    `}>
                    {stock.percentToSell > 0 ? `Sell ${stock.percentToSell}%` : 'Keep 100%'} of{' '}
                    {stock.securityName}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {features.companyInsiderEnabled &&
            !isRetail(
              <div
                css={css`
                  color: #06293a;
                  font-size: 15px;
                  line-height: 1.35;
                `}>
                Important: Your projections include a plan to sell company stock as above; however,
                as a company insider, at this time you are not permitted to do so. The transaction
                that was sent did NOT include the sale. You should plan to log into GuidedChoice
                after the no trading period ends to make the sale, or if you decide not to sell
                after all, consider returning to get updated projections.
              </div>
            )}

          {companyStockKeepPercentage > 0 && (
            <div>
              <div
                css={css`
                  padding: 48px 0 28px;
                  color: #022a3a;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 1.35;
                  text-align: center;
                `}>
                {isRetail
                  ? `Percentage of your ${employerAccount[0].name} retirement plan account in ${companyStockNamesToKeep}`
                  : `Percentage of your retirement plan account with ${
                      config.template.sponsorName
                    } in${' '}
                ${companyStockNamesToKeep}`}
              </div>

              <div>
                <DoughnutChart
                  height={200}
                  percent={percentageOfAccount}
                  css={css`
                    margin: 0 auto;
                  `}
                />
              </div>
            </div>
          )}
        </Page>
      )
    )
  }

  return <Observer>{render}</Observer>
}

export default inject('store', 'guide')(CompanyStockPage)
