import { css, useTheme } from '@emotion/react'
import { matchPath } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import { Button, SVGWrapper } from '../../../../components'
import { BullsEye } from '../../../../assets/icons'
import { numberToDollars } from '../../../../utils'
import {
  AccountGoalBox,
  BalanceSD,
  LabelSD,
  MainSideBarLabel,
  RowSD,
  SideDrawerButtonWrapper,
} from './'

function SideDrawerGoals(props) {
  const theme = useTheme()
  const defaultFill = theme.svgSideBarDrawerFill

  const buildGoalsDisplay = goals => {
    const { history, location } = props

    return goals.map((goal, index) => {
      return (
        <AccountGoalBox
          isHighlighted={!!matchPath(location.pathname, { path: `/goals/${goal.id}` })}
          key={goal.id}
          onClick={() => history.push(`/goals/${goal.id}`)}
          id={'sidebar-goal-' + goal.id}>
          <RowSD>
            <LabelSD>{goal.name}</LabelSD>
          </RowSD>
          <RowSD>
            <BalanceSD
              isHighlighted={!!matchPath(location.pathname, { path: `/goals/${goal.id}` })}>
              ${numberToDollars(goal.totalRemaining, true)}
            </BalanceSD>
          </RowSD>
        </AccountGoalBox>
      )
    })
  }
  const { history, location } = props
  const {
    currentGoals,
    person: { retirementIncomeGoalPct },
    features,
    config: { isSpendown },
  } = props.store
  const goals = buildGoalsDisplay(currentGoals)

  return (
    <div>
      <MainSideBarLabel
        isHighlighted={!!matchPath(location.pathname, { path: '/goals', exact: true })}
        onClick={() => history.push('/goals')}>
        <SVGWrapper
          css={css`
            display: flex;
            justify-content: flex-start;
            align-items: center;
            & span {
              width: 20px;
              height: 22px;
            }
          `}
          margin='4px'
          svg={BullsEye}
          size='mediumLarge'
          fill={defaultFill}
        />
        Goals
      </MainSideBarLabel>
      {!isSpendown && (
        <AccountGoalBox
          isHighlighted={!!matchPath(location.pathname, { path: '/goals/replace-income' })}
          onClick={() => history.push('/goals/replace-income')}>
          <RowSD>
            <LabelSD>Percent of income seeking after retiring</LabelSD>
          </RowSD>
          <RowSD>
            <BalanceSD
              isHighlighted={!!matchPath(location.pathname, { path: '/goals/replace-income' })}>
              {numberToDollars(retirementIncomeGoalPct, true)}%
            </BalanceSD>
          </RowSD>
        </AccountGoalBox>
      )}
      {goals}
      {features.allowAddGoals && (
        <SideDrawerButtonWrapper>
          <Button
            secondary
            label='+ Add a new goal'
            size='small'
            onClick={() => history.push('/goals/add')}
          />
        </SideDrawerButtonWrapper>
      )}
    </div>
  )
}

export default inject('store')(observer(SideDrawerGoals))
