import { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'mobx-react'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import './index.css'
import 'toastr/build/toastr.css'
import 'tippy.js/themes/light.css'

import AppStore from './AppStore'
import Guide from './views/guide'
import { LoginDashboard } from './views'
import { RetailDashboard } from './views'
import { ScrollToTop } from './components'
import AppGateway from './AppGateway'
import ThemeWrapper from './themes/ThemeWrapper'
import AtmosLogin from './views/login/AtmosLogin'

// Add dayjs plugins needed across application
dayjs.extend(isSameOrBefore).extend(isSameOrAfter).extend(isBetween).extend(relativeTime)

function App() {
  const [store] = useState(AppStore.create())
  const { isAuthenticated } = store.auth

  return (
    <Provider store={store}>
      <ThemeWrapper isAuth={isAuthenticated}>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route path='/implement/guide/:caseId' component={Guide} />
              <Route path='/atmos/(register|login|reset-password)' component={LoginDashboard} />
              <Route path='/atmos-login' component={AtmosLogin} />
              {/* old atmos login that still needs to be here */}
              <Route path='/(retail)' component={RetailDashboard} />
              <Route component={AppGateway} />
            </Switch>
          </ScrollToTop>
        </Router>
      </ThemeWrapper>
    </Provider>
  )
}

export default App
