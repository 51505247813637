import * as yup from 'yup'

const schema = yup.object().shape({
  planningWorkPartTimeClient: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  durationStartingAgeClient: yup
    .number()
    .nullable()
    .when('planningWorkPartTimeClient', (planningWorkPartTimeClient, schema) =>
      planningWorkPartTimeClient
        ? schema
            .required('Duration: starting age is a required field')
            .test(
              'is-not-less-than-retirement-age',
              'Starting age for part-time work during retirement may not begin before the retirement age',
              function (value) {
                return this.parent.clientRetirement <= value
              }
            )
        : schema
    ),
  durationStoppingAgeClient: yup
    .number()
    .nullable()
    .when('planningWorkPartTimeClient', (planningWorkPartTimeClient, schema) =>
      planningWorkPartTimeClient
        ? schema
            .required('Duration: stopping age is a required field')
            .test(
              'is-not-less-than-starting-age',
              'Stopping age cannot be before starting age',
              function (value) {
                return this.parent.durationStartingAgeClient <= value
              }
            )
        : schema
    ),
  clientIncome: yup
    .number()
    .nullable()
    .when('planningWorkPartTimeClient', (planningWorkPartTimeClient, schema) =>
      planningWorkPartTimeClient
        ? schema
            .required('Annual income is required')
            .min(1, 'Amount must be between $1 and $10,000,000')
            .max(9999999, 'Amount must be between $0 and $10,000,000')
        : schema
    ),
  planningWorkPartTimeSpouse: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  durationStartingAgeSpouse: yup
    .number()
    .nullable()
    .when('planningWorkPartTimeSpouse', (planningWorkPartTimeSpouse, schema) =>
      planningWorkPartTimeSpouse
        ? schema
            .required('Duration: starting age is a required field')
            .test(
              'is-not-less-than-retirement-age',
              'Starting age for part-time work during retirement may not begin before the retirement age',
              function (value) {
                return this.parent.spouseRetirement <= value
              }
            )
        : schema
    ),
  durationStoppingAgeSpouse: yup
    .number()
    .nullable()
    .when('planningWorkPartTimeSpouse', (planningWorkPartTimeSpouse, schema) =>
      planningWorkPartTimeSpouse
        ? schema
            .required('Duration: stopping age is a required field')
            .test(
              'is-not-less-than-starting-age',
              'Stopping age cannot be before starting age',
              function (value) {
                return this.parent.durationStartingAgeSpouse <= value
              }
            )
        : schema
    ),
  spouseIncome: yup
    .number()
    .nullable()
    .when('planningWorkPartTimeSpouse', (planningWorkPartTimeSpouse, schema) =>
      planningWorkPartTimeSpouse
        ? schema
            .required('Annual income is required')
            .min(1, 'Amount must be between $1 and $10,000,000')
            .max(9999999, 'Amount must be between $0 and $10,000,000')
        : schema
    ),
})

export { schema }
