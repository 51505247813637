/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import dayjs from 'dayjs'
import * as yup from 'yup'

import { Page } from '../elements'
import {
  TextInput,
  TextErrorField,
  ButtonInput,
  Button,
  CurrencyInput,
  ModalConfirmation,
  UsStatesDropdown,
  HelpIcon,
  TooltipText,
  DatePicker,
} from '../../../components'
import _ from 'lodash'
import { stateKeyToValue } from '../../../constants/us-states'
import { reduceValidationError, stringWhitelist } from '../../../utils'

const mapKeysToTree = {
  firstName: 'person',
  lastName: 'person',
  preferredName: 'person',
  birthDate: 'person',
  gender: 'person',
  annualSalary: 'account',
  taxState: 'account',
}

const schema = yup.object().shape({
  preferredName: yup
    .string()
    .nullable()
    .test(
      'is-preferred-valid',
      'Preferred name must be from 1 to 20 characters',
      value => value === null || value === '' || (value.length > 0 && value.length < 21)
    )
    .test(
      'letter-check',
      'Must contain at least one letter',
      value => value === null || value === '' || /[a-zA-Z]/.test(value)
    )
    .test(
      'string-whitelist',
      'Special characters are not allowed',
      value => value === null || value === '' || stringWhitelist(value)
    ),
  gender: yup.string().nullable().required('Select one').oneOf(['F', 'M'], 'Select one'),
  annualSalary: yup
    .number()
    .nullable()
    .required('Annual salary is required')
    .min(1, 'Must be greater than $0')
    .max(9999999, 'Salary must be less than $10,000,000'),
  taxState: yup
    .string()
    .nullable()
    .required('Select one')
    .oneOf(_.keys(stateKeyToValue), 'Select one'),
})

class SomethingWrong extends Component {
  state = { open: false }

  openModal = () => this.setState({ open: true })

  closeModal = () => this.setState({ open: false })

  render() {
    return (
      <div>
        <Button
          id='something-wrong-button'
          type='button'
          secondary
          label='Something wrong?'
          onClick={this.openModal}
        />
        <ModalConfirmation
          bodyText={`
            Just make note to contact your employer about information on this
            page that appears to be incorrect.
          `}
          isModalOpen={this.state.open}
          toggleModal={this.closeModal}
          title='You can still move forward...'
        />
      </div>
    )
  }
}

class AccountInfo extends Component {
  onSubmit = async values => {
    try {
      const { updateAccount, updatePerson } = this.props.onboarding

      const account = getSnapshot(this.props.onboarding.account)
      const accountPatch = _.pickBy(
        values,
        (value, key) => mapKeysToTree[key] === 'account' && value !== account[key]
      )
      const accountPromise = _.isEmpty(accountPatch)
        ? Promise.resolve({})
        : updateAccount({ id: account.id, ...accountPatch })

      const person = getSnapshot(this.props.onboarding.person)
      const personPatch = _.pickBy(
        values,
        (value, key) => mapKeysToTree[key] === 'person' && value !== person[key]
      )
      const personPromise = _.isEmpty(personPatch)
        ? Promise.resolve({})
        : updatePerson({ id: person.id, ...personPatch })

      await Promise.all([accountPromise, personPromise])

      this.props.history.push('/welcome/account-continued')
    } catch (err) {
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  editable = () => ({
    // client specific editable config
    firstName: false,
    lastName: false,
    preferredName: true,
    birthDate: false,
    gender: true,
    annualSalary: true,
    taxState: true,
  })

  initialValues = () => {
    const { onboarding } = this.props
    const editableKeys = _.pickBy(this.editable())
    const initialValues = _.mapValues(
      editableKeys,
      (value, key) => onboarding[mapKeysToTree[key]][key]
    )
    // test for null cases
    // initialValues.firstName = null
    // initialValues.lastName = null
    // initialValues.preferredName = null
    // initialValues.birthDate = null
    // initialValues.gender = null
    // initialValues.annualSalary = null
    // initialValues.taxState = null
    return initialValues
  }

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  render() {
    const {
      person: { firstName, lastName, birthDate },
    } = this.props.onboarding

    return (
      <Page>
        <Page.Heading title='Please confirm your personal information' />
        <Form
          initialValues={this.initialValues()}
          validate={this.validate}
          onSubmit={this.onSubmit.bind(this)}
          subscription={{ submitting: true, submitError: true }}
          render={({ handleSubmit, submitting, submitError }) => (
            <Page.Form>
              <Page.AnimateGroup>
                <Page.AnimateItem>
                  <Page.Field label='Name'>{`${firstName} ${lastName}`}</Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field label='Preferred name'>
                    <Field
                      name='preferredName'
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <TextInput
                          id='preferred-name-field'
                          maxLength={21}
                          error={meta.error}
                          name={input.name}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          placeholder='optional'
                          showError={meta.touched}
                          value={input.value}
                          width='220px'
                        />
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field label='Birthdate'>
                    {birthDate ? (
                      <div>{dayjs(birthDate).format('MM/DD/YYYY')}</div>
                    ) : (
                      // <BirthDateField />
                      <Field
                        name='birthDate'
                        subscription={{ value: true, touched: true, error: true }}
                        validate={value =>
                          yup
                            .string()
                            .required('Birthdate is required')
                            .max(11, 'Maximum character limit 11 exceeded')
                            .validate(value)
                            .then(value => {
                              const atLeast16 = dayjs(value).isBefore(
                                dayjs().subtract(16, 'y'),
                                'd'
                              )
                              const atMost109 = dayjs(value).isAfter(
                                dayjs().subtract(109, 'y'),
                                'd'
                              )
                              if (!atLeast16) {
                                throw new Error('Must be 16 years old or older')
                              } else if (!atMost109) {
                                throw new Error('Must be 109 years old or younger')
                              }
                            })
                            .catch(err => {
                              return err.message
                            })
                        }
                        render={({ input, meta }) => (
                          <DatePicker
                            handleChange={(name, value) => input.onChange(value)}
                            maxYear={new Date().getFullYear()}
                            minYear={1910}
                            name={input.name}
                            date={input.value}
                            showError={meta.error && meta.touched}
                            error={meta.error}
                          />
                        )}
                      />
                    )}
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field label='Gender'>
                    <Field
                      name='gender'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <div>
                          <div id='gender-select-field'>
                            <ButtonInput
                              name={input.name}
                              value='F'
                              isActive={input.value === 'F'}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onClick={(name, value) => input.onChange(value)}
                              text='Female'
                            />
                            <ButtonInput
                              name={input.name}
                              value='M'
                              isActive={input.value === 'M'}
                              onFocus={input.onFocus}
                              onBlur={input.onBlur}
                              onClick={(name, value) => input.onChange(value)}
                              text='Male'
                            />
                          </div>
                          <div>
                            <TextErrorField showError={meta.touched} error={meta.error} />
                          </div>
                        </div>
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field
                    id='annual-salary-field'
                    label={
                      <div
                        css={css`
                          display: flex;
                          justify-content: flex-end;
                          align-items: baseline;
                          & > div {
                            line-height: 100%;
                          }
                        `}>
                        Annual salary {this.props.store.config.isSpendown && <span>&nbsp;</span>}
                        {this.props.store.config.isSpendown && (
                          <HelpIcon tooltip={TooltipText.annualSalary()} />
                        )}
                      </div>
                    }
                    subLabel='(before taxes)'>
                    <Field
                      name='annualSalary'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <CurrencyInput
                          placeholder='Salary'
                          name={input.name}
                          value={input.value}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          error={meta.error}
                          showError={meta.touched}
                          decimalScale={0}
                        />
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Page.Field label='State in which file taxes'>
                    <Field
                      name='taxState'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <UsStatesDropdown
                          id='us-states-dropdown'
                          name={input.name}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          placeholder='Select'
                          selected={input.value || ''}
                          showError={meta.touched}
                          error={meta.error}
                        />
                      )}
                    />
                  </Page.Field>
                </Page.AnimateItem>

                <Page.Animate pose={!submitting && submitError ? 'visible' : 'hidden'}>
                  <Page.ErrorMessage>{!submitting && submitError}</Page.ErrorMessage>
                </Page.Animate>

                <Page.AnimateItem>
                  <Page.ButtonGroup>
                    <SomethingWrong />
                    <div>
                      <Button
                        id='account-info-submit-button'
                        type='button'
                        onClick={handleSubmit}
                        primary
                        label='Looks good'
                        disabled={submitting}
                      />
                    </div>
                  </Page.ButtonGroup>
                </Page.AnimateItem>
              </Page.AnimateGroup>
            </Page.Form>
          )}
        />
      </Page>
    )
  }
}

export default inject('onboarding', 'store')(observer(AccountInfo))
