/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { inject, observer } from 'mobx-react'
import * as yup from 'yup'

import { Page } from '../elements'
import { Checkbox, Button, Spacer } from '../../../components'
import { reduceValidationError } from '../../../utils'
import CCPANotice from './CCPANotice'

const clientSpecificContent = config => {
  switch (config.onBoardWizardId) {
    // Standard
    case 1:
      return `We're partnering with the ${config.template.sponsorName} to`

    // Paychex
    case 2:
      return `We're partnering with your employer, ${config.template.sponsorName}, and 401(k) plan recordkeeper, ${config.template.recordKeeperName}, to`

    // Schwab
    case 3:
      return `We're partnering with your employer, ${config.template.sponsorName}, and plan recordkeeper, ${config.template.recordKeeperName}, to`

    // Retail
    case 4:
      return "Through the coming process, we'll"

    // ADP
    case 5:
      return `We're partnering with your employer, ${config.template.sponsorName}, and plan recordkeeper, ${config.template.recordKeeperName}, to`

    // PTD+
    case 11:
      return `We're partnering with ${config.template.sponsorName} to`

    default:
      return "We're partnering with your employer to"
  }
}

const schema = yup.object().shape({
  terms: yup
    .boolean()
    .equals(
      [true],
      'Please check the box above to confirm your agreement to the terms and conditions'
    ),
})

class AcceptTerms extends Component {
  onSubmit = async values => {
    try {
      await this.props.onboarding.acceptTerms()

      const { isSpendown, isRetail } = this.props.store.config

      if (isRetail) {
        return this.props.history.push('/welcome/contact-info')
      }

      if (isSpendown) {
        // if it's a spendown case send user to contact info page'
        return this.props.history.push('/welcome/contact-info')
      }
      this.props.history.push('/welcome/new-changes')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  render() {
    const {
      person: { displayName },
      account: { taxState },
      config,
      termsOfServiceLink,
      disclosureLink,
    } = this.props.onboarding

    return (
      <Page>
        <Page.AnimateGroup>
          <Page.AnimateItem>
            <Page.WelcomeHeading>
              {displayName ? `Nice to meet you, ${displayName}!` : 'Nice to meet you!'}
            </Page.WelcomeHeading>
          </Page.AnimateItem>

          <Page.AnimateItem>
            <Page.WelcomeParagraph
              css={css`
                margin-bottom: 40px;
                max-width: 1200px;
              `}>
              We're GuidedChoice. Our mission is to bring financial freedom to everyone. Over 35,000
              employers have trusted us to assist and advise their employees.
            </Page.WelcomeParagraph>
          </Page.AnimateItem>
          {this.props.store.features.enableSpendDown ? (
            <Page.AnimateItem>
              <Page.WelcomeParagraph
                css={css`
                  max-width: 1200px;
                `}>
                {clientSpecificContent(config)} provide recommendations for both before and after
                retiring:
                <ul>
                  <li>
                    Before retiring: find out how much you need to save and how to invest that
                    savings to meet your financial goals.
                  </li>
                  <li>
                    After retiring: find out how much you can safely spend each year, how to
                    withdraw money tax-efficiently, when to take Social Security, and whether or not
                    an annuity makes sense for you.
                  </li>
                </ul>
                We'll need some basic information about you to be able do that, but before we can
                get started, please take a few minutes to review our standard&nbsp;
                <Page.Link href={termsOfServiceLink} target='_blank' rel='noreferrer noopener'>
                  Terms of Service
                </Page.Link>
                {' and '}
                <Page.Link href={disclosureLink} target='_blank' rel='noreferrer noopener'>
                  Disclosure
                </Page.Link>
                {' documents and confirm your acceptance of them.'}
              </Page.WelcomeParagraph>
            </Page.AnimateItem>
          ) : (
            <Page.AnimateItem>
              <Page.WelcomeParagraph
                css={css`
                  max-width: 1200px;
                `}>
                {`${clientSpecificContent(config)} provide recommendations for
                    how much you need to save and how to invest that savings to meet your
                    financial goals. We'll need some basic information about you to be
                    able to do that, but before we can get started, please take a few
                    minutes to review our standard `}
                <Page.Link href={termsOfServiceLink} target='_blank' rel='noreferrer noopener'>
                  Terms of Service
                </Page.Link>
                {' and '}
                <Page.Link href={disclosureLink} target='_blank' rel='noreferrer noopener'>
                  Disclosure
                </Page.Link>
                {' documents and confirm your acceptance of them.'}
              </Page.WelcomeParagraph>
            </Page.AnimateItem>
          )}

          <Page.AnimateItem>
            <Form
              initialValues={{ terms: false }}
              onSubmit={this.onSubmit}
              validate={this.validate}
              subscription={{ touched: true, errors: true, submitting: true, submitError: true }}
              render={({ touched, errors, submitting, handleSubmit, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    css={css`
                      display: flex;
                      margin: auto;
                      padding: 20px 0;
                      max-width: 1000px;
                    `}>
                    <div
                      css={css`
                        margin-top: 8px;
                        min-width: 64px;
                      `}>
                      <Field
                        name='terms'
                        subscription={{ value: true }}
                        render={({ input, meta }) => (
                          <Checkbox
                            id='checkbox'
                            checked={input.value}
                            onClick={
                              submitting
                                ? () => {}
                                : () => {
                                    input.onFocus()
                                    input.onChange(!input.value)
                                    input.onBlur()
                                  }
                            }
                            css={css`
                              margin: auto;
                            `}
                          />
                        )}
                      />
                    </div>

                    <Field
                      name='terms'
                      subscription={{ value: true }}
                      render={({ input }) => {
                        const handleTermsClick = submitting
                          ? () => {}
                          : () => {
                              input.onFocus()
                              input.onChange(!input.value)
                              input.onBlur()
                            }

                        return (
                          <Page.Text
                            onClick={handleTermsClick}
                            css={css`
                              cursor: pointer;
                              font-weight: 600;
                            `}>
                            {
                              'I have read and I agree to the terms and conditions as described in the GuidedChoice '
                            }
                            <Page.Link
                              href={termsOfServiceLink}
                              target='_blank'
                              rel='noreferrer noopener'
                              onClick={e => e.stopPropagation()}>
                              Terms of Service
                            </Page.Link>
                            {' and '}
                            <Page.Link
                              href={disclosureLink}
                              target='_blank'
                              rel='noreferrer noopener'
                              onClick={e => e.stopPropagation()}>
                              Disclosure
                            </Page.Link>
                            {' documents.'}
                          </Page.Text>
                        )
                      }}
                    />
                  </div>

                  <Page.TermsSubText
                    css={css`
                      max-width: 1000px;
                    `}>
                    The Terms and Conditions and Disclosure documents apply to the GuidedChoice
                    program only. They are not to be construed as an offer to buy or sell, or the
                    solicitation of an offer to buy or sell, any security or financial product or
                    service to any person in any jurisdiction in which such offer, solicitation,
                    purchase, or sale would be unlawful.
                  </Page.TermsSubText>

                  <Page.Animate
                    pose={
                      (!submitting && touched.terms && errors.terms) || (!submitting && submitError)
                        ? 'visible'
                        : 'hidden'
                    }>
                    <Page.ErrorMessage>
                      {(!submitting && touched.terms && errors.terms) ||
                        (!submitting && submitError)}
                    </Page.ErrorMessage>
                  </Page.Animate>

                  {(taxState === 'CA' || !taxState) && (
                    <>
                      <Spacer space='30px' />
                      <CCPANotice />
                    </>
                  )}

                  <div
                    css={css`
                      text-align: center;
                      margin: 30px 0 60px;
                    `}>
                    <Button
                      id='accept-terms-submit-button'
                      label="Let's Get Started"
                      primary
                      disabled={submitting || !!errors.terms}
                    />
                  </div>
                </form>
              )}
            />
          </Page.AnimateItem>
        </Page.AnimateGroup>
      </Page>
    )
  }
}

export default inject('onboarding', 'store')(observer(AcceptTerms))
