const BulletChecklist = `
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"/>
            <path d="M10 10h30v30H10z"/>
            <g fill="#02769D" fill-rule="nonzero">
                <path d="M30.973 32.958H15.996V17.753h14.977v.798l2.997-2.507v-1.333H13V36h20.97V22.81l-2.997 3.65z"/>
                <path d="m19.893 20.847-1.157 1.28 7.391 8.652L39 15.098 37.985 14l-11.858 9.908z"/>
            </g>
        </g>
    </svg>
`

export default BulletChecklist
