import { Component } from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

const StyledButtonInput = styled.button`
  cursor: pointer;
  width: ${p => p.width};
  height: ${p => (p.small ? '40px' : '48px')};
  padding: 6px;
  border: ${p => p.theme.buttonBorderColor};
  background-color: ${p => (p.isActive ? p.theme.mediumGray : '#FFFFFF')};
  color: ${p => (p.isActive ? '#FFFFFF' : p.theme.lightestGray)};
  transition: 0.3s;
  overflow-x: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: 0;
  }

  .sub-text {
    position: relative;
    top: 6px;
    font-size: 9px;
    line-height: 0;
  }
`

class ButtonInput extends Component {
  static defaultProps = {
    isActive: false,
    width: '160px',
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
    onBlur: PropTypes.func,
    sub: PropTypes.string,
    width: PropTypes.string,
    small: PropTypes.bool,
  }

  handleClick() {
    const { name, onBlur, onClick, value } = this.props
    if (onBlur) {
      onBlur(name)
    }
    if (onClick) {
      onClick(name, value)
    }
  }

  render() {
    const { isActive, name, text, sub, disabled, value, width, small } = this.props
    return (
      <StyledButtonInput
        type='button'
        isActive={isActive}
        name={name}
        value={value}
        onClick={this.handleClick.bind(this)}
        disabled={disabled}
        width={width}
        small={small}>
        {text}
        {sub && (
          <div className='sub-text' key='sub'>
            {sub}
          </div>
        )}
      </StyledButtonInput>
    )
  }
}

export default observer(ButtonInput)
