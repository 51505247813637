import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { HelpPersonIcon, Spacer, HelpIcon, TooltipText } from '../../../components'
import { numberToDollars } from '../../../utils'

const RightSection = styled.div`
  flex: 0 1 425px;
  margin-bottom: ${p => (p.marginBottom ? p.marginBottom : '24px')};
`
const SubHeader = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  line-height: 1.25;
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : null)};
  align-items: ${p => (p.alignItems ? p.alignItems : 'center')};
  flex-wrap: wrap;

  margin: ${p => (p.childMargin ? '0 -8px' : null)};

  & > * {
    margin: ${p => (p.childMargin ? '0 8px' : null)};
  }
`
const FlexChild = styled.div`
  flex: ${p => (p.flex ? p.flex : null)};
`
const SubLabel = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 1.125rem;
  max-width: 400px;
  padding: 2px 0;
  line-height: 1.4;
`
const RightSideContainer = styled.div`
  border: 1px solid #e6e9ea;
  background-color: #f0f4f5;
  box-shadow: 0 2px 2px 0 rgba(74, 96, 106, 0.6);
  padding: 1rem 1.5rem;
`
const RSAmount = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1.375rem;
  font-weight: ${p => (p.bold ? '600' : null)};

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
const RSLabel = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1.25rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  padding: 4px 0px 6px 0px;
`
const RSHelpText = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`
const RSAgeText = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1.375rem;
`

const StrategySeeks = ({ store }) => {
  const {
    recommendedCase,
    modifiedCase,
    person: { displayName: clientName, includeSpouse, retirementIncomeGoalPct, retirementAge },
    spouse: { firstName: spouseFirstName },
  } = store
  const { targetedAftTaxIncome, otherFinancialGoalsTotal, goalNameList, primary, spouse } =
    modifiedCase || recommendedCase

  const subHeaderText = modifiedCase ? 'Modified strategy seeks' : 'Recommended strategy seeks'

  const primaryRetAge = _.get(primary, 'retAge', null)
  const spouseRetAge = _.get(spouse, 'retAge', null)

  const showLaterRetirementAgeTip = !modifiedCase && primaryRetAge > retirementAge

  return (
    <RightSection>
      <SubHeader>{subHeaderText}</SubHeader>

      <RightSideContainer>
        <RSLabel>Monthly income after retiring</RSLabel>
        <FlexRow childMargin>
          <RSAmount bold>{numberToDollars(targetedAftTaxIncome, true)}</RSAmount>
          <SubLabel
            css={css`
              font-size: 0.875rem;
            `}>
            Spendable income (today's value){' '}
          </SubLabel>
        </FlexRow>
        <FlexRow>
          <RSHelpText>
            Replacing {numberToDollars(retirementIncomeGoalPct, true)}% of current income
            {'\u00A0\u00A0\u00A0\u00A0'}
          </RSHelpText>
          <HelpPersonIcon size='xLarge' tooltip={TooltipText.percentIncomeSeekToReplace()} />
        </FlexRow>

        <div
          css={css`
            border-bottom: 1px solid #7a8e96;
            margin: 1.5rem 0;
          `}
        />

        <RSLabel>Retirement age</RSLabel>
        <FlexRow childMargin>
          {!!clientName && (
            <FlexChild flex='1 0 auto'>
              <RSAgeText>{primaryRetAge || 'NA'}</RSAgeText>
              <SubLabel>{clientName} </SubLabel>
            </FlexChild>
          )}
          {!!spouseFirstName && !!includeSpouse && (
            <FlexChild flex='1 0 auto'>
              <RSAgeText>{spouseRetAge || 'NA'}</RSAgeText>
              <SubLabel>{spouseFirstName} </SubLabel>
            </FlexChild>
          )}
        </FlexRow>

        {showLaterRetirementAgeTip && (
          <div
            css={css`
              margin: 1rem 0;
            `}>
            <HelpIcon
              helpLabel='Later than expected?'
              tooltip={TooltipText.laterRetirementAgeExpected()}
            />
          </div>
        )}

        <div
          css={css`
            border-bottom: 1px solid #7a8e96;
            margin: 1.5rem 0;
          `}
        />

        <RSLabel>While also satisfying other goals</RSLabel>
        <FlexRow childMargin>
          <RSAmount bold>{numberToDollars(otherFinancialGoalsTotal, true)}</RSAmount>
          <SubLabel>Total </SubLabel>
        </FlexRow>
        <Spacer space='4px' />
        <SubLabel>Future amount needed for all included goals</SubLabel>
        <Spacer space='2px' />
        {goalNameList && <SubLabel>{`${goalNameList}`}</SubLabel>}
      </RightSideContainer>
    </RightSection>
  )
}

export default inject('store')(observer(StrategySeeks))
