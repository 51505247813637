import { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'

const labelMedia = css`
  line-height: 1.8;
  text-align: left;
  padding: 0;
`

const LabelWrapper = styled.div`
  flex: 1 1 340px;
  text-align: right;
  padding-right: 10px;
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: ${p => p.fontSize};
  line-height: 1.5rem;
  /*transition: 0.8s;*/
  position: relative;

  @media (max-width: 800px) {
    ${labelMedia};
  }

  ${p => p.isDrawer && labelMedia};
  padding-left: ${p => (p.paddingLeft ? p.paddingLeft : p.isDrawer ? '10px' : '')};

  @media (max-width: 800px) {
    ${p => p.styledMedia800 || ''};
  }
`

export const SubText = styled.div`
  position: absolute;
  right: 0;
  padding-right: 10px;
  font-size: 14px;
  line-height: 1rem;
  transition: 0.8s;

  @media (max-width: 800px) {
    top: 26px;
    right: auto;
    left: 0;
  }

  @media (min-width: 1200px) {
    font-size: 1rem;
    line-height: 1rem;
  }
`

export default class Label extends Component {
  static defaultProps = {
    fontSize: '1.125rem',
    paddingLeft: '10px',
    isDrawer: false,
  }

  static propTypes = {
    fontSize: PropTypes.string,
    isDrawer: PropTypes.bool,
    styledMedia800: PropTypes.string,
    subText: PropTypes.string,
    width: PropTypes.string,
  }

  render() {
    const { children, fontSize, isDrawer, paddingLeft, styledMedia800, subText } = this.props

    return (
      <LabelWrapper {...{ fontSize, isDrawer, paddingLeft, styledMedia800, subText }}>
        {children}
        {subText && <SubText>{subText}</SubText>}
      </LabelWrapper>
    )
  }
}
