import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { inject, Observer } from 'mobx-react'

import { API } from '../../../api'
import { WizardStep, Spacer, Loading } from '../../../components'
import { Heading } from '../elements/styled'
import { StepContainer, StepHeader, StepRow, StepNumber, StepText } from './IntroStyle'

function Intro(props) {
  const [state, setState] = useState('loading')

  async function fetchInitialState() {
    const {
      offboarding: { setAcceptedCaseSubmitted },
      store: { acceptedCaseId },
    } = props

    try {
      await API.post('offboard/updateAcceptedCase', null, {
        headers: { 'content-type': 'application/json' },
        params: { caseId: acceptedCaseId },
      })
      setAcceptedCaseSubmitted(true)
      setState('done')
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  useEffect(() => {
    fetchInitialState()
  }, []) // eslint-disable-line

  function introConfig() {
    const { config } = props.store

    if (props.offboarding?.isRetailMember) {
      return {
        introSteps: [
          'Confirm email.',
          'Download or view your personalized advisory guide containing a to-do list for savings and investment changes.',
        ],
        nextPage: props.offboarding.routes[1][0],
      }
    }

    const templates = {
      1: config.canTransact
        ? {
            introSteps: [
              `Review any changes to your ${config.template.planName}.`,
              'Confirm email address.',
              'Decide to have changes implemented automatically or to do it all by yourself.',
            ],
            nextPage: 'account-changes',
          }
        : {
            introSteps: [
              'Confirm email.',
              'Download or view your personalized advisory guide containing a to-do list for savings and investment changes.',
            ],
            nextPage: 'confirm-email',
          },

      2: {
        introSteps: [
          'Confirm email.',
          'Download or view your personalized advisory guide containing a to-do list for savings and investment changes.',
        ],
        nextPage: 'confirm-email',
      },
    }

    return templates[config.templateId]
  }

  function handleBack() {
    props.history.goBack()
  }

  function handleNext() {
    const { history } = props
    const { nextPage } = introConfig()

    history.push(`/implement/${nextPage}`)
  }

  function render() {
    if (state === 'loading') {
      return (
        <div
          css={css`
            padding: 20px;
          `}>
          <Loading />
        </div>
      )
    }

    if (state === 'error') {
      return (
        <div
          css={css`
            padding: 20px;
            color: crimson;
            text-align: center;
          `}>
          Something went wrong with the submission, please try again later
        </div>
      )
    }

    const { introSteps } = introConfig()

    return (
      <WizardStep onBackClick={handleBack} onNextClick={handleNext}>
        <Heading>Let's put your plan into action</Heading>

        <Spacer space='24px' />

        <StepContainer>
          <StepHeader>Easy steps ahead!</StepHeader>

          {introSteps.map((value, index) => (
            <StepRow key={index}>
              <StepNumber>Step {index + 1}:</StepNumber>
              <StepText>{value}</StepText>
            </StepRow>
          ))}
        </StepContainer>
      </WizardStep>
    )
  }

  return <Observer>{render}</Observer>
}

export default inject('store', 'offboarding')(Intro)
