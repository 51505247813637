import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const {
  unit,
  type: {
    sizes: { s: small },
  },
} = variables
const {
  rt: { primary, black, grey600 },
} = colors
const size = reduceCSSCalc(`calc(1.8 * ${unit})`)

const checkbox = {
  color: primary,
  disabledColor: color.convert(`color(${black} a(26%))`),
  fieldMarginBottom: reduceCSSCalc(`calc(1.5 * ${unit})`),
  focusCheckedColor: color.convert(`color(${primary} a(26%))`),
  rippleDuration: '650ms',
  size,
  focusColor: color.convert(`color(${black} a(1%))`),
  focusSize: reduceCSSCalc(`calc(${size} * 2.3)`),
  textColor: black,
  borderColor: grey600,
  textFontSize: small,
  totalHeight: reduceCSSCalc(`calc(1.8 * ${unit})`),
  transitionDuration: '0.2s',
}

export default checkbox
