import { css } from '@emotion/react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { Observer, inject } from 'mobx-react'
import dayjs from 'dayjs'

import { numberToDollars } from '../../../utils'
import { SVGWrapper, HelpIcon, TooltipText } from '../../../components'
import { CircleNegative, CirclePlus, Warning } from '../../../assets/icons'

const TableName = styled.div`
  color: #4a606a;
  font-size: 1.125rem;
  line-height: 1.35;
`

const Table = styled.div`
  overflow-x: auto;
`

const TableHeader = styled.div`
  color: #7a8e96;
  font-size: 0.875rem;
  display: flex;
  align-items: flex-end;
  border: 1px solid transparent;
`

const TableItem = styled.div`
  display: flex;
  color: #7a8e96;
  font-size: 1rem;
  word-break: break-word;
  margin: 12px 0;
`

const TableItemCard = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: baseline;
  border: 1px solid #979797;
  background-color: #e7f3f9;
  padding: 12px 0;
  word-break: normal;
`

const Alert = styled.div`
  display: flex;
  color: #7a8e96;
  font-size: 0.75rem;
  line-height: 1.35;
  margin: 12px 6px;
`

const GrantNumberHeader = styled.div`
  flex: 0 0 125px;
  padding: 0 14px;
  text-align: right;
`
const GrantNumberItem = styled.div`
  flex: 0 0 125px;
  padding: 0 14px;
  text-align: right;
`

const DateHeader = styled.div`
  flex: 0 0 120px;
  padding: 0 14px;
`
const DateItem = styled.div`
  flex: 0 0 120px;
  padding: 0 14px;
`

const QuantityHeader = styled.div`
  flex: 0 0 85px;
  padding: 0 14px;
  text-align: right;
`
const QuantityItem = styled.div`
  flex: 0 0 85px;
  padding: 0 14px;
  text-align: right;
`

const PriceHeader = styled.div`
  flex: 0 0 90px;
  padding: 0 14px;
  text-align: right;
`
const PriceItem = styled.div`
  flex: 0 0 90px;
  padding: 0 14px;
  text-align: right;
`

const AlertHeader = styled.div`
  flex: 0 0 42px;
  padding: 0 14px;
`
const AlertItem = styled.div`
  flex: 0 0 42px;
  padding: 0 14px;
`

function GrantStatus(props) {
  const [active, setActive] = useState(false)

  function handleClick() {
    setActive(!active)
  }

  function render() {
    const icon = active ? CircleNegative : CirclePlus
    const { vestedStockOptions, unvestedStockOptions } = props.store

    return (
      <div>
        <div
          css={css`
            color: #4a606a;
            cursor: pointer;
            font-size: 18px;
            margin: 20px 0;
          `}
          onClick={handleClick}>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}>
            <SVGWrapper svg={icon} fill='#7A8E96' size='large' />
            <div
              css={css`
                padding-left: 10px;
              `}>
              View/update grant status
            </div>
          </div>
        </div>

        {active && (
          <div>
            <TableName
              css={css`
                margin: 20px 0;
              `}>
              Vested options <HelpIcon tooltip={TooltipText.stockOptions()} />
            </TableName>

            <Table>
              <TableHeader>
                <GrantNumberHeader>Grant number</GrantNumberHeader>
                <DateHeader>Grant date</DateHeader>
                <QuantityHeader>Quantity</QuantityHeader>
                <DateHeader>Vested date</DateHeader>
                <DateHeader>Expiration date</DateHeader>
                <PriceHeader>Exercise price</PriceHeader>
                <PriceHeader>Current price</PriceHeader>
                <AlertHeader />
              </TableHeader>

              <div>
                {/* map across vested options */}
                {vestedStockOptions.map(stockOption => (
                  <TableItem key={stockOption.id}>
                    <TableItemCard
                      css={css`
                        min-width: 800px;
                      `}>
                      <GrantNumberItem>{stockOption.optionGrantNo}</GrantNumberItem>

                      <DateItem>{dayjs(stockOption.grantDate).format('M/D/YYYY')}</DateItem>

                      <QuantityItem>{numberToDollars(stockOption.quantity, true)}</QuantityItem>

                      <DateItem>{dayjs(stockOption.vestedDate).format('M/D/YYYY')}</DateItem>

                      <DateItem>{dayjs(stockOption.expirationDate).format('M/D/YYYY')}</DateItem>

                      <PriceItem>${numberToDollars(stockOption.exercisePrice)}</PriceItem>

                      <PriceItem>${numberToDollars(stockOption.currentPrice)}</PriceItem>

                      {stockOption.availableToExercise && stockOption.isUnderWater && (
                        <AlertItem>
                          <SVGWrapper
                            svg={Warning}
                            cursor='default'
                            css={css`
                              margin-top: -7px;
                            `}
                          />
                        </AlertItem>
                      )}
                    </TableItemCard>
                  </TableItem>
                ))}
              </div>
            </Table>
            {vestedStockOptions.filter(
              stockOption => stockOption.availableToExercise && stockOption.isUnderWater
            ).length > 0 && (
              <Alert>
                <div>
                  <SVGWrapper svg={Warning} cursor='default' />
                </div>
                <div
                  css={css`
                    margin: 2px 0 0 6px;
                  `}>
                  Though these options are available to exercise, it is not worth doing so, since
                  you'd have to pay money to do so. Furthermore, GuidedChoice will not include them
                  in the projections until the exercise price is lower than the current price.
                </div>
              </Alert>
            )}

            <TableName
              css={css`
                margin: 40px 0 20px;
              `}>
              Unvested options <HelpIcon tooltip={TooltipText.stockOptions()} />
            </TableName>

            <Table
              css={css`
                margin-bottom: 20px;
              `}>
              <TableHeader>
                <GrantNumberHeader>Grant number</GrantNumberHeader>
                <DateHeader>Grant date</DateHeader>
                <QuantityHeader>Quantity</QuantityHeader>
                <DateHeader>Vested date</DateHeader>
                <DateHeader>Expiration date</DateHeader>
                <PriceHeader>Exercise price</PriceHeader>
              </TableHeader>

              <div>
                {/* map across unvested options */}
                {unvestedStockOptions.map(stockOption => (
                  <TableItem key={stockOption.id}>
                    <TableItemCard
                      css={css`
                        min-width: 680px;
                        max-width: 680px;
                      `}>
                      <GrantNumberItem>{stockOption.optionGrantNo}</GrantNumberItem>

                      <DateItem>{dayjs(stockOption.grantDate).format('M/D/YYYY')}</DateItem>

                      <QuantityItem>{numberToDollars(stockOption.quantity, true)}</QuantityItem>

                      <DateItem>{dayjs(stockOption.vestedDate).format('M/D/YYYY')}</DateItem>

                      <DateItem>{dayjs(stockOption.expirationDate).format('M/D/YYYY')}</DateItem>

                      <PriceItem>${numberToDollars(stockOption.exercisePrice)}</PriceItem>
                    </TableItemCard>
                  </TableItem>
                ))}
              </div>
            </Table>
          </div>
        )}
      </div>
    )
  }

  return <Observer>{render}</Observer>
}

export default inject('store')(GrantStatus)
