import { css } from '@emotion/react'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import styled from '@emotion/styled'
import axios from 'axios'
import _ from 'lodash'

import { API } from '../../../../api'
import { LightGreyText } from '../../styled'
import { Dropdown, Button, TextInput } from '../../../../components'
import { placeholderSymbolReplace } from '../../../../utils'
import { SECURITY_TYPES } from '../../../../constants'

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const InputWrapper = styled.div`
  display: flex;
`

const TextWrapper = styled.div`
  margin-top: 8px;
  padding: 10px 0;
  margin-right: 10px;
`

let cancelBySymbol, cancelByName

const SecuritySearch = props => {
  const [tickerSearchValue, setTickerSearchValue] = useState('')
  const [searchActiveFlag, setSearchActiveFlag] = useState(false)
  const [tickerResults, setTickerResults] = useState([])
  const [nameResults, setNameResults] = useState([])
  const [securityType, setSecurityType] = useState(props.securityType)

  const handleSearchTextChange = (name, value) => {
    setTickerSearchValue(value)
  }

  const searchBySymbol = (value, securityType) => {
    return API.get(
      `security/bySymbol?symbol=${_.toUpper(value)}&securityType=${SECURITY_TYPES[securityType]}`,
      { cancelToken: new axios.CancelToken(cancel => (cancelBySymbol = cancel)) }
    )
  }

  const searchByName = (value, securityType) => {
    return API.get(`security/byName?name=${value}&securityType=${SECURITY_TYPES[securityType]}`, {
      cancelToken: new axios.CancelToken(cancel => (cancelByName = cancel)),
    })
  }

  const searchSecurity = async (value, securityType) => {
    setTickerResults([])
    setNameResults([])
    setSearchActiveFlag(false)

    // don't make calls until user has entered 3 characters
    if (value.length < 3) {
      return
    }

    try {
      const [ticker, name] = await Promise.all([
        searchBySymbol(value, securityType),
        searchByName(value, securityType),
      ])
      setTickerResults(ticker.data)
      setNameResults(name.data)
      setSearchActiveFlag(true)
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('request canceled') // eslint-disable-line
      } else {
        console.error(err)
      }
    }
  }

  const debounceSearchSecurity = useMemo(() => {
    // wait 300 ms between calls
    return _.debounce(searchSecurity, 300)
  }, []) // eslint-disable-line

  const handleClearSecurity = useCallback(() => {
    setTickerSearchValue('')
    setSearchActiveFlag(false)
    setTickerResults([])
    setNameResults([])
    props.investment.setSecurity(null)
  }, [props.investment])

  useEffect(
    props => {
      if (cancelBySymbol || cancelByName) {
        cancelBySymbol && cancelBySymbol()
        cancelByName && cancelByName()
      }

      const formattedValueForAPI = placeholderSymbolReplace(tickerSearchValue, true)
      debounceSearchSecurity(formattedValueForAPI, securityType)
    },
    [tickerSearchValue, debounceSearchSecurity, securityType]
  )

  useEffect(() => {
    handleClearSecurity()
    setSecurityType(props.securityType)
  }, [props.securityType, handleClearSecurity])

  const { showOrLabelText } = props
  const selectedSecurity = _.get(props.investment, 'security.id')

  const tickerOptions = tickerResults.map(security => ({
    value: security.id,
    label: placeholderSymbolReplace(security.ticker),
  }))

  const nameOptions = nameResults.map(security => ({
    value: security.id,
    label: placeholderSymbolReplace(security.securityName),
  }))

  if (selectedSecurity) {
    const { id, securityName, ticker } = props.investment.security
    tickerOptions.unshift({ label: ticker, value: id })
    nameOptions.unshift({
      label: placeholderSymbolReplace(securityName),
      value: id,
    })
  }

  return (
    <div>
      <InputWrapper>
        <TextWrapper>
          <LightGreyText>{showOrLabelText ? 'Or search:' : 'Search:'}</LightGreyText>
        </TextWrapper>
        <TextInput
          name='tickerSearchText'
          onChange={handleSearchTextChange}
          placeholder='Search by Name or Ticker here'
          value={tickerSearchValue}
          width='280px'
        />
        <div
          css={css`
            flex: 1 1 auto;
          `}
        />
        <Button secondary label='Clear' onClick={handleClearSecurity} size='small' width='65px' />
      </InputWrapper>
      <DropdownWrapper>
        <Dropdown
          disabled={tickerOptions.length === 0}
          name='TickerResults'
          onChange={props.onChange}
          options={tickerOptions}
          placeholder='Ticker'
          selected={selectedSecurity}
          forceActive={searchActiveFlag}
          switchForceActive={setSearchActiveFlag}
          width='130px'
        />
        <Dropdown
          disabled={nameOptions.length === 0}
          name='NameResults'
          onChange={props.onChange}
          options={nameOptions}
          placeholder='Name Selection'
          selected={selectedSecurity}
          forceActive={searchActiveFlag}
          switchForceActive={setSearchActiveFlag}
          width='360px'
        />
      </DropdownWrapper>
    </div>
  )
}

export default observer(SecuritySearch)
