import { Component } from 'react'

class Pdf extends Component {
  static displayName = 'Pdf'
  static propTypes = {}

  render() {
    return (
      <div>
        <style>
          {`
            @page {
              size: 8.5in 11in;
              margin: 0;
            }
            /*
              get rid of margins on all elements.
              use padding instead so we get accurate content heights
            */
            * { margin: 0; }
          `}
        </style>
        {this.props.children}
      </div>
    )
  }
}

export default Pdf
