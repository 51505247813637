/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const active = 'rt-tabs-active'

const disabled = 'rt-tabs-disabled'

const hidden = 'rt-tabs-hidden'

const label = 'rt-tabs-label'

const rippleWrapper = 'rt-tabs-rippleWrapper'

const withIcon = 'rt-tabs-withIcon'

const withText = 'rt-tabs-withText'

const tab = 'rt-tabs-tab'

const arrow = 'rt-tabs-arrow'

const arrowContainer = 'rt-tabs-arrowContainer'

const disableAnimation = 'rt-tabs-disableAnimation'

const fixed = 'rt-tabs-fixed'

const inverse = 'rt-tabs-inverse'

const navigation = 'rt-tabs-navigation'

const navigationContainer = 'rt-tabs-navigationContainer'

const pointer = 'rt-tabs-pointer'

const tabs = 'rt-tabs-tabs'

const icon = 'rt-tabs-icon'

const _tabs = css`
  display: flex;
  flex-direction: column;

  ${ds.get('reset')};
`

const _navigation = css`
  box-shadow: inset 0 -1px ${ds.get('tab.navigationBorderColor')};
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  position: relative;
`

const _navigationContainer = css`
  display: flex;

  & .${navigation} {
    flex: 1;
  }
`

const _arrow = css`
  color: ${ds.get('tab.textColor')};
  padding: 0 ${ds.get('tab.labelHPadding')};
`

const _arrowContainer = css`
  align-items: center;
  box-shadow: inset 0 -1px ${ds.get('tab.navigationBorderColor')};
  cursor: pointer;
  display: flex;
`

const _label = css`
  color: ${ds.get('tab.textInactiveColor')};
  font-size: ${ds.get('tab.textHeight')};
  font-weight: ${ds.get('fontWeight.semiBold')};
  line-height: 1;
  padding: ${ds.get('tab.labelVPadding')} ${ds.get('tab.labelHPadding')};
  position: relative;
  text-transform: uppercase;
  transition-duration: ${ds.get('animation.duration')};
  transition-property: background-color, box-shadow, color;
  transition-timing-function: ${ds.get('animation.curveDefault')};

  &:focus {
    background-color: ${ds.get('tab.focusColor')};
    outline: none;
  }

  & > .${rippleWrapper} {
    overflow: hidden;
  }

  &.${active} {
    color: ${ds.get('tab.textColor')};
  }

  &.${disabled} {
    opacity: ${ds.get('tab.labelDisabledOpacity')};
  }

  &:not(.${disabled}) {
    cursor: pointer;
  }

  &.${hidden} {
    display: none;
  }

  &.${withIcon} {
    padding-bottom: ${reduceCSSCalc(
      `calc(${ds.get('tab.labelVPadding')} - ${ds.get('tab.iconMarginBottom')} / 2)`
    )};
    padding-top: ${reduceCSSCalc(
      `calc(${ds.get('tab.labelVPadding')} - ${ds.get('tab.iconMarginBottom')} / 2)`
    )};
    text-align: center;
  }

  &.${withText} {
    & .${icon} {
      margin-bottom: ${ds.get('tab.iconMarginBottom')};
    }
  }
`

const _icon = css`
  display: block;
  height: ${ds.get('tab.iconHeight')};
  line-height: ${ds.get('tab.iconHeight')};
  margin: 0 auto;
`

const _pointer = css`
  background-color: ${ds.get('tab.pointerColor')};
  height: ${ds.get('tab.pointerHeight')};
  margin-top: ${reduceCSSCalc(`calc(-1 * ${ds.get('tab.pointerHeight')})`)};
  position: absolute;
  transition-duration: ${ds.get('animation.duration')};
  transition-property: left, width;
  transition-timing-function: ${ds.get('animation.curveDefault')};
  width: 0;
`

const _tab = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${ds.get('tab.labelVPadding')} ${ds.get('tab.labelHPadding')};
  position: relative;

  &:not(.${active}) {
    display: none;
  }

  &.${active} {
    display: flex;
  }
`

const _fixed = css`
  & .${label} {
    flex: 1;
    text-align: center;
  }
`

const _inverse = css`
  & .${navigation}, & .${arrowContainer} {
    background-color: ${ds.get('tab.inverseBarColor')};
  }

  & .${label} {
    color: ${ds.get('tab.inverseTextInactiveColor')};

    &.${active} {
      color: ${ds.get('tab.inverseTextColor')};
    }
  }

  & .${arrow} {
    color: ${ds.get('tab.inverseTextColor')};
  }

  & .${pointer} {
    background-color: ${ds.get('tab.inversePointerColor')};
  }
`

export default {
  active,
  disabled,
  hidden,
  label: cx(label, _label),
  rippleWrapper,
  withIcon,
  withText,
  tab: cx(tab, _tab),
  arrow: cx(arrow, _arrow),
  arrowContainer: cx(arrowContainer, _arrowContainer),
  disableAnimation,
  fixed: cx(fixed, _fixed),
  inverse: cx(inverse, _inverse),
  navigation: cx(navigation, _navigation),
  navigationContainer: cx(navigationContainer, _navigationContainer),
  pointer: cx(pointer, _pointer),
  tabs: cx(tabs, _tabs),
  icon: cx(icon, _icon),
}
