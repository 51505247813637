import { inject, observer } from 'mobx-react'

import { FieldContainer, Title } from '../styled'

const NothingTodo = props => {
  const { config } = props.store

  switch (config.templateId) {
    case 1:
    case 2:
      return (
        <FieldContainer>
          <Title>
            Right now there is nothing more for you to do. If you would like to add any non-
            {config.template.sponsorShortHand} retirement accounts or if your financial situation
            changes, log back in to the Advisor Service. We can help you allocate your funds or
            create a new strategy based on your situation.
          </Title>
        </FieldContainer>
      )

    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
      return (
        <FieldContainer>
          <Title>
            Good news! Based on your accepted strategy, there's nothing more for you to do at this
            time. GuidedChoice takes care of notifying your plan account's recordkeeper,{' '}
            {config.template.recordKeeperName}, of any changes you‘ve accepted to your contributions
            and investment strategy, so rest easy—we've got your best interests at heart!
          </Title>
        </FieldContainer>
      )
    default:
      return null
  }
}

export default inject('store')(observer(NothingTodo))
