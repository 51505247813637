import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import { withRouter } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

import { API } from '../../../api'
import {
  WizardStep,
  MultiButtonInput,
  TooltipText,
  CurrencyInput,
  InputField,
  PercentInput,
  PercentOrDollarInput,
} from '../../../components'
import { reduceValidationError } from '../../../utils'
import { typeToAPI } from '../utils'
import {
  schema,
  createEditable,
  initializeValues,
  cleanEmployerData,
} from './EmployerContributionsUtils'

class EditEmployerContributions extends Component {
  handleSubmit = async values => {
    try {
      const {
        account: { account },
        match: {
          params: { type },
        },
      } = this.props

      const { planType, id, participantId } = account

      const requestValues = cleanEmployerData(values, account)

      // if different from initialValues, submit PATCH request
      if (!_.isEqual(this.initialValues(this.editable), requestValues)) {
        const response = await API.patch(typeToAPI[type], {
          planType,
          id,
          participantId,
          ...requestValues,
        })

        applySnapshot(account, response.data)
        this.props.store.getAccounts()
      }
      this.props.handleCancel()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  validate = values => {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  editable = () => {
    const {
      match: {
        params: { accountType },
      },
      account: {
        account: { planType, isPrimaryOwned },
        control,
      },
    } = this.props
    return createEditable({ accountType, planType, isPrimaryOwned, control })
  }

  initialValues = editable => {
    const { account, control } = this.props.account
    return initializeValues({ editable, account, control })
  }

  render() {
    const editable = this.editable()
    const { employerContribIsEditable, profitSharingIsEditable } = editable

    return (
      <Form
        onSubmit={this.handleSubmit}
        validate={this.validate}
        initialValues={this.initialValues(editable)}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ handleSubmit, submitting, submitError, values }) => (
          <WizardStep
            onBackClick={this.props.handleCancel}
            onNextClick={handleSubmit}
            backButtonText='Cancel'
            nextButtonText='Save'
            loading={submitting}
            serverError={submitError}>
            <div>
              {employerContribIsEditable && (
                <Field
                  name='employerContrib'
                  render={({ input, meta }) => (
                    <MultiButtonInput
                      name={input.name}
                      value={input.value}
                      label='Employer match?'
                      buttons={[
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]}
                      tooltip={TooltipText.employerMatch()}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                      disabled={!employerContribIsEditable}
                    />
                  )}
                />
              )}

              {employerContribIsEditable && values.employerContrib === true && (
                <>
                  <InputField
                    label='Per dollar match rate'
                    sublabel='(e.g. 50% or $0.50)'
                    flexLabel='0 1 300px'
                    flexInput='0 1 300px'
                    padding='20px 0 3px'
                    paddingInput='0'
                    key='companyMatchRate'>
                    <Field
                      name='companyMatchRate'
                      format={v => v}
                      parse={v => v}
                      render={({ input, meta }) => (
                        <PercentOrDollarInput
                          name={input.name}
                          value={input.value === '' ? null : input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                          decimalScale={2}
                        />
                      )}
                    />
                  </InputField>

                  <InputField
                    label='Max percent match'
                    sublabel='(e.g. up to 6%)'
                    flexLabel='0 1 300px'
                    flexInput='0 1 300px'
                    padding='3px 0'
                    paddingInput='0'
                    key='companyMatchRateMax'>
                    <Field
                      name='companyMatchRateMax'
                      format={v => v}
                      parse={v => v}
                      render={({ input, meta }) => (
                        <PercentInput
                          name={input.name}
                          value={input.value === '' ? null : input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                          width='115px'
                        />
                      )}
                    />
                  </InputField>

                  <InputField
                    label='Max dollar match'
                    sublabel='(e.g. up to $1,500)'
                    flexLabel='0 1 300px'
                    flexInput='0 1 300px'
                    padding='3px 0'
                    paddingInput='0'
                    key='companyMatchDlrMax'>
                    <Field
                      name='companyMatchDlrMax'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      render={({ input, meta }) => (
                        <CurrencyInput
                          name={input.name}
                          value={input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                          width='115px'
                        />
                      )}
                    />
                  </InputField>
                </>
              )}

              {profitSharingIsEditable && (
                <Field
                  name='profitSharing'
                  render={({ input, meta }) => (
                    <MultiButtonInput
                      label='Fixed employer contribution or profit sharing?'
                      tooltip={TooltipText.profitSharing()}
                      buttons={[
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]}
                      name={input.name}
                      value={input.value}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                      disabled={!profitSharingIsEditable}
                    />
                  )}
                />
              )}

              {profitSharingIsEditable && values.profitSharing === true && (
                <InputField
                  label='Expected percentage'
                  flexLabel='0 1 300px'
                  flexInput='0 1 300px'
                  padding='20px 0 3px'
                  paddingInput='0'>
                  <Field
                    name='profitSharingRate'
                    format={v => v}
                    parse={v => v}
                    render={({ input, meta }) => (
                      <PercentInput
                        name={input.name}
                        value={input.value === '' ? null : input.value}
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                        decimalScale={2}
                        maxLength={6}
                        width='115px'
                      />
                    )}
                  />
                </InputField>
              )}
            </div>
          </WizardStep>
        )}
      />
    )
  }
}

export default withRouter(inject('store', 'account')(observer(EditEmployerContributions)))
