import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Button, Spacer, SharedModal } from './'

const ButtonsBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 20px 50px 20px;
  max-width: 680px;
  width: 100%;

  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 0;
  }
`

const BodyText = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 18px;
  line-height: 33px;
`

class ModalConfirmation extends Component {
  static defaultProps = {
    title: 'Delete This Information',
  }

  static propTypes = {
    bodyText: PropTypes.string,
    isModalOpen: PropTypes.bool,
    title: PropTypes.string,
    toggleModal: PropTypes.func,
  }

  render() {
    const { bodyText, isModalOpen, toggleModal, title } = this.props

    return (
      <SharedModal isModalOpen={isModalOpen} title={title} toggleModal={toggleModal}>
        <BodyText>
          <Spacer space='15px' />
          <p>{bodyText}</p>
        </BodyText>
        <ButtonsBox>
          <Button primary label='Got it!' onClick={toggleModal} width='118px' />
        </ButtonsBox>
      </SharedModal>
    )
  }
}

export default ModalConfirmation
