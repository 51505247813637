import styled from '@emotion/styled'

const SSIPage = styled.div``

SSIPage.FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  margin-bottom: 10px;
`
SSIPage.QuestionaryWrapper = styled.div`
  display: flex;
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans';
  margin: 15px 0px;
  align-items: center;
`
SSIPage.Question = styled.div`
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  padding-right: 20px;
  flex: 2;
`
SSIPage.Answer = styled.div`
  flex: 4;
`

SSIPage.QuestionaryLabel = styled.div`
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  padding-right: 20px;
`
SSIPage.ButtonWrapper = styled.div`
  font-size: 14px;
`
SSIPage.Label = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.lightestGray};
  font-size: 1.125rem;
  line-height: 1.35;
  width: 100%;
  flex: 3;
`
SSIPage.Value = styled.div`
  color: ${({ active }) => (active ? '#022a3a' : '#7A8E96')};
  font-weight: ${({ active }) => (active ? '600' : 'normal')};
  flex: 2;
`
SSIPage.MinSpaceInputs = styled.div`
  & [class*='InputWrapper'] {
    margin-top: 0;
    min-height: 0;
    padding: 0;
    height: 30px;
  }
`
// SSI CHANGES Style
SSIPage.ChangesLabel = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 1.125rem;
  word-break: break-word;
  padding: 5px 0;
`
SSIPage.ChangesSSIHeader = styled.span`
  font-size: 20px;
  color: ${p => p.theme.lightestGray};
  padding: 5px 0;
`

SSIPage.ChangesInfoText = styled.div`
  color: ${p => p.theme.mediumGray};
  font-family: Helvetica;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`

SSIPage.ChangesSubTitle = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;
`

SSIPage.ChangesFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${p => p.theme.lightestGray};
  font-size: 1rem;
  word-break: break-word;
  padding: 5px 0;
  align-items: center;
`

SSIPage.ChangesAmount = styled.span`
  color: ${p => p.theme.darkestGray};
  font-size: 1.125rem;
  line-height: 18px;
  text-indent: 12px;
  margin-left: 4px;
`

SSIPage.ChangesLink = styled.a`
  cursor: pointer;
  color: ${p => p.theme.customLinkColor};
  text-decoration: none;
`

SSIPage.ChangesSliderContainer = styled.div`
  position: relative;
  width: 70%;
`

SSIPage.ChangesText = styled.span`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans';
  font-size: 14px;
`

SSIPage.ChangesRemoveExtraHight = styled.div`
  & > div {
    padding: 0;
    margin: 0;
    min-height: 0;
  }
`

export { SSIPage }
