import variables from '../variables'
import colors from '../colors'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { primary, primaryContrast, black },
} = colors

const slider = {
  mainColor: primary,
  mainColorContrast: primaryContrast,
  snapColor: black,
  disabledColor: 'rgba(177, 177, 177, 1)',
  knobSize: reduceCSSCalc(`calc(3.2 * ${unit})`),
  innerKnobSize: reduceCSSCalc(`calc(1.2 * ${unit})`),
  snapSize: reduceCSSCalc(`calc(0.2 * ${unit})`),
  inputWidth: reduceCSSCalc(`calc(5 * ${unit})`),
  barHeight: reduceCSSCalc(`calc(0.2 * ${unit})`),
  pinSize: reduceCSSCalc(`calc(2.6 * ${unit})`),
  pinElevation: reduceCSSCalc(`calc(1.7 * ${unit})`),
  sideSeparation: reduceCSSCalc(`calc(1 * ${unit})`),
  emptyKnobBorder: reduceCSSCalc(`calc(0.2 * ${unit})`),
}

export default slider
