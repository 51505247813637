/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'
import { Field } from 'react-final-form'
// import { Spacer } from '../../../components'
import { SliderWithValueUnderneath, Spacer } from '../../../../components'
import { Page } from '../style'

function ExtendedLifeExpectancy({
  store: { person, spouse },
  primaryFrozenValue,
  spouseFrozenValue,
}) {
  return (
    <>
      <Field
        name='primarySlider'
        subscription={{ value: true, touched: true, error: true }}
        render={({ input, meta }) => (
          <Page.SliderContainer>
            <Page.SliderLabel>{person.displayName}</Page.SliderLabel>
            <Page.SliderValue>
              <SliderWithValueUnderneath
                min={person.retirementAge + ''}
                max={person.age + 85 > 120 ? '120' : (person.age + 85).toString()}
                value={input.value}
                onChange={input.onChange}
                frozenValue={primaryFrozenValue}
              />
            </Page.SliderValue>
          </Page.SliderContainer>
        )}
      />
      {person.includeSpouse && (
        <>
          <Spacer space='8px' />
          <Field
            name='spouseSlider'
            subscription={{ value: true, touched: true, error: true }}
            render={({ input, meta }) => (
              <Page.SliderContainer
                css={css`
                  margin-top: 50px;
                  margin-bottom: 25px;
                `}>
                <Page.SliderLabel>{spouse.firstName}</Page.SliderLabel>
                <Page.SliderValue>
                  <SliderWithValueUnderneath
                    min={spouse.retirementAge + ''}
                    max={spouse.age + 85 > 120 ? '120' : (spouse.age + 85).toString()}
                    value={input.value}
                    onChange={input.onChange}
                    frozenValue={spouseFrozenValue}
                  />
                </Page.SliderValue>
              </Page.SliderContainer>
            )}
          />
        </>
      )}
    </>
  )
}

export default inject('store')(observer(ExtendedLifeExpectancy))
