import { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import { observer, inject } from 'mobx-react'

const StepperContainer = styled.div`
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Step = styled.div`
  color: ${p => (p.active ? '#038ab7' : '#677b84')};
  font-size: 1.125rem;
  width: 160px;
  text-align: center;
  padding: 0 10px;
  transition: color 0.5s;
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 30px solid #7a8e96;
`

class Stepper extends Component {
  render() {
    const { currentStep } = this.props.store.offboarding.offboardingInfo.steps
    const { steps } = this.props
    return (
      <StepperContainer>
        {steps.map((step, i) => {
          return (
            <Fragment key={step}>
              {i !== 0 && <Triangle />}
              <Step active={currentStep === i + 1}>{step}</Step>
            </Fragment>
          )
        })}
      </StepperContainer>
    )
  }
}

export default inject('store')(observer(Stepper))
