import { css, useTheme } from '@emotion/react'
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { API_URL } from '../../../api'
import styled from '@emotion/styled'
import _ from 'lodash'

import { HelpIcon, TooltipText, Button, Spacer, SVGWrapper } from '../../../components'
import { numberToDollars } from '../../../utils/utils'
import {
  CircleNegative,
  CirclePlus,
  MoneyBagFullGreen,
  MoneyBagFullBlue,
} from '../../../assets/icons'
import Chart from './Chart'

function UnderstandingYourResults(props) {
  const [cases, setCases] = useState({})
  const {
    handleCancel,
    modifiedMonthlyIncome,
    store: {
      person,
      spouse,
      allSpendingModifiedCases,
      allSpendingRecommendedCases,
      allSpendingBaseCases,
    },
  } = props
  const theme = useTheme()

  useEffect(() => {
    if (!_.isEmpty(modifiedMonthlyIncome)) {
      return setCases(modifiedMonthlyIncome)
    }
    if (!_.isEmpty(allSpendingModifiedCases)) {
      return setCases(allSpendingModifiedCases)
    } else if (!_.isEmpty(allSpendingRecommendedCases)) {
      return setCases(allSpendingRecommendedCases)
    } else {
      return setCases(allSpendingBaseCases)
    }
  }, []) // eslint-disable-line

  const [activeTab, setActiveTab] = useState(2)
  const [annually, setAnnually] = useState(true)
  const [showMore, setShowMore] = useState()

  if (_.isEmpty(cases)) {
    return null
  }
  return (
    <div
      css={css`
        max-width: 1300px;
        margin: 0 auto;
      `}>
      <LogoContainer>
        <Logo src={API_URL + theme.logo} alt='Logo' />
      </LogoContainer>
      <HeaderContainer>
        <Header>Understanding your results</Header>
        <HelpIcon tooltip={TooltipText.understandingYourResults()} />
      </HeaderContainer>
      <Spacer space='1rem;' />
      <TabContainer>
        <Tab isActive={activeTab === 1} onClick={() => setActiveTab(1)}>
          <TabText isActive={activeTab === 1}>
            {activeTab === 1 ? <b>Weak</b> : <TabSubText>Weak</TabSubText>}
          </TabText>
          <TabSubText isActive={activeTab === 1}>
            {activeTab === 1 ? <b>market results</b> : 'market results'}
          </TabSubText>
        </Tab>
        <Tab isActive={activeTab === 2} onClick={() => setActiveTab(2)}>
          <TabText isActive={activeTab === 2}>
            {activeTab === 2 ? <b>Average</b> : <TabSubText>Average</TabSubText>}
          </TabText>
          <TabSubText isActive={activeTab === 2}>
            {activeTab === 2 ? <b>market results</b> : 'market results'}
          </TabSubText>
        </Tab>
        <Tab isActive={activeTab === 3} onClick={() => setActiveTab(3)}>
          <TabText isActive={activeTab === 3}>
            {activeTab === 3 ? <b>Strong</b> : <TabSubText>Strong</TabSubText>}
          </TabText>
          <TabSubText isActive={activeTab === 3}>
            {activeTab === 3 ? <b>market results</b> : 'market results'}
          </TabSubText>
        </Tab>
      </TabContainer>
      <Spacer space='1rem;' />
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}>
        <Container>
          <InfoCard>
            <div>
              <Label>
                Average monthly retirement paycheck in {activeTab === 1 && 'weak'}{' '}
                {activeTab === 2 && 'average'} {activeTab === 3 && 'strong'} market
              </Label>
              <Spacer space='0.30rem;' />
              <DimmedText>Estimated after-tax spendable money</DimmedText>
            </div>
            <div>
              <Amount>
                {activeTab === 1 &&
                  numberToDollars(cases.weakMarketSpendingAdvice.monthlyIncome, true)}
                {activeTab === 2 &&
                  numberToDollars(cases.averageMarketSpendingAdvice.monthlyIncome, true)}
                {activeTab === 3 &&
                  numberToDollars(cases.strongMarketSpendingAdvice.monthlyIncome, true)}
              </Amount>
              <Spacer space='0.30rem;' />
              <DimmedText>
                (<DollarSign>$</DollarSign>
                {activeTab === 1 &&
                  numberToDollars(cases.weakMarketSpendingAdvice.monthlyIncome * 12, true)}
                {activeTab === 2 &&
                  numberToDollars(cases.averageMarketSpendingAdvice.monthlyIncome * 12, true)}
                {activeTab === 3 &&
                  numberToDollars(cases.strongMarketSpendingAdvice.monthlyIncome * 12, true)}
                &nbsp;annually)
              </DimmedText>
            </div>
          </InfoCard>
          <Spacer space='1rem;' />

          <div>
            Things you may see in the chart{' '}
            <HelpIcon tooltip={TooltipText.thingsYouMaySeeInChart()} />
          </div>
          <Spacer space='0.75rem;' />

          {activeTab === 1 && (
            <Chart spendingByAges={cases.weakMarketSpendingAdvice.spendingByAges} />
          )}
          {activeTab === 2 && (
            <Chart spendingByAges={cases.averageMarketSpendingAdvice.spendingByAges} />
          )}
          {activeTab === 3 && (
            <Chart spendingByAges={cases.strongMarketSpendingAdvice.spendingByAges} />
          )}
          <Spacer space='1rem;' />

          <StrategyCardContainer>
            <InvestmentIncomeCard activeTab={activeTab} cases={cases} />
            <FromToCard activeTab={activeTab} cases={cases} />
            <ShortfallCard activeTab={activeTab} cases={cases} person={person} spouse={spouse} />
            <RetirementCard activeTab={activeTab} cases={cases} person={person} spouse={spouse} />
            <StrategyCard padding='0px' marginBottom='0px' visibility='hidden' />
            <StrategyCard padding='0px' marginBottom='0px' visibility='hidden' />
          </StrategyCardContainer>

          <Spacer space='0.75rem' />
          <div
            css={css`
              cursor: pointer;
              user-select: none;
              display: flex;
            `}>
            <div onClick={() => setShowMore(!showMore)}>
              <SVGWrapper
                css={css`
                  margin-right: 10px;
                `}
                svg={showMore ? CircleNegative : CirclePlus}
                fill='#3A4D57'
                size='large'
              />
            </div>
            <HeaderContainer>
              <span
                css={css`
                  color: 45a606a;
                  font-size: 20px;
                  margin-right: 10px;
                `}>
                View income details
              </span>
              <HelpIcon tooltip={TooltipText.incomeDetails()} />
            </HeaderContainer>
          </div>

          {showMore && (
            <div>
              <Spacer space='0.75rem' />
              <SecondaryText>
                <b>Be aware</b>: Advice and your withdrawal strategy is not a one time event. The
                income amounts are applicable to <b>this calendar year only</b>. The figures may
                differ in subsequent years depending upon new tax laws, what's happened in your
                life, and market projections for the upcoming calendar year.
              </SecondaryText>
              <Spacer space='0.75rem' />

              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}>
                <span
                  css={css`
                    font-weight: 600;
                    margin-right: 100px;
                    font-size: 14px;
                    color: #4a606a;
                  `}>
                  How much can you spend each month in{' '}
                  {activeTab === 1 ? 'a weak' : activeTab === 2 ? 'an average' : 'a strong'} market
                </span>

                <ButtonContainer>
                  <CustomButton active={!annually} onClick={() => setAnnually(false)}>
                    Monthly
                  </CustomButton>
                  <CustomButton active={annually} onClick={() => setAnnually(true)}>
                    Annually
                  </CustomButton>
                </ButtonContainer>
              </div>
              <Spacer space='0.75rem' />

              <table
                css={css`
                  max-width: 683px;
                  border-collapse: collapse;
                  border-bottom: 1px solid #7a8e96;
                `}>
                <thead>
                  <TheadTR>
                    <Th
                      css={css`
                        padding: 10px 33px;
                      `}>
                      Your age
                    </Th>
                    <Th
                      css={css`
                        padding: 10px 33px;
                      `}>
                      Lifetime income
                    </Th>
                    <Th
                      css={css`
                        padding: 10px 33px;
                      `}>
                      Withdrawals from investment balance
                    </Th>
                    <Th
                      css={css`
                        padding: 10px 33px;
                      `}>
                      Total available to spend
                    </Th>
                  </TheadTR>
                </thead>
                {activeTab === 1 && (
                  <tbody align='center'>
                    {annually &&
                      cases.weakMarketSpendingAdvice.spendingByAges.map((item, i) => (
                        <Tr i={i} key={item.age}>
                          <Td
                            css={css`
                              padding: 5px 30px;
                            `}>
                            <SecondaryText>{item.age}</SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>
                                <DollarSign>$</DollarSign>
                              </DollarSign>
                              {numberToDollars(item.lifetimeIncome, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.investmentIncome, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.investmentIncome + item.lifetimeIncome, true)}
                            </SecondaryText>
                          </Td>
                        </Tr>
                      ))}
                    {!annually &&
                      cases.weakMarketSpendingAdvice.spendingByAges.map((item, i) => (
                        <Tr i={i} key={item.age}>
                          <Td
                            css={css`
                              padding: 5px 30px;
                            `}>
                            <SecondaryText>{item.age}</SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.lifetimeIncome / 12, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.investmentIncome / 12, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(
                                (item.investmentIncome + item.lifetimeIncome) / 12,
                                true
                              )}
                            </SecondaryText>
                          </Td>
                        </Tr>
                      ))}
                  </tbody>
                )}
                {activeTab === 2 && (
                  <tbody align='center'>
                    {annually &&
                      cases.averageMarketSpendingAdvice.spendingByAges.map((item, i) => (
                        <Tr i={i} key={item.age}>
                          <Td
                            css={css`
                              padding: 5px 30px;
                            `}>
                            <SecondaryText>{item.age}</SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.lifetimeIncome, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.investmentIncome, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.investmentIncome + item.lifetimeIncome, true)}
                            </SecondaryText>
                          </Td>
                        </Tr>
                      ))}
                    {!annually &&
                      cases.averageMarketSpendingAdvice.spendingByAges.map((item, i) => (
                        <Tr i={i} key={item.age}>
                          <Td
                            css={css`
                              padding: 5px 30px;
                            `}>
                            <SecondaryText>{item.age}</SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.lifetimeIncome / 12, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.investmentIncome / 12, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(
                                (item.investmentIncome + item.lifetimeIncome) / 12,
                                true
                              )}
                            </SecondaryText>
                          </Td>
                        </Tr>
                      ))}
                  </tbody>
                )}
                {activeTab === 3 && (
                  <tbody align='center'>
                    {annually &&
                      cases.strongMarketSpendingAdvice.spendingByAges.map((item, i) => (
                        <Tr i={i} key={item.age}>
                          <Td
                            css={css`
                              padding: 5px 30px;
                            `}>
                            <SecondaryText>{item.age}</SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.lifetimeIncome, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.investmentIncome, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.investmentIncome + item.lifetimeIncome, true)}
                            </SecondaryText>
                          </Td>
                        </Tr>
                      ))}
                    {!annually &&
                      cases.strongMarketSpendingAdvice.spendingByAges.map((item, i) => (
                        <Tr i={i} key={item.age}>
                          <Td
                            css={css`
                              padding: 5px 30px;
                            `}>
                            <SecondaryText>{item.age}</SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.lifetimeIncome / 12, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(item.investmentIncome / 12, true)}
                            </SecondaryText>
                          </Td>
                          <Td>
                            <SecondaryText>
                              <DollarSign>$</DollarSign>
                              {numberToDollars(
                                (item.investmentIncome + item.lifetimeIncome) / 12,
                                true
                              )}
                            </SecondaryText>
                          </Td>
                        </Tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </Container>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
        `}>
        <Button secondary width='120px' onClick={() => handleCancel()} label='Close' />
      </div>
    </div>
  )
}

export default inject('store')(observer(UnderstandingYourResults))

const InvestmentIncomeCard = ({ activeTab, cases }) => {
  let investmentMin
  let investmentMax
  let lifetimeIncomeMin
  let lifetimeIncomeMax

  if (activeTab === 1) {
    investmentMin = Math.min(
      ...cases.weakMarketSpendingAdvice.spendingByAges.map(item => item.investmentIncome / 12)
    )
    investmentMax = Math.max(
      ...cases.weakMarketSpendingAdvice.spendingByAges.map(item => item.investmentIncome / 12)
    )

    lifetimeIncomeMin = Math.min(
      ...cases.weakMarketSpendingAdvice.spendingByAges.map(item => item.lifetimeIncome / 12)
    )
    lifetimeIncomeMax = Math.max(
      ...cases.weakMarketSpendingAdvice.spendingByAges.map(item => item.lifetimeIncome / 12)
    )
  } else if (activeTab === 2) {
    investmentMin = Math.min(
      ...cases.averageMarketSpendingAdvice.spendingByAges.map(item => item.investmentIncome / 12)
    )
    investmentMax = Math.max(
      ...cases.averageMarketSpendingAdvice.spendingByAges.map(item => item.investmentIncome / 12)
    )

    lifetimeIncomeMin = Math.min(
      ...cases.averageMarketSpendingAdvice.spendingByAges.map(item => item.lifetimeIncome / 12)
    )
    lifetimeIncomeMax = Math.max(
      ...cases.averageMarketSpendingAdvice.spendingByAges.map(item => item.lifetimeIncome / 12)
    )
  } else if (activeTab === 3) {
    investmentMin = Math.min(
      ...cases.strongMarketSpendingAdvice.spendingByAges.map(item => item.investmentIncome / 12)
    )
    investmentMax = Math.max(
      ...cases.strongMarketSpendingAdvice.spendingByAges.map(item => item.investmentIncome / 12)
    )

    lifetimeIncomeMin = Math.min(
      ...cases.strongMarketSpendingAdvice.spendingByAges.map(item => item.lifetimeIncome / 12)
    )
    lifetimeIncomeMax = Math.max(
      ...cases.strongMarketSpendingAdvice.spendingByAges.map(item => item.lifetimeIncome / 12)
    )
  }

  return (
    <StrategyCard
      css={css`
        display: flex;
        justify-content: space-between;
        min-height: 280px;
      `}>
      <div>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 5px;
          `}>
          <SvgContainer>
            <MoneyBagFullBlue />
          </SvgContainer>
          <div
            css={css`
              color: #008bac;
            `}>
            Investment income
          </div>
        </div>

        <div>
          <SecondaryText>
            <DollarSign>$</DollarSign>
            <Bold>{numberToDollars(investmentMin, true)}</Bold> to <DollarSign>$</DollarSign>
            <Bold>{numberToDollars(investmentMax, true)}</Bold>
          </SecondaryText>
          <SecondaryText>Monthly spendable</SecondaryText>
        </div>
      </div>

      <Spacer space='15px' />

      <div>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 5px;
          `}>
          <SvgContainer>
            <MoneyBagFullGreen />
          </SvgContainer>
          <div
            css={css`
              color: #76bd23;
            `}>
            Lifetime income
          </div>
        </div>

        <div>
          <SecondaryText>
            <DollarSign>$</DollarSign>
            <Bold>{numberToDollars(lifetimeIncomeMin, true)}</Bold> to <DollarSign>$</DollarSign>
            <Bold>{numberToDollars(lifetimeIncomeMax, true)}</Bold>
          </SecondaryText>
          <SecondaryText>Monthly spendable</SecondaryText>
        </div>
      </div>

      <HelpIconContainer>
        <HelpIcon tooltip={TooltipText.investmentIncomeVSLifetimeIncome()} />
      </HelpIconContainer>
    </StrategyCard>
  )
}
const FromToCard = ({ cases, activeTab }) => {
  return (
    <StrategyCard
      css={css`
        justify-content: space-between;
        min-height: 280px;
      `}>
      <SecondaryText>
        <DollarSign>$</DollarSign>
        <Bold>{numberToDollars(0, true)}</Bold> to <DollarSign>$</DollarSign>
        <Bold>
          {activeTab === 1 &&
            numberToDollars(cases.weakMarketSpendingAdvice.remainingLegacyAmount, true)}
          {activeTab === 2 &&
            numberToDollars(cases.averageMarketSpendingAdvice.remainingLegacyAmount, true)}
          {activeTab === 3 &&
            numberToDollars(cases.strongMarketSpendingAdvice.remainingLegacyAmount, true)}
        </Bold>
        <Spacer space='5px' />
        <SecondaryText>Possible amount</SecondaryText>
        <SecondaryText>remaining for legacy</SecondaryText>
        {_.get(cases, 'currentCompanyStockWeightPercentage', 0) > 0 ? (
          <>
            <Spacer space='5px' />
            <Bold>{numberToDollars(cases.currentCompanyStockWeightPercentage, true)}</Bold>
            <DollarSign>%</DollarSign> <br></br>
            Percentage of account in company stock
          </>
        ) : null}
      </SecondaryText>

      <HelpIconContainer>
        <HelpIcon tooltip={TooltipText.forLegacy()} />
      </HelpIconContainer>
    </StrategyCard>
  )
}
const ShortfallCard = ({ person, spouse, cases, activeTab }) => {
  return (
    <StrategyCard
      css={css`
        justify-content: space-between;
        min-height: 280px;
      `}>
      {activeTab === 1 &&
        (cases.weakMarketSpendingAdvice.firstShortfallYear > 0 ? (
          <div>
            <CardLabel>{cases.weakMarketSpendingAdvice.firstShortfallYear}</CardLabel>
            <SecondaryText>Year of first shortfall</SecondaryText>
          </div>
        ) : (
          <div>
            <CardLabel>N/A</CardLabel>
            <SecondaryText>Year of first shortfall</SecondaryText>
          </div>
        ))}

      {activeTab === 2 &&
        (cases.averageMarketSpendingAdvice.firstShortfallYear > 0 ? (
          <div>
            <CardLabel>{cases.averageMarketSpendingAdvice.firstShortfallYear}</CardLabel>
            <SecondaryText>Year of first shortfall</SecondaryText>
          </div>
        ) : (
          <div>
            <CardLabel>N/A</CardLabel>
            <SecondaryText>Year of first shortfall</SecondaryText>
          </div>
        ))}

      {activeTab === 3 &&
        (cases.strongMarketSpendingAdvice.firstShortfallYear > 0 ? (
          <div>
            <CardLabel>{cases.strongMarketSpendingAdvice.firstShortfallYear}</CardLabel>
            <SecondaryText>Year of first shortfall</SecondaryText>
          </div>
        ) : (
          <div>
            <CardLabel>N/A</CardLabel>
            <SecondaryText>Year of first shortfall</SecondaryText>
          </div>
        ))}

      {activeTab === 1 && (
        <div>
          <CardLabel>
            {numberToDollars(cases.weakMarketSpendingAdvice.standardDeviationInvestment, false)}
          </CardLabel>
          <SecondaryText>Standard deviation of investments</SecondaryText>
        </div>
      )}
      {activeTab === 2 && (
        <div>
          <CardLabel>
            {numberToDollars(cases.averageMarketSpendingAdvice.standardDeviationInvestment, false)}{' '}
          </CardLabel>
          <SecondaryText>Standard deviation of investments</SecondaryText>
        </div>
      )}
      {activeTab === 3 && (
        <div>
          <CardLabel>
            {numberToDollars(cases.strongMarketSpendingAdvice.standardDeviationInvestment, false)}
          </CardLabel>
          <SecondaryText>Standard deviation of investments</SecondaryText>
        </div>
      )}

      <HelpIconContainer>
        <HelpIcon tooltip={TooltipText.standardDeviation()} />
      </HelpIconContainer>
    </StrategyCard>
  )
}
const RetirementCard = ({ person, spouse, cases }) => {
  return (
    <StrategyCard
      css={css`
        justify-content: space-between;
        min-height: 280px;
      `}>
      {person.includeSpouse && spouse.age < spouse.retirementAge ? (
        <div>
          <CardLabel>{spouse.firstName} retires</CardLabel>
          <SecondaryText>
            Year{' '}
            <CardLabel
              css={css`
                display: contents;
              `}>
              {cases.averageMarketSpendingAdvice.spouse.retirementYear}
            </CardLabel>{' '}
            | age{' '}
            <CardLabel
              css={css`
                display: contents;
              `}>
              {cases.averageMarketSpendingAdvice.spouse.retAge}
            </CardLabel>
          </SecondaryText>
        </div>
      ) : null}
      {person.age < person.retirementAge ? (
        <div>
          <CardLabel>{person.displayName} retires</CardLabel>
          <SecondaryText>
            Year{' '}
            <CardLabel
              css={css`
                display: contents;
              `}>
              {cases.averageMarketSpendingAdvice.primary.retirementYear}
            </CardLabel>{' '}
            | age{' '}
            <CardLabel
              css={css`
                display: contents;
              `}>
              {cases.averageMarketSpendingAdvice.primary.retAge}
            </CardLabel>
          </SecondaryText>
        </div>
      ) : null}
      <div>
        <CardLabel>{person.displayName}'s life expectancy</CardLabel>
        <SecondaryText>
          Year{' '}
          <CardLabel
            css={css`
              display: contents;
            `}>
            {cases.averageMarketSpendingAdvice.primary.lifeExpectancyYear}
          </CardLabel>{' '}
          | age{' '}
          <CardLabel
            css={css`
              display: contents;
            `}>
            {cases.averageMarketSpendingAdvice.primary.lifeExpectancyAge}
          </CardLabel>
        </SecondaryText>
      </div>
      {person.includeSpouse && (
        <div>
          <CardLabel>{spouse.firstName}'s life expectancy</CardLabel>
          <SecondaryText>
            Year{' '}
            <CardLabel
              css={css`
                display: contents;
              `}>
              {cases.averageMarketSpendingAdvice.spouse.lifeExpectancyYear}
            </CardLabel>{' '}
            | age{' '}
            <CardLabel
              css={css`
                display: contents;
              `}>
              {cases.averageMarketSpendingAdvice.spouse.lifeExpectancyAge}
            </CardLabel>
          </SecondaryText>
        </div>
      )}

      <HelpIconContainer>
        <HelpIcon tooltip={TooltipText.lifeExpectancy()} />
      </HelpIconContainer>
    </StrategyCard>
  )
}

const HelpIconContainer = styled.div`
  align-self: flex-end;
  margin-top: 20px;
  margin-bottom: -10px;
`

const Header = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 600;
  line-height: 41px;
  padding-right: 10px;
`
const HeaderContainer = styled.span`
  display: flex;
  align-items: center;
`
const TabContainer = styled.div`
  border-bottom: 2px solid #7a8e96;
  display: flex;
  justify-content: space-evenly;
`
const Tab = styled.div`
  border: ${p => (p.isActive ? '2px solid #7A8E96' : null)};
  border-bottom: ${p => (p.isActive ? '1px solid #FFFFFF' : null)};
  background-color: ${p => (p.isActive ? '#FFFFFF' : 'transparent')};
  position: relative;
  top: 3px;
  padding: 10px 80px 12px 80px;
  margin-right: 6%;
  cursor: pointer;

  @media (max-width: 800px) {
    padding-right: 6px;
    padding-left: 6px;
    margin-right: 0;
  }
`
const TabText = styled.div`
  color: ${p => (p.isActive ? p.theme.primaryColor : p.theme.lightestGray)};
  font-size: 1.125rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
`

const TabSubText = styled.div`
  color: ${p => (p.isActive ? p.theme.primaryColor : p.theme.lightestGray)};
  font-size: 0.875rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`
const InfoCard = styled.div`
  width: 100%;
  padding: 2rem 5rem;
  border: 1px solid #e6e9ea;
  background-color: #f0f4f5;
  box-shadow: 0 2px 2px 0 rgba(74, 96, 106, 0.6);
  display: flex;
  justify-content: space-around;
`
const Amount = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: #333;
  margin-bottom: 5px;
  &::before {
    content: '$';
    color: #4d9927;
    padding-right: 3px;
  }
`
const Label = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
`
const DimmedText = styled.div`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
`
const Container = styled.div`
  width: 80%;
`
const StrategyCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  & > * {
    margin: 0 10px;
  }
`
const StrategyCard = styled.div`
  background-color: #f5fafc;
  border: 1px solid #b6c0c4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: ${p => (p.marginBottom ? p.marginBottom : '20px;')};
  min-width: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${p => (p.padding ? p.padding : '30px 15px')};
  flex: 1 1 220px;
  visibility: ${p => (p.visibility ? p.visibility : 'visible')};
`
const CardLabel = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #022a3a;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.25rem;
`
const SecondaryText = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
`
const SvgContainer = styled.div`
  height: 50px;
  width: 50px;
  margin-right: 10px;
`
const DollarSign = styled.span`
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 24px;
`
const Bold = styled.b`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #022a3a;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.25rem;
`
const ButtonContainer = styled.span`
  margin-left: 10px;
  border: 1px solid #7a8e96;
  border-radius: 50px;
  min-width: 187px;
`
const CustomButton = styled.button`
  padding: 4px 15px;
  border: none;
  background-color: ${({ active }) => (active ? '#85c964bd' : 'transparent')};
  border: ${({ active }) => (active ? '1px solid #7a8e96' : 'none')};
  outline: none;
  border-radius: 50px;
  margin: 0px -1px;
  color: #7a8e96;
  cursor: pointer;
`
const TheadTR = styled.tr`
  border-top: 1px solid #7a8e96;
  border-bottom: 1px solid #7a8e96;
  text-align: left;
`
const Tr = styled.tr`
  background-color: ${({ i }) => (i % 2 === 0 ? '#E7F3F9' : '#fff')};
  height: 38px;
`
const Th = styled.th`
  font-size: 14px;
  color: ${p => p.theme.lightestGray};
`
const Td = styled.td`
  text-align: left;
  padding: 5px 30px;
`
const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`
const Logo = styled.img`
  width: 20%;
  height: auto;
`
