import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Box = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  border: 3px solid #767676;
  border-radius: 4px;
  background-color: ${p => (p.checked ? '#767676' : 'white')};
  transition: background-color 0.3s;
`
const Check = styled.div`
  position: absolute;
  top: -0.05rem;
  left: 0.325rem;
  width: 0.5rem;
  height: 1rem;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: ${p => (p.checked ? 'rotate(45deg)' : 'scale(0.6) rotate(45deg)')};
  transition: 0.2s;
`

class Checkbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
  }

  render() {
    return (
      <Box {...this.props}>
        <Check checked={this.props.checked} />
      </Box>
    )
  }
}

export default Checkbox
