import { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

import GoalCard from './GoalCard'
import { FillGaugeCard } from '../IncomeReplacement'
import { Page } from '../elements'

class GoalList extends Component {
  static displayName = 'GoalList'

  static propTypes = {
    goals: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
  }

  render() {
    const {
      goals,
      history,
      includeFill,
      store: {
        config: { isSpendown },
      },
    } = this.props

    return (
      <Page.GoalsWrapper>
        {includeFill && !isSpendown && (
          <FillGaugeCard history={history} name='Percent of income after retiring' />
        )}
        {goals.map(goal => {
          return <GoalCard key={goal.id} goal={goal} history={history} />
        })}
      </Page.GoalsWrapper>
    )
  }
}

export default inject('store')(observer(GoalList))
