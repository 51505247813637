/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'
import * as yup from 'yup'
import { isInt, isMobilePhone } from 'validator'

import {
  Label,
  TextInput,
  PhoneInput,
  Spacer,
  HelpIcon,
  TooltipText,
  ButtonInput,
  TextErrorField,
} from '../../../components'
import { InputBox, Text, BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { reduceValidationError } from '../../../utils'
import { Row, AddressBox } from '../styled'

const schema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .required('Your email address is required')
    .email('A valid email is required'),
  mobilePhone: yup
    .string()
    .nullable()
    .test(
      'is-mobile-phone',
      'Please enter a valid phone number',
      value =>
        value === undefined || value === null || value === '' || isMobilePhone(value || '', 'en-US')
    ),
  phone: yup
    .string()
    .nullable()
    .test(
      'is-mobile-phone',
      'Please enter a valid phone number',
      value =>
        value === undefined || value === null || value === '' || isMobilePhone(value, 'en-US')
    )
    .when('phoneExtension', (phoneExtension, schema) =>
      phoneExtension ? schema.required('Please enter a valid phone number') : schema
    ),
  phoneExtension: yup
    .string()
    .nullable()
    .test(
      'is-phone-extension',
      'Please enter a valid extension',
      value =>
        value === undefined ||
        value === null ||
        value === '' ||
        isInt(value, { min: 0, max: 999999 })
    ),
  receiveElectronicStatements: yup.boolean().nullable().oneOf([true, false], 'Select one'),
  receiveMarketingStatements: yup.boolean().nullable().oneOf([true, false], 'Select one'),
})

class ContactInfoEdit extends Component {
  onSubmit = async values => {
    try {
      const { store, onClose } = this.props
      const {
        contactInfo,
        updateContactInfo,
        communicationPreferences,
        updateCommunicationPreferences,
      } = store
      const { receiveElectronicStatements, receiveMarketingStatements, ...configValues } = values

      if (!_.isEqual(configValues, getSnapshot(contactInfo))) {
        await updateContactInfo(configValues)
      }

      if (
        !_.isEqual(
          receiveElectronicStatements,
          communicationPreferences.RECEIVE_ELECTRONIC_STATEMENTS
        )
      ) {
        await updateCommunicationPreferences({
          RECEIVE_ELECTRONIC_STATEMENTS: receiveElectronicStatements,
          RECEIVE_MARKETING_EMAILS: receiveMarketingStatements,
        })
      }

      if (
        !_.isEqual(receiveMarketingStatements, communicationPreferences.RECEIVE_MARKETING_EMAILS)
      ) {
        await updateCommunicationPreferences({
          RECEIVE_ELECTRONIC_STATEMENTS: receiveElectronicStatements,
          RECEIVE_MARKETING_EMAILS: receiveMarketingStatements,
        })
      }

      onClose()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  initialValues = () => {
    const { store } = this.props
    const {
      RECEIVE_ELECTRONIC_STATEMENTS: receiveElectronicStatements,
      RECEIVE_MARKETING_EMAILS: receiveMarketingStatements,
    } = store.communicationPreferences

    // test for null cases
    // initialValues.email = null
    // initialValues.mobilePhone = null
    // initialValues.phone = null
    // initialValues.phoneExtension = null

    return {
      ...getSnapshot(store.contactInfo),
      receiveElectronicStatements,
      receiveMarketingStatements,
    }
  }

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  render() {
    const { store, onClose } = this.props
    const {
      config: { isRetail },
    } = store

    const {
      mailingAddress1,
      mailingAddress2,
      mailingCity,
      mailingCountry,
      mailingState,
      mailingZip,
    } = store.contactInfo

    const isInstitutional = true

    return (
      <Form
        initialValues={this.initialValues()}
        validate={this.validate}
        onSubmit={this.onSubmit}
        subscription={{ submitting: true, submitError: true }}
        render={({ handleSubmit, submitting }) => (
          <div>
            <Row>
              <Label>Contact email</Label>
              <InputBox>
                <Field
                  name='email'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <TextInput
                      name={input.name}
                      value={input.value}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      showError={meta.touched}
                      error={meta.error}
                      expanded={input.value}
                      css={css`
                        max-width: 100%;
                      `}
                    />
                  )}
                />
              </InputBox>
            </Row>
            <div
              css={css`
                padding-top: 48px;
              `}
            />
            <Row>
              <Label>Mobile</Label>
              <InputBox>
                <Field
                  name='mobilePhone'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <PhoneInput
                      name={input.name}
                      value={input.value}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      showError={meta.touched}
                      error={meta.error}
                    />
                  )}
                />
              </InputBox>
            </Row>

            <div
              css={css`
                max-width: 600px;
                margin-left: 25.8%;
                @media (max-width: 800px) {
                  margin-left: 0;
                }
              `}>
              <div
                css={css`
                  display: flex;
                  justify-content: flex-start;
                  @media (max-width: 800px) {
                    padding-top: 1rem;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                  }
                `}>
                <Row
                  css={css`
                    @media (max-width: 800px) {
                      max-width: 180px;
                    }
                  `}>
                  <Label>Daytime</Label>
                  <InputBox
                    css={css`
                      padding-right: 10px;
                    `}>
                    <Field
                      name='phone'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <PhoneInput
                          name={input.name}
                          value={input.value}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          showError={meta.touched}
                          error={meta.error}
                        />
                      )}
                    />
                  </InputBox>
                </Row>
                <Row>
                  <Label>Ext.</Label>
                  <InputBox>
                    <Field
                      name='phoneExtension'
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <TextInput
                          name={input.name}
                          value={input.value}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onChange={(name, value) => input.onChange(value)}
                          showError={meta.touched}
                          error={meta.error}
                          width='100px'
                        />
                      )}
                    />
                  </InputBox>
                </Row>
              </div>
            </div>

            <div
              css={css`
                padding-top: 48px;
              `}
            />

            {!isRetail && (
              <Row alignItems='baseline'>
                <Label>Address</Label>
                <AddressBox>
                  {isInstitutional ? (
                    <div>
                      <Text>{mailingAddress1}</Text>
                      <Text>{mailingAddress2}</Text>
                      <Text>{mailingCity}</Text>
                      <Text>{mailingState}</Text>
                      <Text>{mailingCountry}</Text>
                      <Text>{mailingZip}</Text>
                      {(!!mailingAddress1 ||
                        !!mailingAddress2 ||
                        !!mailingCity ||
                        !!mailingState ||
                        !!mailingCountry ||
                        !!mailingZip) && <Spacer space='16px' />}
                      <Text>Please contact your employer to update your address</Text>
                    </div>
                  ) : (
                    <div>
                      <Field
                        name='mailingAddress1'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <TextInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            showError={meta.touched}
                            error={meta.error}
                          />
                        )}
                      />

                      <Field
                        name='mailingAddress2'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <TextInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            showError={meta.touched}
                            error={meta.error}
                          />
                        )}
                      />

                      <Field
                        name='mailingCity'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <TextInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            showError={meta.touched}
                            error={meta.error}
                          />
                        )}
                      />

                      <Field
                        name='mailingState'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <TextInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            showError={meta.touched}
                            error={meta.error}
                          />
                        )}
                      />

                      <Field
                        name='mailingCountry'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <TextInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            showError={meta.touched}
                            error={meta.error}
                          />
                        )}
                      />

                      <Field
                        name='mailingZip'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <TextInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onFocus={input.onFocus}
                            onBlur={input.onBlur}
                            showError={meta.touched}
                            error={meta.error}
                          />
                        )}
                      />
                    </div>
                  )}
                </AddressBox>
              </Row>
            )}

            {!isRetail && (
              <div
                css={css`
                  padding-top: 32px;
                `}
              />
            )}
            <Row>
              <Label>
                Electronic delivery consent{' '}
                <HelpIcon size='mediumLarge' tooltip={TooltipText.electronicDeliveryConsent()} />
              </Label>
              <InputBox />
            </Row>
            {!isRetail && (
              <Row>
                <Label>GuidedChoice annual summary</Label>
                <Field
                  name='receiveElectronicStatements'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <InputBox>
                      <ButtonInput
                        isActive={input.value === true}
                        name={input.name}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        text='On'
                        value
                        width='126px'
                        onClick={(name, value) => input.onChange(value)}
                      />
                      <ButtonInput
                        isActive={input.value === false}
                        name={input.name}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        text='Off'
                        value={false}
                        width='126px'
                        onClick={(name, value) => input.onChange(value)}
                      />
                      <TextErrorField showError={meta.touched} error={meta.error} />
                    </InputBox>
                  )}
                />
              </Row>
            )}
            <Row>
              <Label>Email Communications</Label>
              <Field
                name='receiveMarketingStatements'
                subscription={{ value: true, touched: true, error: true }}
                render={({ input, meta }) => (
                  <InputBox>
                    <ButtonInput
                      isActive={input.value === true}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      text='On'
                      value
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                    />
                    <ButtonInput
                      isActive={input.value === false}
                      name={input.name}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      text='Off'
                      value={false}
                      width='126px'
                      onClick={(name, value) => input.onChange(value)}
                    />
                    <TextErrorField showError={meta.touched} error={meta.error} />
                  </InputBox>
                )}
              />
            </Row>

            <div
              css={css`
                padding-top: 32px;
              `}
            />
            <ButtonsBox>
              <BackButton backgroundColor='#FFFFFF' onClick={onClose} disabled={submitting}>
                Cancel
              </BackButton>
              <NextButton onClick={handleSubmit} disabled={submitting}>
                Save
              </NextButton>
            </ButtonsBox>
          </div>
        )}
      />
    )
  }
}

export default inject('store')(observer(ContactInfoEdit))
