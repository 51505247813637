import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { numberToDollars } from '../../utils/utils'

import { Spacer, FillGauge, SVGWrapper } from '../../components'
import { OnTrack, Warning } from '../../assets/icons'
import { Section } from './styles'

const IncomeGoal = ({
  mode,
  store: {
    baseCase,
    spendingModifiedCases,
    spendingBaseCases,
    person: { retirementIncomeGoalPct },
    getBaseCase,
  },
}) => {
  useEffect(() => {
    const fetchBaseCase = async () => {
      try {
        await getBaseCase()
      } catch (err) {
        console.error(err)
      }
    }
    if (mode === 'retMoreThanThreeYearsAway' || mode === 'retWithinThreeYears') {
      fetchBaseCase()
    }
  }, [getBaseCase, mode])

  if (mode === 'retMoreThanThreeYearsAway') {
    return (
      <Section>
        <Section.SectionTitle>You & your income goal</Section.SectionTitle>
        <Spacer space='10px' />
        <Section.OnTrackWrapper>
          {baseCase.isOnTrack ? (
            <SVGWrapper
              svg={OnTrack}
              fill='#7a8e96'
              hoverFill='#053240'
              size='large'
              cursor='default'
              style={{ marginRight: 4 }}
            />
          ) : (
            <SVGWrapper
              svg={Warning}
              fill='#7a8e96'
              hoverFill='#053240'
              size='large'
              cursor='default'
              style={{ marginRight: 4 }}
            />
          )}
          <Section.OnTrackRow isOnTrack={baseCase.isOnTrack}>
            Plan:{' '}
            <Section.OnTrackText>
              {baseCase.isOnTrack ? 'ON TRACK' : 'OFF TRACK'}
            </Section.OnTrackText>
          </Section.OnTrackRow>
        </Section.OnTrackWrapper>
        <Spacer space='12px' />

        <Section.IndentedSection>
          <Section.GoalStatisticBox>
            <Section paddingRight='100px'>
              <div>Your income goal</div>
              <Spacer space='6px' />
              <FillGauge id='incomeGoal' fill={retirementIncomeGoalPct} />
            </Section>
            <Section>
              <div>How you're doing</div>
              <Spacer space='6px' />
              <FillGauge id='income' fill={baseCase.retirementIncomeGoal} />
            </Section>
          </Section.GoalStatisticBox>
        </Section.IndentedSection>
        <Spacer space='12px' />

        <Section.IndentedSection>
          {retirementIncomeGoalPct === baseCase.retirementIncomeGoal ||
          retirementIncomeGoalPct < baseCase.retirementIncomeGoal ? (
            <Section.SecondaryText>
              You are {retirementIncomeGoalPct === baseCase.retirementIncomeGoal && 'meeting'}{' '}
              {retirementIncomeGoalPct < baseCase.retirementIncomeGoal && 'exceeding'} your goal of{' '}
              {retirementIncomeGoalPct}% — great job!
            </Section.SecondaryText>
          ) : (
            <Section.SecondaryText>
              Let's see what you can do to get to your goal of {retirementIncomeGoalPct}%.
            </Section.SecondaryText>
          )}
        </Section.IndentedSection>
        <Spacer space='30px' />
      </Section>
    )
  }

  if (mode === 'retWithinThreeYears') {
    return (
      <Section>
        <Section.SectionTitle>You & your income goal</Section.SectionTitle>
        <Spacer space='10px' />
        <Section.OnTrackWrapper>
          {baseCase.isOnTrack ? (
            <SVGWrapper
              svg={OnTrack}
              fill='#7a8e96'
              hoverFill='#053240'
              size='large'
              cursor='default'
              style={{ marginRight: 4 }}
            />
          ) : (
            <SVGWrapper
              svg={Warning}
              fill='#7a8e96'
              hoverFill='#053240'
              size='large'
              cursor='default'
              style={{ marginRight: 4 }}
            />
          )}
          <Section.OnTrackRow isOnTrack={baseCase.isOnTrack}>
            Plan:{' '}
            <Section.OnTrackText>
              {baseCase.isOnTrack ? 'ON TRACK' : 'OFF TRACK'}
            </Section.OnTrackText>
          </Section.OnTrackRow>
        </Section.OnTrackWrapper>
        <Spacer space='12px' />

        <Section.IndentedSection>
          <Section.GoalStatisticBox>
            <Section paddingRight='100px'>
              <div>Your income goal</div>
              <Spacer space='6px' />
              <FillGauge id='incomeGoal' fill={retirementIncomeGoalPct} />
            </Section>
            <Section>
              <div>How you're doing</div>
              <Spacer space='6px' />
              <FillGauge id='income' fill={baseCase.retirementIncomeGoal} />
            </Section>
          </Section.GoalStatisticBox>
        </Section.IndentedSection>
        <Spacer space='12px' />

        <Section.IndentedSection>
          {retirementIncomeGoalPct === baseCase.retirementIncomeGoal ||
          retirementIncomeGoalPct < baseCase.retirementIncomeGoal ? (
            <Section.SecondaryText>
              You are {retirementIncomeGoalPct === baseCase.retirementIncomeGoal && 'meeting'}{' '}
              {retirementIncomeGoalPct < baseCase.retirementIncomeGoal && 'exceeding'} your goal of{' '}
              {retirementIncomeGoalPct}% — great job!
            </Section.SecondaryText>
          ) : (
            <Section.SecondaryText>
              Let's see what you can do to get to your goal of {retirementIncomeGoalPct}%.
            </Section.SecondaryText>
          )}
        </Section.IndentedSection>
        <Spacer space='30px' />
      </Section>
    )
  }

  return (
    <Section>
      <Section.SectionTitle>You & your income need</Section.SectionTitle>
      <Spacer space='10px' />
      <Section.OnTrackWrapper>
        {_.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountNeeded', 0) <
        _.get(spendingBaseCases, 'averageMarketSpendingAdvice.monthlyIncome', 0) ? (
          <SVGWrapper
            svg={OnTrack}
            fill='#7a8e96'
            hoverFill='#053240'
            size='large'
            cursor='default'
            style={{ marginRight: 4 }}
          />
        ) : (
          <SVGWrapper
            svg={Warning}
            fill='#7a8e96'
            hoverFill='#053240'
            size='large'
            cursor='default'
            style={{ marginRight: 4 }}
          />
        )}
        <Section.OnTrackRow
          isOnTrack={
            _.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountNeeded', 0) <
            _.get(spendingBaseCases, 'averageMarketSpendingAdvice.monthlyIncome', 0)
          }>
          Plan:{' '}
          <Section.OnTrackText>
            {_.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountNeeded', 0) <
            _.get(spendingBaseCases, 'averageMarketSpendingAdvice.monthlyIncome', 0)
              ? 'ON TRACK'
              : 'OFF TRACK'}
          </Section.OnTrackText>
        </Section.OnTrackRow>
      </Section.OnTrackWrapper>
      <Spacer space='12px' />

      <Section.GoalStatisticWrapper>
        <Section.GoalStatisticBox>
          <Section>
            <div>Amount you need</div>
            <Spacer space='17px' />
            <Section.AmountNeeded>
              {numberToDollars(
                _.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountNeeded', 0),
                true
              )}
            </Section.AmountNeeded>
          </Section>
          <Section>
            <div>Desired monthly spending total</div>
            <div>(Need + Want)</div>
            <Spacer space='6px' />
            <Section.AmountNeeded>
              {numberToDollars(
                _.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountDesired', 0),
                true
              )}
            </Section.AmountNeeded>
          </Section>
          <Section>
            <div>How you're doing, on average</div>
            <Spacer space='17px' />
            <Section.AmountNeeded>
              {numberToDollars(
                _.get(spendingBaseCases, 'averageMarketSpendingAdvice.monthlyIncome', 0),
                true
              )}
            </Section.AmountNeeded>
          </Section>
        </Section.GoalStatisticBox>
        <Spacer space='12px' />

        {_.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountNeeded', 0) ===
          _.get(spendingBaseCases, 'averageMarketSpendingAdvice.monthlyIncome', 0) && (
          <Section.SecondaryText>
            It looks like you can meet your income need — great job!
          </Section.SecondaryText>
        )}

        {_.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountNeeded', 0) <
          _.get(spendingBaseCases, 'averageMarketSpendingAdvice.monthlyIncome', 0) && (
          <Section.SecondaryText>
            It looks like you can meet your income need and satisfy at least part of your want —
            great job!
          </Section.SecondaryText>
        )}

        {_.get(spendingModifiedCases, 'spendingScenario.budget.dollarAmountNeeded', 0) >
          _.get(spendingBaseCases, 'averageMarketSpendingAdvice.monthlyIncome', 0) && (
          <Section.SecondaryText>
            Let's see what you can do to get closer to meeting your income need.
          </Section.SecondaryText>
        )}
      </Section.GoalStatisticWrapper>

      <Spacer space='30px' />
    </Section>
  )
}

export default inject('store')(observer(IncomeGoal))
