import { css } from '@emotion/react'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'

import { API, API_URL } from '../../../api'
import { WizardStep, Spacer, HelpIcon, TooltipText, TextInput } from '../../../components'
import { reduceValidationError } from '../../../utils'
import { Heading, Text, SubHeading } from '../elements/styled'
import { FlexRow, EmailContainer, IndentContainer } from './ConfirmEmailStyles'

const schema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email('Valid email is required')
    .max(128, 'Max email length is 128'),
})

class ConfirmEmailForm extends Component {
  confirmEmailConfig = () => {
    const { config } = this.props.store

    const templates = {
      1: config.canTransact
        ? {
            nextPage: 'confirmation',
            shouldPostEmail: false,
            secondaryParagraph: `Your guide will contain not only the allocation we recommend for your ${config.template.planName} account, but also any other savings and investment changes you'll want to make to help ensure you are able to get to the financial future you want.`,
          }
        : {
            nextPage: 'confirmation',
            shouldPostEmail: true,
            secondaryParagraph:
              "Your personalized GuidedChoice Advisory Service Guide contains the savings and investment changes you'll want to make to help ensure you are able to get to the financial future you want.",
          },
      2: {
        nextPage: 'confirmation',
        shouldPostEmail: true,
        secondaryParagraph:
          "Your personalized GuidedChoice Advisory Service Guide contains the savings and investment changes you'll want to make to help ensure you are able to get to the financial future you want.",
      },
    }

    return templates[config.templateId]
  }

  handleBack = () => {
    this.props.history.goBack()
  }

  onSubmit = async ({ email }) => {
    const { history, store, offboarding } = this.props
    const { acceptedCaseId } = store
    const { setEmail } = offboarding
    const { nextPage, shouldPostEmail } = this.confirmEmailConfig()

    if (shouldPostEmail && email) {
      try {
        await API.post(`${API_URL}guide/email`, null, {
          params: { caseId: acceptedCaseId, email },
        })
      } catch (err) {
        console.error(err)
        return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
      }
    }

    setEmail(email || '')
    history.push(`/implement/${nextPage}`)
  }

  validate = values =>
    schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))

  render() {
    const { store } = this.props
    const { config } = store
    const { displayName } = store.person
    const { email } = store.contactInfo
    const { secondaryParagraph } = this.confirmEmailConfig()

    return (
      <Form
        onSubmit={this.onSubmit}
        validate={this.validate}
        initialValues={{ email }}
        render={({ handleSubmit, submitting, submitError }) => (
          <WizardStep
            onBackClick={this.handleBack}
            onNextClick={handleSubmit}
            disableNextButton={submitting}
            disableBackButton={submitting}>
            <Heading>Let's put your plan into action</Heading>

            <Spacer space='24px' />

            <IndentContainer>
              <Text>
                {displayName}, you don't pay any fees for the advice you receive as a member of the{' '}
                {config.template.planName}. The state has taken care of that on your behalf.
              </Text>

              <Spacer space='20px' />

              <EmailContainer>
                <SubHeading>Confirm email</SubHeading>

                <Spacer space='16px' />

                <Text>
                  Confirm the email address to which you would like your GuidedChoice Advisory
                  Service Guide sent. (This will not change the email GuidedChoice or{' '}
                  {config.template.supportLinkOverName} has on record for you, if different.)
                </Text>

                <Spacer space='12px' />

                <Text>{secondaryParagraph}</Text>

                <Spacer space='16px' />

                <FlexRow>
                  <Text>Email</Text>
                  {'\u00A0\u00A0\u00A0'}
                  <Field
                    name='email'
                    render={({ input, meta }) => (
                      <TextInput
                        name={input.name}
                        value={input.value}
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        expanded={input.value}
                        showError={meta.touched}
                        error={meta.error}
                        css={css`
                          max-width: 400px;
                        `}
                      />
                    )}
                  />
                  {'\u00A0\u00A0\u00A0\u00A0\u00A0'}
                  <HelpIcon
                    helpLabel="I don't have an email address"
                    tooltip={TooltipText.noEmailAddress()}
                  />
                </FlexRow>
                <Spacer space='2px' />
              </EmailContainer>

              <div
                css={css`
                  padding-top: 20px;
                  color: crimson;
                `}>
                {!submitting && submitError}
              </div>
            </IndentContainer>
            <Spacer space='12px' />
          </WizardStep>
        )}
      />
    )
  }
}

export default withRouter(inject('store', 'offboarding')(observer(ConfirmEmailForm)))
