import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

Wrapper.Container = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
`

export const Page = styled.div`
  font-family: 'Open Sans';
`
Page.Container = styled.div`
  word-wrap: break-word;
  color: ${p => p.theme.mediumGray};
  max-width: ${p => (p.narrow ? '650px' : p.handy ? '700px' : '800px')};
`
Page.Header = styled.div`
  font-size: 50px;
  line-height: 68px;
  color: ${p => p.theme.darkestGray};
`
Page.Paragraph = styled.div`
  font-size: 14px;
  line-height: 19px;
`
Page.Ul = styled.ul``
Page.Li = styled.li`
  line-height: ${p => (p.small ? '1.5rem' : '27px')};
  font-size: ${p => (p.small ? '14px !important' : '20px')};
`
Page.Tel = styled.a`
  color: ${p => p.theme.primaryColor};
  font-weight: 600;
  text-decoration: none;
`
Page.Link = styled.a`
  color: ${p => p.theme.primaryColor};
  text-decoration: none;
`
Page.SubHeaderDark = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 25px;
  font-weight: 500;
  margin: 30px 0;
`
Page.SubHeaderLight = styled.div`
  font-size: 20px;
  font-weight: 550;
  margin: 30px 0;
`

Page.PrimaryButton = styled.a`
  min-width: 334px;
  display: inline-block;
  background: ${p => p.theme.buttonColor};
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  color: white;
  font-family: 'Open Sans';
  cursor: pointer;
  &:hover {
    background: ${p => p.theme.hoverButtonColor};
  }
  &:active {
    background: ${p => p.theme.activeButtonColor};
  }
  & > div {
    pointer-events: none;
  }
  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }
  & > div:nth-of-type(2),
  div:nth-of-type(3) {
    font-size: 12px;
  }
`

Page.SecondaryButton = styled.a`
  display: inline-block;
  background-color: #4a606a;
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  color: white;
  font-family: 'Open Sans';
  cursor: pointer;
  &:hover {
    background-color: #40525b;
  }
  &:active {
    background-color: #2b373d;
  }
  & > div {
    pointer-events: none;
  }
  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }
  & > div:nth-of-type(2),
  div:nth-of-type(3) {
    font-size: 12px;
  }
`

Page.CancelButton = styled.a`
  display: inline-block;
  border: 1px solid #7a8e96;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  color: #7a8e96;
  font-family: 'Open Sans';
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
  &:active {
    background-color: #d9d9d9;
  }
  & > div {
    pointer-events: none;
  }
  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }
  & > div:nth-of-type(2),
  div:nth-of-type(3) {
    font-size: 12px;
  }
`

Page.ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  margin: 36px 0;
  & > a {
    margin: 1rem;
  }
`

Page.Text = styled.div`
  width: 200px;
  line-height: 1.5rem;
`

Page.Section = styled.div`
  display: flex;
  flex-wrap: wrap;
`

Page.LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  padding-right: 20px;
`

Page.RightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;
`

/// ////////////////////////////////////////////////////////
// Company Stock
/// ////////////////////////////////////////////////////////

Page.CompanyStockTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 50px;
  line-height: 76px;
`
Page.CompanyStockWrapper = styled.div`
  padding-left: 20px;
`
Page.CompanyStockStockTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 36px;
  line-height: 76px;
`
Page.CompanyStockStockParagraph = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 20px;
  line-height: 27px;
`
Page.CompanyStockStockPercentageToSell = styled.div`
  color: #038ab7;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
`
Page.CompanyStockRiskText = styled.div`
  color: #4a4a4a;
  font-size: 24px;
  line-height: 33px;
`
Page.CompanyStockCurrentStock = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 20px;
  line-height: 27px;
  text-align: center;
`
Page.SVGWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
`
Page.SVGColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
Page.SVGContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-left: 43px;
  margin-top: 20px;
  margin-bottom: 10px;
`
Page.SVGCurrentTitle = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 20px;
  line-height: 27px;
`
Page.SVGImplementedTitle = styled.div`
  color: #038ab7;
  font-size: 20px;
  line-height: 27px;
`
/// ////////////////////////////////////////////////////////
// Accept managed account
/// ////////////////////////////////////////////////////////

Page.AcceptTitle = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: Aleo;
  font-size: 50px;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
`
Page.AcceptParagraph = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin-right: 20px;
  max-width: 536px;
`
Page.AcceptDisclaimerWrapper = styled.div`
  max-width: 830px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`
Page.AcceptDivider = styled.div`
  border-top: 1px solid #7a8e96;
  margin: 20px;
`
Page.AcceptSectionTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  padding-top: 20px;
  font-size: 20px;
  text-align: center;
`
Page.AcceptSection = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 830px;
  margin: 0 auto;
  flex-wrap: wrap;

  & > div {
    max-width: 176px;
    text-align: center;
  }
`
Page.AcceptIcon = styled.div`
  width: 200px;
  margin: -25px 0;
  & > div {
    width: 200px;
  }
`
Page.SectionTitle = styled.div`
  color: ${p => p.theme.primaryColor};
  padding-top: 20px;
  font-size: 20px;
`
Page.IconAndTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
Page.AcceptPrice = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  font-weight: bold;
`
Page.AcceptPriceDisclaimer = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 15px;
  line-height: 27px;
  text-align: center;
`
Page.AcceptDisclaimer = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 15px;
  line-height: 27px;
  text-align: center;
`

/// ////////////////////////////////////////////////////////
// Congratulations
/// ////////////////////////////////////////////////////////

Page.Heading = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2.3rem;
  width: 100%;
  color: #222;
  -webkit-transition: 0.75s;
  transition: 0.75s;
  font-weight: 600;
  line-height: 1.25;
  word-break: break-word;
  margin: 40px auto;
  text-align: center;
  line-height: 60px;
`
Page.CongratsUl = styled.ul`
  padding-left: 20px;
  & > li {
    margin: 10px 0;
  }
`
Page.SubHeader = styled.div`
  color: #4a606a;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  line-height: 1.25;
`
Page.CongratsCustomLink = styled(Link)`
  cursor: pointer;
  color: ${p => p.theme.primaryColor};
  text-decoration: none;
`
/// ////////////////////////////////////////////////////////
// Congratulations
/// ////////////////////////////////////////////////////////

Page.EmailContainer = styled.div`
  border: ${p => p.theme.emailContainerBorder};
  padding: 1rem;
  border-radius: 6px;
  margin: 20px 0;
`
/// ////////////////////////////////////////////////////////
// No Guide
/// ////////////////////////////////////////////////////////

export const NoGuideStyle = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

NoGuideStyle.Container = styled.div`
  padding: 16px;
  position: relative;
  width: 100%;
  color: ${p => p.theme.offboardingPageContainerTextColor};
`

///////////////////////////////////////////////////////////
// Save Email Download Guide
///////////////////////////////////////////////////////////

const Layout = styled.div`
  font-family: 'Open Sans';
  font-weight: 500;
  color: #4a606a;
`

Layout.SelectionTitle = styled(Layout)`
  color: #022a3a;
  font-size: 24px;
`

Layout.SelectionBox = styled(Layout)`
  padding: 16px 20px;
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
`

export const StyledSelectionBox = styled(Layout.SelectionBox)`
  max-width: 660px;
  margin: 28px 24px;
`

export const StyledReceiveEmail = styled(Layout.SelectionTitle)``

const CheckboxLabelContainer = styled('div')`
  margin: 12px 0 0;

  label {
    display: inline-block;
    margin: 0;
  }
`

export const StyledConfirmEmail = styled(CheckboxLabelContainer)`
  display: flex;
  align-items: baseline;
  margin-bottom: 12px;

  font-size: 14px;
  line-height: 18px;

  label {
    display: inline-block;
  }
`
