import { Component } from 'react'

import GoalForm from './GoalForm'
import { Page } from '../elements'

class AddGoal extends Component {
  backToDashboard = () => {
    this.props.history.push('/goals')
  }

  render() {
    return (
      <Page>
        <Page.MainTitle>Let's get your goal off the ground</Page.MainTitle>
        <GoalForm handleClose={this.backToDashboard} />
      </Page>
    )
  }
}

export default AddGoal
