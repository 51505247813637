import { toJS } from 'mobx'
import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'
import { API } from '../api'

const { model, array, maybeNull, number, string, boolean, optional, frozen } = types

const SocialSecurity = model({
  participantMonthlyPaymentBeforeTax: maybeNull(number),
  spouseMonthlyPaymentBeforeTax: maybeNull(number),
  participantMonthlyPaymentAfterTax: maybeNull(number),
  spouseMonthlyPaymentAfterTax: maybeNull(number),
  workerAge: maybeNull(number),
  spouseAge: maybeNull(number),
})

const SpendingByAges = model({
  year: maybeNull(number),
  age: maybeNull(number),
  investmentIncome: maybeNull(number),
  lifetimeIncome: maybeNull(number),
})

const Primary = model({
  fullRetirementAgeMonth: maybeNull(number),
  fullRetirementAgeYear: maybeNull(number),
  lifeExpectancyAge: maybeNull(number),
  lifeExpectancyYear: maybeNull(number),
  retAge: maybeNull(number),
  retirementYear: maybeNull(number),
})

const Spouse = model({
  fullRetirementAgeMonth: maybeNull(number),
  fullRetirementAgeYear: maybeNull(number),
  lifeExpectancyAge: maybeNull(number),
  lifeExpectancyYear: maybeNull(number),
  retAge: maybeNull(number),
  retirementYear: maybeNull(number),
})

const OtherLifetimeIncome = model({
  participantMonthlyIncome: maybeNull(number),
  spouseMonthlyIncome: maybeNull(number),
})

const Pension = model({})

const Bequest = model({
  totalBequestExpense: maybeNull(number),
  totalEndOfLifeExpense: maybeNull(number),
})

const PrimaryLifetimeInvestmentIncome = model({
  balanceUsedPercent: maybeNull(number),
  costOfInvestment: maybeNull(number),
  monthlyAmount: maybeNull(number),
  recipient: maybeNull(number),
  remainingInvestmentBalance: maybeNull(number),
  startPaymentAge: maybeNull(number),
})

const SpouseLifetimeInvestmentIncome = model({
  balanceUsedPercent: maybeNull(number),
  costOfInvestment: maybeNull(number),
  monthlyAmount: maybeNull(number),
  recipient: maybeNull(number),
  remainingInvestmentBalance: maybeNull(number),
  startPaymentAge: maybeNull(number),
})

const AverageMarketSpendingAdvice = model({
  distributionTaxRate: maybeNull(number),
  monthlyIncome: maybeNull(number),
  monthlyIncomeCannotOutlive: maybeNull(number),
  participantLifeExpectancy: maybeNull(number),
  spouseLifeExpectancy: maybeNull(number),
  socialSecurity: maybeNull(SocialSecurity),
  spendingByAges: array(SpendingByAges),
  scenarioDescription: maybeNull(string),
  caseId: maybeNull(number),
  firstShortfallYear: maybeNull(number),
  portfolioNumber: maybeNull(number),
  otherLifetimeIncome: maybeNull(OtherLifetimeIncome),
  pension: maybeNull(Pension), // clarify
  remainingLegacyAmount: maybeNull(number),
  riskLevel: maybeNull(number),
  standardDeviationInvestment: maybeNull(number),
  survivorAgeAtFirstShortfallYear: maybeNull(number),
  survivorNameAtFirstShortfallYear: maybeNull(string),
  spouse: maybeNull(Spouse),
  primary: maybeNull(Primary),
})

const StrongMarketSpendingAdvice = model({
  distributionTaxRate: maybeNull(number),
  monthlyIncome: maybeNull(number),
  monthlyIncomeCannotOutlive: maybeNull(number),
  participantLifeExpectancy: maybeNull(number),
  spouseLifeExpectancy: maybeNull(number),
  socialSecurity: maybeNull(SocialSecurity),
  spendingByAges: array(SpendingByAges),
  scenarioDescription: maybeNull(string),
  caseId: maybeNull(number),
  firstShortfallYear: maybeNull(number),
  portfolioNumber: maybeNull(number),
  otherLifetimeIncome: maybeNull(OtherLifetimeIncome),
  pension: maybeNull(Pension), // clarify
  remainingLegacyAmount: maybeNull(number),
  riskLevel: maybeNull(number),
  standardDeviationInvestment: maybeNull(number),
  survivorAgeAtFirstShortfallYear: maybeNull(number),
  survivorNameAtFirstShortfallYear: maybeNull(string),
  spouse: maybeNull(Spouse),
  primary: maybeNull(Primary),
})
const WeakMarketSpendingAdvice = model({
  distributionTaxRate: maybeNull(number),
  caseId: maybeNull(number),
  firstShortfallYear: maybeNull(number),
  portfolioNumber: maybeNull(number),
  monthlyIncome: maybeNull(number),
  monthlyIncomeCannotOutlive: maybeNull(number),
  participantLifeExpectancy: maybeNull(number),
  spouseLifeExpectancy: maybeNull(number),
  socialSecurity: maybeNull(SocialSecurity),
  spendingByAges: array(SpendingByAges),
  scenarioDescription: maybeNull(string),
  otherLifetimeIncome: maybeNull(OtherLifetimeIncome),
  pension: maybeNull(Pension), // clarify
  remainingLegacyAmount: maybeNull(number),
  riskLevel: maybeNull(number),
  standardDeviationInvestment: maybeNull(number),
  survivorAgeAtFirstShortfallYear: maybeNull(number),
  survivorNameAtFirstShortfallYear: maybeNull(string),
  spouse: maybeNull(Spouse),
  primary: maybeNull(Primary),
})

const ShortBudget = model({
  dollarAmountNeeded: maybeNull(number),
  dollarAmountDesired: maybeNull(number),
})
const Disbursement = model({
  amount: maybeNull(number),
  endDate: maybeNull(string),
  inflationAdjustAmt: maybeNull(string),
  inflationAdjusted: maybeNull(boolean),
  paid: maybeNull(boolean),
  startDate: maybeNull(string),
})
const SpendingGoal = model({
  disbursements: array(Disbursement),
  name: maybeNull(string),
})

const SelectedPension = model({
  monthlyPensionIncome: maybeNull(number),
  pensionName: maybeNull(string),
  pensionStartAge: maybeNull(number),
  survivorFraction: maybeNull(number),
})

const SpendingScenario = model({
  budget: maybeNull(ShortBudget),
  emergencyFund: maybeNull(number),
  oneTimeExpense: maybeNull(Bequest),
  scenarioDescription: maybeNull(string),
  scenarioName: maybeNull(string),
  selectedEmployerPension: maybeNull(SelectedPension),
  spendingGoals: array(SpendingGoal),
})

const CompanyStocks = model({
  aggregatedPositionIds: array(number),
  amountToSell: maybeNull(number),
  percentToSell: maybeNull(number),
  positionId: maybeNull(number),
  restricted: maybeNull(boolean),
  securityId: maybeNull(number),
  securityName: maybeNull(string),
  totalValue: maybeNull(number),
})

const SpendingCases = model({
  averageMarketSpendingAdvice: maybeNull(AverageMarketSpendingAdvice),
  strongMarketSpendingAdvice: maybeNull(StrongMarketSpendingAdvice),
  weakMarketSpendingAdvice: maybeNull(WeakMarketSpendingAdvice),
  companyStocks: array(CompanyStocks),
  currentCompanyStockWeightPercentage: maybeNull(number),
  keptCompanyStockWeightPercentage: maybeNull(number),
  primaryLifetimeInvestmentIncome: maybeNull(PrimaryLifetimeInvestmentIncome),
  spouseLifetimeInvestmentIncome: maybeNull(SpouseLifetimeInvestmentIncome),
  scenarioDescription: maybeNull(string),
  scenarioName: maybeNull(string),
  caseId: maybeNull(number),
  spendingScenario: maybeNull(SpendingScenario),
})

const SSIBenefits = model({
  birthDate: maybeNull(string),
  fullRetirementAgeMonth: maybeNull(number),
  fullRetirementAgeMonthlyAmount: maybeNull(number),
  fullRetirementAgeYear: maybeNull(number),
  includeSsi: maybeNull(boolean),
  monthlyAmount: maybeNull(number),
  paymentBeginAge: maybeNull(number),
  personId: maybeNull(number),
  receivingSsi: maybeNull(number),
  useGcEstimate: maybeNull(number),
})

const InstitutionalPensions = model({
  pensionId: maybeNull(number),
  survivorPensionId: maybeNull(number),
})

const PensionAccount = model({
  accountId: maybeNull(number),
  cola: maybeNull(number),
  colaWithCpi: maybeNull(string),
  distOption: maybeNull(number),
  editable: maybeNull(boolean),
  id: maybeNull(number),
  lumpsumreceived: maybeNull(string),
  monthlyPensionIncome: maybeNull(number),
  ownerId: maybeNull(number),
  paymentType: maybeNull(string),
  pensionName: maybeNull(string),
  pensionStartAge: maybeNull(number),
  survivorFraction: maybeNull(number),
  type: maybeNull(string),
})

const Annuity = model({
  annuityName: maybeNull(string),
  annuityStartAge: maybeNull(number),
  beginYear: maybeNull(number),
  cost: maybeNull(number),
  distOption: maybeNull(number),
  endYear: maybeNull(number),
  futureCost: maybeNull(number),
  id: maybeNull(number),
  monthlyIncome: maybeNull(number),
  personId: maybeNull(number),
  survivorFraction: maybeNull(number),
  taxtype: maybeNull(number),
  type: maybeNull(string),
})

const Retirement = model({
  annualIncome: maybeNull(number),
  durationStartingAge: maybeNull(number),
  durationStoppingAge: maybeNull(number),
  id: maybeNull(number),
  includeSsiEstimate: maybeNull(number),
  planningWorkPartTime: maybeNull(number),
})

const SpendDownStore = model({
  spendingBaseCases: maybeNull(SpendingCases),
  spendingModifiedCases: maybeNull(SpendingCases),
  spendingRecommendedCases: maybeNull(SpendingCases),
  modifiedPlus: maybeNull(boolean),
  error: maybeNull(boolean),
  firstTime: true,
  budgetResult: frozen({}),
  bequest: frozen({}),
  spouseSSIBenefitsResult: maybeNull(SSIBenefits),
  primarySSIBenefitsResult: maybeNull(SSIBenefits),
  workerBenefits: optional(frozen({}), {}),
  primaryOptimizedSSI: maybeNull(SSIBenefits),
  spouseOptimizedSSI: maybeNull(SSIBenefits),
  selectedInstitutionalPension: optional(InstitutionalPensions, {}),
  allPensions: array(PensionAccount),
  isSpendDownSet: false,
  modifiedValues: frozen({}),
  remainingInvestmentBalance: maybeNull(number),
  primaryRetirementStatus: maybeNull(Retirement),
  spouseRetirementStatus: maybeNull(Retirement),
  annuity: array(Annuity),
})
  .views(self => ({
    get projectedMonthlyRetirementInc() {
      if (_.get(self.spendingModifiedCases, 'averageMarketSpendingAdvice.monthlyIncome', null)) {
        return _.get(self.spendingModifiedCases, 'averageMarketSpendingAdvice.monthlyIncome', null)
      }

      const projectedMonthlyRetirementInc = _.get(
        self.spendingRecommendedCases,
        'averageMarketSpendingAdvice.monthlyIncome',
        null
      )
      return projectedMonthlyRetirementInc
    },
    get allSpendingBaseCases() {
      return self.spendingBaseCases
    },
    get allSpendingModifiedCases() {
      return self.spendingModifiedCases
    },
    get allSpendingRecommendedCases() {
      return self.spendingRecommendedCases
    },
    get isModifiedPlus() {
      return self.modifiedPlus
    },
    get primaryMonthlyAnnuityAmount() {
      return _.get(
        self.spendingModifiedCases,
        'primaryLifetimeInvestmentIncome.monthlyAmount',
        null
      )
    },
    get primaryMonthlyAnnuityStartAge() {
      return _.get(
        self.spendingModifiedCases,
        'primaryLifetimeInvestmentIncome.startPaymentAge',
        null
      )
    },
    get spouseMonthlyAnnuityAmount() {
      return _.get(self.spendingModifiedCases, 'spouseLifetimeInvestmentIncome.monthlyAmount', null)
    },
    get spouseMonthlyAnnuityStartAge() {
      return _.get(
        self.spendingModifiedCases,
        'spouseLifetimeInvestmentIncome.startPaymentAge',
        null
      )
    },
    get budget() {
      return self.budgetResult || {}
    },
    get spouseSSIBenefits() {
      return self.spouseSSIBenefitsResult
    },
    get primarySSIBenefits() {
      return self.primarySSIBenefitsResult
    },
    get isFirstTimeToSpendDown() {
      return self.firstTime
    },
    get monthlyIncomeCannotOutlive() {
      return _.get(
        self.spendingModifiedCases,
        'averageMarketSpendingAdvice.monthlyIncomeCannotOutlive',
        null
      )
    },
  }))
  .actions(self => ({
    setInitialInfoForSpendDown: flow(function* () {
      const { otherAccounts } = self
      yield Promise.all([
        self.getBudget(),
        self.getBequest(),
        self.getRecommendedAdvicesForSpending(),
        self.getBaseAdvicesForSpending(),
        self.getSSBenefits(),
        self.getFirstTimeToSpendDown(),
        self.getSurvivorPensionOptions(),
        self.getSurvivorPension(),
        self.getAllPensions(),
        self.getAllScenarios(),
        self.makeAnnuityObject(otherAccounts),
      ])
      yield self.getEmergencyFunds()
      self.isSpendDownSet = true
    }),
    setModifiedPlus() {
      self.modifiedPlus = true
    },
    setRemainingInvestmentBalance(newBalance) {
      self.remainingInvestmentBalance = newBalance
    },
    getBaseAdvicesForSpending: flow(function* () {
      const spendingBaseCases = yield API.post('spending/advice/processBaseCase')

      self.spendingBaseCases = spendingBaseCases.data
      return spendingBaseCases.data
    }),
    getRecommendedAdvicesForSpending: flow(function* () {
      const spendingRecommendedCases = yield API.post('spending/advice/processRecommendedCase')
      self.spendingRecommendedCases = spendingRecommendedCases.data
      if (self.spendingModifiedCases === null) {
        self.spendingModifiedCases = spendingRecommendedCases.data
      }
      self.portfolioNumber = _.get(
        spendingRecommendedCases,
        'data.averageMarketSpendingAdvice.portfolioNumber',
        null
      )
      self.primaryRetData = _.get(
        spendingRecommendedCases,
        'data.averageMarketSpendingAdvice.primary',
        null
      )
      self.spouseRetData = _.get(
        spendingRecommendedCases,
        'data.averageMarketSpendingAdvice.spouse',
        null
      )
      return spendingRecommendedCases.data
    }),
    processModifiedCase: flow(function* (newValues) {
      const result = yield API.post('spending/advice/processModifiedCase', {
        annuities: null,
        companyStocks: null,
        emergencyFund: null,
        endOfLife: null,
        estimatedTotalMonthlyIncome: null,
        exclusion: null,
        includedGoalsIds: null,
        outOfPlanMonthlySavings: null,
        participantExpectedLifespan: null,
        portfolioNumber: self.portfolioNumber,
        primary: self.primaryRetData,
        primaryLifetimeInvestmentIncome: null,
        primaryRetirementWork: null,
        primarySocialSecurityIncome: null,
        retirementDollarDesired: null,
        retirementDollarNeeded: null,
        retirementIncomeGoal: null,
        riskLevel: null,
        spouse: self.spouseRetData,
        spouseExpectedLifespan: null,
        spouseLifetimeInvestmentIncome: null,
        spouseRetirementWork: null,
        spouseSocialSecurityIncome: null,
        stockOptions: null,
        ...self.modifiedValues,
        ...newValues,
      })
      self.modifiedValues = {
        ...self.modifiedValues,
        ...newValues,
      }
      self.spendingModifiedCases = result.data
      return result.data
    }),
    processModifiedCaseWithoutSaving: flow(function* (newValues) {
      const result = yield API.post('spending/advice/processModifiedCase', {
        annuities: null,
        companyStocks: null,
        emergencyFund: null,
        endOfLife: null,
        estimatedTotalMonthlyIncome: null,
        exclusion: null,
        includedGoalsIds: null,
        outOfPlanMonthlySavings: null,
        participantExpectedLifespan: null,
        portfolioNumber: self.portfolioNumber,
        primary: self.primaryRetData,
        primaryLifetimeInvestmentIncome: null,
        primaryRetirementWork: null,
        primarySocialSecurityIncome: null,
        retirementDollarDesired: null,
        retirementDollarNeeded: null,
        retirementIncomeGoal: null,
        riskLevel: null,
        spouse: self.spouseRetData,
        spouseExpectedLifespan: null,
        spouseLifetimeInvestmentIncome: null,
        spouseRetirementWork: null,
        spouseSocialSecurityIncome: null,
        stockOptions: null,
        ...self.modifiedValues,
        ...newValues,
      })
      return result.data
    }),
    getPrimaryRetirementStatus: flow(function* () {
      const result = yield API.get('retirement-work')

      if (result.data) {
        self.primaryRetirementStatus = result.data
      } else {
        self.primaryRetirementStatus = {
          annualIncome: null,
          durationStartingAge: null,
          durationStoppingAge: null,
          id: null,
          includeSsiEstimate: null,
          planningWorkPartTime: null,
        }
      }
      return result.data
    }),
    getSpouseRetirementStatus: flow(function* () {
      const result = yield API.get('retirement-work/spouse')

      if (result.data) {
        self.spouseRetirementStatus = result.data
      } else {
        self.spouseRetirementStatus = {
          annualIncome: null,
          durationStartingAge: null,
          durationStoppingAge: null,
          id: null,
          includeSsiEstimate: null,
          planningWorkPartTime: null,
        }
      }
      return result.data
    }),
    savePrimaryRetirementStatus: flow(function* ({
      annualIncome,
      durationStartingAge,
      durationStoppingAge,
      includeSsiEstimate,
      planningWorkPartTime,
    }) {
      planningWorkPartTime = planningWorkPartTime === true ? 1 : 0
      includeSsiEstimate = includeSsiEstimate === true ? 1 : 0

      if (!self.person) {
        yield self.getPerson()
      }

      const result = yield API.post('retirement-work', {
        annualIncome: annualIncome || 0,
        durationStartingAge: durationStartingAge || null,
        durationStoppingAge: durationStoppingAge || null,
        id: self.primaryRetirementStatus.id || null,
        includeSsiEstimate,
        planningWorkPartTime,
        accountId: self.account.id,
        personId: self.person.id,
      })

      self.primaryRetirementStatus = result.data
      return result.data
    }),
    saveSpouseRetirementStatus: flow(function* ({
      annualIncome,
      durationStartingAge,
      durationStoppingAge,
      id,
      includeSsiEstimate,
      planningWorkPartTime,
    }) {
      planningWorkPartTime = planningWorkPartTime === true ? 1 : 0
      includeSsiEstimate = includeSsiEstimate === true ? 1 : 0

      if (!self.spouse) {
        yield self.getSpouse()
      }

      const result = yield API.post('retirement-work/spouse', {
        annualIncome: annualIncome || 0,
        durationStartingAge: durationStartingAge || null,
        durationStoppingAge: durationStoppingAge || null,
        id: id || null,
        includeSsiEstimate,
        planningWorkPartTime,
        accountId: self.account.id,
        personId: self.spouse.id,
      })
      self.spouseRetirementStatus = result.data
      return result.data
    }),

    getWorkerBenefits: flow(function* () {
      if (!self.person) {
        yield self.getPerson()
      }
      const result = yield API.get('spend-down/worker-benefits', {
        params: { personId: self.person.id },
      })
      self.workerBenefits = result.data
      return result.data
    }),
    getAllPensions: flow(function* () {
      const result = yield API.get('pension')
      self.allPensions = result.data
      return result.data
    }),
    getSurvivorPensionOptions: flow(function* () {
      const result = yield API.get('survivor-pension/options')
      self.pensionOptions = result.data
      return result.data
    }),
    getSurvivorPension: flow(function* () {
      const result = yield API.get('survivor-pension')
      self.selectedInstitutionalPension = result.data || {}
      return result.data
    }),
    savePensionOption: flow(function* ({ pensionId }) {
      const result = yield API.post('survivor-pension', {
        pensionId,
        survivorPensionId: 0,
      })
      self.selectedInstitutionalPension = result.data
      return result.data
    }),
    getBudget: flow(function* () {
      const result = yield API.get('monthly-expense')
      self.budgetResult = result.data
      return result.data
    }),
    getBequest: flow(function* () {
      const result = yield API.get('onetime-expense')
      self.bequest = result.data
      return result.data
    }),
    saveBequest: flow(function* ({ totalBequestExpense, totalEndOfLifeExpense }) {
      const result = yield API.post('onetime-expense', {
        totalBequestExpense,
        totalEndOfLifeExpense,
      })
      self.bequest = result.data
      return result.data
    }),
    getFirstTimeToSpendDown: flow(function* () {
      const firstTimeResult = yield API.get('user-settings')
      self.firstTime = firstTimeResult.data.firstTime
      return firstTimeResult.data
    }),
    putFirstTimeToSpendDownFalse: flow(function* () {
      const result = yield API.put('user-settings', { firstTime: false })
      self.firstTime = false
      return result.data
    }),
    postBudget: flow(function* ({
      accountId,
      childcare,
      dollarAmountDesired,
      dollarAmountNeeded,
      grocery,
      housing,
      medical,
      otherExpenses,
      personId,
      totalDebts,
      transportation,
    }) {
      const result = yield API.post('monthly-expense', {
        accountId,
        childcare,
        dollarAmountDesired,
        dollarAmountNeeded,
        grocery,
        housing,
        medical,
        otherExpenses,
        personId,
        totalDebts,
        transportation,
      })
      self.budgetResult = result.data
      return result.data
    }),

    saveSpendownScenario: flow(function* (scenario) {
      const result = yield API.post(
        'saved-scenario/spending',
        {
          ...scenario,
        },
        { params: { personId: self.person.id } }
      )
      return result
    }),
    getScenario: flow(function* (scenarioName) {
      const result = yield API.get('saved-scenario/spending', {
        params: {
          scenarioName,
        },
      })
      return result.data
    }),
    getAllScenarios: flow(function* () {
      if (_.isEmpty(self.allSpendingBaseCases)) {
        yield self.getBaseAdvicesForSpending()
      }
      const result = yield API.get('saved-scenario/spending/past-seven-days')
      let scenarios = []
      if (!_.isEmpty(self.allSpendingBaseCases)) {
        scenarios.push({
          ...toJS(self.allSpendingBaseCases),
          spendingScenario: {
            ...toJS(self.allSpendingBaseCases.spendingScenario),
            scenarioName: 'GuidedChoice initial advice',
          },
        })
      }
      if (result.data) {
        scenarios = [...scenarios, ...result.data]
      }
      self.allSpendingScenarios = [...scenarios]
      return result.data
    }),
    saveEmergencyFunds: flow(function* ({ caseId, amount }) {
      const result = yield API.post('spend-down/emergency-fund', {}, { params: { caseId, amount } })
      self.emergencyFund = result.data || {}
      return result.data
    }),
    getEmergencyFunds: flow(function* () {
      const res = yield API.get('spend-down/emergency-fund/last-accepted')
      self.emergencyFund = res.data || {}
    }),
    getMediaIndex: flow(function* () {
      const result = yield API.get('media-index/documents')
      return result.data
    }),
    getBudgetPDF: flow(function* (url) {
      const res = yield API.get(`general-documents/${url}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
        },
      })
      return res
    }),
    getBudgetEXCEL: flow(function* (url) {
      const res = yield API.get(`general-documents/${url}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      return res
    }),
    setSpouseLifetimeInvestmentIncome(spouseLifetimeInvestmentIncome) {
      self.spouseLifetimeInvestmentIncome = spouseLifetimeInvestmentIncome
    },
    setPrimaryLifetimeInvestmentIncome(primaryLifetimeInvestmentIncome) {
      self.primaryLifetimeInvestmentIncome = primaryLifetimeInvestmentIncome
    },
    getAnnuities: flow(function* () {
      const result = yield API.get('annuity')
      return result.data
    }),
    deletePrimaryMonthlyAnnuity: flow(function* ({ accountsBalance }) {
      const result = yield self.processModifiedCase({ primaryLifetimeInvestmentIncome: null })
      self.setRemainingInvestmentBalance(
        accountsBalance -
          _.get(self, 'spouseLifetimeInvestmentIncome.remainingInvestmentBalance', 0)
      )
      self.setPrimaryLifetimeInvestmentIncome({})
      return result.data
    }),
    deleteSpouseMonthlyAnnuity: flow(function* ({ accountsBalance }) {
      const result = yield self.processModifiedCase({ spouseLifetimeInvestmentIncome: null })
      self.setRemainingInvestmentBalance(
        accountsBalance -
          _.get(self, 'primaryLifetimeInvestmentIncome.remainingInvestmentBalance', 0)
      )
      self.setSpouseLifetimeInvestmentIncome({})
      return result.data
    }),
    getSpendingCaseById: flow(function* (caseId) {
      const result = yield API.get('offboard/spending/getCaseById', {
        params: {
          caseId,
        },
      })
      self.setSpendingAcceptedCaseId(caseId)
      self.setSpendingAcceptedCase(result.data)
      return result.data
    }),
    updateSpendingCaseById: flow(function* () {
      const result = yield API.post('offboard/updateAcceptedCase', null, {
        headers: { 'content-type': 'application/json' },
        params: { caseId: self.spendingAcceptedCaseId },
      })
      return result.data
    }),
    setSpendingAcceptedCaseId: id => {
      self.spendingAcceptedCaseId = id
    },
    setSpendingAcceptedCase: model => {
      self.spendingAcceptedCase = model || {}
    },
    getAnnuityInfo: flow(function* (id) {
      const result = yield API.get(`annuity/${id}`)
      return result.data
    }),
    makeAnnuityObject: flow(function* (otherAccounts) {
      const otherAnnuities = otherAccounts.filter(account => account.type === 'Annuity')
      const annuities = []
      for (let i = 0; i < otherAnnuities.length; i++) {
        const newAnnuity = yield self.getAnnuityInfo(otherAnnuities[i].id)
        annuities.push(newAnnuity)
      }
      self.annuity = annuities
      return annuities
    }),
  }))

export default SpendDownStore
