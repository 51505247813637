import { Component } from 'react'
import styled from '@emotion/styled'
import * as d3 from 'd3'
import PropTypes from 'prop-types'

const StyledChartContainer = styled.div`
  width: ${p => (p.width ? p.width : '150px')};
`

export default class StackedChart extends Component {
  static defaultProps = {
    width: 70,
    height: 275,
    barHeightPercentage: 1,
    currentPercentage: 0,
  }

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    currentPercentage: PropTypes.number,
    barHeightPercentage: PropTypes.number,
  }

  upperRoundedRect = (x, y, width, height, radius) => {
    return `M ${x} ${y} 
        v -${Math.max(height - radius, 0)} 
        a ${radius} ${radius} 0 0 1 ${radius} -${radius} 
        h ${width - 2 * radius} 
        a ${radius} ${radius} 0 0 1 ${radius} ${radius} 
        v ${Math.max(height - radius, 0)} z`
  }

  // (x,y) coordinates starts at upper right corner
  lowerRoundedRect = (x, y, width, height, radius) => {
    return `M ${x} ${y} 
        v ${Math.max(height - radius, 0)} 
        a ${radius} ${radius} 0 0 0 ${radius} ${radius} 
        h ${width - 2 * radius} 
        a ${radius} ${radius} 0 0 0 ${radius} -${radius} 
        v -${Math.max(height - radius, 0)} z`
  }

  roundedRect = (x, y, width, height, radius) => {
    return `M ${x} ${y - radius} 
    v -${Math.max(height - 2 * radius, 0)} 
    a ${radius} ${radius} 0 0 1 ${radius} -${radius} 
    h ${width - 2 * radius} 
    a ${radius} ${radius} 0 0 1 ${radius} ${radius}  
    v ${height - 2 * radius}
    a ${radius} ${radius} 0 0 1 -${radius} ${radius}
    h -${Math.max(width - 2 * radius, 0)}
    a ${radius} ${radius} 0 0 1 -${radius} -${radius}
    z`
  }

  componentDidMount() {
    this.createBarChart()
  }

  componentDidUpdate() {
    this.resetChart()
    this.createBarChart()
  }

  resetChart() {
    const node = this.node
    d3.select(node).selectAll('*').remove()
  }

  createBarChart = () => {
    let { currentPercentage, barHeightPercentage, width, height } = this.props

    currentPercentage = currentPercentage || 0
    barHeightPercentage = Math.max(barHeightPercentage || 1, 0.08)

    const node = this.node
    const borderSize = 3
    const barWidth = Math.max(width - width * 0.2, width - 70)
    const innerWidth = barWidth - borderSize * 2
    const barHeight = barHeightPercentage * height
    const innerHeight = barHeight - borderSize * 2
    const borderRadius = 6

    const currentHeight = Math.min(Math.max(currentPercentage * height, borderSize), innerHeight)

    // Gray border
    d3.select(node)
      .append('path')
      .attr('d', this.roundedRect(0, height, barWidth, barHeight, borderRadius))
      .style('fill', '#8599A0')

    // Shadow
    d3.select(node)
      .append('path')
      .attr('d', ` M ${barWidth} ${height} v -${currentHeight} h ${width - barWidth} z`)
      .style('fill', '#DEE6E8')

    // Light green stripe background
    d3.select(node)
      .append('path')
      .attr(
        'd',
        this.roundedRect(
          borderSize,
          height - borderSize,
          barWidth - borderSize * 2,
          innerHeight,
          borderRadius
        )
      )
      .style('fill', '#94DB71')

    // Define Stripe pattern
    d3.select(node)
      .append('defs')
      .append('pattern')
      .attr('id', 'hash4_4')
      .attr('width', 6)
      .attr('height', 6)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(45)')
      .append('rect')
      .attr('width', 1)
      .attr('height', 8)
      .attr('transform', 'translate(0,0)')
      .attr('fill', '#4D9927')

    // Stripes
    d3.select(node)
      .append('path')
      .attr(
        'd',
        this.upperRoundedRect(
          borderSize,
          height - borderSize,
          innerWidth,
          barHeight - 2 * borderSize,
          borderRadius
        )
      )
      .style('fill', 'url(#hash4_4)')

    // Current Value
    d3.select(node)
      .append('path')
      .attr(
        'd',
        this.lowerRoundedRect(
          borderSize,
          height - currentHeight - borderSize,
          innerWidth,
          currentHeight,
          borderRadius
        )
      )
      .style('fill', '#3F8B1E')
  }

  render() {
    const { width, height } = this.props
    return <svg ref={node => (this.node = node)} width={width} height={height} />
  }
}

export class EmptyStackedChart extends Component {
  static defaultProps = {
    width: 70,
    height: 12,
    barHeightPercentage: 1,
    currentPercentage: 0,
  }

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    currentPercentage: PropTypes.number,
    barHeightPercentage: PropTypes.number,
  }

  upperRoundedRect = (x, y, width, height, radius) => {
    return `M ${x} ${y} 
        v -${Math.max(height - radius, 0)} 
        a ${radius} ${radius} 0 0 1 ${radius} -${radius} 
        h ${width - 2 * radius} 
        a ${radius} ${radius} 0 0 1 ${radius} ${radius} 
        v ${Math.max(height - radius, 0)} z`
  }

  // (x,y) coordinates starts at upper right corner
  lowerRoundedRect = (x, y, width, height, radius) => {
    return `M ${x} ${y} 
        v ${Math.max(height - radius, 0)} 
        a ${radius} ${radius} 0 0 0 ${radius} ${radius} 
        h ${width - 2 * radius} 
        a ${radius} ${radius} 0 0 0 ${radius} -${radius} 
        v -${Math.max(height - radius, 0)} z`
  }

  roundedRect = (x, y, width, height, radius) => {
    return `M ${x} ${y - radius} 
    v -${Math.max(height - 2 * radius, 0)} 
    a ${radius} ${radius} 0 0 1 ${radius} -${radius} 
    h ${width - 2 * radius} 
    a ${radius} ${radius} 0 0 1 ${radius} ${radius}  
    v ${height - 2 * radius}
    a ${radius} ${radius} 0 0 1 -${radius} ${radius}
    h -${Math.max(width - 2 * radius, 0)}
    a ${radius} ${radius} 0 0 1 -${radius} -${radius}
    z`
  }

  componentDidMount() {
    this.createBarChart()
  }

  componentDidUpdate() {
    this.resetChart()
    this.createBarChart()
  }

  resetChart() {
    const node = this.node
    d3.select(node).selectAll('*').remove()
  }

  createBarChart = () => {
    let { currentPercentage, barHeightPercentage, width, height } = this.props

    currentPercentage = currentPercentage || 0
    barHeightPercentage = barHeightPercentage || 1

    const node = this.node
    const borderSize = 3
    const barWidth = Math.max(width - width * 0.2, width - 70)
    const innerWidth = barWidth - borderSize * 2
    const barHeight = barHeightPercentage * height
    const innerHeight = barHeight - borderSize * 2
    const borderRadius = 6

    const currentHeight = Math.min(Math.max(currentPercentage * height, borderSize), innerHeight)

    // Gray border
    d3.select(node)
      .append('path')
      .attr('d', this.roundedRect(0, height, barWidth, barHeight, borderRadius))
      .style('fill', '#8599A0')

    // Shadow
    d3.select(node)
      .append('path')
      .attr('d', ` M ${barWidth} ${height} v -${currentHeight} h ${width - barWidth} z`)
      .style('fill', '#DEE6E8')

    // Current Value
    d3.select(node)
      .append('path')
      .attr('d', this.roundedRect(borderSize, height - borderSize, innerWidth, 6, 3))
      .style('fill', '#3F8B1E')
  }

  render() {
    const { width, height } = this.props
    return <svg ref={node => (this.node = node)} width={width} height={height} />
  }
}

export class ChartContainer extends Component {
  render() {
    const { width, children } = this.props
    return <StyledChartContainer width={width}>{children}</StyledChartContainer>
  }
}
