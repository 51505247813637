const BulletLoans = `
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"/>
            <path d="M10 10h30v30H10z"/>
            <g fill="#02769D" fill-rule="nonzero">
                <path d="M30.214 23.6a3.213 3.213 0 0 0 3.218-3.217 3.225 3.225 0 0 0-3.218-3.217 3.225 3.225 0 0 0-3.217 3.217c-.019 1.78 1.436 3.218 3.217 3.218zM21.799 22.116a3.213 3.213 0 0 0 3.218-3.218 3.225 3.225 0 0 0-3.218-3.217 3.225 3.225 0 0 0-3.218 3.217 3.225 3.225 0 0 0 3.218 3.218zM14.621 27.066h-1.725a.745.745 0 0 0-.75.75v5.923c0 .42.332.752.75.752h1.725v-7.425zM37.661 26.106a3.577 3.577 0 0 0-5.194 0l-2.297 2.39-.936.975a2.025 2.025 0 0 1-1.431.626h-4.558a.788.788 0 0 1-.778-.755c-.018-.46.336-.827.76-.827h4.629c.936 0 1.749-.699 1.908-1.655.035-.221.053-.442.053-.68 0-.442-.336-.792-.76-.792h-2.544c-.83 0-1.625-.386-2.473-.809-.884-.441-1.803-.883-2.863-.956a7.596 7.596 0 0 0-2.791.312c-.972.295-1.678 1.196-1.767 2.244h-.017v7.817h13.162c.9 0 1.749-.368 2.403-1.03l5.494-5.72c.3-.331.3-.828 0-1.14z"/>
            </g>
        </g>
    </svg>
`

export default BulletLoans
