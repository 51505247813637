import { Component } from 'react'
import { observer } from 'mobx-react'
import { ProgressBar, Slider, Dialog } from '../../../components'
import { SplitBarChartContainer, SplitBarChart, DoughnutChart } from '../../shared'

class Demo extends Component {
  state = {
    buttonValue: '',
    sliderValue: 0,
    dialog: false,
  }

  handleChange = (name, value) => {
    this.setState({ buttonValue: value })
  }

  handleSliderChange = value => {
    this.setState({ sliderValue: value })
  }

  handleDialog = () => {
    this.setState({ dialog: !this.state.dialog })
  }

  render() {
    const data = [
      {
        name: 'A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock A Stock',
        newValue: {
          dollar: 40,
          percent: 20,
        },
        oldValue: {
          dollar: 30,
          percent: 15,
        },
      },
      {
        name: 'B Stock',
        newValue: {
          dollar: 20,
          percent: 10,
        },
        oldValue: {
          dollar: 60,
          percent: 30,
        },
      },
      {
        name: 'C Stock',
        newValue: {
          dollar: 200,
          percent: 100,
        },
        oldValue: {
          dollar: 200,
          percent: 100,
        },
      },
    ]
    return (
      <div>
        <div>Demo!</div>
        <DoughnutChart />
        <SplitBarChartContainer oldRiskText='below' newRiskText='above' rangeIndex={1}>
          <SplitBarChart data={data} />
        </SplitBarChartContainer>
        <ProgressBar type='linear' />
        <ProgressBar mode='determinate' type='linear' value={40} buffer={80} />
        <ProgressBar type='circular' multicolor />
        <div>{this.state.sliderValue}</div>
        <Slider
          value={this.state.sliderValue}
          onChange={this.handleSliderChange}
          min={0}
          max={150}
          step={1}
        />
        <button onClick={this.handleDialog}>Dialog test</button>
        <Dialog active={this.state.dialog} onOverlayClick={this.handleDialog}>
          <div>
            Test for tooltip replacementTest for tooltip replacementTest for tooltip replacementTest
            for tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacementTest for tooltip replacementTest for
            tooltip replacementTest for tooltip replacement
          </div>
        </Dialog>
      </div>
    )
  }
}

export default observer(Demo)
