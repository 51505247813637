import styled from '@emotion/styled'
import InlineSVG from 'svg-inline-react'

const SIZE = {
  small: '14px',
  medium: '16px',
  mediumLarge: '20px',
  large: '24px',
  xLarge: '40px',
  custom46: '46px',
  custom55: '55px',
  custom120: '120px',
}

const Wrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  height: calc(${p => p.margin} + ${p => p.margin} + ${p => p.foundSize});
  width: calc(${p => p.margin} + ${p => p.margin} + ${p => p.foundSize});

  & .icon {
    height: ${p => p.foundSize};
    width: ${p => p.foundSize};
    display: inline-block;
  }

  svg {
    transition: ${p => p.transition};
    height: ${p => p.foundSize};
    width: ${p => p.foundSize};

    .icon-color {
      fill: ${p => (!p.active ? p.fill : p.activeFill)};
      stroke: ${p => (!p.active ? p.stroke : p.activeStroke)};
    }

    &:hover {
      .icon-color {
        fill: ${p => !p.active && p.hoverFill};
        stroke: ${p => !p.active && p.hoverStroke};
      }
    }

    &:active {
      .icon-color {
        fill: ${p => p.activeFill};
        stroke: ${p => p.activeStroke};
      }
    }
  }
`

const SVGWrapper = props => {
  const { size, svg, onClick } = props

  // if svg === function, then svg is really a functional component
  if (typeof svg === 'function') {
    return (
      <Wrapper {...props} onClick={onClick} foundSize={SIZE[size]}>
        {svg()}
      </Wrapper>
    )
  }

  return (
    <Wrapper {...props} onClick={onClick} foundSize={SIZE[size]}>
      <InlineSVG className='icon' src={svg} element='span' />
    </Wrapper>
  )
}

export default SVGWrapper
