import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import input from '../input/config'
import reduceCSSCalc from 'reduce-css-calc'

const dropdown = {
  valueBorderSize: reduceCSSCalc(`calc(${input.fieldHeight} / 7)`),
  colorWhite: colors.rt.white,
  colorPrimary: colors.rt.primary,
  colorPrimaryContrast: colors.rt.primaryContrast,
  colorDisabled: color.convert(`color(${colors.rt.black} a(26%))`),
  valueHoverBackground: colors.rt.grey200,
  overflowMaxHeight: '45vh',
  valueBorderRadius: reduceCSSCalc(`calc(0.2 * ${variables.unit})`),
}

export default dropdown
