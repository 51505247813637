/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'

const tooltip = 'rt-tooltip-tooltip'

const tooltipActive = 'rt-tooltip-tooltipActive'

const tooltipWrapper = 'rt-tooltip-tooltipWrapper'

const tooltipTop = 'rt-tooltip-tooltipTop'

const tooltipLeft = 'rt-tooltip-tooltipLeft'

const tooltipRight = 'rt-tooltip-tooltipRight'

const tooltipInner = 'rt-tooltip-tooltipInner'

const _tooltip = css`
  display: block;
  font-family: ${ds.get('preferredFont')};
  font-size: ${ds.get('tooltip.fontSize')};
  font-weight: ${ds.get('fontWeight.bold')};
  line-height: ${ds.fs('s')};
  max-width: ${ds.get('tooltip.maxWidth')};
  padding: ${ds.get('tooltip.margin')};
  pointer-events: none;
  position: absolute;
  text-align: center;
  text-transform: none;
  transform: scale(0) translateX(-50%);
  transform-origin: top left;
  transition: ${ds.get('animation.curveDefault')} ${ds.get('tooltip.animationDuration')} transform;
  z-index: ${ds.z('higher')};

  ${ds.get('reset')};

  &.${tooltipActive} {
    transform: scale(1) translateX(-50%);
  }

  &.${tooltipTop} {
    transform: scale(0) translateX(-50%) translateY(-100%);

    &.${tooltipActive} {
      transform: scale(1) translateX(-50%) translateY(-100%);
    }
  }

  &.${tooltipLeft} {
    transform: scale(0) translateX(-100%) translateY(-50%);

    &.${tooltipActive} {
      transform: scale(1) translateX(-100%) translateY(-50%);
    }
  }

  &.${tooltipRight} {
    transform: scale(0) translateX(0) translateY(-50%);

    &.${tooltipActive} {
      transform: scale(1) translateX(0) translateY(-50%);
    }
  }
`

const _tooltipInner = css`
  background: ${ds.get('tooltip.background')};
  border-radius: ${ds.get('tooltip.borderRadius')};
  color: ${ds.get('tooltip.color')};
  display: block;
  padding: ${ds.get('tooltip.padding')};
`

export default {
  tooltip: cx(tooltip, _tooltip),
  tooltipActive,
  tooltipWrapper,
  tooltipTop,
  tooltipLeft,
  tooltipRight,
  tooltipInner: cx(tooltipInner, _tooltipInner),
}
