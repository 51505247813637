import { types, flow, getRoot, getSnapshot } from 'mobx-state-tree'
import _ from 'lodash'

import { API } from '../api'
import { Goal as IncludedGoal } from './Goals'
import { CompanyStock } from './CompanyStock'
import { StockOption } from './StockOptions'

const { model, array, optional, maybeNull, maybe, number, string, boolean } = types

const investmentMixTable = {
  43: { cCash: 30, aCash: 0 },
  29: { cCash: 36, aCash: 5 },
  20: { cCash: 39, aCash: 10 },
  10: { cCash: 44, aCash: 15 },
  7: { cCash: 51, aCash: 21 },
  3: { cCash: 56, aCash: 27 },
  1: { cCash: 67, aCash: 39 },
  0: { cCash: 77, aCash: 46 },
}

const EnginePerson = model({
  ongoingAnnualContributions: maybeNull(number),
  ongoingMonthlyContributions: maybeNull(number),
  postTaxAnnualContribDollars: maybeNull(number),
  postTaxAnnualContributions: maybeNull(number),
  preTaxAnnualContribDollars: maybeNull(number),
  preTaxAnnualContributions: maybeNull(number),
  retAge: maybeNull(number),
  rothAnnualContribDollars: maybeNull(number),
  rothAnnualContributions: maybeNull(number),
  preTaxSSIncome: maybeNull(number),
  preTax457AnnualContributions: maybeNull(number),
  preTax457AnnualContribDollars: maybeNull(number),
  roth457AnnualContributions: maybeNull(number),
  preTax403bAnnualContribDollars: maybeNull(number),
  preTax403bAnnualContributions: maybeNull(number),
})

const EngineCase = model({
  caseId: maybeNull(number),
  currentTotalBalance: maybeNull(number),
  currentTaxRate: maybeNull(number),
  estimatedFutureValue: maybeNull(number),
  estimatedInflationAdjustedValue: maybeNull(number),
  estimatedMonthlyIncomeFromInvestments: maybeNull(number),
  estimatedMonthlySSIncome: maybeNull(number),
  estimatedTotalMonthlyIncome: maybeNull(number),
  estimatedTotalMonthlyIncomeMax: maybeNull(number),
  estimatedTotalMonthlyIncomeMin: maybeNull(number),
  estAftTaxIncomeAtHundredPercent: maybeNull(number),
  name: maybeNull(string),
  otherFinancialGoalsTotal: maybeNull(number),
  outOfPlanMonthlySavings: maybeNull(number),
  primary: maybeNull(EnginePerson),
  projectedMonthlyInflationAdjustedRetirementIncFactor: maybeNull(number),
  projectedMonthlyInflationAdjustedRetirementInc: maybeNull(number),
  projectedMonthlyRetirementInc: maybeNull(number),
  recomMaxRiskLevel: maybeNull(number),
  recomMinRiskLevel: maybeNull(number),
  retirementIncomeGoal: maybeNull(number),
  riskLevel: maybeNull(number),
  riskLevelAll: maybeNull(number),
  spouse: maybeNull(EnginePerson),
  includedGoals: array(IncludedGoal),
  currentAfterTax: maybeNull(number),
  currentAfterTaxIncome: maybeNull(number),
  targetedAftTaxIncome: maybeNull(number),
  companyStocks: maybeNull(array(CompanyStock)),
  stockOptions: maybeNull(array(StockOption)),
  scenarioDescription: maybeNull(string),
  incomeBeforeTax: maybeNull(number),
  taxAmount: maybeNull(number),
  clientContributions: maybeNull(number),
  spouseContributions: maybeNull(number),
}).views(self => ({
  goalIsIncluded(id) {
    return _.includes(self.includedGoalsIds, id)
  },
  get includedGoalsIds() {
    return self.includedGoals.map(goal => goal.id)
  },
  get outOfPlanAnnualSavings() {
    return self.outOfPlanMonthlySavings * 12
  },
  get estimatedTotalMonthlyIncomeInflationAdjustedMin() {
    return (
      self.estimatedTotalMonthlyIncomeMin *
      ((self.estimatedInflationAdjustedValue + 1) / (self.estimatedFutureValue + 1))
    )
  },
  get estimatedTotalMonthlyIncomeInflationAdjusted() {
    return (
      self.estimatedTotalMonthlyIncome *
      ((self.estimatedInflationAdjustedValue + 1) / (self.estimatedFutureValue + 1))
    )
  },
  get estimatedTotalMonthlyIncomeInflationAdjustedMax() {
    if (self.estimatedFutureValue === 0 || self.estimatedFutureValue === null) {
      return 0
    }
    return (
      self.estimatedTotalMonthlyIncomeMax *
      (self.estimatedInflationAdjustedValue / self.estimatedFutureValue)
    )
  },
  get riskLevelTextShort() {
    if (self.riskLevel === null) {
      return ''
    } else if (self.riskLevel < self.recomMinRiskLevel) {
      return 'Lower than'
    } else if (
      self.riskLevel <= self.recomMaxRiskLevel &&
      self.riskLevel >= self.recomMinRiskLevel
    ) {
      return 'Within'
    } else if (self.riskLevel > self.recomMaxRiskLevel) {
      return 'Higher than'
    } else {
      return ''
    }
  },
  get riskLevelText() {
    if (self.riskLevel === null) {
      return ''
    } else if (self.riskLevel < self.recomMinRiskLevel) {
      return 'Lower than our preferred range'
    } else if (
      self.riskLevel <= self.recomMaxRiskLevel &&
      self.riskLevel >= self.recomMinRiskLevel
    ) {
      return 'Within our preferred range'
    } else if (self.riskLevel > self.recomMaxRiskLevel) {
      return 'Higher than our preferred range'
    } else {
      return ''
    }
  },
  get riskLevelAllTextShort() {
    if (self.riskLevelAll === null) {
      return ''
    } else if (self.riskLevelAll < self.recomMinRiskLevel) {
      return 'Lower than'
    } else if (
      self.riskLevelAll <= self.recomMaxRiskLevel &&
      self.riskLevelAll >= self.recomMinRiskLevel
    ) {
      return 'Within'
    } else if (self.riskLevelAll > self.recomMaxRiskLevel) {
      return 'Higher than'
    } else {
      return ''
    }
  },
  get riskLevelAllText() {
    if (self.riskLevelAll === null) {
      return ''
    } else if (self.riskLevelAll < self.recomMinRiskLevel) {
      return 'Lower than our preferred range'
    } else if (
      self.riskLevelAll <= self.recomMaxRiskLevel &&
      self.riskLevelAll >= self.recomMinRiskLevel
    ) {
      return 'Within our preferred range'
    } else if (self.riskLevelAll > self.recomMaxRiskLevel) {
      return 'Higher than our preferred range'
    } else {
      return ''
    }
  },
  get riskScaleIndex() {
    if (self.recomMaxRiskLevel === null || self.recomMinRiskLevel === null) {
      return -1
    }

    if (self.riskLevel <= self.recomMaxRiskLevel && self.riskLevel >= self.recomMinRiskLevel) {
      const difference = Math.max(self.recomMaxRiskLevel - self.recomMinRiskLevel, 0)
      if (self.riskLevel < self.recomMinRiskLevel + difference / 3) {
        return 0
      } else if (self.riskLevel < self.recomMinRiskLevel + (2 * difference) / 3) {
        return 1
      } else {
        return 2
      }
    } else {
      return -1
    }
  },
  get withinRange() {
    return self.riskScaleIndex >= 0
  },
  get isOnTrack() {
    const { retirementIncomeGoalPct } = getRoot(self).person
    return self.retirementIncomeGoal >= retirementIncomeGoalPct
  },

  // Probably makes more sense to call this totalAnnualContribution
  get totalAnnualSavings() {
    if (self.primary !== null) {
      const { preTaxAnnualContribDollars, postTaxAnnualContribDollars, rothAnnualContribDollars } =
        self.primary
      if (
        preTaxAnnualContribDollars !== null &&
        postTaxAnnualContribDollars !== null &&
        rothAnnualContribDollars !== null
      ) {
        return preTaxAnnualContribDollars + postTaxAnnualContribDollars + rothAnnualContribDollars
      }
    }
    return 0
  },
  get totalAnnualPercentage() {
    if (self.primary !== null) {
      const { preTaxAnnualContributions, postTaxAnnualContributions, rothAnnualContributions } =
        self.primary
      if (
        // null checks might be unnecessary here as null can still be added as 0
        preTaxAnnualContributions !== null &&
        postTaxAnnualContributions !== null &&
        rothAnnualContributions !== null
      ) {
        return preTaxAnnualContributions + postTaxAnnualContributions + rothAnnualContributions
      }
    }
    return 0
  },
  get totalAnnualSavingsSpouse() {
    if (self.spouse !== null) {
      const { preTaxAnnualContribDollars, postTaxAnnualContribDollars, rothAnnualContribDollars } =
        self.spouse
      if (
        preTaxAnnualContribDollars !== null &&
        postTaxAnnualContribDollars !== null &&
        rothAnnualContribDollars !== null
      ) {
        return preTaxAnnualContribDollars + postTaxAnnualContribDollars + rothAnnualContribDollars
      }
    }
    return 0
  },
  get totalAnnualPercentageSpouse() {
    if (self.spouse !== null) {
      const { preTaxAnnualContributions, postTaxAnnualContributions, rothAnnualContributions } =
        self.spouse
      if (
        preTaxAnnualContributions !== null &&
        postTaxAnnualContributions !== null &&
        rothAnnualContributions !== null
      ) {
        return preTaxAnnualContributions + postTaxAnnualContributions + rothAnnualContributions
      }
    }
    return 0
  },
  get client457PreTaxSaving() {
    return (self.primary || 0) && self.primary.preTax457AnnualContributions
  },
  get client457RothSaving() {
    return (self.primary || 0) && self.primary.roth457AnnualContributions
  },
  get client457PreTaxAmount() {
    const { annualSalary } = getRoot(self).account
    return (self.client457PreTaxSaving / 100) * annualSalary
  },
  get client457RothAmount() {
    const { annualSalary } = getRoot(self).account
    return (self.client457RothSaving / 100) * annualSalary
  },
  get total457Savings() {
    return self.client457PreTaxSaving + self.client457RothSaving
  },
  get total457Amount() {
    const { annualSalary } = getRoot(self).account
    return (self.total457Savings / 100) * annualSalary
  },
  get client403bPreTaxSaving() {
    return (self.primary || 0) && self.primary.preTax403bAnnualContributions
  },
  get client403bPreTaxAmount() {
    const { annualSalary } = getRoot(self).account
    return (self.client403bPreTaxSaving / 100) * annualSalary
  },
  get total403bSavings() {
    return (self.primary || 0) && self.primary.preTax403bAnnualContributions
  },
  get total403bAmount() {
    const { annualSalary } = getRoot(self).account
    return (self.total403bSavings / 100) * annualSalary
  },
  get estimatedTotalSSIIncome() {
    if (self.primary && self.spouse) {
      return self.primary.preTaxSSIncome + self.spouse.preTaxSSIncome
    } else if (self.primary && self.spouse === null) {
      return self.primary.preTaxSSIncome
    } else if (self.primary === null && self.spouse) {
      return self.spouse.preTaxSSIncome
    }
    return 0
  },
  get totalMonthlyOtherSavings() {
    if (self.primary !== null && self.spouse !== null) {
      return self.primary.ongoingMonthlyContributions + self.spouse.ongoingMonthlyContributions
    } else if (self.primary !== null) {
      return self.primary.ongoingMonthlyContributions
    } else if (self.spouse !== null) {
      return self.spouse.ongoingMonthlyContributions
    }
    return 0
  },
  get totalAnnualOtherSavings() {
    return self.totalMonthlyOtherSavings * 12
  },
  get goalNameList() {
    const goalList = []
    for (let i = 0; i < self.includedGoals.length; i++) {
      goalList.push(self.includedGoals[i].name)
    }
    return goalList.join(', ')
  },
  get targetGoalPercent() {
    if (self.currentAfterTaxIncome === 0) {
      return 0
    }
    return (self.targetedAftTaxIncome / self.currentAfterTaxIncome) * 100
  },
  get investmentMixHelpData() {
    const difference =
      self.primary && self.primary.retAge
        ? self.primary.retAge - getRoot(self).person.age
        : 65 - getRoot(self).person.age

    if (difference >= 43) {
      return investmentMixTable[43]
    } else if (difference >= 29) {
      return investmentMixTable[29]
    } else if (difference >= 20) {
      return investmentMixTable[20]
    } else if (difference >= 10) {
      return investmentMixTable[10]
    } else if (difference >= 7) {
      return investmentMixTable[7]
    } else if (difference >= 3) {
      return investmentMixTable[3]
    } else if (difference >= 1) {
      return investmentMixTable[1]
    } else {
      return investmentMixTable[0]
    }
  },
  get companyStockNames() {
    if (!self.companyStocks) {
      return ''
    }

    const stockNames = self.companyStocks.map(({ securityName }) => securityName)

    if (stockNames.length === 1) {
      return stockNames[0]
    }

    if (stockNames.length === 2) {
      return stockNames.join(' and ')
    }

    if (stockNames.length > 2) {
      const lastName = 'and ' + stockNames.pop()
      stockNames.push(lastName)
    }

    return stockNames.join(', ')
  },
  get companyStocksToKeep() {
    return self.companyStocks.filter(companyStock => companyStock.percentToSell !== 100)
  },
  get companyStockNamesToKeep() {
    if (!self.companyStocks) {
      return ''
    }

    const stockNames = self.companyStocksToKeep.map(({ securityName }) => securityName)

    if (stockNames.length === 1) {
      return stockNames[0]
    }

    if (stockNames.length === 2) {
      return stockNames.join(' and ')
    }

    if (stockNames.length > 2) {
      const lastName = 'and ' + stockNames.pop()
      stockNames.push(lastName)
    }

    return stockNames.join(', ')
  },
  get companyStockKeepPercentage() {
    if (!self.companyStocks) {
      return 0
    }

    return self.companyStocksToKeep.reduce(
      (percentage, stock) => percentage + (100 - stock.percentToSell),
      0
    )
  },
  get companyStockKeepAmount() {
    return self.companyStocksToKeep.reduce(
      (amount, stock) => amount + (stock.totalValue - stock.amountToSell),
      0
    )
  },
  get companyStockSellPercentage() {
    if (!self.companyStocks) {
      return 0
    }

    return self.companyStocks.reduce((percentage, stock) => percentage + stock.percentToSell, 0)
  },
  get unrestrictedCompanyStocks() {
    if (!self.companyStocks) {
      return []
    }

    return self.companyStocks.filter(companyStock => !companyStock.restricted)
  },
  get restrictedCompanyStocks() {
    if (!self.companyStocks) {
      return []
    }

    return self.companyStocks.filter(companyStock => companyStock.restricted)
  },
  get includedStockOptions() {
    if (!self.stockOptions) {
      return []
    }

    return self.stockOptions.filter(stockOption => stockOption.included)
  },
}))

const Contribution = model({ taxType: number, deferralType: string, value: number })

const ContributionType = model({
  1: maybe(Contribution),
  2: maybe(Contribution),
  5: maybe(Contribution),
})

const ContributionDistribution = model({
  savingsRate: maybe(ContributionType),
  catchUp: maybe(ContributionType),
})

const employerConfig = model({
  rothContribAllowed: maybeNull(boolean),
  posttaxContribAllowed: maybeNull(boolean),
  rateChangeAllowed: false,
  balance: 0,
})

const EngineStore = model({
  baseCase: maybeNull(EngineCase),
  recommendedCase: maybeNull(EngineCase),
  modifiedCase: maybeNull(EngineCase),
  acceptedCase: maybeNull(EngineCase),
  savedScenarios: array(EngineCase),
  acceptedCaseId: maybeNull(number),

  // Stores the accepted case savings rate and catch-up contributions
  acceptedContributionDistribution: maybeNull(ContributionDistribution),

  // Institutional account
  primaryEmployerConfig: optional(employerConfig, {}),

  // Spouse Employer Plan
  spouseEmployerConfig: optional(employerConfig, {}),
})
  .views(self => ({
    get spouseContributionChange() {
      if (self.baseCase.spouse !== null && self.acceptedCase.spouse !== null) {
        const { preTaxAnnualContributions: basePreTax, postTaxAnnualContributions: basePostTax } =
          self.baseCase.spouse
        const { preTaxAnnualContributions: casePreTax, postTaxAnnualContributions: casePostTax } =
          self.acceptedCase.spouse
        return basePreTax !== casePreTax || basePostTax !== casePostTax
      }
      return false
    },
  }))
  .actions(self => ({
    getBaseCase: flow(function* () {
      const baseCase = yield API.post('engine/base')
      self.baseCase = baseCase.data
    }),
    getRecommendedCase: flow(function* () {
      const recommendedCase = yield API.post('engine/recommended')
      self.recommendedCase = recommendedCase.data
    }),
    getModifiedCase: flow(function* (modifiedValues) {
      const modifiedCase = yield API.post('engine/modified', modifiedValues)
      self.modifiedCase = modifiedCase.data
    }),
    getAcceptedCase: flow(function* (caseId) {
      const acceptedCase = yield API.get('offboard/getCaseById', { params: { caseId } })
      self.acceptedCase = acceptedCase.data
    }),
    getAcceptedContributionDistribution: flow(function* (caseId) {
      const acceptedContributionDistribution = yield API.get('offboard/catchup', {
        params: { caseId },
      })
      self.setAcceptedContributionDistribution({ catchUp: acceptedContributionDistribution.data })
    }),
    getSavedScenarios: flow(function* () {
      const savedScenarios = yield API.get('engine/previousCase')
      self.savedScenarios = savedScenarios.data
    }),
    saveScenario: flow(function* ({ name, scenarioDescription }) {
      const savedScenario = yield API.post(
        'engine/save',
        {
          ...getSnapshot(self.modifiedCase),
          name,
          scenarioDescription,
        },
        { params: { name, scenarioDescription } }
      )
      self.modifiedCase = savedScenario.data
      yield self.getSavedScenarios()
    }),
    getPrimaryEmployerConfig: flow(function* () {
      const primaryEmployerAccount = yield new Promise(resolve => {
        resolve({ data: self.active })
      })

      const { rothContribAllowed, posttaxContribAllowed, rateChangeAllowed, balance } =
        primaryEmployerAccount.data

      self.primaryEmployerConfig = balance
        ? {
            rothContribAllowed,
            posttaxContribAllowed,
            rateChangeAllowed,
            balance,
          }
        : {
            rothContribAllowed: rothContribAllowed === null ? false : rothContribAllowed,
            posttaxContribAllowed: posttaxContribAllowed === null ? false : posttaxContribAllowed,
            rateChangeAllowed: rateChangeAllowed === null ? false : rateChangeAllowed,
            balance: balance === null ? 0 : balance,
          }
    }),
    getSpouseEmployerConfig: flow(function* () {
      const { spouseEmployerAccount } = self

      if (spouseEmployerAccount) {
        const spouseAccount = yield API.get(`accounts/nonGCAccount/${spouseEmployerAccount.id}`)
        const { rothContribAllowed, posttaxContribAllowed, balance } = spouseAccount.data
        self.spouseEmployerConfig = {
          rothContribAllowed,
          posttaxContribAllowed,
          balance,
        }
      } else {
        self.spouseEmployerConfig = {}
      }
    }),
    setAcceptedCaseId(value) {
      self.acceptedCaseId = value
    },
    resetModifiedCase() {
      self.modifiedCase = null
    },
    setAcceptedContributionDistribution(value) {
      self.acceptedContributionDistribution = value
    },
  }))

export default EngineStore
