import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import * as R from 'ramda'

import { Page } from '../elements'
import IncomeReplacementDrawer from './IncomeReplacementDrawer'
import {
  HelpIcon,
  Spacer,
  Loading,
  FillGauge,
  PrimaryButton,
  TooltipText,
} from '../../../components'

class GoalsIncomeReplacement extends Component {
  state = { status: 'loading', activeDrawer: false }

  fetchInitialState = async () => {
    try {
      const { baseCase, getBaseCase } = this.props.store
      const noEngineResults = R.isNil(baseCase)

      if (noEngineResults) {
        await getBaseCase()
      }

      this.setState({ status: 'done' })
    } catch (err) {
      console.error(err)
      this.setState({ status: 'error' })
    }
  }

  componentDidMount() {
    this.fetchInitialState()
  }

  toggleDrawer = () => {
    this.setState({ activeDrawer: !this.state.activeDrawer })
  }

  handleSave = data => {
    const { history } = this.props
    const {
      person: { id },
      updatePerson,
    } = this.props.store

    updatePerson({ id, retirementIncomeGoalPct: data }).then(() => {
      this.toggleDrawer()
      history.push('/overall')
    })
  }

  render() {
    if (this.state.status === 'loading') {
      return <Loading />
    }

    if (this.state.status === 'error') {
      return (
        <div
          css={css`
            color: crimson;
          `}>
          Something went wrong, please try again later.
        </div>
      )
    }

    const { retirementIncomeGoalPct } = this.props.store.person

    return (
      <Page>
        <Page.MainTitle>Percent of income seeking after retiring</Page.MainTitle>
        <Page.Container>
          <Page.Text>
            No matter what “retirement” means to you or how near or far away, you know you need to
            save and invest in preparation.
          </Page.Text>
          <Spacer space='12px' />
          <Page.Text>
            Accepted amongst most financial planners is the assertion that you'll need to plan to
            replace from 70% to 90% of your pre-retirement income. Your need may be greater than or
            lesser than that depending upon a variety of factors from lifestyle to health.
          </Page.Text>
          <Page.Row>
            <Page.SeekText>
              <Page.Text fontSize='1.5rem'>Currently, your strategy seeks: </Page.Text>
            </Page.SeekText>
            <Page.SVGContainer>
              <FillGauge fill={retirementIncomeGoalPct} />
            </Page.SVGContainer>
            <HelpIcon
              helpLabel='Will it be enough?'
              tooltip={TooltipText.willReplacementGoalBeEnough()}
            />
          </Page.Row>
          <Page.Row justifyContent='flex-end'>
            <PrimaryButton label='Edit' onClick={this.toggleDrawer} />
          </Page.Row>
        </Page.Container>
        <IncomeReplacementDrawer
          active={this.state.activeDrawer}
          toggleDrawer={this.toggleDrawer}
          handleSave={this.handleSave}
        />
      </Page>
    )
  }
}

export default inject('store')(observer(GoalsIncomeReplacement))
