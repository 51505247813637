import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { HelpIcon, OrderedList, Spacer, Text, WizardStep } from '../../../components'
import { accountTypeToLabel } from '../utils'
import tooltipText from './tooltipText'

const accountTypeToItems = {
  '401k': ['basics', 'contributions', 'investments'],
  '403b': ['basics', 'contributions', 'investments'],
  '457': ['basics', 'contributions457', 'investments'],
  'payroll-deduct-ira': ['basics', 'contributions', 'investments'],
  '401a': ['basics', 'contributions', 'investments'],
  'keogh': ['basics', 'contributions', 'investments'],
  'sep-ira': ['basics', 'contributions', 'investments'],
  'simple-ira': ['basics', 'contributions', 'investments'],
  'ira-rollover': ['basics', 'contributionsIRA', 'investments'],
  'ira-roth': ['basics', 'contributionsIRA', 'investments'],
  'ira-deductible': ['basics', 'contributionsIRA', 'investments'],
  'ira-non-deductible': ['basics', 'contributionsIRA', 'investments'],
  'brokerage-account': ['basics', 'investments'],
  'other-asset': ['basics', 'investments'],
  'pension': ['basics', 'payout'],
  'annuity-fixed': ['basics', 'payout'],
}

const ItemKeyToText = {
  basics: 'Account basics—what to call it, who owns it, etc.',
  contributions:
    'Contributions—whether or not permitted, by whom (account owner and/or employer), and how much',
  contributions457: 'Contributions—whether or not permitted, and how much',
  contributionsIRA: 'Contributions—whether or not making ongoing contributions and how much',
  payout: 'Payout details—when, how, how much, etc.',
  investments:
    'Investments—specific mutual funds/ETFs, individual stocks, and bonds, their balances, etc.',
}

const importantNoteTypes = ['401k', '403b', '457', '401a', 'keogh']

const AddIntro = props => {
  const { match, history, store, location } = props
  const {
    config: { template },
    institutionalAccountUnderReview,
    institutionalAccount,
    anotherRetirementAccountExists,
  } = store
  const { accountType } = match.params

  const isInstitutionalAccount = location?.state?.id === institutionalAccount?.id

  useEffect(() => {
    // check that the type is actually correct
    if (!_.includes(_.keys(accountTypeToItems), accountType)) {
      history.replace('/accounts/add')
    }
  }, [accountType, history])

  const handleBack = () => {
    props.history.goBack()
  }

  const handleNext = async () => {
    const { type, accountType } = props.match.params
    if (type === 'institutional' && anotherRetirementAccountExists) {
      return props.history.push(`/accounts/add/${type}/${accountType}/duplicate-account`, {
        id: location?.state?.id,
      })
    }

    props.history.push(`/accounts/add/${type}/${accountType}/basics`, { id: location?.state?.id })
  }

  const title = `Let's add your ${accountTypeToLabel[accountType]}`
  const List = accountTypeToItems[accountType] || []
  const showImportantNote = _.includes(importantNoteTypes, accountType)

  return (
    <WizardStep onBackClick={handleBack} onNextClick={handleNext} title={title}>
      <Text>
        <p> We need to collect some details about your account: </p>
        <OrderedList>
          {List.map((value, index) => (
            <li key={value}>{ItemKeyToText[value]}</li>
          ))}
        </OrderedList>

        <Spacer space='4px' />

        {showImportantNote && institutionalAccountUnderReview && isInstitutionalAccount ? (
          <Text padding='0 50px'>
            {template.sponsorName} added your current retirement plan information. Please review and
            complete any missing data.
          </Text>
        ) : (
          <Text padding='0 50px'>
            Important: We can give you better investment recommendations if we know all the
            investment options available to you&mdash;whether or not there is a balance in that
            particular option.
            {'\u00A0\u00A0\u00A0'}
            <HelpIcon tooltip={tooltipText()} />
          </Text>
        )}
        <p>We'll walk you through it, step by step.</p>
      </Text>
    </WizardStep>
  )
}

export default inject('store')(observer(AddIntro))
