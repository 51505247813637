// eslint-disable-line
/* eslint-disable react/jsx-handler-names */
import { types, getParent, getEnv, flow } from 'mobx-state-tree'
import _ from 'lodash'
import dayjs from 'dayjs'
import { API } from '../api'
const { model, maybeNull, number, string, literal, boolean } = types

const formToAPIMap = {
  name: 'annuityName',
  participantId: 'personId',
}

export const AnnuityAccount = model({
  annuityName: maybeNull(string),
  annuityStartAge: maybeNull(number),
  beginYear: maybeNull(number),
  colaAmtRate: maybeNull(number),
  colaWithCpi: maybeNull(boolean),
  cost: maybeNull(number),
  distOption: maybeNull(number),
  distOptionName: maybeNull(string),
  endYear: maybeNull(number),
  futureCost: maybeNull(number),
  id: maybeNull(number),
  monthlyIncome: maybeNull(number),
  personId: maybeNull(number),
  survivorFraction: maybeNull(number),
  taxtype: maybeNull(number),
  type: literal('Annuity'),
})
  .views(self => ({
    get planType() {
      return 24
    },
    get participantName() {
      const { spouse, person } = getEnv(getParent(self)).store

      if (self.participantId === spouse.id) {
        return spouse.firstName
      } else {
        return person.displayName
      }
    },
    get name() {
      return self.annuityName || ''
    },
    get participantId() {
      return self.personId
    },
    get startAge() {
      const { birthDate } = getEnv(getParent(self)).store.person
      if (self.beginYear - dayjs(birthDate).year() < 0) {
        return null
      }
      return self.beginYear - dayjs(birthDate).year()
    },
    get startYear() {
      const { birthDate } = getEnv(getParent(self)).store.person
      if (self.beginYear < dayjs(birthDate).year()) {
        return null
      }
      return self.annuityStartAge + dayjs(birthDate).year()
    },
    get isBasicsNull() {
      const basics = ['annuityName', 'personId']
      for (let i = 0; i < basics.length; i++) {
        if (self[basics[i]] !== null) {
          return false
        }
      }
      return true
    },
    get isAnnuityNull() {
      const annuity = [
        'annuityStartAge',
        'beginYear',
        'cost',
        'distOption',
        'endYear',
        'futureCost',
        'id',
        'monthlyIncome',
        'survivorFraction',
      ]

      for (let i = 0; i < annuity.length; i++) {
        if (self[annuity[i]] !== null) {
          return false
        }
      }
      return true
    },
    get isAnnuityPayoutsNull() {
      const annuityPayout = [
        'annuityStartAge',
        'beginYear',
        'cost',
        'distOption',
        'endYear',
        'futureCost',
        'monthlyIncome',
        'survivorFraction',
      ]

      for (let i = 0; i < annuityPayout.length; i++) {
        if (self[annuityPayout[i]] !== null) {
          return false
        }
      }
      return true
    },
    get trackInvestmentsByTaxType() {
      return false
    },
  }))
  .actions(self => ({
    setData(data) {
      _.merge(self, data)
    },
    setAttributes(name, value) {
      const changes = _.isString(name) ? { [name]: value } : name
      _.merge(self, changes)
    },
    handleChange(name, value) {
      if (Object.prototype.hasOwnProperty.call(formToAPIMap, name)) {
        name = formToAPIMap[name]
      }

      self[name] = value
    },
    handleAgeChange(name, value) {
      const { birthDate } = getEnv(getParent(self)).store.person
      self[name] = value
      self.beginYear = self.startYear || dayjs(birthDate).year()
    },
    handleYearChange(name, value) {
      self[name] = value
      self.annuityStartAge = self.startAge || 0
    },
    cleanAnnuityData() {
      const { distOption } = self
      if (distOption === 0) {
        self.handleChange('survivorFraction', 0)
      }
    },
    setParticipantId() {
      const { maritalStatus, includeSpouse, id } = getEnv(getParent(self)).store.person
      if (id !== null) {
        if (!maritalStatus || (maritalStatus && !includeSpouse)) {
          self.handleChange('personId', id)
        }
      }
    },
    createAnnuity: flow(function* ({ annuityName, personId }) {
      const response = yield API.post('annuity', {
        annuityName,
        annuityStartAge: 0,
        beginYear: 0,
        colaAmtRate: 0,
        colaWithCpi: false,
        cost: 0,
        distOption: 0,
        endYear: 0,
        futureCost: 0,
        id: 0,
        monthlyIncome: 0,
        personId,
        survivorFraction: 0,
        taxtype: 0,
        distOptionName: '',
        type: 'Annuity',
      })
      self.annuity = response.data
      return response
    }),
    setAnnuity: flow(function* ({
      annuityName,
      distOptionName,
      annuityStartAge,
      beginYear,
      endYear,
      futureCost,
      id,
      monthlyIncome,
      personId,
      survivorFraction,
      taxtype,
      colaAmtRate,
      colaWithCpi,
      cost,
    }) {
      const response = yield API.put('annuity', {
        annuityName: annuityName || self.annuity.annuityName,
        annuityStartAge,
        beginYear,
        colaAmtRate: colaAmtRate,
        colaWithCpi: colaWithCpi,
        cost: cost || 0,
        distOption: 0,
        endYear: endYear || 0,
        futureCost: futureCost || 0,
        id: id || self.annuity.id,
        monthlyIncome,
        personId: personId || self.annuity.personId,
        survivorFraction: survivorFraction || 0,
        taxtype: Number(taxtype),
        distOptionName,
        type: 'Annuity',
      })
      self.annuity = response.data
    }),
  }))
