import { css } from '@emotion/react'
import { Component } from 'react'
import { Field, FormSpy } from 'react-final-form'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as yup from 'yup'

import {
  Spacer,
  MultiButtonInput,
  DeleteModal,
  InputBox,
  NumberInput,
  CurrencyText,
  CurrencyInput,
  DatePicker,
  TextErrorField,
} from '../../../../components'
import { SECURITY_TYPES, VALUATION_METHODS, TAX_TYPES_TEXT } from '../../../../constants'
import AddBalanceButtons from '../components/AddBalanceButtons'
import {
  LightGreyText,
  InlineBlockContainer,
  SectionBox,
  FormLabel,
  FormText,
  RemoveIcon,
} from '../../styled'

export const afterTaxSchema = yup.object().shape({
  quantity: yup.number().nullable(),
  purchaseDate: yup.string().nullable(),
  totalCostBasis: yup.number().nullable(),
  valuationMethod: yup.number().nullable(),
  totalValue: yup
    .number()
    .nullable()
    .required('Balance is required')
    .min(1, 'Balance must be at least $1')
    .max(99999999, 'Balance must be less than $100,000,000'),
})

class AfterTaxForm extends Component {
  state = { deleteActive: false }

  toggleDeleteModal = () => {
    this.setState({ deleteActive: !this.state.deleteActive })
  }

  render() {
    const { price, securityType } = this.props.security
    const { hideNotInvested, allowRemoval } = this.props
    const enableSharesControl =
      price >= 1 && _.includes([SECURITY_TYPES.Stock, SECURITY_TYPES['Mutual Fund']], securityType)
    const valuationMethodButtons = [
      {
        text: '# of Shares',
        value: VALUATION_METHODS.marketPrice,
      },
      {
        text: 'Balance',
        value: VALUATION_METHODS.accountBalance,
      },
      !hideNotInvested && {
        text: 'Not Invested',
        value: VALUATION_METHODS.notInvested,
      },
    ].filter(item => !!item)

    return (
      <FormSpy subscription={{ values: true, touched: true, errors: true }}>
        {({ values, touched, form, errors }) =>
          values.afterTax ? (
            <div>
              <div>
                <LightGreyText>{`${TAX_TYPES_TEXT[2]} balance info`}</LightGreyText>
                <Spacer space='8px' />
              </div>
              <div>
                <InlineBlockContainer>
                  <SectionBox>
                    {allowRemoval && (
                      <div
                        css={css`
                          position: absolute;
                          right: 10px;
                        `}>
                        <RemoveIcon onClick={() => form.change('afterTax', null)} />
                      </div>
                    )}
                    <InlineBlockContainer
                      css={
                        allowRemoval
                          ? css`
                              padding-top: 14px;
                            `
                          : ''
                      }>
                      {/* If the security type is a Stock or Mutual Fund and the security has a non-zero price, user can add based on # of Shares */}
                      {enableSharesControl && (
                        <Field
                          name='afterTax.valuationMethod'
                          render={({ input, meta }) => (
                            <MultiButtonInput
                              buttons={valuationMethodButtons}
                              name={input.name}
                              value={input.value}
                              onBlur={input.onBlur}
                              error={meta.error}
                              showError={meta.touched}
                              margin='0'
                              onChange={(name, value) => {
                                if (value === VALUATION_METHODS.notInvested) {
                                  this.toggleDeleteModal()
                                } else {
                                  input.onChange(value)
                                  if (value === VALUATION_METHODS.accountBalance) {
                                    form.change('afterTax.quantity', 0)
                                  }
                                  if (value === VALUATION_METHODS.marketPrice) {
                                    const newSharesAmount = Math.round(
                                      (values.afterTax.totalValue || 0) / price
                                    )
                                    form.change('afterTax.quantity', newSharesAmount)
                                    form.change(
                                      'afterTax.totalValue',
                                      Math.round(newSharesAmount * price * 100) / 100
                                    )
                                  }
                                }
                              }}
                            />
                          )}
                        />
                      )}

                      <div>
                        {/* Shares or Balance Inputs */}
                        {values.afterTax.valuationMethod === VALUATION_METHODS.marketPrice && (
                          <InlineBlockContainer>
                            <FormLabel>Shares</FormLabel>
                            <InputBox>
                              <Field
                                name='afterTax.quantity'
                                format={value => (value === null ? undefined : value)}
                                parse={v => v}
                                render={({ input, meta }) => (
                                  <NumberInput
                                    fontSize='14px'
                                    name={input.name}
                                    onBlur={input.onBlur}
                                    onChange={(name, value) => {
                                      input.onChange(value)
                                      form.change(
                                        'afterTax.totalValue',
                                        Math.round(price * (value || 0) * 100) / 100
                                      )
                                    }}
                                    value={input.value}
                                    width='75px'
                                  />
                                )}
                              />
                            </InputBox>
                          </InlineBlockContainer>
                        )}

                        {values.afterTax.valuationMethod === VALUATION_METHODS.marketPrice && (
                          <InlineBlockContainer>
                            <FormLabel padding='30px'>Share price</FormLabel>
                            <FormText padding='0 0 0 30px'>
                              <CurrencyText decimalScale={2} fontSize='14px' value={price} />
                            </FormText>
                          </InlineBlockContainer>
                        )}

                        <InlineBlockContainer>
                          <FormLabel
                            padding={
                              values.afterTax.valuationMethod === VALUATION_METHODS.marketPrice &&
                              '30px'
                            }>
                            Balance
                          </FormLabel>
                          {values.afterTax.valuationMethod === VALUATION_METHODS.accountBalance ? (
                            <InputBox>
                              <Field
                                name='afterTax.totalValue'
                                format={value => (value === null ? undefined : value)}
                                parse={v => v}
                                render={({ input, meta }) => (
                                  <CurrencyInput
                                    decimalScale={2}
                                    fontSize='14px'
                                    name={input.name}
                                    onBlur={input.onBlur}
                                    onChange={(name, value) => input.onChange(value)}
                                    value={input.value}
                                    width='125px'
                                  />
                                )}
                              />
                            </InputBox>
                          ) : (
                            <FormText padding='0 0 0 30px'>
                              <CurrencyText
                                decimalScale={2}
                                fontSize='14px'
                                value={values.afterTax.totalValue || 0}
                              />
                            </FormText>
                          )}
                        </InlineBlockContainer>
                        <div
                          css={css`
                            padding-left: 10px;
                            padding-bottom: 10px;
                            align-self: center;
                          `}>
                          <TextErrorField
                            error={
                              values.afterTax.valuationMethod ===
                                VALUATION_METHODS.accountBalance || values.afterTax.quantity
                                ? _.get(errors, 'afterTax.totalValue', '')
                                : !values.afterTax.quantity
                                ? 'Quantity is required'
                                : ''
                            }
                            showError={
                              values.afterTax.valuationMethod === VALUATION_METHODS.accountBalance
                                ? touched['afterTax.totalValue']
                                : touched['afterTax.quantity']
                            }
                          />
                        </div>

                        {/* Cost Basis Input */}
                        <div
                          css={css`
                            padding-bottom: 20px;
                          `}>
                          <FormLabel>Cost basis</FormLabel>
                          <InputBox>
                            <Field
                              name='afterTax.totalCostBasis'
                              format={value => (value === null ? undefined : value)}
                              parse={v => v}
                              render={({ input, meta }) => (
                                <CurrencyInput
                                  error={meta.error}
                                  fontSize='14px'
                                  name={input.name}
                                  onBlur={input.onBlur}
                                  onChange={(name, value) => input.onChange(value)}
                                  showError={meta.touched}
                                  value={input.value}
                                  width='125px'
                                  decimalScale={2}
                                />
                              )}
                            />
                          </InputBox>
                        </div>

                        {/* purchaseDate input */}
                        <div>
                          <FormLabel>As of date</FormLabel>
                          <InputBox
                            css={css`
                              padding-left: 6px;
                            `}>
                            <Field
                              name='afterTax.purchaseDate'
                              format={v => v}
                              parse={v => v}
                              render={({ input, meta }) => (
                                <DatePicker
                                  date={input.value}
                                  error={meta.error}
                                  fontSize='14px'
                                  handleChange={(name, value) => input.onChange(value)}
                                  maxYear={new Date().getFullYear()}
                                  minYear={1900}
                                  name={input.name}
                                  onBlur={input.onBlur}
                                  showError={meta.touched}
                                />
                              )}
                            />
                          </InputBox>
                        </div>
                        {/* valuationMethod !== VALUATION_METHODS.notInvested && this.renderForm() */}
                      </div>
                    </InlineBlockContainer>
                  </SectionBox>
                </InlineBlockContainer>
              </div>
              <Spacer space='20px' />
              <DeleteModal
                handleConfirmation={() => {
                  form.change('afterTax', null)
                  this.toggleDeleteModal()
                }}
                isModalOpen={this.state.deleteActive}
                title='Set As Not Invested'
                toggleModal={this.toggleDeleteModal}
              />
            </div>
          ) : (
            // If no after-tax investment is found, we ask them if they want to add it here
            <AddBalanceButtons
              value={false}
              onChange={(name, value, taxType) => {
                if (value === taxType) {
                  form.change('afterTax', {
                    quantity: undefined,
                    purchaseDate: dayjs().format('YYYY-MM-DD'),
                    totalCostBasis: undefined,
                    totalValue: undefined,
                    valuationMethod: enableSharesControl
                      ? VALUATION_METHODS.marketPrice
                      : VALUATION_METHODS.accountBalance,
                  })
                }
              }}
              taxType={2}
            />
          )
        }
      </FormSpy>
    )
  }
}

export default AfterTaxForm
