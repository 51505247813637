import { types, getParent, getEnv } from 'mobx-state-tree'
const { model, maybeNull, boolean, number, string, literal, array } = types

const ContributionTier = model('ContributionTier', {
  from: maybeNull(number),
  to: maybeNull(number),
  percentContribution: maybeNull(number),
})

export const InstitutionalAccount = model('ActiveAccount', {
  active: maybeNull(boolean),
  adviced: maybeNull(boolean),
  balance: maybeNull(number),
  companyMatchDlrMax: maybeNull(number),
  companyMatchRate: maybeNull(number),
  companyMatchRateMax: maybeNull(number),
  contribMethod: maybeNull(string),
  contributionEligibility: maybeNull(boolean),
  employeeContrib: maybeNull(boolean),
  employerContrib: maybeNull(boolean),
  employerMatch: maybeNull(boolean),
  id: maybeNull(number),
  name: maybeNull(string),
  participantId: maybeNull(number),
  planType: maybeNull(number),
  postTaxSavingsAmt: maybeNull(number),
  posttaxContribAllowed: maybeNull(boolean),
  posttaxSavingsRate: maybeNull(number),
  preTaxSavingsAmt: maybeNull(number),
  pretaxSavingsRate: maybeNull(number),
  profitSharing: maybeNull(boolean),
  profitSharingRate: maybeNull(number),
  rothContribAllowed: maybeNull(boolean),
  rothSavingsAmt: maybeNull(number),
  rothSavingsRate: maybeNull(number),
  type: literal('Institutional'),
  rateChangeAllowed: maybeNull(boolean),
  contributionTiers: array(ContributionTier),
}).views(self => ({
  get preTaxContribAllowed() {
    return true
  },
  get participantName() {
    const { spouse, person } = getEnv(getParent(self)).store

    if (self.participantId === spouse.id) {
      return spouse.firstName
    } else {
      return person.displayName
    }
  },
  get employerMatchDisplayValue() {
    return self.employerMatch ? 'Yes' : 'No'
  },
  get oneContributionOnly() {
    return self.contributionEligibility && !self.posttaxContribAllowed && !self.rothContribAllowed
  },
  get hasEmployerMatch() {
    return self.contributionEligibility && (self.companyMatchRate || self.companyMatchDlrMax)
  },
  get contributeMax() {
    if (self.contribMethod === 'P') {
      const totalContributionRate = [
        0,
        self.pretaxSavingsRate,
        self.posttaxSavingsRate,
        self.rothSavingsRate,
      ]
        .filter(item => item !== null)
        .reduce((accumulator, current) => accumulator + current)
      // shouldn't this be greater than or equal to?
      return totalContributionRate > self.companyMatchRateMax
    } else if (self.contribMethod === 'D') {
      const totalContributionAmt = [
        0,
        self.preTaxSavingsAmt,
        self.postTaxSavingsAmt,
        self.rothSavingsAmt,
      ]
        .filter(item => item !== null)
        .reduce((accumulator, current) => accumulator + current)
      // shouldn't this be greater than or equal to?
      return totalContributionAmt > self.companyMatchDlrMax
    }
    return null
  },
  get maximumContributionText() {
    if (self.companyMatchRateMax) {
      return `${self.companyMatchRateMax}%`
    }
    return ''
  },
  get totalContributionRate() {
    return (
      self.pretaxSavingsRate +
      self.posttaxContribAllowed * self.posttaxSavingsRate +
      self.rothContribAllowed * self.rothSavingsRate
    )
  },
  get totalContributionAmt() {
    return (
      self.preTaxSavingsAmt +
      self.posttaxContribAllowed * self.postTaxSavingsAmt +
      self.rothContribAllowed * self.rothSavingsAmt
    )
  },
}))
