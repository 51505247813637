import { css } from '@emotion/react'
import { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import { BackButton, NextButton } from '../../../components/styled'
import { Page } from './style'
import { Spacer } from '../../../components'

function Main(props) {
  const {
    history,
    store: {
      spouse,
      person,
      getRecommendedAdvicesForSpending,
      emergencyFund,
      allSpendingBaseCases,
    },
  } = props

  useEffect(() => {}, []) // eslint-disable-line

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getRecommendedAdvicesForSpending()
      } catch (err) {
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  return (
    <>
      <div
        css={css`
          padding: 0 40px;
          max-width: 1000px;
        `}>
        <Page.Heading>Things to consider</Page.Heading>

        <div
          css={css`
            padding-left: 10px;
          `}>
          <Page.MainText>
            {person.maritalStatus && person.includeSpouse && (
              <div>
                And right away, things get a little tricky! While we call income from sources such
                as Social Security and pension lifetime income, when you are married, the total may
                not last both of your lifetimes. <br />
                <br />
              </div>
            )}
            The scenarios you consider in this area do not change the choices and information you
            have given us to this point. This is just a chance to "see what happens if..." You'll be
            able to finalize your choices after you're done planning.
          </Page.MainText>
          <Spacer space='20px' />

          {person.maritalStatus && person.includeSpouse && (
            <Page.Section>
              <Page.SectionTitle>
                See what happens if you both live long happy lives
              </Page.SectionTitle>
              <Page.SecondaryButton
                onClick={() => history.push('/spending/considerations/two-long-lives')}>
                Let's see
              </Page.SecondaryButton>
            </Page.Section>
          )}

          {!person.includeSpouse && (
            <Page.Section>
              <Page.SectionTitle>See what happens if you live a long happy life</Page.SectionTitle>
              <Page.SecondaryButton
                onClick={() => history.push('/spending/considerations/one-long-life')}>
                Let's see
              </Page.SecondaryButton>
            </Page.Section>
          )}

          {person.maritalStatus && person.includeSpouse && (
            <Page.Section>
              <Page.SectionTitle>
                See what happens if{' '}
                {person && person.preferredName
                  ? person.preferredName
                  : person.firstName
                  ? person.firstName
                  : 'account holder'}{' '}
                passes this year
              </Page.SectionTitle>
              <Page.SecondaryButton
                onClick={() => history.push('/spending/considerations/client-dies')}>
                Let's see
              </Page.SecondaryButton>
            </Page.Section>
          )}

          {person.maritalStatus && person.includeSpouse && (
            <Page.Section>
              <Page.SectionTitle>
                See what happens if{' '}
                {spouse && spouse.firstName ? spouse.firstName : 'account holder spouse'} passes
                this year
              </Page.SectionTitle>
              <Page.SecondaryButton
                onClick={() => history.push('/spending/considerations/spouse-dies')}>
                Let's see
              </Page.SecondaryButton>
            </Page.Section>
          )}

          {!_.get(emergencyFund, 'amount', 0) > 0 && (
            <Page.Section>
              <Page.SectionTitle>
                See what happens if you create an emergency fund
              </Page.SectionTitle>
              <Page.SecondaryButton
                onClick={() => history.push('/spending/considerations/emergency-fund')}>
                Let's see
              </Page.SecondaryButton>
            </Page.Section>
          )}

          {_.get(allSpendingBaseCases, 'companyStocks', null).filter(
            companyStock => !companyStock.restricted
          ).length > 0 && (
            <Page.Section>
              <Page.SectionTitle
                css={css`
                  max-width: 400px;
                `}>
                See what happens if you sell off a different amount of company stock
              </Page.SectionTitle>
              <Page.SecondaryButton
                onClick={() => history.push('/spending/considerations/company-stock')}>
                Let's see
              </Page.SecondaryButton>
            </Page.Section>
          )}

          <Spacer space='20px' />

          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
            `}>
            <BackButton backgroundColor='#FFFFFF' onClick={() => history.goBack()}>
              Cancel
            </BackButton>
            <NextButton
              css={css`
                width: 180px;
              `}
              onClick={() => history.push('/spending')}>
              Done planning
            </NextButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default inject('store')(observer(Main))
