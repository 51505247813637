import _ from 'lodash'

export const trackInvestmentsByTaxType = accountType => {
  return _.includes(
    [
      '401a',
      '401k',
      '403b',
      '457',
      'annuity-fixed',
      'keogh',
      // "payroll-deduct-ira",
      'pension',
      'sep-ira',
      'simple-ira',
    ],
    accountType
  )
}
