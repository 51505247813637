import { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useEffect } from 'react'
import { inject, observer, Provider } from 'mobx-react'
import posed, { PoseGroup } from 'react-pose'

import RetailStore from '../../models/RetailStore'

import RetailPayment from './retail-onboarding/payments/RetailPayment'

import RetailWelcome from './retail-onboarding/RetailWelcome'
import RetailReview from './retail-onboarding/RetailReview'
import { API, domain } from '../../api'
import BGRight from '../../assets/images/BGRight.svg'

function RetailDashboard(props) {
  const { location, history } = props

  const [retailStore] = useState(() => RetailStore.create({}))

  useEffect(() => {
    const handleReviewRedirect = async () => {
      try {
        const pricebook = retailStore.getPricebookId()
        if (pricebook) {
          console.log(pricebook)
          const { data } = await API.get('license/status')
          if (data && !data.hasActiveLicense) {
            history.replace('/retail/review')
          }
        }
      } catch (err) {
        console.log('not authenticated')
      }
    }
    handleReviewRedirect()
  }, [history, retailStore])

  return (
    <Provider retailStore={retailStore}>
      <BackgroundWrapper>
        <PoseGroup animateOnMount>
          <RouteContainer key={location.key || location.pathname}>
            <Switch location={location}>
              <Route path='/retail/welcome' component={RetailWelcome} />
              <Route path='/retail/payment' component={RetailPayment} />
              <Route path='/retail/review' component={RetailReview} />
              <Route path='/retail/login' component={RedirectToAzure} />
              <Route path='/retail/setup-account' component={RedirectToAzureSignUp} />
              <Route component={RedirectToWelcome} />
            </Switch>
          </RouteContainer>
        </PoseGroup>
      </BackgroundWrapper>
    </Provider>
  )
}

function RedirectToAzure() {
  useEffect(() => {
    window.location.replace(`https://retail-auth.${domain}`)
  }, [])

  return null
}

function RedirectToAzureSignUp() {
  useEffect(() => {
    window.location.replace(`https://retail-auth.${domain}/oauth2/authorization/B2C_1_signup1`)
  }, [])

  return null
}

function RedirectToWelcome({ history }) {
  useEffect(() => {
    history.replace('/retail/welcome')
  }, []) // eslint-disable-line

  return null
}

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

const BackgroundWrapper = ({ children }) => {
  return <div style={{ background: `url(${BGRight}) no-repeat top right` }}>{children}</div>
}

export default inject('store')(observer(RetailDashboard))
