import { css } from '@emotion/react'
import { Component } from 'react'
import { Route } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import posed, { PoseGroup } from 'react-pose'

import { Page } from '../elements'
import Suggestion from './Suggestion'
import ProjectedValues from './ProjectedValues'
import TellMeMore from './TellMeMore'
import IncomeGoalDecision from './IncomeGoalDecision'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 150, beforeChildren: true },
  exit: { opacity: 0 },
})

class IncomeGoal extends Component {
  state = { status: 'loading' }

  async fetchData() {
    try {
      await this.props.onboarding.getBaseCase()
      this.setState({ status: 'done' })
    } catch (err) {
      console.error(err)
      this.setState({ status: 'error' })
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    return (
      <Page>
        <PoseGroup animateOnMount>
          <RouteContainer key='status'>
            {this.state.status === 'loading' && (
              <Page.AnimateItem poseKey='loading_status'>
                <Page.Loading />
              </Page.AnimateItem>
            )}

            {this.state.status === 'error' && (
              <Page.AnimateItem poseKey='error_status'>
                <Page.ErrorMessage>
                  Oops! Something went wrong, please try again later
                </Page.ErrorMessage>
              </Page.AnimateItem>
            )}

            {this.state.status === 'done' && (
              <Page.AnimateGroup poseKey='done_status'>
                <Page.Heading
                  title='Goal: income after retiring'
                  css={css`
                    margin-bottom: 28px;
                  `}
                />

                <Page.AnimateItem>
                  <Page.Text>
                    Your biggest financial commitment is funding your retirement. Whether
                    “retirement” means stopping work altogether, working part-time, or embarking on
                    a new career, be that decades away or just around the corner, you want to have
                    enough to meet your needs and then provide for other things you want from
                    discretionary spending to big ticket items, such as a vacation or a car.
                  </Page.Text>
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Suggestion />
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <ProjectedValues />
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <TellMeMore />
                </Page.AnimateItem>

                <Page.AnimateItem>
                  <Route component={IncomeGoalDecision} />
                </Page.AnimateItem>
              </Page.AnimateGroup>
            )}
          </RouteContainer>
        </PoseGroup>
      </Page>
    )
  }
}

export default inject('onboarding')(observer(IncomeGoal))
