import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Coin, Bank, LineChart, PieChart } from '../../../../components/icons'

// Breakdown Legend
const LegendStyle = styled.div`
  position: relative;
  height: ${p => (p.height ? `${p.height}px` : null)};
  padding: ${p => (p.padding ? p.padding : null)};
`
const SVGLegendItem = styled.div`
  display: inline-flex;
  align-items: center;
  min-width: 80px;
`
const StyledSVGContainer = styled.div`
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-right: 6px;

  svg {
    .icon-color {
      fill: ${p => p.theme.lightestGray};
    }
  }
`
const LegendText = styled.span`
  font-size: 14px;
  fill: ${p => p.theme.darkestGray};
`
const Legend = () => {
  return (
    <LegendStyle
      height={22}
      css={css`
        margin: 28px 0;
      `}>
      <SVGLegendItem>
        <StyledSVGContainer>
          <Coin />
        </StyledSVGContainer>
        <LegendText>Cash</LegendText>
      </SVGLegendItem>
      <SVGLegendItem>
        <StyledSVGContainer>
          <Bank />
        </StyledSVGContainer>
        <LegendText>Bonds</LegendText>
      </SVGLegendItem>
      <SVGLegendItem>
        <StyledSVGContainer>
          <LineChart />
        </StyledSVGContainer>
        <LegendText>Stock</LegendText>
      </SVGLegendItem>
      <SVGLegendItem>
        <StyledSVGContainer>
          <PieChart />
        </StyledSVGContainer>
        <LegendText>Other</LegendText>
      </SVGLegendItem>
    </LegendStyle>
  )
}

export default Legend
