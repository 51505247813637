import { css } from '@emotion/react'
import { inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'

import { TextErrorField, Label, ButtonInput, HelpIcon, TooltipText } from '../../../components'
import { Row, InputBox, BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { reduceValidationError } from '../../../utils'

const schema = yup.object().shape({
  autoRiskLevelAdjustmentStatus: yup.boolean().nullable().required('Select one'),
})

function AutoRiskAdjustEdit(props) {
  function initialValues() {
    const { autoRiskLevelAdjustmentStatus } = props.store.account
    return { autoRiskLevelAdjustmentStatus }
  }

  function validate(values) {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  async function handleTransaction(autoRiskLevelAdjustmentStatus) {
    try {
      await props.store.updateAutoRiskLevelAdjustmentStatus(autoRiskLevelAdjustmentStatus)
      props.onClose()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  function onSubmit({ autoRiskLevelAdjustmentStatus }) {
    if (props.store.account.autoRiskLevelAdjustmentStatus !== autoRiskLevelAdjustmentStatus) {
      return handleTransaction(autoRiskLevelAdjustmentStatus)
    } else {
      props.onClose()
    }
  }

  return (
    <Form
      initialValues={initialValues()}
      validate={validate}
      onSubmit={onSubmit}
      subscription={{ submitting: true, submitError: true }}
      render={({ handleSubmit, submitting, submitError }) => (
        <div>
          <Row
            css={css`
              margin-top: 12px;
            `}>
            <Label>
              Automative risk level adjustment{' '}
              <HelpIcon tooltip={TooltipText.autoRiskAdjustment()} />
            </Label>
            <Field
              name='autoRiskLevelAdjustmentStatus'
              subscription={{ value: true, touched: true, error: true }}
              render={({ input, meta }) => (
                <InputBox>
                  <ButtonInput
                    name={input.name}
                    text='Include'
                    value
                    isActive={input.value === true}
                    onClick={(name, value) => input.onChange(value)}
                    width='126px'
                  />
                  <ButtonInput
                    name={input.name}
                    text='Exclude'
                    value={false}
                    isActive={input.value === false}
                    onClick={(name, value) => input.onChange(value)}
                    width='126px'
                  />
                  <TextErrorField showError={meta.touched} error={meta.error || submitError} />
                </InputBox>
              )}
            />
          </Row>

          <ButtonsBox>
            <BackButton backgroundColor='#FFFFFF' onClick={props.onClose} disabled={submitting}>
              Cancel
            </BackButton>
            <NextButton onClick={handleSubmit} disabled={submitting}>
              Save
            </NextButton>
          </ButtonsBox>
        </div>
      )}
    />
  )
}

export default inject('store')(AutoRiskAdjustEdit)
