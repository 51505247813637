/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const active = 'rt-link-active'

const icon = 'rt-link-icon'

const link = 'rt-link-link'

const _icon = css`
  font-size: ${reduceCSSCalc(`calc(1.8 * ${ds.get('unit')})`)};
  margin-right: ${ds.get('unit')};
`

const _link = css`
  align-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 1.5;
  position: relative;
  transition: opacity ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')};

  ${ds.get('reset')};

  &:not(.${active}) {
    opacity: 0.5;
  }

  &:hover,
  &:active {
    opacity: 1;
  }

  & > * {
    vertical-align: middle;
  }

  & > small {
    font-size: ${ds.fs('xs')};
    margin-left: ${reduceCSSCalc(`calc(0.8 * ${ds.get('unit')})`)};
    text-align: center;
  }
`

export default {
  active,
  icon: cx(icon, _icon),
  link: cx(link, _link),
}
