import { Field } from 'react-final-form'
import { FlexRow, TextSpan } from '../components'

export function suffixWrapper(Component, label) {
  return (
    <FlexRow childMargin wrap='no-wrap'>
      {Component}
      <TextSpan>{label}</TextSpan>
    </FlexRow>
  )
}

export const Fields = ({ names, subscription, fieldsState = {}, children, originalRender }) => {
  if (!names.length) {
    return (originalRender || children)(fieldsState)
  }
  const [name, ...rest] = names
  return (
    <Field name={name} subscription={subscription}>
      {fieldState => (
        <Fields
          names={rest}
          subscription={subscription}
          originalRender={originalRender || children}
          fieldsState={{ ...fieldsState, [name]: fieldState }}
        />
      )}
    </Field>
  )
}
