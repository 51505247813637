import styled from '@emotion/styled'

import { numberToDollars } from '../../../utils/utils'

const Wrapper = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
  font-size: 14pt;
`
// TotalWithdraw
Wrapper.Title = styled.div`
  font-size: 28pt;
  font-weight: 300;
`
Wrapper.SubTitle = styled.div`
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
`
Wrapper.AdviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24pt 0;
`

Wrapper.AdviceText = styled.div`
  font-size: 16pt;
  line-height: 20pt;
  flex: 2;
`
Wrapper.GrayBox = styled.div`
  width: 511pt;
  background-color: #e6e9ea;
  padding: 12pt;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34pt;
`
Wrapper.GrayBoxInterior = styled.ul`
  padding: inherit;
`
Wrapper.EarlyWithdrawalNote = styled.div`
  font-size: 12pt;
  padding: 12pt;
  padding-bottom: 0pt;
`
Wrapper.AdviceBlueBox = styled.div`
  height: 55pt;
  width: 193pt;
  border: 1px solid #038ab7;
  background-color: rgba(79, 173, 204, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`
Wrapper.AdviceBlueBoxAmount = styled.div`
  font-size: 24pt;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`

Wrapper.Advice = ({ text }) => {
  const date = new Date()
  const month = date.toLocaleString('default', { month: 'long' })
  const dayOfTheMonth = date.getDate()
  const year = date.getFullYear()

  return (
    <Wrapper.AdviceWrapper>
      <Wrapper.AdviceText>{text}</Wrapper.AdviceText>
      <Wrapper.AdviceText>
        {month} {dayOfTheMonth}, {year} to December 31, {year}.
      </Wrapper.AdviceText>
    </Wrapper.AdviceWrapper>
  )
}

Wrapper.Divider = styled.div`
  height: 1px;
  width: 512pt;
  border: 1px solid #038ab7;
  margin: auto;
`
Wrapper.InnerWrapper = styled.div`
  padding-left: 20pt;
`
Wrapper.IncomeWrapper = styled.div`
  display: flex;
  padding: 0 24pt;
  align-items: center;
`
Wrapper.IncomeName = styled.div`
  color: ${p => p.theme.mediumGray};
  flex: 6;
`
Wrapper.IncomeAmount = styled.div`
  line-height: 38px;
  flex: 2;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`

Wrapper.Income = ({ incomeName, amount }) => {
  return (
    <Wrapper.IncomeWrapper>
      <Wrapper.IncomeName>{incomeName}</Wrapper.IncomeName>
      <Wrapper.IncomeAmount>{numberToDollars(amount, true)}</Wrapper.IncomeAmount>
    </Wrapper.IncomeWrapper>
  )
}

Wrapper.WithdrawalTotalBox = styled.div`
  height: 55pt;
  width: 511pt;
  background-color: rgba(79, 173, 204, 0.2);
  display: flex;
  padding: 0 24pt;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34pt;
`
Wrapper.WithdrawalTotalText = styled.div`
  font-size: 24pt;
`
Wrapper.WithdrawalTotalAmount = styled(Wrapper.WithdrawalTotalText)`
  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.TotalWithdrawal = ({ amount, text }) => {
  return (
    <Wrapper.WithdrawalTotalBox>
      <Wrapper.WithdrawalTotalText>{text}</Wrapper.WithdrawalTotalText>
      <Wrapper.WithdrawalTotalAmount>{numberToDollars(amount, true)}</Wrapper.WithdrawalTotalAmount>
    </Wrapper.WithdrawalTotalBox>
  )
}

Wrapper.WithdrawalText = styled.div`
  font-size: 16pt;
  line-height: 20pt;
  padding-bottom: 10pt;
`
Wrapper.AdviceParagraph = styled.div`
  font-size: 16pt;
  line-height: 20pt;
  text-align: center;
  margin-left: -20pt;
  padding: 0 40px;
`

Wrapper.RMDDisclaimer = styled.div`
  text-align: center;
`

// FundsNeededWithdraw

Wrapper.FundsTitle = styled.div`
  font-size: 28pt;
  font-weight: 300;
  line-height: 38pt;
`
Wrapper.FundsSubtitle = styled.div`
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
`

Wrapper.FundsNeededTitle = () => {
  return (
    <>
      <Wrapper.FundsTitle>How to withdraw funds needed</Wrapper.FundsTitle>
      <Wrapper.FundsSubtitle>From which accounts and investments to withdraw</Wrapper.FundsSubtitle>
    </>
  )
}

/// /////////////////////////////////////////////////////////////
// Funds Needed Withdraw Page Styling
/// /////////////////////////////////////////////////////////////

Wrapper.FundsNeededSellFromTitle = styled.div`
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
  margin-top: 15pt;

  &::before {
    content: 'From: ';
    color: ${p => p.theme.mediumGray};
  }
`
Wrapper.FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & > * {
    padding-right: 20px;
  }
`
Wrapper.FundsNeededSellFromSubType = styled.div`
  font-size: 14pt;
  line-height: 24pt;
  padding-left: 40pt;
`
Wrapper.FundsNeededSellFromSubTypeAmount = styled.div`
  font-size: 14pt;
  line-height: 24pt;
  padding-left: 40pt;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.FundsNeededSellFromTypeAmount = styled.div`
  font-size: 14pt;
  font-weight: 600;
  line-height: 20pt;
  margin-top: 15pt;

  &::before {
    content: '$';
    color: ${p => (p.dark ? p.theme.darkestGray : p.theme.lightestGray)};
  }
`
Wrapper.FundsNeededSellFromGrandTotal = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
`
Wrapper.FundsNeededSellFromGrandTotalAmount = styled.div`
  font-size: 16pt;
  font-weight: 600;
  line-height: 38pt;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.GrandTotalDisclaimer = styled.div`
  font-size: 16pt;
  line-height: 20pt;
  text-align: center;
  max-width: 430pt;
  margin: auto;
`
/// /////////////////////////////////////////////////////////////
// Before Purchase Page Styling
/// /////////////////////////////////////////////////////////////

Wrapper.BeforePurchaseTitle = styled.div`
  font-size: 28pt;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 38px;
`
Wrapper.BeforePurchaseWrapper = styled.div`
  padding: 0 24pt;
  min-width: 495pt;
`
Wrapper.BeforePurchaseBlueBox = styled.div`
  height: 107pt;
  width: 495pt;
  background-color: rgba(79, 173, 204, 0.2);
  margin: auto;
`
Wrapper.BeforePurchaseBlueBoxText = styled.div`
  font-size: 16pt;
  line-height: 20pt;
  text-align: center;
  max-width: 300pt;
  padding-top: 10pt;
  margin: auto;
`
Wrapper.BeforePurchaseBlueBoxAmount = styled.div`
  font-size: 24pt;
  line-height: 38pt;
  text-align: center;
  padding-top: 10pt;
  margin: auto;

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`
Wrapper.BeforePurchaseHowTo = styled.div`
  font-size: 20pt;
  line-height: 20pt;

  &::after {
    content: '…four choices to make, but first:';
    color: ${p => p.theme.lightestGray};
    font-size: 14pt;
  }
`
Wrapper.BeforePurchaseGreyBox = styled.div`
  margin: auto;
  width: 100%;
  background-color: #e6e9ea;
  padding: 10pt;
`
Wrapper.BeforePurchaseGreyBoxTitle = styled.div`
  color: #038ab7;
  font-size: 18pt;
  font-weight: 600;
  line-height: 18pt;
`
Wrapper.BeforePurchaseGreyBoxSubTitle = styled.div`
  padding-top: 5pt;
  color: #038ab7;
  font-size: 16pt;
  line-height: 18pt;
`
Wrapper.BeforePurchaseGreyBoxText = styled.p`
  padding-top: 5pt;
  color: ${p => p.theme.lightestGray};
  line-height: 18pt;
`

/// /////////////////////////////////////////////////////////////
// Annuity Tutorial Page Styling
/// /////////////////////////////////////////////////////////////

Wrapper.TutorialStep = styled.div`
  font-size: 20pt;
  font-weight: 600;
  line-height: 20pt;
`
Wrapper.TutorialGreyBox = styled.div`
  width: 532pt;
  background-color: #e6e9ea;
  padding: 10pt;
  margin-top: 8pt;
`
Wrapper.TutorialGreyBoxTitle = styled.div`
  color: #038ab7;
  font-size: 16pt;
  line-height: 18pt;
`
Wrapper.TutorialGreyBoxText = styled.div`
  padding-top: 2pt;
  color: ${p => p.theme.lightestGray};
  line-height: 18pt;
`
Wrapper.TutorialWrapper = styled.div`
  padding: 0 20pt;
`
Wrapper.TutorialTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 18pt;
  font-weight: 600;
  line-height: 18pt;
  padding-top: 8pt;
`
Wrapper.TutorialText = styled.div`
  color: ${p => p.theme.lightestGray};
  line-height: 18pt;
`
Wrapper.TutorialSecondaryGreyBox = styled.div`
  height: 117pt;
  width: 495pt;
  background-color: #e6e9ea;
  margin-top: 8pt;
  padding: 10pt;
`
Wrapper.TutorialSecondaryGreyBoxTitle = styled.div`
  color: #038ab7;
  font-size: 16pt;
  font-weight: 600;
  line-height: 18pt;
`
Wrapper.TutorialSecondaryGreyBoxText = styled.div`
  padding-top: 2pt;
  color: ${p => p.theme.lightestGray};
  line-height: 18pt;
`

/// /////////////////////////////////////////////////////////////
// Annuity Tutorial Page 2 Styling
/// /////////////////////////////////////////////////////////////

Wrapper.Tutorial2Title = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 19pt;
  font-weight: 600;
  font-size: 18pt;
  padding: 0 20pt;
`
Wrapper.Tutorial2Paragraph = styled.div`
  color: ${p => p.theme.mediumGray};
  padding: 0 20pt;
`
Wrapper.Tutorial2StepText = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
  padding: 0 20pt;
`
Wrapper.Tutorial2ListItem = styled.li`
  color: ${p => p.theme.mediumGray};
  line-height: 19pt;
  margin-left: 25pt;
`

/// /////////////////////////////////////////////////////////////
// Annuity Tutorial Page 3 Styling
/// /////////////////////////////////////////////////////////////

export { Wrapper }
