import { css } from '@emotion/react'
import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Page } from '../elements'
import { Wrapper } from './style'
import Logo from '../../../assets/images/GuidedChoice-logo.png'

function CoverPage(props) {
  const {
    guide: {
      date,
      guidePages: {
        pages: { findByName, addPage },
      },
      investmentWithdrawals,
    },
    store: {
      person,
      person: { displayName },
    },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'cover' })
    willMount.current = false
  }

  const config = () => {
    const fullYear = new Date().getFullYear()
    const defaultOptions = {
      guideTitle: 'Personalized \n Retirement Income Guide',
      withAnnuity:
        !_.isEmpty(investmentWithdrawals.primaryLifetimeInvestmentIncome) ||
        !_.isEmpty(investmentWithdrawals.spouseLifetimeInvestmentIncome),
      contentTitle: `Creating Monthly \n Retirement income for ${fullYear}`,
      advice: 'Follow the steps ahead to your predictable \n monthly income',
    }
    return {
      ...defaultOptions,
    }
  }
  const { guideTitle, withAnnuity, contentTitle, advice } = config()

  const page = findByName('cover')

  return (
    <Page
      header={
        <Wrapper.Header>
          <Wrapper.CoverTitle>
            {guideTitle}
            <br />
            {withAnnuity && 'with Annuity'}
          </Wrapper.CoverTitle>

          <Wrapper.CoverText>
            For: {displayName} {person.lastName}
            <br />
            Created: {date}
          </Wrapper.CoverText>

          <Wrapper.ContentsBox>
            <Wrapper.ContentCard>
              <Wrapper.ContentCard.Title>{contentTitle}</Wrapper.ContentCard.Title>
            </Wrapper.ContentCard>
            <div
              css={css`
                padding: 8pt;
              `}
            />

            <Wrapper.AdviceBox>{advice}</Wrapper.AdviceBox>

            <div
              css={css`
                padding: 33pt;
              `}
            />

            <Wrapper.LogoContainer>
              <img src={Logo} alt='Logo' />
            </Wrapper.LogoContainer>
          </Wrapper.ContentsBox>
        </Wrapper.Header>
      }
      page={page}>
      <div />
    </Page>
  )
}

export default inject('store', 'guide')(observer(CoverPage))
