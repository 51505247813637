import { Component } from 'react'
import DropDown from './Dropdown'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import TextErrorField from './TextErrorField'

const DatePickerBox = styled.div`
  display: flex;
`

const TodayLink = styled.div`
  display: inline-block;
  padding-top: 15px;
  width: 100px;
  cursor: pointer;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.4);
`

const InputWrapper = styled.div`
  min-height: 48px;
`

export default class DatePicker extends Component {
  static defaultProps = {
    showToday: false,
    disabled: false,
  }

  static propTypes = {
    maxYear: PropTypes.number.isRequired,
    minYear: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    date: PropTypes.string,
    disabled: PropTypes.bool,
    showToday: PropTypes.bool,
    showError: PropTypes.bool,
    error: PropTypes.string,
  }

  constructor(props) {
    super(props)

    const date = this.setDate(props.date)

    this.state = {
      selectedMonth: date[1],
      selectedDay: date[2],
      selectedYear: date[0],
    }
  }

  setDate = date => {
    if (date) {
      const d = date.split('-')
      return [d[0], this.pad(d[1]), this.pad(d[2])]
    } else {
      return []
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const date = this.setDate(nextProps.date)
    this.setState({
      selectedMonth: date[1],
      selectedDay: date[2],
      selectedYear: date[0],
    })
  }

  componentDidMount() {
    this.checkAllThree()
  }

  range(start, end, year = false) {
    return [...Array(1 + end - start).keys()].map(v => {
      const val = start + v
      return year ? val : this.pad(val)
    })
  }

  pad = num => {
    return ('0' + num).slice(-2)
  }

  handleToday = e => {
    const today = new Date()
    this.setState(
      {
        selectedMonth: this.pad(today.getMonth() + 1),
        selectedYear: today.getFullYear(),
        selectedDay: this.pad(today.getDate()),
      },
      () => {
        this.checkAllThree()
      }
    )
  }

  lastDay = (month, year) => {
    month = this.state.selectedMonth || month
    year = this.state.selectedYear || year || 2010
    return new Date(year, month, 0).getDate()
  }

  handleMonthChange(name, selectedMonth) {
    this.setState({ selectedMonth }, () => this.checkAllThree())
  }

  handleDayChange(name, selectedDay) {
    this.setState({ selectedDay }, () => this.checkAllThree())
  }

  handleYearChange(name, selectedYear) {
    this.setState({ selectedYear }, () => this.checkAllThree())
  }

  checkAllThree() {
    const selectedDay = parseInt(this.state.selectedDay, 10)
    const lastDay = this.lastDay()
    if (selectedDay && selectedDay <= lastDay) {
      this.raiseChange()
    } else if (this.props.hideDay && this.state.selectedMonth && this.state.selectedYear) {
      this.raiseChange()
    } else {
      isNaN(selectedDay)
        ? this.setState({ selectedDay: null })
        : this.setState({ selectedDay: '01' })
    }
  }

  raiseChange() {
    const { selectedYear, selectedMonth, selectedDay } = this.state
    const { handleChange, name } = this.props
    if (selectedYear && selectedMonth && selectedDay) {
      handleChange(name, `${selectedYear}-${selectedMonth}-${selectedDay}`)
    }
    if (this.props.hideDay && selectedYear && selectedMonth) {
      handleChange(name, `${selectedYear}-${selectedMonth}`)
    }
  }

  generateOptions(range) {
    return range.map(value => {
      return { value: value, label: value }
    })
  }

  months() {
    return this.generateOptions(this.range(1, 12))
  }

  days(month = this.state.selectedMonth) {
    if (!month) {
      return [{ value: '', label: '', disabled: true }]
    } else {
      const lastDay = this.lastDay()
      return this.generateOptions(this.range(1, lastDay))
    }
  }

  years() {
    return this.generateOptions(this.range(this.props.minYear, this.props.maxYear, true)).reverse()
  }

  monthDropDown() {
    return this.createDropDown(
      this.state.selectedMonth,
      this.months(),
      this.handleMonthChange.bind(this),
      'mm',
      '65px',
      false,
      this.props.fontSize
    )
  }

  dayDropDown() {
    return this.createDropDown(
      this.state.selectedDay,
      this.days(),
      this.handleDayChange.bind(this),
      'dd',
      '55px',
      false,
      this.props.fontSize
    )
  }

  yearDropDown() {
    return this.createDropDown(
      parseInt(this.state.selectedYear, 10),
      this.years(),
      this.handleYearChange.bind(this),
      'yyyy',
      '75px',
      false,
      this.props.fontSize
    )
  }

  createDropDown(value, options, handleChange, placeholder, width, leftMost, fontSize) {
    return (
      <DropDown
        disabled={this.props.disabled}
        fontSize={fontSize}
        leftMost={leftMost}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        selected={value}
        width={width}
        nowrap
      />
    )
  }

  render() {
    const { error, showError, hideDay } = this.props

    return (
      <InputWrapper>
        <DatePickerBox className='date-picker'>
          <span className='month'>{this.monthDropDown()}</span>
          {hideDay ? '' : <span className='day'>{this.dayDropDown()}</span>}
          <span className='year'>{this.yearDropDown()}</span>
          {this.props.showToday === true && (
            <TodayLink className='today-link' onClick={this.handleToday.bind(this)}>
              Today
            </TodayLink>
          )}
        </DatePickerBox>
        <TextErrorField {...{ error, showError }} />
      </InputWrapper>
    )
  }
}
