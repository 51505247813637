/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { ClassNames, css } from '@emotion/react'
import { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import styled from '@emotion/styled'
import createDecorator from 'final-form-calculate'
import _ from 'lodash'

import { CircleNegative, CirclePlus } from '../../../assets/icons'
import {
  SVGWrapper,
  Button,
  CircleProgressBar,
  Spacer,
  SliderWithValueUnderneath,
  FormError,
  Dropdown as DropdownElement,
  SharedModal,
  HelpIcon,
  TooltipText,
} from '../../../components'
import { numberToDollars } from '../../../utils/utils'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { RadioGroup, RadioButton } from '../../../guided-toolbox'
import { Page } from './style'
import { API } from '../../../api'

const Condition = ({ when, includes, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value.includes(includes) ? children : null)}
  </Field>
)

let primaryInvestment = []

const calculator = createDecorator(
  {
    field: 'primaryStartingAge',
    updates: {
      primarySlider: (ignoredValue, allValues) => {
        return parseInt(allValues.primaryStartingAge)
      },
      primaryAmountSelection: (ignoredValue, allValues) => {
        return null
      },
      steps: (ignoredValue, allValues) => {
        return [1]
      },
      step: async (ignoredValue, allValues) => {
        allValues.getPrimaryNewInvestmentIncome(allValues.primaryStartingAge)
        return 1
      },
    },
  },
  {
    field: 'primarySlider',
    updates: {
      primaryStartingAge: (ignoredValue, allValues) => {
        return parseInt(allValues.primarySlider)
      },
    },
  },
  {
    field: 'accounts',
    updates: {
      steps: (ignoredValue, allValues) => {
        return [1]
      },
    },
  },
  {
    field: 'primaryCustomPercentage',
    updates: {
      primaryCustomAmountSlider: (ignoredValue, allValues) => {
        const item = primaryInvestment.filter(
          item => item.balanceUsedPercent === parseInt(allValues.primaryCustomPercentage)
        )[0]
        return item ? item.balanceUsedPercent : null
      },
    },
  },
  {
    field: 'primaryCustomAmountSlider',
    updates: {
      primaryCustomPercentage: (ignoredValue, allValues) => {
        return allValues.primaryCustomAmountSlider
      },
    },
  },
  {
    field: 'primaryCustomAmountSlider',
    updates: {
      displayWarning: (ignoredValue, allValues) => {
        return allValues.primaryCustomAmountSlider > 79
      },
    },
  }
)

function SingleAnnuity({
  setDrawer,
  handleCancel,
  store: {
    totalPrimaryBalanceExcludingPensionAndAnnuity,
    setRemainingInvestmentBalance,
    processModifiedCase,
    person: { retirementAge, age },
  },
}) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await API.get('/investment-income', {
          params: {
            currentAge: age,
            startPaymentAge: retirementAge,
            investBalance: totalPrimaryBalanceExcludingPensionAndAnnuity,
          },
        })
        primaryInvestment = [...result.data]
      } catch (err) {
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  const handlePost = async values => {
    const primary = primaryInvestment.filter(
      item => item.monthlyAmount + '' === values.primaryAmountSelection
    )[0]

    const primaryLifetimeInvestmentIncome = {
      balanceUsedPercent: primary.balanceUsedPercent,
      costOfInvestment: primary.costOfInvestment,
      monthlyAmount: primary.monthlyAmount,
      recipient: 1,
      remainingInvestmentBalance: primary.remainingInvestmentBalance,
      startPaymentAge: values.primaryStartingAge,
    }

    try {
      await processModifiedCase({ primaryLifetimeInvestmentIncome })
      setRemainingInvestmentBalance(
        _.get(primaryLifetimeInvestmentIncome, 'remainingInvestmentBalance', 0)
      )
      setDrawer('')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const onSubmit = values => {
    return handlePost(values)
  }

  const percentage = []

  for (let i = 1; i <= 100; i += 1) {
    percentage.push(i + '')
  }

  const ages = (() => {
    const arr = []
    const primaryMaxAge = age > 80 ? age : 80
    for (let i = parseInt(age < 60 ? 60 : age); i <= primaryMaxAge; i++) {
      arr.push(i)
    }
    return arr
  })()

  const validate = values => {
    const errors = {}
    if (!values.primaryAmountSelection) {
      errors.primaryAmountSelection = 'Required'
    }

    return errors
  }

  const initialValues = {
    accounts: '',
    steps: [1],
    step: 1,
    primarySlider: age < 60 ? 60 : age,
    primaryStartingAge: age < 60 ? 60 : age,
    primaryAmountSelection: null,
    expandedView: false,
    displayWarning: false,
    displayedMoreThanEightyWarning: false,
    getPrimaryNewInvestmentIncome: _.debounce(async function (primaryStartingAge) {
      try {
        const result = await API.get('/investment-income', {
          params: {
            currentAge: age,
            startPaymentAge: primaryStartingAge,
            investBalance: totalPrimaryBalanceExcludingPensionAndAnnuity,
          },
        })
        primaryInvestment = [...result.data]
      } catch (err) {
        console.error(err)
      }
    }, 200),
  }

  const mutators = {
    increaseStep: (args, state, utils) => {
      utils.changeValue(state, 'step', value => value + 1)
      utils.changeValue(state, 'steps', values => [...values, state.formState.values.step])
    },
    toggleMoreOptions: (args, state, utils) => {
      utils.changeValue(state, 'expandedView', values => !state.formState.values.expandedView)
    },
    toggleWarningModal: (args, state, utils) => {
      utils.changeValue(state, 'displayWarning', values => !state.formState.values.displayWarning)
      utils.changeValue(
        state,
        'displayedMoreThanEightyWarning',
        values => !state.formState.values.displayedMoreThanEightyWarning
      )
    },
  }

  return (
    <Form
      mutators={mutators}
      decorators={[calculator]}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      subscription={{
        submitting: true,
        submitError: true,
        errors: true,
        hasSubmitErrors: true,
        hasValidationErrors: true,
        invalid: true,
        pristine: true,
        values: true,
      }}
      render={({ handleSubmit, submitting, values, form, submitError }) => (
        <MainWrapper>
          <Condition when='steps' includes={1}>
            <div>
              <StepTitle>Step 1 of 2</StepTitle>
              <Page.CardTitle>
                When would you like to begin receiving payments? You can buy more lifetime income to
                start now or later. Starting later will cost less.
              </Page.CardTitle>

              <BorderedCard>
                <div
                  css={css`
                    display: flex;
                    align-items: baseline;
                  `}>
                  <Page.Field style={{ margin: 0, marginRight: 20 }}>
                    <Field
                      name='primaryStartingAge'
                      format={value => (value === null ? undefined : value)}
                      parse={v => v}
                      subscription={{ value: true, touched: true, error: true }}
                      render={({ input, meta }) => (
                        <Dropdown meta={meta} ages={ages} input={input} />
                      )}
                    />
                  </Page.Field>

                  <Field
                    name='primarySlider'
                    format={value => (value === null ? undefined : value)}
                    parse={v => v}
                    subscription={{ value: true, touched: true, error: true }}
                    render={({ input, meta }) => (
                      <SliderWithValueUnderneath
                        min={age < 60 ? 60 : age}
                        max={age > 80 ? age : 80}
                        value={input.value}
                        onChange={input.onChange}
                        displayValue={false}
                        step='1'
                      />
                    )}
                  />
                </div>

                <Button
                  type='button'
                  onClick={form.mutators.increaseStep}
                  primary
                  label='Next'
                  width='140px'
                  css={css`
                    height: 45px;
                    align-self: flex-end;
                  `}
                />
              </BorderedCard>
            </div>
          </Condition>

          {values.displayWarning &&
            !values.displayedMoreThanEightyWarning &&
            values.primaryAmountSelection > 0 &&
            values.primaryCustomAmountSlider > 79 && (
              <SharedModal
                title='Looks like you want to use 80% or more...'
                isModalOpen={values.displayWarning && !values.displayedMoreThanEightyWarning}
                toggleModal={form.mutators.toggleWarningModal}>
                <Page.WarningText>
                  <p>
                    With rare exception, we recommend that you leave at least 20% of your nest egg
                    invested to enable you to keep up with inflation or to use for any unexpected
                    need that comes along.
                  </p>
                </Page.WarningText>

                <Page.ButtonWrapper>
                  <Button
                    primary
                    label='Got it!'
                    onClick={form.mutators.toggleWarningModal}
                    width='100px'
                  />
                </Page.ButtonWrapper>
              </SharedModal>
            )}

          <Condition when='steps' includes={2}>
            <div>
              <div
                css={css`
                  display: flex;
                  align-items: flex-start;
                `}>
                <StepTitle
                  css={css`
                    margin-right: 8px;
                  `}>
                  Step 2 of 2
                </StepTitle>
                <HelpIcon tooltip={TooltipText.fundingAnnuity()} />
              </div>
              <Page.CardTitle>
                Let's look at investing some of your balance ($
                {numberToDollars(totalPrimaryBalanceExcludingPensionAndAnnuity, true)}) to get more
                lifetime income. Amounts are before tax.
              </Page.CardTitle>

              <BorderedCard>
                <Field
                  name='primaryAmountSelection'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <ClassNames>
                      {({ css, cx }) => (
                        <RadioGroup
                          css={css`
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                          `}
                          value={input.value}
                          onChange={input.onChange}>
                          {primaryInvestment.map(item => {
                            if (
                              item.balanceUsedPercent === 75 ||
                              item.balanceUsedPercent === 50 ||
                              item.balanceUsedPercent === 25
                            ) {
                              const label = () => {
                                return (
                                  <>
                                    <Page.UseOfBalance>
                                      Use{' '}
                                      <Page.Percentage>
                                        {item.balanceUsedPercent}
                                        <Page.PercentSign>%</Page.PercentSign>
                                      </Page.Percentage>{' '}
                                      of balance
                                    </Page.UseOfBalance>
                                    <SVGText>
                                      {' '}
                                      (${numberToDollars(
                                        item.remainingInvestmentBalance,
                                        true
                                      )}{' '}
                                      remains){' '}
                                    </SVGText>
                                  </>
                                )
                              }
                              return (
                                <RadioButton
                                  key={item.balanceUsedPercent}
                                  label={label()}
                                  value={item.monthlyAmount + ''}
                                  className={cx(
                                    css`
                                      position: relative;
                                      margin-bottom: 60px;
                                      width: 200px;
                                      margin-top: 145px;
                                    `,
                                    'radio-button'
                                  )}>
                                  <div
                                    css={css`
                                      position: absolute;
                                      left: 0px;
                                      top: -150px;
                                      bottom: 0px;
                                      right: 0px;
                                    `}>
                                    <SVGContainer>
                                      <CircleProgressBar
                                        amount={item.monthlyAmount}
                                        percentage={item.balanceUsedPercent}
                                        speed={50}
                                      />
                                    </SVGContainer>
                                  </div>
                                </RadioButton>
                              )
                            }
                            return null
                          })}
                          {(meta.error || meta.submitError) && meta.touched && (
                            <Error>{meta.error || meta.submitError}</Error>
                          )}
                        </RadioGroup>
                      )}
                    </ClassNames>
                  )}
                />
                <Spacer size='12px' />
                <div
                  css={css`
                    display: flex;
                    z-index: 20;
                  `}>
                  <SVGWrapper
                    svg={values.expandedView ? CircleNegative : CirclePlus}
                    fill='#7a8e96'
                    size='large'
                    onClick={form.mutators.toggleMoreOptions}
                    css={css`
                      margin-right: 10px;
                    `}
                  />
                  <Page.Text
                    css={css`
                      cursor: pointer;
                    `}
                    onClick={form.mutators.toggleMoreOptions}>
                    More options
                  </Page.Text>
                </div>
                {values.expandedView && (
                  <>
                    <div
                      css={css`
                        display: flex;
                        margin: 20px 0;
                        align-items: center;
                      `}>
                      <Page.Field style={{ margin: 0 }}>
                        <Field
                          name='primaryCustomPercentage'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <Dropdown meta={meta} percentage={percentage} input={input} />
                          )}
                        />
                      </Page.Field>

                      <div
                        css={css`
                          margin-top: 54px;
                          margin-left: 20px;
                          width: 100%;
                        `}>
                        <Field
                          name='primaryCustomAmountSlider'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          subscription={{ value: true, touched: true, error: true }}
                          render={({ input, meta }) => (
                            <SliderWithValueUnderneath
                              min='5'
                              max='100'
                              value={input.value}
                              onChange={input.onChange}
                              displayValue={false}
                              step='1'
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div>
                      <Field
                        name='primaryAmountSelection'
                        subscription={{ value: true, touched: true, error: true }}
                        render={({ input, meta }) => (
                          <ClassNames>
                            {({ css, cx }) => (
                              <RadioGroup
                                css={css`
                                  display: flex;
                                  justify-content: space-between;
                                  flex-wrap: wrap;
                                `}
                                value={input.value}
                                onChange={input.onChange}>
                                {primaryInvestment.map(item => {
                                  if (
                                    item.balanceUsedPercent === values.primaryCustomAmountSlider
                                  ) {
                                    const label = () => {
                                      return (
                                        <>
                                          <Page.UseOfBalance>
                                            Use{' '}
                                            <Page.Percentage>
                                              {item.balanceUsedPercent}
                                              <Page.PercentSign>%</Page.PercentSign>
                                            </Page.Percentage>{' '}
                                            of balance
                                          </Page.UseOfBalance>
                                          <SVGText>
                                            {' '}
                                            ($
                                            {numberToDollars(
                                              item.remainingInvestmentBalance,
                                              true
                                            )}{' '}
                                            remains){' '}
                                          </SVGText>
                                        </>
                                      )
                                    }
                                    return (
                                      <RadioButton
                                        key={item.balanceUsedPercent}
                                        label={label()}
                                        value={item.monthlyAmount + ''}
                                        className={cx(
                                          'radio-button',
                                          css`
                                            position: relative;
                                            margin-bottom: 60px;
                                            width: 200px;
                                            margin-top: 145px;
                                          `
                                        )}>
                                        <div
                                          css={css`
                                            position: absolute;
                                            left: 0px;
                                            top: -150px;
                                            bottom: 0px;
                                            right: 0px;
                                          `}>
                                          <SVGContainer>
                                            <CircleProgressBar
                                              amount={item.monthlyAmount}
                                              percentage={item.balanceUsedPercent}
                                              speed={50}
                                            />
                                          </SVGContainer>
                                        </div>
                                      </RadioButton>
                                    )
                                  }
                                  return null
                                })}
                              </RadioGroup>
                            )}
                          </ClassNames>
                        )}
                      />
                    </div>
                  </>
                )}
              </BorderedCard>
            </div>
          </Condition>

          {submitError && <FormError err={submitError} />}
          {values.primaryAmountSelection && totalPrimaryBalanceExcludingPensionAndAnnuity === 0 && (
            <FormError
              err={
                'Your investment balance must be greater than $0 to model the purchase of an annuity.'
              }
            />
          )}

          <ButtonsBox>
            <BackButton backgroundColor='#FFFFFF' onClick={handleCancel}>
              Cancel
            </BackButton>
            <NextButton
              onClick={handleSubmit}
              disabled={
                submitting ||
                !values.primaryAmountSelection ||
                totalPrimaryBalanceExcludingPensionAndAnnuity === 0
              }>
              Recalculate
            </NextButton>
          </ButtonsBox>
        </MainWrapper>
      )}
    />
  )
}

export default inject('store')(observer(SingleAnnuity))

const MainWrapper = styled.div`
  margin-left: 15px;
  color: #7a8e96;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 19px;

  .radio-button {
    & > div {
      border-color: #4a606a;
    }
  }

  .percentageDiv > div > div > div > div::after {
    position: absolute;
    content: '%';
    color: #7a8e96;
    transform: rotate(0deg);
    right: 0px;
    top: 2px;
    font-size: 18px;
  }
`

const Error = styled.span`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
`

const BorderedCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  border: ${p => p.theme.actionItemsBoxBorder};
  border-radius: 8px;
  background-color: #ffffff;
  margin: 10px 0;
  padding: 1rem;
  padding-right: 32px; // fix
  margin-bottom: 20px; // fix
`
const SVGContainer = styled.div`
  position: relative;
  height: 160px;
  width: 200px;
  margin-right: 20px;
  cursor: pointer;
`
const SVGText = styled.span`
  font-size: 0.85rem;
  color: #7a8e96;
  position: absolute;
  left: 16%;
`

const StepTitle = styled.div`
  color: #4a606a;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 20px;
`

const Dropdown = ({ ages = [], input, percentage, meta }) => {
  if (percentage) {
    const percentageOptions = () => {
      const arr = []
      percentage.map(percent => arr.push({ label: percent, value: percent }))
      return arr
    }
    return (
      <div className='percentageDiv'>
        <DropdownElement
          error={meta.error}
          name={input.name}
          onBlur={input.onBlur}
          onChange={(name, value) => input.onChange(value)}
          options={percentageOptions()}
          placeholder=''
          selected={input.value + ''}
          showError={meta.touched}
          width='75px'
        />
      </div>
    )
  }

  const agesOption = () => {
    const arr = []
    ages.map(age => arr.push({ label: age, value: age }))
    return arr
  }

  return (
    <DropdownElement
      error={meta.error}
      name={input.name}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      options={agesOption()}
      placeholder={input.value + ''}
      selected={input.value}
      showError={meta.touched}
      width='75px'
    />
  )
}
