/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const accent = 'rt-button-accent'

const button = 'rt-button-button'

const flat = 'rt-button-flat'

const floating = 'rt-button-floating'

const icon = 'rt-button-icon'

const inverse = 'rt-button-inverse'

const mini = 'rt-button-mini'

const neutral = 'rt-button-neutral'

const primary = 'rt-button-primary'

const raised = 'rt-button-raised'

const rippleWrapper = 'rt-button-rippleWrapper'

const toggle = 'rt-button-toggle'

const squared = 'rt-button-squared'

const solid = 'rt-button-solid'

const _button = css`
  align-content: center;
  align-items: center;
  border: 0;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  font-size: ${reduceCSSCalc(`calc(1.4 * ${ds.get('unit')})`)};
  font-weight: 500;
  height: ${ds.get('button.height')};
  justify-content: center;
  letter-spacing: 0;
  line-height: ${ds.get('button.height')};
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow 0.2s ${ds.get('animation.curveFastOutLinearIn')},
    background-color 0.2s ${ds.get('animation.curveDefault')},
    color 0.2s ${ds.get('animation.curveDefault')};
  white-space: nowrap;

  ${ds.get('reset')};

  & > input {
    height: 0.1px;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0.1px;
    z-index: 0;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  & > span:not([data-react-toolbox='tooltip']) {
    display: inline-block;
    line-height: ${ds.get('button.height')};
    vertical-align: middle;
  }

  & > svg {
    display: inline-block;
    fill: currentColor;
    font-size: 120%;
    height: ${ds.get('button.height')};
    vertical-align: top;
    width: 1em;
  }

  & > * {
    pointer-events: none;
  }

  & > .${rippleWrapper} {
    overflow: hidden;
  }

  &[disabled] {
    color: ${ds.get('button.disabledTextColor')};
    cursor: auto;
    pointer-events: none;
  }
`

const _squared = css`
  border-radius: ${ds.get('button.borderRadius')};
  min-width: ${ds.get('button.squaredMinWidth')};
  padding: ${ds.get('button.squaredPadding')};

  & .${icon} {
    font-size: 120%;
    margin-right: ${ds.get('button.squaredIconMargin')};
    vertical-align: middle;
  }

  & > svg {
    margin-right: ${reduceCSSCalc(`calc(0.5 * ${ds.get('unit')})`)};
  }
`

const _solid = css`
  &[disabled] {
    background-color: ${ds.get('button.disabledBackgroundColor')};
    box-shadow: ${ds.get('shadow[2]')};
  }

  &:active {
    box-shadow: ${ds.get('shadow[2]')};
  }

  &:focus:not(:active) {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  }
`

const _raised = css`
  box-shadow: ${ds.get('shadow[2]')};
  ${_button};
  ${_squared};
  ${_solid};
`

const _flat = css`
  background: transparent;
  ${_button};
  ${_squared};
`

const _floating = css`
  border-radius: 50%;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  ${_button};
  ${_solid};
  font-size: ${ds.get('button.floatingFontSize')};
  height: ${ds.get('button.floatingHeight')};
  width: ${ds.get('button.floatingHeight')};

  & .${icon}:not([data-react-toolbox="tooltip"]) {
    line-height: ${ds.get('button.floatingHeight')};
  }

  & > .${rippleWrapper} {
    border-radius: 50%;
  }

  &.${mini} {
    font-size: ${ds.get('button.floatingMiniFontSize')};
    height: ${ds.get('button.floatingMiniHeight')};
    width: ${ds.get('button.floatingMiniHeight')};

    & .${icon} {
      line-height: ${ds.get('button.floatingMiniHeight')};
    }
  }
`

const _toggle = css`
  background: transparent;
  border-radius: 50%;
  ${_button};
  vertical-align: middle;
  width: ${ds.get('button.height')};

  & > .${icon}, & svg {
    font-size: ${ds.get('button.toggleFontSize')};
    line-height: ${ds.get('button.height')};
    vertical-align: top;
  }

  & > .${rippleWrapper} {
    border-radius: 50%;
  }
`

const _primary = css`
  &:not([disabled]) {
    &.${raised}, &.${floating} {
      background: ${ds.get('button.primaryColor')};
      color: ${ds.get('button.primaryColorContrast')};
    }

    &.${flat}, &.${toggle} {
      color: ${ds.get('button.primaryColor')};

      &:focus:not(:active) {
        background: ${ds.get('button.primaryColorHover')};
      }
    }

    &.${flat}:hover {
      background: ${ds.get('button.primaryColorHover')};
    }
  }
`

const _accent = css`
  &:not([disabled]) {
    &.${raised}, &.${floating} {
      background: ${ds.get('button.accentColor')};
      color: ${ds.get('button.accentColorContrast')};
    }

    &.${flat}, &.${toggle} {
      color: ${ds.get('button.accentColor')};

      &:focus:not(:active) {
        background: ${ds.get('button.accentColorHover')};
      }
    }

    &.flat:hover {
      background: ${ds.get('button.accentColorHover')};
    }
  }
`

const _neutral = css`
  &:not([disabled]) {
    &.${raised}, &.${floating} {
      background-color: ${ds.get('button.neutralColor')};
      color: ${ds.get('button.neutralColorContrast')};
    }

    &.${flat}, &.${toggle} {
      color: ${ds.get('button.neutralColorContrast')};

      &:focus:not(:active) {
        background: ${ds.get('button.neutralColorHover')};
      }
    }

    &.${flat}:hover {
      background: ${ds.get('button.neutralColorHover')};
    }

    &.${inverse} {
      &.${raised}, &.${floating} {
        background-color: ${ds.get('button.neutralColorContrast')};
        color: ${ds.get('button.neutralColor')};
      }

      &.${flat}, &.${toggle} {
        color: ${ds.get('button.neutralColor')};

        &:focus:not(:active) {
          background: ${ds.get('button.neutralColorHover')};
        }
      }

      &.${flat}:hover {
        background: ${ds.get('button.neutralColorHover')};
      }
    }
  }
  &.${inverse}[disabled] {
    background-color: ${ds.get('button.disabledBackgroundInverse')};
    color: ${ds.get('button.disabledTextColorInverse')};
  }
`

export default {
  accent: cx(accent, _accent),
  button: cx(button, _button),
  flat: cx(flat, _flat),
  floating: cx(floating, _floating),
  icon,
  inverse,
  mini,
  neutral: cx(neutral, _neutral),
  primary: cx(primary, _primary),
  raised: cx(raised, _raised),
  rippleWrapper,
  toggle: cx(toggle, _toggle),
  squared: cx(squared, _squared),
  solid: cx(solid, _solid),
}
