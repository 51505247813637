import variables from '../variables'
import colors from '../colors'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables
const {
  rt: { primaryDark, primaryContrast },
} = colors
const titleTotalDistance = reduceCSSCalc(`calc(8 * ${unit})`)
const hPadding = reduceCSSCalc(`calc(2.4 * ${unit})`)

const appBar = {
  color: primaryDark,
  contrast: primaryContrast,
  titleFontSize: reduceCSSCalc(`calc(2 * ${unit})`),
  titleFontWeight: 500,
  titleTotalDistance,
  height: reduceCSSCalc(`calc(6.4 * ${unit})`),
  heightMPortrait: reduceCSSCalc(`calc(5.6 * ${unit})`),
  heightMLandscape: reduceCSSCalc(`calc(4.8 * ${unit})`),
  hPadding,
  titleDistance: reduceCSSCalc(`calc(${titleTotalDistance} - ${hPadding})`),
}

export default appBar
