import styled from '@emotion/styled'

const Wrapper = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
  font-size: 14pt;
`

/// /////////////////////////////////////////////////////////////
/// Disclosure First Page Styling
/// /////////////////////////////////////////////////////////////

Wrapper.DisclosureFirstPageTitle = styled.div`
  font-size: 28pt;
  font-weight: 300;
  line-height: 20pt;
`
Wrapper.DisclosureFirstTitle = styled.div`
  font-size: 20pt;
  font-weight: 600;
  line-height: 20pt;
`
Wrapper.DisclosureFirstParagraphTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 16pt;
  line-height: 18pt;
`
Wrapper.DisclosureFirstParagraph = styled.div`
  color: ${p => p.theme.mediumGray};
  line-height: 18pt;
`

/// /////////////////////////////////////////////////////////////
/// Disclosure Second Page Styling
/// /////////////////////////////////////////////////////////////
Wrapper.DisclosureSecondFootnoteText = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14pt;
  line-height: 17pt;
`

export { Wrapper }
