import styled from '@emotion/styled'
import { API_URL } from '../../../api'

export const MainWrapper = styled.div`
  display: flex;
`

export const MainLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`

export const MainSection = styled.div`
  flex: 1 0 300px;
  display: flex;
  width: 100%;
  margin: auto;
  overflow: hidden;
`

export const MainContent = styled.div`
  flex: 8 1 auto;
  position: relative;
  overflow: auto;
  padding: 1rem;
  min-width: 254px;
  background: url(${p => API_URL + p.theme.bgLeft}) no-repeat top left;
  background-color: ${p => p.theme.homeBgColor};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`
