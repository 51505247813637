import { inject, observer } from 'mobx-react'

import ConfirmEmailForm from './ConfirmEmailForm'
import GetGuideForm from './GetGuideForm'

const templateConfig = templateId => {
  switch (templateId) {
    case 1:
    case 2:
      return <ConfirmEmailForm />

    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    default:
      return <GetGuideForm />
  }
}

function ConfirmEmail(props) {
  const { templateId } = props.store.config
  return templateConfig(templateId) || null
}

export default inject('store')(observer(ConfirmEmail))
