import variables from '../variables'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables

const ripple = {
  duration: `800ms`,
  finalOpacity: 0.3,
  size: reduceCSSCalc(`calc(15 * ${unit})`),
}

export default ripple
