import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const TodoBox = styled.div`
  background: #fff;
  border: 4px solid #0b80ad;
  box-shadow: 8px 8px 0px 0px #90bd31;
  padding: 30px 52px;
  margin: 0 50px;
`

export const ContentContainer = styled.div`
  padding: 0px;
`

export const FieldContainer = styled.div`
  padding-bottom: 20px;
  color: #06293a;
`

export const Title = styled.p`
  padding: 4px 0;
  font-size: 10pt;
  font-weight: bold;
  line-height: 1.5;
`

const BulletTextContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 4px 0;
`

const SquareBullet = styled.div`
  border: 2px solid #038ab7;
  margin-right: 12px;
  padding: 5px;
`
const CircleBullet = styled.div`
  border: 2px solid #038ab7;
  border-radius: 50%;
  margin-right: 12px;
  margin-left: 25px;
  padding: 5px;
`

export const BulletText = props => (
  <BulletTextContainer>
    <SquareBullet />
    <span
      css={css`
        font-size: 10pt;
      `}>
      {props.children}
    </span>
  </BulletTextContainer>
)
export const CircleBulletText = props => (
  <BulletTextContainer>
    <CircleBullet />
    <span
      css={css`
        font-size: 10pt;
      `}>
      {props.children}
    </span>
  </BulletTextContainer>
)

export const FooterSpacer = styled.div`
  bottom: 0;
  height: 50px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
`

export const MainTitle = styled.div`
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-weight: bold;
  line-height: 1.35;
  font-size: 22pt;
  padding: 6px 0;
  text-align: center;
`

export const ContentText = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.darkestGray};
  font-size: 10pt;
  font-style: italic;
  line-height: 1.5;
`

export const FootNote = styled.div`
  padding: 40px 30px 20px;
  font-size: 11pt;
  line-height: 1.35;
  color: ${p => p.theme.darkestGray};
  text-align: center;
`
