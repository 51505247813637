/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useEffect, useState, useMemo } from 'react'
import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import _ from 'lodash'

import {
  Button,
  Loading,
  Drawer,
  Spacer,
  FormError,
  PercentInput,
  Slider,
} from '../../../components'
import { numberToDollars, companyStocksAt100PercentForSale } from '../../../utils/utils'
import UnderstandingYourResults from '../understanding-your-results/UnderstandingYourResults'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { Page } from './style'

import CanYouCoverExpenses from './shared-components/CanYouCoverExpenses'
import WorkPartTimeAfterRetiring from './shared-components/WorkPartTimeAfterRetiring'
import ChangeRetirementAge from './shared-components/ChangeRetirementAge'
import AddAnEmergencyFund from './shared-components/AddAnEmergencyFund'
import { emergencyFundCalculator } from './utils/util'
import { schema } from './utils/validate'
import { reduceValidationError } from '../../../utils'

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

function CompanyStock(props) {
  const {
    history,
    store: {
      processModifiedCaseWithoutSaving,
      allSpendingRecommendedCases,
      spendingModifiedCases,
      totalPrimaryBalanceExcludingPensionAndAnnuity,
      emergencyFund,
      allSpendingBaseCases,
    },
  } = props

  const [modifiedMonthlyIncome, setModifiedMonthlyIncome] = useState({})
  const [status, setStatus] = React.useState('loading')
  const [numberStatus, setNumberStatus] = React.useState('idle')

  useEffect(() => {
    const fetchData = async () => {
      try {
        setModifiedMonthlyIncome(spendingModifiedCases)
        setStatus('success')
      } catch (err) {
        setStatus('error')
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  const handlePost = async (values, form) => {
    try {
      setNumberStatus('loading')
      let primaryUpdates = {}
      if (values.clientRetirement) {
        primaryUpdates = {
          primary: {
            retAge: values.clientRetirement ? parseInt(values.clientRetirement) : null,
          },
        }
      }
      const companyStocksToBeSold = values.companyStocks.filter(stock => stock.percentToSell > 0)
      const formatCompanyStockForSubmit =
        companyStocksToBeSold.length > 0
          ? companyStocksToBeSold
              .map(stock => {
                return stock.aggregatedPositionIds.map(positionId => ({
                  percentToSell: stock.percentToSell,
                  positionId: positionId,
                }))
              })
              .flat()
          : null

      const result = await processModifiedCaseWithoutSaving({
        emergencyFund: values.emergencyFundInput ? values.emergencyFundInput : null,
        ...primaryUpdates,
        primaryRetirementWork: {
          ...props.primaryRetirementStatus,
          durationStartingAge: values.durationStartingAgeClient
            ? values.durationStartingAgeClient
            : null,
          durationStoppingAge: values.durationStoppingAgeClient
            ? values.durationStoppingAgeClient
            : null,
          annualIncome: values.clientIncome,
          planningWorkPartTime: values.planningWorkPartTimeClient ? 1 : 0,
        },
        participantExpectedLifespan: parseInt(values.primarySlider),
        companyStocks: formatCompanyStockForSubmit,
      })
      setNumberStatus('success')
      setModifiedMonthlyIncome(result)
      form.mutators.resetCanCoverExpenses()
      form.mutators.hideCanCoverExpenses()
      form.mutators.displayCanCoverExpenses()
    } catch (err) {
      setNumberStatus('error')
      console.error(err)
      return { [FORM_ERROR]: 'Something went wrong, please try again later.' }
    }
  }

  const onSubmit = (values, form) => {
    document.getElementById('main-app') && (document.getElementById('main-app').scrollTop = 0)
    return handlePost(values, form)
  }

  const companyStocks = _.orderBy(
    _.get(allSpendingBaseCases, 'companyStocks', null).filter(
      companyStock => !companyStock.restricted
    ),
    [stock => stock.percentToSell, stock => stock.securityName.toLowerCase()],
    ['desc', 'asc']
  )

  const initialValues = useMemo(
    () => ({
      seeWhy: false,
      canCoverExpenses: null,
      displayCanCoverExpenses: false,
      primarySlider: _.get(
        allSpendingRecommendedCases,
        'averageMarketSpendingAdvice.primary.lifeExpectancyAge',
        null
      ),
      clientRetirement: _.get(
        spendingModifiedCases,
        'averageMarketSpendingAdvice.primary.retAge',
        null
      ),
      planningWorkPartTimeClient: Boolean(
        _.get(props, 'primaryRetirementStatus.planningWorkPartTime', null)
      ),
      durationStartingAgeClient: _.get(props, 'primaryRetirementStatus.durationStartingAge', null),
      durationStoppingAgeClient: _.get(props, 'primaryRetirementStatus.durationStoppingAge', null),
      clientIncome: _.get(props, 'primaryRetirementStatus.annualIncome', null),
      emergencyFundInput: _.get(emergencyFund, 'amount', 0),
      maximumEmergencyFundInput: totalPrimaryBalanceExcludingPensionAndAnnuity,
      companyStocks: companyStocksAt100PercentForSale(companyStocks),
    }),
    [] // eslint-disable-line
  )

  const mutators = {
    toggleSeeWhy: (args, state, utils) => {
      utils.changeValue(state, 'seeWhy', values => !state.formState.values.seeWhy)
    },
    hideCanCoverExpenses: (args, state, utils) => {
      utils.changeValue(state, 'displayCanCoverExpenses', () => false)
    },
    displayCanCoverExpenses: (args, state, utils) => {
      utils.changeValue(state, 'displayCanCoverExpenses', () => true)
    },
    resetCanCoverExpenses: (args, state, utils) => {
      utils.changeValue(state, 'canCoverExpenses', () => null)
    },
  }

  const validate = values => {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  if (status === 'error') {
    return (
      <div
        css={css`
          color: #e31e27;
          font-size: 14px;
          line-height: 1.5;
          text-align: center;
          padding: 0.25rem 0 0.75rem 0;
        `}>
        Something went wrong, please try again later.
      </div>
    )
  }

  return status === 'success' ? (
    <Page.Container>
      <Form
        keepDirtyOnReinitialize
        mutators={{ ...arrayMutators, ...mutators }}
        decorators={[emergencyFundCalculator]}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ form, handleSubmit, submitting, values, submitError }) => (
          <Page.MainWrapper>
            <span
              css={css`
                font-size: 14px;
              `}>
              Considerations
            </span>
            <Page.PageTitle>If you sell off a different amount of company stock...</Page.PageTitle>
            <Page.Wrapper>
              <Page.LeftSection>
                <Page.IncomeExpectancyCard>
                  <div
                    css={css`
                      font-size: 32px;
                      font-weight: 300;
                    `}>
                    Monthly income you can expect
                  </div>
                  <Page.IncomeValue>
                    {numberStatus === 'loading' ? (
                      <span>
                        <Loading />
                      </span>
                    ) : (
                      numberToDollars(
                        _.get(
                          modifiedMonthlyIncome,
                          'averageMarketSpendingAdvice.monthlyIncome',
                          null
                        ),
                        true
                      )
                    )}
                  </Page.IncomeValue>
                  {_.get(
                    modifiedMonthlyIncome,
                    'spendingScenario.budget.dollarAmountDesired',
                    null
                  ) <=
                    _.get(
                      modifiedMonthlyIncome,
                      'averageMarketSpendingAdvice.monthlyIncome',
                      null
                    ) && (
                    <Page.CanAffordNeeds>
                      Well done, you are meeting your desired monthly spending total, which includes
                      your income need and want!
                    </Page.CanAffordNeeds>
                  )}
                  <Button
                    type='button'
                    secondary
                    label='See why'
                    css={css`
                      background-color: #7a8e96;
                      color: #fff;
                      width: 200px;
                      height: 51px;
                    `}
                    onClick={form.mutators.toggleSeeWhy}
                  />
                </Page.IncomeExpectancyCard>
                <Drawer active={values.seeWhy} width='100%'>
                  <UnderstandingYourResults
                    setDrawer={form.mutators.toggleSeeWhy}
                    handleCancel={form.mutators.toggleSeeWhy}
                    modifiedMonthlyIncome={modifiedMonthlyIncome}
                  />
                </Drawer>
                {values.displayCanCoverExpenses && (
                  <CanYouCoverExpenses modifiedMonthlyIncome={modifiedMonthlyIncome} />
                )}
              </Page.LeftSection>
              <Page.RightSection>
                <Page.BorderedCard>
                  <Page.CardLabel>
                    Company stock in your employer retirement plan
                    <Spacer space='5px' />
                    <Page.Note>UNRESTRICTED</Page.Note>
                  </Page.CardLabel>
                  <FieldArray name='companyStocks' component={CompanyStockFields} />
                  <Spacer space='20px' />

                  <Condition when='canCoverExpenses' is={false}>
                    <div
                      css={css`
                        margin-top: 10px;
                        font-size: 18px;
                        line-height: 24px;
                        color: #4a606a;
                        position: relative;
                      `}>
                      <Page.ConsiderationTryText>
                        Try adjusting some other considerations to see what helps
                      </Page.ConsiderationTryText>
                      <Spacer space='5px' />

                      <ChangeRetirementAge modifiedMonthlyIncome={modifiedMonthlyIncome} />

                      <WorkPartTimeAfterRetiring />

                      {!_.get(emergencyFund, 'amount', 0) > 0 && <AddAnEmergencyFund />}

                      <Spacer space='10px' />
                    </div>
                  </Condition>

                  <Button
                    type='button'
                    onClick={handleSubmit}
                    disabled={submitting}
                    primary
                    label='Recalculate'
                    width='140px'
                    css={css`
                      height: 45px;
                      align-self: flex-end;
                      font-family: 'Open Sans';
                      line-height: 18px;
                      text-align: center;
                    `}
                  />

                  <Condition when='canCoverExpenses' is={false}>
                    <Spacer space='15px' />
                    <Page.ConsiderationDisclaimerText>
                      Return to Overall Strategy to review Social Security, pension, and other
                      lifetime income selections, or click "income needs & wants" to review your
                      budget
                    </Page.ConsiderationDisclaimerText>
                  </Condition>
                </Page.BorderedCard>
              </Page.RightSection>
            </Page.Wrapper>

            {submitError && <FormError err={submitError} />}

            {values.canCoverExpenses === null ? (
              <ButtonsBox
                css={css`
                  max-width: 100%;
                `}>
                <BackButton
                  css={css`
                    font-family: 'Open Sans';
                    line-height: 18px;
                    text-align: center;
                  `}
                  backgroundColor='#FFFFFF'
                  onClick={() => history.goBack()}>
                  Cancel
                </BackButton>
              </ButtonsBox>
            ) : (
              <ButtonsBox
                css={css`
                  max-width: 100%;
                  justify-content: flex-end;
                `}>
                <NextButton
                  css={css`
                    color: #ffffff;
                    font-family: 'Open Sans';
                    line-height: 18px;
                    text-align: center;
                    width: 330px;
                  `}
                  onClick={() => history.push('/spending/considerations')}>
                  Return to other considerations
                </NextButton>
              </ButtonsBox>
            )}
          </Page.MainWrapper>
        )}
      />
    </Page.Container>
  ) : (
    <Loading />
  )
}

export default inject('store')(observer(CompanyStock))

const CompanyStockFields = ({ fields }) =>
  fields.value.length > 0 && (
    <div>
      {fields.map((name, index) => (
        <Field
          key={name}
          name={`${name}.restricted`}
          render={restricted =>
            restricted.input.value ? null : (
              <Field
                name={`${name}.totalValue`}
                render={totalValue => (
                  <div>
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}>
                      <div
                        css={css`
                          flex: 0 1 300px;
                        `}>
                        <span
                          css={css`
                            color: #022a3a;
                            font-size: 18px;
                          `}>
                          Sell{' '}
                        </span>
                        <Field
                          name={`${name}.amountToSell`}
                          render={amountToSell => (
                            <Field
                              name={`${name}.percentToSell`}
                              format={v => v}
                              parse={v => v}
                              render={({ input, meta }) => (
                                <PercentInput
                                  name={input.name}
                                  value={input.value === '' ? null : input.value}
                                  onChange={(name, value) => {
                                    input.onChange(value)
                                    amountToSell.input.onChange(
                                      Math.round((value / 100) * totalValue.input.value)
                                    )
                                  }}
                                  onBlur={input.onBlur}
                                  error={meta.error}
                                  showError={false}
                                  placeholder='0'
                                  width='80px'
                                  css={css`
                                    display: inline-block;
                                    margin-right: 12px;
                                  `}
                                />
                              )}
                            />
                          )}
                        />
                        <Field
                          name={`${name}.amountToSell`}
                          render={({ input }) => (
                            <span
                              css={css`
                                color: #4a606a;
                                font-size: 14px;
                              `}>
                              (${numberToDollars(input.value, true)})
                            </span>
                          )}
                        />
                        <Field
                          name={`${name}.securityName`}
                          render={({ input }) => (
                            <div
                              css={css`
                                color: #7a8e96;
                                font-size: 14px;
                                font-weight: 300;
                                line-height: 1.35;
                              `}>
                              {input.value}
                            </div>
                          )}
                        />
                      </div>
                      <Field
                        name={`${name}.amountToSell`}
                        render={amountToSell => (
                          <Field
                            name={`${name}.percentToSell`}
                            render={percentToSell => (
                              <div
                                css={css`
                                  flex: 1 1 400px;
                                `}>
                                <Slider
                                  value={percentToSell.input.value || 0}
                                  onChange={value => {
                                    percentToSell.input.onChange(value)
                                    amountToSell.input.onChange(
                                      Math.round((value / 100) * totalValue.input.value)
                                    )
                                  }}
                                  step={1}
                                  min={0}
                                  max={100}
                                />
                              </div>
                            )}
                          />
                        )}
                      />
                    </div>
                    <div
                      css={css`
                        color: #f15d7f;
                        line-height: 1.35;
                        margin: 12px 0;
                      `}>
                      <Field
                        name={`${name}.percentToSell`}
                        validate={value => (value > 100 ? 'Cannot sell more than 100%' : undefined)}
                        render={percentToSell => percentToSell.meta.error || ''}
                      />
                    </div>
                  </div>
                )}
              />
            )
          }
        />
      ))}
    </div>
  )
