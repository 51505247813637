import styled from '@emotion/styled'

const Wrapper = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
  font-size: 14pt;
`

Wrapper.RecommendedText = styled.div`
  font-weight: 300;
`
Wrapper.PlanName = styled.div`
  font-size: 28pt;
  font-weight: 300;
  line-height: 38pt;
`
Wrapper.AdviceText = styled.div`
  font-style: italic;
  line-height: 20pt;
  color: ${p => p.theme.lightestGray};
`
Wrapper.BalanceText = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
  text-align: right;
  padding-top: 5pt;
`
Wrapper.BalanceAmount = styled.span`
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
  padding-left: 5pt;
  color: ${p => p.theme.darkestGray};

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
  }
`

export { Wrapper }
