import { useRef } from 'react'
import { observer, inject } from 'mobx-react'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'

function DisclosureThirdPage(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
    },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'disclosureThirdPage' })
    willMount.current = false
  }

  const page = findByName('disclosureThirdPage')
  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.DisclosureFirstPageTitle>The fine print</Wrapper.DisclosureFirstPageTitle>
        <Spacer space='10pt' />
        <Wrapper.DisclosureFirstTitle>Disclosures</Wrapper.DisclosureFirstTitle>
        <Spacer space='5pt' />
        <Wrapper.DisclosureFirstParagraphTitle>
          <b>For Annuity:</b>
        </Wrapper.DisclosureFirstParagraphTitle>
        <Wrapper.DisclosureFirstParagraph>
          The analysis done by GuidedChoice is based on information provided by you about your
          personal and financial circumstances. Please consult with your tax, estate, and/or
          financial advisor to review your decisions as your circumstances change. The information
          in this Guide is not written or intended to be tax or legal advice.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='8pt' />
        <Wrapper.DisclosureFirstParagraph>
          When determining from which investments you should convert/withdraw funds, we first
          consider which accounts provide the optimal tax benefits. Within the accounts themselves,
          our recommendation is to convert/withdraw from investments proportionately to maintain the
          diversification of the account. Conversions/withdrawals from certain investments may be
          subject to pre-determined deferred sales charges, transaction fees, and/or other costs
          applied by your investment account provider. Deductions of such charges, fees, and other
          costs will result in lower monthly spending amounts.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='8pt' />
        <Wrapper.DisclosureFirstParagraph>
          GuidedChoice prioritizes meeting your monthly income requirements rather than providing
          for those amounts desired for final (e.g. burial) expenses and bequests. Your ability to
          provide for these amounts, when applicable, will likely change from year to year depending
          upon your monthly income needs and changes in the market.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='8pt' />
        <Wrapper.DisclosureFirstParagraph>
          We assume retirement is a pre-requisite to the receipt of pension payments, regardless of
          the start age entered into GuidedChoice. Therefore, when applicable, pension payments are
          assumed to begin upon retirement or the payment start age, whichever is later.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='8pt' />
        <Wrapper.DisclosureFirstParagraph>
          Annuity cost estimates are based on currently available market information for a fixed
          annuity with no additional options and may change significantly from day to day. If you
          don't buy your annuity soon, we recommend that you visit GuidedChoice again to update your
          cost estimate before you make your purchase.
        </Wrapper.DisclosureFirstParagraph>
        <Spacer space='8pt' />
        <Wrapper.DisclosureFirstParagraph>
          GuidedChoice does not sell, market, endorse, or receive any compensation from any annuity
          or investment products.
        </Wrapper.DisclosureFirstParagraph>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(DisclosureThirdPage))
