import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { Spacer } from '../../../components'
import { Page, PageHeader } from '../elements'
import { ContentSubHeader, ContentText } from '../elements/styled'

import { numberToDollars } from '../../../utils'

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const InfoBox = styled.div`
  background-color: #e7f3f9;
  color: #038ab7;
  font-size: 16pt;
  padding: 10px 20px;
  margin: ${p => (p.margin ? p.margin : '5px 0')};
  line-height: 2rem;
  width: 400px;
`

const PageTitle = styled.div`
  color: #06293a;
  padding: 6px 0;
  line-height: 1.2;
`

class CatchupContributions extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'CatchupContributions' })
  }

  render() {
    const page = this.props.guide.guidePages.pages.findByName('CatchupContributions')

    const { store } = this.props
    const preTaxAnnualContributions = _.get(
      store,
      'acceptedCase.primary.preTaxAnnualContributions',
      0
    )
    const rothAnnualContributions = _.get(store, 'acceptedCase.primary.rothAnnualContributions')
    const postTaxAnnualContributions = _.get(
      store,
      'acceptedCase.primary.postTaxAnnualContributions',
      0
    )
    const preTaxCatchUp = _.get(store, 'acceptedContributionDistribution.catchUp[1].value', 0)
    const rothCatchUp = _.get(store, 'acceptedContributionDistribution.catchUp[5].value', 0)
    const afterTaxCatchUp = _.get(store, 'acceptedContributionDistribution.catchUp[2].value', 0)

    const contributionsExist =
      preTaxAnnualContributions + rothAnnualContributions + postTaxAnnualContributions > 0
    const displayCatchupPage = store.config.enableCatchupContributions && contributionsExist

    return displayCatchupPage ? (
      <Page header={<PageHeader text='Your Plan' />} page={page}>
        <PageTitle>CATCH-UP CONTRIBUTIONS</PageTitle>
        <ContentSubHeader>
          You elected to divide your contributions into catch-up vs. regular savings as below.
        </ContentSubHeader>
        <Spacer space='12px' />

        <InfoBoxWrapper>
          {preTaxAnnualContributions > 0 && (
            <InfoBox>
              Of {numberToDollars(preTaxAnnualContributions, true)}% pre-tax contribution... <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Portion to catch-up{' '}
              {numberToDollars(preTaxCatchUp, true)}% <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Portion to regular savings{' '}
              {numberToDollars(preTaxAnnualContributions - preTaxCatchUp, true)}% <br />
            </InfoBox>
          )}

          {rothAnnualContributions > 0 && (
            <InfoBox>
              Of {numberToDollars(rothAnnualContributions, true)}% Roth contribution... <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Portion to catch-up{' '}
              {numberToDollars(rothCatchUp, true)}% <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Portion to regular savings{' '}
              {numberToDollars(rothAnnualContributions - rothCatchUp, true)}% <br />
            </InfoBox>
          )}

          {postTaxAnnualContributions > 0 && (
            <InfoBox>
              Of {numberToDollars(postTaxAnnualContributions, true)}% after-tax contribution...{' '}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Portion to catch-up{' '}
              {numberToDollars(afterTaxCatchUp, true)}% <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Portion to regular savings{' '}
              {numberToDollars(postTaxAnnualContributions - afterTaxCatchUp, true)}% <br />
            </InfoBox>
          )}
        </InfoBoxWrapper>

        <Spacer space='24px' />

        <ContentText>
          Be aware: Due to rounding, you may end up under-contributing to either regular savings or
          catch-up. Check your pay stub the pay period before your last available to see if any
          adjustments need to be made. Keep in mind that if adjustments need to be made, they should
          be submitted at least five business days in advance to give your payroll enough time to
          administer your change for the last pay period's paycheck.
        </ContentText>

        <Spacer space='12px' />
      </Page>
    ) : null
  }
}

export default inject('store', 'guide')(observer(CatchupContributions))
