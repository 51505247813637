import _ from 'lodash'
import { types, flow } from 'mobx-state-tree'
import { API, API_URL } from '../api'
const { model, array, maybeNull, number, string, boolean } = types

const Document = model({
  docDate: maybeNull(string),
  docDescription: maybeNull(string),
  docName: maybeNull(string),
  docStatus: maybeNull(boolean),
  docUri: maybeNull(string),
  id: maybeNull(number),
  planId: maybeNull(number),
  recordKeeperId: maybeNull(number),
})

const DocumentsStore = model({
  documents: array(Document),
})
  .views(self => ({
    get termsOfServiceLink() {
      const termsObject = _.find(self.documents, doc => {
        return doc.docDescription === 'Terms of Service'
      })
      return (termsObject && `${API_URL}termsAndConditions?docId=${termsObject.id}`) || '#'
    },
    get disclosureLink() {
      const disclosureObject = _.find(self.documents, doc => {
        return doc.docDescription === 'Disclosure'
      })
      return (
        (disclosureObject && `${API_URL}termsAndConditions?docId=${disclosureObject.id}`) || '#'
      )
    },
    get privacyPolicyLink() {
      const privacyPolicyObject = _.find(self.documents, doc => {
        return doc.docDescription === 'Privacy Policy'
      })
      return (
        (privacyPolicyObject && `${API_URL}termsAndConditions?docId=${privacyPolicyObject.id}`) ||
        '#'
      )
    },
  }))
  .actions(self => ({
    getDocuments: flow(function* () {
      const documents = yield API.get('termsAndConditions/docs')
      self.documents = documents.data
    }),
  }))

export default DocumentsStore
