/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'
import _ from 'lodash'

const appbarFixed = 'rt-layout-appbarFixed'

const layout = 'rt-layout-layout'

const navDrawerClipped = 'rt-layout-navDrawerClipped'

const navDrawerPinned = 'rt-layout-navDrawerPinned'

const sidebarClipped = 'rt-layout-sidebarClipped'

const sidebarPinned = 'rt-layout-sidebarPinned'

const clipped = 'rt-layout-clipped'

const pinned = 'rt-layout-pinned'

const panel = 'rt-layout-panel'

const bodyScroll = 'rt-layout-bodyScroll'

const sidebarDrawer = 'rt-layout-sidebarDrawer'

const navDrawerDrawer = 'rt-layout-navDrawerDrawer'

const appbarInner = 'rt-layout-appbarInner'

const appbarAppBar = 'rt-layout-appbarAppBar'

const appbarLeftIcon = 'rt-layout-appbarLeftIcon'

const navDrawerWrapper = 'rt-layout-navDrawerWrapper'

const sidebarWrapper = 'rt-layout-sidebarWrapper'

const _layout = css`
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-width: 100%;
  position: relative;

  ${ds.get('reset')};
`

const _panel = css`
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: ${ds.get('layout.sideTransition')};

  &:not(.${bodyScroll}) {
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
  }
`

const _sidebarDrawerNavDrawerDrawer = css`
  z-index: ${ds.z('high')};

  &.${pinned} {
    box-shadow: none;
  }

  &.${clipped} {
    height: ${reduceCSSCalc(`calc(100vh - ${ds.get('appbar.height')})`)};
    padding-top: ${reduceCSSCalc(`calc(0.5 * ${ds.get('unit')})`)};
    top: ${ds.get('appbar.height')};

    @media screen and (max-width: ${ds.bp('xxs')}) and (${ds.get('portrait')}) {
      height: ${reduceCSSCalc(`calc(100vh - ${ds.get('appbar.heightMPortrait')})`)};
      top: ${ds.get('appbar.heightMPortrait')};
    }

    @media screen and (max-width: ${ds.bp('xs')}) and (${ds.get('landscape')}) {
      height: ${reduceCSSCalc(`calc(100vh - ${ds.get('appbar.heightMLandscape')})`)};
      top: ${ds.get('appbar.heightMLandscape')};
    }
  }
`

const _sidebarDrawer = css`
  ${_sidebarDrawerNavDrawerDrawer};
`

const _navDrawerDrawer = css`
  ${_sidebarDrawerNavDrawerDrawer};
`

const _appbarInner = css`
  transition: ${ds.get('layout.sideTransition')};
`

const _appbarFixed = css`
  &.${appbarAppBar} {
    z-index: ${ds.z('high')};
  }

  & .${panel} {
    height: ${reduceCSSCalc(`calc(100vh - ${ds.get('appbar.height')})`)};
    max-height: ${reduceCSSCalc(`calc(100vh - ${ds.get('appbar.height')})`)};
    top: ${ds.get('appbar.height')};

    &:not(.${bodyScroll}) {
      overflow-y: scroll;
    }

    @media screen and (max-width: ${ds.bp('xxs')}) and (${ds.get('portrait')}) {
      height: ${reduceCSSCalc(`calc(100vh - ${ds.get('appbar.heightMPortrait')})`)};
      max-height: ${reduceCSSCalc(`calc(100vh - ${ds.get('appbar.heightMPortrait')})`)};
      top: ${ds.get('appbar.heightMPortrait')};
    }

    @media screen and (max-width: ${ds.bp('xs')}) and (${ds.get('landscape')}) {
      height: ${reduceCSSCalc(`calc(100vh - ${ds.get('appbar.heightMLandscape')})`)};
      max-height: ${reduceCSSCalc(`calc(100vh - ${ds.get('appbar.heightMLandscape')})`)};
      top: ${ds.get('appbar.heightMLandscape')};
    }
  }
`

const _navDrawerPinned = css`
  & .${appbarLeftIcon} {
    display: none;
  }

  & .${panel} {
    left: ${ds.get('drawer.mobileWidth')};
  }

  &:not(.${navDrawerClipped}) {
    & .${appbarAppBar} {
      padding-left: ${reduceCSSCalc(
        `calc(${ds.get('drawer.mobileWidth')} + ${ds.get('appbar.hPadding')})`
      )};
    }
  }

  @media screen and (min-width: ${ds.bp('xs')}) {
    & .${panel} {
      left: ${ds.get('drawer.desktopWidth')};
    }

    &:not(.${navDrawerClipped}) {
      & .${appbarAppBar} {
        padding-left: ${reduceCSSCalc(
          `calc(${ds.get('drawer.desktopWidth')} + ${ds.get('appbar.hPadding')})`
        )};
      }
    }
  }
`

const _navDrawerClipped = css`
  & .${navDrawerWrapper} {
    position: relative;
    z-index: ${ds.z('normal')};
  }
`

const _sidebarPinned = css`
  & .${appbarLeftIcon} {
    display: none;
  }

  & .${panel} {
    right: ${ds.get('drawer.mobileWidth')};
  }

  &:not(.${sidebarClipped}) {
    & .${appbarAppBar} {
      padding-right: ${reduceCSSCalc(
        `calc(${ds.get('drawer.mobileWidth')} + ${ds.get('appbar.hPadding')})`
      )};
    }
  }

  @media screen and (min-width: ${ds.bp('xs')}) {
    & .${panel} {
      right: ${ds.get('drawer.desktopWidth')};
    }

    &:not(.${sidebarClipped}) {
      & .${appbarAppBar} {
        padding-right: ${reduceCSSCalc(
          `calc(${ds.get('drawer.desktopWidth')} + ${ds.get('appbar.hPadding')})`
        )};
      }
    }
  }
`

const _sidebarClipped = css`
  & .${sidebarWrapper} {
    position: relative;
    z-index: ${ds.z('normal')};
  }
`

const sidebarWidths = {}
const sidebarWidthsArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 25, 33, 50, 66, 75, 100]
const sidebarIncrements = (increment, platform) => css`
  & .${sidebarDrawer} {
    width: 100%;
  }

  @media screen and (min-width: ${ds.bp('sm')}) {
    & .${sidebarDrawer} {
      width: calc(${ds.get(`standardIncrement[${platform}]`)} * increment);
    }

    &.${sidebarPinned} {
      & .${panel} {
        right: calc(${ds.get(`standardIncrement[${platform}]`)} * increment);
      }

      &:not(.${sidebarClipped}) {
        & .${appbarAppBar} {
          padding-right: calc(
            ${ds.get(`standardIncrement[${platform}]`)} * increment + ${ds.get('appbar.hPadding')}
          );
        }
      }
    }
  }
`
_.forEach(sidebarWidthsArr, pct => {
  const firstLoop = _.includes([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], pct)
    ? sidebarIncrements(pct, 'desktop')
    : ''
  const secondLoop = _.includes([1, 2, 3, 4, 5, 6, 7, 8, 9], pct)
    ? css`
        @media screen and (min-width: ${ds.bp('xs')}) and (${ds.get('landscape')}) {
          ${sidebarIncrements(pct, 'mobile')};
        }

        @media screen and (min-width: ${ds.bp('xs')}) and (orientation: portrait) {
          ${sidebarIncrements(pct, 'desktop')};
        }
      `
    : ''
  const thirdLoop = _.includes([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], pct)
    ? css`
        @media screen and (min-width: ${ds.bp('smTablet')}) {
          ${sidebarIncrements(pct, 'desktop')};
        }
      `
    : ''
  const fourthLoop = _.includes([25, 33, 50, 66, 75, 100], pct)
    ? css`
        & .${sidebarDrawer} {
          width: 100%;
        }
        @media screen and (min-width: ${ds.bp('smTablet')}) {
          & .${panel} {
            right: ${reduceCSSCalc(`calc(${pct} * 1%)`)};
          }

          & .${sidebarDrawer} {
            width: ${reduceCSSCalc(`calc(${pct} * 1%)`)};
          }

          &:not(.${sidebarClipped}) {
            & .${appbarAppBar} {
              padding-right: ${reduceCSSCalc(
                `calc(${reduceCSSCalc(`calc(${pct} * 1%)`)} + ${ds.get('appbar.hPadding')})`
              )};
            }
          }
        }
      `
    : ''
  sidebarWidths['sidebarWidth' + pct] = css`
    ${firstLoop};
    ${secondLoop};
    ${thirdLoop};
    ${fourthLoop};
  `
})

export default {
  appbarFixed: cx(appbarFixed, _appbarFixed),
  layout: cx(layout, _layout),
  navDrawerClipped: cx(navDrawerClipped, _navDrawerClipped),
  navDrawerPinned: cx(navDrawerPinned, _navDrawerPinned),
  sidebarClipped: cx(sidebarClipped, _sidebarClipped),
  sidebarPinned: cx(sidebarPinned, _sidebarPinned),
  clipped,
  pinned,
  panel: cx(panel, _panel),
  bodyScroll,
  sidebarDrawer: cx(sidebarDrawer, _sidebarDrawer),
  navDrawerDrawer: cx(navDrawerDrawer, _navDrawerDrawer),
  appbarInner: cx(appbarInner, _appbarInner),
  appbarAppBar,
  appbarLeftIcon,
  navDrawerWrapper,
  sidebarWrapper,
  ...sidebarWidths,
}
