import { types, flow, getEnv } from 'mobx-state-tree'
import _ from 'lodash'

import { planTypeIdToAccountKey } from '../../constants'
import { API } from '../../api'
import {
  InstitutionalAccount,
  NonGCAccount,
  PensionAccount,
  AnnuityAccount,
  AccountControl,
  Investments,
} from '../../models'
import { typeToAPI } from './utils'
const { model, union, optional, compose } = types

const AccountStore = model({
  account: union(InstitutionalAccount, NonGCAccount, PensionAccount, AnnuityAccount, types.null),
  control: optional(AccountControl, {}),
})
  .views(self => ({
    get showBasics() {
      return true
    },
    get showContributions() {
      return _.includes(
        ['ira-non-deductible', 'ira-deductible', 'ira-rollover', 'ira-roth'],
        planTypeIdToAccountKey[_.get(self.account, 'planType')]
      )
    },
    get showPaycheckContributions() {
      return _.includes(
        ['401k', '403b', '457', 'payroll-deduct-ira', '401a', 'keogh', 'sep-ira', 'simple-ira'],
        planTypeIdToAccountKey[_.get(self.account, 'planType')]
      )
    },
    get showEmployerContributions() {
      return _.includes(
        ['401k', '403b', '401a', 'keogh', 'simple-ira'],
        planTypeIdToAccountKey[_.get(self.account, 'planType')]
      )
    },
    get showPayout() {
      return _.includes(['pension'], planTypeIdToAccountKey[_.get(self.account, 'planType')])
    },
    get showInvestments() {
      return _.includes(
        [
          '401k',
          '403b',
          '457',
          'payroll-deduct-ira',
          '401a',
          'keogh',
          'sep-ira',
          'simple-ira',
          'ira-non-deductible',
          'ira-deductible',
          'ira-rollover',
          'ira-roth',
          'brokerage-account',
          'other-asset',
          'annuity-fixed',
        ],
        planTypeIdToAccountKey[_.get(self.account, 'planType')]
      )
    },
    get showDeleteButton() {
      const accounts = getEnv(self).store.accounts
      const found = _.find(
        accounts,
        account => account.id === self.account.id && !account.employerSponsoredAccount
      )
      return Boolean(found)
    },
  }))
  .actions(self => ({
    getAccount: flow(function* ({ type, id }) {
      const apiPath = `${typeToAPI[type]}/${id}`
      const account = yield API.get(apiPath)
      self.account = account.data
    }),
    setAccount(account) {
      self.account = account
    },
  }))

export default compose(Investments, AccountStore)
