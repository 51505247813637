import { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { inject, observer, Provider } from 'mobx-react'
import posed, { PoseGroup } from 'react-pose'

import OnboardingStore from './OnboardingStore'
import AcceptTerms from './accept-terms/AcceptTerms'
import AccountInfo from './account-info/AccountInfo'
import ProfileInfo from './account-info/ProfileInfo'
import AccountInfoMore from './account-info-more/AccountInfoMore'
import SpouseInfo from './spouse-info/SpouseInfo'
import FirstSSIPage from './social-security-income/FirstSSIPage'
import SecondSSIPage from './social-security-income/SecondSSIPage'
import Pension from '../onboarding/pension/Dashboard'
import DependentsInfo from './dependents-info/DependentsInfo'
import ContactInfo from './contact-info/ContactInfo'
import AccumOrSpend from './accumulating-or-spending/AccumulatingOrSpending'
import Greeting from './welcome-greeting/WelcomeGreeting'
import IncomeGoal from './income-goal/IncomeGoal'
import ReacceptTerms from './reaccept-terms/ReacceptTerms'
import NewChanges from './welcome-new-changes/NewChanges'
import { Loading } from '../../components'
import { Page, NotFound } from './elements'
import { Header } from './elements/Page'
import { useTheme } from '@emotion/react'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function Onboarding(props) {
  const [onboarding] = useState(() =>
    OnboardingStore.create({ status: 'loading' }, { store: props.store })
  )
  const { features } = onboarding
  const { location } = props
  const { isRetail } = props.store.config
  const theme = useTheme()

  useEffect(() => {
    onboarding.setTheme(theme)
    document.documentElement.setAttribute('class', onboarding.background)
    return () => {
      document.documentElement.className = ''
      onboarding.setTheme(null)
    }
  })

  return (
    <Provider onboarding={onboarding}>
      <Page.Container>
        <Header />
        {onboarding.status === 'loading' && <Loading />}

        {onboarding.status === 'error' && (
          <Page.ErrorMessage>Oops! Something went wrong, please try again later</Page.ErrorMessage>
        )}

        {onboarding.status === 'done' && (
          <PoseGroup animateOnMount>
            <RouteContainer key={location.key || location.pathname}>
              <Switch location={location}>
                <Route exact path='/welcome/reaccept-terms' component={ReacceptTerms} />

                {features.enableGoals && (
                  <Route exact path='/welcome/income-goal' component={IncomeGoal} />
                )}
                <Route exact path='/welcome/saving-or-spending' component={AccumOrSpend} />

                <Route exact path='/welcome/greeting' component={Greeting} />

                {(features.enableMobilePhone || isRetail) && (
                  <Route exact path='/welcome/contact-info' component={ContactInfo} />
                )}

                {features.enableDependents && (
                  <Route exact path='/welcome/dependents' component={DependentsInfo} />
                )}

                {features.enableSpouse && (
                  <Route exact path='/welcome/spouse' component={SpouseInfo} />
                )}

                <Route exact path='/welcome/social-security-income' component={FirstSSIPage} />

                <Route exact path='/welcome/social-security-income/2' component={SecondSSIPage} />

                <Route exact path='/welcome/pension-options' component={Pension} />

                {features.enableOtherIncome && (
                  <Route exact path='/welcome/account-continued' component={AccountInfoMore} />
                )}

                <Route
                  exact
                  path='/welcome/account'
                  component={features.enablePreferredName ? AccountInfo : ProfileInfo}
                />

                <Route exact path='/welcome/new-changes' component={NewChanges} />

                <Route exact path='/welcome/accept-terms' component={AcceptTerms} />

                <Route path='/' component={NotFound} />
              </Switch>
            </RouteContainer>
          </PoseGroup>
        )}
      </Page.Container>
    </Provider>
  )
}

export default inject('store')(observer(Onboarding))
