/* eslint-disable import/no-anonymous-default-export */
import { cx, css } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const radio = 'rt-radio-radio'

const radioChecked = 'rt-radio-radioChecked'

const ripple = 'rt-radio-ripple'

const disabled = 'rt-radio-disabled'

const field = 'rt-radio-field'

const input = 'rt-radio-input'

const text = 'rt-radio-text'

const _radio = css`
  border: ${reduceCSSCalc(`calc(0.2 * ${ds.get('unit')})`)} solid ${ds.get('radio.textColor')};
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: ${ds.get('radio.buttonSize')};
  position: relative;
  vertical-align: middle;
  width: ${ds.get('radio.buttonSize')};

  ${ds.get('reset')};

  &::before {
    background-color: ${ds.get('radio.innerColor')};
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    transition: transform 0.2s ${ds.get('animation.curveDefault')};
    width: 100%;
  }

  & .${ripple} {
    background-color: ${ds.get('radio.innerColor')};
    opacity: 0.3;
    transition-duration: 650ms;
  }
`

const _radioChecked = css`
  border: ${reduceCSSCalc(`calc(0.2 * ${ds.get('unit')})`)} solid ${ds.get('radio.innerColor')};
  ${_radio};

  &::before {
    transform: scale(0.65);
  }
`

const _field = css`
  display: block;
  height: ${ds.get('radio.buttonSize')};
  margin-bottom: ${ds.get('radio.fieldMarginBottom')};
  position: relative;
  white-space: nowrap;
`

const _text = css`
  color: ${ds.get('radio.textColor')};
  display: inline-block;
  font-size: ${ds.get('radio.textFontSize')};
  line-height: ${ds.get('radio.buttonSize')};
  padding-left: ${ds.get('unit')};
  vertical-align: middle;
  white-space: nowrap;
`

const _input = css`
  appearance: none;
  border: 0;
  height: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 0;

  &:focus ~ .${radio} {
    box-shadow: 0 0 0 ${ds.get('unit')} ${ds.get('radio.focusColor')};
  }

  &:focus ~ .${radioChecked} {
    box-shadow: 0 0 0 ${ds.get('unit')} ${ds.get('radio.checkedFocusColor')};
  }
`

const _disabled = css`
  ${_field};

  & .${text} {
    color: ${ds.get('radio.disabledColor')};
  }

  & .${radio} {
    border-color: ${ds.get('radio.disabledColor')};
    cursor: auto;
  }

  & .${radioChecked} {
    border-color: ${ds.get('radio.disabledColor')};
    cursor: auto;

    &::before {
      background-color: ${ds.get('radio.disabledColor')};
    }
  }
`

export default {
  radio: cx(radio, _radio),
  radioChecked: cx(radioChecked, _radioChecked),
  ripple,
  disabled: cx(disabled, _disabled),
  field: cx(field, _field),
  input: cx(input, _input),
  text: cx(text, _text),
}
