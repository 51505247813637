/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import _ from 'lodash'

import { API } from '../../../api'
import {
  GreyText,
  LightGreyText,
  UpdateAllContainer,
  StyledFactContainer,
  BorderedStyledFactContainer,
  Description,
  AccountBalance,
  FlexContainer,
} from '../styled'
import {
  Button,
  ButtonsBox,
  CurrencyText,
  DatePicker,
  Loading,
  MultiButtonInput,
  MainTitle,
} from '../../../components'
import { planTypeIdToAccountKey, VALUATION_METHODS, TAX_TYPES } from '../../../constants'
import { trackInvestmentsByTaxType, typeToAPI } from '../utils'
import UpdateInvestmentsGroup from './UpdateInvestmentsGroup'
import { totalBalance, sortFunction } from './UpdateBalanceUtils'

class UpdateBalances extends Component {
  state = { status: 'loading' }

  fetchInitialState = async () => {
    try {
      const { type, id } = this.props.match.params
      const { getAccount, getInvestments } = this.props.account

      await Promise.all([getAccount({ type, id }), getInvestments({ type, id })])

      const {
        account: { planType },
        preTaxInvestments,
        rothInvestments,
        afterTaxInvestments,
        investedInvestments,
      } = this.props.account

      this.setState({
        status: 'done',
        planType,
        preTaxInvestments,
        rothInvestments,
        afterTaxInvestments,
        investedInvestments,
      })
    } catch (err) {
      console.error(err)
      this.setState({ status: 'error' })
    }
  }

  componentDidMount() {
    const { sidebarActive, toggleSidebar } = this.props.main
    if (sidebarActive) {
      toggleSidebar()
    }

    const { history, match } = this.props
    const { type, accountType, id } = match.params
    if (type === 'institutional') {
      return history.replace(`/accounts/${type}/${accountType}/${id}`)
    } else {
      this.fetchInitialState()
    }
  }

  componentWillUnmount() {
    const { store } = this.props
    const { sidebarActive, toggleSidebar } = this.props.main
    store.getAccounts()
    if (!sidebarActive) {
      toggleSidebar()
    }
  }

  handleCancel = () => {
    const { history, match } = this.props
    const { type, accountType, id } = match.params
    history.push(`/accounts/${type}/${accountType}/${id}`)
  }

  handleSave = async values => {
    const { match } = this.props
    const { type, id } = match.params
    const { investedInvestments } = this.props.account

    const allInvestments = [
      ...(values.preTax || []),
      ...(values.roth || []),
      ...(values.afterTax || []),
      ...(values.other || []),
    ]

    const ListOfPromises = []
    // check if single date or individual dates
    // if single date, apply the selected date across all submitted investments
    // if not single date, apply the date, cost basis, shares, and balance where applicable
    // only marketPrice valuationMethod can edit quantity (shares)
    // only afterTax tax type can edit totalCostBasis
    // balance should be taken from formValue as it updates with quantity or independently
    // only submit a PUT update request if values have changed
    _.forEach(allInvestments, formValue => {
      const foundInvestment = investedInvestments.find(investment => investment.id === formValue.id)
      if (foundInvestment) {
        const valuesToUpdate = {
          ...foundInvestment,
          purchaseDate: values.singleDate ? values.singleDateValue : formValue.purchaseDate,
          quantity:
            VALUATION_METHODS.marketPrice === formValue.valuationMethod
              ? formValue.quantity
              : foundInvestment.quantity,
          totalCostBasis:
            TAX_TYPES.afterTax === formValue.taxType
              ? formValue.totalCostBasis
              : foundInvestment.totalCostBasis,
          totalValue: formValue.balance,
        }
        if (!_.isEqual(foundInvestment, valuesToUpdate)) {
          ListOfPromises.push(API.put(`${typeToAPI[type]}/${id}/investments`, valuesToUpdate))
        }
      }
    })
    await Promise.all(ListOfPromises)

    this.handleCancel()
  }

  initialValues = () => {
    const {
      planType,
      preTaxInvestments,
      rothInvestments,
      afterTaxInvestments,
      investedInvestments,
    } = this.state

    const investmentsFormMapping = investments =>
      _.orderBy(
        investments.map(
          ({
            securities,
            purchaseDate,
            totalCostBasis,
            quantity,
            totalValue,
            id,
            securityType,
            valuationMethod,
            taxType,
          }) => ({
            securityId: securities.id,
            securityName: securities.securityName,
            price: securities.price,
            purchaseDate,
            totalCostBasis,
            quantity,
            id,
            balance: totalValue,
            securityType,
            valuationMethod,
            taxType,
          })
        ),
        investment => sortFunction(investment, 'balance'),
        ['desc']
      )

    const investments = trackInvestmentsByTaxType(planTypeIdToAccountKey[planType])
      ? {
          preTax: preTaxInvestments.length > 0 ? investmentsFormMapping(preTaxInvestments) : null,
          roth: rothInvestments.length > 0 ? investmentsFormMapping(rothInvestments) : null,
          afterTax:
            afterTaxInvestments.length > 0 ? investmentsFormMapping(afterTaxInvestments) : null,
        }
      : {
          other:
            investedInvestments.length > 0 ? investmentsFormMapping(investedInvestments) : null,
        }

    return { singleDate: null, singleDateValue: null, ...investments }
  }

  render() {
    if (this.state.status === 'loading') {
      return <Loading />
    }

    if (this.state.status === 'error') {
      return (
        <div
          css={css`
            color: crimson;
          `}>
          Something went wrong, please try again later.
        </div>
      )
    }

    const {
      account: { type, id },
    } = this.props.account
    const isEmployerAccount = !!this.props.store.employerAccounts.find(
      account => account.type === type && account.id === id
    )

    return (
      <div
        css={css`
          max-width: 1200px;
          margin: auto;
          padding: 1rem;
        `}>
        <UpdateAllContainer>
          <MainTitle>Update investment balances</MainTitle>
          <Form
            keepDirtyOnReinitialize
            onSubmit={this.handleSave}
            mutators={{ ...arrayMutators }}
            initialValues={this.initialValues()}
            subscription={{ submitting: true, submitError: true, values: true }}
            render={({ handleSubmit, submitting, values, form }) => (
              <StyledFactContainer>
                <BorderedStyledFactContainer>
                  <Field
                    name='singleDate'
                    render={({ input, meta }) => (
                      <MultiButtonInput
                        name={input.name}
                        value={input.value}
                        label='Updating from a statement? Enter a single date to be applied to all investments in this account, or specify a date for each.'
                        onChange={(name, value) => input.onChange(value)}
                        buttons={[
                          { text: 'Same Date for All', value: true },
                          { text: 'Individual Dates', value: false },
                        ]}
                        tooltip={
                          <div
                            css={css`
                              padding: 8px 0;
                            `}>
                            Select whether to enter the dates individually or apply the same date to
                            all of the investments in this account.
                          </div>
                        }
                      />
                    )}
                  />

                  {values.singleDate && (
                    <>
                      <Description>As of date</Description>
                      <FlexContainer
                        css={css`
                          margin: 0;
                          display: flex;
                        `}>
                        <Field
                          name='singleDateValue'
                          render={({ input, meta }) => (
                            <DatePicker
                              date={input.value}
                              error='Invalid date'
                              fontSize='14px'
                              handleChange={(name, value) => input.onChange(value)}
                              maxYear={new Date().getFullYear()}
                              minYear={1900}
                              name='singlePurchaseDate'
                              onBlur={input.onBlur}
                            />
                          )}
                        />
                      </FlexContainer>
                    </>
                  )}
                </BorderedStyledFactContainer>

                {((values.singleDate && values.singleDateValue) || values.singleDate === false) && (
                  <div
                    css={css`
                      margin-top: 40px;
                    `}>
                    <AccountBalance>
                      <LightGreyText>Account balance: </LightGreyText>
                      <GreyText>
                        <CurrencyText decimalScale={2} value={totalBalance(values)} />
                      </GreyText>
                    </AccountBalance>

                    <div
                      css={css`
                        margin: 40px 0;
                      `}>
                      <UpdateInvestmentsGroup
                        title='Pre-tax investments'
                        balanceLabel='Pre-tax balance:'
                        fieldName='preTax'
                        investments={values.preTax}
                        form={form}
                        enablePurchaseDate={values.singleDate === false}
                        isEmployerAccount={isEmployerAccount}
                      />
                    </div>

                    <div
                      css={css`
                        margin: 40px 0;
                      `}>
                      <UpdateInvestmentsGroup
                        balanceLabel='Roth balance:'
                        title='Roth investments'
                        fieldName='roth'
                        investments={values.roth}
                        form={form}
                        enablePurchaseDate={values.singleDate === false}
                        isEmployerAccount={isEmployerAccount}
                      />
                    </div>

                    <div
                      css={css`
                        margin: 40px 0;
                      `}>
                      <UpdateInvestmentsGroup
                        balanceLabel='After-tax balance:'
                        title='After-tax investments'
                        fieldName='afterTax'
                        investments={values.afterTax}
                        form={form}
                        enablePurchaseDate={values.singleDate === false}
                        enableCostBasis
                        isEmployerAccount={isEmployerAccount}
                      />
                    </div>

                    <div
                      css={css`
                        margin: 40px 0;
                      `}>
                      <UpdateInvestmentsGroup
                        balanceLabel='Investments balance:'
                        title='Investments'
                        fieldName='other'
                        investments={values.other}
                        form={form}
                        enablePurchaseDate={values.singleDate === false}
                        enableCostBasis
                        isEmployerAccount={isEmployerAccount}
                      />
                    </div>
                  </div>
                )}

                <ButtonsBox>
                  <Button
                    label='Cancel'
                    onClick={this.handleCancel}
                    disabled={submitting}
                    secondary
                    width='85px'
                  />

                  {/* Check if valid form for submission */}
                  {((values.singleDate && values.singleDateValue) ||
                    values.singleDate === false) && (
                    <Button
                      label='Save'
                      onClick={handleSubmit}
                      disabled={submitting}
                      primary
                      width='85px'
                    />
                  )}
                </ButtonsBox>
              </StyledFactContainer>
            )}
          />
        </UpdateAllContainer>
      </div>
    )
  }
}

export default inject('store', 'main', 'account')(observer(UpdateBalances))
