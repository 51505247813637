import { css } from '@emotion/react'
import { useState, useEffect, useCallback, useRef } from 'react'
import { inject, Observer } from 'mobx-react'
import styled from '@emotion/styled'

import brand from './brand'
import { Button, Loading, HelpIcon, TooltipText, Dropdown, Spacer } from '../../../components'
import { Dialog } from '../../../guided-toolbox'
import { InvestmentMixChart } from '../elements'
import PersonalizedStrategyIcon from './icons/PersonalizedStrategyIcon'

const GreenButton = styled.a`
  display: inline-block;
  background-color: ${brand.green};
  border-radius: 4px;
  padding: 8px 16px;
  text-align: center;
  color: white;
  font-family: 'Open Sans';
  cursor: pointer;

  &:hover {
    background-color: ${brand.hoverGreen};
  }

  &:active {
    background-color: ${brand.activeGreen};
  }

  & > div {
    pointer-events: none;
  }

  & > div:first-of-type {
    font-size: 18px;
    line-height: 1.25;
  }

  & > div:nth-of-type(2),
  div:nth-of-type(3) {
    font-size: 12px;
  }
`
const Heading = styled.div`
  font-family: Aleo;
  color: #022a3a;
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  margin: 36px 0;
`
const Text = styled.div`
  font-family: 'Open Sans';
  color: #022a3a;
  font-size: 22px;
  line-height: 1.35;
  padding: 20px;
  margin: 36px 0;
`

function PersonalizedStrategy(props) {
  const [state, setState] = useState('loading')
  const [active, setActive] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const isMounted = useRef(true)

  function handleOpen() {
    setActive(true)
  }

  function handleClose() {
    setActive(false)
  }

  const fetchBreakdown = useCallback(async () => {
    try {
      const { acceptedCaseId, getAllBreakdown } = props.store
      await getAllBreakdown(acceptedCaseId)
      if (isMounted.current) {
        setSelectedAccount(props.store.allBreakdown[0]?.name || '')
        setState('done')
      }
    } catch (err) {
      console.error(err)
      if (isMounted.current) {
        setState('error')
      }
    }
  }, [props.store])

  useEffect(() => {
    fetchBreakdown()

    return () => {
      isMounted.current = false
    }
  }, [fetchBreakdown])

  function getOptions() {
    const { allBreakdown } = props.store
    return (
      allBreakdown.reduce((acc, curr) => {
        acc.push({
          label: curr.name,
          value: curr.name,
        })
        return acc
      }, []) || []
    )
  }

  function render() {
    return (
      <div>
        <div>
          <div>
            <PersonalizedStrategyIcon
              css={css`
                width: 100%;
                height: auto;
              `}
            />
          </div>
        </div>
        <GreenButton onClick={handleOpen}>
          <div>Personalized strategy</div>
          <div>See your investments</div>
        </GreenButton>
        <Dialog
          active={active}
          onEscKeyDown={handleClose}
          onOverlayClick={handleClose}
          css={css`
            width: 92%;
            max-width: 1000px;
            max-height: 92vh;
            overflow-y: auto;
          `}>
          <Heading>Your personalized investment strategy</Heading>
          <Text>
            The right mix of investments for retirement planning manages risk while maximizing
            reward. As a managed account holder, you have access to charts that detail the mix
            created for you, from general category to individual holdings. And we'll keep you on
            track through regular rebalancing.
          </Text>

          <div
            css={css`
              margin: 0 1.5rem;
              display: flex;
              align-items: baseline;
            `}>
            <div
              css={css`
                margin-right: 1rem;
              `}>
              Select investment account{' '}
              <HelpIcon size='small' tooltip={TooltipText.selectInvestmentAccount()} />
            </div>

            <Dropdown
              name={'accounts'}
              onChange={(name, value) => {
                setSelectedAccount(value)
              }}
              options={getOptions()}
              placeholder={selectedAccount || ''}
              selected={selectedAccount}
              width={'300px'}
            />
          </div>
          <Spacer space='15px' />

          {state === 'loading' && <Loading />}

          {state === 'error' && (
            <div
              css={css`
                color: crimson;
                text-align: center;
              `}>
              Oops! Something went wrong, please try again later
            </div>
          )}

          {state === 'done' && (
            <InvestmentMixChart
              store={props.store}
              allTabsEnabled={props.store.features.allBreakdownChartsEnabled}
              selectedaccount={selectedAccount || props.store.allBreakdown[0]?.name}
            />
          )}

          <div
            css={css`
              text-align: right;
              margin: 48px 0 24px;
            `}>
            <Button secondary label='Close' onClick={handleClose} width='85px' />
          </div>
        </Dialog>
      </div>
    )
  }

  return <Observer>{render}</Observer>
}

export default inject('store')(PersonalizedStrategy)
