import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { Loading } from '../../../components'
import { InvestmentMixChart } from '../elements'

const SectionChanges = styled.div`
  margin: 24px 0 42px;
`
const HeadingChanges = styled.div`
  font-size: 1.5rem;
  color: #022a3a;
  line-height: 1.35;
`
const ParagraphChanges = styled.div`
  font-size: 1.25rem;
  color: #4a606a;
  line-height: 1.35;
`

function InvestmentMix(props) {
  const [state, setState] = useState('loading')
  const [selectedAccount, setSelectedAccount] = useState(null)

  // fetch breakdown for accepted case id
  async function fetchBreakdown() {
    try {
      const { acceptedCaseId, getAllBreakdown } = props.store
      await getAllBreakdown(acceptedCaseId)
      setState('done')
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  useEffect(() => {
    fetchBreakdown()
    setSelectedAccount(props.store.allBreakdown[0]?.name || '')
  }, []) // eslint-disable-line

  return (
    <SectionChanges>
      <HeadingChanges>Investment mix</HeadingChanges>
      <ParagraphChanges>
        A big part of getting you on track to meet your goals is to ensure that you have the right
        mix of investments working for you. GuidedChoice recommends a personalized mix of
        investments for your employer plan account, tailored to you to help you reach your goals
        while minimizing the volatility (the ups and downs) of your account.
      </ParagraphChanges>
      <div
        css={css`
          margin: 24px 0;
        `}>
        {state === 'loading' && <Loading />}
        {state === 'error' && (
          <div
            css={css`
              color: crimson;
              text-align: center;
            `}>
            Oops! Something went wrong, please try again later
          </div>
        )}
        {state === 'done' && (
          <InvestmentMixChart
            allTabsEnabled
            store={props.store}
            defaultState='investmentHoldings'
            selectedaccount={selectedAccount || props.store.allBreakdown[0]?.name}
          />
        )}
      </div>
    </SectionChanges>
  )
}

export default inject('store')(observer(InvestmentMix))
