import { useState, useEffect, useRef, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import axios from 'axios'
import _ from 'lodash'

import { API } from '../../../api'
import { Loading, ErrorPage, WizardStep } from '../../../components'
import EditBasicsCard from '../basics/EditBasicsCard'
import EditContributionsCard from '../contributions/EditContributionsCard'
import EditPaycheckContributionsCard from '../contributions/EditPaycheckContributionsCard'
import EditEmployerContributionsCard from '../contributions/EditEmployerContributionsCard'
import EditPayoutCard from '../payout/EditPayoutCard'
import EditInvestmentsCard from '../investments/EditInvestmentsCard'
// import DeleteAccountCard from '../delete-account/DeleteAccountCard'

const CancelToken = axios.CancelToken

let cancel

const getAPIpath = ({ type, id }) =>
  ({
    'institutional': 'accounts/institutionalAccount',
    'non-gc': `accounts/nonGCAccount/${id}`,
    'annuity': `annuity/${id}`,
    'pension': `pension/${id}`,
  }[type])

const getInvestmentsAPIpath = ({ type, id }) =>
  ({
    'institutional': 'accounts/institutionalAccount/investments',
    'non-gc': `accounts/nonGCAccount/${id}/investments`,
    // annuity and pension should fail
    'annuity': null,
    'pension': null,
  }[type])

const Overview = props => {
  const [status, setStatus] = useState('loading')
  const prevParamsRef = useRef(props.match.params)

  const fetchInitialState = useCallback(async () => {
    if (cancel) {
      cancel()
    }

    try {
      const { type, id } = props.match.params

      if (!_.includes(['institutional', 'non-gc', 'annuity', 'pension'], type)) {
        throw new Error('Not a valid account type')
      }

      setStatus('loading')

      // get account
      const account = await API.get(getAPIpath({ type, id }), {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
      })
      applySnapshot(props.account, { account: account.data })

      // get control
      const { id: accountId, participantId: personId } = props.account.account
      const control = await API.post('account-control/edit', null, {
        params: { accountId, personId },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
      })
      applySnapshot(props.account.control, control.data)

      // get investments
      const investmentAPIPath = getInvestmentsAPIpath({ type, id })
      const investments = investmentAPIPath
        ? await API.get(investmentAPIPath, {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            }),
          })
        : { data: [] }
      applySnapshot(props.account.investments, investments.data)

      setStatus('done')
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('request canceled') // eslint-disable-line
      } else {
        console.error(err)
        setStatus('error')
      }
    }
  }, [props.match.params, props.account])

  useEffect(() => {
    fetchInitialState()
  }, []) // eslint-disable-line

  useEffect(() => {
    const { type: prevType, id: prevId } = prevParamsRef.current
    const { type, id } = props.match.params
    if (type !== prevType || id !== prevId) {
      fetchInitialState()
    }
    prevParamsRef.current = props.match.params
  }, [props.match.params, fetchInitialState])

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return <ErrorPage />
  }

  const {
    account: {
      participantName,
      name,
      type,
      // type,
      // id
    },
    showBasics,
    showContributions,
    showPaycheckContributions,
    showEmployerContributions,
    showPayout,
    showInvestments,
    // showDeleteButton
  } = props.account

  return (
    <WizardStep title={`${participantName}'s ${name || ''} account info`}>
      {showBasics && <EditBasicsCard />}

      {showContributions && <EditContributionsCard />}

      {showPaycheckContributions && <EditPaycheckContributionsCard />}

      {showEmployerContributions && <EditEmployerContributionsCard />}

      {showPayout && <EditPayoutCard />}

      {showInvestments && type !== 'Annuity' && <EditInvestmentsCard />}
      {/* {showDeleteButton && (
          <DeleteAccountCard name={name} type={type} id={id} history={props.history} />
        )} */}
    </WizardStep>
  )
}

export default inject('account')(observer(Overview))
