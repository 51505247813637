import { css } from '@emotion/react'
import { Field } from 'react-final-form'

import { CurrencyInput, TooltipText, HelpPersonIcon } from '../../../components'
import { numberToDollars } from '../../../utils'
import { BorderedCard, CardTitle } from './EditDrawerStyle'

const MakeChangesAdditionalSavings = ({ store, editable }) => {
  return editable.additionalSavingsIsEditable ? (
    <BorderedCard>
      <CardTitle>Savings in addition to retirement & other accounts</CardTitle>

      <div
        css={css`
          display: inline-block;
          margin-right: 12px;
        `}>
        <Field
          name='additionalAnnualSavings'
          subscription={{ value: true }}
          format={v => v}
          parse={v => v}
          render={({ input }) => (
            <CurrencyInput
              name={input.name}
              value={input.value}
              onChange={(name, value) => input.onChange(value)}
              width='100px'
            />
          )}
        />
      </div>

      <Field
        name='additionalMonthlySavings'
        subscription={{ value: true }}
        format={v => v}
        parse={v => v}
        render={({ input }) => (
          <span
            css={css`
              font-size: 0.875rem;
              color: #677b84;
            `}>
            (${numberToDollars(input.value, true)} per month)
          </span>
        )}
      />

      <div
        css={css`
          font-size: 0.875rem;
          color: #677b84;
        `}>
        Additional annual savings
      </div>

      <HelpPersonIcon
        size='xLarge'
        helpLabel='Already saving to other accounts'
        tooltip={TooltipText.additionalSavings()}
      />
    </BorderedCard>
  ) : null
}

export default MakeChangesAdditionalSavings
