import styled from '@emotion/styled'

const BorderedCard = styled.div`
  border: ${p => p.theme.considerContainerBorder};
  border-radius: 8px;
  background-color: ${p => p.theme.considerContainerBgColor};
  margin: 20px 0;
  padding: 1rem;
`

const DrawerTitle = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1.5rem;
  font-weight: 600;
  padding-top: 24px;
  line-height: 1.3;
`

const TopButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
`

const BottomButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.5rem;
`

const CardTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 1.25rem;
  font-weight: 600;
`

const RetAgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div:first-of-type {
    margin-right: 36px;
  }
`

const InputLabel = styled.div`
  font-size: 0.875rem;
  color: ${p => p.theme.lightestGray};
`

const GoalField = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 13px 0;
  & > div:nth-of-type(1) {
    flex: 0 1 450px;
    & > div:nth-of-type(1) {
      color: #4a606a;
      font-size: 1.25rem;
      font-weight: 600;
      word-break: break-word;
    }
    & > div:nth-of-type(2) {
      color: ${p => p.theme.lightestGray};
      font-size: 0.875rem;
    }
    & > div:nth-of-type(3) {
      color: ${p => p.theme.lightestGray};
      font-size: 0.875rem;
      padding-top: 6px;
    }
  }
  & > div:nth-of-type(2) {
    flex: 1 1 450px;
    padding: 12px 0;
  }
`

const DollarSign = styled.span`
  font-size: 1.125rem;
  color: ${p => p.theme.lightestGray};
`

const DollarAmount = styled.span`
  color: ${p => p.theme.darkestGray};
  font-size: 1.125rem;
  font-weight: 600;
  padding-right: 6px;
`

const LoadingBox = styled.div`
  text-align: center;
  width: 100%;
`
const ContribLimitText = styled.p`
  color: ${p => p.theme.lightestGray};
  font-size: 14px;
  margin: 0px 0px 25px 0px;
`

export {
  BorderedCard,
  DrawerTitle,
  TopButtonGroup,
  BottomButtonGroup,
  CardTitle,
  RetAgeContainer,
  InputLabel,
  GoalField,
  DollarSign,
  DollarAmount,
  LoadingBox,
  ContribLimitText,
}
