import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'

import { API } from '../api'
import { CLIENT_OVERRIDES } from '../constants/client-overrides'

const { model, optional, maybeNull, boolean } = types

const Features = model({
  // Enables View Advice Only option in Value Proposition [unused]
  viewAdviceOnlyEnabled: false,

  // show/hide the "Add account" button in the left nav area
  allowAddAccounts: maybeNull(boolean),

  // show/hide the "Add goals" button in the left nav area
  allowAddGoals: maybeNull(boolean),

  // Enables Automatic Risk Level Adjustment availability
  autoRiskAdjustEnabled: false,

  // Enable all three asset class breakdown chart tabs
  allBreakdownChartsEnabled: false,

  // Enables Company Stock Feature
  companyStockEnabled: false,

  // Discontinue Managed Account Services
  discontinueOptionEnabled: maybeNull(boolean),

  // Discontinue redirects to third-party
  discontinueRedirect: maybeNull(boolean),

  // Enable Login info in profile
  editLoginInfo: maybeNull(boolean),

  // Enables Accounts domain
  enableAccounts: maybeNull(boolean),

  // Enables Additional savings option in Scenario Planning
  enableAdditionalSavings: maybeNull(boolean),

  // Allows viewing/editing Contact Info Address
  enableAddress: maybeNull(boolean),

  // Allows viewing/editing of Daytime Phone Number and optional Extension
  enableDaytimePhone: maybeNull(boolean),

  // Enables ability to include Dependents to planning
  enableDependents: maybeNull(boolean),

  // Enables Goals domain
  enableGoals: maybeNull(boolean),

  // Enables Help route
  enableHelp: maybeNull(boolean),

  // Allows viewing of Login History under Historical Date in Profile
  enableLoginHistory: maybeNull(boolean),

  // Allows viewing/editing of Mobile Phone Number
  enableMobilePhone: maybeNull(boolean),

  // Enables ability to add other earned income, annually to Profile
  enableOtherIncome: maybeNull(boolean),

  // Allows user to add a Preferred Name to use in application
  enablePreferredName: maybeNull(boolean),

  // Allows viewing of Previous Guides under Historical Date in Profile
  enablePreviousGuides: maybeNull(boolean),

  // Enables adjusting Risk level in Scenario Planning: Make Changes
  enableRiskLevelAdjustment: maybeNull(boolean),

  // Enables ability to use spending mode
  enableSpendDown: false,

  // Enables ability to include Spouse to planning
  enableSpouse: maybeNull(boolean),

  // Enables Social Security Income sections
  enableSSI: false,

  // Allows viewing/editing of the State in which the user files taxes
  enableTaxStateChange: maybeNull(boolean),

  // Enables Tools route
  enableTools: maybeNull(boolean),

  // whether or not to show managed account asterisks and labels
  managedAccountAvailable: false,

  // Enables One Time Advice Action Option [unused]
  oneTimeAdviceEnabled: false,

  // show/hide the "Would you also like investment advice for this account?" under add account
  outsideAdviceEnabled: false,

  // Show/hide the entire left nav area
  showSideBar: false,

  // Enables Stock Options Feature
  stockOptionsEnabled: false,

  // Determines blackout period alert for company stocks
  companyInsiderEnabled: false,

  // Personalized Target Date+ (PTD+) Service
  enablePersonalizedTargetDatePlus: maybeNull(boolean),

  // Allow Savings Rate Change Only
  enableSavingsRateUpdate: false,

  // Show links for Plaid Accounts Aggregation
  enablePlaidLink: maybeNull(boolean),

  // Design wants to eliminate the fast facts section in Overall Strategy, this flag disables it
  enableFastFacts: false,

  // Displays the Home page section
  enableHomePage: true,

  // Enables the ability to edit institutional account
  editInstitutionalAccount: maybeNull(boolean),

  institutionalAccountRequiresReview: maybeNull(boolean),
})

// Transform API key names to Features model names
const filterFeatures = ({
  adviceOnly,
  autoRiskLevelAdjustment,
  breakdownDisplayLevel,
  companyStock,
  enableSsi,
  managedAccount,
  oneTime,
  outsideAdvice,
  showSidebar,
  stockOptions,
  ...rest
}) => ({
  viewAdviceOnlyEnabled: Boolean(adviceOnly),
  autoRiskAdjustEnabled: Boolean(autoRiskLevelAdjustment),
  allBreakdownChartsEnabled: breakdownDisplayLevel === 'ALL',
  companyStockEnabled: Boolean(companyStock),
  enableSSI: Boolean(enableSsi),
  managedAccountAvailable: Boolean(managedAccount),
  oneTimeAdviceEnabled: Boolean(oneTime),
  outsideAdviceEnabled: Boolean(outsideAdvice),
  showSideBar: Boolean(showSidebar),
  stockOptionsEnabled: Boolean(stockOptions),
  ...rest,
})

const FeatureStore = model({
  features: optional(Features, {}),
})
  .views(self => ({
    get isRequiredToReviewRetirementAccount() {
      return self.features.institutionalAccountRequiresReview || null
    },
    get institutionalAccountUnderReview() {
      return (
        (self.features.editInstitutionalAccount &&
          self.isRequiredToReviewRetirementAccount &&
          self.institutional.active) ||
        null
      )
    },
  }))
  .actions(self => ({
    getFeatures: flow(function* () {
      const features = yield API.get('features')
      const filteredFeatures = filterFeatures(features.data || {})
      self.features = _.merge(filteredFeatures, CLIENT_OVERRIDES.features)
    }),
  }))

export default FeatureStore
