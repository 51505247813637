import { types, flow, applySnapshot } from 'mobx-state-tree'
import _ from 'lodash'
import dayjs from 'dayjs'
import * as R from 'ramda'

import { API } from '../api'

const { model, array, optional, enumeration, maybeNull, number, string, boolean } = types
const currentYearStartOfDate = dayjs().startOf('year').format('YYYY-MM-DD')

const Disbursement = model({
  amount: maybeNull(number),
  endDate: maybeNull(optional(string, currentYearStartOfDate)),
  inflationAdjustAmt: maybeNull(enumeration(['N', 'Y'])),
  inflationAdjusted: maybeNull(boolean),
  paid: maybeNull(boolean), // syncing with Engine disbursement model
  startDate: maybeNull(optional(string, currentYearStartOfDate)),
}).views(self => ({
  /**
   * @return Date || null
   */
  get startYear() {
    const startDate = dayjs(self.startDate)

    return startDate.isValid() ? startDate.year() : null
  },
  get endYear() {
    const endDate = dayjs(self.endDate)
    return endDate.isValid() ? endDate.year() : null
  },
}))

export const Goal = model({
  id: maybeNull(number),
  name: maybeNull(string),
  participantId: maybeNull(number),
  dependentId: maybeNull(number),
  disbursements: array(Disbursement),
}).views(self => ({
  /**
   * @desc gets first amount in disbursements
   */
  get amount() {
    return R.o(R.prop('amount'), R.head)(self.disbursements)
  },
  /**
   * @desc true if all amounts in disbursements are equal
   * @returns {boolean} true if same amount in all disbursements
   */
  get sameAmount() {
    // pull only unique amounts from disbursements
    const getUniqueAmounts = R.uniqBy(R.prop('amount'))(self.disbursements)
    // only true if all disbursement amounts are equal
    return getUniqueAmounts.length === 1
  },
  /**
   * @desc gets endDate of last disbursement
   */
  get endDate() {
    return R.pipe(R.last, R.prop('endDate'))(self.disbursements)
  },
  /**
   * @desc gets startDate of first disbursement
   */
  get startDate() {
    return R.pipe(R.head, R.prop('startDate'))(self.disbursements)
  },
  /**
   * @return {boolean} final disbursement has been paid or passed
   */
  get isPast() {
    const { endDate, paid } = R.last(self.disbursements)

    return dayjs().isAfter(endDate, 'year') || paid
  },
  /**
   * @desc get total disbursement amount
   * @return {Number} number of years * assumed disbursement amount
   */
  get totalNeeded() {
    // default to 0 if amount is nil
    const getSafeAmount = R.propOr(0, 'amount')
    // accumulate all safe amounts
    const safeAmountTotal = (acc, curr) => acc + getSafeAmount(curr)

    return self.disbursements.reduce(safeAmountTotal, 0)
  },
  get paid() {
    const [currentYear] = self.disbursements.filter(disb => dayjs().isSame(disb.startDate, 'year'))
    return R.propOr(false, 'paid', currentYear)
  },
  /**
   * @desc get the earliest year
   */
  get earliestYear() {
    return R.pipe(R.map(R.prop('startYear')), R.apply(Math.min))(self.disbursements)
  },
  /**
   * @desc get the last year
   */
  get latestYear() {
    return R.pipe(R.map(R.prop('endYear')), R.apply(Math.max))(self.disbursements)
  },
  /**
   * @returns {Boolean} true if One-Time disbursement, false if multiple
   */
  get type() {
    return self.disbursements.length === 1
  },
  /**
   * @desc return remaining/ valid disbursement entries
   */
  get remaining() {
    return self.disbursements.filter(disb => {
      const { endDate, paid } = disb
      const isNotPast = dayjs().isSameOrBefore(endDate, 'year')
      return isNotPast && !paid
    })
  },
  /**
   * @desc retrieve remaining disbursement amount
   * @return {Number} disbursement amount || 0
   */
  get totalRemaining() {
    const getTotal = (acc, curr) => acc + curr.amount || 0
    return self.remaining.reduce(getTotal, 0)
  },
  get yearsRemaining() {
    return self.remaining.length
  },
}))

const GoalsStore = model({
  goals: array(Goal),
})
  .views(self => ({
    get sortedGoals() {
      return _.orderBy(
        self.goals,
        [
          function (goal) {
            return goal.startDate
          },
          function (goal) {
            return goal.totalNeeded
          },
          function (goal) {
            return goal.name
          },
        ],
        ['asc', 'desc', 'asc']
      )
    },
    get currentGoals() {
      return self.sortedGoals.filter(goal => !goal.isPast)
    },
    get pastGoals() {
      return self.sortedGoals.filter(goal => goal.isPast)
    },
    /**
     * @desc generate list of goal names excluding current goal if applicable
     *
     * @param {String} currentId - goal id
     * @return {[ String ]} list of goal names
     */
    goalNames(currentId = null) {
      const getNameLower = R.o(R.toLower, R.prop('name'))
      if (R.isNil(currentId)) {
        return self.sortedGoals.map(getNameLower)
      } else {
        return self.sortedGoals.filter(goal => goal.id !== Number(currentId)).map(getNameLower)
      }
    },
    viewGoalById(id) {
      return self.sortedGoals.find(goal => goal.id === id)
    },
  }))
  .actions(self => ({
    getGoals: flow(function* () {
      const goals = yield API.get('goals')
      self.goals = _.filter(goals.data, goal => !_.isEmpty(goal.disbursements))
    }),
    addGoal: flow(function* (goalObj) {
      const goal = yield API.post('goals', goalObj)
      self.goals.push(goal.data)
    }),
    updateGoal: flow(function* (goalObj) {
      const updatedGoal = yield API.patch('goals', goalObj)
      const findGoal = goal => goal.id === updatedGoal.data.id
      applySnapshot(self.goals.find(findGoal), updatedGoal.data)
    }),
    deleteGoal: flow(function* ({ id }) {
      yield API.delete(`goals/${id}`)
      self.goals.remove(self.goals.find(goal => goal.id === id))
    }),
  }))
export default GoalsStore
