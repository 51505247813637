import { css } from '@emotion/react'
import { useState, useLayoutEffect, useRef, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { numberToDollars } from '../../../utils/utils'
import { Spacer, SVGWrapper, HelpIcon, TooltipText } from '../../../components'
import { BarChartGain, MoneyBagFullGreen, CircleNegative, CirclePlus } from '../../../assets/icons'
import Chart from './Chart'
import { Page } from './style'

function Scenario({
  title,
  scenarios,
  setDescriptionHeight,
  descriptionHeight,
  store: { person, spouse, allPensions, selectedInstitutionalPension, pensionOptions },
}) {
  const [showMore, setShowMore] = useState()
  const [scenario, setScenario] = useState({})

  const updateHeight = useCallback(() => {
    setDescriptionHeight(desc =>
      desc < ReactDOM.findDOMNode(descriptionContainer.current).getBoundingClientRect().height + 60
        ? ReactDOM.findDOMNode(descriptionContainer.current).getBoundingClientRect().height + 60
        : desc
    )
  }, [setDescriptionHeight])

  const descriptionContainer = useRef(null)

  if (descriptionContainer.current) {
    updateHeight()
  }

  let primaryInstitutionalPension = allPensions.filter(
    pension => pension.id === selectedInstitutionalPension.pensionId
  )[0]
  let survivorPaymentAmount
  if (primaryInstitutionalPension === undefined) {
    primaryInstitutionalPension = {}
  } else if (primaryInstitutionalPension !== undefined) {
    survivorPaymentAmount = pensionOptions[primaryInstitutionalPension.pensionStartAge][0].amount
  }

  useLayoutEffect(() => {
    const result = scenarios.filter(scenario => scenario.spendingScenario.scenarioName === title)[0]
    setScenario(result)

    return () => {
      setDescriptionHeight(0)
    }
  }, [scenarios, setDescriptionHeight, title, updateHeight])

  const calculateBiggestDrop = () => {
    const spendingByAges = _.get(scenario, 'averageMarketSpendingAdvice.spendingByAges', [])
    let biggestDrop = 0
    for (let i = 0; i < spendingByAges.length; i++) {
      if (
        spendingByAges[i + 1] !== undefined &&
        spendingByAges[i].lifetimeIncome > spendingByAges[i + 1].lifetimeIncome
      ) {
        if (spendingByAges[i + 1].lifetimeIncome < 0) {
          spendingByAges[i + 1].lifetimeIncome = 0
        }
        const drop = spendingByAges[i].lifetimeIncome - spendingByAges[i + 1].lifetimeIncome
        if (drop > biggestDrop) {
          biggestDrop = drop
        }
      }
    }
    return biggestDrop
  }

  return (
    <div
      css={css`
        color: #4a606a;
        width: 100%;
        padding-right: 5%;
        line-height: 1.5rem;
      `}>
      <Page.ScenarioName>{title}</Page.ScenarioName>

      <Spacer space='0.75rem' />

      <FlexWrapper>
        <Amount>
          {numberToDollars(
            _.get(scenario, 'averageMarketSpendingAdvice.monthlyIncome', null),
            true
          )}
        </Amount>
        <div>
          on{' '}
          <b
            css={css`
              color: #333;
            `}>
            average
          </b>
        </div>
        <div>Monthly retirement paycheck*</div>
        <Spacer space='0.30rem' />
        <div
          css={css`
            font-weight: 200;
          `}>
          ($
          {numberToDollars(
            _.get(scenario, 'averageMarketSpendingAdvice.monthlyIncome', null) * 12,
            true
          )}{' '}
          annually)
        </div>
        <Spacer space='0.50rem' />
        <div
          css={css`
            display: flex;
            width: 100%;
            justify-content: space-evenly;
          `}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}>
            <Page.BiggestDropText>Biggest drop in spending</Page.BiggestDropText>
            <SvgContainer>
              <BarChartGain />
            </SvgContainer>
            <div
              css={css`
                align-self: center;
              `}>
              <Page.BiggestDropValue>
                <Page.DollarSign>$</Page.DollarSign>
                {numberToDollars(calculateBiggestDrop(), true)}
              </Page.BiggestDropValue>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}>
            <Page.BiggestDropText>Max remaining balance</Page.BiggestDropText>
            <SvgContainer>
              <MoneyBagFullGreen />
            </SvgContainer>
            <div
              css={css`
                align-self: center;
              `}>
              <Page.BiggestDropValue>
                <Page.DollarSign>$</Page.DollarSign>
                {numberToDollars(0, true)} - <Page.DollarSign>$</Page.DollarSign>
                {numberToDollars(
                  _.get(scenario, 'averageMarketSpendingAdvice.remainingLegacyAmount', null),
                  true
                )}
              </Page.BiggestDropValue>
            </div>
          </div>
        </div>

        <Spacer space='0.75rem' />
        <Chart scenario={scenario} />
        <Spacer space='0.75rem' />

        <InfoCard descriptionHeight={descriptionHeight}>
          Scenario description
          <div
            ref={descriptionContainer}
            css={css`
              border: 1px solid #7a8e96;
              margin-top: 10px;
              padding: 5px;
              color: #022a3a;
            `}>
            {_.get(scenario, 'spendingScenario.scenarioDescription', null) || 'N/A'}
          </div>
        </InfoCard>

        <Spacer space='0.75rem' />
        <div
          css={css`
            cursor: pointer;
            user-select: none;
            align-self: flex-start;
          `}
          onClick={() => setShowMore(!showMore)}>
          <SVGWrapper
            css={css`
              margin-right: 10px;
            `}
            svg={showMore ? CircleNegative : CirclePlus}
            fill='#3A4D57'
            size='large'
          />
          <span>More details</span>
        </div>

        {showMore && (
          <div
            css={css`
              width: 100%;
              margin-top: 1rem;
            `}>
            {_.get(scenario, 'companyStocks', []).reduce(
              (acc, current) => acc + current.percentToSell,
              0
            ) > 0 ? (
              <>
                <InfoCard>
                  <Label>Company stock selling**</Label>
                  {_.get(scenario, 'companyStocks', []).filter(stock => stock.restricted === false)
                    .length > 0 ? (
                    <>
                      <Label>UNRESTRICTED</Label>
                      {_.get(scenario, 'companyStocks', [])
                        .filter(stock => stock.restricted === false)
                        .map(stock => {
                          return (
                            <Label>
                              {stock.securityName} percent to sell | approximate worth:{' '}
                              <Value>{stock.percentToSell}</Value>% | $
                              <Value>
                                {numberToDollars(
                                  (stock.totalValue * stock.percentToSell) / 100,
                                  true
                                )}
                              </Value>{' '}
                            </Label>
                          )
                        })}
                      <br />
                    </>
                  ) : null}

                  {_.get(scenario, 'companyStocks', []).filter(stock => stock.restricted).length >
                  0 ? (
                    <>
                      <Label>RESTRICTED</Label>
                      {_.get(scenario, 'companyStocks', [])
                        .filter(stock => stock.restricted)
                        .map(stock => {
                          return (
                            <Label>
                              {stock.securityName} percent to sell | approximate worth:{' '}
                              <Value>{stock.percentToSell}</Value>% | $
                              <Value>{numberToDollars(stock.totalValue, true)}</Value>{' '}
                            </Label>
                          )
                        })}
                      <br />
                    </>
                  ) : null}
                  <Label></Label>
                </InfoCard>
                <Spacer space='0.75rem' />
              </>
            ) : null}
            <InfoCard>
              <Label>
                First shortfall year:{' '}
                <Value>
                  {_.get(scenario, 'averageMarketSpendingAdvice.firstShortfallYear', null) > 0
                    ? _.get(scenario, 'averageMarketSpendingAdvice.firstShortfallYear', null)
                    : 'N/A'}
                </Value>
              </Label>
              <Label>
                {_.get(
                  scenario,
                  'averageMarketSpendingAdvice.survivorNameAtFirstShortfallYear',
                  null
                )
                  ? _.get(
                      scenario,
                      'averageMarketSpendingAdvice.survivorNameAtFirstShortfallYear',
                      null
                    )
                  : person.preferredName
                  ? person.preferredName
                  : person.firstName}
                's age that year:{' '}
                <Value>
                  {_.get(
                    scenario,
                    'averageMarketSpendingAdvice.survivorAgeAtFirstShortfallYear',
                    null
                  ) > 0
                    ? _.get(
                        scenario,
                        'averageMarketSpendingAdvice.survivorAgeAtFirstShortfallYear',
                        null
                      )
                    : 'N/A'}
                </Value>
              </Label>
              <br />
              <Label>
                Standard deviation of investments
                <span
                  css={css`
                    margin-left: 5px;
                  `}>
                  <HelpIcon tooltip={TooltipText.standardDeviation()} />:{' '}
                </span>
                <Value>
                  {numberToDollars(
                    _.get(
                      scenario,
                      'averageMarketSpendingAdvice.standardDeviationInvestment',
                      null
                    ),
                    false
                  )}
                </Value>
              </Label>
            </InfoCard>
            <Spacer space='0.75rem' />

            <InfoCard>
              <Label>Social Security begin age | amount</Label>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                `}>
                <Label>
                  {person.displayName}:{' '}
                  <Value>
                    {numberToDollars(
                      _.get(scenario, 'averageMarketSpendingAdvice.socialSecurity.workerAge', null),
                      true
                    )}
                  </Value>{' '}
                  <DollarSign>| $</DollarSign>
                  <Value>
                    {numberToDollars(
                      _.get(
                        scenario,
                        'averageMarketSpendingAdvice.socialSecurity.participantMonthlyPaymentBeforeTax',
                        null
                      ),
                      true
                    )}
                  </Value>
                </Label>
                {person.includeSpouse && (
                  <Label>
                    {spouse.firstName}:{' '}
                    <Value>
                      {numberToDollars(
                        _.get(
                          scenario,
                          'averageMarketSpendingAdvice.socialSecurity.spouseAge',
                          null
                        ),
                        true
                      )}
                    </Value>{' '}
                    <DollarSign>| $</DollarSign>
                    <Value>
                      {numberToDollars(
                        _.get(
                          scenario,
                          'averageMarketSpendingAdvice.socialSecurity.spouseMonthlyPaymentBeforeTax',
                          null
                        ),
                        true
                      )}
                    </Value>{' '}
                  </Label>
                )}
              </div>
              <br />
              {primaryInstitutionalPension.pensionName && (
                <>
                  <Label>
                    {primaryInstitutionalPension.pensionName} begin age | payment:{' '}
                    <Value>{primaryInstitutionalPension.pensionStartAge}</Value>{' '}
                    <DollarSign>| $</DollarSign>
                    <Value>
                      {numberToDollars(primaryInstitutionalPension.monthlyPensionIncome, true)}
                    </Value>
                  </Label>
                  <Label>
                    Survivor benefit | payment:{' '}
                    <Value>{primaryInstitutionalPension.survivorFraction}</Value>
                    <DollarSign>% | $</DollarSign>
                    <Value>{numberToDollars(survivorPaymentAmount, true)}</Value>
                  </Label>
                  <br />
                </>
              )}
              <Label>Other lifetime income modeled begin age | amount</Label>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                `}>
                <Label>
                  {person.displayName}:{' '}
                  <Value>
                    {_.get(scenario, 'primaryLifetimeInvestmentIncome.startPaymentAge', 'N/A')}
                  </Value>{' '}
                  <DollarSign>| $</DollarSign>
                  <Value>
                    {numberToDollars(
                      _.get(scenario, 'primaryLifetimeInvestmentIncome.monthlyAmount', 'N/A'),
                      true
                    )}
                  </Value>
                </Label>
                {person.includeSpouse && (
                  <Label>
                    {spouse.firstName}:{' '}
                    <Value>
                      {_.get(scenario, 'spouseLifetimeInvestmentIncome.startPaymentAge', 'N/A')}
                    </Value>{' '}
                    <DollarSign>| $</DollarSign>
                    <Value>
                      {numberToDollars(
                        _.get(scenario, 'spouseLifetimeInvestmentIncome.monthlyAmount', null),
                        true
                      )}
                    </Value>
                  </Label>
                )}
              </div>
            </InfoCard>
            <Spacer space='0.75rem' />

            <InfoCard>
              <Label>Retirement age</Label>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                `}>
                <Label>
                  {person.displayName}:{' '}
                  <Value>
                    {_.get(scenario, 'averageMarketSpendingAdvice.primary.retAge', null)}
                  </Value>
                </Label>
                {person.includeSpouse && (
                  <Label>
                    {spouse.firstName}:{' '}
                    <Value>
                      {_.get(scenario, 'averageMarketSpendingAdvice.spouse.retAge', 'N/A')}
                    </Value>
                  </Label>
                )}
              </div>
              <br />
              <div
                css={css`
                  display: flex;
                `}>
                <Label
                  css={css`
                    margin-right: 5px;
                  `}>
                  Life expectancy age
                </Label>
                <HelpIcon tooltip={TooltipText.lifeExpectancy()} />
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                `}>
                <Label>
                  {person.displayName}:{' '}
                  <Value>
                    {_.get(scenario, 'averageMarketSpendingAdvice.primary.lifeExpectancyAge', null)}
                  </Value>
                </Label>
                {person.includeSpouse && (
                  <Label>
                    {spouse.firstName}:{' '}
                    <Value>
                      {_.get(
                        scenario,
                        'averageMarketSpendingAdvice.spouse.lifeExpectancyAge',
                        'N/A'
                      )}
                    </Value>
                  </Label>
                )}
              </div>
            </InfoCard>
            <Spacer space='0.75rem' />

            <InfoCard>
              <Label>Monthly spending budget</Label>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                `}>
                <Label>
                  Need: {_.get(scenario, 'spendingScenario.budget.dollarAmountNeeded', null) && '$'}
                  <Value>
                    {numberToDollars(
                      _.get(scenario, 'spendingScenario.budget.dollarAmountNeeded', null),
                      true
                    ) || 'N/A'}
                  </Value>
                </Label>
                <Label>
                  Need + Want:{' '}
                  {_.get(scenario, 'spendingScenario.budget.dollarAmountDesired', null) && '$'}
                  <Value>
                    {numberToDollars(
                      _.get(scenario, 'spendingScenario.budget.dollarAmountDesired', null),
                      true
                    ) || 'N/A'}
                  </Value>
                </Label>
              </div>
              <br />
              <Label>
                Emergency fund: {_.get(scenario, 'spendingScenario.emergencyFund', null) && '$'}
                <Value>
                  {numberToDollars(_.get(scenario, 'spendingScenario.emergencyFund', null), true) ||
                    'N/A'}
                </Value>
              </Label>
              <br />
              <Label>
                End-of-life expenses:{' '}
                <Value>
                  {_.get(scenario, 'spendingScenario.oneTimeExpense.totalEndOfLifeExpense', null) >
                  0
                    ? '$' +
                      numberToDollars(
                        _.get(
                          scenario,
                          'spendingScenario.oneTimeExpense.totalEndOfLifeExpense',
                          null
                        ),
                        true
                      )
                    : 'N/A'}
                </Value>
              </Label>
              <br />
              <Label>
                Bequest:{' '}
                <Value>
                  {_.get(scenario, 'spendingScenario.oneTimeExpense.totalBequestExpense', null) > 0
                    ? '$' +
                      numberToDollars(
                        _.get(
                          scenario,
                          'spendingScenario.oneTimeExpense.totalBequestExpense',
                          null
                        ),
                        true
                      )
                    : 'N/A'}
                </Value>
              </Label>
              <br />
              <Label>Other goals</Label>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                `}>
                <Label>
                  Future amount needed for all: $
                  <Value>
                    {numberToDollars(
                      _.get(scenario, 'spendingScenario.spendingGoals', []).reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue.disbursements[0].amount,
                        0
                      ),
                      true
                    )}
                  </Value>
                </Label>
                <Label>
                  Included goals:{' '}
                  {_.get(scenario, 'spendingScenario.spendingGoals', []).map((goal, i, array) => (
                    <Value key={goal.name}>
                      {goal.name}
                      {i === array.length - 1 ? '' : ', '}
                    </Value>
                  ))}
                </Label>
              </div>
            </InfoCard>
          </div>
        )}
      </FlexWrapper>
    </div>
  )
}

export default inject('store')(observer(Scenario))

const Amount = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: #333;
  margin-bottom: 5px;
  &::before {
    content: '$';
    color: #4d9927;
    padding-right: 3px;
  }
`
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
const SvgContainer = styled.div`
  height: 50px;
  width: 50px;
  align-self: center;
`
const InfoCard = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e9ea;
  background-color: #f0f4f5;
  box-shadow: 0 2px 2px 0 rgba(74, 96, 106, 0.6);
  height: ${({ descriptionHeight }) =>
    descriptionHeight > 0 ? `${descriptionHeight}px}` : '100%'};
`

const Label = styled.div`
  line-height: 1.5rem;
`
const Value = styled.span`
  color: #022a3a;
  font-weight: 600;
`
const DollarSign = styled.span`
  color: ${p => p.theme.lightestGray};
`
