import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import { numberToDollars } from '../../../utils'
import { Layout } from '../elements'
import PersonalStrategy from './PersonalStrategy'
import ManagedBenefits from './ManagedBenefits'
import ValuePropButtons from './ValuePropButtons'

const TitleBlockStyles = styled.div`
  font-family: Aleo;
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: #022a3a;
  margin: 56px 24px 24px;
`
const TitleBlock = ({ ...props }) => (
  <TitleBlockStyles {...props}>
    Let us help you grow your assets over time for a more comfortable retirement
  </TitleBlockStyles>
)

const Divider = styled.div`
  border-top: 1px solid #7a8e96;
  margin: 20px;
`

const Disclaimer = styled.div`
  font-family: 'Open Sans';
  color: #7a8e96;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.42;
  text-align: center;
  margin: 20px 20px 64px;
`

function ValueProposition(props) {
  const { baseCase, acceptedCase } = props.store

  const estimatedMonthlyDifference =
    acceptedCase.estimatedTotalMonthlyIncome - baseCase.estimatedTotalMonthlyIncome

  return (
    <Layout.Container>
      <TitleBlock />

      <PersonalStrategy />

      <Divider />

      <ManagedBenefits />

      <ValuePropButtons />

      <Disclaimer>
        *Projected monthly after-tax income is based on an average market performance. Life
        expectancy is determined using standard mortality tables plus 10 years. When planning as a
        couple, we use the furthest mortality date.{' '}
        {estimatedMonthlyDifference > 0
          ? `Income difference over current strategy based on
        $${numberToDollars(
          estimatedMonthlyDifference,
          true
        )} more each month from accepted strategy retirement age. Each year is counted as a full 12 months,
        regardless of birth date.`
          : ''}
      </Disclaimer>
    </Layout.Container>
  )
}

export default inject('store')(observer(ValueProposition))
