import { Field, FormSpy } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import { ButtonInput } from '../../../components'
import { numberToDollars } from '../../../utils'
import { BorderedCard, GoalField, DollarSign, DollarAmount } from './EditDrawerStyle'

const MakeChangesGoals = ({ store, editable }) => {
  return (
    editable.goalsIsEditable && (
      <BorderedCard>
        <FieldArray name='goals'>
          {({ fields }) =>
            fields.map((name, index) => (
              <GoalField key={name}>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => {
                    const { name, totalRemaining, yearsRemaining } = store.viewGoalById(
                      values.goals[index].id
                    )
                    return (
                      <div>
                        <div>Goal: {name}</div>
                        <div>
                          <DollarSign>$</DollarSign>
                          <DollarAmount>{numberToDollars(totalRemaining, true)}</DollarAmount>{' '}
                          Future annual amount needed
                        </div>
                        <div>
                          (Total future value amounts of ${numberToDollars(totalRemaining, true)}{' '}
                          over {yearsRemaining} {yearsRemaining === 1 ? 'year' : 'years'})
                        </div>
                      </div>
                    )
                  }}
                </FormSpy>
                <Field
                  name={`${name}.active`}
                  subscription={{ value: true }}
                  render={({ input, meta }) => (
                    <div>
                      <ButtonInput
                        name='include'
                        text='Include'
                        isActive={input.value}
                        value
                        onClick={(name, value) => input.onChange(value)}
                        width='200px'
                      />
                      <ButtonInput
                        name='exclude'
                        text='Exclude'
                        isActive={!input.value}
                        value={false}
                        onClick={(name, value) => input.onChange(value)}
                        width='200px'
                      />
                    </div>
                  )}
                />
              </GoalField>
            ))
          }
        </FieldArray>
      </BorderedCard>
    )
  )
}

export default MakeChangesGoals
