/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { FieldArray } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import dayjs from 'dayjs'
import _ from 'lodash'

import { API } from '../../../api'
import { VALUATION_METHODS, TAX_TYPES } from '../../../constants'
import {
  NumberInput,
  CurrencyInput,
  TextErrorField,
  DatePicker,
  DeleteModal,
  PlanOffersOptionModal,
  ConfirmModal,
} from '../../../components'
import { placeholderSymbolReplace, numberToDollars } from '../../../utils'
import { typeToAPI } from '../utils'
import {
  TableTitle,
  TableHeader,
  TableList,
  TableRow,
  SortArrow,
  DeleteIcon,
  DeleteIconColumn,
  InvestmentNameColumn,
  AsOfDateColumn,
  CostBasisColumn,
  SharesColumn,
  BalanceColumn,
  ResponsiveLabel,
  breakpoint,
} from './UpdateInvestmentsGroupStyle'
import { calculateBalance, sortFunction, sortActive } from './UpdateBalanceUtils'

class UpdateInvestmentsGroup extends Component {
  state = { sortBy: 'balance', sortOrder: 'desc', deleteModal: 'closed', investmentToDelete: null }

  handleSortChange = sortBy => {
    let { sortBy: currentSortBy, sortOrder } = this.state
    const { form, fieldName, investments } = this.props

    if (sortBy === currentSortBy) {
      sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
      this.setState({ sortBy, sortOrder })

      const sortedValue = _.orderBy(investments, investment => sortFunction(investment, sortBy), [
        sortOrder,
      ])

      form.change(fieldName, sortedValue)
    } else {
      this.setState({ sortBy, sortOrder: 'desc' })

      const sortedValue = _.orderBy(investments, investment => sortFunction(investment, sortBy), [
        'desc',
      ])

      form.change(fieldName, sortedValue)
    }
  }

  handleSort = values => {
    const { sortBy, sortOrder } = this.state
    const { form, fieldName } = this.props

    const sortedValue = _.orderBy(values, investment => sortFunction(investment, sortBy), [
      sortOrder,
    ])

    form.change(fieldName, sortedValue)
  }

  handleDelete = investmentToDelete => {
    // If account is employer-related and is only investment with this security across accepted tax types,
    // ask if their plan still offers investment option, otherwise handle delete normally
    // If plan offer exists, change investment to Not Invested, otherwise delete normally

    const { isEmployerAccount, form } = this.props
    const { preTax, roth, afterTax, other } = form.getState().values
    const allInvestments = [
      ...(preTax || []),
      ...(roth || []),
      ...(afterTax || []),
      ...(other || []),
    ]
    const lastSecurityLeft =
      allInvestments.filter(investment => investment.securityId === investmentToDelete.securityId)
        .length === 1

    if (isEmployerAccount && lastSecurityLeft) {
      this.setState({ deleteModal: 'planOffer', investmentToDelete })
    } else {
      this.setState({ deleteModal: 'confirm', investmentToDelete })
    }
  }

  handlePlanOffer = value => {
    this.setState({ deleteModal: value ? 'confirmPlanOffer' : 'confirmNonPlanOffer' })
  }

  handleConfirmDelete = () => {
    // TO-DO: figure out how to handle async/await for updating/deleting
    // determine Not Invested ( confirmPlanOffer ) or Delete ( confirmNonPlanOffer || confirm )
    const { deleteModal, investmentToDelete } = this.state
    const { investedInvestments } = this.props.account
    const foundInvestment = investedInvestments.find(
      investment => investment.id === investmentToDelete.id
    )

    const {
      store,
      match: {
        params: { type, id },
      },
    } = this.props

    if (deleteModal === 'confirmPlanOffer') {
      API.put(`${typeToAPI[type]}/${id}/investments`, {
        ...foundInvestment,
        dateOfUserInfo: dayjs().format('YYYY-MM-DD'),
        quantity: 0,
        totalCostBasis: 0,
        totalValue: 0,
        valuationMethod: VALUATION_METHODS.notInvested,
        taxType: TAX_TYPES.notInvested,
      }).then(res => store.getAccounts())
    } else {
      API.delete(`${typeToAPI[type]}/${id}/investments/${foundInvestment.id}`).then(() =>
        store.getAccounts()
      )
    }

    this.removeInvestment(foundInvestment.id)

    this.handleModalClose()
  }

  handleModalClose = () => this.setState({ deleteModal: 'closed', investmentToDelete: null })

  removeInvestment = id => {
    const { form, fieldName } = this.props
    const updatedValue = form.getState().values[fieldName].filter(value => value.id !== id)
    form.change(fieldName, updatedValue)
  }

  render() {
    const { sortBy, sortOrder } = this.state
    const {
      title,
      balanceLabel,
      fieldName,
      investments,
      form,
      enablePurchaseDate,
      enableCostBasis,
    } = this.props

    if (!investments || investments.length === 0) {
      return null
    }

    return (
      <div>
        <TableTitle>
          <div>{title}&nbsp;&nbsp;&nbsp;</div>
          <div>
            {balanceLabel} ${numberToDollars(calculateBalance(investments))}
          </div>
        </TableTitle>

        <TableHeader>
          <div />
          <div onClick={() => this.handleSortChange('name')}>
            Investment name <SortArrow direction={sortActive(sortBy === 'name', sortOrder)} />
          </div>
          {enablePurchaseDate ? <div>As of date</div> : <div />}
          {enableCostBasis ? <div>Cost basis</div> : <div />}
          <div>Shares</div>
          <div onClick={() => this.handleSortChange('balance')}>
            Balance <SortArrow direction={sortActive(sortBy === 'balance', sortOrder)} />
          </div>
        </TableHeader>

        <FieldArray name={fieldName}>
          {({ fields }) => (
            <TableList>
              {fields.map((name, index) => (
                <Field
                  key={name}
                  name={name}
                  render={({ input }) => {
                    const { securityName, valuationMethod, price, balance } = input.value

                    return (
                      <TableRow>
                        <ResponsiveLabel>Investment name</ResponsiveLabel>

                        <DeleteIconColumn>
                          <DeleteIcon onClick={() => this.handleDelete(input.value)} />
                        </DeleteIconColumn>

                        <InvestmentNameColumn>
                          {placeholderSymbolReplace(securityName)}
                        </InvestmentNameColumn>

                        {enablePurchaseDate && <ResponsiveLabel>As of date</ResponsiveLabel>}
                        {enablePurchaseDate ? (
                          <AsOfDateColumn>
                            <Field
                              name={`${name}.purchaseDate`}
                              render={({ input, meta }) => (
                                <DatePicker
                                  date={
                                    input.value
                                      ? dayjs(input.value).format('YYYY-MM-DD')
                                      : dayjs().format('YYYY-MM-DD')
                                  }
                                  error={meta.error}
                                  showError={meta.touched}
                                  fontSize='14px'
                                  handleChange={(name, value) => input.onChange(value)}
                                  maxYear={new Date().getFullYear()}
                                  minYear={1900}
                                  name='purchaseDate'
                                  onBlur={input.onBlur}
                                  disabled={!enablePurchaseDate}
                                />
                              )}
                            />
                          </AsOfDateColumn>
                        ) : (
                          <AsOfDateColumn
                            css={css`
                              @media (max-width: ${breakpoint}) {
                                display: none;
                              }
                            `}
                          />
                        )}

                        {enableCostBasis && <ResponsiveLabel>Cost basis</ResponsiveLabel>}
                        {enableCostBasis ? (
                          <CostBasisColumn>
                            <Field
                              name={`${name}.totalCostBasis`}
                              format={value => (value === null ? undefined : value)}
                              parse={v => v}
                              render={({ input, meta }) => (
                                <div
                                  css={css`
                                    & > div > div > div > input {
                                      text-align: right;
                                    }
                                  `}>
                                  <CurrencyInput
                                    fontSize='14px'
                                    name='totalCostBasis'
                                    onBlur={input.onBlur}
                                    onChange={(name, value) => input.onChange(value)}
                                    error={meta.error}
                                    showError={meta.touched}
                                    value={input.value}
                                    width='120px'
                                    decimalScale={2}
                                  />
                                </div>
                              )}
                            />
                          </CostBasisColumn>
                        ) : (
                          <CostBasisColumn
                            css={css`
                              @media (max-width: ${breakpoint}) {
                                display: none;
                              }
                            `}
                          />
                        )}

                        {valuationMethod === VALUATION_METHODS.marketPrice && (
                          <ResponsiveLabel>Shares</ResponsiveLabel>
                        )}
                        {valuationMethod === VALUATION_METHODS.marketPrice ? (
                          <SharesColumn>
                            <Field
                              name={`${name}.quantity`}
                              format={value => (value === null ? undefined : value)}
                              parse={v => v}
                              render={({ input }) => (
                                <div>
                                  <NumberInput
                                    fontSize='14px'
                                    name='quantity'
                                    onChange={(inputName, value) => {
                                      input.onChange(value)
                                      form.change(
                                        `${name}.balance`,
                                        Math.round(price * (value || 0) * 100) / 100
                                      )
                                    }}
                                    onBlur={() => {
                                      input.onBlur()
                                      this.handleSort(fields.value)
                                    }}
                                    value={input.value}
                                    width='85px'
                                  />
                                </div>
                              )}
                            />
                          </SharesColumn>
                        ) : (
                          <SharesColumn
                            css={css`
                              @media (max-width: ${breakpoint}) {
                                display: none;
                              }
                            `}
                          />
                        )}

                        <ResponsiveLabel>Balance</ResponsiveLabel>
                        {valuationMethod === VALUATION_METHODS.accountBalance ? (
                          <BalanceColumn>
                            <Field
                              name={`${name}.balance`}
                              format={value => (value === null ? undefined : value)}
                              parse={v => v}
                              render={({ input, meta }) => (
                                <div
                                  css={css`
                                    & > div > div > div > input {
                                      text-align: right;
                                    }
                                  `}>
                                  <CurrencyInput
                                    decimalScale={2}
                                    fontSize='14px'
                                    name='balance'
                                    onBlur={() => {
                                      input.onBlur()
                                      this.handleSort(fields.value)
                                    }}
                                    onChange={(name, value) => input.onChange(value)}
                                    value={input.value}
                                    width='125px'
                                    css={css`
                                      padding: 0px;
                                    `}
                                  />
                                  <TextErrorField error={meta.error} showError={meta.touched} />
                                </div>
                              )}
                            />
                          </BalanceColumn>
                        ) : (
                          <BalanceColumn
                            css={css`
                              font-size: 14px;
                              color: #4a606a;
                              margin-top: 12px;
                            `}>
                            ${numberToDollars(balance || 0)}
                          </BalanceColumn>
                        )}
                      </TableRow>
                    )
                  }}
                />
              ))}
            </TableList>
          )}
        </FieldArray>

        <DeleteModal
          css={css`
            text-overflow: ellipsis;
            white-space: normal;
            overflow: hidden;
          `}
          handleConfirmation={this.handleConfirmDelete}
          isModalOpen={this.state.deleteModal === 'confirm'}
          title={
            'Delete investment: ' +
            placeholderSymbolReplace(_.get(this.state.investmentToDelete, 'securityName', '')) +
            '?'
          }
          toggleModal={this.handleModalClose}
        />

        <PlanOffersOptionModal
          isModalOpen={this.state.deleteModal === 'planOffer'}
          handleCancel={this.handleModalClose}
          handleConfirmation={() => this.handlePlanOffer(true)}
          handleNotOffered={() => this.handlePlanOffer(false)}
          title='Does your plan still offer this as an option for you to invest in?'
        />

        <ConfirmModal
          handleConfirm={this.handleConfirmDelete}
          isModalOpen={_.includes(
            ['confirmPlanOffer', 'confirmNonPlanOffer'],
            this.state.deleteModal
          )}
          toggleModal={this.handleModalClose}
          title='Got it. Thanks.'
          secondaryText='Your investment will be removed. This action cannot be undone.'
          confirmButtonText='Save'
          denyButtonText='Cancel'
        />
      </div>
    )
  }
}

export default withRouter(inject('store', 'account')(observer(UpdateInvestmentsGroup)))
