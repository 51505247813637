import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import InlineSVG from 'svg-inline-react'

import { GCLogoDark } from '../../../assets/icons'
import { Spacer, FillGauge } from '../../../components'
import { numberToDollars } from '../../../utils'
import { Page, PageContent } from '../elements'
import {
  ContentMainHeader,
  ContentSubHeader,
  ContentText,
  FlexContainer,
  HorizontalLine,
  GoalTable,
  GoalTableCurrency,
  GoalTableText,
  GoalTableLabel,
} from '../elements/styled'
import {
  Header,
  HeaderContainer,
  HeaderHeading,
  HeaderText,
  SnapshotBanner,
  SnapshotText,
  IndentContainer,
  TableRow,
  FillSVGWrapper,
  LogoSVGWrapper,
} from './GoalPageStyle'

const customerSupportLine = config => {
  const { templateId } = config

  // Retail: 'the GuidedChoice Support Center at (800) 774-7459',
  // InsitutionalNonFLSupportText: 'the GuidedChoice Support Center at (800) 242-6182',
  // SchwabSupportText: 'the Schwab Advice Services Center at (877) 285-4929'
  switch (templateId) {
    case 1:
    case 2:
      return `the ${config.template.supportGroup} via the ${config.template.supportName} at ${config.template.supportLine}`
    case 19:
      return `the GuidedChoice Support Center at (800) 774-7459`
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    default:
      return `the ${config.template.supportName} at ${config.template.supportLine}`
  }
}

class GoalPage1 extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'goals1' })
  }

  render() {
    const { store, guide } = this.props
    const page = guide.guidePages.pages.findByName('goals1')

    const { includeSpouse, maritalStatus, retirementIncomeGoalPct } = store.person
    const { config } = store

    const {
      retirementIncomeGoal,
      projectedMonthlyRetirementInc,
      projectedMonthlyInflationAdjustedRetirementInc,
      currentAfterTaxIncome,
      isOnTrack,
    } = store.acceptedCase

    return (
      <Page
        header={
          <div>
            <Header>
              <PageContent>
                <LogoSVGWrapper>
                  <InlineSVG className='icon' src={GCLogoDark} element='span' />
                </LogoSVGWrapper>
                <HeaderContainer>
                  <HeaderHeading>Your GuidedChoice Advisory Service Guide</HeaderHeading>
                  <HeaderText>
                    Your personalized guide contains information about your goals and gives you a
                    detailed plan and checklist for the savings and investment changes you'll want
                    to make to help ensure you are able to get to the financial future you want. If
                    you need any assistance with this guide, please call{' '}
                    {customerSupportLine(config)}.
                  </HeaderText>
                </HeaderContainer>
              </PageContent>
            </Header>
            <SnapshotBanner>
              <SnapshotText>Snapshot</SnapshotText>
            </SnapshotBanner>
          </div>
        }
        page={page}>
        <ContentMainHeader>Your Goals</ContentMainHeader>
        <ContentSubHeader>RETIREMENT</ContentSubHeader>
        <ContentText>
          Whether “retirement” means stopping work altogether, working part-time, or embarking on a
          new career, saving and investing during these pre-retirement years will provide a level of
          income when the time comes for you to “retire.”{' '}
        </ContentText>
        <IndentContainer paddingLeft='.25in'>
          <ContentSubHeader>MONTHLY INCOME AFTER RETIRING {maritalStatus && '*'}</ContentSubHeader>
          <ContentText>
            You set a goal to have at least {retirementIncomeGoalPct}% of your current income during
            your retirement years.{' '}
            {isOnTrack
              ? `And if you follow the plan
            outlined in the coming pages, you are likely to reach your goal.`
              : `But reaching that goal may involve some tough
            decisions ahead.`}{' '}
            We'll do what we can to help you understand how different financial decisions may impact
            your strategy and outcomes.{' '}
          </ContentText>
        </IndentContainer>

        <Spacer space='6px' />

        <IndentContainer paddingLeft='.5in'>
          <FlexContainer justifyContent='flex-start'>
            <FillSVGWrapper>
              <FillGauge id='guideFill' fill={retirementIncomeGoal} height={100} width={100} />
            </FillSVGWrapper>
            <ContentText>
              Based on our estimate of the income you {includeSpouse && 'and your spouse'} take home
              today, {`$${numberToDollars(currentAfterTaxIncome, true)}`} each month after taxes, we
              project you will have at least {`${numberToDollars(retirementIncomeGoal, true)}%`} of
              that available to you when you retire. In terms of dollars you can spend, this means:{' '}
            </ContentText>
          </FlexContainer>
        </IndentContainer>
        <IndentContainer paddingLeft='.75in'>
          <FlexContainer justifyContent='space-between' childPadding='20px'>
            <GoalTable>
              <TableRow
                childPadding='12px'
                css={css`
                  padding: 10pt 4pt 0;
                `}>
                <GoalTableCurrency>
                  {numberToDollars(projectedMonthlyRetirementInc, true)}
                </GoalTableCurrency>
                <GoalTableText>per month</GoalTableText>
              </TableRow>
              <TableRow
                childPadding='12px'
                css={css`
                  padding: 6pt 4pt 10pt;
                `}>
                <GoalTableCurrency>
                  {numberToDollars(projectedMonthlyRetirementInc * 12, true)}
                </GoalTableCurrency>
                <GoalTableText>per year</GoalTableText>
              </TableRow>
              <HorizontalLine />
              <TableRow
                css={css`
                  padding: 10pt 4pt;
                `}>
                <GoalTableLabel>Today's value</GoalTableLabel>
              </TableRow>
            </GoalTable>
            <GoalTable>
              <TableRow
                childPadding='12px'
                css={css`
                  padding: 10pt 4pt 0;
                `}>
                <GoalTableCurrency>
                  {numberToDollars(projectedMonthlyInflationAdjustedRetirementInc, true)}
                </GoalTableCurrency>
                <GoalTableText>per month</GoalTableText>
              </TableRow>
              <TableRow
                childPadding='12px'
                css={css`
                  padding: 6pt 4pt 10pt;
                `}>
                <GoalTableCurrency>
                  {numberToDollars(projectedMonthlyInflationAdjustedRetirementInc * 12, true)}
                </GoalTableCurrency>
                <GoalTableText>per year</GoalTableText>
              </TableRow>
              <HorizontalLine />
              <TableRow
                css={css`
                  padding: 10pt 4pt;
                `}>
                <GoalTableLabel>Value at retirement</GoalTableLabel>
              </TableRow>
            </GoalTable>
          </FlexContainer>
        </IndentContainer>
        {/* <Spacer space="12px" /> */}
      </Page>
    )
  }
}

export default inject('store', 'guide')(observer(GoalPage1))
