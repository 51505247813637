import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import TextErrorField from './TextErrorField'

const InputWrapper = styled.div`
  margin-top: ${p => (p.marginTop ? p.marginTop : '5px')};
  min-height: 48px;
  padding: 10px 0;
  max-width: ${p => p.width || '200px'};
`

const StyledInput = styled.div`
  position: relative;

  & input {
    position: relative;
    outline: none;
    border: none;
    width: ${p => (p.width ? p.width : '100%')};
    max-width: 100%;
    color: ${p => p.theme.needsWantsContainerInputTextColor || '#022a3a'};
    background-color: transparent;
    transition: border-bottom 0.3s;
    font-size: ${p => (p.fontSize ? p.fontSize : '1.125rem')};
    line-height: 1.5rem;

    border-bottom: ${p =>
      p.showError && p.error
        ? '1.5px solid #E31E27 !important'
        : p.readonlyProp
        ? 'none'
        : `1.5px solid ${p.theme.lightestGray || '#7a8e96'}`};

    &::placeholder {
      color: #b6c0c4;
    }

    &:hover:not(:focus) {
      ${p => (p.readonlyProp ? '' : 'border-bottom: solid 1.5px #053240;')};
    }

    &:focus {
      ${p => (p.readonlyProp ? '' : 'border-bottom: solid 1.5px #008ab6;')};
    }
  }

  @media (max-width: 800px) {
    width: ${p => (p.mobileWidth ? p.mobileWidth : null)};
  }
`

export class BaseInput extends Component {
  static propTypes = {
    error: PropTypes.string,
    fontSize: PropTypes.string,
    readonly: PropTypes.bool,
    showError: PropTypes.bool,
    width: PropTypes.string,
    mobileWidth: PropTypes.string,
  }

  render() {
    const {
      className,
      error,
      fontSize,
      showError,
      width,
      children,
      readonly,
      mobileWidth,
      expanded,
      marginTop,
    } = this.props

    return (
      <InputWrapper marginTop={marginTop} width={width} className={className}>
        <StyledInput
          fontSize={fontSize}
          readonlyProp={readonly}
          showError={showError}
          error={error}
          width={width}
          mobileWidth={mobileWidth}>
          {children}
        </StyledInput>
        <TextErrorField showError={showError} error={error} />
        {expanded && (
          <span
            css={css`
              font-size: 1.125rem;
              word-break: break-all;
              height: 0;
              display: block;
              overflow: hidden;
              opacity: 0;
              padding: 0 8px;
            `}>
            {expanded}
          </span>
        )}
      </InputWrapper>
    )
  }
}

export default BaseInput
