import { API } from '../api'
import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'

import { CLIENT_OVERRIDES } from '../constants/client-overrides'

const { model, maybeNull, number, string, boolean } = types

const Account = model({
  acceptedCase: maybeNull(number),
  annualSalary: maybeNull(number),
  employeeStatus: maybeNull(number),
  id: maybeNull(number),
  managedAccount: maybeNull(boolean),
  participantId: maybeNull(number),
  planId: maybeNull(number),
  productId: maybeNull(number),
  taxState: maybeNull(string),
  autoRiskLevelAdjustmentStatus: maybeNull(boolean),
})

const AccountStore = model({
  account: maybeNull(Account),
}).actions(self => ({
  getAccount: flow(function* () {
    const account = yield API.get('account')
    self.account = _.merge(account.data, CLIENT_OVERRIDES.account)
  }),
  updateAccount: flow(function* (values) {
    const account = yield API.patch('account', values)
    self.account = account.data
  }),
  updateManagedAccountStatus: flow(function* ({
    productId,
    managedAccountStatus,
    caseId,
    savingsRateTransactionOptional,
  }) {
    const account = yield API.post('transactions/change-managed-account-status', {
      accountId: self.account.id,
      productId: productId || self.account.productId,
      managedAccountStatus,
      caseId,
      savingsRateTransactionOptional,
    })
    self.account = account.data
  }),
  updateAutoRiskLevelAdjustmentStatus: flow(function* (autoRiskLevelAdjustmentStatus) {
    const { id } = self
    const account = yield API.post('account/auto-risk-adjustment', {
      accountId: id,
      autoRiskLevelAdjustmentStatus,
    })
    self.account = account.data
  }),
}))

export default AccountStore
