import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Spacer } from '../../../components'
import { numberToDollars } from '../../../utils'

const FactText = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.mediumGray};
  font-size: 1.2rem;
  height: ${p => (p.first ? '50px' : null)};
  max-width: 220px;
  line-height: 1.35;
`

const SSIPaymentText = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.lightestGray};
  font-size: 1rem;
  max-width: 220px;
`

const FactDollarAmount = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 2.4rem;
  display: ${p => (p.inline ? 'inline' : null)};

  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
    padding-right: 2px;
  }
`

const StyledPlus = styled.span`
  color: #4d9927;
  font-size: 2.4rem;
  font-weight: bold;
`

class SSIText extends Component {
  render() {
    const { retirementAge } = this.props
    if (retirementAge > 70) {
      return (
        <>
          <Spacer space='4px' />
          <SSIPaymentText>*SSI payments begin age 70</SSIPaymentText>
        </>
      )
    } else if (retirementAge < 62) {
      return (
        <>
          <Spacer space='4px' />
          <SSIPaymentText>*SSI payments begin age 62</SSIPaymentText>
        </>
      )
    } else {
      return null
    }
  }
}

const FactCard = inject('store')(
  observer(
    class extends Component {
      static defaultProps = {
        amount: 0,
      }

      static propTypes = {
        topLabel: PropTypes.string,
        amount: PropTypes.number,
        bottomLabel: PropTypes.string,
      }

      render() {
        const { topLabel, amount, bottomLabel, retirementAge, plusSign } = this.props

        return (
          <div>
            <FactText first>{topLabel}</FactText>
            <Spacer space='8px' />
            {!!plusSign && <StyledPlus>+</StyledPlus>}
            <FactDollarAmount inline>{numberToDollars(amount, true)}</FactDollarAmount>
            <Spacer space='16px' />
            <FactText>
              {bottomLabel}
              {retirementAge && (retirementAge > 70 || retirementAge < 62) && '*'}
            </FactText>
            {retirementAge && <SSIText retirementAge={retirementAge} />}
            <Spacer space='16px' />
          </div>
        )
      }
    }
  )
)

const HouseholdIncomeCard = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props
        const { currentAfterTaxIncome } = store.baseCase

        return (
          <FactCard
            topLabel='Estimated after-tax household income'
            amount={currentAfterTaxIncome}
            bottomLabel='Each month, currently'
          />
        )
      }
    }
  )
)

const SSIncomeCardPrimary = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props
        const { displayName } = store.person
        const { primary } = store.baseCase
        const { primarySSIBenefits } = store

        return (
          primary && (
            <FactCard
              topLabel='Estimated monthly Social Security income'
              amount={primarySSIBenefits.monthlyAmount}
              bottomLabel={`${displayName} at ${primary.retAge}`}
              retirementAge={primary.retAge}
            />
          )
        )
      }
    }
  )
)

const SSIncomeCardSpouse = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props
        const { firstName } = store.spouse
        const { spouse } = store.baseCase
        const { spouseSSIBenefits } = store

        return (
          spouse && (
            <FactCard
              topLabel='Estimated monthly Social Security income'
              amount={spouseSSIBenefits.monthlyAmount}
              bottomLabel={`${firstName} at ${spouse.retAge}`}
              retirementAge={spouse.retAge}
            />
          )
        )
      }
    }
  )
)

const ExpectRecCard = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props
        const { baseCase, recommendedCase } = store

        const difference = recommendedCase.estimatedFutureValue - baseCase.estimatedFutureValue

        return difference > 0 ? (
          <FactCard
            topLabel='Expect more from investment accounts'
            amount={difference}
            bottomLabel='By implementing our recommendation below'
            plusSign
          />
        ) : null
      }
    }
  )
)

const OtherGoalsCard = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props
        const { otherFinancialGoalsTotal } = store.baseCase

        return (
          <FactCard
            topLabel='Other financial goals'
            amount={otherFinancialGoalsTotal}
            bottomLabel='Total'
          />
        )
      }
    }
  )
)

const OtherSavingsCard = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props
        const { aggregateMonthlyContribution } = store.accountInsight

        return (
          <FactCard
            topLabel='Ongoing annual savings total'
            amount={aggregateMonthlyContribution * 12}
            bottomLabel='To all investment accounts'
          />
        )
      }
    }
  )
)

export {
  HouseholdIncomeCard,
  SSIncomeCardPrimary,
  SSIncomeCardSpouse,
  ExpectRecCard,
  OtherGoalsCard,
  OtherSavingsCard,
}
