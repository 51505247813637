import { css } from '@emotion/react'
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'

import { Spacer, Loading } from '../../../components'
import { Page } from './style'
import PersonalizedStrategy from '../value-proposition/PersonalizedStrategy'
import ImplementChangesIcon from './icons/ImplementChangesIcon'
import ManageRiskIcon from './icons/ManageRiskIcon'
import AutoRebalanceIcon from './icons/AutoRebalanceIcon'
import PhoneSupportIcon from './icons/PhoneSupportIcon'

function ImplementChanges(props) {
  const [state, setState] = useState('idle')
  const {
    store: { config, updateManagedAccountStatus, spendingAcceptedCaseId },
    history,
    spendDownOffboarding: { incrementStep, modeledAnnuity },
  } = props

  async function implementChanges() {
    try {
      setState('loading')

      await updateManagedAccountStatus({
        managedAccountStatus: true,
        caseId: spendingAcceptedCaseId,
        savingsRateTransactionOptional: null,
      })

      incrementStep(history, 'implement-changes')
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  if (state === 'loading') {
    return (
      <ButtonGroup>
        <Loading />
      </ButtonGroup>
    )
  }

  return (
    <Page.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
        line-height: 1.5rem;
      `}>
      <Spacer space='10px' />

      <Page.AcceptTitle>Investment changes will be required</Page.AcceptTitle>
      <Spacer space='20px' />

      <Page.AcceptDisclaimerWrapper>
        <Page.AcceptParagraph>
          Thank you for allowing us to help you stay on track for a retirement paycheck you can
          count on. Upon implementation, {config.template.brandName} will take care of the
          investment allocation changes to your {config.template.sponsorName} retirement plan
          account.
          <br />
          <br />
          Then you'll get a guide that will contain allocations for any other investment plan
          accounts, as well as specific instructions for withdrawing funds needed this calendar year
          for living expenses{modeledAnnuity && ' and for purchase of an annuity'}.<br />
          <br />
          Please note: Withdrawal instructions will be actionable only if you make all the
          investment changes we advise.
        </Page.AcceptParagraph>

        <PersonalizedStrategy />
      </Page.AcceptDisclaimerWrapper>
      <Spacer space='10px' />

      <Page.AcceptDivider />

      <Page.AcceptSectionTitle>
        As a managed account holder, {config.template.brandName} already:
      </Page.AcceptSectionTitle>
      <Spacer space='10px' />

      <Page.AcceptSection>
        <div>
          <Page.AcceptIcon>
            <ImplementChangesIcon />
          </Page.AcceptIcon>
          Implements changes to your savings rate and investments for you
        </div>

        <div>
          <Page.AcceptIcon>
            <ManageRiskIcon />
          </Page.AcceptIcon>
          Minimizes risk — while maximizing reward — for your personalized, diversified portfolio
        </div>

        <div>
          <Page.AcceptIcon>
            <AutoRebalanceIcon
              css={css`
                width: 90%;
                height: auto;
                margin-bottom: 25px;
              `}
            />
          </Page.AcceptIcon>
          Automatically rebalances your account to keep you on track
        </div>

        <div>
          <Page.AcceptIcon>
            <PhoneSupportIcon
              css={css`
                margin-bottom: 15px;
              `}
            />
          </Page.AcceptIcon>
          Is a phone call away whenever you need help
        </div>
      </Page.AcceptSection>
      <Spacer space='20px' />

      <Page.AcceptDisclaimer>
        Be aware: Depending upon the arrangements between GuidedChoice and your plan, your
        transaction could take 24-48 hours to be reflected in your account, or as many as 10
        business days. You may unsubscribe from GuidedChoice advice services at any time, though
        there are no refunds.
      </Page.AcceptDisclaimer>

      <Page.ButtonGroup>
        <Page.CancelButton onClick={() => history.push('/spending')}>
          <div>Cancel</div>
          <div>no action | return to</div>
          <div>Overall Strategy</div>
        </Page.CancelButton>

        {}

        <Page.PrimaryButton onClick={implementChanges}>
          <div>Implement changes</div>
          <div>to employer plan account & I'll implement</div>
          <div>any other investment account changes</div>
        </Page.PrimaryButton>
      </Page.ButtonGroup>
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(ImplementChanges))

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  margin: 36px 0;
  & > a {
    margin: 1rem;
  }
`
