import { css } from '@emotion/react'
import { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import AnnuityDashboard from './annuity-modeling/AnnuityDashboard'
import CompareDrawer from './compare-scenarios/CompareDrawer'
import { DoughnutChart } from '../shared'

import {
  Button,
  FlexRow,
  SVGWrapper,
  Spacer,
  Container as MainContainer,
  HelpIcon,
  TooltipText,
  Drawer,
  Loading,
  Row,
} from '../../components'
import { numberToDollars } from '../../utils/utils'
import { CircleNegative, CirclePlus, Warning as WarningSVG } from '../../assets/icons/'
import { Page, Section } from './style'

import WhereMoneyComesFrom from './shared-components/WhereMoneyComesFrom'
import UnderstandingYourResults from './understanding-your-results/UnderstandingYourResults'

function SpendDown({
  store: {
    budget,
    isModifiedPlus,
    isFirstTimeToSpendDown,
    monthlyIncomeCannotOutlive,
    projectedMonthlyRetirementInc,
    allSpendingScenarios,
    spendingBaseCases,
    spendingRecommendedCases,
    config,
    config: { isRetail },
    processModifiedCase,
  },
  history,
}) {
  const historyFrom = history.location.state ? history.location.state.from : null
  // where money comes from drawer should be open initially if user has made any changes to any 'where money comes from' sections.
  // Pension, other lifetime income, and emergency fund are all handled on this page... Social Security changes navigates to '/social-security'.
  // In SSI components, when pushing to '/spending', the object { from: 'SSI' } is passed to the state object so we know that we just made a
  // change and should set the drawer open initially
  const [showWhereMoneyComesFrom, setShowWhereMoneyComesFrom] = useState(historyFrom === 'SSI')
  const [drawer, setDrawer] = useState('')
  const [numberStatus, setNumberStatus] = useState('idle')
  const isMounted = useRef(true)
  const toggleShow = () => {
    setShowWhereMoneyComesFrom(!showWhereMoneyComesFrom)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isMounted.current) {
          setNumberStatus('loading')
          await processModifiedCase()
          setNumberStatus('success')
        }
      } catch (err) {
        console.error(err)
        if (isMounted.current) {
          setNumberStatus('error')
        }
      }
    }
    fetchData()

    return () => {
      isMounted.current = false
    }
  }, [processModifiedCase])

  const isCompareActive = useMemo(() => drawer === 'compare', [drawer])
  const handleCancel = useCallback(() => setDrawer(''), [setDrawer])

  return (
    <MainContainer>
      <Page.Container>
        <Page.SuccessBox>
          <Page.CongratsText>
            Congratulations! You can expect <br />
            monthly income of <br />
          </Page.CongratsText>
          <Spacer space='5px' />
          {numberStatus === 'loading' ? (
            <span>
              <Loading />
            </span>
          ) : (
            <Page.HeaderAmount>
              {numberToDollars(projectedMonthlyRetirementInc, true)}
            </Page.HeaderAmount>
          )}
          <Spacer space='5px' />
          <Page.BasedText>based on average investment returns and mortality.</Page.BasedText>
          <Spacer space='5px' />
          {projectedMonthlyRetirementInc >= budget.dollarAmountDesired &&
            budget.dollarAmountNeeded !== 0 && (
              <Page.CanAffordNeeds>
                Well done, you are meeting your desired monthly spending total, which includes your
                income need and want!
              </Page.CanAffordNeeds>
            )}
          <Spacer space='5px' />
          <Button
            type='button'
            secondary
            label='See why'
            css={css`
              background-color: #4a606a9c;
              color: #fff;
            `}
            onClick={() => setDrawer('Personalized Help')}
          />

          {_.get(spendingBaseCases, 'currentCompanyStockWeightPercentage', 0) > 0 && !isRetail && (
            <>
              <Spacer space='10px' />

              <Page.BasedText>
                {' '}
                Includes company stock in account with
                <br /> {config.template.planName}{' '}
                <HelpIcon tooltip={TooltipText.changeHowMuchInCompanyStock()} />
              </Page.BasedText>

              <Section>
                <Spacer space='10px' />
                <Section.SecondaryText marginLeft='46px'>
                  Percentage of your account in{' '}
                  {_.get(spendingBaseCases, 'companyStocks', [])
                    .map(stock => stock.securityName)
                    .join(', ')}
                </Section.SecondaryText>
                <Row>
                  <Section.SVGContainer>
                    <Section.SecondaryText>Current percentage</Section.SecondaryText>
                    <Spacer space='10px' />
                    <DoughnutChart
                      percent={_.get(
                        spendingRecommendedCases,
                        'currentCompanyStockWeightPercentage',
                        0
                      )}
                      height={130}
                    />
                  </Section.SVGContainer>
                  <Section.SVGContainer>
                    <Section.PrimaryText>if implemented</Section.PrimaryText>
                    <Spacer space='10px' />
                    <DoughnutChart
                      percent={_.get(
                        spendingRecommendedCases,
                        'keptCompanyStockWeightPercentage',
                        0
                      )}
                      height={130}
                    />
                  </Section.SVGContainer>
                </Row>
              </Section>
            </>
          )}
        </Page.SuccessBox>
        <Drawer active={drawer === 'Personalized Help'} width='100%'>
          <UnderstandingYourResults setDrawer={setDrawer} handleCancel={handleCancel} />
        </Drawer>
        <Spacer space='8px' />
        {/* {isFirstTimeToSpendDown && !isRetail && (
          <Page.SecondaryBox>
            You can choose to secure monthly income that you can't outlive: <br />
            <div
              css={css`
                display: flex;
                align-items: center;
              `}>
              <Page.HeaderAmount
                css={css`
                  font-size: 1.95rem;
                  margin-right: 10px;
                `}>
                {numberToDollars(monthlyIncomeCannotOutlive, true)}
              </Page.HeaderAmount>
              <Page.ButtonBox onClick={() => setDrawer('editAnnuity')}>
                <div>See how</div>
              </Page.ButtonBox>
            </div>
          </Page.SecondaryBox>
        )}  */}{' '}
        {/* We might need it back sometime in the future */}
        <Spacer space='8px' />
        <Page.DollarValue>
          The dollar value shown is after-tax. We estimate the values based on investing as
          recommended.
        </Page.DollarValue>
        <Spacer space='20px' />
        <FlexRow onClick={toggleShow}>
          {showWhereMoneyComesFrom ? (
            <SVGWrapper svg={CircleNegative} onClick={toggleShow} fill='#7a8e96' size='large' />
          ) : (
            <SVGWrapper svg={CirclePlus} onClick={toggleShow} fill='#7a8e96' size='large' />
          )}
          <Page.TooltipLabel>See where the money comes from</Page.TooltipLabel>
        </FlexRow>
        {showWhereMoneyComesFrom && <WhereMoneyComesFrom history={history} />}
        <Spacer space='20px' />
        {budget.dollarAmountNeeded > projectedMonthlyRetirementInc && (
          <Page.WarningContainer>
            <div
              css={css`
                flex: 1;
              `}>
              <SVGWrapper svg={WarningSVG} fill='#3A4D57' size='large' /> &nbsp;
            </div>
            <div
              css={css`
                flex: 10;
              `}>
              Expected monthly income is less <br />
              than your estimated total Need
            </div>
            <div
              css={css`
                flex: 5;
              `}>
              <Page.SecondaryButton onClick={() => history.push('/spending/budget')}>
                <div>Return to budget</div>
              </Page.SecondaryButton>
            </div>
          </Page.WarningContainer>
        )}
      </Page.Container>
      {isFirstTimeToSpendDown ? (
        <Page.ButtonGroup>
          <div
            css={css`
              display: flex;
            `}>
            {isModifiedPlus ? (
              <Page.SecondaryButton onClick={() => history.push('/spending/considerations')}>
                <div>Continue planning</div>
              </Page.SecondaryButton>
            ) : (
              <Page.SecondaryButton onClick={() => history.push('/spending/budget')}>
                <div>Additional planning</div>
              </Page.SecondaryButton>
            )}
            <div
              css={css`
                padding-top: 12px;
                margin-left: -8px;
              `}>
              <HelpIcon tooltip={TooltipText.additionalPlanning()} />
            </div>
          </div>
          <Page.ButtonBox onClick={() => history.push('/spending/finalize-choices')}>
            <div>Finalize choices</div>
          </Page.ButtonBox>
        </Page.ButtonGroup>
      ) : (
        <Page.ButtonGroup>
          <div
            css={css`
              display: flex;
            `}>
            {isModifiedPlus ? (
              <Page.SecondaryButton onClick={() => history.push('/spending/considerations')}>
                <div>Continue planning</div>
              </Page.SecondaryButton>
            ) : (
              <Page.SecondaryButton onClick={() => history.push('/spending/budget')}>
                <div>Additional planning</div>
              </Page.SecondaryButton>
            )}
            <div
              css={css`
                padding-top: 12px;
                margin-left: -8px;
              `}>
              <HelpIcon tooltip={TooltipText.additionalPlanning()} />
            </div>
          </div>
          {allSpendingScenarios.length > 1 && (
            <Page.SecondaryButton onClick={() => setDrawer('compare')}>
              <div>Compare scenarios</div>
            </Page.SecondaryButton>
          )}

          <Page.ButtonBox onClick={() => history.push('/spending/finalize-choices')}>
            <div>Finalize choices</div>
          </Page.ButtonBox>
        </Page.ButtonGroup>
      )}
      <Drawer active={drawer === 'editAnnuity'} title='Securing monthly income' width='70%'>
        <AnnuityDashboard setDrawer={setDrawer} handleCancel={() => setDrawer('')} />
      </Drawer>

      <CompareDrawer
        active={isCompareActive}
        setDrawer={setDrawer}
        handleCancel={handleCancel}
        allSpendingScenarios={allSpendingScenarios}
      />
    </MainContainer>
  )
}

export default inject('store')(observer(SpendDown))
