import { css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'

import { TextErrorField, Checkbox } from '../../../components'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { reduceValidationError } from '../../../utils'

const schema = yup.object().shape({
  discontinue: yup
    .boolean()
    .nullable()
    .oneOf([true], 'Please acknowledge terms')
    .required('Please acknowledge terms'),
})

function DiscontinuePTDPlusForm(props) {
  function initialValues() {
    return { discontinue: null }
  }

  function validate(values) {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  async function handleDiscontinueTransaction() {
    try {
      await props.store.updateManagedAccountStatus({ managedAccountStatus: false })
      props.onClose()
      props.history.push('/discontinue')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  function onSubmit({ discontinue }) {
    if (!discontinue) {
      props.onClose()
    } else {
      return handleDiscontinueTransaction()
    }
  }

  const DiscontinueActive = () => (
    <Form
      initialValues={initialValues()}
      validate={validate}
      onSubmit={onSubmit}
      subscription={{ submitting: true, submitError: true }}
      render={({ handleSubmit, submitting, submitError }) => (
        <div
          css={css`
            padding: 20px;
          `}>
          <div
            css={css`
              color: #4a606a;
              font-size: 22px;
              font-weight: 300;
              margin-bottom: 36px;
              line-height: 1.35;
            `}>
            Are you sure you want to discontinue your Personalized Target Date+ account management
            service?
          </div>

          <div
            css={css`
              color: #4a606a;
              font-size: 18px;
              font-weight: 300;
              line-height: 1.35;
            `}>
            By opting out of the Personalized Target Date+, you acknowledge that you are responsible
            for:
          </div>

          <ul
            css={css`
              color: #4a606a;
              font-size: 18px;
              font-weight: 300;
              line-height: 1.35;
            `}>
            <li>Monitoring your investments</li>
            <li>Rebalancing your account on a regular basis</li>
            <li>
              Determining appropriate changes in strategy based on changes in the economy or your
              financial situation
            </li>
          </ul>

          <div
            css={css`
              display: flex;
              margin: auto;
              margin: 1rem 0 40px;
            `}>
            <Field
              name='discontinue'
              subscription={{ value: true }}
              render={({ input }) => (
                <div
                  css={css`
                    margin-top: 2px;
                    min-width: 42px;
                  `}>
                  <Checkbox
                    id='checkbox'
                    checked={input.value === true}
                    onClick={() => input.onChange(!input.value)}
                    css={css`
                      margin: auto;
                    `}
                  />
                </div>
              )}
            />
            <Field
              name='discontinue'
              subscription={{ value: true }}
              render={({ input }) => (
                <div>
                  <div
                    onClick={() => input.onChange(!input.value)}
                    css={css`
                      cursor: pointer;
                      color: #4a606a;
                      font-size: 1.125rem;
                      font-weight: 300;
                      line-height: 1.5;
                      padding-left: 12px;
                    `}>
                    Yes, I agree
                  </div>
                  <div
                    css={css`
                      font-size: 1rem;
                      font-weight: 300;
                      padding-left: 12px;
                      margin-top: 12px;
                    `}>
                    Note: Your investments will remain in the GuidedChoice allocation until you
                    change them.
                  </div>
                </div>
              )}
            />
          </div>

          <Field
            name='discontinue'
            subscription={{ touched: true, error: true }}
            render={({ meta }) => (
              <div
                css={css`
                  padding: 20px;
                  text-align: center;
                `}>
                <TextErrorField showError={meta.touched} error={meta.error || submitError} />
              </div>
            )}
          />

          <ButtonsBox
            css={css`
              margin-top: 30px;
            `}>
            <BackButton backgroundColor='#FFFFFF' onClick={props.onClose} disabled={submitting}>
              Cancel
            </BackButton>
            <NextButton onClick={handleSubmit} disabled={submitting}>
              Next
            </NextButton>
          </ButtonsBox>
        </div>
      )}
    />
  )

  return <DiscontinueActive />
}

export default withRouter(inject('store')(DiscontinuePTDPlusForm))
