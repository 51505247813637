/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState, useEffect, useMemo } from 'react'
import { observer, inject } from 'mobx-react'
import { Form, Field, useField } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FORM_ERROR } from 'final-form'
import styled from '@emotion/styled'
import _ from 'lodash'

import { CircleNegative, CirclePlus } from '../../../assets/icons'
import {
  Button,
  Spacer,
  SVGWrapper,
  SliderWithValueUnderneath,
  Loading,
  Drawer,
  FormError,
} from '../../../components'
import { numberToDollars, companyStocksAt100PercentForSale } from '../../../utils/utils'
import UnderstandingYourResults from '../understanding-your-results/UnderstandingYourResults'
import { BackButton, ButtonsBox, NextButton } from '../../../components/styled'
import { Page } from './style'

import CanYouCoverExpenses from './shared-components/CanYouCoverExpenses'
import ExtendedLifeExpectancy from './shared-components/ExtendedLifeExpectancy'
import WorkPartTimeAfterRetiring from './shared-components/WorkPartTimeAfterRetiring'
import ChangeRetirementAge from './shared-components/ChangeRetirementAge'
import SellCompanyStock from './shared-components/SellCompanyStock'
import { emergencyFundCalculator } from './utils/util'
import { schema } from './utils/validate'
import { reduceValidationError } from '../../../utils'
import HelpIcon from '../../../components/HelpIcon'
import TooltipText from '../../../components/TooltipText'

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

function EmergencyFund(props) {
  const {
    history,
    store: {
      spouse,
      person,
      allSpendingRecommendedCases,
      spendingModifiedCases,
      processModifiedCaseWithoutSaving,
      totalPrimaryBalanceExcludingPensionAndAnnuity,
      emergencyFund,
      allSpendingBaseCases,
    },
  } = props
  const [modifiedMonthlyIncome, setModifiedMonthlyIncome] = useState({})
  const [status, setStatus] = useState('loading')
  const [numberStatus, setNumberStatus] = useState('idle')

  useEffect(() => {
    const fetchData = async () => {
      try {
        setModifiedMonthlyIncome(spendingModifiedCases)
        setStatus('success')
      } catch (err) {
        setStatus('error')
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  const handlePost = async (values, form) => {
    try {
      setNumberStatus('loading')
      const companyStocksToBeSold = values.companyStocks.filter(stock => stock.percentToSell > 0)
      const formatCompanyStockForSubmit =
        companyStocksToBeSold.length > 0
          ? companyStocksToBeSold
              .map(stock => {
                return stock.aggregatedPositionIds.map(positionId => ({
                  percentToSell: stock.percentToSell,
                  positionId: positionId,
                }))
              })
              .flat()
          : null
      let spouseUpdates = {}
      if (person.includeSpouse) {
        spouseUpdates = {
          spouseExpectedLifespan: values.spouseLifeExpectancy
            ? parseInt(values.spouseLifeExpectancy)
            : null,
          spouseRetirementWork: {
            ...props.spouseRetirementStatus,
            durationStartingAge: values.durationStartingAgeSpouse
              ? values.durationStartingAgeSpouse
              : null,
            durationStoppingAge: values.durationStoppingAgeSpouse
              ? values.durationStoppingAgeSpouse
              : null,
            annualIncome: values.spouseIncome,
            planningWorkPartTime: values.planningWorkPartTimeSpouse ? 1 : 0,
          },
        }
        if (values.spouseRetirement) {
          spouseUpdates = {
            ...spouseUpdates,
            spouse: {
              retAge: values.spouseRetirement ? parseInt(values.spouseRetirement) : null,
            },
          }
        }
      }
      let primaryUpdates = {}
      if (values.clientRetirement) {
        primaryUpdates = {
          primary: {
            retAge: values.clientRetirement ? parseInt(values.clientRetirement) : null,
          },
        }
      }
      const result = await processModifiedCaseWithoutSaving({
        emergencyFund: values.emergencyFundInput ? values.emergencyFundInput : null,
        ...primaryUpdates,
        primaryRetirementWork: {
          ...props.primaryRetirementStatus,
          durationStartingAge: values.durationStartingAgeClient
            ? values.durationStartingAgeClient
            : null,
          durationStoppingAge: values.durationStoppingAgeClient
            ? values.durationStoppingAgeClient
            : null,
          annualIncome: values.clientIncome,
          planningWorkPartTime: values.planningWorkPartTimeClient ? 1 : 0,
        },
        ...spouseUpdates,
        participantExpectedLifespan: values.primarySlider ? parseInt(values.primarySlider) : null,
        spouseExpectedLifespan: values.spouseSlider ? parseInt(values.spouseSlider) : null,
        companyStocks: formatCompanyStockForSubmit,
      })
      setNumberStatus('success')
      setModifiedMonthlyIncome(result)
      form.mutators.resetCanCoverExpenses()
      form.mutators.hideCanCoverExpenses()
      form.mutators.displayCanCoverExpenses()
    } catch (err) {
      setNumberStatus('error')
      console.error(err)
      return { [FORM_ERROR]: 'Something went wrong, please try again later.' }
    }
  }

  const onSubmit = (values, form) => {
    document.getElementById('main-app') && (document.getElementById('main-app').scrollTop = 0)
    return handlePost(values, form)
  }

  const companyStocks = _.orderBy(
    _.get(allSpendingBaseCases, 'companyStocks', null).filter(
      companyStock => !companyStock.restricted
    ),
    [stock => stock.percentToSell, stock => stock.securityName.toLowerCase()],
    ['desc', 'asc']
  )

  const initialValues = useMemo(
    () => ({
      seeWhy: false,
      canCoverExpenses: null,
      displayCanCoverExpenses: false,
      clientRetirement: _.get(
        spendingModifiedCases,
        'averageMarketSpendingAdvice.primary.retAge',
        null
      ),
      spouseRetirement: _.get(
        spendingModifiedCases,
        'averageMarketSpendingAdvice.spouse.retAge',
        null
      ),
      primarySlider: _.get(
        allSpendingRecommendedCases,
        'averageMarketSpendingAdvice.primary.lifeExpectancyAge',
        null
      ),
      spouseSlider: _.get(
        allSpendingRecommendedCases,
        'averageMarketSpendingAdvice.spouse.lifeExpectancyAge',
        null
      ),
      planningWorkPartTimeClient: Boolean(
        _.get(props, 'primaryRetirementStatus.planningWorkPartTime', null)
      ),
      planningWorkPartTimeSpouse: Boolean(
        _.get(props, 'spouseRetirementStatus.planningWorkPartTime', null)
      ),
      durationStartingAgeClient: _.get(props, 'primaryRetirementStatus.durationStartingAge', null),
      durationStoppingAgeClient: _.get(props, 'primaryRetirementStatus.durationStoppingAge', null),
      durationStartingAgeSpouse: _.get(props, 'spouseRetirementStatus.durationStartingAge', null),
      durationStoppingAgeSpouse: _.get(props, 'spouseRetirementStatus.durationStoppingAge', null),
      clientIncome: _.get(props, 'primaryRetirementStatus.annualIncome', null),
      spouseIncome: _.get(props, 'spouseRetirementStatus.annualIncome', null),
      emergencyFundInput: _.get(emergencyFund, 'amount', 0),
      maximumEmergencyFundInput: totalPrimaryBalanceExcludingPensionAndAnnuity,
      companyStocks: companyStocksAt100PercentForSale(companyStocks),
    }),
    [] // eslint-disable-line
  )

  const mutators = {
    toggleSeeWhy: (args, state, utils) => {
      utils.changeValue(state, 'seeWhy', values => !state.formState.values.seeWhy)
    },
    hideCanCoverExpenses: (args, state, utils) => {
      utils.changeValue(state, 'displayCanCoverExpenses', () => false)
    },
    displayCanCoverExpenses: (args, state, utils) => {
      utils.changeValue(state, 'displayCanCoverExpenses', () => true)
    },
    resetCanCoverExpenses: (args, state, utils) => {
      utils.changeValue(state, 'canCoverExpenses', () => null)
    },
  }

  const validate = values => {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  if (status === 'error') {
    return (
      <div
        css={css`
          color: #e31e27;
          font-size: 14px;
          line-height: 1.5;
          text-align: center;
          padding: 0.25rem 0 0.75rem 0;
        `}>
        Something went wrong, please try again later.
      </div>
    )
  }

  return status === 'success' ? (
    <Page.Container>
      <Form
        mutators={{ ...mutators, ...arrayMutators }}
        decorators={[emergencyFundCalculator]}
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        subscription={{ submitting: true, submitError: true, values: true }}
        render={({ form, handleSubmit, submitting, values, submitError }) => (
          <Page.MainWrapper>
            <span
              css={css`
                font-size: 14px;
              `}>
              Considerations
            </span>
            <Page.PageTitle>If you create an emergency fund...</Page.PageTitle>
            <Page.Wrapper>
              <Page.LeftSection>
                <Page.IncomeExpectancyCard>
                  <div
                    css={css`
                      font-size: 32px;
                      font-weight: 300;
                    `}>
                    Monthly income you can expect
                  </div>
                  <Page.IncomeValue>
                    {numberStatus === 'loading' ? (
                      <span>
                        <Loading />
                      </span>
                    ) : (
                      numberToDollars(
                        _.get(
                          modifiedMonthlyIncome,
                          'averageMarketSpendingAdvice.monthlyIncome',
                          null
                        ),
                        true
                      )
                    )}
                  </Page.IncomeValue>
                  {_.get(
                    modifiedMonthlyIncome,
                    'spendingScenario.budget.dollarAmountDesired',
                    null
                  ) <=
                    _.get(
                      modifiedMonthlyIncome,
                      'averageMarketSpendingAdvice.monthlyIncome',
                      null
                    ) && (
                    <Page.CanAffordNeeds>
                      Well done, you are meeting your desired monthly spending total, which includes
                      your income need and want!
                    </Page.CanAffordNeeds>
                  )}
                  <Button
                    type='button'
                    secondary
                    label='See why'
                    css={css`
                      background-color: #7a8e96;
                      color: #fff;
                      width: 200px;
                      height: 51px;
                    `}
                    onClick={form.mutators.toggleSeeWhy}
                  />
                </Page.IncomeExpectancyCard>
                <Drawer active={values.seeWhy} width='100%'>
                  <UnderstandingYourResults
                    setDrawer={form.mutators.toggleSeeWhy}
                    handleCancel={form.mutators.toggleSeeWhy}
                    modifiedMonthlyIncome={modifiedMonthlyIncome}
                  />
                </Drawer>
                {values.displayCanCoverExpenses && (
                  <CanYouCoverExpenses modifiedMonthlyIncome={modifiedMonthlyIncome} />
                )}
              </Page.LeftSection>
              <Page.RightSection>
                <Page.BorderedCard>
                  <Page.CardLabel
                    css={css`
                      margin-bottom: 0;
                    `}>
                    Add an emergency fund
                  </Page.CardLabel>
                  <Spacer space='5px' />
                  <Page.EmergencyAmountText>
                    (amount taken from investment balance &nbsp;
                    <HelpIcon size='medium' tooltip={TooltipText.emergencyFundHelp()} />)
                  </Page.EmergencyAmountText>
                  <Spacer space='15px' />
                  <div>
                    <Page.SliderContainer
                      css={css`
                        margin: 20px 0;
                        display: flex;
                        align-items: baseline;
                      `}>
                      <Page.SliderLabel>
                        <EmergencyFundInputField fieldName='emergencyFundInput' />
                      </Page.SliderLabel>
                      <EmergencyFundSliderField
                        totalPrimaryBalanceExcludingPensionAndAnnuity={
                          totalPrimaryBalanceExcludingPensionAndAnnuity
                        }
                        fieldName='emergencyFundSlider'
                      />
                    </Page.SliderContainer>
                  </div>
                  <Spacer space='5px' />

                  <Condition when='canCoverExpenses' is={false}>
                    <div
                      css={css`
                        margin-top: 10px;
                        font-size: 18px;
                        line-height: 24px;
                        color: #4a606a;
                        position: relative;
                      `}>
                      <Page.ConsiderationTryText>
                        Try adjusting some other considerations to see what helps
                      </Page.ConsiderationTryText>
                      <Spacer space='5px' />

                      <ChangeLifeExpectancyAge
                        spendingModifiedCases={spendingModifiedCases}
                        spouse={spouse}
                        person={person}
                      />

                      <ChangeRetirementAge modifiedMonthlyIncome={modifiedMonthlyIncome} />

                      <WorkPartTimeAfterRetiring />

                      <SellCompanyStock />

                      <Spacer space='10px' />
                    </div>
                  </Condition>

                  <Button
                    type='button'
                    onClick={handleSubmit}
                    disabled={submitting}
                    primary
                    label='Recalculate'
                    width='140px'
                    css={css`
                      height: 45px;
                      align-self: flex-end;
                      font-family: 'Open Sans';
                      line-height: 18px;
                      text-align: center;
                    `}
                  />

                  <Condition when='canCoverExpenses' is={false}>
                    <Spacer space='15px' />
                    <Page.ConsiderationDisclaimerText>
                      Return to Overall Strategy to review Social Security, pension, and other
                      lifetime income selections, or click "income needs & wants" to review your
                      budget
                    </Page.ConsiderationDisclaimerText>
                  </Condition>
                </Page.BorderedCard>
              </Page.RightSection>
            </Page.Wrapper>

            {submitError && <FormError err={submitError} />}

            {values.canCoverExpenses === null ? (
              <ButtonsBox
                css={css`
                  max-width: 100%;
                `}>
                <BackButton
                  css={css`
                    font-family: 'Open Sans';
                    line-height: 18px;
                    text-align: center;
                  `}
                  backgroundColor='#FFFFFF'
                  onClick={() => history.goBack()}>
                  Cancel
                </BackButton>
              </ButtonsBox>
            ) : (
              <ButtonsBox
                css={css`
                  max-width: 100%;
                  justify-content: flex-end;
                `}>
                <NextButton
                  css={css`
                    color: #ffffff;
                    font-family: 'Open Sans';
                    line-height: 18px;
                    text-align: center;
                    width: 330px;
                  `}
                  onClick={() => history.push('/spending/considerations')}>
                  Return to other considerations
                </NextButton>
              </ButtonsBox>
            )}
          </Page.MainWrapper>
        )}
      />
    </Page.Container>
  ) : (
    <Loading />
  )
}

export default inject('store')(observer(EmergencyFund))

const EmergencyFundSliderField = ({ fieldName, totalPrimaryBalanceExcludingPensionAndAnnuity }) => {
  const { input } = useField(fieldName, {
    subscription: { value: true, touched: true, error: true },
  })
  return (
    <SliderWithValueUnderneath
      min='0'
      max={totalPrimaryBalanceExcludingPensionAndAnnuity}
      value={input.value}
      onChange={input.onChange}
      displayValue={false}
      step='100'
    />
  )
}
const Input = styled.input`
  border: none;
  border-bottom: 1px solid #7a8e96;
  outline: none;
  text-indent: 20px;
`
const EmergencyFundInputFieldContainer = styled.div`
  width: 110px;
  position: relative;
  &:before {
    position: absolute;
    content: '$';
  }
  & > input {
    width: 110px;
  }
`
const EmergencyFundInputField = ({ fieldName }) => {
  const { input } = useField(fieldName, {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <EmergencyFundInputFieldContainer>
      <Input {...input} value={numberToDollars(parseInt(input.value), true)} />
    </EmergencyFundInputFieldContainer>
  )
}

const ChangeLifeExpectancyAge = ({ person, spouse, spendingModifiedCases }) => {
  const [open, setOpen] = useState(false)
  return (
    <Page.ConsiderationWrapper>
      <div
        css={css`
          margin: 40px 0;
        `}
        onClick={() => setOpen(!open)}>
        <SVGWrapper
          css={css`
            margin-right: 10px;
          `}
          svg={open ? CircleNegative : CirclePlus}
          fill='#3A4D57'
          size='large'
        />
        <Page.ConsiderationLabel>Change life expectancy age</Page.ConsiderationLabel>
      </div>
      {open && (
        <div>
          <ExtendedLifeExpectancy
            primaryFrozenValue={_.get(
              spendingModifiedCases,
              'averageMarketSpendingAdvice.primary.lifeExpectancyAge',
              null
            )}
            spouseFrozenValue={_.get(
              spendingModifiedCases,
              'averageMarketSpendingAdvice.spouse.lifeExpectancyAge',
              null
            )}
          />
        </div>
      )}
    </Page.ConsiderationWrapper>
  )
}
