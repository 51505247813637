/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import { withRouter } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

import { API } from '../../../api'
import {
  WizardStep,
  MultiButtonInput,
  TooltipText,
  CurrencyInput,
  InputField,
  PercentInput,
  HelpIcon,
} from '../../../components'
import { reduceValidationError, suffixWrapper } from '../../../utils'
import { typeToAPI } from '../utils'
import {
  schema,
  calculator,
  initializeValues,
  createEditable,
  showZeroContributionsError,
} from './PaycheckContributionsUtils'
import { planTypeIdToAccountKey } from '../../../constants'

const EditPaycheckContributions = props => {
  const {
    plan: { contribMethodChangeAllowed },
  } = props.store
  const editableFunc = () => {
    const {
      match: {
        params: { accountType },
      },
      account: {
        account: { planType, isPrimaryOwned, contribMethod },
        control,
      },
    } = props
    return createEditable({
      accountType,
      planType,
      isPrimaryOwned,
      control,
      institutionalContribMethod: contribMethod,
    })
  }

  const handleSubmit = async values => {
    try {
      const editable = editableFunc()
      // if different from initialValues, submit PATCH request
      if (!_.isEqual(initialValues(editable), values)) {
        const {
          account: { account },
          match: {
            params: { type },
          },
        } = props
        const { planType, id, participantId } = account
        const response = await API.patch(typeToAPI[type], {
          planType,
          id,
          participantId,
          ...values,
        })
        applySnapshot(account, response.data)
        props.store.getAccounts()
        await props.store.initializeActiveAccount()
      }
      props.handleCancel()
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const validate = values => {
    const {
      account: { isSpouseOwned },
    } = props.account

    return schema
      .validate(values, { abortEarly: false, context: { isSpouseOwned } })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  const initialValues = editable => {
    const { account, control } = props.account
    return initializeValues({
      editable,
      account,
      control,
      institutionalContribMethod: account.contribMethod,
      editDrawer: true,
    })
  }

  const {
    account: { participantName, isSpouseOwned, id },
  } = props.account
  const {
    institutional: { rothContribAllowed, posttaxContribAllowed },
    features: { editInstitutionalAccount },
    institutionalAccount,
  } = props.store
  const editable = editableFunc()

  const {
    contributionEligibilityIsEditable,
    employeeContribIsEditable,
    contribMethodIsEditable,
    pretaxSavingsRateIsEditable,
    preTaxSavingsAmtIsEditable,
    rothContribAllowedIsEditable,
    rothSavingsRateIsEditable,
    rothSavingsAmtIsEditable,
    posttaxContribAllowedIsEditable,
    posttaxSavingsRateIsEditable,
    postTaxSavingsAmtIsEditable,
  } = editable

  const accountType = planTypeIdToAccountKey[props.account.account.planType]
  const accountsThatNeedContributionLimit = ['401k', '403b', '457', '401a']
  const needHelpIcon = _.includes(accountsThatNeedContributionLimit, accountType)
  const isInstitutionalAccount = id === institutionalAccount?.id

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues(editable)}
      decorators={[calculator]}
      subscription={{ submitting: true, submitError: true, values: true, touched: true }}
      render={({ handleSubmit, submitting, submitError, values, touched }) => {
        const showRothAllowed =
          rothContribAllowedIsEditable &&
          ((values.contributionEligibility === true &&
            values.employeeContrib === true &&
            values.contribMethod !== null) ||
            (values.contributionEligibility === true && values.employeeContrib === false) ||
            values.contributionEligibility === false)

        const showPostTaxAllowed =
          posttaxContribAllowedIsEditable &&
          ((values.contributionEligibility === true &&
            values.employeeContrib === true &&
            values.contribMethod !== null) ||
            (values.contributionEligibility === true && values.employeeContrib === false) ||
            values.contributionEligibility === false)

        return (
          <div>
            <WizardStep
              onBackClick={props.handleCancel}
              onNextClick={handleSubmit}
              backButtonText='Cancel'
              nextButtonText='Save'
              loading={submitting}
              serverError={submitError}>
              <div>
                {contributionEligibilityIsEditable &&
                  !(isInstitutionalAccount && editInstitutionalAccount) && (
                    <Field
                      name='contributionEligibility'
                      render={({ input, meta }) => (
                        <MultiButtonInput
                          name={input.name}
                          value={input.value}
                          label='Eligible?'
                          buttons={[
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                          ]}
                          tooltip={TooltipText.eligibleContributions(true)}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                          disabled={!contributionEligibilityIsEditable}
                        />
                      )}
                    />
                  )}
                {employeeContribIsEditable && values.contributionEligibility === true && (
                  <Field
                    name='employeeContrib'
                    render={({ input, meta }) => (
                      <MultiButtonInput
                        name={input.name}
                        value={input.value}
                        label='Contributing now?'
                        buttons={[
                          { text: 'Yes', value: true },
                          { text: 'No', value: false },
                        ]}
                        tooltip={TooltipText.currentlyContributing()}
                        onChange={(name, value) => input.onChange(value)}
                        onBlur={input.onBlur}
                        error={meta.error}
                        showError={meta.touched}
                      />
                    )}
                  />
                )}
                {showZeroContributionsError(editableFunc, values, touched) && (
                  <div
                    css={css`
                      width: 100%;
                      color: #e31e27;
                      margin-top: 20px;
                      line-height: 1.35;
                    `}>
                    You've indicated you are contributing currently, but your contributions are set
                    to zero. Please change your answer or enter a contribution amount.
                  </div>
                )}
                {(isInstitutionalAccount
                  ? editInstitutionalAccount &&
                    contribMethodChangeAllowed &&
                    values.employeeContrib === true
                  : contribMethodIsEditable && values.employeeContrib === true) && (
                  <div
                    css={css`
                      margin-bottom: 50px;
                    `}>
                    <Field
                      name='contribMethod'
                      render={({ input, meta }) => (
                        <MultiButtonInput
                          name={input.name}
                          value={input.value}
                          label='Contribution type'
                          buttons={[
                            { text: '% of Pay', value: 'P' },
                            { text: 'Fixed $ Amount', value: 'D' },
                          ]}
                          tooltip={TooltipText.contributionType()}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                        />
                      )}
                    />
                  </div>
                )}

                {needHelpIcon && (
                  <HelpIcon
                    helpLabel='Contribution limits'
                    tooltip={TooltipText.contributionLimits()}
                  />
                )}
                {pretaxSavingsRateIsEditable && (
                  <div
                    css={css`
                      width: 100%;
                    `}>
                    {values.employeeContrib === true && values.contribMethod === 'P' && (
                      <InputField
                        label='Pre-tax contribution'
                        flexLabel='0 1 340px'
                        flexInput='0 1 auto'>
                        <Field
                          name='pretaxSavingsRate'
                          format={v => v}
                          parse={v => v}
                          render={({ input, meta }) => (
                            <PercentInput
                              name={input.name}
                              value={input.value === '' ? null : input.value}
                              placeholder='0'
                              onChange={(name, value) => input.onChange(value)}
                              onBlur={name => input.onBlur()}
                              error={meta.error}
                              showError={
                                !showZeroContributionsError(editableFunc, values, touched) &&
                                meta.touched
                              }
                              width='125px'
                            />
                          )}
                        />
                      </InputField>
                    )}
                  </div>
                )}
                {preTaxSavingsAmtIsEditable && (
                  <div
                    css={css`
                      width: 100%;
                    `}>
                    {values.employeeContrib === true && values.contribMethod === 'D' && (
                      <InputField
                        label='Pre-tax contribution'
                        flexLabel='0 1 340px'
                        flexInput='0 1 auto'>
                        <Field
                          name='preTaxSavingsAmt'
                          format={value => (value === null ? undefined : value)}
                          parse={v => v}
                          render={({ input, meta }) =>
                            suffixWrapper(
                              <CurrencyInput
                                name={input.name}
                                value={input.value}
                                placeholder='0'
                                onChange={(name, value) => input.onChange(value)}
                                onBlur={input.onBlur}
                                error={meta.error}
                                showError={
                                  !showZeroContributionsError(editableFunc, values, touched) &&
                                  meta.touched
                                }
                                decimalScale={0}
                                width='125px'
                              />,
                              '/year'
                            )
                          }
                        />
                      </InputField>
                    )}
                  </div>
                )}
                {values.contributionEligibility === false && (
                  <div>
                    <InputField
                      label={`When ${
                        isSpouseOwned ? `${participantName} was` : 'you were'
                      } eligible to contribute:`}
                      flexLabel='0 1 340px'
                      flexInput='0 1 200px'
                    />
                  </div>
                )}
                {isInstitutionalAccount ? (
                  <div>
                    <div
                      css={css`
                        width: 100%;
                      `}>
                      {rothContribAllowed &&
                        rothSavingsRateIsEditable &&
                        values.employeeContrib === true &&
                        values.contributionEligibility === true &&
                        values.contribMethod === 'P' && (
                          <InputField
                            label='Contributing Roth? Enter percentage:'
                            flexLabel='0 1 340px'
                            flexInput='0 1 auto'>
                            <div>
                              <div
                                css={css`
                                  margin-top: 20px;
                                `}>
                                <Field
                                  name='rothSavingsRate'
                                  format={v => v}
                                  parse={v => v}
                                  render={({ input, meta }) => (
                                    <PercentInput
                                      name={input.name}
                                      value={input.value === '' ? null : input.value}
                                      placeholder='0'
                                      onChange={(name, value) => input.onChange(value)}
                                      onBlur={input.onBlur}
                                      error={meta.error}
                                      showError={meta.touched}
                                      width='125px'
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </InputField>
                        )}
                      {rothContribAllowed &&
                        rothSavingsRateIsEditable &&
                        values.employeeContrib === true &&
                        values.contributionEligibility === true &&
                        values.contribMethod === 'D' && (
                          <InputField
                            label='Contributing Roth? Enter amount:'
                            flexLabel='0 1 340px'
                            flexInput='0 1 auto'>
                            <div>
                              <div
                                css={css`
                                  margin-top: 20px;
                                `}>
                                {suffixWrapper(
                                  <Field
                                    name='rothSavingsAmt'
                                    format={value => (value === null ? undefined : value)}
                                    parse={v => v}
                                    render={({ input, meta }) => (
                                      <CurrencyInput
                                        name={input.name}
                                        value={input.value}
                                        placeholder='0'
                                        onChange={(name, value) => input.onChange(value)}
                                        onBlur={input.onBlur}
                                        error={meta.error}
                                        showError={meta.touched}
                                        decimalScale={0}
                                        width='125px'
                                      />
                                    )}
                                  />,
                                  '/year'
                                )}
                              </div>
                            </div>
                          </InputField>
                        )}
                    </div>
                    <div
                      css={css`
                        width: 100%;
                      `}>
                      {posttaxContribAllowed &&
                        posttaxSavingsRateIsEditable &&
                        values.employeeContrib === true &&
                        values.contributionEligibility === true &&
                        values.contribMethod === 'P' && (
                          <InputField
                            label='Contributing after-tax? Enter percentage:'
                            flexLabel='0 1 340px'
                            flexInput='0 1 auto'>
                            <div>
                              <div
                                css={css`
                                  margin-top: 20px;
                                `}>
                                {suffixWrapper(
                                  <Field
                                    name='posttaxSavingsRate'
                                    format={value => (value === null ? undefined : value)}
                                    parse={v => v}
                                    render={({ input, meta }) => (
                                      <PercentInput
                                        name={input.name}
                                        value={input.value === '' ? null : input.value}
                                        placeholder='0'
                                        onChange={(name, value) => input.onChange(value)}
                                        onBlur={input.onBlur}
                                        error={meta.error}
                                        showError={
                                          !showZeroContributionsError(
                                            editableFunc,
                                            values,
                                            touched
                                          ) && meta.touched
                                        }
                                        width='125px'
                                      />
                                    )}
                                  />
                                )}
                              </div>
                            </div>
                          </InputField>
                        )}
                      {posttaxContribAllowed &&
                        posttaxSavingsRateIsEditable &&
                        values.employeeContrib === true &&
                        values.contributionEligibility === true &&
                        values.contribMethod === 'D' && (
                          <InputField
                            label='Contributing after-tax? Enter amount:'
                            flexLabel='0 1 340px'
                            flexInput='0 1 auto'>
                            <div>
                              <div
                                css={css`
                                  margin-top: 20px;
                                `}>
                                {suffixWrapper(
                                  <Field
                                    name='postTaxSavingsAmt'
                                    format={value => (value === null ? undefined : value)}
                                    parse={v => v}
                                    render={({ input, meta }) => (
                                      <CurrencyInput
                                        name={input.name}
                                        value={input.value}
                                        placeholder='0'
                                        onChange={(name, value) => input.onChange(value)}
                                        onBlur={input.onBlur}
                                        error={meta.error}
                                        showError={meta.touched}
                                        decimalScale={0}
                                        width='125px'
                                      />
                                    )}
                                  />,
                                  '/year'
                                )}
                              </div>
                            </div>
                          </InputField>
                        )}
                    </div>
                  </div>
                ) : (
                  <div>
                    {showRothAllowed && (
                      <Field
                        name='rothContribAllowed'
                        render={({ input, meta }) => (
                          <MultiButtonInput
                            name={input.name}
                            value={input.value}
                            label={
                              values.contributionEligibility
                                ? 'Roth permitted?'
                                : 'Were Roth contributions permitted?'
                            }
                            buttons={[
                              { text: 'Yes', value: true },
                              { text: 'No', value: false },
                            ]}
                            tooltip={TooltipText.rothContributions()}
                            onChange={(name, value) => input.onChange(value)}
                            onBlur={input.onBlur}
                            error={meta.error}
                            showError={meta.touched}>
                            {rothSavingsRateIsEditable &&
                              values.contributionEligibility === true &&
                              values.employeeContrib === true &&
                              values.rothContribAllowed === true &&
                              values.contribMethod === 'P' && (
                                <div
                                  css={css`
                                    margin-top: 20px;
                                  `}>
                                  <Field
                                    name='rothSavingsRate'
                                    format={v => v}
                                    parse={v => v}
                                    render={({ input, meta }) => (
                                      <PercentInput
                                        name={input.name}
                                        value={input.value === '' ? null : input.value}
                                        placeholder='0'
                                        onChange={(name, value) => input.onChange(value)}
                                        onBlur={input.onBlur}
                                        error={meta.error}
                                        showError={
                                          !showZeroContributionsError(
                                            editableFunc,
                                            values,
                                            touched
                                          ) && meta.touched
                                        }
                                        width='125px'
                                      />
                                    )}
                                  />
                                </div>
                              )}

                            {rothSavingsAmtIsEditable &&
                              values.contributionEligibility === true &&
                              values.employeeContrib === true &&
                              values.rothContribAllowed === true &&
                              values.contribMethod === 'D' && (
                                <div
                                  css={css`
                                    margin-top: 20px;
                                  `}>
                                  {suffixWrapper(
                                    <Field
                                      name='rothSavingsAmt'
                                      format={value => (value === null ? undefined : value)}
                                      parse={v => v}
                                      render={({ input, meta }) => (
                                        <CurrencyInput
                                          name={input.name}
                                          value={input.value}
                                          placeholder='0'
                                          onChange={(name, value) => input.onChange(value)}
                                          onBlur={input.onBlur}
                                          error={meta.error}
                                          showError={
                                            !showZeroContributionsError(
                                              editableFunc,
                                              values,
                                              touched
                                            ) && meta.touched
                                          }
                                          decimalScale={0}
                                          width='125px'
                                        />
                                      )}
                                    />,
                                    '/year'
                                  )}
                                </div>
                              )}
                          </MultiButtonInput>
                        )}
                      />
                    )}

                    {showPostTaxAllowed && (
                      <Field
                        name='posttaxContribAllowed'
                        render={({ input, meta }) => (
                          <MultiButtonInput
                            name={input.name}
                            value={input.value}
                            label={
                              values.contributionEligibility
                                ? 'After-tax permitted?'
                                : 'Were after-tax contributions permitted?'
                            }
                            buttons={[
                              { text: 'Yes', value: true },
                              { text: 'No', value: false },
                            ]}
                            tooltip={TooltipText.afterTaxContributions()}
                            onChange={(name, value) => input.onChange(value)}
                            onBlur={input.onBlur}
                            error={meta.error}
                            showError={meta.touched}>
                            {posttaxSavingsRateIsEditable &&
                              values.contributionEligibility === true &&
                              values.employeeContrib === true &&
                              values.posttaxContribAllowed === true &&
                              values.contribMethod === 'P' && (
                                <div
                                  css={css`
                                    margin-top: 20px;
                                  `}>
                                  <Field
                                    name='posttaxSavingsRate'
                                    format={v => v}
                                    parse={v => v}
                                    render={({ input, meta }) => (
                                      <PercentInput
                                        name={input.name}
                                        value={input.value === '' ? null : input.value}
                                        placeholder='0'
                                        onChange={(name, value) => input.onChange(value)}
                                        onBlur={input.onBlur}
                                        error={meta.error}
                                        showError={
                                          !showZeroContributionsError(
                                            editableFunc,
                                            values,
                                            touched
                                          ) && meta.touched
                                        }
                                        width='125px'
                                      />
                                    )}
                                  />
                                </div>
                              )}

                            {postTaxSavingsAmtIsEditable &&
                              isSpouseOwned &&
                              values.contributionEligibility === true &&
                              values.employeeContrib === true &&
                              values.posttaxContribAllowed === true &&
                              values.contribMethod === 'D' && (
                                <div
                                  css={css`
                                    margin-top: 20px;
                                  `}>
                                  {suffixWrapper(
                                    <Field
                                      name='postTaxSavingsAmt'
                                      format={value => (value === null ? undefined : value)}
                                      parse={v => v}
                                      render={({ input, meta }) => (
                                        <CurrencyInput
                                          name={input.name}
                                          value={input.value}
                                          placeholder='0'
                                          onChange={(name, value) => input.onChange(value)}
                                          onBlur={input.onBlur}
                                          error={meta.error}
                                          showError={
                                            !showZeroContributionsError(
                                              editableFunc,
                                              values,
                                              touched
                                            ) && meta.touched
                                          }
                                          decimalScale={0}
                                          width='125px'
                                        />
                                      )}
                                    />,
                                    '/year'
                                  )}
                                </div>
                              )}
                          </MultiButtonInput>
                        )}
                      />
                    )}
                  </div>
                )}
                {isSpouseOwned &&
                  values.contributionEligibility &&
                  values.employeeContrib !== null && <ContributionAsterisk />}
              </div>
            </WizardStep>
          </div>
        )
      }}
    />
  )
}

const ContributionAsterisk = () => (
  <div
    css={css`
      color: #7a8e96;
      font-size: 1.125rem;
      line-height: 1.45;
    `}>
    * Current Roth and/or after-tax contributions are not taken into account; however, investment
    balances can be entered.
  </div>
)

export default withRouter(inject('store', 'account')(observer(EditPaycheckContributions)))
