/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css, useTheme } from '@emotion/react'
import { useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Field, useField } from 'react-final-form'

import { CircleNegative, CirclePlus } from '../../../../assets/icons'
import { SVGWrapper, Spacer, ButtonInput, CurrencyInput } from '../../../../components'
import { Page } from '../style'

import DurationStartingAndStoppingAge from '../../shared-components/DurationStartingAndStoppingAge'

function WorkPartTimeAfterRetiring({ store: { person, spouse } }) {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  return (
    <Page.ConsiderationWrapper>
      <Page.ConsiderationContainer onClick={() => setOpen(!open)}>
        <SVGWrapper
          css={css`
            margin-right: 10px;
          `}
          svg={open ? CircleNegative : CirclePlus}
          fill={theme.circleIconColor}
          size='large'
        />
        <Page.ConsiderationLabel>Work part-time after retiring</Page.ConsiderationLabel>
      </Page.ConsiderationContainer>
      {open && (
        <>
          <Spacer space='5px' />
          <Page.ReminderText>
            Be sure to talk with your tax advisor to understand the impact this additional income
            can have on your taxes.
          </Page.ReminderText>
          <Page.ConsiderationBody
            css={css`
              display: flex;
              flex-direction: column;
              padding-left: 34px;
            `}>
            <PlanningWorkPartTimeField person={person} fieldName='planningWorkPartTimeClient' />
            <Condition when='planningWorkPartTimeClient' is>
              <Page.FieldWrapper>
                <Page.Label>Duration: starting age</Page.Label>
                <Page.Value>
                  <DurationStartingAndStoppingAge fieldName='durationStartingAgeClient' />
                </Page.Value>
              </Page.FieldWrapper>
            </Condition>

            <Condition when='planningWorkPartTimeClient' is>
              <Page.FieldWrapper>
                <Page.Label>Duration: stopping age</Page.Label>
                <Page.Value>
                  <DurationStartingAndStoppingAge fieldName='durationStoppingAgeClient' />
                </Page.Value>
              </Page.FieldWrapper>
            </Condition>

            <Condition when='planningWorkPartTimeClient' is>
              <Page.FieldWrapper>
                <Page.Label>Annual income from job after retiring</Page.Label>
                <Page.Value>
                  <IncomeAfterRetirement fieldName='clientIncome' />
                </Page.Value>
              </Page.FieldWrapper>
            </Condition>

            {person.includeSpouse && (
              <>
                <Spacer space='20px' />
                <PlanningWorkPartTimeField spouse={spouse} fieldName='planningWorkPartTimeSpouse' />
                <Condition when='planningWorkPartTimeSpouse' is>
                  <Page.FieldWrapper>
                    <Page.Label>Duration: starting age</Page.Label>
                    <Page.Value>
                      <DurationStartingAndStoppingAge fieldName='durationStartingAgeSpouse' />
                    </Page.Value>
                  </Page.FieldWrapper>
                </Condition>

                <Condition when='planningWorkPartTimeSpouse' is>
                  <Page.FieldWrapper>
                    <Page.Label>Duration: stopping age</Page.Label>
                    <Page.Value>
                      <DurationStartingAndStoppingAge fieldName='durationStoppingAgeSpouse' />
                    </Page.Value>
                  </Page.FieldWrapper>
                </Condition>

                <Condition when='planningWorkPartTimeSpouse' is>
                  <Page.FieldWrapper>
                    <Page.Label>Annual income from job after retiring</Page.Label>
                    <Page.Value>
                      <IncomeAfterRetirement fieldName='spouseIncome' />
                    </Page.Value>
                  </Page.FieldWrapper>
                </Condition>
              </>
            )}
          </Page.ConsiderationBody>
        </>
      )}
    </Page.ConsiderationWrapper>
  )
}
export default inject('store')(observer(WorkPartTimeAfterRetiring))

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

const PlanningWorkPartTimeField = ({ spouse, person, fieldName }) => {
  const { input } = useField(fieldName, {
    subscription: { value: true, touched: true, error: true },
  })
  return (
    <Page.FieldWrapper>
      <Page.Label>
        {' '}
        {person && person.firstName ? person.displayName : spouse.firstName} planning to work
        part-time?
      </Page.Label>
      <Page.Value>
        <ButtonInput
          isActive={input.value === true}
          name={input.name}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          onClick={(name, value) => input.onChange(value)}
          text='Yes'
          value
          width='122px'
        />
        <ButtonInput
          isActive={input.value === false}
          name={input.name}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          onClick={(name, value) => input.onChange(value)}
          text='No'
          value={false}
          width='122px'
        />
      </Page.Value>
    </Page.FieldWrapper>
  )
}

const IncomeAfterRetirement = ({ fieldName }) => {
  return (
    <Field
      name={fieldName}
      format={value => (value === null ? undefined : value)}
      parse={v => v}
      subscription={{ value: true, touched: true, error: true }}
      render={({ input, meta }) => (
        <CurrencyInput
          error={meta.error}
          name={input.name}
          placeholder='0'
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          onChange={(name, value) => input.onChange(value)}
          showError={meta.touched}
          value={input.value}
        />
      )}
    />
  )
}
