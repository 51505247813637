import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { Spacer } from '../../../components'
import { FillGaugeWithDescription, StackedChartWithDescription } from '../elements'
import CurrentStrategy from './CurrentStrategy'

const ModuleContainer = styled.div`
  border: 1px solid #b6c0c4;
  padding: 2rem 2rem 1.25rem 2rem;
  background-color: white;
`

const SectionTitle = styled.div`
  color: #3a4d57;
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 2.25rem;
  max-width: 300px;
`

const LeftCard = styled.div`
  flex: 1 1 395px;
  margin: 10px;
`

const RightCard = styled.div`
  flex: 0 1 20%;
  margin: 10px;
`

const StyledRow = styled.div`
  background-color: ${p => (p.isOnTrack ? '#F1F8F1' : '#FEF7F1')};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  padding: 10px 4px;
`

const StatusText = styled.span`
  color: ${p => p.theme.mediumGray};
`

const StatusMessage = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  padding: 8px 12px 12px 6px;
`

const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  margin: -10px;
`

class StatusCard extends Component {
  offTrackText() {
    const { displayName } = this.props.store.person
    return `${
      displayName ? `${displayName}, if ` : 'If '
    } you continue saving and investing as you do today, you are unlikely to be able to continue your current lifestyle after retiring. Outlined below, however, is our recommended plan to get you on track for the financial future you want.`
  }

  onTrackText() {
    const { displayName } = this.props.store.person
    return `You're doing great${
      displayName ? `, ${displayName}` : ''
    }! Continue saving and investing as you do today, and you are likely to be able to continue your current lifestyle after retiring. Outlined below are any recommendations we have to keep you on track for the financial future you want.`
  }

  render() {
    const { store, displayInflation } = this.props
    const { isOnTrack } = store.baseCase

    return (
      <ModuleContainer>
        <SectionTitle>Current Strategy</SectionTitle>
        <Spacer space='12px' />
        <SectionContainer>
          <LeftCard>
            <StyledRow isOnTrack={isOnTrack}>
              Plan: <StatusText>{isOnTrack ? 'ON TRACK' : 'OFF TRACK'}</StatusText>
            </StyledRow>
            <Spacer space='12px' />
            <FillGaugeWithDescription
              displayInflation={displayInflation}
              engineType='base'
              id='baseIncomeGoal'
            />
            <Spacer space='6px' />
            <StyledRow isOnTrack={isOnTrack}>
              <StatusMessage>{isOnTrack ? this.onTrackText() : this.offTrackText()}</StatusMessage>
            </StyledRow>
          </LeftCard>
          <RightCard>
            <StackedChartWithDescription engineType='base' displayInflation={displayInflation} />
          </RightCard>
        </SectionContainer>
        <Spacer space='12px' />
        <CurrentStrategy displayInflation={displayInflation} />
      </ModuleContainer>
    )
  }
}

export default inject('store')(observer(StatusCard))
