/* eslint-disable react/jsx-handler-names */

import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'

import GoalList from './GoalList'
import { Page } from '../elements'
import { LabeledDivider, AddButton, TooltipText, HelpIcon } from '../../../components'

class GoalsDashboard extends Component {
  addAccount = () => {
    const { history } = this.props
    history.push('/goals/add')
  }

  render() {
    const {
      currentGoals,
      pastGoals,
      person: { displayName },
    } = this.props.store

    return (
      <Page>
        <Page.MainTitle>
          {displayName}'s
          <br />
          Goals
        </Page.MainTitle>
        <HelpIcon helpLabel='What is a goal?' tooltip={TooltipText.whatIsAGoal()} />
        <GoalList goals={currentGoals} history={this.props.history} includeFill />
        {pastGoals.length > 0 && <LabeledDivider label='Past' />}
        <GoalList goals={pastGoals} history={this.props.history} />
        <AddButton
          label='Add new goal'
          link
          underline
          onClick={this.addAccount}
          width='100%'
          css={css`
            @media (max-width: 800px) {
              margin: auto;
            }
          `}
        />
      </Page>
    )
  }
}

export default inject('store')(observer(GoalsDashboard))
