import { observer, inject } from 'mobx-react'

import AssumptionsFirstPage from './AssumptionsFirstPage'

function Assumptions() {
  return (
    <>
      <AssumptionsFirstPage />
    </>
  )
}

export default inject('store')(observer(Assumptions))
