import { inject, observer } from 'mobx-react'
import { css } from '@emotion/react'
import posed, { PoseGroup } from 'react-pose'
import { Header, Page } from '../elements'
import { Button, Row, Spacer } from '../../../components'
import { useEffect, useState } from 'react'
import { API, API_URL, domain } from '../../../api'
import CircleCheck from '../../../assets/icons/CircleCheckSVGWhite.svg'
import _ from 'lodash'
import LearnMoreDrawer from './learn-more/LearnMoreDrawer'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function RetailWelcome(props) {
  const {
    location,
    store: { selectPricebook },
  } = props
  const [pricebookOptions, setPricebookOptions] = useState([])
  const [organizationData, setOrganizationData] = useState(undefined)
  const [learnMoreDrawerOpen, setLearnMoreDrawerOpen] = useState(false)
  const [learnMorePricebook, setLearnMorePricebook] = useState(undefined)
  const [selectedPricebook, setSelectedPricebook] = useState(undefined)
  const [onlyOne, setOnlyOne] = useState(false)
  const [orgPays, setOrgPays] = useState(false)
  const [termsOfServiceLink, setTermsOfServiceLink] = useState(null)
  const promotion = pricebookOptions?.filter(bundle =>
    bundle.prices.find(price => price.introductionPeriodInDays > 1)
  )
  const introPeriod = promotion
    ?.map(bundle => bundle.prices)
    .map(price => price.map(arr => arr.introductionPeriodInDays).find(promo => promo > 1))
  const introPeriodInMonths = introPeriod?.map(promo => Math.floor((promo ?? 0) / 30))
  const specialPromoOffer = pricebookOptions?.find(
    p => p.pricebook.metadata?.subHeader === 'Special Promo Offer!*'
  )

  useEffect(() => {
    const getPricebookOptions = async () => {
      const { data } = await API.get('pricebook/available')
      setPricebookOptions([...data])
      if (data.length === 1) {
        setOnlyOne(true)
        setSelectedPricebook(data[0])
      }
    }
    getPricebookOptions()
  }, [])

  useEffect(() => {
    const getOrganizationData = async () => {
      const { data } = await API.get('organization')
      setOrganizationData(data)
    }
    getOrganizationData()
  }, [])

  useEffect(() => {
    const getTermsLink = async () => {
      const res = await API.get('/termsAndConditions/docs/default')
      const termsObject = _.find(res.data, doc => {
        return doc.docDescription === 'Terms of Service'
      })
      const url = `${API_URL}termsAndConditions/default?docId=${termsObject.id}`
      setTermsOfServiceLink(url)
    }
    getTermsLink()
  }, [])

  useEffect(() => {
    setOrgPays(
      pricebookOptions?.filter(pricebook => pricebook.pricebook.paidBy === 'ORG')?.length > 0
    )
  }, [pricebookOptions])

  const onCancel = () => {
    window.location.replace('https://www.guidedchoice.com')
  }

  const handleSignUp = async () => {
    console.log(selectPricebook)
    await selectPricebook(selectedPricebook.pricebook.id)
    if (selectedPricebook.pricebook.productType === 'GUIDED_CHOICE') {
      window.location.href = `https://retail-auth.${domain}/oauth2/authorization/B2C_1_signup1`
    } else {
      window.location.href = `https://3n.${domain}/create-account`
    }
  }

  return (
    <PoseGroup animateOnMount>
      <RouteContainer key={location.key || location.pathname}>
        <LearnMoreDrawer
          pricebookBundle={learnMorePricebook}
          drawerOpen={learnMoreDrawerOpen}
          setDrawerOpen={setLearnMoreDrawerOpen}
        />
        <Page.Container Container>
          <Header
            css={css`
              padding: 0;
            `}
            organizationLogo={
              organizationData?.logoUri ? `${API_URL}/organization/logo` : undefined
            }
          />
          <Page
            css={css`
              align-items: flex-start;
            `}>
            <Page.Heading title='Sign Up: Welcome!' />
            <Page.Link href={`https://retail-auth.${domain}`}>
              Already have an account? Log in
            </Page.Link>
            <Spacer space='10px' />
            <ProductOptions
              pricebookOptions={pricebookOptions}
              selectedPricebook={selectedPricebook}
              setSelectedPricebook={onlyOne ? undefined : setSelectedPricebook}
              setDrawerOpen={setLearnMoreDrawerOpen}
              setLearnMorePricebook={setLearnMorePricebook}
            />
            {!specialPromoOffer && (
              <>
                <Page.FooterNote>
                  Please see our{' '}
                  <Page.Link href={termsOfServiceLink} target='_blank'>
                    Terms of Service
                  </Page.Link>{' '}
                  {!orgPays && (
                    <>
                      and{' '}
                      <Page.Link href='/retail/payment/cancellation-policy' target='_blank'>
                        Cancellation and Refund Policy
                      </Page.Link>{' '}
                    </>
                  )}
                  for details. Have any questions? We’re available M-F 8am-5pm PST at (800)
                  774-7459, or anytime at{' '}
                  <Page.Link href='mailto:help@guidedchoice.com'>help@guidedchoice.com</Page.Link>.
                </Page.FooterNote>
              </>
            )}
            <Spacer space='10px' />
            {(introPeriodInMonths?.length ?? 0) > 1 ? (
              <>
                <Page.FooterNote>
                  * After the period of free access ends, Advice subscription auto-renews at
                  $14.99/month
                </Page.FooterNote>
              </>
            ) : (
              introPeriodInMonths?.map((x, index) => (
                <>
                  <Page.FooterNote key={index} variant='p14' color='secondary'>
                    * After {x === 1 ? '30 days' : x}
                    {x > 1 && ' months'}, Advice subscription auto-renews at $14.99/month
                  </Page.FooterNote>
                </>
              ))
            )}
            {orgPays && !specialPromoOffer && (
              <Page.FooterNote>*Your organization is paying for your subscription.</Page.FooterNote>
            )}
            {specialPromoOffer && (
              <>
                <Page.FooterNote>
                  * Terms and Conditions apply. We reserve the right to validate eligibility and to
                  modify or end this promotional offer at any time. Please see our{' '}
                  <Page.Link href={termsOfServiceLink} target='_blank'>
                    Terms of Service
                  </Page.Link>{' '}
                  for full details.
                </Page.FooterNote>
              </>
            )}
            <Spacer space='10px' />
            <Row
              css={css`
                justify-content: space-between;
                width: 100%;
              `}>
              <Button label='Cancel' size='small' secondary onClick={onCancel} />
              <Button
                label='Sign up'
                size='small'
                primary
                disabled={!selectedPricebook}
                onClick={handleSignUp}
              />
            </Row>
          </Page>
        </Page.Container>
      </RouteContainer>
    </PoseGroup>
  )
}

export const ProductOptions = props => {
  const {
    pricebookOptions,
    selectedPricebook,
    setSelectedPricebook,
    setDrawerOpen,
    setLearnMorePricebook,
  } = props

  const formatDollars = amount => {
    const formatter = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' })
    return formatter.format(amount)
  }

  return (
    <Row
      css={css`
        justify-content: flex-start;
        flex-wrap: wrap;
      `}>
      {pricebookOptions.map((pricebook, index) => {
        const selected = pricebook === selectedPricebook && setSelectedPricebook
        const orgPays = pricebook.pricebook.paidBy === 'ORG'
        const freeTrial30Day = pricebook.pricebook.metadata?.freeTrial30Day === 'true'
        const subHeader = pricebook.pricebook.metadata?.subHeader
        const specialPromoOffer = subHeader === 'Special Promo Offer!*'
        return (
          <Page.PricebookCard
            key={pricebook.id}
            selected={selected}
            css={css`
              margin-bottom: 20px;
              margin-right: ${index + 1 <= pricebookOptions.length ? '100px' : ''};
            `}>
            <Row
              css={css`
                height: 100%;
              `}>
              <Page.Column
                css={css`
                  justify-content: ${setSelectedPricebook ? 'space-between' : 'center'};
                  height: 100%;
                  width: 45%;
                `}>
                <Page.Column>
                  <Page.PricebookHeading selected={selected}>
                    {pricebook.pricebook.pricebookName}
                  </Page.PricebookHeading>
                  {subHeader && (
                    <>
                      <Spacer space='10px' />
                      <Page.PricebookMetaSubHeader>{subHeader}</Page.PricebookMetaSubHeader>
                    </>
                  )}
                  <Spacer space='10px' />
                  <Page.Column>
                    {pricebook.pricebook.description.map((bullet, index) => (
                      <Page.BulletText key={index} selected={selected}>
                        {bullet}
                      </Page.BulletText>
                    ))}
                  </Page.Column>
                </Page.Column>
                <Page.Column
                  css={css`
                    align-items: ${setSelectedPricebook ? 'center' : 'start'};
                  `}>
                  {setSelectedPricebook && (
                    <Button
                      label={selected ? 'Selected' : 'Select'}
                      select
                      selected={selected}
                      size='small'
                      onClick={() =>
                        setSelectedPricebook(prevState =>
                          prevState !== pricebook ? pricebook : undefined
                        )
                      }
                      disabled={pricebookOptions.length === 1}
                    />
                  )}
                  {!setSelectedPricebook && <Spacer space='10px' />}
                  <Page.Link
                    onClick={() => {
                      setLearnMorePricebook(pricebook)
                      setDrawerOpen(true)
                    }}
                    css={css`
                      padding-left: 5px;
                      color: ${setSelectedPricebook ? (selected ? 'white' : '#4d9927') : ''};
                    `}>
                    Learn more
                  </Page.Link>
                </Page.Column>
              </Page.Column>
              <Page.Column
                css={css`
                  justify-content: space-between;
                  flex-grow: 1;
                  height: 100%;
                  align-items: center;
                  margin-left: '10px';
                `}>
                {selected ? (
                  <img
                    src={CircleCheck}
                    alt='Checkmark'
                    css={css`
                      width: 125px;
                      height: auto;
                      padding: 16px 0px;
                    `}
                  />
                ) : (
                  <>
                    <img src={pricebook.pricebook.logoUrl} alt='Product Logo' height='50px' />
                    <img src={pricebook.pricebook.artUrl} alt='Product Art' height='90px' />
                  </>
                )}
                <Page.PricebookSubCard selected={selected}>
                  <Page.PricebookSubHeading selected={selected}>
                    {specialPromoOffer
                      ? '$0'
                      : orgPays
                      ? '$0*'
                      : freeTrial30Day
                      ? `30 days free`
                      : formatDollars(pricebook.prices[0].price / 100.0)}
                    <span>
                      {pricebook.prices[0].intervalType !== 'NONE' &&
                      !freeTrial30Day &&
                      !specialPromoOffer
                        ? '/' + pricebook.prices[0].intervalType
                        : ''}
                    </span>
                  </Page.PricebookSubHeading>
                  <Spacer space='5px' />
                  <div
                    css={css`
                      align-self: center;
                      font-size: 12px;
                    `}>
                    {freeTrial30Day
                      ? 'then $14.99/month'
                      : pricebook.prices[0].price === 0 || specialPromoOffer
                      ? 'free'
                      : pricebook.prices[0].intervalType === 'MONTH'
                      ? 'auto-renews monthly'
                      : pricebook.prices[0].intervalType === 'YEAR'
                      ? 'auto-renews annually'
                      : 'one-time payment'}
                  </div>
                </Page.PricebookSubCard>
              </Page.Column>
            </Row>
          </Page.PricebookCard>
        )
      })}
    </Row>
  )
}

export default inject('store')(observer(RetailWelcome))
