/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import posed from 'react-pose'
import styled from '@emotion/styled'
import * as yup from 'yup'
import _ from 'lodash'
import HCaptcha from '@hcaptcha/react-hcaptcha'

import { TextInput, Button, FormError } from '../../components'
import { Page, Header } from './elements'
import { AUTH } from '../../api'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

const SendPasswordResetMail = props => {
  const [token, setToken] = useState(null)
  const [siteKey, setSiteKey] = useState(null)
  const captchaRef = useRef(null)

  const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
    // captchaRef.current.execute()
  }

  useEffect(() => {
    const getSiteKey = async () => {
      const res = await AUTH.get('/captcha/siteKey')
      setSiteKey(res.data)
    }
    getSiteKey()
  }, [])

  useEffect(() => {
    if (token) {
      // Make an API call here
      captchaRef?.current?.removeCaptcha()
    }
  }, [token])
  const [status, setStatus] = useState('')
  const {
    location,
    history,
    store: { initiatePasswordReset },
  } = props

  const onSubmit = async values => {
    return handleInitiatePasswordReset(values)
  }

  const handleInitiatePasswordReset = async values => {
    if (!captchaRef?.current?.state?.isRemoved) {
      return {
        [FORM_ERROR]: 'Box must be checked before you can proceed',
      }
    }
    try {
      await initiatePasswordReset(values)
      setStatus('success')
    } catch (err) {
      // if the email is not a match in our DB, we still send user to generic confirmation page
      setStatus('success')
    }
  }

  const handleCancel = () => {
    history.goBack()
  }
  const onGoToLogin = () => {
    history.goBack(-2)
  }

  return (
    <RouteContainer key={location.key || location.pathname}>
      <Page.Container>
        <Header />
        <Page>
          {status === 'success' ? (
            <>
              <Page.Heading title='To complete your password change…' />
              <Page.Text>
                If we have an account under the email address entered, we'll send an email
                containing a link to complete your password change.
              </Page.Text>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                  margin-top: 40px;
                  width: 90%;
                `}>
                <Button
                  onClick={onGoToLogin}
                  label='Got it!'
                  type='button'
                  primary
                  style={{ width: 124 }}
                />
              </div>
            </>
          ) : (
            <>
              <Page.Heading title='Reset password' />
              <Form
                onSubmit={onSubmit}
                subscription={{ submitting: true, submitError: true }}
                render={({ handleSubmit, submitting, submitError }) => (
                  <Page.Form>
                    <Page.AnimateGroup
                      css={css`
                        padding-left: 20px;
                      `}>
                      <Page.AnimateItem>
                        <Page.Text
                          css={css`
                            padding-bottom: 30px;
                          `}>
                          Enter your login email. Click Next and GuidedChoice will send an email to
                          the address entered so that you may continue resetting your password.
                        </Page.Text>
                      </Page.AnimateItem>

                      <Page.AnimateItem>
                        <BorderedCard>
                          <CardTitle>Login email</CardTitle>
                          <EmailField></EmailField>

                          <div
                            css={css`
                              display: flex;
                              justify-content: space-between;
                            `}>
                            <div />
                            <Button
                              onClick={handleSubmit}
                              label='Next'
                              type='submit'
                              primary
                              style={{ width: 124 }}
                              disabled={submitting}
                            />
                          </div>
                        </BorderedCard>

                        {siteKey && (
                          <HCaptcha
                            sitekey={siteKey}
                            onLoad={onLoad}
                            onVerify={setToken}
                            ref={captchaRef}
                          />
                        )}

                        {submitError && <FormError err={submitError} />}
                      </Page.AnimateItem>
                    </Page.AnimateGroup>
                  </Page.Form>
                )}
              />
            </>
          )}
          {status !== 'success' && (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
              `}>
              <Button
                style={{ width: 124 }}
                label='Cancel'
                onClick={handleCancel}
                type='button'
                secondary
              />
              <div></div>
            </div>
          )}
        </Page>
      </Page.Container>
    </RouteContainer>
  )
}

export default inject('store')(observer(SendPasswordResetMail))

function EmailField() {
  const { input, meta } = useField('email', {
    validate: value =>
      yup
        .string()
        .email()
        .required('Email is required')
        .max(128, 'Maximum character limit 128 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='email'
      error={meta.error}
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='name@email.com'
      showError={meta.touched}
      value={input.value}
      width='100%'
      css={css`
        padding-left: 20px;
      `}
    />
  )
}

const BorderedCard = styled.div`
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px 0;
  padding: 1rem;
  max-width: 461px;
`
const CardTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 36px;
  line-height: 46px;
  font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
  font-weight: 300;
  margin-bottom: 25px;
  align-self: flex-start;
  overflow-wrap: break-word;
`
