/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'

import { Page } from '../style'
import { Spacer, Button } from '../../../../components'

function InsuringFinalExpenses() {
  return (
    <Page.Container narrow>
      <Page.Header
        css={css`
          line-height: 2.75rem;
          font-size: 2.3rem;
        `}>
        Insuring final expenses
      </Page.Header>
      <Spacer space='21px;' />

      <Page.Paragraph>
        When you retire, your employer-sponsored group life insurance benefits may terminate—or cost
        too much to continue your policy individually. An insured funeral plan can provide
        protection for you or a spouse and cover your final expenses which typically include:
      </Page.Paragraph>
      <Spacer space='3px;' />

      <Page.Ul>
        <Page.Li small>Vital statistics required for the death certificate</Page.Li>
        <Page.Li small>Copies of the death certificate</Page.Li>
        <Page.Li small>Cost of funeral expenses</Page.Li>
        <Page.Li small>Burial plot</Page.Li>
      </Page.Ul>
      <Spacer space='20px;' />

      <Page.Paragraph>Protection features of insured funeral plans</Page.Paragraph>
      <Spacer space='5px;' />

      <Page.Ul>
        <Page.Li small>
          Pays cash directly to your family, regardless of any other insurance
        </Page.Li>
        <Page.Li small>Benefits cannot be reduced</Page.Li>
        <Page.Li small>Rates can never be increased</Page.Li>
        <Page.Li small>Medical examinations not required in most cases</Page.Li>
        <Page.Li small>Coverage can be applied for at any age till 90</Page.Li>
        <Page.Li small>
          Coverage can be made available instantly for those age 45 to 85 by answering four simple
          medical questions
        </Page.Li>
        <Page.Li small>
          Other features: builds cash and loan values; creates memorial service record
        </Page.Li>
      </Page.Ul>
      <Spacer space='16px;' />

      <Page.Paragraph>
        Contact a funeral advisor for more information or to receive a quote.
      </Page.Paragraph>
      <Spacer space='20px' />

      <Button
        type='button'
        onClick={() => window.close()}
        secondary
        label='Close'
        width='73px'
        css={css`
          height: 41px;
          font-size: 14px;
          text-align: center;
        `}
      />
      <Spacer space='20px' />
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(InsuringFinalExpenses))
