import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import * as R from 'ramda'

import { API_URL } from '../../../api'
import { Card, CardFieldError, HelpIcon, TooltipText, Loading, Spacer } from '../../../components'

const Link = styled.a`
  color: ${p => p.theme.darkestGray};
  text-decoration: underline;
  cursor: pointer;
  word-wrap: break-word;
`

const TableHeader = styled.td`
  color: ${p => p.theme.lightestGray};
  min-width: 160px;
  text-align: ${p => (p.align ? p.align : null)};
  word-wrap: break-word;
  vertical-align: ${p => (p.verticalAlign ? p.verticalAlign : null)};
`

const Table = styled.table`
  table-layout: fixed;

  td {
    padding: 3px 0px;
  }
`

const TableLabel = styled.div`
  min-width: 160px;
  color: ${p => p.theme.lightestGray};
  margin-bottom: ${p => (p.marginBottom ? p.marginBottom : 0)};

  display: flex;
  align-items: ${p => (p.alignItems ? p.alignItems : null)};

  @media (max-width: 1020px) {
    padding: 3px 0 32px 0;
  }
`

const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
`

const Padding = styled.span`
  display: inline-block;
  padding: 0 40px;
`

const StyledCell = styled.td`
  color: ${p => p.theme.darkestGray};
  width: ${p => (p.width ? p.width : 'auto')};
`

const GuideLink = ({ evtDesc, timeStamp }) => {
  const generateGuideLink = ({ guideId }) => `${API_URL}guide/fetch/${guideId}`
  const convertDate = timeStamp => dayjs(timeStamp).format('MMDDYYYY.HHmmss')

  return (
    <Link href={generateGuideLink(evtDesc)} target='_blank' rel='noreferrer noopener'>
      {`GuidedChoice-AdvisoryServiceGuide.${convertDate(timeStamp)}.pdf`}
    </Link>
  )
}

const HistoricalDataContents = inject('store')(
  observer(
    class extends Component {
      state = { status: 'loading' }

      componentDidMount() {
        this.props.store
          .getUserLogs()
          .then(res => this.setState({ status: 'done' }))
          .catch(err => {
            console.error(err)
            this.setState({ status: 'error' })
          })
      }

      parseDate = date => {
        if (date === null) {
          return ''
        }
        return dayjs(date).format('MM/DD/YYYY') || ''
      }

      parseTime = time => {
        if (time === null) {
          return ''
        }

        const localTime = dayjs(time)
        const hour = localTime.format('HH')
        let parsedTime = localTime.format('hh:mm:ss') || ''

        if (parsedTime) {
          if (hour <= 11) {
            parsedTime = parsedTime + ' AM'
          } else {
            parsedTime = parsedTime + ' PM'
          }
        }

        return parsedTime
      }

      // transforms eventDescription
      // normalize to JSON, prep `reason` field for display
      parseDesc = desc => {
        const parseReason = desc => {
          switch (desc.reason) {
            case 'email':
              return { ...desc, reason: 'E' }
            case 'view':
              return { ...desc, reason: 'V' }
            default:
              return desc
          }
        }

        return R.o(parseReason, JSON.parse)(desc)
      }

      // date format
      formatTime = timeStamp => {
        return dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss')
      }

      /**
       * @desc transforms guide data
       *
       * @param {Array} acc - list of guides
       * @param {Object} curr - guide object
       *
       * @return {Array} acc - list of transformed guides
       */
      prepGuide = (acc, curr) => {
        // safely extract timeStamp
        // default (current) timeStamp to act as a functional placeholder
        const safeTime = R.propOr(dayjs().format('YYYY-MM-DD HH:mm:ss'), 'timeStamp')
        const updateGuideModel = {
          eventDescription: this.parseDesc,
          timeStamp: this.formatTime,
        }
        const currGuide = R.evolve(updateGuideModel, curr)
        const { timeStamp } = currGuide
        const prevTimeStamp = R.o(safeTime, R.last)(acc)
        const sameActivity = dayjs(timeStamp).isSame(prevTimeStamp, 'second')
        const updateDesc = R.assocPath(['eventDescription', 'reason'], 'E V')
        const modifyLast = R.adjust(-1, updateDesc)

        // if same timestamp
        // then assume user viewed and emailed
        //  -> modify last guide meta data to include both events (E & V)
        // else append updated current guide to list
        return sameActivity ? modifyLast(acc) : [...acc, currGuide]
      }

      render() {
        const { features } = this.props.store
        const { recentGuideList, recentLoginList, changeSavingsList, recentStatements } =
          this.props.store.userLogs
        const generateReason = evtDesc => R.propOr('', 'reason', evtDesc)
        const generateTransaction = ({ caseId }) => {
          const { transactionObject } = this.props.store.userLogs

          return transactionObject.hasOwnProperty.call(caseId) ? 'Yes' : 'No'
        }
        const updatedGuideList = recentGuideList.reduce(this.prepGuide, [])

        if (this.state.status === 'loading') {
          return <Loading />
        } else if (this.state.status === 'error') {
          return (
            <CardFieldError>
              GuidedChoice does not have data on record for this account
            </CardFieldError>
          )
        }

        return (
          <div>
            {features.enableLoginHistory && (
              <TableContainer>
                <TableLabel>Login history</TableLabel>
                <Table>
                  <tbody>
                    {recentLoginList.map(login => (
                      <tr key={login.eventLogId}>
                        <StyledCell>{this.parseDate(login.timeStamp)}</StyledCell>
                        <StyledCell>
                          <Padding>{this.parseTime(login.timeStamp)}</Padding>
                        </StyledCell>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            )}

            <Spacer space='16px' />

            {features.enablePreviousGuides && (
              <TableContainer>
                <TableLabel alignItems='center'>
                  Previous guides&nbsp;
                  <HelpIcon size='mediumLarge' tooltip={TooltipText.guideHistory()} />
                </TableLabel>
                <Table>
                  <tbody>
                    <tr>
                      <TableHeader verticalAlign='bottom'>PDFs</TableHeader>
                      <TableHeader verticalAlign='bottom'>
                        <Padding>
                          Email (E) <br /> View (V)
                        </Padding>
                      </TableHeader>
                      <TableHeader verticalAlign='bottom'>Sent to recordkeeper</TableHeader>
                    </tr>
                    {updatedGuideList.map(
                      ({ eventLogId, eventDescription: evtDesc, timeStamp }) => (
                        <tr key={eventLogId}>
                          <td>
                            <GuideLink evtDesc={evtDesc} timeStamp={timeStamp} />
                          </td>
                          <StyledCell>
                            <Padding>{generateReason(evtDesc)}</Padding>
                          </StyledCell>
                          <StyledCell>{generateTransaction(evtDesc)}</StyledCell>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </TableContainer>
            )}

            <Spacer space='16px' />

            {changeSavingsList.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                <TableLabel alignItems='center' marginBottom='5px'>
                  Change savings only &nbsp;
                  <HelpIcon size='mediumLarge' tooltip={TooltipText.changeSavingsOnly()} />
                  <br />
                </TableLabel>
                <Table>
                  <tbody>
                    {changeSavingsList.map(change => (
                      <tr key={change.eventLogId}>
                        <td style={{ width: 158 }} />
                        <StyledCell width='125px'>{this.parseDate(change.timeStamp)}</StyledCell>
                        <StyledCell>{this.parseTime(change.timeStamp)}</StyledCell>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}

            <Spacer space='16px' />

            {recentStatements.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                <TableLabel alignItems='center' marginBottom='5px'>
                  Statements
                </TableLabel>
                <Table>
                  <tbody>
                    {recentStatements.map(statement => (
                      <tr key={statement.id}>
                        <td style={{ width: 158 }} />
                        <StyledCell width='125px'>{this.parseDate(statement.timestamp)}</StyledCell>
                        <StyledCell>
                          <Link
                            href={`${API_URL}statement/pdf?itemId=${statement.id}`}
                            target='_blank'
                            rel='noreferrer noopener'>
                            AnnualStatement.pdf
                          </Link>
                        </StyledCell>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        )
      }
    }
  )
)

class HistoricalDataCard extends Component {
  render() {
    return (
      <Card
        title='Historical data'
        editLabel='Older data'
        handleEdit={this.props.showHistoricalData}
        cardWidth='900px'
        id='historical-data'>
        <HistoricalDataContents />
      </Card>
    )
  }
}

export default inject('store')(observer(HistoricalDataCard))
