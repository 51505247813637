import { Component } from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  background-color: #022a3a;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 42pt 40pt;
  position: absolute;
  text-align: center;
  width: 100%;
  line-height: 1.45;
  font-size: 17pt;
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  font-weight: bold;
`

class PageFooter extends Component {
  static displayName = 'PageFooter'

  render() {
    return <Container className={this.props.className}>{this.props.children}</Container>
  }
}

export default PageFooter
