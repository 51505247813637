import * as Styles from './AvailableInvestmentsStyles'
import { Spacer, Row } from '../../../../components'
import AvailableInvestment from './AvailableInvestment'

function AvailableInvestments(props) {
  const { availableInvestments, history, displayTitle } = props
  const { type, accountType, id: accountId } = props.match.params

  const handleInvestmentClick = security => {
    history.push(`/accounts/add/${type}/${accountType}/${accountId}/investment`, {
      security: { ...security },
    })
  }

  return (
    <Styles.InvestmentsContainer>
      {displayTitle && 'Other available investment options'}
      <Spacer space='5px' />
      {availableInvestments.map(investment => (
        <Row
          onClick={() => handleInvestmentClick(investment)}
          style={{ width: '100%', flexWrap: 'nowrap', marginBottom: '10px' }}
          key={investment.id}>
          <AvailableInvestment investment={investment} />
        </Row>
      ))}
      <Spacer space='20px' />
    </Styles.InvestmentsContainer>
  )
}

export default AvailableInvestments
