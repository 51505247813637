const arrowLeft = `
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <title>arrow-pointing-left</title>
    <path
      class="icon-color"
      id="icn_backward-selected"
      data-name="icn/backward-selected"
      d="M21.6,12A9.6,9.6,0,0,0,2.4,12M0,12a12,12,0,0,1,24,0M2.4,12a9.6,9.6,0,
        0,0,19.2,0M24,12A12,12,0,0,1,0,12m13.93,4.13,0,2.82L7,12.07l7-7L14,8,
        9.9,12.09Z"
    />
  </svg>
`

export default arrowLeft
