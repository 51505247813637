/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { useTheme } from '@emotion/react'
import { css } from '@emotion/css'
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import styled from '@emotion/styled'
import _ from 'lodash'
import SSIChanges from '../onboarding/social-security-income/SSIChanges'
import { API_URL } from '../../api'
import { Page, Header } from './style'
import {
  Button,
  Spacer,
  Drawer,
  HelpIcon,
  TooltipText,
  FormError,
  Loading,
  ErrorPage,
} from '../../components'
import { numberToDollars } from '../../utils/utils'

let theme = '#000000'

const SingleSSI = function (props) {
  const {
    history,
    store: {
      person,
      spouse,
      primarySSIBenefits,
      getOptimizedSSI,
      primaryOptimizedSSI,
      saveSSBenefits,
      config,
    },
    store,
    main: { setSidebar, setTopNav },
  } = props
  const [drawer, setDrawer] = useState('')
  const [page, setPage] = useState('default')
  const previousLocation = history.location.state ? history.location.state.location : null

  const useThisTheme = useTheme()
  theme = useThisTheme

  useEffect(() => {
    setSidebar(false)
    setTopNav(false)

    document.documentElement.className = css`
      background: url(${API_URL + theme.bgRight}) no-repeat top right fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    `

    return () => {
      document.documentElement.className = ''
      setSidebar(true)
      setTopNav(true)
    }
  }, []) // eslint-disable-line

  const handleRequest = async () => {
    try {
      setPage('loading')
      await getOptimizedSSI()
      setPage('optimizeSSI')
      return false
    } catch (err) {
      console.error(err)
      setPage('error')
      return {
        [FORM_ERROR]:
          'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
      }
    }
  }

  const handleSave = async () => {
    try {
      await saveSSBenefits({ primarySSIBenefits: primaryOptimizedSSI })
      const isSpendown = _.get(config, 'isSpendown', null)
      if (isSpendown) {
        await store.processModifiedCase()
        return history.push({ pathname: '/spending', state: { from: 'SSI' } })
      }
      return history.push('/overall')
    } catch (err) {
      console.error(err)
      return {
        [FORM_ERROR]:
          'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
      }
    }
  }

  const onSubmitDefaultPage = () => handleRequest()
  const onSubmitOptimizedPage = () => handleSave()

  const initialValues = {
    waitAge: null,
    waitStatus: null,
  }

  if (page === 'loading') {
    return <Loading />
  }

  if (page === 'error') {
    return <ErrorPage />
  }

  if (page === 'default') {
    return (
      <>
        <Header />
        <Page
          css={css`
            max-width: 1000px;
          `}>
          <Page.Heading
            css={css`
              max-width: 100%;
            `}
            title='Social Security income (SSI)'
          />
          <Spacer space='-30px' />
          <Page.PleaseNote>Please note: Social Security estimates are before-tax</Page.PleaseNote>
          <Spacer space='20px' />

          <Form
            initialValues={initialValues}
            onSubmit={onSubmitDefaultPage}
            subscription={{ submitting: true, submitError: true, values: true }}
            render={({ handleSubmit, submitting, submitError }) => (
              <>
                <FieldWrapper>
                  <Label />
                  <Value>{person.displayName}</Value>
                </FieldWrapper>

                <FieldWrapper>
                  <Label>Age SSI payments begin</Label>
                  <Value active>
                    {!primarySSIBenefits.includeSsi && 'N/A'}
                    {primarySSIBenefits.includeSsi &&
                      primarySSIBenefits.receivingSsi === 0 &&
                      primarySSIBenefits.paymentBeginAge}
                    {primarySSIBenefits.includeSsi &&
                      primarySSIBenefits.receivingSsi === 1 &&
                      'Already receiving'}
                  </Value>
                </FieldWrapper>

                <FieldWrapper>
                  <Label>Monthly amount</Label>
                  <Value active>
                    {primarySSIBenefits.includeSsi
                      ? `${numberToDollars(primarySSIBenefits.monthlyAmount, true)}`
                      : 'N/A'}
                  </Value>
                </FieldWrapper>
                <Spacer space='8px' />

                <FieldWrapper>
                  <Label>Status</Label>
                  <Value active>{primarySSIBenefits.includeSsi ? 'Include' : 'Exclude'}</Value>
                </FieldWrapper>

                <FieldWrapper>
                  <Label>Use GuidedChoice estimate?</Label>
                  <Value active>
                    {primarySSIBenefits.includeSsi
                      ? primarySSIBenefits.useGcEstimate
                        ? 'Yes, estimate for me'
                        : "No, I'll enter my own"
                      : 'N/A'}
                  </Value>
                </FieldWrapper>
                <Spacer space='20px' />

                <>
                  <Page.Subtitle>
                    <hr />
                  </Page.Subtitle>
                  <Spacer space='10px' />

                  <FieldWrapper>
                    <Label />
                    <Value>{person.displayName}</Value>
                  </FieldWrapper>
                  <FieldWrapper>
                    <Label>
                      Full retirement age for SSI{' '}
                      <HelpIcon tooltip={TooltipText.fullRetirementAge()} />
                    </Label>
                    <Value> {primarySSIBenefits.fullRetirementAgeYear} </Value>
                  </FieldWrapper>
                  <FieldWrapper>
                    <Label>Monthly amount</Label>
                    <Value>
                      ${numberToDollars(primarySSIBenefits.fullRetirementAgeMonthlyAmount, true)}
                    </Value>
                  </FieldWrapper>
                  <Spacer space='8px' />
                </>

                <Spacer space='25px' />
                <Page.YourChoice>It's your choice...</Page.YourChoice>
                {submitError && <FormError err={submitError} />}
                <Spacer space='20px' />

                <div
                  className={css`
                    display: flex;
                    justify-content: space-around;
                  `}>
                  <Button
                    type='button'
                    label='Keep as is'
                    onClick={() =>
                      previousLocation
                        ? history.replace({
                            pathname: previousLocation.pathname,
                            state: { from: 'SSI' },
                          })
                        : history.goBack()
                    }
                    disabled={submitting}
                    css={css`
                      background-color: #fff;
                      color: #4a606a;
                      border: 1px solid #4a606a;
                    `}
                  />
                  <Button
                    type='button'
                    secondary
                    label='Make changes'
                    css={css`
                      background-color: #4a606a;
                      color: #fff;
                    `}
                    onClick={() => setDrawer('makeSSIChanges')}
                  />
                  {primarySSIBenefits.includeSsi && (
                    <span
                      css={css`
                        display: flex;
                        align-items: flex-start;
                      `}>
                      <Button
                        type='button'
                        primary
                        label='Optimize SSI'
                        onClick={handleSubmit}
                        disabled={submitting}
                      />

                      <span
                        css={css`
                          margin-top: 10px;
                          margin-left: 8px;
                        `}>
                        <HelpIcon tooltip={TooltipText.optimizeSSI()} />
                      </span>
                    </span>
                  )}
                </div>

                <Drawer
                  active={drawer === 'makeSSIChanges'}
                  title='Social Security Income (SSI)'
                  width='50%'>
                  <SSIChanges
                    includeSpouse={person.includeSpouse}
                    person={person}
                    spouse={spouse}
                    handleCancel={() => setDrawer('')}
                    history={history}
                  />
                </Drawer>
              </>
            )}
          />
        </Page>
      </>
    )
  }

  if (page === 'optimizeSSI') {
    return (
      <>
        <Header />
        <Page
          css={css`
            max-width: 1000px;
          `}>
          <Page.Heading
            css={css`
              max-width: 100%;
            `}
            title='Social Security income (SSI)'
          />
          <Spacer space='-30px' />
          <p
            css={css`
              color: #999;
              margin-top: -30px;
            `}>
            Please note: Social Security estimates are before-tax
          </p>
          <Spacer space='20px' />

          <Form
            initialValues={initialValues}
            onSubmit={onSubmitOptimizedPage}
            subscription={{ submitting: true, submitError: true, values: true }}
            render={({ handleSubmit, submitting, submitError, values }) => (
              <>
                <FieldWrapper>
                  <Label />
                  <Value>{person.displayName}</Value>
                </FieldWrapper>

                <FieldWrapper>
                  <Label>Currently selected age SSI begins</Label>
                  <Value active>
                    {!primarySSIBenefits.includeSsi && 'N/A'}
                    {primarySSIBenefits.includeSsi &&
                      primarySSIBenefits.receivingSsi === 0 &&
                      primarySSIBenefits.paymentBeginAge}
                    {primarySSIBenefits.includeSsi &&
                      primarySSIBenefits.receivingSsi === 1 &&
                      'Already receiving'}
                  </Value>
                </FieldWrapper>

                <FieldWrapper>
                  <Label>Monthly amount</Label>
                  <Value active>
                    {primarySSIBenefits.includeSsi
                      ? `${numberToDollars(primarySSIBenefits.monthlyAmount, true)}`
                      : 'N/A'}
                  </Value>
                </FieldWrapper>
                <Spacer space='8px' />

                <FieldWrapper>
                  <Label>Status</Label>
                  <Value active>{primarySSIBenefits.includeSsi ? 'Include' : 'Exclude'}</Value>
                </FieldWrapper>

                <FieldWrapper>
                  <Label>Use GuidedChoice estimate?</Label>
                  <Value active>
                    {primarySSIBenefits.includeSsi
                      ? primarySSIBenefits.useGcEstimate
                        ? 'Yes, estimate for me'
                        : "No, I'll enter my own"
                      : 'N/A'}
                  </Value>
                </FieldWrapper>
                <Spacer space='10px' />

                <hr />

                <Spacer space='10px' />
                <Page.YourChoice>Optimized results</Page.YourChoice>

                <FieldWrapper>
                  <Label />
                  <Value>{person.displayName}</Value>
                </FieldWrapper>
                <FieldWrapper>
                  <Label>Optimal age to begin SSI</Label>
                  <Value> {_.get(primaryOptimizedSSI, 'paymentBeginAge')} </Value>
                </FieldWrapper>
                <FieldWrapper>
                  <Label>Monthly payment at optimal age</Label>
                  <Value>
                    ${numberToDollars(_.get(primaryOptimizedSSI, 'monthlyAmount', null), true)}
                  </Value>
                </FieldWrapper>
                <Spacer space='10px' />
                <hr />
                <Spacer space='10px' />

                <Page.YourChoice>It's your choice...</Page.YourChoice>
                {submitError && <FormError err={submitError} />}
                <Spacer space='20px' />

                <div
                  className={css`
                    display: flex;
                    justify-content: space-around;
                  `}>
                  <Button
                    type='button'
                    label='Keep as is'
                    onClick={() =>
                      previousLocation
                        ? history.replace({
                            pathname: previousLocation.pathname,
                            state: { from: 'SSI' },
                          })
                        : history.goBack()
                    }
                    disabled={submitting}
                  />
                  <Button
                    type='button'
                    secondary
                    label='Make changes'
                    css={css`
                      background-color: #4a606a;
                      color: #fff;
                    `}
                    onClick={() => setDrawer('makeSSIChanges')}
                  />
                  <Button
                    type='button'
                    primary
                    label='Use optimized SSI'
                    onClick={handleSubmit}
                    disabled={
                      primarySSIBenefits.paymentBeginAge === primaryOptimizedSSI.paymentBeginAge &&
                      parseInt(primarySSIBenefits.monthlyAmount) ===
                        parseInt(primaryOptimizedSSI.monthlyAmount)
                    }
                  />
                </div>

                <Drawer
                  active={drawer === 'makeSSIChanges'}
                  title='Social Security Income (SSI)'
                  subtitle='Edit SSI'
                  width='50%'>
                  <SSIChanges
                    includeSpouse={person.includeSpouse}
                    person={person}
                    spouse={spouse}
                    handleCancel={() => setDrawer('')}
                    history={history}
                  />
                </Drawer>
              </>
            )}
          />
        </Page>
      </>
    )
  }
  return null
}

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  margin-bottom: 10px;
`
const Label = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.ssiLabelColor};
  font-size: 1.125rem;
  line-height: 1.35;
  width: 100%;
  flex: 3;
`
const Value = styled.div`
  color: ${({ active }) => (active ? theme.ssiValueColor : theme.ssiLabelColor)};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  flex: 5;
`

export default inject('store', 'main')(observer(SingleSSI))
