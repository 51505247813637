import variables from '../variables'
import colors from '../colors'
import reduceCSSCalc from 'reduce-css-calc'

const { unit } = variables

const avatar = {
  color: colors.rt.white,
  background: colors.rt.grey500,
  size: reduceCSSCalc(`calc(4 * ${unit})`),
  fontSize: reduceCSSCalc(`calc(2.4 * ${unit})`),
}

export default avatar
