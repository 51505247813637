import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import AnnuityDashboard from '../annuity-modeling/AnnuityDashboard'
import EmergencyFundEdit from '../emergency-fund/EmergencyFundEdit'
import PensionEdit from '../pension/PensionDashboard'

import { Page } from '../style'
import { numberToDollars } from '../../../utils/utils'
import { Spacer, Drawer, SVGWrapper, DeleteModal } from '../../../components'
import { CircleX } from '../../../assets/icons'
import HelpIcon from '../../../components/HelpIcon'
import TooltipText from '../../../components/TooltipText'

function WhereMoneyComesFrom({
  history,
  store: {
    spouse,
    person,
    accountsBalance,
    totalSpouseBalanceExcludingPensionAndAnnuity,
    spouseSSIBenefits,
    primarySSIBenefits,
    spouseMonthlyAnnuityAmount,
    primaryMonthlyAnnuityAmount,
    allPensions,
    spendingRecommendedCases,
    pensionOptions,
    primaryMonthlyAnnuityStartAge,
    spouseMonthlyAnnuityStartAge,
    selectedInstitutionalPension,
    employerAccounts,
    deletePrimaryMonthlyAnnuity,
    deleteSpouseMonthlyAnnuity,
    emergencyFund,
    getAnnuities,
    config: { templateId },
  },
}) {
  const [otherPensions, setOtherPensions] = useState([])
  const [annuities, setAnnuities] = useState([])
  const [drawer, setDrawer] = useState('')
  const [beforeTax, setBeforeTax] = useState(true)
  const [deleteStatus, setDeleteStatus] = useState('done')
  const [deletePrimaryActive, setDeletePrimaryActive] = useState(false)
  const [deleteSpouseActive, setDeleteSpouseActive] = useState(false)

  const toggleDeletePrimaryModal = () => {
    setDeletePrimaryActive(!deletePrimaryActive)
    setDeleteStatus('done')
  }
  const toggleDeleteSpouseModal = () => {
    setDeleteSpouseActive(!deleteSpouseActive)
    setDeleteStatus('done')
  }
  const toggleBeforeTax = () => {
    setBeforeTax(!beforeTax)
  }
  let primaryInstitutionalPension = allPensions.filter(
    pension => pension.id === selectedInstitutionalPension.pensionId
  )[0]
  if (primaryInstitutionalPension === undefined) {
    primaryInstitutionalPension = {}
  }

  useEffect(() => {
    const result = allPensions
      .filter(allAvailablePension => {
        return employerAccounts.filter(employerPension => {
          const include = employerPension.id === allAvailablePension.id
          return include
        })
      })
      .filter(pension => pension.id !== primaryInstitutionalPension.id)
      .filter(pension => pension.accountId === null)
    setOtherPensions(result)
  }, [employerAccounts, allPensions, primaryInstitutionalPension.id])

  useEffect(() => {
    const loadAnnuities = async () => {
      const result = await getAnnuities()
      setAnnuities(result)
    }
    loadAnnuities()
    //eslint-disable-next-line
  }, [])

  const taxRate = _.get(
    spendingRecommendedCases,
    'averageMarketSpendingAdvice.distributionTaxRate',
    null
  )

  return (
    <Page.SeeWhereContainer
      css={css`
        padding: 0;
      `}>
      <Page.SmallText
        css={css`
          text-align: right;
        `}>
        <Page.InTheBoxBelowText>In the box below, show amounts as:</Page.InTheBoxBelowText>
        <Page.ButtonContainer>
          <Page.CustomButton active={beforeTax} onClick={toggleBeforeTax}>
            Before tax
          </Page.CustomButton>
          <Page.CustomButton active={!beforeTax} onClick={toggleBeforeTax}>
            After tax
          </Page.CustomButton>
        </Page.ButtonContainer>
      </Page.SmallText>
      <Page.ShowBoxContainer>
        <Page.Section>
          <Page.SubSection>
            <Page.LabelHeader>Social Security</Page.LabelHeader>
            <Page.Value />
          </Page.SubSection>
          <Page.SubSection>
            {primarySSIBenefits.includeSsi ? (
              primarySSIBenefits.useGcEstimate ? (
                <Page.Status>Using GuidedChoice estimates</Page.Status>
              ) : (
                <Page.Status>Using own values</Page.Status>
              )
            ) : null}
            <Page.Value />
          </Page.SubSection>
          <Page.SubSection>
            <Page.Label>{person.displayName}'s monthly payment</Page.Label>
            <Page.Amount>
              {primarySSIBenefits.includeSsi
                ? numberToDollars(
                    beforeTax
                      ? primarySSIBenefits.monthlyAmount
                      : primarySSIBenefits.monthlyAmount * (1 - taxRate),
                    true
                  )
                : 0}
            </Page.Amount>
          </Page.SubSection>

          {primarySSIBenefits.includeSsi && (
            <Page.SubSection>
              <Page.Label>Begins at age</Page.Label>
              <Page.Value>{primarySSIBenefits.paymentBeginAge}</Page.Value>
            </Page.SubSection>
          )}

          {person.includeSpouse && (
            <>
              <Spacer space='8px' />
              <Page.SubSection>
                {spouseSSIBenefits.includeSsi ? (
                  spouseSSIBenefits.useGcEstimate ? (
                    <Page.Status>Using GuidedChoice estimates</Page.Status>
                  ) : (
                    <Page.Status>Using own values</Page.Status>
                  )
                ) : null}
                <Page.Value />
              </Page.SubSection>
              <Page.SubSection>
                <Page.Label>{spouse.firstName}'s monthly payment</Page.Label>
                <Page.Amount>
                  {spouseSSIBenefits.includeSsi
                    ? numberToDollars(
                        beforeTax
                          ? spouseSSIBenefits.monthlyAmount
                          : spouseSSIBenefits.monthlyAmount * (1 - taxRate),
                        true
                      )
                    : 0}
                </Page.Amount>
              </Page.SubSection>
              {spouseSSIBenefits.includeSsi && (
                <Page.SubSection>
                  <Page.Label>Begins at age</Page.Label>
                  <Page.Value>{spouseSSIBenefits.paymentBeginAge}</Page.Value>
                </Page.SubSection>
              )}
            </>
          )}
          <Page.EditButton
            onClick={() =>
              history.push({ pathname: '/social-security', state: { location: history.location } })
            }>
            Edit
          </Page.EditButton>
        </Page.Section>

        {allPensions.length > 0 ? (
          <Page.Section>
            <Page.SubSection>
              <Page.LabelHeader>Pension</Page.LabelHeader>
              <Page.Value />
            </Page.SubSection>
            {primaryInstitutionalPension.pensionName && (
              <Page.SubSection>
                <Page.Label
                  css={css`
                    color: #f48024;
                  `}>
                  Pension option selected <br />
                </Page.Label>
                <Page.Value />
              </Page.SubSection>
            )}
            {primaryInstitutionalPension.pensionName && (
              <>
                <Page.SubSection
                  css={css`
                    margin-top: 5px;
                  `}>
                  <Page.Label>
                    {person.displayName}'s {primaryInstitutionalPension.pensionName}
                  </Page.Label>
                  <Page.Value />
                </Page.SubSection>
                <Page.SubSection>
                  <Page.Label>{person.displayName}'s monthly payment</Page.Label>
                  <Page.Amount>
                    {numberToDollars(
                      beforeTax
                        ? primaryInstitutionalPension.monthlyPensionIncome
                        : primaryInstitutionalPension.monthlyPensionIncome * (1 - taxRate),
                      true
                    )}
                  </Page.Amount>
                </Page.SubSection>
                <Page.SubSection>
                  <Page.Label>Begins at age</Page.Label>
                  <Page.Value>{primaryInstitutionalPension.pensionStartAge}</Page.Value>
                </Page.SubSection>
                <Spacer space='10px' />
              </>
            )}
            {otherPensions.map(pension => (
              <div key={pension.id}>
                <Page.SubSection
                  css={css`
                    margin-top: 5px;
                  `}>
                  <Page.Label>
                    {pension.ownerId === person.id
                      ? person.preferredName
                        ? person.preferredName
                        : person.firstName
                      : spouse.firstName}
                    's {pension.pensionName}
                  </Page.Label>
                  <Page.Value />
                </Page.SubSection>
                {pension.paymentType === 'M' ? (
                  <>
                    <Page.SubSection>
                      <Page.Label>Monthly payment</Page.Label>
                      <Page.Amount>
                        {numberToDollars(
                          beforeTax
                            ? pension.monthlyPensionIncome
                            : pension.monthlyPensionIncome * (1 - taxRate),
                          true
                        )}
                      </Page.Amount>
                    </Page.SubSection>
                    <Page.SubSection>
                      <Page.Label>Begins at age </Page.Label>
                      <Page.Value>{pension.pensionStartAge}</Page.Value>
                    </Page.SubSection>
                  </>
                ) : (
                  <>
                    <Page.SubSection>
                      <Page.Label>Lump sum payment</Page.Label>
                      <Page.Amount>
                        {numberToDollars(
                          beforeTax
                            ? pension.monthlyPensionIncome
                            : pension.monthlyPensionIncome * (1 - taxRate),
                          true
                        )}
                      </Page.Amount>
                    </Page.SubSection>
                    <Page.SubSection>
                      <Page.Label>
                        Expected at age{' '}
                        <HelpIcon
                          size='small'
                          tooltip={TooltipText.lumpSumPensionPayoutWhereMoneyComesFrom()}
                        />
                      </Page.Label>
                      <Page.Value>{pension.pensionStartAge}</Page.Value>
                    </Page.SubSection>
                  </>
                )}
                <Spacer space='10px' />
              </div>
            ))}
            {primaryInstitutionalPension.pensionName &&
              (templateId === 4 ? (
                <Page.EditButton
                  onClick={() =>
                    history.push({
                      pathname: `/accounts/pension/pension/${primaryInstitutionalPension.id}`,
                    })
                  }>
                  Edit
                </Page.EditButton>
              ) : (
                <Page.EditButton onClick={() => setDrawer('editPension')}>
                  {Object.keys(pensionOptions).length > 2 ? 'Edit' : 'View'}
                </Page.EditButton>
              ))}
          </Page.Section>
        ) : null}

        <Page.Section>
          <Page.SubSection>
            <Page.LabelHeader>Other lifetime income</Page.LabelHeader>
            <Page.Value />
          </Page.SubSection>
          <Page.SubSection>
            <Page.LabelWrapper>
              <Page.Label>
                {primaryMonthlyAnnuityAmount > 0 ? (
                  <span
                    css={css`
                      margin-right: 8px;
                    `}>
                    <SVGWrapper
                      onClick={() => setDeletePrimaryActive(!deletePrimaryActive)}
                      svg={CircleX}
                      fill='#b6c0c4'
                      hoverFill='#053240'
                      activeFill='#0495bf'
                      size='large'
                    />
                  </span>
                ) : null}
                {deletePrimaryActive && (
                  <DeleteModal
                    handleConfirmation={async () => {
                      setDeleteStatus('loading')
                      try {
                        await deletePrimaryMonthlyAnnuity({ accountsBalance })
                        setDeletePrimaryActive(!deletePrimaryActive)
                        setDeleteStatus('done')
                      } catch (err) {
                        setDeleteStatus('error')
                      }
                    }}
                    isModalOpen={deletePrimaryActive}
                    title='Delete modeled income'
                    toggleModal={toggleDeletePrimaryModal}
                    loading={deleteStatus === 'loading'}
                    error={deleteStatus === 'error'}
                  />
                )}
                Modeled income for {person.displayName}
              </Page.Label>
            </Page.LabelWrapper>
            <Page.Value />
          </Page.SubSection>
          <Page.SubSection>
            <Page.Label>Monthly payment</Page.Label>
            <Page.Amount>
              {numberToDollars(
                beforeTax
                  ? primaryMonthlyAnnuityAmount
                  : primaryMonthlyAnnuityAmount * (1 - taxRate),
                true
              )}
            </Page.Amount>
          </Page.SubSection>
          <Page.SubSection>
            <Page.Label>Begins at age</Page.Label>
            <Page.Value>{primaryMonthlyAnnuityStartAge || 'N/A'}</Page.Value>
          </Page.SubSection>
          {person.includeSpouse && totalSpouseBalanceExcludingPensionAndAnnuity > 0 && (
            <>
              <Spacer space='10px' />
              <Page.SubSection
                css={css`
                  margin-top: 5px;
                `}>
                <Page.LabelWrapper>
                  <Page.Label>
                    {spouseMonthlyAnnuityAmount > 0 ? (
                      <span
                        css={css`
                          margin-right: 8px;
                        `}>
                        <SVGWrapper
                          onClick={() => {
                            setDeleteSpouseActive(!deleteSpouseActive)
                          }}
                          svg={CircleX}
                          fill='#b6c0c4'
                          hoverFill='#053240'
                          activeFill='#0495bf'
                          size='large'
                        />
                      </span>
                    ) : null}
                    {deleteSpouseActive && (
                      <DeleteModal
                        handleConfirmation={async () => {
                          setDeleteStatus('loading')
                          try {
                            await deleteSpouseMonthlyAnnuity({ accountsBalance })
                            setDeleteSpouseActive(!deleteSpouseActive)
                            setDeleteStatus('done')
                          } catch (err) {
                            setDeleteStatus('error')
                          }
                        }}
                        isModalOpen={deleteSpouseActive}
                        title='Delete modeled income'
                        toggleModal={toggleDeleteSpouseModal}
                        loading={deleteStatus === 'loading'}
                        error={deleteStatus === 'error'}
                      />
                    )}
                    Modeled income for {spouse.firstName}
                  </Page.Label>
                </Page.LabelWrapper>
                <Page.Value />
              </Page.SubSection>
              <Page.SubSection>
                <Page.Label>Monthly payment</Page.Label>
                <Page.Amount>
                  {numberToDollars(
                    beforeTax
                      ? spouseMonthlyAnnuityAmount
                      : spouseMonthlyAnnuityAmount * (1 - taxRate),
                    true
                  )}
                </Page.Amount>
              </Page.SubSection>
              <Page.SubSection>
                <Page.Label>Begins at age</Page.Label>
                <Page.Value>{spouseMonthlyAnnuityStartAge || 'N/A'}</Page.Value>
              </Page.SubSection>
              <Spacer space='10px' />
            </>
          )}

          {annuities.map(account => {
            return (
              <div key={account.id}>
                <Page.SubSection
                  css={css`
                    margin-top: 5px;
                  `}>
                  <Page.Label>
                    {account.personId === person.id
                      ? person.preferredName
                        ? person.preferredName
                        : person.firstName
                      : spouse.firstName}
                    's {account.annuityName}
                  </Page.Label>
                  <Page.Value />
                </Page.SubSection>
                <Page.SubSection>
                  <Page.Label>Monthly payment</Page.Label>
                  <Page.Amount>
                    {numberToDollars(
                      beforeTax ? account.monthlyIncome : account.monthlyIncome * (1 - taxRate),
                      true
                    )}
                  </Page.Amount>
                </Page.SubSection>
                <Page.SubSection>
                  <Page.Label>Begins at age</Page.Label>
                  <Page.Value>{account.annuityStartAge}</Page.Value>
                </Page.SubSection>
                <Spacer space='10px' />
              </div>
            )
          })}
          <Page.EditButton onClick={() => setDrawer('editAnnuity')}>Edit</Page.EditButton>
        </Page.Section>

        <Page.Section
          css={css`
            margin-bottom: 0px;
          `}>
          <Page.SubSection>
            <Page.LabelHeader>
              Investment balance &nbsp;
              {primaryMonthlyAnnuityAmount > 0 ||
              (person.includeSpouse && spouseMonthlyAnnuityAmount > 0) ? (
                <HelpIcon tooltip={TooltipText.investmentBalance()} />
              ) : null}
            </Page.LabelHeader>
            <Page.Amount>
              {numberToDollars(
                Number(accountsBalance) - Number(_.get(emergencyFund, 'amount', 0)),
                true
              )}
            </Page.Amount>
          </Page.SubSection>
        </Page.Section>

        {_.get(emergencyFund, 'amount', null) > 0 && (
          <Page.Section
            css={css`
              margin-top: 0px;
            `}>
            <Page.SubSection>
              <Page.LabelHeader>
                Reduced by emergency savings &nbsp;
                <HelpIcon tooltip={TooltipText.emergencyFundHelp()} />
              </Page.LabelHeader>
              <Page.Amount>
                {numberToDollars(_.get(emergencyFund, 'amount', null), true)}
              </Page.Amount>
            </Page.SubSection>
            <Page.EditButton onClick={() => setDrawer('editEmergencyFund')}>Edit</Page.EditButton>
          </Page.Section>
        )}
      </Page.ShowBoxContainer>

      <Drawer active={drawer === 'editAnnuity'} title='Securing monthly income' width='70%'>
        <AnnuityDashboard setDrawer={setDrawer} handleCancel={() => setDrawer('')} />
      </Drawer>

      <Drawer active={drawer === 'editEmergencyFund'} title='Emergency fund' width='50%'>
        <EmergencyFundEdit setDrawer={setDrawer} handleCancel={() => setDrawer('')} />
      </Drawer>

      <Drawer active={drawer === 'editPension'} title='Pension' width='50%'>
        <PensionEdit history={history} setDrawer={setDrawer} handleCancel={() => setDrawer('')} />
      </Drawer>
    </Page.SeeWhereContainer>
  )
}
export default inject('store', 'main')(observer(WhereMoneyComesFrom))
