import { css, useTheme } from '@emotion/react'
import { useState, useEffect } from 'react'
import { Provider, inject, Observer } from 'mobx-react'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled from '@emotion/styled'

import { API_URL } from '../../api'
import { Loading } from '../../components'
import { LogoHeader, Stepper, Page } from './elements'
import OffboardingStore from './OffboardingStore'
import Intro from './intro/Intro'
import GetYourGuide from './retail/GetYourGuide'
import Contribution from './contribution/Contribution'
import CompanyStock from './company-stock/CompanyStock'
import ValueProposition from './value-proposition/ValueProposition'
import ImplementChanges from './implement-changes/ImplementChanges'
import AccountChanges from './account-changes/AccountChanges'
import ConfirmEmail from './confirm-email/ConfirmEmail'
import Confirmation from './confirmation/Confirmation'
import ConfirmPaycheckContributions from './confirm-paycheck-contributions/ConfirmPaycheckContributions'
import EnrollmentSelection from './enrollment/EnrollmentSelection'
import EnrollmentConfirmed from './enrollment/EnrollmentConfirmed'
import ModificationsComplete from './enrollment/ModificationsComplete'
import InsuringFinalExpenses from './spend-down/static-pages/InsuringFinalExpenses'
import ReverseMortgage from './spend-down/static-pages/ReverseMortgage'
import HandyChecklist from './spend-down/static-pages/HandyChecklist'

const secretGuideLink = true

const Container = styled.div`
  max-width: 1200px;
  padding: 8px;
  margin: auto;
`

function Offboarding(props) {
  const [state, setState] = useState('loading')
  const [offboarding] = useState(() => OffboardingStore.create({}, { store: props.store }))
  const theme = useTheme()

  async function initializeOffboarding() {
    try {
      setState('loading')

      // fetch data needed for offboarding domain
      await Promise.all([
        props.store.getPerson(),
        props.store.getSpouse(),
        props.store.getDependents(),
        props.store.getContactInfo(),
        props.store.getDocuments(),
        props.store.getAcceptedCase(props.store.acceptedCaseId),
        props.store.getInstitutionalAccount(),
        props.store.getPlanOverview(),
      ])

      await offboarding.setIsRetailMember()
      offboarding.setTheme(theme)
      // set offboarding routes as well
      offboarding.setRoutes()

      setState('done')
    } catch (err) {
      console.error(err)
      setState('error')
    }
  }

  useEffect(() => {
    const { acceptedCaseId } = props.store

    // if accepted case id is required, do check here for redirect
    // if check passes, continue on
    if (!acceptedCaseId) {
      return props.history.replace('/overall')
    }

    // set the correct SVG background
    document.documentElement.className = offboarding.background

    // data fetching for offboarding process
    initializeOffboarding()

    return () => {
      // reset background, acceptedCaseId, and acceptedContributionDistribution
      document.documentElement.className = ''
      props.store.setAcceptedCaseId(null)
      props.store.setAcceptedContributionDistribution(null)
      offboarding.setTheme(null)
    }
  }, []) // eslint-disable-line

  function DetermineInitialPage() {
    return <Redirect to={offboarding.initialPage} />
  }

  function handleOnClick(e) {
    if (e.shiftKey) {
      window.open(
        `${window.location.origin}/implement/guide/${props.store.acceptedCaseId}`,
        '_blank'
      )
    }
  }

  const handleOpenPDFGuide = e => {
    const { acceptedCaseId } = props.store
    if (e.shiftKey) {
      window.open(`${API_URL}guide/generateGuide?caseId=${acceptedCaseId}`, '_blank')
    }
  }

  function render() {
    if (state === 'loading') {
      return (
        <div
          css={css`
            margin: 20px;
          `}>
          <Loading />
        </div>
      )
    }

    if (state === 'error') {
      return <Page.ServerError>Oops! Something went wrong, please try again later</Page.ServerError>
    }

    return (
      <Provider offboarding={offboarding}>
        <Container>
          <LogoHeader />

          {offboarding.enableStepper && (
            <Stepper
              currentStep={offboarding.activeStep(props.location.pathname.slice(11))}
              totalSteps={offboarding.routes.map((path, i) => 'Step ' + (i + 1))}
            />
          )}

          <Switch>
            <Route exact path='/implement/intro' component={Intro} />
            <Route exact path='/implement/get-your-guide' component={GetYourGuide} />
            <Route exact path='/implement/contribution' component={Contribution} />
            <Route exact path='/implement/company-stock' component={CompanyStock} />
            <Route exact path='/implement/value-proposition' component={ValueProposition} />
            <Route exact path='/implement/implement-changes' component={ImplementChanges} />
            <Route exact path='/implement/account-changes' component={AccountChanges} />
            <Route exact path='/implement/confirm-email' component={ConfirmEmail} />
            <Route exact path='/implement/confirmation' component={Confirmation} />
            <Route
              exact
              path='/implement/confirm-paycheck-contributions'
              component={ConfirmPaycheckContributions}
            />
            <Route exact path='/implement/enrollment-selection' component={EnrollmentSelection} />
            <Route exact path='/implement/enrollment-confirmed' component={EnrollmentConfirmed} />
            <Route
              exact
              path='/implement/modifications-complete'
              component={ModificationsComplete}
            />
            <Route
              exact
              path='/implement/insuring-final-expenses'
              component={InsuringFinalExpenses}
            />
            <Route exact path='/implement/reverse-mortgage' component={ReverseMortgage} />
            <Route exact path='/implement/handy-checklist' component={HandyChecklist} />

            <Route component={DetermineInitialPage} />
          </Switch>

          {secretGuideLink && (
            <div
              css={css`
                width: 30px;
                height: 30px;
                opacity: 0;
                position: fixed;
                top: 0;
                left: 0;
                cursor: default;
              `}
              onClick={handleOnClick}>
              Open Web Guide
            </div>
          )}

          {secretGuideLink && (
            <div
              css={css`
                width: 30px;
                height: 30px;
                opacity: 0;
                position: fixed;
                top: 0;
                right: 0;
                cursor: default;
              `}
              onClick={handleOpenPDFGuide}>
              Open PDF Guide
            </div>
          )}
        </Container>
      </Provider>
    )
  }

  return <Observer>{render}</Observer>
}

export default inject('store')(Offboarding)
