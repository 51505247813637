import { css } from '@emotion/react'
import { useRef } from 'react'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'

import { Wrapper } from './style'
import { numberToDollars } from '../../../utils/utils'
import HoldingRow from './HoldingRow'
import { SplitBarChartLegend } from '../../shared'

import { Page, PageHeader } from '../elements'
import { IndexToSVGMap } from '../../../constants'

function InstitutionalInvestments(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
    },
    store: {
      plan: { planName },
      institutionalHoldingData,
    },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'investment' })
    willMount.current = false
  }

  const page = findByName('investment')

  const renderRows = (data, svg) => {
    const sortedData = _.orderBy(data, obj => [(obj.name || '').toLowerCase()], ['asc'])

    return sortedData
      .filter(item => item.newValue !== null)
      .map((item, i) => {
        return (
          <HoldingRow
            key={item.name}
            title={item.name}
            i={i}
            svg={svg}
            oldValue={item.oldValue}
            newValue={item.newValue}
          />
        )
      })
  }

  const sortedData = _.orderBy(institutionalHoldingData, obj => [(obj.name || '').toLowerCase()], [
    'asc',
  ])
  const balance = sortedData.reduce((acc, cur) => {
    return (
      acc +
      cur.reduce((total, item) => {
        return total + _.get(item, 'oldValue.dollar', 0)
      }, 0)
    )
  }, 0)

  if (balance === 0) {
    return null
  }

  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.RecommendedText>Recommended investments for:</Wrapper.RecommendedText>
        <Wrapper.PlanName>{planName}</Wrapper.PlanName>
        <Wrapper.AdviceText>
          Before making your withdrawal, be sure to change your investments.
        </Wrapper.AdviceText>
        <Wrapper.BalanceText>
          Balance:
          <Wrapper.BalanceAmount>{numberToDollars(balance, false)}</Wrapper.BalanceAmount>
        </Wrapper.BalanceText>

        <div
          css={css`
            margin-left: -55px;
          `}>
          <SplitBarChartLegend />
        </div>
        {institutionalHoldingData.map((mix, inx) => renderRows(mix, IndexToSVGMap[inx]))}
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(InstitutionalInvestments))
