import { useRef } from 'react'
import { observer, inject } from 'mobx-react'

import { Wrapper } from './style'
import { Page, PageHeader } from '../elements'
import { Spacer } from '../../../components'

function AnnuityTutorialPage2(props) {
  const {
    guide: {
      guidePages: {
        pages: { findByName, addPage },
      },
    },
  } = props

  const willMount = useRef(true)
  if (willMount.current) {
    addPage({ name: 'annuityTutorialPage2' })
    willMount.current = false
  }

  const page = findByName('annuityTutorialPage2')
  return (
    <Page header={<PageHeader padding='20px 40pt 20pt 434pt' />} page={page}>
      <Wrapper>
        <Wrapper.Tutorial2Title>
          GuidedChoice selects the accounts to fund your annuity purchase
        </Wrapper.Tutorial2Title>
        <Spacer space='8pt' />

        <Wrapper.Tutorial2Paragraph>
          When modeling the purchase of an annuity, we will first look to use assets in employer
          plans. The reason for this is that typically plans offer the lowest cost options. If that
          is not an option or if there is not enough money in the plans to fund the desired monthly
          income amount, then we look to use assets from taxable accounts.
        </Wrapper.Tutorial2Paragraph>
        <Spacer space='8pt' />

        <ul>
          <Wrapper.Tutorial2ListItem>
            If your annuity is tax-advantaged, that means you can purchase it using pre-tax funds
            from your tax-deferred retirement account without having to pay taxes on the purchase
            transaction. The IRS treats this type of annuity purchase as a rollover to an IRA, which
            is not a taxable event if done properly. However, when you begin receiving annuity
            income you will have to pay taxes on those payments as ordinary income.
          </Wrapper.Tutorial2ListItem>
          <Spacer space='8pt' />
          <Wrapper.Tutorial2ListItem>
            If your annuity is taxable, that means you purchase it with after-tax dollars. After-tax
            dollars may come from a taxable savings account. If you choose to purchase a taxable
            annuity by withdrawing from a tax-deferred account (such as your retirement plan), you
            will have to pay taxes on the withdrawal, which can make this purchase expensive.
            However, the subsequent annuity payments may entail very little tax until you outlive
            your life expectancy.
          </Wrapper.Tutorial2ListItem>
        </ul>
        <Spacer space='18pt' />

        <Wrapper.TutorialStep>Choice 3: How long?</Wrapper.TutorialStep>
        <Spacer space='4pt' />
        <Wrapper.Tutorial2StepText>
          Finally, you'll need to choose how long the annuity will make payments—and to whom.
          Because the goal is to avoid running out of money, we'll skip those that only pay out for
          a given term and focus on options that last at least one lifetime.
        </Wrapper.Tutorial2StepText>
      </Wrapper>
    </Page>
  )
}

export default inject('store', 'guide')(observer(AnnuityTutorialPage2))
