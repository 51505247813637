/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'

import SSIChanges from './SSIChanges'

import { Page } from '../elements'
import {
  Button,
  HelpIcon,
  TooltipText,
  Spacer,
  Drawer,
  Loading,
  ErrorPage,
} from '../../../components'
import { numberToDollars } from '../../../utils/utils'

import { SSIPage } from './style'

function SecondSSIPage({
  history,
  store: { getSSBenefits, getWorkerBenefits, primarySSIBenefits, spouseSSIBenefits, config },
  onboarding: {
    spouse,
    person,
    person: { includeSpouse },
  },
}) {
  const [drawer, setDrawer] = useState('')
  const [status, setStatus] = useState('loading')

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (_.isEmpty(primarySSIBenefits)) {
          await getSSBenefits()
          await getWorkerBenefits()
        }
        setStatus('success')
      } catch (err) {
        setStatus('error')
        console.error(err)
      }
    }
    fetchData()
  }, []) // eslint-disable-line

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return <ErrorPage />
  }

  const onClick = () => {
    if (config.isRetail) {
      if (!config.isSpendown) {
        return history.push('/welcome/income-goal')
      }
      if (config.isSpendown) {
        return history.push('/welcome/greeting')
      }
    }
    if (!config.isSpendown) {
      return history.push('/welcome/contact-info')
    }
    return history.push('/welcome/pension-options')
  }
  return (
    <Page
      css={css`
        color: #7a8e96;
      `}>
      <Page.Heading title='Social Security income (SSI)' />
      <Page.Container>
        <Spacer space='8px' />

        <>
          <SSIPage.FieldWrapper>
            <SSIPage.Label />
            <SSIPage.Value>{person.displayName}</SSIPage.Value>
            {includeSpouse && <SSIPage.Value>{spouse.firstName}</SSIPage.Value>}
          </SSIPage.FieldWrapper>

          <SSIPage.FieldWrapper>
            <SSIPage.Label>Age SSI payments begin</SSIPage.Label>
            <SSIPage.Value active>
              {!primarySSIBenefits.includeSsi && 'N/A'}
              {primarySSIBenefits.includeSsi &&
                primarySSIBenefits.receivingSsi === 0 &&
                primarySSIBenefits.paymentBeginAge}
              {primarySSIBenefits.includeSsi &&
                primarySSIBenefits.receivingSsi === 1 &&
                'Already receiving'}
            </SSIPage.Value>
            {includeSpouse && (
              <SSIPage.Value active>
                {!spouseSSIBenefits.includeSsi && 'N/A'}
                {spouseSSIBenefits.includeSsi &&
                  spouseSSIBenefits.receivingSsi === 0 &&
                  spouseSSIBenefits.paymentBeginAge}
                {spouseSSIBenefits.includeSsi &&
                  spouseSSIBenefits.receivingSsi === 1 &&
                  'Already receiving'}
              </SSIPage.Value>
            )}
          </SSIPage.FieldWrapper>

          <SSIPage.FieldWrapper>
            <SSIPage.Label>Monthly amount</SSIPage.Label>
            <SSIPage.Value active>
              {primarySSIBenefits.includeSsi
                ? `$${numberToDollars(primarySSIBenefits.monthlyAmount, true)}`
                : 'N/A'}
            </SSIPage.Value>
            {includeSpouse && (
              <SSIPage.Value active>
                {spouseSSIBenefits.includeSsi
                  ? `$${numberToDollars(spouseSSIBenefits.monthlyAmount, true)}`
                  : 'N/A'}
              </SSIPage.Value>
            )}
          </SSIPage.FieldWrapper>
          <Spacer space='8px' />

          <SSIPage.FieldWrapper>
            <SSIPage.Label>Status</SSIPage.Label>
            <SSIPage.Value active>
              {primarySSIBenefits.includeSsi ? 'Include' : 'Exclude'}
            </SSIPage.Value>
            {includeSpouse && (
              <SSIPage.Value active>
                {spouseSSIBenefits.includeSsi ? 'Include' : 'Exclude'}
              </SSIPage.Value>
            )}
          </SSIPage.FieldWrapper>

          <SSIPage.FieldWrapper>
            <SSIPage.Label>Use GuidedChoice estimate?</SSIPage.Label>
            <SSIPage.Value active>
              {primarySSIBenefits.includeSsi
                ? primarySSIBenefits.useGcEstimate
                  ? 'Yes, estimate for me'
                  : "No, I'll enter my own"
                : 'N/A'}
            </SSIPage.Value>
            {includeSpouse && (
              <SSIPage.Value active>
                {spouseSSIBenefits.includeSsi
                  ? spouseSSIBenefits.useGcEstimate
                    ? 'Yes, estimate for me'
                    : "No, I'll enter my own"
                  : 'N/A'}
              </SSIPage.Value>
            )}
          </SSIPage.FieldWrapper>
          <Spacer space='12px' />

          <>
            <hr />
            <Spacer space='12px' />
            <SSIPage.FieldWrapper>
              <SSIPage.Label />
              <SSIPage.Value>{person.displayName}</SSIPage.Value>
              {includeSpouse && <SSIPage.Value>{spouse.firstName}</SSIPage.Value>}
            </SSIPage.FieldWrapper>

            <SSIPage.FieldWrapper>
              <SSIPage.Label>
                Full retirement age for SSI <HelpIcon tooltip={TooltipText.fullRetirementAge()} />
              </SSIPage.Label>
              <SSIPage.Value> {primarySSIBenefits.fullRetirementAgeYear} </SSIPage.Value>
              {includeSpouse && (
                <SSIPage.Value>{spouseSSIBenefits.fullRetirementAgeYear}</SSIPage.Value>
              )}
            </SSIPage.FieldWrapper>

            <SSIPage.FieldWrapper>
              <SSIPage.Label>Monthly amount</SSIPage.Label>
              <SSIPage.Value>
                ${numberToDollars(primarySSIBenefits.fullRetirementAgeMonthlyAmount, true)}
              </SSIPage.Value>
              {includeSpouse && (
                <SSIPage.Value>
                  ${numberToDollars(spouseSSIBenefits.fullRetirementAgeMonthlyAmount, true)}
                </SSIPage.Value>
              )}
            </SSIPage.FieldWrapper>
            <Spacer space='8px' />

            {primarySSIBenefits.paymentBeginAge < primarySSIBenefits.fullRetirementAgeYear ? (
              <p
                css={css`
                  color: #4a606a;
                  padding-left: 20px;
                `}>
                {person.displayName}, if you wait until age of{' '}
                {primarySSIBenefits.fullRetirementAgeYear} to start SSI, you will receive higher
                monthly income. As long as you live to at least age 70, you will receive greater
                overall benefits by starting later.
              </p>
            ) : null}
            {includeSpouse && (
              <>
                {spouseSSIBenefits.paymentBeginAge < spouseSSIBenefits.fullRetirementAgeYear ? (
                  <p
                    css={css`
                      color: #4a606a;
                      padding-left: 20px;
                    `}>
                    {spouse.firstName}, if you wait until age of{' '}
                    {spouseSSIBenefits.fullRetirementAgeYear} to start SSI, you will receive higher
                    monthly income. As long as you live to at least age 70, you will receive greater
                    overall benefits by starting later.
                  </p>
                ) : null}
              </>
            )}
          </>
          <Spacer space='4px' />

          <div
            css={css`
              display: flex;
              justify-content: space-around;
            `}>
            <Button
              type='button'
              secondary
              label='Make changes'
              width='150px'
              css={css`
                background-color: #4a606a;
                color: #fff;
              `}
              onClick={() => setDrawer('makeSSIChanges')}
            />
            <Button type='button' primary label='Keep as is' width='150px' onClick={onClick} />
          </div>

          <Drawer
            active={drawer === 'makeSSIChanges'}
            title='Social Security Income (SSI)'
            subtitle='Edit SSI'
            width='50%'>
            <SSIChanges history={history} handleCancel={() => setDrawer('')} />
          </Drawer>
        </>
      </Page.Container>
    </Page>
  )
}

export default inject('onboarding', 'store')(observer(SecondSSIPage))
