import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'

import { Page } from './style'
import { Spacer } from '../../../components'
import { BackButton, NextButton } from '../../../components/styled'
import { API_URL } from '../../../api'

function Congratulations(props) {
  const {
    history,
    spendDownOffboarding: { incrementStep },
    store: {
      config: { isRetail, isRetailFrs },
    },
  } = props

  const secretGuideLink = true

  const handleOnClick = e => {
    if (e.shiftKey) {
      window.open(
        `${window.location.origin}/implement/guide/${props.store.spendingAcceptedCaseId}`,
        '_blank'
      )
    }
  }

  const handleOpenPDFGuide = e => {
    const { spendingAcceptedCaseId } = props.store
    if (e.shiftKey) {
      window.open(`${API_URL}guide/generateGuide?caseId=${spendingAcceptedCaseId}`, '_blank')
    }
  }

  return (
    <Page.Container
      css={css`
        color: #4a606a;
        max-width: 1000px;
        margin: 0 auto;
        line-height: 1.5rem;
      `}>
      <Page.Heading
        css={css`
          color: #4a606a;
          font-weight: 400;
          text-align: start;
          line-height: 2.75rem;
        `}>
        Congratulations! You've got a solid <Spacer space='5px' />
        plan in the works
      </Page.Heading>
      <div>
        <Page.SubHeader>Strategic decisions considered</Page.SubHeader>
        <Page.CongratsUl>
          <li>When to begin Social Security payments</li>
          {(!isRetail || (isRetail && isRetailFrs)) && (
            <li>Options available with your pension plan</li>
          )}
          <li>Whether to add other lifetime income</li>
          <li>Whether to include an emergency fund in your planning</li>
          <li>What your budget might look like, including end-of-life expenses and bequests</li>
          <li>When to retire—whatever that may mean to you!</li>
          <li>The possibility of working part-time after retiring</li>
          <li>The impact of life expectancy</li>
        </Page.CongratsUl>
        <Spacer space='20px' />
        <Page.SubHeader>Additional options to think about</Page.SubHeader>
        <Page.CongratsUl>
          <li>
            Consider{' '}
            <Page.CongratsCustomLink
              to='insuring-final-expenses'
              target='_blank'
              rel='noreferrer noopener'>
              insuring final expenses
            </Page.CongratsCustomLink>
          </li>
          <li>
            Tap your home equity and opt for a{' '}
            <Page.CongratsCustomLink
              to='reverse-mortgage'
              target='_blank'
              rel='noreferrer noopener'>
              reverse mortgage{' '}
            </Page.CongratsCustomLink>{' '}
            if your home is paid off
          </li>
          <li>Consider a move to a less expensive area</li>
          <li>
            Make certain your finances are in order—
            <Page.CongratsCustomLink to='handy-checklist' target='_blank' rel='noreferrer noopener'>
              handy checklist
            </Page.CongratsCustomLink>
          </li>
        </Page.CongratsUl>
      </div>

      {secretGuideLink && (
        <div
          css={css`
            width: 30px;
            height: 30px;
            opacity: 0;
            position: fixed;
            top: 0;
            left: 0;
            cursor: default;
          `}
          onClick={handleOnClick}>
          Open Web Guide
        </div>
      )}

      {secretGuideLink && (
        <div
          css={css`
            width: 30px;
            height: 30px;
            opacity: 0;
            position: fixed;
            top: 0;
            right: 0;
            cursor: default;
          `}
          onClick={handleOpenPDFGuide}>
          Open PDF Guide
        </div>
      )}

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin: 30px 0;
        `}>
        <BackButton backgroundColor='#FFFFFF' onClick={() => history.goBack()}>
          Back
        </BackButton>
        <NextButton onClick={() => incrementStep(history, 'congratulations')}>Next</NextButton>
      </div>
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(Congratulations))
