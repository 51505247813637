function PreserverDonut() {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 99 98'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Group Copy 4</title>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Artboard' transform='translate(-401.000000, -1433.000000)' strokeWidth='2.390625'>
          <g id='Group-Copy-2' transform='translate(402.000000, 1434.000000)'>
            <g id='Group' transform='translate(41.737772, 28.687500)' stroke='#90BE3F'>
              <path
                d='M0,25.3447826 C0,29.5356522 3.38971539,32.9282609 7.57701087,32.9282609 C11.7643064,32.9282609 15.1540217,29.5356522 15.1540217,25.3447826 C15.1540217,17.7613043 1.29606765,17.7613043 1.29606765,10.2776087 C1.29606765,6.885 3.4894129,3.99130435 7.57701087,3.99130435 C11.7643064,3.99130435 13.8579541,7.18434783 13.8579541,10.2776087'
                id='Shape'></path>
              <line
                x1='7.47731336'
                y1='36.6202174'
                x2='7.47731336'
                y2='32.8284783'
                id='Shape'></line>
              <line
                x1='7.47731336'
                y1='3.89152174'
                x2='7.47731336'
                y2='0.0997826087'
                id='Shape'></line>
            </g>
            <g id='Group'>
              <path
                d='M43.0896024,79.0164474 C27.8815074,76.6006579 16.3233553,63.5151316 16.3233553,47.8125 L0.304161899,47.8125 C0.304161899,72.3730263 19.0608124,92.5046053 43.0896024,95.1217105'
                id='Shape'
                stroke='#0D8BB8'></path>
              <path
                d='M53.3297197,16.5078947 C68.5378146,18.9236842 80.0959668,32.0092105 80.0959668,47.7118421 L96.0137729,47.7118421 C96.0137729,23.1513158 77.2571224,3.01973684 53.2283324,0.402631579'
                id='Shape'
                stroke='#90BE3F'></path>
              <path
                d='M16.7289045,42.6789474 C19.1621997,27.5802632 32.3425486,16.1052632 48.1589674,16.1052632 L48.1589674,0.301973684 C23.4204662,0.301973684 3.14300629,18.9236842 0.506936499,42.7796053'
                id='Shape'
                stroke='#0D8BB8'></path>
              <path
                d='M79.6904176,52.8453947 C77.2571224,67.9440789 64.0767735,79.4190789 48.2603547,79.4190789 L48.2603547,95.2223684 C72.9988558,95.2223684 93.2763158,76.6006579 95.9123856,52.7447368'
                id='Shape'
                stroke='#0D8BB8'></path>
              <g transform='translate(52.721396, 2.013158)' id='Shape' stroke='#90BE3F'>
                <line x1='9.02346968' y1='0.100657895' x2='0.608323799' y2='8.45526316'></line>
                <line x1='41.1632437' y1='31.3046053' x2='27.3745709' y2='44.8934211'></line>
                <line x1='8.11098398' y1='16.6085526' x2='19.9732981' y2='4.83157895'></line>
                <line x1='28.9967677' y1='11.7769737' x2='17.7427775' y2='23.0506579'></line>
                <line x1='36.0938787' y1='20.4335526' x2='24.3329519' y2='32.1098684'></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PreserverDonut
