import { css, useTheme } from '@emotion/react'
import { ButtonBox, ButtonTopText, ButtonBottomText } from './ConfirmationStyle'

const CancelButton = ({ onClick, primaryText, secondaryText }) => (
  <ButtonBox
    onClick={onClick}
    css={css`
      border: 1px solid #b6c0c4;
      background-color: #ffffff;
    `}>
    <ButtonTopText
      css={css`
        color: #4a606a;
      `}>
      {primaryText}
    </ButtonTopText>
    <ButtonBottomText
      css={css`
        color: #4a606a;
      `}>
      {secondaryText}
    </ButtonBottomText>
  </ButtonBox>
)

const SecondaryButton = ({ onClick, primaryText, secondaryText }) => (
  <ButtonBox
    onClick={onClick}
    css={css`
      background-color: #4a606a;
    `}>
    <ButtonTopText>{primaryText}</ButtonTopText>
    <ButtonBottomText>{secondaryText}</ButtonBottomText>
  </ButtonBox>
)

const PrimaryButton = ({ onClick, primaryText, secondaryText }) => {
  const theme = useTheme()
  return (
    <ButtonBox
      onClick={onClick}
      css={css`
        background: ${theme.buttonColor};
      `}>
      <ButtonTopText>{primaryText}</ButtonTopText>
      <ButtonBottomText>{secondaryText}</ButtonBottomText>
    </ButtonBox>
  )
}

export { CancelButton, SecondaryButton, PrimaryButton }
