import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import events, { round, range } from '../utils'
import InjectProgressBar from './ProgressBar'

// const SLIDER_MAIN_COLOR = '#1291BB'

// SLIDER_MAIN_COLOR_CONTRAST = "rgb(255, 255, 255)",

const SLIDER_SNAP_COLOR = 'rgb(0, 0, 0)'

const SLIDER_DISABLED_COLOR = 'rgba(177, 177, 177, 1)'

const SLIDER_KNOB_SIZE = '32'

// SLIDER_INNER_KNOB_SIZE = "12",

const SLIDER_SNAP_SIZE = '2'

// SLIDER_INPUT_WIDTH = "50",

const SLIDER_BAR_HEIGHT = '4'

const SLIDER_PIN_SIZE = '26'

const SLIDER_PIN_ELEVATION = '17'

const SLIDER_SIDE_SEPARATION = '10'

const SLIDER_EMPTY_KNOB_BORDER = '2'

const animationCurveDefault = 'cubic-bezier(0.4, 0, 0.2, 1)'

const SnapsTheme = styled.div`
  display: flex;
  flex-direction: row;
  height: ${SLIDER_SNAP_SIZE}px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: ${SLIDER_KNOB_SIZE / 2 - SLIDER_SNAP_SIZE / 2}px;
  width: calc(100% + ${SLIDER_SNAP_SIZE}px);
  &::after {
    background-color: ${SLIDER_SNAP_COLOR};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${SLIDER_SNAP_SIZE}px;
    width: ${SLIDER_SNAP_SIZE}px;
  }
`

const SnapTheme = styled.div`
  flex: 1;
  &::after {
    background-color: ${SLIDER_SNAP_COLOR};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${SLIDER_SNAP_SIZE}px;
    width: ${SLIDER_SNAP_SIZE}px;
  }
`

const ProgressTheme = styled.div`
  height: 100%;
  left: ${SLIDER_KNOB_SIZE / 2}px;
  position: absolute;
  top: 0;
  width: 100%;
  & > div:first-of-type {
    height: ${SLIDER_BAR_HEIGHT}px;
    position: absolute;
    top: ${SLIDER_KNOB_SIZE / 2 - SLIDER_BAR_HEIGHT / 2}px;
    & [data-ref='value'] {
      transition-duration: 0s;
    }
  }
`

const RiskBar = styled.div`
  background-color: ${p => p.theme.sliderRiskBar};
  top: 4px;
  left: ${p => (p.minRisk - 101) / 5}%;
  height: 22px;
  width: ${p => (p.maxRisk - p.minRisk) / 5}%;
  position: absolute;
  opacity: 0.8;
`

const InnerKnobTheme = styled.div`
  background-color: ${p => p.theme.primaryColor};
  border-radius: 4px;
  height: 38px;
  transition-duration: 0.1s;
  transition-property: height, width, background-color, border;
  transition-timing-function: ${animationCurveDefault};
  width: 17px;
  z-index: 100;
`

const leftKnobTheme = p =>
  p.left &&
  css`
    left: ${p.left};
  `

const KnobTheme = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: ${SLIDER_KNOB_SIZE}px;
  justify-content: center;
  left: 0;
  position: relative;
  top: 0;
  width: ${SLIDER_KNOB_SIZE}px;
  z-index: 200;
  ${leftKnobTheme};
`

const ContainerTheme = styled.div`
  height: ${SLIDER_KNOB_SIZE}px;
  margin-right: ${SLIDER_KNOB_SIZE}px;
  position: relative;
  user-select: none;
  width: calc(100% - ${SLIDER_KNOB_SIZE}px);
  &:not(:last-child) {
    margin-right: ${SLIDER_SIDE_SEPARATION + SLIDER_KNOB_SIZE}px;
  }
  &:not(:first-child) {
    margin-left: ${SLIDER_SIDE_SEPARATION}px;
  }
`

const editableSliderTheme = p =>
  p.editable &&
  css`
    align-items: center;
    display: flex;
    flex-direction: row;
  `

const disabledSliderTheme = p =>
  p.disabled &&
  css`
    cursor: auto;
    pointer-events: none;
    & .${InnerKnobTheme} {
      background-color: ${SLIDER_DISABLED_COLOR};
    }
  `

const pinnedSliderTheme = p =>
  p.pinned &&
  css`
    & .${InnerKnobTheme} {
      &::before {
        background-color: ${p => p.theme.sliderColor};
        border-radius: 50% 50% 50% 0;
        content: '';
        height: var(--slider-pin-size);
        left: 0;
        margin-left: ${(SLIDER_KNOB_SIZE - SLIDER_PIN_SIZE) / 2}px;
        position: absolute;
        top: 0;
        transform: rotate(-45deg) scale(0) translate(0);
        transition: transform 0.2s ease, background-color 0.18s ease;
        width: ${SLIDER_PIN_SIZE}px;
      }
      &::after {
        color: rgb(255, 255, 255);
        content: attr(data-value);
        font-size: 10px;
        height: ${SLIDER_PIN_SIZE}px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        transform: scale(0) translate(0);
        transition: transform 0.2s ease, background-color 0.18s ease;
        width: ${SLIDER_KNOB_SIZE}px;
      }
    }
  `

const pressedSliderTheme = p =>
  (p.pressed &&
    p.pinned &&
    css`
      & .${InnerKnobTheme} {
        &::before {
          transform: rotate(-45deg) scale(1)
            translate(${SLIDER_PIN_ELEVATION}px, ${-1 * SLIDER_PIN_ELEVATION}px);
          transition-delay: 100ms;
        }
        &::after {
          transform: scale(1) translate(0, ${-1 * SLIDER_PIN_ELEVATION}px);
          transition-delay: 100ms;
        }
      }
    `) ||
  (p.pressed &&
    !p.pinned &&
    p.ring &&
    css`
      & .${ProgressTheme} {
        left: ${SLIDER_KNOB_SIZE / 2 + (SLIDER_KNOB_SIZE - SLIDER_EMPTY_KNOB_BORDER * 2) / 2}px;
        width: calc(100% - (${(SLIDER_KNOB_SIZE - SLIDER_EMPTY_KNOB_BORDER * 2) / 2}px));
      }
      & .${InnerKnobTheme} {
        height: 100%;
        transform: translateZ(0);
        width: 100%;
      }
    `) ||
  (p.pressed &&
    !p.pinned &&
    css`
      & .${InnerKnobTheme} {
        height: 100%;
        transform: translateZ(0);
        width: 100%;
      }
    `)

const ringSliderTheme = p =>
  (p.ring &&
    p.pinned &&
    css`
      & .${InnerKnobTheme} {
        background-color: rgb(255, 255, 255);
      }
      & .${ProgressTheme} {
        left: ${SLIDER_KNOB_SIZE / 2}px;
        width: 100%;
      }
    `) ||
  (p.ring &&
    css`
      & .${InnerKnobTheme} {
        background-color: transparent;
        border: ${SLIDER_EMPTY_KNOB_BORDER}px solid rgb(238, 238, 238);
        &::before {
          background-color: ${p => p.theme.primaryColor};
        }
      }
      & .${ProgressTheme} {
        left: ${SLIDER_KNOB_SIZE / 2 + SLIDER_EMPTY_KNOB_BORDER * 2}px;
        transition: left 0.18s ease, width 0.18s ease;
        width: calc(100% - ${SLIDER_EMPTY_KNOB_BORDER * 2}px);
      }
    `)

const SliderTheme = styled.div`
  &:focus .${KnobTheme}::before {
    background-color: ${p => p.theme.primaryColor};
    border-radius: 50%;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.26;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  ${editableSliderTheme};
  ${disabledSliderTheme};
  ${pinnedSliderTheme};
  ${pressedSliderTheme};
  ${ringSliderTheme};
`

const factory = ProgressBar => {
  class Slider extends Component {
    static propTypes = {
      buffer: PropTypes.number,
      className: PropTypes.string,
      disabled: PropTypes.bool,
      editable: PropTypes.bool,
      max: PropTypes.number,
      min: PropTypes.number,
      onChange: PropTypes.func,
      onDragStart: PropTypes.func,
      onDragStop: PropTypes.func,
      pinned: PropTypes.bool,
      snaps: PropTypes.bool,
      step: PropTypes.number,
      theme: PropTypes.shape({
        container: PropTypes.string,
        editable: PropTypes.string,
        innerknob: PropTypes.string,
        innerprogress: PropTypes.string,
        input: PropTypes.string,
        knob: PropTypes.string,
        pinned: PropTypes.string,
        pressed: PropTypes.string,
        progress: PropTypes.string,
        ring: PropTypes.string,
        slider: PropTypes.string,
        snap: PropTypes.string,
        snaps: PropTypes.string,
      }),
      value: PropTypes.number,
    }

    static defaultProps = {
      buffer: 0,
      className: '',
      editable: false,
      max: 100,
      min: 0,
      onDragStart: () => {},
      onDragStop: () => {},
      pinned: false,
      snaps: false,
      step: 0.01,
      value: 0,
    }

    state = {
      inputFocused: false,
      inputValue: null,
      sliderLength: 0,
      sliderStart: 0,
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.inputFocused && this.props.value !== nextProps.value) {
        this.setState({ inputValue: this.valueForInput(nextProps.value) })
      }
    }

    shouldComponentUpdate(nextProps, nextState) {
      return this.state.inputFocused || !nextState.inputFocused
    }

    // eslint-disable-next-line
    UNSAFE_componentWillUpdate(nextProps, nextState) {
      if (nextState.pressed !== this.state.pressed) {
        if (nextState.pressed) {
          this.props.onDragStart()
        } else {
          this.props.onDragStop()
        }
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize)
      events.removeEventsFromDocument(this.getMouseEventMap())
      events.removeEventsFromDocument(this.getTouchEventMap())
      events.removeEventsFromDocument(this.getKeyboardEvents())
    }

    getInput() {
      return this.inputNode && this.inputNode.getWrappedInstance
        ? this.inputNode.getWrappedInstance()
        : this.inputNode
    }

    getKeyboardEvents() {
      return {
        keydown: this.handleKeyDown,
      }
    }

    getMouseEventMap() {
      return {
        mousemove: this.handleMouseMove,
        mouseup: this.handleMouseUp,
      }
    }

    getTouchEventMap() {
      return {
        touchmove: this.handleTouchMove,
        touchend: this.handleTouchEnd,
      }
    }

    addToValue(increment) {
      let value = this.state.inputFocused ? parseFloat(this.state.inputValue) : this.props.value
      value = this.trimValue(value + increment)
      if (value !== this.props.value) this.props.onChange(value)
    }

    handleInputFocus = () => {
      this.setState({
        inputFocused: true,
        inputValue: this.valueForInput(this.props.value),
      })
    }

    handleInputChange = value => {
      this.setState({ inputValue: value })
    }

    handleInputBlur = event => {
      const value = this.state.inputValue || 0
      this.setState({ inputFocused: false, inputValue: null }, () => {
        this.props.onChange(this.trimValue(value), event)
      })
    }

    handleKeyDown = event => {
      if ([13, 27].indexOf(event.keyCode) !== -1) this.getInput().blur()
      if (event.keyCode === 38) this.addToValue(this.props.step)
      if (event.keyCode === 40) this.addToValue(-this.props.step)
    }

    handleMouseDown = event => {
      if (this.state.inputFocused) this.getInput().blur()
      events.addEventsToDocument(this.getMouseEventMap())
      this.start(events.getMousePosition(event))
      events.pauseEvent(event)
    }

    handleMouseMove = event => {
      events.pauseEvent(event)
      this.move(events.getMousePosition(event))
    }

    handleMouseUp = () => {
      this.end(this.getMouseEventMap())
    }

    handleResize = (event, callback) => {
      const { left, right } = ReactDOM.findDOMNode(this.progressbarNode).getBoundingClientRect()
      const cb = callback || (() => {})
      this.setState({ sliderStart: left, sliderLength: right - left }, cb)
    }

    handleSliderBlur = () => {
      events.removeEventsFromDocument(this.getKeyboardEvents())
    }

    handleSliderFocus = () => {
      events.addEventsToDocument(this.getKeyboardEvents())
    }

    handleTouchEnd = () => {
      this.end(this.getTouchEventMap())
    }

    handleTouchMove = event => {
      this.move(events.getTouchPosition(event))
    }

    handleTouchStart = event => {
      if (this.state.inputFocused) this.getInput().blur()
      this.start(events.getTouchPosition(event))
      events.addEventsToDocument(this.getTouchEventMap())
      events.pauseEvent(event)
    }

    end(revents) {
      events.removeEventsFromDocument(revents)
      this.setState({ pressed: false })
    }

    knobOffset() {
      const { max, min, value } = this.props
      if (value > max) {
        return 100
      } else if (value < min) {
        return 0
      }
      return 100 * ((value - min) / (max - min))
    }

    ghostOffset() {
      const { max, min, ghostValue } = this.props
      if (ghostValue > max) {
        return 100
      } else if (ghostValue < min) {
        return 0
      }
      return 100 * ((ghostValue - min) / (max - min))
    }

    move(position) {
      const newValue = this.positionToValue(position)
      if (newValue !== this.props.value) this.props.onChange(newValue)
    }

    positionToValue(position) {
      const { sliderStart: start, sliderLength: length } = this.state
      const { max, min, step } = this.props
      const pos = ((position.x - start) / length) * (max - min)
      return this.trimValue(Math.round(pos / step) * step + min)
    }

    start(position) {
      this.handleResize(null, () => {
        this.setState({ pressed: true })
        this.props.onChange(this.positionToValue(position))
      })
    }

    stepDecimals() {
      return (this.props.step.toString().split('.')[1] || []).length
    }

    trimValue(value) {
      if (value < this.props.min) return this.props.min
      if (value > this.props.max) return this.props.max
      return round(value, this.stepDecimals())
    }

    valueForInput(value) {
      const decimals = this.stepDecimals()
      return decimals > 0 ? value.toFixed(decimals) : value.toString()
    }

    renderSnaps() {
      if (!this.props.snaps) return undefined
      return (
        <SnapsTheme>
          {range(0, (this.props.max - this.props.min) / this.props.step).map(i => (
            <SnapTheme key={`span-${i}`} />
          ))}
        </SnapsTheme>
      )
    }

    renderInput() {
      if (!this.props.editable) return undefined
      return <input />
    }

    render() {
      return (
        <SliderTheme
          data-react-toolbox='slider'
          onBlur={this.handleSliderBlur}
          onFocus={this.handleSliderFocus}
          disabled={this.props.disabled}
          editable={this.props.editable}
          pinned={this.props.pinned}
          pressed={this.props.pressed}
          ring={this.props.value === this.props.min}
          tabIndex='0'>
          <ContainerTheme
            ref={node => {
              this.sliderNode = node
            }}
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleTouchStart}>
            <KnobTheme
              ref={node => {
                this.knobNode = node
              }}
              onMouseDown={this.handleMouseDown}
              onTouchStart={this.handleTouchStart}
              left={`${this.knobOffset()}%`}>
              <InnerKnobTheme data-value={parseInt(this.props.value, 10)} />
            </KnobTheme>

            {this.props.ghostValue && (
              <KnobTheme
                css={css`
                  position: absolute;
                  opacity: 0.2;
                `}
                left={`${this.ghostOffset()}%`}>
                <InnerKnobTheme data-value={parseInt(this.props.ghostValue, 10)} />
              </KnobTheme>
            )}

            <ProgressTheme>
              <ProgressBar
                disabled={this.props.disabled}
                ref={node => {
                  this.progressbarNode = node
                }}
                max={this.props.max}
                min={this.props.min}
                mode='determinate'
                value={this.props.value}
                buffer={this.props.buffer}
              />

              {this.props.minRisk && this.props.maxRisk && (
                <RiskBar minRisk={this.props.minRisk} maxRisk={this.props.maxRisk} />
              )}

              {this.renderSnaps()}
            </ProgressTheme>
          </ContainerTheme>
          {this.renderInput()}
        </SliderTheme>
      )
    }
  }
  return Slider
}

const Slider = factory(InjectProgressBar)
export default Slider
export { factory as sliderFactory }
