import { Line } from 'react-chartjs-2'
import { numberToDollars } from '../../../utils/utils'
import _ from 'lodash'

const Chart = ({ scenario }) => {
  if (_.get(scenario, 'averageMarketSpendingAdvice.spendingByAges', null) === null) {
    return null
  }

  const sum = scenario.averageMarketSpendingAdvice.spendingByAges.map(item => {
    const arr = []
    arr.push(item.lifetimeIncome)
    return arr
  })

  const max = Math.round(Math.max(...sum) / 1000) * 1000

  const ages = () => {
    const arr = []
    const startingAge = scenario.averageMarketSpendingAdvice.primary.retAge
    for (
      let i = startingAge;
      i < startingAge + scenario.averageMarketSpendingAdvice.spendingByAges.length;
      i++
    ) {
      arr.push(i + '')
    }
    return arr
  }

  const incomeAmounts = () => {
    const arr = []
    for (const i of scenario.averageMarketSpendingAdvice.spendingByAges) {
      const amount = i.lifetimeIncome < 0 ? 0 : i.lifetimeIncome
      arr.push(Math.floor(amount))
    }
    return arr
  }

  return (
    <Line
      data={{
        labels: ages(),
        datasets: [
          {
            data: incomeAmounts(),
            borderColor: 'rgba(68, 114, 196,1)',
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
          },
        ],
      }}
      options={{
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            min: 0,
            suggestedMax: max,
            ticks: {
              stepSize: 10000,
              callback: function (value) {
                return '$' + numberToDollars(value, true)
              },
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        elements: {
          line: { tension: 0.25 },
        },
      }}
    />
  )
}

export default Chart
