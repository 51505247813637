/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const container = 'rt-slider-container'

const editable = 'rt-slider-editable'

const innerknob = 'rt-slider-innerknob'

const innerprogress = 'rt-slider-innerprogress'

const input = 'rt-slider-input'

const knob = 'rt-slider-knob'

const pinned = 'rt-slider-pinned'

const pressed = 'rt-slider-pressed'

const progress = 'rt-slider-progress'

const ring = 'rt-slider-ring'

const slider = 'rt-slider-slider'

const snap = 'rt-slider-snap'

const snaps = 'rt-slider-snaps'

const disabled = 'rt-slider-disabled'

const _container = css`
  height: ${ds.get('slider.knobSize')};
  margin-right: ${ds.get('slider.knobSize')};
  position: relative;
  user-select: none;
  width: ${reduceCSSCalc(`calc(100% - ${ds.get('slider.knobSize')})`)};

  ${ds.get('reset')};

  &:not(:last-child) {
    margin-right: ${reduceCSSCalc(`calc(
        ${ds.get('slider.sideSeparation')} + ${ds.get('slider.knobSize')}
      )`)};
  }

  &:not(:first-child) {
    margin-left: ${ds.get('slider.sideSeparation')};
  }
`

const _knob = css`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: ${ds.get('slider.knobSize')};
  justify-content: center;
  left: 0;
  position: relative;
  top: 0;
  width: ${ds.get('slider.knobSize')};
  z-index: ${ds.z('higher')};
`

const _innerknob = css`
  background-color: ${ds.get('slider.mainColor')};
  border-radius: 50%;
  height: ${ds.get('slider.innerKnobSize')};
  transition-duration: 0.1s;
  transition-property: height, width, background-color, border;
  transition-timing-function: ${ds.get('animation.curveDefault')};
  width: ${ds.get('slider.innerKnobSize')};
  z-index: ${ds.z('high')};
`

const _snaps = css`
  display: flex;
  flex-direction: row;
  height: ${ds.get('slider.snapSize')};
  left: 0;
  pointer-events: none;
  position: absolute;
  top: ${reduceCSSCalc(`calc(
      ${ds.get('slider.knobSize')} / 2 - ${ds.get('slider.snapSize')} / 2
    )`)};
  width: ${reduceCSSCalc(`calc(100% + ${ds.get('slider.snapSize')})`)};

  &::after {
    background-color: ${ds.get('slider.snapColor')};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${ds.get('slider.snapSize')};
    width: ${ds.get('slider.snapSize')};
  }
`

const _snap = css`
  flex: 1;

  &::after {
    background-color: ${ds.get('slider.snapColor')};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${ds.get('slider.snapSize')};
    width: ${ds.get('slider.snapSize')};
  }
`

const _input = css`
  margin-bottom: 0;
  padding: 0;
  width: ${ds.get('slider.inputWidth')};

  & > input {
    text-align: center;
  }
`

const _progress = css`
  height: 100%;
  left: ${reduceCSSCalc(`calc(${ds.get('slider.knobSize')} / 2)`)};
  position: absolute;
  top: 0;
  width: 100%;

  & .${innerprogress} {
    height: ${ds.get('slider.barHeight')};
    position: absolute;
    top: ${reduceCSSCalc(`calc(
        ${ds.get('slider.knobSize')} / 2 - ${ds.get('slider.barHeight')} / 2
      )`)};

    & [data-ref='value'] {
      transition-duration: 0s;
    }
  }
`

const _slider = css`
  &:focus .${knob}::before {
    background-color: ${ds.get('slider.mainColor')};
    border-radius: 50%;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.26;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${ds.z('normal')};
  }

  &[disabled] {
    cursor: auto;
    pointer-events: none;

    & .${innerknob} {
      background-color: ${ds.get('slider.disabledColor')};
    }
  }

  &.${editable} {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &.${pinned} .${innerknob} {
    &::before {
      background-color: ${ds.get('slider.mainColor')};
      border-radius: 50% 50% 50% 0;
      content: '';
      height: ${ds.get('slider.pinSize')};
      left: 0;
      margin-left: ${reduceCSSCalc(`calc(
          (${ds.get('slider.knobSize')} - ${ds.get('slider.pinSize')}) / 2
        )`)};
      position: absolute;
      top: 0;
      transform: rotate(-45deg) scale(0) translate(0);
      transition: transform 0.2s ease, background-color 0.18s ease;
      width: ${ds.get('slider.pinSize')};
    }

    &::after {
      color: ${ds.get('colors.rt.background')};
      content: attr(data-value);
      font-size: 10px;
      height: ${ds.get('slider.pinSize')};
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      transform: scale(0) translate(0);
      transition: transform 0.2s ease, background-color 0.18s ease;
      width: ${ds.get('slider.knobSize')};
    }
  }

  &.${pressed} {
    &.${pinned} .${innerknob} {
      &::before {
        transform: rotate(-45deg) scale(1)
          translate(
            ${ds.get('slider.pinElevation')},
            ${reduceCSSCalc(`calc(-1 * ${ds.get('slider.pinElevation')})`)}
          );
        transition-delay: 100ms;
      }

      &::after {
        transform: scale(1)
          translate(0, ${reduceCSSCalc(`calc(-1 * ${ds.get('slider.pinElevation')})`)});
        transition-delay: 100ms;
      }
    }

    &:not(.${pinned}) {
      &.${ring} .${progress} {
        left: ${reduceCSSCalc(
          `calc(${ds.get('slider.knobSize')} / 2 + (${ds.get('slider.knobSize')} - ${ds.get(
            'slider.emptyKnobBorder'
          )} * 2) / 2)`
        )};
        width: ${reduceCSSCalc(
          `calc(100% - ((${ds.get('slider.knobSize')} - ${ds.get(
            'slider.emptyKnobBorder'
          )} * 2) / 2))`
        )};
      }

      & .${innerknob} {
        height: 100%;
        transform: translateZ(0);
        width: 100%;
      }
    }
  }

  &.${ring} {
    & .${innerknob} {
      background-color: transparent;
      border: ${ds.get('slider.emptyKnobBorder')} solid ${ds.get('colors.rt.divider')};

      &::before {
        background-color: ${ds.get('slider.mainColor')};
      }
    }

    & .${progress} {
      left: ${reduceCSSCalc(
        `calc( ${ds.get('slider.knobSize')} / 2 + ${ds.get('slider.emptyKnobBorder')} * 2)`
      )};
      transition: left 0.18s ease, width 0.18s ease;
      width: ${reduceCSSCalc(`calc(100% - ${ds.get('slider.emptyKnobBorder')} * 2)`)};
    }

    &.${pinned} {
      & .${innerknob} {
        background-color: ${ds.get('colors.rt.background')};
      }

      & .${progress} {
        left: ${reduceCSSCalc(`calc(${ds.get('slider.knobSize')} / 2)`)};
        width: 100%;
      }
    }
  }
`

export default {
  container: cx(container, _container),
  editable,
  innerknob: cx(innerknob, _innerknob),
  innerprogress,
  input: cx(input, _input),
  knob: cx(knob, _knob),
  pinned,
  pressed,
  progress: cx(progress, _progress),
  ring,
  slider: cx(slider, _slider),
  snap: cx(snap, _snap),
  snaps: cx(snaps, _snaps),
  disabled,
}
