import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { applySnapshot } from 'mobx-state-tree'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'

import { API } from '../../../api'
import {
  WizardStep,
  Loading,
  CurrencyInput,
  InputField,
  PercentInput,
  PercentOrDollarInput,
  MultiButtonInput,
  TooltipText,
} from '../../../components'
import { reduceValidationError } from '../../../utils'
import { accountTypeToAPI, typeToAPI } from '../utils'
import {
  schema,
  accountTypeNextStep,
  createEditable,
  initializeValues,
  cleanEmployerData,
} from './EmployerContributionsUtils'
import Tiers from './Tiers'

const AddEmployerContributions = props => {
  const [status, setStatus] = useState('loading')
  const {
    institutionalAccountUnderReview,
    institutionalAccount,
    features: { editInstitutionalAccount },
  } = props.store
  const isInstitutionalAccount = props.account?.account?.id === institutionalAccount?.id

  const fetchInitialState = useCallback(async () => {
    try {
      const {
        person: { id: primaryOwnerId },
      } = props.store
      const { account, control } = props.account
      const { id: accountId, planType: accountType, participantId: personId } = account
      const [accountControl] = await Promise.all([
        API.post('account-control/edit', null, {
          params: { accountId, personId },
        }),
        API.get('accounts/institutionalAccount'),
      ])

      applySnapshot(control, accountControl.data)

      // if accountControl.data.employerContribution === false, contributionEligibility === false, or (isPrimary && is403b)
      // submit { employerContrib: false, profitSharing: false, employerMatch: false }
      // along with cleanEmployerData: { companyMatchRate: companyMatchRate === null ? null : 0 , companyMatchRateMax: companyMatchRateMax === null ? null : 0 , companyMatchDlrMax: companyMatchDlrMax === null ? null : 0, profitSharingRate: profitSharingRate === null ? null : 0 }
      const { contributionTiers } = account
      if (
        accountControl.data.employerContribution === false ||
        account.contributionEligibility === false ||
        (personId === primaryOwnerId && accountType === 2) ||
        (isInstitutionalAccount &&
          institutionalAccountUnderReview &&
          _.isEmpty(contributionTiers) &&
          !account.profitSharing)
      ) {
        const {
          store,
          match: {
            params: { type, accountType },
          },
          history,
        } = props
        const {
          planType,
          id,
          participantId,
          companyMatchRate,
          companyMatchRateMax,
          companyMatchDlrMax,
          profitSharingRate,
        } = account

        let response

        if (isInstitutionalAccount) {
          response = await API.patch(typeToAPI[type], {
            planType,
            id,
            participantId,
            employerContrib: false,
            profitSharing: false,
            employerMatch: false,
            companyMatchRate: companyMatchRate === null ? null : 0,
            companyMatchRateMax: companyMatchRateMax === null ? null : 0,
            companyMatchDlrMax: companyMatchDlrMax === null ? null : 0,
            profitSharingRate: profitSharingRate === null ? null : 0,
          })
        } else {
          response = await API.patch(accountTypeToAPI[accountType], {
            planType,
            id,
            participantId,
            employerContrib: false,
            profitSharing: false,
            employerMatch: false,
            companyMatchRate: companyMatchRate === null ? null : 0,
            companyMatchRateMax: companyMatchRateMax === null ? null : 0,
            companyMatchDlrMax: companyMatchDlrMax === null ? null : 0,
            profitSharingRate: profitSharingRate === null ? null : 0,
          })
        }

        // update account store with response.data
        applySnapshot(account, response.data)
        store.getAccounts()
        return history.push(
          `/accounts/add/${type}/${accountType}/${id}/${accountTypeNextStep[accountType]}`
        )
      }

      setStatus('done')
    } catch (err) {
      console.error(err)
      setStatus('error')
    }
  }, [institutionalAccountUnderReview, isInstitutionalAccount, props])

  useEffect(() => {
    const {
      match: {
        params: { accountType },
      },
      history,
      account,
    } = props

    // If there's not a next step available for the account type, then they shouldn't be here
    const allowedAccountTypes = _.keys(accountTypeNextStep)
    if (!_.includes(allowedAccountTypes, accountType) || !_.get(account, 'account.id')) {
      return history.replace('/accounts/add')
    }

    fetchInitialState()
  }, [fetchInitialState, props])

  const onSubmit = async values => {
    const {
      store,
      account: { account },
      match: {
        params: { type, accountType },
      },
      history,
    } = props

    const { planType, id, participantId } = account

    const requestValues = cleanEmployerData(values, account)

    try {
      if (isInstitutionalAccount && institutionalAccountUnderReview) {
        return history.push(
          `/accounts/add/${type}/${accountType}/${id}/${accountTypeNextStep[accountType]}`
        )
      }

      const response = await API.patch(accountTypeToAPI[accountType], {
        planType,
        id,
        participantId,
        ...requestValues,
      })

      // update account store with response.data
      applySnapshot(props.account, { account: response.data })
      store.getAccounts()
      history.push(`/accounts/add/${type}/${accountType}/${id}/${accountTypeNextStep[accountType]}`)
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const validate = values => {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  const editable = () => {
    const {
      match: {
        params: { accountType },
      },
      account: {
        account: { planType, isPrimaryOwned },
        control,
      },
    } = props
    return createEditable({ accountType, planType, isPrimaryOwned, control })
  }

  const initialValues = editable => {
    const {
      account,
      account: { isSpouseOwned },
      control,
    } = props.account
    return initializeValues({
      editable,
      account,
      control,
      editInstitutionalAccount: editInstitutionalAccount && isInstitutionalAccount,
      isSpouseOwned,
    })
  }

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return (
      <div
        css={css`
          color: crimson;
        `}>
        Something went wrong, please try again later.
      </div>
    )
  }

  const { employerContribIsEditable, profitSharingIsEditable } = editable()

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues(editable())}
      subscription={{ submitting: true, submitError: true, values: true }}
      render={({ handleSubmit, submitting, submitError, values }) => (
        <WizardStep
          onNextClick={handleSubmit}
          title="Let's look at employer contributions"
          disableNextButton={submitting}
          disableBackButton={submitting}
          serverError={submitError}>
          <div>
            {isInstitutionalAccount && institutionalAccountUnderReview && (
              <OrgMessage>
                Your organization already filled out this information for you.
              </OrgMessage>
            )}
            {employerContribIsEditable &&
              !(isInstitutionalAccount && institutionalAccountUnderReview) && (
                <Field
                  name='employerContrib'
                  render={({ input, meta }) => (
                    <MultiButtonInput
                      name={input.name}
                      value={input.value}
                      label='Does the employer make matching contributions?'
                      buttons={[
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]}
                      tooltip={TooltipText.employerMatch()}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                      disabled={!employerContribIsEditable}
                    />
                  )}
                />
              )}
            {values.employerMatch && <SubHeader>Employer match</SubHeader>}
            {isInstitutionalAccount && props.account?.account?.contributionTiers?.length > 1 && (
              <Tiers contributionTiers={props.account?.account?.contributionTiers} />
            )}
            {values.employerContrib === true &&
              !(props.account?.account?.contributionTiers?.length > 1) &&
              (!isInstitutionalAccount || values.employerMatch) && (
                <>
                  <InputField
                    label='Match per dollar'
                    sublabel='(e.g. 50% or $0.50)'
                    flexLabel='0 1 300px'
                    flexInput='0 1 300px'
                    padding='20px 0 3px'
                    paddingInput='0'
                    key='companyMatchRate'>
                    <Field
                      name='companyMatchRate'
                      format={v => v}
                      parse={v => v}
                      render={({ input, meta }) => (
                        <PercentOrDollarInput
                          readonly={isInstitutionalAccount && institutionalAccountUnderReview}
                          name={input.name}
                          value={input.value === '' ? null : input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                          decimalScale={2}
                        />
                      )}
                    />
                  </InputField>

                  <InputField
                    label='Maximum match percent'
                    sublabel='(e.g. up to 6%)'
                    flexLabel='0 1 300px'
                    flexInput='0 1 300px'
                    padding='3px 0'
                    paddingInput='0'
                    key='companyMatchRateMax'>
                    <Field
                      name='companyMatchRateMax'
                      format={v => v}
                      parse={v => v}
                      render={({ input, meta }) => (
                        <PercentInput
                          readonly={isInstitutionalAccount && institutionalAccountUnderReview}
                          name={input.name}
                          value={input.value === '' ? null : input.value}
                          onChange={(name, value) => input.onChange(value)}
                          onBlur={input.onBlur}
                          error={meta.error}
                          showError={meta.touched}
                          width='115px'
                        />
                      )}
                    />
                  </InputField>

                  {!(isInstitutionalAccount && institutionalAccountUnderReview) && (
                    <InputField
                      label='Maximum match dollar amount'
                      sublabel='(e.g. up to $1,500)'
                      flexLabel='0 1 300px'
                      flexInput='0 1 300px'
                      padding='3px 0'
                      paddingInput='0'
                      key='companyMatchDlrMax'>
                      <Field
                        name='companyMatchDlrMax'
                        format={value => (value === null ? undefined : value)}
                        parse={v => v}
                        render={({ input, meta }) => (
                          <CurrencyInput
                            name={input.name}
                            value={input.value}
                            onChange={(name, value) => input.onChange(value)}
                            onBlur={input.onBlur}
                            error={meta.error}
                            showError={meta.touched}
                            width='115px'
                          />
                        )}
                      />
                    </InputField>
                  )}
                </>
              )}
            {profitSharingIsEditable &&
              !(isInstitutionalAccount && institutionalAccountUnderReview) && (
                <Field
                  name='profitSharing'
                  render={({ input, meta }) => (
                    <MultiButtonInput
                      label='Does the plan include fixed contributions from the employer OR a profit sharing component?'
                      tooltip={TooltipText.profitSharing()}
                      buttons={[
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]}
                      name={input.name}
                      value={input.value}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                      disabled={!profitSharingIsEditable}
                    />
                  )}
                />
              )}
            {profitSharingIsEditable && values.profitSharing === true && values.profitSharing && (
              <SubHeader>Profit sharing</SubHeader>
            )}
            {profitSharingIsEditable && values.profitSharing === true && values.profitSharing && (
              <InputField
                label='Expected contribution'
                flexLabel='0 1 300px'
                flexInput='0 1 300px'
                padding='20px 0 3px'
                paddingInput='0'>
                <Field
                  name='profitSharingRate'
                  format={v => v}
                  parse={v => v}
                  render={({ input, meta }) => (
                    <PercentInput
                      readonly={isInstitutionalAccount && institutionalAccountUnderReview}
                      name={input.name}
                      value={input.value === '' ? null : input.value}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                      decimalScale={2}
                      maxLength={6}
                      width='115px'
                    />
                  )}
                />
              </InputField>
            )}
          </div>
        </WizardStep>
      )}
    />
  )
}

const SubHeader = styled.div`
  margin-top: 20px;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${p => p.theme.mediumGray};
`

const OrgMessage = styled.div`
  color: ${p => p.theme.mediumGray};
`

export default inject('store', 'account')(observer(AddEmployerContributions))
