import styled from '@emotion/styled'

const Wrapper = styled.div`
  color: ${p => p.theme.darkestGray};
  font-family: 'Open Sans';
`
Wrapper.LetsGetStarted = styled.div`
  font-size: 36px;
  font-weight: 300;
  line-height: 49px;
`
Wrapper.HowTo = styled.div`
  font-size: 16pt;
  font-weight: 600;
  line-height: 20pt;
`
Wrapper.QuoteText = styled.p`
  font-size: 14pt;
  font-style: italic;
  line-height: 16pt;
  text-align: center;
  margin: 13pt 0;
  color: ${p => p.theme.lightestGray};
`
Wrapper.BlueDivider = styled.div`
  height: 1px;
  width: 451pt;
  border: 1px solid #038ab7;
  text-align: center;
  margin: auto;
  margin-bottom: 9pt;
`
Wrapper.BulletTextContainer = styled.div`
  display: flex;
  padding: 6pt 0;
  align-items: flex-start;
  justify-content: flex-start;
`

Wrapper.SquareBullet = styled.div`
  border: 2px solid #038ab7;
  margin-right: 12pt;
  width: 19pt;
  height: 19pt;
  flex: 0 0 19pt;
`

Wrapper.TodoText = styled.span`
  font-size: 16pt;
  line-height: 20pt;
`

Wrapper.Ul = styled.ul`
  padding-left: 45pt;
`

Wrapper.Li = styled.li`
  font-size: 14pt;
  line-height: 20pt;
  &::marker {
    color: #038ab7;
  }
`

Wrapper.BulletText = props => (
  <Wrapper.BulletTextContainer>
    <Wrapper.SquareBullet />
    <Wrapper.TodoText>{props.children}</Wrapper.TodoText>
  </Wrapper.BulletTextContainer>
)

Wrapper.Footer = styled.div`
  height: 137pt;
  width: 612pt;
  background-color: rgba(79, 173, 204, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 27pt;
  padding-left: 41pt;
  padding-right: 41pt;
`
Wrapper.FooterTitle = styled.div`
  font-family: Aleo;
  font-size: 16pt;
  font-weight: bold;
  line-height: 20pt;
  text-align: center;
`
Wrapper.FooterText = styled.div`
  color: ${p => p.theme.lightestGray};
  font-family: Aleo;
  font-size: 14pt;
  font-weight: bold;
  line-height: 20pt;
  text-align: center;
`
export { Wrapper }
