import { css } from '@emotion/react'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import { Switch } from '../../../guided-toolbox'

const GlidePathSwitch = props => {
  const {
    userSettings: { glidePath },
    handleGlidePathChange,
  } = props.store

  const enabled = _.includes(['local', 'QA'], process.env.REACT_APP_DEPLOY_ENV)

  return enabled ? (
    <div
      css={css`
        text-align: right;
      `}>
      <Switch checked={glidePath} label='Glide path' onChange={handleGlidePathChange} />
    </div>
  ) : null
}

export default inject('store')(observer(GlidePathSwitch))
