import { Component } from 'react'
import styled from '@emotion/styled'

import { numberToDollars } from '../../../utils'

const TableData = styled.td`
  color: ${p => p.theme.lightestGray};
  font-size: 12px;
  text-align: left;
  padding: 1px 0;
  min-width: 350px;
  vertical-align: top;
  padding-right: 36px;
`

const TableValue = styled.td`
  color: ${p => p.theme.darkestGray};
  font-size: 12px;
  opacity: ${p => (p.difference === 0 ? '0' : null)};
  width: 25%;
  min-width: 180px;
  padding-right: 12px;
  vertical-align: top;
`

const SignText = styled.span`
  &::before {
    content: ${p => (p.difference < 0 ? "'-'" : null)};
  }
  color: ${p => p.theme.darkestGray};
`

const ColorText = styled.span`
  color: ${p => (p.difference > 0 ? '#3F8B1E' : p.difference < 0 ? '#EF6B1D' : '#022a3a')};

  &::before {
    content: ${p => (p.prefix ? `'${p.prefix}'` : null)};
    color: #051f2c;
  }
  &::first-letter {
    color: ${p => (p.prefix && p.difference < 0 ? '#EF6B1D' : '#022a3a')};
  }

  &::after {
    content: ${p => (p.suffix ? `'${p.suffix}'` : null)};
    color: #051f2c;
  }
`

class CompareRow extends Component {
  static defaultProps = {
    unit: 'D',
    reverseColor: false,
  }

  render() {
    const { label, compareCell, withCell, unit, reverseColor } = this.props
    const reverseColorFactor = reverseColor ? -1 : 1
    const cellDifference = reverseColorFactor * (withCell - compareCell)

    return unit === 'D' ? (
      <tr>
        <TableData>{label}</TableData>
        <TableValue>${numberToDollars(compareCell, true)}</TableValue>
        <TableValue>
          $<ColorText difference={cellDifference}>{numberToDollars(withCell, true)}</ColorText>
        </TableValue>
        <TableValue difference={cellDifference}>
          <SignText difference={reverseColorFactor * cellDifference}>
            <ColorText prefix='$' difference={reverseColorFactor * cellDifference}>
              {numberToDollars(Math.abs(cellDifference), true)}
            </ColorText>
          </SignText>
        </TableValue>
      </tr>
    ) : unit === 'none' ? (
      <tr>
        <TableData>{label}</TableData>
        <TableValue>{numberToDollars(compareCell, true)}</TableValue>
        <TableValue>
          <ColorText difference={cellDifference}>{numberToDollars(withCell, true)}</ColorText>
        </TableValue>
        <TableValue difference={cellDifference}>
          <SignText difference={cellDifference}>
            <ColorText difference={cellDifference}>
              {numberToDollars(Math.abs(cellDifference), true)}
            </ColorText>
          </SignText>
        </TableValue>
      </tr>
    ) : (
      <tr>
        <TableData>{label}</TableData>
        <TableValue>{numberToDollars(compareCell, true)}%</TableValue>
        <TableValue>
          <ColorText difference={cellDifference}>{numberToDollars(withCell, true)}</ColorText>%
        </TableValue>

        <TableValue difference={cellDifference}>
          <SignText difference={cellDifference}>
            <ColorText difference={cellDifference}>
              {numberToDollars(Math.abs(cellDifference), true)}
            </ColorText>
            %
          </SignText>
        </TableValue>
      </tr>
    )
  }
}

class CompareRowDualData extends Component {
  static defaultProps = {
    unit: 'D',
  }

  render() {
    const { label, compareCellFirst, compareCellSecond, withCellFirst, withCellSecond, unit } =
      this.props

    const cellDifferenceFirst = withCellFirst - compareCellFirst
    const cellDifferenceSecond = withCellSecond - compareCellSecond

    return unit === 'D' ? (
      <tr>
        <TableData>{label}</TableData>
        <TableValue>
          ${numberToDollars(compareCellFirst, true)} | ${numberToDollars(compareCellSecond, true)}
        </TableValue>
        <TableValue>
          $
          <ColorText difference={cellDifferenceFirst}>
            {numberToDollars(withCellFirst, true)}
          </ColorText>{' '}
          | $
          <ColorText difference={cellDifferenceSecond}>
            {numberToDollars(withCellSecond, true)}
          </ColorText>
        </TableValue>
        <TableValue difference={withCellFirst - compareCellFirst + cellDifferenceSecond}>
          <SignText difference={cellDifferenceFirst}>
            <ColorText prefix='$' difference={cellDifferenceFirst}>
              {numberToDollars(Math.abs(cellDifferenceFirst), true)}
            </ColorText>
          </SignText>{' '}
          |{' '}
          <SignText difference={cellDifferenceSecond}>
            <ColorText prefix='$' difference={cellDifferenceSecond}>
              {numberToDollars(Math.abs(cellDifferenceSecond), true)}
            </ColorText>
          </SignText>
        </TableValue>
      </tr>
    ) : unit === 'DP' ? (
      <tr>
        <TableData>{label}</TableData>
        <TableValue>
          ${numberToDollars(compareCellFirst, true)} | {numberToDollars(compareCellSecond, true)}%
        </TableValue>
        <TableValue>
          $
          <ColorText difference={cellDifferenceFirst}>
            {numberToDollars(withCellFirst, true)}
          </ColorText>{' '}
          |{' '}
          <ColorText difference={cellDifferenceSecond}>
            {numberToDollars(withCellSecond, true)}
          </ColorText>
          %
        </TableValue>
        {/* cellDifferenceFirst + cellDifferenceSecond checks for non-zero difference between the two values provided */}
        <TableValue difference={cellDifferenceFirst + cellDifferenceSecond}>
          <SignText difference={cellDifferenceFirst}>
            <ColorText prefix='$' difference={cellDifferenceFirst}>
              {numberToDollars(Math.abs(cellDifferenceFirst), true)}
            </ColorText>
          </SignText>{' '}
          |{' '}
          <SignText difference={cellDifferenceSecond}>
            <ColorText difference={cellDifferenceSecond}>
              {numberToDollars(Math.abs(cellDifferenceSecond), true)}
            </ColorText>
            %
          </SignText>
        </TableValue>
      </tr>
    ) : unit === 'PD' ? (
      <tr>
        <TableData>{label}</TableData>
        <TableValue>
          {numberToDollars(compareCellFirst, true)}% | ${numberToDollars(compareCellSecond, true)}
        </TableValue>
        <TableValue>
          <ColorText difference={cellDifferenceFirst}>
            {numberToDollars(withCellFirst, true)}
          </ColorText>
          %{' | '}$
          <ColorText difference={cellDifferenceSecond}>
            {numberToDollars(withCellSecond, true)}
          </ColorText>
        </TableValue>
        <TableValue difference={cellDifferenceFirst + cellDifferenceSecond}>
          <SignText difference={cellDifferenceFirst}>
            <ColorText difference={cellDifferenceFirst}>
              {numberToDollars(Math.abs(cellDifferenceFirst), true)}
            </ColorText>
            %
          </SignText>
          {' | '}
          <SignText difference={cellDifferenceSecond}>
            <ColorText prefix='$' difference={cellDifferenceSecond}>
              {numberToDollars(Math.abs(cellDifferenceSecond), true)}
            </ColorText>
          </SignText>
        </TableValue>
      </tr>
    ) : (
      <tr>
        <TableData>{label}</TableData>
        <TableValue>
          {numberToDollars(compareCellFirst, true)}% | {numberToDollars(compareCellSecond, true)}%
        </TableValue>
        <TableValue>
          <ColorText difference={cellDifferenceFirst}>
            {numberToDollars(withCellFirst, true)}
          </ColorText>
          % |{' '}
          <ColorText difference={cellDifferenceSecond}>
            {numberToDollars(withCellSecond, true)}
          </ColorText>
          %
        </TableValue>
        <TableValue difference={cellDifferenceFirst + cellDifferenceSecond}>
          <SignText difference={cellDifferenceFirst}>
            <ColorText difference={cellDifferenceFirst}>
              {numberToDollars(Math.abs(cellDifferenceFirst), true)}
            </ColorText>
            %
          </SignText>
          |{' '}
          <SignText difference={cellDifferenceSecond}>
            <ColorText difference={cellDifferenceSecond}>
              {numberToDollars(Math.abs(cellDifferenceSecond), true)}
            </ColorText>
            %
          </SignText>
        </TableValue>
      </tr>
    )
  }
}

export { CompareRow, CompareRowDualData }
