import { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Form, Field } from 'react-final-form'

import { Page } from '../elements'
import { Layout } from '../../offboarding/elements/'
import { Button, Spacer, SVGWrapper } from '../../../components'
import { LargeCheckbox } from '../../../guided-toolbox/checkbox'
import { PiggyBank } from '../../../assets/icons'

const StyledSelectionField = styled(Layout.SelectionField)`
  display: flex;

  label {
    display: inline-flex;
    width: 36px;
    transform: scale(1.5, 1.5) translate(5px, 5px);
  }
`

const AccumOrSpend = props => {
  const [isSpending, setIsSpending] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const {
    history,
    store: {
      config: { enableSpendingMode, disableSpendingMode },
    },
    onboarding: { setHasChosenSpendingOrSaving },
  } = props

  useEffect(() => {
    return async () => {
      await setHasChosenSpendingOrSaving(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitSavingOrSpending = async () => {
    try {
      if (isSaving) {
        await disableSpendingMode()
      } else if (isSpending) {
        await enableSpendingMode()
      }

      history.push('/welcome/accept-terms')
    } catch (err) {
      console.error(err)
    }
  }

  function onSubmit(values) {
    return submitSavingOrSpending()
  }

  function toggleSpendOrSave(name) {
    if (name === 'saving') {
      if (isSpending) {
        setIsSpending(false)
      }
      setIsSaving(!isSaving)
    } else {
      if (isSaving) {
        setIsSaving(false)
      }
      setIsSpending(!isSpending)
    }
  }

  return (
    <Page>
      <Page.Heading title={'Are you accumulating or spending your retirement savings?'} />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <Page.AnimateGroup>
              <Page.AnimateItem>
                <StyledSelectionField>
                  <Field
                    name='saving'
                    render={({ input }) => (
                      <Page.Text
                        css={css`
                          font-size: 36px;
                        `}>
                        <LargeCheckbox
                          css={css`
                            vertical-align: middle;
                          `}
                          name={input.name}
                          checked={isSaving}
                          onChange={() => {
                            toggleSpendOrSave(input.name)
                          }}
                        />
                        I'm still saving for retirement{' '}
                        <SVGWrapper
                          svg={PiggyBank}
                          fill='#059FC6'
                          size='custom55'
                          css={css`
                            cursor: text;
                            vertical-align: middle;
                          `}
                        />
                      </Page.Text>
                    )}
                  />
                </StyledSelectionField>
                <Page.Text
                  css={css`
                    margin: 0 40px 20px 0;
                  `}>
                  If you're still getting a paycheck and putting money aside for retirement, we'll
                  help you select an income goal for retirement and then create a plan that can help
                  you reach that goal.
                </Page.Text>
                <Page.Text
                  css={css`
                    margin: 0 40px 0 0;
                  `}>
                  If you're close to retirement—within three years—you may wish to get a better idea
                  of your strategy for turning your savings into regular retirement income that will
                  last. You will have access to our spenddown modeling to look at situations such
                  as, what if I live to 105? What if my spouse passes this year? And, should I buy
                  an annuity?
                </Page.Text>
                <Spacer space='20px' />
                <StyledSelectionField>
                  <Field
                    name='spending'
                    render={({ input }) => (
                      <Page.Text
                        css={css`
                          font-size: 36px;
                        `}>
                        <LargeCheckbox
                          name={input.name}
                          checked={isSpending}
                          onChange={() => {
                            toggleSpendOrSave(input.name)
                          }}
                          css={css`
                            vertical-align: middle;
                          `}
                        />
                        I'm retired and ready to withdraw money for my retirement expenses{' '}
                        <SVGWrapper
                          svg={PiggyBank}
                          fill='#7A8E96'
                          size='custom55'
                          css={css`
                            transform: scale(-1, -1);
                            cursor: text;
                            vertical-align: middle;
                          `}
                        />
                      </Page.Text>
                    )}
                  />
                </StyledSelectionField>
                <Page.Text
                  css={css`
                    margin: 0 40px 0 0;
                  `}>
                  If you're no longer getting a paycheck and are retired, you should have a strategy
                  to make your money last. We'll provide withdrawal advice that seeks to do just
                  that.
                </Page.Text>
                <Spacer space='20px' />
              </Page.AnimateItem>
              <Page.AnimateItem>
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    width: 90%;
                  `}>
                  <Button
                    primary
                    label='Next'
                    width='100px'
                    disabled={!isSaving && !isSpending}
                    onClick={handleSubmit}>
                    Next
                  </Button>
                </div>
              </Page.AnimateItem>
            </Page.AnimateGroup>
          )
        }}
      />
    </Page>
  )
}

export default inject('onboarding', 'store')(observer(AccumOrSpend))
