import { types, getParent, getEnv } from 'mobx-state-tree'
import _ from 'lodash'
const { model, maybeNull, number, string, literal, boolean } = types

const PensionMap = {
  name: 'pensionName',
  participantId: 'ownerId',
}

export const PensionAccount = model({
  accountId: maybeNull(number),
  cola: maybeNull(number),
  colaWithCpi: maybeNull(string),
  distOption: maybeNull(number),
  editable: maybeNull(boolean),
  id: maybeNull(number),
  lumpsumreceived: maybeNull(string),
  monthlyPensionIncome: maybeNull(number),
  ownerId: maybeNull(number),
  paymentType: maybeNull(string),
  pensionName: maybeNull(string),
  pensionStartAge: maybeNull(number),
  survivorFraction: maybeNull(number),
  type: literal('Pension'),
})
  .views(self => ({
    get planType() {
      return 25
    },
    get participantName() {
      const { spouse, person } = getEnv(getParent(self)).store

      if (self.ownerId === spouse.id) {
        return spouse.firstName
      } else {
        return person.displayName
      }
    },
    get name() {
      return self.pensionName
    },
    get participantId() {
      return self.ownerId
    },
    get isBasicsNull() {
      const basics = ['pensionName', 'ownerId']
      for (let i = 0; i < basics.length; i++) {
        if (self[basics[i]] !== null) {
          return false
        }
      }
      return true
    },
    get isPensionNull() {
      const pension = [
        'cola',
        'distOption',
        'lumpsumreceived',
        'monthlyPensionIncome',
        'paymentType',
        'pensionStartAge',
        'survivorFraction',
      ]

      for (let i = 0; i < pension.length; i++) {
        if (self[pension[i]] !== null) {
          return false
        }
      }
      return true
    },
    get trackInvestmentsByTaxType() {
      return false
    },
  }))
  .actions(self => ({
    setData(data) {
      _.merge(self, data)
    },
    setAttributes(name, value) {
      const changes = _.isString(name) ? { [name]: value } : name
      _.merge(self, changes)
    },
    handleChange(name, value) {
      if (Object.prototype.hasOwnProperty.call(PensionMap, name)) {
        name = PensionMap[name]
      }

      self[name] = value
    },
    cleanPensionData() {
      const { paymentType } = self
      if (paymentType === 'M') {
        self.handleChange('lumpsumreceived', 'N')
      } else if (paymentType === 'L') {
        self.handleChange('survivorFraction', 0)
      }
    },
  }))
