/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { NumericFormat } from 'react-number-format'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import _ from 'lodash'
import posed from 'react-pose'
import styled from '@emotion/styled'

import { TextInput, Button, DatePicker, HelpIcon, TooltipText, FormError } from '../../components'
import { RadioGroup, RadioButton } from '../../guided-toolbox'
import { Page, Header } from './elements'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

const ResetPasswordForm = props => {
  const [oneUpperCase, setOneUpperCase] = useState(false)
  const [oneLowerCase, setOneLowerCase] = useState(false)
  const [oneNumber, setOneNumber] = useState(false)
  const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false)
  const [minlength, setMinLength] = useState(false)
  const [enableAuthMethodSelection] = useState(false)

  const {
    location,
    history,
    store: { initiateResetPassword2FA },
  } = props

  const onSubmit = async values => {
    return handleResetPassword(values)
  }

  const initialValues = {
    twoFactorType: 'email',
    birthDate: undefined,
    password: undefined,
    confirmPassword: undefined,
  }

  const validate = values => {
    const errors = {}
    setOneUpperCase(false)
    setOneLowerCase(false)
    setOneNumber(false)
    setOneSpecialCharacter(false)
    setMinLength(false)

    if (!values.twoFactorType) {
      errors.twoFactorType = 'Required'
    }

    if (values.twoFactorType === 'phone') {
      if (values.mobilePhone === undefined || values.mobilePhone === '') {
        errors.mobilePhone = 'Mobile number is required to receive two-factor authentication code'
      }
    }

    if (values.password && values.password.length > 7) {
      setMinLength(true)
    }

    if (values.password) {
      const result = values.password.match(/[0-9]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneNumber(true)
      }
    }

    if (values.password) {
      const result = values.password.match(/[a-z]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneLowerCase(true)
      }
    }

    if (values.password) {
      const result = values.password.match(/[A-Z]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneUpperCase(true)
      }
    }

    if (values.password) {
      const result = values.password.match(/[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneSpecialCharacter(true)
      }
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm password must match password'
    }
    return errors
  }

  const handleResetPassword = async values => {
    try {
      await initiateResetPassword2FA(values)
      history.push('/atmos/register/enter-auth-code/password-2FA')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  const handleCancel = () => {
    history.push('/')
  }

  return (
    <RouteContainer key={location.key || location.pathname}>
      <Page.Container>
        <Header />
        <Page>
          <Page.Heading title='Reset password' />
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            subscription={{
              submitting: true,
              submitError: true,
              errors: true,
              hasSubmitErrors: true,
              hasValidationErrors: true,
              invalid: true,
              pristine: true,
            }}
            validate={validate}
            render={({ handleSubmit, submitting, submitError, values }) => (
              <Page.Form>
                <Page.AnimateGroup>
                  <Page.AnimateItem
                    css={css`
                      padding-left: 20px;
                    `}>
                    <BorderedCard>
                      <CardTitle>Enter profile info</CardTitle>
                      <CardBody>
                        <FieldWrapper>
                          <Label htmlFor='birthDate'>Birthdate</Label>
                          <HTMLField>
                            <BirthDateField />
                          </HTMLField>
                        </FieldWrapper>

                        <FieldWrapper>
                          <Label htmlFor='mobilePhone'>
                            Mobile phone <HelpIcon tooltip={TooltipText.atmosMobileNumber()} />
                          </Label>

                          <HTMLField>
                            <MobilePhoneField />
                          </HTMLField>
                        </FieldWrapper>
                      </CardBody>
                    </BorderedCard>

                    <BorderedCard>
                      <CardTitle>Reset password</CardTitle>
                      <Page.AnimateItem style={{ alignSelf: 'flex-start' }}>
                        <Page.Text>Password must meet the following requirements:</Page.Text>
                        <PasswordRequirements>
                          <Page.Text>
                            {oneUpperCase ? <CheckMark>&#10003;</CheckMark> : <span />} at least 1
                            upper case letter
                          </Page.Text>
                          <Page.Text>
                            {oneLowerCase ? <CheckMark>&#10003;</CheckMark> : <span />} at least 1
                            lower case letter
                          </Page.Text>
                          <Page.Text>
                            {oneNumber ? <CheckMark>&#10003;</CheckMark> : <span />} at least 1
                            number
                          </Page.Text>
                          <Page.Text>
                            {oneSpecialCharacter ? <CheckMark>&#10003;</CheckMark> : <span />} at
                            least 1 special characters
                          </Page.Text>
                          <Page.Text>
                            {minlength ? <CheckMark>&#10003;</CheckMark> : <span />} at least 8
                            characters in length
                          </Page.Text>
                        </PasswordRequirements>
                      </Page.AnimateItem>
                      <CardBody>
                        <FieldWrapper>
                          <Label htmlFor='password'>New password</Label>
                          <HTMLField>
                            <PasswordField />
                          </HTMLField>
                        </FieldWrapper>

                        <FieldWrapper>
                          <Label htmlFor='confirmPassword'>Confirm new password</Label>
                          <HTMLField>
                            <ConfirmPasswordField />
                          </HTMLField>
                        </FieldWrapper>
                      </CardBody>
                    </BorderedCard>

                    {enableAuthMethodSelection && (
                      <BorderedCard>
                        <div
                          css={css`
                            display: flex;
                            align-self: flex-start;
                            flex-wrap: wrap;
                            align-items: center;
                          `}>
                          <CardTitle
                            css={css`
                              word-break: break-word;
                            `}>
                            Select authentication method
                          </CardTitle>{' '}
                          &nbsp; &nbsp;
                          <HelpIcon tooltip={TooltipText.authenticationMethod()} />
                        </div>

                        <AuthenticationRadioGroup />
                      </BorderedCard>
                    )}

                    {submitError && <FormError err={submitError} />}
                  </Page.AnimateItem>

                  <div
                    css={css`
                      display: flex;
                      justify-content: space-between;
                      margin-top: 30px;
                    `}>
                    <Button
                      onClick={handleCancel}
                      style={{ width: 124 }}
                      label='Cancel'
                      type='button'
                      secondary
                    />
                    <Button
                      onClick={handleSubmit}
                      label='Next'
                      type='submit'
                      primary
                      disabled={submitting}
                      style={{ width: 124 }}
                    />
                  </div>
                </Page.AnimateGroup>
              </Page.Form>
            )}
          />
        </Page>
      </Page.Container>
    </RouteContainer>
  )
}

export default inject('store')(observer(ResetPasswordForm))

const BorderedCard = styled.div`
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px 0;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const CardTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 36px;
  line-height: 46px;
  font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
  font-weight: 300;
  margin-bottom: 12px;
  align-self: flex-start;
  word-break: break-word;
`

const PasswordRequirements = styled.div`
  align-self: flex-start;
  word-break: break-word;
`

const CheckMark = styled.span`
  color: green;
`

const HTMLField = styled.div`
  flex: 1;
`

const CardBody = styled.div`
  max-width: 500px;
  width: 100%;
`
const Label = styled.label`
  color: #7a8e96;
  font-size: 18px;
  font-family: 'Open Sans';
  flex: 1;
  text-align: right;
  padding-right: 20px;
`
const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`

function MobilePhoneField() {
  const { input, meta } = useField('mobilePhone')
  return (
    <FieldErrorWrapper>
      <NumericFormat
        format='(###) ###-####'
        {...input}
        customInput={(meta.error || meta.submitError) && meta.touched ? ErrText : Text}
        placeholder='(###) ###-####'
      />
      {(meta.error || meta.submitError) && meta.touched && (
        <Error>{meta.error || meta.submitError}</Error>
      )}
    </FieldErrorWrapper>
  )
}

function BirthDateField() {
  const { input, meta } = useField('birthDate', {
    validate: value =>
      yup
        .string()
        .required('Birthdate is required')
        .max(11, 'Maximum character limit 11 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <DatePicker
      handleChange={(name, value) => input.onChange(value)}
      maxYear={new Date().getFullYear()}
      minYear={1910}
      name={input.name}
      date={input.value}
      showError={meta.touched}
      error={meta.error}
    />
  )
}

function PasswordField() {
  const { input, meta } = useField('password', {
    validate: value =>
      yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(32, 'Password cannot exceed 32 characters')
        .matches(/[0-9]/g, 'Password must include at least one number')
        .matches(/[a-z]/g, 'Password must include at least one lower case letter')
        .matches(/[A-Z]/g, 'Password must include at least one upper case letter')
        .matches(
          /[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g,
          'Password must include at least one special character'
        )
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='password'
      error={meta.error}
      type='password'
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='Password'
      showError={meta.touched}
      value={input.value}
      width='100%'
      passwordRules
    />
  )
}

function ConfirmPasswordField() {
  const { input, meta } = useField('confirmPassword', {
    validate: value =>
      yup
        .string()
        .required('Confirm password is required')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='confirmPassword'
      error={meta.error}
      type='password'
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='Password'
      showError={meta.touched}
      value={input.value}
      width='220px'
    />
  )
}

function AuthenticationRadioGroup() {
  const { input, meta } = useField('twoFactorType', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <div
      css={css`
        margin: 16px;
      `}>
      <RadioGroup name='twoFactorType' value={input.value} onChange={input.onChange}>
        <RadioButton label='Send email to login email' value='email' />
        <RadioButton label='Send text to mobile' value='phone' />
      </RadioGroup>
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </div>
  )
}

const FieldErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Error = styled.span`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
`
const Text = styled.input`
  outline: none;
  border: none;
  width: 220px;
  max-width: 100%;
  color: #022a3a;
  background-color: transparent;
  -webkit-transition: border-bottom 0.3s;
  transition: border-bottom 0.3s;
  font-size: 1.125rem;
  line-height: 1.5rem;
  border-bottom: 1.5px solid #7a8e96;
  margin-top: 5px;
  min-height: 48px;
  padding: 10px 0;
  max-width: 220px;
`

const ErrText = styled.input`
  outline: none;
  border: none;
  width: 220px;
  max-width: 100%;
  color: #022a3a;
  background-color: transparent;
  -webkit-transition: border-bottom 0.3s;
  transition: border-bottom 0.3s;
  font-size: 1.125rem;
  line-height: 1.5rem;
  border-bottom: 1.5px solid #e31e27;
  margin-top: 5px;
  min-height: 48px;
  padding: 10px 0;
  max-width: 220px;
`
