import { useTheme } from '@emotion/react'
/* eslint-disable jsx-quotes */
const MenuIcon = () => {
  const theme = useTheme()

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='36'
      viewBox='0 0 24 24'
      fill={theme.mobileMenuIconFill}>
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z' />
    </svg>
  )
}
export default MenuIcon
