import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Page, PageHeader } from '../elements'
import {
  ClientPlan,
  SpousePlan,
  AdditionalSavings,
  OtherAccounts,
  NothingTodo,
  UpdateInformation,
} from './blocks'
import { MainTitle, ContentText, FootNote, ContentContainer, TodoBox } from './styled'
import Investments from '../../../models/Investments'

const todoPageConfig = templateId => {
  switch (templateId) {
    case 1:
      return {
        clientPlanEnabled: true,
        footNote: (
          <FootNote>
            Details of savings and investment changes can be found in upcoming sections of this
            guide.
          </FootNote>
        ),
      }

    case 2:
      return {
        clientPlanEnabled: false,
        footNote: (
          <FootNote>
            Details of savings and investment changes can be found in upcoming sections of this
            guide.
          </FootNote>
        ),
      }
    case 19:
      return {
        clientPlanEnabled: false,
        footNote: null,
      }
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    default:
      return {
        clientPlanEnabled: false,
        footNote: (
          <FootNote
            css={css`
              font-family: Aleo;
              font-size: 12pt;
              font-weight: bold;
            `}>
            Your plan and the income you can expect at retirement rely on you to make the changes
            you've modeled, then to return each year to update your balances and to let us know of
            any changes affecting your plan so that we can update our advice.
          </FootNote>
        ),
      }
  }
}

class TodoPage extends Component {
  static displayName = 'TodoPage'

  static propTypes = {}

  constructor(props) {
    super(props)
    this.state = Investments.create({ investments: [] })
  }
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'todo' })
  }

  includeClientPlan = () => {
    const { config } = this.props.store
    const { displayName } = this.props.store.person
    const { riskLevel: caseRiskLevel } = this.props.store.acceptedCase
    const { riskLevel: baseRiskLevel } = this.props.store.baseCase

    return (
      caseRiskLevel !== null &&
      baseRiskLevel !== null &&
      caseRiskLevel !== baseRiskLevel && (
        <ClientPlan
          title={`Changes to ${displayName ? `${displayName}'s` : ''} ${
            config.template.planName
          } account`}
          contribution={null}
          investmentMix='Update investment mix'
          additionalText='GuidedChoice sends automatically to the plan administrator, any investment mix changes when the option to implement is selected through the advisory service. Finalization of those investment changes was completed through the Investment Plan Administrator. The investment holdings and their associated allocation percentages is detailed in the next section of your guide.'
        />
      )
    )
  }

  includeSpousePlan = () => {
    const { firstName: spouseName } = this.props.store.spouse
    const { includedSpouseEmployerAccount, spouseContributionChange } = this.props.store

    return (
      includedSpouseEmployerAccount &&
      (includedSpouseEmployerAccount.adviced === true || spouseContributionChange) && (
        <SpousePlan
          title={`Changes to ${
            spouseName ? `${spouseName}'s` : ''
          } current employer plan account (${includedSpouseEmployerAccount.name})`}
          contribution={spouseContributionChange && 'Update contribution'}
          investmentMix={includedSpouseEmployerAccount.adviced === true && 'Update investment mix'}
        />
      )
    )
  }

  includeAdditionalSavings = () => {
    const { special457, special403b } = this.props.store
    const {
      client457PreTaxSaving: base457pretax,
      client457RothSaving: base457roth,
      client403bPreTaxSaving: base403bpretax,
    } = this.props.store.baseCase
    const {
      outOfPlanMonthlySavings,
      client457PreTaxSaving: case457pretax,
      client457RothSaving: case457roth,
      client403bPreTaxSaving: case403bpretax,
    } = this.props.store.acceptedCase

    const special457update =
      special457 && (base457pretax !== case457pretax || base457roth !== case457roth)

    const special403bupdate = special403b && base403bpretax !== case403bpretax

    return (
      (outOfPlanMonthlySavings > 0 || special457update || special403bupdate) && (
        <AdditionalSavings />
      )
    )
  }

  includeOtherAccounts = () => {
    const { otherIncludedAccountsWithoutSpouseEmployer } = this.props.store

    return (
      otherIncludedAccountsWithoutSpouseEmployer.length > 0 && (
        <OtherAccounts accounts={otherIncludedAccountsWithoutSpouseEmployer} />
      )
    )
  }

  updateInformation = () => {
    const {
      store: {
        config: { isRetail },
      },
    } = this.props

    const { getInvestments } = this.state

    return isRetail ? <UpdateInformation getInvestments={getInvestments} /> : null
  }

  render() {
    const page = this.props.guide.guidePages.pages.findByName('todo')
    const {
      config: { templateId },
    } = this.props.store

    const todoPage = todoPageConfig(templateId)

    return (
      <Page header={<PageHeader />} page={page}>
        <TodoBox data-todo-box>
          <ContentContainer>
            <MainTitle>TO-DO LIST</MainTitle>

            <div
              css={css`
                padding: 10px 0;
              `}
            />

            <ContentText>
              While there are no absolute deadlines for your to-do list, this guide will get less
              accurate over time. If, like most to-do lists, it takes awhile to get around to, just
              log in again for a fresh version.
            </ContentText>

            <hr style={{ borderColor: '#038AB7', margin: '24px 0 20px' }} />

            {(todoPage.clientPlanEnabled && this.includeClientPlan()) || null}

            {this.includeSpousePlan() || null}

            {this.includeAdditionalSavings() || null}

            {this.includeOtherAccounts() || null}

            {this.updateInformation() || null}

            {!(todoPage.clientPlanEnabled && this.includeClientPlan()) &&
              !this.includeSpousePlan() &&
              !this.includeAdditionalSavings() &&
              !this.includeOtherAccounts() && <NothingTodo />}
          </ContentContainer>
        </TodoBox>

        {todoPage.footNote}
      </Page>
    )
  }
}

export default inject('store', 'guide')(observer(TodoPage))
