/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'

import { Page } from '../style'
import { Spacer, Button } from '../../../../components'

function HandyChecklist() {
  return (
    <Page.Container handy>
      <Page.Header
        css={css`
          line-height: 2.75rem;
          font-size: 2.3rem;
        `}>
        A retirement checklist for when to retire
      </Page.Header>
      <Spacer space='21px;' />

      <Page.Paragraph>
        When you are near retiring, you need to get your finances in order. This means setting a
        budget, applying for federal programs, and understanding retirement funds. Here is a handy
        retirement checklist so you don't miss anything and know when you can retire.
      </Page.Paragraph>

      <Spacer space='8px;' />

      <Page.Paragraph>
        Note: Look up “retirement checklist” on the internet and you will find many different lists
        put out by many different sources. What we have below is just one such list. You may find it
        helpful to look at others as well.
      </Page.Paragraph>

      <Page.SubHeaderDark>Make a budget</Page.SubHeaderDark>

      <Page.Paragraph>
        Before you choose when to retire, you need a budget[1] to know whether you can live
        comfortably when you stop working. Here are the key factors:
      </Page.Paragraph>

      <Page.Ul>
        <Page.Li small>List and compare your expected expenses and sources of income.</Page.Li>
        <Page.Li small>If you can, pay off your non-mortgage debt prior to retiring.</Page.Li>
        <Page.Li small>Don't forget about healthcare and taxes.</Page.Li>
        <Page.Li small>Learn when to apply for government benefits.</Page.Li>
        <Page.Li small>
          Figure out how much you will get from retirement accounts with your employer.
        </Page.Li>
        <Page.Li small>Keep in mind other income sources.</Page.Li>
        <Page.Li small>Consult with a retirement planner for better estimates for you.</Page.Li>
      </Page.Ul>

      <Page.SubHeaderDark>Federal Programs</Page.SubHeaderDark>

      <Page.Paragraph>
        You are entitled to Social Security benefits and Medicare for health insurance.
      </Page.Paragraph>

      <Page.SubHeaderLight>Apply for Social Security</Page.SubHeaderLight>

      <Page.Paragraph>
        How much you and your spouse will get depends on when you retire.[2] Here are the basic
        rules:
      </Page.Paragraph>

      <Page.Ul>
        <Page.Li small>
          You get full benefits at 65, 66 or 67, depending on when you were born.
        </Page.Li>
        <Page.Li small>You can get benefits at 62 but less than your full amount.</Page.Li>
        <Page.Li small>
          You will get more if you work longer or until you turn 70 when your benefits max out.
        </Page.Li>
        <Page.Li small>
          A spouse can get his or her benefits or 50% of the other spouse's amount.
        </Page.Li>
        <Page.Li small>Social Security benefits are subject to income tax.</Page.Li>
        <Page.Li small>
          Visit the Social Security Administration[3] for an official estimate or use the estimate
          as calculated by GuidedChoice.
        </Page.Li>
      </Page.Ul>

      <Page.SubHeaderLight>Apply for Medicare</Page.SubHeaderLight>

      <Page.Paragraph>
        Healthcare can be a major cost when you retire. Medicare is confusing because there are four
        types (known as “parts”).[4] Keep these factors about Medicare in mind:
      </Page.Paragraph>

      <Page.Ul>
        <Page.Li small>
          Avoid gaps in coverage or late enrollment penalties and apply three months before you’re
          first eligible, typically at age 65.
        </Page.Li>
        <Page.Li small>It usually covers about 50% of your healthcare costs.</Page.Li>
        <Page.Li small>There are premiums, coinsurance, and copays.</Page.Li>
        <Page.Li small>Medicare A only covers hospital services.</Page.Li>
        <Page.Li small>Medicare B covers outpatient services.</Page.Li>
        <Page.Li small>Medicare D covers prescriptions costs.</Page.Li>
        <Page.Li small>Medicare C is an alternative that covers more services.</Page.Li>
        <Page.Li small>Medigap is another option to cover services Plans A, B, and D miss.</Page.Li>
        <Page.Li small>
          Medicare does not cover long term care (LTC) until you have used all other funds.
        </Page.Li>
        <Page.Li small>You can get non-Medicare LTC insurance.</Page.Li>
        <Page.Li small>
          Use online tools[5] to find out what is covered and how much it will cost.
        </Page.Li>
      </Page.Ul>

      <Page.SubHeaderDark>Manage your retirement accounts</Page.SubHeaderDark>

      <Page.Paragraph>
        Your main source of income will be retirement funds through your employer and your
        individual accounts. How these accounts work after you retire depends on the type of funds
        and what you do with them.[6] Here are the key points for different funds:
      </Page.Paragraph>

      <Page.SubHeaderLight>401(k)s</Page.SubHeaderLight>

      <Page.Ul>
        <Page.Li small>
          If you retire after 59-1/2, then you can take money out without a 10% penalty.
        </Page.Li>
        <Page.Li small>
          Your company's plan may let you take funds as an annuity. This means you will get the same
          amount for a fixed period or over your expected lifetime.
        </Page.Li>
        <Page.Li small>
          Your company may let you can take different amounts when you want them or a lump sum.
        </Page.Li>
        <Page.Li small>Qualified distributions are taxable.</Page.Li>
        <Page.Li small>You do not need to take distributions.</Page.Li>
        <Page.Li small>You cannot contribute after you retire.</Page.Li>
        <Page.Li small>You do not pay taxes until you take distributions.</Page.Li>
      </Page.Ul>

      <Page.SubHeaderLight>Traditional and Roth IRAs</Page.SubHeaderLight>

      <Page.Paragraph>
        You can keep funds in your 401(k) or roll them over into a traditional or Roth IRA. These
        rules also apply if you already have IRAs.
      </Page.Paragraph>
      <Spacer space='15px' />
      <Page.Paragraph>
        For a <b>traditional IRA:</b>
      </Page.Paragraph>

      <Page.Ul>
        <Page.Li small>
          You can contribute if you (or your spouse if filing jointly) have taxable compensation.
          (Prior to 1/1/2020, you were unable to contribute if you were age 70-1/2 or older.)
        </Page.Li>
        <Page.Li small>
          You can only contribute wages from work, not investment income or Social Security
          benefits.
        </Page.Li>
        <Page.Li small>You can distribute your 401(k) savings to a new or existing IRA.</Page.Li>
        <Page.Li small>
          You can take the distribution yourself, but you will pay taxes on income if you do not put
          it into your IRA within 60 days.
        </Page.Li>
        <Page.Li small>Withdrawals are taxable.</Page.Li>
      </Page.Ul>

      <Page.Paragraph>
        <b>Roth IRAs</b> have some different rules:
      </Page.Paragraph>

      <Page.Ul>
        <Page.Li small>
          You can contribute at any age if you (or your spouse if filing jointly) have taxable
          compensation and your modified adjusted gross income is below certain amounts.
        </Page.Li>
        <Page.Li small>You do not pay taxes on Roth withdrawals.</Page.Li>
        <Page.Li small>
          Earnings are tax-free if you are over 59-1/2 and have had the account for at least five
          years.
        </Page.Li>
      </Page.Ul>

      <Page.SubHeaderLight>Required Minimum Distributions (RMDs)</Page.SubHeaderLight>

      <Page.Paragraph>
        You must generally start taking withdrawals when you reach age 72 (73 if you reach age 72
        after 12/31/2022).
      </Page.Paragraph>

      <Page.Ul>
        <Page.Li small>
          If you reach the age of 72 in 2023, the required beginning date for your first RMD is
          4/1/2025 for 2024.
        </Page.Li>
        <Page.Li small>
          If you reach age 73 in 2023, you were 72 in 2022 and subject to the age 72 RMD rules in
          effect for 2022.
        </Page.Li>
        <Page.Li small>
          If you reached age 72 in 2022, your first RMD is due by 4/1/2023 and based on your
          12/31/2021 account balance and your second RMD is due by 12/31/2023 and is based on your
          account balance as of 12/31/2022.
        </Page.Li>
      </Page.Ul>
      <Page.Ul>
        <Page.Li small>
          How much you pay is based on how long you are expected to live and your account balance.
        </Page.Li>
        <Page.Li small>There is a 50% penalty if you do not take the RMD.</Page.Li>
        <Page.Li small>You can withdraw more than the RMD.</Page.Li>
        <Page.Li small>There is no RMD for Roth IRAs until after you die.</Page.Li>
      </Page.Ul>

      <Page.Paragraph>
        Note: RMD rules can be confusing, as the rules differ for different types of retirement plan
        accounts. We recommend seeking tax advice to be certain of your obligations and to avoid
        steep penalties.
      </Page.Paragraph>

      <Page.SubHeaderLight>Pensions</Page.SubHeaderLight>

      <Page.Paragraph>
        If your employer offers a pension plan[8] instead of a 401(k) consider these factors:
      </Page.Paragraph>

      <Page.Ul>
        <Page.Li small>Choose to take a lump sum or an annuity.</Page.Li>
        <Page.Li small>
          You can roll over a lump sum into an IRA. You can invest these funds, and it will help
          with taxes.
        </Page.Li>
        <Page.Li small>
          If you take an annuity, you will get a fixed monthly amount that will not depend on
          investment outcomes.
        </Page.Li>
        <Page.Li small>Decide if you want a joint pension with your spouse.</Page.Li>
        <Page.Li small>Your Social Security may be reduced.</Page.Li>
      </Page.Ul>

      <Page.SubHeaderDark>How much to spend</Page.SubHeaderDark>

      <Page.Paragraph
        css={css`
          line-height: 1.2rem;
        `}>
        This is where GuidedChoice can really help. The guide you receive includes a withdrawal
        strategy for the current calendar year. You should expect to return each year so that you
        have advice that is not only in consideration of updates to your financial circumstances,
        but to tax laws and other market environmental issues.
      </Page.Paragraph>
      <Spacer space='10px;' />

      <Page.Paragraph
        css={css`
          line-height: 1.2rem;
        `}>
        Deciding when to retire depends on a good understanding of your finances. Following a
        retirement checklist is vital. GuidedChoice's retirement planners can help you understand
        the different retirement factors and make smart decisions so you can retire with confidence.
      </Page.Paragraph>
      <Spacer space='20px' />

      <Button
        type='button'
        onClick={() => window.close()}
        secondary
        label='Close'
        width='73px'
        css={css`
          height: 41px;
          font-size: 14px;
          text-align: center;
        `}
      />
      <Spacer space='20px' />

      <Page.Paragraph>Article Sources:</Page.Paragraph>
      <Page.Paragraph>
        [1]{' '}
        <Page.Link href='https://www.thebalance.com/how-to-make-a-retirement-budget-2388345'>
          https://www.thebalance.com/how-to-make-a-retirement-budget-2388345
        </Page.Link>
      </Page.Paragraph>

      <Page.Paragraph>
        [2]{' '}
        <Page.Link href='https://www.ssa.gov/planners/retire/retirechart.html'>
          https://www.ssa.gov/planners/retire/retirechart.html
        </Page.Link>
      </Page.Paragraph>

      <Page.Paragraph>
        [3]{' '}
        <Page.Link href='https://www.ssa.gov/OACT/quickcalc/'>
          https://www.ssa.gov/OACT/quickcalc/
        </Page.Link>
      </Page.Paragraph>

      <Page.Paragraph>
        [4]{' '}
        <Page.Link href='https://www.medicareinteractive.org/get-answers/medicare-basics/medicare-coverage-overview/original-medicare'>
          https://www.medicareinteractive.org/get-answers/medicare-basics/medicare-coverage-overview/original-medicare
        </Page.Link>
      </Page.Paragraph>

      <Page.Paragraph>
        [5]{' '}
        <Page.Link href='https://qa.mymedicarematters.org/resource/original-medicare-parts-a-b-costs'>
          https://qa.mymedicarematters.org/resource/original-medicare-parts-a-b-costs
        </Page.Link>
      </Page.Paragraph>

      <Page.Paragraph>
        [6]{' '}
        <Page.Link href='https://www.investopedia.com/articles/personal-finance/111615/how-401k-works-after-retirement.asp'>
          https://www.investopedia.com/articles/personal-finance/111615/how-401k-works-after-retirement.asp
        </Page.Link>
      </Page.Paragraph>

      <Page.Paragraph>
        [7]{' '}
        <Page.Link href='https://www.investopedia.com/terms/r/requiredminimumdistribution.asp\'>
          https://www.investopedia.com/terms/r/requiredminimumdistribution.asp\
        </Page.Link>
      </Page.Paragraph>

      <Page.Paragraph>
        [8]{' '}
        <Page.Link href='https://www.thebalance.com/should-you-cash-in-your-pension-2388768'>
          https://www.thebalance.com/should-you-cash-in-your-pension-2388768
        </Page.Link>
      </Page.Paragraph>

      <Spacer space='10px' />
      <Page.Paragraph>Updated: 5/15/2023</Page.Paragraph>
    </Page.Container>
  )
}

export default inject('store', 'spendDownOffboarding')(observer(HandyChecklist))
