import styled from '@emotion/styled'

const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const EmailContainer = styled.div`
  border: ${p => p.theme.emailContainerBorder};
  padding: 1rem;
  border-radius: 12px;
`

const IndentContainer = styled.div`
  padding-left: 36px;
`

export { FlexRow, EmailContainer, IndentContainer }
