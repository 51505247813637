import styled from '@emotion/styled'
import { Input } from '../../../guided-toolbox'
import { Layout } from '../elements'

const Container = styled(Layout.Container)`
  margin: 24px auto;
  padding: 0 12px;
`
const DisplayXLarge = styled(Layout.DisplayXLarge)`
  margin: 56px 24px 24px;
  line-height: 1.45;
`

const Body = styled(Layout.DisplaySmall)`
  margin: 24px;
`

const StyledSelectionBox = styled(Layout.SelectionBox)`
  max-width: 660px;
  margin: 28px 24px;
`

const CheckboxLabelContainer = styled('div')`
  margin: 12px 0 0;

  label {
    display: inline-block;
    margin: 0;
  }
`

const CheckboxLabel = styled(Layout.Body)`
  display: inline-block;
  width: 96%;
  cursor: pointer;
`

const StyledReceiveEmail = styled(Layout.SelectionTitle)``

const StyledSaveEmail = styled(CheckboxLabelContainer)`
  display: flex;

  margin: 8px;
  margin-left: 4%;

  color: #4a606a;

  & > div:first-of-type {
    margin-left: 1rem;
    font-size: 14px;
    line-height: 18px;
  }
`

const StyledConfirmEmail = styled(CheckboxLabelContainer)`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  font-size: 14px;
  line-height: 18px;

  label {
    display: inline-block;
  }
`

const StyledConfirmEmailInput = styled(Input)`
  padding: 0;
  margin-left: 1rem;

  input {
    font-size: 16px;
  }
`

export {
  Container,
  DisplayXLarge,
  Body,
  StyledSelectionBox,
  CheckboxLabel,
  StyledReceiveEmail,
  StyledSaveEmail,
  StyledConfirmEmail,
  StyledConfirmEmailInput,
}
