import { css } from '@emotion/react'
import { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { HelpIcon, HelpPersonIcon, TooltipText } from '../../../components'
import { numberToDollars } from '../../../utils'

const StrategyCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  & > * {
    margin: 0 10px;
  }
`

const StrategyCard = styled.div`
  background-color: #f5fafc;
  border: 1px solid #b6c0c4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: ${p => (p.marginBottom ? p.marginBottom : '20px;')};
  min-width: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${p => (p.padding ? p.padding : '1rem')};
  flex: 1 1 220px;
  visibility: ${p => (p.visibility ? p.visibility : 'visible')};
`

const Space = styled.div`
  flex-grow: 1;
`

const Label = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 0.875rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  min-height: 48px;
  padding-bottom: 4px;
  margin-bottom: 12px;
  line-height: 1.4;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Divider = styled.div`
  border-bottom: 1px solid #7a8e96;
  margin-bottom: 10px;
`

const Footer = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 0.875rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 8px 0;
  display: flex;
  align-items: center;
  line-height: 1.4;
`

const Amount = styled.div`
  display: inline-block;
  color: ${p => p.theme.darkestGray};
  font-size: 1.25rem;
  &::before {
    content: '$';
    color: ${p => p.theme.lightestGray};
    font-size: 1.25rem;
  }
`

const Text = styled.div`
  color: ${p => p.theme.darkestGray};
  font-size: 1.125rem;
  line-height: 1.25;
`

const SubLabel = styled.div`
  color: ${p => p.theme.lightestGray};
  font-size: 0.75rem;
  text-decoration: ${p => (p.underline ? 'underline' : null)};
  padding: 4px 0;
  line-height: 1.4;
`

const Row = styled.div`
  display: flex;
  justify-content: ${p => (p.justifyContent ? p.justifyContent : 'space-between')};
  align-items: center;
  flex-wrap: wrap;
`

const Dash = styled.span`
  color: ${p => p.theme.darkestGray};
  font-size: 0.875rem;
  padding: 0 4px;
`

const CardRow = styled.div`
  padding: 10px 0;
  min-height: 90px;
`

const SVGContainer = styled.span`
  position: relative;
`

class RangeRow extends Component {
  render() {
    const { firstNumber, secondNumber } = this.props
    if (firstNumber !== null && secondNumber !== null) {
      return (
        <Row justifyContent='flex-start'>
          <Amount>{numberToDollars(firstNumber, true)}</Amount>
          <>
            <Dash>{'\u2013'}</Dash>
            <Amount>{numberToDollars(secondNumber, true)}</Amount>
          </>
        </Row>
      )
    } else if (firstNumber !== null && secondNumber === null) {
      return <Amount>{numberToDollars(firstNumber, true)}</Amount>
    } else {
      return <Amount>0</Amount>
    }
  }
}

const ProjectedIncomeCard = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store, displayInflation } = this.props

        const {
          estimatedTotalMonthlyIncomeMin,
          estimatedTotalMonthlyIncomeMax,
          estimatedTotalMonthlyIncomeInflationAdjustedMin,
          estimatedTotalMonthlyIncomeInflationAdjustedMax,
        } = store.baseCase

        return (
          <StrategyCard>
            <Label>
              Range of projected monthly income after retiring&nbsp;
              <SVGContainer>
                <HelpIcon size='medium' tooltip={TooltipText.projectedIncomeRange()} />
              </SVGContainer>
            </Label>
            <Divider />
            <CardRow>
              <RangeRow
                firstNumber={estimatedTotalMonthlyIncomeMin}
                secondNumber={estimatedTotalMonthlyIncomeMax}
              />
              <SubLabel>Today's value</SubLabel>
            </CardRow>
            {displayInflation && (
              <CardRow>
                <RangeRow
                  firstNumber={estimatedTotalMonthlyIncomeInflationAdjustedMin}
                  secondNumber={estimatedTotalMonthlyIncomeInflationAdjustedMax}
                />
                <SubLabel>Value at retirement</SubLabel>
              </CardRow>
            )}
          </StrategyCard>
        )
      }
    }
  )
)

const RetirementAgeCard = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props
        const { displayName, includeSpouse } = store.person
        const { firstName: spouseName } = store.spouse
        const { primary, spouse } = store.baseCase

        return (
          <StrategyCard>
            <Label>Retirement age</Label>
            <Divider />
            {!!displayName && !!primary && !!primary.retAge && (
              <CardRow>
                <Text>{`${displayName} at ${primary.retAge}*`}</Text>
              </CardRow>
            )}
            {!!spouseName && !!includeSpouse && !!spouse && !!spouse.retAge && (
              <CardRow>
                <Text>{`${spouseName} at ${spouse.retAge}`}</Text>
              </CardRow>
            )}
            <Space />
            <Footer>*Projected values based on this retirement age</Footer>
          </StrategyCard>
        )
      }
    }
  )
)

const InvestmentRiskCard = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props

        const {
          institutionalAccount,
          isInstitutional,
          otherInvestmentAccounts,
          person: { displayName },
          features,
          config,
        } = store

        const { riskLevelText, riskLevelAllText } = store.baseCase

        // If no institutional account exists or institutional account has zero balance,
        // AND there are no other investment accounts, hide this card.
        if (
          (!institutionalAccount ||
            (!!institutionalAccount && institutionalAccount.balance === 0)) &&
          otherInvestmentAccounts.length === 0
        ) {
          return null
        }

        const includeAsterisk =
          features.outsideAdviceEnabled &&
          Boolean(isInstitutional) &&
          Boolean(institutionalAccount.balance)

        return (
          <StrategyCard>
            <Label>
              Investment risk &nbsp;
              <SVGContainer>
                <HelpIcon size='medium' tooltip={TooltipText.investmentRisk()} />
              </SVGContainer>
            </Label>
            <Divider />

            {!!isInstitutional && !!institutionalAccount.balance && (
              <>
                {riskLevelText && (
                  <CardRow>
                    <Text>{riskLevelText}</Text>
                    <SubLabel>
                      {displayName}'s investment plan account with {config.template.sponsorName}
                      {features.outsideAdviceEnabled ? '*' : ''}
                    </SubLabel>
                  </CardRow>
                )}
              </>
            )}

            {!!otherInvestmentAccounts.length && !!riskLevelAllText && (
              <CardRow>
                <Text>{riskLevelAllText}</Text>
                <SubLabel>All investment accounts</SubLabel>
              </CardRow>
            )}

            {includeAsterisk && (
              <SubLabel>*And any other accounts included for investment advice</SubLabel>
            )}
          </StrategyCard>
        )
      }
    }
  )
)

const OngoingMonthlyContributionsCard = inject('store')(
  observer(
    class extends Component {
      render() {
        const {
          store: {
            config: { isSpendown, isRetail },
            features,
            institutional,
            accountInsight: { aggregateMonthlyContribution, sortedContributionList },
          },
        } = this.props

        return (
          <StrategyCard>
            <Label>Ongoing monthly contributions total</Label>
            <Divider />
            <CardRow>
              <Amount>{numberToDollars(aggregateMonthlyContribution, true)}</Amount>
            </CardRow>
            <Space />
            {!!aggregateMonthlyContribution && (
              <Footer>
                <HelpPersonIcon
                  size='xLarge'
                  tooltip={TooltipText.ongoingMonthlyContributions(sortedContributionList)}
                  helpLabel='Accounts contributing to'
                />
              </Footer>
            )}
            {features.enableSavingsRateUpdate &&
              institutional.rateChangeAllowed &&
              !isSpendown &&
              !isRetail && (
                <Link
                  to='/update-contributions-only'
                  css={css`
                    font-size: 14px;
                    color: #038ab7;
                    line-height: 1.35;
                  `}>
                  Change how much I'm saving only
                </Link>
              )}
          </StrategyCard>
        )
      }
    }
  )
)

class CurrentStrategy extends Component {
  render() {
    return (
      <StrategyCardContainer>
        <ProjectedIncomeCard displayInflation={this.props.displayInflation} />
        <RetirementAgeCard />
        <InvestmentRiskCard />
        <OngoingMonthlyContributionsCard />
        <StrategyCard padding='0px' marginBottom='0px' visibility='hidden' />
        <StrategyCard padding='0px' marginBottom='0px' visibility='hidden' />
      </StrategyCardContainer>
    )
  }
}

export default inject('store')(observer(CurrentStrategy))
