import { Component } from 'react'
import styled from '@emotion/styled'

import { DoughnutChart } from '../../shared'
import { Layout } from '../elements'

const ChartContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media only screen and (max-width: 450px) {
    flex-direction: column;

    div {
      display: flex;
      flex-direction: column;
    }
  }
`

const StyledLabel = styled(Layout.Header)`
  margin: auto;
  text-align: center;
  color: ${props => (props.isOld ? '#7a8396' : '#038ab7')};
`

const StyledDoughnut = styled(DoughnutChart)`
  margin: 24px auto;
`

const DisplaySmall = styled(Layout.DisplaySmall)`
  margin: 24px;
  margin-bottom: 8px;

  font-size: 24px;
  line-height: 33px;
  color: #4a4a4a;
`

const Header = styled(Layout.DisplaySmall)`
  margin: 24px;
  text-align: center;
`

const LabeledDoughnut = ({ label, ...props }) => (
  <div>
    <StyledLabel {...props}>{label}</StyledLabel>
    <StyledDoughnut {...props} />
  </div>
)

class SellingStockChart extends Component {
  state = { active: false }

  render() {
    const { companyStockNames, currentPercentage, implementPercentage } = this.props

    return (
      <div>
        <DisplaySmall>Risk will be reduced</DisplaySmall>

        <Header>Percentage of current employer plan account in {companyStockNames}</Header>

        <ChartContainer>
          <LabeledDoughnut
            height={175}
            label='Current percentage'
            isOld
            percent={currentPercentage}
          />
          <LabeledDoughnut
            height={175}
            label='If implemented'
            isOld={false}
            percent={implementPercentage}
          />
        </ChartContainer>
      </div>
    )
  }
}

export default SellingStockChart
