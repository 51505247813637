import { Component } from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import dayjs from 'dayjs'

import { SVGWrapper } from '../../../components/'
import { CircleNegative, CirclePlus } from '../../../assets/icons'

const TableHeader = styled.td`
  color: ${p => p.theme.lightestGray};
  width: ${p => (p.tooltip ? '100px' : '150px')};
  text-align: ${p => (p.align ? p.align : null)};
  white-space: ${p => (p.whitespace ? p.whitespace : null)};
  vertical-align: ${p => (p.verticalAlign ? p.verticalAlign : null)};
  cursor: ${p => (p.cursor ? p.cursor : null)};
  visibility: ${p => (p.hide ? 'hidden' : null)};
`
const Table = styled.table`
  table-layout: fixed;

  td {
    padding: 3px 6px;
  }
`
const ValueCell = styled.td`
  width: 450px;
  color: ${p => p.theme.darkestGray};
`
const StyledCell = styled.td`
  color: ${p => p.theme.darkestGray};
`
const SVGCell = styled.td`
  padding: 0;
  vertical-align: bottom;

  div {
    position: relative;
    top: 3px;
  }
`

class HistoricalDataLoginGroup extends Component {
  state = { expandedView: true }

  toggleView = () => this.setState({ expandedView: !this.state.expandedView })

  parseDate = date => {
    if (date === null) {
      return ''
    }

    return dayjs(date).format('MM/DD/YYYY') || ''
  }

  parseTime = time => {
    if (time === null) {
      return ''
    }

    const localTime = dayjs(time)
    const hour = localTime.format('HH')
    let parsedTime = localTime.format('hh:mm:ss') || ''

    if (parsedTime) {
      if (hour <= 11) {
        parsedTime = parsedTime + ' AM'
      } else {
        parsedTime = parsedTime + ' PM'
      }
    }

    return parsedTime
  }

  render() {
    const { expandedView } = this.state
    const { year, sortedLoginList } = this.props
    /* eslint-disable react/jsx-handler-names */
    return (
      <Table>
        <tbody>
          <tr>
            <SVGCell>
              <SVGWrapper
                svg={expandedView ? CircleNegative : CirclePlus}
                fill='#7a8e96'
                size='large'
                onClick={this.toggleView}
              />
            </SVGCell>
            <TableHeader
              align='right'
              whitespace='nowrap'
              verticalAlign='bottom'
              onClick={this.toggleView}
              cursor='pointer'
              tooltip>
              &nbsp;{year} Logins
            </TableHeader>
            {expandedView && <TableHeader verticalAlign='bottom'>Date</TableHeader>}
            {expandedView && <TableHeader verticalAlign='bottom'>Time</TableHeader>}
          </tr>
          {expandedView &&
            sortedLoginList.map(login => {
              return (
                <tr key={login.eventLogId}>
                  <td />
                  <StyledCell />
                  <StyledCell>{this.parseDate(login.timeStamp)}</StyledCell>
                  <ValueCell>{this.parseTime(login.timeStamp)}</ValueCell>
                </tr>
              )
            })}
        </tbody>
      </Table>
    )
  }
}

export default observer(HistoricalDataLoginGroup)
