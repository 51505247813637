import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { personalNormal } from '../assets/icons'
import { SVGWrapper } from './'
import tippy, { sticky } from 'tippy.js'
import 'tippy.js/dist/tippy.css'

const HelpLabel = styled.span`
  font-size: 0.875rem;
  line-height: 1.45;
  padding: ${p => (p.paddingLabel ? p.paddingLabel : '0px 4px')};
  text-decoration: underline;
`

const PersonalHelp = styled.span`
  display: flex;
  align-items: center;
  color: ${p => (p.active ? p.theme.whatIsAGoalActiveColor : p.theme.lightestGray)};
  cursor: pointer;

  svg {
    fill: ${p => (p.active ? p.theme.whatIsAGoalActiveColor : p.theme.lightestGray)};
  }

  &:hover {
    color: ${p => (p.active ? p.theme.whatIsAGoalActiveColor : '#053240')};
    svg {
      fill: ${p => (p.active ? p.theme.whatIsAGoalActiveColor : '#053240')};
    }
  }

  &:active {
    color: ${p => p.theme.whatIsAGoalActiveColor};
    svg {
      fill: ${p => p.theme.whatIsAGoalActiveColor};
    }
  }
`

class HelpPersonIcon extends Component {
  state = { active: false }
  showState = () => this.setState({ active: true })
  hideState = () => this.setState({ active: false })
  static defaultProps = {
    size: 'large',
  }

  static propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    helpLabel: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
  }

  componentDidMount() {
    if (this.props.tooltip) {
      tippy(this.clickable, {
        trigger: 'click focus',
        content: this.tooltip,
        theme: 'light',
        interactive: true,
        appendTo: () => document.body,
        sticky: true,
        plugins: [sticky],
        arrow: true,
        maxWidth: '550px',
        onShow: this.showState,
        onHide: this.hideState,
        placement: 'auto',
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: true,
              options: {
                flipBehavior: this.props.flipBehavior || 'flip',
              },
            },
          ],
        },
      })
    }
  }

  render() {
    const { onClick, helpLabel, size, children, tooltip } = this.props
    return (
      <PersonalHelp active={this.state.active}>
        <span
          css={css`
            display: flex;
            align-items: center;
          `}
          ref={node => {
            this.clickable = node
          }}>
          <SVGWrapper
            active={this.state.active}
            onClick={onClick}
            size={size}
            fill='#b6c0c4'
            hoverFill='#053240'
            activeFill='#0495bf'
            svg={personalNormal}
            css={css`
              & > * {
                pointer-events: none;
              }
            `}
          />
          {helpLabel && <HelpLabel onClick={onClick}>{helpLabel}</HelpLabel>}
          {children && children}
        </span>
        {tooltip && (
          <div
            style={{ display: 'block', textAlign: 'left' }}
            ref={node => {
              this.tooltip = node
            }}>
            {tooltip}
          </div>
        )}
      </PersonalHelp>
    )
  }
}

export default HelpPersonIcon
