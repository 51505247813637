import createDecorator from 'final-form-calculate'
import _ from 'lodash'
import * as yup from 'yup'

// Validation object
export const schema = yup.object().shape(
  {
    contributionEligibility: yup.boolean().nullable().required('This field is required'),
    employeeContrib: yup
      .boolean()
      .nullable()
      .when('contributionEligibility', (contributionEligibility, schema) =>
        contributionEligibility ? schema.required('This field is required') : schema
      ),
    contribMethod: yup
      .string()
      .nullable()
      .when('employeeContrib', (employeeContrib, schema) =>
        employeeContrib ? schema.required('This field is required') : schema
      ),
    pretaxSavingsRate: yup
      .number()
      .nullable()
      .min(0, 'Please enter a value between 0 and 100')
      .max(100, 'Please enter a value between 0 and 100')
      .when(['employeeContrib', 'contribMethod'], (employeeContrib, contribMethod, schema) =>
        employeeContrib && contribMethod === 'P'
          ? schema.required('This field is required')
          : schema
      )
      .when(
        ['employeeContrib', 'contribMethod', 'rothSavingsRate', 'posttaxSavingsRate'],
        (employeeContrib, contribMethod, rothSavingsRate, posttaxSavingsRate, schema) =>
          employeeContrib && contribMethod === 'P' && !rothSavingsRate && !posttaxSavingsRate
            ? schema.min(1, 'Please enter an amount')
            : schema
      ),
    preTaxSavingsAmt: yup
      .number()
      .nullable()
      .min(0, 'Please enter an amount between $0 and $100,000,000')
      .max(100000000, 'Please enter an amount between $0 and $100,000,000')
      .when(['employeeContrib', 'contribMethod'], (employeeContrib, contribMethod, schema) =>
        employeeContrib && contribMethod === 'D'
          ? schema.required('This field is required')
          : schema
      )
      .when(
        ['employeeContrib', 'contribMethod', 'rothSavingsAmt', 'postTaxSavingsAmt'],
        (employeeContrib, contribMethod, rothSavingsAmt, postTaxSavingsAmt, schema) =>
          employeeContrib && contribMethod === 'D' && !rothSavingsAmt && !postTaxSavingsAmt
            ? schema.min(1, 'Please enter an amount')
            : schema
      ),
    rothContribAllowed: yup.boolean().nullable().required('This field is required'),
    rothSavingsRate: yup
      .number()
      .nullable()
      .min(0, 'Please enter a value between 0 and 100')
      .max(100, 'Please enter a value between 0 and 100')
      .when(
        ['employeeContrib', 'rothContribAllowed', 'contribMethod'],
        (employeeContrib, rothContribAllowed, contribMethod, schema) =>
          employeeContrib && rothContribAllowed && contribMethod === 'P'
            ? schema.required('This field is required')
            : schema
      )
      .when(
        [
          'employeeContrib',
          'contribMethod',
          'pretaxSavingsRate',
          'posttaxSavingsRate',
          '$isSpouseOwned',
        ],
        (
          employeeContrib,
          contribMethod,
          pretaxSavingsRate,
          posttaxSavingsRate,
          isSpouseOwned,
          schema
        ) =>
          employeeContrib &&
          contribMethod === 'P' &&
          isSpouseOwned &&
          !pretaxSavingsRate &&
          !posttaxSavingsRate
            ? schema.min(1, 'Please enter an amount')
            : schema
      ),
    rothSavingsAmt: yup
      .number()
      .nullable()
      .min(0, 'Please enter an amount between $0 and $100,000,000')
      .max(100000000, 'Please enter an amount between $0 and $100,000,000')
      .when(
        ['employeeContrib', 'rothContribAllowed', 'contribMethod'],
        (employeeContrib, rothContribAllowed, contribMethod, schema) =>
          employeeContrib && rothContribAllowed && contribMethod === 'D'
            ? schema.required('This field is required')
            : schema
      )
      .when(
        [
          'employeeContrib',
          'contribMethod',
          'preTaxSavingsAmt',
          'postTaxSavingsAmt',
          '$isSpouseOwned',
        ],
        (
          employeeContrib,
          contribMethod,
          preTaxSavingsAmt,
          postTaxSavingsAmt,
          isSpouseOwned,
          schema
        ) =>
          employeeContrib &&
          contribMethod === 'D' &&
          isSpouseOwned &&
          !preTaxSavingsAmt &&
          !postTaxSavingsAmt
            ? schema.min(1, 'Please enter an amount')
            : schema
      ),
    posttaxContribAllowed: yup.boolean().nullable().required('This field is required'),
    posttaxSavingsRate: yup
      .number()
      .nullable()
      .min(0, 'Please enter a value between 0 and 100')
      .max(100, 'Please enter a value between 0 and 100')
      .when(
        ['employeeContrib', 'posttaxContribAllowed', 'contribMethod'],
        (employeeContrib, posttaxContribAllowed, contribMethod, schema) =>
          employeeContrib && posttaxContribAllowed && contribMethod === 'P'
            ? schema.required('This field is required')
            : schema
      )
      .when(
        [
          'employeeContrib',
          'contribMethod',
          'pretaxSavingsRate',
          'rothSavingsRate',
          '$isSpouseOwned',
        ],
        (
          employeeContrib,
          contribMethod,
          pretaxSavingsRate,
          rothSavingsRate,
          isSpouseOwned,
          schema
        ) =>
          employeeContrib &&
          contribMethod === 'P' &&
          isSpouseOwned &&
          !pretaxSavingsRate &&
          !rothSavingsRate
            ? schema.min(1, 'Please enter an amount')
            : schema
      ),
    postTaxSavingsAmt: yup
      .number()
      .nullable()
      .min(0, 'Please enter an amount between $0 and $100,000,000')
      .max(100000000, 'Please enter an amount between $0 and $100,000,000')
      .when(
        ['employeeContrib', 'posttaxContribAllowed', 'contribMethod'],
        (employeeContrib, posttaxContribAllowed, contribMethod, schema) =>
          employeeContrib && posttaxContribAllowed && contribMethod === 'D'
            ? schema.required('This field is required')
            : schema
      )
      .when(
        [
          'employeeContrib',
          'contribMethod',
          'preTaxSavingsAmt',
          'rothSavingsAmt',
          '$isSpouseOwned',
        ],
        (employeeContrib, contribMethod, preTaxSavingsAmt, rothSavingsAmt, isSpouseOwned, schema) =>
          employeeContrib &&
          contribMethod === 'D' &&
          isSpouseOwned &&
          !preTaxSavingsAmt &&
          !rothSavingsAmt
            ? schema.min(1, 'Please enter an amount')
            : schema
      ),
  },
  [
    ['pretaxSavingsRate', 'rothSavingsRate'],
    ['pretaxSavingsRate', 'posttaxSavingsRate'],
    ['rothSavingsRate', 'posttaxSavingsRate'],
    ['preTaxSavingsAmt', 'rothSavingsAmt'],
    ['preTaxSavingsAmt', 'postTaxSavingsAmt'],
    ['rothSavingsAmt', 'postTaxSavingsAmt'],
  ]
)

// The keys to patch to API
export const accountTypeAllowedKeys = {
  '401a': [
    'contributionEligibility',
    'employeeContrib',
    'contribMethod',
    'pretaxSavingsRate',
    'preTaxSavingsAmt',
    'posttaxContribAllowed',
    'posttaxSavingsRate',
    'postTaxSavingsAmt',
  ],
  '401k': [
    'contributionEligibility',
    'employeeContrib',
    'contribMethod',
    'pretaxSavingsRate',
    'preTaxSavingsAmt',
    'rothContribAllowed',
    'rothSavingsRate',
    'rothSavingsAmt',
    'posttaxContribAllowed',
    'posttaxSavingsRate',
    'postTaxSavingsAmt',
  ],
  '403b': [
    'contributionEligibility',
    'employeeContrib',
    'contribMethod',
    'pretaxSavingsRate',
    'preTaxSavingsAmt',
    'rothContribAllowed',
    'rothSavingsRate',
    'rothSavingsAmt',
    'posttaxContribAllowed',
    'posttaxSavingsRate',
    'postTaxSavingsAmt',
  ],
  '457': [
    'contributionEligibility',
    'employeeContrib',
    'contribMethod',
    'pretaxSavingsRate',
    'preTaxSavingsAmt',
    'rothContribAllowed',
    'rothSavingsRate',
    'rothSavingsAmt',
    'posttaxContribAllowed',
    'posttaxSavingsRate',
    'postTaxSavingsAmt',
  ],
  'keogh': [
    'contributionEligibility',
    'employeeContrib',
    'contribMethod',
    'pretaxSavingsRate',
    'preTaxSavingsAmt',
    'posttaxContribAllowed',
    'posttaxSavingsRate',
    'postTaxSavingsAmt',
  ],
  'other-asset': [
    'contributionEligibility',
    'employeeContrib',
    'contribMethod',
    'pretaxSavingsRate',
    'preTaxSavingsAmt',
    'rothContribAllowed',
    'rothSavingsRate',
    'rothSavingsAmt',
    'posttaxContribAllowed',
    'posttaxSavingsRate',
    'postTaxSavingsAmt',
  ],
  'payroll-deduct-ira': [
    'contributionEligibility',
    'employeeContrib',
    'contribMethod',
    'pretaxSavingsRate',
    'preTaxSavingsAmt',
    'rothContribAllowed',
    'rothSavingsRate',
    'rothSavingsAmt',
    'posttaxContribAllowed',
    'posttaxSavingsRate',
    'postTaxSavingsAmt',
  ],
  'sep-ira': [
    'contributionEligibility',
    'employerContrib',
    'contribMethod',
    'pretaxSavingsRate',
    'preTaxSavingsAmt',
  ],
  'simple-ira': [
    'contributionEligibility',
    'employeeContrib',
    'contribMethod',
    'pretaxSavingsRate',
    'preTaxSavingsAmt',
    'rothContribAllowed',
    'rothSavingsRate',
    'rothSavingsAmt',
    'posttaxContribAllowed',
    'posttaxSavingsRate',
    'postTaxSavingsAmt',
  ],
}

// Calculate the next step
export const accountTypeNextStep = {
  '401a': 'employer-contributions',
  '401k': 'employer-contributions',
  '403b': 'employer-contributions',
  '457': 'investments-intro',
  'keogh': 'employer-contributions',
  'payroll-deduct-ira': 'investments-intro',
  'sep-ira': 'investments-intro',
  'simple-ira': 'employer-contributions',
}

export const calculator = createDecorator(
  // Reset Contributing value if Eligibility is false
  {
    field: 'contributionEligibility',
    updates: {
      employeeContrib: (contributionEligibility, allValues) =>
        contributionEligibility === false ? false : allValues.employeeContrib,
    },
  },

  // Reset Contribution method and pre-tax savings rate and amount when Contributing value is false
  {
    field: 'employeeContrib',
    updates: {
      contribMethod: (employeeContrib, allValues) =>
        employeeContrib === false && allValues.contribMethod === null
          ? 'P'
          : allValues.contribMethod,
      pretaxSavingsRate: (employeeContrib, allValues) =>
        employeeContrib === false ? 0 : allValues.pretaxSavingsRate,
      preTaxSavingsAmt: (employeeContrib, allValues) =>
        employeeContrib === false ? 0 : allValues.preTaxSavingsAmt,
    },
  },

  // Reset corresponding savings rates or amounts when the Contribution Method changes
  {
    field: 'contribMethod',
    updates: {
      pretaxSavingsRate: (contribMethod, allValues) =>
        contribMethod === 'D' ? 0 : allValues.pretaxSavingsRate,
      preTaxSavingsAmt: (contribMethod, allValues) =>
        contribMethod === 'P' ? 0 : allValues.preTaxSavingsAmt,
      rothSavingsRate: (contribMethod, allValues) =>
        contribMethod === 'D' ? 0 : allValues.rothSavingsRate,
      rothSavingsAmt: (contribMethod, allValues) =>
        contribMethod === 'P' ? 0 : allValues.rothSavingsAmt,
      posttaxSavingsRate: (contribMethod, allValues) =>
        contribMethod === 'D' ? 0 : allValues.posttaxSavingsRate,
      postTaxSavingsAmt: (contribMethod, allValues) =>
        contribMethod === 'P' ? 0 : allValues.postTaxSavingsAmt,
    },
  },

  // Set Roth savings rate and amount to zero when Roth is declared not allowed
  {
    field: 'rothContribAllowed',
    updates: {
      rothSavingsRate: (rothContribAllowedValue, allValues) =>
        rothContribAllowedValue === false ? 0 : allValues.rothSavingsRate,
      rothSavingsAmt: (rothContribAllowedValue, allValues) =>
        rothContribAllowedValue === false ? 0 : allValues.rothSavingsAmt,
    },
  },

  // Set after-tax savings rate and amount to zero when after-tax is declared not allowed
  {
    field: 'posttaxContribAllowed',
    updates: {
      posttaxSavingsRate: (posttaxContribAllowedValue, allValues) =>
        posttaxContribAllowedValue === false ? 0 : allValues.posttaxSavingsRate,
      postTaxSavingsAmt: (posttaxContribAllowedValue, allValues) =>
        posttaxContribAllowedValue === false ? 0 : allValues.postTaxSavingsAmt,
    },
  }
)

export const createEditable = ({
  accountType,
  planType,
  isPrimaryOwned,
  control,
  institutionalContribMethod,
}) => {
  const allowedKeys = accountTypeAllowedKeys[accountType]
  const is403b = planType === 2
  const is457 = planType === 4

  // Combination of account type specific rules, account-control API results, primary/spouse owner, special 457 and 403b cases
  const contributionEligibilityIsEditable =
    _.includes(allowedKeys, 'contributionEligibility') && control.paycheckContribution
  const employeeContribIsEditable =
    _.includes(allowedKeys, 'employeeContrib') && control.paycheckContribution
  const contribMethodIsEditable =
    _.includes(allowedKeys, 'contribMethod') &&
    !(isPrimaryOwned && (is403b || is457) && institutionalContribMethod)
  const pretaxSavingsRateIsEditable = _.includes(allowedKeys, 'pretaxSavingsRate')
  const preTaxSavingsAmtIsEditable = _.includes(allowedKeys, 'preTaxSavingsAmt')
  const rothContribAllowedIsEditable = _.includes(allowedKeys, 'rothContribAllowed')
  const rothSavingsRateIsEditable = _.includes(allowedKeys, 'rothSavingsRate')
  const rothSavingsAmtIsEditable = _.includes(allowedKeys, 'rothSavingsAmt')
  const posttaxContribAllowedIsEditable = _.includes(allowedKeys, 'posttaxContribAllowed')
  const posttaxSavingsRateIsEditable = _.includes(allowedKeys, 'posttaxSavingsRate')
  const postTaxSavingsAmtIsEditable = _.includes(allowedKeys, 'postTaxSavingsAmt')

  return {
    contributionEligibilityIsEditable,
    employeeContribIsEditable,
    contribMethodIsEditable,
    pretaxSavingsRateIsEditable,
    preTaxSavingsAmtIsEditable,
    rothContribAllowedIsEditable,
    rothSavingsRateIsEditable,
    rothSavingsAmtIsEditable,
    posttaxContribAllowedIsEditable,
    posttaxSavingsRateIsEditable,
    postTaxSavingsAmtIsEditable,
  }
}

// Initialize Form Values after fetching account information
export const initializeValues = ({
  editable,
  account,
  control,
  institutionalContribMethod,
  editDrawer,
}) => {
  let {
    contributionEligibility,
    contribMethod,
    pretaxSavingsRate,
    preTaxSavingsAmt,
    rothContribAllowed,
    rothSavingsRate,
    rothSavingsAmt,
    posttaxContribAllowed,
    posttaxSavingsRate,
    postTaxSavingsAmt,
    employeeContrib,
  } = account

  if (!control.paycheckContribution) {
    contributionEligibility = false
  }

  if (!editable.pretaxSavingsRateIsEditable || !pretaxSavingsRate) {
    pretaxSavingsRate = 0
  }

  if (!editable.preTaxSavingsAmtIsEditable || !preTaxSavingsAmt) {
    preTaxSavingsAmt = 0
  }

  if (!editable.rothContribAllowedIsEditable) {
    rothContribAllowed = false
  }

  if (!editable.rothSavingsRateIsEditable || !rothSavingsRate) {
    rothSavingsRate = 0
  }

  if (!editable.rothSavingsAmtIsEditable || !rothSavingsAmt) {
    rothSavingsAmt = 0
  }

  if (!editable.posttaxContribAllowedIsEditable) {
    posttaxContribAllowed = false
  }

  if (!editable.posttaxSavingsRateIsEditable || !posttaxSavingsRate) {
    posttaxSavingsRate = 0
  }

  if (!editable.postTaxSavingsAmtIsEditable || !postTaxSavingsAmt) {
    postTaxSavingsAmt = 0
  }

  return {
    contributionEligibility,
    employeeContrib: editDrawer ? employeeContrib : !control.paycheckContribution ? false : null,
    contribMethod: institutionalContribMethod === 'B' ? null : contribMethod,
    pretaxSavingsRate,
    preTaxSavingsAmt,
    rothContribAllowed,
    rothSavingsRate,
    rothSavingsAmt,
    posttaxContribAllowed,
    posttaxSavingsRate,
    postTaxSavingsAmt,
  }
}

// This adds up the savings rates or amounts to see if we should display an error field
export const showZeroContributionsError = (editable, values, touched) => {
  const valuesToCheck = {
    pretaxSavingsRate: editable().pretaxSavingsRateIsEditable,
    rothSavingsRate: editable().rothSavingsRateIsEditable,
    posttaxSavingsRate: editable().posttaxSavingsRateIsEditable,
    preTaxSavingsAmt: editable().preTaxSavingsAmtIsEditable,
    rothSavingsAmt: editable().rothSavingsAmtIsEditable,
    postTaxSavingsAmt: editable().postTaxSavingsAmtIsEditable,
  }

  const percentTotal = values.pretaxSavingsRate + values.rothSavingsRate + values.posttaxSavingsRate
  const dollarTotal = values.preTaxSavingsAmt + values.rothSavingsAmt + values.postTaxSavingsAmt

  const showPercentError =
    percentTotal === 0 &&
    values.contribMethod === 'P' &&
    (valuesToCheck.pretaxSavingsRate ? touched.pretaxSavingsRate : true) &&
    (valuesToCheck.rothSavingsRate ? touched.rothSavingsRate : true) &&
    (valuesToCheck.posttaxSavingsRate ? touched.posttaxSavingsRate : true)

  const showDollarError =
    dollarTotal === 0 &&
    values.contribMethod === 'D' &&
    (valuesToCheck.preTaxSavingsAmt ? touched.preTaxSavingsAmt : true) &&
    (valuesToCheck.rothSavingsAmt ? touched.rothSavingsAmt : true) &&
    (valuesToCheck.postTaxSavingsAmt ? touched.postTaxSavingsAmt : true)

  return values.employeeContrib === true && (showPercentError || showDollarError)
}
