import { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { Spacer } from '../../../components'
import { Page, PageHeader } from '../elements'
import { ContentSubHeader, ContentText, FootnoteText } from '../elements/styled'

const createConfig = ({ features, config, account, isOnTrack }) => {
  switch (config.templateId) {
    // FRS Investment
    // FRS Pension Only
    case 1:
    case 2:
      return {
        investmentContent:
          'A big part of getting you on track to meet your goals is to ensure that you have the right mix of investments working for you. GuidedChoice recommends a personalized mix of investments for your employer plan account, tailored to you to help you reach your goals while minimizing the volatility (the ups and downs) of your account.',
        footNoteContent:
          'Please be aware: While we provide percentages and dollar amounts associated with this and any other recommended investment mix, the dollar amounts are based on the account balance in our database the day this guide is produced. Because investment balances shift, when implementing our advice, more often than not, percentages will be required.',
      }

    // McDonald's (foundation)
    // Atmos Energy
    // Cascade Sawing & Drilling
    // GuidedChoice
    // Paychex
    case 3:
    case 4:
    case 5:
    case 6:
    case 8:
      return {
        investmentContent: `A big part of ${
          isOnTrack ? 'keeping' : 'getting'
        } you on track to meet your goals is to ensure that you have the right mix of investments working for you. GuidedChoice recommends a personalized mix of investments for your employer plan account, tailored to you to help you reach your goals while minimizing the volatility (the ups and downs) of your account. And as a managed account holder, GuidedChoice rebalances your account for you, automatically, every three months to help keep your investments on track.
          `,
        footNoteContent: `Please be aware: While we provide percentages and dollar amounts associated with this ${
          features.outsideAdviceEnabled ? 'and any other' : ''
        } recommended investment mix, the dollar amounts are based on the account balance in our database the day this guide is produced. Because investment balances shift, when implementing our advice, more often than not, percentages will be required.`,
      }

    // NXP, has one-time advice status tracking
    case 7:
      return {
        investmentContent: `A big part of ${
          isOnTrack ? 'keeping' : 'getting'
        } you on track to meet your goals is to ensure that you have the right mix of investments working for you. GuidedChoice recommends a personalized mix of investments for your employer plan account, tailored to you to help you reach your goals while minimizing the volatility (the ups and downs) of your account. ${
          account.managedAccount
            ? 'And as a managed account holder, GuidedChoice rebalances your account for you, automatically, every three months to help keep your investments on track.'
            : 'Through opting to use your one-time advice implementation options, we have sent investment changes, but it is up to you to remember to rebalance and reoptimize your investment allocation regularly to keep your investments on track.'
        }
            `,
        footNoteContent: `Please be aware: While we provide percentages and dollar amounts associated with this ${
          features.outsideAdviceEnabled ? 'and any other' : ''
        } recommended investment mix, the dollar amounts are based on the account balance in our database the day this guide is produced. Because investment balances shift, when implementing our advice, more often than not, percentages will be required.`,
      }

    // Schwab Legacy
    case 9:
      return {
        investmentContent: `A big part of ${
          isOnTrack ? 'keeping' : 'getting'
        } you on track to meet your goals is to ensure that you have the right mix of investments working for you. GuidedChoice recommends a personalized mix of investments for your employer plan account, tailored to you to help you reach your goals while minimizing the volatility (the ups and downs) of your account. And as a point-in-time advice client, GuidedChoice rebalances your account for you, automatically, every year to help keep your investments on track.
              `,
        footNoteContent: `Please be aware: While we provide percentages and dollar amounts associated with this ${
          features.outsideAdviceEnabled ? 'and any other' : ''
        } recommended investment mix, the dollar amounts are based on the account balance in our database the day this guide is produced. Because investment balances shift, when implementing our advice, more often than not, percentages will be required.`,
      }

    // Schwab SIA
    case 10:
      return {
        investmentContent: `A big part of ${
          isOnTrack ? 'keeping' : 'getting'
        } you on track to meet your goals is to ensure that you have the right mix of investments working for you. GuidedChoice recommends a personalized mix of investments for your employer plan account, tailored to you to help you reach your goals while minimizing the volatility (the ups and downs) of your account. ${
          features.autoRiskAdjustEnabled && account.autoRiskLevelAdjustmentStatus
            ? 'And as a managed account holder who has selected the automatic risk level adjustment service, not only does GuidedChoice rebalance your account for you every three months, but also adjusts your risk level to help keep your investments on track.'
            : 'And as a managed account holder, GuidedChoice rebalances your account for you, automatically, every three months to help keep your investments on track.'
        }
                `,
        footNoteContent: `Please be aware: While we provide percentages and dollar amounts associated with this ${
          features.outsideAdviceEnabled ? 'and any other' : ''
        } recommended investment mix, the dollar amounts are based on the account balance in our database the day this guide is produced. Because investment balances shift, when implementing our advice, more often than not, percentages will be required.`,
      }

    // Retail
    case 19:
      return {
        investmentContent: `A big part of ${
          isOnTrack ? 'keeping' : 'getting'
        } you on track to meet your goals is to ensure that you have the right mix of investments working for you. GuidedChoice recommends a personalized mix of investments for all the investment accounts for which you 
        elected to include investment advice, tailored to help you reach your goals while minimizing the volatility (the ups and downs) of your account.
              `,
        secondParagraph: `Over time, balances shift from the initial investment mix selected. 
        We suggest returning to the GuidedChoice Advisory Service every quarter (or at least annually) for rebalance advice to help keep your investment allocation on track.`,
        footNoteContent: `Please be aware: While we provide percentages and dollar amounts associated with any recommended investment mix, the dollar amounts are based on the account balance in our database the day this guide is produced. Because investment balances shift, when implementing our advice, more often than not, percentages will be required.`,
      }
    default:
      return null
  }
}

class HoldingPage2 extends Component {
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'holding2' })
  }

  render() {
    const page = this.props.guide.guidePages.pages.findByName('holding2')
    const {
      active,
      features,
      config,
      account,
      acceptedCase: { isOnTrack },
    } = this.props.store

    const pageConfig = createConfig({ features, config, account, isOnTrack })
    const { isRetail } = config

    return active && active.balance ? (
      <Page header={<PageHeader text='Your Plan' />} page={page}>
        <ContentSubHeader>YOUR INVESTMENT MIX</ContentSubHeader>

        <Spacer space='4px' />

        <ContentText>{pageConfig.investmentContent}</ContentText>

        {isRetail && (
          <>
            <Spacer space='10px' />
            <ContentText>{pageConfig.secondParagraph}</ContentText>
          </>
        )}

        <Spacer space='12px' />

        <FootnoteText>{pageConfig.footNoteContent}</FootnoteText>
      </Page>
    ) : null
  }
}

export default inject('store', 'guide')(observer(HoldingPage2))
