/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import _ from 'lodash'
import createDecorator from 'final-form-calculate'
import { editSchema as schema } from '../annuity/AnnuityUtils'
import { reduceValidationError } from '../../../utils'

import {
  HelpIcon,
  Spacer,
  TooltipText,
  WizardStep,
  ButtonInput,
  TextErrorField,
  Dropdown,
  CurrencyInput,
  PercentInput,
  FormError,
} from '../../../components'
import { Page } from './style'

const calculator = createDecorator(
  {
    field: 'beginYear',
    updates: {
      paymentStartAge: (beginYear, allValues) => {
        return allValues.age + beginYear - allValues.currentYear
      },
    },
  },
  {
    field: 'paymentStartAge',
    updates: {
      beginYear: (paymentStartAge, allValues) =>
        allValues.currentYear + paymentStartAge - allValues.age,
    },
  },
  {
    field: 'endYear',
    updates: {
      paymentEndAge: (endYear, allValues) => allValues.age + endYear - allValues.currentYear,
    },
  },
  {
    field: 'paymentEndAge',
    updates: {
      endYear: (paymentEndAge, allValues) => allValues.currentYear + paymentEndAge - allValues.age,
    },
  }
)

function EditAnnuity(props) {
  const {
    store: {
      person: { maritalStatus, includeSpouse, age, id },
      spouse,
    },
    account: {
      account,
      account: { setAnnuity, participantName },
    },
    history,
  } = props

  const beginYear = () => {
    const endYear = 120 - (account.personId === id ? age : spouse?.age)
    const arr = []
    for (let i = new Date().getFullYear(); i <= new Date().getFullYear() + endYear; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }
  const paymentStartAge = () => {
    const arr = []
    for (let i = account.personId === id ? age : spouse?.age; i <= 120; i++) {
      arr.push({ label: i, value: i })
    }
    return arr
  }

  const onSubmit = async values => {
    try {
      await setAnnuity({
        ...values,
        id: _.get(account, 'id', null),
        personId: _.get(account, 'personId', null),
        colaAmtRate: values.colaWithCpi === true ? null : values.colaAmtRate,
        colaWithCpi: values.colaWithCpi === 'None of these apply' ? false : values.colaWithCpi,
      })
      return history.push('/accounts')
    } catch (err) {
      console.error(err)
      return {
        [FORM_ERROR]:
          'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
      }
    }
  }
  const initialValues = {
    age: account.personId === id ? age : spouse.age,
    currentYear: new Date().getFullYear(),
    annuityName: _.get(account, 'annuityName', null),
    annuityStartAge: _.get(account, 'annuityStartAge', null),
    beginYear: _.get(account, 'beginYear', null),
    colaAmtRate: _.get(account, 'colaAmtRate', null),
    colaWithCpi:
      _.get(account, 'colaWithCpi', null) === false && _.get(account, 'colaAmtRate', null) === null
        ? 'None of these apply'
        : _.get(account, 'colaWithCpi', null),
    cost: _.get(account, 'cost', null),
    distOptionName: _.get(account, 'distOptionName', null),
    endYear: _.get(account, 'endYear', null),
    futureCost: _.get(account, 'futureCost', null),
    monthlyIncome: _.get(account, 'monthlyIncome', null),
    survivorFraction: _.get(account, 'survivorFraction', null),
    taxtype: _.get(account, 'taxtype', null),
  }

  const validate = values => {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true, submitError: true, values: true, touched: true }}
      validate={validate}
      decorators={[calculator]}
      render={({ handleSubmit, values, submitError, submitting }) => (
        <WizardStep
          onBackClick={props.handleCancel}
          onNextClick={handleSubmit}
          backButtonText='Cancel'
          nextButtonText='Save'
          loading={submitting}
          serverError={submitError}>
          <Page.Row>
            <Page.LightText>
              What is the distribution type of the fixed annuity?&nbsp;
              <HelpIcon tooltip={TooltipText.annuityDistribution()} />
            </Page.LightText>
            &nbsp;&nbsp;
          </Page.Row>

          {maritalStatus && includeSpouse ? (
            <Field
              name='distOptionName'
              subscription={{ value: true, touched: true, error: true }}
              render={({ input, meta }) => (
                <div>
                  <Page.DistributionTypeButtonContainer>
                    <ButtonInput
                      name={input.name}
                      value='Single Life'
                      isActive={input.value === 'Single Life'}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='Single Life'
                    />
                    <ButtonInput
                      name={input.name}
                      value='Joint and Survivor'
                      isActive={input.value === 'Joint and Survivor'}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='Joint and Survivor'
                    />
                    <ButtonInput
                      name={input.name}
                      value='Joint Life'
                      isActive={input.value === 'Joint Life'}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='Joint Life'
                    />
                  </Page.DistributionTypeButtonContainer>

                  <Page.DistributionTypeButtonContainer>
                    <ButtonInput
                      name={input.name}
                      value='Life with Period Certain'
                      isActive={input.value === 'Life with Period Certain'}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='Life with Period Certain'
                      width='225px'
                    />
                    <ButtonInput
                      name={input.name}
                      value='Period Certain'
                      isActive={input.value === 'Period Certain'}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='Period Certain'
                      width='225px'
                    />
                  </Page.DistributionTypeButtonContainer>
                  <div>
                    <TextErrorField showError={meta.touched} error={meta.error} />
                  </div>
                </div>
              )}
            />
          ) : (
            <Field
              name='distOptionName'
              subscription={{ value: true, touched: true, error: true }}
              render={({ input, meta }) => (
                <div>
                  <Page.DistributionTypeButtonContainer width='624px'>
                    <ButtonInput
                      name={input.name}
                      value='Single Life'
                      isActive={input.value === 'Single Life'}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='Single Life'
                      width='208px'
                    />
                    <ButtonInput
                      name={input.name}
                      value='Life with Period Certain'
                      isActive={input.value === 'Life with Period Certain'}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='Life with Period Certain'
                      width='208px'
                    />
                    <ButtonInput
                      name={input.name}
                      value='Period Certain'
                      isActive={input.value === 'Period Certain'}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      onClick={(name, value) => input.onChange(value)}
                      text='Period Certain'
                      width='208px'
                    />
                  </Page.DistributionTypeButtonContainer>
                  <div>
                    <TextErrorField showError={meta.touched} error={meta.error} />
                  </div>
                </div>
              )}
            />
          )}

          <Spacer space='20px' />
          <Page.LightText>
            What is the payment start year? Or at what age will {participantName} be when payment
            begins?
          </Page.LightText>
          <Page.PaymentRow>
            <Page.LightText>Year &nbsp;</Page.LightText>
            <Field
              name='beginYear'
              subscription={{ value: true, touched: true, error: true }}
              render={({ input, meta }) => (
                <Dropdown
                  error={meta.error}
                  name={input.name}
                  onBlur={input.onBlur}
                  onChange={(name, value) => input.onChange(value)}
                  options={beginYear()}
                  placeholder=''
                  selected={input.value}
                  showError={meta.touched}
                />
              )}
            />
            <Page.LightText>&nbsp;&nbsp;or age&nbsp;</Page.LightText>
            <Field
              name='paymentStartAge'
              subscription={{ value: true, touched: true, error: true }}
              render={({ input, meta }) => (
                <Dropdown
                  error={meta.error}
                  name={input.name}
                  onBlur={input.onBlur}
                  onChange={(name, value) => input.onChange(value)}
                  options={paymentStartAge()}
                  placeholder=''
                  selected={input.value}
                  showError={meta.touched}
                />
              )}
            />
          </Page.PaymentRow>

          {(_.get(values, 'distOptionName', null) === 'Life with Period Certain' ||
            _.get(values, 'distOptionName', null) === 'Period Certain') && (
            <>
              <Spacer space='20px' />
              <Page.LightText>
                What is the payment end year? Or at what age will {participantName} be when payment
                ends?
              </Page.LightText>
              <Page.PaymentRow>
                <Page.LightText>Year &nbsp;</Page.LightText>
                <Field
                  name='endYear'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <Dropdown
                      error={meta.error}
                      name={input.name}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      options={beginYear()}
                      placeholder=''
                      selected={input.value}
                      showError={meta.touched}
                    />
                  )}
                />
                <Page.LightText>&nbsp;&nbsp;or age&nbsp;</Page.LightText>
                <Field
                  name='paymentEndAge'
                  subscription={{ value: true, touched: true, error: true }}
                  render={({ input, meta }) => (
                    <Dropdown
                      error={meta.error}
                      name={input.name}
                      onBlur={input.onBlur}
                      onChange={(name, value) => input.onChange(value)}
                      options={paymentStartAge()}
                      placeholder=''
                      selected={input.value}
                      showError={meta.touched}
                    />
                  )}
                />
              </Page.PaymentRow>
            </>
          )}

          <Spacer space='20px' />
          <Page.LightText>What is the monthly payment?</Page.LightText>
          <Field
            name='monthlyIncome'
            format={value => (value === null ? undefined : value)}
            parse={v => v}
            render={({ input, meta }) => (
              <CurrencyInput
                name={input.name}
                value={input.value}
                placeholder='0'
                onChange={(name, value) => input.onChange(value)}
                onBlur={input.onBlur}
                error={meta.error}
                showError={meta.touched}
                decimalScale={0}
                width='125px'
                css={css`
                  display: inline-block;
                  margin: 0px;
                  padding: 0px;
                  min-height: 0px;
                `}
              />
            )}
          />

          {(_.get(values, 'distOptionName', null) === 'Joint and Survivor' ||
            _.get(values, 'distOptionName', null) === 'Joint Life') && (
            <>
              <Spacer space='20px' />
              <Page.LightText>What is the survivor benefit?</Page.LightText>
              <Field
                name='survivorFraction'
                format={value => (value === null ? undefined : value)}
                parse={v => v}
                render={({ input, meta }) => (
                  <PercentInput
                    name={input.name}
                    value={input.value === '' ? null : input.value}
                    onChange={(name, value) => input.onChange(value)}
                    onBlur={input.onBlur}
                    error={meta.error}
                    showError={meta.touched}
                    placeholder='0'
                    width='80px'
                    css={css`
                      display: inline-block;
                      margin: 0px;
                      padding: 0px;
                      min-height: 0px;
                    `}
                    isAllowed={({ floatValue }) => floatValue <= 100}
                  />
                )}
              />
            </>
          )}

          <Spacer space='20px' />
          <Page.Row>
            <Page.LightText>
              Do any of the following inflation protection features apply?&nbsp;
              <HelpIcon tooltip={TooltipText.annuityInflation()} />
            </Page.LightText>
          </Page.Row>
          <Spacer space='3px' />
          <Field
            name='colaWithCpi'
            subscription={{ value: true, touched: true, error: true }}
            render={({ input, meta }) => (
              <div>
                <Page.DistributionTypeButtonContainer width='780px'>
                  <ButtonInput
                    name={input.name}
                    value={false}
                    isActive={input.value === false}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    onClick={(name, value) => input.onChange(value)}
                    text='Fixed annual increases'
                    width='260px'
                  />
                  <ButtonInput
                    name={input.name}
                    value
                    isActive={input.value === true}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    onClick={(name, value) => input.onChange(value)}
                    text='Inflation-indexed or COLA'
                    width='260px'
                  />
                  <ButtonInput
                    name={input.name}
                    value='None of these apply'
                    isActive={input.value === 'None of these apply'}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    onClick={(name, value) => input.onChange(value)}
                    text='None of these apply'
                    width='260px'
                  />
                </Page.DistributionTypeButtonContainer>
                <div>
                  <TextErrorField showError={meta.touched} error={meta.error} />
                </div>
              </div>
            )}
          />

          {_.get(values, 'colaWithCpi', null) === false && (
            <>
              <Spacer space='3px' />
              <Page.AnnualRateIncreaseWrapper>
                <Page.LightText>What is the annual rate increase?</Page.LightText>&nbsp;&nbsp;
                <Field
                  name='colaAmtRate'
                  format={value => (value === null ? undefined : value)}
                  parse={v => v}
                  render={({ input, meta }) => (
                    <PercentInput
                      name={input.name}
                      value={input.value === '' ? null : input.value}
                      onChange={(name, value) => input.onChange(value)}
                      onBlur={input.onBlur}
                      error={meta.error}
                      showError={meta.touched}
                      placeholder='0'
                      width='80px'
                      css={css`
                        display: inline-block;
                        margin: 0px;
                        padding: 0px;
                        min-height: 0px;
                      `}
                      decimalScale={3}
                    />
                  )}
                />
              </Page.AnnualRateIncreaseWrapper>
            </>
          )}

          {_.get(values, 'colaWithCpi', null) === false &&
          values.colaAmtRate &&
          values.colaAmtRate > 4 ? (
            <div
              css={css`
                color: red;
              `}>
              The annual rate you've entered seems quite high. Please double check to make certain
              you have entered it correctly.
            </div>
          ) : null}

          <Spacer space='20px' />
          <Page.Row>
            <Page.LightText>
              Did you pay cash for this annuity or did you convert money from a retirement account?
            </Page.LightText>
          </Page.Row>

          <Field
            name='taxtype'
            subscription={{ value: true, touched: true, error: true }}
            render={({ input, meta }) => (
              <div>
                <Page.DistributionTypeButtonContainer width='416px'>
                  <ButtonInput
                    name={input.name}
                    value={0}
                    isActive={input.value === 0}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    onClick={(name, value) => input.onChange(value)}
                    text='Cash'
                    width='208px'
                  />
                  <ButtonInput
                    name={input.name}
                    value={1}
                    isActive={input.value === 1}
                    onFocus={input.onFocus}
                    onBlur={input.onBlur}
                    onClick={(name, value) => input.onChange(value)}
                    text='Converted money'
                    width='208px'
                  />
                </Page.DistributionTypeButtonContainer>
                <div>
                  <TextErrorField showError={meta.touched} error={meta.error} />
                </div>
              </div>
            )}
          />

          {_.get(values, 'taxtype', null) === 1 && (
            <>
              <Spacer space='3px' />
              <Page.Row>
                <Page.LightText>How much did you pay for your annuity?</Page.LightText> &nbsp;
                <HelpIcon tooltip={TooltipText.costOfAnnuity()} />
              </Page.Row>
              <Field
                name='cost'
                format={value => (value === null ? undefined : value)}
                parse={v => v}
                render={({ input, meta }) => (
                  <CurrencyInput
                    name={input.name}
                    value={input.value}
                    placeholder='0'
                    onChange={(name, value) => input.onChange(value)}
                    onBlur={input.onBlur}
                    error={meta.error}
                    showError={meta.touched}
                    decimalScale={0}
                    width='125px'
                    css={css`
                      display: inline-block;
                      margin: 0px;
                      padding: 0px;
                      min-height: 0px;
                    `}
                  />
                )}
              />
            </>
          )}

          <Spacer space='5px' />
          {submitError && <FormError err={submitError} />}
        </WizardStep>
      )}
    />
  )
}

export default withRouter(inject('store', 'account')(observer(EditAnnuity)))
