/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import { Form, FormSpy, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'

import { API, API_URL } from '../../../api'
import { Loading } from '../../../components'
import { LargeCheckbox } from '../../../guided-toolbox/checkbox'
import { reduceValidationError } from '../../../utils'
import { Layout } from '../elements'
import GetGuideButtons from './GetGuideButtons'
import {
  Container,
  DisplayXLarge,
  Body,
  StyledSelectionBox,
  CheckboxLabel,
  StyledReceiveEmail,
  StyledSaveEmail,
  StyledConfirmEmail,
  StyledConfirmEmailInput,
} from './GetGuideStyles'

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .nullable()
    .when('sendEmail', (sendEmail, schema) =>
      sendEmail
        ? schema
            .email('Valid email is required')
            .required('Email is required to be able to send your guide')
        : schema
    ),
  sendEmail: yup.boolean(),
  saveEmail: yup.boolean(),
})

const ShowSaveEmail = ({ children }) => (
  <FormSpy
    subscription={{ values: true, dirtyFields: true }}
    children={({ values, dirtyFields }) => {
      return values.sendEmail && dirtyFields.email ? children : null
    }}
  />
)

function ConfirmEmail(props) {
  function initialValues() {
    const {
      contactInfo: { email },
    } = props.store

    return {
      email,
      sendEmail: true,
      saveEmail: false,
    }
  }

  function validate(values) {
    return schema
      .validate(values, { abortEarly: false })
      .then(valid => {})
      .catch(err => reduceValidationError(err))
  }

  function onSubmit(values) {
    if (values.sendEmail) {
      return submitEmail(values)
    } else {
      window.open(`${API_URL.slice(0, -4)}logout`, '_self')
    }
  }

  async function submitEmail(values) {
    try {
      const { acceptedCaseId, contactInfo, updateContactInfo } = props.store

      // send email for the guide and update email address (if applicable)
      const SendEmailPromise = API.post(`${API_URL}guide/email`, null, {
        params: { caseId: acceptedCaseId, email: values.email.trim() },
      })

      const UpdateEmailPromise =
        values.saveEmail && values.email.trim() !== contactInfo.email
          ? updateContactInfo({ ...getSnapshot(contactInfo), email: values.email.trim() })
          : Promise.resolve()

      await Promise.all([SendEmailPromise, UpdateEmailPromise])
      window.open(`${API_URL.slice(0, -4)}logout`, '_self')
    } catch (err) {
      console.error(err)
      return { [FORM_ERROR]: 'Oops! Something went wrong, please try again later' }
    }
  }

  function openGuide() {
    const { acceptedCaseId } = props.store
    window.open(`${API_URL}guide/generateGuide?caseId=${acceptedCaseId}`, '_blank')
  }

  const { config } = props.store

  return (
    <Container>
      <DisplayXLarge>Changes submitted—now get your guide</DisplayXLarge>
      <Body>
        Your guide contains not only the allocation we recommend for your{' '}
        {config.template.sponsorName} retirement plan account, but also any other savings and
        investment changes you'll want to make to help ensure you are able to get to the financial
        future you want.
      </Body>
      <Form
        initialValues={initialValues()}
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ touched: true, errors: true, submitting: true, submitError: true }}
        render={({ touched, errors, submitting, submitError, handleSubmit }) => {
          const submittingError = !submitting && submitError
          const termsError = !submitting && touched.email && errors.email

          return (
            <form onSubmit={handleSubmit}>
              <StyledSelectionBox>
                <StyledReceiveEmail>Receive via email?</StyledReceiveEmail>
                <StyledConfirmEmail>
                  <Field
                    name='sendEmail'
                    render={({ input, meta }) => (
                      <LargeCheckbox
                        error={meta.error}
                        name={input.name}
                        checked={input.value}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onChange={() => input.onChange(!input.value)}
                      />
                    )}
                  />
                  <Field
                    name='email'
                    render={({ input, meta }) => (
                      <StyledConfirmEmailInput
                        error={meta.error}
                        name={input.name}
                        value={input.value}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onChange={(val, evt) => input.onChange(evt)}
                      />
                    )}
                  />
                </StyledConfirmEmail>

                <ShowSaveEmail>
                  <Field
                    name='saveEmail'
                    render={({ input, meta }) => (
                      <StyledSaveEmail>
                        <LargeCheckbox
                          error={meta.error}
                          name={input.name}
                          checked={input.value}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          onChange={() => input.onChange(!input.value)}
                        />
                        <CheckboxLabel onClick={() => input.onChange(!input.value)}>
                          Save email to record
                        </CheckboxLabel>
                      </StyledSaveEmail>
                    )}
                  />
                </ShowSaveEmail>
              </StyledSelectionBox>
              <div
                css={css`
                  color: #4a606a;
                  margin: 24px;
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 1.45;
                `}>
                Be aware: Depending upon the arrangements between GuidedChoice and your plan, your
                transaction could take 24-48 hours to be reflected in your account, or as many as 10
                business days.
              </div>
              <Layout.ErrorMessage>{submittingError || termsError}</Layout.ErrorMessage>

              {submitting ? (
                <Loading />
              ) : (
                <GetGuideButtons openGuide={openGuide} handleNext={handleSubmit} />
              )}
            </form>
          )
        }}
      />
    </Container>
  )
}

export default withRouter(inject('store')(observer(ConfirmEmail)))
