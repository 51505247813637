/* eslint-disable import/no-anonymous-default-export */
import variables from '../variables'
import colors from '../colors'
import color from 'css-color-function'
import reduceCSSCalc from 'reduce-css-calc'

const {
  unit,
  type: {
    sizes: { s: small },
  },
} = variables
const {
  rt: { primary, black, grey50, grey400 },
} = colors
const switchColor = primary
const switchTotalHeight = reduceCSSCalc(`calc(2.4 * ${unit})`)
const switchFocusSize = reduceCSSCalc(`calc(${switchTotalHeight} * 2)`)
const switchFocusInitSize = reduceCSSCalc(`calc(0.8 * ${unit})`)

export default {
  color: switchColor,
  textColor: black,
  thumbOffColor: grey50,
  trackOnColor: color.convert(`color(${primary} a(50%))`),
  trackOffColor: color.convert(`color(${black} a(26%))`),
  offRippleColor: color.convert(`color(${black} a(40%))`),
  onFocusColor: color.convert(`color(${primary} a(26%))`),
  offFocusColor: color.convert(`color(${black} a(10%))`),
  disabledThumbColor: grey400,
  disabledTrackColor: color.convert(`color(${black} a(12%))`),
  disabledTextColor: color.convert(`color(${black} a(26%))`),
  totalHeight: switchTotalHeight,
  trackLength: reduceCSSCalc(`calc(3.6 * ${unit})`),
  trackHeight: reduceCSSCalc(`calc(1.4 * ${unit})`),
  thumbSize: reduceCSSCalc(`calc(2 * ${unit})`),
  thumbOnColor: switchColor,
  focusInitSize: switchFocusInitSize,
  focusSize: switchFocusSize,
  focusDiff: reduceCSSCalc(`calc((${switchFocusSize} - ${switchFocusInitSize}) / 2)`),
  rippleDuration: '650ms',
  fontSize: small,
  fieldMarginBottom: reduceCSSCalc(`calc(1.5 * ${unit})`),
}
