import { css } from '@emotion/react'
import { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'

import { Spacer } from '../../../components'
import { numberToDollars } from '../../../utils'
import { Page, PageHeader } from '../elements'
import {
  ContentMainHeader,
  ContentText,
  ListPadding,
  InvestmentsListItem,
  FlexContainer,
  StyledBar,
} from '../elements/styled'

const ChartMainLabel = styled.div`
  color: ${p => (p.old ? '#7a8e96' : '#038ab7')};
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
`

const ChartSubLabel = styled.div`
  color: ${p => p.theme.darkestGray};
  text-align: center;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: ${p => (p.bold ? 500 : null)};
  font-size: ${p => (p.bold ? '1.25rem' : '1.125rem')};
  padding: 12px 0 8px 0;
`

const TextHolder = styled.div`
  min-width: 200px;
`

const ListElement = inject('store')(
  observer(
    class extends Component {
      render() {
        const { name: accountName, annualContributionDollars } = this.props

        return (
          <InvestmentsListItem>
            <ListPadding>
              {`${accountName}: $${numberToDollars(
                annualContributionDollars,
                true
              )} each year (about $${numberToDollars(
                annualContributionDollars / 12,
                true
              )} per month)`}
            </ListPadding>
          </InvestmentsListItem>
        )
      }
    }
  )
)

class BarRow extends Component {
  render() {
    const { percentageOld, amountOld, percentageNew, amountNew } = this.props

    return (
      <FlexContainer justifyContent='center' childPadding='8px'>
        <StyledBar isGray>
          <TextHolder>
            {amountOld
              ? `${numberToDollars(percentageOld, true)}% | $${numberToDollars(
                  amountOld,
                  true
                )}/year`
              : '\u2014'}
          </TextHolder>
        </StyledBar>
        <StyledBar>
          <TextHolder>
            {amountNew
              ? `${numberToDollars(percentageNew, true)}% | $${numberToDollars(
                  amountNew,
                  true
                )}/year`
              : '\u2014'}
          </TextHolder>
        </StyledBar>
      </FlexContainer>
    )
  }
}

const ContributionSubSection457 = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props

        const {
          client457PreTaxSaving,
          client457PreTaxAmount,
          client457RothSaving,
          client457RothAmount,
          total457Savings,
          total457Amount,
        } = store.baseCase

        const {
          client457PreTaxSaving: client457PreTaxSavingCase,
          client457PreTaxAmount: client457PreTaxAmountCase,
          client457RothSaving: client457RothSavingCase,
          client457RothAmount: client457RothAmountCase,
          total457Savings: total457SavingsCase,
          total457Amount: total457AmountCase,
        } = store.acceptedCase

        const { special457 } = store

        const changed457Contributions =
          special457 &&
          (client457PreTaxSaving !== client457PreTaxSavingCase ||
            client457RothSaving !== client457RothSavingCase)

        const hasRoth = !!client457RothSaving || !!client457RothSavingCase

        if (!hasRoth && changed457Contributions) {
          return (
            <>
              <Spacer space='8px' />
              <FlexContainer justifyContent='space-around'>
                <ChartMainLabel old>OLD CONTRIBUTION</ChartMainLabel>
                <ChartMainLabel>NEW CONTRIBUTION</ChartMainLabel>
              </FlexContainer>
              <Spacer space='4px' />
              <ChartSubLabel bold>Annual pre-tax total </ChartSubLabel>
              <BarRow
                percentageOld={total457Savings}
                amountOld={total457Amount}
                percentageNew={total457SavingsCase}
                amountNew={total457AmountCase}
              />
              <Spacer space='24px' />
            </>
          )
        } else if (hasRoth && changed457Contributions) {
          return (
            <>
              <Spacer space='8px' />
              <FlexContainer justifyContent='space-around'>
                <ChartMainLabel old>OLD CONTRIBUTION</ChartMainLabel>
                <ChartMainLabel>NEW CONTRIBUTION</ChartMainLabel>
              </FlexContainer>
              <Spacer space='4px' />
              <ChartSubLabel bold>Annual total </ChartSubLabel>
              <BarRow
                percentageOld={total457Savings}
                amountOld={total457Amount}
                percentageNew={total457SavingsCase}
                amountNew={total457AmountCase}
              />
              <Spacer space='6px' />
              <ChartSubLabel bold>Pre-tax Contribution </ChartSubLabel>
              <BarRow
                percentageOld={client457PreTaxSaving}
                amountOld={client457PreTaxAmount}
                percentageNew={client457PreTaxSavingCase}
                amountNew={client457PreTaxAmountCase}
              />
              <Spacer space='6px' />
              <ChartSubLabel bold>Roth Contribution</ChartSubLabel>
              <BarRow
                percentageOld={client457RothSaving}
                amountOld={client457RothAmount}
                percentageNew={client457RothSavingCase}
                amountNew={client457RothAmountCase}
              />
              <Spacer space='24px' />
            </>
          )
        }
        return null
      }
    }
  )
)

const ContributionSubSection403b = inject('store')(
  observer(
    class extends Component {
      render() {
        const { store } = this.props

        const { client403bPreTaxSaving, total403bSavings, total403bAmount } = store.baseCase

        const {
          client403bPreTaxSaving: client403bPreTaxSavingCase,
          total403bSavings: total403bSavingsCase,
          total403bAmount: total403bAmountCase,
        } = store.acceptedCase

        const { special403b } = store

        const changed403bContributions =
          special403b && client403bPreTaxSaving !== client403bPreTaxSavingCase

        if (changed403bContributions) {
          return (
            <>
              <Spacer space='8px' />
              <FlexContainer justifyContent='space-around'>
                <ChartMainLabel old>OLD CONTRIBUTION</ChartMainLabel>
                <ChartMainLabel>NEW CONTRIBUTION</ChartMainLabel>
              </FlexContainer>
              <Spacer space='4px' />
              <ChartSubLabel bold>Annual pre-tax total </ChartSubLabel>
              <BarRow
                percentageOld={total403bSavings}
                amountOld={total403bAmount}
                percentageNew={total403bSavingsCase}
                amountNew={total403bAmountCase}
              />
              <Spacer space='24px' />
            </>
          )
        }
        return null
      }
    }
  )
)

class SavingPage2 extends Component {
  renderAdditionalSavingsText() {
    const {
      outOfPlanMonthlySavings,
      totalAnnualSavings,
      totalAnnualPercentage,
      primary,
      client457PreTaxSaving: client457PreTaxSavingCase,
      client457RothSaving: client457RothSavingCase,
      client403bPreTaxSaving: client403bPreTaxSavingCase,
    } = this.props.store.acceptedCase

    const { client457PreTaxSaving, client457RothSaving, client403bPreTaxSaving } =
      this.props.store.baseCase

    const { special457, special403b } = this.props.store

    const ongoingAnnualContributions = primary ? primary.ongoingAnnualContributions : 0

    const changed457Contributions =
      special457 &&
      (client457PreTaxSaving !== client457PreTaxSavingCase ||
        client457RothSaving !== client457RothSavingCase)

    const changed403bContributions =
      special403b && client403bPreTaxSaving !== client403bPreTaxSavingCase

    const firstSentence = `Your plan ${
      changed457Contributions || changed403bContributions ? 'also ' : ''
    } includes saving an additional $${numberToDollars(outOfPlanMonthlySavings * 12, true)}
      each year (about $${numberToDollars(
        outOfPlanMonthlySavings,
        true
      )} per month) up to and including the year you
      retire. `

    if (ongoingAnnualContributions && (totalAnnualPercentage || totalAnnualSavings)) {
      return `${firstSentence} Keep in mind, this amount is separate from contributions to a
          current employer plan account and ongoing savings to other
          accounts you told us about.`
    } else if (totalAnnualPercentage || totalAnnualSavings) {
      return `${firstSentence} Keep in mind, this amount is separate from contributions to a
          current employer plan account.`
    } else if (ongoingAnnualContributions) {
      return `${firstSentence} Keep in mind, this amount is separate from contributions to other
          accounts you told us about.`
    } else return firstSentence
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.props.guide.guidePages.pages.addPage({ name: 'savings2' })
  }

  render() {
    const page = this.props.guide.guidePages.pages.findByName('savings2')

    const { store } = this.props

    const { displayName } = store.person

    const { sortedContributionListNoEmployerSpouseBrokerage } = store.accountInsight

    const { client457PreTaxSaving, client457RothSaving, client403bPreTaxSaving } = store.baseCase

    const {
      outOfPlanMonthlySavings,
      client457PreTaxSaving: client457PreTaxSavingCase,
      client457RothSaving: client457RothSavingCase,
      client403bPreTaxSaving: client403bPreTaxSavingCase,
    } = store.acceptedCase

    const { special457, special403b } = store

    const changed457Contributions =
      special457 &&
      (client457PreTaxSaving !== client457PreTaxSavingCase ||
        client457RothSaving !== client457RothSavingCase)

    const changed403bContributions =
      special403b && client403bPreTaxSaving !== client403bPreTaxSavingCase

    const showAdditionalSavingsSection =
      !!outOfPlanMonthlySavings || changed457Contributions || changed403bContributions

    const showPage =
      !!sortedContributionListNoEmployerSpouseBrokerage.length || showAdditionalSavingsSection

    return showPage ? (
      <Page header={<PageHeader text='Other Investment Accounts' />} page={page}>
        {!!sortedContributionListNoEmployerSpouseBrokerage.length && (
          <div
            css={css`
              padding-bottom: 48px;
            `}>
            <ContentMainHeader>Ongoing savings to other accounts</ContentMainHeader>
            <Spacer space='12px' />
            <ContentText>
              Your projections take into consideration those accounts to which you've indicated you
              are saving on an ongoing basis. Should these ongoing savings change, you'll want to
              return to the Advisory Service to update your information and obtain new results.
            </ContentText>
            <Spacer space='12px' />
            <ContentText>
              <ul>
                {sortedContributionListNoEmployerSpouseBrokerage.map(account => (
                  <ListElement
                    key={account.accountName + account.accountId}
                    name={account.accountName}
                    annualContributionDollars={account.annualContributionDollars}
                  />
                ))}
              </ul>
            </ContentText>
          </div>
        )}
        {showAdditionalSavingsSection && (
          <div>
            <ContentMainHeader>
              Savings in addition to retirement & other accounts
            </ContentMainHeader>
            <Spacer space='12px' />
            {changed457Contributions && (
              <div>
                <ContentText>{`Your plan includes saving more into ${displayName}'s 457 account:`}</ContentText>
                <ContributionSubSection457 />
              </div>
            )}
            {changed403bContributions && (
              <div>
                <ContentText>{`Your plan includes saving more into ${displayName}'s 403(b) account:`}</ContentText>
                <ContributionSubSection403b />
              </div>
            )}
            {(changed457Contributions || changed403bContributions) && (
              <div
                css={css`
                  padding-bottom: 24px;
                `}>
                <ContentText>
                  Contribution amounts shown have been annualized and rounded up to the next whole
                  percentage equivalent. Therefore, while it may appear greater than the maximum
                  allowable contributions have been permitted, the GuidedChoice projections have
                  taken IRS and plan rule maximums into account.
                </ContentText>
              </div>
            )}
            {!!outOfPlanMonthlySavings && (
              <div>
                <ContentText>{this.renderAdditionalSavingsText()}</ContentText>
                <Spacer space='12px' />
                <ContentText>
                  Where to save: One option may be to increase your current savings commitment in an
                  account into which you already have a balance, especially one that is
                  tax-advantaged, such as a 403(b) plan account, 457 plan account, an Individual
                  Retirement Account (IRA), Health Savings Account, or 529 College Savings Plan.
                </ContentText>
                <Spacer space='12px' />
                <ContentText>
                  Another option may be to set up a new account for this additional savings. Check
                  with a tax advisor to find out what kind of account that might be. If it is a
                  taxable account, ask your advisor what kinds of investments would be tax efficient
                  for your needs.
                </ContentText>
              </div>
            )}
          </div>
        )}
      </Page>
    ) : null
  }
}

export default inject('store', 'guide')(observer(SavingPage2))
