const circlePlus = `
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <title>add-normal-c</title>
    <path
      class="icon-color"
      id="icn_add-normal"
      data-name="icn/add-normal"
      d="M21.6,12A9.6,9.6,0,0,0,2.4,12M0,12a12,12,0,0,1,24,0M2.4,12a9.6,9.6,0,
        0,0,19.2,0M24,12A12,12,0,0,1,0,12m11-1H6V13h5v5H13V13h5V11H13V6H11v5Z"
    />
  </svg>
`

export default circlePlus
