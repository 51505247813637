import { Component } from 'react'
import { observer, inject } from 'mobx-react'

import { Card, CardField, CardFieldError, Drawer } from '../../../components'
import { numberToDollars } from '../../../utils'
import EditContributions from './EditContributions'
import { getIraContributionType } from '../utils/accountTypeToAllowedContributions'

class EditContributionsCard extends Component {
  state = { editActive: false }

  handleEdit = () => this.setState({ editActive: true })

  handleCancel = () => this.setState({ editActive: false })

  render() {
    const {
      account: { type, planType },
      account,
    } = this.props.account

    const canEditContributions = type !== 'Institutional'
    const contributionType = getIraContributionType(planType)
    const annualContribution = account[contributionType]

    return (
      <Card title='Contributions' handleEdit={canEditContributions ? this.handleEdit : null}>
        {annualContribution !== null ? (
          <CardField
            label='Annual contribution'
            value={`$${numberToDollars(annualContribution, true)}`}
          />
        ) : (
          <CardFieldError>
            GuidedChoice does not have data on record for this account
          </CardFieldError>
        )}

        <Drawer
          active={this.state.editActive}
          title='Contributions'
          subtitle='Edit account'
          width='50%'>
          <EditContributions handleCancel={this.handleCancel} />
        </Drawer>
      </Card>
    )
  }
}

export default inject('account')(observer(EditContributionsCard))
