/* eslint-disable import/no-anonymous-default-export */
import { css, cx } from '@emotion/css'
import ds from '../theme'
import reduceCSSCalc from 'reduce-css-calc'

const bar = 'rt-input-bar'

const counter = 'rt-input-counter'

const disabled = 'rt-input-disabled'

const error = 'rt-input-error'

const errored = 'rt-input-errored'

const hidden = 'rt-input-hidden'

const hint = 'rt-input-hint'

const icon = 'rt-input-icon'

const input = 'rt-input-input'

const inputElement = 'rt-input-inputElement'

const required = 'rt-input-required'

const withIcon = 'rt-input-withIcon'

const filled = 'rt-input-filled'

const fixed = 'rt-input-fixed'

const label = 'rt-input-label'

const _input = css`
  padding: ${ds.get('input.padding')} 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  ${ds.get('reset')};

  &.${withIcon} {
    margin-left: ${reduceCSSCalc(`calc(
        ${ds.get('input.iconSize')} + ${ds.get('input.iconRightSpace')}
      )`)};
    max-width: calc(
      100% - ${reduceCSSCalc(`calc(
        ${ds.get('input.iconSize')} + ${ds.get('input.iconRightSpace')}
      )`)}
    );
  }
`

const _icon = css`
  color: ${ds.get('input.textLabelColor')};
  display: block;
  font-size: ${ds.get('input.iconFontSize')} !important;
  height: ${ds.get('input.iconSize')};
  left: ${reduceCSSCalc(`calc(
      -1 * (${ds.get('input.iconSize')} + ${ds.get('input.iconRightSpace')})
    )`)};
  line-height: ${ds.get('input.iconSize')} !important;
  position: absolute;
  text-align: center;
  top: ${ds.get('input.iconOffset')};
  transition: color ${ds.get('animation.duration')} ${ds.get('animation.curveDefault')};
  width: ${ds.get('input.iconSize')};
`

const _inputElement = css`
  background-color: ${ds.get('input.textBackgroundColor')};
  border-bottom: 1px solid ${ds.get('input.textBottomBorderColor')};
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  color: ${ds.get('input.textInputElementColor')};
  display: block;
  font-size: ${ds.get('input.fieldFontSize')};
  outline: none;
  padding: ${ds.get('input.fieldPadding')} 0;
  width: 100%;

  &:required {
    box-shadow: none;
  }

  &:focus:not([disabled]):not([readonly]) {
    & ~ .${bar}::before, & ~ .${bar}::after {
      width: 50%;
    }

    & ~ .${label}:not(.${fixed}) {
      color: ${ds.get('input.textHighlightColor')};
    }

    & ~ .${label} > .${required} {
      color: ${ds.get('input.textRequiredColor')};
    }

    & ~ .${hint} {
      display: block;
      opacity: ${ds.get('input.hintOpacity')};
    }

    & ~ .${icon} {
      color: ${ds.get('input.textHighlightColor')};
    }

    &.${filled} ~ .${hint} {
      opacity: 0;
    }
  }

  &:focus:not([disabled]):not([readonly]),
  &.${filled}, &[type='date'],
  &[type='time'] {
    & ~ .${label}:not(.${fixed}) {
      font-size: ${ds.get('input.labelFontSize')};
      top: ${ds.get('input.focusLabelTop')};
    }
  }

  &.${filled} ~ .${label}.${fixed}, &.${filled} ~ .${hint} {
    display: none;
  }
`

const _label = css`
  color: ${ds.get('input.textLabelColor')};
  font-size: ${ds.get('input.fieldFontSize')};
  left: 0;
  line-height: ${ds.get('input.fieldFontSize')};
  pointer-events: none;
  position: absolute;
  top: ${reduceCSSCalc(`calc(
      ${ds.get('input.padding')} + 1.5 * ${ds.get('input.fieldPadding')}
    )`)};
  transition-duration: ${ds.get('animation.duration')};
  transition-property: top, font-size, color;
  transition-timing-function: ${ds.get('animation.curveDefault')};

  &.${fixed} ~ .${hint} {
    display: none;
  }
`

const _hint = css`
  color: ${ds.get('input.textLabelColor')};
  font-size: ${ds.get('input.fieldFontSize')};
  left: 0;
  line-height: ${ds.get('input.fieldFontSize')};
  opacity: ${ds.get('input.hintOpacity')};
  pointer-events: none;
  position: absolute;
  top: ${reduceCSSCalc(`calc(
      ${ds.get('input.padding')} + 1.5 * ${ds.get('input.fieldPadding')}
    )`)};
  transition-duration: ${ds.get('animation.duration')};
  transition-property: opacity;
  transition-timing-function: ${ds.get('animation.curveDefault')};
`

const _bar = css`
  display: block;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    background-color: ${ds.get('input.textHighlightColor')};
    bottom: 0;
    content: '';
    height: 2px;
    position: absolute;
    transition-duration: 0.2s;
    transition-property: width, background-color;
    transition-timing-function: ${ds.get('animation.curveDefault')};
    width: 0;
  }

  &::before {
    left: 50%;
  }

  &::after {
    right: 50%;
  }
`

const _error = css`
  color: ${ds.get('input.textErrorColor')};
  font-size: ${ds.get('input.labelFontSize')};
  line-height: ${ds.get('input.underlineHeight')};
  margin-bottom: ${reduceCSSCalc(`calc(-1 * ${ds.get('input.underlineHeight')})`)};
`

const _counter = css`
  color: ${ds.get('input.textErrorColor')};
  font-size: ${ds.get('input.labelFontSize')};
  line-height: ${ds.get('input.underlineHeight')};
  margin-bottom: ${reduceCSSCalc(`calc(-1 * ${ds.get('input.underlineHeight')})`)};
  color: ${ds.get('input.textLabelColor')};
  position: absolute;
  right: 0;
`

const _disabled = css`
  & > .${inputElement} {
    border-bottom-style: dashed;
    color: ${ds.get('input.textDisabledTextColor')};
  }
`

const _errored = css`
  padding-bottom: 0;

  & > .${inputElement} {
    border-bottom-color: ${ds.get('input.textErrorColor')};
    margin-top: 1px;
  }

  & > .${counter}, & > .${label} {
    color: ${ds.get('input.textErrorColor')};
  }

  & > .${label} > .${required} {
    color: ${ds.get('input.textRequiredColor')};
  }
`

const _hidden = css`
  display: none;
`

export default {
  bar: cx(bar, _bar),
  counter: cx(counter, _counter),
  disabled: cx(disabled, _disabled),
  error: cx(error, _error),
  errored: cx(errored, _errored),
  hidden: cx(hidden, _hidden),
  hint: cx(hint, _hint),
  icon: cx(icon, _icon),
  input: cx(input, _input),
  inputElement: cx(inputElement, _inputElement),
  required,
  withIcon,
  filled,
  fixed,
  label: cx(label, _label),
}
