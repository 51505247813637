const Bullet3nUniLessons = `
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle stroke="#02769D" stroke-width="2" fill="#E7F3F9" cx="25" cy="25" r="24"/>
            <path d="M10 10h30v30H10z"/>
            <g fill-rule="nonzero">
                <path d="M16.846 20.984v8.86h.005C16.98 31.594 20.58 33 25 33c4.422 0 8.02-1.405 8.147-3.156h.009v-8.86h-16.31z" fill="#02769D"/>
                <path d="M25 28.861c-3.347-1.828-8-3.215-12-5.488 0-.296.01-.59.032-.887 4.136-1.86 8.621-3.009 11.968-4.6 3.349 1.591 7.832 2.774 11.969 4.639.02.293.031.552.031.848-3.998 2.273-8.651 3.66-12 5.488z" fill="#02607F"/>
                <path d="M25 27.977c-3.347-1.832-8-3.217-12-5.49 4-2.272 8.653-3.66 12-5.487 3.349 1.827 7.97 3.25 11.969 5.525-4 2.273-8.62 3.62-11.969 5.452z" fill="#02769D"/>
                <path d="M19.186 27.754a.263.263 0 0 1-.262-.264v-1.557c0-.097.05-.184.133-.231l5.27-3.009a.262.262 0 0 1 .36.1.267.267 0 0 1-.099.36l-5.137 2.934v1.403a.264.264 0 0 1-.265.264z" fill="#FFF"/>
                <path d="M19.18 27.49c-.385 0-.7.315-.7.705v3.931c0 .392.315.708.7.708a.705.705 0 0 0 .705-.708v-3.931a.705.705 0 0 0-.705-.705z" fill="#FFF"/>
            </g>
        </g>
    </svg>
`

export default Bullet3nUniLessons
