import { useEffect, useState } from 'react'
import { observer, inject } from 'mobx-react'
import styled from '@emotion/styled'

import { Card, Drawer } from '../../../components'
import CardField from '../../../components/CardField'
import EditLoginInfo from './EditLoginInfo'

function LoginCard({
  store: {
    contactInfo,
    getLoginEmail,
    getRetailLoginEmail,
    config,
    loginStore: { username },
    config: { isRetail },
  },
}) {
  const fetchUserName = async () => {
    try {
      if (config.isRetail) {
        await getRetailLoginEmail()
      } else {
        await getLoginEmail()
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchUserName()
  }, []) // eslint-disable-line

  const [drawer, setDrawer] = useState('')
  return (
    <Card
      title='Login info'
      cardWidth='900px'
      editLabel='Edit'
      handleEdit={() => setDrawer('EditLoginInfo')}>
      {!isRetail && (
        <CardField
          type='profile'
          label='Email'
          value={<ValueInner>{username || '********'}</ValueInner>}
        />
      )}
      <CardField type='profile' label='Password' value={<ValueInner>********</ValueInner>} />
      <Drawer
        active={drawer === 'EditLoginInfo'}
        title='Login info'
        subtitle='Edit profile'
        width='50%'>
        <EditLoginInfo handleCancel={() => setDrawer('')} />
      </Drawer>
    </Card>
  )
}

export default inject('store')(observer(LoginCard))

const ValueInner = styled.div`
  display: flex;
  justify-content: space-between;
`
