const PieChart = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1792 1792" style="enable-background:new 0 0 1792 1792;" xml:space="preserve">
<style type="text/css">
	.st0-pie-chart-svg{fill:#7A8E96;}
</style>
<path class="st0-pie-chart-svg" d="M768,890l546,546c-70.7,72-153.2,128-247.5,168s-193.8,60-298.5,60c-139.3,0-267.8-34.3-385.5-103
	S171.7,1399.2,103,1281.5S0,1035.3,0,896s34.3-267.8,103-385.5S264.8,299.7,382.5,231S628.7,128,768,128V890z M955,896h773
	c0,104.7-20,204.2-60,298.5s-96,176.8-168,247.5L955,896z M1664,768H896V0c139.3,0,267.8,34.3,385.5,103s210.8,161.8,279.5,279.5
	S1664,628.7,1664,768z"/>
</svg>
`

export default PieChart
